import gql from 'graphql-tag';

const GET_LIST_VENDOR = gql`
  query getVendorList($user: uuid) {
    people_work_placements(
      distinct_on: company
      where: {
        company_profile: { types: { _ilike: "__1%" } }
        user: { _eq: $user }
      }
    ) {
      company_profile {
        id
        logo
        brand_name
        legal_name
        type
      }
    }
  }
`;

const CHECK_VENDOR_COPORATE = gql`
  query($company: uuid) {
    company_profiles(
      where: { id: { _eq: $company }, types: { _ilike: "__1%" } }
    ) {
      id
    }
  }
`;
export { GET_LIST_VENDOR, CHECK_VENDOR_COPORATE };
