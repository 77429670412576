import styled from 'styled-components';
import Constants from '../../Config/Constants';
import Assets from '../../Assets';

export const Wrapper = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 30px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin-top: 2px;
    &.lock {
      background-image: url(${Assets.ICON.LOCK});
    }
    &.up-arrow {
      background-image: url(${Assets.ICON.UP_ARROW});
    }
    &.down-arrow {
      background-image: url(${Assets.ICON.DOWN_ARROW});
    }
  }
`;

export const BodyBoxGroup = styled.div`
  width: 100%;
  background: ${Constants.COLORS.WHITE};
  /* padding: 7.4%; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .sub {
    margin-bottom: 16px;
    label {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI};
      color: ${Constants.COLORS.GREY};
    }
    p {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      color: ${Constants.COLORS.BLACK};
      margin: 0;
    }
  }
  .ant-btn:after {
    animation: 0s !important;
  }
  .showmore {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    font-size: 12px;
    color: #039be5;
    font-family: ${Assets.FONT.MULI};
    background: none;
    line-height: 0;
    display: flex;
    .icon {
      margin-left: 10px;
      bottom: 5px;
      position: relative;
    }
  }
  hr {
    background-color: ${Constants.COLORS.LIGHT_GREY};
    height: 1px;
    border: none;
  }
`;
