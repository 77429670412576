import { Icon } from 'antd';
import React from 'react';
import { WrapperItemContact } from './AnotherContact.style';

export default function ListItem({
  onClickVisibility,
  onClickEdit,
  onClickSetMain,
  isUser,
  isMain,
  isVisible,
  userName,
  url
}) {
  return (
    <WrapperItemContact>
      <div className="content">
        <h3 className="name">{userName}</h3>
        <p className="link">{url}</p>
        {isMain ? (
          <div className="main-contact">Kontak Sosial Utama</div>
        ) : isUser ? (
          <p className="set-main" onClick={onClickSetMain}>
            Tetapkan sebagai kontak sosial utama
          </p>
        ) : (
          ''
        )}
      </div>
      {isUser && (
        <div className="action">
          <Icon
            style={{
              marginRight: 21,
              color: isVisible ? '#039be5' : '#a9a8a8',
              cursor: 'pointer'
            }}
            theme="filled"
            onClick={onClickVisibility}
            type={isVisible ? 'eye' : 'eye-invisible'}
          />
          <Icon
            onClick={onClickEdit}
            type="edit"
            style={{ cursor: 'pointer' }}
            theme="filled"
          />
        </div>
      )}
    </WrapperItemContact>
  );
}
