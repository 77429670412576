import React, { useEffect, useState } from 'react';
import { ModalEdit } from '../..';
import { Form, Select, Input, message, Switch } from 'antd';
import { TitleField } from '../../../Containers/UserProfileDependent/UserDependent.style';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  ADD_ADDRESS,
  UPDATE_ADDRESS,
  SET_UN_MAIN_ADDRESS
} from './AddressDomisili.graphql';
import {
  ButtonFrom,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  SearchableSelect,
  globalCitiesSeachableVars
} from '../../../SharedComponents';
import GoogleMapReact from 'google-map-react';
import images from '../../../Assets/images';
import { MapsWrapper } from './AddressDomisili.style';
import { AuthenticationService } from '../../../Services';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const LocationIcon = ({ text }) => (
  <>
    {text}
    {/* <Icon type="environment" theme="twoTone" /> */}
    <img
      src={images.LOCATION_BLUE}
      style={{ width: 18 }}
      alt="Location Marker"
    />
  </>
);

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const AddEditAddressDomisiliComponent = ({
  open,
  handleClose,
  openAction,
  form,
  dataEdit,
  refetchList,
  setOpenAction
}) => {
  const [isMain, setIsMain] = useState(false);
  const { actionType } = openAction;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;

  const [address, setAddress] = useState('');
  const defaultValue = [-6.958983, 107.655214];
  const [coordinate, setCoordinate] = useState(defaultValue);

  const [
    getProvince,
    { data: queryProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);
  const [getCity, { data: queryCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );

  useEffect(() => {
    if (dataEdit) {
      getProvince({
        variables: {
          where: {
            deletedAt: { _is_null: true }
          }
        }
      });
      getCity({
        variables: {
          ...globalCitiesSeachableVars({ province: dataEdit?.province?.id })
        }
      });
      if (actionType === 'edit') {
        setFieldsValue({
          province: dataEdit?.province?.id,
          city: dataEdit?.city?.id
        });
      }
      if (
        actionType === 'edit' &&
        dataEdit?.address_fields?.coordinates?.lat &&
        dataEdit?.address_fields?.coordinates?.lng
      ) {
        const { lat, lng } = dataEdit?.address_fields?.coordinates;
        setCoordinate([lat, lng]);
      } else {
        setCoordinate(defaultValue);
      }
    }
    setIsMain(dataEdit?.is_main ?? false);
  }, [
    dataEdit,
    getProvince,
    getCity,
    actionType,
    setFieldsValue,
    defaultValue
  ]);

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [addAddress] = useMutation(ADD_ADDRESS, {
    context: CONTEXT_USER
  });
  const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    context: CONTEXT_USER
  });
  const [setUnmainAddress] = useMutation(SET_UN_MAIN_ADDRESS, {
    context: CONTEXT_USER
  });

  const handleCloseAct = () => {
    handleClose();
    resetFields();
    setFieldsValue({
      province: undefined,
      city: undefined
    });
    setCoordinate(defaultValue);
  };

  const handleMutation = async (type, submit) => {
    if (type === 'add') {
      await addAddress({
        variables: submit
      })
        .then(() => {
          refetchList();
          message.success(`Tambah Alamat Berhasil`);
          handleCloseAct();
        })
        .catch(err => {
          message.error(`Tambah Alamat Gagal, ${err}`);
        });
    } else {
      await updateAddress({
        variables: submit
      })
        .then(() => {
          refetchList();
          message.success(`Ubah Alamat Berhasil`);
          handleCloseAct();
        })
        .catch(err => {
          message.error(`Ubah Alamat Gagal, ${err}`);
        });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          const submit = {
            addressType: values.address_type,
            provinceId: values.province,
            cityId: values.city,
            postalCode: values.postal_code,
            address: values.address,
            isMain: isMain ?? false,
            address_fields: {
              coordinates: { lat: coordinate[0], lng: coordinate[1] },
              country: values.country,
              district: values.district,
              sub_district: values.sub_district,
              rt: values.rt,
              rw: values.rw,
              owner_status: values.owner_status,
              distance: values.distance,
              living_status: values.living_status
            }
          };
          if (isMain) {
            setUnmainAddress({
              variables: {
                user: AuthenticationService.getUserId()
              }
            })
              .then(() => {
                refetchList();
                handleMutation('add', submit);
              })
              .catch(err => {
                message.error(`Set main Address Error, ${err}`);
              });
          } else {
            handleMutation('add', submit);
          }
        } else {
          const submit = {
            id: dataEdit.id,
            userId: AuthenticationService.getUserId(),
            addressType: values.address_type,
            provinceId: values.province,
            cityId: values.city,
            postalCode: values.postal_code,
            address: values.address,
            isMain: isMain ?? false,
            address_fields: {
              ...dataEdit.address_fields,
              coordinates: { lat: coordinate[0], lng: coordinate[1] },
              country: values.country,
              district: values.district,
              sub_district: values.sub_district,
              rt: values.rt,
              rw: values.rw,
              owner_status: values.owner_status,
              distance: values.distance,
              living_status: values.living_status
            }
          };
          if (isMain) {
            setUnmainAddress({
              variables: {
                user: AuthenticationService.getUserId()
              }
            })
              .then(() => {
                refetchList();
                handleMutation('edit', submit);
              })
              .catch(err => {
                message.error(`Set main Address Error, ${err}`);
              });
          } else {
            handleMutation('edit', submit);
          }
        }
      }
    });
  };

  const handleChange = (_, target) => {
    if (target === 'main') {
      setIsMain(!isMain);
    }
  };

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      placeholder,
      style,
      loading = false,
      onSearch,
      onTouch,
      onChange,
      fieldValue
    } = res;
    if (type === 'select') {
      return (
        <Select
          loading={loading}
          size="large"
          style={{ width: '100%' }}
          onChange={value => handleChange(value, fieldName)}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={6}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const listFields = [
    {
      labelName: 'Tipe Alamat',
      fieldName: 'address_type',
      type: 'select',
      fieldValue: dataEdit?.address_type,
      placeholder: 'Pilih Tipe Alamat',
      isRequired: true,
      option: [
        { id: 'Home Address', name: 'Alamat Rumah' },
        { id: 'Office Address', name: 'Alamat Kantor' },
        { id: 'Mailing Address', name: 'Alamat Surat' },
        { id: 'Vacation Address', name: 'Alamat Pekerjaan' }
      ]
    },
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'select',
      fieldValue: dataEdit?.address_fields?.country,
      placeholder: 'Pilih Negara',
      isRequired: true,
      option: [{ id: 'Indonesia', name: 'Indonesia' }]
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      placeholder: 'Pilih Provinsi',
      isRequired: true,
      option: queryProvince?.global_provinces ?? [],
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          city: undefined,
          province: e
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      loading: loadingCity,
      fieldValue: getFieldValue('city'),
      placeholder: 'Pilih Kota',
      isRequired: true,
      option: queryCity?.global_cities ?? [],
      onSearch: ({ value }) => {
        getCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      }
    },
    {
      labelName: 'Kecamatan',
      fieldName: 'district',
      type: 'text',
      fieldValue: dataEdit?.address_fields?.district,
      placeholder: 'Tambah Kecamatan',
      isRequired: true
    },
    {
      labelName: 'Kelurahan',
      fieldName: 'sub_district',
      type: 'text',
      fieldValue: dataEdit?.address_fields?.sub_district,
      placeholder: 'Tambah Kelurahan',
      isRequired: false
    },
    {
      labelName: 'Kode Pos',
      fieldName: 'postal_code',
      type: 'number',
      fieldValue: dataEdit?.postal_code,
      placeholder: 'Tambah Kode Pos',
      isRequired: true
    }
  ];
  const listFields2 = [
    {
      labelName: 'Status Pemilik',
      fieldName: 'owner_status',
      type: 'select',
      fieldValue: dataEdit?.address_fields?.owner_status,
      placeholder: 'Pilih Status Pemilik',
      isRequired: true,
      option: [
        { id: 'Own House', name: 'Rumah Pribadi' },
        { id: 'Family House', name: 'Rumah Keluarga' },
        { id: 'Rent', name: 'Sewa' }
      ]
    }
  ];
  const listFields3 = [
    {
      labelName: 'Status Tinggal',
      fieldName: 'living_status',
      type: 'select',
      fieldValue: dataEdit?.address_fields?.living_status,
      placeholder: 'Pilih Status Tinggal',
      isRequired: true,
      option: [
        { id: 'Alone', name: 'Sendiri' },
        { id: 'Family', name: 'Keluarga' },
        { id: 'Relatives', name: 'Kerabat' },
        { id: 'Institution', name: 'Lembaga' }
      ]
    }
  ];

  const handleDelete = () => {
    setOpenAction({ ...openAction, modalDelete: true, addEdit: false });
  };

  const handleSelect = address => {
    setAddress(address);
    setFieldsValue({
      address
    });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const { lat, lng } = latLng;
        setCoordinate([lat, lng]);
      })
      .catch(error => message.error('Error', error));
  };
  return (
    <ModalEdit
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambah'} Alamat`}
      open={open}
      onClose={handleCloseAct}
      sizeSmall
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>RT/RW</TitleField>}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '30%',
                marginRight: 15
              }}
            >
              {getFieldDecorator('rt', {
                initialValue: dataEdit?.address_fields?.rt,
                rules: rule(false)
              })(
                <Input
                  placeholder="Tambah Nomor RT"
                  type="number"
                  onChange={value => handleChange(value, 'rt')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '30%' }}
              required
            >
              {getFieldDecorator('rw', {
                initialValue: dataEdit?.address_fields?.rw,
                rules: rule(false)
              })(
                <Input
                  placeholder="Tambah Nomor RW"
                  type="number"
                  onChange={value => handleChange(value, 'rw')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        <div style={{ padding: '0px', marginBottom: 0 }}>
          <PlacesAutocomplete
            value={address}
            onChange={address => setAddress(address)}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                <Form.Item
                  style={{ padding: '0px 22px', marginBottom: 0 }}
                  label={<TitleField>Alamat</TitleField>}
                >
                  {getFieldDecorator('address', {
                    initialValue: dataEdit?.address,
                    rules: rule(true)
                  })(
                    <Input
                      {...getInputProps({
                        placeholder: 'Tambah Alamat',
                        className: 'location-search-input'
                      })}
                      placeholder="Tambah Alamat"
                      type="text"
                      style={{ width: '100%' }}
                    />
                  )}
                </Form.Item>
                <div
                  style={{
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                  }}
                  className="autocomplete-dropdown-container"
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#fff',
                          cursor: 'pointer',
                          padding: '4px 0px'
                        }
                      : {
                          backgroundColor: '#fff',
                          cursor: 'pointer',
                          padding: '4px 0px'
                        };
                    return (
                      <div
                        key={`${i}`}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <div style={{ padding: '5px 12px', fontSize: 12 }}>
                          {suggestion.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <MapsWrapper style={{ padding: '0px 22px', margin: '13px 0px' }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GMAPS_KEY
            }}
            center={coordinate}
            zoom={17}
          >
            <LocationIcon lat={coordinate[0]} lng={coordinate[1]} text={''} />
          </GoogleMapReact>
        </MapsWrapper>
        {listFields2.length > 0 &&
          listFields2.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div
          style={{
            padding: '0px 22px',
            marginBottom: 0,
            display: 'flex'
          }}
        >
          <Form.Item
            style={{ display: 'inline-block', marginBottom: 0, width: '40%' }}
            label={<TitleField>Jarak Alamat ke Lokasi Kantor</TitleField>}
          >
            {getFieldDecorator('distance', {
              initialValue: dataEdit?.address_fields?.distance
                ? parseInt(dataEdit?.address_fields?.distance)
                : null,
              rules: rule(false)
            })(
              <Input
                placeholder="Tambah Jarak Alamat"
                type="number"
                onChange={value => handleChange(value, 'distance')}
                style={{ width: '100%' }}
              ></Input>
            )}
          </Form.Item>
          <div style={{ marginTop: '48px', marginLeft: '20px', fontSize: 12 }}>
            KM
          </div>
        </div>
        {listFields3.length > 0 &&
          listFields3.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginTop: 20 }}>
          <TitleField style={{ marginRight: '12px' }}>
            Tetapkan Sebagai Alamat Utama{' '}
          </TitleField>
          <Switch
            checked={isMain}
            size="small"
            onChange={value => handleChange(value, 'main')}
          />
        </div>
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleCloseAct}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditAddressDomisili = Form.create({ name: 'AddEdit_Domisili' })(
  AddEditAddressDomisiliComponent
);

export default AddEditAddressDomisili;
