import React from 'react';
import config from './TeamListPage.config';
import { Enhancer } from '../../SharedComponents';

const GroupListPageContainer = props => {
  return <div />;
};

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.menuBarList,
  defaultMenu: config.defaultMenuBar
})(GroupListPageContainer);
