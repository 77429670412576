import React, { useState } from 'react';
import { message } from 'antd';
import { FollowService } from '../../Services';
import UserCardComponent from './UserCard.component';
const UserCardContainer = props => {
  const { user, onClick } = props;
  const [isFollow, setIsFollow] = useState(user.is_followed);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);

  const handleFollow = (user, action) => {
    if (!isLoadingFollow) {
      setIsLoadingFollow(true);
      if (action === 'follow') {
        FollowService.followUser(user.id)
          .then(res => {
            if (res.status === 200) {
              message.success(`Success Follow ${user.name}`);
              setIsFollow(1);
            }
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => setIsLoadingFollow(false));
      } else {
        FollowService.unFolloweUser(user.id)
          .then(res => {
            if (res.status === 200) {
              message.success(`Unfollow ${user.name} success`);
              setIsFollow(0);
            }
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => setIsLoadingFollow(false));
      }
    }
  };

  return (
    <UserCardComponent
      {...props}
      onClick={onClick}
      isFollow={isFollow}
      isLoadingFollow={isLoadingFollow}
      handleFollow={handleFollow}
    />
  );
};

export default UserCardContainer;
