import { message } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ModalDeleteWLBCheck } from '../../Components';
import { ButtonAddList } from '../../SharedComponents';
import { displayLoading } from '../../Utils/CommonUtils';
import AddEditUserInsurance from './AddEditUserInsurance';
import { formatatSalary } from './halperInsurance';
import InsuranceItem from './InsurenceItem';
import { DELETE_INSURANCE } from './UserInsurance.graphql';

const initialOpen = {
  open: false,
  actionType: 'add',
  data: null,
  modalDelete: false
};

const style = {
  styleType: {
    color: '#014a62',
    fontSize: 14,
    fontWeight: 500,
    margin: '0 0 20px 0'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px 12px 32px'
  },
  itemsWraper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
};

export default function UserInsuranceDataComponent({
  data,
  loading = false,
  refetch,
  isUser
}) {
  const [open, setOpen] = useState(initialOpen);

  const [deleteInsurance] = useMutation(DELETE_INSURANCE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleModalEdit = state => {
    setOpen({
      ...open,
      modalDelete: false,
      open: state === 'open',
      data: state === 'open' && open.data,
      actionType: state === 'open' && 'edit'
    });
  };

  const handleDelete = () => {
    deleteInsurance({
      variables: {
        id: open.data.id
      }
    })
      .then(() => {
        refetch();
        handleModalEdit('close');
        message.success('Berhasil menghapus data asuransi');
      })
      .catch(err => {
        message.error(
          `Gagal menghapus data asuransi, silakan coba lagi nanti, ${err}`
        );
      });
  };

  const generateDateInsurance = (join, end) => {
    const date = date => {
      if (date) return moment(date).format('ll');
      else return '-';
    };
    if (join && end) {
      return `${date(join)} - ${date(end)}`;
    } else if (join && !end) {
      return `Mulai dari ${date(join)}`;
    } else if (!join && end) {
      return `Sampai ${date(end)}`;
    }
  };

  const _getList = (name, data) => {
    return data?.[name]?.map((res, i) => {
      return (
        <InsuranceItem
          key={`${i}`}
          isUser={isUser}
          onEdit={() =>
            setOpen({
              ...open,
              open: true,
              actionType: 'edit',
              data: res
            })
          }
          insuranceName={res.global_insurance_list?.name}
          insuranceNumber={res.id}
          date={generateDateInsurance(
            res.identity_fields?.join_date,
            res.identity_fields?.end_date
          )}
          balanceAmount={
            res.identity_fields?.balance_amount
              ? `Rp ${formatatSalary(`${res.identity_fields?.balance_amount}`)}`
              : 'Rp -'
          }
          family={res.identity_fields?.family}
          description={res.identity_fields?.description}
        />
      );
    });
  };

  const _insurance = () => {
    return (
      <>
        <div style={style.wrapper}>
          <h2 style={style.styleType}>Asuransi Pemerintah</h2>
          <div style={style.itemsWraper}>{_getList('government', data)}</div>
        </div>
        <div style={style.wrapper}>
          <h2 style={style.styleType}>Asuransi Premium</h2>
          <div style={style.itemsWraper}>{_getList('premium', data)}</div>
        </div>
        {isUser && (
          <ButtonAddList
            onClick={() =>
              setOpen({ ...open, open: true, actionType: 'add', data: null })
            }
            label="Tambah Data Asuransi"
          />
        )}
        <AddEditUserInsurance
          refetch={refetch}
          open={open.open}
          actionType={open.actionType}
          setOpen={setOpen}
          onClose={() => setOpen(initialOpen)}
          data={open.data}
          insuranceDatas={data}
        />
        <ModalDeleteWLBCheck
          open={open.modalDelete}
          centered
          target="Data Asuransi"
          onClose={() => handleModalEdit('open')}
          onDelete={handleDelete}
        />
      </>
    );
  };

  return <div>{loading ? displayLoading(loading) : _insurance()}</div>;
}
