import styled from 'styled-components';
import Assets from '../../Assets';

export const MemberListWrap = styled.div`
  background: ${Assets.COLOR_PALLETE.white};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 4.3%;

  .list {
    /* position: relative; */
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0px;
    .users-info {
      display: flex;
      width: 50%;
    }
    .photo {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    .info {
      margin-left: 3.2%;
      overflow: hidden;
      span {
        width: 100%;
        display: block;
        font-size: 12px;
        color: ${Assets.COLOR_PALLETE.grey};
        font-family: ${Assets.FONT.MULI};
      }
      .name {
        font-size: 14px;
        color: ${Assets.COLOR_PALLETE.black};
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
      }
    }
  }

  .button-group {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    .ant-btn-primary {
      margin-right: 16px;
    }
  }
`;
