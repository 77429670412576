import { Form, Input, message, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { ModalEdit } from '../../Components';
import { AuthenticationService } from '../../Services';
import { ButtonFrom, TitleField } from '../../SharedComponents';
import {
  GET_LIST_GLOBAL_BANK,
  ADD_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT
} from './UserBankAccount.graphql';

const rule = isRequired => {
  return [{ required: isRequired, message: 'Bagian ini diperlukan' }];
};

const setDataBank = data => {
  const result = [];
  if (data) {
    data.forEach(({ id, name }) => {
      result.push({
        id,
        name
      });
    });
  }
  return result;
};

const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

const AddEditUserBankAccountComponent = ({
  actionType,
  open,
  setOpen,
  onClose,
  form,
  data,
  userId,
  refetch
  // main_bank_account,
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    validateFields,
    resetFields,
    getFieldValue
  } = form;
  const { Option } = Select;

  const [bankName, setBankName] = useState(data?.global_bank?.name ?? '');

  const { data: dataOptionBank } = useQuery(GET_LIST_GLOBAL_BANK, {
    context: CONTEXT_USER
  });

  const [addBankAccount] = useMutation(ADD_BANK_ACCOUNT, {
    context: CONTEXT_USER
  });

  const [updateBankAccount] = useMutation(EDIT_BANK_ACCOUNT, {
    context: CONTEXT_USER
  });

  useEffect(() => {
    if (actionType === 'edit' && data) {
      if (data.is_main) {
        setFieldsValue({
          isMain: true
        });
      }
      if (data.global_bank?.name) {
        setBankName(data.global_bank?.name);
      }
    } else {
      setFieldsValue({
        isMain: false
      });
    }
  }, [actionType, data, setBankName, setFieldsValue]);

  const handleDelete = () => {
    setOpen(e => ({ ...e, open: false, modalDelete: true }));
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  function handleChange(value, fieldName, e) {
    if (fieldName === 'bank') {
      setBankName(e.props.children);
      setFieldsValue({
        [fieldName]: value
      });
    } else {
      setFieldsValue({
        [fieldName]: value
      });
    }
  }

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder, style, label } = res;
    if (type === 'select') {
      return (
        <Select
          size="large"
          style={{ width: '100%' }}
          onChange={(value, e) => handleChange(value, fieldName, e)}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={e => handleChange(e.target.value, fieldName)}
        />
      );
    } else if (type === 'checkbox') {
      return (
        <div style={{ marginTop: 35 }}>
          <span
            style={{
              fontSize: 12,
              fontWeight: 'normal',
              color: '#a9a8a8',
              marginRight: 24,
              marginTop: 18
            }}
          >
            {label}
          </span>
          <Switch
            size="small"
            checked={getFieldValue(fieldName)}
            onChange={e =>
              setFieldsValue({
                [fieldName]: e
              })
            }
          />
        </div>
      );
    }
  };

  const listFields = [
    {
      labelName: 'Bank',
      fieldName: 'bank',
      type: 'select',
      fieldValue: data?.global_bank?.id,
      placeholder: 'Pilih bank',
      isRequired: true,
      option: setDataBank(dataOptionBank?.global_banks ?? [])
    },
    {
      labelName: 'Nama Pemilik Rekening',
      fieldName: 'account_holder',
      type: 'text',
      fieldValue: data?.account_name ?? '',
      placeholder: 'Tambahkan nama',
      isRequired: true
    },
    {
      labelName: 'Nomor Akun',
      fieldName: 'account_number',
      type: 'text',
      fieldValue: data?.account_number ?? '',
      placeholder: 'Tambahkan nomor akun',
      isRequired: true
    }
    // {
    //   labelName: "Currency Code",
    //   fieldName: "currency_code",
    //   type: "text",
    //   fieldValue: "",
    //   placeholder: "Add Currency Coder",
    //   isRequired: true,
    // },
    /**Hide: WLB-3894 */
    // {
    //   label: "Set as main Bank Account",
    //   fieldName: "isMain",
    //   type: "checkbox",
    //   fieldValue: false,
    // },
  ];

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          const submit = {
            user: AuthenticationService.getUserId(),
            isMain: values.isMain || false,
            bank: values.bank,
            bank_name: bankName,
            account_name: values.account_holder,
            account_number: values.account_number,
            currency_code: 'IDR'
          };
          addBankAccount({
            variables: submit
          })
            .then(() => {
              message.success(`Berhasil menambahkan rekening bank`);
              refetch();
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan rekening bank, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          updateBankAccount({
            variables: {
              user: AuthenticationService.getUserId(),
              isMain: values.isMain || false,
              id: data.id,
              bank: values.bank,
              bank_name: bankName,
              account_number: values.account_number,
              currency_code: 'IDR',
              account_name: values.account_holder
            }
          })
            .then(() => {
              message.success(`Berhasil mengubah rekening bank`);
              refetch();
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal mengubah rekening bank, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };
  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Rekening Bank`}
      open={open}
      onClose={handleClose}
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={
                  res.labelName ? (
                    <TitleField>{res.labelName}</TitleField>
                  ) : null
                }
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired ?? false)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditUserBankAccount = Form.create({ name: 'Add_Edit_Bank_Account' })(
  AddEditUserBankAccountComponent
);

export default AddEditUserBankAccount;
