import React from 'react';

import { WrapChipsSection } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { formatDate } from '../../../Components/DetailUserSection/helperForDetail';

function Disciplinary({ data, i, count, nameUser }) {
  const key = data.id;
  const noBorder = count.length === i + 1 || false;
  const discipline_name = data.discipline_name || '-';
  const reference_number = data.reference_number || '-';
  const start_date = formatDate(data.start_date) || '-';
  const end_date = formatDate(data.end_date) || '-';
  const name_user = nameUser || '-';
  const discipline_letter_number = data.discipline_letter_number || '-';
  const reference_date = formatDate(data.reference_date) || '-';
  const remark = data.remark || '-';
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };

  return (
    <WrapChipsSection key={key} fullchips noBorder={noBorder}>
      <div className="chips-wrap">
        <div className="chips">
          <h4 className="chips-name">{renderValue(discipline_name, '-')}</h4>
        </div>
      </div>
      <div className="content">
        <div className="divider">
          <div>
            <h3 className="font-14 color-black semi-bold mb-regular">
              Ref No.{renderValue(reference_number, '-')}
            </h3>
            <p className="color-grey font-12">
              {start_date} - {end_date}
            </p>
            <p className="font-12 semi-bold color-black">
              Given to {name_user}
            </p>
            <p className="font-12 color-primary">
              {renderValue(discipline_letter_number, '-')}
            </p>
            <p className="color-grey font-12 mb-16">
              {renderValue(reference_date, '-')}
            </p>
            <p className="font-12 color-black pre-line">
              {renderValue(remark, '-')}
            </p>
          </div>
        </div>
      </div>
    </WrapChipsSection>
  );
}

export default Disciplinary;
