import React from 'react';
import config from './FollowPage.config';

import { Enhancer } from '../../SharedComponents';

const FollowPageContainer = () => <div />;

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.menuBarList,
  defaultMenu: config.defaultMenuBar
})(FollowPageContainer);
