export const getSubType = type => {
  const _subType = {
    time: '^company-time',
    company: '^company-placement',
    'business-trip': '^business-trip'
  };
  return _subType[type];
};

export const getType = type => {
  let _type = null;
  switch (type) {
    case 'time':
      _type = 'company';
      break;
    case 'all':
    case 'business-trip':
      _type = null;
      break;
    default:
      _type = type;
      break;
  }
  return _type;
};
