import React from 'react';
import { Row, Col } from 'antd';
import {
  SidebarGroupInfoBox,
  MemberCardBox,
  Widget
} from '../../../Containers';
import InviteMemberGroup from '../../../Containers/InviteMemberGroup';

const MemberScreenComponent = props => {
  const { isAdmin } = props;

  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarGroupInfoBox {...props} />
        </Col>
        <Col span={12}>
          <MemberCardBox {...props} />
        </Col>
        <Col span={6}>
          {isAdmin && <InviteMemberGroup {...props} />}
          <Widget {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default MemberScreenComponent;
