import { Button, Checkbox, Modal, Input, Typography } from 'antd';
import React, { memo, useState } from 'react';
import { ModalWrapperDeleteCheck } from './ModalTextarea.style';

export default memo(function ModalTextArea({
  centered,
  open,
  title,
  onClose,
  onDelete,
  label,
  labelCheck,
  action,
  value,
  setValue
}) {
  const [checked, setChecked] = useState(false);
  const { TextArea } = Input;

  const handleClose = () => {
    onClose();
    setChecked(false);
  };

  const handleDelete = () => {
    onDelete();
    setChecked(false);
  };

  return (
    <Modal
      centered={centered}
      visible={open}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalWrapperDeleteCheck>
        <div className="header">{title}</div>
        <div className="body">
          <Typography className="label">{label}</Typography>
          <TextArea rows={4} value={value} onChange={setValue} />
          <div className="check">
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="check-action"
            />
            <p>{labelCheck}</p>
          </div>
        </div>
        <div className="action">
          <span className="cancel" onClick={handleClose}>
            Batalkan
          </span>
          <Button
            disabled={!checked}
            onClick={handleDelete}
            type="primary"
            className="delete"
          >
            {action}
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Modal>
  );
});
