import React, { useState, useCallback } from 'react';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { OverlayWrapper } from './PostImage.style';
import { AuthenticationService } from '../../Services';

const ImageComponent = ({ index, photo, onClick, imageLength }) => {
  return (
    <OverlayWrapper onClick={() => onClick(index)}>
      <img
        alt={photo.title}
        {...photo}
        style={{ imageOrientation: 'from-image', padding: 2 }}
      />
      {index === 3 && imageLength > 4 && (
        <div className="Overlay">
          <div className="textOverlay">+{imageLength - 3}</div>
        </div>
      )}
    </OverlayWrapper>
  );
};

const PostImageComponent = ({ images }) => {
  const userData = AuthenticationService.getUserData();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = index => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const getMeta = url => {
    var img = new Image();
    img.onload = function() {
      return {
        width: this.width,
        height: this.height
      };
    };
    img.src = url;
    return img.onload();
  };
  const imagesGallery = images.map(image => {
    const meta = getMeta(image.url);
    return {
      src: image.url,
      width: meta.width === 0 ? 1 : meta.width,
      height: meta.height === 0 ? 1 : meta.height
    };
  });
  const imagesGalleryReduced = images.map(image => {
    const imgUrl = (userData?.thumbnail_url ?? '') + image.url;
    const meta = getMeta(imgUrl);
    return {
      src: imgUrl,
      width: meta.width === 0 ? 1 : meta.width,
      height: meta.height === 0 ? 1 : meta.height
    };
  });
  const imageLength = imagesGallery.length;

  const imageRenderer = useCallback(
    ({ index, key, photo }) => (
      <ImageComponent
        key={key}
        index={index}
        photo={photo}
        onClick={openLightbox}
        imageLength={imageLength}
      />
    ),
    [imageLength]
  );

  return (
    <div>
      <Gallery
        photos={imagesGalleryReduced.slice(0, 4)}
        renderImage={imageRenderer}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal
            closeOnBackdropClick={true}
            allowFullscreen={true}
            onClose={closeLightbox}
            styles={{
              blanket: base => ({
                ...base
              }),
              positioner: base => ({
                ...base,
                marginTop: 95
              })
            }}
          >
            <Carousel
              styles={{
                view: base => ({
                  ...base,
                  imageOrientation: 'from-image',
                  alignItems: 'center',
                  display: 'flex ',
                  justifyContent: 'center',
                  '& > img': {
                    maxHeight: 'calc(100vh - 230px)'
                  }
                }),
                container: base => ({
                  ...base,
                  width: '100%'
                })
              }}
              currentIndex={currentImage}
              frameProps={{ autoSize: false }}
              views={imagesGallery.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};
export default PostImageComponent;
