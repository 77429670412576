import styled from 'styled-components';
import Assets from '../../Assets';

export const SearchNotFoundWrapper = styled.div`
  margin-top: 15px;
`;

export const SearchCategory = styled.div`
  font-weight: bold;
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 15px;
  background-color: #ffffff;
`;

export const CategoryLabel = styled.label`
  height: 13.5px;
  font-family: 'Muli-SemiBold', sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-left: 35px;
`;

export const SearchContainer = styled.div`
  background-color: #f6f8f9;
  margin-bottom: 10px;
  margin: auto;
  .ant-list {
    background: white;
  }

  .ant-btn-default {
    border-radius: 10%;
    margin-right: 10px;
  }

  .ant-btn-primary {
    margin-right: 10px;
  }

  .ant-list-item {
    margin: 0 35px 0 35px;
  }

  .ant-list-item-meta-description {
    line-height: inherit;
  }

  .my-team {
    margin-bottom: 10px;
  }
`;

export const Name = styled.label`
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  font-weight: bold;
  color: black;
`;

export const Link = styled.a`
  color: black;
`;

export const JobDetails = styled.label`
  font-size: 12px;
  color: grey;
`;

export const FollowedBy = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 15px;
    height: 10px;
    margin: 5px 5px 0 0;
    background-image: url(${Assets.ICON.FOLLOWED});
  }

  .company-follower {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin: 4px 3px 0 3px;
    background-image: url(${Assets.ICON.COMPANY_FOLLOWER});
  }
`;

export const SearchPageComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 25px 0 25px;

  .schedule {
    padding-top: 15px;
    width: 300px;
  }

  #searched-result-list {
    margin-bottom: 5px;
  }
`;

export const Description = styled.div`
  font-size: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const AllSearchTitle = styled.div`
  padding-top: 25px;
  margin-bottom: 10px;
  margin: auto;
`;
