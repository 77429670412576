import React, { useState } from 'react';
import { Button, Collapse } from 'antd';
import { CollapseWrap, PanelItem } from './Collapse.style';
import { DeleteModal, CreateClassroomForm, ButtonWlb, ModalTextArea } from '..';
import { displayLoading } from '../../Utils/CommonUtils';

const { Panel } = Collapse;

const CollapseComponent = props => {
  const {
    deleteClassroomHandler,
    displayDeleteModal,
    setDisplayDeleteModal,
    displaySessionModal,
    setDisplaySessionModal,
    handleUpdateGroup,
    isLoading,
    isRefetch,
    title,
    isOwner,
    endSessionHandler,
    dataClassroom,
    isIndividual
  } = props;

  const [resume, setResume] = useState('');

  return (
    <CollapseWrap>
      <Collapse defaultActiveKey={['group-intro']}>
        <Panel header="Pendahuluan Kelompok Belajar" key="group-intro">
          {isRefetch ? (
            displayLoading(isRefetch)
          ) : (
            <CreateClassroomForm title={title} {...props} />
          )}
          <ButtonWlb
            style={{ marginLeft: '4.3%', marginBottom: '4.3%' }}
            key="1"
            type="primary"
            loading={isLoading}
            onClick={handleUpdateGroup}
          >
            Perbarui
          </ButtonWlb>
        </Panel>
        {isOwner && (
          <Panel header="Kelola Kelompok Pembelajar" key="manage-group">
            <PanelItem>
              <div className="text">
                <p>Hapus Kelompok Pembelajaran </p>
                <p>Jika anda menghapus kelas semua aktifitas akan dihapus</p>
                <Button
                  type="danger"
                  onClick={() => setDisplayDeleteModal(true)}
                >
                  Hapus Kelompok Pembelajaran
                </Button>
                <DeleteModal
                  displayModal={displayDeleteModal}
                  closeModal={() => setDisplayDeleteModal(false)}
                  handleDelete={() => deleteClassroomHandler()}
                  modalTitle="Hapus Kelompok Pembelajaran"
                  modalContent="Apakah Anda yakin ingin menghapus Kelompok Pembelajaran ini? Ini akan menghapus catatan secara permanen"
                />
              </div>
            </PanelItem>
            {dataClassroom && !dataClassroom.finished && (
              <PanelItem>
                <div className="text">
                  <p>Akhir Sesi</p>
                  <p>Akhiri sesi dan semua aktifitas akan dianggap lengkap</p>
                  <ButtonWlb
                    type="primary"
                    onClick={() => setDisplaySessionModal(true)}
                  >
                    Akhir Sesi
                  </ButtonWlb>
                  {/* <DeleteModal
                    wording={'Yes'}
                    displayModal={displaySessionModal}
                    closeModal={() => setDisplaySessionModal(false)}
                    handleDelete={() => endSessionHandler()}
                    modalTitle={'End Session Group Learning'}
                    modalContent={`Are you sure want to end session this Group Learning?`}
                  /> */}
                  <ModalTextArea
                    open={displaySessionModal}
                    isIndividual={isIndividual}
                    centered
                    title="Akhiri Sesi belajar kelompok"
                    label="Ringkasan"
                    labelCheck="Saya mengerti bahwa sesi akhir pembelajaran kelompok ini bersifat permanen dan tidak dapat dibatalkan"
                    action="Akhiri Sesi"
                    value={resume}
                    setValue={e => setResume(e.target.value)}
                    onClose={() => setDisplaySessionModal(false)}
                    onDelete={() => endSessionHandler(resume)}
                  />
                </div>
              </PanelItem>
            )}
          </Panel>
        )}
      </Collapse>
    </CollapseWrap>
  );
};

export default CollapseComponent;
