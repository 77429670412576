import React from "react";
import { CenterLayout } from "../../Components";
import { UserProfileCV } from "../../Containers";

export default function UserPrintCVPage() {
  return (
    <CenterLayout style={{minWidth: 500}} >
      <UserProfileCV />
    </CenterLayout>
  );
}
