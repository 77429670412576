import gql from 'graphql-tag';

export const GET_LIST_RELATIONSHIP = gql`
  {
    people_profile_recommendation_relationships {
      id
      name
    }
  }
`;
export const GET_DETAIL_REQUEST = gql`
  query($where: people_profile_request_recommendations_bool_exp) {
    people_profile_request_recommendations(where: $where) {
      id
      comment
      position
      relationship
      global_user {
        id
        name
        avatar
      }
    }
  }
`;

export const GET_DETAIL_REQUEST_OLD = gql`
  query($id: Int) {
    people_profile_request_recommendations(where: { id: { _eq: $id } }) {
      id
      comment
      position
      relationship
      global_user {
        id
        name
        avatar
      }
    }
  }
`;

export const GET_DATA_RECOMENDATION = gql`
  query($user: uuid!, $id: Int!) {
    people_profile_recommendations(
      where: { id: { _eq: $id }, recommendation_from: { _eq: $user } }
      order_by: [{ id: desc }]
    ) {
      id
      globalUserByRecommendationTo {
        id
        name
        avatar
        people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
          company_job_profile {
            title
          }
        }
      }
      recommendation_from
      position
      relationship
      description
      status
      date_added
      last_modified
    }
  }
`;

export const REVISE_RECOMMENDATION = gql`
  mutation($id: Int!, $description: String!) {
    update_people_profile_recommendations(
      _set: { description: $description }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const GET_LIST_POSITION = gql`
  query($userLogin: uuid!) {
    experience: people_profile_experiences(
      where: { user: { _eq: $userLogin } }
    ) {
      id
      company_name
    }

    education: people_profile_educations(where: { user: { _eq: $userLogin } }) {
      company_profile {
        id
        brand_name
      }
    }
  }
`;

export const GIVE_RECOMMENDATION = gql`
  mutation($objects: [people_profile_recommendations_insert_input!]!) {
    insert_people_profile_recommendations(objects: $objects) {
      affected_rows
    }
  }
`;

export const REQUEST_REFERRAL = gql`
  mutation($objects: [people_profile_request_recommendations_insert_input!]!) {
    insert_people_profile_request_recommendations(objects: $objects) {
      affected_rows
    }
  }
`;
