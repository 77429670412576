import Styled from "styled-components";

export const WrapperSkill = Styled.div`
  max-height: ${props => props.maxHeight ? props.maxHeight : '370px'};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16); 
    border-radius: 5px;
  }
`
export const SkillsItem = Styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    padding: 30px 25px;
    
    .skill-type { 
      position: relative;
      width: 171px;
      height: 50px;
      text-align: center;
      border: 1px solid #e5e5e5;
      border-radius: 41px;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 0px 63px;
      margin-right: 32px;
      
      .recommendation-count {
        position: absolute;
        top: -5px;
        left: 0;
        height: 55px;
        width: 55px;
        background-color: #014a62;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .detail-section { 
      display: flex;
      flex-direction: column;
      width: 294px;
      margin-right: auto;
  
      .level {
        font-size: 14px;
        font-weight: 600;
        color: #014a62;
        margin-bottom: 19px;
        text-transform: capitalize;
      }
      
      .share-with {
        font-size: 12px;
        color: #a9a8a8;
        margin-bottom: 10px;
      }
  
      .endorse-wrapper {
        display: flex;
        flex-direction: row;
      }

      .dumy {
        padding: 24px 0px;
      }
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: 376px;
    margin-left: auto
  }
`;

export const ListPeople = Styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .flex-col {
    flex-direction: column;
  }

  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 22px;

    .user-info {
      display: flex;

      .avatar {
        margin-right: 20px;
      }
      .name-section {
        max-width: 375px;
        
        .name {
          margin: 0 0 2px 0;
          font-weight: 600;
          font-size: 14px;
          color: #000;
        }
        .position {
          margin: 0;
          font-size: 12px;
          font-weight: normal;
          color: #a9a8a8;
        }
      }
    }

    .action {
      width: 91px;
    }

    .ant-btn-default {
      border-color: #039be5;
      color: #039be5;
    }
  }
  .infinite-scroll-component  {
    ::-webkit-scrollbar {
      width: 8px;
    }
    
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.16); 
      border-radius: 5px;
    }
  }
`