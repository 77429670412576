import React from 'react';
import { CreateGroupForm, TopSettingGroup } from '../../Components';
import { style } from './CreateGroupBox.style';

const CreateGroupBoxComponent = props => {
  const { title, buttonTop } = props;

  return (
    <div style={style.boxShadow}>
      <TopSettingGroup title={title} buttonTop={buttonTop} {...props} />
      <CreateGroupForm {...props} />
    </div>
  );
};

export default CreateGroupBoxComponent;
