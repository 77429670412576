import styled from 'styled-components';
import Assets from '../../Assets';

export const PostCardWrapper = styled.div`
  position: relative;
  min-height: 100px;
  display: flow-root;
  padding: 18px;
  border-radius: 3px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 15px;
  .deleted_share_title {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .author {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    padding-bottom: 15px;
    .photo {
      width: 35px;
      height: 35px;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .right {
      float: right;
    }
    .info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .name-container {
      }
      .pos-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: 10px;
      }
      span {
        display: block;
      }
      .name {
        font-family: ${Assets.FONT.MULI_BOLD};
        color: #252525;
        font-size: 12px;
        cursor: pointer;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      .desc {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 11px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      .learning-tracker {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #52c41a;
        font-size: 11px;
      }
    }
    .more-option {
      width: 9px;
      height: 7px;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .time {
      text-align: right;
      min-width: 72px;
      span {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 11px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
      }
    }
  }
  .content {
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 12px;
    line-height: 14px;
    margin-top: 10px;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;

    .activity {
      display: block;
      margin: 8px 0;
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      color: ${Assets.COLOR_PALLETE.dark_blue};
      font-size: 16px;
    }

    .info {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      color: #a9a8a8;
      font-size: 11px;
    }

    .description {
      margin-top: 12px;
      line-height: 1.6;
      font-family: ${Assets.FONT.MULI};
      color: ${Assets.COLOR_PALLETE.black};
      font-size: 12px;
    }

    .mentee {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      color: #a9a8a8;
      font-size: 14px;
    }

    .image-wrapper {
      margin-top: 8px;
      margin-bottom: 2px;
      display: flex;
      flex-direction: row;
      justify-content: start;

      .image {
        margin-right: 6px;
        height: 36px;
        width: 36px;
        border-radius: 50%;
      }

      .more {
        display: flex;
        justify-content: center;
        padding: 4px;
        background-color: #a9a8a8;
        margin-right: 6px;
        height: 36px;
        width: 36px;
        border-radius: 50%;

        .length {
          margin: auto;
          color: #fff;
        }
      }
    }
  }

  .action-group {
    margin-top: 14px;
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
    padding-bottom: 14px;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    .item {
      margin-right: 24px;
      float: left;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .icon {
      float: left;
    }
    .text {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI};
      color: ${Assets.COLOR_PALLETE.grey};
      float: left;
      /* margin-left: 5px; */
    }
  }
  .text.kudos,
  .right {
    float: right !important;
    cursor: pointer;
  }
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    &.love {
      background-image: url(${Assets.ICON.LOVE});
    }
    &.love-pink {
      background-image: url(${Assets.ICON.LOVE_PINK});
    }
    &.comment {
      background-image: url(${Assets.ICON.COMMENT});
    }
    &.share {
      background-image: url(${Assets.ICON.SHARE});
    }
    &.star {
      background-image: url(${Assets.ICON.STAR});
    }
    &.trash {
      background-image: url(${Assets.ICON.TRASH});
    }
  }
  .award {
    .icon {
      &.star {
        background-image: url(${Assets.ICON.STAR_YELLOW});
      }
    }
    .text {
      color: ${Assets.COLOR_PALLETE.black2};
    }
  }

  .item.kudos {
    position: relative;
  }

  .kudos_image {
    float: left;
    margin-right: 3px;
    margin-top: -5px;

    img {
      width: 32px;
      border: 1px solid ${Assets.COLOR_PALLETE.white};
      border-radius: 90px;
      margin-right: -5px;
    }
  }
  .second_row {
    margin-top: 40px;
  }
  .component_kudos_post_card {
    margin-top: -10px;
  }
  .cursor_context_menu {
    cursor: context-menu;
  }

  .component_dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 90px;
    background: ${Assets.COLOR_PALLETE.grey};
    margin-bottom: 2px;
  }

  .pointer {
    cursor: pointer;
  }
`;
