import React from 'react';
import { isUrl, isHashtag } from '../../Utils/CommonUtils';

function ContentLink({ content }) {
  if (content) {
    const splittedContent = content.split(' ');
    const urlContent = splittedContent.map((str, id) => {
      if (isUrl(str)) {
        const replaceHttps = str.replace('https://', '');
        const replaceHttp = replaceHttps.replace('http://', '');
        return (
          <a
            href={'http://' + replaceHttp}
            key={id}
            target="_blank"
            rel="noopener noreferrer"
          >
            {str + ' '}
          </a>
        );
      }
      if (isHashtag(str)) {
        return (
          <span style={{ color: '#1890ff' }} key={id}>
            {str + ' '}
          </span>
        );
      }
      return str + ' ';
    });
    return <p> {urlContent} </p>;
  }
  return <></>;
}

export default ContentLink;
