import React, { useState } from 'react';
import { Spin } from 'antd';
import PostBox from '../PostBox';
import { CardBoxWrap } from './ClassroomEditPost.style';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { TopSettingGroup } from '../../Components';

import { GET_POST_BY_ID } from './graphql';

import Constants from '../../Config/Constants';

const ClassroomEditPostComponent = props => {
  const params = useParams();
  const { title } = props;
  const [isLoading, setLoading] = useState(false);

  const { data, loading } = useQuery(GET_POST_BY_ID, {
    variables: {
      id: params.postId
    },
    context: { headers: { 'X-Hasura-Role': 'user' } },

  });
  return (
    <CardBoxWrap>
      <div className="box">
        {data && !loading && !isLoading ? (
          <>
            <TopSettingGroup title={title} />
            <PostBox
              postType={Constants.POST_TYPE.CLASSROOM}
              edit={'edit-learning-log'}
              editData={data && data.classroom_posts[0]}
              setLoading={setLoading}
              {...props}
            />
          </>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin />
          </div>
        )}
      </div>
    </CardBoxWrap>
  );
};

export default ClassroomEditPostComponent;
