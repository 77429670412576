import { gql } from 'apollo-boost';

export const JOIN_CLASSROOM = gql`
  mutation($classroomId: String!) {
    classroomJoin(classroomId: $classroomId) {
      success
    }
  }
`;

export const CANCEL_JOIN_CLASSROOM = gql`
  mutation($classroomId: String!) {
    cancelRequestMember(classroomId: $classroomId) {
      success
    }
  }
`;

export const PAY_CLASSROOM = gql`
  mutation(
    $objects: [marketplace_user_carts_insert_input!]!
    $user: uuid
    $item_id: String
  ) {
    delete_marketplace_user_carts(
      where: {
        user: { _eq: $user }
        item_id: { _eq: $item_id }
        item_table: { _eq: "classrooms" }
      }
    ) {
      affected_rows
    }

    insert_marketplace_user_carts(objects: $objects) {
      affected_rows
    }
  }
`;
