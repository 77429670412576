import React from 'react';
import { TextSecondary, TextStandard, TextGrey } from '../UserProfileCV.style';

export default function Award({
  title,
  nomor,
  date,
  regno,
  to,
  desc,
  issuing_organization
}) {
  return (
    <div style={{ marginBottom: 35 }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextGrey>{issuing_organization}</TextGrey>
      <TextSecondary>{nomor}</TextSecondary>
      <TextGrey>{date}</TextGrey>
      <TextGrey>{regno}</TextGrey>
      <TextSecondary>{to}</TextSecondary>
      <TextStandard>{desc}</TextStandard>
    </div>
  );
}
