import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { KudosMenus, KudosPopup } from '../../Components';
import { isEmpty } from 'lodash';
import { AuthenticationService } from '../../Services';

const KudosComponent = props => {
  const {
    itemPost,
    postKudosHandler,
    setIsKudosPopupShow,
    isKudosPopupShow,

    kudosList,
    postCancelKudos
  } = props;
  const kudosBy = itemPost?.kudosBy;
  const [isKudosShow, setIsKudosShow] = useState(false);
  const {
    company_id: companyId,
    id: profileId
  } = AuthenticationService.getUserData();

  const [userHasGiveKudo, setUserHasGiveKudo] = useState(() => {
    const kudosByUser = kudosBy.filter(d => d.user_id === profileId);
    if (kudosByUser.length === 0) return [];
    return kudosList.filter(kudos => kudos.id === kudosByUser[0].kudos_id);
  });

  const fetchKudos = kudosItem => {
    setUserHasGiveKudo([
      {
        url_image: kudosItem.url_image,
        template_text: kudosItem.template_text
      }
    ]);
  };

  return (
    <>
      {isKudosPopupShow && (
        <KudosPopup
          modalVisibility={isKudosPopupShow}
          setModalVisibility={setIsKudosPopupShow}
          postId={itemPost?.id}
          companyId={companyId}
          kudosList={kudosList}
        />
      )}
      <KudosMenus
        visible={isKudosShow}
        setVisible={setIsKudosShow}
        kudosList={kudosList}
        onClickItem={kudosItem => {
          postKudosHandler(kudosItem, setIsKudosShow, fetchKudos);
        }}
      />
      {isEmpty(userHasGiveKudo) && (
        <span
          className="icon star"
          onClick={() => {
            setIsKudosShow(true);
          }}
        ></span>
      )}
      {!isEmpty(userHasGiveKudo) && (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => {
                  setIsKudosShow(true);
                }}
              >
                Edit Kudos
              </Menu.Item>
              <Menu.Item
                key="1"
                style={{ color: '#ef4d5e' }}
                onClick={() => {
                  postCancelKudos(() => setUserHasGiveKudo([]));
                }}
              >
                Cancel Kudos
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
          placement="bottomRight"
        >
          <span className="kudos_image">
            <img
              src={userHasGiveKudo[0]?.url_image}
              alt={userHasGiveKudo[0]?.template_text}
            />
          </span>
        </Dropdown>
      )}
    </>
  );
};

export default KudosComponent;
