import styled from 'styled-components';
import Assets from '../../Assets';

export const BannerDetailUserWrap = styled.div`
  position: relative;

  .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 32px;
  }
  .ant-tabs-nav-wrap {
    background-color: ${Assets.COLOR_PALLETE.grey2};
  }
  .ant-tabs {
    color: ${Assets.COLOR_PALLETE.grey};
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${Assets.COLOR_PALLETE.black};
  }
`;
