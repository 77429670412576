import React from 'react';
import ListChips from '../../../../Components/DetailUserSection/ListChips.component';
import { AttachementItemIcon } from '../../../../SharedComponents';

export default function DisciplinaryItem({
  onClickEdit,
  discipline_name,
  reference_number,
  start_date,
  end_date,
  name_user,
  discipline_letter_number,
  reference_date,
  remark,
  attachments,
  noBorder = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <ListChips
      noBorder={noBorder}
      onClickEdit={onClickEdit}
      chipsName={discipline_name}
    >
      <h3 className="font-14 color-black semi-bold mb-regular">
        Ref No.{reference_number}
      </h3>
      <p className="color-grey font-12">
        {start_date} - {end_date}
      </p>
      <p className="font-12 semi-bold color-black">Given to {name_user}</p>
      <p className="font-12 color-primary">{discipline_letter_number}</p>
      <p className="color-grey font-12 mb-16">{reference_date}</p>
      <p className="font-12 color-black pre-line">
        {remark.slice(0, (!isMore && 370) || remark.length) || '-'}
      </p>
      {isMore && (
        <div className="attachment-wrapper">
          {attachments?.map((res, i) => (
            <AttachementItemIcon
              key={`${i}-idx`}
              className="attachment-icon"
              name={res.name}
              url={res.url || res.link}
              size={res.size}
            />
          ))}
        </div>
      )}
      <span className="see-more" onClick={() => setIsMore(!isMore)}>
        ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
      </span>
    </ListChips>
  );
}
