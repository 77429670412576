import React from "react";
import {
  DetailWrapper,
  DetailBadgeItem,
  BadgeLogo,
  BadgeContent,
  Title,
  BlueText,
  GreyText,
  Description,
  AvatarStyled,
} from "./Badges.style";
import { getYear } from "./utilsBadge";

export default function DetailBadge({ data = [] }) {
  return (
    <DetailWrapper>
      {data.talent_assignments.length > 0 ? (
        data?.talent_assignments?.map((res, i) => {
          return (
            <DetailBadgeItem key={i}>
              <BadgeLogo>
                <AvatarStyled src={res?.talent_box?.badge} size={93} shape="square" />
              </BadgeLogo>
              <BadgeContent>
                <Title style={{ textAlign: "left", marginTop: 12 }}>
                  {res?.talent_box?.name ?? "-"}
                </Title>
                <BlueText style={{ textAlign: "left" }}>
                  {res?.talentVersionByTalentVersion?.name ?? "-"}
                </BlueText>
                <GreyText style={{ textAlign: "left" }}>
                  {getYear(
                    res?.talentVersionByTalentVersion?.start_date,
                    res?.talentVersionByTalentVersion?.end_date
                  )}
                </GreyText>
                <Description>{res?.talent_box?.description ?? "-"}</Description>
              </BadgeContent>
            </DetailBadgeItem>
          );
        })
      ) : (
        <div style={{ height: 150 }} />
      )}
    </DetailWrapper>
  );
}
