import React, { useState } from 'react';
import DetailScreen from './DetailScreen';
import PeopleScreen from './PeopleScreen';
import RegulationScreen from './RegulationScreen';
import PostScreen from './PostScreen';
import BannerPage from './BannerPage.component';
// import PostId from './PostScreen/PostScreenId';

const MenuDetail = props => {
  const [dataLoadedCompany, setDataLoadedCompany] = useState({});
  const [updateedFollowers, setUpdateedFollowers] = useState(0);
  return (
    <BannerPage
      setUpdateedFollowers={setUpdateedFollowers}
      isDetailPage
      setDataLoadedCompany={setDataLoadedCompany}
    >
      <DetailScreen
        updateedFollowers={updateedFollowers}
        dataLoadedCompany={dataLoadedCompany}
        {...props}
      />
      ,
    </BannerPage>
  );
};
const menuBarList = [
  {
    renderComponent: props => <MenuDetail {...props} />,
    name: 'Detail',
    path: '/company/:id/detail'
  },
  {
    renderComponent: props => (
      <BannerPage>
        <PostScreen {...props} />
      </BannerPage>
    ),
    name: 'Posting',
    path: '/company/:id/post'
  },
  // {
  //   renderComponent: props => (
  //     <BannerPage {...props}>
  //       <PostId {...props} />
  //     </BannerPage>
  //   ),
  //   name: 'PostId',
  //   path: '/company/:id/post/:postId'
  // },
  {
    renderComponent: props => (
      <BannerPage {...props}>
        <PeopleScreen {...props} />
      </BannerPage>
    ),
    name: 'Pegawai',
    path: '/company/:id/people'
  },
  {
    renderComponent: props => (
      <BannerPage {...props}>
        <RegulationScreen {...props} />
      </BannerPage>
    ),
    name: 'Regulasi',
    path: '/company/:id/regulation'
  }
];
const defaultMenuBar = 'Detail';

export default {
  menuBarList,
  defaultMenuBar
};
