import React, { useState } from "react";
import { Switch } from "antd";
import { WrapperSettingSection } from "../ActionMore.style";

export default function SettingList({
  name,
  description,
  data,
  value,
  onChange,
}) {
  const [check, setCheck] = useState(Object.values(data).includes(value));
  const handleChange = (val, name) => {
    setCheck(val);
    onChange(val, name);
  };
  return (
    <WrapperSettingSection>
      <div className="label-info">
        <h1
          style={{
            textTransform: "capitalize",
          }}
        >
          {name}
        </h1>
        <p>{description}</p>
      </div>
      <div className="action">
        <p className="condition">{check ? 'Show' : "Hide"}</p>
        <Switch
          defaultChecked={check}
          onChange={handleChange}
          className="checkbox-setting"
          size="small"
        />
      </div>
    </WrapperSettingSection>
  );
}
