import { DatePicker, Form, Input, message, Select } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { ModalEdit } from '../../Components';
import { AuthenticationService } from '../../Services';
import {
  Attachment,
  ButtonFrom,
  TitleField,
  AttachmentItem
} from '../../SharedComponents';
import { checkFilesType } from './helperMedicalHistory';
import {
  ADD_MEDICAL_HISTORY,
  EDIT_MEDICAL_HISTORY,
  LIST_MEDICAL_CATEGORY
} from './UserMedicalHistory.graphql';

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const AddEditMedicalHistoryComponent = ({
  actionType,
  open,
  onClose,
  setOpen,
  form,
  data,
  refetch
}) => {
  const {
    getFieldDecorator,
    // setFieldsValue,
    validateFields,
    resetFields,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [attachment, setAttachment] = useState([]);

  useEffect(() => {
    if (data) {
      setAttachment(data.attachments);
    }
  }, [data]);

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [addMedicalHistory] = useMutation(ADD_MEDICAL_HISTORY, {
    context: CONTEXT_USER
  });
  const [editMedicalHistory] = useMutation(EDIT_MEDICAL_HISTORY, {
    context: CONTEXT_USER
  });

  const { data: dataCategory } = useQuery(LIST_MEDICAL_CATEGORY);

  const handleClose = () => {
    onClose();
    resetFields();
    setAttachment([]);
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          const submit = {
            objects: [
              {
                // user_id: AuthenticationService.getUserId(),
                name: values.name,
                age: values.age ? parseInt(values.age) : null,
                start_date: moment(values.start_date).format('ll'),
                end_date: moment(values.end_date).format('ll'),
                hospital_name: values.hospital_name,
                doctor_name: values.doctor_name,
                disease: values.disease,
                consequence: values.consequence,
                laboratory_number: values.laboratory_number,
                laboratory_notes: values.laboratory_notes,
                medical_score: values.medical_score,
                medical_fee: values.medical_fee,
                medical_category: values.medical_category,
                attachments:
                  attachment.length > 0
                    ? [
                        {
                          name: attachment?.[0]?.name,
                          size: attachment?.[0]?.size,
                          url: attachment?.[0]?.url
                        }
                      ]
                    : []
              }
            ]
          };
          addMedicalHistory({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil menambahkan rekam medis`);
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan rekam medis, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          editMedicalHistory({
            variables: {
              id: data.id,
              userId: AuthenticationService.getUserId(),
              name: values.name,
              age: values.age ? parseInt(values.age) : null,
              startDate: moment(values.start_date).format('ll'),
              endDate: moment(values.end_date).format('ll'),
              hospitalName: values.hospital_name,
              doctorName: values.doctor_name,
              disease: values.disease,
              consequence: values.consequence,
              laboratoryNumber: values.laboratory_number,
              laboratoryNotes: values.laboratory_notes,
              medicalScore: values.medical_score,
              medicalFee: values.medical_fee,
              medicalCategory: values.medical_category,
              attachments:
                attachment.length > 0
                  ? [
                      {
                        name: attachment?.[0]?.name,
                        size: attachment?.[0]?.size,
                        url: attachment?.[0]?.url
                      }
                    ]
                  : []
            }
          })
            .then(() => {
              refetch();
              message.success(`Berhasil mengubah rekam medis`);
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal mengubah rekam medis, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
    }
  };

  function handleChange(val, e) {
    if (e === 'upload-attachment') {
      uploadAttachment(val);
    }
  }

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder, style } = res;
    if (type === 'select') {
      return (
        <Select
          size="large"
          style={{ width: '100%' }}
          onChange={value => handleChange(value, fieldName)}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              const dataTestId = `${fieldName}-${res.name}`;
              return (
                <Option key={i} value={res.id} data-testId={dataTestId}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={6}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const handleDelete = () => {
    setOpen(e => ({ ...e, modalDelete: true, open: false }));
  };

  const listFields = [
    {
      labelName: 'Nama Rekam Medis',
      fieldName: 'name',
      type: 'text',
      fieldValue: data?.name,
      placeholder: 'Tambahkan nama',
      isRequired: true
    },
    {
      labelName: 'Usia',
      fieldName: 'age',
      type: 'number',
      fieldValue: data?.age,
      placeholder: 'Tambahkan usia',
      isRequired: false
    }
  ];
  const listFields2 = [
    {
      labelName: 'Nama Rumah Sakit',
      fieldName: 'hospital_name',
      type: 'text',
      fieldValue: data?.hospital_name,
      placeholder: 'Tambahkan nama rumah sakit',
      isRequired: false
    },
    {
      labelName: 'Nama Dokter',
      fieldName: 'doctor_name',
      type: 'text',
      fieldValue: data?.doctor_name,
      placeholder: 'Tambahkan nama dokter',
      isRequired: false
    },
    {
      labelName: 'Penyakit',
      fieldName: 'disease',
      type: 'textArea',
      fieldValue: data?.disease,
      placeholder: 'Tambahkan penyakit',
      isRequired: true
    },
    {
      labelName: 'Konsekuensi',
      fieldName: 'consequence',
      type: 'text',
      fieldValue: data?.consequence,
      placeholder: 'Tambahkan konsekuensi',
      isRequired: false
    },
    {
      labelName: 'Nomor Laboratorium',
      fieldName: 'laboratory_number',
      type: 'text',
      fieldValue: data?.laboratory_number,
      placeholder: 'Tambahkan nomor laboratorium',
      isRequired: false
    },
    {
      labelName: 'Catatan Laboratorium',
      fieldName: 'laboratory_notes',
      type: 'textArea',
      fieldValue: data?.laboratory_notes,
      placeholder: 'Tambahkan catatan laboratorium',
      isRequired: false
    },
    {
      labelName: 'Skor Medis',
      fieldName: 'medical_score',
      type: 'number',
      fieldValue: data?.medical_score,
      placeholder: 'Tambahkan skor medis',
      isRequired: false
    },
    {
      labelName: 'Biaya Medis',
      fieldName: 'medical_fee',
      type: 'text',
      fieldValue: data?.medical_fee,
      placeholder: 'Tambahkan biaya medis',
      isRequired: false
    },
    {
      labelName: 'Kategori Medis',
      fieldName: 'medical_category',
      type: 'select',
      fieldValue: data?.medical_category,
      placeholder: 'Tambahkan kategori medis',
      isRequired: true,
      option:
        dataCategory?.medical_categories?.map(res => {
          return {
            id: res.name,
            name: res.name
          };
        }) ?? []
    }
  ];

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
  };

  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Rekam Medis`}
      open={open}
      onClose={handleClose}
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Form.Item
              label={<TitleField>Tanggal Mulai</TitleField>}
              style={{ display: 'inline-block', marginBottom: 0, width: '50%' }}
            >
              {getFieldDecorator('start_date', {
                initialValue: data?.start_date
                  ? moment(data?.start_date)
                  : null,
                rules: rule(true)
              })(
                <DatePicker
                  placeholder="Pilih tanggal"
                  onChange={(value, e) => handleChange(value, e, 'start_date')}
                  format={'YYYY-MM-DD'}
                  style={{ width: '90%' }}
                />
              )}
            </Form.Item>
            <Form.Item
              label={<TitleField>Tanggal Selesai</TitleField>}
              style={{ display: 'inline-block', marginBottom: 0, width: '50%' }}
            >
              {getFieldDecorator('end_date', {
                initialValue: data?.start_date ? moment(data?.end_date) : null,
                rules: rule(true)
              })(
                <DatePicker
                  placeholder="Pilih tanggal"
                  onChange={(value, e) => handleChange(value, e, 'end_date')}
                  format={'YYYY-MM-DD'}
                  style={{ width: '100%' }}
                  disabledDate={endDate =>
                    !endDate || endDate.isBefore(getFieldValue('start_date'))
                  }
                />
              )}
            </Form.Item>
          </Form.Item>
        </div>
        {listFields2.length > 0 &&
          listFields2.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Attachment
            handleChangeFile={e => handleChange(e, 'upload-attachment')}
            id="upload-medical-history"
            disabled={attachment.length > 0 ?? false}
          />
          {loadingUpload && <span>Mengunggah</span>}
          {errorFile && (
            <span>Ada jenis lampiran yang tidak diperbolehkan</span>
          )}
          {errorSize && (
            <span>Ukuran lampiran yang diunggah tidak boleh melebihi 25MB</span>
          )}
          {attachment &&
            attachment.map((res, i) => {
              const dataTestId = `${res.name}-${res.url}`;
              return (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  data-testId={dataTestId}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              );
            })}
        </div>
        <ButtonFrom
          actionType={actionType}
          submitLabel="Simpan"
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditMedicalHistory = Form.create({ name: 'Add_Edit_Medical_History' })(
  AddEditMedicalHistoryComponent
);

export default AddEditMedicalHistory;
