import moment from 'moment';

const defaultDate = date => moment(date).format('MM/DD/YYYY');

const americanDateFormat = date => moment(date).format('MMMM DD, YYYY');

const differenceDatefromNow = (date, unitTime) => moment().diff(date, unitTime)

const formatedDate = (date, format) => moment(date).format(format)

const dateStringFormatter = param => {
  var monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  let x = moment(param)._i.split(' ')[0];
  x = x.split('-');
  return `${x[2]} ${monthNames[Number(x[1]) - 1]} ${x[0]}`;
};

export default {
  defaultDate,
  americanDateFormat,
  dateStringFormatter,
  differenceDatefromNow,
  formatedDate,
};
