import { gql } from 'apollo-boost';

export const SET_AS_MEMBER = gql`
  mutation($classroomId: String!, $memberUserId: String!) {
    classroomDemoteAdmin(
      classroomId: $classroomId
      memberUserId: $memberUserId
    ) {
      success
    }
  }
`;
