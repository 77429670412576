import { createGlobalState } from 'react-hooks-global-state';

export const { GlobalStateProvider, useGlobalState } = createGlobalState({
  quickSearchResponse: [],
  keyword: '',
  loading: false,
  dataRegistration: {},
  uploadFile: {},
  uploadImageFile: [],
  userInfo: [],
  vendorPath: 'Order',
  groupInfo: {},
  countNotification: 0,
  modular: null
});
