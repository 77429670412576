import React, { useState } from 'react';
import 'antd/dist/antd.css';
import GroupScreen from './TeamScreen.component';
import { message } from 'antd';
import { Group } from '../../../Services';
import queryString from 'query-string';
import { SearchNotFound, DummySpace } from '../../../Components';
import { useAsyncEffect } from 'use-async-effect';
import { checkNextPage } from '../../../Utils/CommonUtils';
import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';

const GroupScreenContainer = props => {
  const queryWord = queryString.parse(props.location.search).keyword;
  const [teamResults, setGroupResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('search-people');

  useAsyncEffect(
    async isMount => {
      if (hasNextPage && queryWord && !isLoading && (isBottom || page === 1)) {
        setIsLoading(true);
        Group.GroupGlobal.getListGroupGlobal(page, queryWord)
          .then(res => {
            if (!isMount()) return;
            const nextPage = checkNextPage(res.total, page, res.per_page);
            setHasNextPage(nextPage);
            if (nextPage) {
              setPage(page + 1);
            }
            setGroupResults(teamResults.concat(res.data.teams));
          })
          .catch(err => {
            message.error('Something Went Wrong');
          })
          .finally(() => {
            if (!isMount()) return;
            setIsLoading(false);
            setIsBottom(false);
          });
      }
    },
    [
      queryWord,
      page,
      hasNextPage,
      isLoading,
      isBottom,
      setIsBottom,
      teamResults
    ]
  );
  return (
    <div id="search-people">
      {teamResults.length === 0 && !isLoading ? (
        <SearchNotFound
          title={`We could not find  "${queryWord}"`}
          desc={
            'Nothing came up for that search. Try shortening or rephrasing your search.'
          }
        />
      ) : (
        <GroupScreen
          {...props}
          searchedGroupResults={teamResults}
          isFetching={isLoading}
        />
      )}
      <DummySpace />
    </div>
  );
};

export default GroupScreenContainer;
