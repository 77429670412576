import React from 'react';
import { ErrorPageWrapper } from '../ErrorPage.style';
import Assets from '../../../Assets/images';
import { ButtonWlb } from '../../../Components';
const Error404Component = () => {
  return (
    <ErrorPageWrapper>
      <div className="error">
        <div className="test-column">
          <img src={Assets.ERROR_IMAGE.ERROR_404} alt="Error 404"></img>
        </div>
        <div className="column-2">
          <h1>Oops, Page not found</h1>
          <p className="description">
            We are really sorry for the inconvenience. It look like you trying
            to access a page that has been deleted or never existed
          </p>
          <ButtonWlb
            onClick={() => window.location.replace('/')}
            type="primary"
          >
            Back to Homepage
          </ButtonWlb>
        </div>
      </div>
    </ErrorPageWrapper>
  );
};
export default Error404Component;
