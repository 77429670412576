import PropTypes from 'prop-types';

const propTypes = {
  selectedEvent: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    from: PropTypes.string,
    at: PropTypes.string,
    when: PropTypes.string
  }),
  handleEventName: PropTypes.func.isRequired,
  listEvent: PropTypes.array.isRequired
};

const defaultProps = {
  selectedEvent: null
};

export default {
  propTypes,
  defaultProps
}
