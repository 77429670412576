import { AuthenticationService } from '../..';
import Constants from '../../../Config/baseUrl';
import BaseService from '../../BaseService';
// import { isObjectEmpty } from '../../../Utils/CommonUtils';

const fetchCompanyTimelineList = (nextPage, companyId) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: 3
    }
  };

  const userId = AuthenticationService.getUserId();
  const url = Constants.COMPANY_TIMELINES_POST(userId, companyId);
  return BaseService.get(url, parameters);
};

const createPost = (body, companyId) => {
  const userId = AuthenticationService.getUserId();
  const url = Constants.COMPANY_TIMELINE_POST(userId, companyId);
  return BaseService.post(url, body);
};

const deletePost = (companyId, postId) => {
  const userId = AuthenticationService.getUserId();
  const url = Constants.COMPANY_TIMELINE_POST_ID(userId, companyId, postId);
  return BaseService.delete(url);
};
const postLikePost = (postOwnerId, companyId, postId, likeValue) => {
  const url = Constants.COMPANY_TIMELINE_LIKE(
    postOwnerId,
    companyId,
    postId,
    likeValue
  );
  return BaseService.post(url);
};

const postComment = (postOwnerId, companyId, postId, content) => {
  const commentContent = {
    content: content
  };
  return BaseService.post(
    Constants.COMPANY_TIMELINE_COMMENT(postOwnerId, companyId, postId),
    commentContent
  );
};

const fetchCommentList = (
  postOwnerId,
  companyId,
  postId,
  nextPage,
  maxResults
) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: maxResults
    }
  };
  const commentList = Constants.COMPANY_TIMELINE_COMMENT(
    postOwnerId,
    companyId,
    postId
  );
  return BaseService.get(commentList, parameters);
};

const deleteComment = (comment, companyId) => {
  const commentUrl = Constants.COMPANY_TIMELINE_COMMENT_ID(
    comment.userId,
    companyId,
    comment.postId,
    comment.id
  );
  return BaseService.delete(commentUrl);
};

const followCompanyServices = (company, action) => {
  // action must be 'follow' or 'unfollow'
  const userId = AuthenticationService.getUserId();
  const body = {
    companyId: company.id
  };

  const followUrl = Constants.COMPANY_TIMELINE_FOllOW(userId, company.id);
  const unfollowUrl = Constants.COMPANY_TIMELINE_UNFOllOW(userId, company.id);
  const url = action === 'follow' ? followUrl : unfollowUrl;
  return BaseService.post(url, body);
};

const getPostId = (userId, postId) => {
  const companyId = AuthenticationService.getCompanyId();
  const url = Constants.COMPANY_TIMELINES_POST_ID(userId, companyId, postId);
  return BaseService.get(url);
};

export default {
  fetchCompanyTimelineList,
  createPost,
  deletePost,
  postLikePost,
  followCompanyServices,
  postComment,
  fetchCommentList,
  deleteComment,
  getPostId
};
