const initialData = {
  type: 3,
  content: '',
  images: [],
  videos: [],
  tags: [],
  files: [],
  privacy: 0,
  canSee: [],
  kudos: {}
};

export default {
  initialData
};
