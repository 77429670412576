import styled from 'styled-components';
import Assets from '../../Assets';

export const WrapperAttachment = styled.div`
  margin-top: 30px;
  .item {
    width: 100%;
    height: 75px;
    background: ${Assets.COLOR_PALLETE.white};
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
    display: flex;
    padding: 20px;
    .thumbnail {
      width: 30px;
      height: 40px;
      img {
        width: inherit;
        height: inherit;
      }
    }
    .info {
      width: 100%;
      margin-left: 20px;
      span {
        display: block;
      }
      .name {
        font-size: 14px;
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: ${Assets.COLOR_PALLETE.black2};
        line-height: 26px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      .desc {
        font-size: 13px;
        color: ${Assets.COLOR_PALLETE.grey};
        line-height: 15px;
      }
    }
    .action {
      .ant-btn {
        border: none;
        padding: 0;
      }
      .icon {
        width: 22px;
        height: 26px;
      }
    }
  }
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin-top: 2px;
    &.download {
      background-image: url(${Assets.ICON.DOWNLOAD});
    }
  }
`;
