import React from 'react';
import {
  VendorShipping,
  TopSettingGroup
  // ButtonWlb
} from '../../../Components';
import {
  ContentWrap,
  // Footer,
  ShippingWrap
} from './Shipping.style';
// import { Select } from 'antd';
// import style from './Shipping.style';

const ShippingComponent = props => {
  return (
    <ShippingWrap>
      <TopSettingGroup title={'Shipping Service'} />
      <ContentWrap>
        <VendorShipping />
      </ContentWrap>
    </ShippingWrap>
  );
};

export default ShippingComponent;
