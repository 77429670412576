import React from 'react';
import { ModalEdit, ButtonFullWlb } from '../../../../Components';
import HobbiesItem from './HobbiesItem';
import { WrapperScroll } from './Hobbires.style';

export default function MoreHobbiesModal({
  nameUser,
  open,
  setOpen,
  data,
  isUser
}) {
  return (
    <ModalEdit
      centered
      padding="0px"
      title={`Hobi ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false }))}
    >
      <WrapperScroll>
        {data &&
          data.length > 0 &&
          data.map((res, i) => (
            <HobbiesItem
              isUser={isUser}
              key={i}
              description={res.description}
              logo={res.levelIndicator}
              name={res.nameIndicator}
              title={res.title}
              detail={res.detail}
              endorsement={res.people_profile_endorsements}
              onClickEdit={() =>
                setOpen(e => ({
                  ...e,
                  open: true,
                  data: res,
                  actionType: 'edit',
                  openMore: false,
                  fromList: true
                }))
              }
            />
          ))}
      </WrapperScroll>
      {isUser && (
        <ButtonFullWlb
          label="Tambahkan Hobi"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
}
