import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding: 0px 0px 0px 20px;
  margin-top: -22px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListAction = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 18px;
  width: 146px;
`;
export const NameWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 14px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px auto;
`;
export const BottonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
`;
export const BorderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin: 0px 0px 16px 14px;
  padding-bottom: 16px
  width: 100%;
`;
export const Headline = styled.h4`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 0px 22px;
`;

export const SelectedPepole = styled.span`
  font-size: 16px;
  color: #039be5;
  font-weight: 600;
`;
export const TitleField = styled.span`
  font-size: 12px;
  color: #a9a8a8;
`;
export const WrapperData = styled.div`
  max-height: ${props => (props.maxHeight ? props.maxHeight : '370px')};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
`;

export const ContentWrapperDetail = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: auto;
  padding: 0px 0px 0px 30px;
`;
