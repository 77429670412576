import React from 'react'
import {ButtonFull} from './ButtonFullWlb.style'
import { Icon } from 'antd'

export default function ButtonFullWlb({onClick, label, type = 'plus'}) {
  return (
    <ButtonFull onClick={onClick} >
      <Icon type={type} style={{ color: "#039be5", marginRight: 12 }} />
      {label}
    </ButtonFull>
  )
}
