import styled from 'styled-components';
import Assets from '../../Assets';

export const TotalFollower = styled.label`
  color: black;
  font-weight: bold;
  padding: 0 5px 0 5px;
`;

export const Description = styled.div`
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
`;

export const Name = styled.label`
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-left: 10px;
  cursor: pointer;
`;

export const JobDetails = styled.label`
  /* font-size: 12px;
  color: grey; */
  display: block;
`;

export const Follower = styled.label`
  /* font-weight: bold;
  color: black;
  margin-left: 3px; */
`;

export const CompanyResultWrapper = styled.label`
  background-color: #fff;
  display: block;
  padding-left: 35px;
  height: 110px;
  position: relative;
  font-family: ${Assets.FONT.MULI};

  .container-searchCompany-borderBottom {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
    width: 810px;
    height: 110px;
  }
`;

export const InfoWrapper = styled.label`
  margin-top: 20px;
  display: inline-block;
  width: 650px;

  margin-left: 75px;
`;
export const AvatarWrapper = styled.label`
  display: inline-block;
  position: absolute;
  top: 20px;
`;

export const ButtonWrapper = styled.label`
  display: inline-block;
  position: absolute;
  top: 35px;
  /* border-bottom: 0.8px solid rgba(0, 0, 0, 0.16);
  padding-bottom: 30px; */
`;

export const FollowedBy = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    margin: 5px 5px 0 0;
    background-image: url(${Assets.ICON.FOLLOWED});
  }

  .company-follower {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 17px;
    height: 17px;
    margin-top: 3px;
    background-image: url(${Assets.ICON.COMPANY_FOLLOWER});
  }
`;
