import Constants from '../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../Assets';

export const ComponentWrapper = styled.div`
  .component_title {
    font-family: ${Assets.FONT.MULI};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
    margin-top: 17.8px;
    margin-bottom: 9.5px;
  }
`;

export const ItemWrapper = styled.div`
  padding: 8px 15px;
  padding-right: 0;
  display: flex;
  align-items: center;

  .component_option_item_image {
    width: 70px;
    height: 46.6px;
    overflow: hidden;
    margin-right: 13px;

    img {
      width: 100%;
      text-indent: -9999px;
    }
  }
`;

export const DetailEvent = styled.div`
  width: 80%;
  align-items: flex-end;
  font-family: ${Assets.FONT.MULI};
  padding-bottom: 20px;
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey2};

  .component_option_item_content_title {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 2px;
  }

  .component_option_item_content_name {
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.dark_blue};
  }

  .component_option_item_content_description {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #a9a8a8;
    color: ${Assets.COLOR_PALLETE.grey};
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .component_option_item_content_date {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export default {
  select: {
    width: '100%',
    height: 25,
    fontSize: 12,
    color: Constants.COLORS.BLACK,
    marginBottom: 25
  }
};
