import React, { useState } from 'react';
import LoginPageComponent from './LoginPage.component';
import { Enhancer } from '../../SharedComponents';
import handler from './LoginPage.handler';

const LoginPageContainer = props => {
  const [form, setValues] = useState({
    email: '',
    password: ''
  });

  const updateField = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  return (
    <LoginPageComponent {...props} updateField={updateField} form={form} />
  );
};

export default Enhancer({
  handler
})(LoginPageContainer);
