import React, { useEffect, useState } from 'react';
import { ModalEdit } from '../../../../Components';
import { Form, Select, message, Spin, Empty, Switch } from 'antd';
import { TitleField } from '../DetailScreen.style';
import { useLazyQuery, useMutation } from 'react-apollo';
import { capitalize } from '../../../../Utils/CommonUtils';
import {
  ADD_SKILL,
  UPDATE_SKILL,
  LIST_SKILL,
  LIST_LEVEL
} from './Skills.graphql';
import { ButtonFrom } from '../../../../SharedComponents';

const AddSkillsComponent = ({
  open,
  onClose,
  actionType,
  form,
  setParentSkill,
  data,
  refetch,
  onDelete
}) => {
  const {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields
  } = form;
  const [share, setShare] = useState(false);
  const { Option } = Select;

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [
    getListSkill,
    { data: dataSkill, loading: loadingSkill }
  ] = useLazyQuery(LIST_SKILL, {
    context: CONTEXT_USER
  });

  const [
    getListLevel,
    { data: dataLevel, loading: loadingLevel }
  ] = useLazyQuery(LIST_LEVEL, {
    context: CONTEXT_USER
  });

  const [addSkill] = useMutation(ADD_SKILL, {
    context: CONTEXT_USER
  });
  const [updateSkill] = useMutation(UPDATE_SKILL, {
    context: CONTEXT_USER
  });

  const handleClose = () => {
    resetFields();
    onClose();
    setShare(false);
  };

  const isEdit = actionType === 'edit';

  useEffect(() => {
    if (data) {
      setShare(data?.is_share);
    } else {
      setShare(false);
    }
  }, [data]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          const submit = {
            objects: [
              {
                skill: values.skill,
                level: values.level,
                is_share: share,
                description: null,
                deletedAt: null
              }
            ]
          };
          addSkill({
            variables: submit
          })
            .then(() => {
              refetch();
              handleClose();
              message.success(`Berhasil menambahkan keahlian`);
            })
            .catch(() => {
              message.error(`Keahlian sudah ditambahkan`);
            });
        } else {
          updateSkill({
            variables: {
              id: data.id,
              object: {
                is_share: share
              }
            }
          })
            .then(() => {
              refetch();
              handleClose();
              message.success(`Berhasil mengubah keahlian`);
            })
            .catch(err => {
              message.error(`Gagal mengubah keahlian, ${err}`);
            });
        }
      }
    });
  };

  const handleChange = (formValue, target, e) => {
    if (target === 'skill_category') {
      setParentSkill(formValue);
      setFieldsValue({
        skill: null
      });
    }
  };

  const listFields = [
    {
      labelName: 'Keahlian',
      fieldName: 'skill',
      type: 'select',
      fieldValue: data?.people_skill?.id ?? undefined,
      option: dataSkill?.skills || [],
      placeholder: 'Pilih keahlian',
      loading: loadingSkill,
      showSearch: true
    },
    {
      labelName: 'Tingkat Keahlian',
      fieldName: 'level',
      type: 'select',
      fieldValue: data?.people_skill_levels?.id ?? undefined,
      option: dataLevel?.people_skill_levels || [],
      placeholder: 'Pilih tingkat keahlian',
      loading: loadingLevel
    }
  ];

  const handleSearch = (value, target) => {
    if (target === 'skill') {
      getListSkill({
        variables: {
          search: `%${value}%`,
          limit: 30
        }
      });
    }
  };

  const handleTouch = (value, target) => {
    if (value) {
      if (target === 'skill') {
        getListSkill({
          variables: {
            limit: 30
          }
        });
      } else if (target === 'level') {
        getListLevel();
      }
    }
  };

  const _switchComponentToRender = ({
    type,
    fieldName,
    option,
    fieldValue,
    placeholder,
    loading,
    showSearch = false
  }) => {
    if (type === 'select') {
      return (
        <Select
          showSearch={showSearch}
          defaultActiveFirstOption={false}
          value={fieldValue}
          placeholder={placeholder}
          notFoundContent={
            loading ? (
              <Spin size="small" />
            ) : option.length === 0 ? (
              <Empty />
            ) : null
          }
          filterOption={false}
          onSearch={val => handleSearch(val, fieldName)}
          onDropdownVisibleChange={val => handleTouch(val, fieldName)}
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option key={`${i}`} value={res.id}>
                  {capitalize(res.name)}
                </Option>
              );
            })}
        </Select>
      );
    }
  };

  return (
    <ModalEdit
      onClickDelete={isEdit ? () => onDelete(data) : null}
      sizeSmall
      title={`${isEdit ? 'Ubah' : 'Tambahkan'}  Keahlian`}
      open={open}
      onClose={handleClose}
    >
      {isEdit && (
        <TitleField style={{ padding: '0px 22px' }}>
          Bagikan dengan jaringan
        </TitleField>
      )}
      <Form style={{ marginTop: isEdit ? 12 : 0 }} onSubmit={handleSubmit}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Item
            style={{
              padding: '0px 22px 12px 22px',
              marginBottom: 8,
              display: 'flex'
            }}
            label={!isEdit && <TitleField>Bagikan dengan jaringan:</TitleField>}
          >
            {getFieldDecorator(
              'share',
              {}
            )(<Switch checked={share} onClick={() => setShare(!share)} />)}
          </Form.Item>
          {isEdit && (
            <TitleField style={{ padding: '0px 0px 22px' }}>
              Tunjukkan keahlian ini
            </TitleField>
          )}
        </div>
        {actionType === 'add' && (
          <>
            <hr />
            {listFields.length > 0 &&
              listFields.map((res, i) => {
                return (
                  <Form.Item
                    key={i}
                    style={{ padding: '0px 22px', marginBottom: 8 }}
                    label={<TitleField>{res.labelName}</TitleField>}
                    help={res.extra}
                  >
                    {getFieldDecorator(res.fieldName, {
                      initialValue: res.fieldValue,
                      rules: [
                        {
                          required: true,
                          message: 'Bagian ini diperlukan'
                        }
                      ]
                    })(_switchComponentToRender(res))}
                  </Form.Item>
                );
              })}
          </>
        )}
        <ButtonFrom
          actionType={actionType}
          onCancel={handleClose}
          submitLabel="Simpan"
        />
      </Form>
    </ModalEdit>
  );
};

const AddSkills = Form.create({ name: 'Add_Skills' })(AddSkillsComponent);

export default AddSkills;
