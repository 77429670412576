import Constants from '../../../Config/Constants';
import styled from 'styled-components';
import { Tooltip } from 'antd';

export const ProductName = styled.span`
  font-family: 'Muli-Bold';
  font-size: 14px;
  display: -webkit-box;
  height: 42px;
  font-weight: normal;
  line-height: 1.5;
  color: ${Constants.COLORS.BLACK};
  overflow-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default {
  colContainer: {
    backgroundColor: Constants.COLORS.WHITE,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    height: '90%',
    marginBottom: 20
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    height: 164
  },
  content: {
    paddingTop: 11,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 14
  },
  productAuthor: {
    marginTop: -5,
    marginBottom: 30,
    fontSize: 10,
    color: Constants.COLORS.GREY
  },
  divRating: {
    marginBottom: 15
  },
  rating: {
    color: Constants.COLORS.LIGHT_BLUE,
    fontSize: 12,
    letterSpacing: 0
  },
  valueRating: {
    fontSize: 10,
    color: Constants.COLORS.BLACK,
    marginLeft: 10,
    textAlign: 'center'
  },
  totalRating: {
    fontSize: 10,
    color: Constants.COLORS.GREY
  },
  divPrice: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  fakePrice: {
    textDecoration: 'line-through',
    fontSize: 10,
    color: Constants.COLORS.GREY,
    display: 'flex',
    alignItems: 'flex-end'
  },
  truePrice: {
    fontFamily: 'Muli-Bold',
    fontSize: 16,
    color: Constants.COLORS.BLACK
  },
  headerImage : {
    display: 'flex',
    padding: '5px 5px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
   '& .ant-tooltip-inner' : {
      color: 'yellow !important',
      backgroundColor: 'green !important',
    }
};
