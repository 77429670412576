import React from 'react';
import Assets from '../../../Assets';
import { ThypoStyled } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { TextSecondary, TextStandard, TextGrey } from '../UserProfileCV.style';

export default function Experience({
  title,
  company,
  type,
  regno,
  date,
  desc,
  desc2,
  data
}) {
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <div style={{ marginBottom: 35, overflow: 'visible' }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextSecondary>{company}</TextSecondary>
      <TextGrey>{type}</TextGrey>
      <TextGrey>{regno}</TextGrey>
      <TextGrey>{date}</TextGrey>
      <TextStandard>{desc}</TextStandard>
      <TextStandard>{desc2}</TextStandard>
      <div>
        {/* <div
            style={{
              display: isUser ? 'flex' : 'none',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              fontStyle="italic"
            >
              Salary :
            </ThypoStyled>
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.black}
              fontStyle="italic"
              style={{ paddingLeft: '5px' }}
            >
              Starts from Rp{' '}
              {res.dataEdit.initial_salary
                ? convertToCurrency(res.dataEdit.initial_salary)
                : '-'}{' '}
              to Rp{' '}
              {res.dataEdit.last_salary
                ? convertToCurrency(res.dataEdit.last_salary)
                : '-'}
            </ThypoStyled>
          </div> */}
        <ThypoStyled
          style={{ textTransform: 'capitalize', paddingTop: '10px' }}
          fontSize="12px"
          margin="6px 0px"
          color={Assets.COLOR_PALLETE.blue_link}
        >
          Reference
        </ThypoStyled>
        <ThypoStyled
          fontSize="12px"
          color={Assets.COLOR_PALLETE.black}
          fontStyle="italic"
        >
          {renderValue(data.referal_name, '-')}
        </ThypoStyled>
        <ThypoStyled
          fontSize="12px"
          color={Assets.COLOR_PALLETE.grey}
          fontStyle="italic"
        >
          {renderValue(data.referal_position, '-')}
        </ThypoStyled>
        <ThypoStyled
          fontSize="12px"
          color={Assets.COLOR_PALLETE.black}
          fontStyle="italic"
        >
          {`${renderValue(
            data?.additional_fields?.referal_contact_phone?.country_code !==
              null
              ? data?.additional_fields?.referal_contact_phone?.country_code
              : '',
            ''
          )}${renderValue(
            data?.additional_fields?.referal_contact_phone?.phone !== ''
              ? data?.additional_fields?.referal_contact_phone?.phone
              : '',
            ''
          )}`}
        </ThypoStyled>
      </div>
    </div>
  );
}
