import React from 'react';
import { GlobalSidebar } from '@worklifebeyond/wlb-utils-components';
import { HCEcoWrapper } from './HCEcosystem.style';

const HCEcosystemComponent = () => {
  return (
    <HCEcoWrapper>
      <GlobalSidebar
        env={{
          SOSMED_URL: process.env.REACT_APP_HC_SOSMED,
          USER_URL: process.env.REACT_APP_HC_TIME + '/',
          PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
          LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
          TALENT_URL: process.env.REACT_APP_HC_TALENT,
          VENDOR_URL: process.env.REACT_APP_HC_VENDOR
        }}
        language="indonesian"
      />
    </HCEcoWrapper>
  );
};

export default HCEcosystemComponent;
