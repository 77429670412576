import { Form, Input, message } from 'antd';
import React from 'react';
import ModalEdit from '../../ModalEdit';
import { ButtonFrom } from '../../../SharedComponents';
import { withRouter } from 'react-router-dom';
import { AuthenticationService } from '../../../Services';

const BuildAResumeComponent = props => {
  const { open, onClose, form } = props;
  const { getFieldDecorator, resetFields, validateFields } = form;

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        // props.history.push(
        //   `/resume/${AuthenticationService.getUserId()}/${values.filename}`,
        //   '_blank'
        // );
        message.success('Resume Downloaded Successfully');
        setTimeout(function() {
          handleClose();
          const win = window.open(
            `/resume/${AuthenticationService.getUserId()}/${values.filename}`,
            '_blank'
          );
          win.focus();
        }, 2000);
      }
    });
  };

  return (
    <ModalEdit
      title="Buat Resume"
      open={open}
      onClose={handleClose}
      sizeVerySmall
    >
      <Form onSubmit={handleSubmit}>
        <p
          style={{
            fontSize: 12,
            color: '#000',
            margin: '0px 22px 15px',
            fontWeight: '600'
          }}
        >
          Simpan Berkas sebagai
        </p>
        <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
          {getFieldDecorator('filename', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Bagian ini diperlukan'
              }
            ]
          })(<Input />)}
        </Form.Item>
        <p style={{ fontSize: 12, color: '#a9a8a8', margin: '12px 22px 0px' }}>
          Akan disimpan sebagai berkas .pdf
        </p>
        <ButtonFrom
          noDivider
          buttonSize="large"
          onCancel={handleClose}
          submitLabel="Unduh"
        />
      </Form>
    </ModalEdit>
  );
};

const BuildAResume = Form.create({ name: 'Build_A_Resume' })(
  BuildAResumeComponent
);

export default withRouter(BuildAResume);
