import styled from 'styled-components';
import Constants from '../../Config/Constants';
// import Assets from '../../Assets';

export const Wrapper = styled.div`
  /* background: pink; */
  display: flex;
  align-items: center;
  line-height: 1.4;
  .icon {
    font-size: 16px;
    color: red;
    margin-right: 10px;
  }
  .icon-edit {
    font-size: 16px;
    color: green;
    margin-right: 10px;
  }
  .icon-hide {
    color: ${Constants.COLORS.GREY};
    font-size: 16px;
    margin-right: 10px;
  }

  .title {
    font-size: 12px;
    display: block;
  }
  .desc {
    display: block;
    font-size: 11px;
    color: ${Constants.COLORS.GREY};
  }
`;

// export const Icon = styled
