import gql from "graphql-tag";

export const GET_LIST_MEDICAL_HISTORY = gql`
  query($userId: uuid!) {
    people_profile_medical_histories(
      where: {
        _and: [{ user_id: { _eq: $userId } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [
        { start_date: desc_nulls_last }
        { end_date: desc_nulls_first }
      ]
    ) {
      id
      name
      age
      start_date
      end_date
      hospital_name
      doctor_name
      disease
      consequence
      laboratory_number
      laboratory_notes
      medical_score
      medical_fee
      medical_category
      attachments
    }
  }
`;

export const ADD_MEDICAL_HISTORY = gql`
  mutation($objects: [people_profile_medical_histories_insert_input!]!) {
    insert_people_profile_medical_histories(objects: $objects) {
      affected_rows
    }
  }
`;
export const DELETE_MEDICAL_HISTORY = gql`
  mutation($id: Int!) {
    update_people_profile_medical_histories(
      _set: { deletedAt: "now()" }
      where: { _and: [{ id: { _eq: $id } }, { deletedAt: { _is_null: true } }] }
    ) {
      affected_rows
    }
  }
`;
export const EDIT_MEDICAL_HISTORY = gql`
  mutation(
    $id: Int!
    $userId: uuid!
    $name: String
    $age: Int
    $startDate: date
    $endDate: date
    $hospitalName: String
    $doctorName: String
    $disease: String
    $consequence: String
    $laboratoryNumber: String
    $laboratoryNotes: String
    $medicalScore: Int
    $medicalFee: String
    $medicalCategory: String
    $attachments: jsonb
  ) {
    update_people_profile_medical_histories(
      _set: {
        name: $name
        age: $age
        start_date: $startDate
        end_date: $endDate
        hospital_name: $hospitalName
        doctor_name: $doctorName
        disease: $disease
        consequence: $consequence
        laboratory_number: $laboratoryNumber
        laboratory_notes: $laboratoryNotes
        medical_score: $medicalScore
        medical_fee: $medicalFee
        medical_category: $medicalCategory
        attachments: $attachments
      }
      where: { _and: [{ id: { _eq: $id } }, { user_id: { _eq: $userId } }] }
    ) {
      affected_rows
    }
  }
`;
export const LIST_MEDICAL_CATEGORY = gql`
  query($search: String, $limit: Int, $offset: Int) {
    medical_categories: people_profile_medical_categories(
      where: { name: { _ilike: $search } }
      limit: $limit
      offset: $offset
      order_by: [{ id: asc }]
    ) {
      id
      name
    }
  }
`;
