import React, { useState } from 'react';
import MyGroupScreenComponent from './MyGroupScreen.component';
import { Group } from '../../../Services';
import { checkNextPage, isObjectEmpty } from '../../../Utils/CommonUtils';
import { message } from 'antd';
import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAsyncEffect } from 'use-async-effect';

const MyGroupScreenContainer = props => {
  const [dataGroup, setDataGroup] = useState([]);
  const [page, setPage] = useState(1);
  // const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isBottom, setIsBottom] = useInfiniteScroll('group-list');
  const params = useLocation();
  const search = queryString.parse(params.search).name;
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  function fetchSearch(keyword) {
    const { pathname } = params;
    const searchParameter = isObjectEmpty(keyword) ? '' : '?name=' + keyword;
    props.history.push(pathname + searchParameter);
    setDataGroup([]);
    setPage(1);
    setIsLoading(false);
    setHasNextPage(true);
  }

  useAsyncEffect(
    async isMount => {
      if (hasNextPage && !isLoading && (isBottom || page === 1)) {
        setIsLoading(true);
        const maxResult = 15;
        await Group.GroupGlobal.getListGroup(page, search, maxResult)
          .then(res => {
            const { data, total, per_page } = res;
            if (!isMount()) return;
            const isNextPage = checkNextPage(total, page, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }
            setDataGroup(dataGroup.concat(data));
          })
          .catch(err =>
            message.error('Something went wrong when load groups data')
          )
          .finally(() => {
            if (!isMount()) return;
            setIsLoading(false);
            setIsBottom(false);
          });
      }
    },
    [dataGroup, hasNextPage, isBottom, isLoading, page, search, setIsBottom]
  );

  return (
    <div id="group-list">
      <MyGroupScreenComponent
        dataGroup={dataGroup}
        isLoading={isLoading}
        defaultSearch={search}
        fetchSearch={fetchSearch}
        {...props}
      />
    </div>
  );
};

export default MyGroupScreenContainer;
