import { Icon, Typography } from '@material-ui/core';
import { Form } from 'antd';
import styled from 'styled-components';
import Assets from '../../Assets';

// FOR PEOPLE PROFILE TAB ITEM
export const ItemWrapper = styled.div`
  margin-bottom: 19px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  p {
    margin: 0px;
  }
  .key {
    width: 40%;
    color: #a9a8a8;
    margin-right: 10px;
  }
  .value {
    width: 60%;
    color: #000;
  }
  .array {
    display: flex;
    flex-direction: column;
    .family {
      margin-bottom: 7px;
    }
  }
`;

// FOR PEOPLE PROFILE TAB ITEM
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 39px;
  @media (min-width: 768px) {
    width: 46%;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }

    h4 {
      color: #014a62;
      font-weight: normal;
      font-size: 14px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .text {
    font-size: 14px;
  }
  .black {
    color: #1b1d1f;
  }
  .show-detail {
    font-siz: 12px;
    text-align: left;
    color: #039be5;
    cursor: pointer;
    user-select: none;
    margin-top: 16px;
  }
  ul {
    margin-left: 3px;
    margin-bottom: 0;
    padding-left: 1.5em;

    li {
      ::marker {
        font-size: 24px;
      }
    }
  }
`;

// FOR PEOPLE PROFILE TAB ITEM
export const TabWrapper = styled.div`
  padding: 24px 32px 12px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`;
// FOR PEOPLE PROFILE TAB ITEM
export const WrapperAddList = styled.div`
  display: flex;
  padding: 0px 30px 24px 30px;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 8px;
    color: #039be5;
  }
  .text {
    cursor: pointer;
    font-size: 14px;
    color: #039be5;
    margin: 0px;
  }
`;

// FOR PEOPLE PROFILE TAB ITEM
export const TitleField = styled.span`
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
  // margin-bottom: 10px;
`;

// FOR FORM REQUIRED WITHOUT * and :
export const FormItem = styled(Form.Item)`
  .ant-form-item-label {
    .ant-form-item-required {
      &:after {
        content: '';
      }
      &:before {
        content: '';
      }
    }
    label {
      &:after {
        content: '';
      }
      &:before {
        content: '';
      }
    }
  }
`;

export const ButtonFilter = styled.div`
  min-height: 43px;
  padding-left: 18px;
  padding-right: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const BlueIcon = styled(Icon)`
  color: #014a62;
`;

export const FilterText = styled(Typography)`
  font-weight: 700;
  color: #000;
`;
