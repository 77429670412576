import styled from 'styled-components';
import Constants from '../../Config/Constants';

export const LanguageSettingsWrapper = styled.div`
  display: flex;
  width: 608.4px;
  background-color: #ffffff;
  flex-direction: column;
  padding: 15px;

  .component_language_settings_wrapper {
    margin-bottom: 20px;

    label.ant-radio-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;

      span.ant-radio + * {
      }
    }
  }

  .component_language_settings_name {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: ${Constants.COLORS.DARK_BLUE};
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${Constants.COLORS.DARK_BLUE};
  }

  .ant-radio-inner::after {
    background-color: ${Constants.COLORS.DARK_BLUE};
  }
`;
