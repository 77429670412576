import Constants from '../../Config/Constants';
import styled from 'styled-components';

const HCWrapper = styled.div`
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 1.5px 3px 0px ${Constants.COLORS.SHADOW};
  background-color: ${Constants.COLORS.WHITE};
  .component_HC_titleLogo {
    height: 15px;
    margin-left: 5px;
  }
  .headerTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .hr {
    margin-bottom: 10px;
    height: 1px;
    background-color: ${Constants.COLORS.LIGHT_GREY};
    border: 1px;
  }
  .menuTitle {
    cursor: pointer;
    padding: 5px;
    .menuTitleImage {
      height: 15px;
    }
    .menuTitleText {
      margin-left: 10px;
      font-size: 14px;
    }
  }
`;
const HCEcoWrapper = styled.div`
  div {
    display: flex !important;
  }
`;


export { HCWrapper, HCEcoWrapper };
