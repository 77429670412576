import React, { useState } from 'react';
import { Upload, message } from 'antd';
import './UploadFile.css';
import config from './UploadFile.config';
import { UploadWrap } from './UploadFile.style';
// import { isEmpty } from 'lodash';
import DisplayFile from './DisplayFile.component';

const UploadFileComponent = props => {
  const {
    // post,
    acceptedFormat,
    listType,
    fileList,
    target,
    handleChange,
    handleUpload,
    children,
    setIsUploading
  } = props;
  const maxUpload = 10;
  const [editedFiles, setEditedFiles] = useState(fileList);
  const uploadValidation = value => {
    const reg = /(?:\.([^.]+))?$/;
    const fileFormat = reg.exec(value.file.name)[1];

    const fileTypeChecker = value.fileList.map(item => {
      if (reg.exec(item.name)[1] === 'xls') {
        return {
          ...item,
          type: config.fileTypeXls
        };
      } else if (reg.exec(item.name)[1] === 'ppt') {
        return {
          ...item,
          type: config.fileTypePpt
        };
      } else {
        return item;
      }
    });
    setEditedFiles(fileTypeChecker);

    const isAcceptedFormat = config.acceptedFormat.includes(fileFormat);
    const fileSize = value.file.size / 1024 / 1024 < 25;
    if (isAcceptedFormat && fileSize) {
      handleChange(target, editedFiles.slice(0, maxUpload));
      // handleChange(target, value.fileList);
    } else if (!isAcceptedFormat) {
      message.error('File format are not supported.');
      deleteFileUpload(value.file.uid);
    } else if (!fileSize) {
      message.error('You can only upload < 25MB');
      deleteFileUpload(value.file.uid);
    }
  };

  const deleteFileUpload = deletedId => {
    const filteredFiles = editedFiles.filter(file => file.uid !== deletedId);
    setEditedFiles(filteredFiles);
    handleChange(target, filteredFiles);
  };

  const _uploadFile = () => (
    <UploadWrap>
      <Upload
        disabled={editedFiles.length >= maxUpload}
        accept={acceptedFormat}
        listType={listType}
        customRequest={handleUpload}
        fileList={editedFiles}
        onChange={value => {
          if (setIsUploading) {
            setIsUploading(value.file.status === 'uploading');
          }
          uploadValidation(value);
        }}
        multiple
        showUploadList={target === 'files' ? false : true}
        // showUploadList={{ showDownloadIcon: false }}
      >
        {children}
      </Upload>

      {target === 'files' &&
        // !isEmpty(fileList) &&
        editedFiles
          .slice(0, maxUpload)
          .map((file, index) => (
            <DisplayFile
              key={index}
              file={file}
              deleteFileUpload={deleteFileUpload}
              editedFiles={editedFiles}
              setEditedFiles={setEditedFiles}
              {...props}
            />
          ))}
    </UploadWrap>
    // handleChange adalah props handlePost dari PostBoxContainer. handlePost(target, value)
  );

  return _uploadFile();
};

export default UploadFileComponent;
