import RegulationScreenComponent from './RegulationScreen.component';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { GET_REGULATION } from './Regulation.graphql';

const RegulationScreenContainer = props => {
  const companyId = props.match.params.id;
  const [searchCondition, setSearchCondition] = useState('%%');
  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'user' } },
    variables: {
      id: companyId,
      key: searchCondition
    }
  };
  const [dataRegulation, setDataRegulation] = useState([]);
  const getRequlationQuery = useQuery(GET_REGULATION, OPTIONS);
  useEffect(() => {
    const fetchRegulation = async () => {
      if (getRequlationQuery.data) {
        setDataRegulation(getRequlationQuery.data.company_regulations);
      }
    };
    fetchRegulation();
  }, [searchCondition, getRequlationQuery]);

  const handleSearch = async value => {
    if (value === '') {
      // message.warning('Please typing your keyword');
      setSearchCondition(`%%`);
    } else {
      setSearchCondition(`%${value}%`);
    }
  };

  return (
    <RegulationScreenComponent
      regulationData={dataRegulation}
      companyId={companyId}
      handleSearch={handleSearch}
      {...props}
    />
  );
};

export default RegulationScreenContainer;
