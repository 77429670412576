import styled from "styled-components";

export const WrapBtn = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0px;
  .btn-wlb-cancel, .btn-wlb-cancel:hover {
    color: #a9a8a8;
    border: none;
  }
  .ant-btn-primary, ant-btn-primary:active {
    border: none;
    background-color: #014a62;
  }
  .ant-btn-primary:hover {
    border: none;
    background-color: #027499;
  }
  .btn-wlb-large {
    height: 45px;
  }
  .btn-wlb-small {
    height: 32px;
  }
`;
