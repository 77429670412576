import React from 'react';
import {
  TopBoxClassroom,
  BodyBoxClassroom,
  textHidden,
  textShow,
  Wrapper
} from './SidebarClassroomInfo.style';
import { Button, Avatar } from 'antd';

import RequestButtonClassroom from './ButtonClassroomAbout.component';
import Assets from '../../Assets';

const convertToRupiah = angka => {
  var rupiah = '';
  var angkarev = angka
    .toString()
    .split('')
    .reverse()
    .join('');
  for (var i = 0; i < angkarev.length; i++)
    if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + '.';
  return (
    'Rp' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('')
  );
};

const SidebarClassroomInfoComponent = props => {
  const {
    dataClassroom,
    handleShowDescription,
    handleShowRules,
    textWrapDescription,
    textWrapRules,
    isAbout,
    isPaid,
    isRequest
  } = props;

  const _renderPrivacy = () => {
    if (dataClassroom.privacy === 0) {
      return (
        <span>
          <i className="icon globe"></i>Kelompok Belajar Umum
        </span>
      );
    } else {
      return (
        <span>
          <i className="icon lock"></i>Privasi Kelompok Pembelajaran
        </span>
      );
    }
  };

  const _renderTopBoxClassroom = () => (
    <TopBoxClassroom>
      <div className="logo">
        <Avatar
          src={
            dataClassroom.avatar_url === '' || !dataClassroom.avatar_url
              ? Assets.DEFAULT_GROUP_AVATAR
              : dataClassroom.avatar_url
          }
        />
      </div>
      <div className="title">
        <span>{dataClassroom.name}</span>
        {_renderPrivacy()}
      </div>
    </TopBoxClassroom>
  );

  const _renderButtonDescription = () => (
    <Button className="showmore" onClick={() => handleShowDescription()}>
      {textWrapDescription ? 'Lebih Sedikit' : 'Lebih Banyak'}
    </Button>
  );

  const _renderButtonRules = () => (
    <Button className="showmore" onClick={() => handleShowRules()}>
      {textWrapRules ? 'Lebih Sedikit' : 'Lebih Banyak'}
    </Button>
  );

  const _renderBodyBoxClassroom = () => (
    <BodyBoxClassroom>
      <div className="sub">
        <label>Keterangan Kelompok Belajar</label>
        <div style={textWrapDescription ? textShow : textHidden}>
          {textWrapDescription
            ? dataClassroom?.description?.split(/\r?\n/).map((line, id) => {
              return <p key={id}> {line}</p>;
            })
            : dataClassroom?.description
              ?.slice(0, 250)
              ?.split(/\r?\n/)
              .map((line, id) => {
                return <p key={id}> {line}</p>;
              })}
          {/* {dataClassroom.description} */}
        </div>
        {dataClassroom?.description?.length > 250 && _renderButtonDescription()}
      </div>
      <div className="sub">
        <label>Group Rules</label>
        <div style={textWrapRules ? textShow : textHidden}>
          {textWrapRules
            ? dataClassroom?.rules?.split(/\r?\n/).map((line, id) => {
              return <p key={id}> {line}</p>;
            })
            : dataClassroom?.rules
              ?.slice(0, 250)
              ?.split(/\r?\n/)
              .map((line, id) => {
                return <p key={id}> {line}</p>;
              })}
          {/* {dataClassroom.rules} */}
        </div>
        {dataClassroom?.rules?.length > 250 && _renderButtonRules()}
      </div>
    </BodyBoxClassroom>
  );

  const _renderPrice = () => {
    return (
      <div style={{ padding: '7.4% 7.4% 0', textAlign: 'center' }}>
        <h6 style={{ fontSize: '1.3rem', fontWeight: 500, marginBottom: 0 }}>
          {convertToRupiah(dataClassroom?.price ?? 0)}
        </h6>
      </div>
    );
  };

  return (
    <>
      <Wrapper hidden={isAbout ? true : false}>
        {_renderTopBoxClassroom()}
        {_renderBodyBoxClassroom()}
      </Wrapper>
      <Wrapper hidden={isAbout ? false : true}>
        {_renderTopBoxClassroom()}
        {isPaid && _renderPrice()}
        <RequestButtonClassroom {...props} />
      </Wrapper>
    </>
  );
};

export default SidebarClassroomInfoComponent;
