import React, { useState } from 'react';
import config from './UserProfile.config';
import { useParams, useLocation } from 'react-router-dom';
import { Enhancer } from '../../SharedComponents';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import { MenuBar } from '../../Containers';
import Error404Component from '../Utility/Error404';

const UserProfileContainer = props => {
  const param = useParams();
  const location = useLocation();
  const profileId = param.profileId;
  const [menuBarList] = useState(config.menuBarList);
  const [blockedMe, , blockLoading] = useBlockMe();

  if (blockLoading) return <div />;
  else if (!blockLoading && blockedMe.includes(profileId)) {
    return <Error404Component />;
  } else {
    return <MenuBar menuBarList={menuBarList} location={location} {...props} />;
  }
};

export default Enhancer({})(UserProfileContainer);

// export default Enhancer({
//   isShowMenubar: true,
//   menuBarList: config.menuBarList,
//   defaultMenu: config.defaultMenuBar
// })(UserProfileContainer);
