import Constants from '../../Config/Constants';
import styled from 'styled-components';

export default {
  container: isShowBottomPostBox => ({
    backgroundColor: Constants.COLORS.WHITE,
    paddingRight: 20,
    paddingLeft: 20,
    minHeight: isShowBottomPostBox ? 200 : 140,
    borderRadius: 2.5,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    marginBottom: 15
  }),
  textArea: {
    fontSize: 12,
    border: 'none',
    resize: 'none',
    placeholderTextSize: 12,
    placeholderTextColor: Constants.COLORS.GREY,
    color: Constants.COLORS.BLACK
  }
};

export const PostBoxWrap = styled.div`
  position: relative;
  text-align: left;
`;
