import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Form, message } from 'antd';
import LocationFormComponent from './LocationForm.component';
import {
  CityServices,
  DistrictServices,
  GetProvinceServices
} from '../../Services/Vendor';
import { useMutation } from '@apollo/react-hooks';
import { ADD_LOCATION, EDIT_LOCATION } from './LocationForm.graphql';

const LocationFromComponentWrapper = props => {
  const {
    setDisplayForm,
    formTitle,
    selectedRecord,
    setRefetch,
    setSelectedRecord
  } = props;
  const [location, setLocation] = useState({});
  const dataProvince = GetProvinceServices();
  const dataCity = CityServices.GetCityByProvinceIdServices(location.province);
  const dataDistrict = DistrictServices.GetDistrictByCityId(location.city);
  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
  };
  const [addLocation] = useMutation(ADD_LOCATION, OPTIONS);
  const [editLocation] = useMutation(EDIT_LOCATION, OPTIONS);
  const selectionData = {
    province: dataProvince,
    city: dataCity,
    district: dataDistrict
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await props.form.validateFields((error, values) => {
      if (!error) {
        const getProvinceName = dataProvince.find(
          item => item.province_id === values.province
        ).province;
        const getCityName = dataCity.find(item => item.city_id === values.city)
          .city_name;
        const getDistrictName = dataDistrict.find(
          item => item.subdistrict_id === values.district
        ).subdistrict_name;
        const sendData = {
          data: {
            label_name: values.label,
            address: values.address,
            province_code: values.province,
            province_name: getProvinceName,
            city_code: values.city,
            city_name: getCityName,
            district_code: values.district,
            district_name: getDistrictName,
            post_code: values.postal,
            status: 'ACTIVE'
          }
        };
        setDisplayForm(false);
        setLocation({});
        addLocation({
          variables: sendData
        });
        message.success('Successfully add new Location');
      }
    });
    setRefetch(true);
    setSelectedRecord({});
    props.form.resetFields();
  };

  const handleSubmitEdit = async event => {
    event.preventDefault();
    await props.form.validateFields((error, values) => {
      if (!error) {
        const getProvinceName = dataProvince.find(
          item => item.province_id === values.province
        ).province;
        const getCityName = dataCity.find(item => item.city_id === values.city)
          .city_name;
        const getDistrictName = dataDistrict.find(
          item => item.subdistrict_id === values.district
        ).subdistrict_name;
        const sendData = {
          id: selectedRecord.id,
          label_name: values.label,
          address: values.address,
          province_code: values.province,
          province_name: getProvinceName,
          city_code: values.city,
          city_name: getCityName,
          district_code: values.district,
          district_name: getDistrictName,
          post_code: values.postal,
          status: 'ACTIVE'
        };
        setDisplayForm(false);
        setLocation({});
        editLocation({
          variables: sendData
        });
        message.success('Successfully edit Location');
      }
    });
    setRefetch(true);
    setSelectedRecord({});
    props.form.resetFields();
  };

  const updateLocationField = (
    fieldName,
    value,
    locationToUpdate = location
  ) => {
    let updatedLocation = { ...locationToUpdate };
    updatedLocation[fieldName] = value;
    setLocation(updatedLocation);
  };

  const isLocationFieldUndefined = fieldName => {
    return location[fieldName] === undefined;
  };

  const isEdit = formTitle === 'Edit Location';

  const handleCancel = () => {
    setDisplayForm(false);
    setSelectedRecord({});
    setLocation({});
  };

  useEffect(() => {
    setLocation({
      province: selectedRecord.province_code,
      city: selectedRecord.city_code,
      district: selectedRecord.district_code
    });
  }, [selectedRecord]);

  return (
    <LocationFormComponent
      {...props}
      handleSubmit={isEdit ? handleSubmitEdit : handleSubmit}
      isEdit={isEdit}
      selectedRecord={selectedRecord}
      updateLocationField={updateLocationField}
      mockedSelectionData={selectionData}
      isLocationFieldUndefined={isLocationFieldUndefined}
      handleCancel={handleCancel}
    />
  );
};

const LocationFormContainer = Form.create({ name: 'Location_Form_Control' })(
  LocationFromComponentWrapper
);

export default LocationFormContainer;
