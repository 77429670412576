import AuthenticationService from '../Auth';
import baseUrl from '../../Config/baseUrl';
import BaseService from '../BaseService';
import axios from 'axios';
import _ from 'lodash';
import Constants from '../../Config/baseUrl';

const getTokenAuth = () => {
  const userData = AuthenticationService.getUserData();
  if (userData !== '' && userData !== undefined && !_.isEmpty(userData)) {
    //console.log(JSON.parse(getCookie('userData')).oauth.access_token);
    return userData.oauth.access_token;
  }
  return '';
};

const createAxiosInstance = url => {
  const instance = axios.create({
    baseURL: url,
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'es',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getTokenAuth()}`
    }
  });

  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      if (error.response && error.response.status === 401) {
        AuthenticationService.removeAllCookies();
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const notificationService = createAxiosInstance(
  process.env.REACT_APP_NOTIFICATION_URL
);

const getNotification = (page = 1, limit = 10, type, sub_type) => {
  const url = '/global/notification/get';
  let parameters = {
    params: {
      limit: limit,
      page: page,
      type: type,
      sub_type: sub_type
    }
  };
  return notificationService.get(url, parameters);
};

const getUnreadNotification = () => {
  const url = '/global/notification/get/count';
  return notificationService.get(url);
};

const readANotification = id => {
  const url = `/global/notification/${id}/read`;
  return notificationService.patch(url);
};
const readAllNotification = id => {
  return notificationService.patch(Constants.READ_ALL_NOTIFICATION());
};

const FETCH_LIST_NOTIFICATION = page => {
  const userId = AuthenticationService.getUserId();
  const url = `${baseUrl.NOTIFICATION(userId)}?page=${page}&maxResults=5`;
  return BaseService.get(url);
};

const readAllNotificationType = (type, isRecent, today, yesterday) => {
  return notificationService.patch(
    Constants.READ_ALL_NOTIFICATION_TYPE(type, isRecent, today, yesterday)
  );
};

const readAllNotificationSubType = (type, isRecent, today, yesterday) => {
  return notificationService.patch(
    Constants.READ_ALL_NOTIFICATION_SUB_TYPE(type, isRecent, today, yesterday)
  );
};

export default {
  FETCH_LIST_NOTIFICATION,
  getNotification,
  readANotification,
  readAllNotification,
  getUnreadNotification,
  readAllNotificationType,
  readAllNotificationSubType
};
