import styled from 'styled-components';
import Constants from '../../Config/Constants';

export const CardBoxWrap = styled.div`
  .box {
    margin-bottom: 24px;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 21px 18.9px 30.1px 27px;
    background: ${Constants.COLORS.WHITE};
    .header {
      margin-bottom: 10px !important;
    }
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .input-label {
    color: #a9a8a8;
    font-size: 14px;
    margin-bottom: 7px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
