import gql from 'graphql-tag';

export const ADD_OHSA = gql`
  mutation addOHSAByUser($object: people_profile_ohsa_insert_input!) {
    insert_people_profile_ohsa_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_OHSA = gql`
  mutation updateOHSA($id: Int!, $object: people_profile_ohsa_set_input!) {
    update_people_profile_ohsa_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const DELETE_OHSA = gql`
  mutation($id: Int!, $userId: uuid) {
    update_people_profile_ohsa(
      _set: { deletedAt: "now()" }
      where: {
        _and: [
          { id: { _eq: $id } }
          { user: { _eq: $userId } }
          { deletedAt: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const LIST_CATEGORY = gql`
  {
    people_profile_ohsa_categories(order_by: { id: asc }) {
      id
      name
    }
  }
`;
