import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import ListDetail from '../../../../Components/DetailUserSection/ListDetail.component';
import AddEditEducation from './AddEditEducation';
import MoreEducation from './MoreEducation';

export default function Education({
  isUser,
  dataEducation,
  refetch,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ ...open, open: true, data: null, actionType: 'add' })
        }
        title="Pendidikan"
        action={isUser ? 'plus' : null}
      >
        <ListDetail
          setOpen={setOpen}
          isUser={isUser}
          data={dataEducation}
          editOnChild
          showMoreText="Pendidikan"
          type="education"
        />
      </DetailUserSection>

      <MoreEducation
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataEducation}
      />
      <AddEditEducation
        setOpen={setOpen}
        openState={open}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onClose={
          open.fromList
            ? () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false,
                  openMore: true
                })
            : () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false
                })
        }
        onCloseModal={
          open.fromList
            ? () => setOpen({ ...open, open: false, openMore: true })
            : () => setOpen({ ...open, open: false })
        }
      />
    </>
  );
}
