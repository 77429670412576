import React from 'react';
import style, { AboutWrap, ButtonEdit } from './About.style';
import { Icon } from 'antd';
import { displayLoading } from '../../Utils/CommonUtils';
import moment from 'moment';
import { listMaritalStatus } from './HelperAbout';

const AboutComponent = props => {
  const {
    loading,
    userProfile,
    profileId,
    userId,
    setOpen,
    dataProvinceCity
  } = props;
  let aboutProfile = {};
  if (userProfile) {
    const {
      main_profile,
      profile_about,
      profile_phone,
      profile_address
    } = userProfile;
    aboutProfile = {
      name: main_profile?.[0]?.name ? main_profile?.[0]?.name : '-',
      gender: profile_about?.[0]?.gender ? profile_about?.[0]?.gender : '-',
      birthdate: profile_about?.[0]?.birthdate
        ? profile_about?.[0]?.birthdate
        : '-',
      marital_status: profile_about?.[0]?.marital_status
        ? profile_about?.[0]?.marital_status
        : '-',
      blood_type: profile_about?.[0]?.blood_type
        ? profile_about?.[0]?.blood_type
        : '-',
      phone: profile_phone?.[0]?.phone
        ? `${profile_phone[0].country_code}${profile_phone?.[0]?.phone}`
        : '-',
      address: profile_address?.[0]?.address
        ? profile_address?.[0]?.address
        : '-',
      city: profile_about?.[0]?.birthplace_city_name
        ? profile_about?.[0]?.birthplace_city_name
        : '-',
      province: profile_about?.[0]?.birthplace_province_name
        ? profile_about?.[0]?.birthplace_province_name
        : '-',

      postal_code: profile_address?.[0]?.postal_code
        ? profile_address?.[0]?.postal_code
        : '-',
      religion: profile_about?.[0]?.religion ?? '-',
      cityId: dataProvinceCity.cityId,
      // city: dataProvinceCity.city ?? '-',
      provinceId: dataProvinceCity.provinceId,
      // province: dataProvinceCity.province ?? '-',
      religionName: dataProvinceCity.religion ?? '-',
      profile_fields: profile_about?.[0]?.profile_fields
    };
  }

  const wrapDataFromArray = (data, isFromObj) => {
    if (data && data[0]) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {data.map((res, i) => (
            <span key={`${i}`}>{isFromObj ? res.name : res}</span>
          ))}
        </div>
      );
    } else return '-';
  };

  const generateBrokenDate = date => {
    if (date) {
      // request date format from android
      const newDate = date.split('T00:00:00.000Z');
      const trueDate = newDate[0].split('-');
      let year = trueDate[0];
      let month = '';
      let day = '';
      if (trueDate[1].length === 1) {
        month = `0${trueDate[1]}`;
      } else month = trueDate[1];

      if (trueDate[2].length === 1) {
        day = `0${trueDate[2]}`;
      } else day = trueDate[2];
      const returnDate = moment(`${year}-${month}-${day}T00:00:00.000Z`).format(
        'll'
      );
      return returnDate !== 'Invalid date'
        ? returnDate
        : moment(date).format('ll');
    } else return date;
  };

  const {
    dialect,
    marital_date,
    marital_place,
    nationality,
    place_of_birth,
    race,
    religion_sect,
    salutation
  } = aboutProfile?.profile_fields ?? {};

  const _buttonEdit = () => (
    <ButtonEdit onClick={() => setOpen({ open: true, data: aboutProfile })}>
      <Icon type="edit" style={style.iconEdit} />
    </ButtonEdit>
  );

  const _about = () => (
    <AboutWrap>
      <table className="information">
        <tbody>
          <tr>
            <td>Jenis Kelamin</td>
            <td>
              {aboutProfile.gender === 'F'
                ? 'Perempuan'
                : aboutProfile.gender === 'M'
                ? 'Laki - Laki'
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Tempat Lahir</td>
            <td>{place_of_birth === '' ? '-' : place_of_birth ?? '-'}</td>
          </tr>
          <tr>
            <td>Tanggal Lahir</td>
            <td>
              {aboutProfile.birthdate && aboutProfile.birthdate !== '-'
                ? moment(aboutProfile.birthdate).format('ll')
                : '-'}
            </td>
          </tr>
          <tr>
            <td>Agama</td>
            <td style={{ textTransform: 'capitalize' }}>
              {aboutProfile.religionName ?? '-'}
            </td>
          </tr>
          {/* <tr>
            <td>Sekte Agama</td>
            <td style={{ textTransform: 'capitalize' }}>
              {religion_sect === '' ? '-' : religion_sect ?? '-'}
            </td>
          </tr> */}
          <tr>
            <td>Status Pernikahan</td>
            <td style={{ textTransform: 'capitalize' }}>
              {!aboutProfile.marital_status ||
              aboutProfile.marital_status === ''
                ? '-'
                : listMaritalStatus.find(
                    item => item.id === aboutProfile.marital_status
                  )?.name ?? '-'}
            </td>
          </tr>
          {/* <tr>
            <td>Tanggal Pernikahan</td>
            <td style={{ textTransform: 'capitalize' }}>
              {!marital_date || marital_date === ''
                ? '-'
                : generateBrokenDate(marital_date)}
            </td>
          </tr>
          <tr>
            <td>Tempat Pernikahan</td>
            <td style={{ textTransform: 'capitalize' }}>
              {marital_place === '' ? '-' : marital_place ?? '-'}
            </td>
          </tr> */}
        </tbody>
      </table>

      <table className="information">
        <tbody>
          {/* <tr>
            <td>Kebangsaan</td>
            <td style={{ textTransform: 'capitalize' }}>
              {wrapDataFromArray(nationality)}
            </td>
          </tr>
          <tr>
            <td>Dialek</td>
            <td style={{ textTransform: 'capitalize' }}>
              {wrapDataFromArray(dialect, true)}
            </td>
          </tr>
          <tr>
            <td>Suku</td>
            <td style={{ textTransform: 'capitalize' }}>
              {wrapDataFromArray(race, true)}
            </td>
          </tr> */}
          <tr>
            <td>Sapaan</td>
            <td style={{ textTransform: 'capitalize' }}>
              {wrapDataFromArray(salutation)}
            </td>
          </tr>
        </tbody>
      </table>
      {profileId === userId && _buttonEdit()}
    </AboutWrap>
  );
  return <div>{loading ? displayLoading(loading) : _about()}</div>;
};

export default AboutComponent;
