import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  GET_GLOBAL_PROFILE_SETTING,
  GET_USER_PROFILE_SETTING,
  UPDATE_USER_PROFILE_SETTING
} from './ProfileInformation.graphql';
import ProfileInformationComponent from './ProfileInformation.component';
import { ProfileInformationWrapper } from './style';
import { AuthenticationService } from '../../../Services';
import { isEmpty } from 'lodash';

const ProfileInformationContainer = () => {
  const userId = AuthenticationService.getUserId();
  const [profileInfoSetting, setProfileInfoSetting] = useState([]);
  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'public' } }
  };
  const OPTIONS2 = {
    context: { headers: { 'X-Hasura-Role': 'user' } },
    variables: { id: userId }
  };

  const { data: listProfileSetting } = useQuery(
    GET_GLOBAL_PROFILE_SETTING,
    OPTIONS
  );
  const { data: userProfileSetting, loading } = useQuery(
    GET_USER_PROFILE_SETTING,
    {
      OPTIONS,
      variables: { id: userId }
    }
  );
  const [updateUserProfileSetting] = useMutation(
    UPDATE_USER_PROFILE_SETTING,
    OPTIONS2
  );

  useEffect(() => {
    if (!isEmpty(userProfileSetting?.people_profile)) {
      setProfileInfoSetting(
        userProfileSetting.people_profile[0]?.settings_section
      );
    }
  }, [userProfileSetting]);

  return (
    <ProfileInformationWrapper>
      {!loading && (
        <ProfileInformationComponent
          listProfileSetting={listProfileSetting}
          profileInfoSetting={profileInfoSetting}
          updateUserProfileSetting={updateUserProfileSetting}
        />
      )}
    </ProfileInformationWrapper>
  );
};

export default ProfileInformationContainer;
