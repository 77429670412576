import { PostListWrapper } from './PostList.style';
import React from 'react';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import PostCardContainer from '../PostCard';
import { SkeletonLoading, DummySpace, SearchNotFound } from '../../Components';
import { PostBox } from '..';
import { AuthenticationService } from '../../Services';
// import { isEmpty } from 'lodash';

const PostListComponent = props => {
  const {
    data,
    isFetching,
    setLoading,
    match,
    restartPage,
    deletePost,
    postType,
    isAdmin,
    dataCompany,
    blockLoaded = true,
    handleVote
  } = props;
  const userId = AuthenticationService.getUserId();
  const profileId = match.params.profileId;

  const _Post = () =>
    data.map((itemPost, index) => {
      return (
        <PostCardContainer
          key={index}
          itemPost={itemPost}
          setLoading={setLoading}
          postType={props.postType}
          match={match}
          deletePost={deletePost}
          restartPage={restartPage}
          isAdmin={isAdmin}
          dataCompany={dataCompany}
          handleVote={handleVote}
          {...props}
        />
      );
    });

  const _PostSkeleton = () => {
    return (
      <PostListWrapper>
        <SkeletonLoading loading={isFetching} active avatar />
      </PostListWrapper>
    );
  };
  return (
    <div>
      {(postType !== 'profile' || userId === profileId) &&
        (postType !== 'companyTimeline' || isAdmin) && (
          <PostBox
            postType={props.postType}
            setLoading={setLoading}
            match={match}
            restartPage={restartPage}
            dataCompany={dataCompany}
            {...props}
          />
        )}
      {!isObjectEmpty(data) && _Post()}
      {isFetching && _PostSkeleton()}
      {!isFetching && isObjectEmpty(data) && blockLoaded && (
        <SearchNotFound
          title="Tidak ada postingan"
          desc="Cobalah untuk mengirimkan sesuatu"
          style={{ width: '100%' }}
        />
      )}
      <DummySpace />
    </div>
  );
};

export default PostListComponent;
