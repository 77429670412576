import React from 'react';
import PaymentScreenComponent from './PaymentScreen.component';
import { useGlobalState } from '../../../globalState';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Constants from '../../../Config/Constants';

const { GRAPHQL_HEADER_OPTIONS, USER_ROLE } = Constants;

const queryMutation = gql`
  mutation insertCompany($data: [company_profiles_insert_input!]!) {
    insert_company_profiles(objects: $data) {
      affected_rows
    }
  }
`;

const GET_LIST_BANK = gql`
  {
    global_banks {
      id
      name
      code
      swift
      bank_fields
    }
  }
`;

const PaymentScreenContainer = props => {
  const [dataReg, setDataReg] = useGlobalState(
    'dataRegistration',
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.USER)
  );
  const [addRegistration] = useMutation(queryMutation);
  const [dataUploadFile] = useGlobalState('uploadFile');
  const listBank = useQuery(GET_LIST_BANK);
  let dataBank = [];

  if (listBank.loading) {
    dataBank = [
      {
        id: 0,
        name: 'Loading...',
        code: '0',
        swift: null,
        bank_fields: null
      }
    ];
  } else {
    dataBank = listBank.data.global_banks;
  }

  return (
    <PaymentScreenComponent
      dataReg={dataReg}
      dataBank={dataBank}
      setDataReg={setDataReg}
      addRegistration={addRegistration}
      dataUploadFile={dataUploadFile}
      {...props}
    />
  );
};

export default PaymentScreenContainer;
