import styled from 'styled-components';
import Assets from '../../Assets';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 var(--black-16);
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  /* padding: 10px; */
  font-family: ${Assets.FONT.MULI};

  thead[class*='ant-table-thead'] th {
    background-color: #ffffff !important;
  }

  .ant-table {
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #010101;
  }

  .ant-table-tbody > tr > td {
    border: none;
    max-width: 130px;
  }

  .anticon.anticon-ellipsis {
    width: 16px;
    height: 16px;
    color: blue;
  }
  .pagination {
    padding: 10px;
  }
`;

export { TableWrapper };
