import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';

const fetchTimeline = (nextPage, options = {}) => {
  const userId = AuthenticationService.getUserId();
  const userTimeline = Constants.TIMELINE(userId);

  return BaseService.get(userTimeline, {
    params: {
      page: nextPage,
      maxResults: 3
    },
    ...options
  });
};

const deleteTimeline = postId => {
  const userId = AuthenticationService.getUserId();
  const url = Constants.TIMELINE_ID(userId, postId);
  return BaseService.delete(url);
};

export default { fetchTimeline, deleteTimeline };
