import React from 'react';
import PostCardContainer from './PostCard.container';
import { Group } from '../../Services';

function UserPostCard(props) {
  const post = props.itemPost;
  const groupId = props.match.params.id;

  //   const _handleCancel = () => {
  //     setDisplayShare(false);
  //   };
  const handleLike = likeValue => {
    return Group.Discussion.postLikePost(
      post.userId,
      groupId,
      post.id,
      likeValue
    );
  };
  return <PostCardContainer {...props} handleLike={handleLike} />;
}
export default UserPostCard;
