import React from 'react';
import {
  ButtonWlb,
  TopSettingGroup,
  VendorOrderShippingStatus
} from '../../../Components';
import {
  ItemCard,
  OrderDetail,
  SectionCard,
  Wrapper
} from './OrderDetail.style';
import Assets from '../../../Assets';
import currencyFormat from '../../../Utils/currencyFormat';
import { displayLoading } from '../../../Utils/CommonUtils';
import Constants from '../../../Config/Constants';
import { Timeline } from 'antd';
import moment from 'moment';

const OrderDetailComponent = props => {
  const { orderId, data, error, onStatusChange, onPageBack } = props;

  if (error) {
    return (
      <div style={{ background: Constants.COLORS.DARK_GREY }}>
        <Wrapper>
          <SectionCard>
            <TopSettingGroup title="Error occured" />
          </SectionCard>
          <div>
            <p>Uh oh, something went wrong when fetching the order details.</p>
            <pre>{'' + error}</pre>
          </div>
        </Wrapper>
      </div>
    );
  }

  return (
    <div style={{ background: Constants.COLORS.DARK_GREY }}>
      <Wrapper>
        <SectionCard>
          <TopSettingGroup
            title="Order Detail"
            onBack={onPageBack}
            buttonTop={[
              <ButtonWlb
                key="1"
                type="primary"
                onClick={() => window.open(`/invoice/${orderId}`)}
              >
                Print Invoice
              </ButtonWlb>
            ]}
          />
          {!data ? (
            displayLoading(true)
          ) : (
            <OrderDetail>
              <div className="info">
                <h4>Customer</h4>
                <p>{data?.invoice_to ? data?.invoice_to : 'tidak ada'}</p>
              </div>
              <div className="info">
                <h4>Order Number</h4>
                <p>{data?.code}</p>
              </div>
            </OrderDetail>
          )}
        </SectionCard>
      </Wrapper>

      <Wrapper>
        <SectionCard style={{ marginBottom: 40 + 15 }}>
          <TopSettingGroup
            title="Item"
            color={Assets.COLOR_PALLETE.black}
            border={false}
          />
          {!data
            ? displayLoading(true)
            : data.global_invoice_items.map((item, key) => {
                return (
                  <ItemCard key={key}>
                    <div className="image">
                      <img
                        alt="Img"
                        src={item.thumbnail || Assets.IMAGE.DEFAULT_IMAGE}
                      />
                    </div>

                    <div className="info">
                      <p>{item.itemname}</p>
                      <p>Total : {item.qty}</p>
                      <p>
                        {item.weight || item.weight === 0
                          ? `Weight: ${item.weight}`
                          : 'This item is digital product'}
                      </p>
                    </div>
                    <div className="info">
                      <p>{currencyFormat(item.single_price)}</p>
                      <p>
                        Total price:{' '}
                        {currencyFormat(item.single_price * item.qty)}
                      </p>
                    </div>
                  </ItemCard>
                );
              })}
        </SectionCard>
      </Wrapper>

      <Wrapper>
        <SectionCard>
          <TopSettingGroup
            title="Shipping Service"
            color={Assets.COLOR_PALLETE.black}
            border={false}
          />
          {!data ? (
            displayLoading(true)
          ) : (
            <>
              {data.delivery_option &&
                data.delivery_data &&
                data.global_shipping_provider && (
                  <ItemCard>
                    {data.global_shipping_provider && (
                      <div className="image">
                        <img
                          alt="shipping service"
                          src={data?.global_shipping_provider?.logo}
                        />
                      </div>
                    )}
                    <div className="info">
                      <h4>
                        {data.delivery_option || ''}{' '}
                        {data.delivery_data.service || ''}
                      </h4>
                    </div>
                  </ItemCard>
                )}
              <ItemCard>
                <div className="shipping">
                  <div className="shipping-info">
                    <h4>Payment Status</h4>
                    <p>{data.status}</p>
                  </div>
                </div>
                <div className="shipping">
                  <div className="shipping-info">
                    <h4>Total Payment</h4>
                    <p>{currencyFormat(data.final_price)}</p>
                  </div>
                </div>
              </ItemCard>
            </>
          )}
        </SectionCard>
      </Wrapper>

      {(!data || data.delivery_data) && (
        <Wrapper>
          <SectionCard>
            <TopSettingGroup
              title="Address"
              color={Assets.COLOR_PALLETE.black}
              border={false}
            />
            {!data ? (
              displayLoading(true)
            ) : (
              <ItemCard>
                <div className="shipping">
                  <div className="shipping-info">
                    <p>{data.delivery_data.address}</p>
                  </div>
                  <h4>Recipient Name</h4>
                  <p>{data.delivery_data.recipient_name || ''}</p>
                </div>

                {data.tracking_status && (
                  <div className="shipping-info tracking-status">
                    <Timeline>
                      {data.tracking_status.map((item, i) => {
                        return (
                          <Timeline.Item
                            color={i !== 0 ? 'gray' : 'blue'}
                            key={i}
                          >
                            {moment(item.time).format('DD-MM-YYYY HH:mm')}{' '}
                            {item.status}
                          </Timeline.Item>
                        );
                      })}
                    </Timeline>
                  </div>
                )}
              </ItemCard>
            )}
          </SectionCard>
        </Wrapper>
      )}

      {data && (
        <Wrapper>
          <VendorOrderShippingStatus
            customerName={data.invoice_to || 'Person'}
            customerId={data.global_wallets?.global_users[0].id}
            invoiceId={data.id}
            status={data.status}
            transferProofs={
              data.global_payment?.global_direct_transfer
                ?.global_direct_transfer_proofs
            }
            refunds={data.marketplace_refund_requests}
            price={data.final_price}
            onStatusChange={onStatusChange}
            isDigitalOrder={!data.delivery_option}
          />
        </Wrapper>
      )}
    </div>
  );
};

export default OrderDetailComponent;
