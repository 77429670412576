import React from 'react';
import { Input, Divider, Select } from 'antd';
import { Link } from 'react-router-dom';
import style from './PollingPost.style';

const duration = [1, 3, 5, 7];

const PollingPostComponent = props => {
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    post,
    handlePost,
    handleAddPollingOption,
    handleChangePollingOption,
    handleRemovePollingOption
  } = props;

  const _renderTextArea = () => (
    <TextArea
      className="textArea"
      style={style.textArea}
      autoSize={{ minRows: 4 }}
      value={post.poll.question}
      onChange={e => handlePost('question', e.target.value)}
      placeholder="Ajukan Pertanyaan"
    />
  );

  const _renderTitle = (title, i) => {
    return (
      <div style={style.flexBetween}>
        <div style={style.title}>{title}</div>
        {i > 1 && (
          <div
            style={style.remove}
            onClick={() => handleRemovePollingOption(i)}
          >
            Hapus
          </div>
        )}
      </div>
    );
  };

  const _renderInput = (value, i) => (
    <Input
      placeholder={`Tambah opsi ${i + 1}`}
      value={value}
      style={style.input}
      onChange={e => handleChangePollingOption(e.target.value, i)}
    />
  );

  const _renderAddOption = () => {
    return (
      <Link style={style.link} onClick={() => handleAddPollingOption()}>
        + Tambah opsi
      </Link>
    );
  };

  const _renderOption = data => {
    return data.options.map((val, key) => {
      return (
        <div key={key}>
          {_renderTitle(`Opsi ${key + 1}*`, key)}
          {_renderInput(val, key)}
        </div>
      );
    });
  };

  const _renderSelect = () => {
    return (
      <Select
        placeholder="Atur durasi polling"
        style={style.select}
        onChange={e => handlePost('duration', e)}
      >
        {duration.map(val => {
          return (
            <Option value={val} key={val}>
              {val} Hari
            </Option>
          );
        })}
      </Select>
    );
  };
  return (
    <div>
      {_renderTextArea()}
      {_renderOption(post.poll)}
      {post.poll.options.length <= 3 && _renderAddOption()}
      <Divider />
      {_renderTitle('Durasi Polling*')}
      {_renderSelect()}
    </div>
  );
};

export default PollingPostComponent;
