import React, { useEffect, useState } from 'react';
import UserProfileCVComponent from './UserProfileCV.component';
import { GET_LIST_CV } from './UserPrintCV.graphql';
import { useLazyQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { jsPDF } from 'jspdf';

export default function UserProfileCV() {
  const { id, filename } = useParams();
  // const [scale, setScale] = useState(1)
  const [scale, setScale] = useState(1);

  const [getData, { data }] = useLazyQuery(GET_LIST_CV, {
    context: {
      headers: {
        'X-Hasura-Role': 'public',
        'Content-Type': 'application/json'
      }
    }
  });

  const saveDiv = async select => {
    const pdf = new jsPDF('p', 'pt', [1000, 841.89]);
    await pdf.html(select, {
      callback: function(pdf) {
        setTimeout(() => {
          pdf.save(filename + '.pdf');
        }, 1000);
      }
    });
    setScale(1);
  };

  const select = document.getElementById('print-resume');
  useEffect(() => {
    if (id) {
      getData({
        variables: {
          user: id
        }
      });
    }

    if (data && select) {
      setTimeout(() => {
        saveDiv(select);
        // buttonDownload.click()
        // window.print()
      }, 2000);
    }
    // eslint-disable-next-line
  }, [data, id, getData, filename, select]);

  return (
    <div>
      <div>
        <UserProfileCVComponent
          // id="print-resume"
          data={data}
          scale={scale}
        />
      </div>
    </div>
  );
}
