import React from 'react';
import { MentorshipList } from '../../Components';

const MentorshipComponent = props => {
  return (
    <div>
      <MentorshipList {...props} />
    </div>
  );
};

export default MentorshipComponent;
