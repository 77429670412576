import React, { useEffect, useState } from 'react';
import { Form, Input, message, Switch } from 'antd';
import { TitleField } from '../../ActionMorePeopleProfile/ActionMore.style';
import { ModalEdit } from '../..';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  ADD_LIST_PHONENUMBER,
  UPDATE_LIST_PHONENUMBER,
  SET_UNMAIN_PHONECODE
} from './PhoneNumber.graphql';
import {
  ButtonFrom,
  GLOBAL_PHONE_CODE_SEARCHABLE,
  SearchableSelect
} from '../../../SharedComponents';
import { AuthenticationService } from '../../../Services';

const AddEditPhoneNumberContainer = ({
  open,
  handleClose,
  openAction,
  form,
  dataEdit,
  refetchList,
  setOpenAction
}) => {
  const { actionType } = openAction;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    getFieldValue
  } = form;
  const [main, setMain] = useState(false);
  const [countryCode, setCountryCode] = useState(null);

  const [addPhoneNumber] = useMutation(ADD_LIST_PHONENUMBER, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [setUnmainPhone] = useMutation(SET_UNMAIN_PHONECODE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [updatePhoneNumber] = useMutation(UPDATE_LIST_PHONENUMBER, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [
    getListGlobalPhoneCode,
    { data, loading: loadingPhone }
  ] = useLazyQuery(GLOBAL_PHONE_CODE_SEARCHABLE);

  useEffect(() => {
    if (open) {
      if (actionType === 'edit') {
        getListGlobalPhoneCode({
          variables: {
            where: {
              id: { _eq: dataEdit?.country_code }
            }
          }
        });
        setCountryCode(dataEdit.code);
        setFieldsValue({
          location: dataEdit?.country_code
        });
      } else {
        setFieldsValue({
          location: undefined
        });
      }
      setMain(dataEdit?.is_main ?? false);
    }
  }, [open, getListGlobalPhoneCode, dataEdit, actionType, setFieldsValue]);

  const handleCloseAct = () => {
    handleClose();
    resetFields();
  };

  const handleMutationAdd = async (values, isMain) => {
    const submit = {
      globalPhoneCodeId: values.location,
      countryCode: countryCode,
      phone: values.phone_number,
      isMain
    };
    await addPhoneNumber({
      variables: submit
    })
      .then(() => {
        message.success(`Tambah Nomor Telepon Berhasil`);
        refetchList();
        handleCloseAct();
      })
      .catch(err => {
        message.error(`Tambah Nomor Telepon Gagal, ${err}`);
      });
  };
  const handleMutationEdit = async (values, isMain) => {
    updatePhoneNumber({
      variables: {
        id: dataEdit.id,
        globalPhoneCodeId: values.location,
        userId: AuthenticationService.getUserId(),
        countryCode: countryCode,
        phone: values.phone_number,
        is_main: isMain
      }
    })
      .then(() => {
        message.success(`Ubah Nomor Telepon Berhasil`);
        refetchList();
        handleCloseAct();
      })
      .catch(err => {
        message.error(`Ubah Nomor Telepon Gagal, ${err}`);
      });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          if (main) {
            setUnmainPhone({
              variables: {
                user: AuthenticationService.getUserId()
              }
            })
              .then(() => {
                handleMutationAdd(values, true);
              })
              .catch(err => {
                message.error(`Error set main phone, ${err}`);
              });
          } else {
            handleMutationAdd(values, false);
          }
        } else {
          if (main) {
            setUnmainPhone({
              variables: {
                user: AuthenticationService.getUserId()
              }
            })
              .then(() => {
                handleMutationEdit(values, true);
              })
              .catch(err => {
                message.error(`Error set main phone, ${err}`);
              });
          } else {
            handleMutationEdit(values, false);
          }
        }
      }
    });
  };

  const handleSetModalDelete = cond => {
    setOpenAction({
      ...openAction,
      addEdit: cond === 'open' ? false : true,
      modalDelete: cond === 'open' ? true : false
    });
  };

  const handleChange = (_, target, data) => {
    if (target === 'location') {
      setCountryCode(data.props.data.code);
    }
  };

  return (
    <ModalEdit
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambah'} Nomor Telepon`}
      open={open}
      onClose={handleCloseAct}
      sizeSmall
      centered
      onClickDelete={
        actionType === 'edit' ? () => handleSetModalDelete('open') : null
      }
    >
      <Form onSubmit={handleSubmit}>
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Nomor Telepon</TitleField>}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '25%' }}
              required
              disabled
            >
              {getFieldDecorator('location', {
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <SearchableSelect
                  fieldValue={getFieldValue('location')}
                  placeholder={'Kode Negara'}
                  style={{ width: '90%' }}
                  withEmptyIcon={false}
                  loading={loadingPhone}
                  option={
                    data?.global_phone_code?.map(data => ({
                      id: data.id,
                      name: `(${data.code}) ${data.country}`,
                      data
                    })) || []
                  }
                  onSearch={({ value }) => {
                    getListGlobalPhoneCode({
                      variables: {
                        where: {
                          _or: value
                            ? [
                                { country: { _ilike: `%${value}%` } },
                                { code: { _ilike: `%${value}%` } }
                              ]
                            : undefined
                        },
                        limit: 20
                      }
                    });
                  }}
                  fieldName={'location'}
                  onTouch={({ isOpen }) => {
                    if (isOpen) {
                      getListGlobalPhoneCode({
                        variables: {
                          limit: 20
                        }
                      });
                    }
                  }}
                  onChange={(_, data) => {
                    setCountryCode(data.props.data.data.code);
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '75%' }}
              required
            >
              {getFieldDecorator('phone_number', {
                initialValue: actionType === 'edit' ? dataEdit.phone : null,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Input
                  placeholder="Tambah Nomor Telepon"
                  type="number"
                  onChange={value => handleChange(value, 'phone_number')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        <Form.Item
          style={{
            padding: '0px 22px 12px 22px',
            marginBottom: 8,
            display: 'flex'
          }}
          label={<TitleField>Tetapkan Sebagai Nomor Telpon Utama</TitleField>}
        >
          {getFieldDecorator(
            'main',
            {}
          )(
            <Switch
              size="small"
              checked={main}
              onClick={() => setMain(!main)}
            />
          )}
        </Form.Item>
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleCloseAct}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditPhoneNumber = Form.create({ name: 'AddEdit_Phone_Number' })(
  AddEditPhoneNumberContainer
);

export default AddEditPhoneNumber;
