import React from "react";
import ListItem from "./ListItem";
import moment from "moment";
import { Empty } from "antd";
import { WrapperData } from "./Recommendation.style";

export default function ListReceived({
  data,
  setPublish,
  publish,
  updateStatus,
  refetch,
  setOpen,
  stateOpen,
}) {

  const handleRevise = (request_to, name, id) => {
    setOpen({
      ...stateOpen,
      open: false,
      openAsk: true,
      askData: {
        request_to,
        name,
        id
      },
    })
  }

  return (
    <div>
      <WrapperData>
        {data.length > 0
          ? data.map((res, i) => (
              <ListItem
                refetch={refetch}
                updateStatus={updateStatus}
                setPublish={setPublish}
                publish={publish}
                status={res?.status}
                type="received"
                key={i}
                id={res?.id}
                userId={res?.global_user?.id}
                avatar={res?.global_user?.avatar}
                name={res?.global_user?.name}
                date={moment(res?.date_added).format("DD MMM YYYY")}
                position={res?.position}
                relationship={res?.relationship}
                message={res?.description}
                onRevise={() => handleRevise(res?.global_user?.id, res?.global_user?.name, res.id)}
              />
            ))
          : <Empty style={{marginTop: 10}} />}
        </WrapperData>
    </div>
  );
}
