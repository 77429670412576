import React from 'react';

import config from './HomePage.config';
import { Enhancer } from '../../SharedComponents';

const HomePageContainer = () => <div />;

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.menuBarList,
  defaultMenu: config.defaultMenuBar
})(HomePageContainer);
