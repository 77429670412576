import React from 'react';
import {
  TopBoxGroup,
  BodyBoxGroup,
  textHidden,
  textShow,
  Wrapper
} from './SidebarGroupInfo.style';
import { Button } from 'antd';
// import { ButtonWlb } from '../../Components';
import RequestButtonGroup from './ButtonGroupAbout.component';

const SidebarGroupInfoComponent = props => {
  const {
    dataGroup,
    handleShowDescription,
    handleShowRules,
    textWrapDescription,
    textWrapRules,
    isAbout
  } = props;
  const _renderPrivacy = () => {
    if (dataGroup.privacy === 2) {
      return (
        <span>
          <i className="icon lock"></i>Tim Kerja Tertutup
        </span>
      );
    }
    if (dataGroup.privacy === 0) {
      return <span>Tim Kerja Publik</span>;
    } else if (dataGroup.privacy === 1) {
      return <span>Tim Kerja Pribadi</span>;
    }
  };
  const _renderTopBoxGroup = () => (
    <TopBoxGroup
      onClick={() => props.history.push(`/group/${dataGroup.id}/discussion`)}
      style={{ cursor: 'pointer' }}
    >
      <div className="logo">
        <img alt="logo" src={dataGroup.avatar} />
      </div>
      <div className="title">
        <span>{dataGroup.name}</span>
        {_renderPrivacy()}
      </div>
    </TopBoxGroup>
  );
  const _renderButtonDescription = () => (
    <Button className="showmore" onClick={() => handleShowDescription()}>
      {textWrapDescription
        ? 'Tampilkan Lebih Sedikit'
        : 'Tampilkan Lebih Banyak'}
      <i
        className={textWrapDescription ? 'icon up-arrow' : 'icon down-arrow'}
      ></i>
    </Button>
  );

  const _renderButtonRules = () => (
    <Button className="showmore" onClick={() => handleShowRules()}>
      {textWrapRules ? 'Tampilkan Lebih Sedikit' : 'Tampilkan Lebih Banyak'}
      <i className={textWrapRules ? 'icon up-arrow' : 'icon down-arrow'}></i>
    </Button>
  );

  const stringShorter = string => {
    const maxLen = 110;
    const separator = ' ';

    if (string?.length <= maxLen) return string;
    return string?.substr(0, string.lastIndexOf(separator, maxLen));
  };

  const _renderBodyBoxGroup = () => {
    return (
      <BodyBoxGroup>
        <div className="sub">
          <label>Deskripsi Tim Kerja</label>
          <div style={textWrapDescription ? textShow : textHidden}>
            {textWrapDescription
              ? dataGroup?.description?.split(/\r?\n/).map((line, id) => {
                  return <p key={id}> {line}</p>;
                })
              : stringShorter(dataGroup?.description)
                  ?.split(/\r?\n/)
                  .map((line, id) => {
                    return <p key={id}> {line}</p>;
                  })}
          </div>
          {dataGroup?.description?.length > 110 && _renderButtonDescription()}
        </div>
        <div className="sub">
          <label>Aturan</label>
          <div style={textWrapRules ? textShow : textHidden}>
            {textWrapRules
              ? dataGroup?.rules?.split(/\r?\n/).map((line, id) => {
                  return <p key={id}> {line}</p>;
                })
              : stringShorter(dataGroup?.rules)
                  ?.split(/\r?\n/)
                  .map((line, id) => {
                    return <p key={id}> {line}</p>;
                  })}
          </div>
          {dataGroup?.rules?.length > 110 && _renderButtonRules()}
        </div>
      </BodyBoxGroup>
    );
  };

  return (
    <>
      <Wrapper hidden={isAbout ? true : false}>
        {_renderTopBoxGroup()}
        {_renderBodyBoxGroup()}
      </Wrapper>
      <Wrapper hidden={isAbout ? false : true}>
        {_renderTopBoxGroup()}
        <RequestButtonGroup {...props} />
      </Wrapper>
    </>
  );
};

export default SidebarGroupInfoComponent;
