import React, { useState } from 'react';
import { ModalEdit } from '../../../../Components';
import { Tabs, Button } from 'antd';
import { ContentWrapper } from './Recommendation.style';
import ListReceived from './ListReceived';
import ListSent from './ListSent';
import { useMutation } from 'react-apollo';
import { UPDATE_STATUS_RECOMMENDATION } from './Recommendation.graphql';

export default function EditRecommendationTab({
  open,
  onClose,
  data,
  refetch,
  setOpen,
  stateOpen,
  dataSent,
  refetchSent
}) {
  const [publish, setPublish] = useState([]);
  const { TabPane } = Tabs;

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [updateStatus] = useMutation(UPDATE_STATUS_RECOMMENDATION, {
    context: CONTEXT_USER
  });

  const handleSubmit = () => {
    onClose();
  };

  return (
    <ModalEdit title="Ubah Rujukan" open={open} onClose={onClose}>
      <ContentWrapper>
        <Tabs defaultActiveKey="1">
          <TabPane tab={`Diterima (${data.length})`} key="1">
            <ListReceived
              stateOpen={stateOpen}
              setOpen={setOpen}
              refetch={refetch}
              updateStatus={updateStatus}
              setPublish={setPublish}
              publish={publish}
              data={data}
            />
          </TabPane>
          <TabPane
            tab={`Dikirimkan (${dataSent?.people_profile_recommendations.length})`}
            key="2"
          >
            <ListSent
              onClose={onClose}
              refetchSent={refetchSent}
              stateOpen={stateOpen}
              setOpen={setOpen}
              refetch={refetch}
              updateStatus={updateStatus}
              setPublish={setPublish}
              publish={publish}
              dataSent={dataSent && dataSent.people_profile_recommendations}
            />
          </TabPane>
        </Tabs>
      </ContentWrapper>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: 22,
          marginTop: 32,
        }}
      >
        <Button onClick={onClose} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div> */}
    </ModalEdit>
  );
}
