import React, { useState } from 'react';
import config from './SpecificFollower.config';
import SpecificFollowerComponent from './SpecificFollower.component';
import { AuthenticationService } from '../../Services';
import { useQuery } from 'react-apollo';
import { GET_EMPLOYEES } from './listOfEmployee.graphql';
import { useLocation } from 'react-router-dom';
import Constants from '../../Config/Constants';

const { GRAPHQL_HEADER_OPTIONS, USER_ROLE } = Constants;

const SpecificFollowerContainer = props => {
  const {
    handlePost,
    setIsShowModal,
    canSee,
    setFocusPost
    // isShowSpecificFollower
  } = props;

  const location = useLocation();
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(canSee);
  const [limit, setLimit] = useState(10);

  const getEmployees = useQuery(GET_EMPLOYEES, {
    ...GRAPHQL_HEADER_OPTIONS(USER_ROLE.PUBLIC),
    variables: {
      id: AuthenticationService.getUserData().company_id,
      key: `%${search}%`,
      limit: limit,
      offset: 0
    },
    skip: location.pathname !== '/companypost'
  });

  const _addCheckedFollower = follower => {
    setChecked(checked.concat(follower.global_user));
  };

  const _removeCheckedFollower = follower => {
    const checkedFollower = checked.filter(user => {
      return user.id !== follower.id;
    });
    setChecked(checkedFollower.global_user);
  };

  const handleChangeCheckBox = (followerSelected, value) => {
    if (value) {
      return _addCheckedFollower(followerSelected);
    } else {
      _removeCheckedFollower(followerSelected);
    }
  };

  const saveFollowers = () => {
    handlePost('canSee', checked);
    setIsShowModal(false);
    setFocusPost(true);
  };

  const resetFollowers = () => {
    setIsShowModal(false);
    setChecked(canSee);
  };

  const handleScroll = e => {
    const { currentTarget } = e;
    if (
      currentTarget.scrollTop + currentTarget.clientHeight >=
      currentTarget.scrollHeight
    ) {
      setLimit(curr => curr + 10);
    }
  };

  return (
    <SpecificFollowerComponent
      checkedFollowers={checked}
      followers={getEmployees?.data?.people_work_placements}
      handleChangeCheckBox={handleChangeCheckBox}
      saveFollowers={saveFollowers}
      resetFollowers={resetFollowers}
      setSearch={setSearch}
      isFetching={getEmployees?.loading}
      handleScroll={handleScroll}
      {...props}
    />
  );
};

SpecificFollowerContainer.propTypes = config.propTypes;
SpecificFollowerContainer.defaultProps = config.defaultProps;

export default SpecificFollowerContainer;
