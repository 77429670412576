import React from 'react';
import { useQuery } from 'react-apollo';

import CompanyAddressBoxComponent from './CompanyAddressBox.component';
import AddressDetailsModal from './AddressDetailsModal.component';

import {
  GET_COMPANY_ADDRESSES,
  GET_COMPANY_MAIN_ADDRESS
} from './CompanyAddressBox.graphql';

const CompanyAddressBoxContainer = props => {
  const { companyId, dataLoadedCompany } = props;

  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState(null);
  const [openDetails, setOpenDetails] = React.useState(false);

  const { data: listData, loading: loadingList } = useQuery(
    GET_COMPANY_ADDRESSES,
    {
      variables: {
        id: companyId,
        search: `%${search}%`,
        offset: 0,
        limit: 5
      }
    }
  );

  const { data: mainData } = useQuery(GET_COMPANY_MAIN_ADDRESS, {
    variables: {
      id: companyId
    }
  });

  const handleSearchInput = event => {
    const value = event.target.value;
    const next = value.trim();
    setSearch(next);
  };

  const toggleDetails = () => {
    setOpenDetails(!openDetails);
  };

  const addresses = listData?.office_address || [];

  const activeItem = mainData?.profiles[0]?.address;
  const activeId = activeItem?.id;
  const selectedItem =
    selected || (search || !activeItem ? addresses[0] : activeItem);
  const selectedId = selectedItem?.id;

  const listing = React.useMemo(() => {
    const addresses = listData?.office_address || [];

    return search || !activeItem
      ? addresses
      : [activeItem, ...addresses.filter(item => item.id !== activeId)];
  }, [listData, search, activeItem, activeId]);

  return (
    <>
      <CompanyAddressBoxComponent
        {...props}
        data={listing}
        loading={loadingList}
        selected={selectedId}
        selectedItem={selectedItem}
        active={activeId}
        onSearchInput={handleSearchInput}
        onSelectedChange={setSelected}
        onDetailsClick={toggleDetails}
      />

      <AddressDetailsModal
        open={openDetails}
        onClose={toggleDetails}
        dataOffice={selected}
        dataCompany={dataLoadedCompany}
        activeOfficeId={activeId}
      />
    </>
  );
};

export default CompanyAddressBoxContainer;
