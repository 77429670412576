import styled from 'styled-components';
import Assets from '../../Assets';

export const WrapperDetailSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-color: #fff;
  padding: 0px 32px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    padding: 28px 0px;
    align-items: center;

    .title {
      font-size: 16px;
      margin: 0px;
    }
  }
`;

export const WrapperContentSection = styled.div``;

export const ButtonEdit = styled.div`
  font-size: 18px;
  color: rgb(169, 168, 168);
  &:hover {
    cursor: pointer;
  }
`;

export const DivStyled = styled.div`
  display: ${props => props.display};
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};
  margin: ${props => props.margin};
  width: ${props => props.width};
  overflow: ${props => props.overflow};
  border-bottom: ${props => props.borderBottom};
  padding: ${props => props.padding};
  justify-content: ${props => props.justifyContent};
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  flex-wrap: ${props => props.flexWrap};
  gap: ${props => props.gap};
`;

export const ThypoStyled = styled.p`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-style: ${props => props.fontStyle};
  color: ${props => (props.color ? props.color : '#000')};
  margin: ${props => (props.margin ? props.margin : '0px')};
  width: ${props => props.width};
  height: ${props => props.height};
  overflow: ${props => props.overflow};
  font-family: ${Assets.FONT.MULI};
  cursor: ${props => props.cursor};
  text-align: ${props => props.textAlign};
  line-height: ${props => props.lineHeight};
`;

export const WrapperIndicator = styled.span`
  width: 171.6px;
  height: 50.1px;
  border-radius: 41px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
`;

export const Indicator = styled.span`
  width: 55.5px;
  height: 53.7px;
  border-radius: 44px;
  background-color: #014a62;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
`;

export const WrapChipsSection = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
  padding: 30px 0;
  font-weight: normal;
  .chips-wrap {
    min-width: 263px;
    .chips {
      max-width: 232px;
      box-sizing: border-box;
      padding: 16.5px 27px;
      border-radius: 41px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      width: ${props => (props.fullchips ? '100%' : 'max-content')};
      .chips-name {
        font-size: 14px;
        font-weight: normal;
        color: #050505;
        margin: 0px;
      }
    }
  }
  .content {
    width: 100%;
    .divider {
      display: flex;
      padding-bottom: 30px;
      border-bottom: ${props =>
        props.noBorder ? 'none' : '1px solid #e5e5e5'};
      width: 100%;
      .icon-edit {
        margin-left: auto;
        cursor: pointer;
      }
      .attachment-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 16px;
        .attachment-icon {
          margin-right: 20px;
        }
      }
      .see-more {
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #a9a8a8;
      }
    }
    p {
      margin: 0 0 8px 0;
    }
    .font-12 {
      font-size: 12px;
    }
    .font-14 {
      font-size: 14px;
    }
    .semi-bold {
      font-weight: 600;
    }
    .color-primary {
      color: #014a62;
    }
    .color-grey {
      color: #a9a8a8;
    }
    .color-balck {
      color: #1b1d1f;
    }
    .mb-16 {
      margin-bottom: 16px;
    }
    .mb-regular {
      margin-bottom: 8px;
    }
    .pre-line {
      white-space: pre-line;
    }
  }
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .attachment-icon {
    margin-right: 20px;
  }
`;
