import { Icon } from 'antd';
import React from 'react';
import { WrapperBtn } from './Attachment.style';

export const ButtonAddAttachment = ({
  disabled,
  id,
  multiple,
  handleChangeFile
}) => {
  return (
    <>
      <label htmlFor={id}>
        <WrapperBtn disabled={disabled}>
          <Icon
            type="plus"
            style={{
              color: '#039be5',
              fontSize: 25,
              fontWeight: '600',
              cursor: disabled ? 'not-allowed' : 'pointer'
            }}
          />
        </WrapperBtn>
      </label>
      <input
        disabled={disabled}
        onChange={handleChangeFile}
        style={{ visibility: 'hidden' }}
        type="file"
        id={id}
        name="updateFile"
        multiple={multiple}
      />
    </>
  );
};
