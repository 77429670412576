import styled from 'styled-components';

const SearchAndFilter = styled.div`
  display: flex;
  width: 1174px;
  height: 150px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
  justify-content: space-around;
  padding-left: 10px;
  margin: 10px 0px 10px 0px;

  #filter-field {
    width: 500px;
  }

  .ant-input-search {
    font-family: 'Muli-SemiBold';
    width: 100%;
    max-width: 475.1px;
    height: 63px;
    border-radius: 3px;
    border: solid 1px #e5e5e5;
    background-color: #ffffff;
  }

  .total-employee {
    font-family: 'Muli-SemiBold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
`;

export { SearchAndFilter };
