import React, { useState } from 'react';
import { ModalEdit } from '../..';
import { Form, Select, Input, Button, message, Avatar } from 'antd';
import {
  TitleField,
  Headline,
  SelectedPepole,
  Position,
  AvatarItemSelect
} from '../ActionMore.style';
import { useQuery, useMutation } from 'react-apollo';
import {
  GET_LIST_RELATIONSHIP,
  GET_LIST_POSITION,
  GIVE_RECOMMENDATION,
  GET_DETAIL_REQUEST,
  REVISE_RECOMMENDATION
} from './GiveRecommendation.graphql.js';
import { AuthenticationService } from '../../../Services';
import { generateValue, getRelationship } from '../ActionMoreHelper';
import MessageAnswer from './MessageAnswer';

const GiveRecommendationComponent = ({
  open,
  onClose,
  form,
  name = '',
  profileId,
  from,
  dataNotification,
  subtype = null
}) => {
  const { request_id } = dataNotification?.data || {};
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue
  } = form;
  const [valueRequest, setValueRequest] = useState({
    position: null,
    relationship: null,
    name: '',
    avatar: null,
    id: null
  });

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [giveRecommendation] = useMutation(GIVE_RECOMMENDATION, {
    context: CONTEXT_USER
  });
  const [reviseRecommendation] = useMutation(REVISE_RECOMMENDATION, {
    context: CONTEXT_USER
  });

  const { data: dataRequest } = useQuery(GET_DETAIL_REQUEST, {
    skip: !request_id,
    wlb_skipPatch: true,
    context: CONTEXT_PUBLIC,
    variables: {
      where: {
        id: { _eq: request_id }
      }
    },
    onCompleted: data => {
      if (data) {
        const { position, relationship, global_user } =
          data.people_profile_request_recommendations?.[0] || {};
        setValueRequest({
          position,
          relationship,
          name: global_user?.name,
          avatar: global_user?.avatar,
          id: global_user?.id
        });
        setFieldsValue({ subject: global_user?.id });
      }
    }
  });

  const { data: dataRelationship } = useQuery(GET_LIST_RELATIONSHIP, {
    context: CONTEXT_PUBLIC
  });

  const { data: dataPosition } = useQuery(GET_LIST_POSITION, {
    context: CONTEXT_PUBLIC,
    variables: {
      userLogin: AuthenticationService.getUserId()
    }
  });

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (subtype === 'recommendation-revision') {
          const sent = {
            id: dataNotification?.data?.recommendation_id,
            description: values.recommendation
          };
          reviseRecommendation({ variables: sent })
            .then(res => {
              message.success(`Revise recommendation success`);
              onClose();
              resetFields();
            })
            .catch(err => {
              message.error(`Revise recommendation error, ${err}`);
            });
        } else {
          const submit = {
            objects: [
              {
                recommendation_to:
                  from === 'notification' ? valueRequest.id : profileId,
                relationship:
                  from === 'notification'
                    ? valueRequest.relationship
                    : values.relationship,
                position:
                  from === 'notification'
                    ? valueRequest.position
                    : values.position,
                description: values.recommendation
              }
            ]
          };
          giveRecommendation({
            variables: submit
          })
            .then(() => {
              message.success(`Give referral success`);
              onClose();
              resetFields();
            })
            .catch(err => {
              message.error(`Give referral error, ${err}`);
            });
        }
      }
    });
  };

  const _switchComponentToRender = res => {
    const { type, fieldName, option, labelName } = res;

    if (type === 'select') {
      return (
        <Select
          size="large"
          loading={from === 'notification' && !dataRequest}
          style={
            fieldName === 'relationship'
              ? {
                  textTransform: 'capitalize'
                }
              : null
          }
        >
          {fieldName !== 'subject' && (
            <Option value={null} disabled>
              Choose {labelName}
            </Option>
          )}
          {option &&
            option.map((res, i) => {
              if (fieldName === 'subject') {
                return (
                  <Option key={i} value={valueRequest.id}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Avatar
                        style={{ height: 26, width: 26, marginTop: 1 }}
                        src={valueRequest.avatar}
                        size="large"
                      />
                      <AvatarItemSelect>
                        <Position size="14px">{valueRequest.name}</Position>
                      </AvatarItemSelect>
                    </div>
                  </Option>
                );
              } else {
                return (
                  <Option
                    key={i}
                    value={fieldName === 'relationship' ? res.name : res.id}
                    style={
                      fieldName !== 'position' && {
                        textTransform: 'capitalize'
                      }
                    }
                  >
                    {res.name}
                  </Option>
                );
              }
            })}
        </Select>
      );
    } else if (type === 'textArea') {
      return <TextArea rows={4} placeholder="Add referral" />;
    }
  };

  const subject = from === 'notification' ? valueRequest.name : name;
  const listFields = [
    {
      labelName: 'Relationship',
      fieldName: 'relationship',
      type: 'select',
      fieldValue: null,
      option: getRelationship(dataRelationship, subject),
      required: false
    },
    {
      labelName: 'Position at the time',
      fieldName: 'position',
      type: 'select',
      fieldValue: null,
      option: generateValue(dataPosition),
      required: true
    },
    {
      labelName: 'Referral',
      fieldName: 'recommendation',
      type: 'textArea',
      fieldValue: '',
      required: false
    }
  ];

  const listFieldsNotif = [
    {
      labelName: 'Subject',
      fieldName: 'subject',
      type: 'select',
      fieldValue: null,
      option: [{ valueRequest }],
      // option: dataRelationship?.people_profile_recommendation_relationships,
      required: true
    },
    {
      labelName: 'Referral',
      fieldName: 'recommendation',
      type: 'textArea',
      fieldValue: '',
      required: true
    }
  ];

  let data = from === 'notification' ? listFieldsNotif : listFields;

  const handleClose = () => {
    onClose();
    resetFields();
  };

  return (
    <ModalEdit
      title="Give Referral"
      open={open}
      onClose={handleClose}
      sizeSmall
    >
      {from === 'notification' && subtype === 'social-referral-request' && (
        <MessageAnswer message={dataNotification?.data?.comment} />
      )}
      <Headline>
        How well do you know <SelectedPepole>{subject}</SelectedPepole>?
      </Headline>
      <Form onSubmit={handleSubmit}>
        {data.length > 0 &&
          data.map((res, i) => {
            return (
              <Form.Item
                key={i}
                style={{ padding: '0px 22px', marginBottom: 8 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: [
                    {
                      required: res.required,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <Form.Item
          style={{
            marginBottom: 0,
            padding: '20px 22px 0px 22px',
            marginTop: 22
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Cancel
            </Button>
            <Button
              disabled={from === 'notification' && !dataRequest}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ModalEdit>
  );
};

const GiveRecommendation = Form.create({ name: 'Give_Recommendation' })(
  GiveRecommendationComponent
);

export default GiveRecommendation;
