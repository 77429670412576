import React, { useState, useEffect } from 'react';
import PostListComponent from './PostList.component';
import { AuthenticationService, PostService } from '../../Services';
import useInfiniteScroll from '../../SharedComponents/CustomHook/useInfiniteScroll';
import { checkNextPage } from '../../Utils/CommonUtils';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';

const PostListUser = props => {
  const [dataPost, setDataPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('user-profile');
  const [isFetching, setIsFetching] = useState(false);
  const profileId = props.match.params.profileId;
  const userId = AuthenticationService.getUserId();

  useEffect(() => {
    restartPage();
  }, [profileId]);

  useAsyncEffect(
    async isMount => {
      if (hasNextPage && !isFetching && (isBottom || page === 1)) {
        setIsFetching(true);
        await PostService.fetchHomePostList(page, profileId)
          .then(response => {
            if (!isMount()) return;
            const { per_page, total, data } = response;
            const isNextPage = checkNextPage(total, page, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }
            setDataPost(dataPost.concat(data));
          })
          .catch(error => {})
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
            setIsBottom(false);
          });
      }
    },
    [dataPost, hasNextPage, isBottom, isFetching, page, setIsBottom, profileId]
  );

  function deletePost(itemPost) {
    PostService.deletePost(itemPost.userId, itemPost.id)
      .then(res => {
        if (res.status === 200) {
          let newDataPost = dataPost.filter(post => {
            return post.id !== itemPost.id;
          });
          setDataPost(newDataPost);
          message.success('Postingan Anda telah dihapus');
        }
      })
      .catch(err => message.error('Something went wrong'));
  }

  function restartPage() {
    setDataPost([]);
    setHasNextPage(true);
    setPage(1);
  }

  const handleVote = async (postId, optionId) => {
    const _dataPost = dataPost;
    const indexPost = _dataPost.findIndex(e => e.id === postId);
    let post = _dataPost[indexPost];

    await PostService.votePoll(userId, postId, optionId)
      .then(res => {
        if (post.count_shared_post > 0) {
          _dataPost.map((val, i) => {
            if (val.sharedPost) {
              if (val.sharedPost.id === postId) {
                _dataPost[i] = {
                  ...val,
                  sharedPost: { ...val.sharedPost, poll: { ...res } }
                };
              }
            }
          });
        }
        post = { ...post, poll: { ...post.poll, ...res } };

        _dataPost[indexPost] = post;

        setDataPost([..._dataPost]);
        message.success('Vote Success Submitted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  const handleUndoVote = async postId => {
    let _dataPost = dataPost;
    const indexPost = _dataPost.findIndex(e => e.id === postId);
    let post = _dataPost[indexPost];

    await PostService.undoVote(userId, postId)
      .then(response => {
        if (post.count_shared_post > 0) {
          _dataPost.map((val, i) => {
            if (val.sharedPost) {
              if (val.sharedPost.id === postId) {
                _dataPost[i] = {
                  ...val,
                  sharedPost: { ...val.sharedPost, poll: { ...response.poll } }
                };
              }
            }
          });
        }

        post = { ...post, poll: { ...post.poll, ...response.poll } };
        _dataPost[indexPost] = post;
        setDataPost([..._dataPost]);
        message.success('Undo Vote Success Submitted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <PostListComponent
      data={dataPost}
      isFetching={isFetching}
      deletePost={deletePost}
      restartPage={restartPage}
      handleVote={handleVote}
      handleUndoVote={handleUndoVote}
      {...props}
    />
  );
};
export default PostListUser;
