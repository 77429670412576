import React from 'react';

import config from './LoginForm.config';

const LoginFormComponent = ({ form, updateField, login }) => {
  const { email, password } = form;

  return (
    <>
      <span>
        Username:
        <input value={email} name="email" onChange={updateField} />
      </span>
      <br />
      <span>
        Password:
        <input
          value={password}
          name="password"
          type="password"
          onChange={updateField}
        />
      </span>
      <br />
      <button onClick={() => login(form)}>Submit</button>
    </>
  );
};

LoginFormComponent.propTypes = config.propTypes;
LoginFormComponent.defaultProps = config.defaultProps;

export default LoginFormComponent;
