import { Form, Icon, Input, message, Select, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { ModalEdit } from '../../Components';
import { AuthenticationService } from '../../Services';
import { ButtonFrom, TitleField } from '../../SharedComponents';
import { GET_DATA_EDUCATION, UPDATE_INTRO } from './EditIntro.graphql';

const rule = (isRequired, strict = false) => {
  if (strict) {
    return [
      {
        required: isRequired,
        message: 'Bagian ini diperlukan'
      },
      {
        pattern: /^[a-z A-Z.]*$/,
        message: 'This field is text only'
      },
      {
        max: 50,
        message: 'You have exceeded the maximum character limit'
      }
    ];
  } else {
    return [
      {
        required: isRequired,
        message: 'Bagian ini diperlukan'
      }
    ];
  }
};

const isNoEducation = (list, id) => {
  if (list && list.length > 0) {
    let result = true;
    list.forEach(res => {
      if (res.id === id) {
        result = false;
      }
    });
    return result;
  } else return true;
};

const EditIntroComponent = ({ open, onClose, form, data, refetch }) => {
  const {
    getFieldDecorator,
    validateFields,
    resetFields
    // getFieldValue,
  } = form;
  const { Option } = Select;

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const [getEducation, { data: dataEducation }] = useLazyQuery(
    GET_DATA_EDUCATION,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [updateIntro] = useMutation(UPDATE_INTRO, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  useEffect(() => {
    if (data) {
      getEducation({
        variables: {
          userId: AuthenticationService.getUserId()
        }
      });
    }
  }, [data, getEducation]);

  const _switchComponentToRender = res => {
    const { type, option, placeholder, style, disabled, loading = false } = res;
    if (type === 'select') {
      return (
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder={placeholder}
          loading={loading}
          allowClear
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.company_profile?.brand_name || '-'}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          disabled={disabled}
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
        />
      );
    }
  };

  const listEducation =
    dataEducation?.people_profile?.[0]?.global_user?.schools;

  const listFields = [
    {
      labelName: 'Nama Lengkap',
      fieldName: 'full_name',
      type: 'text',
      fieldValue: data?.name,
      placeholder: 'Nama Lengkap',
      isRequired: true,
      disabled: true
    },
    {
      labelName: (
        <span>
          Nama Resmi{' '}
          <Tooltip
            color={'#eff2f4'}
            placement="right"
            title="Nama lengkap anda termasuk gelar"
          >
            {' '}
            <Icon type="info-circle" />
          </Tooltip>
        </span>
      ),
      fieldName: 'official_name',
      type: 'text',
      fieldValue: data?.official_name,
      placeholder: 'Tambahkan Nama Resmi',
      isRequired: false,
      isStrictRule: true
    },
    {
      labelName: 'Nama Panggilan',
      fieldName: 'nick_name',
      type: 'text',
      fieldValue: data?.nickname,
      placeholder: 'Tambahkan Nama Panggilan',
      isRequired: false,
      isStrictRule: true
    },
    {
      labelName: 'Pendidikan',
      fieldName: 'education',
      type: 'select',
      fieldValue:
        !listEducation ||
        listEducation.length === 0 ||
        isNoEducation(listEducation, data?.educationId)
          ? undefined
          : data?.educationId ?? undefined,
      placeholder: 'Pilih Pendidikan',
      isRequired: false,
      option: listEducation ?? [],
      loading: !listEducation
    }
  ];

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        updateIntro({
          variables: {
            name: values.full_name,
            userId: AuthenticationService.getUserId(),
            educationId: values.education,
            data: {
              official_name: values.official_name,
              initial_name: values.nick_name
            }
          }
        })
          .then(() => {
            refetch(values.education);
            message.success(`Berhasil mengubah intro`);
            handleClose();
          })
          .catch(err => {
            message.error(`Gagal mengubah intro, ${err}`);
          });
      }
    });
  };

  return (
    <ModalEdit
      sizeSmall
      title="Mengubah Intro"
      open={open}
      onClose={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired, res.isStrictRule)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <ButtonFrom
          submitLabel="Simpan"
          actionType="edit"
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const EditIntro = Form.create({ name: 'Edit_Intro' })(EditIntroComponent);

export default EditIntro;
