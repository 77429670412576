import gql from 'graphql-tag';

export const ADD_PUBLICATION = gql`
  mutation addPublicationByUser(
    $objects: people_profile_publications_insert_input!
  ) {
    insert_people_profile_publications_one(object: $objects) {
      id
    }
  }
`;

export const UPDATE_PUBLICATION = gql`
  mutation updatePublicationByUser(
    $id: Int!
    $objects: people_profile_publications_set_input!
  ) {
    update_people_profile_publications_by_pk(
      pk_columns: { id: $id }
      _set: $objects
    ) {
      id
    }
  }
`;
export const DELETE_PUBLICATION = gql`
  mutation($id: Int!, $userId: uuid) {
    update_people_profile_publications(
      _set: { deletedAt: "now()" }
      where: {
        _and: [
          { id: { _eq: $id } }
          { user: { _eq: $userId } }
          { deletedAt: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;
