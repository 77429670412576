import { gql } from 'apollo-boost';

export const GET_LIST_MEMBER = gql`
  query($classroom_id: uuid_comparison_exp!, $limit: Int, $offset: Int) {
    classroom_members(
      where: { classroom_id: $classroom_id }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      is_admin
      global_user {
        id
        avatar
        name
        people_work_placements {
          company_job_profile {
            position
            title
          }
        }
      }
      created_at
    }
  }
`;
