import styled from 'styled-components';

export const DisplayFileWrap = styled.div`
  background: #eff2f4;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  .file {
    display: flex;
    width: 100%;
  }
  .file-image {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }
  .file-input {
    width: 85%;
  }
  .closed-icon {
    /* float: right; */
    display: inline;
    cursor: pointer;
  }
`;
