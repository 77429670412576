import React from 'react';
import { ModalEdit } from '../../Components';

import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {
  WrapperCKEditor,
  ActionWrapperDesc
} from './CompanyDescriptionAction.style';
import { Button, Divider } from 'antd';
import DOMPurify from 'dompurify';

const editorConfiguration = {
  toolbar: ['']
};

const CompanyDescriptionAction = ({
  open,
  handleClose,
  desc,
  setDesc,
  handleSubmit
}) => {
  return (
    <ModalEdit
      onClickDelete={null}
      title={`${!desc || desc === '' ? 'Tambahkan' : 'Ubah'} Deskripsi`}
      open={open}
      onClose={handleClose}
    >
      <WrapperCKEditor
        desc={!desc || desc === '' ? 'Tambahkan deskripsi instansi' : ''}
      >
        <div className="wrapper">
          <CKEditor
            editor={DecoupledEditor}
            config={editorConfiguration}
            onInit={editor => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
            }}
            data={DOMPurify.sanitize(desc)}
            // eslint-disable-next-line no-unused-vars
            onChange={(event, editor) => {
              const data = editor.getData();
              setDesc(data);
            }}
          />
        </div>
        <ActionWrapperDesc>
          <Divider />
          <div className="action">
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Batal
            </Button>
            <Button onClick={() => handleSubmit(desc)} type="primary">
              Kirim
            </Button>
          </div>
        </ActionWrapperDesc>
      </WrapperCKEditor>
    </ModalEdit>
  );
};

export default CompanyDescriptionAction;
