import { Form, Input, Switch, DatePicker, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ModalDeleteWLBCheck, ModalEdit } from '../../../../Components';
import { TitleField } from '../DetailScreen.style';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom,
  FormDateInline
} from '../../../../SharedComponents';
import { month } from '../../../../Containers/UserProfileAbout/HelperAbout';
import moment from 'moment';
import { AuthenticationService } from '../../../../Services';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import Axios from 'axios';
import { generateDateFormat, getErrorMessage } from '../HelperDetailScreen';
import { useMutation } from 'react-apollo';
import {
  ADD_DISCIPLINARY,
  DELETE_DISCIPLINARY,
  UPDATE_DISCIPLINARY
} from './Disciplinary.graphql';
import { isAfter } from '../HelperDetailScreen';

const generateDate = date => {
  if (date) {
    return date.split('-');
  } else {
    return null;
  }
};

const year = new Date().getFullYear();
const until = year - 70;
const optionYear = [];
for (let i = year; i >= until; i--) {
  optionYear.push(i);
}

const optionDay = [];
for (let i = 1; i <= 31; i++) {
  optionDay.push(i);
}

const ComponentDate = ({
  labelName,
  fieldName,
  fieldValue,
  onChange,
  getFieldDecorator,
  disabledDate
}) => {
  return (
    <Form.Item
      label={<TitleField>{labelName}</TitleField>}
      style={{
        display: 'inline-block',
        marginBottom: 0,
        width: '50%'
      }}
    >
      {getFieldDecorator(fieldName, {
        initialValue: fieldValue
          ? moment(fieldValue, 'YYYY/MM/DD')
          : moment(new Date(), 'YYYY/MM/DD'),
        rules: [
          {
            required: true,
            message: 'Bagian ini diperlukan'
          }
        ]
      })(
        <DatePicker
          placeholder="Pilih Tanggal"
          onChange={onChange}
          format={'YYYY-MM-DD'}
          style={{ width: '90%' }}
          disabledDate={disabledDate}
        />
      )}
    </Form.Item>
  );
};

const AddEditDisciplinaryComponent = ({
  open,
  actionType,
  onClose,
  setOpen,
  form,
  data,
  refetch,
  nameUser
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    resetFields
  } = form;
  const { TextArea } = Input;
  const [share, setShare] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorTotalAttch, setTotalAttch] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const [addDisciplinary] = useMutation(ADD_DISCIPLINARY, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });
  const [updateDisciplinary] = useMutation(UPDATE_DISCIPLINARY, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });
  const [deleteDisciplinary] = useMutation(DELETE_DISCIPLINARY, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });

  useEffect(() => {
    if (actionType === 'edit') {
      setAttachment(data?.attachments || []);
    }
    setShare(data?.is_share || false);
  }, [actionType, data]);

  const handleClose = () => {
    onClose();
    resetFields();
    setAttachment([]);
    setShare(false);
    setErrorFile(false);
    setErrorSize(false);
    setTotalAttch(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen(openState => ({
      ...openState,
      open: false
    }));
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen(openState => ({
      ...openState,
      open: true
    }));
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const totalAttachment = tempFile.length + attachment.length;
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (totalAttachment > 5) {
      setTotalAttch(true);
      setTimeout(() => {
        setTotalAttch(false);
      }, 3000);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const submitDate = (day, month, year) => {
          if (day.length === 1) {
            return `${year}-${month}-0${day}`;
          } else {
            return `${year}-${month}-${day}`;
          }
        };
        const submit = {
          ...(actionType === 'edit' && { id: data.id }),
          object: {
            is_share: share,
            reference_number: values.reference_number,
            discipline_letter_number: values.disciplinary_letter_number,
            reference_date: submitDate(values.day, values.month, values.year),
            discipline_name: values.disciplines_name,
            start_date: generateDateFormat(values.start_date),
            end_date: generateDateFormat(values.end_date),
            remark: values.remark,
            attachments: attachment
          }
        };
        if (actionType === 'add') {
          addDisciplinary({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil menambahkan disipliner`);
              handleClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan disipliner, ${err}`);
            });
        } else {
          updateDisciplinary({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil mengubah disipliner`);
              handleClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah disipliner, ${err}`);
            });
        }
      }
    });
  };

  const handleDelete = () => {
    deleteDisciplinary({
      variables: {
        id: data.id,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        refetch();
        message.success(`Berhasil menghapus disipliner`);
        setDeleteState({ open: false });
        handleClose();
      })
      .catch(err => {
        message.error(`Gagal menghapus disipliner, ${err}`);
        setDeleteState({ open: false });
      });
  };

  function handleChange(val, e) {
    if (e === 'upload-attachment') {
      uploadAttachment(val);
    }
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
    document.getElementById('upload-disciplinary').value = null;
  };

  const listFields = [
    {
      labelName: 'Nomor Referensi',
      fieldName: 'reference_number',
      type: 'text',
      fieldValue: data?.reference_number,
      placeholder: 'Tambahkan nomor referensi',
      required: true
    },
    {
      labelName: 'Nomor Surat Disipliner',
      fieldName: 'disciplinary_letter_number',
      type: 'text',
      fieldValue: data?.discipline_letter_number,
      placeholder: 'Tambahkan nomor surat disipliner',
      required: true
    }
  ];
  const listFields2 = [
    {
      labelName: 'Nama Disipliner',
      fieldName: 'disciplines_name',
      type: 'text',
      fieldValue: data?.discipline_name,
      placeholder: 'Tambahkan nama disipliner',
      required: true
    },
    {
      labelName: 'Nama Pegawai',
      fieldName: 'employee_name',
      type: 'text',
      fieldValue: nameUser,
      placeholder: 'Tambahkan nama pegawai',
      required: true
    }
  ];

  const FieldReference_date = [
    {
      fieldName: 'day',
      fieldValue: generateDate(data && data.reference_date)?.[2],
      isRequired: true,
      placeholder: 'Tanggal',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue: generateDate(data && data.reference_date)?.[1],
      isRequired: true,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue: generateDate(data && data.reference_date)?.[0],
      isRequired: true,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];

  const _switchComponentToRender = res => {
    const { type, placeholder } = res;
    if (type === 'text') {
      return <Input placeholder={placeholder} style={{ width: '100%' }} />;
    }
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Disipliner`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr style={{ border: 'none', borderTop: 'solid 1px #e5e5e5' }} />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <FormDateInline
            title="Tanggal Referensi"
            required={true}
            date={FieldReference_date}
            getFieldDecorator={getFieldDecorator}
          />
          {listFields2.length > 0 &&
            listFields2.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <ComponentDate
                getFieldDecorator={getFieldDecorator}
                labelName="Tanggal Mulai"
                fieldName="start_date"
                fieldValue={data?.start_date || undefined}
                onChange={() => setFieldsValue({ end_date: undefined })}
                disabledDate={current =>
                  isAfter(current, getFieldValue('end_date'))
                }
              />
              <ComponentDate
                getFieldDecorator={getFieldDecorator}
                labelName="Tanggal Selesai"
                fieldName="end_date"
                fieldValue={data?.end_date || undefined}
                disabledDate={current =>
                  isAfter(getFieldValue('start_date'), current)
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Komentar</TitleField>}
          >
            {getFieldDecorator('remark', {
              initialValue: data && data?.remark,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan komentar" />)}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-disciplinary"
              disabled={(attachment.length >= 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorTotalAttch && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>
          <ButtonFrom submitLabel="Simpan" onCancel={handleClose} />
        </Form>
      </ModalEdit>

      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Disipliner"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditDisciplinary = Form.create({ name: 'Add_Disciplinary' })(
  AddEditDisciplinaryComponent
);

export default AddEditDisciplinary;
