import styled from 'styled-components';
import Assets from '../../Assets';
import Constants from '../../Config/Constants';

export const Wrap = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 11.2px;
  .heading {
    font-size: 12px;
    font-family: ${Assets.FONT.MULI_BOLD};
    color: ${Constants.COLORS.BLACK};
    padding-bottom: 10px;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
  }
  .select {
    width: 100%;
  }
`;
