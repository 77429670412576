import { gql } from 'apollo-boost';

export const GET_CLASSROOM_BY_ID = gql`
  query($classroomId: uuid!) {
    classrooms_by_pk(id: $classroomId) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      resume
      academy_course_category {
        code
        name
        id
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`;
