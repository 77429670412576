import styled from 'styled-components';
import Assets from '../../../Assets';

export const PaymentScreenWrap = styled.div`
  position: relative;
  width: 100%;
  font-family: ${Assets.FONT.MULI};
  margin-top: 40px;
  position: relative;

  .ant-form label {
    font-size: 12px;
    line-height: 18px;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    color: ${Assets.COLOR_PALLETE.black};
  }

  .ant-input {
    font-size: 12px;
    line-height: 18px;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black};
    padding: 8px 14px;
  }

  .ant-select {
    font-size: 12px;
    line-height: 18px;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black};
    
    .ant-select-selection {
      padding: 8px 14px;
    }
    
    .ant-select-selection-selected-value {
      overflow: unset;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }

    .ant-select-selection--single {
      height: unset !important;
    }
  }

  .ant-form-item {
    padding-bottom: 0;
    margin-bottom: 16px;
  }
  .ant-form-vertical .ant-form-item {
    padding-bottom: 0;
  }

`;

export default {
  center: {
    textAlign: 'center'
  },
  buttonContinue: {
    textAlign: 'center',
    marginTop: '100px'
  }
}