import React from 'react';
import { Avatar, Col, Rate, Row } from 'antd';
import Assets from '../../../Assets';

import style, { BannerWrapper } from './VendorBanner.style';

const VendorBannerComponent = ({ vendor, rating, productSold: product }) => {
  const { brand_name, logo, profile_field, description } = vendor;
  const productSold =
    product?.global_invoice_items_aggregate?.aggregate?.sum?.qty === null
      ? 0
      : product?.global_invoice_items_aggregate?.aggregate?.sum?.qty;

  // const rating = rate?.total?.aggregate?.avg?.value;

  // Dikarenakan belom ada data field maka aku hidden dulu

  // const _renderBottomBanner = () => (
  //   <Col span={24} style={style.colBottom}>
  //     {socialMedia.linkedIn ? (
  //       <Icon
  //         style={style.icon}
  //         type="linkedin" theme="filled"/>
  //     ) : null}
  //     {socialMedia.twitter ? (
  //       <Icon
  //         style={style.icon}
  //         type="twitter"/>
  //     ) : null}
  //     {socialMedia.facebook ? (
  //         <Icon
  //           style={style.icon}
  //           type="facebook" theme="filled"/>)
  //       : null}
  //     {socialMedia.facebook ? (
  //       <Icon
  //         style={style.icon}
  //         type="mail"/>
  //     ) : null}
  //   </Col>
  // );

  const _renderContent = () => (
    <Col span={24} style={style.colContent}>
      <Col span={5}>
        <Avatar
          size={150}
          src={logo ? logo : Assets.ICON.DEFAULT_STORE}
          style={style.avatar}
        />
      </Col>
      <Col span={8} style={style.colDescription}>
        <p style={style.vendorName}>{brand_name ? brand_name : null}</p>
        <p style={style.vendorDescription}>
          {description ? description : null}
        </p>
        <p style={style.vendorMoto}>
          {profile_field ? profile_field.headline : null}
        </p>
      </Col>
      <Col span={4} style={style.colDescription}>
        <p style={style.productSold}>Product Sold</p>
        <p style={style.vendorProductSold}>{productSold}</p>
      </Col>
      <Col span={7} style={style.colDescription}>
        <p style={style.vendorRating}>Vendor Rating</p>
        <Rate
          allowHalf
          style={style.rate}
          value={rating ? rating : null}
          disabled
        />
      </Col>
    </Col>
  );

  const _renderHeader = () => (
    <Col span={24}>
      <div style={style.bannerWrapper}>
        <img
          alt="Banner"
          src={
            profile_field.banner ? profile_field.banner : Assets.DEFAULT_BANNER
          }
          style={style.banner}
        />
      </div>
    </Col>
  );
  return (
    <Row type="flex" style={style.container}>
      <BannerWrapper>
        {_renderHeader()}
        {_renderContent()}
      </BannerWrapper>
    </Row>
  );
};

export default VendorBannerComponent;
