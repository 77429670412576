import styled from 'styled-components';
import Assets from '../../../Assets';

export const Box = styled.div`
  padding: 12px 20px;
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .description {
    color: ${Assets.COLOR_PALLETE.grey};
    padding: 16px 0;

    .content {
      white-space: pre-line;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 0;
      overflow: hidden;
    }

    .more {
      all: unset;
      cursor: pointer;
      display: inline-block;
      color: ${Assets.COLOR_PALLETE.blue_link};
      margin-top: 8px;
    }

    .more:hover {
      text-decoration: underline;
    }
  }
`;

export default {
  top16: {
    marginTop: '16px'
  }
};
