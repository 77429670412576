import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const GetProvinceServices = () => {
  const query = gql`
    {
      getProvince {
        province_id
        province
      }
    }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.getProvince;
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

export default GetProvinceServices;
