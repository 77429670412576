import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { UserProfile } from '../../Services';

const useBlockMe = () => {
  const [blockLoading, setBlockLoading] = useState(false);
  const [blocker, setBlocker] = useState([]);
  const [isLoadedBlock, setIsLoadedBlock] = useState(false);

  useAsyncEffect(async isMount => {
    setBlockLoading(true);
    UserProfile.getListUserBlockedMe()
      .then(res => {
        if (!isMount()) return;
        let blockedList = res.data.map(item => item['user_id']);
        setBlocker(blockedList);
      })
      .finally(() => {
        if (!isMount()) return;
        setBlockLoading(false);
        setIsLoadedBlock(true);
      });
  }, []);
  return [blocker, isLoadedBlock, blockLoading];
};

export default useBlockMe;
