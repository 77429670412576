import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-apollo';

import VendorOrderListComponent from './VendorOrderList.component';
import { GET_LIST_ORDER } from './VendorOrderList.Graphql';

const VendorOrderListContainer = props => {
  const { setOrderDetail } = props;

  const [filterData, setFilterData] = React.useState({
    search: '',
    orderBy: 'desc',
    include: {}
  });
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [selectedId, setSelectedId] = useState(null);

  const params = useParams();

  const { data, loading } = useQuery(GET_LIST_ORDER, {
    fetchPolicy: 'cache-and-network',
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } },
    variables: {
      companyId: params.id,
      module_name: 'marketplace',
      status: filterData?.include?.status || [
        'pending',
        'paid',
        'review',
        'packing',
        'shipping',
        'completed',
        'rejected',
        'refund_pending',
        'refund_paid'
      ],
      limit: limit,
      offset: offset,
      search: filterData.search ? `%${filterData.search}%` : null,
      dateFrom: filterData?.include?.date?.from || null,
      dateTo: filterData?.include?.date?.to || null,
      priceFrom: filterData?.include?.salary?.min || null,
      priceTo: filterData?.include?.salary?.max || null,
      order: filterData.orderBy
    },
    notifyOnNetworkStatusChange: true
  });

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     // console.log(
  //     //   `selectedRowKeys: ${selectedRowKeys}`,
  //     //   'selectedRows: ',
  //     //   selectedRows
  //     // );
  //   },
  //   getCheckboxProps: record => ({
  //     disabled: record.name === 'Disabled User',
  //     name: record.name
  //   })
  // };

  const handleOrderDetail = record => {
    if (record == null) {
      setOrderDetail(false);
      return;
    }

    setOrderDetail(true);
    setSelectedId(record.id);
  };

  return (
    <VendorOrderListComponent
      orderList={data}
      totalOrder={data?.total?.aggregate?.count ?? 0}
      loading={loading}
      setPage={setOffset}
      offset={offset}
      limit={limit}
      setLimit={setLimit}
      // rowSelection={rowSelection}
      handleOrderDetail={handleOrderDetail}
      selectedId={selectedId}
      filterData={filterData}
      setFilterData={setFilterData}
      {...props}
    />
  );
};

export default VendorOrderListContainer;
