import styled from 'styled-components';

export const Footer = styled.div`
  position: relative;
  display: flex;
  margin-top: 50px;

  .ant-checkbox-wrapper {
    width: 100%;
  }
`;