import { Avatar, Icon } from 'antd';
import React from 'react';
import Assets from '../../../Assets';
import { PHeaderWrap } from '../UserProfileCV.style';

export default function Header({
  name,
  src,
  position,
  location,
  url,
  phone,
  email
}) {
  return (
    <PHeaderWrap>
      {/* <Avatar src={src} className="profile" /> */}
      <div className="info">
        <h1 className="name-user">{name}</h1>
        <p className="possition">{position}</p>

        <p className="url">Address : </p>
        <ul>
          {location.length > 0 &&
            location.map((row, key) => (
              <li className="phone" key={key}>
                {row.address}
              </li>
            ))}
        </ul>
        <p className="url"> Social Media : {}</p>
        <ul>
          {url.length > 0 &&
            url.map((row, key) => (
              <li className="url" key={key}>
                {row.url}
              </li>
            ))}
        </ul>

        <p className="phone">Phone Number : </p>

        <ul>
          {phone.length > 0 &&
            phone.map((row, key) => (
              <li className="phone" key={key}>
                {row.country_code + row.phone}
              </li>
            ))}
        </ul>
        <p className="email"> Email : </p>

        <ul>
          {email.length > 0 &&
            email.map((row, key) => (
              <li className="email" key={key}>
                {row.email}
              </li>
            ))}
        </ul>
      </div>
    </PHeaderWrap>
  );
}
