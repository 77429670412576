import React from 'react';
import { Input } from 'antd';
import {
  FollowerContent,
  FollowerPageHeader,
  UserList,
  UserRow
} from './Follower.style';
import { FollowerCard } from '..';
import { DisplayLoading, SearchNotFound } from '../../Components';
import { AuthenticationService } from '../../Services';
import { isEmpty } from 'lodash';

const { Search } = Input;

const FollowerComponent = props => {
  const {
    searchValue,
    userList,
    followsCategory,
    loading,
    defaultSearch,
    handleSearch,
    totalUser,
    setTotalUser,
    userId,
    name
  } = props;
  const ownerId = AuthenticationService.getUserId();
  let totalRow = Math.round(userList.length / 2);
  const rows = Array.from(Array(totalRow).keys());
  let timeout = 0;
  const quickSearch = e => {
    const keyword = e.target.value.trim();
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      handleSearch(props.location, keyword);
    }, 1000);
  };

  const _messageInfo = () => {
    if (searchValue === undefined && followsCategory === 'Following') {
      return {
        title: `You haven't follow anyone yet`,
        desc: `Let's follow someone`
      };
    }
    if (searchValue && followsCategory === 'Following') {
      return {
        title: `You haven't follow ${searchValue}`,
        desc: ``
      };
    }
    if (!searchValue && followsCategory === 'Followers') {
      return {
        title: `You don't have any follower yet`,
        desc: ``
      };
    }
    if (searchValue && followsCategory === 'Followers') {
      return {
        title: `${searchValue} haven't follow you`,
        desc: ``
      };
    }
  };
  const setFollowing = diff => {
    if (followsCategory === 'Following') return setTotalUser(totalUser + diff);
    else return;
  };

  return (
    <FollowerContent>
      <FollowerPageHeader>
        <div className={'follow-type'}>
          {ownerId === userId ? 'My ' : name + `'s`} {followsCategory}{' '}
          {totalUser !== undefined && `(${totalUser})`}
        </div>
        <Search
          placeholder={'Search ' + followsCategory}
          onChange={quickSearch}
          defaultValue={defaultSearch}
        />
      </FollowerPageHeader>

      {isEmpty(userList) && !loading ? (
        <SearchNotFound
          title={_messageInfo().title}
          desc={_messageInfo().desc}
        />
      ) : (
        <UserList>
          {rows.map(row => {
            let startNumber = row;
            let endIndex = row + 2;

            if (row >= 1) {
              startNumber += row;
              endIndex += row;
            }

            const usersToDisplay = userList.slice(startNumber, endIndex);

            return (
              <UserRow key={row}>
                {usersToDisplay.map(user => (
                  <FollowerCard
                    user={user}
                    key={user.id}
                    setTotalUser={setFollowing}
                  />
                ))}
              </UserRow>
            );
          })}
        </UserList>
      )}
      {loading && <DisplayLoading />}
    </FollowerContent>
  );
};

export default FollowerComponent;
