import React from "react";
import { TextSecondary, TextStandard, TextGrey, TextPrimary } from "../UserProfileCV.style";

export default function OrganizationActivity({
  title,
  organization,
  location,
  date,
  memberId,
  desc,
}) {
  return (
    <div style={{ marginBottom: 35, overflow: 'visible' }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextSecondary>{organization}</TextSecondary>
      <TextPrimary>{location}</TextPrimary>
      <TextGrey>{date}</TextGrey>
      <TextStandard>{memberId}</TextStandard>
      <TextStandard>{desc}</TextStandard>
    </div>
  );
}
