import styled from 'styled-components';
import Assets from '../../Assets';

export const KudosPostCardWrapper = styled.div`
  background: ${Assets.COLOR_PALLETE.white};
  position: absolute;
  left: -220px;
  top: -30px;
  padding: 15px;
  width: 298px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  display: ${props => (props.isShow ? 'block' : 'none')};

  .component_kudos_post_card_title {
    font-family: ${Assets.FONT.MULI};
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 15px;
  }

  .component_kudos_post_card_items {
    /* height: 300px; */
    /* overflow-y: scroll; */
  }

  .component_kudos_post_card_item {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover {
      background-color: ${Assets.COLOR_PALLETE.grey2};
    }

    .component_kudos_image_wrapper {
      width: 44px;
      height: 44px;
      overflow: hidden;
    }

    img {
      width: 100%;
    }

    .component_kudos_post_card_item_right {
      width: 70%;
      margin-left: 15px;

      .component_kudos_post_card_item_title {
        font-family: ${Assets.FONT.MULI};
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #000000;
      }

      .component_kudos_post_card_item_desription {
        font-family: ${Assets.FONT.MULI};
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #a9a8a8;
      }
    }
  }
`;
