const initialData = {
  type: 0,
  content: '',
  images: [],
  videos: [],
  tags: [],
  files: [],
  privacy: 0,
  canSee: [],
  hashtag: [],
  sharedPost: {},
  event: undefined,
  location: {},
  task: undefined,
  poll: {
    duration: 0,
    question: '',
    options: ['', '']
  }
};

export default {
  initialData
};
