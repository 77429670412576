import React, { useState, useEffect } from 'react';
import {
  Select,
  AutoComplete,
  DatePicker,
  Input,
  Avatar,
  Icon,
  Tooltip
} from 'antd';

import style from './LearningTrackerPost.style';
import { TaskPostCard } from '..';
import ModalAddMentee from './ModalAddMentee';
import _ from 'lodash';
import Assets from '../../Assets';
import { AuthenticationService } from '../../Services';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const LearningTrackerPost = props => {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    modalDelete: false
  });
  const [timeUnit, setTimeUnit] = useState('Minute');
  const [duration, setDuration] = useState(null);
  const {
    post,
    selectedTask,
    handlePost,
    setTask,
    classroomMember,
    handleSelectMentee,
    dataMentee,
    listActivity,
    selectedActivity,
    handleSelectActivity,
    onSearchAutocomplete,
    onSelectAutocomplete,
    listCompetence,
    competencyValue,
    onChangeAutocomplete
  } = props;

  const _renderTitle = title => <div style={style.title}>{title}</div>;
  const userData = AuthenticationService.getUserData();

  const handleSubject = e => {
    const { value } = e.target;
    handlePost('subject', value);
  };

  const _renderSubject = () => {
    return (
      <Input.Group compact>
        <Input
          name="subject"
          type="text"
          value={post?.subject}
          style={style.subjectInput}
          onChange={handleSubject}
        />
      </Input.Group>
    );
  };

  const _renderSelect = (handleSelect, listActivity = []) => {
    const placeholderText = 'Pilih aktivitas';

    return (
      <Select
        style={style.select}
        onSelect={handleSelect}
        optionLabelProp="label"
        placeholder={placeholderText}
        value={selectedActivity?.activity}
      >
        {listActivity &&
          listActivity.map((data, idx) => {
            return (
              <Option
                key={idx}
                style={style.optionactivity}
                value={data.activity}
                label={data.activity}
              >
                {data.activity}
              </Option>
            );
          })}
      </Select>
    );
  };

  const _renderAutocomplete = name => {
    return (
      <AutoComplete
        value={competencyValue.value}
        // dataSource={listCompetence}
        style={style.autocomplete}
        onSelect={onSelectAutocomplete}
        onSearch={value => onSearchAutocomplete(value, name)}
        onChange={onChangeAutocomplete}
        placeholder="input here"
      >
        {listCompetence.length > 0 ? (
          listCompetence.map(val => {
            return (
              <Option key={val.id} value={val.title}>
                {val.title}
              </Option>
            );
          })
        ) : (
          <Option value={'empty'} disabled>
            Not found
          </Option>
        )}
      </AutoComplete>
    );
  };

  const _renderDetailBox = () => {
    return selectedTask ? (
      <div style={style.selectedBox}>
        <TaskPostCard
          img={userData.company_logo ? userData.company_logo : Assets.LOGO_WLB}
          name={selectedTask.name}
          description={selectedTask.description}
          deleteTask={() => {
            handlePost('task', {});
            setTask(null);
          }}
        />
      </div>
    ) : null;
  };

  const _renderDate = handle => {
    const dateNow = Date.now();
    return (
      <DatePicker
        value={moment(post.date ? post.date : dateNow)}
        style={{ width: '50%' }}
        onChange={handle}
      />
    );
  };

  const handleDate = val => {
    if (val && val._d) {
      const date = new Date(val._d).toISOString();
      handlePost('date', date);
    }
  };

  useEffect(() => {
    if (!_.isNull(duration)) {
      const { name, value } = duration;
      let newValue = Number(value);
      if (timeUnit === 'Hours') {
        handlePost(name, newValue * 60);
      } else {
        handlePost(name, newValue);
      }
    }
    // eslint-disable-next-line
  }, [timeUnit]);

  const handleTimeUnit = value => {
    setTimeUnit(value);
  };

  const handleDuration = e => {
    const { name, value } = e.target;
    let newValue = Number(value);
    setDuration({ name, value });
    if (timeUnit === 'Hours') {
      newValue = value * 60;
      handlePost(name, newValue);
    } else {
      handlePost(name, newValue);
    }
  };

  const handleDescription = e => {
    const { name, value } = e.target;
    handlePost(name, value);
  };

  const _renderDuration = () => {
    return (
      <Input.Group compact>
        <Input
          name="duration"
          style={style.durationInput}
          onChange={handleDuration}
          value={post?.duration}
        />
        <Select name="timeUnit" value={timeUnit} onSelect={handleTimeUnit}>
          <Option value="Hours">Jam</Option>
          <Option value="Minute">Menit</Option>
        </Select>
      </Input.Group>
    );
  };

  const _renderDescription = () => {
    return (
      <TextArea
        name="description"
        value={post.description ? post.description : ''}
        rows={5}
        onChange={handleDescription}
      />
    );
  };

  const _renderMentee = () => {
    return (
      <div style={style.menteeWrapper}>
        <Avatar
          size={40}
          icon={<Icon className="ant-upload-text" type="plus" />}
          style={style.btnPlus}
          onClick={() => setOpen(e => ({ ...e, open: true }))}
        />
        {dataMentee &&
          dataMentee.map((data, index) => {
            return (
              <Tooltip placement="topLeft" title={data.name} key={index}>
                <Avatar
                  size={40}
                  src={data.avatar}
                  style={style.menteeAvatar}
                />
              </Tooltip>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      {_renderTitle('Subjek')}
      {_renderSubject()}
      {_renderTitle('Aktivitas')}
      {_renderSelect(handleSelectActivity, listActivity)}
      {_renderTitle('Kompetensi')}
      {_renderAutocomplete('competence')}
      {_renderTitle('Tanggal')}
      {_renderDate(handleDate)}
      {_renderTitle('Durasi')}
      {_renderDuration()}
      {_renderTitle('Keterangan')}
      {_renderDescription()}
      {_renderTitle('Peserta')}
      {_renderMentee()}
      {_renderDetailBox()}
      <ModalAddMentee
        setOpen={setOpen}
        open={open.open}
        onClose={() => setOpen({ open: false })}
        data={classroomMember}
        handleSelectMentee={handleSelectMentee}
      />
    </div>
  );
};

export default LearningTrackerPost;
