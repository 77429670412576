import React from 'react';
import { Row, Col } from 'antd';
import {
  SidebarClassroomInfoBox,
  ReviewCardBoxContainer
} from '../../../Containers';
import { Mentorship } from '../../../Containers';

const ReviewScreenComponent = props => {
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarClassroomInfoBox {...props} />
        </Col>
        <Col span={12}>
          <ReviewCardBoxContainer {...props} />
        </Col>
        <Col span={6}>
          <Mentorship {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default ReviewScreenComponent;
