import { gql } from 'apollo-boost';

export const CANCEL_LEARNING_CONTRIB = gql`
  mutation($requestId: bigint!) {
    update_classroom_contrib_requests(
      where: { id: { _eq: $requestId } }
      _set: { status: 3 }
    ) {
      affected_rows
    }
  }
`;
