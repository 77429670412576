import React, { useState } from 'react';
import { CommentListWrapper, MoreComment, DeletedComment } from './style';
import { Avatar } from 'antd';
import moment from 'moment';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import Assets from '../../Assets';
import {
  CommentAction,
  DeleteModal,
  CommentBox,
  PostContent
  // ContentLink
} from '../../Components';
import { Link } from 'react-router-dom';
import { CommentService } from '../../Services';

function RenderCommentItem(props) {
  const {
    comment,
    profile,
    handleDelete,
    isAdmin,
    dataCompany,
    postType,
    logoCompany,
    showLikeComment
  } = props;
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isCommentLikeByUser, setIsCommentLike] = useState(
    showLikeComment ? comment.isLiked : false
  );
  const [totalLikeComment, setTotalLikeComment] = useState(
    showLikeComment ? comment.likedBy.length : 0
  );

  const [hasDeleted, setHasDeleted] = useState(
    comment.content === 'comment was deleted'
  );
  const commentLifeTime = moment(comment.date_added).fromNow();
  const username = comment.user.name;
  const user = comment.user;
  const userId = comment.userId;
  const avatar = comment.user.avatar
    ? comment.user.avatar
    : Assets.DEFAULT_PHOTO;
  const content = comment.content;
  const profileId = profile.id;
  const brandName = dataCompany?.main_profile?.[0]?.brand_name
    ? dataCompany?.main_profile?.[0]?.brand_name
    : '';
  const companyId = dataCompany?.main_profile?.[0]?.id
    ? dataCompany?.main_profile?.[0]?.id
    : '';
  const postedBy = isAdmin ? `posted by ${user.name}` : '';
  const peoplePosition = user?.people_work_placements?.[0]
    ?.company_employee_position?.name
    ? user?.people_work_placements?.[0]?.company_employee_position?.name +
      ' at '
    : '';
  const peopleCompany = user?.people_work_placements?.[0]?.company_profile
    ?.brand_name
    ? user?.people_work_placements?.[0]?.company_profile?.brand_name
    : '-';
  // const logoCompany =
  //   dataCompany?.main_profile?.[0]?.logo ?? Assets.DEFAULT_PHOTO;

  const likeComment = likeValue => {
    return CommentService.postLikeUnlikeComment(
      comment.userId,
      comment.postId,
      comment.id,
      likeValue
    );
  };

  const handleLikeComment = () => {
    if (!isLoadingLike) {
      setIsLoadingLike(true);
      if (isCommentLikeByUser) {
        likeComment(0)
          .then(() => {
            setIsCommentLike(!isCommentLikeByUser);
            setTotalLikeComment(totalLikeComment - 1);
          })
          .finally(() => setIsLoadingLike(false));
      } else {
        likeComment(1)
          .then(() => {
            setIsCommentLike(!isCommentLikeByUser);
            setTotalLikeComment(totalLikeComment + 1);
          })
          .finally(() => setIsLoadingLike(false));
      }
    }
  };

  const peopleProfileLength = (text, maxLength) => {
    if (text.length >= maxLength) {
      return text.substring(0, maxLength).trim() + '...';
    } else {
      return text;
    }
  };

  return (
    <CommentListWrapper>
      <div className="author">
        <div className="photo">
          <Avatar
            shape={postType === 'companyTimeline' ? 'square' : 'circle'}
            src={postType === 'companyTimeline' ? logoCompany : avatar}
            icon={'user'}
          />
        </div>
        <div className="info">
          <div className="name-container">
            <Link
              to={
                postType === 'companyTimeline'
                  ? `/company/${companyId}/post`
                  : `/profile/${userId}/detail`
              }
            >
              <span className="name">
                {postType === 'companyTimeline' ? brandName : username}
              </span>
            </Link>
            <span className="desc">
              {postType === 'companyTimeline'
                ? postedBy
                : peopleProfileLength(peoplePosition + peopleCompany, 70)}
            </span>
          </div>
          <div className="pos-container">
            {!hasDeleted && profileId === userId && (
              <div className="more-option">
                <CommentAction setVisibleModal={setDisplayDeleteModal} />
                <DeleteModal
                  closeModal={() => setDisplayDeleteModal(false)}
                  displayModal={displayDeleteModal}
                  modalTitle="Hapus komentar?"
                  modalContent="Apakah Anda yakin ingin menghapus komentar ini?"
                  handleDelete={() => {
                    handleDelete(comment);
                    setDisplayDeleteModal(false);
                    setHasDeleted(true);
                  }}
                  wording="Hapus"
                />
              </div>
            )}

            <div className="time">
              <span>{commentLifeTime}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        {showLikeComment && (
          <div className="comment-like">
            <span
              className={isCommentLikeByUser ? 'icon love-pink' : 'icon love'}
              onClick={() => handleLikeComment()}
            />
          </div>
        )}
        {/* {content.split(/\r?\n/).map((line, id) => {
          return <p key={id}> {line}</p>;
        })} */}
        {hasDeleted ? (
          <DeletedComment>
            <img src={Assets.ICON.BLOCK} alt="block icon" /> Komentar telah
            dihapus
          </DeletedComment>
        ) : (
          <PostContent content={content} />
        )}
        {showLikeComment && (
          <div className="total-like-comment">
            Menyukai ({totalLikeComment})
          </div>
        )}
        {/* <ContentLink content={content} /> */}
      </div>
    </CommentListWrapper>
  );
}

const CommentListComponent = props => {
  const {
    commentList,
    handleDelete,
    profile,
    contentInput,
    setContent,
    handleSubmit,
    hasNextPage,
    setFetchMoreComment,
    dataCompany,
    isAdmin,
    postType,
    avatar,
    showLikeComment = false
  } = props;

  const logoCompany =
    dataCompany?.main_profile?.[0]?.logo ?? Assets.DEFAULT_PHOTO;
  const avatarForCommentBox =
    postType === 'companyTimeline' ? logoCompany : avatar ?? profile.avatar;

  const CommentList = commentList.map(comment => {
    return (
      <RenderCommentItem
        key={comment.id}
        comment={comment}
        profile={profile}
        handleDelete={handleDelete}
        dataCompany={dataCompany}
        isAdmin={isAdmin}
        postType={postType}
        logoCompany={logoCompany}
        showLikeComment={showLikeComment}
      />
    );
  });

  const readMoreComment = () => {
    return (
      <MoreComment onClick={() => setFetchMoreComment(true)}>
        View more comment
      </MoreComment>
    );
  };

  return (
    <div>
      {!isObjectEmpty(commentList) && CommentList}
      <CommentBox
        avatar={avatarForCommentBox}
        contentInput={contentInput}
        handleChange={setContent}
        handleSubmit={handleSubmit}
      />
      {!isObjectEmpty(commentList) && hasNextPage && readMoreComment()}
    </div>
  );
};

export default CommentListComponent;
