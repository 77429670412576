import React, { useState, useEffect } from 'react';
import { ModalEdit } from '../';
import { Input, Button, Avatar } from 'antd';
import style, {
  FlexRow,
  FlexColumn,
  CheckboxStyled
} from './LearningTrackerPost.style';
import Assets from '../../Assets';

const EditEmergencyContact = props => {
  const { open, onClose, data, handleSelectMentee } = props;
  const defaultAvatar = Assets.DEFAULT_PHOTO;

  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    if (data) {
      setFiltered(data);
    }
  }, [data]);

  const handleSearch = e => {
    const { value } = e.target;

    const filteredData = data.filter(item =>
      item.global_user.name
        .toLowerCase()
        .trim()
        .includes(value)
    );
    setFiltered(filteredData);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalEdit sizeSmall title={'Mentee'} open={open} onClose={handleClose}>
      <div style={{ padding: '0px 22px', marginBottom: 8 }}>
        <Input
          placeholder="Cari mentee"
          size="large"
          style={{ marginBottom: 20 }}
          onChange={handleSearch}
        />
        {filtered &&
          filtered.map(val => {
            const position =
              val.global_user &&
              val.global_user.people_work_placements &&
              val.global_user.people_work_placements.length > 0 &&
              val.global_user.people_work_placements[0].company_job_profile
                ? val.global_user.people_work_placements[0].company_job_profile
                    .title
                : '';
            return (
              <FlexRow
                className="justifyBetween"
                key={val.global_user.id}
                style={style.userListWrapper}
              >
                <FlexRow>
                  <Avatar
                    size={40}
                    src={
                      val.global_user.avatar
                        ? val.global_user.avatar
                        : defaultAvatar
                    }
                    style={{ marginRight: 20 }}
                  />
                  <FlexColumn>
                    <span style={style.fontBold}>{val.global_user.name}</span>
                    <span>{position}</span>
                  </FlexColumn>
                </FlexRow>
                <CheckboxStyled
                  checked={val.checked}
                  onChange={e => handleSelectMentee(e, val)}
                />
              </FlexRow>
            );
          })}
        <div style={{ display: 'flex' }}>
          <div style={style.actionModalWrapper}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Batalkan
            </Button>
            <Button
              htmlType="submit"
              onClick={handleClose}
              style={style.btnSubmitMentee}
            >
              Kirim
            </Button>
          </div>
        </div>
      </div>
    </ModalEdit>
  );
};

export default EditEmergencyContact;
