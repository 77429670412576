import React from 'react';
import { Modal, Avatar } from 'antd';
import { PostBox } from '../../Containers';
import {
  PostAttachment,
  PostImage,
  PostVideo,
  PostTaggedPeople,
  PostContent,
  KudosPostCard,
  RenderLocation,
  PostPollItem
} from '..';
import { isEmpty } from 'lodash';
import { PostListWrapper } from '../../Containers/PostList/PostList.style';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import { ModalSharePostBoxWrap } from './ModalSharePostBox.style';
import EventContainer from '../../Containers/PostCard/Event.graphql';

const ModalSharePostBoxComponent = props => {
  const {
    display,
    dataShare,
    handleCancel,
    postType,
    restartPage,
    match,
    setLoading
  } = props;

  const DataShare = () => {
    const { user } = dataShare;
    return (
      <PostListWrapper>
        <div className="author">
          <div className="photo">
            <Avatar shape={'circle'} src={user.avatar} icon={'user'} />
          </div>
          <div className="info">
            <span className="name">{user.name}</span>
            <span className="desc">
              {user?.people_work_placements?.[0]?.company_employee_position
                ?.name
                ? user?.people_work_placements?.[0]?.company_employee_position
                    ?.name + ' at '
                : ''}
              {user?.people_work_placements?.[0]?.company_profile?.brand_name
                ? user?.people_work_placements?.[0]?.company_profile?.brand_name
                : '-'}
            </span>
            <span className="desc">
              {user.follower ? user.follower + ' Follower' : null}
            </span>
          </div>
        </div>

        <div className="content">
          {dataShare.event !== null && (
            <EventContainer eventId={dataShare.event} />
          )}

          {!dataShare.type !== 4 && <PostContent content={dataShare.content} />}

          {!isEmpty(dataShare.poll) && (
            <PostPollItem
              itemPost={dataShare}
              // handleVote={handleVote}
              // profileId={profileId}
              // handleUndoVote={handleUndoVote}
              sharedPost
              isSharing
            />
          )}

          {!isEmpty(dataShare.images) && (
            <PostImage images={dataShare.images} />
          )}
          {!isEmpty(dataShare.videos) && (
            <PostVideo videos={dataShare.videos} />
          )}
          {!isEmpty(dataShare.files) && (
            <PostAttachment file={dataShare.files} />
          )}

          {!isEmpty(dataShare?.kudos) && (
            <KudosPostCard
              img={dataShare?.kudos?.url_image}
              kudosName={dataShare?.kudos?.name}
              name={dataShare?.kudos?.to}
              description={dataShare?.kudos?.desc}
            />
          )}
          {!isEmpty(dataShare.location) && (
            <RenderLocation coordinates={dataShare.location} />
          )}
          {isObjectEmpty(dataShare.tags) ? null : (
            <PostTaggedPeople taggedUsers={dataShare.tags} />
          )}
        </div>
      </PostListWrapper>
    );
  };

  return (
    <ModalSharePostBoxWrap>
      <Modal
        title="Bagikan"
        visible={display}
        footer={null}
        closable={true}
        onCancel={handleCancel}
      >
        <PostBox
          sharedPost={dataShare}
          postType={postType}
          restartPage={restartPage}
          match={match}
          setLoading={setLoading}
        >
          <DataShare />
        </PostBox>
      </Modal>
    </ModalSharePostBoxWrap>
  );
};

export default ModalSharePostBoxComponent;
