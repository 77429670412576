import React from 'react';

import AccountSettingsComponent from './AccountSettingsPage.component';
import Privacy from './Privacy';

const accountSettingsMenubar = [
  {
    renderComponent: props => <AccountSettingsComponent {...props} />,
    name: 'Akun',
    path: '/account-settings'
  },
  {
    renderComponent: props => <Privacy {...props} />,
    name: 'Pribadi',
    path: '/account-settings/privacy'
  }
];
const defaultMenuBar = 'Akun';

export default {
  accountSettingsMenubar,
  defaultMenuBar
};
