import gql from 'graphql-tag';

const GET_LIST_SHIPPING_SERVICE = gql`
  {
    global_shipping_providers {
      id
      name
      logo
      service_type
    }
  }
`;

const GET_VENDOR_SHIPPING_SERVICE = gql`
  query($vendor: uuid) {
    company_shipping_availables(where: { company: { _eq: $vendor } }) {
      courier_services
    }
  }
`;

const UPDATE_VENDOR_SHIPPING_SERVICE = gql`
  mutation updateCourier($courier_services: JSON) {
    updateShipping(courier_services: $courier_services) {
      success
    }
  }
`;

export {
  GET_LIST_SHIPPING_SERVICE,
  GET_VENDOR_SHIPPING_SERVICE,
  UPDATE_VENDOR_SHIPPING_SERVICE
};
