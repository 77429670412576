import React from 'react';
import { Wrapper } from './style';
import DateTime from '../../Utils/dateTime';
// import IMAGE from '../../Assets/images';

const Index = props => {
  const { contents } = props;

  return (
    <Wrapper>
      <div
        style={{
          backgroundImage: `url(${contents[0]?.featured_image?.medium})`
        }}
      >
        <div className="backdrop" />
        <div className="content">
          <div className="date">
            {DateTime.dateStringFormatter(contents[0]?.post_date)}
          </div>
          <div className="title">{contents[0]?.title}</div>
          <div className="description">
            {contents[0]?.content?.replace(/<[^>]*>?/gm, '')?.length > 113
              ? contents[0]?.content?.replace(/<[^>]*>?/gm, '')?.slice(0, 113) +
                '...'
              : contents[0]?.content?.replace(/<[^>]*>?/gm, '')}
          </div>
        </div>
      </div>
      <section>
        <div
          style={{
            backgroundImage: `url(${contents[1]?.featured_image?.medium})`
          }}
        >
          <div className="backdrop" />
          <div className="content">
            <div className="date">
              {DateTime.dateStringFormatter(contents[1]?.post_date)}
            </div>
            <div className="title">{contents[1]?.title}</div>
            <div className="description">
              {contents[1]?.content?.replace(/<[^>]*>?/gm, '')?.length > 77
                ? contents[1]?.content
                    ?.replace(/<[^>]*>?/gm, '')
                    ?.slice(0, 77) + '...'
                : contents[1]?.content?.replace(/<[^>]*>?/gm, '')}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${contents[2]?.featured_image?.medium})`
          }}
        >
          <div className="backdrop" />
          <div className="content">
            <div className="date">
              {DateTime.dateStringFormatter(contents[2]?.post_date)}
            </div>
            <div className="title">{contents[2]?.title}</div>
            <div className="description">
              {contents[2]?.content?.replace(/<[^>]*>?/gm, '')?.length > 77
                ? contents[2]?.content
                    ?.replace(/<[^>]*>?/gm, '')
                    ?.slice(0, 77) + '...'
                : contents[2]?.content?.replace(/<[^>]*>?/gm, '')}
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default Index;
