import { gql } from 'apollo-boost';

export const GET_REQUESTED_MEMBERS = gql`
  query($classroom_id: uuid!, $limit: Int, $offset: Int) {
    classroom_member_requests(
      where: { classroom_id: { _eq: $classroom_id }, status: { _eq: 0 } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      status
      global_user {
        people_work_placements {
          company_job_profile {
            title
          }
        }
        id
        avatar
        name
        email
      }
      created_at
    }
  }
`;

export const GET_MEMBERS = gql`
  query($classroom_id: uuid_comparison_exp!, $limit: Int, $offset: Int) {
    classroom_members(
      where: { classroom_id: $classroom_id }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      is_admin
      is_mentor
      global_user {
        id
        avatar
        name
        email
      }
      created_at
    }
  }
`;

export const GET_JOINED_CLASSROOM = gql`
  query($classroomId: uuid!, $userId: uuid) {
    classrooms_by_pk(id: $classroomId) {
      id
      classroom_members(where: { global_user: { id: { _eq: $userId } } }) {
        global_user {
          id
          name
        }
      }
    }
  }
`;

export const GET_CONTRIB_REQUEST = gql`
  query($limit: Int, $offset: Int, $classroomId: uuid) {
    classroom_contrib_requests_aggregate(
      where: { classroom_id: { _eq: $classroomId } }
    ) {
      aggregate {
        count
      }
    }
    classroom_contrib_requests(
      limit: $limit
      offset: $offset
      where: {
        classroom_id: { _eq: $classroomId }
      }
      order_by:[{date_added:desc}]
    ) {
      id
      status
      classroom {
        id
        name
        avatar_url
      }
      contrib: global_user {
        id
        name
        contributor_label
        avatar
        people_work_placements {
          company_job_profile {
            id
            position
            title
            id
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      issued_by: globalUserByIssuedByUserId {
        id
        name
        contributor_label
        avatar
        people_work_placements {
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
    }
  }
`;
