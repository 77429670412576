import React, { useState, useEffect } from 'react';
import BlockedComponent from './Blocked.component';
// import Assets from '../../../Assets';
import { UserProfile } from '../../../Services';
import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';
import { checkNextPage } from '../../../Utils/CommonUtils';
import { isEmpty } from 'lodash';
import { message } from 'antd';

const BlockedContainer = () => {
  const [usersBlocked, setUsersBlocked] = useState([]);
  const [searchUser, setSearchUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [hasBlocked, setHasBlocked] = useState(false);
  const [isBottom, setIsBottom] = useInfiniteScroll('blocked-user');
  const [blockedPage, setBlockedPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    const maxResults = 10;
    if (hasNextPage && !isLoading && (isBottom || blockedPage === 1)) {
      setIsLoading(true);
      UserProfile.getListBlockedUsers(blockedPage, maxResults)
        .then(res => {
          const { per_page, total, data } = res;
          const isNextPage = checkNextPage(total, blockedPage, per_page);
          setHasNextPage(isNextPage);
          if (isNextPage) {
            setBlockedPage(blockedPage + 1);
          }
          if (blockedPage === 1) {
            setUsersBlocked(data);
          } else {
            setUsersBlocked(usersBlocked.concat(data));
          }
        })
        .finally(() => {
          setIsLoading(false);
          setIsBottom(false);
        });
    }
  }, [
    hasBlocked,
    hasNextPage,
    isBottom,
    blockedPage,
    usersBlocked,
    isLoading,
    setIsBottom
  ]);

  const searchHandler = keyword => {
    let searchParam = isEmpty(keyword) ? '' : keyword.trim();
    setSearchUser([]);
    setIsFetching(true);
    UserProfile.getUserSearch(searchParam).then(res => {
      setSearchUser(res.data);
      setIsFetching(false);
    });
  };

  const changeBlockHandler = value => {
    setIsLoading(true);
    UserProfile.addBlockedUser(value.key).then(res => {
      setIsLoading(false);
      setHasBlocked(!hasBlocked);
      setHasNextPage(true);
      setBlockedPage(1);
      // hasBlocked for trigger useEffect again
    });
  };

  const filterUsers = blockedId => {
    UserProfile.deleteBlockedUser(blockedId).then(() => {
      setUsersBlocked(users =>
        users.filter(u => u.blocked_user_id !== blockedId)
      );
      message.success('Unblocked success');
    });
  };
  return (
    <BlockedComponent
      usersBlocked={usersBlocked}
      searchUser={searchUser}
      searchHandler={searchHandler}
      changeBlockHandler={changeBlockHandler}
      filterUsers={filterUsers}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};

export default BlockedContainer;
