import styled from 'styled-components';
import Assets from '../../Assets';

export const VendorListWrap = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  background-color: ${Assets.COLOR_PALLETE.white};
  min-height: 90px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 30px;
  margin-bottom: 10px;
  cursor: pointer;

  .photo{
    margin-right: 60px;
    display: inline-block;
    img{
      height: 60px;
      border-radius: 100%;
    }
  }
  .info{
    display: inline-block;
    p{
      margin: 0;
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.grey};
      font-family: ${Assets.FONT.MULI};
      &:first-child{
        font-size: 18px;
        color: ${Assets.COLOR_PALLETE.black};
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
      }
    }
  }
  .groupBtn{
    width: 30%;
    text-align: right;
    padding: 2% 0px;
  }
`;

export default {
  Waiting: {
    backgroundColor: '#ffa000',
    borderColor: '#ffa000'
  },
  Reject: {
    backgroundColor: '#ef4d5e',
    borderColor: '#ef4d5e'
  }
}