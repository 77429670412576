import React from 'react';
import Assets from '../../../Assets';
import { ThypoStyled } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { TextSecondary, TextStandard, TextGrey } from '../UserProfileCV.style';
import moment from 'moment';

export default function Education({
  title,
  company,
  degree,
  date,
  location,
  score,
  desc,
  data
  // certificate,
}) {
  const formatDate = date => moment(date).format('MMM YYYY');
  const generateLocation = (city, province, country) => {
    if (country && city && province) {
      return `${city}, ${province}, ${country}`;
    } else if (city && province) {
      return `${city}, ${province}`;
    } else if (city) {
      return `${city}`;
    } else if (province) {
      return `${province}`;
    } else if (country) {
      return `${country}`;
    }
    return '-';
  };
  const generateDegree = (degree, faculty) => {
    if (degree && faculty) {
      return `${degree} Degree, ${faculty}`;
    } else if (!degree) {
      return `${faculty}`;
    } else {
      return '-';
    }
  };
  const generateDateWorkingStatus = (start_date, end_date) => {
    let e_date = moment(end_date);
    if (start_date) {
      return `${formatDate(start_date)} - ${
        end_date
          ? formatDate(end_date) +
            ` (` +
            deferentDate(start_date, end_date) +
            ')'
          : `present (` + moment(start_date).toNow(true) + ')'
      }`;
    }
    return '-';
  };
  const deferentDate = (start_date, end_date) => {
    const a = moment(end_date);
    const b = moment(start_date);
    if (a.diff(b, 'years') > 0) {
      if (a.diff(b, 'years') === 1) {
        return 'in a year';
      } else {
        return `in ${a.diff(b, 'years')} year`;
      }
    } else if (a.diff(b, 'months') > 0) {
      return `in ${a.diff(b, 'months')} month`;
    } else {
      return moment(start_date).to(end_date);
    }
  };
  return (
    <div style={{ marginBottom: 35, overflow: 'visible' }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.grey}
        fontStyle="italic"
      >
        {generateLocation(
          data?.education_fields?.city?.name,
          data?.education_fields?.province?.name,
          data?.education_fields?.country
        )}
      </ThypoStyled>
      <ThypoStyled
        style={{ textTransform: 'capitalize' }}
        fontSize="12px"
        margin="6px 0px"
        color={Assets.COLOR_PALLETE.blue_link}
      >
        {generateDegree(
          data.degree && data.degree.name,
          data.faculty && data.faculty.name
        )}
      </ThypoStyled>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.grey}
        fontStyle="italic"
      >
        {generateDateWorkingStatus(data.start_date, data.end_date)}
      </ThypoStyled>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.black}
        fontStyle="italic"
      >
        {`GPA : ${data?.education_fields?.gpa ?? '-'}`}
      </ThypoStyled>
      <TextStandard>{desc}</TextStandard>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.blue_link}
        fontStyle="italic"
        style={{ padingLeft: '10px' }}
      >
        {`Certificate ID ${data?.education_fields?.ceritificate_id ?? '-'}`}
      </ThypoStyled>
      {/* <TextGrey>{date}</TextGrey>
      <TextGrey>{location}</TextGrey>
      <TextGrey>{score}</TextGrey>
      */}
      {/* <TextGrey>{certificate}</TextGrey> */}
    </div>
  );
}
