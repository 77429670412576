import React from 'react';
import {
  WrapperDetailSection,
  WrapperContentSection,
  ButtonEdit
} from './DetailUserSection.style';
import { Icon } from 'antd';

export default function DetailUserSection(props) {
  const {
    title,
    action = false,
    onClickAction,
    noTitleLine = false,
    ...content
  } = props;
  const _buttonEdit = () => (
    <ButtonEdit onClick={onClickAction}>
      <Icon
        style={action === 'plus' ? { color: '#039be5' } : null}
        type={action}
      />
    </ButtonEdit>
  );

  const _wrapper = () => (
    <WrapperDetailSection>
      <div
        style={
          noTitleLine
            ? { borderBottom: 'none', padding: '28px 0px 10px 0px' }
            : null
        }
        className="header"
      >
        <h3 className="title">{title}</h3>
        {action ? _buttonEdit() : null}
      </div>
      <WrapperContentSection {...content} />
    </WrapperDetailSection>
  );

  return (
    <div>
      <div>{_wrapper()}</div>
    </div>
  );
}
