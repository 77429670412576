import React from 'react';
import { Row, Col } from 'antd';
import {
  SidebarClassroomInfoBox,
  ClassroomEditPost,
  Mentorship
} from '../../../Containers';

const EditPostScreenComponent = props => {
  const title = 'Edit Learning Log Post';
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarClassroomInfoBox {...props} />
        </Col>
        <Col id={'classroom-post'} span={12}>
          <ClassroomEditPost title={title} {...props} />
        </Col>
        <Col span={6}>
          <Mentorship {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default EditPostScreenComponent;
