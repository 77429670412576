import React, { useState } from 'react';
import { Button, Popover } from 'antd';
import Icon from 'antd/es/icon';
import { ActionButtons } from './TableAction.style';

const TableItemActionContainer = props => {
  const {
    typeForm,
    //Location
    record,
    setDisplayForm,
    setDisplayDeleteModal,
    setSelectedRecord,
    setFormTitle,
    //Showcase
    handleEditModalVisibility,
    handleDeleteModalVisibility,
    setSelectedRecordId,
    dataSource
  } = props;

  const [displayAction, setDisplayAction] = useState(false);

  const deleteRecord = item => {
    setFormTitle('Delete Item');
    setDisplayAction(false);
    setSelectedRecord(item);
    setDisplayDeleteModal(true);
  };

  const showEditForm = item => {
    setFormTitle('Edit Location');
    setDisplayAction(false);
    setSelectedRecord(item);
    setDisplayForm(true);
  };

  const showcaseEdit = id => {
    setSelectedRecordId(id);
    setDisplayAction(false);
    handleEditModalVisibility();
  };

  const showcaseDelete = id => {
    setSelectedRecordId(id);
    setDisplayAction(false);
    handleDeleteModalVisibility();
  };

  const _renderPopoverLocation = () => {
    return (
      <Popover
        placement="bottomRight"
        trigger="click"
        visible={displayAction}
        onVisibleChange={() => setDisplayAction(!displayAction)}
        content={
          <ActionButtons>
            <Button onClick={() => showEditForm(record)}>Edit</Button>
            <Button onClick={() => deleteRecord(record)}>Delete</Button>
          </ActionButtons>
        }
      >
        <Icon
          onClick={() => setDisplayAction(!displayAction)}
          type="ellipsis"
        />
      </Popover>
    );
  };
  const _renderPopoverShowcase = () => (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={displayAction}
      onVisibleChange={() => setDisplayAction(!displayAction)}
      content={
        <ActionButtons>
          <Button onClick={() => showcaseEdit(dataSource.key)}>Edit</Button>
          {dataSource.product === 0 && (
            <Button onClick={() => showcaseDelete(dataSource.key)}>
              Delete
            </Button>
          )}
        </ActionButtons>
      }
    >
      <Icon onClick={() => setDisplayAction(!displayAction)} type="ellipsis" />
    </Popover>
  );

  return (
    <>
      {typeForm === 'showcase' ? _renderPopoverShowcase() : null}
      {typeForm === 'location' ? _renderPopoverLocation() : null}
    </>
  );
};

export default TableItemActionContainer;
