import styled from 'styled-components';
import Constants from '../../../Config/Constants';
import Assets from '../../../Assets';

export const Wrapper = styled.div`
  padding: 15px;
  font-family: ${Assets.FONT.MULI};
  section{
    margin-bottom: 30px;
  }
  h1 {
    /* font-family: ${Assets.FONT.MULI_BOLD}; */
    font-size: 18px;
    color: ${Constants.COLORS.DARK_BLUE};
  }
  p {
    color: ${Constants.COLORS.GREY};
    font-size: 12px;
    max-width: 60%;
  }
`;
