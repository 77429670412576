import gql from 'graphql-tag';

export const GET_LIST_INSURANCE = gql`
  query($user: uuid) {
    government: people_identities(
      where: {
        user: { _eq: $user }
        deletedAt: { _is_null: true }
        global_insurance_list: {
          id: { _is_null: false }
          type: { _eq: "government" }
        }
      }
      order_by: [{ date_added: desc_nulls_last }]
    ) {
      id
      type
      global_insurance_list {
        type
        name
      }
      identity_fields
    }
    premium: people_identities(
      where: {
        user: { _eq: $user }
        deletedAt: { _is_null: true }
        global_insurance_list: {
          id: { _is_null: false }
          type: { _eq: "premium" }
        }
      }
      order_by: [{ date_added: desc_nulls_last }]
    ) {
      id
      type
      global_insurance_list {
        type
        name
      }
      identity_fields
    }
  }
`;

export const GET_LIST_FAMILY = gql`
  query($user: uuid) {
    people_dependants(
      where: { user: { _eq: $user }, deletedAt: { _is_null: true } }
      order_by: [{ id: desc }]
    ) {
      id
      name
      relationship
    }
  }
`;
export const ADD_INSURANCE = gql`
  mutation($objects: [people_identities_insert_input!]!) {
    insert_people_identities(objects: $objects) {
      affected_rows
    }
  }
`;
export const UPDATE_INSURANCE = gql`
  mutation update_people_identities(
    $user: uuid!
    $type: String!
    $id: String!
    $changes: people_identities_set_input!
  ) {
    update_people_identities(
      where: {
        user: { _eq: $user }
        type: { _eq: $type }
        id: { _eq: $id }
        deletedAt: { _is_null: true }
      }
      _set: $changes
    ) {
      affected_rows
    }
  }
`;
export const DELETE_INSURANCE = gql`
  mutation($id: String!) {
    delete_people_identities(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const GET_LIST_INSURANCE_NAME = gql`
  query getInsuranceList($type_filter: String) {
    global_insurance_list(
      where: {
        is_verified: { _eq: true }
        deletedAt: { _is_null: true }
        type: { _eq: $type_filter }
      }
      order_by: [{ id: asc }]
    ) {
      id
      enum
      type
      name
    }
  }
`;
export const INSURANCE_CHECKER = gql`
  query CheckerInsurance($id: String!, $user: uuid!, $type: String!) {
    people_identities(
      where: {
        id: { _eq: $id }
        user: { _eq: $user }
        type: { _eq: $type }
        deletedAt: { _is_null: false }
      }
    ) {
      id
    }
  }
`;

export const CHECK_DUPLICATED_INSURANCE = gql`
  query CheckerInsurance($id: String!, $type: String!, $user: uuid) {
    people_identities(
      where: {
        _or: [
          { id: { _eq: $id }, type: { _neq: $type }, user: { _eq: $user } }
          {
            id: { _eq: $id }
            user: { _neq: $user }
            global_insurance_list: { type: { _eq: "government" } }
          }
        ]
      }
    ) {
      id
    }
  }
`;

export const ADD_WITH_UPDATE_QUERY = gql`
  mutation update_people_identities(
    $id: String
    $changes: people_identities_set_input
  ) {
    update_people_identities(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`;
