import styled from 'styled-components';
import Assets from '../../../Assets';
import Constant from '../../../Config/Constants';

export const VendorBalanceTabs = styled.div`
  position: relative;

  .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 32px;
  }
  .ant-tabs-nav-wrap {
    background-color: ${Assets.COLOR_PALLETE.white};
  }
  .ant-tabs {
    color: ${Assets.COLOR_PALLETE.grey};
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${Assets.COLOR_PALLETE.black};
  }

  .ant-tabs-tabpane {
    padding: 0 4.3%;
  }
`;

export const VendorBalanceWrap = styled.div`
  position: relative;
  background-color: ${Assets.COLOR_PALLETE.white};
  padding: 10px;
  box-shadow: ${Constant.BOX_SHADOW};
  font-family: ${Assets.FONT.MULI};
  .balance-total {
    margin-left: 4.3%;
    font-family: ${Assets.FONT.MULI_BOLD};
    font-size: 32px;
    color: #373e41;
  }
`;
