import {
  BankAccountCard,
  BankAccountItemAction,
  BankAccountItemInformation
} from '../BankAccountListCard/BankAccountListCard.style';
import { Button } from 'antd';
import React from 'react';

const BankAccountCardComponent = props => {
  const { bankAccount, handleEditBankAccount, handleDeleteBankAccount } = props;
  return (
    <BankAccountCard key={bankAccount.id}>
      <BankAccountItemInformation>
        <span className={'bank-name'}>
          {bankAccount?.global_bank?.name ?? ''}
        </span>
        <span className={'user-name'}>{bankAccount?.account_name ?? ''}</span>
        <span className={'account-number'}>
          {bankAccount?.account_number ?? ''}
        </span>
      </BankAccountItemInformation>
      <BankAccountItemAction>
        <Button
          type="primary"
          onClick={() => handleEditBankAccount(bankAccount)}
        >
          Ubah Rekening Bank
        </Button>
        <Button
          type="danger"
          ghost
          onClick={() => handleDeleteBankAccount(bankAccount)}
        >
          Hapus Rekening Bank
        </Button>
      </BankAccountItemAction>
    </BankAccountCard>
  );
};

export default BankAccountCardComponent;
