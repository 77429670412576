import React from 'react';
import { Avatar } from 'antd';
import {
  Description,
  JobDetails,
  Name,
  ResultWrapper,
  InfoWrapper,
  AvatarWrapper
} from './style';

const PeopleResultComponent = props => {
  const { learning } = props;
  return (
    <ResultWrapper>
      <AvatarWrapper>
        <Avatar shape={'square'} size={50} src={learning.thumbnail} />
      </AvatarWrapper>
      <InfoWrapper>
        <a
          href={process.env.REACT_APP_LEARNING_REDIRECT + learning.slug}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <Name> {learning.title}</Name>
        </a>
        <Description>
          <JobDetails>{learning.topic}</JobDetails>
        </Description>
      </InfoWrapper>
    </ResultWrapper>
  );
};

export default PeopleResultComponent;
