import { Form, Input, message, Select } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { ModalEdit } from '../../Components';
import { AuthenticationService } from '../../Services';
import {
  Attachment,
  ButtonFrom,
  TitleField,
  AttachmentItem
} from '../../SharedComponents';
import { checkFilesType } from '../UserProfileMedicalHistory/helperMedicalHistory';
import { MONTH } from './constantPsychological';
import {
  ADD_PSYCHOLOGICAL_TEST,
  UPDATE_PSYCHOLOGICAL_TEST
} from './UserPsychological.graphql';

const generateDate = date => {
  if (date) {
    return date.split('-');
  } else {
    return null;
  }
};

const getListYear = () => {
  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }
  return optionYear;
};

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const AddEditPsychologicalTestComponent = ({
  actionType,
  open,
  onClose,
  form,
  setOpen,
  data,
  profileId,
  refetch
}) => {
  const {
    getFieldDecorator,
    // setFieldsValue,
    validateFields,
    resetFields
    // getFieldValue,
  } = form;

  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [attachment, setAttachment] = useState([]);

  const { Option } = Select;
  const handleClose = () => {
    onClose();
    resetFields();
    setAttachment([]);
  };

  const [addPsychologicaltest] = useMutation(ADD_PSYCHOLOGICAL_TEST, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [updatePsychologicaltest] = useMutation(UPDATE_PSYCHOLOGICAL_TEST, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  useEffect(() => {
    if (actionType === 'edit' && data) {
      setAttachment(data?.attachments ?? []);
    } else {
      setAttachment([]);
    }
  }, [data, setAttachment, actionType]);

  const handleDelete = () => {
    setOpen(e => ({ ...e, modalDelete: true, open: false }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const generateDate = (day, month, year) => {
          if (day.length === 1) {
            return `${year}-${month}-0${day}`;
          } else {
            return `${year}-${month}-${day}`;
          }
        };
        if (actionType === 'add') {
          const submit = {
            objects: {
              // user_id: profileId,
              name: values.name,
              subject: values.subject,
              institution: values.institution,
              test_date: moment(
                generateDate(values.day, values.month, values.year)
              ).format('ll'),
              test_cost: values.test_cost,
              test_score: values.test_score,
              test_score_range: values.test_score_range,
              test_result: values.test_result,
              recommendation_result: values.recommendation_result,
              general_conclusion: values.general_conclusion,
              purpose: values.purpose,
              attachments: attachment
            }
          };
          addPsychologicaltest({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil menambahkan asesmen`);
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan asesmen, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          const submit = {
            id: data.id,
            objects: {
              user_id: profileId,
              name: values.name,
              subject: values.subject,
              institution: values.institution,
              test_date: moment(
                generateDate(values.day, values.month, values.year)
              ).format('ll'),
              test_cost: values.test_cost,
              test_score: values.test_score,
              test_score_range: values.test_score_range,
              test_result: values.test_result,
              recommendation_result: values.recommendation_result,
              general_conclusion: values.general_conclusion,
              purpose: values.purpose,
              attachments: attachment
            }
          };
          updatePsychologicaltest({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil mengubah asesmen`);
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal mengubah asesmen, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  const TOKEN = AuthenticationService.getTokenAuth();

  const uploadAttachment = async e => {
    const inputFile = document.getElementById('upload-psycological-test');
    setErrorFile(false);
    setErrorSize(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else {
      tempFile.forEach(val => {
        setLoadingUpload(true);
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            inputFile.value = '';
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            inputFile.value = '';
            setLoadingUpload(false);
          });
      });
    }
  };

  function handleChange(val, e) {
    if (e === 'upload-attachment') {
      uploadAttachment(val);
    }
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder, style } = res;
    if (type === 'select') {
      return (
        <Select
          size="large"
          style={{ width: '100%' }}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        >
          {option &&
            option.map((res, i) => {
              const dataTestIdYear = `${fieldName}-${res}`;
              const dataTestMonth = `${fieldName}-${res.name}`;
              return (
                <Option
                  key={i}
                  value={
                    fieldName === 'year' || fieldName === 'day' ? res : res.id
                  }
                  data-testId={
                    fieldName === 'year' || fieldName === 'day'
                      ? dataTestIdYear
                      : dataTestMonth
                  }
                >
                  {fieldName === 'year' || fieldName === 'day' ? res : res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  // Psychological Test berubah nama jadi Asesmen
  const listFields = [
    {
      labelName: 'Nama Asesmen',
      fieldName: 'name',
      type: 'text',
      fieldValue: data?.name,
      placeholder: 'Tambahkan nama asesmen',
      isRequired: true
    },
    {
      labelName: 'Pokok Penilaian',
      fieldName: 'subject',
      type: 'text',
      fieldValue: data?.subject,
      placeholder: 'Tambahkan pokok penilaian',
      isRequired: true
    },
    {
      labelName: 'Penyelenggara',
      fieldName: 'institution',
      type: 'text',
      fieldValue: data?.institution,
      placeholder: 'Tambahkan penyelenggara',
      isRequired: true
    }
  ];
  const listFieldsDate = [
    {
      fieldName: 'day',
      type: 'select',
      fieldValue: data?.test_date ? generateDate(data.test_date)[2] : undefined,
      placeholder: 'Tanggal',
      isRequired: true,
      option: optionDay
    },
    {
      fieldName: 'month',
      type: 'select',
      fieldValue: data?.test_date ? generateDate(data.test_date)[1] : undefined,
      placeholder: 'Bulan',
      isRequired: true,
      option: MONTH
    },
    {
      fieldName: 'year',
      type: 'select',
      fieldValue: data?.test_date ? generateDate(data.test_date)[0] : undefined,
      placeholder: 'Tahun',
      isRequired: true,
      option: getListYear()
    }
  ];
  const listFields2 = [
    {
      labelName: 'Biaya Asesmen',
      fieldName: 'test_cost',
      type: 'text',
      fieldValue: data?.test_cost,
      placeholder: 'Tambahkan biaya asesmen',
      isRequired: true
    },
    {
      labelName: 'Skor Asesmen',
      fieldName: 'test_score',
      type: 'number',
      fieldValue: data?.test_score,
      placeholder: 'Tambahkan skor asesmen',
      isRequired: true
    },
    {
      labelName: 'Rentang Skor Asesmen',
      fieldName: 'test_score_range',
      type: 'text',
      fieldValue: data?.test_score_range,
      placeholder: 'Tambahkan rentang skor asesmen',
      isRequired: true
    },
    {
      labelName: 'Hasil Asesmen',
      fieldName: 'test_result',
      type: 'text',
      fieldValue: data?.test_result,
      placeholder: 'Tambahkan hasil asesmen',
      isRequired: false
    },
    {
      labelName: 'Hasil Rekomendasi',
      fieldName: 'recommendation_result',
      type: 'text',
      fieldValue: data?.recommendation_result,
      placeholder: 'Tambahkan hasil rekomendasi',
      isRequired: false
    },
    {
      labelName: 'Kesimpulan Umum',
      fieldName: 'general_conclusion',
      type: 'text',
      fieldValue: data?.general_conclusion,
      placeholder: 'Tambahkan kesimpulan umum',
      isRequired: false
    },
    {
      labelName: 'Tujuan',
      fieldName: 'purpose',
      type: 'text',
      fieldValue: data?.purpose,
      placeholder: 'Tambahkan tujuan',
      isRequired: false
    }
  ];

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
  };

  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Asesmen`}
      open={open}
      onClose={handleClose}
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Tanggal Asesmen</TitleField>}
            style={{
              marginBottom: 0
            }}
            required
          >
            {listFieldsDate.length > 0 &&
              listFieldsDate.map((res, i) => {
                return (
                  <Form.Item
                    key={`${i}-${res.fieldName}`}
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                      width: '30%',
                      marginRight: 16
                    }}
                  >
                    {getFieldDecorator(res.fieldName, {
                      initialValue: res.fieldValue,
                      rules: rule(res.isRequired)
                    })(_switchComponentToRender(res))}
                  </Form.Item>
                );
              })}
          </Form.Item>
        </div>
        {listFields2.length > 0 &&
          listFields2.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Attachment
            handleChangeFile={e => handleChange(e, 'upload-attachment')}
            id="upload-psycological-test"
            disabled={attachment.length > 0 ?? false}
          />
          {loadingUpload && <span>Mengunggah</span>}
          {errorFile && (
            <span>Ada jenis lampiran yang tidak diperbolehkan</span>
          )}
          {errorSize && (
            <span>Ukuran lampiran yang diunggah tidak boleh melebihi 25MB</span>
          )}
          {attachment &&
            attachment.map((res, i) => {
              return (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              );
            })}
        </div>
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditPsychologicalTest = Form.create({
  name: 'Add_Edit_Psychological_Test'
})(AddEditPsychologicalTestComponent);

export default AddEditPsychologicalTest;
