import styled from 'styled-components';
import Assets from '../../Assets';

export const KudosPopupWrapper = styled.div`
  cursor: context-menu;
  display: ${props => (props.modalVisibility ? 'bloc' : 'none')};

  .component_kudos_content {
    background-color: ${Assets.COLOR_PALLETE.white};
    display: block;
    position: fixed;
    z-index: 10001;
    top: 70px;
    left: 33%;
    width: 510px;
    max-height: 600px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .component_kudos_header {
    font-family: ${Assets.FONT.MULI};
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black2};
    border-bottom: solid 1px rgba(0, 0, 0, 0.16);
    position: relative;
    width: 100%;
    white-space: nowrap;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-left: 15px;
  }

  .component_kudos_header::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .component_kudos_header_text {
    padding-top: 5px;
  }
  .component_kudos_header_item {
    /* display: inline-block; */
    /* padding: 15px 5px; */
    height: 35px;
    margin-left: 15px;
    cursor: pointer;

    &.selected {
      border-bottom: solid 3px #419bf9;
    }

    .component_kudos_header_count {
      display: inline-block;
      margin-left: 5px;
    }

    .component_kudos_header_image {
      height: 26px;
      width: 26px;
      overflow: hidden;
      border-radius: 90px;
      color: ${Assets.COLOR_PALLETE.grey};
      background-color: ${Assets.COLOR_PALLETE.grey};
    }

    img {
      width: 100%;
      background-color: ${Assets.COLOR_PALLETE.grey};
    }
  }

  .component_kudos_body {
    padding: 15px 20px;
    max-height: 400px;
    overflow-x: scroll;
  }

  .component_kudos_body::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .component_kudos_body_item {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
  }

  .component_kudos_body_item_avatar {
    width: 45px;
    height: 45px;
    border-radius: 90px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .component_kudos_badge {
    display: flex;
    align-items: center;

    .component_kudos_badge_image {
      overflow: hidden;
      width: 35px;
      height: 35px;
      position: relative;
      background-color: ${Assets.COLOR_PALLETE.grey};
      border-radius: 90px;
    }

    img {
      text-indent: -9999px;
      width: 100%;
      color: ${Assets.COLOR_PALLETE.grey};
      background-color: ${Assets.COLOR_PALLETE.grey};
    }
    .component_kudos_badge_text {
      width: 60px;
      margin-left: 10px;
      font-family: ${Assets.FONT.MULI};
      font-size: 10px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  .component_kudos_body_item_left {
    display: flex;
    align-items: center;

    & > div:nth-child(2) {
      margin-left: 20px;
    }

    .component_kudos_avatar_title {
      font-family: ${Assets.FONT.MULI};
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    .component_kudos_avatar_description {
      font-family: ${Assets.FONT.MULI};
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #a9a8a8;
    }
  }
`;
