import { useLayoutEffect } from 'react';

const useScriptGMaps = () => {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    const apiKey = process.env.REACT_APP_GMAPS_KEY;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useScriptGMaps;
