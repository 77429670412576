import { gql } from 'apollo-boost';

export const SUBMIT_REVIEW = gql`
  mutation($classroomId: String!, $description: String!, $star: Int!) {
    classroomCreateReview(
      classroomId: $classroomId
      description: $description
      star: $star
    ) {
      success
    }
  }
`;
