import styled from 'styled-components';
import Assets from '../../Assets';

const PaginationWrapper = styled.div`
  /* background: pink; */
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  div.select {
    display: inline-block;
    overflow: hidden;
  }
  select {
    border: none;
    background: white;
  }
  .show-off {
    margin: 0 25px;
  }
  .cursor-group {
    width: 80px;
    display: flex;
    justify-content: space-around;
  }
  .paginate {
    cursor: pointer;
    color: ${Assets.COLOR_PALLETE.grey};
    &.disabled {
      color: ${Assets.COLOR_PALLETE.grey3};
      cursor: no-drop;
    }
  }
`;

export { PaginationWrapper };
