import React from 'react';
import { Button } from 'antd';
import { ButtonWlb } from './ButtonWlb.style';

const ButtonWlbComponent = props => {
  const {
    type,
    children,
    onClick,
    size,
    style,
    disabled,
    htmlType,
    loading
  } = props;
  return (
    <ButtonWlb>
      <Button
        loading={loading}
        style={style ? style : null}
        disabled={disabled ? disabled : false}
        type={type ? type : null}
        onClick={onClick ? onClick : null}
        htmlType={htmlType ? htmlType : null}
        size={size ? size : null}
        {...props}
      >
        {children ? children : 'Button'}
      </Button>
    </ButtonWlb>
  );
};

export default ButtonWlbComponent;
