import React from 'react';
import PostCardContainer from './PostCard.container';
import { Company, Kudos, AuthenticationService } from '../../Services';

function CompanyPostCard(props) {
  const post = props.itemPost;
  const companyId = AuthenticationService.getUserData().company_id;

  const handleLike = likeValue => {
    return Company.likePostCompany(post.userId, post.id, likeValue);
  };

  const handleKudos = kudosId => {
    return Kudos.updateKudosCompanyId(companyId, post.id, kudosId);
  };

  const cancelKudos = () => {
    return Kudos.cancelKudosCompanyId(companyId, post.id);
  };

  return (
    <PostCardContainer
      {...props}
      handleLike={handleLike}
      handleKudos={handleKudos}
      cancelKudos={cancelKudos}
      feature="companyPost"
    />
  );
}
export default CompanyPostCard;
