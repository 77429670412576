import { message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { ModalDeleteWLBCheck } from '../../Components';
// import { AuthenticationService } from "../../Services";
import { ButtonAddList, TabWrapper } from '../../SharedComponents';
import { displayLoading } from '../../Utils/CommonUtils';
import AddEditMedicalRecord from './AddEditMedicalRecord';
import MedicalItem from './MedicalItem';
import MedicalRecordModalDetail from './MedicalRecordModalDetail.component';
import { DELETE_MEDICAL_RECORD } from './UserMedicalRecord.graphql';

const initialOpen = {
  open: false,
  actionType: 'add',
  data: null,
  modalDelete: false
};

const initialActive = {
  medical_test_aware_of: true,
  has_disability: true
};

export default function UserMedicalRecordComponent({
  data,
  loading = false,
  isUser,
  refetch
}) {
  const [open, setOpen] = useState(initialOpen);
  const [active, setActive] = useState(initialActive);
  const [detail, setDetail] = React.useState({
    open: false,
    data: null
  });

  const handleClickDetail = data => setDetail({ open: true, data });
  const handleCloseDetail = () => setDetail({ open: false, data: null });

  const [deleteMedicalRecord] = useMutation(DELETE_MEDICAL_RECORD);
  const handleModalEdit = state => {
    setOpen({
      ...open,
      modalDelete: false,
      open: state === 'open' ? true : false,
      data: state === 'open' ? open.data : null,
      actionType: state === 'open' ? 'edit' : null
    });
  };

  const onButtonEditClick = data => {
    setOpen({
      ...open,
      open: true,
      actionType: 'edit',
      data: data
    });
  };

  const handleDelete = () => {
    deleteMedicalRecord({
      variables: {
        id: open.data.id
        // userId: AuthenticationService.getUserId(),
      }
    })
      .then(() => {
        refetch();
        handleModalEdit('close');
        message.success('Berhasil menghapus profil medis');
        setActive(initialActive);
      })
      .catch(err => {
        message.error(
          `Gagal menghapus profil medis, silakan coba lagi nanti, ${err}`
        );
      });
  };

  const _medical = () => {
    return (
      <>
        <TabWrapper>
          {data &&
            data.people_profile_medical_records.map((res, i) => (
              <MedicalItem
                key={`${i}-${res.id}`}
                data={res}
                isUser={isUser}
                onClickDetail={handleClickDetail}
                onEdit={() => onButtonEditClick(res)}
              />
            ))}
        </TabWrapper>
        {isUser && (
          <ButtonAddList
            onClick={() =>
              setOpen({ ...open, open: true, actionType: 'add', data: null })
            }
            label="Tambah Profil Medis"
          />
        )}
        <MedicalRecordModalDetail
          open={detail.open}
          data={detail.data}
          onClose={handleCloseDetail}
          isUser={isUser}
          onEdit={() => {
            setDetail(e => ({ ...e, open: false }));
            onButtonEditClick(detail.data);
          }}
        />
        <AddEditMedicalRecord
          active={active}
          setActive={setActive}
          initialActive={initialActive}
          refetch={refetch}
          open={open.open}
          data={open.data}
          actionType={open.actionType}
          setOpen={setOpen}
          onClose={() => setOpen(initialOpen)}
        />
        <ModalDeleteWLBCheck
          open={open.modalDelete}
          centered
          target="Profil Medis"
          onClose={() => handleModalEdit('open')}
          onDelete={handleDelete}
        />
      </>
    );
  };
  return <div>{loading ? displayLoading(loading) : _medical()}</div>;
}
