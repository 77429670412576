import styled from 'styled-components';

const ActionButtons = styled.div`
  display: flex;
  border-radius: 2.5px;
  box-shadow: 0 1.5px 3px 0 var(--black-16);
  background-color: #ffffff;
  flex-direction: column;
  justify-content: space-between;

  button {
    border: none;
    color: #007fb2;
    font-family: 'Muli-Light';
  }

  .ant-btn {
    box-shadow: none;
  }
`;

export { ActionButtons };
