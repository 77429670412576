import React, { useState } from 'react';
import MainContainer from './mainContainer';
import { CommentService } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { message } from 'antd';
import { checkNextPage } from '../../Utils/CommonUtils';

function CommentListUser(props) {
  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const maxResults = 5;
  const {
    postId,
    postOwnerId,
    totalComment,
    setTotalComment,
    setIsLoading
  } = props;

  useAsyncEffect(
    async isMounted => {
      // if (totalComment > 0) {
      await CommentService.fetchCommentList(
        postOwnerId,
        postId,
        page,
        maxResults
      ).then(response => {
        if (!isMounted()) return;
        const { per_page, total, data } = response;
        const isNextPage = checkNextPage(total, page, per_page);
        setHasNextPage(isNextPage);
        setCommentList(data.concat(commentList));
      });
      // }
    },
    [postId, postOwnerId, page, maxResults]
  );
  const setFetchMoreComment = () => {
    setPage(page + 1);
  };
  const _handleDelete = async comment => {
    // setIsLoading(true);
    await CommentService.deleteComment(comment)
      .then(() => {
        const commentUpdated = commentList.map(oldComment => {
          if (oldComment.id === comment.id) {
            return { ...oldComment, content: 'comment was deleted' };
          } else {
            return oldComment;
          }
        });
        setCommentList(commentUpdated);
        message.success('Komentar dihapus');
        setTotalComment(totalComment - 1);
      })
      .catch(() => message.error('Something went wrong'));
    // setIsLoading(false);
  };

  const _handleSubmit = async content => {
    // setIsLoading(true);
    await CommentService.postCommentPost(postOwnerId, postId, content)
      .then(() => {
        CommentService.fetchCommentList(
          postOwnerId,
          postId,
          1,
          maxResults
        ).then(response => {
          const { per_page, total, data } = response;
          const isNextPage = checkNextPage(total, page, per_page);
          setHasNextPage(isNextPage);
          setCommentList(data);
          setTotalComment(totalComment + 1);
        });
      })
      .catch(() => message.error('Something went wrong'));
    // setIsLoading(false);
  };

  return (
    <MainContainer
      commentList={commentList}
      handleDelete={_handleDelete}
      handleSubmit={_handleSubmit}
      hasNextPage={hasNextPage}
      setFetchMoreComment={setFetchMoreComment}
      showLikeComment={true}
      {...props}
    />
  );
}

export default CommentListUser;
