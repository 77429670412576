import React, { useState } from 'react';
import InviteMemberGroupComponent from './InviteMemberGroup.component';
import { message } from 'antd';
import { UserProfile, Group } from '../../Services';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useBlockMe } from '../../SharedComponents/CustomHook';
const InviteMemberGroupContainer = () => {
  const params = useParams();
  const groupId = params.id;

  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState([]);
  const [value, setValue] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [blockedMe] = useBlockMe();

  const changeHandler = value => {
    setValue(value.key);
  };

  const searchHandler = keyword => {
    let searchParam = isEmpty(keyword) ? '' : keyword.trim();
    setUser([]);
    setIsFetching(true);
    UserProfile.getUserSearch(searchParam).then(res => {
      let filteredUser = res.data.filter(item => !blockedMe?.includes(item.id));
      setUser(filteredUser);
      setIsFetching(false);
    });
  };

  const inviteHandler = () => {
    setLoading(true);
    setTimeout(function() {
      Group.Member.inviteMember(groupId, value)
        .then(res => {
          setLoading(false);
          message.success('Invite Successful');
          setTimeout(() => window.location.reload(), 1000);
        })
        .catch(err => {
          setLoading(false);
          message.error('Already a member of the group');
        });
    }, 1500);
  };

  return (
    <div>
      <InviteMemberGroupComponent
        users={users}
        inviteHandler={inviteHandler}
        loading={loading}
        changeHandler={changeHandler}
        searchHandler={searchHandler}
        value={value}
        isFetching={isFetching}
      />
    </div>
  );
};

export default InviteMemberGroupContainer;
