import React, { useState } from 'react';
import MemberCardBoxComponent from './MemberCardBox.component';
import { Group } from '../../Services';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import useInfiniteScroll from '../../SharedComponents/CustomHook/useInfiniteScroll';
import { useAsyncEffect } from 'use-async-effect';
import { checkNextPage } from '../../Utils/CommonUtils';

const MemberCardBoxContainer = props => {
  const { isAdmin } = props;
  const params = useParams();
  const groupId = params.id;
  const [admin, setAdmin] = useState([]);
  const [member, setMember] = useState([]);
  const [requestMember, setRequestMember] = useState([]);
  const [isBottom, setIsBottom] = useInfiniteScroll('group-member');
  const [memberLoading, setMemberLoading] = useState(false);
  const [memberPage, setMemberPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const refreshPage = () => {
    setTimeout(() => window.location.reload(), 1000);
  };

  useAsyncEffect(
    async isMount => {
      if (isAdmin) {
        await Group.Member.getListRequestMember(groupId, 1, 50).then(res => {
          if (!isMount()) return;
          // console.log('requestMember', res);
          setRequestMember(res.data);
        });
      }
    },
    [groupId]
  );
  useAsyncEffect(
    async isMount => {
      await Group.Member.getListAdmin(groupId, 1, 50).then(res => {
        if (!isMount()) return;
        setAdmin(res.data);
      });
    },
    [groupId]
  );

  useAsyncEffect(
    async isMount => {
      if (hasNextPage && !memberLoading && (isBottom || memberPage === 1)) {
        setMemberLoading(true);
        const maxResults = 10;
        await Group.Member.getListMember(groupId, memberPage, maxResults)
          .then(res => {
            const { per_page, total, data } = res;
            if (!isMount()) return;
            const isNextPage = checkNextPage(total, memberPage, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setMemberPage(memberPage + 1);
            }
            setMember(member.concat(data));
          })
          .catch(err => {
            message.error('please check your internet connection');
          })
          .finally(() => {
            if (!isMount()) return;
            setMemberLoading(false);
            setIsBottom(false);
          });
      }
    },
    [
      groupId,
      hasNextPage,
      isBottom,
      member,
      memberLoading,
      memberPage,
      setIsBottom
    ]
  );

  const handleSetAsAdmin = async item => {
    await Group.Member.setMemberAsAdmin(groupId, item.id).then(() => {
      message.success(`${item.name} has became an admin`);
      refreshPage();
    });
  };

  const handleSetAsMember = async item => {
    await Group.Member.setAdminAsMember(groupId, item.id).then(() => {
      message.success(`${item.name} has became a member`);
      refreshPage();
    });
  };

  const handleKickMember = async (type, item) => {
    await Group.Member.kickMemberGroup(groupId, item.id).then(() => {
      message.success(`${item.name} has removed from group`);
      refreshPage();
    });
  };

  const handleLeaveMember = async () => {
    const adminValidation = isAdmin && admin.length === 1;
    if (!adminValidation) {
      await Group.Member.leaveMemberGroup(groupId).then(() => {
        message.success('Leave group success');
        props.history.push('/group');
      });
    } else message.error('Please assign 1 more admin to leave group');
  };

  const approveMember = acceptId => {
    setIsLoading(true);
    Group.Member.approveRequest(groupId, acceptId)
      .then(res => {
        if (res.status === 200) {
          message.success('Add member success');
          setIsLoading(false);
          refreshPage();
        }
      })
      .catch(() => message.error('Something went wrong'));
  };
  const rejectMember = rejectId => {
    setIsLoading(true);
    Group.Member.rejectRequest(groupId, rejectId)
      .then(res => {
        if (res.status === 200) {
          message.success('Reject member success');
          setIsLoading(false);
          refreshPage();
        }
      })
      .catch(() => message.error('Something went wrong'));
  };
  
  return (
    <MemberCardBoxComponent
      handleSetAsAdmin={handleSetAsAdmin}
      handleSetAsMember={handleSetAsMember}
      handleKickMember={handleKickMember}
      handleLeaveMember={handleLeaveMember}
      memberLoading={memberLoading}
      member={member}
      admin={admin}
      requestMember={requestMember}
      approveMember={approveMember}
      rejectMember={rejectMember}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default MemberCardBoxContainer;
