import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import { EventPostCard } from '../../Components';
import DateTime from '../../Utils/dateTime';

const GET_EVENT = gql`
  query($id: Int) {
    event_schedules(where: { id: { _eq: $id } }) {
      id
      title
      description
      summary
      slug
      thumbnail
      event_type {
        name
      }
      datestart
      dateend
      location_info
      pricing
      global_user {
        id
        name
        avatar
        people_work_placements {
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
      average_rating
      count_reviews
      status
    }
  }
`;

const EventContainer = ({ eventId }) => {
  const getEvent = useQuery(GET_EVENT, {
    context: { headers: { 'X-Hasura-Role': 'public' } },
    variables: {
      id: eventId
    },
    skip: eventId === null
  });
  const dataEvent = getEvent?.data?.event_schedules?.[0];
  const eventDetailUrl = `${
    process.env.REACT_APP_HC_LEARNING
  }/detail_event/${dataEvent?.slug || ''}`;

  if (dataEvent !== undefined) {
    return (
      <EventPostCard
        img={dataEvent?.thumbnail}
        title={dataEvent?.title}
        name={dataEvent?.global_user?.name}
        eventDetailUrl={eventDetailUrl}
        description={dataEvent?.description
          .replace(/<[^>]*>?/gm, '')
          .slice(0, 100)}
        time={
          <span>
            {DateTime.defaultDate(new Date(dataEvent?.datestart))}
            &nbsp;-&nbsp;
            {DateTime.defaultDate(new Date(dataEvent?.dateend))}
          </span>
        }
      />
    );
  } else return <div />;
};

export default EventContainer;
