import { useState } from 'react';
import { useAsyncEffect } from 'use-async-effect';
import { UserProfile } from '../../Services';

const useWhoCanSeeMyFollow = userId => {
  const [loading, setLoading] = useState(false);
  const [followPrivacy, setFollowPrivacy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [userName, setUserName] = useState('');
  useAsyncEffect(async isMount => {
    setLoading(true);
    await UserProfile.getUserInfo(userId)
      .then(res => {
        if (!isMount) return;
        setUserName(res.name);
        if (res.who_can_see_my_follow === 1) {
          setFollowPrivacy(true);
        } else {
          setFollowPrivacy(false);
        }
        // setFollowPrivacy(res.who_can_see_my_follow ? true : false);
      })
      .finally(() => {
        setLoading(false);
        setIsLoaded(true);
      });
  }, []);
  return [followPrivacy, isLoaded, loading, userName];
};

export default useWhoCanSeeMyFollow;
