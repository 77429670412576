import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import AddEditServiceAgreement from './AddEditServiceAgreement';
import MoreServiceAgreement from './MoreServiceAgreement';
import ServiceAgreementList from './ServiceAgreementList';

const INTIAL_STATE = {
  open: false,
  data: null,
  actionType: null,
  openMore: false,
  fromList: false
};

export default function ServiceAgreement({
  isUser,
  nameUser,
  refetch,
  dataServiceAgreement
}) {
  const [open, setOpen] = useState(INTIAL_STATE);

  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title="Perjanjian Layanan"
        action={isUser ? 'plus' : null}
      >
        <ServiceAgreementList
          nameUser={nameUser}
          data={dataServiceAgreement || []}
          isUser={isUser}
          setOpen={setOpen}
        />
      </DetailUserSection>
      <MoreServiceAgreement
        open={open.openMore}
        setOpen={setOpen}
        isUser={isUser}
        nameUser={nameUser}
        data={dataServiceAgreement || []}
      />
      <AddEditServiceAgreement
        open={open.open}
        refetch={refetch}
        actionType={open.actionType}
        nameUser={nameUser}
        data={open.data}
        setOpen={setOpen}
        onClose={() =>
          setOpen({
            open: false,
            data: null,
            actionType: null,
            fromList: false,
            openMore: open.fromList ? true : false
          })
        }
      />
    </>
  );
}
