import React from 'react';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import { formatDate } from '../HelperDetailScreen';
import ServiceAgreementItem from './ServiceAgreementItem';

export default function ServiceAgreementList({
  setOpen,
  isUser,
  data,
  nameUser
}) {
  const handleEdit = data => {
    setOpen(e => ({
      ...e,
      open: true,
      data,
      actionType: 'edit'
    }));
  };
  return (
    <>
      {data?.slice(0, 2)?.map((res, i, count) => {
        return (
          <ServiceAgreementItem
            key={res.id}
            noBorder={count.length === i + 1 || false}
            code={res.code || '-'}
            start_date={formatDate(res.start_date) || '-'}
            end_date={formatDate(res.end_date) || '-'}
            description={res.description || '-'}
            attachments={res.attachments || []}
            onClickEdit={isUser ? () => handleEdit(res) : null}
          />
        );
      })}
      {data.length > 2 && (
        <ThypoStyled
          color={'#007fb2'}
          fontSize="16px"
          fontWeight="600"
          cursor="pointer"
          margin="0px 0px 23px 0px"
          onClick={() => setOpen(e => ({ ...e, openMore: true }))}
        >
          Tampilkan Lebih Banyak Perjanjian Layanan...
        </ThypoStyled>
      )}
    </>
  );
}
