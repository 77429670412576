import React from 'react';
import { FormAction, FormWrapper } from './LocationForm.style';
import { Button, Form, Input, Select } from 'antd';
import './LocationFrom.style.css';

const { TextArea } = Input;
const { Option } = Select;

const LocationFormComponent = props => {
  const { getFieldDecorator, resetFields } = props.form;
  const {
    handleSubmit,
    updateLocationField,
    handleCancel,
    isLocationFieldUndefined,
    mockedSelectionData,
    selectedRecord,
    isEdit
  } = props;

  const onCancel = () => {
    resetFields();
    handleCancel();
  };

  const formItems = [
    {
      type: 'text',
      labelName: 'Label Name',
      fieldName: 'label',
      initValue: selectedRecord.label_name ? selectedRecord.label_name : ''
    },
    {
      type: 'textArea',
      labelName: 'Address',
      fieldName: 'address',
      initValue: selectedRecord.address ? selectedRecord.address : ''
    },
    {
      type: 'selection',
      labelName: 'Province',
      fieldName: 'province',
      initValue: selectedRecord.province_code
        ? selectedRecord.province_code
        : null,
      dependencyField: null
    },
    {
      type: 'selection',
      labelName: 'City',
      fieldName: 'city',
      initValue: selectedRecord.city_code ? selectedRecord.city_code : null,
      dependencyField: !isEdit ? 'province' : null
    },
    {
      type: 'selection',
      labelName: 'District',
      fieldName: 'district',
      initValue: selectedRecord.district_code
        ? selectedRecord.district_code
        : null,
      dependencyField: !isEdit ? 'city' : null
    },
    {
      type: 'number',
      labelName: 'Postal code',
      fieldName: 'postal',
      initValue: selectedRecord.post_code ? selectedRecord.post_code : ''
    }
  ];

  const renderFormItem = formItem => {
    const { fieldName, labelName, initValue } = formItem;
    return (
      <Form.Item key={formItem.fieldName} label={labelName}>
        {getFieldDecorator(fieldName, {
          rules: [
            {
              required: true,
              message: `Please input ${labelName.toLowerCase()}`
            }
          ],
          initialValue: isEdit ? initValue : ''
        })(_switchComponentToRender(formItem))}
      </Form.Item>
    );
  };

  const _switchComponentToRender = formItem => {
    const { type, dependencyField, labelName, fieldName } = formItem;

    if (type === 'selection') {
      const isDisabled =
        dependencyField === null
          ? false
          : isLocationFieldUndefined(dependencyField);
      return (
        <Select
          disabled={isDisabled}
          placeholder={`Select ${labelName}`}
          onChange={value => updateLocationField(fieldName, value)}
        >
          {_mapOptionFromMockSelection(fieldName)}
        </Select>
      );
    }

    if (type === 'text' || type === 'number') {
      return (
        <Input
          type={type}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }

    if (type === 'textArea') {
      return (
        <TextArea
          rows={4}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }
  };

  const _mapOptionFromMockSelection = fieldName => {
    if (fieldName === 'province') {
      return mockedSelectionData[fieldName].map((item, id) => (
        <Option key={id} value={item.province_id}>
          {item.province}
        </Option>
      ));
    }
    if (fieldName === 'city') {
      return mockedSelectionData[fieldName].map((item, id) => (
        <Option key={id} value={item.city_id}>
          {`${item.type}  ${item.city_name}`}
        </Option>
      ));
    }
    if (fieldName === 'district') {
      return mockedSelectionData[fieldName].map((item, id) => (
        <Option key={id} value={item.subdistrict_id}>
          {item.subdistrict_name}
        </Option>
      ));
    }
    return mockedSelectionData[fieldName].map((item, id) => (
      <Option key={id} value={item}>
        {item}
      </Option>
    ));
  };

  return (
    <FormWrapper>
      <Form layout={'vertical'} onSubmit={handleSubmit}>
        {formItems.map(item => renderFormItem(item))}
        <Form.Item>
          <FormAction>
            <Button onClick={() => onCancel()}>Cancel</Button>
            <Button
              htmlType="submit"
              className={'submit-button'}
              type="primary"
            >
              Save
            </Button>
          </FormAction>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

export default LocationFormComponent;
