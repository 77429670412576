import React from 'react';
import { PageHeader } from 'antd';
import { TopSettingGroupWrapper } from './TopSettingGroup.style';
import { useLocation } from 'react-router-dom';

const TopSettingGroupComponent = props => {
  const {
    title,
    buttonTop = null,
    color = null,
    border = true,
    borderRadius = true,
    style = null
  } = props;
  const location = useLocation();

  return (
    <TopSettingGroupWrapper
      style={style}
      border={border}
      color={color}
      borderRadius={borderRadius}
    >
      {location.pathname === '/createGroup' ? (
        <PageHeader
          onBack={() => window.history.back()}
          title={title}
          extra={buttonTop}
        />
      ) : (
        <PageHeader title={title} extra={buttonTop} />
      )}
    </TopSettingGroupWrapper>
  );
};

export default TopSettingGroupComponent;
