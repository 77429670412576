import React from 'react';
import { Select } from 'antd';
import style, {
  ComponentWrapper,
  ItemWrapper,
  DetailEvent
} from './EventPost.style';
import config from './EventPost.config';
import Constants from '../../Config/Constants';
import DateTime from '../../Utils/dateTime';
import { EventPostCard } from '../';

const { Option } = Select;

const EventPost = ({
  selectedEvent,
  handleEventName,
  listEvent,
  isFocus,
  setEventKeyword,
  handlePost,
  setEvent
}) => {
  const _renderDetailEvent = event => (
    <DetailEvent>
      <div className="component_option_item_content_title">{event?.title}</div>
      <div className="component_option_item_content_name">
        {event?.global_user?.name}
      </div>
      <div className="component_option_item_content_description">
        {event?.description?.replace(/<[^>]*>?/gm, '').slice(0, 100)}
      </div>
      <div className="component_option_item_content_date">
        {event.datestart && DateTime.defaultDate(new Date(event.datestart))}
        &nbsp;-&nbsp;
        {event.dateend && DateTime.defaultDate(new Date(event.dateend))}
      </div>
    </DetailEvent>
  );

  const _renderDetailBox = () => {
    return selectedEvent ? (
      <EventPostCard
        img={selectedEvent?.thumbnail}
        title={selectedEvent?.title}
        name={selectedEvent?.global_user?.name}
        description={selectedEvent?.description
          ?.replace(/<[^>]*>?/gm, '')
          .slice(0, 100)}
        time={
          <span>
            {selectedEvent.datestart &&
              DateTime.defaultDate(new Date(selectedEvent.datestart))}
            &nbsp;-&nbsp;
            {selectedEvent.dateend &&
              DateTime.defaultDate(new Date(selectedEvent.dateend))}
          </span>
        }
        deleteEvent={() => {
          handlePost('event', undefined);
          setEvent(null);
        }}
      />
    ) : null;
  };

  const _renderTitle = () => (
    <div className="component_title">
      {Constants.MESSAGES.POST_BOX.TITLE.EVENT}
    </div>
  );

  const _renderSelect = () => {
    return (
      <Select
        style={style.select}
        showSearch
        onSelect={handleEventName}
        placeholder="Pilih acara"
        optionLabelProp="label"
        filterOption={(input, option) =>
          option.props.label.toLocaleLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        value={selectedEvent?.id}
        onSearch={keyword => setEventKeyword(keyword)}
      >
        {listEvent?.map(event => {
          return (
            <Option key={event.id} value={event.id} label={event.title}>
              <ItemWrapper>
                <div className="component_option_item_image">
                  <img src={event.thumbnail} alt={event.title} />
                </div>
                {_renderDetailEvent(event)}
              </ItemWrapper>
            </Option>
          );
        })}
      </Select>
    );
  };
  return (
    <ComponentWrapper>
      {_renderTitle()}
      {_renderSelect()}
      {isFocus && _renderDetailBox()}
    </ComponentWrapper>
  );
};

EventPost.propTypes = config.propTypes;
EventPost.defaultProps = config.defaultProps;

export default EventPost;
