import Constants from "../../../Config/Constants";

export default {
  wrapper: {
    width: 167.5
  },
  wrapperRow: {
    marginTop: 0
  },
  text: {
    fontSize: 6,
    color: Constants.COLORS.BLACK
  },
  nameText: {
    color: Constants.COLORS.LIGHT_BLUE
  },
  timeStampText: {
    fontSize: 6,
    color: Constants.COLORS.GREY
  },
  hr: {
    marginTop: 0,
    marginBottom: 12,
    height: 1,
    backgroundColor: Constants.COLORS.LIGHT_GREY,
    border: "none"
  },
  wrapperBottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  markAsRead: {
    cursor: 'pointer',
    fontSize: 6,
    color: Constants.COLORS.DARK_BLUE
  },
  link: {
    fontSize: 6,
    color: Constants.COLORS.DARK_BLUE
  }
}
