import React from "react";
import StoreScreen from './StoreScreen';
import PaymentScreen from './PaymentScreen';
import ReadyScreen from './ReadyScreen';


const menuStepsList = [
  {
    renderComponent: (props) => <StoreScreen {...props}/>,
    name: 'Store'
  },
  {
    renderComponent: (props) => <PaymentScreen {...props} />,
    name: 'Payment'
  },
  {
    renderComponent: (props) => <ReadyScreen {...props} />,
    name: 'Ready'
  }
];
const defaultMenuSteps = 'Store';


export default {
  menuStepsList,
  defaultMenuSteps
}
