export default {
  LOGIN: '/auth/login',

  //post global
  POST: userId => `/users/${userId}/posts`,
  POST_ID: (userId, postId) => `/users/${userId}/posts/${postId}`,
  POST_LIKE_ID: (userId, postId, likeValue) =>
    `/users/${userId}/posts/${postId}/like/${likeValue}`,
  POST_AWARD_ID: (userId, postId, awardValue) =>
    `/users/${userId}/posts/${postId}/award/${awardValue}`,
  POST_COMMENT: (userId, postId) => `/users/${userId}/posts/${postId}/comments`,
  POST_COMMENT_ID: (commentOwnerId, postId, commentId) =>
    `/users/${commentOwnerId}/posts/${postId}/comments/${commentId}`,
  POST_COMMENT_LIKE: (commentOwnerId, postId, commentId) =>
    `/users/${commentOwnerId}/posts/${postId}/comments/${commentId}/like`,
  POST_LIKES: (userId, postId) => `/users/${userId}/posts/${postId}/like`,
  POST_VOTE: (userId, postId, optionId) =>
    `/users/${userId}/posts/${postId}/vote/${optionId}`,
  USERS_BY_VOTE: (userId, postId, optionId) =>
    `/users/${userId}/posts/${postId}/votes/${optionId}`,
  UNDO_VOTE: (userId, postId) => `/users/${userId}/posts/${postId}/vote/undo`,

  //Timeline
  TIMELINE: userId => `/users/${userId}/timelines`,
  TIMELINE_ID: (userId, postId) => `/users/${userId}/timelines/${postId}`,

  //COMPANY
  COMPANY: userId => `/users/${userId}/company`,
  COMPANY_FOLLOWER: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/followers`,
  COMPANY_FOLLOW: (userId, companyId, followValue) =>
    `/users/${userId}/company/${companyId}/${followValue}`,
  COMPANY_BY_ID: (userId, companyId) => `/users/${userId}/company/${companyId}`,

  //company post
  COMPANY_POST: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/posts`,
  COMPANY_POST_ID: (userId, companyId, postId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}`,
  COMPANY_POST_LIKE: (userId, companyId, postId, likeValue) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/like/${likeValue}`,
  COMPANY_POST_COMMENT: (userId, companyId, postId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/comments`,
  COMPANY_POST_COMMENT_ID: (userId, companyId, postId, commentId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/comments/${commentId}`,
  COMPANY_POST_LIKES: (userId, postId, companyId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/like`,

  //company timeline
  COMPANY_TIMELINE_POST: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/timeline`,
  COMPANY_TIMELINE_POST_ID: (userId, companyId, timelineId) =>
    `/users/${userId}/company/${companyId}/timeline/${timelineId}`,
  COMPANY_TIMELINES_POST: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/timelines`,
  COMPANY_TIMELINES_POST_ID: (userId, companyId, timelineId) =>
    `/users/${userId}/company/${companyId}/timelines/${timelineId}`,
  COMPANY_TIMELINE_LIKE: (userId, companyId, postId, likeValue) =>
    `/users/${userId}/company/${companyId}/timeline/${postId}/like/${likeValue}`,
  COMPANY_TIMELINE_COMMENT: (userId, companyId, postId) =>
    `/users/${userId}/company/${companyId}/timeline/${postId}/comments`,
  COMPANY_TIMELINE_COMMENT_ID: (userId, companyId, postId, commentId) =>
    `/users/${userId}/company/${companyId}/timeline/${postId}/comments/${commentId}`,
  COMPANY_TIMELINE_FOllOW: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/follow`,
  COMPANY_TIMELINE_UNFOllOW: (userId, companyId) =>
    `/users/${userId}/company/${companyId}/unfollow`,

  //Connection
  FRIENDSHIP: userId => `/users/${userId}/friendships/`,
  TOTAL_FOLLOWING_FOLLOWER: userId => `/users/${userId}/friendships/detail`,
  FOLLOWING: userId => `/users/${userId}/friendships/following`,
  FOLLOWER: userId => `/users/${userId}/friendships/followers`,
  FOLLOW: userId => `/users/${userId}/friendships/follow`,
  UNFOLLOW: userId => `/users/${userId}/friendships/unfollow`,

  //Group
  GROUPS_GLOBAL: userId => `/users/${userId}/groups`,
  GROUPS: userId => `/users/${userId}/groups`,
  GROUPS_ID: (userId, groupId) => `/users/${userId}/groups/${groupId}`,
  GROUP_POST: (userId, groupId) => `/users/${userId}/groups/${groupId}/posts`,
  GROUP_POST_ID: (userId, groupId, postId) =>
    `/users/${userId}/groups/${groupId}/posts/${postId}`,
  GROUP_POST_LIKE: (userId, groupId, postId, likeValue) =>
    `/users/${userId}/groups/${groupId}/posts/${postId}/like/${likeValue}`,
  GROUP_COMMENT: (userId, groupId, postId) =>
    `/users/${userId}/groups/${groupId}/posts/${postId}/comments`,
  GROUP_COMMENT_ID: (userId, groupId, postId, commentId) =>
    `/users/${userId}/groups/${groupId}/posts/${postId}/comments/${commentId}`,

  GROUP_MEMBER: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/members`,

  GROUP_ALL_MEMBER: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/all-members`,

  GROUP_MEMBER_ID: (userId, groupId, groupMemberId) =>
    `/users/${userId}/groups/${groupId}/members/${groupMemberId}`,
  GROUP_MEMBER_TO_ADMIN: (userId, groupId, groupMemberId) =>
    `/users/${userId}/groups/${groupId}/members/${groupMemberId}/ismember`,
  GROUP_ADMIN_TO_MEMBER: (userId, groupId, groupMemberId) =>
    `/users/${userId}/groups/${groupId}/members/${groupMemberId}/isadmin`,
  GROUP_ADMIN: (userId, groupId) => `/users/${userId}/groups/${groupId}/admins`,
  GROUP_LEAVE: (userId, groupId) => `/users/${userId}/groups/${groupId}/leave`,
  GROUP_JOIN: (userId, groupId) => `/users/${userId}/groups/${groupId}/join`,
  GROUP_REQUEST_MEMBER_LIST: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/request-members`,
  GROUP_ACCEPT_REQUEST_MEMBER: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/accept-member`,
  GROUP_DECLINE_REQUEST_MEMBER: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/reject-member`,
  GROUP_CANCEL_REQUEST_JOIN: (userId, groupId) =>
    `/users/${userId}/groups/${groupId}/cancel-request`,
  GROUP_POST_LIKES: (userId, groupId, postId) =>
    `/users/${userId}/groups/${groupId}/posts/${postId}/like`,

  //Search
  SEARCH: `/search`,

  //Users
  USER: `/users`,
  USER_ID: userId => `/users/detail/${userId}`,
  USER_BLOCK: userId => `users/detail/${userId}/users-blocked`,
  USER_BLOCK_ME: userId => `users/detail/${userId}/users-blocked-me`,
  USER_CAN_SEE_MY_FOLLOW: userId =>
    `users/detail/${userId}/user-can-see-my-follow`,

  // Notification
  NOTIFICATION: userId => `/users/${userId}/notifications`,
  READ_ALL_NOTIFICATION: () => `/global/notification/read/all`,
  READ_ALL_NOTIFICATION_TYPE: (type, isRecent, today, yesterday) =>
    `/global/notification/read/all?${`${
      type === 'all' ? '' : `type=${type}&`
    }`}${isRecent ? `start=${today}` : `end=${yesterday}`}`,

  READ_ALL_NOTIFICATION_SUB_TYPE: (subType, isRecent, today, yesterday) =>
    `/global/notification/read/all?sub_type=${subType}&${
      isRecent ? `start=${today}` : `end=${yesterday}`
    }`,

  // Widget-User
  WIDGET: userId => `/users/${userId}/widgets-user`,
  WIDGET_ID: (userId, widgetId) => `/users/${userId}/widgets-user/${widgetId}`,

  // Forgot Password
  FORGOT_PASS: `${process.env.REACT_APP_API_URL}/global/accounts/forgotpwd`,

  // Kudos
  KUDOS: userId => `/users/${userId}/kudos`,
  KUDOS_COMPANY_ID: (userId, companyId, postId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/kudos`,
  UNKUDOS_COMPANY_ID: (userId, companyId, postId) =>
    `/users/${userId}/company/${companyId}/posts/${postId}/unkudos`,

  //Classroom
  CLASSROOMS_GLOBAL: userId => `/users/${userId}/classrooms-global`,
  CLASSROOMS: userId => `/users/${userId}/classrooms`,
  CLASSROOMS_ID: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}`,
  CLASSROOM_POST: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/posts`,
  CLASSROOM_POST_ID: (userId, classroomId, postId) =>
    `/users/${userId}/classrooms/${classroomId}/posts/${postId}`,
  CLASSROOM_POST_LIKE: (userId, classroomId, postId, likeValue) =>
    `/users/${userId}/classrooms/${classroomId}/posts/${postId}/like/${likeValue}`,
  CLASSROOM_COMMENT: (userId, classroomId, postId) =>
    `/users/${userId}/classrooms/${classroomId}/posts/${postId}/comments`,
  CLASSROOM_COMMENT_ID: (userId, classroomId, postId, commentId) =>
    `/users/${userId}/classrooms/${classroomId}/posts/${postId}/comments/${commentId}`,
  CLASSROOM_MEMBER: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/members`,
  CLASSROOM_ALL_MEMBER: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/all-members`,
  CLASSROOM_MEMBER_ID: (userId, classroomId, groupMemberId) =>
    `/users/${userId}/classrooms/${classroomId}/members/${groupMemberId}`,
  CLASSROOM_MEMBER_TO_ADMIN: (userId, classroomId, groupMemberId) =>
    `/users/${userId}/classrooms/${classroomId}/members/${groupMemberId}/ismember`,
  CLASSROOM_ADMIN_TO_MEMBER: (userId, classroomId, groupMemberId) =>
    `/users/${userId}/classrooms/${classroomId}/members/${groupMemberId}/isadmin`,
  CLASSROOM_ADMIN: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/admins`,
  CLASSROOM_LEAVE: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/leave`,
  CLASSROOM_JOIN: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/join`,
  CLASSROOM_REQUEST_MEMBER_LIST: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/request-members`,
  CLASSROOM_ACCEPT_REQUEST_MEMBER: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/accept-member`,
  CLASSROOM_DECLINE_REQUEST_MEMBER: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/reject-member`,
  CLASSROOM_CANCEL_REQUEST_JOIN: (userId, classroomId) =>
    `/users/${userId}/classrooms/${classroomId}/cancel-request`
};
