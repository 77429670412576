import React from 'react';
import Assets from '../../Assets';
import { AvatarStyled2 } from '../BannerProfile/BannerProfile.style';

function AvatarDetail({ avatar }) {
  const [src, setSrc] = React.useState(avatar);

  return (
    <AvatarStyled2
      style={
        !avatar && {
          padding: 28,
          backgroundColor: Assets.COLOR_PALLETE.grey2
        }
      }
      onError={e => {
        if (e) {
          setSrc(Assets.DEFAULT_LOGO);
        }
      }}
      src={src}
      shape="square"
    />
  );
}

export default AvatarDetail;
