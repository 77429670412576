import styled from 'styled-components';
import Assets from '../../Assets';

export const VendorListBoxWrap = styled.div`
  position: relative;
  padding: 12px;
  background-color: ${Assets.COLOR_PALLETE.white};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const BoxShadow = styled.div`
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
`;