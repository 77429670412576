import React from 'react';
import {
  DivStyled,
  WrapperIndicator,
  Indicator,
  ThypoStyled,
  ButtonEdit
} from './DetailUserSection.style';
import Assets from '../../Assets';
import { Icon, Avatar } from 'antd';
import DirectToUserDetail from '../DirectToUserDetail';
import { Divider, useMediaQuery } from '@material-ui/core';

export default function ListUtils({
  showMoreText,
  data = [],
  isUser,
  type,
  setOpen,
  setOpenEndorse,
  handleChidAction,
  isFollowingFollowed,
  onClickMore,
  onUnendorsed,
  onClickMorePeople,
  total
}) {
  const isMobileScreen = useMediaQuery(
    '(min-width: 320px) and (max-width: 767px)'
  );
  if (data === undefined) {
    return <h1>Loading...</h1>;
  }
  if (data.length < 1) {
    return '';
  }

  const _buttonEdit = resEdit => {
    if (isUser) {
      return (
        <div style={{ display: 'flex', alignContent: 'flex-start' }}>
          <ButtonEdit
            onClick={
              type === 'hobbies'
                ? () =>
                    setOpen({ open: true, data: resEdit, actionType: 'edit' })
                : () => handleChidAction(resEdit)
            }
          >
            <Icon theme="outlined" type="edit" />
          </ButtonEdit>
        </div>
      );
    } else {
      if (type !== 'hobbies') {
        const { has_endorse } = resEdit;
        return (
          <div style={{ display: 'flex', alignContent: 'flex-start' }}>
            {isFollowingFollowed && (
              <ButtonEdit
                onClick={
                  has_endorse > 0
                    ? () => onUnendorsed(resEdit)
                    : () =>
                        setOpenEndorse({
                          open: true,
                          data: resEdit,
                          actionType: 'add'
                        })
                }
              >
                <Icon
                  style={{
                    backgroundColor: has_endorse > 0 ? '#fff' : '#039be5',
                    borderRadius: '50%',
                    padding: 5,
                    color: has_endorse > 0 ? '#039be5' : '#fff',
                    fontSize: 14,
                    border: has_endorse > 0 && '1px solid #039be5'
                    // cursor: has_endorse > 0 && "default",
                  }}
                  type={has_endorse > 0 ? 'check' : 'plus'}
                />
              </ButtonEdit>
            )}
          </div>
        );
      }
    }
  };

  const _content = (res, i) => (
    <>
      <DivStyled
        style={{ minHeight: 136 }}
        key={i}
        padding="28px 0px 10px 0px"
        display="flex"
        justifyContent="space-between"
      >
        <div
          style={{
            display: 'flex',
            gap: 24,
            flexDirection: isMobileScreen ? 'column' : 'row'
          }}
        >
          <WrapperIndicator>
            <Indicator>
              {type === 'hobbies' ? (
                <Avatar src={res.levelIndicator} />
              ) : (
                res.countpeople
              )}
            </Indicator>
            <ThypoStyled
              margin="0px 0px 0px 63px"
              fontSize="12px"
              textAlign="center"
            >
              {res.nameIndicator}
            </ThypoStyled>
          </WrapperIndicator>
          <DivStyled display="flex" flexDirection="column">
            <ThypoStyled
              style={{ textTransform: 'capitalize' }}
              fontSize="14px"
              color={Assets.COLOR_PALLETE.blue_link}
              margin="5px 0px 12px 0px"
              fontWeight="600"
            >
              {res.title}
            </ThypoStyled>
            {type === 'hobbies' && (
              <DivStyled
                flexWrap="wrap"
                display="flex"
                flexDirection="row"
                margin="0px 0px 10px 0px"
              >
                {res.detail.map((res, i) => (
                  <DivStyled
                    key={i}
                    margin="0px 10px 10px 0px"
                    backgroundColor="#fff"
                    borderRadius="60px"
                    border="solid 1px #014a62"
                    padding="7px 21px"
                  >
                    <ThypoStyled color="#014a62" fontSize="12px">
                      {res}
                    </ThypoStyled>
                  </DivStyled>
                ))}
              </DivStyled>
            )}
            <ThypoStyled
              fontSize="12px"
              margin={
                type === 'hobbies' ? '0px 0px 24px 0px' : '0px 0px 0px 0px'
              }
            >
              {type === 'hobbies' && res.description}
            </ThypoStyled>
            {type === 'hobbies' ? (
              res.people_profile_endorsements.length > 0 && (
                <DivStyled display="flex" flexDirection="column">
                  <ThypoStyled
                    fontSize="12px"
                    color="#a9a8a8"
                    margin="0px 0px 6px 0px"
                  >
                    Share the same hobby with :
                  </ThypoStyled>
                  <DivStyled
                    display="flex"
                    flexDirection="row"
                    margin="0px 0px 30px 0px"
                  >
                    {res.people_profile_endorsements.map((people, idx) => (
                      <Avatar
                        key={idx}
                        alt={people?.global_user?.name}
                        src={people?.global_user?.avatar}
                        size={38}
                        style={{ marginRight: 8 }}
                      ></Avatar>
                    ))}
                  </DivStyled>
                </DivStyled>
              )
            ) : (
              <DivStyled display="flex" flexDirection="column">
                {res.people_profile_skills.length > 0 && (
                  <>
                    <ThypoStyled
                      fontSize="12px"
                      color="#a9a8a8"
                      margin="0px 0px 6px 0px"
                    >
                      Bagikan keterampilan yang sama dengan :
                    </ThypoStyled>
                    <DivStyled
                      display="flex"
                      flexDirection="row"
                      margin="0px 0px 30px 0px"
                    >
                      {res.people_profile_skills
                        .slice(0, 4)
                        .map((people, idx) => (
                          <DirectToUserDetail
                            key={idx}
                            userId={people?.global_user?.id}
                          >
                            <Avatar
                              alt={people?.global_user?.name}
                              src={people?.global_user?.avatar}
                              size={38}
                              style={{ marginRight: 8 }}
                            />
                          </DirectToUserDetail>
                        ))}
                      {res.count_people_skill > 4 && (
                        <Avatar
                          size={38}
                          style={{ marginRight: 8, cursor: 'pointer' }}
                          onClick={() =>
                            onClickMorePeople({
                              data: res.dataEdit,
                              isFromList: false
                            })
                          }
                        >
                          <span>+{res.count_people_skill - 4}</span>
                        </Avatar>
                      )}
                    </DivStyled>
                  </>
                )}
              </DivStyled>
            )}
          </DivStyled>
        </div>
        {_buttonEdit(res)}
      </DivStyled>
      {data.length >= 2 && 2 !== i + 1 && <Divider />}
    </>
  );

  return (
    <DivStyled display="flex" flexDirection="column">
      {data && data.slice(0, 2).map((res, i) => _content(res, i))}
      {total > 2 ? (
        <ThypoStyled
          color={Assets.COLOR_PALLETE.blue_link}
          fontSize="16px"
          fontWeight="600"
          cursor="pointer"
          margin="0px 0px 23px 0px"
          onClick={onClickMore}
        >
          {`Tampilkan Lebih Banyak${showMoreText}...`}
        </ThypoStyled>
      ) : null}
    </DivStyled>
  );
}
