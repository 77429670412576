import { Form, Input, message, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { AuthenticationService } from '../../../Services';
import { ButtonFrom, TitleField } from '../../../SharedComponents';
import ModalEdit from '../../ModalEdit';
import {
  GET_LIST_SOCIALS,
  ADD_LIST_SOCIALS,
  EDIT_LIST_SOCIALS,
  SET_UNMAINMAIN_CONTACT
} from './AnotherContact.graphql';

const pattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const AddEditAnotherContactComponent = ({
  open,
  handleClose,
  openAction,
  form,
  setOpenAction,
  refetch,
  data
}) => {
  const { Option } = Select;
  const { actionType } = openAction;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue
  } = form;

  const [main, setMain] = useState(false);
  const { data: dataSocial } = useQuery(GET_LIST_SOCIALS);

  const [addSocial] = useMutation(ADD_LIST_SOCIALS, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [editSocial] = useMutation(EDIT_LIST_SOCIALS, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setUnmainContact] = useMutation(SET_UNMAINMAIN_CONTACT, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleCloseAct = () => {
    handleClose();
    resetFields();
  };

  useEffect(() => {
    if (actionType === 'add') {
      setFieldsValue({
        is_main: false
      });
      setMain(false);
    } else {
      if (data) {
        setFieldsValue({
          is_main: data?.is_main ?? false
        });
        setMain(data?.is_main ?? false);
      }
    }
  }, [data, actionType, setMain, setFieldsValue]);

  const handleMutation = (variables, type) => {
    if (type === 'add') {
      addSocial({
        variables
      })
        .then(() => {
          refetch();
          message.success(`Tambah Kontak Sosial Berhasil`);
          handleCloseAct();
        })
        .catch(err => {
          message.error(`Tambah Kontak Sosial Gagal, ${err}`);
        });
    } else {
      editSocial({
        variables
      })
        .then(() => {
          refetch();
          message.success(`Ubah Kontak Sosial Berhasil`);
          handleCloseAct();
        })
        .catch(err => {
          message.error(`Ubah Kontak Sosial Gagal, ${err}`);
        });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          const submit = {
            objects: [
              {
                social_media_id: values.socials_type,
                name: values.social.split('/')[3],
                url: values.social,
                is_main: values.is_main,
                is_visible: false
              }
            ]
          };
          if (values.is_main) {
            setUnmainContact({
              variables: {
                user_id: AuthenticationService.getUserId(),
                sosmedId: values.socials_type
              }
            })
              .then(() => {
                refetch();
                handleMutation(submit, 'add');
              })
              .catch(err => {
                message.error(`Add Social Contact error, ${err}`);
              });
          } else {
            handleMutation(submit, 'add');
          }
        } else {
          const submit = {
            id: data.id,
            objects: {
              social_media_id: values.socials_type,
              name: values.social.split('/')[3],
              url: values.social,
              is_main: values.is_main
            }
          };
          if (values.is_main) {
            setUnmainContact({
              variables: {
                user_id: AuthenticationService.getUserId(),
                sosmedId: values.socials_type
              }
            })
              .then(() => {
                refetch();
                handleMutation(submit, 'edit');
              })
              .catch(err => {
                message.error(`Add Social Contact error, ${err}`);
              });
          } else {
            handleMutation(submit, 'edit');
          }
        }
      }
    });
  };

  const handleChange = (formValue, target) => {
    if (target === 'email') {
      setFieldsValue({
        email: formValue.target.value
      });
    } else if (target === 'is_main') {
      setFieldsValue({
        is_main: formValue
      });
      setMain(formValue);
    }
  };

  const handleDelete = () => {
    setOpenAction({
      ...openAction,
      addEdit: false,
      modalDelete: true
    });
  };

  return (
    <ModalEdit
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambah'} Kontak Sosial`}
      open={open}
      onClose={handleCloseAct}
      sizeSmall
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Kontak Sosial</TitleField>}
            style={{ marginBottom: 0 }}
          >
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '25%' }}
              required
              disabled
            >
              {getFieldDecorator('socials_type', {
                initialValue: data?.global_social_medium?.id,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Select
                  size="large"
                  style={{ width: '90%' }}
                  placeholder="Sosial"
                  onChange={value => handleChange(value, 'socials_type')}
                >
                  {dataSocial &&
                    dataSocial?.global_social_media.map((res, i) => (
                      <Option key={`${i}`} value={res.id}>
                        {res.name}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '75%' }}
              required
            >
              {getFieldDecorator('social', {
                initialValue: data?.url,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  },
                  {
                    pattern,
                    message: "Bidang ini harus berupa url yang valid, dengan https:// or http:// dan nama pengguna.",
                  },
                ],
              })(
                <Input
                  placeholder="Tambah Kontak Sosial"
                  type="text"
                  onChange={value => handleChange(value, 'social')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        <Form.Item
          style={{
            padding: '0px 22px 12px 22px',
            marginBottom: 8,
            display: 'flex'
          }}
          label={<TitleField>Tetapkan Sebagai Kontak Sosial Media:</TitleField>}
        >
          {getFieldDecorator('is_main', {
            initialValue: data?.is_main ?? false
            // value: main
          })(
            <Switch
              checked={main}
              onChange={value => handleChange(value, 'is_main')}
              size="small"
            />
          )}
        </Form.Item>
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleCloseAct}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditAnotherContact = Form.create({ name: 'AddEdit_Another' })(
  AddEditAnotherContactComponent
);

export default AddEditAnotherContact;
