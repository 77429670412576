import React from 'react';
import { Dropdown, Menu, Icon } from 'antd';
import Assets from '../../Assets';
import { DeleteModal } from '../../Components';
import { Wrapper } from './PostDelete.style';

const PostDeleteComponent = props => {
  const {
    isNotOwner,
    displayDeleteModal,
    setDisplayDeleteModal,
    deleteHandler,
    editHandler,
    canEdit
  } = props;
  const path = window.location.href;
  const wording = isNotOwner ? 'Sembunyikan' : 'Hapus';
  const description = isNotOwner
    ? 'Sembunyikan dari timeline'
    : 'Hapus postingan Anda';
  const isEdit = path.includes('social-learning');

  return (
    <>
      <Dropdown
        className="more-option"
        overlay={
          <Menu>
            {!isNotOwner && isEdit && canEdit && (
              <Menu.Item className="menu-item" onClick={() => editHandler()}>
                <Wrapper>
                  <Icon type="edit" className="icon-edit" />
                  <div>
                    <span className="title">Ubah</span>
                    <span className="desc">Ubah postingan anda</span>
                  </div>
                </Wrapper>
              </Menu.Item>
            )}

            <Menu.Item
              className="menu-item"
              onClick={() => setDisplayDeleteModal(true)}
            >
              <Wrapper>
                <Icon
                  type={isNotOwner ? 'eye-invisible' : 'delete'}
                  className={isNotOwner ? 'icon-hide' : 'icon'}
                />
                <div>
                  <span className="title">{wording}</span>
                  <span className="desc">{description}</span>
                </div>
              </Wrapper>
            </Menu.Item>
            {/* <Menu.Item>Hide</Menu.Item> */}
          </Menu>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <img alt={'more'} src={Assets.ICON.MORE} />
      </Dropdown>
      <DeleteModal
        displayModal={displayDeleteModal}
        closeModal={() => setDisplayDeleteModal(false)}
        handleDelete={() => deleteHandler()}
        modalTitle={CONFIRMATION[wording]?.title}
        wording={CONFIRMATION[wording]?.button}
        modalContent={CONFIRMATION[wording]?.content}
      />
    </>
  );
};

const CONFIRMATION = {
  Hapus: {
    title: 'Hapus postingan',
    content:
      'Apakah Anda yakin ingin menghapus postingan ini? Postingan akan terhapus secara permanen',
    button: 'Hapus'
  },
  Sembunyikan: {
    title: 'Sembunyikan postingan',
    content:
      'Apakah Anda yakin ingin menyembunyikan postingan ini? Postingan akan tersembunyi secara permanen',
    button: 'Sembunyikan'
  }
};

export default PostDeleteComponent;
