import { Carousel } from 'antd';
import styled from 'styled-components';
import Constants from '../../../Config/Constants';

export default {
  container: {
    marginBottom: 11.2,
    width: '100%',
    borderRadius: 2.5,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    backgroundColor: Constants.COLORS.WHITE,
    height: 'max-content',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  arrowsWrapper: {
    padding: '0px 12.3px',
    zIndex: 1,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    position: 'absolute',
    width: '100%',
    margin: 'auto 0px',
    pointerEvents: 'none'
  },
  arrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5px',
    borderRadius: '3px',
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    cursor: 'pointer',
    backgroundColor: Constants.COLORS.WHITE,
    pointerEvents: 'auto'
  }
};

export const CarouselWrapper = styled(Carousel)`
  > .slick-dots li button {
    width: 6px;
    height: 6px;
    border-radius: 100%;
  }
  > .slick-dots li.slick-active button {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: ${Constants.COLORS.DARK_BLUE};
  }

  img {
    cursor: pointer;
  }
`;
