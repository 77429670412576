import { gql } from 'apollo-boost';

export const ADD_MEMBER = gql`
  mutation($email: String!, $classroomId: String!) {
    classroomAddMemberByEmail(email: $email, classroomId: $classroomId) {
      id
      name
      avatar
    }
  }
`;
