import React, { useState } from 'react';
import UploadImageComponent from './UploadImage.component';

function UploadImageContainer(props) {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async file => {
    setPreviewImage(file.response);
    setPreviewVisible(true);
  };

  return (
    <UploadImageComponent
      handleCancel={handleCancel}
      handlePreview={handlePreview}
      previewVisible={previewVisible}
      previewImage={previewImage}
      {...props}
    />
  );
}

export default UploadImageContainer;
