import React from 'react';
import { Col, Row } from 'antd';
import { PostList, SidebarGroupInfoBox, Widget } from '../../../Containers';
import Constants from '../../../Config/Constants';

const DiscussionComponent = props => {
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarGroupInfoBox {...props} />
        </Col>
        <Col id={'team-post'} span={12}>
          <PostList postType={Constants.POST_TYPE.TEAM} {...props} />
        </Col>
        <Col span={6}>
          <Widget {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default DiscussionComponent;
