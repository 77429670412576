import styled from "styled-components";

export const WrapperContact = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 30px 0px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    .icon {
      margin-right: 5px;
    }
    .name {
      font-size: 16px;
      color: #014a62;
      margin: 0px;
      font-weight: normal;
    }
  }
`;
export const WrapperItemContact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 33px;
  .content {
    max-width: 420px;
    p {
      margin: 0px;
    }
    h3 {
      margin: 0px;
    }
    .name {
      font-size: 14px;
      color: #000;
      margin-bottom: 4px;
    }
    .link {
      font-size: 12px;
      color: #a9a8a8;
      margin-bottom: 14px;
    }
    .main-contact {
      font-size: 12px;
      padding: 13px 20px;
      border-radius: 5px;
      background-color: #014a62;
      color: #fff;
      margin-bottom: 19px;
      max-width: 150px;
    }
    .set-main {
      font-size: 12px;
      color: #039be5;
      margin-bottom: 25px;
      cursor: pointer;
    }
  }
  .action {
  }
`;

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #039be5;
  padding: 24px 0px;
  font-size: 16px;
  font-weight: 600;
`;
