import React from 'react';
import UserCard from '../UserCard';
import { EmployeeListCardWrapper } from './EmployeeListCard.style';
import { Row, Col } from 'antd';
import DisplayLoading from '../DisplayLoading';

const EmployeeListCardComponent = props => {
  const { dataEmployees, idList, isFetching } = props;

  const handleClickUser = userId => {
    props.history.push(`/profile/${userId}/detail`);
  };

  return (
    <EmployeeListCardWrapper style={{ width: '100%' }}>
      <div style={{ width: 'auto' }} className={'employee-row'}>
        <Row id={idList} gutter={24}>
          {dataEmployees.map(user => (
            <Col sm={24} md={12} xl={8} key={user.id}>
              <UserCard
                key={user.id}
                onClick={userId => handleClickUser(userId)}
                contentDirection={'vertical'}
                avatarSize={120}
                user={user}
                {...props}
              />
            </Col>
          ))}
        </Row>
        {isFetching && (
          <div style={{ margin: '20px auto' }}>
            <DisplayLoading />
          </div>
        )}
      </div>
    </EmployeeListCardWrapper>
  );
};

export default EmployeeListCardComponent;
