import React from 'react';
import { Avatar, List } from 'antd';
import 'antd/dist/antd.css';
import { FollowedBy } from '../../Pages/Search/SearchPage.style';
import { ButtonWlb } from '../../Components';
import {
  GroupDescription,
  GroupDetail,
  GroupFollower,
  GroupTitle,
  GroupName
} from './TeamResultList.style';
import './TeamResultList.css';
import { Link } from 'react-router-dom';

const GroupResultComponent = props => {
  const { team, isJoin,isRequest, joinGroupHandler } = props;
  const totalMember =
    (team.member && team.member.length) + (team.admin && team.admin.length);

  return (
    <List.Item>
      <List.Item.Meta
        className={'team-description'}
        avatar={<Avatar shape={'circle'} size={64} src={team.avatar} />}
        title={
          <GroupTitle>
            <Link to={`/group/${team.id}/about`}>
              <GroupName>{team.name}</GroupName>
            </Link>
            <GroupFollower>
              <span>Group &#9702;</span>
              <span> {totalMember} people</span>
            </GroupFollower>
          </GroupTitle>
        }
        description={
          <GroupDetail>
            <GroupDescription>{team.description}</GroupDescription>
            <FollowedBy className={'team-member'}>
              {/* {team.member.map(m =>
                      <Avatar key={m.link} shape={"circle"} size={40} src={m.link}/>
                  )} */}
            </FollowedBy>
          </GroupDetail>
        }
      />
      <ButtonWlb
        type={isJoin | isRequest ? 'default' : 'primary'}
        size={'small'}
        style={{ width: 85 }}
        onClick={() => joinGroupHandler(team)}
      >
        {isRequest ? 'Requested' : isJoin ? 'Joined' :'Join'}
      </ButtonWlb>
    </List.Item>
  );
};

export default GroupResultComponent;
