import { Select } from "antd";
import styled from "styled-components";

export const FalilyCom = styled(Select)`
  & > .ant-select-selection {
    & > .ant-select-selection__rendered {
      & > ul {
        & > .ant-select-selection__choice {
          display: none;
        }
      }
      .ant-select-selection__placeholder {
        margin-left: 10px;
        display: block !important;
      }
      .ant-select-search__field {
        padding: 1px 0px;
      }
    }
  }
`;
export const WrapperItemFamily = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px 22px 0px;
  .item {
    padding: 6px 19px;
    background-color: #014a62;
    border-radius: 16px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    p {
      margin: 0px;
      color: #fff;
      font-size: 12px;
      font-weight: normal;
      margin-right: 19px;
    }
  }
`;
