import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import SidebarComponent from './SidebarProfile.component';
import { AuthenticationService, ConnectionService } from '../../Services';

const SidebarProfile = props => {
  const { history } = props;
  const [userFollowing, setUserFollowing] = useState(0);
  const [userFollower, setUserFollower] = useState(0);
  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const user = AuthenticationService.getUserData();
  const userId = user.id;

  useEffect(() => {
    const source = Axios.CancelToken.source();
    const promise = ConnectionService.totalFollowerFollowing(userId, {
      cancelToken: source.token
    });

    promise.then(
      response => {
        setUserFollowing(response.data.total_following);
        setUserFollower(response.data.total_follower);
        setIsFetchingProfile(false);
      },
      err => {
        if (Axios.isCancel(err)) {
          return;
        }

        setIsFetchingProfile(false);
      }
    );

    return () => source.cancel();
  }, [userId]);

  return (
    <SidebarComponent
      userInfo={user}
      userFollower={userFollower}
      userFollowing={userFollowing}
      isFetchingProfile={isFetchingProfile}
      history={history}
    />
  );
};

export default SidebarProfile;
