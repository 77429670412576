import gql from 'graphql-tag';

const GET_GLOBAL_PROFILE_SETTING = gql`
  query {
    global_people_profile_setting_sections(order_by: { id: asc }) {
      id
      name
      value
      description
    }
  }
`;
const GET_USER_PROFILE_SETTING = gql`
  query($id: uuid) {
    people_profile(where: { id: { _eq: $id } }) {
      id
      settings_section
    }
  }
`;

const UPDATE_USER_PROFILE_SETTING = gql`
  mutation($id: uuid, $setting: jsonb) {
    update_people_profile(
      _set: { settings_section: $setting }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        settings_section
      }
    }
  }
`;

export {
  GET_GLOBAL_PROFILE_SETTING,
  GET_USER_PROFILE_SETTING,
  UPDATE_USER_PROFILE_SETTING
};
