import React from 'react';
import { List } from 'antd';
import GroupResultContainer from './TeamResult.container';
import './TeamResultList.css';

const GroupResultList = props => {
  const { searchedGroupResults } = props;

  return (
    <List
      className={'main'}
      dataSource={searchedGroupResults}
      renderItem={team => <GroupResultContainer team={team} />}
    />
  );
};

export default GroupResultList;
