import React from 'react';
import PostCardContainer from './PostCard.container';
import { PostService } from '../../Services';

function UserPostCard(props) {
  const post = props.itemPost;

  //   const _handleCancel = () => {
  //     setDisplayShare(false);
  //   };
  const handleLike = likeValue => {
    return PostService.postLike(post.userId, post.id, likeValue);
  };
  return <PostCardContainer {...props} handleLike={handleLike} />;
}
export default UserPostCard;
