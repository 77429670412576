import React from 'react';
import OrderDetailComponent from './OrderDetail.component';
import { useQuery, useApolloClient } from 'react-apollo';
import {
  GET_ORDER_DETAIL,
  UPDATE_ORDER_PAYMENT,
  UPDATE_ORDER_STATUS
} from './OrderDetail.graphql';
import { message } from 'antd';

const context = { headers: { 'X-Hasura-Role': 'organization-administrator' } };

const OrderDetailContainer = props => {
  const { orderId, onPageBack } = props;

  const { data, error, refetch } = useQuery(GET_ORDER_DETAIL, {
    fetchPolicy: 'cache-and-network',
    context: context,
    variables: {
      id: orderId
    }
  });

  const client = useApolloClient();

  const handleStatusChange = (status, id_shipping = null) => {
    const id = data.invoice.id;

    let mutation = UPDATE_ORDER_PAYMENT;
    let variables;

    switch (status) {
      case 'rejected': {
        variables = { id: id, status: status, paymentStatus: status };
        break;
      }
      case 'refund_paid': {
        variables = { id: id, status: status, paymentStatus: 'refunded' };
        break;
      }
      case 'paid':
      case 'completed':
      case 'review': {
        variables = { id: id, status: status, paymentStatus: 'paid' };
        break;
      }
      default: {
        mutation = UPDATE_ORDER_STATUS;
        variables = {
          id: `${id}`,
          status: status,
          shippingId: id_shipping || data.invoice.delivery_data?.id_shipping
        };
        break;
      }
    }

    const promise = client.mutate({
      mutation: mutation,
      context: context,
      variables: variables
    });

    promise.then(
      () => {
        message.success(`Status set to ${status}`);
        refetch();
      },
      err => {
        console.error(err);
        message.error(`Failed to set status to ${status}`);
      }
    );
  };

  return (
    <OrderDetailComponent
      orderId={orderId}
      data={data?.invoice}
      error={error}
      onStatusChange={handleStatusChange}
      onPageBack={onPageBack}
    />
  );
};

export default OrderDetailContainer;
