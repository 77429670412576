import React, { useState, useEffect } from 'react';
import DetailPostComponent from './DetailPost.component';
import CompanyPost from '../../Services/Company/Post';
import CompanyTimeline from '../../Services/Company/Timeline';
import { PostService } from '../../Services';
import { displayLoading } from '../../Utils/CommonUtils';
import { message } from 'antd';

const DetailPostContainer = props => {
  const [itemPost, setItemPost] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const idPost = props.match.params.postId;
  const userId = props.match.params.userId;
  const [postType, setPostType] = useState('company');

  useEffect(() => {
    const _fetchGetPost = async () => {
      await CompanyPost.getPost(idPost, userId)
        .then(res => {
          setItemPost(res);
          setIsLoading(false);
          setPostType('company');
        })
        .catch(() => {
          PostService.getPostId(userId, idPost)
            .then(res => {
              setItemPost(res);
              setIsLoading(false);
              setPostType('profile');
            })
            .catch(() => {
              CompanyTimeline.getPostId(userId, idPost)
                .then(res => {
                  setItemPost(res);
                  setIsLoading(false);
                  setPostType('companyTimeline');
                })
                .catch(() => {
                  message.warning('Data not found');
                });
            });
        });
    };

    _fetchGetPost();
  }, [idPost, userId]);

  const handleVote = async (postId, optionId) => {
    let post = itemPost;

    await PostService.votePoll(userId, postId, optionId)
      .then(res => {
        post = { ...post, poll: { ...post.poll, ...res } };
        setItemPost({ ...post });
        message.success('Vote Success Submitted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  const handleUndoVote = async postId => {
    let post = itemPost;

    await PostService.undoVote(userId, postId)
      .then(response => {
        post = { ...post, poll: { ...post.poll, ...response.poll } };
        setItemPost({ ...post });
        message.success('Vote Deleted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <div>
      {isLoading ? (
        displayLoading(isLoading)
      ) : (
        <DetailPostComponent
          itemPost={itemPost}
          postType={postType}
          listType={
            postType === 'companyTimeline' ? 'company-timeline-detail' : null
          }
          companyId={userId}
          handleVote={handleVote}
          handleUndoVote={handleUndoVote}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          {...props}
        />
      )}
    </div>
  );
};

export default DetailPostContainer;
