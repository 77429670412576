import React from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal, Icon, message } from 'antd';

function UploadImageComponent(props) {
  const {
    acceptedFormat,
    fileList,
    handleCancel,
    handlePreview,
    previewImage,
    handleChange,
    previewVisible,
    handleUpload,
    target,
    maxUpload = 1,
    setIsUploading
  } = props;

  const uploadValidation = value => {
    const isJpgOrPng =
      value.file.type === 'image/jpeg' || value.file.type === 'image/png';
    const imageSize = value.file.size / 1024 / 1024 < 25;
    if (isJpgOrPng && imageSize) {
      handleChange(target, value.fileList.slice(0, maxUpload));
    } else if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file.');
    } else if (!imageSize) {
      message.error('You can only upload < 25MB');
    }
  };

  return (
    <div>
      <Upload
        accept={acceptedFormat}
        listType="picture-card"
        fileList={fileList}
        customRequest={handleUpload}
        onPreview={handlePreview}
        onChange={value => {
          if (setIsUploading) {
            setIsUploading(value.file.status === 'uploading');
          }
          uploadValidation(value);
        }}
        multiple
        showUploadList={{ showDownloadIcon: false }}
        // disabled={fileList.length >= 5}
      >
        {maxUpload !== undefined ? (
          fileList.length < maxUpload && (
            <Icon className="ant-upload-text" type="plus" />
          )
        ) : (
          <Icon className="ant-upload-text" type="plus" />
        )}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
}

export default UploadImageComponent;
