import Styled from "styled-components";

export const WrapperScroll = Styled.div`
  max-height: ${props => props.maxHeight ? props.maxHeight : '450px'};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16); 
    border-radius: 5px;
  }
`

export const WrapperHobbies = Styled.div`
  display: flex; 
  flex-direction: column;
  
  .content {
    display: flex; 
    flex-direction: row;
    padding: 30px 25px;

    .hobbies { 
      position: relative;
      width: 171px;
      height: 50px;
      text-align: center;
      border: 1px solid #e5e5e5;
      border-radius: 41px;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      padding: 0px 20px 0px 63px;
      margin-right: 32px;
      
      .hobbies-img {
        position: absolute;
        top: -4px;
        left: 0;
        height: 55px;
        width: 55px;
        background-color: #014a62;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 50%;
          width: auto;
        }
      }
    }

    .detail-section { 
      display: flex;
      flex-direction: column;
      margin-right: auto;
      
      .name {
        font-size: 14px;
        color: #014a62;
        margin-bottom: 20px;
        font-weight: 600;
      }

      .wrapper-category {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .detail-hobbies {
          margin: 0px 10px 10px 0px;
          background-color: #fff;
          border-radius: 60px;
          border: solid 1px #014a62;
          padding: 7px 21px;
          font-size: 12px;
          color: #014a62;
        }
      }
      
      .description {
        font-size: 12px;
        color: #000000;
        margin-bottom: 21px;
      }

      .share {
        font-size: 12px;
        color: #a9a8a8;
        margin-bottom: 10px;
      }

      .wrapper-recommendation {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: 580px;
    margin-left: auto
  }
`;
