import React from 'react';

import { Input } from 'antd';
import GoogleMapReact from 'google-map-react';

import { TopSettingGroup } from '../../Components';
import {
  CompanyAddressWrapper,
  AddressItemWrapper
} from './CompanyAddressBox.style';

import Images from '../../Assets/images';

const CompanyAddressBoxComponent = props => {
  const {
    data,
    loading,
    selected,
    selectedItem,
    active,
    onSearchInput,
    onSelectedChange,
    onDetailsClick
  } = props;

  const lat = selectedItem?.address_field.lat;
  const lng = selectedItem?.address_field.lng;

  return (
    <div>
      <TopSettingGroup title="Kantor" color="black" />

      <CompanyAddressWrapper>
        <div className="aside">
          <Input.Search
            placeholder="Cari Kantor"
            onChange={onSearchInput}
            className="search"
          />

          {!data.length && loading && <div>Loading</div>}

          {data.length > 0 ? (
            data.map(item => (
              <AddressItemWrapper
                key={item.id}
                selected={item.id === selected}
                onClick={() => onSelectedChange(item)}
              >
                <h3 className="name">{item.office_name}</h3>
                {item.id === active && <h4 className="active">Main Office</h4>}
                <p className="address">{item.address}</p>
                <button className="detail" onClick={onDetailsClick}>
                  Detail
                </button>
              </AddressItemWrapper>
            ))
          ) : (
            <AddressItemWrapper>
              <h3 className="name">Tidak ada hasil yang ditemukan</h3>
              <p className="address">
                Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan
                pencarian Anda
              </p>
            </AddressItemWrapper>
          )}
        </div>
        <div className="main">
          <GoogleMapReact
            center={{ lat, lng }}
            defaultZoom={16}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GMAPS_KEY,
              libraries: 'places'
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            <img
              src={Images.LOCATION_BLUE}
              style={{ width: 18 }}
              lat={lat}
              lng={lng}
            />
          </GoogleMapReact>
        </div>
      </CompanyAddressWrapper>
    </div>
  );
};

export default CompanyAddressBoxComponent;
