import styled from 'styled-components';

const CommentBoxWrapper = styled.div`
  position: relative;
  display: block;
  padding-top: 5px;
  border-radius: 3px;
  background-color: #ffffff;

  .comment-box {
    width: 100%;
    display: inline-block;
    .photo {
      width: 12%;
      width: 35px;
      height: 35px;
      float: left;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .input-box {
      padding-left: 10px;
      width: 83%;
      @media (max-width: 1199px) {
        width: 75%;
      }
      float: left;
      .form-input {
        border: none;
      }
    }
    .right {
      float: right;
      button {
        box-shadow: none;
        border: none;
        padding: 3px;
      }
    }
  }
`;

export { CommentBoxWrapper };
