import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import _ from 'lodash';
import {
  // ContentLink,
  PostAttachment,
  PostImage,
  PostTaggedPeople,
  PostVideo,
  ModalSharePostBox,
  PostContent,
  RenderLocation,
  KudosPostCard,
  PostPollItem
} from '../../Components';
import { CommentList } from '..';
import { PostCardWrapper } from './PostCard.style';
import Assets from '../../Assets';
import PostDeleteContainer from '../PostDelete/PostDelete.container';
import moment from 'moment';
import { AuthenticationService } from '../../Services';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import EventContainer from './Event.graphql';
import TaskContainer from './Task.graphql';
import KudosComponent from './kudos';
import ModalShowLike from './ModalShowLike.component';

const PostCardComponent = props => {
  const {
    itemPost,
    postAwardHandler,
    postLikeHandler,
    totalLike,
    award,
    isUserLiked,
    dataShare,
    handleShare,
    displayModalShare,
    handleCancelShare,
    postType,
    deletePost,
    restartPage,
    match,
    setLoading,
    dataCompany,
    isAdmin,
    postKudosHandler,
    kudosLength,
    editPost,
    handleVote,
    handleUndoVote
  } = props;

  const profileId = AuthenticationService.getUserId();
  const { user } = itemPost;
  const postId = itemPost.id;
  const postOwnerId = itemPost.userId;
  const postLifeTime = moment(itemPost.date_added).fromNow();
  const [totalComment, setTotalComment] = useState(0);
  // const [isKudosShow, setIsKudosShow] = useState(false);
  const [isKudosPopupShow, setIsKudosPopupShow] = useState(false);
  // const [activeTabKudos, setActiveKudosTab] = useState(0);
  const [showLike, setShowLike] = useState(false);

  useEffect(() => {
    if (itemPost) {
      setTotalComment(itemPost.total_comment);
    }
  }, [itemPost]);

  // const [userHasGiveKudo, setUserHasGiveKudo] = useState(false);
  const userData = AuthenticationService.getUserData();
  const logoCompany =
    dataCompany?.main_profile?.[0]?.logo ?? Assets.DEFAULT_PHOTO;
  const brandName = dataCompany?.main_profile?.[0]?.brand_name
    ? dataCompany?.main_profile?.[0]?.brand_name
    : '';
  const avatar =
    user?.avatar !== '' && user?.avatar ? user?.avatar : Assets.DEFAULT_PHOTO;

  const companyId = dataCompany?.main_profile?.[0]?.id
    ? dataCompany?.main_profile?.[0]?.id
    : '';
  const postedBy = isAdmin && user ? `posted by ${user.name}` : '';
  const peoplePosition = user?.people_work_placements?.[0]?.company_job_profile
    ?.title
    ? user?.people_work_placements?.[0]?.company_job_profile?.title + ' at '
    : '';
  const peopleCompany =
    user?.people_work_placements?.[0]?.company_profile?.brand_name ?? '-';

  function SharedPost({ shared }) {
    const avatar =
      shared.user?.avatar !== '' && shared.user?.avatar
        ? shared.user?.avatar
        : Assets.DEFAULT_PHOTO;
    const sharedLifeTime = moment(shared.date_added).fromNow();
    const peoplePositionShared = shared.user?.people_work_placements?.[0]
      ?.company_employee_position?.name
      ? shared.user?.people_work_placements?.[0]?.company_employee_position
          ?.name + ' at '
      : '';
    const peopleCompanyShared =
      shared.user?.people_work_placements?.[0]?.company_profile?.brand_name ??
      '-';
    const isEmptyShare =
      !shared.content &&
      !shared?.images &&
      !shared?.videos &&
      !shared?.files &&
      isEmpty(shared.tags);
    if (!isEmptyShare) {
      return (
        <PostCardWrapper>
          <div className="author">
            <div className="photo">
              <Avatar
                shape={'circle'}
                src={(userData?.thumbnail_url ?? '') + avatar}
                icon={'user'}
              />
            </div>
            <div className="info">
              <Link to={'/profile/' + shared.user?.id + '/detail'}>
                <span className="name">{shared.user?.name}</span>
              </Link>
              <span className="desc">
                {peoplePositionShared + peopleCompanyShared}
              </span>
            </div>
            <div className="right">
              <div className="time">
                <span>{sharedLifeTime}</span>
              </div>
            </div>
          </div>

          <div className="content">
            {shared.event !== null && <EventContainer eventId={shared.event} />}

            {shared.type !== 4 && <PostContent content={shared.content} />}
            {!isEmpty(shared.poll) && (
              <PostPollItem
                itemPost={shared}
                handleVote={handleVote}
                profileId={profileId}
                handleUndoVote={handleUndoVote}
                sharedPost
              />
            )}
            {!isEmpty(shared.images) && <PostImage images={shared.images} />}
            {!isEmpty(shared.videos) && <PostVideo videos={shared.videos} />}
            {!isEmpty(shared.files) && <PostAttachment file={shared.files} />}

            {!isEmpty(shared.kudos) && (
              <KudosPostCard
                img={shared.kudos?.url_image}
                kudosName={shared.kudos?.name}
                name={shared.kudos?.to}
                description={shared.kudos?.desc}
              />
            )}
            {!isEmpty(shared.location) && (
              <RenderLocation coordinates={shared.location} />
            )}
            {!isEmpty(shared.tags) && (
              <PostTaggedPeople taggedUsers={shared.tags} />
            )}
          </div>
        </PostCardWrapper>
      );
    } else
      return (
        <PostCardWrapper>
          <div className="deleted_share_title">Konten ini tidak tersedia</div>
          <div className="deleted_share_content">
            Jika hal ini terjadi, postingan telah dihapus oleh pemilik
          </div>
        </PostCardWrapper>
      );
  }

  const handleShowLike = () => {
    if (totalLike > 0) {
      setShowLike(!showLike);
    }
  };

  return (
    <div>
      <PostCardWrapper>
        <div className="author">
          <div className="photo">
            <Avatar
              shape={postType === 'companyTimeline' ? 'square' : 'circle'}
              src={postType === 'companyTimeline' ? logoCompany : avatar}
              icon={'user'}
            />
          </div>
          <div className="info">
            <div className="name-container">
              <Link
                to={
                  postType === 'companyTimeline'
                    ? `/company/${companyId}/post`
                    : `/profile/${user.id}/detail`
                }
              >
                <span className="name">
                  {postType === 'companyTimeline' ? brandName : user.name}
                </span>
              </Link>
              <span className="desc">
                {postType === 'companyTimeline'
                  ? postedBy
                  : peoplePosition + peopleCompany}
              </span>
            </div>
            <div className="pos-container">
              {(postOwnerId === profileId || postType === 'global') && (
                <>
                  <PostDeleteContainer
                    itemPost={itemPost}
                    deletePost={deletePost}
                    canEdit={itemPost.type === 3}
                    editPost={editPost}
                    isNotOwner={
                      postType === 'global' && !(postOwnerId === profileId)
                    }
                  />
                </>
              )}
              <div className="time">
                <span>{postLifeTime}</span>
              </div>
            </div>

            {itemPost.type === 3 && (
              <span className="learning-tracker">Catatan Pembelajaran</span>
            )}
          </div>
        </div>

        <div className="content">
          {itemPost.type === 3 && (
            <>
              <div>
                <span className="activity">{itemPost.activity}</span>
                <span className="info">
                  {moment(itemPost.date).format('MMMM DD, YYYY')} -{' '}
                  {itemPost.duration} Minutes
                </span>
              </div>
              <div>
                <p className="description">{itemPost.description}</p>
              </div>
              <div>
                <span className="mentee">Peserta</span>
                <div className="image-wrapper">
                  {Array.isArray(itemPost.learning_tracker_mentees) &&
                    itemPost.learning_tracker_mentees
                      .slice(0, 4)
                      .map((mentee, idx) => {
                        const {
                          name,
                          avatar,
                          id
                        } = mentee.classroom_member.global_user;
                        return (
                          <a
                            key={idx}
                            href={'/profile/' + id + '/detail'}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={avatar} alt={name} className="image" />
                          </a>
                        );
                      })}
                  {Array.isArray(itemPost.learning_tracker_mentees) &&
                    itemPost.learning_tracker_mentees.length > 4 && (
                      <div className="more">
                        <span className="length">
                          +{itemPost.learning_tracker_mentees.length - 4}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
          {itemPost.type === 4 && (
            <PostPollItem
              itemPost={itemPost}
              handleVote={handleVote}
              handleUndoVote={handleUndoVote}
              profileId={profileId}
            />
          )}
          {!isEmpty(itemPost.task) && (
            <TaskContainer
              img={
                userData.company_logo ? userData.company_logo : Assets.LOGO_WLB
              }
              taskId={itemPost.task.taskId}
            />
          )}
          {!_.isNull(itemPost.event) && itemPost.event !== '' && (
            <EventContainer eventId={itemPost.event} />
          )}

          {itemPost.type !== 4 && <PostContent content={itemPost.content} />}

          {/* <ContentLink content={itemPost.content} /> */}
          {!isEmpty(itemPost.images) && <PostImage images={itemPost.images} />}
          {!isEmpty(itemPost.videos) && <PostVideo videos={itemPost.videos} />}
          {!isEmpty(itemPost.files) && <PostAttachment file={itemPost.files} />}

          {!isEmpty(itemPost?.kudos) && (
            <KudosPostCard
              img={itemPost?.kudos?.url_image}
              kudosName={itemPost?.kudos?.name}
              name={itemPost?.kudos?.to}
              description={itemPost?.kudos?.desc}
            />
          )}

          {!isEmpty(itemPost.location) && (
            <RenderLocation coordinates={itemPost.location} />
          )}
          {!isEmpty(itemPost.tags) && (
            <PostTaggedPeople taggedUsers={itemPost.tags} />
          )}
          {!isEmpty(itemPost.sharedPost) && (
            <SharedPost shared={itemPost.sharedPost} />
          )}
        </div>

        <div className="action-group">
          <div>
            <div className="item">
              <span
                className="text cursor_context_menu pointer"
                onClick={handleShowLike}
              >
                Menyukai ({totalLike})
              </span>
            </div>
            <div className="itemPost right cursor_context_menu">
              <span className="text">Komentar ({totalComment}) &nbsp; </span>
              {postType !== 'company' &&
                postType !== 'companyTimeline' &&
                postType !== 'team' && (
                  <span className="text">
                    <span className="component_dot" />
                    &nbsp;Dibagikan (
                    {itemPost.count_shared_post
                      ? itemPost.count_shared_post
                      : 0}
                    )&nbsp;
                  </span>
                )}
              {postType === 'company' && (
                <span
                  className="text kudos"
                  onClick={() => setIsKudosPopupShow(true)}
                >
                  <span className="component_dot" /> &nbsp;Kudos ({kudosLength})
                </span>
              )}
            </div>
          </div>
          <div className="second_row">
            <div className="item">
              <span
                className={isUserLiked ? 'icon love-pink' : 'icon love'}
                onClick={() => postLikeHandler(itemPost, postType)}
              />
            </div>
            <div className="item">
              <span className="icon comment" />
            </div>
            {(postType === 'global' ||
              (postType === 'profile' && itemPost.privacy === 0)) && (
              <div className="item" onClick={() => handleShare(itemPost)}>
                <span className="icon share" />
              </div>
            )}
            <div
              className="itemPost right"
              hidden={profileId === itemPost.canAward ? false : true}
            >
              <div
                className={award ? 'award' : ''}
                onClick={() => postAwardHandler()}
              >
                <span className={'icon star'} />
              </div>
            </div>
            {postType === 'company' && itemPost.kudosBy && (
              <div className="itemPost right">
                <div className="item kudos">
                  <KudosComponent
                    isKudosPopupShow={isKudosPopupShow}
                    setIsKudosPopupShow={setIsKudosPopupShow}
                    postKudosHandler={postKudosHandler}
                    kudosLength={kudosLength}
                    itemPost={itemPost}
                    postId={itemPost?.id}
                    {...props}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="comment">
          <CommentList
            postId={postId}
            postOwnerId={postOwnerId}
            postType={postType}
            match={props.match}
            totalComment={totalComment}
            setTotalComment={setTotalComment}
            setIsLoading={props.setLoading}
            {...props}
          />
        </div>
      </PostCardWrapper>
      {displayModalShare && (
        <ModalSharePostBox
          display={displayModalShare}
          dataShare={dataShare}
          handleCancel={handleCancelShare}
          postType={postType}
          restartPage={restartPage}
          match={match}
          setLoading={setLoading}
        />
      )}
      <ModalShowLike
        userId={itemPost.userId}
        postId={itemPost.id}
        open={showLike}
        handleClose={handleShowLike}
        postType={postType}
      />
    </div>
  );
};

export default PostCardComponent;
