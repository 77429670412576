import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import { Form, Input, Switch, message } from 'antd';
import { SelectStyled, TitleField } from '../DetailScreen.style';
import Axios from 'axios';
import { useMutation } from 'react-apollo';
import {
  ADD_PUBLICATION,
  DELETE_PUBLICATION,
  UPDATE_PUBLICATION
} from './Publication.graphql';
import { generateDateFormat, getErrorMessage } from '../HelperDetailScreen';
import moment from 'moment';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom,
  FormDateInline
} from '../../../../SharedComponents';
import { AuthenticationService } from '../../../../Services';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import { month } from '../../../../Containers/UserProfileAbout/HelperAbout';

const AddPublicationComponent = ({
  open,
  onClose,
  openState,
  onCloseModal,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields
  } = form;
  const { TextArea } = Input;
  const [deleteState, setDeleteState] = useState({ open: false });
  const [share, setShare] = useState(false);

  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorMax, setErrorMax] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [addPublication] = useMutation(ADD_PUBLICATION, {
    context: CONTEXT_USER
  });
  const [updatePublication] = useMutation(UPDATE_PUBLICATION, {
    context: CONTEXT_USER
  });
  const [deletePublication] = useMutation(DELETE_PUBLICATION, {
    context: CONTEXT_USER
  });

  useEffect(() => {
    if (actionType === 'edit') {
      setAttachment(data?.attachments ?? []);
      setLoadingUpload(false);

      setShare(data?.is_share);
    } else if (actionType === 'add') {
      setAttachment([]);
      setLoadingUpload(false);
      setShare(false);
    }
  }, [data, setFieldsValue, people_profile, actionType]);

  const handleChange = (formValue, target) => {
    if (target === 'upload-attachment') {
      uploadAttachment(formValue);
    }
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setErrorMax(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (tempFile.length + attachment.length > 5) {
      setErrorMax(true);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
      Array.from(document.querySelectorAll('#upload-publication')).forEach(
        input => (input.value = '')
      );
    }
  };
  const listFields = [
    {
      labelName: 'Judul Publikasi',
      fieldName: 'publication_title',
      type: 'text',
      fieldValue: data && data?.publication_title,
      placeholder: 'Tambahkan judul publikasi',
      required: true
    },
    {
      labelName: 'Media Publikasi',
      fieldName: 'publication_media',
      type: 'text',
      fieldValue: data && data?.publication_media,
      placeholder: 'Tambahkan media publikasi',
      required: true
    },
    {
      labelName: 'Penulis',
      fieldName: 'authors',
      type: 'text-multiple',
      fieldValue: data?.authors ?? [],
      placeholder: 'Tambahkan penulis',
      required: true
    },
    {
      labelName: 'Tautan Publikasi',
      fieldName: 'publication_url',
      type: 'text',
      fieldValue: data && data?.publication_url,
      placeholder: 'Tambahkan tautan publikasi',
      required: false
    }
  ];

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const objects = {
          is_share: share,
          publication_title: values.publication_title,
          publication_media: values.publication_media,
          authors: values.authors,
          publication_url: values.publication_url,
          description: values.description,
          publication_date: generateDateFormat(
            moment(`${values.year}/${values.month}/${values.day}`, 'YYYY/MM/DD')
          ),
          attachments: attachment
        };
        if (actionType === 'add') {
          addPublication({
            variables: { objects }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil menambahkan publikasi`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan publikasi, ${err}`);
            });
        } else if (actionType === 'edit') {
          updatePublication({
            variables: { id: data.id, objects: objects }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil mengubah publikasi`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah publikasi, ${err}`);
            });
        }
      }
    });
  };

  const _switchComponentToRender = res => {
    const { type, placeholder } = res;
    if (type === 'text') {
      return (
        <Input
          mode="tags"
          placeholder={placeholder}
          style={{ width: '100%' }}
        />
      );
    } else if (type === 'text-multiple') {
      return (
        <SelectStyled
          mode="tags"
          style={{ width: '100%' }}
          placeholder={`.${placeholder}`}
        />
      );
    }
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
    setLoadingUpload(false);
  };

  const handleDelete = () => {
    deletePublication({
      variables: {
        id: data.id,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        resetFields();
        refetch();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus publikasi`);
        onClose();
      })
      .catch(err => {
        message.error(`Gagal menghapus publikasi, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    // setShare(false);
  };

  const handleModalDelete = () => {
    setDeleteState({ open: true });
    onCloseModal();
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
  };

  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };
  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }
  const FieldCertificate_date = [
    {
      fieldName: 'day',
      fieldValue: generateDate(data && data.publication_date)?.[2],
      isRequired: true,
      placeholder: 'Tanggal',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue: generateDate(data && data.publication_date)?.[1],
      isRequired: true,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue:
        data &&
        data.publication_date !== 'Invalid date' &&
        data &&
        data.publication_date !== null
          ? generateDate(data && data.publication_date)?.[0]
          : undefined,
      isRequired: true,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];
  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'}  Publikasi`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data && data.description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan keterangan" />)}
          </Form.Item>

          <FormDateInline
            title="Tanggal Publikasi"
            required={true}
            date={FieldCertificate_date}
            getFieldDecorator={getFieldDecorator}
          />

          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-publication"
              disabled={(attachment.length === 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> There are file types that are not allowed</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorMax && <span>Jumlah lampiran tidak boleh lebih dari 5</span>}
            {attachment.length > 5 && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.length > 0 &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
            disabled={attachment.length > 5}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Publikasi"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditPublication = Form.create({ name: 'Add_Publication' })(
  AddPublicationComponent
);

export default AddEditPublication;
