import React from 'react';
import { Col, Row } from 'antd';

import {
  AboutClassroom,
  SidebarClassroomInfoBox,
  Mentorship
} from '../../../Containers';

const GroupAboutComponent = props => {

  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarClassroomInfoBox {...props} />
        </Col>
        <Col id={'classroom-post'} span={12}>
          <AboutClassroom {...props} />
        </Col>
        <Col span={6}>
          <Mentorship {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default GroupAboutComponent;
