import React from "react";
import { SkillsItem } from "./Skils.style";
import { Avatar, Icon } from "antd";
import { ButtonEdit } from "../../../../Components/DetailUserSection/DetailUserSection.style";
import { DirectToUserDetail } from "../../../../Components";

export default function SkilsItem({
  count,
  skils,
  people_profile_skills,
  count_people_skill,
  isFollowingFollowed,
  level,
  onClickDelete,
  onClickEdit,
  isUser,
  has_endorse,
  onUnendorsed,
  onEndorse,
  data,
  onClickMorePeople,
}) {
  return (
    <SkillsItem>
      <div className="content">
        <div className="skill-type">
          <div className="recommendation-count">{count}</div>
          {skils}
        </div>
        <div className="detail-section">
          <div className="level">{level}</div>
          {people_profile_skills.length > 0 ? (
            <>
              <div className="share-with">Bagikan keterampilan yang sama dengan :</div>
              <div className="endorse-wrapper">
                {people_profile_skills.slice(0, 4).map((user, idx) => (
                  <DirectToUserDetail key={idx} userId={user?.global_user?.id}>
                    <Avatar
                      src={user?.global_user?.avatar}
                      size={38}
                      style={{ marginRight: 8 }}
                    />
                  </DirectToUserDetail>
                ))}
                {count_people_skill > 4 && (
                  <Avatar
                    onClick={onClickMorePeople}
                    size={38}
                    style={{ marginRight: 8, cursor: "pointer" }}
                  >
                    +{count_people_skill - 4}
                  </Avatar>
                )}
              </div>
            </>
          ) : (
            <div className="dumy" />
          )}
        </div>
        {isUser ? (
          <>
            {onClickDelete && (
              <Icon
                className="icon-delete"
                type="delete"
                theme="filled"
                style={{ color: "#a9a8a8", cursor: "pointer" }}
                onClick={onClickDelete}
              />
            )}
            {onClickEdit && (
              <Icon
                className="icon-edit"
                theme="outlined"
                type="edit"
                style={{ color: "#a9a8a8", cursor: "pointer" }}
                onClick={onClickEdit}
              />
            )}
          </>
        ) : isFollowingFollowed ? (
          <ButtonEdit
            onClick={
              has_endorse > 0 ? () => onUnendorsed(data) : () => onEndorse(data)
            }
          >
            <Icon
              style={{
                backgroundColor: has_endorse > 0 ? "#fff" : "#039be5",
                borderRadius: "50%",
                padding: 5,
                color: has_endorse > 0 ? "#039be5" : "#fff",
                fontSize: 14,
                border: has_endorse > 0 && "1px solid #039be5",
              }}
              type={has_endorse > 0 ? "check" : "plus"}
            />
          </ButtonEdit>
        ) : (
          ""
        )}
      </div>
      <div className="divider" />
    </SkillsItem>
  );
}
