import React from 'react';
import MainContainer from './PostBox.container';
import { PostService } from '../../Services';
import config from './PostBox.config';
import { message } from 'antd';

function GlobalPostbox(props) {
  const onSubmit = (content, resetContent, formatBody, sharedPost) => {
    const bodyPost = formatBody(content);
    props.setLoading(true);
    PostService.createPost(bodyPost)
      .then(() => {
        if (sharedPost) {
          message.success('Berhasil membagikan postingan');
        } else {
          message.success('Postingan berhasil dikirim');
        }
      })
      .catch(() => {
        message.error('Something went wrong');
      })
      .finally(() => {
        props.setLoading(false);
        resetContent(config.initialData);
      });
  };
  return (
    <MainContainer onSubmit={onSubmit} postType={props.postType} {...props} />
  );
}

export default GlobalPostbox;
