import React, { useState } from 'react';

import config from './TeamHomePage.config';
import { Enhancer } from '../../SharedComponents';
import { Group, AuthenticationService } from '../../Services';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { MenuBar } from '../../Containers';
import { useAsyncEffect } from 'use-async-effect';
import { DisplayLoading } from '../../Components';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import DetailPostScreen from './PostDetail';

const GroupHomePageContainer = props => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const groupId = params.id;
  const currentPath = location.pathname.split('/')[3];
  const ownId = AuthenticationService.getUserId();
  const [menuBarList, setMenubarList] = useState(config.menuBarList);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isJoin, setIsJoin] = useState(true);
  const [isRequest, setIsRequest] = useState(true);
  // const [blockedMe, setBlockedMe] = useState();
  const [blockedMe] = useBlockMe();

  useAsyncEffect(
    async isMount => {
      setIsLoading(true);
      await Group.Discussion.fetchGroupInfo(groupId)
        .then(res => {
          if (!isMount()) return;
          if (res.admin.includes(ownId)) {
            setIsAdmin(true);
          } else {
            setMenubarList(() =>
              menuBarList.filter(menu => menu.name !== 'Setting')
            );
          }
          if (res.isJoined === false) {
            history.replace(`/group/${groupId}/about`);
          }
        })
        .finally(() => {
          if (!isMount()) return;
          setIsLoading(false);
        });
    },
    [groupId, ownId]
  );
  if (isLoading) {
    return <DisplayLoading />;
  } else {
    if (currentPath === 'post') {
      return (
        <DetailPostScreen
          // menuBarList={menuBarList}
          location={location}
          isAdmin={isAdmin}
          isRequest={isRequest}
          setIsRequest={setIsRequest}
          blockedMe={blockedMe}
          {...props}
        />
      );
    } else {
      return (
        <MenuBar
          menuBarList={menuBarList}
          location={location}
          isAdmin={isAdmin}
          isRequest={isRequest}
          setIsRequest={setIsRequest}
          blockedMe={blockedMe}
          {...props}
        />
      );
    }
  }
};

export default Enhancer({})(GroupHomePageContainer);
