import gql from "graphql-tag";

export const GET_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;
export const GET_PROVINCE_CITY_ID = gql`
  query($province: Int!, $city: Int!) {
    global_provinces(where: { id: { _eq: $province } }) {
      id
      code
      name
    }

    global_cities(where: { id: { _eq: $city } }) {
      id
      code
      name
    }
  }
`;
export const GET_CITY = gql`
  query($province: Int, $city: Int) {
    global_cities(
      where: { province: { _eq: $province }, id: { _eq: $city } }
      order_by: { name: asc }
      limit: 50
    ) {
      id
      code
      name
    }
  }
`;
export const ADD_ADDRESS = gql`
  mutation(
    $addressType: String!
    $provinceId: Int!
    $cityId: Int!
    $postalCode: String!
    $address: String!
    $isMain: Boolean!
    $address_fields: jsonb!
  ) {
    insert_people_addresses(
      objects: {
        name: $addressType
        province: $provinceId
        city: $cityId
        postal_code: $postalCode
        address: $address
        is_main: $isMain
        address_fields: $address_fields
      }
    ) {
      affected_rows
    }
  }
`;
export const LIST_ADDRESS = gql`
  query($user: uuid!) {
    people_addresses(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [{ is_main: desc_nulls_last, id: desc }]
    ) {
      id
      address_type: name
      province: global_province {
        id
        name
      }
      city: global_city {
        id
        name
      }
      postal_code
      address
      address_fields
      is_visible
      is_main
    }
  }
`;
export const UPDATE_ADDRESS = gql`
  mutation(
    $id: Int!
    $userId: uuid!
    $addressType: String!
    $provinceId: Int!
    $cityId: Int!
    $postalCode: String!
    $address: String!
    $isMain: Boolean!
    $address_fields: jsonb!
  ) {
    update_people_addresses(
      _set: {
        name: $addressType
        province: $provinceId
        city: $cityId
        postal_code: $postalCode
        address: $address
        is_main: $isMain
        address_fields: $address_fields
      },
      where: {
        _and: {
          id: {_eq: $id},
          user: {_eq: $userId}
        }
      }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_ADDRESS = gql`
  mutation($id: Int!) {
    update_people_addresses(
      _set: {deletedAt: "now()"}
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export const SET_MAIN_ADDRESS = gql`
  mutation($user: uuid!, $id: Int!) {
    a: update_people_addresses(
      _set: { is_main: false }
      where: { user: { _eq: $user } }
    ) {
      affected_rows
    }

    b: update_people_addresses(
      _set: { is_main: true }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
export const SET_UN_MAIN_ADDRESS = gql`
  mutation($user: uuid!) {
    update_people_addresses(
      _set: { is_main: false }
      where: { user: { _eq: $user } }
    ) {
      affected_rows
    }
  }
`;
export const SET_VISIBILITY_ADDRESS = gql`
  mutation($id: Int!, $userId: uuid!, $isVisible: Boolean) {
    update_people_addresses(
      _set: { is_visible: $isVisible }
      where: { _and: { id: { _eq: $id }, user: { _eq: $userId } } }
    ) {
      affected_rows
    }
  }
`;
