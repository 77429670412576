import styled from 'styled-components';
import Assets from '../../Assets';

const NavbarWrapper = styled.div`
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 100;

  .ic-notif-wrapper {
    display: flex;
    position: relative;
  }

  .badge-notif {
    position: absolute;
    background: red;
    border-radius: 50%;
    color: white;
    height: 20px;
    width: -webkit-fill-available;
    top: -12px;
    left: 11px;
    font-size: 10px;
    font-family: 'Muli';
    text-align: center;
    padding-top: 3px;
  }

  & .main {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    .ant-select-dropdown-menu {
      max-height: 661px !important;
    }

    .ant-select-dropdown-menu {
      max-height: 661px !important;
    }

    .flex-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .search-wrap {
      position: relative;
      display: inline-block;
      width: 50vw;
      height: 40px;
    }

    .ant-input-search-icon {
      color: #014a62;
    }

    .logo {
      position: relative;
      display: inline-block;
      width: 115px;
      height: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .menu-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      ul {
        margin: 0;
        padding: 0;
        float: left;
        width: 206px;
        display: flex;
        justify-content: space-between;
        margin-right: 38px;
        li {
          display: block;
          float: left;
          span {
            width: 20px;
            height: 20px;
          }

          .badge span {
            width: 20px;
            height: 20px;
            bottom: 10px;
            .ant-badge-count {
              width: 5px;
              height: 20px;
              padding: 0;
            }
          }
        }
      }
    }
  }
`;

const Icon = styled.span`
  display: block;
  background-size: contain;
  display: flex;
  flex-direction: row;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 24px !important ;
  height: 24px !important ;
  cursor: pointer;
  &.home {
    background-image: url(${Assets.ICON.HOME});
    width: 12px;
  }
  &.home-blue {
    background-image: url(${Assets.ICON.HOME_BLUE});
    width: 12px;
  }
  &.follow {
    background-image: url(${Assets.ICON.PEOPLE});
    width: 15px;
  }
  &.follow-blue {
    background-image: url(${Assets.ICON.PEOPLE_BLUE});
    width: 15px;
  }
  &.message {
    background-image: url(${Assets.ICON.MESSAGE});
    width: 12px;
    height: 10px;
  }
  &.notification {
    background-image: url(${Assets.ICON.NOTIFICATION});
    width: 15px;
  }
  &.notification-blue {
    background-image: url(${Assets.ICON.NOTIFICATION_BLUE});
    width: 15px;
  }
  &.message-icon {
    background-image: url(${Assets.ICON.MAIL});
    width: 15px;
  }
`;

const IconWrapper = styled.div`
  z-index: 1;
  display: flex;
  width: 20vw;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
`;
const Profile = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: red;
  display: block;
  float: right;
  background: url(${props => props.photo});
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 1px;
  cursor: pointer;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;

  .text {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    cursor: pointer;
    color: #014a62;
  }
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;
const ListNotifWrapper = styled.div`
  background-color: ${props => (props.read ? '#fff' : '#eff2f4')};
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: row;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px;
  &:before {
    content: ${props => (props.read ? null : '" "')};
    position: absolute;
    top: 12px;
    right: 12px;
    width: 8px;
    height: 8px;
    background-color: #ef4d5e;
    border-radius: 50%;
  }
  .body-notification {
    max-width: 265px;
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .content-notification {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 45px;
      font-size: 14px;
      color: #000000;
      margin-bottom: 1px;
      span {
        color: #039be5;
        font-weight: bold;
      }
      b {
        font-weight: 500;
        &:nth-child(-n + 2) {
          color: #039be5;
          font-weight: bolder;
        }
      }
    }
    .time {
      font-size: 12px;
      color: #a9a8a8;
    }
  }
`;

export {
  NavbarWrapper,
  Icon,
  Profile,
  IconWrapper,
  FooterWrapper,
  WrapperContent,
  ListNotifWrapper
};
