import gql from 'graphql-tag';

export const GET_LIST_BANK_ACCOUNT = gql`
  query getBankAccounts($userId: uuid!) {
    main_bank_account: people_work_salaries(
      where: {
        people_work_placement: {
          user: { _eq: $userId }
          status: { _eq: "ACTIVE" }
        }
        active: { _eq: true }
        deletedAt: { _is_null: true }
        people_profile_bank: { deletedAt: { _is_null: true } }
      }
      order_by: [{ id: desc }]
      limit: 1
    ) {
      id
      people_profile_bank {
        id
        account_name
        account_number
        creator_company
        global_bank {
          id
          code
          name
          logo
        }
      }
    }
    all_bank_accounts: people_profile_banks(
      where: { user: { _eq: $userId }, deletedAt: { _is_null: true } }
      order_by: [{ creator_company: asc }, { id: desc }]
    ) {
      id
      account_name
      account_number
      creator_company
      global_bank {
        id
        code
        name
        logo
      }
    }
  }
`;
export const GET_LIST_GLOBAL_BANK = gql`
  query {
    global_banks(
      where: { deletedAt: { _is_null: true } }
      order_by: [{ code: asc_nulls_last }, { name: asc_nulls_last }]
    ) {
      id
      name
    }
  }
`;
export const ADD_BANK_ACCOUNT = gql`
  mutation(
    $user: uuid
    $isMain: Boolean
    $bank: Int
    $bank_name: String
    $account_name: String
    $account_number: String
    $currency_code: String
  ) {
    unset: update_people_profile_banks(
      where: { user: { _eq: $user }, deletedAt: { _is_null: $isMain } }
      _set: { is_main: false }
    ) {
      affected_rows
    }

    insert_people_profile_banks(
      objects: {
        #user: $user
        bank: $bank
        bank_name: $bank_name
        account_name: $account_name
        account_number: $account_number
        currency_code: $currency_code
        is_main: $isMain
      }
    ) {
      affected_rows
    }
  }
`;

export const SET_MAIN_BANK_ACCOUNT = gql`
  mutation($userId: uuid!, $mainBankAccount: Int) {
    update_global_users(
      _set: { main_bank_account: $mainBankAccount }
      where: { id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;
export const EDIT_BANK_ACCOUNT = gql`
  mutation(
    $user: uuid!
    $isMain: Boolean
    $id: Int!
    $bank: Int
    $bank_name: String
    $account_name: String
    $account_number: String
    $currency_code: String
  ) {
    unset: update_people_profile_banks(
      where: { user: { _eq: $user }, deletedAt: { _is_null: $isMain } }
      _set: { is_main: false }
    ) {
      affected_rows
    }

    update_people_profile_banks(
      _set: {
        user: $user
        bank: $bank
        bank_name: $bank_name
        account_name: $account_name
        account_number: $account_number
        currency_code: $currency_code
        is_main: $isMain
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_BANK_ACCOUNT_USER = gql`
  mutation update_people_profile_banks(
    $id: Int
    $changes: people_profile_banks_set_input
  ) {
    update_people_profile_banks(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`;
