import React from 'react';
import { Skeleton } from 'antd';

const SkeletonComponent = props => {
  const { active, avatar, loading } = props;
  return (
    <Skeleton
      avatar={avatar ? avatar : null}
      active={active ? active : null}
      loading={loading}
    />
  );
};

export default SkeletonComponent;
