import React, { useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Images from '../../Assets/images';
import { AddressDesc, MapWrap } from './LocationPost.style';
import { Icon } from 'antd';

const LocationIcon = ({ text }) => (
  <>
    {/* <span>{text}</span> */}
    <img
      src={Images.LOCATION_BLUE}
      style={{ width: 18 }}
      alt={'Location Info'}
      // className="marker"
    />
  </>
);

const RenderMap = props => {
  const { coordinates } = props;
  const urlMaps = 'https://www.google.com/maps/search/?api=1&query=';
  return (
    <>
      {coordinates.latitude && coordinates.longitude ? (
        <>
          <MapWrap>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GMAPS_KEY
              }}
              // style={{ width: '50%', height: '50%' }}
              center={{
                lat: coordinates.latitude,
                lng: coordinates.longitude
              }}
              zoom={17}
              options={{ fullscreenControl: false }}
            >
              <LocationIcon
                // text={address.split(',')[0]}
                lat={coordinates.latitude}
                lng={coordinates.longitude}
              />
            </GoogleMapReact>
          </MapWrap>
          <AddressDesc>
            di{' '}
            <a
              href={
                urlMaps + coordinates.latitude + ',' + coordinates.longitude
              }
              rel="noopener noreferrer"
              target="_blank"
            >
              {coordinates.address}
            </a>
          </AddressDesc>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RenderMap;

export const DraggableMap = props => {
  const {
    coordinates,
    changeMarkerPosition,
    endChangeMarker,
    handleCloseMap
  } = props;
  const refMap = useRef();
  return (
    <>
      <MapWrap>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GMAPS_KEY
          }}
          // style={{ width: '50%', height: '50%' }}d
          center={{
            lat: coordinates?.latitude,
            lng: coordinates?.longitude
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onChange={({ center }) => changeMarkerPosition(center)}
          onDragEnd={endChangeMarker}
          zoom={16}
          ref={refMap}
          options={{ fullscreenControl: false }}
        ></GoogleMapReact>
        <img
          src={Images.LOCATION_BLUE}
          style={{ width: 18 }}
          alt={'Location Info'}
          className="marker"
        />
        <Icon type="close" className="close-map" onClick={handleCloseMap} />
      </MapWrap>

      <AddressDesc>
        di{' '}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${coordinates.latitude},${coordinates.longitude}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {coordinates.address}
        </a>
      </AddressDesc>
    </>
  );
};
