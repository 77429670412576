import { gql } from 'apollo-boost';

export const GET_CLASSROOM_MEMBER = gql`
  query(
    $classroom_id: uuid_comparison_exp!
    $limit: Int
    $offset: Int
    $keyword: String
  ) {
    classroom_members_aggregate(
      where: {
        classroom_id: $classroom_id
        global_user: { name: { _ilike: $keyword } }
      }
    ) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: {
        classroom_id: $classroom_id
        global_user: { name: { _ilike: $keyword } }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      is_admin
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          company_profile {
            brand_name
            legal_name
          }
          company_job_profile {
            position
            title
          }
        }
      }
      created_at
    }
  }
`;
