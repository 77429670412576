import React, { useState } from 'react';
import { MemberListWrap, SingleLineWrapper } from './MemberList.style';
import { PopOverRoleMemberAction } from '..';
import { AuthenticationService } from '../../Services';
import Assets from '../../Assets';
import { Avatar, Button } from 'antd';
import { TextField, MenuItem, InputAdornment } from '@material-ui/core';
// import { isObjectEmpty } from '../../Utils/CommonUtils';
import { ButtonWlb } from '..';
import SearchIcon from '@material-ui/icons/Search';

const MemberListComponent = props => {
  const {
    dataMember,
    type,
    handleSetAsAdmin,
    handleSetAsMember,
    handleLeaveMember,
    handleKickMember,
    isAdmin,
    approveMember,
    rejectMember,
    isLoading,
    onCancelContrib,
    enableSearch,
    friendshipLoading,
    onFollowUser
  } = props;

  const myId = AuthenticationService.getUserId();

  const [sortMember, setSortMember] = useState('Latest');
  const [searchMember, setSearchMember] = useState('');

  const searchList = searchMember
    ? dataMember.filter(
        item =>
          item?.name?.toLowerCase()?.includes(searchMember.toLowerCase()) ||
          item?.global_user?.name
            ?.toLowerCase()
            .includes(searchMember.toLowerCase())
      )
    : dataMember;

  const _list = searchList.sort((a, b) => {
    const createdA = new Date(a.created_at).getTime();
    const createdB = new Date(b.created_at).getTime();
    if (sortMember === 'Latest') {
      return createdB - createdA;
    } else {
      return createdA - createdB;
    }
  });

  const followButton = (followed, userId) => {
    return (
      <ButtonWlb
        type={followed ? 'default' : 'primary'}
        size="small"
        loading={friendshipLoading}
        onClick={e => {
          e.stopPropagation();
          onFollowUser(followed, userId);
        }}
        style={{ marginRight: 18, width: 80 }}
        disabled={friendshipLoading}
      >
        {followed && !friendshipLoading
          ? 'Following'
          : !followed && !friendshipLoading
          ? 'Follow'
          : ' '}
      </ButtonWlb>
    );
  };

  const _renderMember = _list.map((item, index) => {
    const avatar = item.avatar !== '' ? item.avatar : Assets.DEFAULT_PHOTO;
    return (
      <a
        key={index}
        href={'/profile/' + item.id + '/detail'}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="list">
          <div className="users-info">
            <div className="photo">
              <Avatar src={avatar} size={47} />
            </div>
            <div className="info">
              <span className="name">{item.name}</span>
              <span className="field">
                {item?.people_work_placements?.[0]?.company_job_profile
                  ?.title ?? ''}
              </span>
            </div>
          </div>
          {isAdmin && (
            <div className="button-group">
              {item.id !== myId && followButton(item.followed, item.id)}
              <PopOverRoleMemberAction
                handleSetAsRole={() => handleSetAsAdmin(item)}
                handleKickMember={handleKickMember}
                handleLeaveMember={handleLeaveMember}
                roleLabel="Tetapkan sebagai Admin"
                isMyCard={item.id === myId}
                item={item}
                isSocialLearning
                {...props}
              />
            </div>
          )}
        </div>
      </a>
    );
  });

  const _renderAdmin = _list.map((item, index) => {
    return (
      <div key={index} className="list">
        <div className="users-info">
          <div className="photo">
            <Avatar
              src={item.avatar ? item.avatar : Assets.DEFAULT_PHOTO}
              alt="avatar"
              size={47}
            />
          </div>
          <div className="info">
            <span className="name">{item.name}</span>
            <span className="field">
              {item?.people_work_placements?.[0]?.company_job_profile?.title ??
                ''}
            </span>
          </div>
        </div>
        {isAdmin && (
          <div className="button-group">
            {item.id !== myId && followButton(item.followed, item.id)}
            <PopOverRoleMemberAction
              handleSetAsRole={() => handleSetAsMember(item)}
              handleLeaveMember={() => handleLeaveMember()}
              roleLabel="Hapus sebagai Admin"
              isShowKickMember={false}
              isMyCard={item.id === myId}
              isSocialLearning
              {...props}
            />
          </div>
        )}
      </div>
    );
  });

  const _renderMentor =
    dataMember.length > 0 ? (
      dataMember.map((item, index) => {
        return (
          <div key={index} className="list">
            <div className="users-info">
              <div className="photo">
                <img
                  src={item.avatar ? item.avatar : Assets.DEFAULT_PHOTO}
                  alt="avatar"
                />
              </div>
              <div className="info">
                <span className="name">{item.name}</span>
                <span className="field">
                  {item?.global_user?.people_work_placements?.[0]
                    ?.company_job_profile?.title ?? ''}
                </span>
              </div>
            </div>
            {(isAdmin || item.id === myId) && (
              <div className="button-group">
                <PopOverRoleMemberAction
                  handleSetAsRole={() => handleSetAsMember(item)}
                  handleLeaveMember={() => handleLeaveMember()}
                  roleLabel="Tetapkan sebagai Anggota"
                  isShowKickMember={false}
                  isMyCard={item.id === myId}
                  isSocialLearning
                  {...props}
                />
              </div>
            )}
          </div>
        );
      })
    ) : (
      <div className="list">
        <div className="no-users">No Learning Contributor</div>
      </div>
    );

  const RequestMember = () => {
    return (
      <div>
        {_list.map((item, index) => (
          <div key={index} className="list">
            <div className="users-info">
              <div className="photo">
                <img
                  src={
                    item.global_user.avatar
                      ? item.global_user.avatar
                      : Assets.DEFAULT_PHOTO
                  }
                  alt="avatar"
                  style={{ height: '50px', width: '50px', objectFit: 'cover' }}
                />
              </div>
              <div className="info">
                <span className="name">{item.global_user.name}</span>
                <span className="field">
                  {item?.global_user?.people_work_placements?.[0]
                    ?.company_job_profile?.title ?? ''}
                </span>
              </div>
            </div>

            <div className="button-group">
              <ButtonWlb
                type="danger"
                size="small"
                style={{ width: 80 }}
                loading={false}
                onClick={() => rejectMember(item.global_user.id)}
                disabled={isLoading}
              >
                Tolak
              </ButtonWlb>
              <ButtonWlb
                type="primary"
                size="small"
                loading={false}
                onClick={() => approveMember(item.global_user.id)}
                style={{ marginLeft: 18, width: 80 }}
                disabled={isLoading}
              >
                Terima
              </ButtonWlb>
              {/* {(isAdmin || item.id === myId) && (
                <PopOverRoleMemberAction
                  handleSetAsRole={() => handleSetAsMember(item)}
                  handleLeaveMember={() => handleLeaveMember()}
                  roleLabel="Tetapkan sebagai Anggota"
                  isShowKickMember={false}
                  isMyCard={item.id === myId}
                  {...props}
                />
              )} */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const _renderContributor = dataMember.map((item, index) => {
    const status = ['Waiting', 'Accepted', 'Rejected', 'Canceled'];
    return (
      <div key={index} className="list">
        <div className="users-info">
          <div className="photo">
            <Avatar
              src={
                item?.contrib?.avatar
                  ? item?.contrib?.avatar
                  : Assets.DEFAULT_PHOTO
              }
              alt="avatar"
              size={47}
            />
          </div>
          <div className="info">
            <SingleLineWrapper className="name">
              {item?.contrib?.name}
            </SingleLineWrapper>
            <span className="field">
              {item?.contrib?.people_work_placements?.[0]?.company_job_profile
                ?.title ?? ''}
            </span>
            {item.status !== 1 && (
              <div
                className={`contrib-status ${
                  item?.status === 0
                    ? 'waiting'
                    : item?.status === 2
                    ? 'rejected'
                    : 'canceled'
                }`}
              >
                {status[item?.status]}
              </div>
            )}
          </div>
        </div>

        {item.status === 0 && (
          <Button onClick={() => onCancelContrib(item)} type="danger" ghost>
            Batalkan Undangan
          </Button>
        )}
        {/* {(isAdmin || item.id === myId) && (
          <div className="button-group">
            <PopOverRoleMemberAction
              handleSetAsRole={() => handleSetAsMember(item)}
              handleLeaveMember={() => handleLeaveMember()}
              roleLabel="Tetapkan sebagai Anggota"
              isShowKickMember={false}
              isMyCard={item.id === myId}
              {...props}
            />
          </div>
        )} */}
      </div>
    );
  });

  return (
    <MemberListWrap>
      {enableSearch && (
        <div className="list">
          <TextField
            select
            value={sortMember}
            onChange={e => setSortMember(e.target.value)}
            SelectProps={{
              disableUnderline: true
            }}
          >
            <MenuItem value="Latest">Latest</MenuItem>
            <MenuItem value="Oldest">Oldest</MenuItem>
          </TextField>
          <TextField
            placeholder="Search Member"
            variant="outlined"
            size="small"
            value={searchMember}
            onChange={e => setSearchMember(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </div>
      )}

      {type === 'request' && <RequestMember />}
      {type === 'admin' && _renderAdmin}
      {type === 'mentor' && _renderMentor}
      {type === 'member' && _renderMember}
      {type === 'contributor' && _renderContributor}
    </MemberListWrap>
  );
};

export default MemberListComponent;
