import gql from 'graphql-tag';

export const ADD_LICENCES = gql`
  mutation addCertificateByUser(
    $objects: [people_profile_certificates_insert_input!]!
  ) {
    insert_people_profile_certificates(objects: $objects) {
      affected_rows
    }
  }
`;
export const UPDATE_LICENCES = gql`
  mutation($id: Int!, $objects: people_profile_certificates_set_input!) {
    update_people_profile_certificates_by_pk(
      pk_columns: { id: $id }
      _set: $objects
    ) {
      id
    }
  }
`;

export const DELETE_LICENCES = gql`
  mutation($id: Int!) {
    update_people_profile_certificates(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const SET_SHARE_LICENCES = gql`
  mutation($section: Boolean!, $user: uuid!) {
    a: update_people_profile(
      _delete_key: { settings_section: "certification" }
      where: { id: { _eq: $user } }
    ) {
      affected_rows
    }

    b: update_people_profile(
      _append: { settings_section: "certification" }
      where: { id: { _eq: $user }, _not: { id: { _is_null: $section } } }
    ) {
      affected_rows
    }
  }
`;
