import React from 'react';
import { WrapperLicences } from './Licences.style';
import { Icon } from 'antd';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import Assets from '../../../../Assets';
import { AttachementItemIcon } from '../../../../SharedComponents';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';

export default function LicencesItem({
  onClickEdit,
  avatar,
  company,
  title,
  date,
  isUser,
  data,
  dividerWidth = null,
  contentPadding = null,
  contentWidth = null,
  noDivider = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <WrapperLicences
      contentPadding={contentPadding}
      contentWidth={contentWidth}
      dividerWidth={dividerWidth}
    >
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="title">{title}</div>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            {data.label || '-'}
          </ThypoStyled>
          <div className="company">{company || '-'}</div>
          <div className="date">{date || '-'} </div>
          <ThypoStyled fontSize="12px" style={{ whiteSpace: 'pre-line' }}>
            {data.description?.slice(
              0,
              (!isMore && 370) || data.description?.length
            ) || '-'}
          </ThypoStyled>

          {isMore && (
            <>
              <div style={{ paddingTop: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'start',
                    paddingTop: '10px'
                  }}
                >
                  <ThypoStyled
                    fontSize="12px"
                    color={Assets.COLOR_PALLETE.grey}
                    fontStyle="italic"
                  >
                    Certificate URL :
                  </ThypoStyled>
                  <ThypoStyled
                    fontSize="12px"
                    color={Assets.COLOR_PALLETE.blue_link}
                    fontStyle="italic"
                    style={{ paddingLeft: '10px' }}
                  >
                    {`${data.url || '-'}`}
                  </ThypoStyled>
                </div>
                <ThypoStyled
                  fontSize="12px"
                  color="#014a62"
                  margin="0px 0px 12px 0px"
                >
                  {data.credential || '-'}
                </ThypoStyled>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px'
                }}
              >
                {data.attachments &&
                  data.attachments !== '[]' &&
                  data.attachments.length >= 1 &&
                  data.attachments.map((res, key) => (
                    <AttachementItemIcon
                      key={key}
                      url={res.url}
                      name={res.name}
                    />
                  ))}
              </div>
            </>
          )}
          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      {!noDivider && <div className="divider" />}
    </WrapperLicences>
  );
}
