import React, { useState } from "react";
import ListItem from "./ListItem";
import moment from "moment";
import { Skeleton, message, Empty } from "antd";
import { useMutation } from "react-apollo";
import { DELETE_RECOMMENDATION } from "./Recommendation.graphql";
import { WrapperData } from "./Recommendation.style";
import { ModalDeleteWLBCheck } from "../../../../Components";

export default function ListSent({
  dataSent,
  setPublish,
  publish,
  updateStatus,
  refetch,
  setOpen,
  stateOpen,
  refetchSent,
  onClose,
}) {
  const [deleteState, setDeleteState] = useState({
    open: false,
    dataDelete: null,
  });

  const [deleteRecommendation] = useMutation(DELETE_RECOMMENDATION, {
    context: { headers: { "X-Hasura-Role": "user" } },
  });

  const handleRevise = (id, name, dataEdit) => {
    setOpen({
      ...stateOpen,
      open: false,
      openEdit: true,
      dataEdit: {
        id,
        name,
        dataEdit,
      },
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ ...deleteState, open: false, dataDelete: null });
    setOpen({
      ...stateOpen,
      open: true,
    });
  };

  const handleDelete = () => {
    deleteRecommendation({
      variables: {
        id: deleteState.dataDelete,
      },
    })
      .then(() => {
        refetchSent();
        handleCloseModalDelete();
        message.success("Delete Referral Sucess");
      })
      .catch((err) => {
        message.error(`Delete Referral Failed, ${err}`);
      });
  };

  const handleOpenDelete = (id) => {
    onClose();
    setDeleteState({ ...deleteState, open: true, dataDelete: id });
  };

  return (
    <div>
      <WrapperData>
        {dataSent ? (
          dataSent.length > 0 ? (
            dataSent.map((res, i) => {
              return (
                <ListItem
                  refetch={refetch}
                  updateStatus={updateStatus}
                  setPublish={setPublish}
                  publish={publish}
                  id={res?.id}
                  status={res?.status}
                  key={i}
                  type="sent"
                  userId={res?.global_user?.id}
                  avatar={res?.globalUserByRecommendationTo?.avatar}
                  name={res?.globalUserByRecommendationTo?.name}
                  date={moment(res?.date_added).format("DD MMM YYYY")}
                  position={res?.position}
                  relationship={res?.relationship}
                  message={res?.description}
                  onRevise={() =>
                    handleRevise(
                      res?.id,
                      res?.globalUserByRecommendationTo?.name,
                      res
                    )
                  }
                  onDelete={() => handleOpenDelete(res.id)}
                />
              );
            })
          ) : (
            <Empty style={{ marginTop: 10 }} />
          )
        ) : (
          <Skeleton active avatar paragraph={{ rows: 4 }} />
        )}
      </WrapperData>
      <ModalDeleteWLBCheck 
        centered
        open={deleteState.open}
        target="Referral"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </div>
  );
}
