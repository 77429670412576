import { Button, Checkbox, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { memo, useState } from 'react';
import { ModalWrapperDeleteCheck } from './ModalDeleteWithText.style';

export default memo(function ModalDeleteWithText({
  centered,
  open,
  header,
  textButton,
  message,
  textDesc,
  onClose,
  handleChange,
  placeholder,
  value,
  onSubmit
}) {
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    onClose();
    setChecked(false);
  };

  const handleDelete = () => {
    onSubmit();
    setChecked(false);
  };

  return (
    <Modal
      centered={centered}
      visible={open}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalWrapperDeleteCheck>
        <div className="header">{header}</div>
        <div className="body">
          <p>{textDesc}</p>
          <TextArea
            rows={4}
            value={value}
            placeholder={placeholder}
            onChange={value => {
              handleChange(value.target.value);
            }}
          />
          <div className="check" style={{ paddingTop: '10px' }}>
            <Checkbox
              // style={{backgroundColor: '#ef4d5e', borderColor: '#ef4d5e'}}
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="check-action"
            />
            <p>{message}</p>
          </div>
        </div>
        <div className="action">
          <span className="cancel" onClick={handleClose}>
            Cancel
          </span>
          <Button
            disabled={!checked}
            onClick={handleDelete}
            type="danger"
            className="delete"
          >
            {textButton}
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Modal>
  );
});
