import { gql } from 'apollo-boost';

export const UPDATE_LEARNING_TRACKER = gql`
  mutation(
    $activity: String!
    $date: DateTime!
    $duration: Int!
    $mentees: [String]!
    $postId: String!
    $files: [FileType]
    $commitment: String
    $description: String
    $subject: String
    $location: LocationType
    $tags: [String]
    $peopleTags: [String]
    $competencyId: Int
  ) {
    classroomUpdateLearningTracker(
      activity: $activity
      date: $date
      duration: $duration
      mentees: $mentees
      postId: $postId
      files: $files
      commitment: $commitment
      description: $description
      subject: $subject
      tags: $tags
      location: $location
      peopleTags: $peopleTags
      competency: $competencyId
    ) {
      id
    }
  }
`;
