import React, { useState } from 'react';
import { Error404Page } from '../../Pages';
import { Group } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { useParams } from 'react-router-dom';

const Index = props => {
  const params = useParams();

  const [isFounded, setIsFounded] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useAsyncEffect(async isMounted => {
    setIsLoading(true);
    await Group.Discussion.fetchGroupInfo(params.id)
      .then(res => {
        setIsFounded(true);
      })
      .catch(err => {
        setIsFounded(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isFounded === false && isLoading === false) {
    return <Error404Page />;
  }

  return props.children;
};

export default Index;
