import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  DELETE_BANK_ACCOUNT,
  GET_ACCOUNT_BANK_LIST
} from './BankAccountListGraphQl';
import BankAccountListCardComponent from './BankAccountListCard.component';
import { message } from 'antd';
import Constants from '../../Config/Constants';
import DeleteConfirmationModalContainer from '../DeleteConfirmationModal';

const { GRAPHQL_HEADER_OPTIONS, USER_ROLE } = Constants;

const BankAccountListCardContainer = () => {
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState({});

  const { data, error, loading, refetch } = useQuery(
    GET_ACCOUNT_BANK_LIST,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  const [deleteBankAccount] = useMutation(
    DELETE_BANK_ACCOUNT,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  let bankAccounts = [];

  if (error) {
    message.error('Something went wrong');
    return;
  }

  if (data) {
    bankAccounts = data.company_profile_banks;
  }

  const _hideModal = () => {
    setAddModalVisibility(false);
    setEditModalVisibility(false);
    setDeleteModalVisibility(false);
    setSelectedBankAccount({});
  };

  const _handleDeleteBankAccount = bankAccount => {
    setSelectedBankAccount(bankAccount);
    setDeleteModalVisibility(true);
  };

  const _handleEditBankAccount = bankAccount => {
    setSelectedBankAccount(bankAccount);
    setEditModalVisibility(true);
  };

  const _showConfirmationDeleteModal = () => {
    const deletedRecord = {
      deleted_at: 'now()',
      id: selectedBankAccount.id
    };

    return (
      <DeleteConfirmationModalContainer
        handleDeleteModalVisibility={_hideModal}
        deleteModalVisibility={deleteModalVisibility}
        deleteRecordMutation={deleteBankAccount}
        refetchContentQuery={refetch}
        recordToDelete={deletedRecord}
        validatorFields={['update_company_profile_banks', 'affected_rows']}
        modalTitle={'Delete bank account'}
        modalContent={
          'Are you sure to' +
          ' delete this record?, This will' +
          ' delete the record permanently'
        }
      />
    );
  };
  return (
    <BankAccountListCardComponent
      refetchBankAccountList={refetch}
      selectedBankAccount={selectedBankAccount}
      handleAddModalVisibility={setAddModalVisibility}
      editModalVisibility={editModalVisibility}
      addModalVisibility={addModalVisibility}
      bankAccounts={bankAccounts}
      handleHideModal={_hideModal}
      handleEditBankAccount={_handleEditBankAccount}
      handleDeleteBankAccount={_handleDeleteBankAccount}
      showConfirmationDeleteModal={_showConfirmationDeleteModal}
      loading={loading}
    />
  );
};

export default BankAccountListCardContainer;
