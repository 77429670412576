import React, { useState, useEffect } from 'react';
import { LocationWrap } from './LocationPost.style';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { DraggableMap } from './map';
import DisplayLoading from '../DisplayLoading';
import { isEmpty } from 'lodash';
import Geocode from 'react-geocode';
import Constant from '../../Config/Constants';

const { LOCATION } = Constant.UTILITIES;

Geocode.setApiKey(process.env.REACT_APP_GMAPS_KEY);
Geocode.enableDebug();

const Location = props => {
  const { post, handlePost, handleUtility } = props;
  const [address, setAddress] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [coordinates, setCoordinates] = useState(post.location);

  const handleChange = address => {
    setAddress(address);
    setShowMap(false);
  };

  const handleSelect = address => {
    setShowMap(true);
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        // console.log('Successfully got latitude and longitude', lat, lng);
        setCoordinates({ latitude: lat, longitude: lng, address });
        handlePost('location', { latitude: lat, longitude: lng, address });
      })
      // eslint-disable-next-line no-console
      .catch(error => console.error('Error Geocodebyaddress', error));
  };

  const changeMarkerPosition = center => {
    setCoordinates(coordinates => ({
      latitude: center.lat,
      longitude: center.lng,
      address: coordinates.address
    }));
    // console.log('refMap', refMap);
  };

  const endChangeMarker = () => {
    Geocode.fromLatLng(coordinates.latitude, coordinates.longitude).then(
      response => {
        const newAddress = response.results[0].formatted_address;
        setCoordinates(coordinates => ({
          ...coordinates,
          address: newAddress
        }));
        handlePost('location', {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          address: newAddress
        });
      },
      error => {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    );
  };
  const handleCloseMap = () => {
    handlePost('location', {});
    handleUtility(LOCATION);
  };

  useEffect(() => {
    if (post.location !== {}) {
      setShowMap(true);
      setAddress(post.location.address);
    }
  }, [post]);

  return (
    <LocationWrap>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={1000}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Cari Tempat',
                className: 'location-search-input'
              })}
            />
            <div className="autocomplete-container">
              {loading && <DisplayLoading />}
              {suggestions.map((suggestion, index) => {
                // console.log('suggestion', suggestion);
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';

                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className
                      // style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {showMap && !isEmpty(coordinates) && (
        <DraggableMap
          coordinates={coordinates}
          setCoordinates={setCoordinates}
          changeMarkerPosition={changeMarkerPosition}
          endChangeMarker={endChangeMarker}
          handleCloseMap={handleCloseMap}
        ></DraggableMap>
      )}
    </LocationWrap>
  );
};

export default Location;
