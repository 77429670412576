import React from 'react';
import FollowerContainer from './Follower.container';
import FollowingContainer from './Following.container';
import { useParams } from 'react-router-dom';
import { AuthenticationService } from '../../Services';
import { useWhoCanSeeMyFollow } from '../../SharedComponents/CustomHook';
import { SearchNotFound, DisplayLoading, DummySpace } from '../../Components';
import { Widget } from '../../Containers';
import { Col, Row } from 'antd';

function Follower(props) {
  const ownerId = AuthenticationService.getUserId();
  const params = useParams();
  const profileId = params.profileId;
  const [
    followPrivacy,
    followLoaded,
    followLoading,
    name
  ] = useWhoCanSeeMyFollow(profileId);
  const followsCategory = props.followsCategory;

  const FollowingComp = () => {
    if (followLoading && ownerId !== profileId)
      return (
        <>
          <DummySpace />
          <DisplayLoading />
        </>
      );
    else if (
      !followLoading &&
      ownerId !== profileId &&
      followPrivacy &&
      followLoaded
    ) {
      return (
        <SearchNotFound
          title="Private Information"
          desc={`${name} has set follow/following information to private`}
          style={{ width: '50%' }}
        />
      );
    } else {
      switch (followsCategory) {
        case 'Followers':
          return <FollowerContainer {...props} name={name} />;
        default:
          return <FollowingContainer {...props} name={name} />;
      }
    }
  };
  return (
    <Row gutter={16}>
      <Col span={18}>
        <FollowingComp />
      </Col>
      <Col span={6} style={{ marginTop: '15px' }}>
        <Widget {...props} />
      </Col>
    </Row>
  );
}
export default Follower;
