import styled from 'styled-components';

const LoginAndSecurityPanel = styled.div`
  .ant-collapse {
    border-radius: 0px;
    border: none;
  }

  .ant-collapse.ant-collapse-icon-position-right.login-and-security__settings {
    width: 608.4px;
    background-color: #ffffff;
    font-family: 'Muli-SemiBold';
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;

    .ant-collapse-header {
      color: #014a62;
    }
  }

  .manage-password {
    label {
      font-family: 'Muli-SemiBold';
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    input {
      /* width: 279.7px; */
      font-family: 'Muli-SemiBold';
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    .ant-row.ant-form-item {
      padding: 0px;
    }

    #forget-password {
      color: #039be5 !important;
      font-size: 12px;
      background: none !important;
      border: none;
      padding: 0 !important;
      cursor: pointer;
    }

    .ant-btn.ant-btn-primary {
      background-color: #014a62;
      font-size: 12px;
      color: #ffffff;
    }
  }
  .password-input {
    width: 60%;
  }
`;

export { LoginAndSecurityPanel };
