import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import ListDetail from '../../../../Components/DetailUserSection/ListDetail.component';
import AddEdit from './AddEdit';
import MoreExperience from './MoreExperience';

export default function Project({
  isUser,
  dataAward,
  refetch,
  people_profile,
  profileId,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });
  // console.log(nameUser)
  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title="Penghargaan"
        action={isUser ? 'plus' : null}
      >
        <ListDetail
          setOpen={setOpen}
          editOnChild
          isUser={isUser}
          data={dataAward}
          nameUser={nameUser}
          showMoreText="Penghargaan"
          type="award"
        />
      </DetailUserSection>
      <AddEdit
        setOpen={setOpen}
        openState={open}
        profileId={profileId}
        people_profile={people_profile}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        nameUser={nameUser}
        onClose={() =>
          setOpen({
            open: false,
            data: null,
            actionType: null,
            fromList: false,
            openMore: open.fromList ? true : false
          })
        }
      />
      <MoreExperience
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataAward}
      />
    </>
  );
}
