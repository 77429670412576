import React, { useState } from 'react';
import PostDeleteComponent from './PostDelete.component';

const PostDeleteContainer = props => {
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const { deletePost, itemPost, isNotOwner, canEdit, editPost } = props;

  const deleteHandler = () => {
    deletePost(itemPost);
    setDisplayDeleteModal(false);
  };

  const editHandler = () => {
    editPost(itemPost);
    setDisplayDeleteModal(false);
  };

  return (
    <PostDeleteComponent
      deleteHandler={deleteHandler}
      editHandler={editHandler}
      displayDeleteModal={displayDeleteModal}
      setDisplayDeleteModal={setDisplayDeleteModal}
      isNotOwner={isNotOwner}
      canEdit={canEdit}
    />
  );
};

export default PostDeleteContainer;
