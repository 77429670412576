import styled from 'styled-components';
import Assets from '../../Assets';

export const BoxShadow = styled.div`
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: ${Assets.COLOR_PALLETE.white};
  min-height: 50px;
  margin-bottom: 12px;
  display: inline-block;
  padding: 0px 12.3px;
`;

export const Title = styled.div`
  font-family: ${Assets.FONT.MULI_BOLD};
  color: #252525;
  font-size: 16px;
  border-bottom: 1px solid rgb(229, 229, 229);
  padding: 12.3px 0px;
`;

export const Content = styled.div`
  font-family: ${Assets.FONT.MULI_BOLD};
`;
