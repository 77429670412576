import { gql } from 'apollo-boost';

export const ADD_LEARNING_CONTRIB = gql`
  mutation($classroomId: uuid!, $contribUserId: uuid!) {
    insert_classroom_contrib_requests_one(
      object: { classroom_id: $classroomId, contrib_user_id: $contribUserId }
    ) {
      id
    }
  }
`;
