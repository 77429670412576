import React from 'react';
import { useGetUnreadNotification } from '../../SharedComponents/CustomHook';
import CenterLayout from '../CenterLayout';
import { StyledTabs, SubNav } from './MenuBar.style';

const NOTIFICATION_PATH = {
  business_trip: 'business-trip',
  placement: 'company'
};

const MenuBarComponent = ({
  searchParams,
  menuBarList,
  menuBarActive,
  setMenuBarActive,
  defaultMenu
}) => {
  const allowHooksCall = defaultMenu === 'Notification';
  const [count, modules] = useGetUnreadNotification(allowHooksCall);

  const _navigationComponent = () => (
    <ul>
      {menuBarList.map(item => {
        // !!! Only for notification feature
        const isUnread = modules?.filter(res =>
          item.path.includes(NOTIFICATION_PATH[res.type] || res.type)
        );
        // !!!
        const splittedPath = item.path.split('/');
        const splittedMenu = menuBarActive.split('/');

        const styleActivePath = splittedPath.map((p, id) => {
          if (!p.startsWith(':')) return p;
          return splittedMenu[id];
        });

        const navigation = splittedPath.map((p, id) => {
          if (!p.startsWith(':')) return p;
          if (p === ':params') return searchParams;
          return splittedMenu[id];
        });

        const liStyle =
          JSON.stringify(styleActivePath) === JSON.stringify(splittedMenu)
            ? 'active'
            : '';
        return (
          <StyledTabs
            key={item.name}
            className={liStyle}
            onClick={() =>
              setMenuBarActive(navigation.toString().replace(/,/g, '/'))
            }
            isUnread={item.name === 'All' ? count > 0 : isUnread.length > 0}
          >
            {item.name}
          </StyledTabs>
        );
      })}
    </ul>
  );

  return (
    <SubNav>
      <CenterLayout>
        <div className="main">
          <div className="container">
            <div className="menu-subnav">{_navigationComponent()}</div>
          </div>
        </div>
      </CenterLayout>
    </SubNav>
  );
};

export default MenuBarComponent;
