import React from 'react';

import NotificationScreen from './NotificationScreen';

const menuBarList = [
  {
    renderComponent: props => (
      <NotificationScreen title="Your Notification" {...props} />
    ),
    name: 'All',
    path: '/notification/all'
  },
  // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Social Media',
    path: '/notification/social'
  },
  // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Learning',
    path: '/notification/learning'
  },
  // // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  // {
  //   renderComponent: props => (
  //     <NotificationScreen title="Following Activity" {...props} />
  //   ),
  //   name: 'Vendor',
  //   path: '/notification/vendor'
  // },
  // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Time',
    path: '/notification/time'
  },
  // // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Business Trip',
    path: '/notification/business-trip'
  },
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Performance',
    path: '/notification/performance'
  },
  // // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Financial',
    path: '/notification/financial'
  },
  // // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  // {
  //   renderComponent: props => (
  //     <NotificationScreen title="Following Activity" {...props} />
  //   ),
  //   name: 'Talent',
  //   path: '/notification/talent'
  // },
  // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  {
    renderComponent: props => (
      <NotificationScreen title="Following Activity" {...props} />
    ),
    name: 'Company',
    path: '/notification/company'
  }
  // TODO ENABLE THIS FIELD WHEN WANT TO DISPLAY FOLLOWING NOTIFICATION TAB
  // {
  //   renderComponent: props => (
  //     <NotificationScreen title="Following Activity" {...props} />
  //   ),
  //   name: 'Recruitment',
  //   path: '/notification/recruitment'
  // }
];

const defaultMenuBar = 'Notification';

export default {
  menuBarList,
  defaultMenuBar
};
