import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { NotificationActivity } from '../../Components';
import { GiveRecomendation } from '../../Components/ActionMorePeopleProfile';
import EditRecommendation from '../../Pages/UserProfile/DetailScreen/Recommendation/EditRecommendation';
import { LIST_SENT_RECOMMENDATION } from '../../Pages/UserProfile/DetailScreen/Recommendation/Recommendation.graphql';
import { AuthenticationService } from '../../Services';
const NotificationComponent = props => {
  const [recomendation, setRecomendation] = useState({
    open: false,
    data: null,
    subtype: null
  });
  const { type, notification, isFetching } = props;

  const [
    getListSentRemnd,
    { data: dataSent, refetch: refetchSent }
  ] = useLazyQuery(LIST_SENT_RECOMMENDATION, {
    context: { headers: { 'X-Hasura-Role': 'public' } }
  });

  useEffect(() => {
    if (recomendation.subtype === 'social-referral-revision-request') {
      getListSentRemnd({
        variables: {
          user: AuthenticationService.getUserId(),
          id: recomendation?.data?.data?.recommendation_id
        }
      });
    }
  }, [getListSentRemnd, recomendation]);

  return (
    <>
      <div id={'notificationPage'}>
        <NotificationActivity
          type={type}
          notification={notification}
          loading={isFetching}
          // history={history}
          setRecomendation={setRecomendation}
        />
      </div>

      <GiveRecomendation
        open={
          recomendation.open &&
          recomendation.subtype === 'social-referral-request'
        }
        onClose={() =>
          setRecomendation({
            ...recomendation,
            open: false,
            data: null,
            subtype: null
          })
        }
        dataNotification={recomendation.data}
        from="notification"
        subtype={recomendation.subtype}
        name={recomendation.data?.title}
      />
      <EditRecommendation
        refetchSent={refetchSent}
        open={
          recomendation.open &&
          recomendation.subtype === 'social-referral-revision-request'
        }
        onClose={() =>
          setRecomendation({
            ...recomendation,
            open: false,
            data: null,
            subtype: null
          })
        }
        name={recomendation.data?.data?.name?.replace(
          'asking you to revise your referral',
          ''
        )}
        id={recomendation?.data?.data?.recommendation_id}
        dataEdit={dataSent?.people_profile_recommendations?.[0] || {}}
      />
    </>
  );
};

export default NotificationComponent;
