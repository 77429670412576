import { Empty, Select, Spin } from 'antd';
import React from 'react';
import { capitalize } from '../../Utils/CommonUtils';

export const SearchableSelect = ({
  fieldValue,
  placeholder,
  loading,
  option,
  onSearch,
  fieldName,
  onTouch,
  onChange,
  isCapitalizeOptions = false,
  style = { width: '100%' },
  withEmptyIcon = true,
  disabled = false
}) => {
  const { Option } = Select;

  return (
    <Select
      disabled={disabled}
      showSearch
      allowClear
      defaultActiveFirstOption={false}
      value={fieldValue}
      placeholder={placeholder}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : option?.length === 0 ? (
          withEmptyIcon ? (
            <Empty />
          ) : (
            'Tidak ada data'
          )
        ) : null
      }
      filterOption={false}
      onSearch={value => onSearch({ value, fieldName })}
      onDropdownVisibleChange={isOpen => onTouch({ isOpen, fieldName })}
      onChange={onChange}
      style={style}
    >
      {option &&
        option.map((res, i) => {
          const dataTestId = `${res.id}-${res.name}`;
          return (
            <Option
              data={res}
              key={`${i}`}
              value={res.id}
              data-testId={dataTestId}
            >
              {isCapitalizeOptions ? capitalize(res.name) : res.name}
            </Option>
          );
        })}
    </Select>
  );
};
