import React from 'react';
import { Avatar, Col, Row, Select, Spin } from 'antd';
import { delaySearch } from '../../Utils/CommonUtils';
import './TagPeople.css';
import style, { TagWrapper } from './TagPeople.style';

const { Option } = Select;

const TagPeopleComponent = ({
  users,
  setKeyword,
  handlePost,
  isFetching,
  postType,
  defaultValue,
  forKudos = false,
  userId
}) => {
  let timeout = 0;
  const quickSearch = value => {
    timeout = delaySearch(timeout, () => setKeyword(value.trim()));
  };
  const _renderAvatar = avatar => (
    <Col span={5}>
      <Avatar size={40} src={avatar} />
    </Col>
  );
  const _renderPositionDetail = people_work_placements => {
    if (!people_work_placements) {
      return null;
    }

    return people_work_placements.map(
      ({ company_employee_position, company_profile }, index) => {
        const position =
          company_employee_position && company_employee_position.name
            ? company_employee_position.name
            : null;
        const company =
          company_profile && company_profile.brand_name
            ? `di ${company_profile.brand_name}`
            : null;

        return position ? (
          <p
            key={index}
            style={style.descriptionText}
          >{`${position} ${company}`}</p>
        ) : null;
      }
    );
  };

  const _renderDescription = ({ name, people_work_placements }) => (
    <Col span={19}>
      <p style={style.nameText}>{name}</p>
      {_renderPositionDetail(people_work_placements)}
    </Col>
  );
  const _renderDescriptionCompany = (name, people_work_placements) => (
    <Col span={19}>
      <p style={style.nameText}>{name}</p>
      <p style={style.descriptionText}>{people_work_placements}</p>
    </Col>
  );

  const _renderOption = () => {
    return users.map(user => (
      <Option
        key={user.id ? user.id : user.key}
        label={user.name}
        value={user.id}
      >
        <Row style={style.rowContainer}>
          {_renderAvatar(user.avatar)}
          {_renderDescription(user)}
        </Row>
      </Option>
    ));
  };

  const _renderOptionCompany = () => {
    return users.map(user => (
      <Option
        key={user?.global_user?.id}
        label={user?.global_user?.name}
        value={user?.global_user?.id}
      >
        <Row style={style.rowContainer}>
          {_renderAvatar(user?.global_user?.avatar)}
          {_renderDescriptionCompany(
            user?.global_user?.name,
            user?.company_employee_position?.name
          )}
        </Row>
      </Option>
    ));
  };

  return (
    <TagWrapper>
      {forKudos && defaultValue ? (
        <Select
          mode="multiple"
          style={style.select}
          optionLabelProp="label"
          placeholder="Bersama"
          onSearch={quickSearch}
          onChange={value => {
            handlePost('tags', [{ key: userId }, ...value]);
          }}
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          labelInValue
          filterOption={false}
        >
          {postType === 'company' ? _renderOptionCompany() : _renderOption()}
        </Select>
      ) : (
        <Select
          mode="multiple"
          style={style.select}
          optionLabelProp="label"
          placeholder="Bersama"
          onSearch={quickSearch}
          onChange={value => {
            handlePost('tags', value);
          }}
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          labelInValue
          filterOption={false}
        >
          {postType === 'company' ? _renderOptionCompany() : _renderOption()}
        </Select>
      )}
    </TagWrapper>
  );
};

export default TagPeopleComponent;
