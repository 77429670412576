import React, { useEffect, useState } from 'react';
import MemberCardBoxComponent from './ReviewCardBox.component';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import _ from 'lodash';
import { getCookie } from '../../Utils/CommonUtils';
import {
  GET_CLASSROOM_BY_ID,
  GET_REVIEW_LIST,
  GET_FEEDBACK_REVIEW,
  SUBMIT_REVIEW,
  UPDATE_REVIEW,
  GET_MAIN_REVIEW
} from './graphql';

const ReviewCardBoxContainer = props => {
  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const token = _.isEmpty(userData) ? '' : userData.oauth.access_token;
  const CONTEXT_USER = {
    headers: { 'X-Hasura-Role': 'user', Authorization: `Bearer ${token}` }
  };

  const params = useParams();
  const classroomId = params.id;
  const [fullResume, setFullResume] = useState(false);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState('');
  const [isRating, setIsRating] = useState(false);

  const [mutationAddReview] = useMutation(SUBMIT_REVIEW);
  const [mutationEditReview] = useMutation(UPDATE_REVIEW);

  const {
    data: detailClassroom,
    loading: classroomLoading,
    refetch
  } = useQuery(GET_CLASSROOM_BY_ID, {
    variables: { classroomId: classroomId },
    context: CONTEXT_USER
  });

  const {
    data: reviewList,
    loading: reviewListLoading,
    refetch: refetchReviewList
  } = useQuery(GET_REVIEW_LIST, {
    variables: { classroomId: classroomId },
    context: CONTEXT_USER
  });

  const {
    data: feedbackReview,
    loading: feedbackReviewLoading,
    refetch: refetchFeedbackReview
  } = useQuery(GET_FEEDBACK_REVIEW, {
    variables: { classroomId: classroomId },
    context: CONTEXT_USER
  });

  const {
    data: averageReview,
    loading: averageReviewLoading,
    refetch: refetchAverageReview
  } = useQuery(GET_MAIN_REVIEW, {
    variables: { classroomId: classroomId },
    context: CONTEXT_USER
  });

  useEffect(() => {
    refetch();
    refetchReviewList();
    refetchFeedbackReview();
    refetchAverageReview();
  }, [refetch, refetchAverageReview, refetchFeedbackReview, refetchReviewList]);

  useEffect(() => {
    if (feedbackReview && !feedbackReviewLoading) {
      if (feedbackReview.classroom_reviews.length > 0) {
        const findMyReview = feedbackReview.classroom_reviews.find(
          review => review.global_user.id === userData.id
        );
        if (findMyReview) {
          setIsRating(true);
          setRating(feedbackReview.classroom_reviews[0].star);
          setDescription(feedbackReview.classroom_reviews[0].description);
        }
      }
    }
  }, [feedbackReview, feedbackReviewLoading, userData.id]);

  const toggleResume = () => {
    setFullResume(!fullResume);
  };

  const handleSubmitReview = data => {
    const dataToSend = {
      ...data,
      classroomId: classroomId
    };
    setRating(data.rating);
    setDescription(data.description);
    if (isRating) {
      const dataUpdate = { ...dataToSend, userId: userData.id };
      mutationEditReview({
        variables: dataUpdate,
        context: CONTEXT_USER
      })
        .then(() => {
          refetchFeedbackReview();
          refetchAverageReview();
          refetchReviewList();
          message.success('Successfully submit feedback');
        })
        .catch(() => {
          message.error('Something went wrong !');
        });
    } else {
      mutationAddReview({
        variables: dataToSend,
        context: CONTEXT_USER
      })
        .then(() => {
          refetchFeedbackReview();
          refetchAverageReview();
          refetchReviewList();
          message.success('Successfully submit feedback');
        })
        .catch(() => {
          message.error('Something went wrong !');
        });
    }
  };

  return (
    <MemberCardBoxComponent
      fullResume={fullResume}
      toggleResume={toggleResume}
      rating={rating}
      description={description}
      setDescription={setDescription}
      handleSubmitReview={handleSubmitReview}
      detailClassroom={detailClassroom}
      classroomLoading={classroomLoading}
      reviewList={reviewList}
      reviewListLoading={reviewListLoading}
      feedbackReview={feedbackReview}
      feedbackReviewLoading={feedbackReviewLoading}
      averageReview={averageReview}
      averageReviewLoading={averageReviewLoading}
      {...props}
    />
  );
};

export default ReviewCardBoxContainer;
