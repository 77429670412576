import React from 'react';
import { Icon } from 'antd';
import { WrapperPublication } from './Publication.style';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import Assets from '../../../../Assets';
import { AttachementItemIcon } from '../../../../SharedComponents';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';

export default function EducationItem({ onClickEdit, isUser, data }) {
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <WrapperPublication>
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={data.avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="school">{renderValue(data.title, '-')}</div>
          <div className="degree">{renderValue(data.company, '-')}</div>
          <div className="date">{renderValue(data.time, '-')}</div>
          <div className="description">{renderValue(data.date, '-')}</div>
          <div className="description" style={{ padding: '15px 0px' }}>
            {renderValue(data.description, '-')}{' '}
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'start'
              }}
            >
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.grey}
                // fontStyle="italic"
              >
                Publication URL :
              </ThypoStyled>
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.blue_link}
                // fontStyle="italic"
                style={{ paddingLeft: '5px' }}
              >
                {renderValue(data.dataEdit.publication_url, '-')}
              </ThypoStyled>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            {data.attachments &&
              data.attachments !== '[]' &&
              data.attachments.length >= 1 &&
              data.attachments.map((res, key) => (
                <AttachementItemIcon key={key} url={res.url} name={res.name} />
              ))}
          </div>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      <div className="divider" />
    </WrapperPublication>
  );
}
