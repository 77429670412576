import DOMPurify from 'dompurify';
import React, { memo } from 'react';
import { formatCurrency, parseCurrency } from '../../Utils/currencyFormat';
import { WrapperBodyNotification } from './NotificationActivity.style';
import { RenderFixedWord } from './NotificationContent.config';

export default memo(function NotificationContent({ type, data }) {
  const _generateNotification = (subtype, props) => {
    const { message, title } = props;
    const urlNotif = window.location.href;
    const notifType = urlNotif.substring(urlNotif.lastIndexOf('/') + 1);
    if (subtype === 'social-post-company') {
      return (
        <p>
          <strong style={{ textTransform: 'capitalize' }}>{title} </strong>
          {message && message.length > 190
            ? message?.slice(0, 180) + '...'
            : message}
        </p>
      );
    } else if (subtype === 'congratulations') {
      return (
        <p>
          Congratulations! You just became{' '}
          <strong>the most finished practice test</strong>
        </p>
      );
    } else if (subtype === 'company-placement-reminder-contract') {
      return (
        <p>
          <strong style={{ textTransform: 'capitalize' }}>{title} </strong>
          {message}
        </p>
      );
    } else if (subtype.includes('business-trip')) {
      return (
        <p>
          {notifType === 'all' && (
            <strong style={{ textTransform: 'capitalize' }}>{title} </strong>
          )}
          <span
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
          />
        </p>
      );
    } else if (subtype === 'performance-classification-notif') {
      const messageLenght = message.length;

      const getIndexAfterInvitation = message.indexOf('committee in');
      const getIndexAfterInvitationClass = message.indexOf('for position');
      const getIndexAfterStatus = message.indexOf('Evaluation for');
      const getIndexAfterStatusClass = message.indexOf(' in ');

      const getIndexBeforeInvitation = getIndexAfterInvitation + 12;
      const getIndexBeforeStatus = getIndexAfterStatus + 14;
      const getIndexBeforeClass = getIndexAfterStatusClass + 4;
      const getIndexReminder = message.indexOf('ended in');
      const getIndexComplete = message.indexOf('is complete');

      const isInvitation = getIndexAfterInvitation > 0;
      const isReminder = getIndexReminder > 0;
      const isComplete = getIndexComplete > 0;

      const classifictionBold = message.substr(
        getIndexBeforeInvitation,
        getIndexAfterInvitationClass - getIndexBeforeInvitation
      );

      const positiomInStatusBold = message.substr(
        getIndexBeforeStatus,
        getIndexAfterStatusClass - getIndexBeforeStatus
      );

      const positionBold = message.substr(
        getIndexAfterInvitationClass + 12,
        messageLenght
      );

      const classificationClassBold = message.substr(
        getIndexBeforeClass,
        (isReminder ? getIndexReminder : getIndexComplete) - getIndexBeforeClass
      );

      const renderManipulateMessage = () => {
        if (isInvitation) {
          return (
            <>
              <span>{`${message.substr(0, getIndexBeforeInvitation)}`}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {classifictionBold}
              </strong>
              <span>{message.substr(getIndexAfterInvitationClass, 12)}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {positionBold}
              </strong>
            </>
          );
        } else if (isReminder || isComplete) {
          return (
            <>
              <span>{`${message.substr(0, getIndexBeforeStatus)}`}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {positiomInStatusBold}
              </strong>
              <span>{message.substr(getIndexAfterStatusClass, 4)}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {`${classificationClassBold} `}
              </strong>
              <span>
                {message.substr(
                  isReminder ? getIndexReminder : getIndexComplete,
                  messageLenght
                )}
              </span>
            </>
          );
        }
      };

      return (
        <p>
          {/* {notifType === 'all' && ( */}
          <strong style={{ textTransform: 'capitalize' }}>{`${title} `}</strong>
          {/* )} */}
          {renderManipulateMessage()}
        </p>
      );
    } else if (
      subtype === 'performance-goal-activities' ||
      subtype === 'performance-task-activities'
    ) {
      const messageLenght = message.length;
      const getIndexSender = message.indexOf(':');
      const indexProgressUpdate = message.indexOf('Progress');
      const getIndexBeforeMoveTask = message.indexOf('Task is moved');

      const getIndexBeforeReporter = getIndexSender + 5;
      const getIndexBeforeAddTask = getIndexSender + 17;
      const getIndexBeforeDeleteTask = getIndexSender + 15;

      const getIndexAfterReporter = message.indexOf('as a new reporter');
      const getIndexAfterAddTask = message.indexOf('to this goal.');
      const getIndexAfterDeleteTask = message.indexOf('in this goal');

      const isSetReporter = getIndexAfterReporter >= 1;
      const isAddTask = message.indexOf('added new task') >= 1;
      const isDeleteTask = message.indexOf('deleted task') >= 1;
      const isUpdate = message.indexOf('Update') >= 1;
      const isProgress = indexProgressUpdate >= 1;
      const isMoveTask = getIndexBeforeMoveTask >= 1;
      const isPosting =
        getIndexSender >= 1 &&
        !(
          isSetReporter ||
          isAddTask ||
          isDeleteTask ||
          isUpdate ||
          isProgress ||
          isMoveTask
        );

      const senderBold = message.substr(0, getIndexSender);
      const reporterBold = message.substr(
        getIndexBeforeReporter,
        getIndexAfterReporter - getIndexBeforeReporter
      );
      const TaskBold = message.substr(
        isAddTask ? getIndexBeforeAddTask : getIndexBeforeDeleteTask,
        (isAddTask ? getIndexAfterAddTask : getIndexAfterDeleteTask) -
          (isAddTask ? getIndexBeforeAddTask : getIndexBeforeDeleteTask)
      );

      const renderManipulateMessage = () => {
        if (isSetReporter) {
          return (
            <>
              <strong
                style={{ textTransform: 'capitalize' }}
              >{`${senderBold}`}</strong>
              <span>{`${message.substr(getIndexSender, 5)}`}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {reporterBold}
              </strong>
              <span>
                {message.substr(getIndexAfterReporter, messageLenght)}
              </span>{' '}
            </>
          );
        } else if (isAddTask || isDeleteTask) {
          return (
            <>
              <strong
                style={{ textTransform: 'capitalize' }}
              >{`${senderBold}`}</strong>
              <span>{`${message.substr(
                getIndexSender,
                isAddTask ? 16 : 14
              )} `}</span>
              <strong style={{ textTransform: 'capitalize' }}>
                {TaskBold}
              </strong>
              <span>
                {message.substr(
                  isAddTask ? getIndexAfterAddTask : getIndexAfterDeleteTask,
                  messageLenght
                )}
              </span>
            </>
          );
        } else if (isPosting) {
          return (
            <>
              <strong
                style={{ textTransform: 'capitalize' }}
              >{`${senderBold}`}</strong>
              <span>{`${message.substr(getIndexSender, messageLenght)} `}</span>
            </>
          );
        } else if (isUpdate || isProgress || isMoveTask) {
          const indexBeforeUpdate = message.indexOf('from');
          const indexFieldUpdate = message.indexOf('Update');
          const beforeFrom = indexBeforeUpdate + 5;
          const indexAfterUpdate = message.indexOf('to');
          const afterFrom = indexAfterUpdate + 3;
          const before = message.substr(
            beforeFrom,
            indexAfterUpdate - beforeFrom
          );
          const after = message.substr(afterFrom, messageLenght);

          const fieldUpdate = message.substr(
            isProgress
              ? indexProgressUpdate
              : isMoveTask
              ? getIndexBeforeMoveTask
              : indexFieldUpdate,
            indexBeforeUpdate -
              (isProgress
                ? indexProgressUpdate
                : isMoveTask
                ? getIndexBeforeMoveTask
                : indexFieldUpdate)
          );

          if (indexBeforeUpdate >= 1) {
            return (
              <>
                <strong
                  style={{ textTransform: 'capitalize' }}
                >{`${senderBold}`}</strong>
                <span>{`: ${fieldUpdate}`}</span>
                <span>{message.substr(indexBeforeUpdate, 5)}</span>
                <strong
                  style={{ textTransform: 'capitalize' }}
                >{`${before}`}</strong>
                <span>{message.substr(indexAfterUpdate, 3)}</span>
                <strong
                  style={{ textTransform: 'capitalize' }}
                >{`${after}`}</strong>
              </>
            );
          } else {
            return (
              <>
                <strong
                  style={{ textTransform: 'capitalize' }}
                >{`${senderBold}`}</strong>
                <span>{`${message.substr(
                  getIndexSender,
                  messageLenght
                )} `}</span>
              </>
            );
          }
        } else {
          return <span>{message}</span>;
        }
      };

      return (
        <p>
          {/* {notifType === 'all' && ( */}
          <strong style={{ textTransform: 'capitalize' }}>{`${title} `}</strong>
          {/* )} */}
          {renderManipulateMessage()}
        </p>
      );
    } else if (
      subtype === 'performance-goal-comments' ||
      subtype === 'performance-task-comments'
    ) {
      const messageLenght = message.length;
      const getIndexBeforeComment = message.indexOf('commented on');
      const getIndexAfterComment = getIndexBeforeComment + 13;
      const getIndexMiddleComment = message.indexOf('post in');

      const commenterBold = message.substr(0, getIndexBeforeComment);
      const onPostBold = message.substr(
        getIndexAfterComment,
        getIndexMiddleComment - getIndexAfterComment
      );
      const goalBold = message.substr(getIndexMiddleComment + 7, messageLenght);

      return (
        <p>
          {/* {notifType === 'all' && ( */}
          <strong style={{ textTransform: 'capitalize' }}>{`${title} `}</strong>
          {/* )} */}
          <strong
            style={{ textTransform: 'capitalize' }}
          >{`${commenterBold}`}</strong>
          <span>{`${message.substr(getIndexBeforeComment, 13)} `}</span>
          <strong style={{ textTransform: 'capitalize' }}>{onPostBold}</strong>
          <span>{`${message.substr(getIndexMiddleComment, 7)} `}</span>
          <strong style={{ textTransform: 'capitalize' }}>{goalBold}</strong>
        </p>
      );
    } else if (subtype === 'social-personal-info-updated') {
      const _title = title.split(' has updated your ');
      const _message = title.split(_title?.[0]);
      return (
        <p>
          <strong
            style={{ textTransform: 'capitalize' }}
          >{`${_title[0]}`}</strong>
          <span>{_message}</span>
        </p>
      );
    } else if (subtype === 'social-referral-revision-request') {
      const userName = title.replace('asking you to revise your referral', '');
      return (
        <p>
          <strong>{userName}</strong>
          <span> ask for revision referral</span>
        </p>
      );
    } else if (subtype === 'social-referral-request') {
      const userName = title.replace('asking for your referral', '');
      return (
        <p>
          <strong>{userName}</strong>
          <span> asking for your referral</span>
        </p>
      );
    } else if (subtype === 'vendor-request-refund-approved') {
      const approved = subtype === 'vendor-request-refund-approved';
      const start = message.indexOf(approved ? ' approved' : ' rejected');
      const end = message.indexOf('invoice code of ');

      const vendor = message.slice(0, start);
      const msg = message.slice(start, end + 16);
      const invoice = message.slice(end + 16);

      return (
        <p>
          <strong>{vendor}</strong> {msg} <strong>{invoice}</strong>
        </p>
      );
    } else if (subtype === 'claim-limit-top-up') {
      const idx = message.lastIndexOf('top up ');

      const msg = message.slice(0, idx + 7);
      const raw = message.slice(idx + 7);

      const parsed = parseCurrency(raw);
      const amount = formatCurrency(parsed.amount, false, parsed.currency);

      return (
        <p>
          {type === 'all' && <strong>[Claim Limit] </strong>}
          {msg} <strong>{amount}</strong>
        </p>
      );
    } else if (subtype === 'claim-limit-adjust') {
      const fromIdx = message.lastIndexOf(' from ');
      const toIdx = message.lastIndexOf(' to ');

      const msg = message.slice(0, fromIdx);

      const fromRaw = message.slice(fromIdx + 6, toIdx);
      const fromParsed = parseCurrency(fromRaw);
      const from = formatCurrency(
        fromParsed.amount,
        false,
        fromParsed.currency
      );

      const toRaw = message.slice(toIdx + 4);
      const toParsed = parseCurrency(toRaw);
      const to = formatCurrency(toParsed.amount, false, toParsed.currency);

      return (
        <p>
          {type === 'all' && <strong>[Claim Limit] </strong>}
          {msg} from <strong>{from}</strong> to <strong>{to}</strong>
        </p>
      );
    } else if (subtype === 'financial-claim-activity') {
      const match = / (requested|rejected your|processed your|approved your|cancelled) /.exec(
        message
      );
      const claim = message.lastIndexOf(' claim');

      const user = message.slice(0, match.index);
      const m1 = message.slice(match.index, match.index + match[0].length);
      const policy = message.slice(match.index + match[0].length, claim);
      const m2 = message.slice(claim);

      return (
        <p>
          <strong>{user}</strong>
          {m1}
          <strong>{policy}</strong>
          {m2}
        </p>
      );
    } else if (subtype === 'social-people-company-tag') {
      const pattern = /"[a-zA-Z0-9 ]{1,256}"/g;
      const result = pattern.exec(title);
      const text = result?.input || title;
      const truncate = text.split(result?.[0] || '""');
      return (
        <p>
          <strong style={{ textTransform: 'capitalize' }}>{truncate}</strong>{' '}
          <span>{result}</span>
          {message && message.length > 190
            ? message?.slice(0, 180) + '...'
            : message}
        </p>
      );
    } else if (subtype.includes('company-time')) {
      const _subType = subtype.substring(13);
      const fixedWord = RenderFixedWord(_subType, message);
      const fixedWordIndex = message.indexOf(fixedWord);
      const firstWord = message.substr(0, fixedWordIndex);
      const lastWord = message.substr(
        fixedWordIndex + fixedWord.length,
        message.length
      );

      // untuk case leave balance edit
      const secondFixedWord = 'at the';
      const secondFixedWordIndex = message.indexOf(secondFixedWord);
      const secondFirstWord = message.substring(
        fixedWordIndex + fixedWord.length,
        secondFixedWordIndex
      );
      const secondLastWord = message.substr(
        secondFixedWordIndex + secondFixedWord.length,
        message.length
      );

      const renderManipulateMessage = (
        <p>
          {notifType === 'all' && <strong>{`[Time] `}</strong>}
          {fixedWordIndex !== -1 ? (
            <>
              <strong>{firstWord}</strong>
              {fixedWord}
              {_subType === 'leave-balance-edit' ? (
                <>
                  <strong>{secondFirstWord}</strong>
                  {secondFixedWord}
                  <strong>{secondLastWord}</strong>
                </>
              ) : (
                <strong>{lastWord}</strong>
              )}
            </>
          ) : (
            message
          )}
        </p>
      );

      return renderManipulateMessage;
    } else {
      return (
        <p>
          <strong style={{ textTransform: 'capitalize' }}>{title} </strong>
          {message && message.length > 190
            ? message?.slice(0, 180) + '...'
            : message}
        </p>
      );
    }
  };

  return (
    <WrapperBodyNotification>
      {_generateNotification(data.subtype, data)}
    </WrapperBodyNotification>
  );
});
