import { gql } from 'apollo-boost';

const GET_EMPLOYEES = gql`
  query getEmployee($id: uuid!, $key: String, $limit: Int, $offset: Int) {
    people_work_placements(
      where: {
        company: { _eq: $id }
        _or: [
          {
            global_user: {
              _or: [
                { name: { _ilike: $key } }
                {
                  people_profile_educations: {
                    company_profile: {
                      _or: [
                        { brand_name: { _ilike: $key } }
                        { legal_name: { _ilike: $key } }
                      ]
                    }
                  }
                }
              ]
            }
          }
          { regno: { _ilike: $key } }
          {
            company_address: {
              _or: [
                { office_name: { _ilike: $key } }
                { global_city: { name: { _ilike: $key } } }
                { global_province: { name: { _ilike: $key } } }
              ]
            }
          }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_employee_position {
        id
        name
      }
      company_address {
        id
        office_name
      }
    }
  }
`;

export { GET_EMPLOYEES };
