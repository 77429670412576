import React, { useState } from 'react';
import TagPeopleComponent from './TagPeople.component';
import { SearchService } from '../../Services';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';
import { useBlockMe } from '../../SharedComponents/CustomHook';

function TagPeopleUserProfile(props) {
  const { handlePost } = props;
  const [isFetching, setFetching] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [blockedMe, blockLoaded] = useBlockMe();

  useAsyncEffect(
    async isMounted => {
      if (keyword && blockLoaded) {
        setPeopleList([]);
        setFetching(true);
        await SearchService.searchByKeywordAndType(keyword, 'users')
          .then(response => {
            if (!isMounted()) return;
            let filteredUser = response.data.users.filter(
              item => !blockedMe?.includes(item.id)
            );
            setPeopleList(filteredUser);
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => {
            if (!isMounted()) return;
            setFetching(false);
          });
      }
    },
    [keyword, blockLoaded]
  );

  return (
    <TagPeopleComponent
      users={peopleList}
      setKeyword={setKeyword}
      handlePost={handlePost}
      isFetching={isFetching}
    />
  );
}

export default TagPeopleUserProfile;
