import React, { useState } from 'react';
import { message } from 'antd';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { ModalDeleteWLBCheck } from '../../Components';
import { ButtonAddList, TabWrapper } from '../../SharedComponents';
import { displayLoading } from '../../Utils/CommonUtils';
import AddEditMedicalHistory from './AddEditMedicalHistory';
import MedicalHistoryItem from './MedicalHistoryItem.js';
import MedicalHistoryModalDetail from './MedicalHistoryModalDetail.component';
import { DELETE_MEDICAL_HISTORY } from './UserMedicalHistory.graphql';

const initialOpen = {
  open: false,
  actionType: 'add',
  data: null,
  modalDelete: false
};

const getDate = date => {
  if (date) {
    return moment(date).format('ll');
  } else return date;
};

export default function UserMedicalHistoryComponent({
  loading = false,
  data,
  isUser,
  refetch
  // profileId,
}) {
  const [open, setOpen] = useState(initialOpen);
  const [detail, setDetail] = React.useState({
    open: false,
    data: null
  });

  const handleClickDetail = data =>
    setDetail({
      open: true,
      data: {
        ...data,
        date: `${getDate(data.start_date)} - ${getDate(data.end_date)}`
      }
    });
  const handleCloseDetail = () => setDetail({ open: false, data: null });

  const [deleteMedical] = useMutation(DELETE_MEDICAL_HISTORY, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleModalEdit = state => {
    setOpen({
      ...open,
      modalDelete: false,
      open: state === 'open',
      data: state === 'open' ? open.data : null,
      actionType: state === 'open' ? 'edit' : null
    });
  };

  const onButtonEditClick = data => {
    setOpen({
      ...open,
      open: true,
      actionType: 'edit',
      data: data
    });
  };

  const handleDelete = () => {
    deleteMedical({
      variables: {
        id: open.data.id
        // userId: profileId,
      }
    })
      .then(() => {
        refetch();
        handleModalEdit('close');
        message.success(`Berhasil menghapus rekam medis`);
      })
      .catch(err => {
        message.error(
          `Gagal menghapus rekam medis, silakan coba lagi nanti, ${err}`
        );
      });
  };

  const _medicalHistory = () => {
    return (
      <>
        <TabWrapper>
          {data &&
            data.people_profile_medical_histories.map((res, i) => (
              <MedicalHistoryItem
                key={`${i}-${res.id}`}
                data={{
                  ...res,
                  date: `${getDate(res.start_date)} - ${getDate(res.end_date)}`
                }}
                isUser={isUser}
                onClickDetail={handleClickDetail}
                onEdit={() => onButtonEditClick(res)}
              />
            ))}
        </TabWrapper>
        {isUser && (
          <ButtonAddList
            onClick={() =>
              setOpen({ ...open, open: true, actionType: 'add', data: null })
            }
            label="Tambah Rekam medis"
          />
        )}
        <MedicalHistoryModalDetail
          open={detail.open}
          data={detail.data}
          onClose={handleCloseDetail}
          isUser={isUser}
          onEdit={() => {
            setDetail(e => ({ ...e, open: false }));
            onButtonEditClick(detail.data);
          }}
        />
        <AddEditMedicalHistory
          refetch={refetch}
          open={open.open}
          actionType={open.actionType}
          data={open.data}
          setOpen={setOpen}
          onClose={() => setOpen(initialOpen)}
        />
        <ModalDeleteWLBCheck
          open={open.modalDelete}
          centered
          target="Rekam Medis"
          onClose={() => handleModalEdit('open')}
          onDelete={handleDelete}
        />
      </>
    );
  };
  return <div>{loading ? displayLoading(loading) : _medicalHistory()}</div>;
}
