import React from 'react'
import { BoxShadow, Title, Content } from './WidgetPeopleProfile.style'

export default function WidgetProfilWrapper({title, ...content}) {
  return (
    <BoxShadow>
      <Title>{title}</Title>
      <Content {...content} />
    </BoxShadow>
  )
}
