import { Form, Select } from 'antd';
import React from 'react';
import { TitleField } from '../GlobalStyle';

export const FormDateInline = ({
  title,
  required,
  date,
  getFieldDecorator
}) => {
  const { Option } = Select;
  return (
    <div style={{ padding: '0px 22px', marginBottom: 8 }}>
      <Form.Item
        label={<TitleField>{title}</TitleField>}
        style={{
          marginBottom: 0
        }}
        required={required}
      >
        {date.map((res, i) => {
          return (
            <Form.Item
              key={`${i}-`}
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '33.33%'
              }}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: [
                  {
                    required: res.isRequired,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Select
                  placeholder={res.placeholder}
                  style={{ width: '90%' }}
                  allowClear={res.allowClear ?? false}
                >
                  {res.option(res.optionList).map((option, i) => {
                    const fieldTitle = title.replace(/ /g, '');
                    const dataTestId = `${fieldTitle}-${res.fieldName}-${option.value}`;
                    return (
                      <Option
                        key={`${i}-opt`}
                        data-testId={dataTestId}
                        value={option.value}
                      >
                        {option.name}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          );
        })}
      </Form.Item>
    </div>
  );
};
