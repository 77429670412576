import gql from 'graphql-tag';

const GET_GLOBAL_BANK_LIST = gql`
	{ global_banks{
    id
    name
    code
    swift
    bank_fields
  }
}
`;

const ADD_BANK_ACCOUNT = gql`
	mutation addBankAccount($data: [company_profile_banks_insert_input!]!){
    insert_company_profile_banks(objects:$data){
      affected_rows
    }
  }
`;

const EDIT_BANK_ACCOUNT  = gql`
	mutation editBankAccount($bank:Int!, $bank_name:String!, $account_name:String!, $account_number:String!, $id:Int!){
  update_company_profile_banks(
    _set:{
      bank:$bank,
      bank_name:$bank_name,
      account_name:$account_name,
      account_number:$account_number
    },where:{
        id:{_eq:$id}
    }
  ){
    affected_rows
  }
}
`;
export {
	GET_GLOBAL_BANK_LIST,
	EDIT_BANK_ACCOUNT,
	ADD_BANK_ACCOUNT
}
