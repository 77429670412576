import styled from 'styled-components';

const CenterLayoutWrapper = styled.div`
  @media (min-width: 1200px) {
    width: 1195px;
    min-width: 1216px;
  }

  margin: auto;
`;

export { CenterLayoutWrapper };
