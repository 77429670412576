import styled from "styled-components";

export const ButtonFull = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #039be5;
  padding: 24px 0px;
  font-size: 16px;
  font-weight: 600;
`;