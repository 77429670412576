import Constants from '../../Config/Constants';
import styled from 'styled-components';

export default {
  select: {
    width: '100%',
    fontSize: 12
  },
  rowContainer: {
    padding: 10
  },
  nameText: {
    fontSize: 12,
    color: Constants.COLORS.BLACK,
    margin: 0
  },
  descriptionText: {
    margin: 0,
    fontSize: 12,
    color: Constants.COLORS.GREY
  }
};

export const TagWrapper = styled.div`
  .ant-select-selection__placeholder {
    padding-left: 3px;
  }
  .ant-select-search__field {
    padding-left: 0;
  }
`;
