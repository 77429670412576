import React from 'react';
import * as _ from 'lodash';
import { Col, Row, Select } from 'antd';

import './PrivacyPostBox.css';
import style from './PrivacyPostBox.style';
import config from './PrivacyPostBox.config';
import Constants from '../../Config/Constants';
import { ClickOut } from '..';
const { Option } = Select;

const { PRIVACY, COMPANY_PRIVACY } = Constants;

const PrivacyPostBoxComponent = ({
  canSee,
  handlePost,
  setIsShowModal,
  setSelectPrivacy,
  postType,
  privacy
}) => {
  const _getPrivacyDescription = privacy => {
    let description = privacy.DESCRIPTION;

    if (privacy.VALUE === 4 && !_.isEmpty(canSee)) {
      const followerNames = canSee.map(follower => follower.name);
      description = _.join(followerNames, ', ');
      if (followerNames.length > 3) {
        description =
          _.join(followerNames.slice(0, 3), ', ') +
          `,..and ${followerNames.slice(3).length} other`;
      }
    }
    return (
      <div
        style={style.description}
        className={
          'component_privacy_post_box_item_description ' +
          (description.length > 45 &&
            'component_privacy_post_box_specific_follower')
        }
      >
        {description}
      </div>
    );
  };

  const _topRow = (privacy, span1, span2) => (
    <Row style={style.fullHeight}>
      <Col span={span1}>
        <img style={style.image} src={privacy.IMAGE} alt="imagePrivacy" />
      </Col>
      <Col span={span2} style={style.fullHeight}>
        <p style={style.text} className="component_privacy_post_box_item_title">
          {privacy.TEXT}
        </p>
      </Col>
    </Row>
  );

  const _bottomRow = privacy => (
    <Row>
      <Col span={3} />
      <Col span={21}>{_getPrivacyDescription(privacy)}</Col>
    </Row>
  );

  const _renderOption = () => {
    if (postType === Constants.POST_TYPE.COMPANY) {
      return COMPANY_PRIVACY.map(privacy => {
        return (
          <Option
            key={privacy.VALUE}
            value={privacy.VALUE}
            label={_topRow(privacy, 6, 18)}
            style={{ ...style.fullWidth, wordBreak: 'break-all' }}
          >
            <div className="component_privacy_post_box_item">
              {_topRow(privacy, 3, 21)}
              {_bottomRow(privacy)}
            </div>
          </Option>
        );
      });
    } else {
      return PRIVACY.map(privacy => {
        return (
          <Option
            key={privacy.VALUE}
            value={privacy.VALUE}
            label={_topRow(privacy, 6, 18)}
            style={{ ...style.fullWidth, wordBreak: 'break-all' }}
          >
            <div className="component_privacy_post_box_item">
              {_topRow(privacy, 3, 21)}
              {_bottomRow(privacy)}
            </div>
          </Option>
        );
      });
    }
  };

  const _handleSelect = value => {
    if (value === 4) {
      setIsShowModal(true);
    }
  };

  return (
    <ClickOut onClickOutside={() => setSelectPrivacy(false)}>
      <Select
        className="privacy-post-box"
        style={style.select}
        optionLabelProp="label"
        defaultValue={config.defaultValue}
        dropdownStyle={style.dropdownStyle}
        dropdownMenuStyle={style.menuStyle}
        onSelect={_handleSelect}
        onChange={value => handlePost('privacy', value)}
        onFocus={() => setSelectPrivacy(true)}
        value={privacy}
      >
        {_renderOption()}
      </Select>
    </ClickOut>
  );
};

PrivacyPostBoxComponent.propTypes = config.propTypes;
PrivacyPostBoxComponent.defaultProps = config.defaultProps;

export default PrivacyPostBoxComponent;
