import gql from "graphql-tag";

export const ADD_SERVICE_AGREEMENT = gql`
  mutation addSAByUser(
    $object: people_profile_service_agreements_insert_input!
  ) {
    insert_people_profile_service_agreements_one(object: $object) {
      id
    }
  }
`;

export const UPDATE_SERVICE_AGREEMENT = gql`
  mutation addSAByUser(
    $id: Int!
    $object: people_profile_service_agreements_set_input!
  ) {
    update_people_profile_service_agreements_by_pk(
      pk_columns: { id: $id }
      _set: $object
    ) {
      id
    }
  }
`;

export const DELETE_SERVICE_AGREEMENT = gql`
  mutation($id: Int!) {
    update_people_profile_service_agreements(
      _set: { deletedAt: "now()" }
      where: { _and: [{ id: { _eq: $id } }, { deletedAt: { _is_null: true } }] }
    ) {
      affected_rows
    }
  }
`;
