import React, { useState } from 'react';
import PostListComponent from './PostList.component';
import { Group } from '../../Services';
import useInfiniteScroll from '../../SharedComponents/CustomHook/useInfiniteScroll';
import { checkNextPage } from '../../Utils/CommonUtils';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';

const PostListCompany = props => {
  const { blockedMe, listType } = props;
  const [dataPost, setDataPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('team-post');
  const [isFetching, setIsFetching] = useState(false);
  const groupId = props.match.params.id;
  const postId = props.match.params.postId;

  useAsyncEffect(
    async isMount => {
      if (blockedMe && hasNextPage && !isFetching && (isBottom || page === 1)) {
        setIsFetching(true);
        if (listType === 'detail-post') {
          await Group.Discussion.getGroupPostId(groupId, postId)
            .then(res => {
              if (!isMount()) return;
              setDataPost([res]);
            })
            .catch(() => {
              setDataPost([]);
            })
            .finally(() => {
              if (!isMount()) return;
              setIsFetching(false);
              setIsBottom(true);
              setHasNextPage(false);
            });
        } else {
          await Group.Discussion.fetchGroupPostList(groupId, page)
            .then(response => {
              if (!isMount()) return;
              const { per_page, total, data } = response;
              const isNextPage = checkNextPage(total, page, per_page);
              setHasNextPage(isNextPage);
              if (isNextPage) {
                setPage(page + 1);
              }
              // BLOCKED POST FROM BLOCK LIST
              let filteredPost = data.filter(
                item => !blockedMe?.includes(item.userId)
              );
              setDataPost(dataPost.concat(filteredPost));
            })
            .catch(error => {})
            .finally(() => {
              if (!isMount()) return;
              setIsFetching(false);
              setIsBottom(false);
            });
        }
      }
    },
    [dataPost, groupId, hasNextPage, isBottom, isFetching, page, setIsBottom]
  );

  function deletePost(post) {
    Group.Discussion.deletePost(groupId, post.id)
      .then(res => {
        if (res.status === 200) {
          let newDataPost = dataPost.filter(p => p.id !== post.id);
          setDataPost(newDataPost);
          message.success('Postingan Anda telah dihapus');
        }
      })
      .catch(err => message.error('Something went wrong'));
  }

  function restartPage() {
    setDataPost([]);
    setHasNextPage(true);
    setPage(1);
  }
  return (
    <PostListComponent
      data={dataPost}
      isFetching={isFetching}
      deletePost={deletePost}
      restartPage={restartPage}
      {...props}
    />
  );
};
export default PostListCompany;
