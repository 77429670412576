import gql from "graphql-tag";

export const GET_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;

export const GET_CITY = gql`
  query($prov: Int) {
    global_cities(
      where: { province: { _eq: $prov } }
      order_by: { name: asc }
    ) {
      id
      code
      name
    }
  }
`;
export const ADD_EDUCATION = gql`
  mutation(
    $school: uuid!
    $from: date!
    $to: date
    $is_present: Boolean
    $description: String
    $field_of_study: Int!
    $degree: Int!
  ) {
    insert_people_profile_educations(
      objects: {
        school: $school
        from: $from
        to: $to
        is_present: $is_present
        description: $description
        field_of_study: $field_of_study
        degree: $degree
      }
    ) {
      affected_rows
    }
  }
`;

// export const ADD_AWARD = gql`
//   mutation(
//     $title: String!
//     $company_name: String!
//     $location: jsonb!
//     $from: date!
//     $to: date
//     $is_present: Boolean!
//     $description: String
//   ) {
//     insert_people_profile_experiences(
//       objects: {
//         title: $title
//         company_name: $company_name
//         location: $location
//         from: $from
//         to: $to
//         is_present: $is_present
//         description: $description
//       }
//     ) {
//       affected_rows
//     }
//   }
// `;
// export const UPDATE_AWARD = gql`
//   mutation updateExperienceByUser(
//     $title: String!
//     $company_name: String!
//     $location: jsonb!
//     $from: date!
//     $to: date
//     $is_present: Boolean!
//     $description: String
//     $id: Int!
//   ) {
//     update_people_profile_experiences(
//       _set: {
//         title: $title
//         company_name: $company_name
//         location: $location
//         from: $from
//         to: $to
//         is_present: $is_present
//         description: $description
//       }
//       where: { id: { _eq: $id } }
//     ) {
//       affected_rows
//     }
//   }
// `;

export const DELETE_AWARD = gql`
mutation(
  $id: Int!,
  $userId: uuid!
) {
  update_people_profile_awards(
    _set: {deletedAt: "now()"},
    where: {
      _and: [
        {id: {_eq: $id}},
        {user: {_eq: $userId}},
        {deletedAt: {_is_null: true}}
      ]
    }
  ) {
    affected_rows
  }
}
`;

export const UPDATE_AWARD = gql`
 mutation updateAwardByUser($id:Int!,$object:people_profile_awards_set_input!){
  update_people_profile_awards_by_pk(pk_columns:{id:$id},_set:$object){
    id
  }
}
`;

export const ADD_AWARD = gql`
 mutation addAwardByUser($object:people_profile_awards_insert_input!){
  insert_people_profile_awards_one(object:$object){
    id
  }
}

`;
