import styled from 'styled-components';
import Assets from '../../Assets';

export const TopSettingGroupWrapper = styled.div`
  background: ${Assets.COLOR_PALLETE.white};
  border-top-left-radius: ${props => (props.borderRadius ? `3px` : `none`)};
  border-top-right-radius: ${props => (props.borderRadius ? `3px` : `none`)};
  padding-bottom: 12px;
  border-bottom: ${props =>
    props.border ? `1px solid ${Assets.COLOR_PALLETE.grey3}` : `none`};

  .ant-page-header {
    padding: 0;
  }

  .ant-page-header-heading-title {
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    color: ${props =>
      props.color ? props.color : Assets.COLOR_PALLETE.dark_blue};
    font-size: 16px;
    line-height: 40px;
  }
  .ant-page-header-back {
    line-height: 22px;
    margin-left: 10px;
  }
  /* .ant-page-header-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
`;
