import { AuthenticationService } from '../..';
import Constants from '../../../Config/baseUrl';
import BaseService from '../../BaseService';
// import { isEmpty } from 'lodash';

const fetchCompanyPostList = nextPage => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: 3
    }
  };

  const userData = AuthenticationService.getUserData();
  const url = Constants.COMPANY_POST(userData.id, userData.company_id);
  return BaseService.get(url, parameters);
};

const createPost = body => {
  const userData = AuthenticationService.getUserData();
  const url = Constants.COMPANY_POST(userData.id, userData.company_id);

  return BaseService.post(url, body);
};

const deletePost = postId => {
  const userData = AuthenticationService.getUserData();
  const postOnCompany = Constants.COMPANY_POST_ID(
    userData.id,
    userData.company_id,
    postId
  );
  return BaseService.delete(postOnCompany);
};

const likePostCompany = (postUserId, postId, likeValue) => {
  const userData = AuthenticationService.getUserData();
  const url = Constants.COMPANY_POST_LIKE(
    postUserId,
    userData.company_id,
    postId,
    likeValue
  );
  return BaseService.post(url);
};

const postComment = (postOwnerId, postId, content) => {
  const commentContent = {
    content: content
  };
  const userData = AuthenticationService.getUserData();
  return BaseService.post(
    Constants.COMPANY_POST_COMMENT(postOwnerId, userData.company_id, postId),
    commentContent
  );
};

const fetchCommentList = (postOwnerId, postId, nextPage, maxResults) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: maxResults
    }
  };
  const userData = AuthenticationService.getUserData();
  const commentList = Constants.COMPANY_POST_COMMENT(
    postOwnerId,
    userData.company_id,
    postId
  );
  return BaseService.get(commentList, parameters);
};

const deleteComment = comment => {
  const userData = AuthenticationService.getUserData();
  const commentUrl = Constants.COMPANY_POST_COMMENT_ID(
    comment.userId,
    userData.company_id,
    comment.postId,
    comment.id
  );
  return BaseService.delete(commentUrl);
};

const getPost = (id, userId) => {
  const userData = AuthenticationService.getUserData();
  const url = `${Constants.COMPANY_POST(userId, userData.company_id)}/${id}`;
  return BaseService.get(url);
};

export default {
  fetchCompanyPostList,
  createPost,
  fetchCommentList,
  deletePost,
  likePostCompany,
  deleteComment,
  postComment,
  getPost
};
