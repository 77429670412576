import styled from 'styled-components';
import Assets from '../../Assets';
import { Avatar } from 'antd';

export const BoxShadow = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
`;

export const BannerBoxWrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 256px;
  border-radius: 5px;
  padding: 0px 26px 44px 26px;
  display: flex;
  flex-wrap:wrap;
  font-family: ${Assets.FONT.MULI};
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};

  @media (max-width: 1199px) {
   gap: 24px;
   justify-content: center;
   padding-bottom: 16px;
  }

  .avatar {
    position: relative;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      flex: 100%;
      text-align:center;
    }

    img {
      width: 168px;
      height: 168px;
      border-radius: 100%;
    }
    .absolute-icon {
      width: 168px;
      height: 168px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        .icon-profile-camera {
          opacity: 1;
        }
      }
      .icon-profile-camera {
        opacity: 0;
      }
    }
  }

  .information-right {
    @media (min-width: 1199px) {
      border-left: 1px solid ${Assets.COLOR_PALLETE.grey};
      padding-top: 25px;
  }


  }

  .information {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
    flex: 50;
  }
    @media (min-width: 1199px) {
      width: 40%;
      padding-left: 40px;
  }

    & div > span {
      display: block;
    }

    span {
      /* display: block; */
      font-size: 12px;
      /* color: ${Assets.COLOR_PALLETE.grey}; */
    }
    button {
      span {
        /* color: ${Assets.COLOR_PALLETE.white}; */
      }
    }
    .name {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 18px;
      color: ${Assets.COLOR_PALLETE.black};
    }
    .desc {
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.black2};
    }
    .number {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 15px;
      color: ${Assets.COLOR_PALLETE.black};
      display: inline-block;
    }
    .count {
      display: flex;
      margin-top: 8px;
      .total {
        width: 100%;
        cursor: pointer;
      }
    }
    td:nth-child(1) {
      color: ${Assets.COLOR_PALLETE.black};
      padding-right: 40px;
    }
  }

  .btnBannerGroup {
    display: flex;
    margin-top: 30px;
  }

  .component_kudos_post_card {
    top: 100px;
    left: 30%;
  }
`;

export default {
  removeBorderRight: {
    // borderTopRightRadius: 0,
    // borderBottomRightRadius: 0
  },
  removeBorderLeft: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    padding: 10,
    marginLeft: 5
  },
  icon: {
    fontSize: 16,
    paddingRight: 20,
    color: '#CECFD0'
  },
  icon2: {
    fontSize: 16,
    paddingRight: 20,
    color: '#CECFD0',
    height: 18,
    width: 'auto',
    cursor: 'pointer'
  },
  iconChangeProfile: {
    fontSize: 55,
    color: '#fff',
    width: 'auto'
  },
  socialMediaSection: {
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
};

export const AvatarStyled = styled(Avatar)`
  width: auto;
  height: 31px;

  img {
    width: auto !important;
    height: 100% !important
    margin: auto;
  }
`;
export const AvatarStyled2 = styled(Avatar)`
  width: 100% !important;
  height: 100% !important;

  img {
    width: auto !important;
    height: 100% !important
    margin: auto;
  }
`;

export const ButtonEdit = styled.div`
  font-size: 18px;
  color: rgb(169, 168, 168);
  margin-top: 100%;
  &:hover {
    cursor: pointer;
  }
`;
export const WrapperButton = styled.div`
  margin-left: auto;
  padding: 0px 32px 0px;
  height: 48px;
`;

export const WrapperHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
`;
