import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';

import Axios from 'axios';
import { Form, Input, Switch, message } from 'antd';
import { TitleField } from '../DetailScreen.style';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { ADD_AWARD, DELETE_AWARD, UPDATE_AWARD } from './Experience.graphql';
import { generateDateFormat, getErrorMessage } from '../HelperDetailScreen';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom,
  FormDateInline
} from '../../../../SharedComponents';
import { AuthenticationService } from '../../../../Services';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import { month } from '../../../../Containers/UserProfileAbout/HelperAbout';

const AddEditComponents = ({
  open,
  onClose,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen,
  openState,
  nameUser
}) => {
  const { validateFields, getFieldDecorator, resetFields } = form;
  const { TextArea } = Input;
  const [share, setShare] = useState(false);
  const [deleteState, setDeleteState] = useState({ open: false });
  const [addAward] = useMutation(ADD_AWARD);
  const [UpdateAward] = useMutation(UPDATE_AWARD);
  const [deleteAward] = useMutation(DELETE_AWARD);

  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  useEffect(() => {
    if (data) {
      setAttachment(data.attachments);
      setShare(data?.is_share);
    } else {
      setAttachment([]);
      setShare(false);
    }
  }, [open, data, people_profile]);

  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const object = {
          is_share: share,
          reference_number: values.reference_number,
          letter_number: values.letter_number,
          certificate_number: values.certificate_number,
          award_name: values.award_name,
          issuing_organization: values.issuing_organization,
          reference_date: generateDateFormat(
            moment(`${values.year}/${values.month}/${values.day}`, 'YYYY/MM/DD')
          ),
          remark: values.remark !== null ? values.remark : '',
          attachments:
            attachment.length > 0
              ? [
                  {
                    name: attachment?.[0]?.name,
                    url: attachment?.[0]?.url,
                    size: attachment?.[0]?.size
                  }
                ]
              : []
        };
        if (actionType === 'add') {
          addAward({
            variables: { object }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil menambahkan penghargaan`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan penghargaan, ${err}`);
            });
        } else if (actionType === 'edit') {
          UpdateAward({
            variables: {
              id: data.id,
              object: object
            }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil mengubah penghargaan`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah penghargaan, ${err}`);
            });
        }
      }
    });
  };

  function handleChange(val, e) {
    if (e === 'upload-attachment') {
      uploadAttachment(val);
    }
  }
  const listFields = [
    {
      labelName: 'Nomor Referensi',
      fieldName: 'reference_number',
      type: 'text',
      fieldValue: data && data?.reference_number,
      placeholder: 'Tambahakan nomor referensi',
      required: true
    },
    {
      labelName: 'Nomor Surat Penghargaan',
      fieldName: 'letter_number',
      type: 'text',
      fieldValue: data && data?.letter_number,
      placeholder: 'Tambahkan nomor surat penghargaan',
      required: false
    },
    {
      labelName: 'Tambahkan sertifikat penghargaan',
      fieldName: 'certificate_number',
      type: 'text',
      fieldValue: data && data?.certificate_number,
      placeholder: 'Tambahkan nomor sertifikat penghargaan',
      required: false
    },
    {
      labelName: 'Nama Penghargaan',
      fieldName: 'award_name',
      type: 'text',
      fieldValue: data && data?.award_name,
      placeholder: 'Tambahkan nama penghargaan',
      required: true
    },
    {
      labelName: 'Nama Pegawai',
      fieldName: 'organization_id',
      type: 'text',
      fieldValue: nameUser,
      placeholder: 'Tambahkan nama pegawai',
      required: true
    },
    {
      labelName: 'Organisasi Penerbit',
      fieldName: 'issuing_organization',
      type: 'text',
      fieldValue: data && data?.issuing_organization,
      placeholder: 'Tambahkan organisasi penerbit',
      required: true
    }
  ];

  const _switchComponentToRender = res => {
    const { type, fieldName, placeholder } = res;
    if (type === 'text') {
      return (
        <Input
          readOnly={fieldName === 'organization_id'}
          placeholder={placeholder}
          style={{ width: '100%' }}
        />
      );
    }
  };

  const handleDelete = () => {
    deleteAward({
      variables: {
        id: data.id,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus penghargaan`);
        onClose();
      })
      .catch(err => {
        message.error(`Gagal menghapus penghargaan, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
    document.getElementById('upload-award').value = null;
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
    }
  };
  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }

  const FieldReference_date = [
    {
      fieldName: 'day',
      fieldValue: generateDate(data && data.reference_date)?.[2],
      isRequired: true,
      placeholder: 'Tanggal',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue: generateDate(data && data.reference_date)?.[1],
      isRequired: true,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue: generateDate(data && data.reference_date)?.[0],
      isRequired: true,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];
  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Penghargaan`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}

          <FormDateInline
            title="Tanggal referensi"
            required={true}
            date={FieldReference_date}
            getFieldDecorator={getFieldDecorator}
          />

          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Komentar</TitleField>}
          >
            {getFieldDecorator('remark', {
              initialValue: data && data?.remark,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan komentar" />)}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-award"
              disabled={attachment.length > 0 ?? false}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {attachment !== '[]' &&
              attachment?.map((res, i) => (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Penghargaan"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEdit = Form.create({ name: 'Add_Award' })(AddEditComponents);

export default AddEdit;
