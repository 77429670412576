import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';

const fetchHomePostList = (nextPage, profileId) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: 3
    }
  };

  const userTimeline = Constants.POST(profileId);

  return BaseService.get(userTimeline, parameters);
};

const createPost = body => {
  const userId = AuthenticationService.getUserId();

  return BaseService.post(Constants.POST(userId), body);
};

const deletePost = (postOwnerId, postId) => {
  const url = Constants.POST_ID(postOwnerId, postId);
  return BaseService.delete(url);
};

const postLike = (postOwnerId, postId, value) => {
  const url = Constants.POST_LIKE_ID(postOwnerId, postId, value);
  return BaseService.post(url);
};

const postAward = (postOwnerId, postId, value) => {
  const url = Constants.POST_AWARD_ID(postOwnerId, postId, value);
  return BaseService.post(url);
};

const postLikeOnGroup = (postUserId, groupId, postOnGroupId, number) => {
  //const url = Constants.POST_LIKE_ID(po)
  return BaseService.post(
    `${Constants.POST_GROUP(
      postUserId,
      groupId
    )}/${postOnGroupId}/like/${number}`
  );
};

const getPostId = (userId, postId) => {
  const url = Constants.POST_ID(userId, postId);
  return BaseService.get(url);
};

const getGlobalPostLike = (postOwnerId, postId) => {
  const url = Constants.POST_LIKES(postOwnerId, postId);
  return BaseService.get(url);
};

const getCompanyPostLike = (postOwnerId, postId, companyId) => {
  const url = Constants.COMPANY_POST_LIKES(postOwnerId, postId, companyId);
  return BaseService.get(url);
};

const getGroupPostLike = (userId, groupId, postId) => {
  const url = Constants.GROUP_POST_LIKES(userId, groupId, postId);
  return BaseService.get(url);
};

const votePoll = (userId, postId, optionId) => {
  const url = Constants.POST_VOTE(userId, postId, optionId);
  return BaseService.post(url);
};

const getUsersByVote = (userId, postId, optionId) => {
  const url = Constants.USERS_BY_VOTE(userId, postId, optionId);
  return BaseService.get(url);
};

const undoVote = (userId, postId) => {
  const url = Constants.UNDO_VOTE(userId, postId);
  return BaseService.delete(url);
};

export default {
  fetchHomePostList,
  createPost,
  postLike,
  postAward,
  deletePost,
  postLikeOnGroup,
  getPostId,
  getGlobalPostLike,
  getCompanyPostLike,
  getGroupPostLike,
  votePoll,
  getUsersByVote,
  undoVote
};
