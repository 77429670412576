import gql from "graphql-tag";

export const UPDATE_SUMMARY = gql`
  mutation($userId: uuid!, $data: jsonb!) {
    update_people_profile(
      where: {
        _and: [{ id: { _eq: $userId } }, { deletedAt: { _is_null: true } }]
      }
      _append: { profile_fields: $data }
    ) {
      affected_rows
    }
  }
`;