import React from 'react';
import { Row } from 'antd';
import { Footer } from './VendorShipping.style';
// import Assets from '../../Assets';
import CardShipping from './CardShipping';
import { isEmpty } from 'lodash';
import DisplayLoading from '../DisplayLoading';
import ButtonWlb from '../ButtonWlb';

const VendorShippingComponent = props => {
  const {
    dataServiceShipping,
    vendorShipping,
    handleOnSave,
    disableSave
  } = props;
  return (
    <div>
      <Row gutter={24}>
        {!isEmpty(dataServiceShipping) ? (
          dataServiceShipping.map(item => (
            <CardShipping
              key={item.id}
              item={item}
              vendorShipping={vendorShipping}
              {...props}
            />
          ))
        ) : (
          <DisplayLoading />
        )}
      </Row>
      <Footer>
        <ButtonWlb
          type="primary"
          size="large"
          disabled={disableSave ? true : false}
          onClick={handleOnSave}
        >
          Save
        </ButtonWlb>
      </Footer>
    </div>
  );
};

export default VendorShippingComponent;
