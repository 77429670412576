import styled from 'styled-components';
import Assets from '../../Assets';
import { Typography } from 'antd';

const { Text } = Typography;

export const PollingRadioValue = styled(Text)`
  color: #333;
`;

export const PollingRadio = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 18px;
  margin-top: 8px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  background: linear-gradient(
    to right,
    #e5e5e5 ${props => props.value}%,
    #fff ${props => props.value}% 100%
  );
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export const VoteDetailWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const TextLink = styled.div`
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  color: ${Assets.COLOR_PALLETE.blue_link};
`;

export const style = {
  pointer: { cursor: 'pointer' },
  check_icon: {
    width: '17px',
    height: '17px',
    marginLeft: '8px'
  },
  textBlue: {
    color: Assets.COLOR_PALLETE.blue_link
  },
  textGrey: {
    color: Assets.COLOR_PALLETE.grey
  },
  bull: {
    color: Assets.COLOR_PALLETE.grey,
    padding: '0px 5px'
  }
};
