import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import { Form, Select, Input, message, Switch } from 'antd';
import { TitleField } from '../DetailScreen.style';
import { useMutation, useLazyQuery } from 'react-apollo';
import { ADD_OHSA, UPDATE_OHSA, DELETE_OHSA } from './Education.graphql';
import { generateDateFormat2, getErrorMessage } from '../HelperDetailScreen';
import moment from 'moment';
import { LIST_CATEGORY } from './Education.graphql';
import { removeDuplicate } from '../HelperDetailScreen';
import { month } from '../../../../Containers/UserProfileAbout/HelperAbout';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom,
  FormDateInline
} from '../../../../SharedComponents';
import { AuthenticationService } from '../../../../Services';
import Axios from 'axios';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';

const AddOhsaComponent = ({
  open,
  onClose,
  openState,
  form,
  dataQuery,
  onSearch,
  refetch,
  actionType,
  data,
  setListcategory,
  people_profile,
  setDataQuery,
  setOpen,
  nameUser
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields
  } = form;

  const { Option } = Select;
  const { TextArea } = Input;
  const [deleteState, setDeleteState] = useState({
    open: false
  });

  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorMax, setErrorMax] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [share, setShare] = useState(false);
  const [loading] = useState({
    category: false
  });

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [addOhsa] = useMutation(ADD_OHSA, {
    context: CONTEXT_USER
  });
  const [updateOHSA] = useMutation(UPDATE_OHSA, {
    context: CONTEXT_USER
  });
  const [deleteOhsa] = useMutation(DELETE_OHSA, {
    context: CONTEXT_USER
  });

  const [getCategory, { data: daftarCategory }] = useLazyQuery(LIST_CATEGORY, {
    context: CONTEXT_USER
  });
  const [getCategorySug, { data: daftarCategorySug }] = useLazyQuery(
    LIST_CATEGORY,
    {
      context: CONTEXT_USER
    }
  );

  useEffect(() => {
    setLoadingUpload(false);
    setAttachment([]);
    if (actionType === 'edit') {
      getCategory();
      setAttachment(data?.attachments);
      setShare(data?.is_share);
    } else if (actionType === 'add') {
      setShare(false);
    }
  }, [data, getCategory, setFieldsValue, people_profile, actionType]);

  const handleChange = (formValue, target, e) => {
    if (target === 'upload-attachment') {
      uploadAttachment(formValue);
    }
  };
  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setErrorMax(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (tempFile.length + attachment.length > 5) {
      setErrorMax(true);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      tempFile.forEach(val => {
        setLoadingUpload(true);
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
      Array.from(document.querySelectorAll('#upload-ohsa')).forEach(
        input => (input.value = '')
      );
    }
  };

  const listFields = [
    {
      labelName: 'Kode Kejadian',
      fieldName: 'incident_code',
      type: 'text',
      placeholder: 'Tambahkan kode kejadian',
      fieldValue: data?.incident_code ?? null,
      required: true
    },
    {
      labelName: 'Nama Perusahaan',
      fieldName: 'company_name',
      type: 'text',
      placeholder: 'Tambahkan nama perusahaan',
      fieldValue: data?.company_name ?? null,
      required: true
    },
    {
      labelName: 'Lokasi Kejadian',
      fieldName: 'incident_location',
      type: 'text',
      placeholder: 'Tambahkan lokasi kejadian',
      fieldValue: data?.incident_location?.incident_location ?? null,
      required: true
    },
    {
      labelName: 'Lokasi Perusahaan',
      fieldName: 'company_location',
      type: 'text',
      placeholder: 'Tambahkan lokasi perusahaan',
      fieldValue: data?.company_location?.company_location ?? null,
      required: true
    },
    {
      labelName: 'Pegawai',
      fieldName: 'name',
      type: 'text',
      placeholder: 'Tambahkan pegawai',
      fieldValue: nameUser,
      required: true,
      readOnly: true
    }
  ];

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        let attach = [];
        attachment.length >= 1 &&
          attachment.map(row => {
            attach.push(row);
          });
        const object = {
          is_share: share,
          incident_code:
            values.incident_code !== null ? values.incident_code : '-',
          company_name: values.company_name,
          incident_location: {
            incident_location: values.incident_location
          },
          company_location: {
            company_location: values.company_location
          },
          // employees: list.employee,
          category: values.category,
          incident_date_time: generateDateFormat2(
            moment(
              `${values.year}/${values.month}/${values.day} ${values.hour}:${values.minute}:00`,
              'YYYY-MM-DD hh:mm:00'
            )
          ),
          severity: values.severity,
          description: values.description !== null ? values.description : '',
          attachments: attach
        };
        if (actionType === 'add') {
          addOhsa({
            variables: { object }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(
                `Berhasil menambahkan riyawat kesehatan dan keselamatan kerja`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(
                msg ||
                `Gagal menambahkan riyawat kesehatan dan keselamatan kerja, ${err}`
              );
            });
        } else if (actionType === 'edit') {
          updateOHSA({
            variables: {
              id: data?.id,
              object: object
            }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(
                `Berhasil mengubah riyawat kesehatan dan keselamatan kerja`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(
                msg ||
                `Gagal mengubah riyawat kesehatan dan keselamatan kerja, ${err}`
              );
            });
        }
      }
    });
  };

  const handleRequestSugestion = (isOpen, fieldName) => {
    if (isOpen) {
      switch (fieldName) {
        case 'category':
          getCategorySug();
          break;
        default:
          break;
      }
    }
  };

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      labelName,
      isLoading,
      placeholder,
      readOnly
    } = res;
    if (type === 'select') {
      return (
        <Select
          // defaultActiveFirstOption
          showSearch
          size="large"
          onChange={(value, e) => handleChange(value, fieldName, e)}
          onSearch={e => onSearch(e, fieldName)}
          onDropdownVisibleChange={isOpen =>
            handleRequestSugestion(isOpen, fieldName)
          }
          dropdownStyle={{ whiteSpace: 'normal' }}
          loading={isLoading}
          style={
            fieldName === 'degree'
              ? { textTransform: 'capitalize', whiteSpace: 'normal' }
              : { whiteSpace: 'normal' }
          }
          filterOption={(input, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
        >
          <Option value={null} disabled>
            Pilih {labelName}
          </Option>
          {option.length > 0 &&
            option.map((res, i) => {
              return (
                <Option
                  style={
                    fieldName === 'degree'
                      ? { textTransform: 'capitalize', whiteSpace: 'normal' }
                      : { whiteSpace: 'normal' }
                  }
                  key={i}
                  value={res.name}
                >
                  {fieldName === 'school' ? res.legal_name : res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text') {
      return (
        <Input
          mode="tags"
          placeholder={placeholder}
          onChange={value => handleChange(value, 'tags')}
          style={{ width: '100%' }}
          readOnly={readOnly}
        ></Input>
      );
    }
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  const handleDelete = () => {
    deleteOhsa({
      variables: {
        id: data.id,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        resetFields();
        refetch();
        setDeleteState({ open: false });
        message.success(
          `Berhasil menghapus riyawat kesehatan dan keselamatan kerja`
        );
        onClose();
      })
      .catch(err => {
        message.error(
          `Gagal menghapus riyawat kesehatan dan keselamatan kerja, ${err}`
        );
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setListcategory({
      category: []
    });
    setDataQuery({
      category: []
    });
    // setShare(false);
  };

  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }
  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };

  const getTime = (date, format) => {
    if (data) {
      return moment(date).format(format);
    } else {
      return undefined;
    }
  };

  const FieldReference_date = [
    {
      fieldName: 'day',
      fieldValue: getTime(data && data.incident_date_time, 'DD'),
      isRequired: true,
      placeholder: 'Tanggal',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue: generateDate(data && data.incident_date_time)?.[1],
      isRequired: true,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue: generateDate(data && data.incident_date_time)?.[0],
      isRequired: true,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];

  const optionHour = [];
  for (let i = 1; i < 24; i++) {
    let a = i;
    if (i < 10) {
      a = '0' + i;
    }
    optionHour.push(a.toString());
  }
  const optionMenute = [];
  for (let i = 1; i < 60; i++) {
    let a = i;
    if (i < 10) {
      a = '0' + i;
    }
    optionMenute.push(a.toString());
  }
  const FieldReference_date2 = [
    {
      fieldName: 'hour',
      fieldValue: getTime(data && data.incident_date_time, 'hh'),
      isRequired: true,
      placeholder: 'Jam',
      optionList: optionHour,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'minute',
      fieldValue: getTime(data && data.incident_date_time, 'mm'),
      isRequired: true,
      placeholder: 'Menit',
      optionList: optionMenute,
      option: optionMenute => {
        return optionMenute.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];
  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
    document.getElementById('upload-award').value = null;
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'
          } Riyawat Kesehatan dan Keselamatan Kerja`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          {/* <TagFormFreeText
            labelName="Employee"
            fieldName="employee"
            fieldValue={''}
            isRequired={list.employee}
            placeholder="Add Employee"
            onChange={value => handleChange(value)}
            list={list.employee}
            onDelete={res => handleDeleteTag(res, 'employee')}
            getFieldDecorator={getFieldDecorator}
            onInputKeyDown={e => handleKeyDownTag(e, 'employee')} */}
          {/* /> */}
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={
              <TitleField>
                Kategori Riwayat Kesehatan dan Keselamatan Kerja
              </TitleField>
            }
          // help={res.extra}
          >
            {getFieldDecorator('category', {
              initialValue: data && data?.category,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                fieldName: 'category',
                type: 'select',
                fieldValue: data?.category ?? null,
                option: removeDuplicate([
                  ...(dataQuery?.category ?? []),
                  ...(daftarCategory?.people_profile_ohsa_categories ?? []),
                  ...(daftarCategorySug?.people_profile_ohsa_categories ?? [])
                ]),
                isLoading: loading.category,
                labelName: 'kategori riwayat kesehatan dan keselematan kerja'
              })
            )}
          </Form.Item>
          <FormDateInline
            title="Tanggal Kejadian"
            required={true}
            date={FieldReference_date}
            getFieldDecorator={getFieldDecorator}
          />
          <FormDateInline
            title="Waktu Kejadian"
            required={true}
            date={FieldReference_date2}
            getFieldDecorator={getFieldDecorator}
          />
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Dampak Kejadian</TitleField>}
          // help={res.extra}
          >
            {getFieldDecorator('severity', {
              initialValue: data && data?.severity,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                fieldName: 'severity',
                type: 'text',
                placeholder: 'Tambahkan dampak'
              })
            )}
          </Form.Item>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data && data?.description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              <TextArea
                rows={4}
                placeholder="Tambahkan keterangan"
                onChange={value => handleChange(value, 'description')}
              />
            )}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-ohsa"
              disabled={(attachment.length === 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorMax && <span>Jumlah lampiran tidak boleh lebih dari 5</span>}
            {attachment.length > 5 && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.length > 0 &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
            disabled={attachment.length > 5}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Riyawat Kesehatan dan Keselamatan Kerja"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEdit = Form.create({ name: 'Add_Ohsa' })(AddOhsaComponent);

export default AddEdit;
