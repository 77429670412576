import gql from 'graphql-tag';

export const GET_BADGE = gql`
  query getBadge($user: uuid!, $type: String!) {
    talent_assignments(
      where: {
        badge_classification: { _is_null: false }
        user: { _eq: $user }
        talentVersionByTalentVersion: {
          classification_type: { _eq: $type }
          status: { _eq: "COMPLETED" }
          visibility: { _eq: "PUBLISH" }
        }
      }
      order_by: [{ id: desc }]
    ) {
      id
      talent_score
      talent_box {
        id
        name
        badge
        description
      }
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
      }
    }
  }
`;
