import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { BannerDetailUser, WidgetPeopleProfile } from '../../Containers';
import { UserProfile, Kudos } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { Error404Page } from '../../Pages';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

const HeaderProfile = props => {
  const child = props.children;
  const params = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [isFounded, setIsFounded] = useState();
  const [nameUser, setNameUser] = useState('');
  const [kudosList, setKudosList] = useState([]);

  useAsyncEffect(
    async isMounted => {
      if (!isMounted()) return;
      setIsFetching(true);
      UserProfile.getUserInfo(params.profileId)
        .then(() => {
          if (!isMounted()) return;
          setIsFounded(true);
        })
        .catch(() => {
          if (!isMounted()) return;
          setIsFounded(false);
        })
        .finally(() => {
          if (!isMounted()) return;
          setIsFetching(false);
        });
    },
    [params]
  );
  useAsyncEffect(async isMounted => {
    await Kudos.getListKudos()
      .then(res => {
        if (!isMounted()) return;
        setKudosList(res.data);
      })
      .catch(() => {
        if (!isMounted()) return;
      });
  }, []);

  const isMobileScreen = useMediaQuery('(max-width: 1200px)');

  if (!isFounded && !isFetching) {
    return <Error404Page />;
  }

  return (
    <Row gutter={isMobileScreen ? 0 : 16} style={{ marginTop: '15px' }}>
      <Col span={isMobileScreen ? 24 : 18} id={'user-profile'}>
        <BannerDetailUser
          setNameUser={setNameUser}
          kudosList={kudosList}
          {...props}
        />
        {child}
      </Col>
      {!isMobileScreen && (
        <Col span={6}>
          <WidgetPeopleProfile nameUser={nameUser} {...props} />
        </Col>
      )}
    </Row>
  );
};

export default HeaderProfile;
