import { AuthenticationService } from '..';
import BaseService from '../BaseService';
import baseUrl from '../../Config/baseUrl';

const getListWidget = () => {
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.WIDGET(userId);
  return BaseService.get(url);
};

const updateWidgetStatus = (status, widgetId) => {
  const body = {
    status: status
  };
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.WIDGET_ID(userId, widgetId);
  return BaseService.put(url, body);
};

export default { getListWidget, updateWidgetStatus };
