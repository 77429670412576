import gql from 'graphql-tag';

export const LIST_SKILL_CATEGORY = gql`
  {
    people_skills(
      where: { parent: { _is_null: true } }
      order_by: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;
export const LIST_SKILL = gql`
  query($search: String, $limit: Int, $offset: Int) {
    skills: people_skills(
      where: {
        _and: [
          { deletedAt: { _is_null: true } }
          { _or: [{ name: { _ilike: $search } }] }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;
export const LIST_LEVEL = gql`
  {
    people_skill_levels(order_by: [{ id: asc }]) {
      id
      name
    }
  }
`;
export const ADD_SKILL = gql`
  mutation($objects: [people_profile_skills_insert_input!]!) {
    insert_people_profile_skills(
      on_conflict: {
        constraint: people_profile_skills_user_skill_key
        update_columns: [level, is_share, description, deletedAt]
      }
      objects: $objects
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SKILL = gql`
  mutation updateSkill($id: Int!, $object: people_profile_skills_set_input!) {
    update_people_profile_skills_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation($id: Int!) {
    delete_people_profile_skills(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const SET_SHARE_SKILLS = gql`
  mutation($section: Boolean!, $user: uuid!) {
    a: update_people_profile(
      _delete_key: { settings_section: "skills" }
      where: { id: { _eq: $user } }
    ) {
      affected_rows
    }

    b: update_people_profile(
      _append: { settings_section: "skills" }
      where: { id: { _eq: $user }, _not: { id: { _is_null: $section } } }
    ) {
      affected_rows
    }
  }
`;

export const LIST_SKILL_ENDORSEMENT = gql`
  {
    endorsement_relationships {
      id
      name
    }
  }
`;

export const ADD_SKILL_ENDORSEMENT = gql`
  mutation($objects: [people_profile_skill_endorsements_insert_input!]!) {
    insert_people_profile_skill_endorsements(objects: $objects) {
      affected_rows
    }
  }
`;
export const DELETE_SKILL_ENDORSEMENT = gql`
  mutation($id: Int!) {
    delete_people_profile_skill_endorsements(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const GET_LIST_USER_SKILL = gql`
  query($limit: Int, $id: uuid!, $userLogin: uuid!, $is_share: Boolean) {
    total: people_profile_skills_aggregate(
      where: {
        _and: [
          { user: { _eq: $id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    skills: people_profile_skills(
      where: {
        _and: [
          { user: { _eq: $id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      limit: $limit
      order_by: [{ id: desc }]
    ) {
      id
      level
      user
      is_share
      people_skill_level {
        id
        name
      }
      people_skill {
        id
        name
        people_profile_skills(
          where: {
            _and: [
              { user: { _neq: $id } }
              { deletedAt: { _is_null: true } }
              { is_share: { _eq: true } }
            ]
          }
          limit: 5
          order_by: { id: desc }
        ) {
          id
          user
          global_user {
            id
            name
            avatar
          }
        }
        people_profile_skills_aggregate(
          where: {
            _and: [
              { user: { _neq: $id } }
              { deletedAt: { _is_null: true } }
              { is_share: { _eq: true } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      endorsements_skill_count: people_profile_skill_endorsements_aggregate {
        aggregate {
          count
        }
      }
      checker_user_login_endorsements: people_profile_skill_endorsements(
        where: {
          _and: [
            { endorse_from: { _eq: $userLogin } }
            { deletedAt: { _is_null: true } }
          ]
        }
      ) {
        id
      }
    }
  }
`;

export const PEOPLE_WITH_SAME_SKILL = gql`
  query getPeopleSameSkill(
    $skillID: Int!
    $userID: String!
    $search: String
    $limit: Int
    $offset: Int
  ) {
    peopleSameSkill(
      skill_id: $skillID
      user_id: $userID
      search: $search
      limit: $limit
      offset: $offset
    ) {
      id: user_id
      name
      avatar
      position
      company
      is_followed
    }
  }
`;
export const GET_TOTAL_PEOPLE_WITH_SKILL = gql`
  query($skillID: Int!, $userID: uuid!, $search: String) {
    total: people_profile_skills_aggregate(
      where: {
        _and: [
          { skill: { _eq: $skillID } }
          { user: { _neq: $userID } }
          { is_share: { _eq: true } }
          { deletedAt: { _is_null: true } }
          {
            global_user: {
              name: { _ilike: $search }
              people_work_placements: {
                _and: [
                  { status: { _eq: "ACTIVE" } }
                  { deletedAt: { _is_null: true } }
                ]
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
