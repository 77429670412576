import React from 'react';
import { GroupWrapper } from './Team.style';
import { Row, Col } from 'antd';
import IMAGES from '../../../Assets/images';
import { Link } from 'react-router-dom';

const GroupComponent = props => {
  const { teamList, withHeader = true } = props;
  const renderHeader = () => (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col span={3}>
          <img
            src={IMAGES.TEAM_ICON}
            alt="HC Ecosystem"
            className="headerIcon"
          />
        </Col>
        <Col span={21}>
          <span className="headerTitle">Kelompok</span>
        </Col>
      </Row>
      <hr className="hr" />
    </div>
  );
  const renderBody = () => (
    <>
      {teamList.slice(0, 3).map(team => (
        <Row
          key={team.id}
          type="flex"
          align="middle"
          style={{ marginBottom: 10 }}
        >
          <Col span={6}>
            <img src={team.avatar} alt="avatar" style={{ width: 55 }} />
          </Col>
          <Col span={18}>
            <div className="team">
              <Link to={`/group/${team.id}/discussion`}>{team.name}</Link>
              <span className="teamDesc">
                {`${team.member.length + team.admin.length} orang`}
              </span>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );

  return (
    <GroupWrapper
      boxShadow={!withHeader ? 'none' : undefined}
      withHeader={withHeader}
    >
      {withHeader && renderHeader()}
      {renderBody()}
      {teamList.length > 3 && <Link to="/group">Lihat semua kelompok</Link>}
    </GroupWrapper>
  );
};

export default GroupComponent;
