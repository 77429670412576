import styled from 'styled-components';

const UserProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  .user-description {
    width: 120px;
    font-size: 11px;
    color: #a9a8a8;
  }

  .user-description-wrapper {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    font-family: 'Muli-regular', sans-serif;
  }
`;

const PopOverProfileAction = styled.div`
  font-family: 'Muli-regular', sans-serif;
  div:hover {
    background-color: #eff2f4;
  }

  .logout {
    margin-top: 5px;
    border-top: 1px solid #e8e8e8;
  }
`;

export { UserProfile, PopOverProfileAction };
