import { Icon } from 'antd';
import React from 'react';
import { TitleField } from '../../GlobalStyle';
import { ButtonAddAttachment } from './ButtonAddAttachment';
import { TooltipAttachment } from './TooltipAttachment';

export const Attachment = ({
  handleChangeFile,
  id,
  disabled,
  multiple = false
}) => {
  return (
    <div>
      <TitleField>
        Lampiran{' '}
        <TooltipAttachment>
          <Icon
            theme="outlined"
            type="info-circle"
            style={{ color: '#014a62', marginLeft: 12 }}
          />
        </TooltipAttachment>
      </TitleField>
      <ButtonAddAttachment
        handleChangeFile={handleChangeFile}
        id={id}
        disabled={disabled}
        multiple={multiple}
      />
    </div>
  );
};
