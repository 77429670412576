import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { Error404Page } from '../../Pages';
import { useParams } from 'react-router-dom';

import { GET_CLASSROOM_BY_ID } from './getClassroomById.graphql';

const Index = props => {
  const params = useParams();

  const [isFounded, setIsFounded] = useState();

  const { error, loading } = useQuery(GET_CLASSROOM_BY_ID, {
    variables: { classroomId: params.id },
    context: { headers: { 'X-Hasura-Role': 'user' } },

  });

  useEffect(() => {
    if (error) {
      if (error.graphQLErrors.length > 0) {
        setIsFounded(false);
      } else {
        setIsFounded(true);
      }
    }
    // eslint-disable-next-line
  }, [error]);

  if (isFounded === false && loading === false) {
    return <Error404Page />;
  }

  return props.children;
};

export default Index;
