import React, { useState } from 'react';
import { BodyBoxClassroom } from './SidebarClassroomInfo.style';
import {
  ButtonWlb,
  ModalConfirmation,
  ModalDeleteWithText
} from '../../Components';
import { message } from 'antd';
import { useMutation } from 'react-apollo';
import { CONSUME_APPROVE_CLASSROOM } from '../../Pages/ClassroomDetail/getClassroomById.graphql';
import { useHistory } from 'react-router-dom';

const RequestButtonClassroom = props => {
  const {
    isRequest,
    checkoutClassroom,
    requestJoin,
    cancelRequestJoin,
    isLoading,
    isPaid,
    paymentIsLoading,
    contribData
  } = props;

  const history = useHistory();
  // isRequest props are from TeamHomePage.container
  const [open, setOpen] = useState({
    confirmation: false,
    cancelModal: '',
    approve: false,
    dataDesc: ''
  });
  const [hideRequested, setHideRequested] = useState(false);
  const [APPROVE_CLASSROOM] = useMutation(CONSUME_APPROVE_CLASSROOM, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  let requestApproval =
    contribData.classroom_contrib_requests.length >= 1
      ? contribData.classroom_contrib_requests[0].status === 0
      : false;

  const classRoom = requestApproval
    ? contribData.classroom_contrib_requests[0].classroom.id
    : null;

  let requestClassroom =
    contribData.classroom_contrib_requests.length >= 1
      ? contribData.classroom_contrib_requests[0].status === 1
      : false;

  const handleCloseAction = () => {
    setOpen({
      confirmation: false,
      cancelModal: false,
      approve: false,
      dataDesc: ''
    });
  };

  const handleSubmit = () => {
    APPROVE_CLASSROOM({
      variables: {
        classroomId: classRoom,
        approve: open.approve,
        message: open.dataDesc
      }
    })
      .then(() => {
        window.location.reload();
        history.replace(`/social-learning/${classRoom}/discussion`);
        message.success(
          `${open.approve ? 'Approve' : 'Cancel'} Classroom success`
        );
        handleCloseAction();
      })
      .catch(err => {
        message.error(
          `${open.approve ? 'Approve' : 'Cancel'} Classroom error, ${err}`
        );
      });
  };

  const handleChange = value => {
    setOpen({
      ...open,
      dataDesc: value
    });
  };

  return (
    <BodyBoxClassroom
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {requestApproval && !requestClassroom ? (
        <div>
          <ButtonWlb
            type={'primary'}
            style={{ width: 230 }}
            onClick={() =>
              setOpen({
                ...open,
                confirmation: true,

                approve: true
              })
            }
            disabled={isLoading}
            // loading
          >
            Approve
          </ButtonWlb>

          <ButtonWlb
            type={'danger'}
            style={{ width: 230, marginTop: '10px' }}
            onClick={() =>
              setOpen({
                ...open,
                cancelModal: true,
                approve: false
              })
            }
            disabled={isLoading}
            // loading
          >
            Cancel
          </ButtonWlb>
        </div>
      ) : (
        <>
          <ButtonWlb
            type="primary"
            style={{ width: 230 }}
            hidden={isRequest}
            onClick={() => {
              if (!isPaid) requestJoin();
              if (isPaid) checkoutClassroom();
            }}
            disabled={isLoading}
            // loading={isLoading}
          >
            {paymentIsLoading
              ? 'Loading...'
              : isPaid
              ? 'Pay Now'
              : 'Meminta untuk Bergabung'}
          </ButtonWlb>
          <div hidden={!isRequest}>
            <ButtonWlb
              type={hideRequested ? 'danger' : 'default'}
              style={{ width: 230 }}
              onMouseEnter={() => setHideRequested(true)}
              onMouseLeave={() => setHideRequested(false)}
              onClick={() => cancelRequestJoin()}
              disabled={isLoading}
              // loading
            >
              {hideRequested ? 'Batalkan permintaan' : 'Diminta'}
            </ButtonWlb>
          </div>
        </>
      )}
      <ModalConfirmation
        open={open.confirmation}
        title={'Approval Social Learning Request'}
        content={
          'Are you sure you want to approve this social learning request'
        }
        onCancel={() => {
          setOpen({
            ...open,
            confirmation: false,
            cancelModal: false,
            dataDesc: ''
          });
        }}
        textPrimary={'Confirm'}
        onSubmit={handleSubmit}
      />
      <ModalDeleteWithText
        open={open.cancelModal}
        header={'Reject Social Learning Request ?'}
        textButton={'Reject'}
        message={
          'i understand that reject this social learning request is permanent and cannot be undone.'
        }
        textDesc={'Rejection Statement'}
        onClose={() => {
          setOpen({
            ...open,
            confirmation: false,
            cancelModal: false,
            dataDesc: ''
          });
        }}
        value={open.dataDesc}
        placeholder={'Add Reject Statement here'}
        handleChange={handleChange}
        onSubmit={handleSubmit}
      />
    </BodyBoxClassroom>
  );
};

export default RequestButtonClassroom;
