import styled from 'styled-components';
import Assets from '../../Assets';

export const Wrap = styled.div`
  padding: 32px;
  display: flex;
  font-family: ${Assets.FONT.MULI};
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};

  .information {
    width: 50%;
    span {
      display: block;
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.grey};
    }
    button {
      span {
        color: ${Assets.COLOR_PALLETE.white};
      }
    }
    .name {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 18px;
      color: ${Assets.COLOR_PALLETE.black};
    }
    .desc {
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.black2};
    }
    .number {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 15px;
      color: ${Assets.COLOR_PALLETE.black};
      display: inline-block;
    }
    .count {
      display: flex;
      margin-top: 8px;
      .total {
        width: 100%;
      }
    }
    td {
      line-height: 32px;
    }
    td:nth-child(1) {
      color: ${Assets.COLOR_PALLETE.black};
      padding-right: 40px;
    }
  }
`;

export const ButtonEdit = styled.div`
  position: absolute;
  right: 32px;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;

export const WrapperDependent = styled.div`
  padding: 24px 32px 12px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ItemWrapper = styled.div`
  margin-bottom: 6px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  p {
    margin: 0px;
  }
  .icon {
    color: #014a62;
    margin-right: 11px;
  }
  .value {
    max-width: 200px;
    line-height: normal;
    color: #000;
    font-size: 12px;
    margin-bottom: 4px;
  }
`;

export const ItemWrapperDetail = styled.div`
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .user-name {
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #014a62;
    }
    .icon {
      color: #014a62;
      cursor: pointer;
    }
  }
  .content-wrapper {
    .content {
      display: flex;
      align-items: flex-start;

      .label {
        font-size: 12px;
        color: #a9a8a8;
        width: 250px;
      }
      .value {
        font-size: 12px;
        color: #000;
        text-transform: capitalize;
      }
    }
  }
`;

export const WrapperItemDependent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  // only tablet and ups
  @media (min-width: 768px) {
    width: 46%;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }
    .title {
      h4 {
        font-size: 14px;
        color: #014a62;
        margin: 0;
      }
      span {
        font-size: 12px;
        color: #a9a8a8;
        text-transform: capitalize;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
  }
  .is-beneficiary {
    color: #014a62;
    font-size: 12px;
    font-weight: 600;
    margin-top: 16px;
  }
  .show-detail {
    font-size: 12px;
    text-align: left;
    color: #039be5;
    cursor: pointer;
    user-select: none;
    margin-top: 16px;
  }
`;

export default {
  iconEdit: {
    fontSize: 18,
    color: `${Assets.COLOR_PALLETE.grey}`
  }
};

export const TitleField = styled.span`
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
`;

export const WrapperField = styled.div`
  margin-bottom: 18px;
`;

export const WrapperAddDependent = styled.div`
  display: flex;
  padding: 0px 30px 24px 30px;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 8px;
    color: #039be5;
  }
  .text {
    cursor: pointer;
    font-size: 14px;
    color: #039be5;
    margin: 0px;
  }
`;
