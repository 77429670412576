import React, { useState } from 'react';
import AboutGroupComponent from './AboutGroup.component';
import { useParams } from 'react-router-dom';
import { Group } from '../../Services';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';
import { useHistory } from 'react-router-dom';

const AboutGroupContainer = props => {
  const { setIsRequest } = props;
  const history = useHistory();
  const [groupInfo, setGroupInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [isJoined, setIsJoined] = useState(false);
  // const [isRequest, setIsRequest] = useState(true);
  const params = useParams();
  const groupId = params.id;
  useAsyncEffect(
    async isMount => {
      setIsLoading(true);
      await Group.Discussion.fetchGroupInfo(groupId)
        .then(res => {
          if (!isMount()) return;
          setGroupInfo(res);
        })
        .finally(() => {
          if (!isMount()) return;
          setIsLoading(false);
        });
    },
    [groupId]
  );

  const requestJoin = () => {
    setIsLoading(true);
    Group.Member.joinGroup(groupId)
      .then(res => {
        if (groupInfo.privacy === 0) {
          history.push('/group/' + groupId + '/discussion');
        }
        if (groupInfo.privacy === 2) {
          message.success('Request has been sent to group admin.');
          setIsRequest(true);
        }
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => setIsLoading(false));
  };

  const cancelRequestJoin = () => {
    setIsLoading(true);
    Group.Member.cancelRequestJoin(groupId)
      .then(res => {
        if (res.status === 200) {
          message.success('Cancel request join group success');
          setIsRequest(false);
        }
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => setIsLoading(false));
  };

  return (
    <AboutGroupComponent
      groupInfo={groupInfo}
      isLoading={isLoading}
      requestJoin={requestJoin}
      cancelRequestJoin={cancelRequestJoin}
      {...props}
    />
  );
};

export default AboutGroupContainer;
