import React from 'react';
import { useApolloClient, useQuery } from 'react-apollo';

import { Avatar, Button, message } from 'antd';
import { CircularProgress } from '@material-ui/core';
import { OrgChart } from '@worklifebeyond/wlb-utils-components';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import DefaultAvatar from '../../Assets/images/photo/default.png';

import {
  buildListToTree,
  mapOrgChartNode,
  OrgChartDisplay,
  OrgChartDrawer,
  PrimitiveOrgNode
} from './OSStyles';
import OrganizationDetailsModal from './OrganizationDetailsModal.component';

import { AuthenticationService } from '../../Services';
import { GET_ORG_UNIT_CHILDREN } from './OrganizationStructureModal.graphql';
import { useParams } from 'react-router-dom';

const EMPTY_ARRAY = [];

const OrganizationStructureModal = props => {
  const { open, onClose } = props;
  const param = useParams();
  const companyId = param.id;

  const DEFAULT_OPTIONS = {
    context: {
      headers: {
        'X-Hasura-Role': 'public'
      }
    }
  };

  const instanceRef = React.useRef();

  const [detailsState, setDetailsState] = React.useState(null);
  const [listing, setListing] = React.useState(EMPTY_ARRAY);

  const client = useApolloClient();

  const { loading, error } = useQuery(GET_ORG_UNIT_CHILDREN, {
    skip: !open,
    fetchPolicy: 'network-only',
    variables: {
      organizationWhere: {
        deletedAt: { _is_null: true },
        company: { _eq: companyId },
        parent: { _is_null: true }
      },
      profileWhere: {
        deletedAt: { _is_null: true },
        company: { _eq: companyId },
        parent: { _is_null: true }
      },
      placementWhere: {
        deletedAt: { _is_null: true },
        company: { _eq: companyId },
        status: { _eq: 'ACTIVE' },
        global_user: { status: { _eq: 'ACTIVE' } }
      }
    },
    ...DEFAULT_OPTIONS,
    onCompleted: data => {
      if (data) {
        setListing(data.organizations.map(node => mapOrgChartNode(node, null)));
      } else {
        setListing(EMPTY_ARRAY);
      }
    }
  });

  const tree = React.useMemo(() => {
    return buildListToTree(listing);
  }, [listing]);

  const handleDetailsClose = () => {
    setDetailsState({ ...detailsState, open: false });
  };

  const handleDetailsClick = data => {
    setDetailsState({ open: true, ...data });
  };

  const expandNode = node => {
    const promise = client.query({
      query: GET_ORG_UNIT_CHILDREN,
      fetchPolicy: 'network-only',
      variables: {
        organizationWhere: {
          deletedAt: { _is_null: true },
          company: { _eq: companyId },
          parent: { _eq: node.id }
        },
        profileWhere: {
          deletedAt: { _is_null: true },
          company: { _eq: companyId },
          job_profile_supervisor: {
            organization: { _eq: node.id }
          }
        },
        placementWhere: {
          deletedAt: { _is_null: true },
          company: { _eq: companyId },
          status: { _eq: 'ACTIVE' },
          global_user: { status: { _eq: 'ACTIVE' } }
        }
      },
      ...DEFAULT_OPTIONS
    });

    promise.then(
      result => {
        const children = result.data.organizations;

        if (children.length < 1) {
          message.info(`${node.name} doesn't have have any suborganizations`);
          return;
        }

        setListing(prev => {
          const next = [];
          const mapped = [];

          const exists = new Set();

          for (const child of children) {
            exists.add(child.id);
            mapped.push(mapOrgChartNode(child, node));
          }

          for (const child of prev) {
            if (!exists.has(child.id)) {
              next.push(child);
            }
          }

          return next.concat(mapped);
        });
      },
      error => {
        console.error(error);

        const msg = `Failed to retrieve suborganizations for ${node.name}`;
        message.error(msg);
      }
    );
  };

  const collapseNode = node => {
    if (!node.children || node.children.length < 1) {
      return;
    }

    const removals = new Set();
    removals.add(node.id);

    // NOTE(intrnl): working around an issue with react-zoom-pan-pinch where
    // collapsing the top-most node results in the node being out of bounds.
    if (node.parent === null) {
      const instance = instanceRef.current;

      requestAnimationFrame(() => {
        instance.resetTransform();
      });
    }

    setListing(prev => {
      const next = [];

      for (const item of prev) {
        if (removals.has(item.parent)) {
          removals.add(item.id);
          continue;
        }

        next.push(item);
      }

      return next.length === prev.length ? prev : next;
    });
  };

  const renderNode = React.useCallback(
    node => {
      return (
        <OUNode
          data={node.data}
          hasChildren={!!node.children?.length}
          onDetails={handleDetailsClick}
          onExpand={() => expandNode(node)}
          onCollapse={() => collapseNode(node)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <OrgChartDrawer
      visible={open}
      onClose={onClose}
      closable
      title="Struktur Organisasi"
      placement="bottom"
      height="100%"
    >
      {error ? (
        <div>
          <div>Something went wrong while retrieving organization details</div>
        </div>
      ) : loading || !tree[0] ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <TransformWrapper
          options={{
            // NOTE(intrnl): this is about the limit of the orgchart, any less
            // and the line path drawing will break
            minScale: 0.75,
            maxScale: 2
          }}
        >
          {instance => (
            <OrgChartDisplay>
              {/* NOTE(intrnl): this seems to be the only way of retrieving the transform's instance */}
              {((instanceRef.current = instance), null)}

              <TransformComponent>
                <OrgChart
                  data={tree[0]}
                  render={renderNode}
                  chartId="osChart"
                />
              </TransformComponent>

              <Button.Group className="zoom-buttons">
                <Button onClick={instance.zoomIn}>+</Button>
                <Button onClick={instance.zoomOut}>-</Button>
              </Button.Group>
            </OrgChartDisplay>
          )}
        </TransformWrapper>
      )}

      <OrganizationDetailsModal
        {...detailsState}
        onClose={handleDetailsClose}
      />
    </OrgChartDrawer>
  );
};

const comparator = (a, b) => {
  return a.open === b.open;
};

export default React.memo(OrganizationStructureModal, comparator);

const DEFAULT_VISIBLE_PLACEMENTS = 5;
const EMPTY_PROFILE = {
  id: null,
  title: 'Unassigned',
  people_work_placements: []
};

/// <OUNode />
const isOUNodeEqual = (a, b) => {
  return a.data.id === b.data.id && a.hasChildren === b.hasChildren;
};

const OUNode = React.memo(props => {
  const { data, hasChildren, onDetails, onExpand, onCollapse } = props;

  const level = data.organization_level;
  const profiles = data.company_job_profiles;

  const handleArrowClick = hasChildren ? onCollapse : onExpand;

  const handleDetailsClick = jobProfileId => {
    onDetails({
      organizationId: data.id,
      organizationName: data.name,
      organizationLevel: level,
      jobProfileId: jobProfileId
    });
  };

  return (
    <PrimitiveOrgNode>
      <div className="header column">
        <span className="title">{data.name}</span>
        <div className="legend">
          <div
            className="legend-dots"
            style={{ backgroundColor: level?.color_hex || '#e5e5e5' }}
          />
          {level ? (
            <span>{level.name}</span>
          ) : (
            <span style={{ color: '#747272' }}>None</span>
          )}
        </div>
      </div>

      <div className="grid" style={{ '--items': profiles.length || 1 }}>
        {profiles.length ? (
          profiles.map(prof => (
            <OUSubnode
              key={prof.id}
              data={prof}
              onDetails={handleDetailsClick}
            />
          ))
        ) : (
          <OUSubnode data={EMPTY_PROFILE} />
        )}
      </div>

      {data.organization_children_aggregate.aggregate.count > 0 && (
        <button className="more" onClick={handleArrowClick}>
          <ExpandMoreIcon
            className="more-icon"
            style={{ transform: hasChildren ? 'rotate(180deg)' : '' }}
            title={`Show ${hasChildren ? 'less' : 'more'}`}
          />
        </button>
      )}
    </PrimitiveOrgNode>
  );
}, isOUNodeEqual);

// <OUSubnode />
const isOUSubnodeEqual = (a, b) => {
  return a.data.id === b.data.id;
};

const OUSubnode = React.memo(props => {
  const { data, onDetails } = props;

  const [visible, setVisible] = React.useState(DEFAULT_VISIBLE_PLACEMENTS);

  const placements = data.people_work_placements;
  const length = placements.length;

  const canSeeMore = visible < length;
  const canSeeLess = visible > DEFAULT_VISIBLE_PLACEMENTS;

  const handleSeeMore = ev => {
    ev.preventDefault();

    const next = visible + 5;
    setVisible(Math.min(next, length));
  };

  const handleSeeLess = ev => {
    ev.preventDefault();

    setVisible(DEFAULT_VISIBLE_PLACEMENTS);
  };

  return (
    <div className="subcard">
      <div className="header">
        <span className="title">{data.title}</span>

        {onDetails && data !== EMPTY_PROFILE && (
          <button className="expand" onClick={() => onDetails(data.id)}>
            <OpenInNewIcon className="expand-icon" />
          </button>
        )}
      </div>

      <div className="list">
        {placements.length > 0 ? (
          placements.slice(0, visible).map(emp => (
            <div key={emp.id} className="item">
              <Avatar
                src={emp.global_user.avatar || DefaultAvatar}
                className="item-avatar"
              />

              <span className="item-name">{emp.global_user.name}</span>
            </div>
          ))
        ) : (
          <div className="item">
            <Avatar src={DefaultAvatar} className="item-avatar" />
            <span className="item-name">Unassigned</span>
          </div>
        )}
      </div>

      {(canSeeMore || canSeeLess) && (
        <div className="item">
          {canSeeMore && (
            <button className="link" onClick={handleSeeMore}>
              +{length - visible} lebih
            </button>
          )}

          <span className="spacer" />

          {canSeeLess && (
            <button className="link" onClick={handleSeeLess}>
              Lihat Lebih Sedikit
            </button>
          )}
        </div>
      )}
    </div>
  );
}, isOUSubnodeEqual);
