import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import { ShowMoreText } from '../../../../Components/DetailUserSection/SeeMoreText';
import AddEditExperience from './AddEditExperience';
import ExperienceItem from './ExperienceItem';
import MoreExperience from './MoreExperience';

export default function Experience({
  isUser,
  dataExperiences,
  refetch,
  people_profile,
  profileId,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: false,
      actionType: 'edit',
      data
    }));
  };

  const _data = [...(dataExperiences || [])].slice(0, 2);

  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title="Pengalaman"
        action={isUser ? 'plus' : null}
      >
        {_data?.map((res, i) => {
          const { country_code = '', phone = '' } =
            res.dataEdit?.additional_fields?.referal_contact_phone || {};
          return (
            <ExperienceItem
              key={i}
              avatar={res.avatar}
              isUser={isUser}
              title={res.title}
              company={res.company}
              date={res.time}
              location={res.location}
              description={res.description}
              achievements={res?.dataEdit?.achievements}
              refName={res.dataEdit.referal_name}
              refPosition={res.dataEdit.referal_position}
              refPhone={`${country_code + phone || '-'}`}
              company_line_of_business={res.dataEdit?.company_line_of_business}
              onClickEdit={() => handleEditItem(res.dataEdit)}
              noDivider={_data.length === i + 1}
              contentPadding="25px 0px"
              contentWidth="650px"
              dividerWidth="720px"
            />
          );
        })}
        {dataExperiences?.length > 2 && (
          <ShowMoreText
            label="Tampilkan Lebih Banyak Pengalaman..."
            onClick={() => {
              setOpen(e => ({
                ...e,
                openMore: true
              }));
            }}
          />
        )}
      </DetailUserSection>
      <AddEditExperience
        setOpen={setOpen}
        openState={open}
        profileId={profileId}
        people_profile={people_profile}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onClose={() =>
          setOpen({
            open: false,
            data: null,
            actionType: null,
            fromList: false,
            openMore: open.fromList ? true : false
          })
        }
      />
      <MoreExperience
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataExperiences}
      />
    </>
  );
}
