import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

const GetDistrict = () => {
  const query = gql`
  {
    getSubdistrict{
        subdistrict_id
        subdistrict_name
    }
  }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.getSubdistrict;
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

const GetDistrictByCityId = cityId => {
  const query = gql`
  {
    getSubdistrict(city_id:${cityId}){
        subdistrict_id
        subdistrict_name
    }
  }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.getSubdistrict;
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

export default { GetDistrict, GetDistrictByCityId };
