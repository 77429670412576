import React from 'react';
import { MenuBar, CenterLayout } from '../../Components';
import { Content, Container } from './style';
import { useHistory } from 'react-router-dom';

const MenuBarContainer = props => {
  const { menuBarList, location } = props;
  const history = useHistory();
  const [_menuBarList, setMenuBarList] = React.useState([]);

  React.useEffect(() => {
    if (menuBarList.length >= 1) {
      setMenuBarList(menuBarList);
    }
  }, [menuBarList]);

  const setMenuActive = menu => {
    history.push(menu);
  };

  const RenderMenuBar = () => {
    return (
      <MenuBar
        menuBarList={_menuBarList}
        menuBarActive={location.pathname}
        setMenuBarActive={setMenuActive}
        searchParams={location.search}
      />
    );
  };

  const splittedPath = location.pathname.split('/');

  let listMenu = _menuBarList
    ? _menuBarList.filter((comp, i) => {
        const splittedMenu = comp.path.split('/');
        const temp = splittedMenu.map((p, id) => {
          if (!p.startsWith(':')) return p;
          return splittedPath[id];
        });

        return JSON.stringify(temp) === JSON.stringify(splittedPath);
      })[0]
    : {};

  if (!listMenu) {
    return true;
  }

  const RenderMenu = listMenu.renderComponent;

  return (
    <div>
      <RenderMenuBar />
      <Content>
        <Container>
          <CenterLayout>
            <RenderMenu {...props} />
          </CenterLayout>
        </Container>
      </Content>
    </div>
  );
};

export default MenuBarContainer;
