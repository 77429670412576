import React, { useRef, useState, useLayoutEffect } from 'react';

import { PostContentWrapper } from './PostContent.style';
import { ContentLink } from '../../Components';

const PostContent = props => {
  const { content } = props;
  const [postSize, setPostSize] = useState();
  const [showMore, setShowMore] = useState(false);
  const postRef = useRef();

  useLayoutEffect(() => {
    setPostSize(postRef.current.getBoundingClientRect());
  }, []);
  return (
    <PostContentWrapper>
      <div ref={postRef} className={showMore ? 'text-show' : 'text-hidden'}>
        {content &&
          content.split(/\r?\n/).map((line, id) => {
            return <ContentLink content={line} key={id} />;
          })}
      </div>
      {postSize?.height >= 120 && (
        <span className="text-more" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Lebih Sedikit' : 'Lebih Banyak'}
        </span>
      )}
    </PostContentWrapper>
  );
};

export default PostContent;
