import { GetModularSettings } from '@smartasn/wlb-utils-components';
import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout } from '../Components';
import { useGlobalState } from '../globalState';
import {
  AccountSettings,
  ClassroomDetail,
  CompanyPage,
  CreateGroup,
  DetailPostPage,
  Error404Page,
  FollowPage,
  FormProduct,
  GroupAboutPage,
  GroupHomePage,
  GroupListPage,
  HomePage,
  LoginPage,
  NotificationPage,
  SearchPage,
  StepsPage,
  UnderDevPage,
  UserPrintCVePage,
  UserProfile,
  VendorList,
  VendorPage,
  VendorPrintInvoicePage
} from '../Pages';
import { AuthenticationService } from '../Services';
import { hasModule } from '../Utils/CommonUtils';
import AuthenticationRoute from './AuthenticationRoute';

export const routes = {
  FOLLOW: { path: '/following/:profileId/', component: FollowPage },
  FOLLOWER: { path: '/followers/:profileId/', component: FollowPage },

  //Group
  MYTEAM_ABOUT: { path: '/group/:id/about', component: GroupAboutPage },
  MYTEAM: { path: '/group/:id/discussion', component: GroupHomePage },
  MYTEAM_POST_ID: {
    path: '/group/:id/post/:postId',
    component: GroupHomePage
  },
  MYTEAM_MEMBER: { path: '/group/:id/member', component: GroupHomePage },
  MYTEAM_SETTING: {
    path: '/group/:id/setting',
    component: GroupHomePage
  },
  CREATE_TEAM: { path: '/createGroup', component: CreateGroup },
  TEAM: { path: '/group', component: GroupListPage },

  NOTIFICATION: {
    path: '/notification/:type',
    component: NotificationPage
  },

  //Search Page
  ALL_SEARCH_RESULT: {
    path: '/search-result/all/',
    component: SearchPage
  },
  PEOPLE_SEARCH_RESULT: {
    path: '/search-result/people/',
    component: SearchPage
  },
  COMPANIES_SEARCH_RESULT: {
    path: '/search-result/company/',
    component: SearchPage
  },
  TEAM_SEARCH_RESULT: {
    path: '/search-result/group/',
    component: SearchPage
  },
  LEARNING_SEARCH_RESULT: {
    path: '/search-result/learning/',
    component: SearchPage
  },
  JOB_SEARCH_RESULT: {
    path: '/search-result/job/',
    component: SearchPage
  },

  ACCOUNT_SETTINGS: {
    path: '/account-settings',
    component: AccountSettings
  },
  ACCOUNT_SETTINGS_PRIVACY: {
    path: '/account-settings/privacy',
    component: AccountSettings
  },

  //Company Timeline
  COMPANY_PROFILE: {
    path: '/company/:id/detail',
    component: CompanyPage
  },
  COMPANY_POST: { path: '/company/:id/post', component: CompanyPage },
  COMPANY_POST_ID: {
    path: '/company/:userId/post/:postId',
    component: DetailPostPage
    // component: CompanyPage
  },
  COMPANY_PEOPLE: {
    path: '/company/:id/people',
    component: CompanyPage
  },
  COMPANY_REGULATION: {
    path: '/company/:id/regulation',
    component: CompanyPage
  },

  //User Profile
  USER_PROFILE: {
    path: '/profile/:profileId/detail',
    component: UserProfile
  },
  USER_PROFILE_POST_ID: {
    path: '/profile/:profileId/post/:postId',
    component: UserProfile
  },
  USER_PROFILE_POST: {
    path: '/profile/:profileId/post',
    component: UserProfile
  },

  //Global
  COMPANY_TIMELINE: { path: '/companypost', component: HomePage },
  DEFAULT: { path: '/', component: HomePage },
  // ORDER_DETAIL: '/vendor/:id/order/:orderid',

  DETAIL_POST: {
    path: '/user/:userId/post/:postId',
    component: DetailPostPage
  },

  //Vendor
  STEPS: { path: '/vendorRegistration', component: StepsPage },
  VENDOR_PRODUCT_EDIT: {
    path: '/vendor/:id/product/:productID',
    component: FormProduct
  },
  VENDOR_PRODUCT_ADD: {
    path: '/vendor/:id/product',
    component: FormProduct
  },
  VENDOR_LIST: { path: '/vendorList', component: VendorList },
  VENDOR_ORDER: { path: '/vendor/:id/order', component: VendorPage },
  VENDOR_PRODUCT: {
    path: '/vendor/:id/products',
    component: VendorPage
  },
  VENDOR_INFO: {
    path: '/vendor/:id/information',
    component: VendorPage
  },
  VENDOR_SHOWCASE: {
    path: '/vendor/:id/showcase',
    component: VendorPage
  },
  VENDOR_SHIPPING: {
    path: '/vendor/:id/shipping',
    component: VendorPage
  },
  VENDOR_BANK: {
    path: '/vendor/:id/bankaccount',
    component: VendorPage
  },
  VENDOR_BALANCE: { path: '/vendor/:id/balance', component: VendorPage },
  VENDOR_DETAIL: { path: '/vendor/:id', component: VendorPage },

  // Group Learning
  CLASSROOM_REVIEW: {
    path: '/social-learning/:id/review',
    component: ClassroomDetail
  },
  CLASSROOM_DETAIL: {
    path: '/social-learning/:id/discussion',
    component: ClassroomDetail
  },
  CLASSROOM_MEMBER: {
    path: '/social-learning/:id/member',
    component: ClassroomDetail
  },
  CLASSROOM_SETTING: {
    path: '/social-learning/:id/setting',
    component: ClassroomDetail
  },
  CLASSROOM_ABOUT: {
    path: '/social-learning/:id/about',
    component: ClassroomDetail
  },
  CLASSROOM_EDIT_POST: {
    path: '/social-learning/:id/post/:postId/edit',
    component: ClassroomDetail
  },
  CLASSROOM_POST: {
    path: '/social-learning/:id/post/:postId',
    component: ClassroomDetail
  },

  ERROR_404: { path: '/error404', component: Error404Page },
  PAGE_UNDER_DEV: {
    path: '/page-under-development',
    component: UnderDevPage
  }
};

const underDevUrl = ['/claim/apply', '/cash-advance/apply', '/reference/apply'];

const Router = () => {
  const [modular, setModular] = useGlobalState('modular');
  const userData = AuthenticationService.getUserData();
  const { data, loading } = GetModularSettings(userData['company_id']);

  useEffect(() => {
    if (data) {
      setModular(data);
    }
  }, [data, setModular]);

  let _route = Object.getOwnPropertyNames(routes);
  const filterRoute = val => _route.filter(i => !i.includes(val));
  if (!hasModule(data, 'vendor')) {
    _route = filterRoute('VENDOR');
    _route = filterRoute('STEPS');
  }
  if (!hasModule(data, 'learning')) {
    _route = filterRoute('CLASSROOM');
  }

  const RouterComponent = _route.map((route, id) => {
    return (
      <AuthenticationRoute
        key={id}
        path={routes[route].path}
        component={routes[route].component}
        exact
      />
    );
  });

  return (
    <Switch>
      <Route id="login" path={'/login'} component={LoginPage} />
      <Route
        id="login"
        path={'/invoice/:id'}
        component={VendorPrintInvoicePage}
      />
      <Route path={underDevUrl} component={UnderDevPage} />
      <Route
        id="login"
        path={'/resume/:id/:filename'}
        component={UserPrintCVePage}
      />
      {!loading && (
        <Layout>
          <Switch>
            {[...RouterComponent, <Route component={Error404Page} key="err" />]}
          </Switch>
        </Layout>
      )}
    </Switch>
  );
};

export default withRouter(Router);
