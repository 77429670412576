import Constants from '../../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../../Assets';

export const ImageProductUpload = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    width: 130px;
    height: 130px;
  }
`;

export const FormTitle = styled.div`
  color: ${Constants.COLORS.DARK_BLUE};
  font-family: ${Assets.FONT.MULI};
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
`;

export const FormWrapper = styled.div`
  background-color: ${Constants.COLORS.WHITE};
  padding: 45px;
  margin-top: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-family: ${Assets.FONT.MULI};
`;

export default {
  wrapperDiv: {
    backgroundColor: Constants.COLORS.WHITE,
    padding: 45
  },
  h2: {
    color: Constants.COLORS.DARK_BLUE
  },
  formItem: {
    marginBottom: 0
  },
  amountFormItem: {
    marginBottom: 0,
    marginLeft: 25
  },
  defaultMarginTop: {
    marginTop: 24
  },
  fullWidth: {
    width: '100%'
  },
  radioButton: {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  },
  weightShipping: {
    backgroundColor: Constants.COLORS.WHITE,
    padding: 45,
    marginTop: 24
  },
  processingTimeText: {
    color: Constants.COLORS.GREY,
    fontSize: 12,
    marginTop: 10,
    marginBottom: 10
  },
  divProcessTime: {
    display: 'inline-block'
  },
  smallFormItem: {
    width: 150,
    marginRight: 10
  },
  maximumText: {
    marginTop: 10,
    color: Constants.COLORS.GREY,
    textAlign: 'center'
  },
  spaceTopBottom: {
    marginTop: 10,
    marginBottom: 10
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 20
  },
  button: {
    marginLeft: 20,
    backgroundColor: Constants.COLORS.DARK_BLUE
  },
  imageProduct: {
    display: 'flex',
    flexDirection: 'column'
  },
  productAvatar: {
    width: 130,
    height: 130,
    marginBottom: 5
  },
  weightGroup: {
    display: 'flex'
  },
  imageWrapper: {
    marginBottom: 15,
    marginRight: 15
  },
  deleteImageButton: {
    display: 'flex',
    justifyContent: 'center'
  }
};
