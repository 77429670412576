import React from 'react';
import { Icon } from 'antd';
import { PaginationWrapper } from './Paginations.style';

const PaginationWlb = props => {
  const {
    offset,
    limit,
    total,
    prevClick,
    nextClick,
    // isBeginning,
    onChangeLimit
  } = props;
  const firstNum = offset + 1;
  const secondNum = offset + limit > total ? total : offset + limit;

  return (
    <PaginationWrapper>
      <div className="select">
        <span>Rows per page: </span>
        <select onChange={onChangeLimit} value={limit}>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
        </select>
      </div>
      {total && (
        <span className="show-off">
          Show {firstNum}-{secondNum} of {total}
        </span>
      )}
      <div className="cursor-group">
        <Icon
          className={offset === 0 ? 'paginate disabled' : 'paginate'}
          type="left"
          onClick={offset !== 0 ? prevClick : undefined}
        />
        <Icon
          className={offset + limit >= total ? 'paginate disabled' : 'paginate'}
          type="right"
          onClick={offset + limit >= total ? undefined : nextClick}
        />
      </div>
    </PaginationWrapper>
  );
};

export default PaginationWlb;
