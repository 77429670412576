import baseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';
import { isObjectEmpty } from '../../Utils/CommonUtils';

const userId = AuthenticationService.getUserId();

const getListKudos = () => {
  const url = Constants.KUDOS(userId);
  return baseService.get(url);
};

const updateKudosCompanyId = (companyId, postId, kudosId) => {
  const url = Constants.KUDOS_COMPANY_ID(userId, companyId, postId);
  const body = {
    kudos_id: kudosId
  };
  return baseService.post(url, body);
};

const cancelKudosCompanyId = (companyId, postId) => {
  const url = Constants.UNKUDOS_COMPANY_ID(userId, companyId, postId);
  return baseService.post(url);
};

const getListKudosPostByCompanyId = (companyId, postId, kudosId = '') => {
  const url = Constants.KUDOS_COMPANY_ID(userId, companyId, postId);
  const body = {
    params: {
      kudos_id: kudosId
    }
  };
  if (isObjectEmpty(kudosId)) {
    delete body.params;
  }
  return baseService.get(url, body);
};

export default {
  getListKudos,
  updateKudosCompanyId,
  getListKudosPostByCompanyId,
  cancelKudosCompanyId
};
