const moment = require('moment');

export const generateDateFormat = date => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  } else {
    return null;
  }
};
export const formatDate = (date, format = 'll') => {
  if (date) {
    return moment(date).format(format);
  } else {
    return null;
  }
};

export const removeDuplicate = arr =>
  arr.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

export const changeToNumber = (number, spacer = null) => {
  if (number && !Number.isInteger(number)) {
    if (spacer) {
      const dataNumber = number.split(spacer)[1];
      return parseInt(dataNumber.split('.').join(''));
    } else {
      return parseInt(number.split('.').join(''));
    }
  } else {
    return number;
  }
};

export const convertToCurrency = number => {
  if (number !== '' && number !== null) {
    return number
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
};

export const generateDateFormat2 = date => {
  if (date) {
    return moment(date).format('YYYY-MM-DD hh:mm:00');
  } else {
    return null;
  }
};

export const isAfter = (leftDate, rightDate) =>
  moment(leftDate).isAfter(rightDate);

export const validateNumber = ({
  number,
  min,
  max,
  isAllowNegative = true
}) => {
  const _number = parseInt(number);

  if (number !== undefined || number !== null) {
    if (!isAllowNegative && _number < 0) {
      return {
        validateStatus: 'error',
        errorMsg: 'Can not be negative'
      };
    }

    if (_number < min) {
      return {
        validateStatus: 'error',
        errorMsg: `Can not be less than ${min}`
      };
    }

    if (_number > max) {
      return {
        validateStatus: 'error',
        errorMsg: `Can not be greater than ${max}`
      };
    }
  }

  return {
    validateStatus: 'success',
    errorMsg: null
  };
};

export const getErrorMessage = (message = '') => {
  if (message.includes('too long')) {
    return 'Kesalahan input: Batas karakter terlampaui.';
  }
};
