import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import { Form, Select, Input, message, Switch } from 'antd';
import { TitleField, SelectStyled } from '../DetailScreen.style';
import { useQuery, useMutation } from 'react-apollo';
import {
  GET_CATEGORY_AND_SUB,
  GET_TYPE,
  ADD_HOBBIES,
  EDIT_HOBBIES,
  DELETE_HOBBIES
} from './Hobbies.graphql';
import { ButtonFrom } from '../../../../SharedComponents';
import { getErrorMessage } from '../HelperDetailScreen';

const AddHobbiesComponent = props => {
  const {
    open,
    onClose,
    data,
    actionType,
    refetch,
    setOpen,
    openState
  } = props;
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields
  } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [share, setShare] = useState(false);
  const [error, setError] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const [category, setCategory] = useState({
    category: null,
    sub_category: null
  });
  const [type, setType] = useState({
    value: null,
    children: null
  });

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data: dataCategory } = useQuery(GET_CATEGORY_AND_SUB, {
    context: CONTEXT_PUBLIC
  });
  const { data: dataType } = useQuery(GET_TYPE, {
    variables: {
      category: category.category,
      sub_category: category.sub_category
    },
    context: CONTEXT_PUBLIC
  });

  const [insertHobbies] = useMutation(ADD_HOBBIES, {
    context: CONTEXT_USER
  });
  const [updateHobbies] = useMutation(EDIT_HOBBIES, {
    context: CONTEXT_USER
  });
  const [deleteHobbies] = useMutation(DELETE_HOBBIES, {
    context: CONTEXT_USER
  });

  useEffect(() => {
    if (actionType === 'edit') {
      setCategory({
        category: data.category,
        sub_category: data.sub_category
      });
      setType({
        value: data.type,
        children: data.nameIndicator
      });
      setFieldsValue({
        type: data.nameIndicator,
        category: data.category,
        sub_category: data.sub_category,
        detail: data.detail,
        deskripsi: data.description
      });
      setShare(data?.is_share || false);
    } else {
      setShare(false);
    }
  }, [data, actionType, setFieldsValue]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        setError(false);
        if (actionType === 'add') {
          insertHobbies({
            variables: {
              isShare: share,
              hobby: type.value,
              hobbyFields: {
                type: type.children,
                details: values.detail
              },
              description: values.deskripsi
            }
          })
            .then(() => {
              refetch();
              message.success(`Berhasil menambahkan hobi`);
              onClose();
              resetFields();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan hobi, ${err}`);
            });
        } else if (actionType === 'edit') {
          updateHobbies({
            variables: {
              id: data.id,
              isShare: share,
              hobby: type.value,
              hobby_fields: {
                type: type.children,
                details: values.detail
              },
              description: values.deskripsi
            }
          })
            .then(() => {
              refetch();
              message.success(`Berhasil mengubah hobi`);
              onClose();
              resetFields();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || 'Gagal mengubah hobi');
            });
        }
      } else {
        setError(true);
      }
    });
  };

  const handleDelete = () => {
    deleteHobbies({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        refetch();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus hobi`);
        onClose();
        resetFields();
      })
      .catch(() => {
        message.error('Gagal menghapus hobi');
      });
  };

  const handleChange = (formValue, target, e) => {
    if (target === 'sub_category' || target === 'category') {
      setType({
        value: null,
        children: null
      });
      setFieldsValue({
        type: null
      });
      setCategory({
        ...category,
        [target]: formValue
      });
    } else if (target === 'type') {
      setType({
        value: e.props.value,
        children: e.props.children
      });
    } else if (target === 'tags') {
      setError(false);
    }
  };

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder } = res;

    if (type === 'select') {
      return (
        <Select
          showSearch={fieldName === 'type'}
          optionFilterProp="children"
          size="large"
          onChange={(value, e) => handleChange(value, fieldName, e)}
        >
          <Option value={null} disabled>
            {placeholder}
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text') {
      return (
        <SelectStyled
          mode="tags"
          onChange={value => handleChange(value, 'tags')}
          style={{ width: '100%' }}
          placeholder={`-Tambahkan rincian hobi`}
        ></SelectStyled>
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          placeholder="Tambahkan keterangan"
          rows={4}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const listFields = [
    {
      labelName: 'Kategori',
      fieldName: 'category',
      type: 'select',
      required: true,
      fieldValue: actionType === 'edit' ? data?.category : null,
      option: dataCategory?.hobby_categories,
      placeholder: 'Pilih kategori'
    },
    {
      labelName: 'Subkategori',
      fieldName: 'sub_category',
      type: 'select',
      required: true,
      fieldValue: actionType === 'edit' ? data?.sub_category : null,
      option: dataCategory?.hobby_sub_categories,
      placeholder: 'Pilih subkategori'
    },
    {
      labelName: 'Tipe',
      fieldName: 'type',
      type: 'select',
      required: true,
      fieldValue: null,
      option: dataType?.people_hobbies,
      placeholder: 'Pilih tipe hobi'
    },
    {
      labelName: 'Rincian',
      fieldName: 'detail',
      type: 'text',
      required: true,
      fieldValue: actionType === 'edit' ? data?.detail : [],
      extra: (
        <>
          <TitleField>contoh: Pop, Rock, Jazz</TitleField>
          {error ? (
            <span style={{ fontSize: 14, marginLeft: 8 }}>
              Bagian ini diperlukan
            </span>
          ) : null}
        </>
      )
    },
    {
      labelName: 'Keterangan',
      fieldName: 'deskripsi',
      type: 'textArea',
      required: false,
      fieldValue: actionType === 'edit' ? data?.description : ''
    }
  ];

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Hobi`}
        open={open}
        onClose={handleClose}
      >
        <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
          <TitleField> Bagikan dengan jaringan:</TitleField>
          <Switch
            style={{ marginLeft: '8px' }}
            checked={share}
            onClick={() => setShare(!share)}
          />
        </Form.Item>
        <hr />
        <Form onSubmit={handleSubmit}>
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Hobi"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditHobbies = Form.create({ name: 'Add_Identity_Card' })(
  AddHobbiesComponent
);

export default AddEditHobbies;
