import Constants from '../../Config/Constants';

export default {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%',
    overflowWrap: 'break-word'
  },
  select: {
    minWidth: 86
  },
  dropdownStyle: {
    minWidth: 220
  },
  text: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 12,
    paddingRight: 40,
    color: Constants.COLORS.GREY,
    marginTop: -2
  },
  image: {
    marginBottom: 5
  },
  description: {
    fontSize: 10,
    marginTop: -13,
    marginBottom: -20,
    color: Constants.COLORS.GREY,
    paddingRight: 20
    // wordBreak: 'break-all',
    // wordWrap: 'break-word',
    // hyphens: 'auto',
    // overflowWrap: 'break-word'
  },
  menuStyle: {
    // background: 'blue'
    // color: 'blue'
  }
};
