import BankAccountFormComponent from '../../Components/BankAccountForm/BankAccountForm.component';
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ADD_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT,
  GET_GLOBAL_BANK_LIST
} from './BankAccountFormGraphQl';
import Constants from '../../Config/Constants';
import { cloneDeep } from 'lodash';
import { message } from 'antd';

const { GRAPHQL_HEADER_OPTIONS, USER_ROLE } = Constants;

const BankAccountFormContainer = props => {
  const {
    addModalVisibility,
    editModalVisibility,
    handleHideModal,
    refetchBankAccountList,
    selectedBankAccount
  } = props;

  const newInitialBankAccount = {
    bank: null,
    bank_name: null,
    account_name: null,
    account_number: null
  };

  const modalVisibility = editModalVisibility || addModalVisibility;
  const [newBankAccount, setNewBankAccount] = useState(newInitialBankAccount);
  const { data } = useQuery(GET_GLOBAL_BANK_LIST);
  const [addBankAccount] = useMutation(
    ADD_BANK_ACCOUNT,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  const [editBankAccount] = useMutation(
    EDIT_BANK_ACCOUNT,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  let globalBankList = [];

  if (data) {
    globalBankList = data.global_banks;
  }

  const _handleBankAccountChange = (attribute, value) => {
    newBankAccount[attribute] = value;
    let newBankAccountReference = cloneDeep(newBankAccount);
    setNewBankAccount(newBankAccountReference);
  };

  const _handleSubmitNewBankAccount = async () => {
    const successMessage = `Successfully add new bank account item`;
    const errorMessage = `Failed add new bank account`;

    const { error: errorResponse, data: dataResponse } = await addBankAccount({
      variables: { data: newBankAccount }
    });

    if (errorResponse) {
      message.error(errorMessage);
      return;
    }

    const {
      affected_rows: affectedRows
    } = dataResponse.insert_company_profile_banks;
    if (affectedRows >= 1) {
      message.success(successMessage);
    }
  };

  const _editBankAccountMutation = async () => {
    const successMessage = `Successfully edit bank account item`;
    const errorMessage = `Failed edit bank account`;

    const { id } = selectedBankAccount;
    const { bank, bank_name, account_name, account_number } = newBankAccount;

    const { error: errorResponse, data: dataResponse } = await editBankAccount({
      variables: {
        id: id,
        bank: bank,
        bank_name: bank_name,
        account_name: account_name,
        account_number: account_number
      }
    });

    if (errorResponse) {
      message.error(errorMessage);
      return;
    }

    const {
      affected_rows: affectedRows
    } = dataResponse.update_company_profile_banks;
    if (affectedRows >= 1) {
      message.success(successMessage);
    }
  };

  const _handlePositiveActionForm = async () => {
    if (editModalVisibility) {
      await _editBankAccountMutation();
    } else {
      await _handleSubmitNewBankAccount();
    }

    setNewBankAccount(newInitialBankAccount);
    await refetchBankAccountList();
    handleHideModal();
  };

  const _handleOnChangeSelection = selectedBank => {
    const { key, label } = selectedBank;
    _handleBankAccountChange('bank', key);
    _handleBankAccountChange('bank_name', label);
  };

  const _handleModalTitle = () => {
    if (editModalVisibility) {
      return 'Edit bank account';
    }

    return 'Add bank account';
  };

  const _handleFormPlaceHolder = () => {
    if (editModalVisibility) {
      return {
        selectBankPlaceholder: selectedBankAccount?.bank_name ?? '',
        accountBankNumberPlaceholder: 'account bank number',
        accountHolderNamePlaceholder: 'user holder name',
        initialSelectedBank: selectedBankAccount?.global_bank?.id ?? '',
        initialAccountBankNumber: selectedBankAccount?.account_number ?? '',
        initialAccountHolderName: selectedBankAccount?.account_name ?? ''
      };
    }

    return {
      selectBankPlaceholder: 'select bank',
      accountBankNumberPlaceholder: 'account bank number',
      accountHolderNamePlaceholder: 'user holder name'
    };
  };

  useEffect(() => {
    if (editModalVisibility) {
      const {
        global_bank,
        bank_name,
        account_number,
        account_name
      } = selectedBankAccount;
      const editBank = {
        bank: global_bank.id,
        bank_name: bank_name,
        account_name: account_name,
        account_number: account_number
      };
      //   console.log('edit bank', editBank);
      setNewBankAccount(editBank);
    } else {
      setNewBankAccount(newInitialBankAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalVisibility]);

  return (
    <BankAccountFormComponent
      globalBankList={globalBankList}
      handleHideModal={handleHideModal}
      modalVisibility={modalVisibility}
      handleOnSelectionChange={_handleOnChangeSelection}
      handleBankAccountChange={_handleBankAccountChange}
      handlePositiveActionForm={_handlePositiveActionForm}
      handleModalTitle={_handleModalTitle}
      handleFormPlaceHolder={_handleFormPlaceHolder}
    />
  );
};

export default BankAccountFormContainer;
