import gql from "graphql-tag";

const ADD_LOCATION = gql`
  mutation addOriginShipping(
    $data: [company_origin_shipping_addresses_insert_input!]!
  ) {
    insert_company_origin_shipping_addresses(objects: $data) {
      affected_rows
    }
  }
`;

const EDIT_LOCATION = gql`
  mutation updateLocationVendor(
    $label_name: String!
    $address: String!
    $province_code: Int!
    $province_name: String!
    $city_code: Int!
    $city_name: String!
    $district_code: Int!
    $district_name: String!
    $post_code: Int!
    $status: enum_global_activation_status!
    $id: Int!
  ) {
    update_company_origin_shipping_addresses(
      _set: {
        label_name: $label_name
        address: $address
        province_code: $province_code
        province_name: $province_name
        city_code: $city_code
        city_name: $city_name
        district_code: $district_code
        district_name: $district_name
        post_code: $post_code
        status: $status
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const GET_LIST_LOCATION = gql`
query getOriginShipping($company:uuid!){
  company_origin_shipping_addresses(
    where:{company:{_eq:$company}}
  ){
    id
    label_name
    address
    province_code
    province_name
    city_code
    city_name
    district_code
    district_name
    post_code
    status
  }
}
`;

export {
  ADD_LOCATION,
  EDIT_LOCATION,
  GET_LIST_LOCATION
}