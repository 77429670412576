import axios from "axios";

import {JwService, UploadService} from "../BaseService";

export const getVideo = (mediaId) => JwService.get(`/${mediaId}`);

const getVideoCredential = async (file) => {
  const body = {
    title: file.name,
    description: file.name,
    format: file.type
  };

  return await UploadService.post('/video', body);
};

const uploadVideo = async (AWSUploadInformation, file) => {
  const {
    protocol,
    address,
    path,
    query: {
      AWSAccessKeyId,
      Expires,
      Signature
    }
  } = AWSUploadInformation;

  const url = `${protocol}://${address}${path}?AWSAccessKeyId=
  ${AWSAccessKeyId}&Expires=${Expires}&Signature=${Signature}`;
  const config = {
    headers: {
      'Accept': 'video/mp4',
      'Content-Type': 'video/mp4'
    }
  };

  return await axios.put(url, file, config);
};

const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  return await UploadService.post('', formData);
};

export default {
  getVideo,
  uploadVideo,
  uploadFile,
  getVideoCredential
};
