import React from "react";
import { Modal, Icon } from "antd";
import {
  ModalContent,
  ModalTitle,
  ModalWrapper,
  ModalHeader,
} from "./ModalEdit.style";
import "./ModalEdit.style.css";

export default function ModalEdit({
  open,
  onClose,
  onSubmit,
  title,
  sizeSmall = false,
  sizeVerySmall = false,
  onClickDelete = null,
  padding = "22px 0px",
  centered = false,
  ...child
}) {

  const modalClass = `${centered && "spacer-modal"} class-modal${
    sizeSmall ? "-small" : sizeVerySmall ? "-very-small" : ""
  }`;

  return (
    <Modal
      className={modalClass}
      centered={centered}
      visible={open}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalWrapper>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <div>
            {onClickDelete && (
              <Icon
                onClick={onClickDelete}
                type="delete"
                style={{ color: "#a9a8a8", cursor: "pointer", fontSize: 20 }}
              />
            )}
            <Icon
              type="close"
              style={{
                color: "#a9a8a8",
                cursor: "pointer",
                marginLeft: 26,
                fontSize: 20,
              }}
              onClick={onClose}
            />
          </div>
        </ModalHeader>
        <ModalContent padding={padding} {...child} />
      </ModalWrapper>
    </Modal>
  );
}
