export const generateValue = dataPosition => {
  let position_list = [];
  if (dataPosition) {
    if (dataPosition.education.length > 0) {
      dataPosition.education.forEach(edu => {
        position_list.push({
          id: `Study at ${edu?.company_profile?.brand_name ?? '-'}`,
          name: `Study at ${edu?.company_profile?.brand_name ?? '-'}`
        });
      });
    }
    if (dataPosition.experience.length > 0) {
      dataPosition.experience.forEach(exp => {
        position_list.push({
          id: `Work at ${exp.company_name ?? '-'}`,
          name: `Work at ${exp.company_name ?? '-'}`
        });
      });
    }
    if (dataPosition.working && dataPosition.working.length > 0) {
      dataPosition.working.forEach(work => {
        position_list.push({
          id: `Work at ${work.company_job_profile.company_profile.brand_name ??
            '-'}`,
          name: `Work at ${work.company_job_profile.company_profile
            .brand_name ?? '-'}`
        });
      });
    }
  }
  return position_list;
};

export const mappingRelationship = (dataRelationship, subject) => {
  return dataRelationship?.people_profile_recommendation_relationships?.map(
    res => ({ ...res, name: res.name.replace("'subject'", subject) })
  );
};

export const getRelationship = (dataRelationship, subject) => {
  return subject
    ? mappingRelationship(dataRelationship, subject)
    : dataRelationship?.people_profile_recommendation_relationships;
};
