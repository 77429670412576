export const listMaritalStatus = [
  {
    id: 'single',
    name: 'Lajang'
  },
  {
    id: 'married',
    name: 'Menikah'
  },
  {
    id: 'divorced',
    name: 'Cerai'
  },
  {
    id: 'separated',
    name: 'Berpisah'
  },
  {
    id: 'widowed',
    name: 'Janda'
  }
];

export const listBlood = [
  {
    id: 'A',
    name: 'A'
  },
  {
    id: 'B',
    name: 'B'
  },
  {
    id: 'AB',
    name: 'AB'
  },
  {
    id: 'O',
    name: 'O'
  }
];

export const listGender = [
  {
    id: 'M',
    name: 'Laki - Laki'
  },
  {
    id: 'F',
    name: 'Perempuan'
  }
];

export const month = [
  {
    value: '01',
    text: 'Januari',
    valueMini: '1',
    short: 'Jan'
  },
  {
    value: '02',
    text: 'Februari',
    valueMini: '2',
    short: 'Feb'
  },
  {
    value: '03',
    text: 'Maret',
    valueMini: '3',
    short: 'Mar'
  },
  {
    value: '04',
    text: 'April',
    valueMini: '4',
    short: 'Apr'
  },
  {
    value: '05',
    text: 'Mei',
    valueMini: '5',
    short: 'Mei'
  },
  {
    value: '06',
    text: 'Juni',
    valueMini: '6',
    short: 'Jun'
  },
  {
    value: '07',
    text: 'Juli',
    valueMini: '7',
    short: 'Jul'
  },
  {
    value: '08',
    text: 'Agustus',
    valueMini: '8',
    short: 'Agu'
  },
  {
    value: '09',
    text: 'September',
    valueMini: '9',
    short: 'Sep'
  },
  {
    value: '10',
    text: 'Oktober',
    valueMini: '10',
    short: 'Okt'
  },
  {
    value: '11',
    text: 'November',
    valueMini: '11',
    short: 'Nov'
  },
  {
    value: '12',
    text: 'Desember',
    valueMini: '12',
    short: 'Des'
  }
];
