import styled from 'styled-components';
import Assets from '../../Assets';

export const StepShippingWrap = styled.div`
  background-color: ${Assets.COLOR_PALLETE.white};
`;
export const StepShipping = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  display: flex;
  padding: 28px 0;
`;

export const Status = styled.div`
  position: relative;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  padding: 8px;
  background-color: ${props => (props.active ? '#039be5' : '#e5e5e5')};
  margin-right: 55px;
  position: relative;

  &::before {
    position: absolute;
    content: ' ';
    width: 85px;
    height: 2px;
    background-color: ${props => (props.active ? '#039be5' : '#e5e5e5')};
    top: 18px;
  }

  &:last-child {
    margin-right: 0;

    &::before {
      display: none;
    }
  }

  .tooltip {
    color: #039be5;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    font-family: ${Assets.FONT.MULI};
    font-size: 14px;
    pointer-events: none;
    white-space: nowrap;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    z-index: 2;
  }

  &:hover .tooltip {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .icon {
    position: relative;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 44px;
    min-height: 16px;

    &.payment {
      background-image: url(${Assets.ICON.MONEY_DOLLAR});
      min-height: 24px;
      top: -2px;
      left: -2px;
    }
    &.review {
      background-image: url(${Assets.ICON.EYE});
      top: 2px;
      left: -2px;
    }
    &.packing {
      background-image: url(${Assets.ICON.GIFT_CARD});
      top: 2px;
    }
    &.shipping {
      background-image: url(${Assets.ICON.SHIPPING});
      top: 2px;
    }
    &.completed {
      background-image: url(${Assets.ICON.COMPLETED});
      top: 2px;
    }
  }
`;

export const RespondWrap = styled(StepShippingWrap)`
  border-top: 1px solid ${Assets.COLOR_PALLETE.grey};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding-bottom: 24px;
`;

export const ResponseDetails = styled.div`
  font-family: ${Assets.FONT.MULI};
  background-color: #f7f8f9;
  border-radius: 4px;
  padding: 16px 24px;
  margin: 0 16px 16px;

  .title {
    color: ${Assets.COLOR_PALLETE.dark_blue};

    &.danger {
      color: ${Assets.COLOR_PALLETE.red};
    }
  }

  .time {
    color: ${Assets.COLOR_PALLETE.grey};
    margin-left: 16px;
  }

  .body {
    margin: 0;
  }

  .attachments {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;

    &:empty {
      display: none;
    }
  }
`;

export const ButtonGroupRespond = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 80%;
`;

export const PaymentGuideWrapper = styled.div`
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eff2f4;
    padding: 20px;
    border-radius: 4px;

    .title {
      color: ${Assets.COLOR_PALLETE.grey};
      font-size: 16px;
      margin-bottom: 8px;
    }

    .bank {
      display: flex;
    }

    .bank-logo {
      height: 54px;
      margin-right: 16px;
    }

    .account-name,
    .account-number {
      color: ${Assets.COLOR_PALLETE.dark_blue};
      font-size: 18px;
      margin: 0;
    }
  }

  .body {
    margin-top: 20px;
  }

  .section {
    margin-bottom: 16px;

    & > p:last-child {
      margin-bottom: 0;
    }
  }

  .section-header {
    color: ${Assets.COLOR_PALLETE.grey};
    font-size: 14px;
    margin-bottom: 4px;
  }

  .payment-amount {
    color: ${Assets.COLOR_PALLETE.dark_blue};
  }

  .ant-collapse {
    background-color: transparent;
    border: 0;

    & > .ant-collapse-item {
      border-bottom: 0;
    }

    & > .ant-collapse-item + .ant-collapse-item {
      border-top: 1px solid ${Assets.COLOR_PALLETE.grey};
    }

    .ant-collapse-content {
      border-top: 0;

      & > .ant-collapse-content-box {
        padding: 0 0 16px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &.ant-collapse-icon-position-right
      > .ant-collapse-item
      > .ant-collapse-header {
      padding: 12px 0;
      padding-right: 32px;

      .ant-collapse-arrow {
        right: 0;
      }
    }
  }

  .highlight {
    color: ${Assets.COLOR_PALLETE.light_blue};
  }
`;
