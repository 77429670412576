import IMAGES from '../../../Assets/images';
import { hasModule } from '../../../Utils/CommonUtils';

const {
  START_TIME_ATTENDANCE,
  REQUEST_LEAVE,
  REQUEST_OVERTIME,
  ONGOING_FEEDBACK,
  LEARNING_DIARY,
  MENTORING,
  WALLET,
  SQUAD,
  DIGIDOC
} = IMAGES.QUICK_ACCESS;

const listMenu = data => [
  {
    image: START_TIME_ATTENDANCE,
    title: 'Kehadiran',
    url: `${process.env.REACT_APP_HC_TIME}/attendance`,
    hidden: !hasModule(data, 'time')
  },
  {
    image: REQUEST_LEAVE,
    title: 'Cuti',
    url: `${process.env.REACT_APP_HC_TIME}/leave/apply`,
    hidden: !hasModule(data, 'time')
  },
  {
    image: REQUEST_OVERTIME,
    title: 'Lembur',
    url: `${process.env.REACT_APP_HC_TIME}/overtime/apply`,
    hidden: !hasModule(data, 'time')
  },
  {
    image: ONGOING_FEEDBACK,
    title: 'Umpan Balik Berkelanjutan',
    url: `${process.env.REACT_APP_HC_PERFORMANCE}/survey`,
    hidden: !hasModule(data, 'performance')
  },
  {
    image: DIGIDOC,
    title: 'Dokumen Digital',
    url: `${process.env.REACT_APP_HC_TIME}/digital-document`
  },
  {
    image: SQUAD,
    title: 'Tim Kerja',
    url: `${process.env.REACT_APP_HC_PERFORMANCE}/squad`,
    hidden: !hasModule(data, 'performance')
  },
  {
    image: WALLET,
    title: 'Dompet',
    url: `${process.env.REACT_APP_HC_VENDOR}/profile/claim`,
    hidden: !hasModule(data, 'vendor')
  },
  {
    image: LEARNING_DIARY,
    title: 'Catatan Pembelajaran',
    url: `${process.env.REACT_APP_NEW_LEARNING}/profile/learning-diary`,
    hidden: !hasModule(data, 'learning')
  },
  {
    image: MENTORING,
    title: 'Bimbingan',
    url: `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning`,
    hidden: !hasModule(data, 'learning')
  }
];

export default {
  listMenu
};
