import { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const LIST_SETTING_QUERY = gql`
  query getDetailSection($id: uuid!) {
    people_profile(where: { id: { _eq: $id } }) {
      id
      settings_section
    }
  }
`;
const useGetSettingSection = profileId => {
  const [settingSection, setSettingSection] = useState([]);
  const { data } = useQuery(LIST_SETTING_QUERY, {
    context: { headers: { 'X-Hasura-Role': 'public' } },
    variables: {
      id: profileId
    }
  });

  useEffect(() => {
    if (data) {
      setSettingSection(
        Object.values(data?.people_profile?.[0]?.settings_section ?? [])
      );
    }
  }, [data]);

  return [settingSection];
};

export default useGetSettingSection;
