import styled from 'styled-components';
import Assets from '../../Assets';

export const ComponentWrapper = styled.div`
  font-family: ${Assets.FONT.MULI};
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey2};
  padding: 8px 15px;
  position: relative;

  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .component_content {
    margin-left: 20px;
  }

  .component_image {
    width: 65px;
    height: 65px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .component_item_title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
  }

  .component_item_description {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
`;
