import React from 'react';
import { Col, Row } from 'antd';

import {
  BankAccountList,
  DummySpace,
  SideBarVendor,
  VendorLocation,
  VendorService,
  VendorShowcase,
  CenterLayout
} from '../../../Components';
import {
  VendorInformation,
  VendorOrderList,
  VendorProductList,
  VendorShippingService,
  VendorBalance,
  VendorBanner
} from '../../../Containers';
import Constants from '../../../Config/Constants';
import { VendorPage } from './DetailScreen.style';

const {
  SIDEBAR_VENDOR: {
    ORDER,
    LOCATION,
    SHOWCASE,
    PRODUCT,
    SERVICE,
    SHIPPING,
    INFORMATION,
    BANK_ACCOUNT,
    BALANCE
  }
} = Constants;

const DetailScreenComponent = props => {
  const { activeMenu, setActiveMenu, dataVendorInformation, companyId } = props;

  const _renderBanner = () => <VendorBanner />;

  const renderContent = () => {
    let content = null;
    if (activeMenu === ORDER) {
      content = <VendorOrderList {...props} />;
    }

    if (activeMenu === PRODUCT) {
      content = (
        <VendorProductList
          companyId={companyId}
          setActiveMenu={setActiveMenu}
        />
      );
    }

    if (activeMenu === LOCATION) {
      return <VendorLocation companyId={companyId} />;
    }

    if (activeMenu === SHOWCASE) {
      return <VendorShowcase companyId={companyId} />;
    }

    if (activeMenu === SERVICE) {
      return <VendorService companyId={companyId} />;
    }

    if (activeMenu === SHIPPING) {
      return <VendorShippingService />;
    }

    if (activeMenu === INFORMATION) {
      return <VendorInformation vendor={dataVendorInformation} {...props} />;
    }

    if (activeMenu === BANK_ACCOUNT) {
      return <BankAccountList />;
    }

    if (activeMenu === BALANCE) {
      return <VendorBalance {...props} />;
    }

    return content;
  };

  const _renderContent = () => {
    return (
      <VendorPage>
        <Row className="rowContainer">
          <Col span={7}>
            <SideBarVendor {...props} />
          </Col>
          <Col span={17} className="colContent">
            {renderContent()}
          </Col>
        </Row>
        <DummySpace />
      </VendorPage>
    );
  };

  return (
    <CenterLayout>
      {_renderBanner()}
      {_renderContent()}
    </CenterLayout>
  );
};

export default DetailScreenComponent;
