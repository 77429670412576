import { AuthenticationService } from '../../Services';
// not used anymore
const login = props => async form => {
  // event.preventDefault();
  await AuthenticationService.login(form).then(res => {
    let cookie = res;
    if (cookie.chat) {
      delete cookie.chat;
    }
    AuthenticationService.setCookie('userData', JSON.stringify(cookie), 1000);
    window.location.replace('/');
  });
};

export default {
  login
};
