import React from 'react';
import { WrapperItemAtch } from './Attachment.style';
import { Icon, Input } from 'antd';
import { getFileIcon, getFileType } from './helperAttachment';

export const AttachmentItem = ({
  fileName,
  size,
  src,
  type,
  onDelete,
  onChange
}) => {
  const icon = getFileIcon(type || getFileType(src), src);

  return (
    <WrapperItemAtch>
      <div className="field">
        <img alt="logo" src={icon} className="img" />
        <Input
          value={fileName}
          onChange={onChange}
          className="text-field"
          size="large"
        />
        <Icon onClick={onDelete} className="icon" type="close" />
      </div>
      <div className="preview-wrapper">
        <div className="size">
          {size ? (size / 1024 / 1024).toFixed(2) : ''} MB
        </div>
        <div className="dot" />
        <a
          href={src}
          rel="noopener noreferrer"
          target="_blank"
          className="preview"
        >
          Preview
        </a>
      </div>
    </WrapperItemAtch>
  );
};
