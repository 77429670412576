import styled from 'styled-components';

const AccountSettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  .ant-collapse.ant-collapse-icon-position-right.login-and-security__settings {
    .ant-collapse-header {
      color: #014a62;
    }
  }

  .manage-password {
    label {
      font-family: 'Muli-SemiBold';
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }

    input {
      font-family: 'Muli-SemiBold';
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  .active-menu-content {
    font-family: 'Muli-SemiBold';
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  .active-menu-header {
    background-color: #ffffff;
    font-family: 'Muli-SemiBold';
    padding: 15px 15px 15px 15px;
    border-bottom: solid 2px #f7f8f9;
  }
`;

export { AccountSettingsWrapper };
