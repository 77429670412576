import React from 'react';

import { Modal, Icon } from 'antd';
import GoogleMapReact from 'google-map-react';

import {
  AddressDetailsBody,
  AddressDetailsHeader
} from './CompanyAddressBox.style';
import Images from '../../Assets/images';
import Assets from '../../Assets';

// - Offices that are shown here will always be active, we don't need a check
const AddressDetailsModal = props => {
  const { open, onClose, dataOffice, dataCompany, activeOfficeId } = props;

  if (!dataOffice) {
    return null;
  }

  const lat = dataOffice.address_field.lat;
  const lng = dataOffice.address_field.lng;

  const prof_field = dataCompany.profile_field || {};

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      footer={null}
      closable={false}
      centered
      width={960}
    >
      <AddressDetailsHeader>
        <span>{dataOffice.office_name}</span>

        <button title="Close" className="close" onClick={onClose}>
          <Icon type="close" />
        </button>
      </AddressDetailsHeader>
      <AddressDetailsBody>
        <div className="maps-container">
          <GoogleMapReact
            center={{ lat, lng }}
            defaultZoom={16}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GMAPS_KEY,
              libraries: 'places'
            }}
            yesIWantToUseGoogleMapApiInternals
          >
            <img
              src={Images.LOCATION_BLUE}
              style={{ width: 18 }}
              lat={lat}
              lng={lng}
            />
          </GoogleMapReact>
        </div>

        <div className="twopane">
          <div>
            <p className="header">{dataOffice.office_name}</p>
            {dataOffice.id === activeOfficeId && (
              <p className="office-main">Main Office</p>
            )}
            <p className="office-address">{dataOffice.address}</p>
            <p className="office-location">
              {dataOffice.global_province.name} - {dataOffice.global_city.name}
            </p>
            <p className="office-postal">{dataOffice.postal_code}</p>

            <p className="header header-mt">Informasi Tambahan</p>
            <p>{dataOffice.address_field.additional_info || '-'}</p>
          </div>
          <div>
            <p className="header">Status Kantor</p>
            <p style={{ color: Assets.COLOR_PALLETE.green }}>Aktif</p>

            <p className="header header-mt">Kontak</p>
            {renderContactInformation(
              'phone',
              `${dataCompany.country_code || ''}${dataCompany.phone || ''}`
            )}
            {renderContactInformation('email', prof_field.email)}
            {renderContactInformation('fax', prof_field.fax)}
            {renderContactInformation('linkedin', prof_field.linkedin)}
            {renderContactInformation('twitter', prof_field.twitter)}
            {renderContactInformation('facebook', prof_field.facebook)}
          </div>
        </div>
      </AddressDetailsBody>
    </Modal>
  );
};

export default AddressDetailsModal;

const ICON_TYPE = {
  phone: Assets.ICON.PHONE_GREY,
  email: Assets.ICON.MAIL_GREY,
  fax: Assets.ICON.FAX,
  linkedin: Assets.ICON.LINKEDIN_GREY,
  twitter: Assets.ICON.TWITTER_GREY,
  facebook: Assets.ICON.FACEBOOK_GREY
};

const renderContactInformation = (type, data) => {
  return (
    <div className="contact">
      <img src={ICON_TYPE[type]} />
      <span>{data || '-'}</span>
    </div>
  );
};
