import styled from 'styled-components';
import Assets from '../../Assets';

export const CreateGroupFormWrap = styled.div`
  padding: 2.8% 3.4%;
  background: ${Assets.COLOR_PALLETE.white};
  display: flex;

  .ant-form label {
    font-size: 12px;
    line-height: 18px;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    color: ${Assets.COLOR_PALLETE.black};
  }

  .ant-input {
    font-size: 12px;
    line-height: 18px;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black};
    padding: 8px 14px;
  }

  .ant-select {
    font-size: 12px;
    height: 12px;
    /* line-height: 12px; */
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black};

    .ant-select-selection {
      padding: 2px 14px;
    }

    .ant-select-selection-selected-value {
      overflow: unset;
    }

    .ant-select-selection__rendered {
      margin-left: 0;
    }

    .ant-select-selection--single {
      height: unset !important;
    }
  }

  .ant-form-item {
    padding-bottom: 0;
    margin-bottom: 16px;
  }
  .ant-form-vertical .ant-form-item {
    padding-bottom: 0;
  }

  .display-avatar {
    width: 100px;
    height: 100px;
    display: inline-block;
    .ant-avatar-lg {
      width: 100%;
      height: 100%;
    }
    .ant-btn {
      margin: 20px 0;
    }
  }

  .privacy-form .ant-form-explain {
    margin-top: 12px;
  }
`;
