import React from 'react';
import PostListComponent from './PostList.component';
import Constant from '../../Config/Constants';
import Global from './global';
import CompanyPost from './companyPost';
import CompanyTimeline from './companyTimeline';
import GroupPost from './team';
import ProfilePost from './userProfile';
import ClassroomPost from './classroom';

function PostList(props) {
  const postType = props.postType;
  switch (postType) {
    case Constant.POST_TYPE.GLOBAL_TIMELINE:
      return <Global {...props} />;
    case Constant.POST_TYPE.COMPANY:
      return <CompanyPost {...props} />;
    case Constant.POST_TYPE.COMPANY_TIMELINE:
      return <CompanyTimeline {...props} />;
    case Constant.POST_TYPE.TEAM:
      return <GroupPost {...props} />;
    case Constant.POST_TYPE.PROFILE:
      return <ProfilePost {...props} />;
    case Constant.POST_TYPE.CLASSROOM:
      return <ClassroomPost {...props} />;
    default:
      return <PostListComponent {...props} />;
  }
}
export default PostList;
