import React from 'react';
import { BannerWrap } from './Banner.style';
import { Row, Col } from 'antd';
import Assets from '../../Assets';
import { AuthenticationService } from '../../Services';
import { useMediaQuery } from '@material-ui/core';

const BannerComponent = ({
  bannerCompany,
  children,
  phoneCompany,
  sosmedCompany
}) => {
  const isMobileScreen = useMediaQuery('(max-width: 1200px)');

  const userData = AuthenticationService.getUserData();
  const _renderBackground = () => (
    <div className="backdropImg">
      <img src={bannerCompany.bannerPhoto} alt={'bannerPhoto'} /> {/* */}
    </div>
  );

  const labelSpan = isMobileScreen ? 12 : 6;

  const _renderInformation = () => (
    <div className="information">
      {_renderLogo()}
      <div className="info-left">
        <h2>{bannerCompany.name}</h2>
        <span>{bannerCompany.address}</span>
        <span>{bannerCompany.tagline}</span>
      </div>
      <div className="info-right">
        <Row>
          <Col className="label" span={labelSpan}>
            Nama Resmi
          </Col>
          <Col span={12}>{bannerCompany?.legalName || '-'}</Col>
        </Row>
        <Row>
          <Col className="label" span={labelSpan}>
            Sebutan
          </Col>
          <Col span={12}>{bannerCompany?.brandName || '-'}</Col>
        </Row>
        <Row>
          <Col className="label" span={labelSpan}>
            Website
          </Col>
          <Col span={12}>{bannerCompany?.website || '-'}</Col>
        </Row>
        <Row>
          <Col className="label" span={labelSpan}>
            Nomor Telepon
          </Col>
          <Col span={12}>
            {phoneCompany?.country_code} {phoneCompany?.phone ?? '-'}
          </Col>
        </Row>
      </div>
    </div>
  );
  const _renderLogo = () => (
    <div className="logo">
      <img
        src={
          bannerCompany.logo ??
          (userData?.thumbnail_url ?? '') + Assets.DEFAULT_PHOTO
        }
        alt={'bannerLogo'}
      />
    </div>
  );

  const _renderFooter = () => (
    <div className="footer">
      <div className="socialMedia">
        <a
          target="_blank"
          href={`https://${sosmedCompany?.linkedin}`}
          rel="noopener noreferrer"
        >
          <img src={Assets.ICON.LINKEDIN_GREY} alt="linkedin" />
        </a>
        <a
          target="_blank"
          href={`https://${sosmedCompany?.twitter}`}
          rel="noopener noreferrer"
        >
          <img src={Assets.ICON.TWITTER_GREY} alt="twitter" />
        </a>
        <a
          target="_blank"
          href={`https://${sosmedCompany?.facebook}`}
          rel="noopener noreferrer"
        >
          <img src={Assets.ICON.FACEBOOK_GREY} alt="facebook" />
        </a>
        <a
          target="_blank"
          href={`mailto:${sosmedCompany?.email}`}
          rel="noopener noreferrer"
        >
          <img src={Assets.ICON.MAIL_GREY} alt="mail" />
        </a>
        <a
          target="_blank"
          href={`tel:${sosmedCompany?.fax}`}
          rel="noopener noreferrer"
        >
          <img src={Assets.ICON.FAX} alt="fax" />
        </a>
      </div>
      <div className="buttonGroup">{children}</div>
    </div>
  );

  return (
    <BannerWrap>
      {_renderBackground()}
      {_renderInformation()}

      {_renderFooter()}
    </BannerWrap>
  );
};

export default BannerComponent;
