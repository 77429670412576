import gql from 'graphql-tag';

const GET_ALL_PRODUCT = gql`
  query(
    $limit: Int
    $offset: Int
    $vendor: uuid!
    $categoryId: Int
    $showcaseId: bigint
    $priceFilter: Int_comparison_exp
    $archivedStatus: Boolean
  ) {
    total: v_marketplace_product_aggregate(
      where: {
        category: { _eq: $categoryId }
        showcase: { _eq: $showcaseId }
        vendor: { _eq: $vendor }
        is_archived: { _eq: $archivedStatus }
        price: $priceFilter
      }
    ) {
      aggregate {
        count
      }
    }
    v_marketplace_product(
      where: {
        category: { _eq: $categoryId }
        showcase: { _eq: $showcaseId }
        vendor: { _eq: $vendor }
        is_archived: { _eq: $archivedStatus }
        price: $priceFilter
      }
      limit: $limit
      offset: $offset
    ) {
      id
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      is_archived
      name
      description
      images
      price
      minimum_order
      available_stock
      weight
      insurance
      is_always_available
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      marketplace_product_reviews_aggregate {
        aggregate {
          avg {
            value
          }
        }
      }
    }
  }
`;

const GET_SHOWCASE_LIST_BY_VENDOR_ID = gql`
  query getShowcaseByVendorId($vendorId: uuid!) {
    marketplace_vendor_showcase(where: { vendor: { _eq: $vendorId } }) {
      id
      name
      description
      marketplace_products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const SHOW_CATEGORY_PRODUCT = gql`
  {
    marketplace_product_categories {
      id
      code
      name
      fa_class
      thumbnail
      parent
      count_course
    }
  }
`;

const ADD_PRODUCT = gql`
  mutation addProduct($data: [marketplace_products_insert_input!]!) {
    insert_marketplace_products(objects: $data) {
      affected_rows
    }
  }
`;

const GET_LIST_SHOWCASE = gql`
  query getListShowcase($vendor: uuid!, $limit: Int!, $offset: Int!) {
    marketplace_vendor_showcase(
      where: { vendor: { _eq: $vendor } }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
      marketplace_products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const GET_ALL_PRODUCT_BY_SHOWCASE = gql`
  query getListProduct(
    $showcase: bigint!
    $vendor: uuid!
    $limit: Int!
    $offset: Int!
  ) {
    marketplace_products(
      where: {
        showcase: { _eq: $showcase }
        vendor: { _eq: $vendor } #ini untuk per vendor
      }
      limit: $limit
      offset: $offset
    ) {
      id
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      name
      description
      images
      price
      minimum_order
      current_stock
      weight
      insurance
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
    }
  }
`;

const CHECK_DELETE_PRODUCT = gql`
  query ($productId: String!) {
  global_invoice_items_aggregate(where: {item_table_id: {_eq: $productId}, item_table: {_eq: "marketplace_products"}, global_invoice: {status: {_neq: "cancelled"}, global_payment: {_or: [{payment_status: {_eq: "paid"}}, {payment_status: {_eq: "pending"}, expires_at: {_is_null: false, _gt: "now()"}}]}}}) {
    aggregate {
      count
    }
  }
}
`
const DELETE_PRODUCT = gql`
  mutation($productId: String!) {
    deleteMarketplaceProduct(productId: $productId) {
      success
    }
  }
`;

const UPDATE_ARCHIVE = gql`
  mutation($productData: marketplace_products_set_input, $productId: uuid!) {
    update_marketplace_products(
      _set: $productData
      where: { id: { _eq: $productId } }
    ) {
      affected_rows
    }
  }
`;
export {
  GET_SHOWCASE_LIST_BY_VENDOR_ID,
  SHOW_CATEGORY_PRODUCT,
  ADD_PRODUCT,
  GET_ALL_PRODUCT,
  GET_LIST_SHOWCASE,
  GET_ALL_PRODUCT_BY_SHOWCASE,
  CHECK_DELETE_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_ARCHIVE
};
