import gql from 'graphql-tag';

const GET_COMPANY_PROFILE = gql`
  query getCompanyProfile($companyId: uuid!) {
    company_profiles(where: { id: { _eq: $companyId } }) {
      id
      brand_name
      legal_name
      logo
      profile_field
      global_company_industry_type {
        id
        name
      }
    }
  }
`;

const GET_PRODUCT_SOLD = gql`
  query($vendor: uuid) {
    global_invoice_items_aggregate(
      where: {
        global_invoice: {
          global_wallet_vendor: { foreign_owner_id: { _eq: $vendor } }
        }
      }
    ) {
      aggregate {
        sum {
          qty
        }
      }
    }
  }
`;

const GET_AVG_RATING = gql`
  query($vendor: uuid, $offset: Int, $limit: Int, $star: Int) {
    total: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: $star } }
    ) {
      aggregate {
        count
        avg {
          value
        }
      }
    }
    star5: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: 5 } }
    ) {
      aggregate {
        count
      }
    }
    star4: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: 4 } }
    ) {
      aggregate {
        count
      }
    }
    star3: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: 3 } }
    ) {
      aggregate {
        count
      }
    }
    star2: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: 2 } }
    ) {
      aggregate {
        count
      }
    }
    star1: marketplace_product_reviews_aggregate(
      where: { vendor_id: { _eq: $vendor }, value: { _eq: 1 } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export { GET_COMPANY_PROFILE, GET_PRODUCT_SOLD, GET_AVG_RATING };
