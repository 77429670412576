import React, { useEffect, useState } from 'react';
import { compose, withHandlers } from 'recompose';
import config from './PostBox.config';
import handler from './PostBox.handler';
import KudosPostBoxComponent from './KudosPostBox.component';
import { isObjectEmpty, isHashtag } from '../../Utils/CommonUtils';
import Constants from '../../Config/Constants';
import { useLocation } from 'react-router-dom';

const PostBoxContainer = props => {
  const {
    sharedPost,
    onSubmit,
    selectedKudos,
    setVisiblePost,
    mainProfile
  } = props;

  const location = useLocation();
  const [isShowSpecificFollower, setIsShowModal] = useState(false);
  const [isFocus, setFocusPost] = useState(false);
  const [isSelectPrivacy, setSelectPrivacy] = useState(false);
  const userId = location?.pathname.split('/')[2];
  let kudos = selectedKudos;
  delete kudos?.date_added;
  delete kudos?.date_deleted;
  delete kudos?.last_modified;
  kudos.to = mainProfile?.name;
  const [post, setPost] = useState({
    ...config.initialData,
    tags: [
      {
        key: userId,
        label: mainProfile?.name
      }
    ],
    kudos: kudos
  });
  const [utility, setUtility] = useState('');
  const [tagsUtility, setTagsUtility] = useState(false);
  const { IMAGE, FILES, TAGS, LOCATION } = Constants.UTILITIES;
  const [locationUtility, setLocationUtility] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (selectedKudos) {
      setPost((e) => ({
        ...e,
        content: mainProfile?.name + " " + selectedKudos.template_text,
      }));
    }
  }, [selectedKudos, mainProfile, setPost]);

  const handlePost = (target, value) => {
    let kudos = selectedKudos;
    delete kudos?.date_added;
    delete kudos?.date_deleted;
    delete kudos?.last_modified;
    kudos.to = mainProfile?.name;
    setPost({
      ...post,
      [target]: value,
      kudos: kudos,
      sharedPost: {}
    });
  };

  const handlePostType = (target, value) => {
    handlePost(target, value);
    setFocusPost(true);
  };

  const handleFocusTextArea = value => setFocusPost(value);

  const handleUtility = target => {
    if (target === TAGS) {
      if (tagsUtility && isObjectEmpty(post.tags)) {
        setTagsUtility(false);
      } else {
        setTagsUtility(true);
      }
    }

    if (target === IMAGE) {
      if (utility === IMAGE && isObjectEmpty(post.images)) {
        setUtility('');
      } else if (isObjectEmpty(post.files)) {
        setUtility(target);
      }
    }
    if (target === FILES) {
      if (utility === FILES && isObjectEmpty(post.files)) {
        setUtility('');
      } else if (isObjectEmpty(post.images)) {
        setUtility(target);
      }
    }
    if (target === LOCATION) {
      if (locationUtility) {
        setLocationUtility(false);
        handlePost('location', {});
      } else {
        setLocationUtility(true);
      }
    }
  };

  const isShowBottomPostBox =
    post.content !== '' ||
    isFocus ||
    utility !== '' ||
    isSelectPrivacy ||
    sharedPost ||
    tagsUtility;

  const formatBody = content => {
    function parseResponse(value) {
      return value.map(val => {
        return {
          name: val.name,
          url: val.response,
          filetype: val.type,
          size: val.size
        };
      });
    }
    function parseCanSee(value) {
      return value.map(val => {
        return val.id;
      });
    }
    function parseTags(value) {
      return value.map(val => {
        return val.key;
      });
    }
    const images = parseResponse(content.images);
    const videos = parseResponse(content.videos);
    const files = parseResponse(content.files);
    const canSee = parseCanSee(content.canSee);
    const tags = parseTags(content.tags);
    const splittedContent = content.content.trim().split(/\s+/);
    const hashtag = splittedContent.filter(word => isHashtag(word));

    return {
      ...content,
      images: images,
      videos: videos,
      tags: tags,
      files: files,
      sharedPost: content.sharedPost,
      canSee: canSee,
      hashtag: hashtag
    };
  };

  const handleSubmit = content => {
    onSubmit(content, setPost, formatBody, sharedPost);
    setUtility('');
    setTagsUtility(false);
    setFocusPost(false);
    setVisiblePost(false);
  };

  return (
    <KudosPostBoxComponent
      post={post}
      handlePost={handlePost}
      handleSubmit={handleSubmit}
      handlePostType={handlePostType}
      isShowBottomPostBox={isShowBottomPostBox}
      handleFocusTextArea={handleFocusTextArea}
      handleUtility={handleUtility}
      utility={utility}
      isShowSpecificFollower={isShowSpecificFollower}
      setIsShowModal={setIsShowModal}
      setSelectPrivacy={setSelectPrivacy}
      tagsUtility={tagsUtility}
      userId={userId}
      locationUtility={locationUtility}
      setIsUploading={setIsUploading}
      isUploading={isUploading}
      setFocusPost={setFocusPost}
      {...props}
    />
  );
};

export default compose(withHandlers(handler))(PostBoxContainer);
