import { Form, Input, Modal, Select, Button } from 'antd';
import React from 'react';
import { isEmpty } from 'lodash';
import { Wrapper } from './style';
import { checkInteger } from '../../Utils/CommonUtils';

const { Option } = Select;

const BankAccountFormComponent = props => {
  const {
    globalBankList,
    handleHideModal,
    handleModalTitle,
    modalVisibility,
    handleFormPlaceHolder,
    handleOnSelectionChange,
    handleBankAccountChange,
    handlePositiveActionForm
  } = props;

  const { getFieldDecorator } = props.form;
  const onSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        handlePositiveActionForm();
      }
    });
  };

  const _Modal = () => {
    const modalTitle = handleModalTitle();
    const {
      selectBankPlaceholder,
      accountBankNumberPlaceholder,
      accountHolderNamePlaceholder,
      initialAccountBankNumber,
      initialAccountHolderName,
      initialSelectedBank
    } = handleFormPlaceHolder();

    return (
      <Modal
        visible={modalVisibility}
        onCancel={handleHideModal}
        destroyOnClose={true}
        footer={null}
        // onOk={handlePositiveActionForm}
        // okText={'Save'}
        title={modalTitle}
        style={{ fontFamily: 'Muli-Regular, sans-serif' }}
      >
        <Wrapper>
          <Form layout="vertical" onSubmit={null}>
            <Form.Item label={'Bank Name'}>
              {modalTitle === 'Add bank account'
                ? getFieldDecorator('select-bank', {
                    rules: [
                      { required: true, message: 'Please Choose Bank Name' }
                    ]
                  })(
                    <Select
                      placeholder={selectBankPlaceholder}
                      onChange={handleOnSelectionChange}
                      labelInValue
                    >
                      {_renderGlobalBankList()}
                    </Select>
                  )
                : getFieldDecorator('select-bank', {
                    rules: [
                      { required: true, message: 'Please Choose Bank Name' }
                    ],
                    initialValue: {
                      key: initialSelectedBank
                    }
                  })(
                    <Select
                      placeholder={selectBankPlaceholder}
                      onChange={handleOnSelectionChange}
                      labelInValue
                    >
                      {_renderGlobalBankList()}
                    </Select>
                  )}
            </Form.Item>

            <Form.Item label={'Name of Account Holder'}>
              {getFieldDecorator('account_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please input Name of Account Holder'
                  }
                ],
                initialValue: !isEmpty(initialAccountHolderName)
                  ? initialAccountHolderName
                  : null
              })(
                <Input
                  placeholder={accountHolderNamePlaceholder}
                  onChange={event =>
                    handleBankAccountChange('account_name', event.target.value)
                  }
                />
              )}
            </Form.Item>

            <Form.Item label="Account Number">
              {getFieldDecorator('account_number', {
                rules: [
                  { required: true, message: 'Please input Account Number' }
                ],
                initialValue: !isEmpty(initialAccountBankNumber)
                  ? initialAccountBankNumber
                  : null
              })(
                <Input
                  rows={4}
                  placeholder={accountBankNumberPlaceholder}
                  type={'Number'}
                  onKeyPress={e => {
                    if (
                      (e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={event =>
                    handleBankAccountChange(
                      'account_number',
                      String(checkInteger(event.target.value))
                    )
                  }
                />
              )}
            </Form.Item>
            <div className="component_button_wrapper">
              <Button onClick={handleHideModal}>Cancel</Button>
              <Button type="primary" onClick={onSubmit}>
                Save
              </Button>
            </div>
          </Form>
        </Wrapper>
      </Modal>
    );
  };

  const _renderGlobalBankList = () =>
    globalBankList.map(bank => (
      <Option key={bank.id} value={bank.id}>
        {bank.name}
      </Option>
    ));
  return _Modal();
};

export default Form.create({ name: 'BankAccountFormComponent' })(
  BankAccountFormComponent
);
