import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import { Form, Select, Input, Switch, message, InputNumber } from 'antd';
import { SelectStyled, TitleField } from '../DetailScreen.style';
import { useMutation } from 'react-apollo';
import {
  ADD_SPEAKER,
  DELETE_SPEAKER,
  UPDATE_SPEAKER
} from './Experience.graphql';
import { ButtonFrom } from '../../../../SharedComponents';
import { getErrorMessage, validateNumber } from '../HelperDetailScreen';

const AddEditComponents = ({
  open,
  onClose,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen,
  openState
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;

  const [share, setShare] = useState(data?.is_share ?? false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const [location, setLocation] = useState({
    city: '',
    province: ''
  });
  const [totalParticipantValidation, setTotalParticipantValidation] = useState({
    value: null
  });

  const [addSpeaker] = useMutation(ADD_SPEAKER);
  const [UpdateSpeaker] = useMutation(UPDATE_SPEAKER);
  const [deleteMutation] = useMutation(DELETE_SPEAKER);

  useEffect(() => {
    if (data) {
      setShare(data?.is_share);
    } else {
      setShare(false);
    }
  }, [data, people_profile]);

  const validateTotalParticipant = value => {
    setTotalParticipantValidation({
      ...validateNumber({ number: value, min: 1, isAllowNegative: false }),
      value
    });
    setFieldsValue({ total_participants: value });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error && totalParticipantValidation.validateStatus !== 'error') {
        const object = {
          is_share: share,
          event_name: values.event_name,
          roles: values.roles,
          organizer: values.organizer,
          scale: values.scale,
          location: { event_location: values.location },
          total_participants: values.total_participants,
          description: values.description
        };
        if (actionType === 'add') {
          addSpeaker({
            variables: { object }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(
                `Berhasil menambahkan Pengalaman Pembicara/Narasumber/Juri`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan Pengalaman Pembicara/Narasumber/Juri, ${err}`);
            });
        } else if (actionType === 'edit') {
          UpdateSpeaker({
            variables: {
              id: data.id,
              object: object
            }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(
                `Berhasil mengubah Pengalaman Pembicara/Narasumber/Juri`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah Pengalaman Pembicara/Narasumber/Juri, ${err}`);
            });
        }
      }
    });
  };

  const handleChange = (formValue, target, e) => {
    if (target === 'province') {
      setFieldsValue({
        city: null
      });
      setLocation({
        ...location,
        province: e.props.children
      });
    } else if (target === 'city') {
      setFieldsValue({
        city: null,
        city_name: e.props.children
      });
      setLocation({
        ...location,
        city: e.props.children
      });
    }
  };

  const listFields = [
    {
      labelName: 'Peran',
      fieldName: 'roles',
      type: 'text-multiple',
      fieldValue: data?.roles ?? [],
      required: true
    },
    {
      labelName: 'Penyelenggara',
      fieldName: 'organizer',
      type: 'text',
      fieldValue: data && data?.organizer,
      placeholder: 'Tambahkan penyelenggara',
      required: true
    },
    {
      labelName: 'Skala Acara',
      fieldName: 'scale',
      type: 'select',
      fieldValue: data && data?.scale,
      required: true,
      option: [
        {
          name: 'Perusahaan'
        },
        {
          name: 'Nasional'
        },
        {
          name: 'Internasional'
        },
        {
          name: 'Non-formal'
        }
      ]
    },
    {
      labelName: 'Lokasi',
      fieldName: 'location',
      type: 'text',
      fieldValue: data && data?.location?.event_location,
      placeholder: 'Tambahkan lokasi',
      required: true
    },
    {
      labelName: 'Total Peserta',
      fieldName: 'total_participants',
      type: 'number',
      fieldValue: data && data?.total_participants,
      placeholder: 'Tambahkan total peserta',
      required: true,
      onChange: validateTotalParticipant,
      extra: totalParticipantValidation.errorMsg,
      validateStatus: totalParticipantValidation.validateStatus
    }
  ];

  const _switchComponentToRender = res => {
    const { type, fieldName, option, labelName, placeholder, ...rest } = res;
    if (type === 'select') {
      return (
        <Select
          filterOption={false}
          showSearch
          size="large"
          onChange={(value, e) => handleChange(value, fieldName, e)}
        >
          <Option value={null} disabled>
            Pilih {labelName}
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.name}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text') {
      return (
        <Input
          placeholder={placeholder}
          onChange={value => handleChange(value)}
          style={{ width: '100%' }}
        ></Input>
      );
    } else if (type === 'number') {
      return (
        <InputNumber
          placeholder={placeholder}
          onChange={value => handleChange(value)}
          style={{ width: '100%' }}
          {...rest}
        ></InputNumber>
      );
    } else if (type === 'text-multiple') {
      return (
        <SelectStyled
          mode="tags"
          onChange={value => handleChange(value, 'tags')}
          style={{ width: '100%' }}
          placeholder={`-Tambahkan Peran`}
        ></SelectStyled>
      );
    }
  };

  const handleDelete = () => {
    deleteMutation({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        message.success(`Delete Speaker/Informant/Jury success`);
        onClose();
      })
      .catch(err => {
        message.error(`Delete Speaker/Informant/Jury error, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setShare(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${
          actionType === 'add' ? 'Tambahkan' : 'Ubah'
        } Pengalaman Pembicara/Narasumber/Juri`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Nama Acara</TitleField>}
            // help={res.extra}
          >
            {getFieldDecorator('event_name', {
              initialValue: data && data?.event_name,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                fieldName: 'event_name',
                type: 'text',
                fieldValue: data && data?.event_name,
                placeholder: 'Tambahkan nama acara',
                required: true
              })
            )}
          </Form.Item>
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                  validateStatus={res.validateStatus}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data && data?.description,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              <TextArea
                rows={4}
                placeholder="Tambahkan keterangan"
                onChange={value => handleChange(value, 'description')}
              />
            )}
          </Form.Item>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Pengalaman Pembicara/Narasumber/Juri"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEdit = Form.create({ name: 'Add_Speaker' })(AddEditComponents);

export default AddEdit;
