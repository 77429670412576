import styled from 'styled-components';

const RegulationScreenWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  .regulation-content {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    .ant-tabs {
      width: 100%;
      .ant-tabs-nav-wrap {
        display: none;
      }
    }
  }

  .regulation-catalogue {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e8e8e8;
  }

  .description {
    padding: 16px;
  }

  .ant-menu-item {
    font-size: 14px;

    color: #000000;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }

  .selected-regulation {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: #000000;
    font-family: 'Muli-Bold', sans-serif;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  .regulation-search {
    font-family: 'Muli-Bold', sans-serif;
    display: flex;
    flex-direction: column;
    height: 97px;
    justify-content: space-around;
    width: 100%;
    padding: 12px 16px;
    border-bottom: 1px solid #e8e8e8;
    .ant-input {
      height: 36px;
      border-radius: 5px;
      border: solid 1px #e5e5e5;
      background-color: #ffffff;
      font-size: 12px;
      font-family: 'Muli-Bold', sans-serif;
    }

    .search-input {
      width: 100%;
    }
  }

  .regulation-list {
    display: flex;
    flex-direction: column;
  }

  .regulation-context {
    display: flex;
    flex-direction: column;
    height: 400px;
    width: 100%;

    .button-back {
      display: flex;
      align-items: center;

      .icon {
        cursor: pointer;
        padding-left: 21px;
      }
    }
  }

  .regulation-title {
    width: 347px;
    height: 64px;
    background-color: #f7f8f9;
    display: flex;
    align-items: center;

    span {
      padding-left: 25px;
    }
  }

  .regulation-clause-list {
    width: 100%;
    height: 80%;

    li {
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      font-family: 'Muli-Regular', sans-serif;
      color: #000000;
    }

    li::before {
      color: #014a62 !important;
    }
  }

  .ant-collapse {
    border: none;
    overflow-y: scroll;
  }

  .ant-collapse-header {
    background: white;
  }

  #content-title {
    font-family: 'Muli-Regular', sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #014a62;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: #014a62;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 30px;
  }

  .ant-menu-item ant-menu-item-selected {
    background-color: #f7f8f9;
  }
`;

export { RegulationScreenWrapper };
