import React from 'react';
import { Col, Row, Select } from 'antd';

import style, { OptionItemWrapper } from './TaskPost.style';
import Constants from '../../Config/Constants';
import Images from '../../Assets/images';
import { TaskPostCard } from '../';
import { AuthenticationService } from '../../Services';
import Assets from '../../Assets';

const { Option } = Select;
const { TASK } = Constants.MESSAGES.POST_BOX.TITLE;

const TaskPost = props => {
  const {
    selectedTask,
    handleTask,
    listTask,
    // listCompanies,
    setTaskKeyword,
    handlePost,
    setTask
  } = props;

  const userData = AuthenticationService.getUserData();

  const _renderTitle = title => <div style={style.title}>{title}</div>;

  const _renderSelect = (placeholder, handleSelect, children = []) => {
    const placeholderText = 'Select a ' + placeholder?.toLowerCase();

    return (
      <Select
        style={style.select}
        showSearch
        onSelect={handleSelect}
        optionLabelProp="label"
        placeholder={placeholderText}
        filterOption={(input, option) =>
          option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        value={selectedTask?.id}
        onSearch={keyword => setTaskKeyword(keyword)}
      >
        {children}
      </Select>
    );
  };

  const _renderDetailEvent = (task, taskStyle) => (
    <OptionItemWrapper>
      <Row style={taskStyle.rowTask}>
        <Col span={4}>
          <img
            style={taskStyle.imgTask}
            src={
              userData.company_logo ? userData.company_logo : Assets.LOGO_WLB
            }
            alt="Task"
          />
        </Col>
        <Col span={1} />
        <Col span={19}>
          <div className="component_item_title">{task.name}</div>
          <div className="component_item_description">{task.description}</div>
          <div style={taskStyle.asTask}>{task.as}</div>
        </Col>
      </Row>
    </OptionItemWrapper>
  );

  const _mappingTask = listTask?.map(task => {
    return (
      <Option
        key={task.id}
        style={style.optionTask}
        value={task.id}
        label={task.name}
      >
        {_renderDetailEvent(task, style.optionChildrenStyle)}
      </Option>
    );
  });

  const _renderDetailBox = () => {
    return selectedTask ? (
      <div style={style.selectedBox}>
        <TaskPostCard
          img={userData.company_logo ? userData.company_logo : Assets.LOGO_WLB}
          name={selectedTask.name}
          description={selectedTask.description}
          deleteTask={() => {
            handlePost('task', {});
            setTask(null);
          }}
        />
      </div>
    ) : null;
  };

  return (
    <div>
      {_renderTitle(TASK)}
      {_renderSelect(TASK, handleTask, _mappingTask)}
      {_renderDetailBox()}
    </div>
  );
};

export default TaskPost;
