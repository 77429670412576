import PropTypes from 'prop-types';

const propTypes = {
  handlePost: PropTypes.func.isRequired,
  setIsShowModal: PropTypes.func.isRequired
};

const defaultProps = {

};

const defaultValue = 0;

export default {
  propTypes,
  defaultProps,
  defaultValue
}
