import React from 'react';
import { MemberListWrap } from './MemberList.style';
import { PopOverRoleMemberAction } from '..';
import { AuthenticationService } from '../../Services';
import Assets from '../../Assets';
// import { isObjectEmpty } from '../../Utils/CommonUtils';
import { ButtonWlb } from '..';
import Avatar from '../Avatar';

const MemberListComponent = props => {
  const {
    dataMember,
    type,
    handleSetAsAdmin,
    handleSetAsMember,
    handleLeaveMember,
    handleKickMember,
    isAdmin,
    approveMember,
    rejectMember,
    isLoading
    // isMemberLoading
  } = props;

  const myId = AuthenticationService.getUserId();

  const _renderMember = dataMember.map((item, index) => {
    const avatar = item.avatar !== '' ? item.avatar : Assets.DEFAULT_PHOTO;
    return (
      <div key={index} className="list">
        <div className="users-info">
          <div className="photo">
            {/* <img src={avatar} alt="avatar" /> */}
            <Avatar avatar={avatar} defaultPhoto={Assets.DEFAULT_PHOTO} />
          </div>
          <div className="info">
            <span className="name">{item.name}</span>
            <span className="field">
              {item?.people_work_placements?.[0]?.company_employee_position
                ?.name ?? ''}
            </span>
            <span className="company">
              {item?.people_work_placements?.[0]?.company_profile?.brand_name ??
                '-'}
            </span>
          </div>
        </div>
        {(isAdmin || item.id === myId) && (
          <div className="button-group">
            <PopOverRoleMemberAction
              handleSetAsRole={() => handleSetAsAdmin(item)}
              handleKickMember={handleKickMember}
              // handleKickMember={() => handleKickMember(item)}
              handleLeaveMember={handleLeaveMember}
              roleLabel="Set as Admin"
              isMyCard={item.id === myId}
              item={item}
              {...props}
            />
          </div>
        )}
      </div>
    );
  });

  const _renderAdmin = dataMember.map((item, index) => {
    return (
      <div key={index} className="list">
        <div className="users-info">
          <div className="photo">
            <img
              src={item.avatar ? item.avatar : Assets.DEFAULT_PHOTO}
              alt="avatar"
            />
          </div>
          <div className="info">
            <span className="name">{item.name}</span>
            <span className="field">
              {item?.people_work_placements?.[0]?.company_employee_position
                ?.name ?? ''}
            </span>
            <span className="company">
              {item?.people_work_placements?.[0]?.company_profile?.brand_name ??
                '-'}
            </span>
          </div>
        </div>
        {(isAdmin || item.id === myId) && (
          <div className="button-group">
            <PopOverRoleMemberAction
              handleSetAsRole={() => handleSetAsMember(item)}
              handleLeaveMember={() => handleLeaveMember()}
              roleLabel="Tetapkan sebagai Anggota"
              isShowKickMember={false}
              isMyCard={item.id === myId}
              {...props}
            />
          </div>
        )}
      </div>
    );
  });

  const RequestMember = () => {
    return (
      <div>
        {dataMember.map((item, index) => (
          <div key={index} className="list">
            <div className="users-info">
              <div className="photo">
                <img
                  src={
                    item.user.avatar ? item.user.avatar : Assets.DEFAULT_PHOTO
                  }
                  alt="avatar"
                />
              </div>
              <div className="info">
                <span className="name">{item.user.name}</span>
                <span className="field">
                  {item?.user?.people_work_placements?.[0]
                    ?.company_employee_position?.name ?? ''}
                </span>
                <span className="company">
                  {item?.user?.people_work_placements?.[0]?.company_profile
                    ?.brand_name ?? '-'}
                </span>
              </div>
            </div>

            <div className="button-group">
              <ButtonWlb
                type="danger"
                size="small"
                style={{ width: 80 }}
                loading={false}
                onClick={() => rejectMember(item.user_id)}
                disabled={isLoading}
              >
                Tolak
              </ButtonWlb>
              <ButtonWlb
                type="primary"
                size="small"
                loading={false}
                onClick={() => approveMember(item.user_id)}
                style={{ marginLeft: 18, width: 80 }}
                disabled={isLoading}
              >
                Terima
              </ButtonWlb>
              {/* {(isAdmin || item.id === myId) && (
                <PopOverRoleMemberAction
                  handleSetAsRole={() => handleSetAsMember(item)}
                  handleLeaveMember={() => handleLeaveMember()}
                  roleLabel="Tetapkan sebagai Anggota"
                  isShowKickMember={false}
                  isMyCard={item.id === myId}
                  {...props}
                />
              )} */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <MemberListWrap>
      {type === 'request' && <RequestMember />}
      {type === 'admin' && _renderAdmin}
      {type === 'member' && _renderMember}
    </MemberListWrap>
  );
};

export default MemberListComponent;
