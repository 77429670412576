import React, { useEffect, useState } from 'react';
import { ModalDeleteWLBCheck, ModalEdit } from '../..';
import ListAddress from './ListAddress';
import { ScrollWrapper } from './AddressDomisili.style';
import ButtonAdd from './ButtonAdd';
import AddEditAddressDomisili from './AddEditAddressDomisili';
import { useQuery, useMutation } from 'react-apollo';
import {
  LIST_ADDRESS,
  SET_MAIN_ADDRESS,
  DELETE_ADDRESS,
  SET_VISIBILITY_ADDRESS
} from './AddressDomisili.graphql';
import { Empty, Skeleton, message } from 'antd';

export default function AddressDomisili({
  open,
  handleClose,
  setOpen,
  stateOpen,
  profileId,
  isUser
}) {
  const [openAction, setOpenAction] = useState({
    addEdit: false,
    data: null,
    actionType: null,
    modalDelete: false
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data: dataList, error, refetch: refetchList } = useQuery(
    LIST_ADDRESS,
    {
      context: CONTEXT_USER,
      variables: {
        user: profileId
      }
    }
  );

  if (error) {
    message.error(`Error : ${error}`);
  }

  const [setMainAddress] = useMutation(SET_MAIN_ADDRESS, {
    context: CONTEXT_USER
  });
  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    context: CONTEXT_USER
  });
  const [setVisibility] = useMutation(SET_VISIBILITY_ADDRESS, {
    context: CONTEXT_USER
  });

  const handleAddButtonClick = () => {
    setOpen({ ...stateOpen, address: false });
    setOpenAction({ ...openAction, addEdit: true, actionType: 'add' });
  };
  const handleClickAction = dataEdit => {
    setOpen({ ...stateOpen, address: false });
    setOpenAction({
      ...openAction,
      addEdit: true,
      actionType: 'edit',
      data: dataEdit
    });
  };
  const handleButtonCloseAddEdit = () => {
    setOpen({ ...stateOpen, address: true });
    setOpenAction({
      ...openAction,
      addEdit: false,
      actionType: null,
      data: null
    });
  };

  const handleSetmain = id => {
    setMainAddress({
      variables: {
        user: profileId,
        id
      }
    })
      .then(() => {
        refetchList();
        message.success(`Tetapkan sebagai alamat utama berhasil`);
      })
      .catch(err => {
        message.error(`Tetapkan sebagai alamat utama gagal, ${err}`);
      });
  };

  const handleSetModalDelete = () => {
    setOpenAction({
      ...openAction,
      addEdit: true,
      modalDelete: false
    });
  };

  const handleDelete = () => {
    deleteAddress({
      variables: {
        id: openAction.data.id
      }
    })
      .then(() => {
        refetchList();
        setOpen({ ...stateOpen, address: true });
        setOpenAction({
          ...openAction,
          addEdit: false,
          modalDelete: false,
          actionType: null,
          data: null
        });
        message.success(`Hapus alamat sukses`);
      })
      .catch(err => {
        message.error(`Hapus alamat gagal, ${err}`);
      });
  };

  const handleChangeVisibility = (id, isVisible) => {
    setVisibility({
      variables: {
        id,
        userId: profileId,
        isVisible: !isVisible
      }
    })
      .then(() => {
        refetchList();
        message.success(
          `${isVisible ? 'Tidak' : ''} Perlihatkan alamat sukses`
        );
      })
      .catch(err => {
        message.error(
          `${isVisible ? 'Tidak' : ''} Perlihatkan alamat sukses, ${err}`
        );
      });
  };

  return (
    <>
      <ModalEdit
        title="Alamat"
        open={open}
        onClose={handleClose}
        sizeSmall
        centered
        padding="0px"
      >
        <ScrollWrapper>
          {dataList ? (
            dataList.people_addresses.length > 0 ? (
              dataList.people_addresses.map((res, i) => {
                if (!isUser && !res.is_visible) return '';
                else {
                  return (
                    <ListAddress
                      isUser={isUser}
                      key={i}
                      data={res}
                      isVisible={res.is_visible}
                      onClickAction={() => handleClickAction(res)}
                      onClickSetMain={() => handleSetmain(res.id)}
                      onClickVisibility={() =>
                        handleChangeVisibility(res.id, res.is_visible)
                      }
                    />
                  );
                }
              })
            ) : (
              <Empty style={{ margin: '36px 0px' }} />
            )
          ) : (
            <div style={{ padding: '12px 24px' }}>
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          )}
        </ScrollWrapper>

        {isUser && <ButtonAdd onClick={handleAddButtonClick} />}
      </ModalEdit>

      <AddEditAddressDomisili
        refetchList={refetchList}
        dataEdit={openAction.data}
        openAction={openAction}
        setOpenAction={setOpenAction}
        open={openAction.addEdit}
        handleClose={handleButtonCloseAddEdit}
      />

      <ModalDeleteWLBCheck
        open={openAction.modalDelete}
        centered
        target="Alamat"
        onClose={handleSetModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
}
