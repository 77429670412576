import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Select, message } from 'antd';

import './VendorProductList.css';
import {
  ProductListVendor,
  Pagination,
  TopSettingGroup,
  ButtonWlb,
  DisplayLoading,
  ModalDeleteCustomCheck,
  ModalNotifWlbCheck,
  ModalConfirmation
} from '../../../Components';
import style from './VendorProductList.style';
import { isEmpty } from 'lodash';
import {
  DELETE_PRODUCT,
  GET_LIST_SHOWCASE,
  UPDATE_ARCHIVE
} from './VendorProductGraphQl';
import { useQuery } from '@apollo/react-hooks';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useMutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';

// const {
//   SIDEBAR_VENDOR: { SHOWCASE }
// } = Constants;

const { Option } = Select;

const VendorProductListComponent = props => {
  const vendorId = props.companyId;
  const [isCollapse, setCollapse] = useState({
    onePage: false,
    title: false
  });
  const [open, setOpen] = useState({
    confirmation: false,
    openArchive: false,
    id: null,
    name: '',
    delete: false
  });

  const [deleteProduct] = useMutation(DELETE_PRODUCT);
  const [ArchiveProduct] = useMutation(UPDATE_ARCHIVE);

  const [showCase, setShowCase] = useState([]);
  const {
    showCaseList,
    productList,
    showcaseProductLoading,
    setSelectedShowCase,
    selectedShowCase,
    totalShowcaseProduct,
    limitShowcase,
    offsetShowcase,
    changeLimitShowcaseProduct,
    isLoadingProductList,
    changeLimitProduct,
    // setActiveMenu,
    cursorPaginationShowcase,
    cursorPagination,
    offset,
    limit,
    totalProduct,
    cursorPaginationArchive,
    offsetArchive,
    limitArchive,
    totalArchiveProduct,
    productArchiveList,
    allArchiveLoading,
    changeLimitArchive,
    loadQuery
  } = props;

  const { loading: showCaseLoading, data: listShowCase } = useQuery(
    GET_LIST_SHOWCASE,
    {
      variables: {
        vendor: vendorId,
        limit: 100,
        offset: 0
      }
    }
  );
  let showcaseCategories = [{ id: 0, name: 'All' }];

  useEffect(() => {
    if (listShowCase) {
      setShowCase([
        ...showcaseCategories,
        ...listShowCase.marketplace_vendor_showcase
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listShowCase]);

  const _renderContent = (isLoading, products, title) => {
    if (isLoading) {
      return <DisplayLoading />;
    }
    const isEmptyProduct = isEmpty(products);
    if (isEmptyProduct) {
      return <span>No Product yet</span>;
    }
    return (
      <ProductListVendor
        vendorId={vendorId}
        productList={products}
        title={title}
        setOpen={setOpen}
        open={open}
      />
    );
  };

  const PaginationProduct = ({ title }) => {
    const changeLimit = value => {
      if (title === 'Product') {
        return changeLimitProduct(value);
      } else if (title === 'Archived') {
        return changeLimitArchive(value);
      } else return changeLimitShowcaseProduct(value);
    };

    const prevProduct = () => {
      if (title === 'Product') {
        const prevOffset =
          offsetArchive - limitArchive < 0 ? 0 : offsetArchive - limitArchive;
        return cursorPagination(prevOffset);
      } else if (title === 'Archived') {
        const prevOffset = offset - limit < 0 ? 0 : offset - limit;
        return cursorPaginationArchive(prevOffset);
      } else {
        const prevOffsetShowcase =
          offsetShowcase - limitShowcase < 0
            ? 0
            : offsetShowcase - limitShowcase;
        return cursorPaginationShowcase(prevOffsetShowcase);
      }
    };
    const nextProduct = () => {
      if (title === 'Product') {
        return cursorPagination(offset + limit);
      } else if (title === 'Archived') {
        return cursorPagination(offsetArchive + limitArchive);
      } else return cursorPaginationShowcase(offsetShowcase + limitShowcase);
    };
    return (
      <Pagination
        offset={pageSet().setOffset}
        limit={pageSet().setLimit}
        total={pageSet().total}
        prevClick={() => prevProduct()}
        nextClick={() => nextProduct()}
        onChangeLimit={e => changeLimit(parseInt(e.target.value))}
      />
    );
  };

  const RenderProductList = ({
    title,
    products,
    isLoading,
    rightHeader = null,
    comp = null
  }) => {
    return (
      <div style={style.container}>
        <TopSettingGroup
          title={title}
          buttonTop={rightHeader}
          onBack={
            !isCollapse.onePage
              ? isCollapse.onePage
              : () => {
                  setCollapse({ ...isCollapse, onePage: false });
                }
          }
        />
        <div style={style.contentProduct}>
          {_renderContent(
            isLoading,
            !isCollapse.onePage
              ? title === 'Product' || title === 'Archived'
                ? products.slice(0, 6)
                : products.slice(0, 3)
              : products,
            title
          )}
          {comp}
          {isLoading}
          {isCollapse.onePage && <PaginationProduct title={isCollapse.title} />}
          {/* {title === 'Showcase' && !isCollapse && (
            <PaginationProduct title={title} />
          )}
          {title === 'Products' && <PaginationProduct title={title} />} */}
        </div>
      </div>
    );
  };

  const _buttonAddProduct = () => (
    <Link to={vendorId + '/product'}>
      <ButtonWlb key="1" type="primary" onClick={() => window.scrollTo(0, 0)}>
        Add Product
      </ButtonWlb>
    </Link>
  );

  // const _editHeader = () => (
  //   <span style={style.editText} onClick={() => setActiveMenu(SHOWCASE)}>
  //     Edit
  //   </span>
  // );

  const _collapseText = title => {
    return (
      <>
        {!isCollapse.onePage && (
          <p
            style={style.collapseText}
            onClick={() =>
              setCollapse({
                onePage: true,
                title: title
              })
            }
          >
            Tampilkan Lebih Banyak
          </p>
        )}
      </>
    );
  };

  const _selectBox = () => {
    const isEmptyShowCase = isEmpty(showCase);
    return (
      <Select
        className="vendor-product-list"
        style={style.select}
        dropdownStyle={style.option}
        onSelect={value => setSelectedShowCase(value)}
        placeholder={'Select Showcase'}
        loading={showCaseLoading}
        value={selectedShowCase}
      >
        {isEmptyShowCase
          ? null
          : showCase.map(showCase => (
              <Option key={showCase.id} value={showCase.id}>
                {showCase.name}
              </Option>
            ))}
      </Select>
    );
  };

  const pageSet = () => {
    let setOffset = offsetArchive;
    let setLimit = limitArchive;
    let setTotal = totalArchiveProduct;
    let product = productArchiveList;
    let loading = allArchiveLoading;
    let button = '';
    if (isCollapse.title === 'Product') {
      setOffset = offset;
      setLimit = limit;
      setTotal = totalProduct;
      product = productList;
      loading = isLoadingProductList;
      button = _buttonAddProduct();
    } else if (isCollapse.title === 'Showcase') {
      setOffset = offsetShowcase;
      setLimit = limitShowcase;
      setTotal = totalShowcaseProduct;
      product = showCaseList;
      loading = showcaseProductLoading;
      button = _selectBox();
    }

    return {
      setOffset,
      setLimit,
      setTotal,
      product,
      loading,
      button
    };
  };

  const handleDelete = () => {
    deleteProduct({
      variables: {
        productId: open.id
      },
      context: { headers: { 'X-Hasura-Role': 'organization-hr-admin' } }
    })
      .then(() => {
        message.success('Delete Product success');
        loadQuery();
        setOpen({
          ...open,
          confirmation: false,
          noDelete: false,
          openArchive: false
        });
      })
      .catch(() => {
        message.error('Delete Product Failed, try again later');
      });
  };

  const handleArchive = () => {
    ArchiveProduct({
      variables: {
        productId: open.id,
        productData: {
          is_archived_manual: open.type !== 'Archived'
        }
      },
      context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
    })
      .then(() => {
        message.success('Archive Product success');
        loadQuery();
        setOpen({
          ...open,
          confirmation: false,
          noDelete: false,
          openArchive: false
        });
        if (open.stok === 0) {
          props.history.push(`${vendorId}/product/${open.id}/`);
        }
      })
      .catch(() => {
        message.error('Archive Product Failed, try again later');
      });
  };
  return (
    <>
      {!isCollapse.onePage ? (
        <div>
          <RenderProductList
            title="Showcase"
            products={showCaseList}
            // editHeader={_editHeader()}
            isLoading={showcaseProductLoading}
            comp={_collapseText('Showcase')}
            rightHeader={_selectBox()}
          />
          <RenderProductList
            title="Product"
            products={productList}
            isLoading={isLoadingProductList}
            comp={_collapseText('Product')}
            rightHeader={_buttonAddProduct()}
          />
          <RenderProductList
            title="Archived"
            products={productArchiveList}
            // editHeader={_editHeader()}
            isLoading={allArchiveLoading}
            comp={_collapseText('Archived')}
          />
        </div>
      ) : (
        <RenderProductList
          title={isCollapse.title}
          products={pageSet().product}
          // editHeader={_editHeader()}
          isLoading={pageSet().loading}
          rightHeader={pageSet().button}
        />
      )}
      <ModalDeleteCustomCheck
        open={open.confirmation}
        centered
        target={open.name}
        message={
          'I understand that deleting this product is permanent and cannot be undone.'
        }
        onClose={() => {
          setOpen({
            ...open,
            confirmation: false,
            delete: true
          });
        }}
        onDelete={handleDelete}
      />
      <ModalNotifWlbCheck
        open={open.noDelete}
        centered
        target="Unable to Delete"
        message="unable to delete this product because there is on ongoing sales on this product"
        confirm="confirm"
        onConfirm={() => {
          setOpen({
            ...open,
            noDelete: false
          });
        }}
      />
      <ModalConfirmation
        open={open.openArchive}
        title={
          open.type === 'Archived' ? 'Unarchive Product?' : 'Archive Product?'
        }
        onCancel={() => {
          setOpen({
            ...open,
            openArchive: false
          });
        }}
        content={
          open.type === 'Archived'
            ? 'Are you sue you want to unarchive this product? This product will be go back to showcase and user will be able to purchase it.'
            : 'Are you sure you want to archive this product? This product will not be able to be purchased by any user if you archive it'
        }
        textPrimary="Confirm"
        onSubmit={handleArchive}
      />
    </>
  );
};

export default withRouter(VendorProductListComponent);
