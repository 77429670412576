import React, { useState } from 'react';
import { message } from 'antd';
import 'antd/dist/antd.css';
import PeopleResult from './PeopleResult.component';
import FollowPageServices from '../../Services/FollowPage';

const PeopleResultContainer = props => {
  const { person } = props;
  const [isFollow, setIsFollow] = useState(person.isFollowed);
  const [isLoading, setIsLoading] = useState(false);

  const handleFollow = person => {
    if (!isLoading) {
      setIsLoading(true);
      FollowPageServices.followUser(person.id)
        .then(res => {
          if (res.status === 200) {
            message.success(`Success Follow ${person.name} `);
            setIsFollow(true);
          }
        })
        .catch(err => message.error('Something went wrong'))
        .finally(() => setIsLoading(false));
    }
  };

  const handleUnfollow = person => {
    if (!isLoading) {
      setIsLoading(true);
      FollowPageServices.unFolloweUser(person.id)
        .then(res => {
          if (res.status === 200) {
            message.success(`Unfollow ${person.name} success`);
            setIsFollow(false);
          }
        })
        .catch(err => message.error('Something went wrong'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PeopleResult
      person={person}
      isFollow={isFollow}
      handleUnfollow={handleUnfollow}
      handleFollow={handleFollow}
    />
  );
};

export default PeopleResultContainer;
