import React, { useState } from 'react';
import StoreScreenComponent from './StoreScreen.component';
import WelcomeScreen from '../WelcomeScreen';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useGlobalState } from '../../../globalState';
import { UploadService } from '../../../Services';

const StoreScreenContainer = props => {
  const [location, setLocation] = useState({});
  const [dataReg, setDataReg] = useGlobalState('dataRegistration');
  // const [uploadFile, setUploadFile] = useGlobalState('uploadFile');
  const [uploadFile, setUploadFile] = useState([]);
  const GET_LIST_PROVINCE = gql`
    {
      global_provinces(order_by: { name: asc }) {
        id
        code
        name
      }
    }
  `;
  const GET_LIST_CITY = gql`
  {
    global_cities(where: { province: { _eq: ${
      dataReg.province ? dataReg.province : location.province
    } } }, order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;
  const GET_LIST_CATEGORY = gql`
    {
      global_company_industry_types {
        id
        name
      }
    }
  `;
  const GET_LIST_CLASSIFICATION = gql`
    {
      marketplace_vendor_classifications {
        id
        name
        description
        banner
      }
    }
  `;
  const province = useQuery(GET_LIST_PROVINCE);
  const city = useQuery(GET_LIST_CITY);
  const category = useQuery(GET_LIST_CATEGORY);
  const classification = useQuery(GET_LIST_CLASSIFICATION);

  let dataProvince = [];
  let dataCity = [];
  let dataCategory = [];
  let dataClassification = [];

  if (province.data) {
    dataProvince = province.data.global_provinces;
  }
  if (city.data) {
    dataCity = city.data.global_cities;
  }
  if (category.data) {
    dataCategory = category.data.global_company_industry_types;
  }
  if (classification.data) {
    dataClassification = classification.data.marketplace_vendor_classifications;
  }

  const { currentSteps } = props;
  const selectionData = {
    province: dataProvince,
    city: dataCity,
    vendor_category: dataCategory,
    vendor_classification: dataClassification
  };
  const initialData = {
    files: []
  };
  const [files, setFiles] = useState(initialData);

  const handleUpload = async ({ onSuccess, file }) => {
    const { url } = await UploadService.uploadFile(file);
    onSuccess(url, file);
  };
  const handleChange = (target, value) => {
    setUploadFile([]);
    setFiles({
      ...files,
      [target]: value
    });

    value.forEach(val => {
      if (val.status === 'done') {
        setUploadFile(prevFile => {
          return [...prevFile, { name: val.name, url: val.response }];
        });
      }
    });
  };

  return (
    <div>
      {currentSteps === -1 ? <WelcomeScreen {...props} /> : null}
      {currentSteps === 0 ? (
        <StoreScreenComponent
          selectionData={selectionData}
          setDataReg={setDataReg}
          dataReg={dataReg}
          dataUpload={uploadFile}
          location={location}
          setLocation={setLocation}
          handleUpload={handleUpload}
          handleChange={handleChange}
          files={files}
          {...props}
        />
      ) : null}
    </div>
  );
};

export default StoreScreenContainer;
