import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import PostCardContainer from './PostCard.container';
import { Classroom } from '../../Services';

import { LIKE_POST } from './classroomLikePost.graphql';

function UserPostCard(props) {
  const postId = props.itemPost.id;

  const [likePost] = useMutation(LIKE_POST);

  const handleLike = likeValue => {
    return likePost({ variables: { postId, like: likeValue === 1 } });
  };
  return <PostCardContainer {...props} handleLike={handleLike} />;
}
export default UserPostCard;
