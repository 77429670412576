import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import { Tabs, Skeleton, Empty } from 'antd';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import EditRecommendationTab from './EditRecommendationTab';
import AskForRevision from './AskForRevision';
import EditRecommendation from './EditRecommendation';
import { useQuery } from 'react-apollo';
import { LIST_SENT_RECOMMENDATION } from './Recommendation.graphql';
import ContentRecommendation from './ContentRecommendation';
import MoreRecommendationTab from './MoreRecommendationTab';

export default function Recommendation({
  isUser,
  refetch,
  // people_profile,
  profileId,
  nameUser,
  dataRecommendations
}) {
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState('1');
  const [open, setOpen] = useState({
    open: false,
    data: [],
    openAsk: false,
    askData: {
      request_to: null,
      name: '',
      id: null
    },
    openEdit: false,
    dataEdit: {
      id: null,
      name: '',
      dataEdit: null
    },
    openMore: false
  });

  const { data: dataSent, refetch: refetchSent } = useQuery(
    LIST_SENT_RECOMMENDATION,
    {
      skip: !profileId,
      variables: {
        user: profileId
      }
    }
  );

  const handleClose = () => {
    setOpen({ ...open, open: false });
  };
  return (
    <>
      <DetailUserSection
        title="Rujukan"
        noTitleLine
        onClickAction={() =>
          setOpen({ ...open, open: true, data: dataRecommendations })
        }
        action={isUser ? 'edit' : null}
      >
        <Tabs
          onChange={active => setActiveKey(active)}
          activeKey={activeKey}
          defaultActiveKey="1"
        >
          <TabPane tab={`Diterima (${dataRecommendations.length})`} key="1">
            {dataRecommendations.length > 0 ? (
              dataRecommendations.slice(0, 2).map((recommendation, i) => {
                if (recommendation.status === 'PUBLISH') {
                  return (
                    <ContentRecommendation
                      key={i}
                      lastItem={
                        i + 1 === dataRecommendations.slice(0, 2).length
                      }
                      recommendation={recommendation}
                    />
                  );
                } else {
                  return null;
                }
              })
            ) : (
              <Empty
                description={<span>Tidak ada data</span>}
                style={{ margin: '60px 0px' }}
              />
            )}
          </TabPane>
          <TabPane
            tab={`Dikirimkan (${dataSent?.people_profile_recommendations.length})`}
            key="2"
          >
            {dataSent ? (
              dataSent.people_profile_recommendations.length > 0 ? (
                dataSent.people_profile_recommendations
                  .slice(0, 2)
                  .map((recommendation, i) => {
                    if (recommendation.status === 'PUBLISH') {
                      return (
                        <ContentRecommendation
                          type="sent"
                          key={i}
                          lastItem={
                            i + 1 ===
                            dataSent.people_profile_recommendations.slice(0, 2)
                              .length
                          }
                          recommendation={recommendation}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
              ) : (
                <Empty
                  description={<span>Tidak ada data</span>}
                  style={{ margin: '60px 0px' }}
                />
              )
            ) : (
              <Skeleton active avatar paragraph={{ rows: 4 }} />
            )}
          </TabPane>
        </Tabs>
        {(dataRecommendations.length > 2 ||
          dataSent?.people_profile_recommendations.length > 2) && (
          <ThypoStyled
            margin="0px 0px 23px"
            color="#039be5"
            cursor="pointer"
            fontSize="16px"
            fontWeight="600"
            onClick={() => setOpen({ ...open, openMore: true })}
          >
            Tampilkan Lebih Banyak Rujukan...
          </ThypoStyled>
        )}
      </DetailUserSection>

      <MoreRecommendationTab
        open={open.openMore}
        userName={nameUser.split(' ')[0]}
        onClose={() => setOpen({ ...open, openMore: false })}
        dataRecaived={dataRecommendations}
        dataSent={dataSent}
      />

      <EditRecommendationTab
        refetchSent={refetchSent}
        dataSent={dataSent}
        setOpen={setOpen}
        stateOpen={open}
        refetch={refetch}
        onClose={handleClose}
        open={open.open}
        data={open.data}
      />

      <EditRecommendation
        refetchSent={refetchSent}
        open={open.openEdit}
        onClose={() => setOpen({ ...open, openEdit: false, open: true })}
        name={open.dataEdit.name}
        id={open.dataEdit.id}
        dataEdit={open.dataEdit.dataEdit}
      />

      <AskForRevision
        open={open.openAsk}
        askData={open.askData}
        onClose={() =>
          setOpen({
            ...open,
            openAsk: false,
            open: true,
            askData: {
              request_to: null,
              name: '',
              id: null
            }
          })
        }
      />
    </>
  );
}
