import React, { useState, useRef, useCallback, useEffect } from 'react';
import { ModalEdit } from '..';
import {
  Body,
  Footer,
  SliderStyled,
  Cropper
} from './ChangePhotoProfile.styled.js';
import { Button, message } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import Axios from 'axios';
import { AuthenticationService } from '../../Services';
import { useMutation } from 'react-apollo';
import { UPDATE_PROFILE } from './ChangePhotoProfile.graphql';

const { REACT_APP_UPLOAD_URL } = process.env;
const UPLOAD_URL = REACT_APP_UPLOAD_URL;

const pixelRatio = 4;

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext('2d');
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

export default function ChangePhotoProfile({ open, onClose, refetch }) {
  const [upImg, setUpImg] = useState();
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [zoom, setZoom] = useState(0);
  const [loading, setloading] = useState(false);

  const [updateProfile] = useMutation(UPDATE_PROFILE);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleClose = () => {
    setCompletedCrop(null);
    onClose();
  };

  const handleUpdateImage = link => {
    updateProfile({
      variables: {
        id: AuthenticationService.getUserId(),
        avatar: link
      },
      context: { headers: { 'X-Hasura-Role': 'user' } }
    })
      .then(() => {
        setloading(false);
        handleClose();
        message.success(`Change Photo success`);
        refetch();
      })
      .catch(err => {
        setloading(false);
        message.success(`Change Photo Error, please try again later, ${err}`);
      });
  };

  function generateDownload(previewCanvas, crop, setloading) {
    if (!crop || !previewCanvas) {
      message.info(`Please choose a photo to upload`);
      return;
    }

    const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);
    canvas.toBlob(
      blob => {
        setloading(true);
        const anchor = document.createElement('a');
        anchor.download = 'new-profile.png';
        anchor.href = URL.createObjectURL(blob);
        const formData = new FormData();
        formData.append('file', blob);
        return Axios.post(UPLOAD_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true
        })
          .then(response => {
            handleUpdateImage(response.data.url);
          })
          .catch(err => {
            setloading(false);
          });
      },
      'image/png',
      1
    );
  }
  const onSelectFile = e => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type.split('/')[0];
      if (fileType === 'image') {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener('load', () => setUpImg(reader.result));
          reader.readAsDataURL(e.target.files[0]);
        }
      } else {
        message.warning('The input file must be an image');
      }
    }
  };

  return (
    <ModalEdit
      padding="0px"
      open={open}
      onClose={handleClose}
      sizeSmall
      title="Photo Profile"
    >
      <Body>
        <div style={{ transform: `scale(${1 + zoom / 500})` }}>
          <Cropper
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={c => setCompletedCrop(c)}
          />
        </div>
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            width: completedCrop?.width ?? 0,
            height: completedCrop?.height ?? 0
          }}
        />
      </Body>
      <Footer>
        <div className="wrapper-slider">
          <span className="zoom-title">Zoom</span>
          <SliderStyled
            min={0}
            value={zoom}
            onChange={value => setZoom(value)}
            className="slider-change-photo"
            tipFormatter={null}
          />
        </div>
        <div className="wrapper-action">
          <input
            onChange={onSelectFile}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            id="change-photo-profile"
          />
          <label htmlFor="change-photo-profile">
            <div size="large" className="button-upload">
              Upload Photo
            </div>
          </label>
          <Button
            onClick={() =>
              generateDownload(
                previewCanvasRef.current,
                completedCrop,
                setloading
              )
            }
            size="large"
            className="button-save"
          >
            {loading ? 'Uploading..' : 'Save'}
          </Button>
        </div>
      </Footer>
    </ModalEdit>
  );
}
