import React, { useState } from 'react';
import { Tabs } from 'antd';
import {
  TopSettingGroup,
  ButtonWlb,
  BalanceTrack,
  Pagination,
  DisplayLoading,
  DummySpace
} from '../../../Components';
import { VendorBalanceTabs, VendorBalanceWrap } from './VendorBalance.style';
import { ToPrice } from '../../../Utils/currencyFormat';

const VendorBalanceComponent = props => {
  const {
    balance,
    dataSpend,
    dataSpendLoading,
    dataIncome,
    totalIncome,
    totalSpend,
    dataIncomeLoading,
    limit,
    offset,
    handleLimit,
    prevSpend,
    nextSpend,
    prevIncome,
    nextIncome
  } = props;

  const spend = balance?.spend?.aggregate?.sum?.value ?? 0;
  const income = balance?.income?.aggregate?.sum?.value ?? 0;
  const [tab, setTab] = useState(1);

  const { TabPane } = Tabs;
  const _Balance = () => (
    <div>
      <TopSettingGroup
        title="Your Balance"
        color="black"
        border={false}
        buttonTop={[
          <ButtonWlb key="1" type="primary">
            Withdraw
          </ButtonWlb>
        ]}
      />
      <p className={`balance-total`}>Rp{ToPrice(income - spend)}</p>
    </div>
  );

  const _Tabs = () => (
    <VendorBalanceTabs>
      <Tabs defaultActiveKey="1" onChange={e => setTab(parseInt(e))}>
        <TabPane tab="Spend" key="1">
          {_mappingBalanceSpend()}
        </TabPane>
        <TabPane tab="Income" key="2">
          {_mappingBalanceIncome()}
        </TabPane>
      </Tabs>
    </VendorBalanceTabs>
  );
  const _handleDetail = id => null;
  const _mappingBalanceSpend = () => {
    if (dataSpendLoading || !dataSpend) {
      return <DisplayLoading />;
    } else {
      return (
        <div>
          {dataSpend.map(item => (
            <BalanceTrack
              key={item.transaction_item_id}
              handleDetail={() => _handleDetail(item.id)}
              dataBalance={item}
            />
          ))}
        </div>
      );
    }
  };

  const _mappingBalanceIncome = () => {
    if (dataIncomeLoading || !dataIncome) {
      return <DisplayLoading />;
    } else {
      return (
        <div>
          {dataIncome.map(item => (
            <BalanceTrack
              key={item.transaction_item_id}
              handleDetail={() => _handleDetail(item.id)}
              dataBalance={item}
              type="income"
            />
          ))}
        </div>
      );
    }
  };

  return (
    <VendorBalanceWrap>
      {_Balance()}
      {_Tabs()}
      <DummySpace height={50} />
      <Pagination
        limit={limit}
        offset={offset}
        total={tab === 1 ? totalSpend : totalIncome}
        prevClick={() => {
          tab === 1 ? prevSpend() : prevIncome();
        }}
        nextClick={() => {
          tab === 1 ? nextSpend() : nextIncome();
        }}
        onChangeLimit={e => handleLimit(e.target.value)}
      />
    </VendorBalanceWrap>
  );
};

export default VendorBalanceComponent;
