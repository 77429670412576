import { Icon, Tooltip } from 'antd';
import React from 'react';
import Assets from '../../../Assets';
import { AvatarStyled } from '../../BannerProfile/BannerProfile.style';
import {
  WrapperEmail,
  WrapperContent,
  EmailName,
  TextRed,
  MainEmail
} from './EmailAddress.style';

export default function ListEmail({
  is_main,
  isUser,
  isVisible,
  onClickVisibility,
  email,
  is_verified,
  onClickDelete,
  time_verification
}) {
  const verifiedLogo = () => {
    if (is_verified || is_main) {
      return (
        <AvatarStyled
          style={{ marginLeft: 10 }}
          size={22}
          src={Assets.ICON.VERIFIED}
        />
      );
    }
  };

  const buttonVissible = () => {
    if (isUser) {
      if (is_verified || is_main) {
        return (
          <Icon
            style={{
              marginRight: 21,
              color: isVisible ? '#039be5' : '#a9a8a8'
            }}
            onClick={onClickVisibility}
            type={`eye${isVisible ? '' : '-invisible'}`}
            theme="filled"
          />
        );
      } else {
        return (
          <Tooltip
            placement="leftTop"
            title={'To show this email, you need to verify it first'}
          >
            <Icon
              style={{
                marginRight: 21,
                color: isVisible ? '#039be5' : '#a9a8a8'
              }}
              type={`eye${isVisible ? '' : '-invisible'}`}
              theme="filled"
            />
          </Tooltip>
        );
      }
    }
  };
  return (
    <WrapperEmail>
      <WrapperContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <EmailName>{email}</EmailName>
          {verifiedLogo()}
        </div>
        {is_main && (
          <div>
            <MainEmail>Email Utama</MainEmail>
          </div>
        )}
        {!is_verified && !is_main && (
          <TextRed margintop="10px">
            {`Email ini akan dihapus dalam ${time_verification} jika tidak terverifikasi`}
          </TextRed>
        )}
      </WrapperContent>
      <div>
        {buttonVissible()}
        {isUser && !is_main && (
          <Icon
            onClick={onClickDelete}
            type="delete"
            style={{
              color: '#ef4d5e',
              cursor: 'pointer',
              fontSize: 20
            }}
          />
        )}
      </div>
    </WrapperEmail>
  );
}
