import styled from 'styled-components';
import Assets from '../../../Assets';
import CONSTANTS from '../../../Config/Constants';

export const Wrapper = styled.div`
  background-color: ${CONSTANTS.COLORS.WHITE};
  margin-bottom: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const SectionCard = styled.div`
  margin-bottom: 15px;
`;

export const OrderDetail = styled.div`
  /* position: relative; */
  font-family: ${Assets.FONT.MULI};
  font-size: 14px;
  padding: 10px 4.3%;
  /* background-color: ${Assets.COLOR_PALLETE.white}; */
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  .info {
    margin-top: 20px;
    h4 {
      color: ${Assets.COLOR_PALLETE.black};
    }
    p {
      color: ${Assets.COLOR_PALLETE.grey};
    }
  }

`;

export const ItemCard = styled(OrderDetail)`
  display: flex;
  /* justify-content: space-between; */
  .image {
    width: 120px;
    margin-right: 15px;
    img {
      width: 100%;
      border-radius: 3px;
    }
  }
  .info {
    width: 50%;
    margin: 0;
  }
  .shipping {
    width: 300px;
  }
  .shipping-info {
    margin-right: 10px;
    h4 {
      color: ${Assets.COLOR_PALLETE.black};
    }
    p {
      color: ${Assets.COLOR_PALLETE.grey};
    }
  }

  .tracking-status {
    .ant-timeline-item-head-blue {
      background-color: #1890ff;
    }

    .ant-timeline-item-head-gray {
      /* border-color: none; */
      border: none;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
`;
