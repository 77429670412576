import React, { useState } from 'react';
import GroupComponent from './Team.component';
import { Group, AuthenticationService } from '../../../Services';
import { message } from 'antd';
import { isEmpty } from 'lodash';
import { DummySpace } from '../..';
import { useAsyncEffect } from 'use-async-effect';

const GroupContainer = props => {
  const [teamList, setGroupList] = useState([]);
  const userId = AuthenticationService.getUserId();

  useAsyncEffect(
    async isMount => {
      // setIsLoading(true);
      const maxResult = 4;
      await Group.GroupGlobal.getListGroup(1, '', maxResult)
        .then(res => {
          if (!isMount()) return;
          setGroupList(res.data);
        })
        .catch(err =>
          message.error('Something went wrong when load team data')
        );
      // setIsLoading(false);
    },
    [userId]
  );

  return (
    <>
      {isEmpty(teamList) ? (
        <DummySpace />
      ) : (
        <GroupComponent withHeader={props.withHeader} teamList={teamList} />
      )}
    </>
  );
};

export default GroupContainer;
