import React from 'react';
import { useMutation } from 'react-apollo';

import { Row, Col, Icon, message } from 'antd';

import {
  CompanyDetailBox,
  CompanyAddressBox,
  CompanyDescriptionAction
} from '../../../Containers';
import { ModalConfirmation, TopSettingGroup } from '../../../Components';
import style, { Box } from './DetailScreen.style';

import { getCookie } from '../../../Utils/CommonUtils';
import { UPDATE_COMPANY_DESCTIPTION } from './DetailScreen.graphql';
import DOMPurify from 'dompurify';

const userData = getCookie('userData') ? JSON.parse(getCookie('userData')) : {};

const DetailScreenComponent = props => {
  const { dataCompany, activeCompanyId, companyId, refetch } = props;

  const [desc, setDesc] = React.useState('');
  const [open, setOpen] = React.useState({
    modal: false,
    confirmation: false,
    action: '',
    dataDesc: ''
  });

  const [updateCompanyDescription] = useMutation(UPDATE_COMPANY_DESCTIPTION, {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
  });

  const showEditDescription =
    userData.roles?.includes('organization-hr-admin') &&
    activeCompanyId === companyId;

  const handleOpenAction = () => {
    setOpen({
      ...open,
      modal: true
    });
    setDesc(dataCompany.description);
  };
  const handleCloseAction = () => {
    setOpen({
      modal: false,
      confirmation: false,
      action: '',
      dataDesc: ''
    });
  };

  const handleOpenModal = (action, dataDesc = '') => {
    setOpen({
      ...open,
      confirmation: true,
      modal: false,
      action,
      dataDesc
    });
  };

  const handleSubmit = () => {
    updateCompanyDescription({
      variables: {
        company: companyId,
        description: {
          description: open.dataDesc
        }
      }
    })
      .then(() => {
        message.success(`Update description success`);
        handleCloseAction();
        refetch();
      })
      .catch(err => {
        message.error(`Update description error, ${err}`);
      });
  };

  return (
    <>
      <Row gutter={16}>
        <Col xs={{ span: 24 }} lg={{ span: 18 }}>
          <Box style={style.top16}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <TopSettingGroup
                style={{ width: '100%' }}
                title="Deskripsi"
                color="black"
              />
              {showEditDescription && (
                <Icon
                  onClick={handleOpenAction}
                  style={(style.icon, { cursor: 'pointer', marginRight: 10 })}
                  type="edit"
                  theme="outlined"
                />
              )}
            </div>
            <DescriptionComponent description={dataCompany.description} />
          </Box>
          <Box style={style.top16}>
            <CompanyAddressBox {...props} />
          </Box>
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <Box style={style.top16}>
            <CompanyDetailBox {...props} />
          </Box>
        </Col>
      </Row>

      <CompanyDescriptionAction
        open={open.modal}
        handleClose={() => handleOpenModal('cancel')}
        value={dataCompany.description}
        setDesc={setDesc}
        desc={desc}
        handleSubmit={dataDesc => handleOpenModal('submit', dataDesc)}
      />

      <ModalConfirmation
        open={open.confirmation}
        title={
          open.action === 'cancel'
            ? 'Buang Perubahan?'
            : open.action === 'submit'
            ? 'Lanjutkan Perubahan?'
            : ''
        }
        content={
          open.action === 'cancel'
            ? 'Apakah anda yakin akan membuang perubahan?'
            : open.action === 'submit'
            ? 'Apakah anda yakin akan menyimpan perubahan?'
            : ''
        }
        onCancel={() => {
          setOpen({
            ...open,
            confirmation: false,
            modal: true,
            action: '',
            dataDesc: ''
          });
        }}
        textPrimary={
          open.action === 'cancel'
            ? 'Buang'
            : open.action === 'submit'
            ? 'Lanjut'
            : ''
        }
        onSubmit={
          open.action === 'cancel'
            ? handleCloseAction
            : open.action === 'submit'
            ? handleSubmit
            : null
        }
      />
    </>
  );
};

export default DetailScreenComponent;

// No browsers support `line-clamp` CSS property yet, but the vendor prefix
// `-webkit-line-clamp` is supported by Firefox as well. However this support
// comes with some issues.

// Firefox doesn't like it if we truncate a node that contains multiple "block"
// child nodes, it truncates them separately instead of as a whole, to remedy
// that we'll just replace <p> with <span> as it's inline, and use <br> to
// provide spacing between paragraphs.

// While it is hack-ish at best, this approach is still better than manually
// trying to measure the appropriate truncation.

const READ_INITIAL = 0;
const READ_HIDDEN = 1;
const READ_VISIBLE = 2;

const DescriptionComponent = ({ description }) => {
  const transformed = React.useMemo(() => {
    if (!description) {
      return '';
    }

    return description
      .replace(/<\/p><p>/g, '</p><br><br><p>')
      .replace(/<p>/g, '<span>')
      .replace(/<\/p>/g, '</span>');
  }, [description]);

  const [state, setState] = React.useState(READ_INITIAL);
  const nodeRef = React.useRef();

  const toggleVisibility = () => {
    setState(state === READ_HIDDEN ? READ_VISIBLE : READ_HIDDEN);
  };

  React.useEffect(() => {
    const el = nodeRef.current;

    if (el.$text === transformed) {
      return;
    }

    if (state !== READ_INITIAL) {
      setState(READ_INITIAL);
      return;
    }

    el.$text = transformed;

    if (el.scrollHeight > el.clientHeight) {
      setState(READ_HIDDEN);
    }
  }, [transformed, state]);

  return (
    <div className="description">
      <p
        ref={nodeRef}
        className="content"
        style={{ WebkitLineClamp: state === READ_VISIBLE ? 'initial' : 5 }}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(transformed) }}
      />

      {state >= READ_HIDDEN && (
        <button className="more" onClick={toggleVisibility}>
          Lihat {state === READ_HIDDEN ? 'Semua' : 'Lebih Sedikit'}
        </button>
      )}
    </div>
  );
};
