import React, { useState, useEffect } from 'react';
import style, { FormWrapper, ImageProductUpload } from './FormProduct.style';
import { checkPrice } from './FormProduct.config';
import { UploadImage } from '../..';
import { isEmpty } from 'lodash';
//
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  Select,
  Checkbox,
  Switch,
  Radio,
  Avatar
} from 'antd';

const { Option } = Select;

const FormProductComponent = props => {
  const {
    form,
    uploadImages,
    handleUploadImage,
    handleChangeImage,
    categoryData,
    showcaseData,
    detailProduct,
    handleDeleteImage,
    productImages
  } = props;
  let product =
    !isEmpty(detailProduct) && detailProduct.v_marketplace_product[0];
  const [isAlwaysAvailable, setIsAlwaysAvailable] = useState(false);
  const [isDigitalProduct, setIsDigitalProduct] = useState(false);
  const [isPreorder, setIsPreorder] = useState(false);
  const { getFieldDecorator, setFields } = form;

  const checkStock = (rule, value, callback) => {
    if (value > 0 || isAlwaysAvailable) {
      return callback();
    }
    callback('Amount of product must greater than zero');
  };

  const checkWeight = (rule, value, callback) => {
    if (value > 0 || isDigitalProduct) {
      return callback();
    }

    callback('Product weight must be greater than zero');
  };

  useEffect(() => {
    if (detailProduct) {
      setIsAlwaysAvailable(
        detailProduct.v_marketplace_product[0].is_always_available
      );
      setIsDigitalProduct(
        detailProduct.v_marketplace_product[0].is_digital_product
      );
      setIsPreorder(detailProduct.v_marketplace_product[0].is_preorder);
    }
  }, [detailProduct]);

  return (
    <>
      <FormWrapper>
        <h2 style={style.h2}>Product Image</h2>
        <p>
          Image format .jpg .jpeg .png and minimum image size 300 x 300px (For
          optimum image, use minimum size 700 x 700 px) (Maximum upload of 5
          images)
        </p>
        <Row type="flex">
          {!isEmpty(product) &&
            productImages.map((image, index) => (
              <Col key={index} span={3} style={style.imageWrapper}>
                <Avatar
                  shape="square"
                  src={image}
                  style={style.productAvatar}
                />
                <div style={style.deleteImageButton}>
                  <Button
                    size="small"
                    type="dashed"
                    onClick={() => handleDeleteImage(image)}
                  >
                    Delete
                  </Button>
                </div>
              </Col>
            ))}
        </Row>
        <ImageProductUpload>
          <UploadImage
            acceptedFormat=".png,.jpg,.jpeg"
            listType="picture-card"
            fileList={uploadImages.images}
            target="images"
            handleUpload={handleUploadImage}
            handleChange={handleChangeImage}
            maxUpload={5 - productImages.length}
          />
        </ImageProductUpload>
      </FormWrapper>
      <FormWrapper>
        <h2 style={style.h2}>Product Detail</h2>
        <Form.Item label="Product Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please input Product Name' }],
            initialValue: product?.name
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Product Category">
          {getFieldDecorator('category', {
            rules: [
              { required: true, message: 'Please input Product Category' }
            ],
            initialValue: product?.marketplace_product_category?.id
          })(
            <Select>
              {!isEmpty(categoryData) &&
                categoryData.marketplace_product_categories.map(category => (
                  <Option key={category.id} value={category.id}>
                    {category.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Product Showcase">
          {getFieldDecorator('showcase', {
            rules: [
              { required: true, message: 'Please input Product Showcase' }
            ],
            initialValue: product?.marketplace_vendor_showcase?.id
          })(
            <Select>
              {!isEmpty(showcaseData) &&
                showcaseData.marketplace_vendor_showcase.map(showcase => (
                  <Option key={showcase.id} value={showcase.id}>
                    {showcase.name}
                  </Option>
                ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [{ message: 'Please input your username!' }],
            initialValue: product?.description
          })(<Input.TextArea autoSize={{ minRows: 3 }} />)}
        </Form.Item>
      </FormWrapper>
      <FormWrapper>
        <Row>
          <Col span={12}>
            <h2 style={style.h2}>Product Pricing</h2>
            <Form.Item label="Price (Rp)">
              {getFieldDecorator('price', {
                rules: [
                  { required: true, message: 'Please input Product Price. ' },
                  { validator: checkPrice }
                ],
                initialValue: product?.price
              })(
                <InputNumber
                  style={style.smallFormItem}
                  min={0}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
            <Form.Item label="Discount Price (Rp)">
              {getFieldDecorator('discount_price', {
                rules: [],
                initialValue: product?.dummy_price
              })(
                <InputNumber
                  style={style.smallFormItem}
                  min={0}
                  formatter={value =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <h2 style={style.h2}>Product Management</h2>
            <Form.Item label="Status" style={style.formItem}>
              {getFieldDecorator('is_always_available', {
                rules: [
                  { required: true, message: 'Please input product status' }
                ],
                initialValue: product?.is_always_available
              })(
                <Radio.Group style={style.fullWidth}>
                  <Radio
                    style={style.radioButton}
                    value={false}
                    onClick={() => setIsAlwaysAvailable(false)}
                  >
                    Limited Stock
                  </Radio>
                  <Form.Item label="Amount">
                    {getFieldDecorator('current_stock', {
                      rules: [{ validator: checkStock }],
                      initialValue: product?.available_stock
                    })(
                      <InputNumber
                        style={style.smallFormItem}
                        min={1}
                        disabled={isAlwaysAvailable}
                      />
                    )}
                  </Form.Item>
                  <Radio
                    style={style.radioButton}
                    value={true}
                    onClick={() => {
                      setIsAlwaysAvailable(true);
                      setFields({ current_stock: { value: 1, errors: null } });
                    }}
                  >
                    Always Available
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </FormWrapper>
      <FormWrapper>
        <h2 style={style.h2}>Weight &amp; Shipping</h2>
        <Form.Item>
          {getFieldDecorator('is_digital_product', {
            valuePropName: 'checked',
            initialValue: product?.is_digital_product
          })(
            <Checkbox
              value={true}
              onClick={() => {
                setIsDigitalProduct(!isDigitalProduct);
                setFields({ weight: { value: 0, errors: null } });
              }}
            >
              This is digital product
            </Checkbox>
          )}
        </Form.Item>
        <Form.Item label="Weight (gram)">
          {getFieldDecorator('weight', {
            rules: [
              { required: !isDigitalProduct },
              { validator: checkWeight }
            ],
            initialValue: product?.weight
          })(
            <InputNumber
              style={style.smallFormItem}
              min={0}
              disabled={isDigitalProduct}
            />
          )}
        </Form.Item>
        <p style={style.spaceTopBottom}>
          The volumetric weight calculation formula is: <br />
          <strong>(P×L×T) ÷ (6000) in centimeter</strong>
        </p>
        <div style={style.weightGroup}>
          <Form.Item>
            {getFieldDecorator('panjang', {
              initialValue: product?.detail_field?.volume?.p
            })(
              <InputNumber
                style={style.smallFormItem}
                min={0}
                placeholder="Panjang"
                disabled={isDigitalProduct}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('lebar', {
              initialValue: product?.detail_field?.volume?.l
            })(
              <InputNumber
                style={style.smallFormItem}
                min={0}
                placeholder="Lebar"
                disabled={isDigitalProduct}
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('tinggi', {
              initialValue: product?.detail_field?.volume?.t
            })(
              <InputNumber
                style={style.smallFormItem}
                min={0}
                placeholder="Tinggi"
                disabled={isDigitalProduct}
              />
            )}
          </Form.Item>
        </div>
        <Form.Item>
          {getFieldDecorator('insurance', {
            valuePropName: 'checked',
            initialValue: product?.insurance
          })(<Checkbox value={true}>Insurance</Checkbox>)}
        </Form.Item>
        <Form.Item label="Pre Order">
          {getFieldDecorator('preorder', {
            valuePropName: 'checked',
            initialValue: product?.is_preorder
          })(<Switch onChange={() => setIsPreorder(!isPreorder)} />)}
          {isPreorder
            ? ' Pre Order is available'
            : ' Pre Order is not available'}
        </Form.Item>
        <span>
          Process Time (day) <br />
        </span>
        <p style={style.processingTimeText}>
          The processing time must be filled to determine how long the products
          processed.
          <br />
        </p>
        <Form.Item>
          {getFieldDecorator('preorder_time', {
            rules: [
              {
                required: isPreorder,
                message: 'Please input preorder time'
              }
            ],
            initialValue: product?.preorder_time
          })(
            <InputNumber
              style={style.smallFormItem}
              min={0}
              placeholder="(Days)"
              disabled={!isPreorder}
            />
          )}
        </Form.Item>
      </FormWrapper>
      <div style={style.buttonGroup}>
        <Button
          size="large"
          type="danger"
          ghost
          style={style.button}
          htmlType="reset"
          onClick={() => props.history.goBack()}
        >
          Cancel
        </Button>
        <Button
          size="large"
          type="primary"
          style={style.button}
          htmlType="submit"
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default FormProductComponent;
