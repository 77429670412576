import { Icon } from "antd";
import React from "react";
import { WrapChipsSection } from "./DetailUserSection.style";

export default function ListChips({
  chipsName,
  onClickEdit,
  noBorder,
  fullchips = false,
  ...children
}) {
  return (
    <WrapChipsSection fullchips={fullchips} noBorder={noBorder}>
      <div className="chips-wrap">
        <div className="chips">
          <h4 className="chips-name">{chipsName}</h4>
        </div>
      </div>
      <div className="content">
        <div className="divider">
          <div {...children} />
          {onClickEdit && (
            <Icon className="icon-edit" onClick={onClickEdit} type="edit" />
          )}
        </div>
      </div>
    </WrapChipsSection>
  );
}
