import React from 'react';
import { Wrapper } from './DirectMessage.style';
import Constants from '../../../Config/Constants';
import { Select } from 'antd';

const privacy = Constants.PRIVACY;
const { Option } = Select;

const DirectMessageComponent = () => {
  const dropdown = () => (
    <Select style={{ width: 160 }} defaultValue={'Public'}>
      {privacy.map(p => (
        <Option key={p.VALUE} value={p.TEXT}>
          <img src={p.IMAGE} alt="option" /> {p.TEXT}
        </Option>
      ))}
    </Select>
  );
  return (
    <Wrapper>
      <section>
        <h1>Send messages directly to me</h1>
        <p>Select who can send you a message directly</p>
        {dropdown()}
      </section>
      <section>
        <h1>Online Status</h1>
        <p>Select who can see you online at WLB.</p>
        {dropdown()}
      </section>
      <section>
        <h1>Send messages directly to me</h1>
        <p>
          When someone sends you a message, people in the conversation will know
          when you’ve seen it. Select who can see the seen status.
        </p>
        {dropdown()}
      </section>
    </Wrapper>
  );
};

export default DirectMessageComponent;
