import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import AboutClassroomComponent from './AboutClassroom.component';
import { useParams } from 'react-router-dom';

import { GET_CLASSROOM_BY_ID } from './getClassroomById.graphql';

const AboutClassroomContainer = props => {
  const [classroomInfo, setClassroomInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const classroomId = params.id;

  const { data: dataClassroom, loading: dataClassroomIsLoading } = useQuery(
    GET_CLASSROOM_BY_ID,
    {
      variables: { classroomId },
      context: { headers: { 'X-Hasura-Role': 'user' } },
    }
  );

  useEffect(() => {
    setIsLoading(dataClassroomIsLoading);
    if (!dataClassroomIsLoading && dataClassroom) {
      setClassroomInfo(dataClassroom.classrooms_by_pk);
    }
    // eslint-disable-next-line
  }, [dataClassroomIsLoading]);

  return (
    <AboutClassroomComponent
      classroomInfo={classroomInfo}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default AboutClassroomContainer;
