import React from 'react';
import Assets from '../../Assets';
import { DisplayLoading } from '../../Components';
import { AuthenticationService } from '../../Services';
import useGetProfile from '../../SharedComponents/CustomHook/useGetProfile';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import { SidebarProfileWrapper } from './SidebarProfile.style';

const SidebarProfileComponent = props => {
  const { userInfo, userFollowing, userFollower, isFetchingProfile } = props;
  const userData = AuthenticationService.getUserData();
  const [profileName, profilePhoto, position, brandName] = useGetProfile();
  const _SidebarProfile = () => {
    return (
      <SidebarProfileWrapper>
        <div
          className="profile-wrapper"
          style={{ cursor: 'pointer' }}
          onClick={() => props.history.push(`/profile/${userInfo.id}/detail`)}
        >
          <div className="photo">
            <img
              alt={'profile'}
              src={
                profilePhoto
                  ? (userData?.thumbnail_url ?? '') + profilePhoto
                  : Assets.DEFAULT_PHOTO
              }
            />
          </div>
          <div className="info">
            <span className="name">{profileName ?? userInfo.name}</span>
            <span className="desc">
              {/* {position ?? userInfo.position_name ?? '-'} */}
              {`${userData?.position_name} di ${userData?.company_name}`}
            </span>
            {/* <span className="desc">
              {brandName ?? userInfo.company_name ?? '-'}
            </span> */}
          </div>
        </div>
        <div className="item-count">
          <div
            className="count"
            onClick={() => props.history.push(`/followers/${userInfo.id}/`)}
          >
            {isFetchingProfile ? (
              <DisplayLoading />
            ) : (
              <span className="number">{userFollower}</span>
            )}
            <span className="text">Pengikut</span>
          </div>
          <div
            className="count"
            onClick={() => props.history.push(`/following/${userInfo.id}/`)}
          >
            {isFetchingProfile ? (
              <DisplayLoading />
            ) : (
              <span className="number">{userFollowing}</span>
            )}
            <span className="text">Mengikuti</span>
          </div>
        </div>
        {/* <div className="skills">
        <img src={Assets.SKILLS} />
      </div> */}
      </SidebarProfileWrapper>
    );
  };
  return <div>{!isObjectEmpty(userInfo) && _SidebarProfile()}</div>;
};

export default SidebarProfileComponent;
