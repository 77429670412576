import styled from "styled-components";
import Assets from "../../../Assets";
import { Select, Form } from "antd";

export const TitleField = styled.span`
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
  margin-right: ${(props) => props.marginRight};
`;

export const SwitchWrapper = styled.span`
  display: flex;
  flex-direction: row;
  padding: 0px 22px;
  margin-bottom: 32px;
`;

export const SelectStyled = styled(Select)`
  & > .ant-select-selection {
    & > .ant-select-selection__rendered {
      & > ul {
        & > .ant-select-selection__choice {
          color: #000 !important;
          background-color: #eff2f4 !important;
          border-radius: 60px !important;
          border: solid 1px #d0d4d4 !important;
          padding: 0px 16px !important;

          & > .ant-select-selection__choice__remove {
            & > .anticon {
              color: #000 !important;
            }
          }

          & > div {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
`;

export const Headline = styled.h4`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 0px 22px;
`;

export const SelectedPepole = styled.span`
  font-size: 16px;
  color: ${Assets.COLOR_PALLETE.blue};
  font-weight: 600;
`;

export const FormItemStyled = styled(Form.Item)`
  & > div {
    width: 100%;
  }
`;

export const WrapperSection = styled.div`
  margin: 28px 0px;
  display: flex;
  flex-direction: row;
  justify-context: flex-start;
`;
  
  export const WrapperRecmndItm = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 36px;
  width: 84%;
`;
