import React from 'react';

import { Form, Input } from 'antd';
import { AttachmentCard } from '@worklifebeyond/wlb-utils-components';

import moment from 'moment';

import { ButtonWlb, TopSettingGroup, DeleteModal } from '..';
import Assets from '../../Assets';

import {
  ButtonGroupRespond,
  RespondWrap,
  ResponseDetails,
  Status,
  StepShipping,
  StepShippingWrap
} from './VendorOrderShippingStatus.style';
import RefundProcessModal from './RefundProcessModal';

const INITIAL_ALERT_STATE = {
  open: false,
  title: '',
  message: '',
  action: '',
  set_state: null
};

const VendorOrderShippingStatusComponent = props => {
  const {
    customerName,
    customerId,
    invoiceId,
    status,
    transferProofs,
    refunds,
    price,
    onStatusChange,
    isDigitalOrder
  } = props;
  const { getFieldDecorator, validateFields } = props.form;

  const [openRefund, setOpenRefund] = React.useState(false);
  const [alertState, setAlertState] = React.useState(INITIAL_ALERT_STATE);

  const isRefundPending = status === 'refund_pending';
  const isRefundPaid = status === 'refund_paid';

  const isRejected = status === 'rejected';

  const isPending = status === 'pending';
  const isPaid = status === 'paid';
  const isReview = status === 'review';
  const isPacking = status === 'packing';
  const isShipping = status === 'shipping';
  const isCompleted = status === 'completed';

  const statusName = [
    {
      label: 'Payment Review',
      name: 'payment',
      active: isPaid || isReview || isPacking || isShipping || isCompleted
    },
    {
      label: 'Order Review',
      name: 'review',
      active: isReview || isPacking || isShipping || isCompleted
    },
    {
      label: 'Packing',
      name: 'packing',
      active: isPacking || isShipping || isCompleted
    },
    {
      label: 'Shipping',
      name: 'shipping',
      active: isShipping || isCompleted
    },
    {
      label: 'Completed',
      name: 'completed',
      active: isCompleted
    }
  ];

  const handleAlertClose = () => {
    setAlertState({ ...alertState, open: false });
  };

  const handleAlertConfirm = () => {
    const state = alertState.set_state;
    handleAlertClose();

    if (state) {
      onStatusChange(state);
    }
  };

  const _status = () => {
    return statusName.map((item, index) => (
      <Status key={index} active={item.active}>
        <span className={`icon ${item.name}`} />
        <span className="tooltip">{item.label}</span>
      </Status>
    ));
  };

  const _respond = () => {
    const handleChatClick = () => {
      const chatUrl = process.env.REACT_APP_CHAT;
      const matrixUrl = process.env.REACT_APP_MATRIX_URL;

      window.open(`${chatUrl}/?chat=@${customerId}:${matrixUrl}#/`, '_blank');
    };

    const chatButton = (
      <ButtonWlb disabled={!customerId} onClick={handleChatClick}>
        Chat Customer
      </ButtonWlb>
    );

    if (isRefundPending) {
      const latest = refunds[refunds.length - 1];

      if (!latest) {
        return null;
      }

      const handleRefundOpen = () => {
        setOpenRefund(true);
      };

      const handleRefundClose = () => {
        setOpenRefund(false);
      };

      const handleRefundSubmit = () => {
        onStatusChange('refund_paid');
        handleRefundClose();
      };

      return (
        <ButtonGroupRespond key={1}>
          {chatButton}

          <ButtonWlb
            type="danger"
            style={{ marginLeft: 20 }}
            onClick={handleRefundOpen}
          >
            Process Refund
          </ButtonWlb>
          <ButtonWlb
            type="primary"
            style={{ marginLeft: 20 }}
            onClick={() =>
              onStatusChange(isDigitalOrder ? 'completed' : 'review')
            }
          >
            Continue to Shipping
          </ButtonWlb>

          <RefundProcessModal
            open={openRefund}
            invoiceId={invoiceId}
            data={latest}
            price={price}
            onClose={handleRefundClose}
            onSubmit={handleRefundSubmit}
          />
        </ButtonGroupRespond>
      );
    } else if (isRefundPaid) {
      return (
        <ButtonGroupRespond key={2}>
          <p style={{ color: Assets.COLOR_PALLETE.red, marginBottom: 20 }}>
            Order has been refunded
          </p>

          {chatButton}
        </ButtonGroupRespond>
      );
    } else if (isRejected) {
      return (
        <ButtonGroupRespond key={2}>
          <p style={{ color: Assets.COLOR_PALLETE.red, marginBottom: 20 }}>
            Order has been rejected
          </p>

          {chatButton}
        </ButtonGroupRespond>
      );
    } else if (isPending) {
      const handleRejectClick = () => {
        setAlertState({
          open: true,
          title: 'Reject Payment?',
          message:
            'Are you sure you want to reject this payment? The order will be cancelled once you reject this payment',
          action: 'Confirm',
          set_state: 'rejected'
        });
      };

      return (
        <ButtonGroupRespond key={3}>
          {chatButton}

          <ButtonWlb
            type="danger"
            style={{ marginLeft: 80 }}
            onClick={handleRejectClick}
          >
            Reject Payment
          </ButtonWlb>
          <ButtonWlb
            type="primary"
            style={{ marginLeft: 80 }}
            disabled={!transferProofs || transferProofs.length < 1}
            onClick={() =>
              onStatusChange(isDigitalOrder ? 'completed' : 'paid')
            }
          >
            Confirm Payment
          </ButtonWlb>
        </ButtonGroupRespond>
      );
    } else if (isPaid) {
      return (
        <ButtonGroupRespond key={4}>
          {chatButton}

          <ButtonWlb
            type="primary"
            style={{ marginLeft: 80 }}
            onClick={() =>
              onStatusChange(isDigitalOrder ? 'completed' : 'review')
            }
          >
            Process Order
          </ButtonWlb>
        </ButtonGroupRespond>
      );
    } else if (isReview) {
      return (
        <ButtonGroupRespond key={5}>
          {chatButton}

          <ButtonWlb
            type="primary"
            style={{ marginLeft: 80 }}
            onClick={() => onStatusChange('packing')}
          >
            Continue to Shipping
          </ButtonWlb>
        </ButtonGroupRespond>
      );
    } else if (isPacking) {
      const handleFormSubmit = ev => {
        ev.preventDefault();

        validateFields((error, values) => {
          if (!error) {
            onStatusChange('shipping', values.receipt_number);
          }
        });
      };

      return (
        <ButtonGroupRespond key={6}>
          <Form onSubmit={handleFormSubmit}>
            <Form.Item>
              {getFieldDecorator('receipt_number', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Tracking number'
                  }
                ]
              })(<Input placeholder={'Tracking number'} />)}
            </Form.Item>

            {chatButton}

            <ButtonWlb
              type="primary"
              style={{ marginLeft: 80 }}
              htmlType="submit"
            >
              Shipping
            </ButtonWlb>
          </Form>
        </ButtonGroupRespond>
      );
    } else if (isShipping || isCompleted) {
      return <ButtonGroupRespond key={7}>{chatButton}</ButtonGroupRespond>;
    }

    return null;
  };

  const _details = () => {
    const array = [];
    let idx = 0;

    if (
      (isPending || isPaid || isReview || isCompleted || isRefundPaid) &&
      transferProofs?.length
    ) {
      const dateAdded = transferProofs[0].date_added;

      array.push(
        <ResponseDetails key={idx++}>
          <div className="header">
            <span className="title">{customerName} paid the payment</span>
            <span className="time">
              {moment(dateAdded).format('MMM D, YYYY')}
            </span>
          </div>
          <div className="attachments">
            {transferProofs.map((file, idx) => (
              <AttachmentCard key={idx} name={file.name} url={file.file} />
            ))}
          </div>
        </ResponseDetails>
      );
    }

    if (isRefundPending) {
      const latest = refunds[refunds.length - 1];

      if (latest) {
        array.push(
          <ResponseDetails key={idx++}>
            <div className="header">
              <span className="title danger">
                {customerName} is asking for refund
              </span>
              <span className="time">
                {moment(latest.date_added).format('MMM D, YYYY')}
              </span>
            </div>
            <p className="body">{latest.reason}</p>
            <div className="attachments">
              {latest.marketplace_refund_request_files.map((file, idx) =>
                file.vendor_side ? null : (
                  <AttachmentCard key={idx} name={file.name} url={file.file} />
                )
              )}
            </div>
          </ResponseDetails>
        );
      }
    }

    if (isRefundPaid) {
      const latest = refunds[refunds.length - 1];

      if (latest) {
        array.push(
          <ResponseDetails key={idx}>
            <div className="header">
              <span className="title danger">Refund Receipt</span>
              <span className="time">
                {moment(latest.date_added).format('MMM D, YYYY')}
              </span>
            </div>
            <div className="attachments">
              {latest.marketplace_refund_request_files.map((file, idx) =>
                !file.vendor_side ? null : (
                  <AttachmentCard key={idx} name={file.name} url={file.file} />
                )
              )}
            </div>
          </ResponseDetails>
        );
      }
    }

    return array;
  };

  return (
    <div>
      <StepShippingWrap>
        <TopSettingGroup
          title="Shipping Status"
          color={Assets.COLOR_PALLETE.black}
          border={false}
        />
        <StepShipping>{_status()}</StepShipping>
      </StepShippingWrap>

      <RespondWrap>
        <TopSettingGroup
          title="Respond"
          color={Assets.COLOR_PALLETE.black}
          border={false}
          borderRadius={false}
        />

        {_details()}
        {_respond()}
      </RespondWrap>

      <DeleteModal
        displayModal={alertState.open}
        closeModal={handleAlertClose}
        handleDelete={handleAlertConfirm}
        modalTitle={alertState.title}
        modalContent={alertState.message}
        wording={alertState.action}
      />
    </div>
  );
};
const VendorOrderShippingStatusComponentForm = Form.create({
  name: 'shipping_status'
})(VendorOrderShippingStatusComponent);

export default VendorOrderShippingStatusComponentForm;
