import React, { useState, useEffect } from 'react';
import VendorLocationTableComponent from './VendorLocationTable.component';
import { DELETE_LOCATION, GET_LIST_LOCATION } from './VendorLocationGraphQl';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TableItemActionContainer from '../PopOver/TableItemAction/TableItemActionContainer';
import { message } from 'antd';
// import { GET_LIST_LOCATION } from '../LocationForm/LocationForm.graphql';

const OPTIONS = {
  context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
};
const VendorLocationTableContainer = props => {
  const { companyId } = props;
  const [selectedRecord, setSelectedRecord] = useState({});
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayForm, setDisplayForm] = useState(false);
  const [formTitle, setFormTitle] = useState('Edit Location');
  const [deleteLocation] = useMutation(DELETE_LOCATION, OPTIONS);
  const typeForm = 'location';
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const OPTIONS_LOCATION = {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } },
    fetchPolicy: 'no-cache',
    variables: {
      company: companyId,
      limit: limit,
      offset: offset
    },
    notifyOnNetworkStatusChange: true
  };
  const dataLocation = useQuery(GET_LIST_LOCATION, OPTIONS_LOCATION);
  const [refetch, setRefetch] = useState(true);

  useEffect(() => {
    const refetchLocation = async () => {
      await dataLocation.refetch();
      setRefetch(false);
    };
    refetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  useEffect(() => {
    if (dataLocation.data) {
      setData(dataLocation.data.company_origin_shipping_addresses);
      setTotalData(dataLocation.data.total.aggregate.count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLocation.data]);

  const handleDeleteLocation = async () => {
    await deleteLocation({
      variables: {
        deleted_at: 'now()',
        id: selectedRecord.id
      }
    });
    await dataLocation.refetch();
    message.success(`Success deleted ${selectedRecord.label_name} !`);
    setDisplayDeleteModal(false);
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label_name',
      key: 'label_name'
    },
    {
      title: 'Province',
      dataIndex: 'province_name',
      key: 'province_name'
    },
    {
      title: 'City',
      dataIndex: 'city_name',
      key: 'city_name'
    },
    {
      title: 'District',
      dataIndex: 'district_name',
      key: 'district_name'
    },
    // {
    //   title: 'Postal Code',
    //   dataIndex: 'post_code',
    //   key: 'post_code'
    // },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => {
        return (
          <TableItemActionContainer
            id={id}
            record={record}
            setDisplayForm={setDisplayForm}
            setDisplayDeleteModal={setDisplayDeleteModal}
            setSelectedRecord={setSelectedRecord}
            formTitle={formTitle}
            setFormTitle={setFormTitle}
            typeForm={typeForm}
            queryLocation={dataLocation}
          />
        );
      }
    }
  ];

  const handleLimit = limit => {
    setLimit(parseInt(limit));
  };

  const fetchMore = (offsetState, newOffset) => {
    dataLocation.fetchMore({
      variables: {
        offset: offsetState,
        company: companyId,
        limit: limit
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }
        return Object.assign({}, prevResult, {
          company_origin_shipping_addresses: [
            ...fetchMoreResult.company_origin_shipping_addresses
          ]
        });
      }
    });
    setOffset(newOffset);
  };

  const nextLocation = () => {
    fetchMore(offset + limit, offset + limit);
  };
  const prevLocation = () => {
    const prevOffset = offset - limit < 0 ? 0 : offset - limit;
    fetchMore(prevOffset, prevOffset);
  };

  return (
    <VendorLocationTableComponent
      {...props}
      columns={columns}
      dataSource={data}
      displayDeleteModal={displayDeleteModal}
      onCloseModal={setDisplayDeleteModal}
      selectedRecord={selectedRecord}
      setSelectedRecord={setSelectedRecord}
      displayForm={displayForm}
      setDisplayForm={setDisplayForm}
      formTitle={formTitle}
      setFormTitle={setFormTitle}
      handleDeleteLocation={handleDeleteLocation}
      refetch={refetch}
      setRefetch={setRefetch}
      queryLocation={dataLocation}
      offset={offset}
      limit={limit}
      totalLocation={totalData}
      handleLimit={handleLimit}
      prevLocation={prevLocation}
      nextLocation={nextLocation}
    />
  );
};

export default VendorLocationTableContainer;
