import React, { useState } from 'react';
import { message, Spin } from 'antd';
import ModalEdit from '../../ModalEdit';
import { useMutation, useQuery } from 'react-apollo';
import {
  LIST_SETTING_QUERY,
  SET_SHARE_SECTION,
  LIST_SETTING_SECTION
} from './SettingSection.graphql';
import { AuthenticationService } from '../../../Services';
import SettingList from './SettingList';

export default function SettingSection({ open, onClose }) {
  const [selectedSection, setSelectedSection] = useState([]);
  const { data } = useQuery(LIST_SETTING_QUERY, {
    variables: {
      id: AuthenticationService.getUserId()
    },
    onCompleted: ({ people_profile }) => {
      setSelectedSection(people_profile?.[0]?.settings_section || []);
    }
  });
  const { data: dataSetting } = useQuery(LIST_SETTING_SECTION, {
    context: { headers: { 'X-Hasura-Role': 'public' } }
  });

  const [setSection] = useMutation(SET_SHARE_SECTION, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleChange = (val, name, fieldName) => {
    setSelectedSection(() => {
      const newArr = [...Object.values(selectedSection)];
      if (val) {
        newArr.push(name);
      } else {
        const index = newArr.indexOf(name);
        if (index > -1) {
          newArr.splice(index, 1);
        }
      }
      setSection({
        variables: {
          id: AuthenticationService.getUserId(),
          setting: newArr
        }
      })
        .then(() => {
          message.success(`Update ${fieldName} setting success`);
          return newArr;
        })
        .catch(err => {
          message.error(`Update ${fieldName} setting error, ${err}`);
        });
      return newArr;
    });
  };

  const hide = [];
  return (
    <ModalEdit
      title="Setting Section"
      padding="0px"
      open={open}
      onClose={onClose}
    >
      {data && dataSetting ? (
        dataSetting.global_people_profile_setting_sections.map((res, i) => {
          if (!hide.includes(res.value)) {
            return (
              <SettingList
                key={`${i}idx`}
                onChange={val => handleChange(val, res.value, res.name)}
                name={res.name}
                description={res.description}
                data={data.people_profile?.[0]?.settings_section ?? []}
                value={res.value}
              />
            );
          }
        })
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '124px 0px',
            justifyContent: 'center'
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </ModalEdit>
  );
}
