import React, { useState, useEffect } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import { ShowMoreText } from '../../../../Components/DetailUserSection/SeeMoreText';
import { AuthenticationService } from '../../../../Services';
import AddEdit from './AddEdit';
import MoreProject from './MoreProject';
import { GET_POSITION } from './Project.graphql';
import ProjectItem from './ProjectItem';

export default function Project({
  isUser,
  dataProject,
  refetch,
  people_profile,
  profileId,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  let dataAsc = [];
  dataProject.map(res => {
    if (res.dataEdit.position !== null) {
      dataAsc.push({
        id: res.dataEdit.position,
        name: res.dataEdit.position
      });
    }
  });

  const [dataQuery, setDataQuery] = useState({
    associate: dataAsc
  });
  const client = useApolloClient();
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };
  const [listSelect, setListSelect] = useState({
    associate: dataAsc
  });
  const { data: dataPosition } = useQuery(GET_POSITION, {
    variables: {
      userId: AuthenticationService.getUserId(),
      search: null,
      limit: 5
    }
  });
  useEffect(() => {
    let dataAsc = dataQuery.associate;
    dataPosition &&
      dataPosition.people_profile_educations.length >= 1 &&
      dataPosition.people_profile_educations.map(row => {
        dataAsc.push({
          id: 'Student at ' + row?.study_at?.brand_name ?? '',
          name: 'Student at ' + row?.study_at?.brand_name ?? ''
        });
      });

    dataPosition &&
      dataPosition.people_profile_experiences.length >= 1 &&
      dataPosition.people_profile_experiences.map(row => {
        dataAsc.push({
          id: row?.as + ' at ' + row?.working_at ?? '',
          name: row?.as + ' at ' + row?.working_at ?? ''
        });
      });
    setDataQuery({
      ...dataQuery,
      associate: dataAsc
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPosition]);

  const handleSearch = async (val, target) => {
    if (target === 'associate') {
      const { data: dataAs } = await client.query({
        query: GET_POSITION,
        variables: {
          userId: AuthenticationService.getUserId(),
          search: `%${val}%`,
          limit: 5
        },
        context: CONTEXT_USER
      });
      setListSelect({
        ...listSelect,
        associate: []
      });
      if (dataAs) {
        let asc = [];
        dataAs.people_profile_educations.length >= 1 &&
          dataAs.people_profile_educations.map(row => {
            asc.push({
              id: 'Student at ' + row?.study_at?.brand_name ?? '',
              name: 'Student at ' + row?.study_at?.brand_name ?? ''
            });
          });

        dataAs.people_profile_experiences.length >= 1 &&
          dataAs.people_profile_experiences.map(row => {
            asc.push({
              id: row?.as + ' at ' + row?.working_at ?? '',
              name: row?.as + ' at ' + row?.working_at ?? ''
            });
          });
        // console.log(asc)
        setDataQuery({
          ...dataQuery,
          associate: asc
        });
      }
    }
  };

  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: false,
      actionType: 'edit',
      data: data?.dataEdit
    }));
  };

  const _data = [...(dataProject?.slice(0, 2) || [])];
  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title="Projek"
        action={isUser ? 'plus' : null}
      >
        {_data?.map((res, i) => {
          return (
            <ProjectItem
              key={i}
              onClickEdit={() => handleEditItem(res)}
              isUser={isUser}
              avatar={res.avatar}
              title={res.title}
              company={res.company}
              date={res.time}
              location={res.location}
              description={res.description}
              data={res}
              noDivider={_data.length === i + 1}
              contentPadding="25px 0px"
              contentWidth="650px"
              dividerWidth="720px"
            />
          );
        })}
        {dataProject?.length > 2 && (
          <ShowMoreText
            label="Tampilkan Lebih Banyak Projek..."
            onClick={() => {
              setOpen(e => ({
                ...e,
                openMore: true
              }));
            }}
          />
        )}
      </DetailUserSection>
      <AddEdit
        setOpen={setOpen}
        openState={open}
        profileId={profileId}
        people_profile={people_profile}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onSearch={handleSearch}
        dataQuery={dataQuery}
        setDataQuery={setDataQuery}
        setListSelect={setListSelect}
        onClose={() =>
          setOpen({
            open: false,
            data: null,
            actionType: null,
            fromList: false,
            openMore: open.fromList ? true : false
          })
        }
      />
      <MoreProject
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataProject}
      />
    </>
  );
}
