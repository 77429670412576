import React, { useState, useEffect } from 'react';
import { WidgetSettingsWrapper } from './WidgetSettings.style';
import { WidgetService } from '../../Services';
import { DisplayLoading } from '../../Components';
import WidgetSettingsComponent from './WidgetSettings.component';
import { message } from 'antd';

const WidgetSettingsContainer = props => {
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    WidgetService.getListWidget()
      .then(res => {
        setWidgets(res.data);
      })
      .catch(err => message.error('Something went wrong when load Widget Data'))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const MapWidgetSettings = () => {
    return widgets.map(widget => {
      return <WidgetSettingsComponent key={widget.id} widget={widget} />;
    });
  };

  return (
    <WidgetSettingsWrapper>
      {loading ? <DisplayLoading /> : <MapWidgetSettings />}
    </WidgetSettingsWrapper>
  );
};

export default WidgetSettingsContainer;
