import React from 'react';
import PostCardContainer from './PostCard.container';
import { CompanyTimeline } from '../../Services';

function CompanyTimelinePostCard(props) {
  const post = props.itemPost;
  const companyId =
    props.listType === 'company-timeline-detail'
      ? props.match.params.userId
      : props.match.params.id;

  const handleLike = likeValue => {
    return CompanyTimeline.postLikePost(
      post.userId,
      companyId,
      post.id,
      likeValue
    );
  };

  return (
    <PostCardContainer
      {...props}
      handleLike={handleLike}
      feature="companyPost"
    />
  );
}
export default CompanyTimelinePostCard;
