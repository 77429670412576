import React from "react";
import { Link } from "react-router-dom";

export default function DirectToUserDetail({ userId, ...child }) {
  if (userId) {
    return <Link to={`/profile/${userId}/detail`} {...child} />;
  } else {
    return <div {...child} />;
  }
}
