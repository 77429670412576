import moment from "moment";

export const getYear = (start_date, end_date) => {
  if (end_date) {
    return `${moment(start_date).format("YYYY")} - ${moment(end_date).format(
      "YYYY"
    )}`;
  } else {
    return `${moment(start_date).format("YYYY")}`;
  }
};