import React from 'react';
import ReadyScreenComponent from './ReadyScreen.component';

const ReadyScreenContainer = (props) => {
  return (
    <ReadyScreenComponent {...props} />
  );
};

export default ReadyScreenContainer;
