import React, { useState } from 'react';
import { ModalDeleteWLBCheck, ModalEdit } from '../..';
import { ScrollWrapper } from './PhoneNumber.style';
import { Empty, message } from 'antd';
import ButtonAddPhone from './ButtonAddPhone';
import ListPhone from './ListPhone';
import AddEditPhoneNumber from './AddEditPhoneNumber';
import { useMutation, useQuery } from 'react-apollo';
import {
  GET_LIST_PHONENUMBER,
  DELETE_LIST_PHONENUMBER,
  SET_MAIN_PHONECODE,
  UPDATE_VISIBILITY_PHONE
} from './PhoneNumber.graphql';

export default function PhoneNumber({
  open,
  handleClose,
  setOpen,
  stateOpen,
  profileId,
  isUser
}) {
  const [openAction, setOpenAction] = useState({
    addEdit: false,
    data: null,
    actionType: null,
    modalDelete: false
  });
  const [deletePhoneNumber] = useMutation(DELETE_LIST_PHONENUMBER, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setMainPhone] = useMutation(SET_MAIN_PHONECODE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [setVisibility] = useMutation(UPDATE_VISIBILITY_PHONE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const { data, refetch } = useQuery(GET_LIST_PHONENUMBER, {
    variables: {
      user: profileId
    },
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleAddButtonClick = () => {
    setOpen({ ...stateOpen, phoneNumber: false });
    setOpenAction({ ...openAction, addEdit: true, actionType: 'add' });
  };
  const handleClickAction = (id, country_code, phone, is_main, code) => {
    setOpen({ ...stateOpen, phoneNumber: false });
    setOpenAction({
      ...openAction,
      addEdit: true,
      actionType: 'edit',
      data: {
        id,
        country_code,
        phone,
        is_main,
        code
      }
    });
  };
  const handleButtonCloseAddEdit = () => {
    setOpen({ ...stateOpen, phoneNumber: true });
    setOpenAction({
      ...openAction,
      addEdit: false,
      actionType: null,
      data: null,
      modalDelete: false
    });
  };

  const handleSetModalDelete = cond => {
    setOpenAction({
      ...openAction,
      addEdit: cond === 'open' ? false : true,
      modalDelete: cond === 'open' ? true : false
    });
  };

  const handleDelete = () => {
    deletePhoneNumber({
      variables: {
        id: openAction.data.id,
        userId: profileId
      }
    })
      .then(() => {
        message.success(`Hapus nomor telepon berhasil`);
        refetch();
        handleButtonCloseAddEdit();
      })
      .catch(err => {
        message.error(`Hapus nomor telepon gagal, ${err}`);
      });
  };

  const handleSetMain = id => {
    setMainPhone({
      variables: {
        user: profileId,
        id
      }
    })
      .then(() => {
        message.success(`Tetapkan sebagai nomor telpon utama berhasil`);
        refetch();
      })
      .catch(err => {
        message.error(`Tetapkan sebagai nomor telpon utama gagal, ${err}`);
      });
  };

  const handleClickVisibility = res => {
    setVisibility({
      variables: {
        id: res.id,
        userId: profileId,
        isVisible: !res.is_visible
      }
    })
      .then(() => {
        message.success(
          `${res.is_visible ? 'Tidak' : ''} Perlihatkan nomor telepon sukses`
        );
        refetch();
      })
      .catch(err => {
        message.error(
          `${
            res.is_visible ? 'Tidak' : ''
          } Perlihatkan nomor telepon gagal, ${err}`
        );
      });
  };

  return (
    <>
      <ModalEdit
        title="Nomor Telepon"
        open={open}
        onClose={handleClose}
        sizeSmall
        padding="0px"
        centered
      >
        <ScrollWrapper>
          {data && data.phone_numbers.length > 0 ? (
            data.phone_numbers.map(res => {
              if (!isUser && !res.is_visible) {
                return '';
              } else {
                return (
                  <ListPhone
                    isUser={isUser}
                    key={res.id}
                    onClickAction={() =>
                      handleClickAction(
                        res.id,
                        res.global_phone_code_id,
                        res.phone,
                        res.is_main,
                        res.country_code
                      )
                    }
                    is_main={res.is_main}
                    phone={`${res.country_code}${res.phone}`}
                    country={res.global_phone_code_id}
                    onClickSetMain={() => handleSetMain(res.id)}
                    isVisible={res.is_visible}
                    onClickVisibility={() => handleClickVisibility(res)}
                  />
                );
              }
            })
          ) : (
            <Empty style={{ margin: '50px 0px' }} />
          )}
        </ScrollWrapper>
        {isUser && <ButtonAddPhone onClick={handleAddButtonClick} />}
      </ModalEdit>
      <AddEditPhoneNumber
        dataEdit={openAction.data}
        refetchList={refetch}
        openAction={openAction}
        open={openAction.addEdit}
        handleClose={handleButtonCloseAddEdit}
        setOpenAction={setOpenAction}
      />

      <ModalDeleteWLBCheck
        open={openAction.modalDelete}
        centered
        target="Nomor Telepon"
        onClose={() => handleSetModalDelete('close')}
        onDelete={handleDelete}
      />
    </>
  );
}
