import React, { useState } from 'react';
import { useQuery } from 'react-apollo';

import { LIST_RELIGIONS, GET_RELIGION_BY_ID } from './About.graphql';

import AboutComponent from './About.component';
import EditAboutContainer from './EditAboutContainer';

const AboutContainer = props => {
  const { loading, userProfile, refetch } = props;
  const { profileId } = props.match.params;
  const [open, setOpen] = useState({
    open: false,
    data: null
  });
  const [dataProvinceCity, setDataProvinceCity] = useState({
    religion: null
  });

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };

  const { data: dataReligion } = useQuery(LIST_RELIGIONS, {
    context: CONTEXT_PUBLIC
  });

  useQuery(GET_RELIGION_BY_ID, {
    skip: !userProfile?.profile_about?.[0]?.religion,
    context: CONTEXT_PUBLIC,
    variables: {
      religion: userProfile?.profile_about?.[0]?.religion
    },
    onCompleted: data => {
      if (data) {
        setDataProvinceCity({
          religion: data?.global_religions?.[0]?.name
        });
      }
    }
  });

  return (
    <>
      {!loading && (
        <AboutComponent
          dataProvinceCity={dataProvinceCity}
          setOpen={setOpen}
          {...props}
        />
      )}
      <EditAboutContainer
        refetch={refetch}
        profileId={profileId}
        dataReligion={dataReligion && dataReligion.global_religions}
        data={open.data}
        open={open.open}
        onClose={() => setOpen({ open: false, data: null })}
      />
    </>
  );
};

export default AboutContainer;
