import {message, Modal} from 'antd';
import Button from 'antd/es/button';
import React from 'react';
import './DeleteConfirmationModal.style.css';

const DeleteConfirmationModalContainer = (props) => {
	const {
		handleDeleteModalVisibility,
		deleteModalVisibility,
		deleteRecordMutation,
		refetchContentQuery,
		validatorFields,
		recordToDelete,
		modalContent,
		modalTitle,
	} = props;
	
	const _handleDeleteRecord = async (recordToDelete) => {
		const {data, error} = await deleteRecordMutation(
				{variables: recordToDelete}
		);

		if (error) {
			message.error('Failed to delete record');
			return;
		}

		if (data) {
			const affected_rows = data[validatorFields[0]][validatorFields[1]];
			if(affected_rows > 0){
				message.success('Successfully delete record');
			}else {
				message.error('Failed to delete record');
			}
			await refetchContentQuery();
		}

		handleDeleteModalVisibility()
	};

	return (
			<Modal className={'confirmation-modal'}
			       visible={deleteModalVisibility}
			       closable={false}
			       title={<label className={'modal-title'}>{modalTitle}</label>}
			       content={'hello world'}
			       footer={
				       <div className={'button-wrapper'}>
					       <Button id={'cancel-button'} key="back"
					               onClick={handleDeleteModalVisibility}>
						       Cancel
					       </Button>
					       <Button id={'delete-button'} key="submit"
					               onClick={() => _handleDeleteRecord(recordToDelete)}>
						       Delete
					       </Button>
				       </div>
			       }
			>
				<p id={'modal-content'}>{modalContent}</p>
			</Modal>
	);
};

export default DeleteConfirmationModalContainer;
