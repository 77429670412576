import { Modal } from 'antd';
import styled from 'styled-components';
import Assets from '../../Assets';

export const ShowVoteWrapper = styled(Modal)`
  font-family: ${Assets.FONT.MULI_SEMIBOLD};
  .ant-modal-title {
    text-align: left;
  }
  .ant-modal-header {
    border-bottom: 1.5px solid #eff2f4 !important;
  }

  .ant-modal-body {
    text-align: left;
  }

  .user-like {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }

  .info {
    margin-left: 19.3px;
  }

  .name {
    margin-bottom: 2px;
    color: black;
    font-size: 14px;
  }

  .pos {
    margin-bottom: 0px;
    color: #a9a8a8;
    font-size: 12px;
  }

  .avatar {
    width: 50;
  }
`;

export const ModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 0;
  height: 45px;
  border-bottom: 1.5px solid #eff2f4 !important;

  .title {
    height: 45px;
    padding: 0px 15px 0px 15px;
    border-bottom: 1.5px solid #eff2f4 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title-text {
    color: black;
    font-size: 14px;
  }
`;
