import gql from 'graphql-tag';

const GET_ALL_SHOWCASE = gql`
  query getListShowcase($companyId: uuid!, $limit: Int!, $offset: Int!) {
    total: marketplace_vendor_showcase_aggregate(
      where: { vendor: { _eq: $companyId } }
    ) {
      aggregate {
        count
      }
    }
    marketplace_vendor_showcase(
      where: { vendor: { _eq: $companyId } }
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
      marketplace_products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const ADD_SHOWCASE_ITEM = gql`
  mutation addVendorShowcaseItem(
    $data: [marketplace_vendor_showcase_insert_input!]!
  ) {
    insert_marketplace_vendor_showcase(objects: $data) {
      affected_rows
    }
  }
`;

const EDIT_SHOWCASE_ITEM = gql`
  mutation editVendorShowcaseItem(
    $name: String!
    $description: String!
    $id: bigint!
  ) {
    update_marketplace_vendor_showcase(
      _set: { name: $name, description: $description }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const DELETE_SHOWCASE_ITEM = gql`
  mutation deleteVendorShowcaseItem($deleted_at: timestamptz!, $id: bigint!) {
    update_marketplace_vendor_showcase(
      _set: { deleted_at: $deleted_at }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export {
  GET_ALL_SHOWCASE,
  ADD_SHOWCASE_ITEM,
  EDIT_SHOWCASE_ITEM,
  DELETE_SHOWCASE_ITEM
};
