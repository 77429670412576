import { gql } from 'apollo-boost';

export const GET_LIST_CLASSROOM_POST = gql`
  query($classroomId: uuid!, $offset: Int, $limit: Int) {
    classroom_posts(
      where: { classroom_id: { _eq: $classroomId } }
      order_by: { created_at: desc_nulls_last }
      offset: $offset
      limit: $limit
    ) {
      id
      classroom_id
      global_user {
        id
        name
        avatar
        people_work_placements {
          company_profile {
            brand_name
            legal_name
          }
          company_job_profile {
            position
            title
          }
        }
      }
      classroom_learning_tracker_mentees {
        classroom_member {
          global_user {
            id
            name
            avatar
          }
        }
      }
      classroom_post_comments {
        id
        content
        global_user {
          id
          name
          avatar
          people_work_placements {
            company_profile {
              brand_name
              legal_name
            }
            company_job_profile {
              position
              title
            }
          }
        }
        date_added
        classroom_post_comment_likes {
          global_user {
            id
          }
        }
      }
      classroom_post_likes {
        global_user {
          id
        }
      }
      created_at
      content
      date
      activity
      event
      duration
      tags
      location
      type
      description
      commitment
      topic
      event_schedule {
        id
        type
        category
        title
        slug
        thumbnail
        description
        location_info
        pricing
      }
      classroom_post_files {
        file
        name
        type
      }
    }
  }
`;

export const DELETE_CLASSROOM_POST = gql`
  mutation($postId: String!) {
    classroomDeletePost(postId: $postId) {
      success
    }
  }
`;

export const GET_CLASSROOM_POST_BY_ID = gql`
  query($id: uuid!) {
    classroom_posts(where: { id: { _eq: $id } }) {
      id
      classroom_id
      global_user {
        id
        name
        avatar
        people_work_placements {
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            position
            title
          }
        }
      }
      classroom_learning_tracker_mentees {
        classroom_member {
          global_user {
            id
            name
            avatar
          }
        }
      }
      classroom_post_comments {
        id
        content
        global_user {
          id
          name
          avatar
          people_work_placements {
            company_profile {
              id
              brand_name
              legal_name
            }
            company_job_profile {
              id
              position
              title
            }
          }
        }
        date_added
        classroom_post_comment_likes {
          global_user {
            id
          }
        }
      }
      classroom_post_likes {
        global_user {
          id
        }
      }
      created_at
      content
      date
      activity
      event
      duration
      tags
      location
      type
      description
      commitment
      topic
      event_schedule {
        id
        type
        category
        title
        slug
        thumbnail
        description
        location_info
        pricing
      }
      classroom_post_files {
        id
        file
        name
        type
      }
    }
  }
`;
