import Assets from '../../Assets';

const bannerCompany = {
  name: '',
  logo: Assets.DEFAULT_PHOTO,
  tagline: '-',
  legalName: '-',
  brandName: '-',
  phone: '-',
  website: '-',
  description: '-',
  bannerPhoto: 'https://i.ibb.co/gMFdTxT/1034-3x.png',
  socialMedia: [
    {
      socialMedia: 'linkedin',
      url: 'https://google.com'
    },
    {
      socialMedia: 'twitter',
      url: 'https://google.com'
    },
    {
      socialMedia: 'facebook',
      url: 'https://google.com'
    },
    {
      socialMedia: 'mail',
      url: 'mailto:google@gmail.com'
    }
  ]
};

export default { bannerCompany };
