import gql from 'graphql-tag';

const ADD_PRODUCT = gql`
  mutation addProduct($data: [marketplace_products_insert_input!]!) {
    insert_marketplace_products(objects: $data) {
      affected_rows
    }
  }
`;

const UPDATE_PRODUCT_GRAPHQL = gql`
  mutation updateProduct(
    $name: String!
    $category: Int!
    $showcase: bigint!
    $description: String!
    $minimum_order: Int!
    $price: Int!
    $current_stock: Int!
    $weight: Int!
    $insurance: Boolean!
    $images: jsonb!
    $is_always_available: Boolean!
    $is_digital_product: Boolean!
    $is_preorder: Boolean!
    $preorder_time: Int!
    $detail_field: jsonb!
    $id: uuid!
    $dummy_price: float8
  ) {
    update_marketplace_products(
      _set: {
        name: $name
        category: $category
        showcase: $showcase
        description: $description
        minimum_order: $minimum_order
        price: $price
        dummy_price: $dummy_price
        current_stock: $current_stock
        weight: $weight
        insurance: $insurance
        images: $images
        is_always_available: $is_always_available
        is_digital_product: $is_digital_product
        is_preorder: $is_preorder
        preorder_time: $preorder_time
        detail_field: $detail_field
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const GET_DETAIL_PRODUCT = gql`
  query getDetailProduct($id: uuid!) {
    v_marketplace_product(where: { id: { _eq: $id } }) {
      id
      company_profile {
        id
        brand_name
        logo
      }
      name
      marketplace_product_category {
        id
        name
      }
      marketplace_vendor_showcase {
        id
        name
      }
      description
      minimum_order
      current_stock
      available_stock
      weight
      date_added
      last_modified
      images
      price
      is_always_available
      is_digital_product
      is_preorder
      preorder_time
      detail_field
      insurance
      dummy_price
    }
  }
`;

const SHOW_CATEGORY_PRODUCT = gql`
  {
    marketplace_product_categories {
      id
      code
      name
      fa_class
      thumbnail
      parent
      count_course
    }
  }
`;

const GET_SHOWCASE_LIST_BY_VENDOR_ID = gql`
  query getShowcaseByVendorId($vendorId: uuid!) {
    marketplace_vendor_showcase(where: { vendor: { _eq: $vendorId } }) {
      id
      name
      description
      marketplace_products_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export {
  ADD_PRODUCT,
  UPDATE_PRODUCT_GRAPHQL,
  GET_DETAIL_PRODUCT,
  GET_SHOWCASE_LIST_BY_VENDOR_ID,
  SHOW_CATEGORY_PRODUCT
};
