import * as _ from "lodash";

import Constants from "../../Config/Constants";

export default {
  search: (checkedFollowers) => ({
    borderRadius: 3,
    fontSize: 12,
    border: 1,
    borderColor: Constants.COLORS.LIGHT_GREY,
    marginBottom: _.isEmpty(checkedFollowers) ? 0 : 20
  }),
  div: {
    paddingTop: 10,
    paddingLeft: 22,
    paddingRight: 22,
    paddingBottom: 10
  },
  bodyModal: {
    padding: 0
  },
  saveButton: {
    color: Constants.COLORS.WHITE,
    backgroundColor: Constants.COLORS.DARK_BLUE
  },
  hr: {
    margin: 0,
    height: 2,
    backgroundColor: Constants.COLORS.LIGHT_GREY,
    border: "none"
  },
  followerTitle: {
    fontSize: 14,
    fontWeight: 'bold'
  }
}
