import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/id'; // without this line it didn't work

import { MicrolearningPopup } from '@smartasn/wlb-utils-components';
import client from './Config/Client';
import Router from './Config/Router';
import { GlobalStateProvider } from './globalState';
import { theme } from './Utils/theme';
import { MuiThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { AuthenticationService } from './Services';
import AntdConfigProvider from './Config/AntdConfig';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    environment: process.env.REACT_APP_SENTRY_ENV
  });

  Sentry.configureScope(scope =>
    scope.setExtra('userData', AuthenticationService.getUserData())
  );
}

moment.locale('id');

const App = () => (
  <ApolloProvider client={client}>
    <MuiThemeProvider theme={theme}>
      <GlobalStateProvider>
        <AntdConfigProvider>
          <BrowserRouter>
            <MicrolearningPopup />
            <Router />
          </BrowserRouter>
        </AntdConfigProvider>
      </GlobalStateProvider>
    </MuiThemeProvider>
  </ApolloProvider>
);

export default App;
