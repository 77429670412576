import Constants from '../../Config/Constants';

export default {
  title: {
    marginBottom: 9.5,
    fontSize: 12,
    color: Constants.COLORS.GREY
  },
  remove: {
    marginBottom: 9.5,
    fontSize: 12,
    cursor: 'pointer',
    color: '#ef4d5e'
  },
  link: {
    fontSize: 12,
    color: Constants.COLORS.LIGHT_BLUE,
    marginTop: 17.8
  },
  input: {
    marginBottom: 10
  },
  select: {
    width: '100%',
    height: 25,
    fontSize: 12,
    color: Constants.COLORS.BLACK,
    marginBottom: 19
  },
  textArea: {
    fontSize: 12,
    padding: 0,
    border: 'none',
    resize: 'none',
    placeholderTextSize: 12,
    placeholderTextColor: Constants.COLORS.GREY,
    color: Constants.COLORS.BLACK
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  }
};
