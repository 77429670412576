import React, { useState, useEffect } from 'react';
import SettingClassroomBoxComponent from './SettingClassroomBox.component';
import { UploadService } from '../../Services';
import { message, Form } from 'antd';
import { cloneDeep } from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import {
  GET_CLASSROOM_BY_ID,
  GET_CLASSROOM_CATEGORIES,
  UPDATE_CLASSROOM,
  DELETE_CLASSROOM,
  END_SESSION
} from './graphql';
import _ from 'lodash';
import { getCookie } from '../../Utils/CommonUtils';

const SettingClassroomBoxContainer = props => {
  const { title, isIndividual } = props;
  const params = useParams();
  const history = useHistory();
  const classroomId = params.id;
  const learningENV = process.env.REACT_APP_NEW_LEARNING;
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displaySessionModal, setDisplaySessionModal] = useState(false);
  const [displayUploadImage, setDisplayUploadImage] = useState(false);
  const { getFieldDecorator } = props.form;
  const [dataClassroom, setDataClassroom] = useState({
    classroomId: classroomId,
    avatarUrl: '',
    name: '',
    description: '',
    categoryId: null,
    privacy: null,
    rules: '',
    finished: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const initial = { images: [] };
  const [post, setPost] = useState(initial);
  const [isOwner, setIsOwner] = useState(false);

  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const userId = userData && userData.id;
  const token = _.isEmpty(userData) ? '' : userData.oauth.access_token;
  const CONTEXT_USER = {
    headers: { 'X-Hasura-Role': 'user', Authorization: `Bearer ${token}` }
  };

  const [mutationDeleteClassroom] = useMutation(DELETE_CLASSROOM);
  const [mutationUpdateClassroom] = useMutation(UPDATE_CLASSROOM);
  const [endSession] = useMutation(END_SESSION);
  const { data: clasroomCategories } = useQuery(GET_CLASSROOM_CATEGORIES);
  const { data, refetch, loading } = useQuery(GET_CLASSROOM_BY_ID, {
    variables: { classroomId: classroomId },
    context: CONTEXT_USER
  });

  useEffect(() => {
    if (data) {
      const _data = data.classrooms_by_pk;
      setDataClassroom({
        ...dataClassroom,
        avatarUrl: _data.avatar_url,
        name: _data.name,
        description: _data.description,
        privacy: _data.privacy,
        rules: _data.rules,
        categoryId: _.isNull(_data.academy_course_category)
          ? ''
          : _data.academy_course_category.id,
        finished: _data.is_finished,
        resume: _data.resume
      });
      if (userId === _data.global_user.id) {
        setIsOwner(true);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const _deleteClassroomHandler = () => {
    mutationDeleteClassroom({
      variables: { classroomId: dataClassroom.classroomId },
      context: CONTEXT_USER
    })
      .then(() => {
        message.success('Successfully delete classroom');
        window.location.href = learningENV;
      })
      .catch(() => {
        message.error('Something went wrong !');
      });
  };

  const _handleAttributeChange = (attributeName, value) => {
    dataClassroom[attributeName] = value;
    let clonedClassroom = cloneDeep(dataClassroom);
    setDataClassroom(clonedClassroom);
  };

  const handleChangeImage = value => {
    setDataClassroom({
      ...dataClassroom,
      avatarUrl: value
    });
    setDisplayUploadImage(false);
  };

  const handleUploadImage = async ({ onSuccess, file }) => {
    const { url } = await UploadService.uploadFile(file);
    onSuccess(url, file);
    handleChangeImage(url);
  };

  const execUpdateIntroClassroom = async () => {
    setIsLoading(true);
    mutationUpdateClassroom({
      variables: dataClassroom,
      context: CONTEXT_USER
    })
      .then(() => {
        message.success('data successfully updated.');
        refetch();
        setIsLoading(false);
      })
      .catch(() => {
        message.error('Something went wrong !');
        setIsLoading(false);
      });
  };

  const handleUpdateClassroom = () => {
    props.form.validateFields(error => {
      if (!error) {
        execUpdateIntroClassroom();
      }
    });
  };

  const handleDisplayChangeImage = action => {
    setDisplayUploadImage(action);
  };

  const endSessionHandler = resume => {
    if (isIndividual) {
      endSession({
        variables: {
          classroomId: params.id,
          resume
        }
      })
        .then(() => {
          setDisplaySessionModal(false);
          message.success('Data successfully updated.');
          refetch();
          setIsLoading(false);
          history.replace(`/social-learning/${classroomId}/review`, {
            redirectFrom: 'end-session'
          });
        })
        .catch(() => {
          setDisplaySessionModal(false);
          message.error('Something went wrong !');
          setIsLoading(false);
        });
    } else {
      const dataToSend = { ...dataClassroom, finished: true, resume: resume };
      setDataClassroom(dataToSend);
      mutationUpdateClassroom({
        variables: dataToSend,
        context: CONTEXT_USER
      })
        .then(() => {
          setDisplaySessionModal(false);
          message.success('data successfully updated.');
          refetch();
          setIsLoading(false);
          history.replace(`/social-learning/${classroomId}/review`, {
            redirectFrom: 'end-session'
          });
        })
        .catch(() => {
          setDisplaySessionModal(false);
          message.error('Something went wrong !');
          setIsLoading(false);
        });
    }
  };

  return (
    <SettingClassroomBoxComponent
      title={title}
      {...props}
      deleteClassroomHandler={_deleteClassroomHandler}
      displayDeleteModal={displayDeleteModal}
      setDisplayDeleteModal={setDisplayDeleteModal}
      handleAttributeChange={_handleAttributeChange}
      getFieldDecorator={getFieldDecorator}
      dataClassroom={dataClassroom}
      handleUpdateGroup={handleUpdateClassroom}
      isLoading={isLoading}
      isRefetch={loading}
      handleUpload={handleUploadImage}
      handleChangeAvatar={handleChangeImage}
      initData={post}
      displayUploadImage={displayUploadImage}
      handleDisplayChangeImage={handleDisplayChangeImage}
      clasroomCategories={clasroomCategories}
      isOwner={isOwner}
      displaySessionModal={displaySessionModal}
      setDisplaySessionModal={setDisplaySessionModal}
      endSessionHandler={endSessionHandler}
    />
  );
};

const SettingClassroomBoxContainerForm = Form.create({
  name: 'Setting_Classroom_Form_Control'
})(SettingClassroomBoxContainer);

export default SettingClassroomBoxContainerForm;
