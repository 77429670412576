import styled from "styled-components";
import Assets from "../../Assets";
import { Select } from "antd";

export const WrapperSettingSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 30px;
  border-bottom: 1px solid #eff2f4;
  .label-info {
    font-weight: 600;
    h1 {
      font-size: 16px;
      margin-bottom: 7px;
    }
    p {
      text-transform: capitalize:
      font-size: 14px;
      color: #a9a8a8;
      margin: 0px;
    }
  }
  .action {
    display: flex;
    flex-direction: row;
    align-items: center;
    .condition {
      font-size: 12px;
      margin: 0px;
      margin-right: 9px;
    }
  }
`;

export const TitleField = styled.span`
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
  margin-right: ${(props) => props.marginRight};
`;

export const Headline = styled.h4`
  font-size: 16px;
  color: #000;
  font-weight: 600;
  padding: 0px 22px;
`;

export const SelectedPepole = styled.span`
  font-size: 16px;
  color: ${Assets.COLOR_PALLETE.blue};
  font-weight: 600;
`;

export const AvatarItemSelect = styled.div`
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const Position = styled.span`
  margin-right: auto;
  font-size: ${props => props.size};
`;

export const SelectHidden = styled(Select)`
  & > div {
    overflow: hidden
  }
`;

export const WrapperMessage = styled.div`
  margin: 0px 22px;
  background-color: #f7f8f9;
  border-radius: 5px;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 20px;  

  p {
    color: #000;
    margin: 0px;
  }
  span {
    margin-top: 10px;
    color: #039be5;
    cursor: pointer;
  }
`;
