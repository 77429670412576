import styled from 'styled-components';
import Assets from '../../../Assets';


export const MightLikeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`

export const MightLikeCompoenent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
`

export const MightLikeInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`

export const TitleMight = styled.h5`
  font-family: ${Assets.MULI_SEMIBOLD};
  font-size: 14px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 175px;
  max-height: 18px;
  white-space: nowrap; 
`

export const GreyMight = styled.span`
  font-family: ${Assets.MULI};
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 175px;
  max-height: 18px;
  white-space: nowrap; 
`

export const ViewAll = styled.p`
  color: #007ebc;
  font-size: 12px;
  cursor: pointer;
  margin: 12px 0px 24px 0px;
`;
