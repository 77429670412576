/**
 * The words contained in the add and edit address options
 */

export const ADDRESS_TYPE = {
  'Home Address': 'Alamat Rumah',
  'Office Address': 'Alamat Kantor',
  'Mailing Address': 'Alamat Surat',
  'Vacation Address': 'Alamat Pekerjaan'
};

export const LIVING_STATUS = {
  Alone: 'Sendiri',
  Family: 'Keluarga',
  Relatives: 'Kerabat',
  Institution: 'Lembaga'
};

export const OWNER_STATUS = {
  'Own House': 'Rumah Pribadi',
  'Family House': 'Rumah Keluarga',
  Rent: 'Sewa'
};
