import React, { useEffect } from 'react';
import NotificationSocketComponent from './NotificationSocket.component';
// import io from 'socket.io-client';
// import { AuthenticationService } from '../../Services';
import _ from 'lodash';
import { useGlobalState } from '../../globalState';

const NotificationSocketContainer = props => {
  const [countNotification, setCountNotification] = useGlobalState(
    'countNotification'
  );
  // const socket = io(process.env.REACT_APP_SOCKET_URL);
  // const userId = AuthenticationService.getUserId();
  // socket.emit('register', userId);
  // const getMsg = socket.on('notify', function(msg) {
  //   console.log(msg);
  //    if(msg){
  //   setDataNotification(dataNotification.push(msg))
  //   localStorage.setItem('notification', JSON.stringify(_.uniqBy(dataNotification, obj=>obj.data.postId)));
  //   const jsonNotification = JSON.parse(localStorage.getItem('notification'));
  //   setCountNotification(_.size(jsonNotification))
  // }
  // });

  const jsonNotification = JSON.parse(localStorage.getItem('notification'));

  useEffect(() => {
    const countTotal = async () => {
      setCountNotification(_.size(jsonNotification));
    };
    countTotal();
  }, [jsonNotification, setCountNotification]);

  return (
    <NotificationSocketComponent
      countNotification={countNotification}
      {...props}
    />
  );
};

export default NotificationSocketContainer;
