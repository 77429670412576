import React from 'react';
import { Modal } from 'antd';
import LocationFormComponentContainer from '../LocationForm';
import './LocationModal.style.css';

const LocationFormModalComponent = props => {
  const { displayForm, formTitle, selectedRecord, setSelectedRecord } = props;

  return (
    <Modal
      title={formTitle}
      visible={displayForm}
      footer={null}
      closable={false}
      className={'location-modal'}
    >
      <LocationFormComponentContainer
        selectedRecord={selectedRecord}
        setSelectedRecord={setSelectedRecord}
        {...props}
      />
    </Modal>
  );
};

export default LocationFormModalComponent;
