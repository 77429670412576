import React from 'react';
import 'antd/dist/antd.css';
import {
  CategoryLabel,
  SearchCategory,
  SearchContainer
} from '../SearchPage.style';
import { CompanyResultList } from '../../../Containers';
import { DisplayLoading } from '../../../Components';

const CompanyScreenComponent = props => {
  const { searchCategory, companyList, isFetching } = props;

  const companyMapped = companyList.map((company, id) => {
    return <CompanyResultList key={id} {...props} company={company} />;
  });
  return (
    <SearchContainer id={'company-list'}>
      <SearchCategory>
        <CategoryLabel>{searchCategory}</CategoryLabel>
      </SearchCategory>
      {companyMapped}
      {isFetching && <DisplayLoading />}
    </SearchContainer>
  );
};

export default CompanyScreenComponent;
