import React, { useState, useEffect } from 'react';
import { TrendingTopic as TrendingTopicComponent } from '../../Components';
// import axios from 'axios';
// import { TrendingTopicServices } from '../../Services/BaseService';

// const url = 'https://wlb.co.id/wp-json/wlb/v1/posts/';

const Index = () => {
  const [contents /* setContents */] = useState([]);
  const [isFetchingSuccess /* setIsFetchingSuccess */] = useState(false);

  useEffect(() => {
    // TrendingTopicServices.get().then(res => {
    //   // console.log(res);
    //   setContents(res?.data);
    //   setIsFetchingSuccess(true);
    // });
  }, []);

  if (isFetchingSuccess) {
    return <TrendingTopicComponent contents={contents} />;
  } else return <div />;
};

export default Index;
