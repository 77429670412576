import styled from 'styled-components';

const JobDetails = styled.label`
  font-size: 12px;
  color: grey;
`;

const Company = styled.label`
  font-size: 12px;
  color: grey;
  padding-top: 2px;
  width: 100%;
`;

const Description = styled.div`
  font-size: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: -10px;
`;

const UserCard = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 100px;
  background-color: #fff;

  .anticon {
    padding-left: 20px;
    color: grey;
  }
  .user-card-meta {
    width: 280px;
  }
  .ant-card-bordered {
    border: none;
  }

  .social-media-wrapper {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    height: 20px;
  }

  .social-media {
    padding-left: 60px;
  }
`;

export { Description, JobDetails, Company, UserCard };
