import styled from 'styled-components';
import Constants from '../../../Config/Constants';

const JobDetails = styled.label`
  font-size: 12px;
  color: ${Constants.COLORS.GREY};
`;

const Company = styled.label`
  font-size: 12px;
  color: ${Constants.COLORS.GREY};
  padding-top: 2px;
  width: 100%;
`;

const Description = styled.div`
  font-size: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-top: -10px;
  overflow: hidden;
`;

const UserCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  margin: 5px 0px;
  padding: 5px 0;

  .anticon {
    padding-right: 30px;
    color: ${Constants.COLORS.GREY};
    font-size: 15px;
  }
  .user-card-meta {
    width: 300px;
  }
  .ant-card-bordered {
    border: none;
  }

  .closed-icon {
    color: ${Constants.COLORS.BLACK};
    font-size: 13px;
    position: absolute;
    top: 4%;
    right: 0;
    cursor: pointer;
    z-index: 2;
  }

  .social-media-wrapper {
    border-top: 1px solid #e8e8e8;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    /* height: 30px; */
    padding: 5px 100px;
    border-radius: 0 0 5px 5px;
  }
`;

export { Description, JobDetails, Company, UserCard };
