import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import baseUrl from '../../Config/baseUrl';

import { isObjectEmpty } from '../../Utils/CommonUtils';

const createGroupService = async payload => {
  const userId = AuthenticationService.getUserId();
  const createGroupUrl = baseUrl.GROUPS(userId);

  return await BaseService.post(createGroupUrl, payload);
};

const getListGroup = (page = 1, name = '', maxResults = 50) => {
  let parameters = {
    params: {
      name: name,
      page: page,
      maxResults: maxResults
    }
  };
  if (isObjectEmpty(name)) {
    delete parameters.params.name;
  }
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.GROUPS(userId);

  return BaseService.get(url, parameters);
};

const getListGroupGlobal = (page = 1, name = '', maxResults = 30) => {
  let parameters = {
    params: {
      query: name,
      page: page,
      maxResults: maxResults,
      type : 'teams'
    }
  };
  
  
  if (isObjectEmpty(name)) {
    delete parameters.params.name;
  }
  const userId = AuthenticationService.getUserId();
  // const url = baseUrl.GROUPS_GLOBAL(userId);

  const url = baseUrl.SEARCH;

  return BaseService.get(url, parameters);
};

const deleteGroup = groupId => {
  const userId = AuthenticationService.getUserId();
  const deleteGroupUrl = baseUrl.GROUPS_ID(userId, groupId);
  return BaseService.delete(deleteGroupUrl);
};

const updateIntroGroup = (groupId, payload) => {
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.GROUPS_ID(userId, groupId);
  return BaseService.put(url, payload);
};

export default {
  createGroupService,
  getListGroup,
  getListGroupGlobal,
  deleteGroup,
  updateIntroGroup
};
