import gql from 'graphql-tag';

export const GET_LIST_RELATIONSHIP = gql`
  query {
    people_dependant_relationships(
      where: { deletedAt: { _is_null: true } }
      order_by: [{ id: asc }]
    ) {
      id
      name
    }
  }
`;

export const UPDATE_DEPENDENT = gql`
  mutation(
    $id: Int!
    $name: String!
    $relationship: String!
    $birthdate: date!
    $birthplace: String!
    $identity_number: String
    $marital_status: String
    $working_status: String
    $gender: bpchar!
    $is_beneficiary: Boolean!
    $dependent_status: String
    $civil_servant: Boolean!
    $is_company_group_employee: Boolean!
    $company: String
    $dependant_fields: jsonb
    $blood_type: String!
    $country: Int!
    $province: Int!
    $city: Int!
    $postal_code: String!
    $address: String!
    $district: String
    $subDistrict: String
  ) {
    update_people_dependants(
      _set: {
        name: $name
        relationship: $relationship
        birthdate: $birthdate
        birthplace: $birthplace
        identity_number: $identity_number
        marital_status: $marital_status
        working_status: $working_status
        gender: $gender
        is_beneficiary: $is_beneficiary
        dependent_status: $dependent_status
        civil_servant: $civil_servant
        is_company_group_employee: $is_company_group_employee
        company: $company
        dependant_fields: $dependant_fields
        blood_type: $blood_type
        country: $country
        province: $province
        city: $city
        postal_code: $postal_code
        address: $address
        district: $district
        sub_district: $subDistrict
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const ADD_DEPENDENT = gql`
  mutation(
    $name: String!
    $relationship: String!
    $birthdate: date!
    $identity_number: String
    $marital_status: String
    $working_status: String
    $gender: bpchar!
    $is_beneficiary: Boolean!
    $birthplace: String!
    $dependent_status: String
    $civil_servant: Boolean!
    $is_company_group_employee: Boolean!
    $company: String
    $dependant_fields: jsonb
    $blood_type: String!
    $country: Int!
    $province: Int!
    $city: Int!
    $postal_code: String!
    $address: String!
    $district: String
    $subDistrict: String
  ) {
    insert_people_dependants(
      objects: {
        name: $name
        gender: $gender
        relationship: $relationship
        is_beneficiary: $is_beneficiary
        birthplace: $birthplace
        birthdate: $birthdate
        dependent_status: $dependent_status
        identity_number: $identity_number
        marital_status: $marital_status
        working_status: $working_status
        civil_servant: $civil_servant
        is_company_group_employee: $is_company_group_employee
        company: $company
        dependant_fields: $dependant_fields
        blood_type: $blood_type
        country: $country
        province: $province
        city: $city
        postal_code: $postal_code
        address: $address
        district: $district
        sub_district: $subDistrict
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_DEPENDENT = gql`
  mutation($id: Int!) {
    update_people_dependants(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const GET_LIST_PHONECODE = gql`
  {
    global_phone_code(order_by: [{ country: asc }]) {
      country
      code
    }
  }
`;
export const GET_LIST_CITY = gql`
  query($id: Int) {
    global_cities(
      where: { province: { _eq: $id } }
      order_by: { name: asc }
      limit: 50
    ) {
      id
      code
      name
    }
  }
`;
export const GET_LIST_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;
