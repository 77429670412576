import React from 'react';
import { Col, Row } from 'antd';
import { HCEcosystem, CenterLayout } from '../../Components';
import { VendorListBox, SidebarProfile } from '../../Containers';
import { AuthenticationService } from '../../Services';
import {
  BoxShadow,
  VendorListBoxWrap
} from '../../Containers/VendorListBox/VendorListBox.style';

const VendorListComponent = props => {
  const role = AuthenticationService.getUserData().roles;
  return (
    <CenterLayout>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarProfile />
          <HCEcosystem />
        </Col>
        <Col id={'vendor-list'} span={18}>
          {role.includes('organization-administrator') ? (
            <VendorListBox {...props} />
          ) : (
            <VendorListBoxWrap>No Access</VendorListBoxWrap>
          )}
        </Col>
      </Row>
    </CenterLayout>
  );
};

export default VendorListComponent;
