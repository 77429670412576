import React from 'react';
import { PrintInvoiceWrap } from './PrintInvoice.style';
import { VendorPrintInvoice } from '../../../Components';
import DateTime from '../../../Utils/dateTime';
const PrintInvoiceComponent = props => {
  const {
    dataOrderDetail
    // detailLoading
  } = props;

  const {
    invoice_to,
    date_added,
    code,
    delivery_data,
    global_invoice_items
  } = dataOrderDetail.invoice;

  const date = DateTime.americanDateFormat(date_added);
  const items = global_invoice_items;

  return (
    <PrintInvoiceWrap>
      <div>
        <VendorPrintInvoice.Header />
        <VendorPrintInvoice.Content
          purpose={invoice_to}
          address={delivery_data.address}
          date={date}
          invoiceCode={code}
          logisticsBrand={'JNE-REGULAR'}
          items={items}
        />
        <VendorPrintInvoice.Footer />
      </div>
    </PrintInvoiceWrap>
  );
};

export default PrintInvoiceComponent;
