import React from 'react';
import { FormProduct } from '../../../Containers';
import { CenterLayout } from '../../../Components';
import { Enhancer } from '../../../SharedComponents';

const FormProductContainer = props => {
  return (
    <CenterLayout>
      <FormProduct {...props} />
    </CenterLayout>
  );
};

// export default FormProductContainer;
export default Enhancer({})(FormProductContainer);
