import { gql } from 'apollo-boost';

export const GET_CLASSROOM_BY_ID = gql`
  query($classroomId: uuid!, $userId: uuid) {
    classrooms_by_pk(id: $classroomId) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      academy_course_category {
        id
        code
        name
      }
      classroom_members(where: { global_user: { id: { _eq: $userId } } }) {
        global_user {
          id
          name
        }
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`;

export const CHECK_IS_JOINED = gql`
  query($classroomId: uuid!, $userId: uuid) {
    classrooms_by_pk(id: $classroomId) {
      id
      classroom_members(where: { global_user: { id: { _eq: $userId } } }) {
        global_user {
          id
          name
        }
      }
    }
  }
`;
