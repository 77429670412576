import React, { useState, useEffect } from 'react';
import VendorInformationComponent from './VendorInformation.component';
import { useMutation } from 'react-apollo';
import { Form, message } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { UploadService } from '../../../Services';
import { UPDATE_INFORMATION } from './VendorInformation.graphql';

const VendorInformationContainerWrapper = props => {
  const { vendor } = props;
  const [btnOpenActive, setBtnOpenActive] = useState(false);
  const [btnClosedActive, setBtnClosedActive] = useState(false);
  const [btnScheduledActive, setBtnScheduledActive] = useState(false);
  const [schedule, setSchedule] = useState([]);
  useEffect(() => {
    if (!isEmpty(vendor.profile_field.schedule)) {
      setSchedule(vendor.profile_field.schedule);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
  };
  const [updateInformation] = useMutation(UPDATE_INFORMATION, OPTIONS);
  const initData = {
    images: [],
    cover: []
  };
  const [dataImage, setDataImage] = useState(initData);
  const [urlImage, setUrlImage] = useState([]);
  const days = [
    { monday: 'Monday' },
    { tuesday: 'Tuesday' },
    { wednesday: 'Wednesday' },
    { thursday: 'Thursday' },
    { friday: 'Friday' },
    { saturday: 'Saturday' },
    { sunday: 'Sunday' }
  ];

  const _setBtnOpenActive = () => {
    setBtnOpenActive(!btnOpenActive);
    setBtnClosedActive(false);
    setBtnScheduledActive(false);
  };

  const _setBtnClosedActive = () => {
    setBtnClosedActive(!btnClosedActive);
    setBtnOpenActive(false);
    setBtnScheduledActive(false);
  };

  const _setBtnScheduledActive = () => {
    setBtnScheduledActive(!btnScheduledActive);
    setBtnOpenActive(false);
    setBtnClosedActive(false);
  };

  const shopStatus = () => {
    if (btnOpenActive) {
      return 'OPEN';
    } else if (btnClosedActive) {
      return 'CLOSED';
    } else if (btnScheduledActive) {
      return 'SCHEDULED';
    } else {
      return 'UNKNOWN';
    }
  };

  const time = value => {
    return moment(value).format('HH.mm');
  };
  const handleSubmit = event => {
    event.preventDefault();
    props.form.validateFields((error, values) => {
      const dataForm = {
        brand_name: values.brand_name,
        logo: !isEmpty(urlImage.images) ? urlImage.images[0] : vendor.logo,
        profile_field: {
          headline: values.headline,
          description: values.description,
          shop_status: shopStatus(),
          schedule: {
            monday: isEmpty(schedule['monday'])
              ? {}
              : {
                  from: time(values.Monday_from),
                  to: time(values.Monday_to)
                },
            tuesday: isEmpty(schedule['tuesday'])
              ? {}
              : {
                  from: time(values.Tuesday_from),
                  to: time(values.Tuesday_to)
                },
            wednesday: isEmpty(schedule['wednesday'])
              ? {}
              : {
                  from: time(values.Wednesday_from),
                  to: time(values.Wednesday_to)
                },
            thursday: isEmpty(schedule['thursday'])
              ? {}
              : {
                  from: time(values.Thursday_from),
                  to: time(values.Thursday_to)
                },
            friday: isEmpty(schedule['friday'])
              ? {}
              : {
                  from: time(values.Friday_from),
                  to: time(values.Friday_to)
                },
            saturday: isEmpty(schedule['saturday'])
              ? {}
              : {
                  from: time(values.Saturday_from),
                  to: time(values.Saturday_to)
                },
            sunday: isEmpty(schedule['sunday'])
              ? {}
              : {
                  from: time(values.Sunday_from),
                  to: time(values.Sunday_to)
                }
          },
          banner: !isEmpty(urlImage.cover)
            ? urlImage.cover[0]
            : vendor.profile_field.banner
        },
        id: vendor.id
      };
      updateInformation({
        variables: dataForm
      }).then(() => {
        props.history.go('/vendor/' + props.match.params.id);
        message.success('Vendor Information updated !');
      });
    });
  };

  const handleUploadImage = async ({ onSuccess, file }) => {
    const { url } = await UploadService.uploadFile(file);
    onSuccess(url, file);
  };

  const handleChangeImage = (target, value) => {
    setDataImage({
      ...dataImage,
      [target]: value
    });
    let newDataImage = {
      images: [],
      cover: []
    };

    dataImage.images.forEach(image => {
      newDataImage.images.push(image.response);
    });
    dataImage.cover.forEach(cover => {
      newDataImage.cover.push(cover.response);
    });

    setUrlImage(newDataImage);
  };

  const handleChangeSchedule = checkedValues => {
    Object.keys(schedule).forEach(sch => {
      if (!checkedValues.includes(sch)) {
        setSchedule(currentSch => ({ ...currentSch, [sch]: {} }));
      } else {
        setSchedule(currentSch => ({
          ...currentSch,
          [sch]: `${sch}`
        }));
      }
    });
  };

  useEffect(() => {
    if (vendor.profile_field.shop_status === 'OPEN') {
      setBtnOpenActive(true);
    } else if (vendor.profile_field.shop_status === 'CLOSED') {
      setBtnClosedActive(true);
    } else if (vendor.profile_field.shop_status === 'SCHEDULED') {
      setBtnScheduledActive(true);
    }
  }, [vendor]);
  return (
    <VendorInformationComponent
      btnOpenActive={btnOpenActive}
      setBtnOpenActive={_setBtnOpenActive}
      btnClosedActive={btnClosedActive}
      setBtnClosedActive={_setBtnClosedActive}
      btnScheduledActive={btnScheduledActive}
      setBtnScheduledActive={_setBtnScheduledActive}
      vendor={vendor}
      handleSubmit={handleSubmit}
      days={days}
      handleChangeImage={handleChangeImage}
      handleUploadImage={handleUploadImage}
      dataImage={dataImage}
      schedule={schedule}
      handleChangeSchedule={handleChangeSchedule}
      {...props}
    />
  );
};
const VendorInformationContainer = Form.create({
  name: 'VendorInformationForm'
})(VendorInformationContainerWrapper);
export default VendorInformationContainer;
