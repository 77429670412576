import gql from "graphql-tag";

export const GET_LIST_COUNT_EMOT = gql`
  query getCountEmot($user: uuid!) {
    satisfied_all: ongoing_feedback_lists_aggregate(
      where: { ongoing_to: { _eq: $user }, summary: { _eq: "SATISFIED" } }
    ) {
      aggregate {
        count
      }
    }
    ordinary_all: ongoing_feedback_lists_aggregate(
      where: { ongoing_to: { _eq: $user }, summary: { _eq: "ORDINARY" } }
    ) {
      aggregate {
        count
      }
    }
    disappointed_all: ongoing_feedback_lists_aggregate(
      where: { ongoing_to: { _eq: $user }, summary: { _eq: "DISAPPOINTED" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
