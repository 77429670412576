import styled from 'styled-components';
import Assets from '../../Assets';

export const VendorShippingWrap = styled.div`
  width: 100%;
  height: 250px;
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
  border: 1px solid ${Assets.COLOR_PALLETE.grey};
  margin-bottom: 24px;
  .ant-table-thead {
    th:nth-child(2) {
      padding-left: 15%;
    }
  }
  .ant-table-tbody > tr.ant-table-row-selected td {
    color: inherit;
    background: ${Assets.COLOR_PALLETE.white};
  }
  img {
    width: 68px;
  }
`;

export const Footer = styled.div`
  position: relative;
  text-align: right;
  margin-top: 50px;
  padding: 15px 24px;

  .ant-checkbox-wrapper {
    width: 100%;
  }
`;
