import React from 'react';
import Table from 'antd/es/table';
import { TableWrapper } from './VendorLocationTable.style';
import LocationFormModalContainer from '../AddLocationFormModal';
import { DeleteModal, Pagination } from '..';
import { TopSettingGroup, ButtonWlb, DisplayLoading } from '../';

const VendorLocationTableComponent = props => {
  const {
    columns,
    dataSource,
    selectedRecord,
    displayDeleteModal,
    onCloseModal,
    displayForm,
    setDisplayForm,
    formTitle,
    setFormTitle,
    handleDeleteLocation,
    setRefetch,
    queryLocation,
    setSelectedRecord,
    limit,
    offset,
    handleLimit,
    prevLocation,
    nextLocation,
    totalLocation
  } = props;

  const handleAddLocation = () => {
    setFormTitle('Add Location');
    setDisplayForm(true);
  };

  const _buttonTop = [
    <ButtonWlb key="1" type="primary" onClick={() => handleAddLocation()}>
      Add Location
    </ButtonWlb>
  ];
  return (
    <TableWrapper>
      <TopSettingGroup title={'Location'} buttonTop={_buttonTop} />
      {!queryLocation.loading && dataSource ? (
        <Table
          rowKey={record => record.id}
          pagination={false}
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 'max-content' }}
        />
      ) : (
        <DisplayLoading />
      )}
      <DeleteModal
        closeModal={() => onCloseModal(false)}
        displayModal={displayDeleteModal}
        modalTitle={formTitle}
        modalContent={`Are you sure delete ${selectedRecord.label_name} ?`}
        handleDelete={handleDeleteLocation}
      />
      <LocationFormModalContainer
        formTitle={formTitle}
        displayForm={displayForm}
        setDisplayForm={setDisplayForm}
        selectedRecord={selectedRecord}
        setRefetch={setRefetch}
        queryLocation={queryLocation}
        setSelectedRecord={setSelectedRecord}
      />
      {
        <div className="pagination">
          <Pagination
            offset={offset}
            limit={limit}
            // total={totalProduct}
            prevClick={() => prevLocation()}
            nextClick={() => nextLocation()}
            onChangeLimit={e => handleLimit(e.target.value)}
            total={totalLocation}
          />
        </div>
      }
    </TableWrapper>
  );
};

export default VendorLocationTableComponent;
