import { gql } from 'apollo-boost';

export const CONTRIBUTOR_LIST = gql`
  query(
    $offset: Int
    $limit: Int
    $contributorLabel: String
    $searchQuery: String
    $findNullContributor: Boolean
  ) {
    global_users(
      offset: $offset
      limit: $limit
      where: {
        name: { _ilike: $searchQuery }
        contributor_label: {
          _eq: $contributorLabel
          _is_null: $findNullContributor
        }
      }
    ) {
      id
      name
      avatar
      contributor_label
      people_work_placements {
        company_job_profile {
          id
          position
          title
        }
        company_profile {
          id
          brand_name
          legal_name
        }
      }
    }
  }
`;
