import React from 'react';
import { WrapperVideo } from './PostVideo.style';

const PostVideoComponent = ({ videos }) => {
  // const [videoState, setVideoState] = useState([]);
  // const countVideo = videos.length;

  // const videoMetaData = [];

  // useEffect(() => {
  //   videos.map((item, index) => {
  //     return UploadService.getVideo(item).then(function(response) {
  //       videoMetaData.push(response);
  //       if (videoState.length < 1) {
  //         setVideoState(videoMetaData);
  //       }
  //       return null;
  //     });
  //   });
  // });

  // const _video = () => {
  //   const content = [];
  //   if (videoState.length !== 0) {
  //     for (let index = 0; index < videoState.length; index++) {
  //       if (videoState[index] !== undefined) {
  //         content.push(
  //           <div>
  //             <ReactJWPlayer
  //               playerId="1gQfwd2J"
  //               playerScript="https://cdn.jwplayer.com/libraries/1gQfwd2J.js"
  //               playlist={videoState[index].url.playlist[0].sources}
  //             />
  //           </div>
  //         );
  //       }
  //     }
  //   }
  //   return content;
  // };

  return (
    <div>
      <WrapperVideo>
        {/* <div className={countVideo > 5 ? 'show-more' : `show-` + countVideo}>
          {_video()}
        </div> */}
      </WrapperVideo>
    </div>
  );
};

export default PostVideoComponent;
