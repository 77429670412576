import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

import Mentorship from './Mentorship.component';
import { LIST_OF_MEMBERS } from './listOfMembers.graphql';

const MentorshipContainer = props => {
  const params = useParams();
  const classroomId = params.id;
  const [members, setMembers] = useState([]);

  const { data: listOfMembers, errors } = useQuery(LIST_OF_MEMBERS, {
    variables: { classroom_id: { _eq: classroomId } }
  });

  useEffect(() => {
    if (listOfMembers) {
      setMembers(listOfMembers.classroom_members);
    }
    if (errors && errors.length > 0) {
      message.error('Something went wrong when load Member Data');
    }
  }, [listOfMembers, errors]);

  return <Mentorship members={members} {...props} />;
};

export default MentorshipContainer;
