import React from 'react';

import { WrapChipsSection } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { formatDate } from '../../../Components/DetailUserSection/helperForDetail';

function Service({ data, i, count }) {
  const key = data.id;
  const noBorder = count.length === i + 1 || false;
  const code = data.code || '-';
  const start_date = formatDate(data.start_date) || '-';
  const end_date = formatDate(data.end_date) || '-';
  const description = data.description || '-';

  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <WrapChipsSection key={key} fullchips noBorder={noBorder}>
      <div className="chips-wrap">
        <div className="chips">
          <h4 className="chips-name">{code}</h4>
        </div>
      </div>
      <div className="content">
        <div className="divider">
          <div>
            <p className="color-primary font-14 mb-regular">
              {start_date} - {end_date}
            </p>
            <p className="font-12 color-black pre-line">
              {renderValue(description, '-')}
            </p>
          </div>
        </div>
      </div>
    </WrapChipsSection>
  );
}

export default Service;
