import styled from 'styled-components';

const VendorPage = styled.div`
  .rowContainer {
    margin-top: 30px;
  }
  .colContent {
    padding-left: 30px;
  }
`;
export { VendorPage };
