import { Form, Input, message, Radio, Select } from 'antd';
import React, { useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { ModalEdit } from '../../Components';
import { AuthenticationService } from '../../Services';
import { ButtonFrom, FormItem, TitleField } from '../../SharedComponents';
import { LIST_BLOOD_TYPE } from './constantMedical';
import {
  ADD_MEDICAL_RECORD,
  EDIT_MEDICAL_RECORD
} from './UserMedicalRecord.graphql';

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};
const ToggleComponent = ({ label, children, onChange, value }) => {
  return (
    <FormItem
      style={{ padding: '0px 22px', marginBottom: 0 }}
      label={<TitleField>{label}</TitleField>}
    >
      <Radio.Group onChange={onChange} value={value}>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
      {value && { ...children }}
    </FormItem>
  );
};

const AddEditMedicalRecordComponent = ({
  actionType,
  open,
  onClose,
  form,
  setOpen,
  data,
  refetch,
  initialActive,
  active,
  setActive
}) => {
  const {
    getFieldDecorator,
    // setFieldsValue,
    validateFields,
    resetFields
    // getFieldValue,
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;

  const [editMedicalRecord] = useMutation(EDIT_MEDICAL_RECORD);
  const [addMedicalRecord] = useMutation(ADD_MEDICAL_RECORD);

  const handleClose = () => {
    onClose();
    resetFields();
    setActive(initialActive);
  };

  useEffect(() => {
    if (data) {
      setActive(e => ({
        ...e,
        medical_test_aware_of: data.additional_medical_conditions
          ? true
          : false,
        has_disability: data.disabilities ? true : false
      }));
    }
  }, [data, setActive]);

  const handleDelete = () => {
    setOpen(e => ({ ...e, modalDelete: true, open: false }));
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'add') {
          addMedicalRecord({
            variables: {
              // userId: AuthenticationService.getUserId(),
              name: values.medical_test_name,
              reason: values.medical_test_reason,
              medicalConditions: active.medical_test_aware_of
                ? values.medical_test_aware_of
                : null,
              disabilities: active.has_disability
                ? values.has_disability
                : null,
              hospitalizedReason: values.reason_being_hospitalized,
              bloodType: values.bloodtype,
              bloodRhesus: values.rhesus,
              bodyHeight: `${values.body_height} cm`,
              bodyWeight: `${values.body_weight} kg`,
              headSize: values.head_size ? `${values.head_size} cm` : null,
              clothesSize: values.clothes_size,
              pantsSize: values.pants_size
            }
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil manambahkan profil medis');
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan profil medis, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          editMedicalRecord({
            variables: {
              id: data.id,
              userId: AuthenticationService.getUserId(),
              name: values.medical_test_name,
              reason: values.medical_test_reason,
              medicalConditions: active.medical_test_aware_of
                ? values.medical_test_aware_of
                : null,
              disabilities: active.has_disability
                ? values.has_disability
                : null,
              hospitalizedReason: values.reason_being_hospitalized,
              bloodType: values.bloodtype,
              bloodRhesus: values.rhesus,
              bodyHeight: `${values.body_height} cm`,
              bodyWeight: `${values.body_weight} kg`,
              headSize: values.head_size ? `${values.head_size} cm` : null,
              clothesSize: values.clothes_size,
              pantsSize: values.pants_size
            }
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil mengubah profil medis');
            })
            .catch(err => {
              message.error(
                `Gagal mengubah profil medis, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  function handleChange() {
    // event.preventDefault();
    // if(fieldName === 'medical_test_aware_of' || fieldName === "has_disability") {
    //   if(e.target.value === '' && actionType === 'edit') {
    //     setActive({
    //       ...active,
    //       [fieldName]: false
    //     })
    //   }
    // }
  }

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder, style } = res;
    if (type === 'select') {
      return (
        <Select
          size="large"
          style={{ width: '100%' }}
          onChange={value => handleChange(value, fieldName)}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              const dataTestId = `${fieldName}-${res.name}`;
              return (
                <Option key={i} value={res.id} data-testId={dataTestId}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={6}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const rules = (required, pattern) => [
    {
      pattern,
      message: 'Tidak dapat menggunakan karakter khusus'
    },
    {
      required,
      message: 'Bagian ini diperlukan'
    }
  ];
  const listFields = [
    {
      labelName: 'Nama Tes Medis*',
      fieldName: 'medical_test_name',
      type: 'text',
      fieldValue: data?.medical_test_name,
      placeholder: 'Tambahkan nama tes medis',
      isRequired: true,
      rules: rules(() => this.isRequired, /^[A-Za-z0-9 ]*$/)
    },
    {
      labelName: 'Alasan Tes Medis*',
      fieldName: 'medical_test_reason',
      type: 'textArea',
      fieldValue: data?.medical_test_reason,
      placeholder: 'Tambahkan alasan tes medis',
      isRequired: true,
      rules: rules(() => this.isRequired, /^[A-Za-z0-9 .,/]*$/)
    }
  ];
  const listFields2 = [
    {
      labelName:
        'Apakan anda mempunyai kondisi medis yang perlu diperhatikan?*',
      fieldName: 'medical_test_aware_of',
      type: 'textArea',
      fieldValue: data?.additional_medical_conditions,
      placeholder: 'Tambahkan kondisi medis yang perlu diperhatikan',
      isRequired: true,
      rules: rules(active.medical_test_aware_of, /^[A-Za-z0-9 .,/]*$/)
    },
    {
      labelName: 'Punya Disabilitas?*',
      fieldName: 'has_disability',
      type: 'textArea',
      fieldValue: data?.disabilities,
      placeholder: 'Tambahkan info disabilitas',
      isRequired: true,
      rules: rules(active.has_disability, /^[A-Za-z0-9 .,/]*$/)
    }
  ];
  const listFields3 = [
    {
      labelName: 'Penyebab Masuk Rumah Sakit',
      fieldName: 'reason_being_hospitalized',
      type: 'textArea',
      fieldValue: data?.hospitalized_reason,
      placeholder: 'Tambahkan penyebab masuk rumah sakit',
      isRequired: false,
      rules: rules(false, /^[A-Za-z0-9 .,/]*$/)
    },
    {
      labelName: 'Golongan Darah*',
      fieldName: 'bloodtype',
      type: 'select',
      fieldValue: data?.blood_type,
      placeholder: 'Pilih golongan darah',
      isRequired: true,
      option: LIST_BLOOD_TYPE
    },
    {
      labelName: 'Resus*',
      fieldName: 'rhesus',
      type: 'select',
      fieldValue: data?.blood_rhesus,
      placeholder: 'Pilih Resus',
      isRequired: true,
      option: [
        {
          id: 'Positif',
          name: 'Positif'
        },
        {
          id: 'Negatif',
          name: 'Negatif'
        }
      ]
    },
    {
      labelName: 'Tinggi Badan*',
      fieldName: 'body_height',
      type: 'number',
      fieldValue:
        data && data.body_height
          ? parseInt(data.body_height.split(' ')[0])
          : undefined,
      placeholder: 'Tambahkan tinggi badan',
      isRequired: true,
      style: { width: '40%' },
      footer: 'cm',
      rules: rules(true, /^[0-9]*$/)
    },
    {
      labelName: 'Berat Badan*',
      fieldName: 'body_weight',
      type: 'number',
      fieldValue:
        data && data.body_weight
          ? parseInt(data.body_weight.split(' ')[0])
          : undefined,
      placeholder: 'Tambahkan berat badan',
      isRequired: true,
      style: { width: '40%' },
      footer: 'kg',
      rules: rules(true, /^[0-9]*$/)
    },
    {
      labelName: 'Ukuran Kepala',
      fieldName: 'head_size',
      type: 'number',
      fieldValue:
        data && data.head_size
          ? parseInt(data.head_size.split(' ')[0])
          : undefined,
      placeholder: 'Tambahkan ukuran kepala',
      isRequired: false,
      style: { width: '40%' },
      footer: 'cm',
      rules: rules(false, /^[0-9]*$/)
    },
    {
      labelName: 'Ukuran Baju',
      fieldName: 'clothes_size',
      type: 'text',
      fieldValue: data?.clothes_size,
      placeholder: 'Tambahkan ukuran baju',
      isRequired: false,
      style: { width: '40%' },
      rules: rules(false, /^[A-Za-z0-9 ]*$/)
    },
    {
      labelName: 'Ukuran Celana',
      fieldName: 'pants_size',
      type: 'text',
      fieldValue: data?.pants_size,
      placeholder: 'Tambahkan ukuran celana',
      style: { width: '40%' },
      isRequired: false,
      rules: rules(false, /^[A-Za-z0-9 ]*$/)
    }
  ];

  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Profil Medis`}
      open={open}
      onClose={handleClose}
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <FormItem
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: res.rules ?? rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </FormItem>
            );
          })}
        {listFields2.map((res, i) => {
          return (
            <ToggleComponent
              key={`${i}-${res.fieldName}`}
              label={res.labelName}
              value={active[res.fieldName]}
              onChange={e =>
                setActive({ ...active, [res.fieldName]: e.target.value })
              }
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules:
                  res.rules ??
                  rule(active[res.fieldName] ? res.isRequired : false)
              })(_switchComponentToRender(res))}
            </ToggleComponent>
          );
        })}
        {listFields3.length > 0 &&
          listFields3.map((res, i) => {
            // const dataTestId = `$${res.fieldName}-${res.fieldValue}`;
            return (
              <FormItem
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: res.rules ?? rule(res.isRequired)
                })(_switchComponentToRender(res))}
                {res.footer && (
                  <span style={{ marginLeft: 20 }} className="ant-form-text">
                    {' '}
                    {res.footer}
                  </span>
                )}
              </FormItem>
            );
          })}
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditMedicalRecord = Form.create({ name: 'Add_Edit_Medical_Record' })(
  AddEditMedicalRecordComponent
);

export default AddEditMedicalRecord;
