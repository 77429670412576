import React from 'react';
import { VendorListWrap } from './VendorList.style';
import { Link } from 'react-router-dom';
import { DisplayLoading } from '..';
import { AuthenticationService } from '../../Services';

const VendorListComponent = ({
  dataListVendor,
  isLoadingListVendor,
  errorVendor
}) => {
  const userData = AuthenticationService.getUserData();
  return isLoadingListVendor ? (
    <DisplayLoading />
  ) : (
    !errorVendor && dataListVendor && (
      <div>
        <Link to={'/vendor/' + dataListVendor.id}>
          <VendorListWrap>
            <div className="photo">
              <img
                src={(userData?.thumbnail_url ?? '') + dataListVendor.logo}
                alt="data list vendor"
              />
            </div>
            <div className="info">
              <p>{dataListVendor.brand_name}</p>
              <p>{dataListVendor.legal_name}</p>
            </div>
            <div className="groupBtn"></div>
          </VendorListWrap>
        </Link>
      </div>
    )
  );
};

export default VendorListComponent;
