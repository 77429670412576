import React, { memo } from 'react';
import { Icon } from 'antd';
import { WrapperItemDependent, ItemWrapper } from './UserDependent.style';

const ItemField = ({ icon, value }) => {
  return (
    <ItemWrapper>
      <Icon theme="filled" type={icon} className="icon" />
      <p style={{ textTransform: 'capitalize' }} className="value">
        {value === '' ? '-' : value}
      </p>
    </ItemWrapper>
  );
};
export default memo(function UserDependentItem({
  onEdit,
  isUser,
  data,
  nameUser,
  childDependentAge,
  birthdate,
  onClickDetail
}) {
  const { name, relationship, address, is_beneficiary } = data;

  const handleClickDetail = () => {
    onClickDetail({
      ...data,
      childDependentAge,
      isUser
    });
  };

  return (
    <WrapperItemDependent>
      <div className="header">
        <div className="title">
          <h4>{name}</h4>
          <span>{relationship}</span>
        </div>
        {isUser && <Icon type="edit" onClick={onEdit} className="icon" />}
      </div>
      <div className="content">
        <ItemField icon="environment" field="Address" value={address ?? '-'} />
        <ItemField
          icon="calendar"
          field="Date of Birth"
          value={birthdate || '-'}
        />
      </div>
      {is_beneficiary && (
        <h3 className="is-beneficiary">{'Penerima dari ' + nameUser}</h3>
      )}
      <p onClick={handleClickDetail} className="show-detail">
        Tampilkan Detail Tanggungan
      </p>
    </WrapperItemDependent>
  );
});
