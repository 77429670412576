import { UPDATE_KTP, UPDATE_NPWP } from './UserIdentityCard.graphql';

export const updatePws = ({ client, dataPws, formData }) => {
  const { id, ktp, npwp } = dataPws?.people_work_salaries?.[0] || {};
  const { type, id: id_number } = formData;

  const isKTP = type === 'KTP';

  let isEditingField = false;

  if (isKTP) {
    isEditingField = id_number !== ktp;
  } else {
    isEditingField = id_number !== npwp;
  }

  if (isEditingField) {
    client.mutate({
      mutation: isKTP ? UPDATE_KTP : UPDATE_NPWP,
      variables: {
        id,
        id_number
      }
    });
  }
};
