import React, { useState } from 'react';
import CreateGroupBoxComponent from './CreateGroupBox.component';
import { ButtonWlb } from '../../Components';
import { cloneDeep, isEmpty } from 'lodash';
import { Form, message } from 'antd';
import { Group } from '../../Services/';
import { useHistory } from 'react-router-dom';
const CreateGroupBoxWrapper = props => {
  /* TODO
		- Avatar need to be updated using actual image
		- add field to input image.
	 */
  const initialGroup = {
    name: '',
    description: '',
    privacy: null,
    avatar:
      'https://storage.googleapis.com/wlb-dev/1571303139820_15b2f070-a59c-4d61-8086-15d244915a2e.jpeg',
    member: []
  };
  const { form } = props;
  const { validateFields } = form;

  const [newGroup, setNewGroup] = useState(initialGroup);
  const [loading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;
  const title = 'Buat Tim Kerja';
  const history = useHistory();
  const _handleAttributeChange = (attributeName, value) => {
    // newGroup[attributeName] = value;
    let clonedGroup = cloneDeep(newGroup);
    clonedGroup[attributeName] = value;
    setNewGroup(clonedGroup);
  };

  const _handleSubmitGroup = () => {
    validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        // const response = await Group.GroupGlobal.createGroupService(newGroup);
        // if (!isEmpty(response)) {
        //   message.success('Group successfully created');
        //   form.resetFields();
        //   setNewGroup(initialGroup);
        //   setLoading(false);
        // }
        Group.GroupGlobal.createGroupService(newGroup)
          .then(res => {
            if (!isEmpty(res)) {
              message.success('Group successfully created');
              history.push('/group');
            }
          })
          .catch(() => message.error('Something went wrong'))
          .finally(() => {
            form.resetFields();
            setNewGroup(initialGroup);
            setLoading(false);
          });
      }
    });
  };

  const buttonTop = [
    <ButtonWlb
      key="1"
      type="primary"
      loading={loading}
      onClick={() => _handleSubmitGroup()}
    >
      Buat
    </ButtonWlb>
  ];

  return (
    <CreateGroupBoxComponent
      title={title}
      buttonTop={buttonTop}
      getFieldDecorator={getFieldDecorator}
      group={newGroup}
      handleAttributeChange={_handleAttributeChange}
      {...props}
    />
  );
};

const CreateGroupBoxContainer = Form.create({ name: 'Group_Form_Control' })(
  CreateGroupBoxWrapper
);

export default CreateGroupBoxContainer;
