import { Avatar, Empty, Spin } from 'antd';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Assets from '../../Assets';
import { Notification } from '../../Services';
import { useDirectNotification } from '../../SharedComponents/CustomHook';
import {
  FooterWrapper,
  WrapperContent,
  ListNotifWrapper
} from './NavBar.style';

const Footer = ({ onMarkAsRead, onSeeAll, setVisible }) => {
  return (
    <FooterWrapper>
      <p onClick={onMarkAsRead} className="text">
        Mark as read
      </p>
      <Link to={onSeeAll}>
        <p onClick={() => setVisible(visible => !visible)} className="text">
          See All
        </p>
      </Link>
    </FooterWrapper>
  );
};

const ListItem = ({
  name,
  content,
  time,
  avatar,
  setRecomendation,
  data,
  setVisible
}) => {
  const directTo = useDirectNotification();

  const handleClickItem = (data, setVisible) => {
    setVisible(visible => !visible);
    const { _id } = data;
    Notification.readANotification(_id); //request read notification
    const { type, subtype } = data;
    if (
      type === 'social' &&
      (subtype === 'social-referral-request' ||
        subtype === 'social-referral-revision-request')
    ) {
      setRecomendation(e => ({ ...e, open: true, data, subtype }));
    } else {
      directTo(data);
    }
  };

  return (
    <ListNotifWrapper
      read={data.read}
      onClick={() => handleClickItem(data, setVisible)}
    >
      <Avatar
        src={avatar ?? Assets.IMAGE.AVATAR_ALT}
        style={{ width: 35, height: 35 }}
      />
      <div className="body-notification">
        {contentNotification?.[data.type]?.(data) || (
          <DefaultItem name={name} content={content} />
        )}
        <span className="time">{moment(time).fromNow()}</span>
      </div>
    </ListNotifWrapper>
  );
};

export default memo(function PopupList({
  list,
  onSeeAll,
  onMarkAsRead,
  setRecomendation,
  setVisible
}) {
  return (
    <WrapperContent>
      {list ? (
        list.length > 0 ? (
          list.map((res, i) => (
            <ListItem
              setVisible={setVisible}
              data={res}
              setRecomendation={setRecomendation}
              key={i}
              time={res.data.date}
              avatar={res.data.avatar || res.data.avatar_url}
              name={res.title}
              content={res.message}
            />
          ))
        ) : (
          <Empty />
        )
      ) : (
        <div style={{ margin: '50px auto' }}>
          <Spin size="large" />
        </div>
      )}
      <Footer
        onSeeAll={onSeeAll}
        onMarkAsRead={onMarkAsRead}
        setVisible={setVisible}
      />
    </WrapperContent>
  );
});

const contentNotification = {
  social: ({ subtype, title, message }) => {
    switch (subtype) {
      case 'social-personal-info-updated': {
        const _title = title.split(' has updated your ');
        const _message = title.split(_title?.[0]);
        return (
          <p className="content-notification">
            <span>{_title[0]}</span>
            {_message}
          </p>
        );
      }
      case 'social-referral-revision-request': {
        const userName = title.replace(
          'asking you to revise your referral',
          ''
        );
        return (
          <p>
            <strong>{userName}</strong>
            <span> ask for revision referral</span>
          </p>
        );
      }
      case 'social-referral-request': {
        const userName = title.replace('asking for your referral', '');
        return (
          <p>
            <strong>{userName}</strong>
            <span> asking for your referral</span>
          </p>
        );
      }
      default:
        return <DefaultItem name={title} content={message} />;
    }
  },
  business_trip: ({ title, message }) => (
    <p
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(`<b>${title}</b> ${message}`)
      }}
      className="content-notification"
    />
  ),
  vendor: ({ subtype, title, message }) => {
    if (subtype === 'vendor-request-refund-approved') {
      const approved = subtype === 'vendor-request-refund-approved';
      const start = message.indexOf(approved ? ' approved' : ' rejected');
      const end = message.indexOf('invoice code of ');

      const vendor = message.slice(0, start);
      const msg = message.slice(start, end + 16);
      const invoice = message.slice(end + 16);

      return (
        <p className="content-notification">
          <strong>{vendor}</strong> {msg} <strong>{invoice}</strong>
        </p>
      );
    } else {
      return <DefaultItem name={title} content={message} />;
    }
  }
};

const DefaultItem = ({ name, content }) => (
  <p className="content-notification">
    <span>{name}</span>
    {' ' + content}
  </p>
);
