import React, { useState } from 'react';
import PeopleScreenComponent from './PeopleScreen.component';
import queryString from 'query-string';
import { DisplayLoading, SearchNotFound } from '../../../Components';
import { Query } from 'react-apollo';
import { GET_LIST_EMPLOYEES } from './PeopleScreen.graphql';

const PeopleScreenContainer = props => {
  const companyId = props.match.params.id;
  const queryWord = queryString.parse(props.location.search).keyword;
  const [isFetching, setIsFetching] = useState(false);

  const handleSearch = async (location, keyword) => {
    const { pathname } = location;
    const searchParam = keyword ? '?keyword=' + keyword : '';
    props.history.push(pathname + searchParam);
  };

  return (
    <>
      <Query
        query={GET_LIST_EMPLOYEES}
        fetchPolicy="network-only"
        variables={{
          company: companyId,
          search: `%${queryWord ?? ''}%`,
          limit: 15,
          offset: 0
        }}
      >
        {({ data, error, fetchMore }) => {
          if (error) return <p>{error.message}</p>;
          if (data) {
            const dataEmp = data?.getEmployeeInCompany?.employee ?? [];
            const totalEmp = data?.getEmployeeInCompany?.total ?? 0;
            return (
              <>
                <PeopleScreenComponent
                  {...props}
                  totalEmployee={totalEmp}
                  companyId={companyId}
                  employees={dataEmp}
                  handleSearch={handleSearch}
                  isFetching={isFetching}
                  onLoadMore={() => {
                    if (dataEmp.length < totalEmp) {
                      setIsFetching(true);
                      fetchMore({
                        variables: { offset: dataEmp.length },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          setIsFetching(false);
                          if (!fetchMoreResult) return prev;
                          return Object.assign({}, prev, {
                            getEmployeeInCompany: {
                              total: fetchMoreResult.getEmployeeInCompany.total,
                              employee: [
                                ...prev.getEmployeeInCompany.employee,
                                ...fetchMoreResult.getEmployeeInCompany.employee
                              ].filter(
                                (v, i, a) =>
                                  a.findIndex(t => t.id === v.id) === i
                              ),
                              __typename: 'ListEmployeeType'
                            }
                          });
                        }
                      });
                    }
                  }}
                />

                {data.getEmployeeInCompany.employee.length === 0 && (
                  <SearchNotFound
                    title={`We could not find  "${queryWord}"`}
                    desc={
                      'Nothing came up for that search. Try shortening or rephrasing your search.'
                    }
                  />
                )}
              </>
            );
          } else {
            return <DisplayLoading />;
          }
        }}
      </Query>
    </>
  );
};

export default PeopleScreenContainer;
