import styled from 'styled-components';

const ActionButtons = styled.div`
  display: flex;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  flex-direction: column;
  justify-content: space-between;

  button {
    border: none;
    color: #000;
    font-family: 'Muli-Light';
    &:hover {
      background-color: #e6f7ff;
      color: #000;
    }
  }
`;
const PopOverBody = styled.div`
  border-radius: 2.5px;
  .more-option {
    display: block;
    float: right;
    width: 9px;
    height: 7px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
`;

export { ActionButtons, PopOverBody };
