import { useHistory } from 'react-router-dom';
import { AuthenticationService } from '../../Services';

export default function useDirectNotification() {
  const history = useHistory();
  const userId = AuthenticationService.getUserId();
  const directTo = notif => {
    const { subtype, data, type } = notif;
    let url = '/';

    // condition by type
    if (type === 'performance') {
      const isCompleted = notif?.data?.message?.indexOf('is complete') > 0;
      switch (subtype) {
        case 'performance-goal-activities':
        case 'performance-task-activities':
        case 'performance-goal-comments':
        case 'performance-task-comments':
          window.open(`${process.env.REACT_APP_HC_PERFORMANCE}`);
          break;
        case 'performance-classification-notif':
          !isCompleted &&
            window.open(
              `${process.env.REACT_APP_HC_PERFORMANCE}/committee/version/${notif.data.classification_id}/${notif.data.position_id}`
            );
          break;
        default:
          break;
      }
    } else if (type === 'company') {
      const BASE_URL_TIME = process.env.REACT_APP_HC_TIME;
      switch (subtype) {
        case 'company-time-overtime-request':
        case 'company-time-overtime-approval-spv':
        case 'company-time-overtime-approval-pos':
        case 'company-time-overtime-rejected':
        case 'company-time-overtime-cancelled':
          window.open(`${BASE_URL_TIME}/overtime`);
          break;
        case 'company-time-leave-approval-spv':
        case 'company-time-leave-approval-pos':
        case 'company-time-leave-rejected':
        case 'company-time-leave-cancelled':
        case 'company-time-leave-request':
        case 'company-time-leave-balance-edit':
          window.open(`${BASE_URL_TIME}/leave`);
          break;
        case 'company-placement-reminder-contract':
          directTo(notif);
          break;
        default:
          break;
      }
    }

    // condition by subtype
    switch (subtype) {
      // Social Media
      case 'social-people-tag':
      case 'social-post-global':
      case 'social-post-share':
        url = `/user/${data?.poster_id}/post/${data?.post_id}`;
        history.push(url);
        break;
      case 'social-people-follow':
        url = `/followers/${userId}/`;
        history.push(url);
        break;
      case 'social-post-reply':
      case 'social-post-like':
        url = `/user/${data?.post_author_id}/post/${data?.post_id}`;
        history.push(url);
        break;
      case 'social-post-company':
      case 'social-post-company-like':
      case 'social-people-company-tag':
      case 'social-post-company-reply':
        url = `/company/${data?.poster_id}/post/${data?.post_id}`;
        history.push(url);
        break;
      case 'social-post-company-timeline':
      case 'social-post-company-like-timeline':
      case 'social-post-company-comment-timeline':
      case 'social-post-company-reply-timeline':
      case 'social-people-company-tag-timeline':
        url = `/company/${data?.company_id}/post/${data?.post_id}`;
        history.push(url);
        break;
      case 'social-skill-endorse':
      case 'social-company-post-kudos':
      case 'social-referral-given':
        // case 'social-referral-request':
        url = `/profile/${data?.target_id}/detail`;
        history.push(url);
        break;
      case 'social-personal-info-updated':
        url = `/profile/${userId}/detail`;
        history.push(url);
        break;
      case 'social-group-request-join-approve':
        url = `/group/${data?.group_id}/discussion`;
        history.push(url);
        break;
      case 'social-group-request-join':
      case 'social-group-promoted-to-admin':
        url = `/group/${data?.group_id}/member`;
        history.push(url);
        break;
      case 'social-post-group':
      case 'social-post-group-reply':
      case 'social-post-group-like':
      case 'social-people-group-tag':
        url = `/group/${data?.group_id}/post/${data?.post_id}`;
        history.push(url);
        break;
      // Learning
      case 'learning-classroom-invited':
      case 'learning-classroom-request-accepted':
        url = `/social-learning/${data?.classroom_id}/discussion`;
        history.push(url);
        break;
      case 'learning-classroom-assigned-learning-contrib':
      case 'learning-classroom-contrib-request-approved':
        url = `/social-learning/${data?.classroom_id}/about`;
        history.push(url);
        break;
      case 'learning-classroom-request-join':
        url = `/social-learning/${data?.classroom_id}/member`;
        history.push(url);
        break;
      case 'learning-classroom-post-like':
      case 'learning-classroom-post-comment':
      case 'learning-classroom-post-tracker':
      case 'learning-classroom-post-event':
      case 'learning-classroom-post':
      case 'learning-classroom-post-tag':
        url = `/social-learning/${data?.classroom_id}/post/${data?.post_id}/`;
        history.push(url);
        break;
      case 'company-placement-reminder-contract':
        url = `/profile/${data?.pwp_user}/detail`;
        history.push(url);
        break;
      case 'learning-microlearning-enrolled':
        // window.open(`${process.env.REACT_APP_HC_LEARNING}/instructor/micro-learnings/learner/${notif.data.microlearning_id}`)
        window.open(
          `${process.env.REACT_APP_HC_LEARNING}/instructor/micro-learnings/manage/${notif.data.microlearning_id}`
        );
        break;
      case 'learning-microlearning-rejected':
        window.open(
          `${process.env.REACT_APP_HC_LEARNING}/instructor/micro-learnings/manage/${notif.data.microlearning_id}`
        );
        break;
      case 'learning-microlearning-approved':
      case 'learning-microlearning-comment':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning/${notif.data.microlearning_id}`
        );
        break;
      case 'learning-microlearning-comment-reply':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning/${notif.data.microlearning_id}`
        );

        break;
      case 'learning-microlearning-reminder-schedule-set':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning/${notif.data.microlearning_id}`
        );
        history.push(url);
        break;
      case 'learning-microlearning-assigned-org':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning/${notif.data.microlearning_id}`
        );
        history.push(url);
        break;
      case 'learning-microlearning-reminder-daily':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/micro_learning/${notif.data.microlearning_id}`
        );
        history.push(url);
        break;
      case 'learning-direct-transfer-course-approved':
      case 'learning-direct-transfer-course-rejected':
      case 'learning-direct-transfer-book-approved':
      case 'learning-direct-transfer-book-rejected':
      case 'learning-direct-transfer-microlearning-approved':
      case 'learning-direct-transfer-microlearning-rejected':
        window.open(
          `${process.env.REACT_APP_HC_VENDOR}/transaction/Purchase%20History`,
          '_self'
        );
        break;
      case 'learning-course-organization-assignment':
        window.open(
          `${process.env.REACT_APP_NEW_LEARNING}/learner/course/${notif.data.slug}`,
          '_self'
        );
        break;

      // Business Trip
      case 'business-trip-request-for-another':
      case 'business-trip-processing':
      case 'business-trip-rejected':
      case 'business-trip-cancelled-to-user':
      case 'business-trip-cancelled-to-group':
      case 'business-trip-approved':
        window.open(`${process.env.REACT_APP_HC_TIME}/trip/activity`);
        break;
      case 'business-trip-request':
      case 'business-trip-cancelled':
        window.open(`${process.env.REACT_APP_HC_TIME}/trip/request`);
        break;
      case 'business-trip-processing-group':
      case 'business-trip-rejected-group':
      case 'business-trip-approved-group':
        window.open(`${process.env.REACT_APP_HC_TIME}/trip/group-activity`);
        break;

      // Vendor
      case 'vendor-request-refund-approved':
        window.open(`${process.env.REACT_APP_HC_VENDOR}/transaction`);
        break;

      // Claim and Claim Limit
      case 'claim-limit-top-up':
      case 'claim-limit-adjust':
        window.open(`${process.env.REACT_APP_HC_VENDOR}/profile/claim`);
        break;
      case 'financial-claim-activity':
        window.open(
          `${process.env.REACT_APP_HC_TIME}/claim/${/ (rejected|processed|approved) your /.test(data.message)
            ? 'activity'
            : 'request'
          }`
        );
        break;
      default:
        break;
    }
  };
  return directTo;
}
