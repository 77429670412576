import React from 'react';
import PropTypes from 'prop-types';

import utils from '../../../Utils';
import { VendorOrderStatus } from '../../../Components';

const propTypes = {
  vendor: PropTypes.shape().isRequired
};

const defaultProps = {};

const columns = [
  {
    title: 'Order',
    dataIndex: 'code',
    key: 'order',
    render: text => text
  },
  {
    title: 'Price',
    dataIndex: 'final_price',
    key: 'price',
    render: text => utils.currencyFormat(text)
  },
  {
    title: 'Customer',
    dataIndex: 'invoice_to',
    key: 'customer'
  },
  {
    title: 'Date',
    dataIndex: 'date_added',
    key: 'date',
    render: text => utils.dateTime.defaultDate(text)
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: text => <VendorOrderStatus status={text} />
  }
];

export default {
  propTypes,
  defaultProps,
  columns
};
