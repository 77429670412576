import React, { useState } from 'react';
import VendorServiceComponent from './VendorService.component';

const VendorServiceContainer = props => {
  const [checked, setChecked] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const _setChecked = () => {
    setChecked(!checked);
    setDisabledBtn(!disabledBtn);
  };

  const _setDisabledBtn = () => setDisabledBtn(!disabledBtn);

  return (
    <VendorServiceComponent
      agreeChecked={checked}
      setAgreeChecked={_setChecked}
      disabledBtn={disabledBtn}
      setDisabledBtn={_setDisabledBtn}
      {...props}
    />
  );
};

export default VendorServiceContainer;
