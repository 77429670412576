import styled from 'styled-components';

const FormWrapper = styled.div`
  padding: 0 40px 0 40px;
  font-family: 'Muli-Light', sans-serif;
  font-size: 11px !important;

  .ant-form-explain {
    text-align: left;
  }

  .ant-form-item-required {
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .ant-row ant-form-item {
    width: 254.6px;
    border-radius: 2.5px;
    border: solid 0.5px #a9a8a8;
    background-color: #ffffff;
  }
`;

const FormAction = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }

  .submit-button {
    border-radius: 2.5px;
    border: solid 0.5px #707070;
    background-color: #014a62;
    color: #ffffff;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background-color: #014a62;
  }
`;

export { FormWrapper, FormAction };
