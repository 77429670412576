import React from 'react';
import PostCardContainer from './PostCard.container';
import { PostService } from '../../Services';

function GlobalPostCard(props) {
  const post = props.itemPost;

  //   const _handleCancel = () => {
  //     setDisplayShare(false);
  //   };
  const handleLike = likeValue => {
    return PostService.postLike(post.userId, post.id, likeValue);
  };

  const handleAward = awardValue => {
    return PostService.postAward(post.userId, post.id, awardValue);
  };

  return (
    <PostCardContainer
      {...props}
      handleLike={handleLike}
      handleAward={handleAward}
    />
  );
}
export default GlobalPostCard;
