import styled from 'styled-components';
import Assets from '../../Assets';

export const WrapperPostTaggedPeople = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  padding: 17px 0px;
  border-top: 1px solid ${Assets.COLOR_PALLETE.grey};
  margin-top: 17px;
  span {
    display: block;
  }
  .userTagged {
    margin-right: 3px;
  }
`;
