import React from 'react';
import Header from './shared/Header';
import Section from './shared/Section';
import { PrintCVWrap, WrapRelative } from './UserProfileCV.style';
import Experience from './shared/Experience';
import Project from './shared/Project';
import Award from './shared/Award';
import Education from './shared/Education';
import Speaker from './shared/Speaker';
import Training from './shared/Training';
import Publications from './shared/Publications';
import Hobbies from './shared/Hobbies';
import moment from 'moment';
import Assets from '../../Assets';
import { isEmpty } from 'lodash';
// import Skills from "./shared/Skills";
import OrganizationActivity from './shared/OrganizationActivity';
import Service from './shared/Service';
import Disciplinary from './shared/Disciplinary';
import Ohsa from './shared/Ohsa';

// const formatDate = date => moment(date).format('MMM YYYY');
const formatDate = (date, format = 'll') => {
  if (date) {
    return moment(date).format(format);
  } else {
    return null;
  }
};

const generateDate = (start_date, end_date, isPresent) => {
  if (start_date) {
    return `${formatDate(start_date)} - ${
      end_date
        ? formatDate(end_date) + ` (` + moment(start_date).to(end_date) + ')'
        : `present (` + moment(start_date).toNow(true) + ')'
    }`;
  }
  return '-';
};
const generateList = list => {
  let _res = [];
  if (list) {
    if (typeof list === 'object') {
      if (!isEmpty(list)) {
        list.map(res => _res.push(res));
      }
      return _res.join(', ');
    } else {
      return list;
    }
  } else {
    return '-';
  }
};

function UserProfileCVComponentData({ data, scale }) {
  if (data) {
    const {
      main = [],
      phone = [],
      social_contacts = [],
      projects = [],
      experiences = [],
      education = [],
      certificates = [],
      speaker = [],
      awards = [],
      publications = [],
      hobbies = [],
      skills = [],
      organization_activities = [],
      email = [],
      service_agreements = [],
      disciplinaries = [],
      ohsa_list = []
    } = data;
    // console.log(data);
    // const allPhone = [];
    // phone.length > 0 &&
    //   phone.map(row => {
    //     allPhone.push(row.country_code + row.phone);
    //   });

    // const allEmail = [];
    // email.length > 0 &&
    //   email.map(row => {
    //     allEmail.push(row.email);
    //   });

    // const allSosmed = [];
    // social_contacts.length > 0 &&
    //   social_contacts.map(row => {
    //     allSosmed.push(row.url);
    //   });
    // console.log(allSosmed);

    const renderValue = (value, secondValue) => {
      if (value !== null && value !== '' && value !== undefined) {
        return value;
      } else {
        return secondValue;
      }
    };

    return (
      <WrapRelative id="print-resume">
        <PrintCVWrap scale={scale}>
          <img
            className="wlb-logo"
            alt="logo"
            src={Assets.ICON.SMARTASN_LOGO_CV}
          />
          <Header
            src={main?.[0]?.avatar}
            name={main?.[0]?.name}
            position={
              main?.[0]?.people_work_placements?.[0]?.company_employee_position
                ?.name +
              ' at ' +
              main?.[0]?.people_work_placements?.[0]?.company_profile.brand_name
            }
            location={main?.[0]?.people_addresses}
            url={social_contacts}
            phone={phone}
            email={email}
          />
          <Section title="Summary">
            <p>{main?.[0]?.people_profiles?.[0]?.profile_fields?.summary} </p>
          </Section>
          {experiences[0] ? (
            <Section title="Experience">
              {experiences.map(res => {
                return (
                  <Experience
                    key={res.id}
                    title={res.title}
                    company={res.company_name}
                    type={res.title}
                    regno={res.decree}
                    date={generateDate(res.from, res.to, res.is_present)}
                    desc={renderValue(res.description, '-')}
                    desc2={res.achievements}
                    data={res}
                  />
                );
              })}
            </Section>
          ) : (
            ''
          )}
          {projects[0] ? (
            <Section title="Project">
              {projects.map(res => {
                return (
                  <Project
                    key={res.id}
                    title={renderValue(res.project_name, '-')}
                    company={
                      res.associate_user?.people_work_placements?.[0]
                        ?.company_profile?.brand_name
                    }
                    type={generateList(res.creators)}
                    date={generateDate(
                      res.start_date,
                      res.end_date,
                      res.is_current
                    )}
                    location={renderValue(res.location?.project_location, '-')}
                    url={renderValue(res.project_url, '-')}
                    desc={renderValue(res.description, '-')}
                    assoc={`Associate with ${generateList(
                      res.creators
                    )} ${res.position ?? ''}`}
                  />
                );
              })}
            </Section>
          ) : (
            ''
          )}
          {education[0] ? (
            <Section title="Education">
              {education.map(res => {
                return (
                  <Education
                    key={res.id}
                    title={renderValue(res.company_profile?.school_name, '-')}
                    // company={res.company_profile?.school_name}
                    degree={renderValue(res.major?.name, '-')}
                    date={generateDate(
                      res.start_date,
                      res.end_date,
                      res.is_present
                    )}
                    location={renderValue(
                      res.company_profile?.company_address,
                      '-'
                    )}
                    score={res.score}
                    desc={renderValue(res.description, '-')}
                    data={res}
                  />
                );
              })}
            </Section>
          ) : (
            ''
          )}
          {certificates[0] ? (
            <Section title="Training, License & Certification">
              {certificates.map(res => {
                return (
                  <Training
                    key={res.id}
                    title={renderValue(res.name, '-')}
                    company={renderValue(
                      res.issuing_organization?.legal_name,
                      '-'
                    )}
                    organization={renderValue(
                      res.issuing_organization?.brand_name,
                      '-'
                    )}
                    number={renderValue(res.issuer_name, '-')}
                    date={generateDate(
                      res.issues_date,
                      res.expirations_date,
                      !res.can_expire
                    )}
                    location={renderValue(res.address, '-')}
                    desc={renderValue(res.description, '-')}
                    data={res}
                  />
                );
              })}
            </Section>
          ) : (
            ''
          )}
          {speaker[0] ? (
            <Section title="Speaker/Informant/Jury">
              {speaker.map(res => (
                <Speaker
                  key={res.id}
                  title={res.event_name}
                  company={res.organizer}
                  speaker={generateList(res.roles)}
                  location={renderValue(res.location?.event_location, '-')}
                  participant={`${res.scale} Event with ${res.total_participants} Participants`}
                  desc={renderValue(res.description, '-')}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {awards[0] ? (
            <Section title="Award">
              {awards.map(res => (
                <Award
                  key={res.id}
                  title={renderValue(res.award_name, '-')}
                  nomor={'Ref No.' + res.reference_number}
                  date={formatDate(res.reference_date)}
                  regno={`Award No.${res.letter_number} - ${res.certificate_number}`}
                  to={`Awarded to ${main?.[0]?.name}`}
                  desc={renderValue(res.remark, '-')}
                  issuing_organization={renderValue(
                    res.issuing_organization,
                    '-'
                  )}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {organization_activities[0] ? (
            <Section title="Organization Activity">
              {organization_activities.map(res => (
                <OrganizationActivity
                  key={res.id}
                  title={res.position_name ?? '-'}
                  organization={res.organization_name ?? '-'}
                  location={
                    res.city?.name +
                    ', ' +
                    res.province?.name +
                    ', ' +
                    res.country
                  }
                  date={generateDate(res.start_date, res.end_date)}
                  memberId={
                    res.member_id !== null
                      ? res.member_id !== ''
                        ? 'Member ID ' + res.member_id
                        : 'Member ID -'
                      : 'Member ID -'
                  }
                  desc={renderValue(res.position_description, '-')}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {publications[0] ? (
            <Section title="Publications">
              {publications.map(res => (
                <Publications
                  key={res.id}
                  title={renderValue(res.publication_title, '-')}
                  name={generateList(res.authors)}
                  company={renderValue(res.publication_media, '-')}
                  date={
                    res?.publication_date
                      ? moment(res?.publication_date).format('MMM YYYY')
                      : '-'
                  }
                  url={renderValue(res.publication_url, '-')}
                  desc={renderValue(res.description, '-')}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {hobbies[0] ? (
            <Section title="Hobbies">
              {hobbies.map(res => (
                <Hobbies
                  key={res.id}
                  title={res.people_hobby?.name}
                  type={res.people_hobby?.people_hobby?.name}
                  desc={renderValue(res.description, '-')}
                  details={res.hobby_fields?.details}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {service_agreements[0] ? (
            <Section title="Service Agreement">
              {service_agreements.map((res, i, count) => (
                <Service key={i} data={res} i={i} count={count} />
              ))}
            </Section>
          ) : (
            ''
          )}
          {disciplinaries[0] ? (
            <Section title="Disciplinary">
              {disciplinaries.map((res, i, count) => (
                <Disciplinary
                  key={i}
                  data={res}
                  i={i}
                  count={count}
                  nameUser={main?.[0]?.name}
                />
              ))}
            </Section>
          ) : (
            ''
          )}
          {ohsa_list[0] ? (
            <Section title="Occupational Health and Safety Act History">
              {ohsa_list.map((res, i, count) => (
                <Ohsa key={i} data={res} />
              ))}
            </Section>
          ) : (
            ''
          )}
          {skills[0] ? (
            <Section title="Skills">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {skills.map(res => (
                  // <Skills
                  //   key={res.id}
                  //   title={res.people_skill.name}
                  //   level={res.people_skill_level.name}
                  //   desc={renderValue(res.description,'-')}
                  // />
                  <div
                    key={res.id}
                    style={{
                      backgroundColor: '#014a62',
                      color: '#fff',
                      padding: '14px 34px',
                      borderRadius: 5,
                      marginRight: 30,
                      marginBottom: 12
                    }}
                  >
                    {res.people_skill.name ?? '-'}
                  </div>
                ))}
              </div>
            </Section>
          ) : (
            ''
          )}
        </PrintCVWrap>
      </WrapRelative>
    );
  } else return '';
}

export default class UserProfileCVComponent extends React.Component {
  render() {
    return (
      <div>
        <UserProfileCVComponentData
          data={this.props.data}
          scale={this.props.scale}
        />
      </div>
    );
  }
}
