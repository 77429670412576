import gql from 'graphql-tag';

const GET_ACCOUNT_BANK_LIST = gql`
{
  company_profile_banks{
    id
    global_bank{
      id
      name
      swift
      code
    }
    bank_name
    account_name
    account_number
    branch
  }
}
`;

const DELETE_BANK_ACCOUNT = gql`
	mutation deleteBankAccount($deleted_at:timestamptz!, $id:Int!){
  update_company_profile_banks(
    _set:{
      deleted_at:$deleted_at
    },where:{
        id:{_eq:$id}
    }
  ){
    affected_rows
  }
}	
`;

export {
	GET_ACCOUNT_BANK_LIST,
	DELETE_BANK_ACCOUNT
};
