import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import TagPeopleComponent from './TagPeople.component';
import { AuthenticationService } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import { SEARCH_USER_COMPANY } from './TagPeopleGraphQl';

function TagPeopleCompanyPost(props) {
  const { handlePost } = props;
  const company_id = AuthenticationService.getUserData().company_id;
  const [peopleList, setPeopleList] = useState([]);
  const [keyword, setKeyword] = useState('');
  // const [isFetching, setFetching] = useState(false);
  const [blockedMe, blockLoaded] = useBlockMe();

  const OPTIONS_SEARCH = {
    context: { headers: { 'X-Hasura-Role': 'public' } },
    variables: {
      id: company_id,
      key: `%${keyword}%`,
      limit: 10,
      offset: 0
    }
  };
  const {
    data: dataSearchUserCompany,
    refetch: refetchUserCompany,
    loading
  } = useQuery(SEARCH_USER_COMPANY, OPTIONS_SEARCH);

  useAsyncEffect(
    async isMounted => {
      if (!isMounted) return;
      if (dataSearchUserCompany && blockLoaded) {
        refetchUserCompany();
        let filteredUser = dataSearchUserCompany?.people_work_placements?.filter(
          item => !blockedMe?.includes(item.global_user?.id)
        );
        setPeopleList(filteredUser);
      }
    },
    [dataSearchUserCompany, keyword]
  );

  return (
    <TagPeopleComponent
      users={peopleList}
      setKeyword={setKeyword}
      handlePost={handlePost}
      isFetching={loading}
      {...props}
    />
  );
}

export default TagPeopleCompanyPost;
