import gql from 'graphql-tag';

export const GET_LIST_ORDER = gql`
  query getListOrder(
    $companyId: uuid!
    $module_name: String!
    $status: [String!]!
    $search: String
    $dateFrom: timestamp
    $dateTo: timestamp
    $priceFrom: float8
    $priceTo: float8
    $limit: Int!
    $offset: Int!
    $order: order_by
  ) {
    total: global_invoices_aggregate(
      where: {
        global_wallet_vendor: { company_profiles: { id: { _eq: $companyId } } }
        module_name: { _eq: $module_name }
        status: { _in: $status }
        date_added: { _gte: $dateFrom, _lte: $dateTo }
        final_price: { _gte: $priceFrom, _lte: $priceTo }
        _or: [
          { invoice_to: { _ilike: $search } }
          { code: { _ilike: $search } }
        ]
        _not: {
          _and: [
            { status: { _eq: "pending" } }
            { global_payment: { payment_method: { _neq: "direct_transfer" } } }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }

    global_invoices(
      where: {
        global_wallet_vendor: { company_profiles: { id: { _eq: $companyId } } }
        module_name: { _eq: $module_name }
        status: { _in: $status }
        date_added: { _gte: $dateFrom, _lte: $dateTo }
        final_price: { _gte: $priceFrom, _lte: $priceTo }
        _or: [
          { invoice_to: { _ilike: $search } }
          { code: { _ilike: $search } }
        ]
        _not: {
          _and: [
            { status: { _eq: "pending" } }
            { global_payment: { payment_method: { _neq: "direct_transfer" } } }
          ]
        }
      }
      limit: $limit
      offset: $offset
      order_by: [{ date_added: $order }]
    ) {
      id
      code
      invoice_to
      invoice_to_id
      final_price
      payment_method
      date_added
      status
    }
  }
`;
