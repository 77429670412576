export const fileTypeChecker = name => {
  const nameSplited = name.split('.');
  const idx = nameSplited.length - 1;
  const res = nameSplited[idx];

  if (
    res === 'doc' ||
    res === 'docx' ||
    res === 'xls' ||
    res === 'pdf' ||
    res === 'xlsx' ||
    res === 'ppt' ||
    res === 'pptx'
  ) {
    return false;
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif'
  ) {
    return false;
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    return false;
  } else {
    return true;
  }
};

export const checkFilesType = files => {
  let result = false;
  let isMore25mb = false;
  files.forEach(file => {
    if (fileTypeChecker(file.name)) {
      result = true;
    }
    if (file.size >= 1024 * 1024 * 25) {
      isMore25mb = true;
    }
  });

  return [result, isMore25mb];
};
