import styled from 'styled-components';
import Assets from '../../../Assets';

export const SearchWrap = styled.div`
  position: relative;
  background: ${Assets.COLOR_PALLETE.white};
  padding: 24px 10px;
  height: 75px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  .ant-select-selection--single {
    border: none;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    border-radius: unset;
  }
`;

export const Wrap = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 15px;
`;
