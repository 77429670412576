import React from 'react';
import { LanguageSettingsWrapper } from './style';
import LanguageSettingsComponent from './LanguagetSettings.component';
import { Radio } from 'antd';

const LanguageSettingsContainer = () => {
  return (
    <LanguageSettingsWrapper>
      <Radio.Group defaultValue="Bahasa indonesia" name="language-settings">
        <LanguageSettingsComponent name="Bahasa indonesia" />
      </Radio.Group>
    </LanguageSettingsWrapper>
  );
};

export default LanguageSettingsContainer;
