import React, { useState } from 'react';
import { DivStyled, ThypoStyled } from './DetailUserSection.style';
import { Avatar } from 'antd';
import Assets from '../../Assets';
import {
  generateDateWorkingStatus,
  accessRightNEWSwitcher,
  toArrayRight
} from './helperForDetail';
import moment from 'moment';
import DetailSubordinate from './DetailSubordinate';
import DirectToUserDetail from '../DirectToUserDetail';
import { AttachementItemIcon } from '../../SharedComponents';
import { useMediaQuery } from '@material-ui/core';

const style = {
  wrapperAttch: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxWidth: 232,
    justifyContent: 'space-between'
  },
  attch: {
    marginTop: 0,
    marginBottom: 22
  }
};

export default function WorkingStatus(props) {
  const isMobileScreen = useMediaQuery(
    '(min-width: 320px) and (max-width: 767px)'
  );
  const { dataWorkingStatus, nameUser } = props;
  let data = {};
  const [limitSub, setLimitSub] = useState(5);
  const [detail, setDetail] = useState(false);

  const _userInfo = employee => {
    return employee.length > 0
      ? employee.map((res, i) => {
          return (
            res?.people_work_placements.length > 0 &&
            res?.people_work_placements.map((row, j) => {
              return (
                <DirectToUserDetail key={i} userId={row?.global_user?.id}>
                  <DivStyled
                    display="flex"
                    flexDirection="row"
                    margin="0px 0px 14px 0px"
                  >
                    <Avatar
                      src={row?.global_user?.avatar}
                      shape="square"
                      size="large"
                    />
                    <DivStyled
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <DivStyled
                        margin="0px 8px"
                        overflow="hidden"
                        width="170px"
                      >
                        <ThypoStyled fontSize="12px" fontWeight="600">
                          {row?.global_user?.name}
                        </ThypoStyled>
                        <ThypoStyled fontSize="12px">{res?.title}</ThypoStyled>
                      </DivStyled>
                    </DivStyled>
                  </DivStyled>
                </DirectToUserDetail>
              );
            })
          );
        })
      : null;
  };

  const _userInfoSubordinate = employee => {
    return employee.length > 0
      ? employee.map((res, i) => {
          return (
            <DirectToUserDetail key={i} userId={res?.global_user?.id}>
              <DivStyled
                display="flex"
                flexDirection="row"
                margin="0px 0px 14px 0px"
              >
                <Avatar
                  src={res?.global_user?.avatar}
                  shape="square"
                  size="large"
                />
                <DivStyled
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <DivStyled margin="0px 8px" overflow="hidden" width="170px">
                    <ThypoStyled fontSize="12px" fontWeight="600">
                      {res?.global_user?.name}
                    </ThypoStyled>
                    <ThypoStyled fontSize="12px">{res?.title}</ThypoStyled>
                  </DivStyled>
                </DivStyled>
              </DivStyled>
            </DirectToUserDetail>
          );
        })
      : null;
  };
  const handleLimitSub = type => {
    let reset = 5;
    if (type === 'seeMore') {
      reset = limitSub + 5;
    }
    setLimitSub(reset);
  };

  const handleCloseDetail = () => {
    setDetail(!detail);
  };

  const _leftComponent = leftComponent => {
    let dataSubordinate = [];
    leftComponent.subordinate.length >= 1 &&
      leftComponent.subordinate.map(row => {
        row.people_work_placements.map(res => {
          dataSubordinate.push({
            title: row.title,
            ...res
          });
        });
      });
    return (
      <DivStyled display="flex" flexDirection="column" width="auto">
        <DivStyled display="flex" flexDirection="column">
          {leftComponent.supervisor.length > 0 && (
            <ThypoStyled fontSize="14px" fontWeight="600" margin="15px 0px">
              Atasan Langsung
            </ThypoStyled>
          )}
          {_userInfo(leftComponent.supervisor)}
        </DivStyled>

        <DivStyled display="flex" flexDirection="column">
          {dataSubordinate.length > 0 && (
            <ThypoStyled fontSize="14px" fontWeight="600" margin="15px 0px">
              Bawahan Langsung
            </ThypoStyled>
          )}
          {_userInfoSubordinate(dataSubordinate.slice(0, limitSub))}
          {dataSubordinate.length > 0 && dataSubordinate.length > 5 && (
            <ThypoStyled
              fontSize="14px"
              color={Assets.COLOR_PALLETE.blue_link}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setDetail(true);
              }}
            >
              Lihat Lebih Banyak
            </ThypoStyled>
          )}
        </DivStyled>
      </DivStyled>
    );
  };

  const Detail = ({
    name,
    value,
    noStyle,
    isArray,
    customValue = undefined
  }) => (
    <DivStyled display="flex" flexDirection="row" margin="0px 0px 20px 0px">
      <ThypoStyled fontSize="12px" width="150px">
        {name}
      </ThypoStyled>
      <DivStyled flexDirection="column">
        {customValue ||
          (isArray ? (
            value.map((res, i) => (
              <ThypoStyled
                key={i}
                style={!noStyle ? { textTransform: 'capitalize' } : null}
                fontSize="12px"
                color={Assets.COLOR_PALLETE.grey}
                width="auto"
                margin="0px 0px 12px 0px"
              >
                {res}
              </ThypoStyled>
            ))
          ) : (
            <ThypoStyled
              style={!noStyle ? { textTransform: 'capitalize' } : null}
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              width="auto"
            >
              {value}
            </ThypoStyled>
          ))}
      </DivStyled>
    </DivStyled>
  );
  const _rightComponent = data => (
    <DivStyled
      display="flex"
      flexDirection="column"
      margin={` ${isMobileScreen ? '24px 0px 0px 0px' : '16px 0px 0px 48px'}`}
    >
      <Detail name="NIP" value={data.employeId} />
      <Detail name="Surat Ketetapan" value={data.decree} />
      <Detail name="Email" value={data.email} noStyle />
      <Detail name="Organisasi" value={data.level} />
      <Detail name="Jabatan" value={data.level} />
      <Detail name="Jenis Kepegawaian" value={data.type} />
      <Detail name="Pengaturan Lokasi Kerja" value={data.place} />
      <Detail name="Pengaturan Waktu Kerja" value={data.time} />
      <Detail name="Kelas Jabatan" value={data.job_grade} />
      <Detail name="Pusat Pembiayaan" value={data.cost_center} />
      <Detail name="Fasilitas" isArray value={toArrayRight(data.facility)} />
      <Detail
        name="Hak Akses"
        isArray
        value={accessRightNEWSwitcher(data.access)}
      />
      <Detail
        name="Lampiran"
        customValue={
          <div style={style.wrapperAttch}>
            {data.attachment[0]
              ? data.attachment.map(({ name, url }) => (
                  <AttachementItemIcon
                    key={url}
                    style={style.attch}
                    name={name}
                    url={url}
                  />
                ))
              : '-'}
          </div>
        }
      />
    </DivStyled>
  );
  return dataWorkingStatus
    ? dataWorkingStatus.map((res, i) => {
        data = {
          title: res?.company_job_profile?.title ?? '-',
          time: generateDateWorkingStatus(res.start_date, res.end_date),
          logo: res.company_logo?.logo,
          organization:
            res?.company_job_profile?.company_organization?.name ?? '-',
          join_date: res
            ? res?.join_date
              ? moment(res.join_date).format('DD MMM YYYY')
              : '-'
            : '-',
          permanent_date: res
            ? res.permanent_date
              ? moment(res.permanent_date).format('DD MMM YYYY')
              : '-'
            : '-',
          pre_retirement_date: res
            ? res.pre_retirement_date
              ? moment(res.pre_retirement_date).format('DD MMM YYYY')
              : '-'
            : '-',
          retired_date: res
            ? res.retired_date
              ? moment(res.retired_date).format('DD MMM YYYY')
              : '-'
            : '-',
          leftComponent: {
            supervisor: [res?.company_job_profile?.supervisor] ?? [],
            subordinate: res?.company_job_profile?.subordinate ?? [],
            immediate: [res?.company_job_profile?.immediate_manager] ?? []
          },
          rightComponent: {
            employeId: res?.regno ?? '-',
            email: res?.email ?? '-',
            organization:
              res?.company_job_profile?.company_organization?.name ?? '-',
            level: res?.company_job_profile?.company_employee_rank?.name ?? '-',
            type: res?.type ?? '-',
            time: res?.type_time ?? '-',
            place: res?.type_place ?? '-',
            access: res?.company_job_profile?.roles ?? '-',
            job_grade: res?.company_employee_rank?.job_grade ?? '-',
            cost_center: res?.company_organization?.cost_center ?? '-',
            decree: res?.decree ?? '-',
            facility: res?.facilities ?? [],
            attachment: res.attachment ?? []
          }
        };

        return (
          <DivStyled
            key={i}
            padding="28px 0px"
            display="flex"
            flexDirection="column"
          >
            <DivStyled
              display="flex"
              flexDirection={isMobileScreen ? 'column' : 'row'}
              alignItems="center"
              gap="16px"
            >
              <Avatar
                style={
                  !data.logo && {
                    padding: 28,
                    backgroundColor: Assets.COLOR_PALLETE.grey2
                  }
                }
                src={data.logo || Assets.DEFAULT_LOGO}
                shape="square"
                size={100}
              />
              <DivStyled>
                <ThypoStyled fontSize="14px" fontWeight="600">
                  {data.title}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.grey}
                  margin="4px 0px 5.8px 0px"
                >
                  {data.time}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.blue_link}
                >
                  {data.organization}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.blue_link}
                >
                  {`Bergabung sejak ${data.join_date} (Pegawai Tetap Sejak ${data.permanent_date})`}
                </ThypoStyled>
                <ThypoStyled fontSize="12px">
                  {`Prapensiun sejak ${data.pre_retirement_date}`}
                </ThypoStyled>
              </DivStyled>
            </DivStyled>

            <DivStyled
              display="flex"
              flexDirection={isMobileScreen ? 'column' : 'row'}
              margin={`16px 0px 0px ${isMobileScreen ? '0' : '118'}px`}
            >
              {_leftComponent(data.leftComponent)}
              {_rightComponent(data.rightComponent)}
            </DivStyled>
            <DetailSubordinate
              open={detail}
              nameUser={nameUser}
              handleClose={handleCloseDetail}
              data={data.leftComponent}
            />
          </DivStyled>
        );
      })
    : 'Loading';
}
