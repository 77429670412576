import React from 'react';
import Assets from '../../../Assets';
import { ThypoStyled } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { TextSecondary, TextStandard, TextGrey } from '../UserProfileCV.style';

export default function Training({
  title,
  company,
  number,
  date,
  location,
  organization,
  desc,
  data
}) {
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <div style={{ marginBottom: 35 }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.grey}
        fontStyle="italic"
      >
        <ThypoStyled
          fontSize="12px"
          color={Assets.COLOR_PALLETE.grey}
          fontStyle="italic"
        >
          {`${data?.level === null ? '' : data?.level} - ${
            data?.type === null ? '' : data?.type + ' Training'
          }`}
        </ThypoStyled>
      </ThypoStyled>
      <TextGrey>{number}</TextGrey>
      {/* <TextSecondary>{company}</TextSecondary> */}
      {/* <TextGrey>{organization}</TextGrey> */}
      <TextGrey>{date}</TextGrey>
      {/* <TextGrey>{location}</TextGrey> */}
      <TextStandard>{desc}</TextStandard>
      <div style={{ paddingTop: '10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'start',
            paddingTop: '10px'
          }}
        >
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            Certificate URL :
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.blue_link}
            fontStyle="italic"
            style={{ paddingLeft: '10px' }}
          >
            {`${renderValue(data.credential_url, '-')}`}
          </ThypoStyled>
        </div>
        <ThypoStyled fontSize="12px" color="#014a62" margin="0px 0px 12px 0px">
          {`Credential ID ${renderValue(data.credential_id, '-')}`}
        </ThypoStyled>
      </div>
    </div>
  );
}
