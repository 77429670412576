import React from 'react';
import { Select, Spin, Avatar } from 'antd';
import { Wrap } from './InviteMemberClassroom.style';
import ButtonWlb from '../../Components/ButtonWlb';

const InviteMemberClassroomComponent = props => {
  const {
    users,
    loading,
    inviteHandler,
    searchHandler,
    changeHandler,
    value,
    isFetching
  } = props;

  let timeout = 0;
  const _Search = value => {
    const keyword = value.trim();
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      searchHandler(keyword);
    }, 1000);
  };

  const option = users.map(user => (
    <Select.Option key={user.id} label={user.name} value={user.email}>
      <Avatar src={user.avatar} />
      &nbsp;{user.name}
    </Select.Option>
  ));

  return (
    <Wrap>
      <p className="heading">Undang Anggota</p>
      <Select
        className="select"
        showArrow={false}
        // value={value}
        showSearch
        onSearch={_Search}
        placeholder="Masukkan nama atau alamat email"
        onChange={value => changeHandler(value)}
        filterOption={false}
        optionLabelProp="label"
        labelInValue
        notFoundContent={isFetching ? <Spin size="small" /> : 'Tidak ada data'}
      >
        {option}
      </Select>
      {value && (
        <ButtonWlb
          type="primary"
          // disabled={!value}
          loading={loading}
          style={{ align: 'center', marginTop: '10px' }}
          onClick={inviteHandler}
        >
          Tambah
        </ButtonWlb>
      )}
    </Wrap>
  );
};

export default InviteMemberClassroomComponent;
