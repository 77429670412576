import React, { useState } from 'react';
import { LoginAndSecurityConfigComponent } from './LoginAndSecuritySettings.component';
import { CHANGE_PASSWORD } from './LoginAndSecuritySetttings.graphql';
import { useMutation } from 'react-apollo';
import { Form, message } from 'antd';
import axios from 'axios';
import { AuthenticationService } from '../../Services';
import BaseUrl from '../../Config/baseUrl';

const LoginAndSecurityWrap = props => {
  const [
    confirmDirty
    // setConfirmDirty
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { form } = props;
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const validateNewPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    if (value && confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  const handleSubmit = () => {
    form.validateFields((err, values) => {
      if (!err) {
        changePassword({
          variables: {
            oldPassword: values.currentPassword.trim(),
            newPassword: values.newPassword.trim()
          }
        })
          .then(res => {
            const { changePassword } = res.data;
            if (changePassword.message === 'succeed') {
              message.success('Change Password Success');
              props.form.resetFields();
            }
          })
          .catch(err => {
            const errorMessage = err.message;
            message.error(errorMessage.slice(15));
            props.form.resetFields();
          });
      }
    });
  };

  const handleForgotPassword = () => {
    setIsLoading(true);
    const userData = AuthenticationService.getUserData();
    axios
      .post(BaseUrl.FORGOT_PASS, { email: userData.email })
      .then(res => {
        message.success(res.data.message);
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <LoginAndSecurityConfigComponent
        {...props}
        handleSubmit={handleSubmit}
        validateNewPassword={validateNewPassword}
        validateToNextPassword={validateToNextPassword}
        handleForgotPassword={handleForgotPassword}
        isLoading={isLoading}
      />
    </>
  );
};

const LoginAndSecurityContainer = Form.create()(LoginAndSecurityWrap);

export default LoginAndSecurityContainer;
