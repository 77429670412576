import React from 'react';
import { Button, Dropdown } from 'antd';
import { ActionButtons, PopOverBody } from './CommentAction.style';
import Assets from '../../../Assets';

const CommentActionContainer = props => {
  const { setVisibleModal } = props;

  return (
    <PopOverBody>
      <Dropdown
        className="more-option"
        overlay={
          <ActionButtons>
            <Button onClick={() => setVisibleModal(true)}>Hapus</Button>
          </ActionButtons>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <img alt="user" src={Assets.ICON.MORE} />
      </Dropdown>
    </PopOverBody>
  );
};

export default CommentActionContainer;
