import React from 'react';
import {
  UserCardWrapper,
  UserContent,
  UserInformation,
  AvatarWrapper
} from './UserCard.style';
import { Avatar } from 'antd';
import Assets from '../../Assets';
import { ButtonWlb } from '..';
import { AuthenticationService } from '../../Services';

const UserCardComponent = props => {
  const mySelf = AuthenticationService.getUserId();
  const {
    contentDirection,
    avatarSize,
    user,
    isFollow,
    isLoadingFollow,
    handleFollow,
    onClick
  } = props;
  return (
    <UserCardWrapper
      onClick={() => onClick(user.id)}
      style={{ width: 'auto', cursor: 'pointer' }}
    >
      <div className={'user-card'}>
        <AvatarWrapper size={`${avatarSize}px`}>
          <Avatar
            size={user.avatar ? avatarSize : 70}
            shape={user.avatar ? 'circle' : 'square'}
            src={user.avatar ? user.avatar : Assets.IMAGE.AVATAR}
          />
        </AvatarWrapper>
        <UserContent className={`user-content ${contentDirection}`}>
          <UserInformation>
            <div className={'user-title'}>{user.name}</div>
            <div className="position">{user.position}</div>
            <div>{user.company}</div>
          </UserInformation>
          {mySelf !== user.id && (
            <ButtonWlb
              type={isFollow ? 'default' : 'primary'}
              style={{ width: 150 }}
              loading={isLoadingFollow}
              disabled={isLoadingFollow}
              onClick={e => {
                e.stopPropagation();
                isFollow
                  ? handleFollow(user, 'unfollow')
                  : handleFollow(user, 'follow');
              }}
            >
              {isFollow ? 'Mengikuti' : 'Ikuti'}
            </ButtonWlb>
          )}
        </UserContent>
      </div>
    </UserCardWrapper>
  );
};

export default UserCardComponent;
