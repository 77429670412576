import React from 'react';
import { SearchNotFound } from './SearchNotFound.style';
import Assets from '../../Assets';

const SearchNotFoundComponent = props => {
  const { title, desc } = props;
  return (
    <SearchNotFound
      style={{
        width: '100%',
        marginTop: '15px',
        marginBottom: '100px',
        boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)'
      }}
      {...props}
    >
      <div>
        <img alt="not found" src={Assets.ICON.SEARCH_NOT_FOUND} />
      </div>
      <p>{title}</p>
      <p>{desc}</p>
    </SearchNotFound>
  );
};

export default SearchNotFoundComponent;
