import styled from 'styled-components';

export const WrapperPhone = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 26px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`;
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PhoneName = styled.h3`
  color: #000;
  margin-bottom: 4px;
  font-size: 14px;
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 430px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 5px;
  }
`;

export const AddButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #039be5;
  padding: 24px 0px;
  font-size: 16px;
  font-weight: 600;
`;

export const TextGrey = styled.p`
  color: #a9a8a8;
  margin-bottom: 4px;
  font-size: 12px;
`;

export const TextBlue = styled.span`
  cursor: pointer;
  font-size: 12px;
  color: #039be5;
  margin-top: ${props => props.margintop};
`;

export const MainPhone = styled.div`
  font-size: 12px;
  background-color: #014a62;
  color: #fff;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 10px;
  cursor: default;
  max-width: 180px;
`;
