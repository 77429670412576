import styled from 'styled-components';
import Constants from '../../Config/Constants';

export const HashtagWrapper = styled.div`
  font-size: 12px;
  color: ${Constants.COLORS.BLACK};

  .component_tag_rendered {
    margin-bottom: 10px;

    .ant-tag {
      margin-bottom: 5px;
    }
  }
`;
