export default {
  HASHTAG_ICON: require('./hashtag.png'),
  IMAGE_ICON: require('./image.png'),
  VIDEO_ICON: require('./video.png'),
  AVATAR: require('./person.png'),
  LOCATION_ICON: require('./location.png'),
  LOCATION_BLUE: require('./location-blue.svg'),
  ATTACHMENT_ICON: require('./attachment.png'),
  PERSON_ICON: require('./person.png'),
  LANGUAGE_ICON: require('./language.png'),
  POST_ICON: require('./post.png'),
  SEND_ICON: require('./send.svg'),
  EVENT_ICON: require('./event.png'),
  TASK_ICON: require('./task.png'),
  POLLING_ICON: require('./icon/polling.svg'),
  // GROUP_LOGO: require('./group-logo.png'),
  GROUP_LOGO: require('./group-logo.jpg'),
  PRIVACY_ONLY_ME: require('./privacy/only-me.svg'),
  PRIVACY_FOLLOWER: require('./privacy/follower.svg'),
  PRIVACY_FOLLOWING_FOLLOWER: require('./privacy/following-follower.svg'),
  PRIVACY_PUBLIC: require('./privacy/public.svg'),
  PRIVACY_SPECIFIC_FOLLOWER: require('./privacy/specific-follower.svg'),
  HC_ECOSYSTEM: {
    LOGO: require('./icon/hc-ecosystem/hc-ecosystem.svg'),
    ANALYTIC: require('./icon/hc-ecosystem/analytic.svg'),
    VENDOR: require('./icon/hc-ecosystem/vendor.svg'),
    CHAT: require('./icon/hc-ecosystem/chat.svg'),
    LEARNING: require('./icon/hc-ecosystem/learning.svg'),
    ORGANIZATION: require('./icon/hc-ecosystem/organization.svg'),
    FINANCE: require('./icon/hc-ecosystem/finance.svg'),
    TIME: require('./icon/hc-ecosystem/time.svg'),
    DIGITAL_DOCUMENT: require('./icon/hc-ecosystem/digital-document.svg'),
    RECRUITMENT: require('./icon/hc-ecosystem/recruitment.svg'),
    SUPPORT: require('./icon/hc-ecosystem/support.svg'),
    TALENT: require('./icon/hc-ecosystem/talent.svg'),
    REWARD: require('./icon/hc-ecosystem/reward.svg'),
    PERFORMANCE: require('./icon/hc-ecosystem/performance.svg'),
    JOB_PORTAL: require('./icon/hc-ecosystem/job-portal.svg'),
    TEAM: require('./icon/hc-ecosystem/team.svg')
  },
  QUICK_ACCESS: {
    LOGO: require('./icon/quick-access/quick-access.svg'),
    START_TIME_ATTENDANCE: require('./icon/quick-access/start-time-attendance.svg'),
    REQUEST_LEAVE: require('./icon/quick-access/request-leave.svg'),
    REQUEST_OVERTIME: require('./icon/quick-access/request-overtime.svg'),
    ONGOING_FEEDBACK: require('./icon/quick-access/ongoint-feedback.svg'),
    REQUEST_CLAIM: require('./icon/quick-access/request-claim.svg'),
    REQUEST_CASH_ADVANCE: require('./icon/quick-access/request-cash-advance.svg'),
    REQUEST_REFERENCE: require('./icon/quick-access/request-reference.svg'),
    LEARNING_DIARY: require('./icon/quick-access/auto_stories-24px.svg'),
    MENTORING: require('./icon/quick-access/cast-for-education-white-24dp.svg'),
    SQUAD: require('./icon/quick-access/squad.svg'),
    WALLET: require('./icon/quick-access/wallet.svg'),
    DIGIDOC: require('./icon/quick-access/digidoc.svg')
  },
  VENDOR_BANNER: {
    LOGO: require('./vendorBanner/logo.jpg'),
    BANNER: require('./vendorBanner/banner.png')
  },
  ERROR_IMAGE: {
    ERROR_404: require('./utilities/error404.svg'),
    ERROR_500: require('./utilities/error500.svg'),
    COMING_SOON: require('./utilities/coming-soon.svg'),
    MAINTENANCE: require('./utilities/page-under-dev.png')
  },
  TEAM_ICON: require('./icon/team.svg'),
  SAMPLE: require('./sample.jpg')
};
