import React, { useState } from 'react';
import { AccountSettingsWrapper } from './Privacy.style';
import {
  BlockedAccount,
  // MyActivity,
  // DirectMessage,
  ProfileInformation
} from '../../../Containers';
import SidebarSettings from '../../../Components/SidebarSettings';

const PrivacyComponent = () => {
  const [activeMenu, setActiveMenu] = useState('Informasi Profil Saya');
  const handleSwitchMenu = selectedMenu => setActiveMenu(selectedMenu);
  const menu = [
    'Informasi Profil Saya',
    // 'My Activity',
    // 'Direct Message',
    'Blok Akun'
  ];
  const renderMenu = () => {
    switch (activeMenu) {
      case 'Informasi Profil Saya':
        return <ProfileInformation />;
      // case 'My Activity':
      //   return <MyActivity />;
      // case 'Direct Message':
      //   return <DirectMessage />;
      case 'Blok Akun':
        return <BlockedAccount />;
      default:
        return <ProfileInformation />;
    }
  };
  return (
    <AccountSettingsWrapper>
      <SidebarSettings
        handleSwitchMenu={handleSwitchMenu}
        menu={menu}
        title="Pengaturan Pribadi"
      />
      <div className={'active-menu-content'}>
        <div className={'active-menu-header'}>{activeMenu}</div>
        {renderMenu()}
      </div>
    </AccountSettingsWrapper>
  );
};

export default PrivacyComponent;
