import React, { useState } from 'react';
import { BodyBoxGroup } from './SidebarGroupInfo.style';
import { ButtonWlb } from '../../Components';

const RequestButtonGroup = props => {
  const { isRequest, requestJoin, cancelRequestJoin, isLoading } = props;
  // isRequest props are from TeamHomePage.container
  const [hideRequested, setHideRequested] = useState(false);

  return (
    <BodyBoxGroup
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <ButtonWlb
        type="primary"
        style={{ width: 230 }}
        hidden={isRequest}
        onClick={() => requestJoin()}
        disabled={isLoading}
        // loading={isLoading}
      >
        Meminta untuk Bergabung
      </ButtonWlb>
      <div hidden={!isRequest}>
        <ButtonWlb
          type={hideRequested ? 'danger' : 'default'}
          style={{ width: 230 }}
          onMouseEnter={() => setHideRequested(true)}
          onMouseLeave={() => setHideRequested(false)}
          onClick={() => cancelRequestJoin()}
          disabled={isLoading}
          // loading
        >
          {hideRequested ? 'Batalkan permintaan' : 'Diminta'}
        </ButtonWlb>
      </div>
    </BodyBoxGroup>
  );
};

export default RequestButtonGroup;
