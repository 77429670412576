import Constants from '../../../Config/Constants';
import Assets from '../../../Assets';
import styled from 'styled-components';
import { Select } from 'antd';

export default {
  container: {
    backgroundColor: Constants.COLORS.WHITE,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    paddingBottom: 20
  },
  header: {
    color: Constants.COLORS.DARK_BLUE,
    fontFamily: Assets.FONT.MULI_BOLD,
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 15
  },
  table: {
    // paddingLeft: 30,
    // paddingRight: 30,
    cursor: 'pointer'
  },
  pagination: {
    padding: 10
  }
};

export const OrderListWrapper = styled.div`
  font-family: ${Assets.FONT.MULI};
  .order-list-page {
    padding-top: 20px;
    padding-right: 20px;
  }
`;

export const SelectBorderless = styled(Select)`
  & .ant-select-selection {
    border: none;
  }
`;
