import CONSTANTS from '../../../Config/Constants';
import ASSETS from '../../../Assets';
import styled from 'styled-components';

export const BannerWrapper = styled.div`
  font-family: ${ASSETS.FONT.MULI};
`;

export default {
  container: {
    marginTop: 30,
    boxShadow: '0px 1.5px 3px 0px ' + CONSTANTS.COLORS.SHADOW,
    backgroundColor: '#fff'
  },
  bannerWrapper: {
    width: '100%',
    maxHeight: 270,
    overflow: 'hidden'
  },
  banner: {
    // margin: 'auto',
    // maxWidth: '100%'
    width: '100%',
    height: 270
  },
  colContent: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    backgroundColor: CONSTANTS.COLORS.WHITE
  },
  avatar: {
    backgroundColor: '#fff',
    zIndex: 1,
    marginTop: -60
  },
  colDescription: {
    height: '100%',
    paddingLeft: 30,
    flexDirection: 'column'
  },
  vendorName: {
    paddingTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
    color: CONSTANTS.COLORS.BLACK,
    margin: 0
  },
  vendorDescription: {
    margin: 0,
    marginTop: -4,
    fontSize: 12,
    color: CONSTANTS.COLORS.GREY
  },
  vendorMoto: {
    margin: 0,
    marginTop: 8,
    fontSize: 12,
    color: CONSTANTS.COLORS.BLACK
  },
  productSold: {
    paddingTop: 20,
    margin: 0,
    marginTop: 8,
    fontSize: 12,
    color: CONSTANTS.COLORS.GREY
  },
  vendorProductSold: {
    fontSize: 18,
    color: CONSTANTS.COLORS.BLACK
  },
  colVendorRating: {
    height: '100%',
    paddingLeft: 30,
    flexDirection: 'column'
  },
  vendorRating: {
    margin: 0,
    marginTop: 27,
    fontSize: 12,
    color: CONSTANTS.COLORS.GREY
  },
  rate: {
    color: CONSTANTS.COLORS.LIGHT_BLUE,
    fontSize: 14
  },
  colBottom: {
    backgroundColor: CONSTANTS.COLORS.DARK_GREY,
    padding: 10
  },
  icon: {
    fontSize: 16,
    paddingLeft: 20,
    color: '#CECFD0'
  }
};
