import styled from "styled-components";

const EmployeeListCardWrapper = styled.div`
  display: block;
  
  .employee-row{
    width: 1174px;
    display: block;
    padding-bottom: 21px;
  }
`;

export {EmployeeListCardWrapper}
