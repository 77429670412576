import { DatePicker, Form, Icon, Input, message, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ModalEdit } from '../../Components';
import { ButtonFrom, TitleField } from '../../SharedComponents';
import { FalilyCom, WrapperItemFamily } from './UserInsurance.style';
import {
  GET_LIST_FAMILY,
  ADD_INSURANCE,
  UPDATE_INSURANCE,
  GET_LIST_INSURANCE_NAME,
  INSURANCE_CHECKER,
  ADD_WITH_UPDATE_QUERY
} from './UserInsurance.graphql';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from 'react-apollo';
import { AuthenticationService } from '../../Services';
import moment from 'moment';
import { getValidateDuplicatedGovernment } from './halperInsurance';

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const getResponseMessage = (err, type = 'menambahkan') => {
  if (err?.message.includes('Uniqueness violation')) {
    message.error(`Nomor ID telah digunakan`);
  } else {
    message.error(
      `Gagal ${type} data asuransi, silakan coba lagi nanti, ${err}`
    );
  }
};

const filterSelected = (option, list) => {
  return (
    option.filter(({ id: id1 }) => !list.some(({ id: id2 }) => id2 === id1)) ??
    []
  );
};

const AddEditUserInsuranceComponent = ({
  actionType,
  open,
  onClose,
  form,
  setOpen,
  refetch,
  data,
  insuranceDatas
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    validateFields,
    resetFields,
    getFieldValue,
    setFields
  } = form;
  const { Option } = Select;
  const [list, setList] = useState([]);
  const [typeSubmit, setTypeSubmit] = useState(null);
  const client = useApolloClient();

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data: dataFamily } = useQuery(GET_LIST_FAMILY, {
    variables: {
      user: AuthenticationService.getUserId()
    },
    context: CONTEXT_USER,
    fetchPolicy: 'no-cache'
  });

  const [
    getListInsuranceName,
    { data: listInsuranceName, loading: loadingInsuranceName }
  ] = useLazyQuery(GET_LIST_INSURANCE_NAME, {
    context: CONTEXT_USER
  });

  const [addInsurance] = useMutation(ADD_INSURANCE, {
    context: CONTEXT_USER
  });
  const [editInsurance] = useMutation(UPDATE_INSURANCE, {
    context: CONTEXT_USER
  });
  const [addWithUpdateQuery] = useMutation(ADD_WITH_UPDATE_QUERY, {
    context: CONTEXT_USER
  });

  useEffect(() => {
    if (open) {
      getListInsuranceName({
        variables: {
          type_filter: data?.global_insurance_list?.type
        }
      });
      setList(data?.identity_fields?.family ?? []);
      setTypeSubmit(data?.type ?? null);
      setFieldsValue({
        family: data?.identity_fields?.family?.map(({ id }) => id) ?? []
      });
    }
  }, [data, setList, open, getListInsuranceName, setFieldsValue]);

  const handleClose = () => {
    onClose();
    resetFields();
    setList([]);
    setTypeSubmit(null);
  };

  const handleDelete = () => {
    setOpen(e => ({ ...e, modalDelete: true, open: false }));
  };

  const mutationAddInsurance = async (values, identity_fields) => {
    const { data: dataInsurance } = await client.query({
      query: INSURANCE_CHECKER,
      variables: {
        id: values.insurance_number,
        user: AuthenticationService.getUserId(),
        type: typeSubmit
      },
      context: CONTEXT_USER,
      fetchPolicy: 'no-cache'
    });

    if (dataInsurance?.people_identities?.length > 0) {
      // Add menggunakan query UPDATE
      addWithUpdateQuery({
        variables: {
          id: dataInsurance.people_identities[0].id,
          changes: {
            id: dataInsurance.people_identities[0].id,
            type: typeSubmit,
            deletedAt: null,
            identity_fields
          }
        }
      })
        .then(() => {
          refetch();
          handleClose();
          message.success('Berhasil menambahkan data asuransi');
        })
        .catch(err => {
          getResponseMessage(err);
        });
    } else {
      // Add menggunakan query INSERT
      const submit = {
        objects: {
          type: typeSubmit,
          id: values.insurance_number,
          identity_fields
        }
      };
      addInsurance({
        variables: submit
      })
        .then(() => {
          refetch();
          handleClose();
          message.success('Berhasil menambahkan data asuransi');
        })
        .catch(err => {
          getResponseMessage(err);
        });
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields(async (error, values) => {
      const isErrorGov = await getValidateDuplicatedGovernment(client, values);

      if (isErrorGov) {
        message.error('Insurance Number has been used');
      } else if (!error) {
        const _identity_fields = {
          join_date: values.join_date
            ? moment(values.join_date).format('YYYY-MM-DD')
            : null,
          end_date: values.end_date
            ? moment(values.end_date).format('YYYY-MM-DD')
            : null,
          balance_amount: values.balance_amount
            ? parseInt(values.balance_amount)
            : null,
          description: values.description,
          family: list
        };
        if (actionType === 'add') {
          mutationAddInsurance(values, _identity_fields);
        } else {
          const submit = {
            user: AuthenticationService.getUserId(),
            type: data?.type,
            id: data.id,
            changes: {
              type: values.insurance_name,
              id: values.insurance_number,
              identity_fields: _identity_fields
            }
          };
          editInsurance({
            variables: submit
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil mengubah data asuransi');
            })
            .catch(err => {
              message.error(
                `Gagal mengubah data asuransi, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  const handleKeyDown = async e => {
    if (e.keyCode === 8) {
      setList(list);
    }
  };

  const handleChange = async (value, fieldName, extra) => {
    if (fieldName === 'family') {
      const result = [...list];
      extra.forEach(res => {
        result.push({
          id: res?.props?.data?.id,
          name: res?.props?.data?.name,
          relationship: res?.props?.data?.relationship
        });
      });
      const _result = result.filter(el => el.id !== undefined);
      const unique = [];
      _result.map(x =>
        unique.filter(a => a.id == x.id).length > 0 ? null : unique.push(x)
      );
      setList(unique);
    } else if (fieldName === 'insurance_type') {
      getListInsuranceName({
        variables: {
          type_filter: value
        }
      });
      setFieldsValue({
        insurance_name: undefined
      });
      setTypeSubmit(null);
    } else if (fieldName === 'insurance_name') {
      const insuranceTypeValue = await getFieldValue('insurance_type');
      if (insuranceTypeValue === undefined) {
        setFields({
          insurance_name: {
            value: value,
            errors: [
              new Error('Harap isi Tipe Asuransi sebelum mengisi Nama Asuransi')
            ]
          }
        });
      }
      setTypeSubmit(extra?.props?.data?.id);
    }
  };

  const _switchComponentToRender = res => {
    const { type, fieldName, placeholder, style, option, loading } = res;
    if (type === 'select-tag') {
      const optionFiltered = filterSelected(option, list);
      return (
        <FalilyCom
          mode="multiple"
          style={{ width: '100%' }}
          onChange={(val, extra) => handleChange(val, fieldName, extra)}
          placeholder={placeholder}
          filterOption={false}
          onInputKeyDown={handleKeyDown}
          notFoundContent={null}
          showArrow
        >
          {option &&
            optionFiltered?.map((res, i) => (
              <Option data={res} key={i} value={res.id}>
                {res.name}
              </Option>
            ))}
        </FalilyCom>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style || { width: '100%' }}
          type={type}
          placeholder={placeholder}
          onChange={val => handleChange(val, fieldName)}
        />
      );
    } else if (type === 'select') {
      return (
        <Select
          size="large"
          loading={loading}
          placeholder={placeholder}
          onChange={(value, extra) => handleChange(value, fieldName, extra)}
        >
          {option &&
            option?.map((res, i) => {
              return (
                <Option
                  disabled={res.disabled}
                  data={res}
                  key={i}
                  value={res.id}
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else {
      return (
        <Input.TextArea
          rows={6}
          style={style || { width: '100%' }}
          placeholder={placeholder}
          onChange={val => handleChange(val, fieldName)}
        />
      );
    }
  };

  const insuranceNames = useMemo(
    () => getInsuranceNames(insuranceDatas, listInsuranceName),
    [insuranceDatas, listInsuranceName]
  );

  const listFields = [
    {
      labelName: 'Tipe Asuransi',
      fieldName: 'insurance_type',
      type: 'select',
      fieldValue: data?.global_insurance_list?.type ?? undefined,
      placeholder: 'Pilih tipe asuransi',
      isRequired: true,
      option: [
        { id: 'government', name: 'Asuransi Pemerintah' },
        { id: 'premium', name: 'Asuransi Premium' }
      ]
    },
    {
      labelName: 'Nama Asuransi',
      fieldName: 'insurance_name',
      type: 'select',
      loading: loadingInsuranceName,
      fieldValue: !loadingInsuranceName ? data?.type : undefined,
      placeholder: 'Pilih nama asuransi',
      isRequired: true,
      option: insuranceNames
    },
    {
      labelName: 'Nomor Asuransi',
      fieldName: 'insurance_number',
      type: 'text',
      fieldValue: data?.id,
      placeholder: 'Tambahkan nomor asuransi',
      isRequired: true
    }
  ];
  const listFields2 = [
    {
      labelName: 'Saldo',
      fieldName: 'balance_amount',
      type: 'number',
      fieldValue: data?.identity_fields?.balance_amount,
      placeholder: 'Tambahkan saldo',
      isRequired: false
    },
    {
      labelName: 'Keluarga dan Penerima',
      fieldName: 'family',
      type: 'select-tag',
      // fieldValue: list ?? [],
      placeholder: 'Tambahkan keluarga dan penerima',
      isRequired: false,
      option: dataFamily?.people_dependants ?? []
    }
  ];
  const listFields3 = [
    {
      labelName: 'Keterangan',
      fieldName: 'description',
      type: 'textArea',
      fieldValue: data?.identity_fields?.description,
      placeholder: 'Tambahkan keterangan',
      isRequired: false
    }
  ];

  const handleDeleteFamily = res => {
    let numbers = [...list];
    const i = numbers.indexOf(res);
    if (i >= 0) {
      numbers.splice(i, 1);
    }
    setList(numbers);
    setFieldsValue({
      family: numbers?.map(({ id }) => id) ?? []
    });
  };

  const getDate = date => {
    if (date) {
      return moment(date, 'YYYY/MM/DD');
    } else return null;
  };
  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Data Asuransi`}
      open={open}
      onClose={handleClose}
      onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            style={{ marginBottom: 0, display: 'inline-block', width: '100%' }}
          >
            <Form.Item
              label={<TitleField>Tanggal Berlaku</TitleField>}
              style={{ display: 'inline-block', marginBottom: 0, width: '50%' }}
            >
              {getFieldDecorator('join_date', {
                initialValue: getDate(data?.identity_fields?.join_date),
                rules: rule(false)
              })(
                <DatePicker
                  allowClear
                  placeholder="Pilih tanggal"
                  format={'YYYY-MM-DD'}
                  style={{ width: '90%' }}
                />
              )}
            </Form.Item>
            <Form.Item
              label={<TitleField>Tanggal Berakhir</TitleField>}
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '50%',
                marginLeft: 'auto'
              }}
            >
              {getFieldDecorator('end_date', {
                initialValue: getDate(data?.identity_fields?.end_date),
                rules: rule(false)
              })(
                <DatePicker
                  allowClear
                  placeholder="Pilih tanggal"
                  format={'YYYY-MM-DD'}
                  style={{ width: '100%' }}
                />
              )}
            </Form.Item>
          </Form.Item>
        </div>
        {listFields2.length > 0 &&
          listFields2.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <WrapperItemFamily>
          {list &&
            list.map((res, i) => (
              <div key={`${i}-`} className="item">
                <p>{res.name}</p>
                <Icon
                  type="close"
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    fontSize: 12
                  }}
                  onClick={() => handleDeleteFamily(res)}
                />
              </div>
            ))}
        </WrapperItemFamily>
        {listFields3.length > 0 &&
          listFields3.map((res, i) => {
            return (
              <Form.Item
                key={`${i}-${res.fieldName}`}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: rule(res.isRequired)
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <ButtonFrom
          actionType={actionType}
          submitLabel="Simpan"
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditUserInsurance = Form.create({ name: 'Add_Edit_User_Insurance' })(
  AddEditUserInsuranceComponent
);

export default AddEditUserInsurance;

const getInsuranceNames = (insuranceDatas, listInsuranceName) => {
  const ownedInsurance = insuranceDatas?.government.map(({ type }) => type);

  return (
    listInsuranceName?.global_insurance_list.map(insurance => ({
      id: insurance.enum,
      name: insurance.name,
      disabled: ownedInsurance.includes(insurance.enum)
    })) || []
  );
};
