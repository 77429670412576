import React, { useState } from 'react';
import style, {
  BannerBoxWrap,
  BoxShadow,
  AvatarStyled,
  WrapperButton,
  WrapperHeader,
  ButtonEdit
} from './BannerProfile.style';
import { ButtonWlb } from '..';
import { Col, Icon, Dropdown, Menu } from 'antd';
import Assets from '../../Assets';
import { displayLoading } from '../../Utils/CommonUtils';
import { isEmpty } from 'lodash';
import { DisplayLoading, KudosMenus } from '../../Components';
import { KudosPostBox } from '../../Containers';
import Constants from '../../Config/Constants';
import {
  GiveRecomendation,
  RequestRecommendation,
  SettingSection,
  BuildAResume
} from '../ActionMorePeopleProfile';
import AddressDomisili from '../ShortcutIcon/AddressDomisili/AddressDomisili.container';
import PhoneNumber from '../ShortcutIcon/PhoneNumber/PhoneNumber.comtainer';
import EmailAddress from '../ShortcutIcon/EmailAddress/EmailAddress.container';
import ChangePhotoProfile from '../ChangePhotoProfile';
import { AuthenticationService } from '../../Services';
import AnotherContact from '../ShortcutIcon/AnotherContact/AnotherContact.container';

const BannerProfileComponent = props => {
  const {
    children,
    mainProfile,
    loading,
    follower,
    following,
    isFetchingConnection,
    userId,
    isFollow,
    isLoadingFollow,
    handleFollow,
    handleShareProfile,
    handleBlock,
    setIsFetchingConnection,
    setOpenIntro,
    userProfile,
    kudosList,
    isFollowingFollowed,
    refetch
  } = props;
  const userData = AuthenticationService.getUserData();

  const [visibleKudos, setVisibleKudos] = useState(false);
  const [visibleKudosPost, setVisibleKudosPost] = useState(false);
  const [selectedKudos, setSelectedKudos] = useState(null);
  const [open, setOpen] = useState({
    giveRecomendation: false,
    requestRecomendation: false,
    askForRecommendation: false,
    buildResume: false,
    address: false,
    phoneNumber: false,
    email: false,
    changeProfile: false,
    anotherContact: false,
    settingSection: false
  });
  const [refferalType, setRefferalType] = useState('');

  const { socialMedia } = mainProfile;
  const profileId = props.match.params.profileId;

  const url =
    process.env.REACT_APP_CHAT +
    `/?chat=@${profileId}:${process.env.REACT_APP_MATRIX_URL}#/`;

  const handleRefferal = type => {
    setRefferalType(type);
    setOpen({ ...open, giveRecomendation: true });
  };

  const dataEdit = {
    name: userProfile?.main_profile?.[0]?.name,
    official_name: mainProfile.official_name,
    nickname: mainProfile.nickname,
    educationId: mainProfile.educationId,
    education: mainProfile.education,
    list_education: mainProfile.list_education
  };

  const style_icon = { fontSize: 16, color: '#014a62', paddingRight: 20 };

  const _SocialMedia = () => {
    if (!isEmpty(socialMedia)) {
      return (
        <Col span={24} style={style.socialMediaSection}>
          {socialMedia.linkedIn ? (
            <Icon style={style.icon} type="linkedIn" theme="filled" />
          ) : null}
          {socialMedia.twitter ? (
            <Icon style={style.icon} type="twitter" />
          ) : null}
          {socialMedia.facebook ? (
            <Icon style={style.icon} type="facebook" theme="filled" />
          ) : null}
          {socialMedia.facebook ? (
            <Icon style={style.icon} type="mail" />
          ) : null}
        </Col>
      );
    } else {
      return (
        <Col span={24} style={style.socialMediaSection}>
          {/* <Icon style={style.icon} type="linkedin" theme="filled" />
          <Icon style={style.icon} type="twitter" />
          <Icon style={style.icon} type="facebook" theme="filled" /> */}
          <Icon
            onClick={() => setOpen({ ...open, phoneNumber: true })}
            style={{ ...style.icon, ...style_icon }}
            type="phone"
            theme="filled"
          />
          <Icon
            onClick={() => setOpen({ ...open, email: true })}
            style={{ ...style.icon, ...style_icon }}
            theme="filled"
            type="mail"
          />
          <Icon
            onClick={() => setOpen({ ...open, address: true })}
            style={{ ...style.icon, ...style_icon }}
            theme="filled"
            type="environment"
          />
          <Icon
            onClick={() => setOpen({ ...open, anotherContact: true })}
            style={{ ...style.icon, ...style_icon }}
            type="global"
          />
        </Col>
      );
    }
  };

  const _dropdownMenu = () => (
    <Menu style={{ padding: 10 }}>
      {/* <Menu.Item key="0">Message</Menu.Item> */}
      {userId !== profileId && (
        <Menu.Item key="0" onClick={() => setVisibleKudos(true)}>
          Berikan Kudos
        </Menu.Item>
      )}
      {isFollowingFollowed && userId !== profileId && (
        <Menu.Item
          key="1"
          onClick={() => setOpen({ ...open, giveRecomendation: true })}
          // onClick={() => handleRefferal('give')}
        >
          Give Referral
        </Menu.Item>
      )}
      {isFollowingFollowed && userId !== profileId && (
        <Menu.Item
          key="2"
          onClick={() => setOpen({ ...open, requestRecomendation: true })}
          // onClick={() => handleRefferal('request')}
        >
          Request Referral
        </Menu.Item>
      )}
      {userId === profileId && (
        <Menu.Item
          key="3"
          onClick={() => setOpen({ ...open, askForRecommendation: true })}
        >
          Minta Referensi
        </Menu.Item>
      )}
      <Menu.Item key="4" onClick={() => handleShareProfile()}>
        Bagikan Profil (Salin URL)
      </Menu.Item>
      {userId !== profileId && (
        <Menu.Item key="5">
          <a href={url} target="_blank" rel="noopener noreferrer">
            Pesan
          </a>
        </Menu.Item>
      )}
      {userId !== profileId && (
        <Menu.Item
          key="6"
          style={{ color: 'red' }}
          onClick={() => handleBlock()}
        >
          Blokir
        </Menu.Item>
      )}
      {userId === profileId && (
        <Menu.Item
          key="7"
          onClick={() => setOpen({ ...open, buildResume: true })}
        >
          Buat Resume
        </Menu.Item>
      )}
    </Menu>
  );

  const _informationLeft = () => (
    <div>
      <span
        className="name"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {mainProfile.name}{' '}
        {/* {mainProfile.badge === null ? null : (
          <AvatarStyled
            style={{ marginLeft: 10 }}
            size={31}
            src={mainProfile.badge}
          />
        )} */}
        {mainProfile.is_verified && (
          <AvatarStyled
            style={{ marginLeft: 10 }}
            size={22}
            src={Assets.ICON.VERIFIED}
          />
        )}
      </span>
      <span className="desc">{mainProfile.biography} </span>
      <span>{mainProfile.location}</span>
      <div className="count">
        <div
          className="total"
          onClick={() => props.history.push(`/followers/${profileId}/`)}
        >
          {isFetchingConnection ? (
            <DisplayLoading />
          ) : (
            <>
              <span className="number">{follower}</span>
              {` Pengikut`}
            </>
          )}
        </div>
        <div
          className="total"
          onClick={() => props.history.push(`/following/${profileId}/`)}
        >
          {isFetchingConnection ? (
            <DisplayLoading />
          ) : (
            <>
              <span className="number">{following}</span>
              {` Mengikuti`}
            </>
          )}
        </div>
      </div>
      <div className="btnBannerGroup">
        {userId === profileId && (
          <ButtonWlb
            type="primary"
            onClick={() => setOpen({ ...open, settingSection: true })}
            style={style.removeBorderRight}
          >
            Pengaturan
          </ButtonWlb>
        )}
        {userId !== profileId && !isFetchingConnection && (
          <ButtonWlb
            type={isFollow ? 'default' : 'primary'}
            disabled={isLoadingFollow}
            style={{
              width: 180,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0
            }}
            onClick={() =>
              isFollow
                ? handleFollow('unfollow', mainProfile.name)
                : handleFollow('follow', mainProfile.name)
            }
          >
            {isFollow ? 'Mengikuti' : 'Ikuti'}
          </ButtonWlb>
        )}
        {!isFetchingConnection && (
          <div>
            <KudosMenus
              visible={visibleKudos}
              setVisible={setVisibleKudos}
              kudosList={kudosList}
              onClickItem={item => {
                setSelectedKudos(item);
                setVisibleKudosPost(true);
                setVisibleKudos(false);
              }}
            />

            {selectedKudos && (
              <KudosPostBox
                setSelectedKudos={setSelectedKudos}
                selectedKudos={selectedKudos}
                visible={visibleKudosPost}
                setVisible={setVisibleKudosPost}
                mainProfile={mainProfile}
                postType={Constants.POST_TYPE.GLOBAL_TIMELINE}
                setLoading={setIsFetchingConnection}
                setVisiblePost={setVisibleKudosPost}
              />
            )}

            <Dropdown
              overlay={_dropdownMenu}
              trigger={['click']}
              placement="bottomLeft"
              disabled={isLoadingFollow}
            >
              <ButtonWlb
                type="primary"
                // onClick={null}
                style={style.removeBorderLeft}
              >
                <Icon type="down" />
              </ButtonWlb>
            </Dropdown>
          </div>
        )}
        {!isFetchingConnection && (
          <>
            <SettingSection
              open={open.settingSection}
              onClose={() => setOpen({ ...open, settingSection: false })}
            />
            <GiveRecomendation
              profileId={profileId}
              open={open.giveRecomendation}
              onClose={() => setOpen({ ...open, giveRecomendation: false })}
              name={mainProfile.name}
              refferalType={refferalType}
            />
            <BuildAResume
              open={open.buildResume}
              onClose={() => setOpen({ ...open, buildResume: false })}
            />
            <RequestRecommendation
              open={open.requestRecomendation}
              onClose={() => setOpen({ ...open, requestRecomendation: false })}
              name={mainProfile.name}
            />
            <RequestRecommendation
              open={open.askForRecommendation}
              onClose={() => setOpen({ ...open, askForRecommendation: false })}
            />
            <AddressDomisili
              isUser={userId === profileId}
              profileId={profileId}
              stateOpen={open}
              setOpen={setOpen}
              open={open.address}
              handleClose={() => setOpen({ ...open, address: false })}
            />
            <PhoneNumber
              isUser={userId === profileId}
              profileId={profileId}
              stateOpen={open}
              setOpen={setOpen}
              open={open.phoneNumber}
              handleClose={() => setOpen({ ...open, phoneNumber: false })}
            />
            <EmailAddress
              isUser={userId === profileId}
              profileId={profileId}
              stateOpen={open}
              setOpen={setOpen}
              open={open.email}
              handleClose={() => setOpen({ ...open, email: false })}
            />
            <AnotherContact
              isUser={userId === profileId}
              profileId={profileId}
              stateOpen={open}
              setOpen={setOpen}
              open={open.anotherContact}
              handleClose={() => setOpen({ ...open, anotherContact: false })}
            />
          </>
        )}
      </div>
    </div>
  );

  const TD_STYLE = { whiteSpace: 'nowrap', verticalAlign: 'baseline' };

  const _informationRight = () => (
    <div>
      <table>
        <tbody>
          <tr>
            <td style={TD_STYLE}>Nama</td>
            <td>{mainProfile.official_name || '-'}</td>
          </tr>
          <tr>
            <td style={TD_STYLE}>Nama Panggilan</td>
            <td>{mainProfile.nickname || '-'}</td>
          </tr>
          <tr>
            <td style={TD_STYLE}>Posisi Sekarang</td>
            <td>
              {mainProfile.currentPosition +
                ' at ' +
                mainProfile.currentCompany}
            </td>
          </tr>
          <tr>
            <td style={TD_STYLE}>Pendidikan</td>
            <td>{mainProfile.education || '-'}</td>
          </tr>
        </tbody>
      </table>
      {_SocialMedia()}
    </div>
  );

  const _Banner = (informationLeft, informationRight) => (
    <>
      <div>
        <WrapperHeader>
          <WrapperButton>
            {userId === profileId && (
              <ButtonEdit
                onClick={() => setOpenIntro({ open: true, data: dataEdit })}
              >
                <Icon type={'edit'} />
              </ButtonEdit>
            )}
          </WrapperButton>
          <BannerBoxWrap>
            <div className="avatar">
              <img
                src={
                  mainProfile.avatar
                    ? (userData?.thumbnail_url ?? '') + mainProfile.avatar
                    : Assets.DEFAULT_PHOTO
                }
                alt="name"
              />
              {userId === profileId && (
                <div
                  onClick={() => setOpen({ ...open, changeProfile: true })}
                  className="absolute-icon"
                >
                  <Icon
                    className="icon-profile-camera"
                    style={style.iconChangeProfile}
                    type="camera"
                    theme="filled"
                  />
                </div>
              )}
            </div>
            <div className="information">{informationLeft}</div>
            <div className="information information-right">
              {informationRight}
            </div>
          </BannerBoxWrap>
        </WrapperHeader>
        {children}
      </div>
      {!isFetchingConnection && (
        <ChangePhotoProfile
          refetch={refetch}
          open={open.changeProfile}
          onClose={() => setOpen({ ...open, changeProfile: false })}
        />
      )}
    </>
  );

  return (
    <div>
      {loading ? (
        displayLoading(loading)
      ) : (
        <BoxShadow>
          {_Banner(_informationLeft(), _informationRight())}
        </BoxShadow>
      )}
    </div>
  );
};

export default BannerProfileComponent;
