import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import { ShowMoreText } from '../../../../Components/DetailUserSection/SeeMoreText';
import AddEdit from './AddEdit';
import MoreExperience from './MoreExperience';
import ExperienceItem from './ExperienceItem';

export default function Project({
  isUser,
  dataOrganization,
  refetch,
  people_profile,
  profileId,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: false,
      actionType: 'edit',
      data
    }));
  };

  const _data = [...(dataOrganization || [])].slice(0, 2);
  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title="Organisasi"
        action={isUser ? 'plus' : null}
      >
        {_data?.map((res, i) => (
          <ExperienceItem
            key={i}
            avatar={res.avatar}
            isUser={isUser}
            title={res.title}
            company={res.company}
            date={res.time}
            location={res.location}
            description={res.description}
            data={res}
            organizationType={res.dataEdit?.organization_type}
            onClickEdit={() => handleEditItem(res.dataEdit)}
            noDivider={_data.length === i + 1}
            contentPadding="25px 0px"
            contentWidth="650px"
            dividerWidth="720px"
          />
        ))}
        {dataOrganization?.length > 2 && (
          <ShowMoreText
            label="Tampilkan Lebih Banyak Organisasi..."
            onClick={() => {
              setOpen(e => ({
                ...e,
                openMore: true
              }));
            }}
          />
        )}
      </DetailUserSection>
      <AddEdit
        setOpen={setOpen}
        openState={open}
        profileId={profileId}
        people_profile={people_profile}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onClose={() =>
          setOpen({
            open: false,
            data: null,
            actionType: null,
            fromList: false,
            openMore: open.fromList ? true : false
          })
        }
      />
      <MoreExperience
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataOrganization}
      />
    </>
  );
}
