import { Button, Checkbox, Modal } from 'antd';
import React, { memo, useState } from 'react';
import { ModalWrapperDeleteCheck } from './ModalDeleteCheck.style';

export default memo(function ModalDeleteCheck({
  centered,
  open,
  target,
  message,
  onClose,
  onDelete
}) {
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    onClose();
    setChecked(false);
  };

  const handleDelete = () => {
    onDelete();
    setChecked(false);
  };

  return (
    <Modal
      centered={centered}
      visible={open}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalWrapperDeleteCheck>
        <div className="header">Hapus {target}?</div>
        <div className="body">
          <p>
            Apakah Anda yakin akan menghapus{' '}
            <span style={{ color: '#ef4d5e' }}>{target.toLowerCase()}</span> ini
            secara permanen?
          </p>
          <div className="check">
            <Checkbox
              // style={{backgroundColor: '#ef4d5e', borderColor: '#ef4d5e'}}
              checked={checked}
              onChange={() => setChecked(!checked)}
              className="check-action"
            />
            <p>{message}</p>
          </div>
        </div>
        <div className="action">
          <span className="cancel" onClick={handleClose}>
            Batalkan
          </span>
          <Button
            disabled={!checked}
            onClick={handleDelete}
            type="danger"
            className="delete"
          >
            Hapus
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Modal>
  );
});
