import React from 'react';
import {
  WrapperDomisili,
  WrapperContent,
  TextGrey,
  AddressName,
  TextBlue,
  MainAddress
} from './AddressDomisili.style';
import { Icon } from 'antd';
import { ADDRESS_TYPE, LIVING_STATUS, OWNER_STATUS } from './constant';

export default function ListAddress({
  onClickAction,
  data,
  onClickSetMain,
  isUser,
  isVisible,
  onClickVisibility
}) {
  const { address, postal_code, is_main, address_fields, address_type } = data;

  return (
    <WrapperDomisili>
      <WrapperContent>
        <AddressName>{address}</AddressName>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextGrey>{OWNER_STATUS[address_fields?.owner_status]}</TextGrey>
          <TextGrey style={{ margin: '0px 12px 5px 12px' }}>-</TextGrey>
          <TextGrey>{LIVING_STATUS[address_fields?.living_status]}</TextGrey>
        </div>
        <TextGrey>{`${data?.city?.name ?? '-'}, ${data?.province?.name ??
          '-'}`}</TextGrey>
        <TextGrey>{postal_code}</TextGrey>
        <TextGrey>{ADDRESS_TYPE[address_type]}</TextGrey>
        {is_main ? (
          <MainAddress>Alamat Utama</MainAddress>
        ) : isUser ? (
          <TextBlue onClick={onClickSetMain} margintop="10px">
            Tetapkan Sebagai Alamat Utama
          </TextBlue>
        ) : null}
      </WrapperContent>
      <div>
        {isUser && (
          <Icon
            style={{
              marginRight: 21,
              color: isVisible ? '#039be5' : '#a9a8a8'
            }}
            onClick={onClickVisibility}
            type={`eye${isVisible ? '' : '-invisible'}`}
            theme="filled"
          />
        )}
        {isUser && (
          <Icon onClick={onClickAction} type={`edit`} theme="filled" />
        )}
      </div>
    </WrapperDomisili>
  );
}
