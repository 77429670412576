import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
`;

const Container = styled.div`
  position: relative;
`;

export { Content, Container };
