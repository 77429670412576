import React from 'react';
import { useQuery } from 'react-apollo';
import OnGoingFeedbackComponent from './OnGoingFeedback.component';
import { GET_LIST_COUNT_EMOT } from './OnGoingFeedback.graphql';

export default function OnGoingFeedback({ profileId }) {
  const { data, loading } = useQuery(GET_LIST_COUNT_EMOT, {
    variables: {
      user: profileId
    },
    context: { headers: { 'X-Hasura-Role': 'organization-staff' } }
  });

  return <OnGoingFeedbackComponent loading={loading} data={data} />;
}
