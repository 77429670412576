import { Button, Divider, Modal } from 'antd';
import React from 'react';
import { ModalWrapper } from './ModalConfirmation.style';

export default function ModalConfirmation({
  open,
  title,
  content,
  onCancel,
  onSubmit,
  textPrimary = '',
  buttonSize = 'small',
  alert = false
}) {
  return (
    <Modal
      className="class-modal-confirmation-action-wlb-small"
      centered
      title={null}
      visible={open}
      closable={false}
      bodyStyle={{ padding: 0 }}
      footer={null}
    >
      <ModalWrapper>
        <div className={`title ${alert ? 'alert' : 'primary'}`}>{title}</div>
        <Divider />
        <div className="content">{content}</div>
        <div className="action">
          <Button
            className="btn-wlb-cancel"
            onClick={onCancel}
            style={{ marginRight: 10 }}
          >
            Batalkan
          </Button>
          <Button
            className={`btn-wlb-${buttonSize}`}
            onClick={onSubmit}
            type={alert ? 'danger' : 'primary'}
          >
            {textPrimary}
          </Button>
        </div>
      </ModalWrapper>
    </Modal>
  );
}
