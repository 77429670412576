import React from 'react';
import { SearchAndFilter } from './SearchAndFilter.style';
import { Input } from 'antd';

const { Search } = Input;

const SearchAndFilterComponent = props => {
  const { handleSearch, totalEmployee } = props;
  let timeout = 0;

  const quickSearch = e => {
    const keyword = e.target.value.trim();
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      handleSearch(props.location, keyword);
    }, 1000);
  };
  return (
    <SearchAndFilter
      style={{ width: '100%', padding: '10px 20px', margin: '14px 0px' }}
    >
      <label className={'total-employee'}>{totalEmployee} Pegawai</label>
      <Search placeholder="Cari Pegawai" onChange={quickSearch} />
    </SearchAndFilter>
  );
};

export default SearchAndFilterComponent;
