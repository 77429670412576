import { Avatar, Card } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ButtonWlb } from '../../Components';
import {
  // Company,
  Description,
  JobDetails,
  UserCard
} from './FollowerCard.style';
import { AuthenticationService } from '../../Services';
const FollowerCardComponent = props => {
  const { user, buttonHandler, isFollow, loading } = props;
  const peoplePosition =
    user?.people_work_placements?.[0]?.company_employee_position?.name ?? '';
  const peopleCompany =
    user?.people_work_placements?.[0]?.company_profile?.brand_name ?? '-';
  const ownerID = AuthenticationService.getUserId();
  const userData = AuthenticationService.getUserData();
  const _renderButton = isFollow => {
    return (
      <ButtonWlb
        type={isFollow ? 'default' : 'primary'}
        size={'small'}
        style={{ width: 85, marginTop: 13 }}
        onClick={() => buttonHandler(user.id, user.name)}
        disabled={loading}
      >
        {isFollow ? 'Following' : 'Follow'}
      </ButtonWlb>
    );
  };

  return (
    <UserCard key={user.name}>
      <Card className={'user-card'}>
        <Link to={`/profile/${user.id}/detail`}>
          <div className={'user-card-meta'}>
            <Card.Meta
              avatar={
                <Avatar
                  src={(userData?.thumbnail_url ?? '') + user.avatar}
                  size={50}
                />
              }
              title={user.name}
              description={
                <Description>
                  <JobDetails>{peoplePosition}</JobDetails>
                  <JobDetails>{peopleCompany}</JobDetails>
                </Description>
              }
            />
          </div>
        </Link>
        {user.id !== ownerID && _renderButton(isFollow)}
      </Card>
      {/*<div className={"social-media-wrapper"}>*/}
      {/*  <div className={"social-media"}>*/}
      {/*    {user.socialMedia.map(socialMedia => {*/}
      {/*      let socialMediaName = Object.keys(*/}
      {/*          socialMedia)[0];*/}
      {/*      return (<Icon key={socialMediaName} type={socialMediaName}/>)*/}
      {/*    })}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </UserCard>
  );
};

export default FollowerCardComponent;
