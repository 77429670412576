import { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AuthenticationService } from '../../Services';

const GET_PEOPLE_PROFILE = gql`
  query getProfile($id: uuid!) {
    main_profile: global_users(where: { id: { _eq: $id } }) {
      id
      name
      avatar
      working_status: people_work_placements(
        where: { user: { _eq: $id }, status: { _eq: "ACTIVE" } }
        limit: 1
      ) {
        company_profile {
          id
          brand_name
        }
        company_job_profile {
          id
          title
          company_address {
            id
            office_name
          }
        }
      }
    }
  }
`;

const useGetProfile = () => {
  const [profilePhoto, setPhotoProfile] = useState('');
  const [profileName, setProfileName] = useState('');
  const [working, setWorking] = useState({
    position: '',
    brand_name: ''
  });

  const userId = AuthenticationService.getUserId();
  const OPTIONS = {
    context: { headers: { role: 'user' } },
    variables: {
      id: userId
    }
  };

  const { data } = useQuery(GET_PEOPLE_PROFILE, OPTIONS);

  useEffect(() => {
    if (data) {
      setPhotoProfile(data.main_profile[0].avatar);
      setProfileName(data.main_profile[0].name);
      setWorking({
        position:
          data.main_profile[0].working_status?.[0]?.company_job_profile
            ?.title ?? '-',
        brand_name:
          data.main_profile[0].working_status?.[0]?.company_job_profile
            ?.company_address?.office_name ?? '-'
      });
    }
  }, [data]);

  return [profileName, profilePhoto, working.position, working.brand_name];
};

export default useGetProfile;
