import { Icon } from 'antd';
import React from 'react';
import { ModalEdit } from '../../Components';
import { ItemWrapperDetail } from './UserDependent.style';
import dateTime from '../../Utils/dateTime';

const _gender = {
  M: 'Male',
  F: 'Female'
};

export default function DetailDependent({
  open,
  onClose,
  data,
  onEdit,
  isUser
}) {
  const {
    name,
    relationship,
    is_beneficiary,
    birthplace,
    birthdate,
    gender,
    childDependentAge,
    identity_number,
    marital_status,
    working_status,
    civil_servant,
    is_company_group_employee,
    dependent_status,
    dependant_fields,
    blood_type,
    address,
    postal_code,
    global_country,
    company
  } = data || {};

  return (
    <ModalEdit
      padding="22px"
      centered
      title="Dependent Detail"
      open={open}
      onClose={onClose}
    >
      <ItemWrapperDetail>
        <div className="head">
          <h4 className="user-name">{name}</h4>
          {isUser && <Icon type="edit" onClick={onEdit} className="icon" />}
        </div>
        <div className="content-wrapper">
          <ItemDetail label="Nama" value={name} />
          <ItemDetail label="Hubungan" value={relationship} />
          <ItemDetail
            label="Penerima"
            value={is_beneficiary ? 'Ya' : 'Tidak'}
          />
          <ItemDetail label="Tempat Lahir" value={birthplace} />
          <ItemDetail
            label="Tanggal Lahir"
            value={dateTime.formatedDate(birthdate, 'DD MMMM YYYY')}
          />
          <ItemDetail label="Jenis Kelamin" value={_gender[gender]} />
          <ItemDetail label="Usia Tanggungan" value={childDependentAge} />
          <ItemDetail label="Nomor Identitas" value={identity_number} />
          <ItemDetail label="Status Perkawinan" value={marital_status} />
          <ItemDetail label="Status Pekerjaan" value={working_status} />
          <ItemDetail
            label="Aparatur Sipil Negara"
            value={civil_servant ? 'Ya' : 'Tidak'}
          />
          <ItemDetail
            label="Pegawai Swasta"
            value={is_company_group_employee ? 'Ya' : 'Tidak'}
          />
          <ItemDetail label="Perusahaan" value={company} />
          <ItemDetail label="Status Tanggungan" value={dependent_status} />
          <ItemDetail
            label="Nomor Telepon"
            value={dependant_fields?.phone_code + dependant_fields?.phone}
          />
          <ItemDetail label="Golongan Darah" value={blood_type} />
          <ItemDetail label="Alamat" value={address} />
          <ItemDetail label="Negara" value={global_country?.country_name} />
          <ItemDetail label="Kode Pos" value={postal_code} />
        </div>
      </ItemWrapperDetail>
    </ModalEdit>
  );
}

const ItemDetail = ({ label, value }) => (
  <div className="content">
    <p className="label">{label}</p>
    <p className="value">{value || '-'}</p>
  </div>
);
