import React, { useState, useEffect } from 'react';
import BannerDetailUserComponent from './BannerDetailUser.component';
import { useQuery, useLazyQuery } from 'react-apollo';
import { GET_MAIN_PROFILE, GET_USER_BADGE } from './BannerDetailUser.graphql';
import { useAsyncEffect } from 'use-async-effect';
import {
  ConnectionService,
  AuthenticationService,
  FollowService,
  UserProfile
} from '../../Services';
import { message } from 'antd';
import {
  LIST_CITY,
  LIST_PROVINCE,
  LIST_RELIGIONS,
  EDUCATION_BY_ID
} from '../UserProfileAbout/About.graphql';
import gql from 'graphql-tag';

const userId = AuthenticationService.getUserId();

const BannerDetailUserContainer = props => {
  const [follower, setFollower] = useState(0);
  const [following, setFollowing] = useState(0);
  const [isFetchingConnection, setIsFetchingConnection] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const [openIntro, setOpenIntro] = useState({
    open: false,
    data: null
  });
  const [idEducation, setIdEducation] = useState(null);
  const [province, setProvince] = useState(null);
  // const [isFetchingUserDetail, setIsFetchingUserDetail] = useState(false);

  const profileId = props.match.params.profileId;
  // const [isFetchingUserDetail, setIsFetchingUserDetail] = useState(false);
  const DATA_FOLLOW = gql`
    query {
      getTotalFollowersCompany(company: "${profileId}") {
        total
        is_following
        is_followed
      }
    }
  `;

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data: dataFollow, refetch: refectchFolwdFolwing } = useQuery(
    DATA_FOLLOW,
    {
      context: CONTEXT_USER
    }
  );

  useAsyncEffect(
    async isMounted => {
      if (!isMounted()) return;
      setIsFetchingConnection(true);
      await ConnectionService.totalFollowerFollowing(profileId).then(
        response => {
          if (!isMounted()) return;
          setFollowing(response.data.total_following);
          setFollower(response.data.total_follower);
          setIsFetchingConnection(false);
        }
      );
      if (!isMounted()) return;
      setIsLoadingFollow(true);
      await UserProfile.getUserInfo(profileId).then(res => {
        if (!isMounted()) return;
        setIsFollow(res.isFollowed);
        setIsLoadingFollow(false);
      });
    },
    [profileId]
  );

  const handleFollow = (type, name) => {
    setIsLoadingFollow(true);
    if (type === 'follow') {
      FollowService.followUser(profileId)
        .then(() => {
          setIsFollow(true);
          setIsLoadingFollow(false);
          setFollower(follower + 1);
          message.success(`Berhasil Mengikuti ${name}`);
          refectchFolwdFolwing();
        })
        .catch(() => message.error('Something went wrong'));
    } else {
      FollowService.unFolloweUser(profileId)
        .then(() => {
          setIsFollow(false);
          setIsLoadingFollow(false);
          setFollower(follower - 1);
          message.success(`Berhasil Berhenti Mengikuti ${name}`);
          refectchFolwdFolwing();
        })
        .catch(() => message.error('Something went wrong'));
    }
  };

  const handleShareProfile = () => {
    // window.location.href;
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => message.success('URL copied to clipboard'));
  };

  const handleBlock = () => {
    setIsLoadingFollow(true);
    UserProfile.addBlockedUser(profileId).then(() => {
      message.success('Block success');
      setIsLoadingFollow(false);
      refectchFolwdFolwing();
    });
  };

  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'public' } },
    variables: {
      id: profileId
    }
  };
  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const { data, loading, refetch } = useQuery(GET_MAIN_PROFILE, OPTIONS);
  const { data: dataBadge } = useQuery(GET_USER_BADGE, {
    variables: {
      id: profileId
    },
    context: { headers: { 'X-Hasura-Role': 'organization-staff' } }
  });

  const [getCity, { data: dataCity }] = useLazyQuery(LIST_CITY, {
    context: CONTEXT_PUBLIC
  });
  const [getProvince, { data: dataProvince }] = useLazyQuery(LIST_PROVINCE, {
    context: CONTEXT_PUBLIC
  });
  const [getReligion, { data: dataReligion }] = useLazyQuery(LIST_RELIGIONS, {
    context: CONTEXT_PUBLIC
  });
  const [getEducation, { data: dataEducation }] = useLazyQuery(
    EDUCATION_BY_ID,
    {
      context: CONTEXT_PUBLIC
    }
  );

  useEffect(() => {
    getReligion();
    getProvince();
    getCity({
      variables: {
        limit: 10,
        where: { province: province ? { _eq: province } : undefined }
      }
    });

    if (data?.profile_about?.[0]?.global_user?.main_education || idEducation) {
      getEducation({
        variables: {
          id:
            idEducation ?? data?.profile_about?.[0]?.global_user?.main_education
        }
      });
    }
  }, [
    getCity,
    getProvince,
    getReligion,
    province,
    data,
    idEducation,
    getEducation
  ]);

  let profile = {};

  if (data) {
    const { main_profile, working_status, profile_about } = data;
    props.setNameUser(main_profile?.[0]?.name ?? '-');

    profile = {
      name: main_profile?.[0]?.name ? main_profile?.[0]?.name : '-',
      is_verified: profile_about?.[0]?.is_verified,
      avatar: main_profile?.[0]?.avatar && main_profile[0].avatar,
      biography: main_profile?.[0]?.biography
        ? main_profile?.[0]?.biography
        : '-',
      educationId: profile_about?.[0]?.global_user?.main_education,
      education: idEducation
        ? dataEducation?.people_profile_educations?.[0]?.company_profile
            ?.legal_name ?? null
        : null,
      list_education:
        data?.profile_about?.[0]?.global_user?.people_profile_educations,
      // currentCompany: data.working_status?.[0]?.company_profile?.brand_name
      //   ? data.working_status?.[0]?.company_profile?.brand_name
      //   : '-',
      currentCompany:
        working_status?.[0]?.company_job_profile?.company_address
          ?.office_name || '-',
      // currentPosition: working_status?.[0]?.company_employee_position?.name
      //   ? working_status?.[0]?.company_employee_position?.name
      //   : '-',
      currentPosition: working_status?.[0]?.company_job_profile?.title || '-',
      previous: '-',
      location: [
        working_status?.[0]?.company_job_profile?.company_address?.global_city
          ?.name || '',
        working_status?.[0]?.company_job_profile?.company_address
          ?.global_province?.name || '',
        'Indonesia'
      ]
        .filter(location => location !== '')
        .join(', '),
      social_links: data?.profile_about?.[0]?.social_links
        ? data?.profile_about?.[0]?.social_links
        : '-',
      badge: dataBadge?.talent_assignments?.[0]?.talent_box?.badge ?? null,
      official_name: data?.profile_about?.[0]?.profile_fields?.official_name,
      nickname: data?.profile_about?.[0]?.profile_fields?.initial_name
    };
  }

  const handleRefetch = id => {
    refetch();
    setIdEducation(id);
  };

  return (
    <BannerDetailUserComponent
      {...props}
      setProvince={setProvince}
      // province={province}
      dataCity={dataCity}
      dataProvince={dataProvince}
      dataReligion={dataReligion}
      setOpenIntro={setOpenIntro}
      openIntro={openIntro}
      refetch={handleRefetch}
      mainProfile={profile}
      loading={loading}
      isFetchingConnection={isFetchingConnection}
      setIsFetchingConnection={setIsFetchingConnection}
      follower={follower}
      following={following}
      profileId={profileId}
      userProfile={data}
      userId={userId}
      isFollow={isFollow}
      handleFollow={handleFollow}
      isLoadingFollow={isLoadingFollow}
      handleShareProfile={handleShareProfile}
      handleBlock={handleBlock}
      isFollowingFollowed={
        dataFollow &&
        dataFollow.getTotalFollowersCompany.is_followed &&
        dataFollow.getTotalFollowersCompany.is_following
      }
    />
  );
};

export default BannerDetailUserContainer;
