import React, { useState, useEffect } from 'react';
import { compose, withHandlers } from 'recompose';
import { useParams } from 'react-router-dom';

import handler from './PostBox.handler';
import PostBoxComponent from './PostBox.component';
import { isObjectEmpty, isHashtag } from '../../Utils/CommonUtils';
import Constants from '../../Config/Constants';
import { useQuery, useMutation, useApolloClient } from 'react-apollo';
import { GET_LIST_EVENT } from './ListEvent.graphql';
import { GET_LIST_TASK } from './ListTask.graphql';
import { GET_LIST_MEMBER } from './ListClassroomMembers.graphql';
import { GET_ACTIVITIES } from './ListActivities.graphql';
import { GET_COMPETENCIES } from './ListCompetencies.graphql';
import { ADD_COMPETENCY } from './createPost.graphql';
import { AuthenticationService } from '../../Services';
import { useScriptGMaps } from '../../SharedComponents/CustomHook';
import moment from 'moment';

const PostBoxContainer = props => {
  const { postType, sharedPost, onSubmit, edit, editData } = props;

  const client = useApolloClient();
  const params = useParams();
  const classroomId = params.id;
  const initialData = {
    type: 0,
    content: '',
    images: [],
    videos: [],
    tags: [],
    files: [],
    privacy: 0,
    canSee: [],
    hashtag: [],
    sharedPost: {},
    event: undefined,
    location: {},
    task: undefined,
    poll: {
      duration: 0,
      question: '',
      options: ['', '']
    }
  };

  const [isShowSpecificFollower, setIsShowModal] = useState(false);
  const [isFocus, setFocusPost] = useState(false);
  const [isSelectPrivacy, setSelectPrivacy] = useState(false);
  const [post, setPost] = useState(initialData);
  const [utility, setUtility] = useState('');
  const [tagsUtility, setTagsUtility] = useState(false);
  const [locationUtility, setLocationUtility] = useState(false);
  const { IMAGE, FILES, TAGS, LOCATION } = Constants.UTILITIES;
  const { VALUE } = Constants.MESSAGES.POST_BOX;
  // const [hashtagUtility, setHashtagUtility] = useState(false);

  const [selectedEvent, setEvent] = useState(null);
  const [selectedTask, setTask] = useState(null);

  const [listCompanies] = useState([]);
  const [eventKeyword, setEventKeyword] = useState('');
  const [taskKeyword, setTaskKeyword] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [dataMentee, setDatamentee] = useState([]);
  const [classroomMember, setClassroomMember] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [competencies, setCompetencies] = useState([]);
  const [competencyValue, setCompetencyValue] = useState({
    searchDelay: '',
    value: ''
  });

  const getListEvent = useQuery(GET_LIST_EVENT, {
    context: { headers: { 'X-Hasura-Role': 'public' } },
    variables: {
      search: `%${eventKeyword}%`,
      limit: 100,
      offset: 0
    }
  });
  const getListTask = useQuery(GET_LIST_TASK, {
    context: { headers: { 'X-Hasura-Role': 'organization-staff' } },
    variables: {
      search: `%${taskKeyword}%`,
      offset: 0,
      limit: 100,
      user: AuthenticationService.getUserId()
    }
  });
  const { data: dataMembers } = useQuery(GET_LIST_MEMBER, {
    context: { headers: { 'X-Hasura-Role': 'user' } },
    variables: { classroom_id: { _eq: classroomId } }
  });
  const { data: dataActivities } = useQuery(GET_ACTIVITIES, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [addCompetency] = useMutation(ADD_COMPETENCY, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  useEffect(() => {
    const editValue = edit === 'edit-learning-log' ? 3 : 0;
    const dateNow = Date.now();
    if (postType === 'classroom') {
      const _dataMentee = [];
      const _members = [];
      const _images = [];

      if (dataMembers) {
        if (editData) {
          editData.classroom_learning_tracker_mentees.map(member => {
            _dataMentee.push({
              id: member.classroom_member.global_user.id,
              name: member.classroom_member.global_user.name,
              avatar: member.classroom_member.global_user.avatar
            });
          });
          dataMembers.classroom_members.map(member => {
            const isChecked = _dataMentee.find(
              e => e.id === member.global_user.id
            );
            _members.push({
              ...member,
              checked: isChecked ? true : false
            });
          });
          if (editData.classroom_post_files.length > 0) {
            editData.classroom_post_files.map((file, i) => {
              if (file.type === 'image/jpeg' || file.type === 'image/png') {
                _images.push({
                  uid: i,
                  name: file.name,
                  file: file.file,
                  type: file.type,
                  size: file.size,
                  status: 'done',
                  url: file.file,
                  response: file.file
                });
              }
            });
            setUtility(IMAGE);
          }
          if (editData.location) {
            setLocationUtility(true);
          }

          setCompetencyValue(prevState => ({
            ...prevState,
            value: editData.learning_log_competency.title
          }));
          setSelectedActivity({
            activity: editData.activity
          });
        } else {
          dataMembers.classroom_members.map(member => {
            _members.push({
              ...member,
              checked: false
            });
          });
        }
      }
      setClassroomMember(_members);
      setDatamentee(_dataMentee);
      setPost({
        ...initialData,
        images: _images,
        type: editValue,
        subject: editData ? editData.subject : null,
        activity: editData ? editData.activity : null,
        date: editData ? editData.date : moment(dateNow).format('YYYY-MM-DD'),
        description: editData ? editData.description : null,
        competencyId: editData ? editData.learning_log_competency.id : 0,
        mentees: _dataMentee,
        duration: editData ? editData.duration : null,
        location: editData ? { ...editData.location } : {}
      });
    } else {
      setPost(initialData);
    }
  }, [postType, editData, dataMembers, edit, IMAGE]);

  useEffect(() => {
    if (dataActivities) {
      setActivities(dataActivities.learning_log_activities);
    }
  }, [dataActivities]);
  useScriptGMaps();

  const handlePost = (target, value) => {
    if (sharedPost) {
      setPost({
        ...post,
        [target]: value,
        sharedPost: {
          userId: sharedPost ? sharedPost.userId : '',
          postId: sharedPost ? sharedPost.id : ''
        }
      });
    } else {
      setPost({
        ...post,
        [target]: value,
        sharedPost: {}
      });
    }

    if (target === 'taskId') {
      setPost({
        ...post,
        task: {
          companyId: AuthenticationService.getUserData().company_id,
          taskId: value
        }
      });
    }

    if (target === 'question') {
      setPost({
        ...post,
        poll: {
          ...post.poll,
          [target]: value
        }
      });
    }

    if (target === 'options') {
      setPost({
        ...post,
        poll: {
          ...post.poll,
          [target]: value
        }
      });
    }

    if (target === 'duration') {
      if (post.type === 3) {
        setPost({
          ...post,
          [target]: value
        });
      } else {
        setPost({
          ...post,
          poll: {
            ...post.poll,
            [target]: value
          }
        });
      }
    }
  };

  const handlePostType = (target, value) => {
    handlePost(target, value);
    setFocusPost(true);
  };

  const handleTask = value => {
    const task = getListTask?.data?.performance_tasks.find(
      item => item.id === value
    );
    setTask(task);
    handlePost('taskId', task.id);
    setFocusPost(true);
  };

  const handleEventName = value => {
    const event = getListEvent?.data?.event_schedules.find(
      item => item.id === value
    );
    setEvent(event);
    handlePost('location', event?.location);
    handlePost('event', event.id);
    setFocusPost(true);
  };

  const handleFocusTextArea = value => setFocusPost(value);

  const handleUtility = target => {
    if (target === TAGS) {
      if (tagsUtility && isObjectEmpty(post.tags)) {
        setTagsUtility(false);
      } else {
        setTagsUtility(true);
      }
    }

    if (target === IMAGE) {
      if (utility === IMAGE && isObjectEmpty(post.images)) {
        setUtility('');
      } else if (isObjectEmpty(post.files)) {
        setUtility(target);
      }
    }
    if (target === FILES) {
      if (utility === FILES && isObjectEmpty(post.files)) {
        setUtility('');
      } else if (isObjectEmpty(post.images)) {
        setUtility(target);
      }
    }
    if (target === LOCATION) {
      if (locationUtility) {
        setLocationUtility(false);
        handlePost('location', {});
      } else {
        setLocationUtility(true);
      }
    }

    // if (target === HASHTAG) {
    //   setHashtagUtility(curr => !curr);
    // }
  };

  const isShowBottomPostBox =
    post.content !== '' ||
    isFocus ||
    utility !== '' ||
    isSelectPrivacy ||
    sharedPost ||
    tagsUtility;

  const formatBody = content => {
    function parseResponse(value) {
      return value.map(val => {
        return {
          name: val.name,
          url: val.response,
          filetype: val.type,
          size: val.size
        };
      });
    }
    function parseCanSee(value) {
      if (value?.global_user) {
        return value.map(val => {
          return val?.global_user?.id;
        });
      } else {
        return value.map(val => {
          return val.id;
        });
      }
    }
    function parseTags(value) {
      return value.map(val => {
        return val.key;
      });
    }
    const images = parseResponse(content.images);
    const videos = parseResponse(content.videos);
    const files = parseResponse(content.files);
    const canSee = parseCanSee(content.canSee);
    const tags = parseTags(content.tags);
    const splittedContent = content.content.trim().split(/\s+/);
    const hashtag = splittedContent.filter(word => isHashtag(word));

    if (content.type !== VALUE.EVENT) {
      delete content?.event;
      // delete content?.location;
    }

    if (content.type !== VALUE.TASK) {
      delete content?.task;
    }
    return {
      ...content,
      images: images,
      videos: videos,
      tags: tags,
      files: files,
      sharedPost: content.sharedPost,
      canSee: canSee,
      location: content.location,
      hashtag: hashtag
    };
  };

  const handleSelectActivity = value => {
    const selected = activities.find(item => item.activity === value);
    handlePost('activity', selected.activity);
    setSelectedActivity(selected);
  };

  const handleSearchAutocomplete = async searchText => {
    const { data } = await client.query({
      query: GET_COMPETENCIES,
      variables: { query: `%${searchText}%` }
    });
    if (data) {
      setCompetencies(data.learning_log_competencies);
    }
  };

  const handleSelectAutocomplete = value => {
    const competency = competencies.find(
      competency => competency.title === value
    );
    handlePost('competencyId', competency.id);
  };

  const handleChangeAutoComplete = e => {
    setCompetencyValue(prevState => ({
      ...prevState,
      value: e
    }));
  };

  const handleSelectMentee = (e, dataMember) => {
    let _data = dataMentee;
    const val = e.target.checked;

    if (val) {
      _data.push({
        id: dataMember.global_user.id,
        name: dataMember.global_user.name,
        avatar: dataMember.global_user.avatar
      });
    } else {
      const index = _data.findIndex(
        val => val.id === dataMember.global_user.id
      );
      _data.splice(index, 1);
    }

    let _members = classroomMember;
    const index = _members.findIndex(
      item => item.global_user.id === dataMember.global_user.id
    );
    _members[index] = { ..._members[index], checked: e.target.checked };

    setClassroomMember([..._members]);
    setDatamentee(_data);
    handlePost('mentees', _data);
  };
  const resetContent = () => {
    setPost(initialData);
    setDatamentee([]);
    setSelectedActivity(null);
  };

  const handleSubmit = content => {
    if (postType !== 'classroom') {
      delete content.activity;
      delete content.date;
      delete content.description;
      delete content.mentees;
      delete content.activity;
      delete content.subject;
      delete content.competencyId;
    }

    if (post.type !== 4) {
      delete content.poll;
    }

    if (post.type === 3) {
      const { value } = competencyValue;
      const searchValue = value.substring(1, value.length - 1);

      const isCompetencyExist = competencies.findIndex(
        item => item === searchValue
      );

      if (isCompetencyExist === -1) {
        addCompetency({ variables: { title: searchValue } }).then(
          ({ data }) => {
            const { id } = data.insert_learning_log_competencies_one;
            content.competencyId = id;
            onSubmit(content, resetContent, formatBody, sharedPost);
          }
        );
      }
      if (isCompetencyExist === 0) {
        onSubmit(content, resetContent, formatBody, sharedPost);
      }
    } else {
      onSubmit(content, resetContent, formatBody, sharedPost);
    }

    setEventKeyword('');
    setTaskKeyword('');
    setUtility('');
    setEvent(null);
    setTask(null);
    setTagsUtility(false);
    setLocationUtility(false);
    setFocusPost(false);
  };

  const handleAddPollingOption = () => {
    const _options = post.poll.options;
    _options.push('');
    handlePost('options', _options);
  };

  const handleRemovePollingOption = index => {
    const _options = post.poll.options;
    _options.splice(index, 1);
    handlePost('options', _options);
  };

  const handleChangePollingOption = (value, key) => {
    const _options = post.poll.options;
    _options[key] = value;
    handlePost('options', _options);
  };

  return (
    <PostBoxComponent
      post={post}
      handlePost={handlePost}
      handleSubmit={handleSubmit}
      handlePostType={handlePostType}
      isShowBottomPostBox={isShowBottomPostBox}
      handleFocusTextArea={handleFocusTextArea}
      handleUtility={handleUtility}
      utility={utility}
      isShowSpecificFollower={isShowSpecificFollower}
      setIsShowModal={setIsShowModal}
      setSelectPrivacy={setSelectPrivacy}
      tagsUtility={tagsUtility}
      selectedEvent={selectedEvent}
      handleEventName={handleEventName}
      listEvent={
        getListEvent?.data?.event_schedules
          ? getListEvent?.data?.event_schedules
          : []
      }
      isFocus={isFocus}
      setEvent={setEvent}
      selectedTask={selectedTask}
      handleTask={handleTask}
      listTask={
        getListTask?.data?.performance_tasks
          ? getListTask?.data?.performance_tasks
          : []
      }
      listCompanies={listCompanies}
      locationUtility={locationUtility}
      // hashtagUtility={hashtagUtility}
      setEventKeyword={setEventKeyword}
      setTaskKeyword={setTaskKeyword}
      setFocusPost={setFocusPost}
      setTask={setTask}
      isUploading={isUploading}
      setIsUploading={setIsUploading}
      sharedPost={sharedPost}
      classroomMember={classroomMember}
      dataMentee={dataMentee}
      handleSelectMentee={handleSelectMentee}
      listActivity={activities}
      selectedActivity={selectedActivity}
      handleSelectActivity={handleSelectActivity}
      onSearchAutocomplete={handleSearchAutocomplete}
      onSelectAutocomplete={handleSelectAutocomplete}
      onChangeAutocomplete={handleChangeAutoComplete}
      listCompetence={competencies}
      competencyValue={competencyValue}
      edit={edit}
      handleAddPollingOption={handleAddPollingOption}
      handleChangePollingOption={handleChangePollingOption}
      handleRemovePollingOption={handleRemovePollingOption}
      {...props}
    ></PostBoxComponent>
  );
};

export default compose(withHandlers(handler))(PostBoxContainer);
