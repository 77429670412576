import React from 'react';
import TagPeopleComponent from './TagPeople.component';
import Constant from '../../Config/Constants';
import Global from './global';
import CompanyPost from './companyPost';
import CompanyTimeline from './companyTimeline';
import GroupPost from './team';
import ProfilePost from './userProfile';
import Classroom from './classroom';

function TagPeople(props) {
  const postType = props.postType;
  switch (postType) {
    case Constant.POST_TYPE.GLOBAL_TIMELINE:
      return <Global {...props} />;
    case Constant.POST_TYPE.COMPANY:
      return <CompanyPost {...props} />;
    case Constant.POST_TYPE.COMPANY_TIMELINE:
      return <CompanyTimeline {...props} />;
    case Constant.POST_TYPE.TEAM:
      return <GroupPost {...props} />;
    case Constant.POST_TYPE.PROFILE:
      return <ProfilePost {...props} />;
    case Constant.POST_TYPE.CLASSROOM:
      return <Classroom {...props} />
    default:
      return <TagPeopleComponent {...props} />;
  }
}
export default TagPeople;
