import React from 'react';
import PostScreen from './PostScreen';
import DetailScreen from './DetailScreen';
import HeaderProfile from './HeaderProfile.component';

// import PostId from './PostScreen/PostScreenId';
const menuBarList = [
  {
    renderComponent: props => (
      <HeaderProfile {...props} displayTabs={true}>
        <DetailScreen {...props} />
      </HeaderProfile>
    ),
    name: 'Detail',
    path: '/profile/:profileId/detail'
  },
  {
    renderComponent: props => (
      <HeaderProfile {...props} displayTabs={false}>
        <PostScreen {...props} />
      </HeaderProfile>
    ),
    name: 'Postingan',
    path: '/profile/:profileId/post'
  }
  // {
  //   renderComponent: props => <PostId displayTabs={false} {...props} />,
  //   name: 'PostId',
  //   path: '/profile/:profileId/post/:postId'
  // }
];
const defaultMenuBar = 'Detail';

export default {
  menuBarList,
  defaultMenuBar
};
