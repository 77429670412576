import { message } from "antd";
import { useState } from "react";
import { Notification } from "../../Services";

const useGetNotification = (limit = 4) => {
  const [data, setData] = useState(undefined);

  const fetchData = () => {
    Notification.getNotification(1, limit)
      .then((response) => {
        const { docs } = response;
        setData(docs);
      })
      .catch(() => {
        message.error("Something went wrong");
      })
  };

  return [data, fetchData]
}

export default useGetNotification;