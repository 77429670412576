import { UploadService } from '../../Services';

const handleUploadVideo = () => async ({ onSuccess, file }) => {
  const { link: AWSUploadInformation } = await UploadService.getVideoCredential(
    file
  );
  await UploadService.uploadVideo(AWSUploadInformation, file);

  onSuccess(AWSUploadInformation.path, file);
};

const handleUpload = () => async ({ onSuccess, file }) => {
  const { url } = await UploadService.uploadFile(file);
  onSuccess(url, file);
};

const searchFollower = () => async value => {
  return [];
};

export default {
  handleUploadVideo,
  handleUpload,
  searchFollower
};
