import { Avatar } from 'antd';
import Styled from 'styled-components';

export const WrapperScroll = Styled.div`
  max-height: ${props => (props.maxHeight ? props.maxHeight : '450px')};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16); 
    border-radius: 5px;
  }
`;

export const WrapperExperience = Styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    padding: ${props => props.contentPadding || '25px 33px 30px 33px'};

    .avatar-wrapper {
      width: 100px;
      height: 100px;
      @media (max-width: 1199px) {
       width: 50px;
       height: 50px;
      }
      margin-right: 24px;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      max-width: ${props => props.contentWidth || '580px'};
      margin-right: auto;

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 8px;
      }

      .company {
        font-size: 12px;
        color: #007fb2;
        margin-bottom: 8px;
      }

      .date {
        font-size: 12px;
        text-align: left;
        color: #a9a8a8;
        margin-bottom: 8px;
      }
      .line-of-business {
        font-size: 12px;
        text-align: left;
        color: #a9a8a8;
        margin-bottom: 12px;
      }
      .italic {
        font-style: italic;
      }
      .location {
        font-size: 12px;
        text-align: left;
        color: #014a62;
        margin-bottom: 8px;
      }
      .description {
        font-size: 12px;
        color: #000000;
        white-space: break-spaces;
        overflow-wrap: break-word;
      }
      .reference {
        color: #007fb2;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .ref {
        font-size: 12px;
        margin: 0 0 2px 0;
      }
      .ref-name {
        color: #000;
      }
      .ref-position {
        color: #a9a8a8;
      }

      .see-more {
        margin-top: 16px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #a9a8a8;
      }
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: ${props => props.dividerWidth || '580px'};
    margin-left: auto
}
`;

export const AvatarStyled = Styled(Avatar)`
  width: 100% !important;
  height: 100% !important;

  img {
    width: auto !important;
    height: 100% !important
    margin: auto;
  }
`;
