import React, { useEffect } from 'react';
import { Form, Select, Input, Button, message } from 'antd';
import { TitleField, Headline, SelectedPepole } from './Recommendation.style';
import { useMutation, useLazyQuery } from 'react-apollo';
import { generateValue } from '../../../../Components/ActionMorePeopleProfile/ActionMoreHelper';
import { ModalEdit } from '../../../../Components';
import { AuthenticationService } from '../../../../Services';
import {
  GET_RELATIONSHIP,
  GET_POSITION,
  EDIT_RECOMMENDATION
} from './Recommendation.graphql';

const EditRecommendationComponent = ({
  open,
  onClose,
  form,
  name = '',
  id,
  dataEdit,
  refetchSent
}) => {
  const { Option } = Select;
  const { TextArea } = Input;
  const { validateFields, getFieldDecorator, resetFields } = form;

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [updateRecommendation] = useMutation(EDIT_RECOMMENDATION, {
    context: CONTEXT_USER
  });

  const [getListRelationShip, { data: dataRelationship }] = useLazyQuery(
    GET_RELATIONSHIP,
    {
      context: CONTEXT_PUBLIC
    }
  );
  const [getListPosition, { data: dataPosition }] = useLazyQuery(GET_POSITION, {
    context: CONTEXT_PUBLIC,
    variables: {
      userLogin: AuthenticationService.getUserId()
    }
  });

  useEffect(() => {
    getListRelationShip();
    getListPosition();
  }, [getListRelationShip, getListPosition]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const submit = {
          id,
          position: values.position,
          relationship: values.relationship,
          description: values.recommendation
        };
        updateRecommendation({
          variables: submit
        })
          .then(() => {
            refetchSent();
            message.success('Berhasil mengubah rujukan');
            resetFields();
            onClose();
          })
          .catch(err => {
            message.error(`Gagal mengubah rujukan, ${err}`);
          });
        // console.log("submit : ", submit);
      }
    });
  };
  const handleChange = (formValue, target) => {};

  const _switchComponentToRender = res => {
    const { type, fieldName, option, placeholder } = res;

    if (type === 'select') {
      return (
        <Select
          size="large"
          onChange={value => handleChange(value, fieldName)}
          style={
            fieldName === 'relationship'
              ? {
                  textTransform: 'capitalize'
                }
              : null
          }
        >
          <Option value={null} disabled>
            {placeholder}
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option
                  key={i}
                  value={fieldName === 'relationship' ? res.name : res.id}
                  style={
                    fieldName !== 'position' && {
                      textTransform: 'capitalize'
                    }
                  }
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={4}
          onChange={value => handleChange(value, fieldName)}
          placeholder="Tambahkan rujukan"
        />
      );
    }
  };

  const listFields = [
    {
      labelName: 'Hubungan',
      fieldName: 'relationship',
      type: 'select',
      fieldValue: dataEdit?.relationship,
      option: dataRelationship?.people_profile_recommendation_relationships,
      required: false,
      placeholder: 'Pilih hubungan'
    },
    {
      labelName: 'Posisi pada saat',
      fieldName: 'position',
      type: 'select',
      fieldValue: dataEdit?.position,
      option: generateValue(dataPosition),
      required: true,
      placeholder: 'Pilih posisi pada saat'
    },
    {
      labelName: 'Rujukan',
      fieldName: 'recommendation',
      type: 'textArea',
      fieldValue: dataEdit?.description,
      required: false
    }
  ];

  const handleClose = () => {
    onClose();
    resetFields();
  };

  return (
    <ModalEdit title="Ubah Rujukan" open={open} onClose={handleClose} sizeSmall>
      <Headline>
        Seberapa baik Anda mengenal <SelectedPepole>{name}</SelectedPepole> ?
      </Headline>
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={i}
                style={{ padding: '0px 22px', marginBottom: 8 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: [
                    {
                      required: res.required,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <Form.Item
          style={{
            marginBottom: 0,
            padding: '20px 22px 0px 22px',
            marginTop: 22
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Batalkan
            </Button>
            <Button type="primary" htmlType="submit">
              Kirim
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ModalEdit>
  );
};

const EditRecommendation = Form.create({ name: 'Edit_Recommendation' })(
  EditRecommendationComponent
);

export default EditRecommendation;
