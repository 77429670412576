import styled from 'styled-components';
import Assets from '../../Assets';

export const StepsWrap = styled.div`
  margin: 16px 0px;
  font-family: ${Assets.FONT.MULI};
  .ant-steps-item-wait .ant-steps-item-icon {
    border-color: ${Assets.COLOR_PALLETE.grey};
    background-color: ${Assets.COLOR_PALLETE.grey};
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${Assets.COLOR_PALLETE.white};
  }
`;
