import React from 'react';
import { WrapperItemSquare } from './Attachment.style';
import {
  downloadAttachmentAs,
  getFileIcon,
  getFileType
} from './helperAttachment';

export const AttachementItemIcon = ({ url, name, type, ...props }) => {
  const icon = getFileIcon(type || getFileType(url), url);
  const handleDownload = (url, data) => downloadAttachmentAs(url, data);

  return (
    <WrapperItemSquare {...props} onClick={() => handleDownload(url, name)}>
      <img alt="img" className="icon" src={icon} />
      <div className="text-wrapper">
        <p className="name">{name}</p>
      </div>
    </WrapperItemSquare>
  );
};
