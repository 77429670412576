import styled from 'styled-components';
import Assets from '../../Assets';

export const Description = styled.div`
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
`;

export const Name = styled.label`
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-left: 10px;
  cursor: pointer;
`;

export const JobDetails = styled.label`
  /* font-size: 12px;
  color: grey; */
  display: block;
`;

export const Follower = styled.label`
  /* font-weight: bold;
  color: black;
  margin-left: 3px; */
`;

export const PeopleResultWrapper = styled.label`
  background-color: #fff;
  display: block;
  padding-left: 35px;
  height: 90px;
  position: relative;
  font-family: ${Assets.FONT.MULI};
  .container-peopleResult-borderBottom {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.16);
    height: 90px;
    width: 810px;
  }
`;

export const InfoWrapper = styled.label`
  margin-top: 15px;
  display: inline-block;
  width: 630px;

  padding-bottom: 18px;
  margin-left: 55px;
`;
export const AvatarWrapper = styled.label`
  display: inline-block;
  position: absolute;
  top: 20px;
`;

export const ButtonWrapper = styled.label`
  display: inline-block;
  position: absolute;
  top: 35px;
  margin-left: 40px;
  /* border-bottom: 0.8px solid rgba(0, 0, 0, 0.16);
  padding-bottom: 30px; */
`;
