import styled from 'styled-components';
import Assets from '../../Assets';

export const VendorShowcaseWrap = styled.div`
  position: relative;
  border-radius: 2.5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  font-family: ${Assets.FONT.MULI};

  .ant-modal.showcase-modal {
    font-family: ${Assets.FONT.MULI};
  }
  thead[class*='ant-table-thead'] th {
    background-color: #ffffff;
  }

  .ant-table {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #010101;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }

  .anticon.anticon-ellipsis {
    width: 16px;
    height: 16px;
    color: blue;
  }
  .pagination {
    padding: 10px;
  }
`;

export const ContentWrap = styled.div`
  position: relative;
  padding: 15px 24px;
  background: ${Assets.COLOR_PALLETE.white};

  h2 {
    margin: 0;
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 11px;
    font-family: ${Assets.FONT.MULI};
  }

  p {
    margin: 0;
    color: ${Assets.COLOR_PALLETE.grey};
    font-size: 11px;
    font-family: ${Assets.FONT.MULI};
    margin-bottom: 24px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      color: ${Assets.COLOR_PALLETE.grey};
      font-size: 11px;
      font-family: ${Assets.FONT.MULI};
      line-height: 2em;
    }
    .anticon {
      margin-right: 12px;
      color: ${Assets.COLOR_PALLETE.blue};
    }
  }
`;

export default {
  lineBorder: {
    borderTop: `1px solid ${Assets.COLOR_PALLETE.grey}`
  },
  marginNone: {
    margin: 0
  },
  btnModal: {
    width: '50%',
    border: 'none',
    borderRadius: 0
  }
};

export const ShowcaseModalWrap = styled.div`
  font-family: ${Assets.FONT.MULI};

  .component_button_wrapper {
    text-align: right;

    .ant-btn.ant-btn-primary {
      margin-left: 10px;
      background-color: ${Assets.COLOR_PALLETE.dark_blue};
    }
  }
`;
