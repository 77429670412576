import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';

import {
  Form,
  Select,
  Input,
  Switch,
  DatePicker,
  Checkbox,
  message,
  InputNumber
} from 'antd';
import { TitleField } from '../DetailScreen.style';
import moment from 'moment';
import { useMutation, useLazyQuery } from 'react-apollo';
import {
  ADD_EXPERIENCE,
  DELETE_EXPERIENCE,
  UPDATE_EXPERIENCE
} from './Experience.graphql';
import {
  generateDateFormat,
  getErrorMessage,
  isAfter
} from '../HelperDetailScreen';
import {
  ButtonFrom,
  GLOBAL_PHONE_CODE_SEARCHABLE,
  SearchableSelect
} from '../../../../SharedComponents';

const AddExperienceComponent = ({
  open,
  onClose,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen,
  openState
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [share, setShare] = useState(false);
  const [masterpiece, setMasterpiece] = useState(data?.is_masterpiece);
  const [currently, setCurrently] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };
  const [addExperience] = useMutation(ADD_EXPERIENCE, {
    context: CONTEXT_USER
  });
  const [UpdateExperience] = useMutation(UPDATE_EXPERIENCE, {
    context: CONTEXT_USER
  });
  const [deleteExperience] = useMutation(DELETE_EXPERIENCE, {
    context: CONTEXT_USER
  });

  const [
    getListGlobalPhoneCode,
    { data: dataCode, loading: loadingPhone }
  ] = useLazyQuery(GLOBAL_PHONE_CODE_SEARCHABLE);

  useEffect(() => {
    if (data) {
      const { country_code, phone } =
        data?.additional_fields?.referal_contact_phone || {};
      setMasterpiece(data?.is_masterpiece);
      setCurrently(data.is_present);
      setFieldsValue({ type: country_code || undefined });
      setFieldsValue({ referal_contact_phone: phone || '' });
      setShare(data?.is_share);
      getListGlobalPhoneCode({
        variables: {
          where: {
            code: country_code ? { _eq: country_code } : undefined
          }
        }
      });
    } else {
      setMasterpiece(false);
      setShare(false);
    }
  }, [open, data, people_profile, setFieldsValue, getListGlobalPhoneCode]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const object = {
          is_share: share,
          company_name: values.company_name,
          company_line_of_business: values.company_line_of_business,
          title: values.position_name,
          decree: values.decree,
          location: { experience_location: values.location },
          is_present: currently,
          description: values.description,
          is_masterpiece: masterpiece,
          achievements: values.achievement,
          from: generateDateFormat(values.start_date),
          to: generateDateFormat(values.end_date),
          reason_to_leave: values.reason_to_leave,
          // initial_salary: values.initial_salary,
          // last_salary: values.last_salary,
          referal_name: values.referal_name,
          referal_position: values.referal_position,
          additional_fields: {
            referal_contact_phone: {
              country_code: values.type,
              phone: String(values.referal_contact_phone)
            }
          }
        };
        if (actionType === 'add') {
          addExperience({
            variables: { object }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Berhasil menambahkan pengalaman`);
              onClose();
              setShare(false);
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan pengalaman, ${err}`);
            });
        } else if (actionType === 'edit') {
          UpdateExperience({
            variables: {
              id: data.id,
              object: object
            }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Berhasil mengubah pengalaman`);
              onClose();
              setShare(false);
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah pengalaman, ${err}`);
            });
        }
      }
    });
  };

  const listFields = [
    {
      labelName: 'Nama Perusahaan',
      fieldName: 'company_name',
      type: 'text',
      fieldValue: data && data?.company_name,
      placeholder: 'Tambahkan Perusahaan',
      required: true
    },
    {
      labelName: 'Bidang Usaha',
      fieldName: 'company_line_of_business',
      type: 'text',
      fieldValue: data && data?.company_line_of_business,
      placeholder: 'Tambahkan bidang usaha perusahaan',
      required: false
    },
    {
      labelName: 'Jabatan',
      fieldName: 'position_name',
      type: 'text',
      fieldValue: data && data?.title,
      placeholder: 'Tambah Jabatan',
      required: true
    },
    {
      labelName: 'Surat Ketetapan',
      fieldName: 'decree',
      type: 'text',
      fieldValue: data && data?.decree,
      placeholder: 'Tambahkan surat ketetapan',
      required: false
    },
    {
      labelName: 'Lokasi Perusahaan',
      fieldName: 'location',
      type: 'text',
      fieldValue: data && data?.location?.experience_location,
      placeholder: 'Tambahkan Lokasi Perusahaan',
      required: true
    }
  ];

  const listFields2 = [
    {
      labelName: 'Nomor Referensi',
      fieldName: 'referal_name',
      type: 'text',
      fieldValue: data && data?.referal_name,
      placeholder: 'Tambahkan nomor referensi',
      required: false
    },
    {
      labelName: 'Posisi Pemberi Referensi',
      fieldName: 'referal_position',
      type: 'text',
      fieldValue: data && data?.referal_position,
      placeholder: 'Tambahkan posisi pemberi referensi',
      required: false
    }
  ];

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      placeholder,
      fieldValue,
      style,
      withEmptyIcon,
      loading,
      onSearch,
      onTouch,
      onChange
    } = res;
    if (type === 'select') {
      return (
        <Select
          filterOption={false}
          showSearch
          size="large"
          placeholder={placeholder}
        >
          <Option value={null} disabled>
            Kode Negara
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.code}>
                  {`(${res.code}) - ${res.country}`}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          style={style}
          withEmptyIcon={withEmptyIcon}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text') {
      return <Input placeholder={placeholder} style={{ width: '100%' }} />;
    } else if (type === 'number') {
      return (
        <InputNumber
          type="number"
          placeholder={placeholder}
          style={{ width: '100%' }}
        />
      );
    } else if (type === 'rupiah') {
      return (
        <InputNumber
          placeholder={placeholder}
          style={{ width: '100%' }}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          parser={value => value.replace(/\D/g, '')}
        />
      );
    }
  };

  const handleDelete = () => {
    deleteExperience({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus pengalaman`);
        onClose();
        setCurrently(false);
      })
      .catch(err => {
        message.error(`Gagal menghapus pengalaman, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setCurrently(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };
  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambah' : 'Ubah'}  Pengalaman`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField>Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              const dataTestId = `${res.fieldName}-${res.fieldValue}`;
              return (
                <Form.Item
                  key={i}
                  data-testId={dataTestId}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}

          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={<TitleField>Tanggal Mulai</TitleField>}
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                  width: '50%'
                }}
                extra={
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(
                      'currently_studying',
                      {}
                    )(
                      <Checkbox
                        checked={currently}
                        onClick={() => {
                          setFieldsValue({ end_date: undefined });
                          setCurrently(!currently);
                        }}
                      >
                        <TitleField>
                          Saat ini saya sedang bekerja dalam posisi ini
                        </TitleField>
                      </Checkbox>
                    )}
                  </Form.Item>
                }
              >
                {getFieldDecorator('start_date', {
                  initialValue:
                    data && data.from
                      ? moment(data.from, 'YYYY/MM/DD')
                      : moment(new Date(), 'YYYY/MM/DD'),
                  rules: [
                    {
                      required: true,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Pilih tanggal"
                    onChange={() => setFieldsValue({ end_date: undefined })}
                    format={'YYYY-MM-DD'}
                    style={{ width: '90%' }}
                    disabledDate={current =>
                      !currently && isAfter(current, getFieldValue('end_date'))
                    }
                  />
                )}
              </Form.Item>
              {!currently && (
                <Form.Item
                  label={<TitleField>Tanggal Selesai</TitleField>}
                  style={{ display: 'inline-block', width: '50%' }}
                >
                  {getFieldDecorator('end_date', {
                    initialValue:
                      data && data.to
                        ? moment(data.to, 'YYYY/MM/DD')
                        : moment(new Date(), 'YYYY/MM/DD'),
                    rules: [
                      {
                        required: true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(
                    <DatePicker
                      placeholder="Pilih tanggal"
                      format={'YYYY-MM-DD'}
                      style={{ width: '90%' }}
                      disabledDate={current =>
                        isAfter(getFieldValue('start_date'), current)
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data && data?.description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan keterangan" />)}
          </Form.Item>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Hasil Karya</TitleField>}
          >
            <Switch
              // size="small"
              checked={masterpiece}
              onClick={() => setMasterpiece(!masterpiece)}
            />
            <TitleField>
              {' '}
              Saya punya hasil karya dalam pengalaman ini
            </TitleField>
          </Form.Item>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Pencapaian</TitleField>}
          >
            {getFieldDecorator('achievement', {
              initialValue: data && data?.achievements,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan pencapaian" />)}
          </Form.Item>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Alasan Keluar Perusahaan</TitleField>}
          >
            {getFieldDecorator('reason_to_leave', {
              initialValue: data && data?.reason_to_leave
            })(
              <TextArea
                placeholder="Tambahkan alasan keluar perusahaan"
                rows={4}
              />
            )}
          </Form.Item>

          {listFields2.length > 0 &&
            listFields2.map((res, i) => {
              const dataTestId = `${res.fieldName}-${res.fieldValue}`;
              return (
                <Form.Item
                  key={i}
                  data-testId={dataTestId}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <Form.Item
            label={<TitleField>Nomor Kontak Referensi</TitleField>}
            style={{ padding: '0px 22px', marginBottom: 8 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'between'
              }}
            >
              <div style={{ width: '30%' }} id="id_country_code">
                {getFieldDecorator('type', {
                  rules: [
                    {
                      required: false,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  _switchComponentToRender({
                    labelName: 'Type',
                    fieldName: 'type',
                    type: 'searchable-select',
                    placeholder: 'Kode Negara',
                    withEmptyIcon: false,
                    option: dataCode?.global_phone_code?.map(
                      ({ code, country }) => ({
                        id: code,
                        name: `(${code}) ${country}`
                      })
                    ),
                    fieldValue: getFieldValue('type'),
                    loading: loadingPhone,
                    onSearch: ({ value }) => {
                      getListGlobalPhoneCode({
                        variables: {
                          limit: 20,
                          where: {
                            _or: value
                              ? [
                                  { country: { _ilike: `%${value}%` } },
                                  { code: { _ilike: `%${value}%` } }
                                ]
                              : undefined
                          }
                        }
                      });
                    },
                    onTouch: ({ isOpen }) => {
                      if (isOpen) {
                        getListGlobalPhoneCode({
                          variables: {
                            limit: 20
                          }
                        });
                      }
                    }
                  })
                )}
              </div>
              <div style={{ width: '70%', paddingLeft: '10px' }}>
                {getFieldDecorator('referal_contact_phone', {
                  initialValue:
                    data && data
                      ? data?.additional_fields?.referal_contact_phone?.phone
                      : '',
                  rules: [
                    {
                      required: false,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  _switchComponentToRender({
                    labelName: 'Company Name',
                    fieldName: 'referal_contact_phone',
                    type: 'number',
                    fieldValue:
                      data && data
                        ? data?.additional_fields?.referal_contact_phone?.phone
                        : '',
                    placeholder: 'Tambahkan nomor kontak',
                    required: true
                  })
                )}
              </div>
            </div>
          </Form.Item>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Pengalaman"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditExperience = Form.create({ name: 'Add_Experience' })(
  AddExperienceComponent
);

export default AddEditExperience;
