import styled from 'styled-components';

export const UploadWrap = styled.div`
  position: relative;
  margin-bottom: 10px;

  .ant-upload-list-item {
    font-size: 11px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 130px;
    height: 130px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 130px;
    height: 130px;
  }
`;

export default {
  image: {
    width: '100%'
  }
};
