import gql from 'graphql-tag';

export const GET_LIST_CV = gql`
  query($user: uuid!) {
    main: global_users(where: { id: { _eq: $user } }) {
      id
      avatar
      name
      email
      people_profiles {
        id
        profile_fields
      }
      people_addresses {
        id
        address
        global_city {
          id
          name
        }
        global_province {
          id
          name
        }
      }
      people_work_placements(order_by: { id: desc }) {
        id
        company_employee_position {
          id
          name
        }
        company_profile {
          id
          brand_name
        }
      }
    }
    skills: people_profile_skills(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
    ) {
      id
      description
      level
      people_skill_level {
        id
        name
      }
      skill_fields
      people_skill {
        id
        name
      }
    }

    hobbies: people_profile_hobbies(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
    ) {
      id
      name
      description
      hobby
      people_hobby {
        id
        icon
        name
        people_hobby {
          id
          name
        }
        # hobby_category {
        #   id
        #   name
        # }
        # hobby_sub_category {
        #  id
        #  name
        # }
      }
      hobby_fields
    }

    phone: people_phones(
      where: { user: { _eq: $user }, deletedAt: { _is_null: true } }
    ) {
      id
      country_code
      phone
    }
    email: people_emails(
      where: { user: { _eq: $user }, deletedAt: { _is_null: true } }
      order_by: { is_main: desc }
    ) {
      email
      user
      status
      deletedAt
      is_main
      is_visible
      is_verified
      date_exp_verification
      global_user {
        email
      }
    }
    social_contacts: people_profile_social_contacts(
      where: { user_id: { _eq: $user }, deletedAt: { _is_null: true } }
    ) {
      id
      url
    }

    experiences: people_profile_experiences(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      title
      company_name
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      location
      from
      to
      decree
      description
      achievements
      initial_salary
      last_salary
      is_present
      is_share
      company_name
      referal_name
      referal_position
      additional_fields
      reason_to_leave
      company_line_of_business
      is_masterpiece
    }

    projects: people_profile_projects(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_current
      project_name
      creators
      location
      start_date
      end_date
      description
      associate_user {
        id
        name
        people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      project_url
      position
    }

    education: people_profile_educations(
      where: {
        _and: [
          { user: { _eq: $user } }
          { deletedAt: { _is_null: true } }
          {
            company_profile: {
              _and: [
                { types: { _ilike: "_1%" } }
                { deletedAt: { _is_null: true } }
              ]
            }
          }
        ]
      }
      order_by: { id: desc }
    ) {
      id
      is_present
      company_profile {
        id
        school_name: legal_name
        company_address {
          city: global_city {
            name
            id
          }
          province: global_province {
            name
            id
          }
          address_field
        }
      }
      faculty: global_faculty {
        id
        name
      }
      degree: global_degree {
        id
        name
      }
      major: global_major {
        id
        name
      }
      start_date: from
      end_date: to
      description
      is_present
      education_fields # other info
    }

    certificates: people_profile_certificates(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_shared
      name
      type
      level
      is_shared
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      issuer_name
      issues_date: date_issued
      expirations_date: date_expired
      can_expire
      credential_id
      credential_url
      description
      attachments
    }

    speaker: people_profile_speaker(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      event_name
      location
      organizer
      roles
      scale
      total_participants
      description
    }

    awards: people_profile_awards(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      award_name
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      reference_date
      reference_number
      letter_number
      remark
      certificate_number
      attachments
      issuing_organization
    }

    publications: people_profile_publications(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      publication_title
      authors
      publication_media
      publication_date
      description
      publication_url
      attachments
    }

    organization_activities: people_profile_organization_activities(
      where: {
        _and: [{ user_id: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
    ) {
      id
      organization_name
      organization_type
      member_id
      position_name
      position_description
      start_date
      end_date
      country
      province: global_province {
        id
        name
      }
      city: global_city {
        id
        name
      }
      is_shared_with_network
      logo
    }
    service_agreements: people_profile_service_agreements(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      code
      name
      start_date
      end_date
      description
      attachments
    }

    disciplinaries: people_profile_disciplinary(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      discipline_name
      reference_number
      start_date
      end_date
      discipline_letter_number
      reference_date
      remark
      attachments
    }
    ohsa_list: people_profile_ohsa(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      company_name
      company_location
      category
      employee: global_user {
        name
      }
      incident_location
      incident_date_time
      severity
      description
      incident_code
      attachments
    }
  }
`;
