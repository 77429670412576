import PropTypes from 'prop-types';

const propTypes = {
  followers: PropTypes.array.isRequired,
  handleChangeCheckBox: PropTypes.func.isRequired
};

const defaultProps = {

};

export default {
  propTypes,
  defaultProps
}
