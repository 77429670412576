import React, { useState } from 'react';

import config from './GroupAboutPage.config';
import { Enhancer } from '../../SharedComponents';
import { Group, AuthenticationService } from '../../Services';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { MenuBar } from '../../Containers';
import { useAsyncEffect } from 'use-async-effect';

const GroupAboutContainer = props => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const groupId = params.id;
  const ownId = AuthenticationService.getUserId();
  const [
    menuBarList
    // setMenubarList
  ] = useState(config.menuBar);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequest, setIsRequest] = useState(false);

  useAsyncEffect(
    async isMount => {
      setIsLoading(true);
      await Group.Discussion.fetchGroupInfo(groupId)
        .then(res => {
          if (!isMount()) return;
          if (res.isJoined === true) {
            history.replace(`/group/${groupId}/discussion`);
          }
          setIsRequest(res.isRequest);
        })
        .finally(() => {
          if (!isMount()) return;
          setIsLoading(false);
        });
    },
    [groupId, ownId]
  );
  if (isLoading) return <div />;
  else
    return (
      <MenuBar
        menuBarList={menuBarList}
        location={location}
        isRequest={isRequest}
        setIsRequest={setIsRequest}
        {...props}
      />
    );
};

export default Enhancer({})(GroupAboutContainer);
