import React, { useState, useEffect } from 'react';
import { ModalDeleteCustomCheck, ModalEdit, ModalNotifWlbCheck } from '../..';
import { ScrollWrapper } from './EmailAddress.style';
import ListEmail from './ListEmail';
import ButtonAdd from './ButtonAdd';
import AddEditEmail from './AddEditEmail';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  SOFT_DELETE,
  PERMANENT_DELETE,
  GET_LIST_EMAIL_ADDRESS,
  SET_MAIN_EMAIL,
  SET_VISIBILITY_EMAIL
} from './EmailAddress.graphql';
import { Empty, message, Skeleton } from 'antd';
import { AuthenticationService } from '../../../Services';
import moment from 'moment';

export default function EmailAddress({
  open,
  handleClose,
  setOpen,
  stateOpen,
  isUser,
  profileId
}) {
  const [getlistEmail, { data, error, refetch }] = useLazyQuery(
    GET_LIST_EMAIL_ADDRESS,
    {
      fetchPolicy: 'network-only',
      context: { headers: { 'X-Hasura-Role': 'user' } }
    }
  );

  const [setMainEmail] = useMutation(SET_MAIN_EMAIL, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setVisibilityEmail] = useMutation(SET_VISIBILITY_EMAIL, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [deleteEmailSoft] = useMutation(SOFT_DELETE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [deleteEmailPermanent] = useMutation(PERMANENT_DELETE, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  useEffect(() => {
    if (open) {
      getlistEmail({
        variables: {
          user: profileId
        }
      });
    }
  }, [open, getlistEmail, profileId]);

  const [openAction, setOpenAction] = useState({
    addEdit: false,
    data: null,
    actionType: null,
    modalDelete: false,
    modalVerification: false
  });

  const handleSetModalDelete = cond => {
    setOpenAction({
      ...openAction,
      addEdit: false,
      modalDelete: cond === 'open' ? true : false
    });
  };

  const handleConfirm = () => {
    setOpenAction({
      ...openAction,
      modalVerification: false
    });
  };

  const handleAddButtonClick = () => {
    setOpen({ ...stateOpen, email: false });
    setOpenAction({ ...openAction, addEdit: true, actionType: 'add' });
  };
  const handleClickAction = dataEdit => {
    setOpen({ ...stateOpen, email: false });
    setOpenAction({
      ...openAction,
      addEdit: true,
      actionType: 'edit',
      data: dataEdit
    });
  };
  const handleButtonCloseAddEdit = () => {
    setOpen({ ...stateOpen, email: true });
    setOpenAction({
      ...openAction,
      addEdit: false,
      actionType: null,
      data: null,
      modalDelete: false
    });
    getlistEmail({
      variables: {
        user: profileId
      }
    });
  };

  const handleVisibility = (isVisible, email) => {
    setVisibilityEmail({
      variables: {
        userId: profileId,
        email,
        isVisible: !isVisible
      }
    })
      .then(() => {
        refetch();
        message.success(`${isVisible ? 'Tidak' : ''} Perlihatkan email sukses`);
      })
      .catch(() => {
        message.error(`${isVisible ? 'Tidak' : ''} Perlihatkan email gagal`);
      });
  };

  const handleSetMain = data => {
    setMainEmail({
      variables: {
        email: data.email,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        refetch();
        message.success('Tetapkan sebagai email utama berhasil');
      })
      .catch(() => {
        message.error('Tetapkan sebagai email utama gagal');
      });
  };

  const handleDelete = () => {
    if (openAction.data.is_verified) {
      deleteEmailSoft({
        variables: {
          email: openAction.data.email
        }
      })
        .then(() => {
          handleButtonCloseAddEdit();
          message.success('Hapus email berhasil');
        })
        .catch(() => {
          message.error('Hapus email gagal');
        });
    } else {
      deleteEmailPermanent({
        variables: {
          email: openAction.data.email
        }
      })
        .then(() => {
          handleButtonCloseAddEdit();
          message.success('Hapus email berhasil');
        })
        .catch(() => {
          message.error('Hapus email gagal');
        });
    }
  };

  if (error) {
    message.error(`Error : ${error}`);
  }
  const onClickDelete = dataEdit => {
    setOpenAction({
      ...openAction,
      addEdit: false,
      modalDelete: true,
      data: dataEdit,
      messageDelete: dataEdit.is_verified
        ? 'Data akan terhapus secara permanen dan email tidak dapat dimasukkan kembali'
        : 'Data yang belum diverifikasi dapat dimasukkan lagi'
    });
  };
  return (
    <>
      <ModalEdit
        title="Email"
        open={open}
        onClose={handleClose}
        sizeSmall
        padding="0px"
        centered
      >
        <ScrollWrapper>
          {data &&
            data.people_emails.length > 0 &&
            data.people_emails.map((res, i) => {
              if (!isUser && !res.is_visible) {
                return '';
              } else {
                if (res.email === data.owner.email) {
                  return (
                    <ListEmail
                      key={`${i}-${res.email}`}
                      isVisible={res.is_visible}
                      onClickVisibility={() =>
                        handleVisibility(res.is_visible, res.email)
                      }
                      onClickAction={() => handleClickAction(res)}
                      is_main={res.email === data.owner.email}
                      email={res.email}
                      onClickSetMain={() => handleSetMain(res)}
                      isUser={isUser}
                      is_verified={res.is_verified}
                      onClickDelete={() => onClickDelete(res)}
                      time_verification={
                        res.date_exp_verification !== null
                          ? moment().to(
                              moment(res.date_exp_verification).add(1, 'days')
                            )
                          : '3 days'
                      }
                    />
                  );
                }
              }
            })}
          {data ? (
            data.people_emails.length > 0 ? (
              data.people_emails.map((res, i) => {
                if (!isUser && !res.is_visible) {
                  return '';
                } else {
                  if (res.email !== data.owner.email) {
                    return (
                      <ListEmail
                        key={`${i}-${res.email}`}
                        isVisible={res.is_visible}
                        onClickVisibility={() =>
                          handleVisibility(res.is_visible, res.email)
                        }
                        onClickAction={() => handleClickAction(res)}
                        is_main={res.email === data.owner.email}
                        email={res.email}
                        onClickSetMain={() => handleSetMain(res)}
                        isUser={isUser}
                        is_verified={res.is_verified}
                        onClickDelete={() => onClickDelete(res)}
                        time_verification={
                          res.date_exp_verification !== null
                            ? moment().to(
                                moment(res.date_exp_verification).add(1, 'days')
                              )
                            : '3 days'
                        }
                      />
                    );
                  }
                }
              })
            ) : (
              <Empty style={{ margin: '36px 0px' }} />
            )
          ) : (
            <div style={{ padding: '12px 24px' }}>
              <Skeleton active paragraph={{ rows: 4 }} />
            </div>
          )}
        </ScrollWrapper>
        {isUser && <ButtonAdd onClick={handleAddButtonClick} />}
      </ModalEdit>
      <AddEditEmail
        setOpenAction={setOpenAction}
        openAction={openAction}
        open={openAction.addEdit}
        handleClose={handleButtonCloseAddEdit}
      />
      <ModalDeleteCustomCheck
        open={openAction.modalDelete}
        centered
        target="Alamat Email"
        message={openAction.messageDelete}
        onClose={() => handleSetModalDelete('close')}
        onDelete={handleDelete}
      />{' '}
      <ModalNotifWlbCheck
        open={openAction.modalVerification}
        centered
        target="Verifikasi Email"
        message="Untuk menampilkan alamat email ini di profil Anda, Harap ikuti instruksi dalam email yang kami kirimkan ke email terkait untuk memverifikasi alamat email ini."
        confirm="Konfirmasi"
        onConfirm={handleConfirm}
      />
    </>
  );
}
