import styled from 'styled-components';
import Assets from '../../Assets';

const PostListWrapper = styled.div`
  position: relative;
  min-height: 100px;
  display: flow-root;
  padding: 18px;
  border-radius: 3px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 15px;

  .author {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    padding-bottom: 15px;
    .photo {
      width: 35px;
      height: 35px;
      float: left;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .right {
      float: right;
    }
    .info {
      margin-left: 10px;
      margin-top: 3px;
      float: left;
      span {
        display: block;
      }
      .name {
        font-family: ${Assets.FONT.MULI_BOLD};
        color: #252525;
        font-size: 12px;
      }
      .desc {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 12px;
      }
    }
    .more-option {
      display: block;
      float: right;
      width: 9px;
      height: 4px;
      img {
        width: 100%;
      }
    }
    .time {
      margin-top: 6px;
      width: 100%;
      display: block;
      float: right;
      text-align: right;
      span {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 11px;
      }
    }
  }
  .content {
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 12px;
    line-height: 13px;
    margin-top: 10px;
  }

  .action-group {
    margin-top: 14px;
    width: 100%;
    display: inline-block;
    padding-bottom: 17px;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    .item {
      margin-right: 24px;
      float: left;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .icon {
      float: left;
    }
    .text {
      font-size: 11px;
      font-family: ${Assets.FONT.MULI};
      color: ${Assets.COLOR_PALLETE.grey};
      float: left;
      margin-left: 5px;
    }
  }
  .right {
    float: right !important;
  }
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin-top: 2px;
    &.love {
      background-image: url(${Assets.ICON.LOVE});
    }
    &.comment {
      background-image: url(${Assets.ICON.COMMENT});
    }
    &.share {
      background-image: url(${Assets.ICON.SHARE});
    }
    &.star {
      background-image: url(${Assets.ICON.STAR});
    }
  }
  .award {
    .icon {
      &.star {
        background-image: url(${Assets.ICON.STAR_YELLOW});
      }
    }
    .text {
      color: ${Assets.COLOR_PALLETE.black2};
    }
  }
`;

export { PostListWrapper };
