import React, { useState } from 'react';
import 'antd/dist/antd.css';
import PeopleScreenComponent from '../PeopleScreen/PeopleScreen.component';
import CompanyScreenComponent from '../CompanyScreen/CompanyScreen.component';
import GroupScreenComponent from '../TeamScreen/TeamScreen.component';
import LearningComponent from '../Learning/component';
import { message } from 'antd';
import { SearchService } from '../../../Services';
import queryString from 'query-string';
import {
  SearchNotFound,
  DummySpace,
  DisplayLoading
} from '../../../Components';
import { useAsyncEffect } from 'use-async-effect';
import { CategoryLabel, AllSearchTitle } from '../SearchPage.style';
import { useBlockMe } from '../../../SharedComponents/CustomHook';

const AllResultScreen = props => {
  const queryWord = queryString.parse(props.location.search).keyword;

  const [users, setUsers] = useState([]);
  // const [jobs, setJobs] = useState([]);
  const [company, setCompany] = useState([]);
  const [teams, setGroups] = useState([]);
  const [learning, setlearning] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [blockedMe, blockLoaded] = useBlockMe();

  useAsyncEffect(
    async isMount => {
      if (queryWord && blockLoaded) {
        setIsFetching(true);
        SearchService.searchByKeywordAndType(queryWord, 'all', 1)
          .then(res => {
            if (!isMount()) return;
            // FILTER USER BLOCKED ME
            let filteredUser = res.data.users.filter(
              item => !blockedMe?.includes(item.id)
            );
            setUsers(filteredUser);
            setCompany(res.data.company);
            setGroups(res.data.teams);
            setlearning(res.data.learning);
          })
          .catch(err => message.error('Something Went Wrong'))
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
          });
      }
    },
    [queryWord, blockLoaded]
  );

  const notFound =
    users.length === 0 &&
    company.length === 0 &&
    teams.length === 0 &&
    learning.length === 0;

  return (
    <>
      <AllSearchTitle>
        <CategoryLabel>All Search Results</CategoryLabel>
      </AllSearchTitle>
      {isFetching && <DisplayLoading />}
      {users.length > 0 && (
        <PeopleScreenComponent
          {...props}
          peopleList={users}
          searchCategory={'People'}
        />
      )}
      {company.length > 0 && (
        <CompanyScreenComponent
          {...props}
          companyList={company}
          searchCategory={'Company'}
        />
      )}
      {teams.length > 0 && (
        <GroupScreenComponent
          {...props}
          searchedGroupResults={teams}
          searchCategory={'Group'}
        />
      )}
      {learning.length > 0 && (
        <LearningComponent
          {...props}
          learningList={learning}
          searchCategory={'Learning'}
        />
      )}
      {blockLoaded && !isFetching && notFound && (
        <SearchNotFound
          title={`We could not find  "${queryWord}"`}
          desc={
            'Nothing came up for that search. Try shortening or rephrasing your search.'
          }
        />
      )}
      <DummySpace />
    </>
  );
};

export default AllResultScreen;
