import React from 'react';
import VendorListBoxComponent from './VendorListBox.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_LIST_VENDOR } from './VendorListBoxGraphQl';
import { AuthenticationService } from '../../Services';

const VendorListBoxContainer = props => {
  const userData = AuthenticationService.getUserData();
  const { data, loading, error } = useQuery(GET_LIST_VENDOR, {
    variables: {
      user: userData.id
    }
  });
  return (
    <VendorListBoxComponent
      dataListVendor={data?.people_work_placements?.[0]?.company_profile}
      isLoadingListVendor={loading}
      errorVendor={error}
      {...props}
    />
  );
};
export default VendorListBoxContainer;
