import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';

import { AuthenticationService } from '../Services';

let currentRole;

const COOKIE_DOMAIN = process.env.REACT_APP_DOMAIN;

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
  credentials: 'include',
  fetch: (uri, options) => {
    const nextRole = options?.headers?.['X-Hasura-Role'] ?? 'public';

    if (currentRole !== nextRole) {
      currentRole = nextRole;
      document.cookie = `X-Hasura-Role=${nextRole}; path=/; domain=${COOKIE_DOMAIN}; secure`;
    }

    return fetch(uri, {
      ...options,
      headers: {
        ...options.headers,
        'X-Hasura-Role': nextRole
      }
    });
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      if (
        process.env.NODE_ENV !== 'development' &&
        extensions &&
        (extensions.code === 'invalid-jwt' ||
          extensions.code === 'invalid-headers')
      ) {
        AuthenticationService.removeAllCookies();
        window.location = process.env.REACT_APP_SSO_URL;
      } else {
        console.error(`[GraphQL error]: Message: ${message}`);
      }
    });
  }
  if (networkError) console.error(`[Network error]: `, networkError);
});

export default new ApolloClient({
  link: errorLink.concat(httpLink),
  cache: new InMemoryCache()
});
