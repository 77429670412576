import { Icon } from 'antd';
import React, { memo } from 'react';
import { WrapperExperience } from './Experience.style';
import Assets from '../../../../Assets';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';

export default memo(function ExperienceItem({
  onClickEdit,
  isUser,
  avatar,
  title,
  company,
  date,
  location,
  description
}) {
  return (
    <WrapperExperience>
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="title">{title}</div>
          <div className="company">{company}</div>
          <div className="date">{date}</div>
          <div className="location">{location}</div>
          <div className="description">{description}</div>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      <div className="divider" />
    </WrapperExperience>
  );
});
