import React, { useState, useEffect } from 'react';
import { Table, Col } from 'antd';
import { VendorShippingWrap } from './VendorShipping.style';
import Assets from '../../Assets';

const CardShipping = props => {
  const { item, vendorShipping, setVendorShipping, setDisableSave } = props;
  //   const [serviceType, setServiceType] = useState(item.service_type);
  const [
    courierName
    // setCourierName
  ] = useState(item.name);
  const [selectedShipping, setSelectedShipping] = useState([]);

  useEffect(() => {
    vendorShipping.forEach(vendor => {
      if (vendor.courier.toLowerCase() === item.name.toLowerCase()) {
        const selected = vendor.type.map(typ => item.service_type.indexOf(typ));
        setSelectedShipping(selected);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, vendorShipping]);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const isSelectedInclude = vendorShipping.some(
      vendor => vendor.courier.toLowerCase() === courierName.toLowerCase()
    );
    setSelectedShipping(selectedRowKeys);
    setVendorShipping(vendorShipping => {
      const item = vendorShipping.map(vendor => {
        if (vendor.courier.toLowerCase() === courierName.toLowerCase()) {
          return { type: selectedRows, courier: courierName };
        } else {
          return vendor;
        }
      });
      if (!isSelectedInclude) {
        item.push({ type: selectedRows, courier: courierName });
      }
      return item;
    });
    setDisableSave(false);
  };

  const rowSelection = {
    selectedShipping,
    onChange: onSelectChange,
    selectedRowKeys: selectedShipping
  };

  const logoService = item.logo
    ? item.logo
    : Assets.IMAGE.DEFAULT_IMAGE || item.logo !== undefined
    ? item.logo
    : Assets.IMAGE.DEFAULT_IMAGE;
  return (
    <Col span={8} key={item.id}>
      <VendorShippingWrap>
        <Table
          rowSelection={rowSelection}
          rowKey={record => item.service_type.indexOf(record)}
          columns={[
            {
              title: <img src={logoService} alt="service" />,
              dataIndex: []
            }
          ]}
          pagination={{ position: 'none' }}
          dataSource={item.service_type}
        />
      </VendorShippingWrap>
    </Col>
  );
};

export default CardShipping;
