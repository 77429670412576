import moment from 'moment';

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

export const listFilter = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        { value: ['pending', 'refund_pending'], label: 'New Order' },
        { value: 'paid', label: 'Payment Reviewed' },
        { value: 'review', label: 'Order Reviewed' },
        { value: 'packing', label: 'Packed' },
        { value: 'shipping', label: 'Shipped' },
        { value: 'completed', label: 'Done' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'refund_paid', label: 'Refunded' }
      ]
    }
  },
  {
    name: 'Date',
    fieldName: 'date',
    type: 'date'
  },
  {
    name: 'Price',
    fieldName: 'salary',
    type: 'salary',
    min: 0,
    max: 999999999
  }
];

export function dateFormat(date, format) {
  if (!date) {
    return null;
  }

  return moment(date).format(format);
}

export function dateSubtract(date, long, time, format) {
  return moment(date)
    .subtract(long, time)
    .format(format);
}
