import Assets from '../Assets';

const displayFileTypeImage = fileName => {
  const reg = /(?:\.([^.]+))?$/;
  const fileFormat = reg.exec(fileName)[1];
  const isExcel = fileFormat === 'xls' || fileFormat === 'xlsx';
  const isPdf = fileFormat === 'pdf';
  const isPpt =
    fileFormat === 'ppt' ||
    fileFormat === 'pptx' ||
    fileFormat === 'ppsx' ||
    fileFormat === 'pot' ||
    fileFormat === 'potx' ||
    fileFormat === 'pptm';
  const isWord =
    fileFormat === 'docs' || fileFormat === 'docx' || fileFormat === 'doc';
  const isTxt = fileFormat === 'txt';
  if (isExcel) {
    return Assets.ICON.EXCEL_SOLID;
  } else if (isPdf) {
    return Assets.ICON.PDF_SOLID;
  } else if (isPpt) {
    return Assets.ICON.POWERPOINT_SOLID;
  } else if (isWord) {
    return Assets.ICON.WORD_SOLID;
  } else if (isTxt) {
    return Assets.ICON.TXT_SOLID;
  }
};

const displayFileDesc = fileName => {
  const reg = /(?:\.([^.]+))?$/;
  const fileFormat = reg.exec(fileName)[1];
  const isExcel = fileFormat === 'xls' || fileFormat === 'xlsx';
  const isPdf = fileFormat === 'pdf';
  const isPpt =
    fileFormat === 'ppt' ||
    fileFormat === 'pptx' ||
    fileFormat === 'ppsx' ||
    fileFormat === 'pot' ||
    fileFormat === 'potx' ||
    fileFormat === 'pptm';
  const isWord =
    fileFormat === 'docs' || fileFormat === 'docx' || fileFormat === 'doc';
  const isTxt = fileFormat === 'txt';
  if (isExcel) {
    return 'Spreadsheet';
  } else if (isPdf) {
    return 'PDF';
  } else if (isPpt) {
    return 'Presentation';
  } else if (isWord) {
    return 'Document';
  } else if (isTxt) {
    return 'Text';
  }
};

export { displayFileTypeImage, displayFileDesc };
