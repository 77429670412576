import styled from 'styled-components';

const BankAccountListHeader = styled.div`
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
`;

const BankAccountListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    /* padding-left: 10px; */
  }
`;

const BankAccountCard = styled.div`
  margin-top: 20px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 133.5px;
  border-radius: 2.5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);

  #message {
    font-size: 16px;
    font-family: 'Muli-Bold', sans-serif;
    color: #014a62;
    margin-left: 2%;
  }
`;

const BankAccountItemInformation = styled.div`
  display: flex;
  flex-direction: column;

  span {
    /* padding-top: 2.5px;
    padding-bottom: 2.5px; */
    padding: 2.5px 20px;
  }

  .bank-name {
    font-size: 14px;
    font-family: 'Muli-SemiBold', sans-serif;
    color: black;
  }

  .user-name {
    font-size: 12px;
    font-family: 'Muli-Regular', sans-serif;
    color: black;
    line-height: 20px;
  }

  .account-number {
    font-size: 12px;
    font-family: 'Muli-Regular', sans-serif;
    color: #a9a8a8;
  }
`;

const BankAccountItemAction = styled.div`
  display: flex;
  padding-right: 11px;
  width: 225px;
  flex-direction: column;
  justify-content: space-between;
  height: 75px;
  align-items: center;

  .ant-btn {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    width: 200px;
    border-radius: 2.5px;
    font-family: 'Muli-Regular';
  }
`;

export {
  BankAccountListHeader,
  BankAccountListWrapper,
  BankAccountCard,
  BankAccountItemInformation,
  BankAccountItemAction
};
