import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthenticationService } from '../Services';

export default ({ component: Component, ...rest }) => {
  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      AuthenticationService.listenCookieChange();
    }
  }, []);

  // this section is for local token stored, it should be allowed in v1
  // if (!AuthenticationService.getTokenAuth()) {
  //   if (process.env.NODE_ENV === 'development') {
  //     return <Redirect to={{ pathname: '/login' }} />;
  //   }
  // }

  return <Route {...rest} render={props => <Component {...props} />} />;
};
