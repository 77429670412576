import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import config from './Notification.config';
import style from './Notification.style';
import { routes } from '../../../Config/Router';

const PopOverNotification = ({ notifications, markAll }) => {
  const _renderBottomContent = () => (
    <div style={style.wrapperBottom}>
      <p onClick={() => markAll('notification', [])} style={style.markAsRead}>
        Mark as read
      </p>
      <Link to={routes.NOTIFICATION.path} style={style.link}>
        <p>See all</p>
      </Link>
    </div>
  );

  const _renderDetailNotification = notification => (
    <>
      <p style={style.text}>
        {notification.suffixDescription}
        <span style={style.nameText}> {notification.name} </span>
        {notification.prefixDescription}
      </p>
      <p style={style.timeStampText}>{notification.timeStamp}</p>
    </>
  );

  const _renderContent = () => (
    <>
      {notifications.map(notification => (
        <div key={notification.name} style={style.wrapperRow}>
          <Row>
            <Col span={5}>
              <Avatar size={22} src={notification.image} />
            </Col>
            <Col span={19}>{_renderDetailNotification(notification)}</Col>
          </Row>
          <hr style={style.hr} />
        </div>
      ))}
    </>
  );

  return (
    <div style={style.wrapper}>
      {_renderContent()}
      {_renderBottomContent()}
    </div>
  );
};

PopOverNotification.propTypes = config.propTypes;
PopOverNotification.defaultProps = config.defaultProps;

export default PopOverNotification;
