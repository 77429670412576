import { gql } from 'apollo-boost';

const GET_LIST_EVENT = gql`
  query($search: String, $offset: Int, $limit: Int) {
    event_schedules(
      where: { title: { _ilike: $search } }
      offset: $offset
      limit: $limit
    ) {
      id
      title
      description
      summary
      slug
      thumbnail
      event_type {
        name
      }
      datestart
      dateend
      location_info
      pricing
      global_user {
        id
        name
        avatar
        people_work_placements {
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
      average_rating
      count_reviews
      status
    }
  }
`;

export { GET_LIST_EVENT };
