import gql from 'graphql-tag';

export const GET_DATA_EDUCATION = gql`
  query($userId: uuid!) {
    people_profile(where: { id: { _eq: $userId } }) {
      id
      global_user {
        id
        schools: people_profile_educations(where: { company_profile: {} }) {
          id
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
    }
  }
`;
export const UPDATE_INTRO = gql`
  mutation($userId: uuid!, $educationId: Int, $name: String!, $data: jsonb!) {
    update_global_users(
      _set: { name: $name, main_education: $educationId }
      where: { id: { _eq: $userId } }
    ) {
      affected_rows
    }
    update_people_profile(
      _append: { profile_fields: $data }
      where: { id: { _eq: $userId } }
    ) {
      affected_rows
    }
  }
`;
