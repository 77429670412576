import React from 'react';
import { Wrapper, BodyBoxClassroom } from './AboutClassroom.style';

const AboutClassroomComponent = props => {
  const { classroomInfo } = props;
  return (
    <>
      <Wrapper>
        <BodyBoxClassroom>
          <h3>Tentang kelompok belajar ini</h3>
          <hr></hr>
          {classroomInfo && (
            <div className="sub">
              <label>Jenis</label>
              <p>General</p>
              <br />
              <label>Keterangan</label>
              {classroomInfo?.description?.split(/\r?\n/).map((line, id) => {
                return <p key={id}> {line}</p>;
              })}
            </div>
          )}
        </BodyBoxClassroom>
      </Wrapper>
      <Wrapper>
        <BodyBoxClassroom>
          <h3>Aturan Kelompok Belajar</h3>
          <hr></hr>
          {classroomInfo && (
            <div className="sub">
              {classroomInfo?.rules?.split(/\r?\n/).map((line, id) => {
                return <p key={id}> {line}</p>;
              })}
            </div>
          )}
        </BodyBoxClassroom>
      </Wrapper>
    </>
  );
};

export default AboutClassroomComponent;
