import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Muli-Regular', sans-serif;

  .title {
    font-size: 14px;
    font-weight: 600;
    padding: 24px 20px 0px 20px;
  }

  .alert {
    color: #ef4d5e;
  }

  .primary {
    color: #014a62;
  }

  .content {
    padding: 0px 20px 24px 20px;
    font-size: 12px;
  }

  .action {
    padding: 10px 20px 24px 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .btn-wlb-cancel,
    .btn-wlb-cancel:hover {
      color: #a9a8a8;
      border: none;
    }
    .ant-btn-primary,
    ant-btn-primary:active {
      border: none;
      background-color: #014a62;
    }
    .ant-btn-primary:hover {
      border: none;
      background-color: #027499;
    }
    .btn-wlb-large {
      height: 45px;
    }
    .btn-wlb-small {
      height: 32px;
    }
  }
`;

// export const ButtonStyled = styled(Button)`
//   background-color: ${Assets.COLOR_PALLETE.dark_blue};
// `;
