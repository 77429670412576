import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import { AuthenticationService } from './Services';


// remove redirect to login
if (process.env.NODE_ENV !== 'development') {
  AuthenticationService.listenCookieChange();
}
//   window.location.href = process.env.REACT_APP_SSO_URL;
// } else {
ReactDOM.render(<App />, document.getElementById('root'));
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
