import React, { useState, useEffect } from 'react';
import {
  ListWrapper,
  ListContent,
  ListAction,
  NameWrapper,
  ActionWrapper,
  BorderWrapper,
  BottonWrapper
} from './Recommendation.style';
import { Avatar, Switch, message as msg } from 'antd';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import { DirectToUserDetail } from '../../../../Components';

export default function ListItem({
  name,
  date,
  position,
  relationship,
  message,
  avatar,
  type,
  onDelete,
  onRevise,
  status,
  id,
  updateStatus,
  refetch,
  userId
}) {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (status === 'PUBLISH') {
      setCheck(true);
    }
  }, [status]);

  const handleCheck = (e, idslc) => {
    setCheck(e);
    updateStatus({
      variables: {
        id: idslc,
        status: e ? 'PUBLISH' : 'HIDE'
      }
    })
      .then(() => {
        refetch();
        msg.success(`Update status success`);
      })
      .catch(err => {
        msg.error(`Update status error, ${err}`);
      });
  };

  return (
    <ListWrapper>
      <div style={{ width: 58 }}>
        <Avatar src={avatar} size={58} />
      </div>
      <BorderWrapper style={{ width: '100%' }}>
        <ListContent>
          <DirectToUserDetail userId={userId}>
            <NameWrapper>
              <ThypoStyled
                fontSize="14px"
                color="#000"
                margin="0px 14px 0px 0px"
              >
                {name}
              </ThypoStyled>
              <ThypoStyled fontSize="12px" color="#a9a8a8">
                {date}
              </ThypoStyled>
            </NameWrapper>
            <ThypoStyled
              fontSize="12px"
              color="#a9a8a8"
              margin="0px 0px 9px 0px"
            >
              {position}
            </ThypoStyled>
            <ThypoStyled
              style={{ textTransform: 'capitalize' }}
              fontSize="12px"
              color="#014a62"
              margin="0px 0px 9px 0px"
            >
              {relationship}
            </ThypoStyled>
            <ThypoStyled fontSize="12px" color="#000">
              {message}
            </ThypoStyled>
          </DirectToUserDetail>
        </ListContent>
        <ListAction>
          <BottonWrapper>
            {type !== 'received' && (
              <ThypoStyled
                onClick={onDelete}
                margin="0px 12px 0px 0px"
                cursor="pointer"
                color="#ef4d5e"
                fontSize="12px"
              >
                Hapus
              </ThypoStyled>
            )}
            <ThypoStyled
              onClick={onRevise}
              cursor="pointer"
              color="#039be5"
              fontSize="12px"
            >
              {type === 'received' ? 'Minta revisi' : 'Revisi'}
            </ThypoStyled>
          </BottonWrapper>
          <ActionWrapper>
            <ThypoStyled margin="0px 12px 0px 0px" fontSize="12px">
              {check ? 'Tampilkan' : 'Sembunyikan'}
            </ThypoStyled>
            <Switch
              defaultChecked={status === 'PUBLISH'}
              onChange={e => handleCheck(e, id)}
              size="small"
            />
          </ActionWrapper>
        </ListAction>
      </BorderWrapper>
    </ListWrapper>
  );
}
