import { Avatar } from 'antd';
import React from 'react';
import { ModalDetail } from '..';
import DirectToUserDetail from '../DirectToUserDetail';
import { ScrollWrapper } from '../ShortcutIcon/EmailAddress/EmailAddress.style';
import { DivStyled, ThypoStyled } from './DetailUserSection.style';
// import ModalDetail from "../ModalDetail";
// import { ModalDeleteWLBCheck, ModalDetail } from "../..";

export default function DetailSubordinate({
  open,
  handleClose,
  data,
  nameUser
}) {
  const _userInfo = employee => {
    return employee.length > 0
      ? employee.map((res, i) => {
          return (
            res?.people_work_placements.length > 0 &&
            res?.people_work_placements.map((row, j) => {
              return (
                <DirectToUserDetail key={i} userId={row?.global_user?.id}>
                  <DivStyled
                    display="flex"
                    flexDirection="row"
                    margin="0px 0px 14px 0px"
                  >
                    <Avatar
                      src={row?.global_user?.avatar}
                      shape="square"
                      size="large"
                    />
                    <DivStyled
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <DivStyled
                        margin="0px 8px"
                        overflow="hidden"
                        width="170px"
                      >
                        <ThypoStyled fontSize="12px" fontWeight="600">
                          {row?.global_user?.name}
                        </ThypoStyled>
                        <ThypoStyled fontSize="12px">{res?.title}</ThypoStyled>
                      </DivStyled>
                    </DivStyled>
                  </DivStyled>
                </DirectToUserDetail>
              );
            })
          );
        })
      : null;
  };
  const _leftComponent = leftComponent => (
    <DivStyled
      display="flex"
      flexDirection="column"
      width="auto"
      padding="10px"
    >
      {/* <DivStyled display="flex" flexDirection="column">
        {leftComponent.immediate[0] !== null && (
          <ThypoStyled fontSize="14px" fontWeight="600" margin="15px 0px">
           Immediate Manager
          </ThypoStyled>
        )}
        {_userInfo(leftComponent.immediate[0] !== null ?leftComponent.immediate : [])}
      </DivStyled> */}

      <DivStyled display="flex" flexDirection="column">
        {leftComponent.supervisor.length > 0 && (
          <ThypoStyled fontSize="14px" fontWeight="600" margin="15px 0px">
            Direct Supervisor
          </ThypoStyled>
        )}
        {_userInfo(leftComponent.supervisor)}
      </DivStyled>

      <DivStyled display="flex" flexDirection="column">
        {leftComponent.subordinate.length > 0 && (
          <ThypoStyled fontSize="14px" fontWeight="600" margin="15px 0px">
            Direct Subordinates
          </ThypoStyled>
        )}
        <DivStyled
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {_userInfo(leftComponent.subordinate)}
        </DivStyled>
      </DivStyled>
    </DivStyled>
  );

  return (
    <>
      <ModalDetail
        title={`${nameUser}'s OfficeMate`}
        open={open}
        onClose={handleClose}
        sizeSmall
        padding="0px"
      >
        <ScrollWrapper>{_leftComponent(data)}</ScrollWrapper>
      </ModalDetail>
    </>
  );
}
