import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Row, Tooltip } from 'antd';
import _ from 'lodash';

import style from './MentorshipList.style';

const strMaxLength = (text, maxLength) => {
  if (text.length >= maxLength) {
    return text.substring(0, maxLength).trim() + '...';
  } else {
    return text;
  }
};

const MentorshipListComponent = props => {
  const { members, isIndividual } = props;

  const param = useParams();
  const classroomId = param.id;

  const [mentors, setMentors] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [individualMentor, setIndividualMentor] = useState(null);
  const [memberIndividual, setMemberIndividual] = useState(null);

  useEffect(() => {
    if (members) {
      const _mentors = members.filter(mentor => mentor.is_mentor);
      const _participants = members.filter(
        member =>
          !member.is_admin && !member.is_mentor && !member.is_individual_mentor
      );
      const _individualMentor = members.find(
        mentor => mentor.is_individual_mentor
      );
      const _memberIndividual = members.find(
        mentor => !mentor.is_individual_mentor
      );

      setMentors(_mentors);
      setParticipants(_participants);
      setIndividualMentor(_individualMentor);
      setMemberIndividual(_memberIndividual);
    }
  }, [members]);

  const _renderHeader = () => (
    <div>
      <Row>
        <Col span={24}>
          <span style={style.headerTitle}>Anggota</span>
        </Col>
      </Row>

      <hr style={style.hr} />
    </div>
  );

  const _renderFooter = () => (
    <div>
      <hr style={style.hr} />

      <Row>
        <Col span={24}>
          <Link to={`/social-learning/${classroomId}/member`}>Lihat Semua</Link>
        </Col>
      </Row>
    </div>
  );

  const _renderListMentor = () => {
    return (
      mentors.length > 0 &&
      mentors.slice(0, 5).map(mentor => (
        <Col key={mentor.global_user.id} span={4} style={style.colImage}>
          <div style={style.divImage}>
            <a
              href={`${process.env.REACT_APP_HC_LEARNING}/learning-contributor/${mentor.global_user.id}/course`}
            >
              <Tooltip
                placement="bottom"
                title={mentor.global_user.name}
                overlayStyle={style.mentorshipTooltip}
              >
                <img
                  src={mentor.global_user.avatar}
                  alt={mentor.global_user.name}
                  style={style.iconQuick}
                />
              </Tooltip>
            </a>
          </div>
        </Col>
      ))
    );
  };

  const _renderListMember = () => {
    const list = isIndividual ? memberIndividual : participants;
    return (
      list.length > 0 &&
      list.slice(0, 5).map(participant => (
        <Col key={participant.global_user.id} span={4} style={style.colImage}>
          <div style={style.divImage}>
            <Tooltip
              placement="bottom"
              title={participant.global_user.name}
              overlayStyle={style.mentorshipTooltip}
            >
              <img
                src={participant.global_user.avatar}
                alt={participant.global_user.name}
                style={style.iconQuick}
              />
            </Tooltip>
          </div>
        </Col>
      ))
    );
  };

  const _renderIndividualMentor = type => {
    const list = type === 'member' ? memberIndividual : individualMentor;
    let userId = '';
    let avatar = '';
    let name = '';
    let work = '';

    if (list) {
      userId = list.global_user.id;
      avatar = list.global_user.avatar;
      name = list.global_user.name;
      work =
        list.global_user.people_work_placements &&
        list.global_user.people_work_placements[0] &&
        list.global_user.people_work_placements[0].company_job_profile &&
        !_.isNull(
          list.global_user.people_work_placements[0].company_job_profile.title
        )
          ? list.global_user.people_work_placements[0].company_job_profile
              .title +
              ' at ' +
              list.global_user.people_work_placements &&
            list.global_user.people_work_placements[0] &&
            list.global_user.people_work_placements[0].company_profile &&
            list.global_user.people_work_placements[0].company_profile
              .brand_name
          : '-';
    }

    const ComponentIndividual = () => {
      return (
        <Col key={userId} span={24} style={style.colIndividual}>
          <div style={style.divImage}>
            <img src={avatar} alt={name} style={style.iconQuick} />
          </div>
          <div style={style.divProfile}>
            <span style={style.profileName}>{name}</span>
            <br />
            <span style={style.profileWork}>{strMaxLength(work, 30)}</span>
          </div>
        </Col>
      );
    };

    if (type === 'member') {
      return (
        <Link to={`/profile/${userId}/detail`}>
          <ComponentIndividual />
        </Link>
      );
    } else {
      return (
        <a
          href={`${process.env.REACT_APP_HC_LEARNING}/learning-contributor/${userId}/course`}
        >
          <ComponentIndividual />
        </a>
      );
    }
  };

  const _renderBody = () => (
    <>
      <Row type="flex" style={style.wrapperMenu}>
        <Col span={24}>
          <span style={style.menuTitle}>
            Kontributor Pembelajaran {!isIndividual && `(${mentors.length})`}
          </span>
        </Col>
        {isIndividual ? _renderIndividualMentor() : _renderListMentor()}
      </Row>
      <Row type="flex" style={style.wrapperMenu}>
        <Col span={24}>
          <span style={style.menuTitle}>
            Peserta {!isIndividual && `(${participants.length})`}
          </span>
        </Col>
        {isIndividual ? _renderIndividualMentor('member') : _renderListMember()}
      </Row>
    </>
  );

  return (
    <div style={style.container}>
      {_renderHeader()}
      {_renderBody()}
      {!isIndividual && _renderFooter()}
    </div>
  );
};

export default MentorshipListComponent;
