import React, { useEffect, useState } from 'react';
import FollowerComponent from './Follower.component';
import { message } from 'antd';
import { checkNextPage } from '../../Utils/CommonUtils';
import { ConnectionService } from '../../Services';
import queryString from 'query-string';
import {
  useBlockMe,
  useInfiniteScroll
} from '../../SharedComponents/CustomHook';
import { useAsyncEffect } from 'use-async-effect';
import { Error404Page } from '../../Pages';

const FollowerContainer = props => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [followUser, setFollowUser] = useState([]);
  const [isBottom, setIsBottom] = useInfiniteScroll('follower-page');
  const queryWord = queryString.parse(props.location.search).keyword;
  const userId = props.match.params.profileId;
  const [hasNextPage, setHasNextPage] = useState(true);
  const [totalFollowUser, setTotalFollowUser] = useState(0);
  const [blockedMe, blockLoaded] = useBlockMe();
  const [isFounded, setIsFounded] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setFollowUser([]);
    setPage(1);
    setHasNextPage(true);
  }, [queryWord, userId]);

  useAsyncEffect(
    async isMount => {
      if (!isMount()) return;
      if (blockLoaded && !loading && hasNextPage && (isBottom || page === 1)) {
        setLoading(true);
        setFirstLoad(true);
        await ConnectionService.getListFollowers(userId, page, queryWord)
          .then(response => {
            if (!isMount()) return;
            setIsFounded(true);
            const { total, per_page, data } = response;
            const nextPage = checkNextPage(total, page, per_page);
            if (nextPage) {
              setPage(page + 1);
            }
            setTotalFollowUser(total);
            setHasNextPage(nextPage);
            let filteredUser = data.filter(
              item => !blockedMe?.includes(item.id)
            );
            setFollowUser(followUser.concat(filteredUser));
          })
          .catch(() => {
            if (!isMount()) return;
            setIsFounded(false);
            message.error('Something Went Wrong');
          })
          .finally(() => {
            if (!isMount()) return;
            setLoading(false);
            setIsBottom(false);
          });
      }
    },
    [
      hasNextPage,
      isBottom,
      loading,
      page,
      queryWord,
      followUser,
      setIsBottom,
      userId,
      blockLoaded
    ]
  );

  const fetchSearch = async (location, keyword) => {
    const { pathname } = location;
    const searchParam = keyword ? '?keyword=' + keyword : '';
    props.history.push(pathname + searchParam);
  };

  const renderContent = () => {
    if (blockLoaded && firstLoad) {
      return (
        <div id="follower-page">
          {isFounded === true ? (
            <FollowerComponent
              searchValue={queryWord}
              loading={loading}
              defaultSearch={queryWord}
              handleSearch={fetchSearch}
              userList={followUser}
              totalUser={totalFollowUser}
              setTotalUser={setTotalFollowUser}
              blockLoaded={blockLoaded}
              userId={userId}
              {...props}
            />
          ) : (
            <Error404Page />
          )}
        </div>
      );
    }
    return <div />;
  };

  return renderContent();
};

export default FollowerContainer;
