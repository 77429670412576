import React from 'react';

import config from './AccountSettingsPage.config';
import { Enhancer } from '../../SharedComponents';

const AccountSettingsContainer = () => <div />;

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.accountSettingsMenubar,
  defaultMenu: config.defaultMenuBar
})(AccountSettingsContainer);
