import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import ListUtils from '../../../../Components/DetailUserSection/ListUtils.component';
import AddEditHobbies from './AddEditHobbies';
import MoreHobbiesModal from './MoreHobbiesModal';

export default function Hobbies({
  isUser,
  dataHobbies,
  refetch,
  profileId,
  people_profile,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  return (
    <>
      <DetailUserSection
        title="Hobi"
        onClickAction={() =>
          setOpen({ ...open, open: true, data: null, actionType: 'add' })
        }
        action={isUser ? 'plus' : null}
      >
        <ListUtils
          setOpen={setOpen}
          type="hobbies"
          isUser={isUser}
          data={dataHobbies}
          total={dataHobbies?.length}
          editOnChild
          showMoreText=" Hobi"
          onClickMore={() => setOpen({ ...open, openMore: true })}
        />
      </DetailUserSection>

      <MoreHobbiesModal
        nameUser={nameUser}
        open={open.openMore}
        setOpen={setOpen}
        data={dataHobbies}
        isUser={isUser}
      />

      <AddEditHobbies
        setOpen={setOpen}
        openState={open}
        people_profile={people_profile}
        profileId={profileId}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onClose={
          open.fromList
            ? () =>
                setOpen({
                  ...open,
                  openMore: true,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false
                })
            : () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false
                })
        }
      />
    </>
  );
}
