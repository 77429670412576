import React, { useState } from 'react';
import { Modal, Input, Avatar, Spin } from 'antd';
import { Header, Content } from './ModalAddContributor.style';
import { ButtonWlb } from '..';

const ModalAddContributorComponent = props => {
  const { Search } = Input;

  const {
    open,
    onClose,
    listContributor,
    loadingListContributor,
    onSubmit,
    setSearchContrib
  } = props;
  const [selected, setSelected] = useState(null);

  return (
    <Modal
      visible={open}
      closable={false}
      centered={false}
      bodyStyle={{ padding: 0 }}
      footer={[
        <ButtonWlb type="link" onClick={onClose}>
          Cancel
        </ButtonWlb>,
        <ButtonWlb
          size="default"
          type="primary"
          onClick={() => onSubmit(selected)}
          disabled={!selected}
        >
          Request
        </ButtonWlb>
      ]}
    >
      <Header>Add Learning Contributor</Header>
      <Content>
        <div className="search">
          <Search
            size="large"
            placeholder="Search Learning Contributor"
            onChange={e => setSearchContrib(e.target.value)}
          />
        </div>
        <div className="title">Learning Contributor</div>
        {loadingListContributor ? (
          <div className="search">
            <Spin />
          </div>
        ) : (
          listContributor.map((val, i) => {
            return (
              <div
                className="user-wrapper"
                key={i}
                onClick={() => {
                  setSelected(val);
                }}
                style={{
                  backgroundColor: selected === val ? '#eff2f4' : ''
                }}
              >
                <div className="avatar">
                  <Avatar
                    src={val.avatar ? val.avatar : ''}
                    icon={!val.avatar ? 'user' : ''}
                    size={45}
                  />
                </div>
                <div className="user">
                  <div className="name">{val.name}</div>
                  <div className="position">
                    {
                      val?.people_work_placements?.[0]?.company_job_profile
                        ?.title
                    }
                  </div>
                </div>
              </div>
            );
          })
        )}
      </Content>
    </Modal>
  );
};

export default ModalAddContributorComponent;
