import gql from 'graphql-tag';

export const LIST_SENT_RECOMMENDATION = gql`
  query($user: uuid!, $id: Int) {
    people_profile_recommendations(
      where: {
        recommendation_from: { _eq: $user }
        deletedAt: { _is_null: true }
        id: { _eq: $id }
      }
      order_by: [{ id: desc }]
    ) {
      id
      globalUserByRecommendationTo {
        id
        name
        avatar
        people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
          company_job_profile {
            title
          }
        }
      }
      position
      relationship
      description
      status
      date_added
      last_modified
    }
  }
`;

export const UPDATE_STATUS_RECOMMENDATION = gql`
  mutation($id: Int!, $status: String!) {
    update_people_profile_recommendations(
      _set: { status: $status }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const ASK_FOR_REVISION = gql`
  mutation($objects: [people_profile_request_recommendations_insert_input!]!) {
    insert_people_profile_request_recommendations(objects: $objects) {
      affected_rows
    }
  }
`;

export const GET_RELATIONSHIP = gql`
  {
    people_profile_recommendation_relationships {
      id
      name
    }
  }
`;

export const GET_POSITION = gql`
  query($userLogin: uuid!) {
    experience: people_profile_experiences(
      where: { user: { _eq: $userLogin } }
    ) {
      id
      company_name
    }

    education: people_profile_educations(where: { user: { _eq: $userLogin } }) {
      company_profile {
        id
        brand_name
      }
    }
  }
`;

export const EDIT_RECOMMENDATION = gql`
  mutation(
    $position: String
    $relationship: String
    $description: String
    $id: Int!
  ) {
    update_people_profile_recommendations(
      _set: {
        position: $position
        relationship: $relationship
        description: $description
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_RECOMMENDATION = gql`
  mutation($id: Int!) {
    update_people_profile_recommendations(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
