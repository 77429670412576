import { DatePicker, Form, Input, message, Switch } from 'antd';
import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { ModalDeleteWLBCheck, ModalEdit } from '../../../../Components';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import { AuthenticationService } from '../../../../Services';
import { getErrorMessage, isAfter } from '../HelperDetailScreen';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom,
  TitleField
} from '../../../../SharedComponents';
import {
  ADD_SERVICE_AGREEMENT,
  DELETE_SERVICE_AGREEMENT,
  UPDATE_SERVICE_AGREEMENT
} from './ServiceAgreement.graphql';

const ComponentDate = ({
  labelName,
  fieldName,
  fieldValue,
  onChange,
  getFieldDecorator,
  disabledDate
}) => {
  return (
    <Form.Item
      label={<TitleField>{labelName}</TitleField>}
      style={{
        display: 'inline-block',
        marginBottom: 0,
        width: '50%'
      }}
    >
      {getFieldDecorator(fieldName, {
        initialValue: fieldValue
          ? moment(fieldValue, 'YYYY/MM/DD')
          : moment(new Date(), 'YYYY/MM/DD'),
        rules: [
          {
            required: true,
            message: 'Bagian ini diperlukan'
          }
        ]
      })(
        <DatePicker
          placeholder="Pilih Tanggal"
          onChange={onChange}
          format={'YYYY-MM-DD'}
          style={{ width: '90%' }}
          disabledDate={disabledDate}
        />
      )}
    </Form.Item>
  );
};

const UPLOAD_ID = 'upload-service-agreement';
const OPTION_USER = {
  context: {
    headers: {
      'X-Hasura-Role': 'user'
    }
  }
};

const AddEditServiceAgreementComponent = ({
  open,
  actionType,
  onClose,
  setOpen,
  form,
  data,
  refetch
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    resetFields
  } = form;
  const [share, setShare] = useState(false);
  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorTotalAttch, setTotalAttch] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });

  const [addServiceAgreement] = useMutation(ADD_SERVICE_AGREEMENT, OPTION_USER);
  const [updateServiceAgreement] = useMutation(
    UPDATE_SERVICE_AGREEMENT,
    OPTION_USER
  );
  const [deleteServiceAgreement] = useMutation(
    DELETE_SERVICE_AGREEMENT,
    OPTION_USER
  );

  useEffect(() => {
    if (data) {
      setAttachment(data?.attachments || []);
      setShare(data?.is_share || false);
    }
  }, [data]);

  const handleClose = () => {
    onClose();
    resetFields();
    setAttachment([]);
    setShare(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen(openState => ({
      ...openState,
      open: false
    }));
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen(openState => ({
      ...openState,
      open: true
    }));
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const totalAttachment = tempFile.length + attachment.length;
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (totalAttachment > 5) {
      setTotalAttch(true);
      setTimeout(() => {
        setTotalAttch(false);
      }, 3000);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
    }
  };

  const handleChange = (val, e) => {
    if (e === 'upload-attachment') {
      uploadAttachment(val);
    }
  };

  const handleDelete = () => {
    deleteServiceAgreement({
      variables: {
        id: data?.id
      }
    })
      .then(() => {
        refetch();
        message.success(`Berhasil menghapus perjanjian layanan`);
        setDeleteState({ open: false });
        handleClose();
      })
      .catch(err => {
        message.error(`Gagal menghapus perjanjian layanan, ${err}`);
        setDeleteState({ open: false });
      });
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const submit = {
          ...(actionType === 'edit' && { id: data?.id }),
          object: {
            is_share: share,
            code: values.code,
            name: '',
            start_date: moment(values.start_date).format('YYYY-MM-DD'),
            end_date: moment(values.end_date).format('YYYY-MM-DD'),
            description: values.description,
            attachments: attachment
          }
        };

        if (actionType === 'add') {
          addServiceAgreement({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil menambahkan perjanjian layanan`);
              handleClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan perjanjian layanan, ${err}`);
            });
        } else {
          updateServiceAgreement({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success(`Berhasil mengubah perjanjian layanan`);
              handleClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah perjanjian layanan, ${err}`);
            });
        }
      }
    });
  };

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
    document.getElementById(UPLOAD_ID).value = null;
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${
          actionType === 'add' ? 'Tambahkan' : 'Ubah'
        } Perjanjian Layanan`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Kode Perjanjian Layanan</TitleField>}
          >
            {getFieldDecorator('code', {
              initialValue: data?.code,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              <Input
                placeholder="Tambahkan kode perjanjian layanan"
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <ComponentDate
                getFieldDecorator={getFieldDecorator}
                labelName="Tanggal Mulai"
                fieldName="start_date"
                fieldValue={data?.start_date || undefined}
                onChange={() => setFieldsValue({ end_date: undefined })}
                disabledDate={current =>
                  isAfter(current, getFieldValue('end_date'))
                }
              />
              <ComponentDate
                getFieldDecorator={getFieldDecorator}
                labelName="Tanggal Selesai"
                fieldName="end_date"
                fieldValue={data?.end_date || undefined}
                disabledDate={current =>
                  isAfter(getFieldValue('start_date'), current)
                }
              />
            </Form.Item>
          </div>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data?.description,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              <Input.TextArea
                rows={4}
                placeholder="Tambahkan keterangan"
                onChange={value => handleChange(value, 'description')}
              />
            )}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id={UPLOAD_ID}
              disabled={(attachment.length >= 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorTotalAttch && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.url || res.link}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>
          <ButtonFrom submitLabel="Simpan" onCancel={handleClose} />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Perjanjian Layanan"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditServiceAgreement = Form.create({ name: 'Add_Service_Agreement' })(
  AddEditServiceAgreementComponent
);

export default AddEditServiceAgreement;
