import Constants from '../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../Assets';

export const OptionItemWrapper = styled.div`
  font-family: ${Assets.FONT.MULI};
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey2};
  padding: 8px 15px;

  .component_item_title {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
  }

  .component_item_description {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export default {
  title: {
    marginTop: 17.8,
    marginBottom: 9.5,
    fontSize: 12,
    color: Constants.COLORS.GREY
  },
  selectedBox: {
    paddingTop: 9.5,
    paddingBottom: 9.5,
    paddingLeft: 12.5,
    paddingRight: 12.5,
    marginBottom: 25,
    backgroundColor: Constants.COLORS.DARK_GREY
  },
  select: {
    width: '100%',
    height: 25,
    fontSize: 12,
    color: Constants.COLORS.BLACK,
    marginBottom: 25
  },
  optionCompany: {
    fontSize: 12,
    color: Constants.COLORS.BLACK
  },
  optionTask: {
    padding: 0,
    margin: 0
  },
  optionChildrenStyle: {
    rowTask: {
      paddingTop: 8,
      paddingLeft: 12,
      paddingRight: 12
    },
    imgTask: {
      width: 44,
      height: 44
    },
    fromTask: {
      height: 11,
      fontSize: 10,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    },
    asTask: {
      height: 11,
      fontSize: 10,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    }
  },
  optionSelectedStyle: {
    rowTask: {
      padding: 0
    },
    imgTask: {
      width: 44
      // height: 44
    },
    titleTask: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 2.5
    },
    fromTask: {
      height: 13,
      fontSize: 12,
      color: Constants.COLORS.DARK_BLUE,
      marginBottom: 1.5
    },
    asTask: {
      height: 13,
      fontSize: 12,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    }
  }
};
