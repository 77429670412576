import React, { useState, useEffect } from 'react';
import { DivStyled, ThypoStyled, ButtonEdit } from './DetailUserSection.style';
import Assets from '../../Assets';
import { Icon } from 'antd';
import { AttachementItemIcon } from '../../SharedComponents';
import AvatarDetail from './AvatarDetail';
import { useMediaQuery } from '@material-ui/core';

export default function ListDetail({
  data,
  type,
  editOnChild,
  showMoreText = '',
  isUser,
  setOpen,
  nameUser = ''
}) {
  const isMobileScreen = useMediaQuery('(max-width: 1200px)');
  const [isMore, setIsMore] = useState(false);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (data) {
      setValue(data);
    }
  }, [data]);

  if (data === undefined) {
    return <h1>Loading...</h1>;
  }
  if (data.length < 1) {
    return '';
  }

  const _buttonEdit = resEdit => (
    <div style={{ display: 'flex', alignContent: 'flex-start' }}>
      <ButtonEdit
        onClick={() =>
          setOpen(e => ({
            ...e,
            open: true,
            data: resEdit,
            actionType: 'edit'
          }))
        }
      >
        <Icon type="edit" />
      </ButtonEdit>
    </div>
  );

  const _renderChild = (res, i) => (
    <DivStyled
      key={i}
      padding="28px 0px "
      display="flex"
      flexDirection="column"
      borderBottom={
        value.length === 1 ? null : i === 0 && '1px solid rgba(0, 0, 0, 0.16)'
      }
    >
      <DivStyled
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <DivStyled display="flex" flexDirection="row">
          <div
            style={{
              ...(isMobileScreen
                ? {
                    width: 50,
                    height: 50
                  }
                : {
                    width: 100,
                    height: 100
                  })
            }}
          >
            <AvatarDetail avatar={res.avatar || Assets.DEFAULT_LOGO} />
          </div>
          <DivStyled margin="0px 0px 0px 24px">
            <ThypoStyled fontSize="14px" fontWeight="600">
              {res.dataEdit?.other_school || res.title}
            </ThypoStyled>
            {renderOhsa(res)}
            {type === 'education' && (
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.grey}
                fontStyle="italic"
              >
                {res.detail_school}
              </ThypoStyled>
            )}
            <ThypoStyled
              style={{ textTransform: 'capitalize' }}
              fontSize="12px"
              margin="6px 0px"
              color={Assets.COLOR_PALLETE.blue_link}
            >
              {res.company}
            </ThypoStyled>
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              fontStyle="italic"
            >
              {res.time}
            </ThypoStyled>
            {type === 'award' && (
              <>
                <ThypoStyled
                  fontSize="12px"
                  color="#014a62"
                  margin="0px 0px 12px 0px"
                >
                  {`Nomor Referensi ${res.reference_number}`}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.black}
                  fontStyle="italic"
                >
                  {`Diberikan kepada ${nameUser}`}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.grey}
                  fontStyle="italic"
                >
                  {`${res.letter_number} - ${res.certificate_number}`}
                </ThypoStyled>
              </>
            )}
            {type === 'education' && (
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.black}
                fontStyle="italic"
              >
                {`Indeks Prestasi Kumulatif (IPK): ${res.dataEdit
                  ?.education_fields?.gpa ?? '-'}`}
              </ThypoStyled>
            )}
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              fontStyle="italic"
            >
              {res.location}
            </ThypoStyled>
            {type === 'publication' && (
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.black}
                fontStyle="italic"
              >
                {res.date || '-'}
              </ThypoStyled>
            )}
            {type === 'speaker' && (
              <>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.black}
                  fontStyle="italic"
                >
                  {res.roles}
                </ThypoStyled>
                <ThypoStyled
                  fontSize="12px"
                  color={Assets.COLOR_PALLETE.black}
                  fontStyle="italic"
                >
                  {`${res?.dataEdit.scale} Event with ${res.total_participants} Participants`}
                </ThypoStyled>
              </>
            )}

            <ThypoStyled fontSize="12px" style={{ marginTop: 10 }}>
              {res.description.slice(
                0,
                (!isMore && 370) || res.description?.length
              ) || '-'}
            </ThypoStyled>
            {isMore && _renderChildDetail(res)}
            {isMore && _renderChildBottom(res)}
            {isMore && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px'
                }}
              >
                {res.attachments &&
                  res.attachments !== '[]' &&
                  res.attachments.length >= 1 &&
                  res.attachments.map((res, key) => (
                    <AttachementItemIcon
                      key={key}
                      url={res.url}
                      name={res.name}
                    />
                  ))}
              </div>
            )}
            <p
              onClick={() => setIsMore(!isMore)}
              style={{
                margin: '16px 0 0',
                fontSize: 12,
                cursor: 'pointer',
                userSelect: 'none',
                color: '#a9a8a8'
              }}
            >
              ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
            </p>
          </DivStyled>
        </DivStyled>
        {editOnChild && isUser && _buttonEdit(res.dataEdit)}
      </DivStyled>
    </DivStyled>
  );

  const _renderChildDetail = res => {
    if (type === 'education') {
      return (
        <ThypoStyled
          fontSize="12px"
          color={Assets.COLOR_PALLETE.blue_link}
          fontStyle="italic"
          style={{ padingLeft: '10px' }}
        >
          {`Nomor Ijazah ${res.dataEdit?.education_fields?.ceritificate_id ??
            '-'}`}
        </ThypoStyled>
      );
    } else if (type === 'ohsa') {
      return (
        <div style={{ paddingTop: '10px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              fontStyle="italic"
            >
              Incident Code :
            </ThypoStyled>
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.blue_link}
              fontStyle="italic"
              style={{ paddingLeft: '10px' }}
            >
              {`${res.dataEdit.incident_code}`}
            </ThypoStyled>
          </div>
        </div>
      );
    }
  };

  const _renderChildBottom = res => {
    if (type === 'publication') {
      return (
        <div style={{ paddingTop: '10px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.grey}
              // fontStyle="italic"
            >
              Tautan Publikasi:
            </ThypoStyled>
            <ThypoStyled
              fontSize="12px"
              color={Assets.COLOR_PALLETE.blue_link}
              // fontStyle="italic"
              style={{ paddingLeft: '5px' }}
            >
              {res.dataEdit.publication_url || '-'}
            </ThypoStyled>
          </div>
        </div>
      );
    }
  };

  const renderOhsa = res => {
    if (type === 'ohsa') {
      return (
        <>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            {res?.dataEdit?.company_location?.company_location}
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color="#014a62"
            margin="0px 0px 12px 0px"
          >
            {`${res?.dataEdit?.category ?? '-'}`}
          </ThypoStyled>
          <ThypoStyled
            style={{ textTransform: 'capitalize' }}
            fontSize="12px"
            margin="6px 0px"
            color={Assets.COLOR_PALLETE.blue_link}
          >
            {res?.employee ?? '-'}
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            {res?.dataEdit?.incident_location?.incident_location}
          </ThypoStyled>

          <ThypoStyled
            fontSize="12px"
            color="#014a62"
            margin="0px 0px 12px 0px"
          >
            {`${res?.time_ohsa ?? '-'}`}
          </ThypoStyled>
          <ThypoStyled /* height="53px" overflow="hidden" */ fontSize="12px">
            {res?.dataEdit?.severity}
          </ThypoStyled>
        </>
      );
    }
  };

  return (
    <DivStyled display="flex" flexDirection="column">
      {value && value.slice(0, 2).map((res, i) => _renderChild(res, i))}
      {value && value.length > 2 ? (
        <ThypoStyled
          color={Assets.COLOR_PALLETE.blue_link}
          fontSize="16px"
          fontWeight="600"
          cursor="pointer"
          margin="0px 0px 23px 0px"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: true
            }))
          }
        >
          {`Tampilkan Lebih Banyak ${showMoreText}...`}
        </ThypoStyled>
      ) : null}
    </DivStyled>
  );
}
