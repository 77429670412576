import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { Notification } from '../../Services';

export default function useGetUnreadNotification(allowHooksCall) {
  const [count, setCount] = useState(0);
  const [modules, setModules] = useState([]);

  useAsyncEffect(async () => {
    if (allowHooksCall) {
      await Notification.getUnreadNotification().then(response => {
        setCount(response?.unread ?? 0);
        setModules(response?.modules ?? []);
      });
    }
  }, []);

  return [count, modules];
}
