import React from 'react';
import { useApolloClient, useQuery } from 'react-apollo';

import { Avatar, Button, message } from 'antd';
import { CircularProgress } from '@material-ui/core';
import { OrgChart } from '@worklifebeyond/wlb-utils-components';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DefaultAvatar from '../../Assets/images/photo/default.png';

import {
  buildListToTree,
  mapOrgChartNode,
  OrgChartDisplay,
  OrgChartDrawer,
  OrgTitleLegend,
  PrimitiveOrgNode
} from './OSStyles';

import { GET_ORG_DETAIL_CHILDREN } from './OrganizationStructureModal.graphql';
import { useParams } from 'react-router-dom';

const EMPTY_ARRAY = [];

const OrganizationDetailsModal = props => {
  const {
    open = false,
    onClose,
    jobProfileId,
    organizationId,
    organizationName,
    organizationLevel
  } = props;

  const param = useParams();
  const companyId = param.id;

  const instanceRef = React.useRef();

  const [listing, setListing] = React.useState(EMPTY_ARRAY);

  const client = useApolloClient();

  const DEFAULT_OPTIONS = {
    context: {
      headers: {
        'X-Hasura-Role': 'public'
      }
    }
  };

  const { loading, error } = useQuery(GET_ORG_DETAIL_CHILDREN, {
    skip: !open,
    fetchPolicy: 'network-only',
    variables: {
      profileWhere: {
        deletedAt: { _is_null: true },
        company: { _eq: companyId },
        id: { _eq: jobProfileId }
      },
      childrenWhere: {
        deletedAt: { _is_null: true },
        company: { _eq: companyId },
        organization: { _eq: organizationId }
      }
    },
    ...DEFAULT_OPTIONS,
    onCompleted: data => {
      if (data) {
        setListing(data.profiles.map(node => mapOrgChartNode(node, null)));
      } else {
        setListing(EMPTY_ARRAY);
      }
    }
  });

  const tree = React.useMemo(() => {
    return buildListToTree(listing);
  }, [listing]);

  const expandNode = node => {
    const promise = client.query({
      query: GET_ORG_DETAIL_CHILDREN,
      fetchPolicy: 'network-only',
      variables: {
        profileWhere: {
          deletedAt: { _is_null: true },
          company: { _eq: companyId },
          parent: { _eq: node.id },
          organization: { _eq: organizationId }
        },
        childrenWhere: {
          deletedAt: { _is_null: true },
          company: { _eq: companyId },
          organization: { _eq: organizationId }
        }
      },
      ...DEFAULT_OPTIONS
    });

    promise.then(
      result => {
        const children = result.data.profiles;

        if (children.length < 1) {
          message.info(`${node.name} doesn't have have any subordinates`);
          return;
        }

        setListing(prev => {
          const next = [];
          const mapped = [];

          const exists = new Set();

          for (const child of children) {
            exists.add(child.id);
            mapped.push(mapOrgChartNode(child, node));
          }

          for (const child of prev) {
            if (!exists.has(child.id)) {
              next.push(child);
            }
          }

          return next.concat(mapped);
        });
      },
      error => {
        console.error(error);

        const msg = `Failed to retrieve subordinates for ${node.name}`;
        message.error(msg);
      }
    );
  };

  const collapseNode = node => {
    if (!node.children || node.children.length < 1) {
      return;
    }

    const removals = new Set();
    removals.add(node.id);

    // NOTE(intrnl): working around an issue with react-zoom-pan-pinch where
    // collapsing the top-most node results in the node being out of bounds.
    if (node.parent === null) {
      const instance = instanceRef.current;

      requestAnimationFrame(() => {
        instance.resetTransform();
      });
    }

    setListing(prev => {
      const next = [];

      for (const item of prev) {
        if (removals.has(item.parent)) {
          removals.add(item.id);
          continue;
        }

        next.push(item);
      }

      return next.length === prev.length ? prev : next;
    });
  };

  const renderNode = React.useCallback(
    node => {
      return (
        <OUNode
          data={node.data}
          hasChildren={!!node.children?.length}
          organizationLevel={organizationLevel}
          onExpand={() => expandNode(node)}
          onCollapse={() => collapseNode(node)}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationLevel]
  );

  return (
    <OrgChartDrawer
      visible={open}
      onClose={onClose}
      closable
      title={
        <div>
          <span>{organizationName} Details</span>
          {organizationLevel && (
            <OrgTitleLegend>
              <div
                className="legend-dots"
                style={{ backgroundColor: organizationLevel.color_hex }}
              />

              {organizationLevel.name}
            </OrgTitleLegend>
          )}
        </div>
      }
      placement="bottom"
      height="100%"
    >
      {error ? (
        <div>
          <div>Something went wrong while retrieving organization details</div>
        </div>
      ) : loading || !tree[0] ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <TransformWrapper
          options={{
            // NOTE(intrnl): this is about the limit of the orgchart, any less
            // and the line path drawing will break
            minScale: 0.75,
            maxScale: 2
          }}
        >
          {instance => (
            <OrgChartDisplay>
              {/* NOTE(intrnl): this seems to be the only way of retrieving the transform's instance */}
              {((instanceRef.current = instance), null)}

              <TransformComponent>
                <OrgChart
                  data={tree[0]}
                  render={renderNode}
                  chartId="osChart"
                />
              </TransformComponent>

              <Button.Group className="zoom-buttons">
                <Button onClick={instance.zoomIn}>+</Button>
                <Button onClick={instance.zoomOut}>-</Button>
              </Button.Group>
            </OrgChartDisplay>
          )}
        </TransformWrapper>
      )}
    </OrgChartDrawer>
  );
};

const comparator = (a, b) => {
  return (
    a.open === b.open &&
    a.jobProfileId === b.jobProfileId &&
    a.organizationId === b.organizationId &&
    a.organizationName === b.organizationName &&
    a.organizationLevel === b.organizationLevel
  );
};

export default React.memo(OrganizationDetailsModal, comparator);

const DEFAULT_VISIBLE_PLACEMENTS = 5;
const DEFAULT_LEVEL_COLOR = '#e5e5e5';

/// <OUNode />
const isOUNodeEqual = (a, b) => {
  return a.data.id === b.data.id && a.hasChildren === b.hasChildren;
};

const OUNode = React.memo(props => {
  const { data, hasChildren, organizationLevel, onExpand, onCollapse } = props;

  const [visible, setVisible] = React.useState(DEFAULT_VISIBLE_PLACEMENTS);

  const rank = data.company_employee_rank;
  const placements = data.people_work_placements;
  const length = placements.length;

  const canSeeMore = visible < length;
  const canSeeLess = visible > DEFAULT_VISIBLE_PLACEMENTS;

  const handleArrowClick = () => {
    if (hasChildren) {
      onCollapse(data);
    } else {
      onExpand(data);
    }
  };

  const handleSeeMore = ev => {
    ev.preventDefault();

    const next = visible + 5;
    setVisible(Math.min(next, length));
  };

  const handleSeeLess = ev => {
    ev.preventDefault();

    setVisible(DEFAULT_VISIBLE_PLACEMENTS);
  };

  return (
    <PrimitiveOrgNode
      style={{
        borderStyle: 'solid',
        borderColor: organizationLevel?.color_hex || DEFAULT_LEVEL_COLOR,
        width: 240
      }}
    >
      <div className="header column">
        <span className="title">{data.title}</span>
        <div className="legend">
          <div
            className="legend-dots"
            style={{
              backgroundColor: rank?.color_hex || DEFAULT_LEVEL_COLOR
            }}
          />
          {rank ? (
            <span>{rank.name}</span>
          ) : (
            <span style={{ color: '#747272' }}>None</span>
          )}
        </div>
      </div>

      <div className="list">
        {placements.length > 0 ? (
          placements.slice(0, visible).map(placement => (
            <div key={placement.id} className="item">
              <Avatar
                src={placement.global_user.avatar || DefaultAvatar}
                className="item-avatar"
              />

              <span className="item-name">{placement.global_user.name}</span>
            </div>
          ))
        ) : (
          <div className="item">
            <Avatar src={DefaultAvatar} className="item-avatar" />
            <span className="item-name">Unassigned</span>
          </div>
        )}

        {(canSeeMore || canSeeLess) && (
          <div className="item">
            {canSeeMore && (
              <button className="link" onClick={handleSeeMore}>
                +{length - visible} lebih
              </button>
            )}

            <span className="spacer" />

            {canSeeLess && (
              <button className="link" onClick={handleSeeLess}>
                Lihat Lebih Sedikit
              </button>
            )}
          </div>
        )}
      </div>

      {data.job_profile_children_aggregate.aggregate.count > 0 && (
        <button className="more" onClick={handleArrowClick}>
          <ExpandMoreIcon
            className="more-icon"
            style={{ transform: hasChildren ? 'rotate(180deg)' : '' }}
            title={`Show ${hasChildren ? 'less' : 'more'}`}
          />
        </button>
      )}
    </PrimitiveOrgNode>
  );
}, isOUNodeEqual);
