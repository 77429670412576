import React, { useState, useEffect } from 'react';
import { getPercent } from '../../Utils/CommonUtils';
import {
  PollingRadio,
  PollingRadioValue,
  VoteDetailWrapper,
  TextLink,
  style
} from './PostPoll.style';
import { useHistory } from 'react-router-dom';
import Assets from '../../Assets';
import moment from 'moment';
import ModalShowVote from './ModalShowVotes.component';

const PostPollComponent = props => {
  const {
    itemPost,
    handleVote,
    handleUndoVote,
    sharedPost,
    isSharing,
    profileId
  } = props;

  const history = useHistory();

  const [isVoted, setIsVoted] = useState({});
  const [showVote, setShowVote] = useState(false);

  useEffect(() => {
    if (itemPost) {
      if (itemPost.type === 4) {
        if (itemPost.poll.options.length > 0) {
          const _isVoted = itemPost.poll.options.find(item => item.isVotedByMe);
          if (_isVoted) {
            setIsVoted(_isVoted);
          }
        }
      }
    }
  }, [itemPost]);

  const handleShowVote = () => {
    setShowVote(!showVote);
  };

  const daysRemaining = endDate => {
    const _todaysdate = new Date();
    const _endDate = new Date(endDate);

    return _endDate - _todaysdate;
  };

  const convertDate = ms => {
    const days = ms / 1000 / 60 / 60 / 24;
    return Math.floor(days);
  };

  const handleClickPost = () => {
    const postOwner = itemPost?.user?.id;
    const url = `/user/${postOwner}/post/${itemPost.id}`;
    history.push(url);
  };

  const handleUndo = postId => {
    setIsVoted({});
    handleUndoVote(postId);
  };

  return (
    <div
      onClick={() => (sharedPost ? handleClickPost() : null)}
      style={{ cursor: sharedPost ? 'pointer' : 'default' }}
    >
      <PollingRadioValue strong>{itemPost.poll.question}</PollingRadioValue>
      {itemPost.poll.options.map(val => {
        return (
          <PollingRadio
            key={val.id}
            onClick={() =>
              sharedPost ||
              daysRemaining(itemPost.poll.ends_at) < 0 ||
              (daysRemaining(itemPost.poll.ends_at) >= 0 &&
                val.id === isVoted.id)
                ? null
                : handleVote(itemPost.id, val.id)
            }
            value={getPercent(val.voteCount, itemPost.poll.totalVoteCount)}
            disabled={
              sharedPost ||
              daysRemaining(itemPost.poll.ends_at) < 0 ||
              (daysRemaining(itemPost.poll.ends_at) >= 0 &&
                val.id === isVoted.id)
            }
          >
            <PollingRadioValue strong>
              {val.option}
              {val.isVotedByMe && (
                <img
                  src={Assets.ICON.CHECK_CIRCLE}
                  alt=""
                  style={style.check_icon}
                />
              )}
            </PollingRadioValue>
            <PollingRadioValue>
              {Math.round(
                getPercent(val.voteCount, itemPost.poll.totalVoteCount)
              )}
              %
            </PollingRadioValue>
          </PollingRadio>
        );
      })}
      <VoteDetailWrapper>
        <TextLink
          onClick={() => (isSharing ? null : setShowVote(true))}
          disabled={isSharing}
        >
          {itemPost.poll.totalVoteCount} Vote
        </TextLink>
        <div style={style.bull}>&bull;</div>
        <div style={style.textGrey}>
          {daysRemaining(itemPost.poll.ends_at) === 0
            ? 'Last day'
            : daysRemaining(itemPost.poll.ends_at) < 0
            ? 'Poll closed'
            : `${convertDate(daysRemaining(itemPost.poll.ends_at))} Days left`}
        </div>
        {itemPost.poll.options.find(item => item.isVotedByMe) &&
          daysRemaining(itemPost.poll.ends_at) >= 0 &&
          !sharedPost && (
            <>
              <div style={style.bull}>&bull;</div>
              <span
                style={{ ...style.textBlue, ...style.pointer }}
                onClick={() => handleUndoVote(itemPost.id)}
              >
                Undo
              </span>
            </>
          )}
      </VoteDetailWrapper>
      <ModalShowVote
        userId={profileId}
        itemPost={itemPost}
        open={showVote}
        handleClose={handleShowVote}
        sharedPost={sharedPost}
      />
    </div>
  );
};

export default PostPollComponent;
