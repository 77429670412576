import React from 'react';
import { Radio } from 'antd';

const LanguageSettingsComponent = props => {
  return (
    <div className="component_language_settings_wrapper">
      <Radio value={props.name}>
        <div className="component_language_settings_name">{props.name}</div>
      </Radio>
    </div>
  );
};

export default LanguageSettingsComponent;
