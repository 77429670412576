import { message } from 'antd';
import _, { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { UserProfile } from '../../Services';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import { getCookie } from '../../Utils/CommonUtils';
import { MEMBER_LIST } from '../MemberCardBoxClassroom/graphql';
import { ADD_MEMBER } from './inviteMember.graphql';
import InviteMemberClassroomComponent from './InviteMemberClassroom.component';

const InviteMemberClassroomContainer = () => {
  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const token = _.isEmpty(userData) ? '' : userData.oauth.access_token;
  const params = useParams();
  const classroomId = params.id;

  const [loading, setLoading] = useState(false);
  const [users, setUser] = useState([]);
  const [value, setValue] = useState(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const [blockedMe] = useBlockMe();

  const CONTEXT_USER = {
    headers: { 'X-Hasura-Role': 'user', Authorization: `Bearer ${token}` }
  };

  const {
    data: listMember,
    refetch: refetchMember,
    loading: loadingMember
  } = useQuery(MEMBER_LIST, {
    variables: {
      classroom_id: {
        _eq: classroomId
      }
    },
    context: CONTEXT_USER
  });

  const [addMember] = useMutation(ADD_MEMBER, { context: CONTEXT_USER });

  const changeHandler = value => {
    setValue(value.key);
  };

  const searchHandler = keyword => {
    let searchParam = isEmpty(keyword) ? '' : keyword.trim();
    setUser([]);
    if (searchParam) {
      setIsFetching(true);
      UserProfile.getUserSearch(searchParam).then(res => {
        let filteredUser = res.data.filter(
          item => !blockedMe?.includes(item.id)
        );
        setUser(filteredUser);
        setIsFetching(false);
      });
    }
  };

  const inviteHandler = () => {
    setLoading(true);
    setTimeout(function() {
      let isMember = listMember.classroom_members.some(
        member => member.global_user.email === value
      );

      if (isMember) {
        setLoading(false);
        return message.error(`This account already set as a member.`);
      }

      addMember({ variables: { email: value, classroomId } })
        .then(() => {
          refetchMember();
          setLoading(false);
          message.success('Invite Successful');
          setTimeout(() => window.location.reload(), 1000);
        })
        .catch(err => {
          setLoading(false);
          message.error(err.message.replace('GraphQL error:', ''));
        });
    }, 1500);
  };

  if (loadingMember) {
    return null;
  }

  return (
    <div>
      <InviteMemberClassroomComponent
        users={users}
        inviteHandler={inviteHandler}
        loading={loading}
        changeHandler={changeHandler}
        searchHandler={searchHandler}
        value={value}
        isFetching={isFetching}
      />
    </div>
  );
};

export default InviteMemberClassroomContainer;
