import { gql } from 'apollo-boost';

export const MEMBER_LIST = gql`
  query getMemberSocialLearning(
    $classroom_id: uuid_comparison_exp!
    $limit: Int
    $offset: Int
  ) {
    classroom_members_aggregate(where: { classroom_id: $classroom_id }) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: { classroom_id: $classroom_id }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      is_admin
      is_mentor
      is_individual_mentor
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            position
            title
          }
        }
      }
      created_at
    }
  }
`;

export const MEMBER_LIST_ADMIN = gql`
  query getMemberSocialLearningAdmin(
    $classroom_id: uuid_comparison_exp!
    $limit: Int
    $offset: Int
  ) {
    classroom_members_aggregate(
      where: { classroom_id: $classroom_id, is_admin: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: { classroom_id: $classroom_id, is_admin: { _eq: true } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            position
            title
          }
        }
      }
      created_at
    }
  }
`;

export const MEMBER_LIST_CONTRIBUTOR = gql`
  query getMemberSocialLearningContributor(
    $classroom_id: uuid_comparison_exp!
    $limit: Int
    $offset: Int
  ) {
    classroom_members_aggregate(
      where: { classroom_id: $classroom_id, is_mentor: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: { classroom_id: $classroom_id, is_mentor: { _eq: true } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            title
          }
        }
      }
      created_at
    }
  }
`;

export const MEMBER_LIST_PARTICIPANT = gql`
  query getMemberSocialLearningParticipant(
    $classroom_id: uuid_comparison_exp!
    $limit: Int
    $offset: Int
  ) {
    classroom_members_aggregate(
      where: {
        classroom_id: $classroom_id
        is_admin: { _eq: false }
        is_mentor: { _eq: false }
      }
    ) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: {
        classroom_id: $classroom_id
        is_admin: { _eq: false }
        is_mentor: { _eq: false }
      }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            title
          }
        }
      }
      created_at
    }
  }
`;
