// use this if format is  "{bold namePeople} - fixed word - {bold nameData}"
export const RenderFixedWord = (_subType, message) => {
  const newWordingChecker = (newWording, fallbackText, fallbackText2) => {
    if (message.includes(newWording)) {
      return newWording;
    } else {
      if (message.includes(fallbackText)) {
        return fallbackText;
      } else return fallbackText2;
    }
  };

  switch (_subType) {
    case 'overtime-request':
      return 'just request overtime for';
    case 'attendance-checkout':
      return 'has checked out';
    case 'attendance-checkin':
      return 'has checked in';
    case 'leave-request':
      return newWordingChecker(
        'just request leave and permission for',
        'just request leave and permission request for',
        'just request leave for'
      );
    case 'leave-approval-spv':
    case 'leave-approval-pos':
      return 'approved your leave and permission request for';
    case 'leave-rejected':
      return 'rejected your leave and permission request for';
    case 'leave-balance-edit':
      return 'has edited the leave and permission balance of';
    case 'overtime-approval-spv':
    case 'overtime-approval-pos':
      return 'approved your overtime request for';
    case 'leave-cancelled':
    case 'leave-cancelled-restore':
      return newWordingChecker(
        'cancelled leave and permission request for',
        'cancelled leave request for'
      );
    case 'overtime-rejected':
      return 'rejected your overtime request for';
    case 'overtime-cancelled':
      return 'cancelled overtime request for';
    default:
      return '';
  }
};
