import { Icon } from 'antd';
import React, { memo } from 'react';
import Assets from '../../../../Assets';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';
import { WrapperProject } from './Project.style';

export default memo(function ProjectItem({
  onClickEdit,
  isUser,
  avatar,
  title,
  company,
  date,
  location,
  description,
  data,
  dividerWidth = null,
  contentPadding = null,
  contentWidth = null,
  noDivider = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <WrapperProject
      dividerWidth={dividerWidth}
      contentWidth={contentWidth}
      contentPadding={contentPadding}
    >
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="title">{title}</div>
          <div className="company">{company}</div>
          <div className="location">{location}</div>
          <div className="date">{date}</div>
          <div className="description">
            {description.slice(0, (!isMore && 370) || description.length)}
          </div>
          {isMore && (
            <>
              <div className="description" style={{ paddingTop: '10px' }}>
                {`Associate with ${data.associate_user} ${data.dataEdit
                  ?.position ?? ''}`}
              </div>
              <div style={{ paddingTop: '10px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'start',
                    paddingTop: '10px'
                  }}
                >
                  <div className="location">Project URL :</div>
                  <div className="company" style={{ paddingLeft: '5px' }}>
                    {' '}
                    {data.project_url !== '' ? data.project_url : '-'}
                  </div>
                </div>
              </div>
            </>
          )}

          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      {!noDivider && <div className="divider" />}
    </WrapperProject>
  );
});
