import React, { useState } from 'react';
import { ButtonWlb, TopSettingGroup, Pagination } from '..';
import { VendorShowcaseWrap, ShowcaseModalWrap } from './VendorShowcase.style';
import { Form, Input, message, Modal, Button } from 'antd';
import Table from 'antd/es/table';
import { displayLoading } from '../../Utils/CommonUtils';
import DeleteConfirmationModalContainer from '../DeleteConfirmationModal';
import EditShowcase from './editShowcase';
import './ModalShowcase.css';

const VendorShowcaseComponent = props => {
  const {
    columns,
    loading,
    dataSource,
    modalTitle,
    selectedRecordId,
    refetchShowcaseData,
    addModalVisibility,
    editModalVisibility,
    addVendorShowcaseMutation,
    handleAddModalVisibility,
    updateVendorShowcaseMutation,
    deleteShowcaseMutation,
    deleteModalVisibility,
    hideModal,
    offset,
    limit,
    totalData,
    prevShowcase,
    nextShowcase,
    handleLimit
    // hasMoreShowcase
  } = props;
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');
  const { TextArea } = Input;

  const _buttonAddShowcase = [
    <ButtonWlb key="1" type="primary" onClick={handleAddModalVisibility}>
      Add Showcase
    </ButtonWlb>
  ];

  const _resetInput = () => {
    setDescription('');
    setTitle('');
    props.form.resetFields();
  };

  const _handleAddShowcaseItem = async newShowcase => {
    let error = undefined;
    let successMessage = `Successfully add new showcase item`;
    let errorMessage = `Failed add new showcase item`;

    error = (
      await addVendorShowcaseMutation({
        variables: newShowcase
      })
    ).error;

    return { error, successMessage, errorMessage };
  };

  const _handlePositiveActionShowcaseModal = async e => {
    // if (isEmpty(title) || isEmpty(description)) {
    //   message.error('All item are required');
    //   return;
    // }
    e.preventDefault();

    props.form.validateFields(async (err, values) => {
      if (!err) {
        const newShowcase = {
          data: {
            name: title,
            description: description
          }
        };

        const {
          error,
          successMessage,
          errorMessage
        } = await _handleAddShowcaseItem(newShowcase);

        if (error) {
          message.error(errorMessage);
          return;
        }

        hideModal();
        message.success(successMessage);
        await refetchShowcaseData();
        _resetInput();
      }
    });
  };

  const _handleCancelButton = () => {
    hideModal();
    _resetInput();
  };

  // const _buttonModal = [
  //   <ButtonWlb
  //     style={style.btnModal}
  //     key="1"
  //     type="secondary"
  //     onClick={_handleCancelButton}
  //   >
  //     Cancel
  //   </ButtonWlb>,
  //   <ButtonWlb
  //     style={style.btnModal}
  //     key="2"
  //     type="primary"
  //     onClick={_handlePositiveActionShowcaseModal}
  //   >
  //     Save
  //   </ButtonWlb>
  // ];

  const _Top = () => (
    <TopSettingGroup title="Showcase List" buttonTop={_buttonAddShowcase} />
  );

  const _Modal = () => {
    let showcaseNamePlaceholder = 'showcase name';
    let showcaseDescriptionPlaceholder = 'showcase description';
    const { getFieldDecorator } = props.form;

    return (
      <Modal
        visible={addModalVisibility}
        onCancel={hideModal}
        footer={null}
        title={modalTitle}
        className="showcase-modal"
      >
        <ShowcaseModalWrap>
          <Form layout="vertical" onSubmit={null}>
            <Form.Item label={'Name'}>
              {getFieldDecorator('title', {
                rules: [
                  { required: true, message: 'Please input Showcase Name' }
                ],
                initialValue: title
              })(
                <Input
                  placeholder={showcaseNamePlaceholder}
                  onChange={event => setTitle(event.target.value)}
                />
              )}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [
                  { required: true, message: 'Please input Description' }
                ],
                initialValue: description
              })(
                <TextArea
                  rows={4}
                  placeholder={showcaseDescriptionPlaceholder}
                  onChange={event => setDescription(event.target.value)}
                />
              )}
            </Form.Item>
            <div className="component_button_wrapper">
              <Button onClick={_handleCancelButton}>Cancel</Button>
              <Button
                type="primary"
                onClick={_handlePositiveActionShowcaseModal}
              >
                Save
              </Button>
            </div>
          </Form>
        </ShowcaseModalWrap>
      </Modal>
    );
  };

  const _EditModal = () =>
    editModalVisibility && (
      <EditShowcase
        {...props}
        record={dataSource.find(item => {
          return item.key === selectedRecordId;
        })}
        updateVendorShowcaseMutation={updateVendorShowcaseMutation}
      />
    );
  const _showConfirmationDeleteModal = () => {
    const deletedRecord = {
      deleted_at: 'now()',
      id: selectedRecordId
    };
    return (
      <DeleteConfirmationModalContainer
        handleDeleteModalVisibility={hideModal}
        deleteModalVisibility={deleteModalVisibility}
        deleteRecordMutation={deleteShowcaseMutation}
        refetchContentQuery={refetchShowcaseData}
        recordToDelete={deletedRecord}
        validatorFields={[
          'update_marketplace_vendor_showcase',
          'affected_rows'
        ]}
        modalTitle={'Delete showcase'}
        modalContent={
          'Are you sure to' +
          ' delete this record?, This will' +
          ' delete the record permanently'
        }
      />
    );
  };

  return (
    <VendorShowcaseWrap>
      {_Top()}
      {loading ? (
        displayLoading(true)
      ) : (
        <Table pagination={false} columns={columns} dataSource={dataSource} />
      )}
      {_Modal()}
      {_EditModal()}
      {_showConfirmationDeleteModal()}
      <div className="pagination">
        <Pagination
          limit={limit}
          offset={offset}
          total={totalData}
          // isBeginning={offset <= 0}
          // isEnd={!hasMoreShowcase}
          prevClick={() => prevShowcase()}
          nextClick={() => nextShowcase()}
          onChangeLimit={e => handleLimit(e.target.value)}
        />
      </div>
    </VendorShowcaseWrap>
  );
};

export default Form.create({ name: 'VendorShowcaseForm' })(
  VendorShowcaseComponent
);
