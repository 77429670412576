import PropTypes from 'prop-types';

const propTypes = {
  displayModal: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalContent: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  handleDelete: PropTypes.func.isRequired
};

const defaultProps = {
  displayModal: false,
  modalTitle: 'Delete',
  modalContent: 'delete item',
  wording: 'Delete',
  closeModal: null,
  handleDelete: null
};

export default {
  propTypes,
  defaultProps
};
