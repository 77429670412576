import React, { useState } from 'react';
import { ModalEdit } from '../..';
import { Form, Input, message, Switch } from 'antd';
import { TitleField } from '../../ActionMorePeopleProfile/ActionMore.style';
import {
  GET_ADDED_EMAIL,
  INSERT_EMAIL,
  UPDATE_EMAIL,
  SET_UNMAIN_ALL_EMAIL_BY_USER
} from './EmailAddress.graphql';
import { useApolloClient, useMutation } from 'react-apollo';
import { AuthenticationService } from '../../../Services';
import { ButtonFrom } from '../../../SharedComponents';

const AddEditEmailComponent = ({
  open,
  handleClose,
  openAction,
  form,
  setOpenAction
}) => {
  const { actionType, data } = openAction;
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  const [value, setValue] = useState(null);
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    setFields
  } = form;

  const [insertEmail] = useMutation(INSERT_EMAIL, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [updateEmail] = useMutation(UPDATE_EMAIL, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setUnnmainUserEmail] = useMutation(SET_UNMAIN_ALL_EMAIL_BY_USER, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleCloseAct = () => {
    resetFields();
    setValue(null);
    handleClose();
    setFields({
      email: {
        errors: false
      }
    });
  };

  const mutatuinAddEmail = (email, isMain) => {
    insertEmail({
      variables: {
        isMain,
        email
      }
    })
      .then(() => {
        message.success(`Tambah Email Berhasil`);
        handleCloseAct();
        setOpenAction({
          ...openAction,
          modalVerification: true,
          modalDelete: false,
          addEdit: false
        });
      })
      .catch(err => {
        let error = JSON.parse(JSON.stringify(err));
        if (
          error.message ===
          'GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "people_emails_pk"'
        ) {
          setFields({
            email: {
              errors: [
                new Error(
                  'Alamat email ini sudah terdaftar. Silakan tambahkan alamat email baru'
                )
              ]
            }
          });
        } else {
          message.error(`Tambah Email Gagal, ${err}`);
        }
      });
  };
  const mutatuinEditEmail = (email, isMain) => {
    updateEmail({
      variables: {
        userId: AuthenticationService.getUserId(),
        oldEmail: data.email,
        newEmail: email,
        isMain
      }
    })
      .then(() => {
        message.success(`Ubah Email Berhasil`);
        handleCloseAct();
        setOpenAction({
          ...openAction,
          modalVerification: true,
          modalDelete: false,
          addEdit: false
        });
      })
      .catch(err => {
        message.error(`Ubah Email Gagal, ${err}`);
      });
  };

  const mutation = async (email, isMain) => {
    const { data: emailAdded } = await client.query({
      query: GET_ADDED_EMAIL,
      variables: {
        email
      },
      context: { headers: { 'X-Hasura-Role': 'user' } }
    });

    if (
      emailAdded?.checker_global_user.length > 0 ||
      emailAdded?.checker_people_email.length > 0
    ) {
      setFields({
        email: {
          errors: [
            new Error(
              'This email address already listed. Please add new email address'
            )
          ]
        }
      });
    } else {
      if (actionType === 'add') {
        if (isMain) {
          setUnnmainUserEmail({
            variables: {
              user: AuthenticationService.getUserId()
            }
          })
            .then(() => {
              mutatuinAddEmail(email, false);
            })
            .catch(err => {
              message.error(`Set main email error, ${err}`);
            });
        } else {
          mutatuinAddEmail(email, isMain);
        }
      } else {
        if (isMain) {
          setUnnmainUserEmail({
            variables: {
              user: AuthenticationService.getUserId()
            }
          })
            .then(() => {
              mutatuinEditEmail(email, isMain);
            })
            .catch(err => {
              message.error(`Set main email error, ${err}`);
            });
        } else {
          mutatuinEditEmail(email, isMain);
        }
      }
    }
    setLoading(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        setLoading(true);
        mutation(values.email.toLowerCase(), false);
        // handleCloseAct();
      }
    });
  };

  const handleChange = (formValue, target) => {
    if (target === 'email') {
      setValue(formValue.target.value);
      setFieldsValue({
        email: value
      });
    }
  };

  const handleDelete = () => {
    setOpenAction({
      ...openAction,
      addEdit: false,
      modalDelete: true
    });
  };

  return (
    <ModalEdit
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambah'} Email`}
      open={open}
      onClose={handleCloseAct}
      sizeSmall
      onClickDelete={false}
      // onClickDelete={actionType === 'edit' ? handleDelete : null}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item
          label={<TitleField>Email</TitleField>}
          style={{ padding: '0px 22px', marginBottom: 0 }}
          required
        >
          {getFieldDecorator('email', {
            initialValue: actionType === 'edit' ? data.email : value,
            rules: [
              {
                type: 'email',
                required: true,
                message:
                  value === null || value === ''
                    ? 'Bagian ini diperlukan'
                    : 'Email tidak valid'
              }
            ]
          })(
            <Input
              placeholder="Tambah Email"
              onChange={value => handleChange(value, 'email')}
              style={{ width: '100%' }}
            ></Input>
          )}
        </Form.Item>
        {/* <Form.Item
          style={{
            padding: "0px 22px 12px 22px",
            marginTop: 14,
            display: "flex",
          }}
          label={<TitleField>Set as main Email Address:</TitleField>}
        >
          {getFieldDecorator("isMain", {
            initialValue: data?.is_main ?? false,
            valuePropName: "checked",
          })(<Switch size="small" />)}
        </Form.Item> */}
        <ButtonFrom
          submitLabel="Simpan"
          loading={loading}
          actionType={actionType}
          onCancel={handleCloseAct}
        />
      </Form>
    </ModalEdit>
  );
};

const AddEditEmail = Form.create({ name: 'AddEdit_Email' })(
  AddEditEmailComponent
);

export default AddEditEmail;
