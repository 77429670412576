import React from 'react';
import { ModalEdit, ButtonFullWlb } from '../../../../Components';
import LicencesItem from './LicencesItem';
import { WrapperScroll } from './Licences.style';

export default function MoreLicences({
  nameUser,
  setOpen,
  open,
  isUser,
  data
}) {
  return (
    <ModalEdit
      centered
      padding="0px"
      title={`Pelatihan, Lisensi & Sertifikasi ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false, fromList: false }))}
    >
      <WrapperScroll>
        {data &&
          data.length > 0 &&
          data.map((res, i) => (
            <LicencesItem
              key={i}
              isUser={isUser}
              avatar={res.avatar}
              company={res.company}
              title={res.title}
              date={res.time}
              credential={res.location}
              url={res.url}
              data={res}
              onClickEdit={() =>
                setOpen(e => ({
                  ...e,
                  open: true,
                  openMore: false,
                  data: res.dataEdit,
                  actionType: 'edit',
                  fromList: true
                }))
              }
            />
          ))}
      </WrapperScroll>
      {isUser && (
        <ButtonFullWlb
          label="Add Pelatihan, Lisensi & Sertifikasi"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
}
