import Constants from '../../Config/Constants';

const buttonStyle = {
  width: 86,
  height: 25,
  fontSize: 12
};

export default {
  container: {
    height: 50
  },
  hr: {
    height: 2,
    backgroundColor: Constants.COLORS.LIGHT_BLUE,
    border: 'none',
    marginBottom: 10
  },
  iconImage: {
    width: 16,
    height: 16,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconVideo: {
    width: 17,
    height: 17,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconPerson: {
    width: 11,
    height: 11,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconAttachment: {
    width: 6.3,
    height: 12,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconLocation: {
    width: 13,
    height: 13,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconHashtag: {
    width: 9,
    height: 11,
    marginRight: 16,
    cursor: 'pointer'
  },
  iconLanguage: {
    width: 10,
    height: 10,
    marginRight: 8
  },
  rightColumn: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  privilegeButton: {
    ...buttonStyle,
    paddingLeft: 7,
    color: Constants.COLORS.GREY
  },
  postButton: {
    ...buttonStyle,
    marginLeft: 16,
    color: Constants.COLORS.WHITE,
    backgroundColor: Constants.COLORS.DARK_BLUE,
    height: 30
  },
  postDisableButton: {
    ...buttonStyle,
    marginLeft: 16,
    color: Constants.COLORS.WHITE,
    backgroundColor: Constants.COLORS.GREY,
    height: 30
  }
};
