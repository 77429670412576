import React from 'react';
import { Icon } from 'antd';
import { AddButton } from './AnotherContact.style';

export default function ButtonAdd({ onClick }) {
  return (
    <AddButton onClick={onClick}>
      <Icon type="plus" style={{ color: '#039be5', marginRight: 12 }} />
      Tambah Kontak Sosial
    </AddButton>
  );
}
