import styled from 'styled-components';
import Constants from '../../../Config/Constants';
import Assets from '../../../Assets';

export const ProfileInformationWrapper = styled.div`
  padding: 15px;

  .ant-collapse {
    border-radius: 0px;
    border: none;
  }

  .ant-collapse.ant-collapse-icon-position-right.component_collapse {
    background-color: #ffffff;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;

    .ant-collapse-header {
      color: ${Constants.COLORS.DARK_BLUE};
    }
  }

  p {
    font-family: 'Muli-SemiBold';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: ${Constants.COLORS.GREY};
  }

  .ant-select-selection-selected-value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin-left: 5px;
    }
  }

  .component_profile_information_panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    section {
      width: 60%;
    }

    h4 {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.46;
      letter-spacing: normal;
      text-align: left;
      color: ${Constants.COLORS.BLACK};
    }

    .widget-toggle {
      display: flex;
      width: 100px;
      justify-content: space-between;

      .widget-status {
        font-size: 13px;
        color: ${Assets.COLOR_PALLETE.black2};
        display: flex;
        align-items: center;
      }
    }

    .ant-switch.ant-switch-checked {
      background-color: #014a62;
      height: 21px;
    }
  }
`;
