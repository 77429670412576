import React from 'react';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import { formatDate } from '../HelperDetailScreen';
import DisciplinaryItem from './DisciplinaryItem';

export default function DisciplinaryList({ setOpen, isUser, data, nameUser }) {
  const handleEdit = data => {
    setOpen(e => ({
      ...e,
      open: true,
      data,
      actionType: 'edit'
    }));
  };
  return (
    <>
      {data?.slice(0, 2)?.map((res, i, count) => {
        return (
          <DisciplinaryItem
            key={res.id}
            noBorder={count.length === i + 1 || false}
            discipline_name={res.discipline_name || '-'}
            reference_number={res.reference_number || '-'}
            start_date={formatDate(res.start_date) || '-'}
            end_date={formatDate(res.end_date) || '-'}
            name_user={nameUser || '-'}
            discipline_letter_number={res.discipline_letter_number || '-'}
            reference_date={formatDate(res.reference_date) || '-'}
            remark={res.remark || '-'}
            attachments={isUser ? res.attachments || [] : []}
            onClickEdit={isUser ? () => handleEdit(res) : null}
          />
        );
      })}
      {data?.length > 2 && (
        <ThypoStyled
          color={'#007fb2'}
          fontSize="16px"
          fontWeight="600"
          cursor="pointer"
          margin="0px 0px 23px 0px"
          onClick={() => setOpen(e => ({ ...e, openMore: true }))}
        >
          Tampilkan Lebih Banyak Kedisiplinan...
        </ThypoStyled>
      )}
    </>
  );
}
