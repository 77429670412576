import styled from 'styled-components';
import Assets from '../../../Assets';

export const WelcomeWrap = styled.div`
  text-align: center;
  font-family: ${Assets.FONT.MULI};
  margin-top: 40px;
  position: relative;
  
  p{
    margin-top: 90px;
    margin-bottom: 200px;
  }
`;