import styled from 'styled-components';
import Assets from '../../Assets';

export const GroupCardWrap = styled.div`
  position: relative;
  min-width: 265px;
  height: 260px;
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
  padding: 18px;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .footer {
    display: flex;
  }
`;

export const Top = styled.div`
  position: relative;
  display: flex;
  padding-bottom: 25px;
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey2};
  .logo {
    width: 65px;
    height: 65px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  .info {
    font-family: ${Assets.FONT.MULI};
    font-size: 12px;
    color: ${Assets.COLOR_PALLETE.grey};
    padding: 14px 11px;
    span {
      width: 100%;
      display: block;
    }
    span:first-child {
      color: ${Assets.COLOR_PALLETE.black2};
    }
  }
`;

export const Desc = styled.div`
  position: relative;
  padding: 16px 0px;
  overflow: hidden;
  height: 120px;
  p {
    font-size: 12px;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export const UserJoined = styled.div`
  position: relative;
  display: flex;
  margin-right: 10px;
  img {
    width: 33px;
    height: 33px;
    border-radius: 100%;
  }
`;

export const MoreUser = styled.div`
  position: relative;
  display: flex;
  width: 33px;
  height: 33px;
  background: ${Assets.COLOR_PALLETE.grey};
  border-radius: 100%;
  font-family: ${Assets.FONT.MULI};
  color: ${Assets.COLOR_PALLETE.white};
  padding: 5px 8px;
`;
