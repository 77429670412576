import styled from 'styled-components';
import Constants from '../../Config/Constants';

export const ErrorPageWrapper = styled.div`
  background-color: ${Constants.COLORS.DARK_GREY};
  .error {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .column-2 {
    margin-left: 80px;
    width: 440px;
  }
  .description {
    color: ${Constants.COLORS.GREY};
  }
  .link {
    color: ${Constants.COLORS.LIGHT_BLUE};
  }
`;
