import PropTypes from 'prop-types';

const propTypes = {
  isShowSpecificFollower: PropTypes.bool.isRequired,
  canSee: PropTypes.array.isRequired,
  handlePost: PropTypes.func.isRequired,
  setIsShowModal: PropTypes.func.isRequired
};

const defaultProps = {

};

export default {
  propTypes,
  defaultProps
}
