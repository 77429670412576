import React, { useState, useEffect } from 'react';
import { ModalEdit } from '../../Components';
import { Select, Input, Form, DatePicker, message, Switch, Radio } from 'antd';
import { TitleField } from './UserDependent.style';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import {
  GET_LIST_RELATIONSHIP,
  UPDATE_DEPENDENT,
  ADD_DEPENDENT
} from './UserProfileDependant.graphql';
import {
  ButtonFrom,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PHONE_CODE_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  COUNTRY_INDONESIA,
  SearchableSelect,
  globalCitiesSeachableVars,
  countryIndonesiaVar
} from '../../SharedComponents';
import {
  month,
  listBlood,
  listMaritalStatus,
  listGender
} from '../UserProfileAbout/HelperAbout';
import dateTime from '../../Utils/dateTime';

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const generateSubmitDate = (day, month, year) => {
  if (day.length === 1) {
    return `${year}-${month}-0${day}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

const getAgeDependent = ({ day, month, year }) => {
  if (day && month && year) {
    return (
      dateTime.differenceDatefromNow(
        generateSubmitDate(day, month, year),
        'years'
      ) || 0
    );
  } else return undefined;
};

const EditIdentityCard = props => {
  const { open, onClose, data, refetch, actionType, setOpen } = props;
  const {
    getFieldDecorator,
    validateFields,
    resetFields,
    getFieldValue,
    setFieldsValue,
    getFieldsValue
  } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [check, setCheck] = useState(false);
  const valuesData = getFieldsValue();

  const [
    getListGlobalPhoneCode,
    { data: dataPhone, loading: loadingPhone }
  ] = useLazyQuery(GLOBAL_PHONE_CODE_SEARCHABLE);
  const [getListCity, { data: dataCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );
  const [
    getListProvince,
    { data: dataProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);
  const [
    getListCountry,
    { data: dataCountry, loading: loadingCountry }
  ] = useLazyQuery(COUNTRY_INDONESIA);

  useEffect(() => {
    if (data) {
      setCheck(data?.is_beneficiary ?? false);
    }
    if (open) {
      if (actionType === 'edit') {
        const { phone_code } = data?.dependant_fields || {};
        getListCity({
          variables: {
            ...globalCitiesSeachableVars({
              province: data?.global_province?.id
            })
          }
        });
        getListCountry({
          variables: { ...countryIndonesiaVar() }
        });

        getListGlobalPhoneCode({
          variables: {
            where: {
              code: phone_code ? { _eq: phone_code } : undefined
            },
            limit: 20
          }
        });
        getListProvince({
          variables: {
            where: {
              deletedAt: { _is_null: true }
            }
          }
        });
        setFieldsValue({
          province: data?.global_province?.id || undefined,
          city: data?.global_city?.id || undefined,
          location: data?.dependant_fields?.phone_code || undefined,
          country: data?.global_country?.id || undefined
        });
      } else {
        setFieldsValue({
          province: undefined,
          city: undefined,
          location: undefined,
          country: undefined
        });
      }
    }
  }, [
    data,
    open,
    getListGlobalPhoneCode,
    getListCity,
    getListProvince,
    actionType,
    setFieldsValue,
    getListCountry
  ]);

  const [updateDependent] = useMutation(UPDATE_DEPENDENT, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [addDependent] = useMutation(ADD_DEPENDENT, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const { data: dataRelationship } = useQuery(GET_LIST_RELATIONSHIP, {
    wlb_skipPatch: true,
    context: {
      headers: {
        'X-Hasura-Role': 'public'
      }
    }
  });

  const listFields = [
    {
      labelName: 'Nama',
      fieldName: 'name',
      type: 'text',
      fieldValue: data?.name ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan nama'
    },
    {
      labelName: 'Jenis Kelamin',
      fieldName: 'gender',
      type: 'select',
      fieldValue: data?.gender ?? undefined,
      isRequired: true,
      placeholder: 'Pilih jenis kelamin',
      option: listGender
    },
    {
      labelName: 'Hubungan',
      fieldName: 'relationship',
      type: 'select',
      fieldValue: data?.relationship ?? undefined,
      isRequired: true,
      option: dataRelationship?.people_dependant_relationships ?? [],
      placeholder: 'Pilih hubungan'
    }
  ];

  const birthplace = [
    {
      labelName: 'Tempat Lahir',
      fieldName: 'birthplace',
      type: 'text',
      fieldValue: data?.birthplace ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan tempat lahir'
    }
  ];
  const listFields2 = [
    {
      labelName: 'Status Tanggungan',
      fieldName: 'dependent_status',
      type: 'text',
      fieldValue: data?.dependent_status ?? undefined,
      isRequired: false,
      placeholder: 'Tambahkan status tanggungan'
    },
    {
      labelName: 'Usia Tanggungan',
      fieldName: 'child_dependent_age',
      type: 'text',
      fieldValue: getAgeDependent(valuesData),
      isRequired: false,
      disable: true,
      placeholder: 'Tambahkan usia'
    },
    {
      labelName: 'Nomor Identitas',
      fieldName: 'identity_number',
      type: 'text',
      fieldValue: data?.identity_number ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan nomor identitas'
    },
    {
      labelName: 'Status Perkawinan',
      fieldName: 'marital_status',
      type: 'select',
      fieldValue: data?.marital_status ?? undefined,
      isRequired: true,
      placeholder: 'Pilih status perkawinan',
      option: listMaritalStatus
    },
    {
      labelName: 'Status Pekerjaan',
      fieldName: 'working_status',
      type: 'select',
      fieldValue: data?.working_status ?? undefined,
      isRequired: true,
      placeholder: 'Pilih status pekerjaan',
      option: [
        //hardcode dari BE
        {
          id: 'employee',
          name: 'Karyawan'
        },
        {
          id: 'unemployed',
          name: 'Penganggur'
        }
      ]
    },
    {
      labelName: 'Aparatur Sipil Negara',
      fieldName: 'civil_servant',
      type: 'radio',
      fieldValue: data?.civil_servant ?? true,
      isRequired: true,
      option: [
        { name: 'Ya', value: true },
        { name: 'Tidak', value: false }
      ]
    },
    {
      labelName: 'Pegawai Swasta',
      fieldName: 'company_group_employee',
      type: 'radio',
      fieldValue: data?.is_company_group_employee ?? true,
      isRequired: true,
      option: [
        { name: 'Ya', value: true },
        { name: 'Tidak', value: false }
      ]
    },
    {
      labelName: 'Perusahaan',
      fieldName: 'company',
      type: 'text',
      fieldValue: data?.company ?? undefined,
      isRequired: false,
      placeholder: 'Tambahkan perusahaan'
    }
  ];

  const listFields3 = [
    {
      labelName: 'Golongan Darah',
      fieldName: 'bloodtype',
      type: 'select',
      fieldValue: data?.blood_type ?? undefined,
      isRequired: true,
      placeholder: 'Pilih golongan darah',
      option: listBlood
    },
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'searchable-select',
      fieldValue: getFieldValue('country'),
      isRequired: true,
      placeholder: 'Pilih negara',
      option:
        dataCountry?.global_country?.map(({ id, country_name }) => ({
          id,
          name: country_name
        })) || [],
      loading: loadingCountry,
      onSearch: ({ value }) => {
        getListCountry({
          variables: {
            limit: 20,
            ...countryIndonesiaVar({ search: value })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getListCountry({
            variables: {
              limit: 20,
              ...countryIndonesiaVar()
            }
          });
        }
      },
      onChange: country => setFieldsValue({ country })
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      isRequired: true,
      placeholder: 'Pilih provinsi',
      option: dataProvince?.global_provinces ?? [],
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getListProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getListProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          city: undefined,
          province: e
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      fieldValue: getFieldValue('city'),
      isRequired: true,
      placeholder: 'Pilih kota',
      option: dataCity?.global_cities ?? [],
      loading: loadingCity,
      onSearch: ({ value }) => {
        getListCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getListCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      }
    },
    {
      labelName: 'Kecamatan',
      fieldName: 'district',
      type: 'text',
      fieldValue: data?.district ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan kecamatan'
    },
    {
      labelName: 'Kelurahan',
      fieldName: 'sub_district',
      type: 'text',
      fieldValue: data?.sub_district ?? undefined,
      isRequired: false,
      placeholder: 'Tambahkan kelurahan'
    },
    {
      labelName: 'Kode Pos',
      fieldName: 'postal_code',
      type: 'number',
      fieldValue: data?.postal_code ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan kode pos'
    },
    {
      labelName: 'Alamat',
      fieldName: 'address',
      type: 'textArea',
      fieldValue: data?.address ?? undefined,
      isRequired: true,
      placeholder: 'Tambahkan alamat'
    }
  ];

  const handleClose = () => {
    onClose();
    resetFields();
    setCheck(false);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'edit') {
          const submit = {
            id: data.id,
            name: values.name,
            relationship: values.relationship,
            birthplace: values.birthplace,
            birthdate: generateSubmitDate(
              values.day,
              values.month,
              values.year
            ),
            identity_number: values.identity_number,
            marital_status: values.marital_status,
            working_status: values.working_status,
            gender: values.gender,
            is_beneficiary: values.beneficiary ?? check,
            dependent_status: values.dependent_status,
            civil_servant: values.civil_servant,
            is_company_group_employee: values.company_group_employee,
            company: values.company,
            dependant_fields: {
              phone: values.phone_number,
              phone_code: values.location
            },
            district: values.district,
            subDistrict: values.sub_district,
            blood_type: values.bloodtype,
            country: values.country,
            province: values.province,
            city: values.city,
            postal_code: values.postal_code,
            address: values.address
          };
          updateDependent({
            variables: submit
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil mengubah tanggungan');
            })
            .catch(err => {
              message.error(
                `Gagal mengubah tanggungan, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          const submit = {
            name: values.name,
            relationship: values.relationship,
            birthplace: values.birthplace,
            birthdate: generateSubmitDate(
              values.day,
              values.month,
              values.year
            ),
            identity_number: values.identity_number,
            marital_status: values.marital_status,
            working_status: values.working_status,
            gender: values.gender,
            is_beneficiary: values.beneficiary ?? check,
            dependent_status: values.dependent_status,
            civil_servant: values.civil_servant,
            is_company_group_employee: values.company_group_employee,
            company: values.company,
            dependant_fields: {
              phone: values.phone_number,
              phone_code: values.location
            },
            district: values.district,
            subDistrict: values.sub_district,
            blood_type: values.bloodtype,
            country: values.country,
            province: values.province,
            city: values.city,
            postal_code: values.postal_code,
            address: values.address
          };
          addDependent({
            variables: submit
          })
            .then(() => {
              refetch();
              message.success('Berhasil menambahkan tanggungan');
              handleClose();
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan tanggungan, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  const handleChange = (formValue, e) => {
    if (e === 'province') {
      getListCity({
        variables: {
          ...globalCitiesSeachableVars({ province: formValue })
        }
      });
      setFieldsValue({
        city: undefined
      });
    }
  };

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      disable = false,
      placeholder,
      fieldValue,
      loading,
      onSearch,
      onTouch,
      onChange
    } = res;

    if (type === 'select') {
      return (
        <Select
          style={{ width: '100%', textTransform: 'capitalize' }}
          size="large"
          disable={disable}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option
                  style={{ textTransform: 'capitalize' }}
                  key={`${i}-${fieldName}`}
                  value={fieldName === 'relationship' ? res.name : res.id}
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          disabled={disable}
          style={fieldName === 'working_status' ? { width: '50%' } : null}
          type={type}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    } else if (type === 'date') {
      return (
        <DatePicker
          format={'YYYY-MM-DD'}
          style={{ width: '50%' }}
          onChange={(value, e) => handleChange(value, e, fieldName)}
        />
      );
    } else if (type === 'radio') {
      return (
        <Radio.Group>
          {option.map((res, i) => (
            <Radio key={`${i}-radio`} value={res.value}>
              {res.name}
            </Radio>
          ))}
        </Radio.Group>
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={4}
          onChange={value => handleChange(value, fieldName)}
          placeholder={placeholder}
        />
      );
    }
  };

  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };

  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }

  const idValue = getFieldValue('identity_number');
  const rulesMax = {
    required: true,
    max: 25,
    message:
      idValue && idValue.length > 24
        ? 'Anda telah melampaui batas karakter maksimum'
        : 'Bagian ini diperlukan'
  };
  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Tanggungan`}
      open={open}
      onClose={handleClose}
      onClickDelete={
        actionType === 'edit'
          ? () => setOpen(e => ({ ...e, modalDelete: true, open: false }))
          : null
      }
    >
      <Form onSubmit={handleSubmit}>
        {listFields.map((res, i) => {
          return (
            <Form.Item
              key={`${i}-1`}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules:
                  res.fieldName === 'identity_number'
                    ? [rulesMax]
                    : rule(res.isRequired)
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <Form.Item
          style={{
            padding: '0px 22px 0px 22px',
            marginBottom: '-22px'
          }}
          label={<TitleField>Penerima</TitleField>}
        >
          <Form.Item
            style={{
              display: 'inline-block',
              marginRight: 21
            }}
          >
            {getFieldDecorator(
              'beneficiary',
              {}
            )(
              <Switch
                size="small"
                checked={check}
                onClick={() => setCheck(!check)}
              />
            )}
          </Form.Item>
          <span>Tanggungan ini akan menjadi penerima tunjangan</span>
        </Form.Item>
        {birthplace.map((res, i) => {
          return (
            <Form.Item
              key={`${i}-1`}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: rule(res.isRequired)
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Tanggal Lahir</TitleField>}
            style={{
              marginBottom: 0
            }}
            required
          >
            <Form.Item
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '33.33%'
              }}
            >
              {getFieldDecorator('day', {
                initialValue: generateDate(data && data.birthdate)?.[2],
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Select
                  placeholder="Tanggal"
                  style={{ width: '90%' }}
                  onChange={(value, e) => handleChange(value, 'day', e)}
                >
                  {optionDay.map(res => (
                    <Option key={res} value={res}>
                      {res}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '33.33%'
              }}
            >
              {getFieldDecorator('month', {
                initialValue: generateDate(data && data.birthdate)?.[1],
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Select
                  placeholder="Bulan"
                  style={{ width: '90%' }}
                  onChange={(value, e) => handleChange(value, 'month', e)}
                >
                  {month.map((res, i) => (
                    <Option key={i.toString()} value={res.value}>
                      {res.text}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              style={{
                display: 'inline-block',
                marginBottom: 0,
                width: '33.33%'
              }}
            >
              {getFieldDecorator('year', {
                initialValue: generateDate(data && data.birthdate)?.[0],
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Select
                  placeholder="Tahun"
                  style={{ width: '90%' }}
                  onChange={(value, e) => handleChange(value, 'year', e)}
                >
                  {optionYear.length > 0 &&
                    optionYear.map(res => (
                      <Option key={res} value={res}>
                        {res}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        {listFields2.map((res, i) => {
          return (
            <Form.Item
              key={`${i}-2`}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules:
                  res.fieldName === 'identity_number'
                    ? [rulesMax]
                    : rule(res.isRequired)
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Nomor Telepon</TitleField>}
            style={{ marginBottom: 0 }}
            required
          >
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '25%' }}
              required
              disabled
            >
              {getFieldDecorator('location', {
                initialValue: data?.dependant_fields?.phone_code,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <SearchableSelect
                  fieldValue={getFieldValue('location')}
                  placeholder={'Kode Negara'}
                  style={{ width: '90%' }}
                  loading={loadingPhone}
                  withEmptyIcon={false}
                  option={
                    dataPhone?.global_phone_code?.map(({ code, country }) => ({
                      id: code,
                      name: `(${code}) ${country}`
                    })) || []
                  }
                  onSearch={({ value }) => {
                    getListGlobalPhoneCode({
                      variables: {
                        limit: 20,
                        where: {
                          _or: value
                            ? [
                                { country: { _ilike: `%${value}%` } },
                                { code: { _ilike: `%${value}%` } }
                              ]
                            : undefined
                        }
                      }
                    });
                  }}
                  fieldName={'location'}
                  onTouch={({ isOpen }) => {
                    if (isOpen) {
                      getListGlobalPhoneCode({
                        variables: {
                          limit: 20
                        }
                      });
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '75%' }}
              required
            >
              {getFieldDecorator('phone_number', {
                initialValue: data?.dependant_fields?.phone,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Input
                  placeholder="Tambahkan nomor telepon"
                  type="number"
                  onChange={value => handleChange(value, 'phone_number')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        {listFields3.map((res, i) => {
          return (
            <Form.Item
              key={`${i}-3`}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules:
                  res.fieldName === 'identity_number'
                    ? [rulesMax]
                    : rule(res.isRequired)
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const EditUserDependentContainer = Form.create({ name: 'Edit_Dependent' })(
  EditIdentityCard
);

export default EditUserDependentContainer;
