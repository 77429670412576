import gql from 'graphql-tag';

export const GET_WORKING_STATUS = gql`
  query getWorkingStatus($id: uuid!) {
    working: people_work_placements(
      where: {
        _and: [
          { user: { _eq: $id } }
          { deletedAt: { _is_null: true } }
          { status: { _in: ["ACTIVE", "INACTIVE", "REGISTERED"] } }
        ]
      }
      order_by: [{ id: desc }]
      limit: 1
    ) {
      id
      company
      regno
      email
      type
      type_time
      type_place
      start_date
      decree: reference
      end_date
      join_date
      decree: reference
      attachment
      global_user {
        id
        date_added
      }
      company_logo: company_profile {
        id
        logo
      }
      company_job_profile {
        id
        title
        roles
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
        }
        supervisor: job_profile_supervisor {
          id
          title
          people_work_placements(
            where: {
              _and: [
                { global_user: { deletedAt: { _is_null: true } } }
                { status: { _eq: "ACTIVE" } }
                { deletedAt: { _is_null: true } }
              ]
            }
            limit: 1
            order_by: { global_user: { name: asc } }
          ) {
            global_user {
              id
              name
              avatar
            }
          }
        }
        subordinate: job_profile_children {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {
                  company_job_profile: {
                    job_profile_supervisor: {
                      people_work_placements: {
                        _and: [
                          { user: { _eq: $id } }
                          { status: { _eq: "ACTIVE" } }
                          { deletedAt: { _is_null: true } }
                        ]
                      }
                    }
                  }
                }
                { status: { _eq: "ACTIVE" } }
                { deletedAt: { _is_null: true } }
              ]
            }
          ) {
            global_user {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`;
