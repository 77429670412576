import styled from 'styled-components';
import Assets from '../../Assets';

export const Wrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 301px;

  & > div:first-child,
  section > div {
    border-radius: 4px;
    color: ${Assets.COLOR_PALLETE.white};
    position: relative;
  }

  & > div:first-child {
    display: inline-block;
    height: 100%;
    width: 55%;
    background-color: red;
    margin-right: 11.5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .content {
      position: absolute;
      bottom: 15px;
    }
  }

  .content {
    margin: 15px;
  }

  section {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 44%;

    & > div:first-child {
      margin-bottom: 10px;
    }

    & > div {
      position: relative;
      display: inline-block;
      background-color: red;
      height: 48%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .content {
        position: absolute;
        margin-top: 45px;
      }
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }

  .description,
  .date {
    font-size: 12px;
    font-weight: 400;
  }

  .backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
`;
