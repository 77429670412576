import { gql } from 'apollo-boost';

// const GET_BANNER_COMPANY = gql`
//   query getCompany($id: uuid!) {
//     main_profile: company_profiles(where: { id: { _eq: $id } }) {
//       id
//       legal_name
//       brand_name
//       logo
//       phone
//       profile_field
//       type
//     }
//   }
// `;

const GET_SOSMED_COMPANY = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      profile_field
    }
  }
`;

const GET_PHONE_COMPANY = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      id
      phone
      country_code
    }
  }
`;

const GET_FOLLOW_COMPANY = gql`
  query($id: String!) {
    getTotalFollowersCompany(company: $id) {
      total
      is_following
      is_followed
    }
  }
`;

export { GET_PHONE_COMPANY, GET_FOLLOW_COMPANY, GET_SOSMED_COMPANY };
