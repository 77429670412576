import React from 'react';
import { CompanyDetailBoxWrap } from './CompanyDetailBox.style';
import { TopSettingGroup } from '../../Components';
import { displayLoading } from '../../Utils/CommonUtils';

const CompanyDetailBoxComponent = props => {
  const { companyBox, loading, followerCompany } = props;
  const companyDetailBox = () => {
    return (
      <CompanyDetailBoxWrap>
        <TopSettingGroup title="Detail" color="black" />
        <div className="item-count">
          <div className="count">
            <span className="number">{companyBox.totalEmployee}</span>
            <span className="text">Pegawai</span>
          </div>
          <div className="count">
            <span className="number">{followerCompany}</span>
            <span className="text">Pengikut</span>
          </div>
        </div>
        <div className="sub-info">
          <p>Bidang</p>
          <p className="sub-info-value">{companyBox.industry ?? '-'}</p>
        </div>
        <div className="sub-info">
          <p>Tipe</p>
          <p className="sub-info-value">{companyBox.type ?? '-'}</p>
        </div>
        <div className="sub-info">
          <p>Didirikan</p>
          <p className="sub-info-value">{companyBox.founded ?? '-'}</p>
        </div>
        <div className="sub-info">
          <p>Kantor Pusat</p>
          <p>{companyBox.headquarter ?? '-'}</p>
        </div>
        <div className="sub-info">
          <p>Spesialisasi</p>
          <p className="sub-info-value">{companyBox.specialities ?? '-'}</p>
        </div>
      </CompanyDetailBoxWrap>
    );
  };
  return <div>{loading ? displayLoading(loading) : companyDetailBox()}</div>;
};

export default CompanyDetailBoxComponent;
