import gql from 'graphql-tag';

const GET_LIST_SPEND = gql`
  query getListSpend($id_vendor: uuid!, $offset: Int, $limit: Int) {
    total: global_transactions_aggregate(
      where: {
        transaction_item_table: { _neq: "system" }
        global_wallet_account: { foreign_owner_id: { _eq: $id_vendor } }
      }
    ) {
      aggregate {
        count
      }
    }
    global_transactions(
      where: {
        transaction_item_table: { _neq: "system" }
        global_wallet_account: { foreign_owner_id: { _eq: $id_vendor } }
      }
      offset: $offset
      limit: $limit
      order_by: [{ id: desc }]
    ) {
      id
      name
      value
      date_created
      transaction_item_table
      transaction_item_id
    }
  }
`;

const GET_LIST_INCOME = gql`
  query getListIncome($id_vendor: uuid!, $offset: Int, $limit: Int) {
    total: global_transactions_aggregate(
      where: {
        transaction_item_table: { _neq: "system" }
        globalWalletAccountByTo: { foreign_owner_id: { _eq: $id_vendor } }
      }
    ) {
      aggregate {
        count
      }
    }
    global_transactions(
      where: {
        transaction_item_table: { _neq: "system" }
        globalWalletAccountByTo: { foreign_owner_id: { _eq: $id_vendor } }
      }
      offset: $offset
      limit: $limit
      order_by: [{ id: desc }]
    ) {
      id
      name
      value
      date_created
      transaction_item_table
      transaction_item_id
    }
  }
`;

const GET_BALANCE = gql`
  query getBalance($id_vendor: uuid!) {
    spend: global_transactions_aggregate(
      where: {
        transaction_item_table: { _neq: "system" }
        global_wallet_account: { foreign_owner_id: { _eq: $id_vendor } }
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    income: global_transactions_aggregate(
      where: {
        transaction_item_table: { _neq: "system" }
        globalWalletAccountByTo: { foreign_owner_id: { _eq: $id_vendor } }
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
  }
`;

export { GET_LIST_SPEND, GET_LIST_INCOME, GET_BALANCE };
