import React from 'react';
import '../../Assets/css/style.css';
import { NavBar } from '../index';
import { Wrapper } from './Layout.style';

const LayoutComponent = props => {
  const { children } = props;
  return props.location.pathname !== '/page-under-development' ? (
    <Wrapper>
      <NavBar />
      {children}
    </Wrapper>
  ) : (
    children
  );
};

export default LayoutComponent;
