import React from 'react';
import { BannerProfile } from '../../Components';
import { Tabs } from 'antd';
import { BannerDetailUserWrap } from './BannerDetailUser.style';
import {
  UserDetailAbout,
  UserIdentityCard,
  UserEmergency,
  UserDependent,
  UserMedicalRecord,
  UserPsychologicalTest,
  UserMedicalHistory,
  UserEditIntro,
  UserBankAccount,
  UserInsuranceData
} from '../';

const BannerDetailUserComponent = props => {
  const {
    displayTabs,
    profileId,
    userId,
    setOpenIntro,
    openIntro,
    // setProvince,
    // dataCity,
    // dataProvince,
    // dataReligion,
    refetch
  } = props;
  const { TabPane } = Tabs;

  const Tab = () => (
    <BannerDetailUserWrap>
      <Tabs defaultActiveKey="1" onChange={null}>
        <TabPane tab="Tentang" key="1">
          <UserDetailAbout {...props} />
        </TabPane>
        {/* <TabPane tab="Kartu Identitas" key="2">
          <UserIdentityCard {...props} />
        </TabPane>
        <TabPane tab="Tanggungan" key="3">
          <UserDependent {...props} />
        </TabPane>
        <TabPane tab="Kontak Darurat" key="4">
          <UserEmergency {...props} />
        </TabPane>
        <TabPane tab="Rekening" key="5">
          <UserBankAccount {...props} />
        </TabPane>
        <TabPane tab="Data Asuransi" key="6">
          <UserInsuranceData {...props} />
        </TabPane>
        <TabPane tab="Profil Medis" key="7">
          <UserMedicalRecord {...props} />
        </TabPane>
        <TabPane tab="Rekam Medis" key="8">
          <UserMedicalHistory {...props} />
        </TabPane>
        <TabPane tab="Asesmen" key="9">
          <UserPsychologicalTest {...props} />
        </TabPane> */}
      </Tabs>
    </BannerDetailUserWrap>
  );

  return (
    <div>
      <BannerProfile {...props}>
        {displayTabs && profileId === userId && <Tab />}
      </BannerProfile>
      <UserEditIntro
        refetch={refetch}
        data={openIntro.data}
        open={openIntro.open}
        onClose={() => setOpenIntro(false)}
      />
    </div>
  );
};

export default BannerDetailUserComponent;
