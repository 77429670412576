import { Icon } from 'antd';
import React, { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_LIST_PHONECODE_BY_CODE } from './PhoneNumber.graphql';
import {
  WrapperPhone,
  WrapperContent,
  PhoneName,
  TextGrey,
  TextBlue,
  MainPhone
} from './PhoneNumber.style';

export default function ListPhone({
  onClickSetMain,
  onClickAction,
  is_main = false,
  phone,
  country,
  isUser,
  isVisible,
  onClickVisibility
}) {
  const [getPhone, { data }] = useLazyQuery(GET_LIST_PHONECODE_BY_CODE);

  useEffect(() => {
    if (country) {
      getPhone({
        variables: {
          id: country
        }
      });
    }
  }, [country, getPhone]);

  return (
    <WrapperPhone>
      <WrapperContent>
        <PhoneName>{phone}</PhoneName>
        <TextGrey>{data?.global_phone_code?.[0]?.country}</TextGrey>
        {is_main ? (
          <MainPhone>Nomor Telepon Utama</MainPhone>
        ) : isUser ? (
          <TextBlue onClick={onClickSetMain} margintop="10px">
            Tetapkan sebagai nomor telpon utama
          </TextBlue>
        ) : null}
      </WrapperContent>
      <div style={{ display: 'flex' }}>
        {isUser && (
          <Icon
            style={{
              marginRight: 21,
              color: isVisible ? '#039be5' : '#a9a8a8'
            }}
            onClick={onClickVisibility}
            type={`eye${isVisible ? '' : '-invisible'}`}
            theme="filled"
          />
        )}
        {isUser && (
          <Icon onClick={onClickAction} type={`edit`} theme="filled" />
        )}
      </div>
    </WrapperPhone>
  );
}
