import React from 'react';
import {
  Desc,
  // MoreUser,
  GroupCardWrap,
  Top
  // UserJoined
} from './TeamCard.style';
import { Link } from 'react-router-dom';
import AvatarGroup from './AvatarGroup';

const GroupCardComponent = props => {
  const { data } = props;

  const _top = () => {
    const teamLink = `/group/${data.id}/discussion`;
    return (
      <Link to={teamLink}>
        <Top>
          <div className="logo">
            {/* <img src={data.avatar} alt="avatar" /> */}
            <AvatarGroup avatar={data.avatar} />
          </div>
          <div className="info">
            <span>{data.name}</span>
            <span>{data.member.length + data.admin.length} people</span>
          </div>
        </Top>
      </Link>
    );
  };

  const _desc = () => (
    <Desc>
      {data?.description?.split(/\r?\n/).map((line, id) => {
        return <p key={id}> {line}</p>;
      })}
      {/* <p>{data.description}</p> */}
    </Desc>
  );

  return (
    <GroupCardWrap>
      {_top()}
      {_desc()}
      <div className="footer">
        {/*  {_userJoined()}*/}
        {/*  {data.userJoin.length  > 5 ? _moreUser():null}*/}
      </div>
    </GroupCardWrap>
  );
};

export default GroupCardComponent;
