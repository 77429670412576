import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';

import {
  Form,
  Select,
  Input,
  Switch,
  DatePicker,
  Checkbox,
  message,
  InputNumber
} from 'antd';
import { TitleField } from '../DetailScreen.style';
import moment from 'moment';
import { useMutation, useLazyQuery } from 'react-apollo';
import {
  ADD_ORGANIZATION,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION
} from './Organization.graphql';
import {
  generateDateFormat,
  getErrorMessage,
  isAfter
} from '../HelperDetailScreen';
import { AuthenticationService } from '../../../../Services';
import {
  ButtonFrom,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  SearchableSelect,
  globalCitiesSeachableVars
} from '../../../../SharedComponents';

const AddEditComponents = ({
  open,
  onClose,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen,
  openState
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [share, setShare] = useState(false);
  const [currently, setCurrently] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });

  const [addOrganization] = useMutation(ADD_ORGANIZATION);
  const [UpdateOrganization] = useMutation(UPDATE_ORGANIZATION);
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION);
  const [
    getProvince,
    { data: queryProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);
  const [getCity, { data: queryCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );

  useEffect(() => {
    if (data) {
      getProvince({
        variables: {
          where: {
            deletedAt: { _is_null: true }
          }
        }
      });
      getCity({
        variables: {
          ...globalCitiesSeachableVars({ province: data?.province?.id })
        }
      });
      setCurrently(data?.end_date === null);
      setFieldsValue({
        city: data?.city?.id,
        city_name: data?.city?.name,
        province: data?.province?.id
      });
      setShare(data?.is_shared_with_network);
    } else {
      setShare(false);
      setFieldsValue({
        province: undefined,
        city_name: undefined,
        city: undefined
      });
    }
  }, [data, people_profile, getProvince, getCity, setFieldsValue]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const objects = {
          is_shared_with_network: share,
          organization_name: values.organization_name,
          organization_type: values.organization_type,
          member_id: values.member_id,
          position_name: values.position_name,
          position_description: values.position_description,
          start_date: generateDateFormat(values.start_date),
          end_date: generateDateFormat(values.end_date),
          country: values.country,
          province_id: values.province,
          city_id: values.city,
          logo: {}
        };
        if (actionType === 'add') {
          // console.log(objects)
          addOrganization({
            variables: { objects }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Berhasil menambahkan organisasi`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan organisasi, ${err}`);
            });
        } else if (actionType === 'edit') {
          UpdateOrganization({
            variables: {
              id: data.id,
              object: objects
            }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Berhasil mengubah organisasi`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah organisasi, ${err}`);
            });
        }
      }
    });
  };

  const listFields = [
    {
      labelName: 'Nama Organisasi',
      fieldName: 'organization_name',
      type: 'text',
      fieldValue: data && data?.organization_name,
      placeholder: 'Tambahkan nama organisasi',
      required: true
    },
    {
      labelName: 'Tipe Organisasi',
      fieldName: 'organization_type',
      type: 'text',
      fieldValue: data && data?.organization_type,
      placeholder: 'Tambahkan tipe organisasi',
      required: true
    },
    {
      labelName: 'Nomor Keanggotaan',
      fieldName: 'member_id',
      type: 'text',
      fieldValue: data && data?.member_id,
      placeholder: 'Tambahkan nomor keanggotaan',
      required: false
    },
    {
      labelName: 'Jabatan',
      fieldName: 'position_name',
      type: 'text',
      fieldValue: data && data?.position_name,
      placeholder: 'Tambah Jabatan',
      required: true
    }
  ];

  const listFields2 = [
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'select',
      fieldValue: data && data?.country,
      placeholder: 'Pilih Negara',
      required: true,
      option: [
        {
          name: 'Indonesia',
          id: 'Indonesia'
        }
      ]
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      placeholder: 'Pilih provinsi',
      required: true,
      option: queryProvince?.global_provinces ?? [],
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          city: undefined,
          city_name: undefined,
          province: e
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      fieldValue: getFieldValue('city'),
      placeholder: 'Tambahkan kota',
      required: true,
      loading: loadingCity,
      option: queryCity?.global_cities ?? [],
      onSearch: ({ value }) => {
        getCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      }
    }
  ];

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      labelName,
      placeholder,
      loading,
      fieldValue,
      onSearch,
      onTouch,
      onChange
    } = res;
    if (type === 'select') {
      return (
        <Select filterOption={false} showSearch size="large" loading={loading}>
          <Option value={null} disabled>
            Pilih {labelName}
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text') {
      return (
        <Input placeholder={placeholder} style={{ width: '100%' }}></Input>
      );
    } else if (type === 'number') {
      return (
        <InputNumber
          placeholder={placeholder}
          style={{ width: '100%' }}
        ></InputNumber>
      );
    }
  };

  const handleDelete = () => {
    deleteOrganization({
      variables: {
        id: data.id,
        userId: AuthenticationService.getUserId()
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus organisasi`);
        onClose();
        setCurrently(false);
      })
      .catch(err => {
        message.error(`Gagal menghapus organisasi, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setCurrently(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Organisasi`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan Jabatan</TitleField>}
          >
            {getFieldDecorator('position_description', {
              initialValue: data && data?.position_description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              <TextArea rows={4} placeholder="Tambahakan keterangan posisi" />
            )}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={<TitleField>Tanggal Mulai</TitleField>}
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                  width: '50%'
                }}
                extra={
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(
                      'currently_studying',
                      {}
                    )(
                      <Checkbox
                        checked={currently}
                        onClick={() => {
                          setFieldsValue({ end_date: undefined });
                          setCurrently(!currently);
                        }}
                      >
                        <TitleField>
                          Saat ini saya sedang bekerja dalam posisi ini
                        </TitleField>
                      </Checkbox>
                    )}
                  </Form.Item>
                }
              >
                {getFieldDecorator('start_date', {
                  initialValue:
                    data && data.start_date
                      ? moment(data.start_date, 'YYYY/MM/DD')
                      : moment(new Date(), 'YYYY/MM/DD'),
                  rules: [
                    {
                      required: true,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Pilih tanggal"
                    format={'YYYY-MM-DD'}
                    style={{ width: '90%' }}
                    onChange={() => setFieldsValue({ end_date: undefined })}
                    disabledDate={current =>
                      !currently && isAfter(current, getFieldValue('end_date'))
                    }
                  />
                )}
              </Form.Item>
              {!currently && (
                <Form.Item
                  label={<TitleField>Tanggal Selesai</TitleField>}
                  style={{ display: 'inline-block', width: '50%' }}
                >
                  {getFieldDecorator('end_date', {
                    initialValue:
                      data && data.end_date
                        ? moment(data.end_date, 'YYYY/MM/DD')
                        : moment(new Date(), 'YYYY/MM/DD'),
                    rules: [
                      {
                        required: true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(
                    <DatePicker
                      placeholder="Pilih tanggal"
                      format={'YYYY-MM-DD'}
                      style={{ width: '90%' }}
                      disabledDate={current =>
                        isAfter(getFieldValue('start_date'), current)
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          {listFields2.length > 0 &&
            listFields2.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Organisasi"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEdit = Form.create({ name: 'Add_Organization' })(AddEditComponents);

export default AddEdit;
