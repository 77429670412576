import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VendorShippingComponent from './VendorShipping.component';
import { useQuery, useMutation } from 'react-apollo';
import {
  GET_LIST_SHIPPING_SERVICE,
  GET_VENDOR_SHIPPING_SERVICE,
  UPDATE_VENDOR_SHIPPING_SERVICE
} from './VendorShipping.graphql';
import { message } from 'antd';

const VendorShippingContainer = props => {
  const params = useParams();
  const [dataServiceShipping, setDataServiceShipping] = useState([]);
  const [vendorShipping, setVendorShipping] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'public' } }
  };

  const OPTIONS_2 = {
    context: { headers: { role: 'organization-administrator' } },
    variables: {
      vendor: params.id
    }
  };

  const gqlGetListShippingService = useQuery(
    GET_LIST_SHIPPING_SERVICE,
    OPTIONS
  );

  const getVendorShippingService = useQuery(
    GET_VENDOR_SHIPPING_SERVICE,
    OPTIONS_2
  );
  const [updateVendorShippingMutation, { error: mutationError }] = useMutation(
    UPDATE_VENDOR_SHIPPING_SERVICE
  );

  const handleOnSave = () => {
    const shippingNotNull = vendorShipping.filter(shipping => {
      return shipping.type.length !== 0;
    });

    updateVendorShippingMutation({
      variables: {
        courier_services: shippingNotNull
      }
    }).then(res => {
      if (res.data.updateShipping.success === 1) {
        getVendorShippingService.refetch();
        message.success('Update shipping vendor success.');
      }
    });
    setDisableSave(true);
  };
  if (mutationError) {
    message.error('Something went wrong');
  }

  useEffect(() => {
    const fetchListShippingService = () => {
      if (gqlGetListShippingService.data) {
        setDataServiceShipping(
          gqlGetListShippingService.data.global_shipping_providers
        );
      }
    };
    const fetchVendorShippingService = () => {
      if (getVendorShippingService.data) {
        setVendorShipping(
          getVendorShippingService.data?.company_shipping_availables[0]
            ?.courier_services
        );
      }
    };
    fetchListShippingService();
    fetchVendorShippingService();
  }, [gqlGetListShippingService, getVendorShippingService]);

  return (
    <VendorShippingComponent
      dataServiceShipping={dataServiceShipping}
      vendorShipping={vendorShipping || []}
      setVendorShipping={setVendorShipping}
      handleOnSave={handleOnSave}
      disableSave={disableSave}
      setDisableSave={setDisableSave}
      {...props}
    />
  );
};

export default VendorShippingContainer;
