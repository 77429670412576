import React from 'react';
import { TopSettingGroup, CollapseGroup } from '../../Components';

const SettingGroupBoxComponent = (props) => {

  const {title, buttonTop } = props;

  return (
    <div>
      <TopSettingGroup
        title={title}
        buttonTop = {buttonTop}
        {...props}
      />
      
      <CollapseGroup title={title} {...props} />
    </div>
  );
};

export default SettingGroupBoxComponent;