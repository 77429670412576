import React from 'react';
import { CommentBoxWrapper } from './CommentBox.style';
import { Avatar, Input, Button } from 'antd';
import Constants from '../../Config/Constants';
import IMAGES from '../../Assets/images';

const { SEND_ICON, AVATAR } = IMAGES;
const { INPUT_PLACEHOLDER, SUBMIT_BUTTON } = Constants.MESSAGES.COMMENT_BOX;

const CommentBoxComponent = props => {
  const { handleChange, contentInput, handleSubmit } = props;
  const avatar = props.avatar ? props.avatar : AVATAR;
  const { TextArea } = Input;
  return (
    <CommentBoxWrapper>
      <div className="comment-box ">
        <div className="photo">
          <Avatar src={avatar} icon={'user'} />
        </div>
        <div className="input-box">
          <TextArea
            className="form-input"
            rows={2}
            placeholder={INPUT_PLACEHOLDER}
            onChange={event => handleChange(event.target.value)}
            value={contentInput}
          />
        </div>
        <div className="right">
          <Button className="button-send" onClick={handleSubmit}>
            <img
              className="menuTitleImage"
              src={SEND_ICON}
              alt={SUBMIT_BUTTON}
            />
          </Button>
        </div>
      </div>
    </CommentBoxWrapper>
  );
};

export default CommentBoxComponent;
