import React from 'react';
import Assets from '../../Assets';
import { AvatarStyled2 } from '../BannerProfile/BannerProfile.style';

function AvatarGroup({ avatar }) {
  const [src, setSrc] = React.useState(avatar);

  return (
    <AvatarStyled2
      style={
        !avatar && {
          padding: 28,
          backgroundColor: Assets.COLOR_PALLETE.grey2
        }
      }
      onError={e => {
        if (e) {
          setSrc(
            'https://storage.googleapis.com/wlb-dev/1571303139820_15b2f070-a59c-4d61-8086-15d244915a2e.jpeg'
          );
        }
      }}
      src={src}
      shape="square"
    />
  );
}

export default AvatarGroup;
