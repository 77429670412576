import { Avatar, Button, Empty, Icon, Input, message, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { DirectToUserDetail, ModalEdit } from "../../../../Components";
import {
  PEOPLE_WITH_SAME_SKILL,
  GET_TOTAL_PEOPLE_WITH_SKILL,
} from "./Skills.graphql";
import { ListPeople } from "./Skils.style";
import InfiniteScroll from "react-infinite-scroll-component";
import { AuthenticationService, FollowService } from "../../../../Services";

const Loader = () => (
  <>
    <div className="item">
      <Skeleton avatar paragraph={{ rows: 1 }} />
    </div>
    <div className="item">
      <Skeleton avatar paragraph={{ rows: 1 }} />
    </div>
    <div className="item">
      <Skeleton avatar paragraph={{ rows: 1 }} />
    </div>
    <div className="item">
      <Skeleton avatar paragraph={{ rows: 1 }} />
    </div>
  </>
);

const UserPeopleSkill = ({
  name,
  avatar,
  position,
  onClickAction,
  id,
  userIdLogin,
  is_followed,
}) => {
  const [isFollowed, setIsFollowed] = useState(0);
  return (
    <div className="item">
      <DirectToUserDetail userId={id}>
        <div className="user-info">
          <Avatar src={avatar} size={45} className="avatar" />
          <div className="name-section">
            <h5 className="name">{name}</h5>
            <p className="position">{position}</p>
          </div>
        </div>
      </DirectToUserDetail>
      {id !== userIdLogin && (
        <Button
          onClick={() =>
            onClickAction({
              is_followed: is_followed + isFollowed === 1,
              setIsFollowed: setIsFollowed,
            })
          }
          type={is_followed + isFollowed === 1 ? "default" : "primary"}
          className="action"
        >
          {is_followed + isFollowed === 1 ? "Following" : "Follow"}
        </Button>
      )}
    </div>
  );
};

const LIMIT_QUERY = 20;

export default function PeopleWithSameSkill({ open, onClose, data, isUser }) {
  const CONTEXT = { headers: { "X-Hasura-Role": isUser ? "user" : "public" } };
  const USER_ID_LOGIN = AuthenticationService.getUserId()

  const [
    getPeopleSameSkill,
    { data: dataQuery, fetchMore, loading },
  ] = useLazyQuery(PEOPLE_WITH_SAME_SKILL, {
    context: CONTEXT,
    fetchPolicy: "cache-and-network",
  });

  const [getTotalPeopleSameSkill, { data: dataTotal }] = useLazyQuery(
    GET_TOTAL_PEOPLE_WITH_SKILL,
    {
      context: CONTEXT,
    }
  );

  useEffect(() => {
    if (open) {
      getPeopleSameSkill({
        variables: {
          skillID: data?.people_skill?.id,
          userID: data?.user,
          limit: LIMIT_QUERY,
          offset: 0,
        },
      });
      getTotalPeopleSameSkill({
        variables: {
          skillID: data?.people_skill?.id,
          userID: data?.user,
        },
      });
    }
  }, [open, getPeopleSameSkill, getTotalPeopleSameSkill, data, LIMIT_QUERY]);

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        skillID: data?.people_skill?.id,
        userID: data?.user,
        limit: LIMIT_QUERY,
        offset: dataQuery?.peopleSameSkill?.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        } else {
          return Object.assign({}, prev, {
            peopleSameSkill: [
              ...prev.peopleSameSkill,
              ...fetchMoreResult.peopleSameSkill,
            ].filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i),
          });
        }
      },
    });
  };

  const handleSearch = (e) => {
    getPeopleSameSkill({
      variables: {
        skillID: data?.people_skill?.id,
        userID: data?.user,
        limit: LIMIT_QUERY,
        offset: 0,
        search: `%${e.target.value}%`,
      },
    });
    getTotalPeopleSameSkill({
      variables: {
        skillID: data?.people_skill?.id,
        userID: data?.user,
        search: `%${e.target.value}%`,
      },
    });
  };

  const handleClickAction = ({
    userId,
    is_followed,
    userName,
    setIsFollowed,
  }) => {
    if (is_followed) {
      FollowService.unFolloweUser(userId)
        .then(() => {
          message.success(`Unfollow ${userName} success`);

          // TODO : decrease followers manually
          setIsFollowed((e) => e - 1);
        })
        .catch(() => message.error("Something went wrong"));
    } else {
      FollowService.followUser(userId)
        .then(() => {
          message.success(`Success Follow ${userName}`);

          // TODO : increase followers manually
          setIsFollowed((e) => e + 1);
        })
        .catch(() => message.error("Something went wrong"));
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalEdit
      sizeSmall
      centered
      padding="0px"
      title={`People with ${data?.people_skill?.name || ""} Skill`}
      open={open}
      onClose={handleClose}
    >
      <div style={{ padding: "16px 22px 0px" }}>
        <Input
          placeholder="Search"
          onChange={handleSearch}
          size="large"
          suffix={
            <Icon className={"icon-search-bar-header-wlb"} type="search" />
          }
        />
      </div>

      <ListPeople>
        {!loading && dataQuery?.peopleSameSkill?.length > 0 && (
          <InfiniteScroll
            dataLength={dataQuery?.peopleSameSkill?.length || 0}
            next={handleFetchMore}
            hasMore={
              (dataTotal?.total?.aggregate?.count || 0) >
              (dataQuery?.peopleSameSkill?.length || 0)
            }
            height={450}
            loader={<Loader />}
          >
            {dataQuery?.peopleSameSkill?.map((res, i) => {
              return (
                <UserPeopleSkill
                  userIdLogin={USER_ID_LOGIN}
                  key={`${res.id}-${i}`}
                  name={res.name}
                  id={res.id}
                  is_followed={res.is_followed ? 1 : 0}
                  avatar={res.avatar}
                  position={res.position}
                  onClickAction={({ is_followed, setIsFollowed }) =>
                    handleClickAction({
                      userId: res.id,
                      userName: res.name,
                      is_followed,
                      setIsFollowed,
                    })
                  }
                />
              );
            })}
          </InfiniteScroll>
        )}

        {!loading && dataQuery?.peopleSameSkill?.length === 0 && <Empty style={{margin: '100px 0'}} />}
        {loading && <Loader /> }
      </ListPeople>
    </ModalEdit>
  );
}
