import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';
import { isObjectEmpty } from '../../Utils/CommonUtils';

const userId = AuthenticationService.getUserId();

const getListAllMember = (classroomId, maxResults, keyword) => {
  let parameters = {
    params: {
      name: keyword,
      maxResults: maxResults
    }
  };

  if (isObjectEmpty(keyword)) {
    delete parameters.params.name;
  }
  const getListMemberUrl = Constants.CLASSROOM_ALL_MEMBER(userId, classroomId);

  return BaseService.get(getListMemberUrl, parameters);
};

const getListMember = (classroomId, page, maxResults, keyword) => {
  let parameters = {
    params: {
      name: keyword,
      page: page,
      maxResults: maxResults
    }
  };

  if (isObjectEmpty(keyword)) {
    delete parameters.params.name;
  }
  const getListMemberUrl = Constants.CLASSROOM_MEMBER(userId, classroomId);

  return BaseService.get(getListMemberUrl, parameters);
};

const getListAdmin = (classroomId, page, maxResults) => {
  const userId = AuthenticationService.getUserId();
  const getListAdminUrl = Constants.CLASSROOM_ADMIN(
    userId,
    classroomId,
    page,
    maxResults
  );

  return BaseService.get(getListAdminUrl);
};

const setAdminAsMember = (classroomId, classroomMemberId) => {
  const url = Constants.CLASSROOM_MEMBER_TO_ADMIN(
    userId,
    classroomId,
    classroomMemberId
  );
  return BaseService.post(url);
};

const setMemberAsAdmin = (classroomId, classroomMemberId) => {
  const url = Constants.CLASSROOM_ADMIN_TO_MEMBER(
    userId,
    classroomId,
    classroomMemberId
  );
  return BaseService.post(url);
};

const kickMemberClassroom = (classroomId, classroomMemberId) => {
  const url = Constants.CLASSROOM_MEMBER_ID(
    userId,
    classroomId,
    classroomMemberId
  );
  return BaseService.delete(url);
};

const leaveMemberClassroom = classroomId => {
  const url = Constants.CLASSROOM_LEAVE(userId, classroomId);
  return BaseService.post(url);
};

const inviteMember = (classroomId, memberId) => {
  const url = Constants.CLASSROOM_MEMBER_ID(userId, classroomId, memberId);
  return BaseService.post(url);
};

const joinClassroom = classroomId => {
  const url = Constants.CLASSROOM_JOIN(userId, classroomId);
  return BaseService.post(url);
};

const getListRequestMember = (classroomId, page, maxResults) => {
  // let parameters = {
  //   params: {
  //     page: page,
  //     maxResults: maxResults
  //   }
  // };
  const getRequestMemberList = Constants.CLASSROOM_REQUEST_MEMBER_LIST(
    userId,
    classroomId
  );
  return BaseService.get(getRequestMemberList);
};
const approveRequest = (classroomId, acceptId) => {
  const acceptRequest = Constants.CLASSROOM_ACCEPT_REQUEST_MEMBER(
    userId,
    classroomId
  );
  return BaseService.post(acceptRequest, { users: [acceptId] });
};
const rejectRequest = (classroomId, rejectId) => {
  const rejectRequest = Constants.CLASSROOM_DECLINE_REQUEST_MEMBER(
    userId,
    classroomId
  );
  return BaseService.post(rejectRequest, { users: [rejectId] });
};
const cancelRequestJoin = classroomId => {
  const url = Constants.CLASSROOM_CANCEL_REQUEST_JOIN(userId, classroomId);
  return BaseService.post(url);
};

export default {
  getListAllMember,
  getListMember,
  getListAdmin,
  setAdminAsMember,
  setMemberAsAdmin,
  leaveMemberClassroom,
  kickMemberClassroom,
  inviteMember,
  joinClassroom,
  getListRequestMember,
  approveRequest,
  rejectRequest,
  cancelRequestJoin
};
