import React from 'react';
import { Col, Row } from 'antd';
// import style from './NotificationScreen.style';
import { Notification, Widget } from '../../../Containers';

const NotificationScreenComponent = props => {
  // const { title } = props;

  return (
    <Row gutter={16} style={{ marginTop: '15px' }}>
      <Col span={18} id={`notification-you`}>
        {/* <h3 style={style.h3}>{title}</h3> */}
        <Notification {...props} />
      </Col>
      <Col span={6}>
        <Widget {...props} />
      </Col>
    </Row>
  );
};

export default NotificationScreenComponent;
