import React from 'react';
import { Col, Row } from 'antd';
import { ButtonWlb } from '../../../Components';
import { WelcomeWrap } from './WelcomeScreen.style';

const WelcomeScreenComponent = props => {
  const { setCurrentSteps } = props;

  return (
    <WelcomeWrap>
      <Row>
        <Col span={24}>
          <h2>Welcome to Vendor</h2>
          <p>
            {`Thank You for choosing us to power your online store! This quick setup will configure the basic setting. 
            It's completely optional and shouldn't take any longer than two minutes.`}{' '}
          </p>
          <ButtonWlb type="primary" onClick={e => setCurrentSteps(0)}>
            Continue
          </ButtonWlb>
        </Col>
      </Row>
    </WelcomeWrap>
  );
};

export default WelcomeScreenComponent;
