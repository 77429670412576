import styled from 'styled-components';
import Constants from '../../Config/Constants';
import Assets from '../../Assets';

export default {
  title: {
    cursor: 'pointer',
    height: '100%',
    fontSize: 12,
    fontWeight: 500,
    marginTop: 5,
    color: Constants.COLORS.BLACK
  },
  iconPost: {
    width: 9,
    height: 9,
    marginRight: 6
  },
  iconEvent: {
    width: 9,
    height: 10,
    marginRight: 6
  },
  iconTask: {
    width: 12,
    height: 12,
    marginRight: 6
  }
};

export const TopBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  /* border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey3}; */
  margin-bottom: 20px;

  & > div {
    text-align: center;
    flex: 1;
  }

  .component_menu_item {
    padding: 20px 0;
  }
`;
