import styled from 'styled-components';
import Assets from '../../Assets';

export const ComponentWrapper = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: solid 1px ${Assets.COLOR_PALLETE.grey2};
  background-color: ${Assets.COLOR_PALLETE.grey2};
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
  .component_giving_kudos_image {
    margin-right: 15px;
    width: 100px;
    height: 100px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .component_giving_kudos_content {
    width: 70%;
  }

  .compoent_giving_kudos_badge {
    font-family: ${Assets.FONT.MULI};
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${Assets.COLOR_PALLETE.dark_blue};
    margin-bottom: 2px;
  }

  .compoent_giving_kudos_name {
    font-family: ${Assets.FONT.MULI};
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Assets.COLOR_PALLETE.black};
    margin-bottom: 3px;
  }

  .compoent_giving_kudos_description {
    font-family: ${Assets.FONT.MULI};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #a9a8a8;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;
