import React, { useState } from 'react';
import PostListComponent from './PostList.component';
import {
  useBlockMe,
  useInfiniteScroll
} from '../../SharedComponents/CustomHook';
import { checkNextPage } from '../../Utils/CommonUtils';
import { Timeline, AuthenticationService, PostService } from '../../Services';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';
import axios from 'axios';

const PostListGlobal = props => {
  const [dataPost, setDataPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('global-post');
  const [isFetching, setIsFetching] = useState(false);
  const userId = AuthenticationService.getUserId();
  const [blockedMe, blockLoaded, blockLoading] = useBlockMe();

  useAsyncEffect(
    async isMount => {
      let source = axios.CancelToken.source();
      if (
        blockLoaded &&
        hasNextPage &&
        !isFetching &&
        (isBottom || page === 1)
      ) {
        setIsFetching(true);
        await Timeline.fetchTimeline(page, { cancelToken: source.token })
          .then(response => {
            if (!isMount()) {
              return () => source.cancel();
            }
            const { per_page, total, data } = response;
            const isNextPage = checkNextPage(total, page, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }
            // BLOCKED POST FROM BLOCK LIST
            let filteredPost = data.filter(
              item => !blockedMe?.includes(item.userId)
            );
            // remove kudos from data list
            const dataPostWithoutKudos = dataPost
              .concat(filteredPost)
              .map(item => {
                delete item.canAward;
                delete item.award;
                return item;
              });
            setDataPost(dataPostWithoutKudos);
          })
          .catch(error => {})
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
            setIsBottom(false);
          });
      }
    },
    [
      dataPost,
      hasNextPage,
      isBottom,
      isFetching,
      page,
      setIsBottom,
      blockLoaded
    ]
  );

  function deletePost(itemPost) {
    if (itemPost.userId === userId) {
      PostService.deletePost(itemPost.userId, itemPost.id)
        .then(res => {
          if (res.status === 200) {
            let newDataPost = dataPost.filter(post => {
              return post.id !== itemPost.id;
            });
            setDataPost(newDataPost);
            message.success('Your post has been deleted');
          }
        })
        .catch(err => message.error('Something went wrong'));
    } else {
      Timeline.deleteTimeline(itemPost.timelineId)
        .then(res => {
          let newDataPost = dataPost.filter(p => p.id !== itemPost.id);
          setDataPost(newDataPost);
          message.success('Post has been hidden');
        })
        .catch(err => message.error('Something went wrong'));
    }
  }

  function restartPage() {
    setDataPost([]);
    setHasNextPage(true);
    setPage(1);
  }

  const handleVote = async (postId, optionId) => {
    const _dataPost = dataPost;
    const indexPost = _dataPost.findIndex(e => e.id === postId);
    let post = _dataPost[indexPost];

    await PostService.votePoll(userId, postId, optionId)
      .then(res => {
        if (post.count_shared_post > 0) {
          _dataPost.map((val, i) => {
            if (val.sharedPost) {
              if (val.sharedPost.id === postId) {
                _dataPost[i] = {
                  ...val,
                  sharedPost: { ...val.sharedPost, poll: { ...res } }
                };
              }
            }
          });
        }
        post = { ...post, poll: { ...post.poll, ...res } };

        _dataPost[indexPost] = post;

        setDataPost([..._dataPost]);
        message.success('Vote Success Submitted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  const handleUndoVote = async postId => {
    let _dataPost = dataPost;
    const indexPost = _dataPost.findIndex(e => e.id === postId);
    let post = _dataPost[indexPost];

    await PostService.undoVote(userId, postId)
      .then(response => {
        if (post.count_shared_post > 0) {
          _dataPost.map((val, i) => {
            if (val.sharedPost) {
              if (val.sharedPost.id === postId) {
                _dataPost[i] = {
                  ...val,
                  sharedPost: { ...val.sharedPost, poll: { ...response.poll } }
                };
              }
            }
          });
        }

        post = { ...post, poll: { ...post.poll, ...response.poll } };
        _dataPost[indexPost] = post;
        setDataPost([..._dataPost]);
        message.success('Undo Vote Success Submitted');
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <PostListComponent
      data={dataPost}
      isFetching={isFetching}
      deletePost={deletePost}
      restartPage={restartPage}
      blockedMe={blockedMe}
      blockLoaded={blockLoaded}
      blockLoading={blockLoading}
      handleVote={handleVote}
      handleUndoVote={handleUndoVote}
      {...props}
    />
  );
};
export default PostListGlobal;
