import React from 'react';
import LoadingWrapper from './style';
import Assets from '../../Assets';

const DisplayLoading = () => {
  return (
    <LoadingWrapper>
      <img alt={'loading ...'} src={Assets.ICON.LOADING} />
    </LoadingWrapper>
  );
};

export default DisplayLoading;
