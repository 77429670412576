import React from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import { TaskPostCard } from '../../Components';

const GET_TASK = gql`
  query($id: bigint) {
    performance_tasks(where: { id: { _eq: $id } }) {
      id
      name
      description
      enddate
      progress
      target
      target_stretch
      status
      attachment
      performance_goal {
        name
        kpi_weight_unit {
          id
          name
        }
      }
      performance_group {
        id
        name
        color
      }
    }
  }
`;

const TaskContainer = ({ taskId, img }) => {
  const getTask = useQuery(GET_TASK, {
    context: { headers: { 'X-Hasura-Role': 'organization-staff' } },
    variables: {
      id: taskId
    }
  });

  const dataTask = getTask?.data?.performance_tasks?.[0];
  if (dataTask !== undefined) {
    return (
      <TaskPostCard
        img={img}
        name={dataTask?.name}
        description={dataTask?.description}
      />
    );
  } else return <div />;
};

export default TaskContainer;
