import React, { useState, useEffect } from 'react';
import BannerBoxComponent from './BannerBox.component';
import { AuthenticationService, CompanyGlobal } from '../../Services';
import { message } from 'antd';
import Config from './BannerCompany.config';
import { useQuery } from 'react-apollo';
import { GET_PHONE_COMPANY, GET_SOSMED_COMPANY } from './BannerBox.graphql';
// import Assets from '../../Assets';

const activeCompanyId = AuthenticationService.getCompanyId();

const BannerBoxContainer = props => {
  const { companyId, setUpdateedFollowers, isDetailPage } = props;
  const [mainProfile, setMainProfile] = useState(Config.bannerCompany);
  const [isFollow, setIsFollow] = useState(false);
  const [isStructureVisible, setIsStructureVisible] = useState(false);

  const { data: phoneCompany } = useQuery(GET_PHONE_COMPANY, {
    variables: {
      id: companyId
    }
  });

  const { data: sosmedCompany } = useQuery(GET_SOSMED_COMPANY, {
    variables: {
      id: companyId
    }
  });

  const handleFollowCompany = () => {
    if (isFollow) {
      CompanyGlobal.followCompanyServices(companyId, 'unfollow')
        .then(() => {
          message.success('Berhasil berhenti mengikuti instansi');
          setIsFollow(!isFollow);

          // TODO : decrease followers manually
          isDetailPage && setUpdateedFollowers(e => e - 1);
        })
        .catch(err => message.error('Something went wrong : ', err));
    } else {
      CompanyGlobal.followCompanyServices(companyId, 'follow')
        .then(() => {
          message.success('Berhasil mengikuti instansi');
          setIsFollow(!isFollow);

          // TODO : increase followers manually
          isDetailPage && setUpdateedFollowers(e => e + 1);
        })
        .catch(err => message.error('Something went wrong : ', err));
    }
  };

  useEffect(() => {
    CompanyGlobal.getCompanyById(companyId)
      .then(res => {
        setIsFollow(res.isFollowed === 1);
        setIsStructureVisible(res.profile_field?.structure_visible ?? true);
        setMainProfile({
          ...mainProfile,
          totalFollower: res.totalFollower || 0,
          name: res.brand_name || '-',
          logo: res.logo || null,
          tagline: res.profile_field?.headline || '-',
          legalName: res.legal_name || '-',
          brandName: res.brand_name || '-',
          phone: res.phone || '-',
          website: res.profile_field?.website || '-',
          description: res.profile_field?.description || '-',
          socialMedia: res.profile_field?.social_links || '-',
          bannerPhoto:
            res.profile_field?.banner || Config.bannerCompany.bannerPhoto
        });
      })
      .catch(() => {
        message.error('Something went wrong');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  return (
    <BannerBoxComponent
      {...props}
      activeCompanyId={activeCompanyId}
      phoneCompany={phoneCompany && phoneCompany?.main_profile?.[0]}
      sosmedCompany={
        sosmedCompany && sosmedCompany?.main_profile?.[0]?.profile_field
      }
      bannerCompany={mainProfile}
      isStructureVisible={isStructureVisible}
      isFollow={isFollow}
      handleFollowCompany={handleFollowCompany}
    />
  );
};

export default BannerBoxContainer;
