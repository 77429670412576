import gql from "graphql-tag";

export const GET_LIST_MEDICAL_RECORD = gql`
  query($userId: uuid!) {
    people_profile_medical_records(
      where: {
        _and: [{ user_id: { _eq: $userId } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [{ id: desc }]
    ) {
      id
      deletedAt
      user_id
      medical_test_name
      medical_test_reason
      additional_medical_conditions
      disabilities
      hospitalized_reason
      blood_type
      blood_rhesus
      body_height
      body_weight
      head_size
      clothes_size
      pants_size
    }
  }
`;
export const ADD_MEDICAL_RECORD = gql`
  mutation(
    #$userId: uuid!
    $name: String!
    $reason: String
    $medicalConditions: String
    $disabilities: String
    $hospitalizedReason: String
    $bloodType: String!
    $bloodRhesus: String!
    $bodyHeight: String!
    $bodyWeight: String!
    $headSize: String
    $clothesSize: String
    $pantsSize: String
  ) {
    insert_people_profile_medical_records(
      objects: [
        {
          #user_id: $userId
          medical_test_name: $name
          medical_test_reason: $reason
          additional_medical_conditions: $medicalConditions
          disabilities: $disabilities
          hospitalized_reason: $hospitalizedReason
          blood_type: $bloodType
          blood_rhesus: $bloodRhesus
          body_height: $bodyHeight
          body_weight: $bodyWeight
          head_size: $headSize
          clothes_size: $clothesSize
          pants_size: $pantsSize
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const EDIT_MEDICAL_RECORD = gql`
  mutation(
    $id: Int!
    $userId: uuid!
    $name: String!
    $reason: String
    $medicalConditions: String
    $disabilities: String
    $hospitalizedReason: String
    $bloodType: String!
    $bloodRhesus: String!
    $bodyHeight: String!
    $bodyWeight: String!
    $headSize: String
    $clothesSize: String
    $pantsSize: String
  ) {
    update_people_profile_medical_records(
      _set: {
        medical_test_name: $name
        medical_test_reason: $reason
        additional_medical_conditions: $medicalConditions
        disabilities: $disabilities
        hospitalized_reason: $hospitalizedReason
        blood_type: $bloodType
        blood_rhesus: $bloodRhesus
        body_height: $bodyHeight
        body_weight: $bodyWeight
        head_size: $headSize
        clothes_size: $clothesSize
        pants_size: $pantsSize
      }
      where: { _and: [{ id: { _eq: $id } }, { user_id: { _eq: $userId } }] }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_MEDICAL_RECORD = gql`
  mutation($id: Int!) {
    update_people_profile_medical_records(
      _set: { deletedAt: "now()" }
      where: { _and: [{ id: { _eq: $id } }, { deletedAt: { _is_null: true } }] }
    ) {
      affected_rows
    }
  }
`;
