import React from 'react';
import { AboutGroup } from '../../Containers';

const menuBar = [
  {
    renderComponent: props => <AboutGroup {...props} />,
    name: 'About',
    path: '/group/:id/about'
  }
];

const defaultMenuBar = 'About';

export default {
  menuBar,
  defaultMenuBar
};
