import React, { useState } from 'react';
import Widget from './Widget.component';
import { WidgetService } from '../../Services';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';

const WidgetContainer = props => {
  const [widgets, setWidgets] = useState([]);

  useAsyncEffect(async isMount => {
    // setLoading(true);
    await WidgetService.getListWidget()
      .then(res => {
        if (!isMount()) return;
        const displayWidgets = res.data.filter(d => d.status === true);
        setWidgets(displayWidgets);
      })
      .catch(err =>
        message.error('Something went wrong when load Widget Data')
      );
  }, []);

  return <Widget widgets={widgets} {...props} />;
};

export default WidgetContainer;
