import BaseService from '../BaseService';
import Constants from '../../Config/baseUrl';

const postCommentPost = (postOwnerId, postId, content) => {
  const commentContent = {
    content: content
  };
  return BaseService.post(
    Constants.POST_COMMENT(postOwnerId, postId),
    commentContent
  );
};

const deleteComment = comment => {
  const commentId = comment.id;
  const postId = comment.postId;
  const commentOwnerId = comment.userId;
  return BaseService.delete(
    Constants.POST_COMMENT_ID(commentOwnerId, postId, commentId)
  );
};

const fetchCommentList = (userId, postId, nextPage, maxResults) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: maxResults
    }
  };
  const commentList = Constants.POST_COMMENT(userId, postId);
  return BaseService.get(commentList, parameters);
};

const getLikeComment = (userId, postId, commentId) => {
  const likeComment = Constants.POST_COMMENT_LIKE(userId, postId, commentId);
  return BaseService.get(likeComment);
};
const postLikeUnlikeComment = (userId, postId, commentId, value) => {
  const likeComment = `${Constants.POST_COMMENT_LIKE(
    userId,
    postId,
    commentId
  )}/${value}`;
  return BaseService.post(likeComment);
};

export default {
  postCommentPost,
  deleteComment,
  fetchCommentList,
  getLikeComment,
  postLikeUnlikeComment
};
