import { gql } from 'apollo-boost';

export const CREATE_COMMENT = gql`
  mutation($postId: String!, $content: String!) {
    classroomPostCreateComment(postId: $postId, content: $content) {
      id
      success
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation($commentId: String!) {
    classroomPostDeleteComment(commentId: $commentId) {
      success
    }
  }
`;
