import React from 'react';
import { Wrapper, BodyBoxGroup } from './AboutGroup.style';
import { Row, Col } from 'antd';
import { Widget, SidebarGroupInfoBox } from '..';

const AboutGroupComponent = props => {
  const {
    groupInfo
    // isLoading
  } = props;
  return (
    <>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarGroupInfoBox {...props} isAbout={true} />
        </Col>
        <Col id={'team-post'} span={12}>
          <Wrapper>
            <BodyBoxGroup>
              <h3>About This Group</h3>
              <hr></hr>
              {groupInfo && (
                <div className="sub">
                  <label>Type</label>
                  <p>General</p>
                  <br />
                  <label>Description</label>
                  {groupInfo?.description?.split(/\r?\n/).map((line, id) => {
                    return <p key={id}> {line}</p>;
                  })}
                  {/* <p>{groupInfo.description}</p> */}
                </div>
              )}
            </BodyBoxGroup>
          </Wrapper>
          <Wrapper>
            <BodyBoxGroup>
              <h3>Group Rules</h3>
              <hr></hr>
              {groupInfo && (
                <div className="sub">
                  {groupInfo?.rules?.split(/\r?\n/).map((line, id) => {
                    return <p key={id}> {line}</p>;
                  })}
                  {/* <p>{groupInfo.rules}</p> */}
                </div>
              )}
            </BodyBoxGroup>
          </Wrapper>
        </Col>
        <Col span={6}>
          <Widget {...props} />
        </Col>
      </Row>
    </>
  );
};

export default AboutGroupComponent;
