import styled from 'styled-components';
import Assets from '../../Assets';
import { Select, AutoComplete } from 'antd';

export const AboutWrap = styled.div`
  position: relative;
  padding: 32px;
  display: flex;
  font-family: ${Assets.FONT.MULI};
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }

  .information {
    display: initial;
    width: 50%;
    span {
      display: block;
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.grey};
    }
    button {
      span {
        color: ${Assets.COLOR_PALLETE.white};
      }
    }
    .name {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 18px;
      color: ${Assets.COLOR_PALLETE.black};
    }
    .desc {
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.black2};
    }
    .number {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 15px;
      color: ${Assets.COLOR_PALLETE.black};
      display: inline-block;
    }
    .count {
      display: flex;
      margin-top: 8px;
      .total {
        width: 100%;
      }
    }
    tr {
      display: table-row;
    }
    td {
      line-height: 32px;
      vertical-align: baseline;
      min-width: 130px;
    }
    td:nth-child(1) {
      color: ${Assets.COLOR_PALLETE.black};
      padding-right: 40px;
    }
  }
`;

export const ButtonEdit = styled.div`
  position: absolute;
  right: 32px;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;
export const AutoCompleteStyled = styled(AutoComplete)`
  & > .ant-select-selection {
    & > .ant-select-selection__rendered {
      & > .ant-select-selection__placeholder {
        z-index: 9999;
      }
      & > ul {
        & > li {
          & > .ant-select-search__field__wrap {
            & > input {
              height: 30px;
              border-radius: 4px !important;
            }
          }
        }
      }
    }
  }
`;

export const SelectStyled = styled(Select)`
  & > .ant-select-selection {
    & > .ant-select-selection__rendered {
      & > .ant-select-selection__placeholder {
        z-index: 9999;
      }
    }
  }
`;

export const TagInput = styled(Select)`
  & > .ant-select-selection {
    & > .ant-select-selection__rendered {
      & > ul {
        & > .ant-select-selection__choice {
          display: none;
        }
      }
    }
  }
`;

export const WrapperItemTag = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px 22px 0px;
  .item {
    padding: 6px 19px;
    background-color: #014a62;
    border-radius: 16px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    p {
      margin: 0px;
      color: #fff;
      font-size: 12px;
      font-weight: normal;
      margin-right: 19px;
    }
  }
`;

export default {
  iconEdit: {
    fontSize: 18
  }
};
