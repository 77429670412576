import styled from 'styled-components';
import Assets from '../../Assets';

export const ComponentWrapper = styled.div`
  width: 100%;
  font-family: ${Assets.FONT.MULI};
  margin-bottom: 10px;
  position: relative;

  .component_image {
    img {
      width: 100%;
    }
  }

  .component_title {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
    white-space: normal;
    word-wrap: break-word;
    margin-bottom: 4px;
  }

  .component_content {
    padding: 10px 15px;
    background-color: ${Assets.COLOR_PALLETE.grey2};
  }

  .component_name {
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.dark_blue};
    margin-bottom: 5px;
  }

  .component_description {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #a9a8a8;
    color: ${Assets.COLOR_PALLETE.grey};
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .component_time {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
  }

  .close-icon {
    position: absolute;
    right: 10px;
    bottom: 100px;
    cursor: pointer;
  }
`;
