import styled from 'styled-components';

const Wrapper = styled.div`
  background: #f7f8f9;
  width: 100%;

  @media (min-width: 1200px) {
    min-width: 1195px;
    min-height: 110vh;
  }
  display: inline-block;
`;

export { Wrapper };
