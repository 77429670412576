import React from 'react';
import { Col, Row } from 'antd';
import {
  PostList,
  SidebarProfile,
  Widget,
  TrendingTopic
} from '../../../Containers';
import { HCEcosystem } from '../../../Components';
import Constants from '../../../Config/Constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import BNIComponent from '../../../Components/Widgets/BNIWidget/BNI.component';

const GlobalScreenComponent = props => {
  // we can't use antd breakpoint bcs it need newer version of *antd*, which means a lot of breaking changes.
  // we will stick with with breakpoints from MUIv4 for now

  const isMobileScreen = useMediaQuery('(max-width: 1200px)');

  return (
    <Row gutter={isMobileScreen ? 0 : 16} style={{ marginTop: '15px' }}>
      {!isMobileScreen && (
        <Col span={6}>
          <SidebarProfile history={props.history} />
          <HCEcosystem />
        </Col>
      )}
      <Col id={'timeline'} span={isMobileScreen ? 24 : 12}>
        {isMobileScreen && <BNIComponent variant="rectangle" />}
        <TrendingTopic />
        <PostList
          postType={Constants.POST_TYPE[props.timelineType]}
          {...props}
        />
      </Col>
      <Col span={6}>
        <Widget isMobileScreen={isMobileScreen} {...props} />
      </Col>
    </Row>
  );
};

export default GlobalScreenComponent;
