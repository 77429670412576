import React, { useState } from 'react';
import MainContainer from './mainContainer';
import { Group } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { message } from 'antd';
import { checkNextPage } from '../../Utils/CommonUtils';

function CommentListGroup(props) {
  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const maxResults = 5;
  const {
    postId,
    postOwnerId,
    totalComment,
    setTotalComment,
    setIsLoading
  } = props;
  const groupId = props.match.params.id;

  useAsyncEffect(
    async isMounted => {
      // if (totalComment > 0) {
      await Group.Discussion.fetchCommentList(
        postOwnerId,
        groupId,
        postId,
        page,
        maxResults
      ).then(response => {
        if (!isMounted()) return;
        const { per_page, total, data } = response;
        const isNextPage = checkNextPage(total, page, per_page);
        setHasNextPage(isNextPage);
        setCommentList(data.concat(commentList));
      });
      // }
    },
    [groupId, postId, postOwnerId, page, maxResults]
  );

  const setFetchMoreComment = () => {
    setPage(page + 1);
  };

  const _handleDelete = async comment => {
    setIsLoading(true);
    await Group.Discussion.deleteComment(comment, groupId)
      .then(() => {
        const commentUpdated = commentList.map(oldComment => {
          if (oldComment.id === comment.id) {
            return { ...oldComment, content: 'comment was deleted' };
          } else {
            return oldComment;
          }
        });
        setCommentList(commentUpdated);
        message.success('Komentar dihapus');
        setTotalComment(totalComment - 1);
      })
      .catch(() => message.error('Something went wrong'));
    setIsLoading(false);
  };

  const _handleSubmit = async content => {
    setIsLoading(true);
    await Group.Discussion.postComment(postOwnerId, groupId, postId, content)
      .then(() => {
        Group.Discussion.fetchCommentList(
          postOwnerId,
          groupId,
          postId,
          1,
          maxResults
        ).then(response => {
          const { per_page, total, data } = response;
          const isNextPage = checkNextPage(total, page, per_page);
          setHasNextPage(isNextPage);
          setCommentList(data);
          setTotalComment(totalComment + 1);
        });
      })
      .catch(() => message.error('Something went wrong'));
    setIsLoading(false);
  };

  return (
    <MainContainer
      commentList={commentList}
      handleDelete={_handleDelete}
      handleSubmit={_handleSubmit}
      hasNextPage={hasNextPage}
      setFetchMoreComment={setFetchMoreComment}
      {...props}
    />
  );
}

export default CommentListGroup;
