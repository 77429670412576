import gql from 'graphql-tag';

const CHANGE_PASSWORD = gql`
  mutation cPwd($oldPassword: String!, $newPassword: String!) {
    changePassword(old_password: $oldPassword, new_password: $newPassword) {
      changed
      message
    }
  }
`;

export { CHANGE_PASSWORD };
