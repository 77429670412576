import React, { useState, useEffect } from 'react';
import PostCardComponent from './PostCard.component';
import { AuthenticationService } from '../../Services';
import { message } from 'antd';

const PostCardContainer = props => {
  const {
    itemPost,
    handleLike,
    handleAward,
    handleKudos,
    cancelKudos,
    feature,
    handleVote
  } = props;

  const id = AuthenticationService.getUserId();
  const [totalLike, setTotalLike] = useState(0);
  const [isUserLiked, setIsUserLiked] = useState(false);
  const [award, setAward] = useState(itemPost.award);
  const [displayModalShare, setDisplayModalShare] = useState(false);
  const [dataShare, setDataShare] = useState({});
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [isLoadingAward, setIsLoadingAward] = useState(false);
  const [isLoadingKudos, setIsLoadingKudos] = useState(false);
  const [kudosLength, setKudosLength] = useState(itemPost?.kudosBy?.length);

  const [kudosByUser, setKudosByUser] = useState(
    itemPost?.kudosBy?.filter(d => d.user_id === id).length === 1
  );

  useEffect(() => {
    if (itemPost) {
      setTotalLike(itemPost.total_like);
      setIsUserLiked(
        feature === 'companyPost'
          ? itemPost.isLikedByMe
          : itemPost.likedBy.includes(id)
      );
    }
    // eslint-disable-next-line
  }, [itemPost]);

  const postAwardHandler = () => {
    if (!isLoadingAward) {
      setIsLoadingAward(true);
      if (award) {
        handleAward(0)
          .then(res => {
            setAward(false);
          })
          .catch(error => {
            message.error('Something went wrong');
          })
          .finally(() => {
            setIsLoadingAward(false);
          });
      } else {
        handleAward(1)
          .then(res => {
            setAward(true);
          })
          .catch(error => {
            message.error('Something went wrong');
          })
          .finally(() => {
            setIsLoadingAward(false);
          });
      }
    }
  };

  const postLikeHandler = () => {
    if (!isLoadingLike) {
      setIsLoadingLike(true);
      if (isUserLiked) {
        handleLike(0)
          .then(res => {
            setIsUserLiked(!isUserLiked);
            const newTotal = totalLike - 1;
            setTotalLike(newTotal);
          })
          .catch(error => {
            message.error('Something went wrong');
          })
          .finally(() => {
            setIsLoadingLike(false);
          });
      } else {
        handleLike(1)
          .then(res => {
            setIsUserLiked(!isUserLiked);
            const newTotal = totalLike + 1;
            setTotalLike(newTotal);
          })
          .catch(error => {
            message.error('Something went wrong');
          })
          .finally(() => {
            setIsLoadingLike(false);
          });
      }
    }
  };

  const postKudosHandler = (kudosItem, isVisibleMenu, fetchKudos) => {
    if (!isLoadingKudos) {
      setIsLoadingKudos(true);
      handleKudos(kudosItem.id)
        .then(res => {
          fetchKudos(kudosItem);
          if (!kudosByUser) {
            setKudosByUser(true);
            setKudosLength(kudosLength + 1);
          }
          isVisibleMenu(false);
        })
        .catch(() => {
          message.error('Something went wrong');
        })
        .finally(() => {
          setIsLoadingKudos(false);
        });
    }
  };

  const postCancelKudos = fetchKudos => {
    if (!isLoadingKudos) {
      setIsLoadingKudos(true);
      cancelKudos()
        .then(() => {
          setKudosLength(kudosLength - 1);
          fetchKudos();
          setKudosByUser(false);
        })
        .catch(() => message.error('Something went wrong'))
        .then(() => setIsLoadingKudos(false));
    }
  };

  const handleShare = post => {
    setDataShare(post);
    setDisplayModalShare(true);
  };

  const handleCancelShare = () => {
    setDisplayModalShare(!displayModalShare);
  };

  return (
    <PostCardComponent
      {...props}
      postLikeHandler={postLikeHandler}
      postAwardHandler={postAwardHandler}
      totalLike={totalLike}
      isUserLiked={isUserLiked}
      award={award}
      handleShare={handleShare}
      handleCancelShare={handleCancelShare}
      displayModalShare={displayModalShare}
      dataShare={dataShare}
      postKudosHandler={postKudosHandler}
      postCancelKudos={postCancelKudos}
      kudosLength={kudosLength}
      handleVote={handleVote}
    />
  );
};

export default PostCardContainer;
