import React, { useState } from 'react';
import Badge from './Badges.compoent';
import {
  ViewAll,
  ModalWrapper,
  ModalHeader,
  ModalTitle,
  ModalContent
} from './Badges.style';
import { Row, Modal, Icon, Tabs } from 'antd';
import DetailBadge from './DetailBadge';
import './style.css';

export default function BadgesContainer({
  talentBadgeData,
  talentPerformanceData,
  nameUser
}) {
  const [open, setOpen] = useState(false);
  const { TabPane } = Tabs;

  const badgeCollection = [
    ...talentBadgeData?.talent_assignments,
    ...talentPerformanceData?.talent_assignments
  ];

  return (
    <>
      <Row type="flex">
        {badgeCollection?.slice(0, 4).map((res, i) => {
          return <Badge key={i} data={res} />;
        })}
      </Row>
      {badgeCollection?.length > 0 ? (
        <ViewAll onClick={() => setOpen(true)}>Lihat Semua Lencana</ViewAll>
      ) : null}
      <Modal
        className="class-modal"
        visible={open}
        footer={null}
        closable={false}
        title={null}
        bodyStyle={{ padding: 0 }}
      >
        <ModalWrapper>
          <ModalHeader>
            <ModalTitle>{`Lencana ${nameUser.split(' ')[0]}`}</ModalTitle>
            <Icon
              type="close"
              style={{
                fontSize: '16px',
                color: '#a9a8a8',
                cursor: 'pointer'
              }}
              onClick={() => setOpen(false)}
            />
          </ModalHeader>
          <ModalContent>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Talenta" key="1">
                <DetailBadge data={talentBadgeData} />
              </TabPane>
              <TabPane tab="Kinerja" key="2">
                <DetailBadge data={talentPerformanceData} />
              </TabPane>
            </Tabs>
          </ModalContent>
        </ModalWrapper>
      </Modal>
    </>
  );
}
