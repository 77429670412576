import { message } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import { ModalDeleteWLBCheck } from '../..';
import { AuthenticationService } from '../../../Services';
import ModalEdit from '../../ModalEdit';
import AddEditAnotherContact from './AddEditAnotherContact';
import {
  DELETE_LIST_SOCIALS,
  GET_LIST_ANOTHER_CONTACT,
  SET_VISIBILITY_LIST_SOCIALS,
  SET_MAIN_CONTACT
} from './AnotherContact.graphql';
import ButtonAdd from './ButtonAdd';
import ListContact from './ListContact';

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

const grouppingData = data => {
  const newData = { ...data };
  const grouped = groupBy(
    newData.social_contacts,
    social => social.global_social_medium.name
  );
  return [
    grouped.get('Facebook'),
    grouped.get('Twitter'),
    grouped.get('Instagram'),
    grouped.get('LinkedIn'),
    grouped.get('TikTok')
  ].filter(function(el) {
    return el != null;
  });
};
export default function AnotherContact({
  open,
  handleClose,
  setOpen,
  stateOpen
}) {
  const { profileId } = useParams();
  const [openAction, setOpenAction] = useState({
    addEdit: false,
    data: null,
    actionType: null,
    modalDelete: false
  });

  const { data, error, refetch } = useQuery(GET_LIST_ANOTHER_CONTACT, {
    variables: {
      userId: profileId
    },
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const [deleteContact] = useMutation(DELETE_LIST_SOCIALS, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setVisibility] = useMutation(SET_VISIBILITY_LIST_SOCIALS, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [setMainContact] = useMutation(SET_MAIN_CONTACT, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  if (error) {
    message.error(`Error load contact, ${error}`);
  }

  const handleAddButtonClick = () => {
    setOpen({ ...stateOpen, anotherContact: false });
    setOpenAction({ ...openAction, addEdit: true, actionType: 'add' });
  };
  const handleClickAction = dataEdit => {
    setOpen({ ...stateOpen, anotherContact: false });
    setOpenAction({
      ...openAction,
      addEdit: true,
      actionType: 'edit',
      data: dataEdit
    });
  };
  const handleButtonCloseAddEdit = () => {
    setOpen({ ...stateOpen, anotherContact: true });
    setOpenAction({
      ...openAction,
      addEdit: false,
      actionType: null,
      data: null,
      modalDelete: false
    });
  };

  const handleSetModalDelete = cond => {
    setOpenAction({
      ...openAction,
      addEdit: cond === 'open' ? false : true,
      modalDelete: cond === 'open' ? true : false
    });
  };

  const handleVisibility = (id, isVisible) => {
    setVisibility({
      variables: {
        id,
        is_visible: !isVisible
      }
    })
      .then(() => {
        refetch();
        message.success(
          `${isVisible ? 'Tidak' : ''} Perlihatkan kontak sosial sukses`
        );
      })
      .catch(err => {
        message.error(
          `${isVisible ? 'Tidak' : ''} Perlihatkan kontak sosial gagal, ${err}`
        );
      });
  };

  const handleDelete = () => {
    deleteContact({
      variables: {
        id: openAction.data.id
      }
    })
      .then(() => {
        refetch();
        message.success(`Hapus kontak sosial sukses`);
        handleButtonCloseAddEdit();
      })
      .catch(err => {
        message.error(`Hapus kontak sosial gagal , ${err}`);
      });
  };

  const handleMainContact = (id, sosmedId) => {
    setMainContact({
      variables: {
        user_id: profileId,
        id,
        sosmedId
      }
    })
      .then(() => {
        refetch();
        message.success(`Tetapkan sebagai kontak sosial utama berhasil`);
      })
      .catch(err => {
        message.error(`Tetapkan sebagai kontak sosial utama gagal, ${err}`);
      });
  };

  const isUser = AuthenticationService.getUserId() === profileId;
  return (
    <>
      <ModalEdit
        title="Sosial"
        open={open}
        onClose={handleClose}
        sizeSmall
        padding="0px"
        centered
      >
        {data &&
          grouppingData(data).map((res, i) => {
            return (
              <ListContact
                key={`${i}`}
                data={res}
                onClickVisibility={handleVisibility}
                onClickEdit={handleClickAction}
                onClickSetMain={handleMainContact}
                isUser={isUser}
              />
            );
          })}
        {isUser && <ButtonAdd onClick={handleAddButtonClick} />}
      </ModalEdit>

      <AddEditAnotherContact
        refetch={refetch}
        setOpenAction={setOpenAction}
        openAction={openAction}
        data={openAction.data}
        open={openAction.addEdit}
        handleClose={handleButtonCloseAddEdit}
      />

      <ModalDeleteWLBCheck
        open={openAction.modalDelete}
        centered
        data={openAction.data}
        target="Kontak Sosial"
        onClose={() => handleSetModalDelete('close')}
        onDelete={handleDelete}
      />
    </>
  );
}
