import React from 'react';
import { ButtonFullWlb, ModalEdit } from '../../../../Components';
import DisciplinaryItem from './DisciplinaryItem';
import { WrapperScroll } from '../Award/Experience.style';
import { formatDate } from '../HelperDetailScreen';

export default function MoreDisciplinary({
  open,
  nameUser,
  setOpen,
  isUser,
  data
}) {
  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: true,
      actionType: 'edit',
      data
    }));
  };
  return (
    <ModalEdit
      centered
      padding="0px"
      title={`Kedisiplinan ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false, fromList: false }))}
    >
      <WrapperScroll>
        <div style={{ padding: '0px 30px' }}>
          {data?.map(res => {
            return (
              <DisciplinaryItem
                key={res.id}
                discipline_name={res.discipline_name || '-'}
                reference_number={res.reference_number || '-'}
                start_date={formatDate(res.start_date) || '-'}
                end_date={formatDate(res.end_date) || '-'}
                name_user={nameUser || '-'}
                discipline_letter_number={res.discipline_letter_number || '-'}
                reference_date={formatDate(res.reference_date) || '-'}
                remark={res.remark || '-'}
                attachments={isUser ? res.attachments || [] : []}
                onClickEdit={isUser ? () => handleEditItem(res) : null}
              />
            );
          })}
        </div>
      </WrapperScroll>
      {isUser && (
        <ButtonFullWlb
          label="Tambahkan Kedisiplinan"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
}
