import React from 'react';

import Discussion from './Discussion';
import Setting from './Setting';
import MemberScreen from './MemberScreen';

import PageWrapper from './PageWrapper.component';

const menuBarList = [
  {
    renderComponent: props => (
      <PageWrapper>
        <Discussion {...props} />
      </PageWrapper>
    ),
    name: 'Diskusi',
    path: '/group/:id/discussion'
  },
  // {
  //   renderComponent: props => <GroupPostId {...props} />,
  //   name: 'PostId',
  //   path: '/group/:id/discussion/:postId'
  // },
  {
    renderComponent: props => (
      <PageWrapper>
        <MemberScreen {...props} />,
      </PageWrapper>
    ),
    name: 'Anggota',
    path: '/group/:id/member'
  },
  {
    renderComponent: props => (
      <PageWrapper>
        <Setting {...props} />
      </PageWrapper>
    ),
    name: 'Pengaturan',
    path: '/group/:id/setting'
  }
];

const defaultMenuBar = 'Discussion';

export default {
  menuBarList,
  defaultMenuBar
};
