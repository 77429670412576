import { gql } from 'apollo-boost';

const GET_LIST_TASK = gql`
  query($user: uuid!, $offset: Int, $limit: Int, $search: String) {
    performance_tasks(
      where: {
        performance_task_assignments: { user: { _eq: $user } }
        visibility: { _eq: "PUBLIC" }
        name: { _ilike: $search }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      enddate
      progress
      target
      target_stretch
      status
      attachment
      performance_goal {
        name
        kpi_weight_unit {
          id
          name
        }
      }
      performance_group {
        id
        name
        color
      }
    }
  }
`;

export { GET_LIST_TASK };
