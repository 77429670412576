import React from 'react';
import { Button, Icon, Input } from 'antd';
import Assets from '../../Assets';

import {
  BottomPostBox,
  UploadFile,
  KudosPostCard,
  LocationPost
  // ClickOut
} from '../../Components';
import UploadImage from '../UploadImage';
import Constants from '../../Config/Constants';
import style, { PostBoxWrap } from './PostBox.style';
import { SpecificFollower, TagPeople } from '..';

const { TextArea } = Input;
const { TEXT_AREA_PLACEHOLDER } = Constants.MESSAGES.POST_BOX;

const { IMAGE, FILES } = Constants.UTILITIES;

const KudosPostBoxComponent = props => {
  const {
    post,
    handlePost,
    // handlePostType,
    handleFocusTextArea,
    handleUtility,
    utility,
    handleSubmit,
    isShowSpecificFollower,
    setIsShowModal,
    handleUpload,
    groupId,
    postType,
    setSelectPrivacy,
    visible,
    tagsUtility,
    setVisible,
    selectedKudos,
    setSelectedKudos,
    mainProfile,
    userId,
    locationUtility,
    setIsUploading,
    isUploading,
    setFocusPost
  } = props;

  const _renderTextArea = () => (
    <TextArea
      className="textArea"
      style={style.textArea}
      autoSize={{ minRows: 4 }}
      focus={'true'}
      onFocus={() => handleFocusTextArea(true)}
      value={post.content}
      onChange={e => handlePost('content', e.target.value)}
      placeholder={TEXT_AREA_PLACEHOLDER}
    />
  );

  const _renderImageUploadFile = () => (
    <UploadImage
      acceptedFormat=".png,.jpg,.jpeg"
      listType="picture-card"
      fileList={post.images}
      target="images"
      handleUpload={handleUpload}
      handleChange={handlePost}
      maxUpload={10}
      setIsUploading={setIsUploading}
    />
  );

  // const _renderVideoUploadFile = () => (
  //   <UploadFile
  //     acceptedFormat=".mp4,.avi,.3gp"
  //     handleUpload={handleUploadVideo}
  //     fileList={post.videos}
  //     target="videos"
  //     handleChange={handlePost}
  //   >
  //     <Button size="small" style={{ fontSize: 11 }}>
  //       <Icon type="upload" /> Upload
  //     </Button>
  //   </UploadFile>
  // );

  const _renderAttachmentUploadFile = () => (
    <UploadFile
      acceptedFormat=".doc,.docx,.xlsx,.xls,.ppt,.pptx,.txt,.pdf"
      fileList={post.files}
      target="files"
      handleUpload={handleUpload}
      handleChange={handlePost}
      setIsUploading={setIsUploading}
    >
      <Button size="small" style={{ fontSize: 11 }}>
        <Icon type="upload" /> Upload
      </Button>
    </UploadFile>
  );
  const _renderBottomPostBox = () => (
    <BottomPostBox
      disabeledSomeEl={true}
      post={post}
      handlePost={handlePost}
      handleUtility={handleUtility}
      handleSubmit={handleSubmit}
      setIsShowModal={setIsShowModal}
      postType={'global'}
      setSelectPrivacy={setSelectPrivacy}
      isUploading={isUploading}
    />
  );

  const _renderSpecificFollower = () => (
    <SpecificFollower
      isShowSpecificFollower={isShowSpecificFollower}
      canSee={post.canSee}
      handlePost={handlePost}
      setIsShowModal={setIsShowModal}
      setFocusPost={setFocusPost}
    />
  );

  const _renderTagPeople = () => (
    <TagPeople
      groupId={groupId}
      postType={postType}
      handlePost={handlePost}
      defaultValue={post?.tags}
      forKudos={true}
      userId={userId}
    />
  );
  const _renderLocationPost = () => (
    <LocationPost handlePost={handlePost} handleUtility={handleUtility} />
  );

  return (
    <PostBoxWrap visible={visible}>
      <div className="ant-modal-wrap">
        <div className="component_wrapper">
          <div className="component_title">
            <img src={Assets.ICON.SHARE_KUDOS} alt={'Share'} />
            Share on My Timeline
            <span
              className="component_close"
              onClick={setVisible ? () => setVisible(false) : null}
            >
              <Icon type="close" />
            </span>
          </div>
          <div className="component_content">
            {_renderSpecificFollower()}
            {_renderTextArea()}
            <KudosPostCard
              img={selectedKudos?.url_image}
              kudosName={selectedKudos?.name}
              name={mainProfile?.name}
              description={selectedKudos?.desc}
            />
            {utility === IMAGE ? _renderImageUploadFile() : null}
            {/* {utility === VIDEO ? _renderVideoUploadFile() : null} */}
            {utility === FILES ? _renderAttachmentUploadFile() : null}
            {tagsUtility ? _renderTagPeople() : null}
            {locationUtility && _renderLocationPost()}
            {/* <span hidden={utility !== TAGS}>{_renderTagPeople()}</span> */}
            {props.children ? props.children : null}
            {_renderBottomPostBox()}
          </div>
        </div>
      </div>
      <div
        className="ant-modal-mask"
        onClick={
          setVisible
            ? () => {
                setVisible(false);
                setSelectedKudos(null);
              }
            : null
        }
      ></div>
    </PostBoxWrap>
  );
};

export default KudosPostBoxComponent;
