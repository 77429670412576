import React, { useEffect } from 'react';
import { ModalEdit } from '../../Components';
import { TitleField } from './UserEmergency.style';
import { Input, Select, Form, message } from 'antd';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import {
  GET_LIST_RELATIONSHIP,
  UPDATE_EMERGENCY_CONTACT,
  ADD_EMERGENCY_CONTACT
} from './UserEmergencyContact.graphql';
import {
  ButtonFrom,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PHONE_CODE_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  SearchableSelect,
  globalCitiesSeachableVars
} from '../../SharedComponents';
import { AuthenticationService } from '../../Services';

const rule = isRequired => {
  return [
    {
      required: isRequired,
      message: 'Bagian ini diperlukan'
    }
  ];
};

const EditEmergencyContact = props => {
  const { open, onClose, data, refetch, actionType, setOpen } = props;
  const {
    getFieldDecorator,
    validateFields,
    resetFields,
    getFieldValue,
    setFieldsValue
  } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;

  const [
    getListGlobalPhoneCode,
    { data: phone_code, loading: loadingPhone }
  ] = useLazyQuery(GLOBAL_PHONE_CODE_SEARCHABLE);
  const { data: dataRelationship } = useQuery(GET_LIST_RELATIONSHIP, {
    context: {
      headers: {
        'X-Hasura-Role': 'public'
      }
    }
  });

  const [getListCity, { data: dataCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );
  const [
    getListProvince,
    { data: dataProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);

  useEffect(() => {
    if (open) {
      const { province } = data?.emergency_contact_fields || {};
      getListProvince({
        variables: {
          where: {
            deletedAt: { _is_null: true }
          }
        }
      });
      getListCity({
        variables: {
          limit: actionType === 'add' ? 20 : null,
          ...globalCitiesSeachableVars({
            province: province && actionType === 'edit' ? province : undefined
          })
        }
      });
      getListGlobalPhoneCode({
        variables: {
          where: {
            code:
              actionType === 'edit' && data?.country_code
                ? { _eq: data?.country_code }
                : undefined
          },
          limit: actionType === 'add' ? 20 : null
        }
      });
      if (actionType === 'edit') {
        setFieldsValue({
          province: data?.emergency_contact_fields?.province,
          city: data?.emergency_contact_fields?.city,
          country_code: data?.country_code
        });
      } else {
        setFieldsValue({
          province: undefined,
          city: undefined,
          country_code: undefined
        });
      }
    }
  }, [
    data,
    open,
    getListCity,
    getListProvince,
    actionType,
    getListGlobalPhoneCode,
    setFieldsValue
  ]);

  const [updateEmergencyContact] = useMutation(UPDATE_EMERGENCY_CONTACT, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });
  const [insertEmergencyContact] = useMutation(ADD_EMERGENCY_CONTACT, {
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });

  const listFields = [
    {
      labelName: 'Nama',
      fieldName: 'name',
      type: 'text',
      fieldValue: data?.name,
      required: true,
      placeholder: 'Tambahkan nama'
    },
    {
      labelName: 'Hubungan',
      fieldName: 'relationship',
      type: 'select',
      fieldValue: data?.relationship,
      required: false,
      option: dataRelationship?.people_dependant_relationships ?? [],
      placeholder: 'Tambahkan hubungan'
    }
  ];
  const listFields2 = [
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'select',
      fieldValue: data?.emergency_contact_fields?.country,
      required: true,
      placeholder: 'Pilih negara',
      option: [{ id: 'Indonesia', name: 'Indonesia' }]
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      required: true,
      placeholder: 'Pilih provinsi',
      option: dataProvince?.global_provinces ?? [],
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getListProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getListProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          city: undefined,
          province: e
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      fieldValue: getFieldValue('city'),
      required: true,
      placeholder: 'Pilih kota',
      option: dataCity?.global_cities ?? [],
      loading: loadingCity,
      onSearch: ({ value }) => {
        getListCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getListCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      }
    },
    {
      labelName: 'Kecamatan',
      fieldName: 'district',
      type: 'text',
      fieldValue: data?.emergency_contact_fields?.district,
      required: true,
      placeholder: 'Tambahkan kecamatan'
    },
    {
      labelName: 'Kelurahan',
      fieldName: 'sub_district',
      type: 'text',
      fieldValue: data?.emergency_contact_fields?.sub_district,
      required: false,
      placeholder: 'Tambahkan kelurahan'
    },
    {
      labelName: 'Kode Pos',
      fieldName: 'postal_code',
      type: 'number',
      fieldValue: data?.emergency_contact_fields?.postal_code,
      required: true,
      placeholder: 'Tambahkan kode pos'
    },
    {
      labelName: 'Alamat',
      fieldName: 'address',
      type: 'textArea',
      fieldValue: data?.address,
      required: true,
      placeholder: 'Tambahkan alamat'
    }
  ];

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        if (actionType === 'edit') {
          const submit = {
            id: data.id,
            user: AuthenticationService.getUserId(),
            name: values.name,
            relationship: values.relationship,
            countryCode: values.country_code,
            phone: values.phone,
            emergencyContactFields: {
              country: values.country,
              province: values.province,
              city: values.city,
              district: values.district,
              sub_district: values.sub_district,
              postal_code: values.postal_code
            },
            address: values.address
          };
          updateEmergencyContact({
            variables: submit
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil mengubah kontak darurat');
            })
            .catch(err => {
              message.error(
                `Gagal mengubah kontak darurat, silakan coba lagi nanti, ${err}`
              );
            });
        } else {
          const submit = {
            objects: {
              // user: AuthenticationService.getUserId(),
              name: values.name,
              relationship: values.relationship,
              country_code: values.country_code,
              phone: values.phone,
              emergency_contact_fields: {
                country: values.country,
                province: values.province,
                city: values.city,
                district: values.district,
                sub_district: values.sub_district,
                postal_code: values.postal_code
              },
              address: values.address
            }
          };
          insertEmergencyContact({
            variables: submit
          })
            .then(() => {
              refetch();
              handleClose();
              message.success('Berhasil menambahkan kontak darurat');
            })
            .catch(err => {
              message.error(
                `Gagal menambahkan kontak darurat, silakan coba lagi nanti, ${err}`
              );
            });
        }
      }
    });
  };

  function handleChange(value, fieldName) {
    if (fieldName === 'province') {
      setFieldsValue({
        city: undefined
      });
      getListCity({
        variables: {
          ...globalCitiesSeachableVars({ province: value })
        }
      });
    }
    // event.preventDefault();
  }

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      style,
      placeholder,
      fieldValue,
      loading,
      onSearch,
      onTouch,
      onChange
    } = res;

    if (type === 'select') {
      return (
        <Select
          style={style ?? { width: '100%', textTransform: 'capitalize' }}
          size="large"
          onChange={value => handleChange(value, fieldName)}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option
                  key={i}
                  value={fieldName === 'relationship' ? res.name : res.id}
                  style={{ textTransform: 'capitalize' }}
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          placeholder={placeholder}
          type={type}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    } else if (type === 'textArea') {
      return (
        <TextArea
          rows={6}
          placeholder={placeholder}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const idValue = getFieldValue('phone');
  const rulesMax = {
    required: true,
    max: 25,
    message:
      idValue && idValue.length > 24
        ? 'Anda telah melampaui batas karakter maksimum'
        : 'Bagian ini diperlukan'
  };
  const rulesStanddard = {
    required: true,
    message: 'Bagian ini diperlukan'
  };

  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Kontak Darurat`}
      open={open}
      onClose={handleClose}
      onClickDelete={
        actionType === 'edit'
          ? () => setOpen(e => ({ ...e, modalDelete: true, open: false }))
          : null
      }
    >
      <Form onSubmit={handleSubmit}>
        {listFields.map((res, i) => {
          return (
            <Form.Item
              key={i}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: [res.fieldName === 'phone' ? rulesMax : rulesStanddard]
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Nomor Telepon</TitleField>}
            style={{ marginBottom: 0 }}
            required
          >
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '25%' }}
              required
              disabled
            >
              {getFieldDecorator('country_code', {
                initialValue: data?.country_code,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <SearchableSelect
                  fieldValue={getFieldValue('country_code')}
                  placeholder={'Kode Negara'}
                  style={{ width: '90%' }}
                  loading={loadingPhone}
                  withEmptyIcon={false}
                  option={
                    phone_code?.global_phone_code?.map(({ code, country }) => ({
                      id: code,
                      name: `(${code}) ${country}`
                    })) || []
                  }
                  onSearch={({ value }) => {
                    getListGlobalPhoneCode({
                      variables: {
                        limit: 20,
                        where: {
                          _or: value
                            ? [
                                { country: { _ilike: `%${value}%` } },
                                { code: { _ilike: `%${value}%` } }
                              ]
                            : undefined
                        }
                      }
                    });
                  }}
                  fieldName={'country_code'}
                  onTouch={({ isOpen }) => {
                    if (isOpen) {
                      getListGlobalPhoneCode({
                        variables: {
                          limit: 20
                        }
                      });
                    }
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              style={{ display: 'inline-block', marginBottom: 0, width: '75%' }}
              required
            >
              {getFieldDecorator('phone', {
                initialValue: actionType === 'edit' ? data.phone : null,
                rules: [
                  {
                    required: true,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(
                <Input
                  placeholder="Tambahkan nomor telepon"
                  type="number"
                  onChange={value => handleChange(value, 'phone')}
                  style={{ width: '100%' }}
                ></Input>
              )}
            </Form.Item>
          </Form.Item>
        </div>
        {listFields2.map((res, i) => {
          return (
            <Form.Item
              key={`${i}`}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules:
                  res.fieldName === 'phone' ? [rulesMax] : rule(res.required)
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const EditEmergencyContactContainer = Form.create({
  name: 'Edit_Emergency_Contact'
})(EditEmergencyContact);

export default EditEmergencyContactContainer;
