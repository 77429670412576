import PropTypes from 'prop-types';

const propTypes = {
  status: PropTypes.string.isRequired
}

const defaultProps = {};

export default {
  propTypes,
  defaultProps
};
