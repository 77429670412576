import { gql } from 'apollo-boost';

export const GET_MAIN_REVIEW = gql`
  query($classroomId: String!) {
    classroomGetStars(classroomId: $classroomId) {
      stars {
        one
        two
        three
        four
        five
      }
      average
      count
      classroom {
        id
        name
      }
    }
  }
`;
