import styled from 'styled-components';
import Assets from '../../../Assets';
import Constants from '../../../Config/Constants';

export const AkhlakWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 12px 0;
  width: 33%;
  .title {
    margin: 6px 0 0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: #000;
  }
  .p4 {
    padding: 0px 4px;
  }
`;

export const style = {
  container: {
    marginBottom: 11.2,
    padding: 12.3,
    width: '100%',
    borderRadius: 2.5,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    backgroundColor: Constants.COLORS.WHITE
  },
  headerTitle: {
    fontSize: 16,
    marginBottom: 3.2,
    fontFamily: Assets.FONT.MULI,
    fontWeight: 600,
    color: 'black'
  },
  headerIcon: {
    width: 25,
    marginRight: 20
  },
  hr: {
    marginTop: 10,
    height: 1,
    backgroundColor: Constants.COLORS.LIGHT_GREY,
    border: 'none'
  },
  wrapperMenu: {},
  colImage: {
    marginTop: 10,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  divImage: {
    marginTop: 4,
    marginBottom: 2,
    width: 36,
    height: 36,
    backgroundColor: Constants.COLORS.DARK_BLUE,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center'
  },
  iconQuick: {
    height: 18,
    width: 18,
    margin: 'auto'
  },
  menuTitle: {
    textAlign: 'center',
    fontSize: 12,
    margin: '4px 0',
    fontFamily: Assets.FONT.MULI,
    color: '#000'
  },
  moreIcon: {
    fontSize: 15,
    color: Constants.COLORS.DARK_BLUE
  },
  colMore: {
    marginTop: 4,
    marginBottom: 2,
    width: 36,
    height: 36,
    border: '1px solid',
    borderColor: Constants.COLORS.DARK_BLUE,
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
