import styled from 'styled-components';

const Follower = styled.div`
  display: flex;
  flex-direction: row;
  height: 65px;
  align-items: center;
  border-bottom: #f7f8f9 2px solid;
  font-family: 'Muli-SemiBold';

  .ant-btn-default {
    border-radius: 10%;
    background-color: #014a62;
    font-size: 11px;
    color: #ffffff;
    margin-left: 10px;
  }

  .ant-btn-primary {
    border-radius: 2.5px black;
    font-size: 11px;
    color: black;
    background-color: #ffffff;
    margin-left: 10px;
    border-color: grey;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  justify-content: space-between;
  color: #014a62;
  font-family: 'Muli-SemiBold';
  font-size: 12px;

  a {
    color: #014a62;
  }
`;

const FollowerInformation = styled.div`
  display: flex;
  width: 161px;
  font-size: 12px;
  padding-left: 3px;

  a {
    padding-right: 3px;
  }
`;

export { Follower, Actions, FollowerInformation };
