import { Button, Form } from 'antd';
import React from 'react';
import { WrapBtn } from './ButtonForm.style';

export const ButtonFrom = ({
  isDeleteOnBottom = false,
  actionType,
  onDelete,
  deleteLabel,
  onCancel,
  disabled = false,
  cancelLabel = 'Batalkan',
  submitLabel = 'Submit',
  noDivider = false,
  buttonSize = 'small'
}) => {
  return (
    <Form.Item
      style={{
        marginBottom: 0,
        padding: '20px 22px 0px 22px',
        borderTop: noDivider ? 'none' : '0.5px solid #d0d4d4',
        marginTop: noDivider ? 0 : 22
      }}
    >
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {isDeleteOnBottom && actionType === 'edit' && (
            <p
              onClick={onDelete}
              style={{ color: '#ef4d5e', cursor: 'pointer', margin: 0 }}
            >
              {deleteLabel}
            </p>
          )}
          <WrapBtn>
            <Button
              className="btn-wlb-cancel"
              type="link"
              onClick={onCancel}
              style={{ marginRight: 10 }}
            >
              {cancelLabel}
            </Button>
            <Button
              className={`btn-wlb-${buttonSize}`}
              type="primary"
              htmlType="submit"
              disabled={disabled}
            >
              {submitLabel}
            </Button>
          </WrapBtn>
        </div>
      </div>
    </Form.Item>
  );
};
