import React from 'react';

import config from './StepsPage.config';
import {Enhancer} from '../../SharedComponents';

const StepsPageContainer = () => (<div />);

export default Enhancer({
  isShowMenubar: false,
  isShowSteps: true,
  menuStepsList: config.menuStepsList,
  defaultMenuSteps: config.defaultMenuSteps
})(StepsPageContainer);
