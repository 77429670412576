import styled from 'styled-components';
import Constants from '../../Config/Constants';

const LocationWrap = styled.div`
  /* background: red;
  width: 100%;
  height: 200px; */
  margin: 8px 0;
  .location-search-input {
    border: 1px ${Constants.COLORS.LIGHT_GREY} solid;
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 11px;
    border-radius: 5px;
    outline: none;
    /* background: pink; */
  }
  .autocomplete-container {
    border-bottom: black;
    border-left: black;
    border-right: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 2px 2px;
  }
  .suggestion-item {
    padding: 8px;
    font-size: 11px;
    text-align: left;
    cursor: pointer;
  }

  .suggestion-item--active {
    background-color: #fafafa;
    font-size: 11px;
    padding: 8px;
    text-align: left;
    cursor: pointer;
  }
`;

const MapWrap = styled.div`
  width: 100%;
  height: 200px;
  display: block;
  margin: 10px 0;
  position: relative;
  .marker {
    /* display: none; */
    position: absolute;
    left: 50%;
    top: 50%;
  }
  .close-map {
    position: absolute;
    right: 4%;
    top: 4%;
    cursor: pointer;
    svg {
      width: 25;
      height: 25;
      color: grey;
    }
  }
`;
const AddressDesc = styled.div`
  font-size: 12px;
  margin: 10px 0;
  /* width: 100%;
  height: 200px;
  display: block;
  margin: 10px 0; */
`;

export { LocationWrap, MapWrap, AddressDesc };
