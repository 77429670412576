import { gql } from 'apollo-boost';

export const GET_COMPANY_ADDRESSES = gql`
  query($id: uuid!, $search: String, $offset: Int, $limit: Int) {
    office_address: company_addresses(
      where: {
        company: { _eq: $id }
        status: { _eq: "ACTIVE" }
        deletedAt: { _is_null: true }
        _or: [{ office_name: { _ilike: $search } }]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      office_name
      global_province {
        id
        name
      }
      global_city {
        id
        name
      }
      address
      address_field
      postal_code
      phone
    }
  }
`;

export const GET_COMPANY_MAIN_ADDRESS = gql`
  query($id: uuid!) {
    profiles: company_profiles(where: { id: { _eq: $id } }) {
      id
      address: company_address {
        id
        office_name
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
        address
        address_field
        postal_code
        phone
      }
    }
  }
`;
