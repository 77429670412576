import { Col, Row } from 'antd';
import React from 'react';
import IMAGES from '../../../Assets/images';
import { useGlobalState } from '../../../globalState';
import config from './QuickAccess.config';
import style from './QuickAccess.style';

const QuickAccessComponent = ({ withHeader = true }) => {
  const [data] = useGlobalState('modular');
  const _renderHeader = () => (
    <div>
      <Row style={{ position: 'static', zIndex: -1 }}>
        <Col span={3}>
          <img
            src={IMAGES.QUICK_ACCESS.LOGO}
            alt="HC Ecosystem"
            style={style.headerIcon}
          />
        </Col>
        <Col span={21}>
          <span style={style.headerTitle}>Akses Cepat</span>
        </Col>
      </Row>

      <hr style={style.hr} />
    </div>
  );

  // const _renderMoreColumn = () => (
  //   <Col span={8} style={style.colImage}>
  //     <div style={style.colMore}>
  //       <Icon type="appstore" theme="filled" style={style.moreIcon} />
  //     </div>
  //     <span style={style.menuTitle}>More</span>
  //   </Col>
  // );

  const _renderListMenu = () => {
    return config.listMenu(data).map(
      menu =>
        !menu.hidden && (
          <Col
            key={menu.title}
            span={8}
            style={style.colImage}
            onClick={() => window.open(menu.url)}
          >
            <div style={style.divImage}>
              <img src={menu.image} alt={'title'} style={style.iconQuick} />
            </div>
            <span style={style.menuTitle}>{menu.title}</span>
          </Col>
        )
    );
  };

  const _renderBody = () => (
    <Row type="flex" style={style.wrapperMenu}>
      {_renderListMenu()}
      {/* more di hide dulu, belum fix dari ui dan probis nya */}
      {/* {_renderMoreColumn()} */}
    </Row>
  );

  return (
    <div style={withHeader ? style.container : {}}>
      {withHeader && _renderHeader()}
      {_renderBody()}
    </div>
  );
};

export default QuickAccessComponent;
