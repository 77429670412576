import React, { useState } from 'react';
import CommentListComponent from './component';
import { AuthenticationService } from '../../Services';
import { useGetProfile } from '../../SharedComponents/CustomHook';

const MainContainer = props => {
  const { commentList, handleDelete, handleSubmit } = props;
  const [content, setContent] = useState('');
  const profile = AuthenticationService.getUserData();
  const [nameUser, profileUser] = useGetProfile()

  const _handleDelete = async comment => {
    await handleDelete(comment);
  };

  const _handleSubmit = async () => {
    const contentTrimmed = content.trim();
    if (contentTrimmed !== '') {
      await handleSubmit(contentTrimmed);
    }
    setContent('');
  };

  return (
    <CommentListComponent
      {...props}
      avatar={profileUser}
      nameUser={nameUser}
      commentList={commentList}
      handleDelete={_handleDelete}
      profile={profile}
      contentInput={content}
      setContent={setContent}
      handleSubmit={_handleSubmit}
    />
  );
};

export default MainContainer;
