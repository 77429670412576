import React, { useState } from 'react';
import DetailScreenComponent from './DetailScreen.component';
import { InformationServices } from '../../../Services/Vendor';
import { useGlobalState } from '../../../globalState';
import { VendorListBoxWrap } from '../../../Containers/VendorListBox/VendorListBox.style';
import { AuthenticationService } from '../../../Services';

const DetailScreenContainer = props => {
  const [activeMenu, setActiveMenu] = useGlobalState('vendorPath');
  const [orderDetail, setOrderDetail] = useState(false);
  const companyId = props.match.params.id;

  const dataVendorInformation = InformationServices.GetInformationServices(
    companyId
  );

  const role = AuthenticationService.getUserData().roles;
  if (!role.includes('organization-administrator')) {
    return <VendorListBoxWrap>No Access</VendorListBoxWrap>;
  }
  return (
    <DetailScreenComponent
      companyId={companyId}
      activeMenu={activeMenu}
      setActiveMenu={setActiveMenu}
      orderDetail={orderDetail}
      setOrderDetail={setOrderDetail}
      dataVendorInformation={dataVendorInformation}
      {...props}
    />
  );
};

export default DetailScreenContainer;
