import React from 'react';
import Collapse from 'antd/es/collapse';
// import { Select } from 'antd';
// import Constants from '../../../Config/Constants';
// import Switch from 'antd/es/switch';
// import InformationSetting from './InformationSetting.component';
import FollowInformation from './FollowInformation.component';

// const privacy = Constants.PRIVACY;
// const { Option } = Select;
const { Panel } = Collapse;

const ProfileInformationComponent = props => {
  // const { listProfileSetting } = props;
  // const [privateAccountStatus, setPrivateAccountStatus] = useState(false);

  // const dropdown = () => (
  //   <Select style={{ width: 160 }} defaultValue={'Public'}>
  //     {privacy.map(p => (
  //       <Option key={p.VALUE} value={p.TEXT}>
  //         <img src={p.IMAGE} alt="option" /> {p.TEXT}
  //       </Option>
  //     ))}
  //   </Select>
  // );
  return (
    <>
      <Collapse
        defaultActiveKey={'0'}
        expandIconPosition={'right'}
        className={'component_collapse'}
        accordion
      >
        {/* <Panel header="Account Privacy" key="0">
          <div className="component_profile_information_panel">
            <section>
              <h4>Private Account</h4>
              <p>
                When your account is private, only people you approve can see
                your activity and profile. Your existing followers won&apos;t be
                affected
              </p>
            </section>
            <div className={'widget-toggle'}>
              <div className={'widget-status'}>
                {privateAccountStatus ? 'On' : 'Off'}
              </div>
              <Switch
                checked={privateAccountStatus}
                onChange={val => setPrivateAccountStatus(val)}
              />
            </div>
          </div>
        </Panel> */}
        <Panel header="Siapa yang dapat melihat pengikut saya" key="1">
          <FollowInformation />
        </Panel>
        {/* <Panel header="Who can see my contact" key="2">
          <p>
            Choose who can see your contact information such as email, Facebook,
            Linkedin, and Twitter Information
          </p>
          {dropdown()}
        </Panel> */}
        {/* <Panel header="Who can see my profile information" key="3">
          {listProfileSetting?.global_people_profile_setting_sections.map(
            setting => (
              <InformationSetting
                key={setting.id}
                setting={setting}
                {...props}
              />
            )
          )}
        </Panel> */}
      </Collapse>
    </>
  );
};

export default ProfileInformationComponent;
