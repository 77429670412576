import Image from '../Assets/images';

const DELAY_TIME = {
  DEBOUNCE_SEARCH: 500
};

const POST_TYPE = {
  TEAM: 'team',
  COMPANY: 'company',
  COMPANY_TIMELINE: 'companyTimeline',
  GLOBAL_TIMELINE: 'global',
  PROFILE: 'profile',
  CLASSROOM: 'classroom'
};

const POP_OVER_PROFILE_MENU = [
  { label: 'Pengaturan Akun', link: '/account-settings' },
  { label: 'Administrator', link: process.env.REACT_APP_HC_ADMIN },
  {
    label: 'Company Group',
    link: process.env.REACT_APP_HC_HOLDING,
    role: 'holding-administrator'
  },
  { label: 'Manage Vendor', link: '/vendorList' },
  { label: 'Help', link: 'https://crm.wlb.co.id/forms/ticket' }
];

const PRIVACY = [
  {
    TEXT: 'Publik',
    DESCRIPTION: 'Semua orang bisa melihat',
    IMAGE: Image.PRIVACY_PUBLIC,
    VALUE: 0
  },
  {
    TEXT: 'Pengikut',
    DESCRIPTION: 'Hanya pengikut yang bisa melihat',
    IMAGE: Image.PRIVACY_FOLLOWER,
    VALUE: 1
  },
  {
    TEXT: 'Mengikuti Pengikut',
    DESCRIPTION: 'Hanya Pengikut yang diikuti bisa melihat',
    IMAGE: Image.PRIVACY_FOLLOWING_FOLLOWER,
    VALUE: 2
  },
  {
    TEXT: 'Pengikut Khusus',
    DESCRIPTION: 'Belum dipilih',
    IMAGE: Image.PRIVACY_SPECIFIC_FOLLOWER,
    VALUE: 4
  },
  {
    TEXT: 'Hanya Saya',
    DESCRIPTION: 'Hanya saya yang bisa melihat',
    IMAGE: Image.PRIVACY_ONLY_ME,
    VALUE: 3
  }
];

const COMPANY_PRIVACY = [
  {
    TEXT: 'Company',
    DESCRIPTION: 'Anyone on company can see',
    IMAGE: Image.PRIVACY_PUBLIC,
    VALUE: 0
  }
  // {
  //   TEXT: 'Specific Follower',
  //   DESCRIPTION: 'Not Selected Yet',
  //   IMAGE: Image.PRIVACY_SPECIFIC_FOLLOWER,
  //   VALUE: 4
  // }
];

const SIDEBAR_VENDOR = {
  DASHBOARD: 'Dashboard',
  ORDER: 'Order',
  PRODUCT: 'Product',
  // STATISTIC: 'Statistic',
  INFORMATION: 'Information',
  SHOWCASE: 'Showcase',
  LOCATION: 'Location',
  SHIPPING: 'Shipping',
  BANK_ACCOUNT: 'Bank Account',
  BALANCE: 'Balance'
};

const GRAPHQL_HEADER_OPTIONS = role => {
  return { context: { headers: { 'X-Hasura-Role': role } } };
};

const USER_ROLE = {
  ORG_ADMIN: 'organization-administrator',
  PUBLIC: 'public',
  USER: 'user'
};

export default {
  GRAPHQL_HEADER_OPTIONS,
  USER_ROLE,
  PRIVACY,
  COMPANY_PRIVACY,

  POP_OVER_PROFILE_MENU,
  UTILITIES: {
    IMAGE: 'images',
    VIDEO: 'videos',
    TAGS: 'tags',
    FILES: 'files',
    LOCATION: 'Location',
    HASHTAG: 'Hashtag'
  },
  COLORS: {
    WHITE: '#fff',
    LIGHT_BLUE: '#039be5',
    GREY: '#a9a8a8',
    DARK_GREY: '#f7f8f9',
    LIGHT_GREY: '#e5e5e5',
    BLACK: '#000000',
    BLUE: '#007fb2',
    DARK_BLUE: '#014a62',
    SHADOW: 'rgba(0, 0, 0, 0.16)'
  },
  BOX_SHADOW: '0px 1.5px 3px 0px rgba(0, 0, 0, 0.16)',
  MESSAGES: {
    POST_BOX: {
      TITLE: {
        POST: 'Postingan',
        EVENT: 'Acara',
        TASK: 'Task',
        TRACKER: 'Catatan Pembelajaran',
        POLLING: 'Polling'
      },
      VALUE: {
        POST: 0,
        EVENT: 1,
        TASK: 2,
        TRACKER: 3,
        POLLING: 4
      },
      TEXT_AREA_PLACEHOLDER: 'Tuliskan Sesuatu...',
      SUBMIT_BUTTON: 'Kirimkan'
    },
    COMMENT_BOX: {
      INPUT_PLACEHOLDER: 'Tambahkan komentar',
      SUBMIT_BUTTON: 'Kirim'
    }
  },
  SIDEBAR_VENDOR,
  DELAY_TIME,
  POST_TYPE
};

// learning
// dev : learning.worklifebeyond.com/learning_course/${slug}
// staging : learning.worklife-beyond.com/learning_course/${slug}

//brand_name

//get user role public
