import React from 'react';
import WidgetProfilWrapper from './WidgetPeopleProfile.component';
import { Badges, OnGoingFeedback } from '../../Components';
import { GET_BADGE } from './WidgetPeopleProfile.graphql';
import { useQuery } from 'react-apollo';
import { Skeleton } from 'antd';

const WidgetPeopleProfileContainer = props => {
  const { profileId } = props.match.params;
  const { data: talentBadgeData, error: talentBadgeError } = useQuery(
    GET_BADGE,
    {
      variables: {
        user: profileId,
        type: 'TALENT'
      },
      context: {
        headers: { 'X-Hasura-Role': 'organization-staff' }
      }
    }
  );

  const {
    data: talentPerformanceData,
    error: talentPerformanceError
  } = useQuery(GET_BADGE, {
    variables: {
      user: profileId,
      type: 'PERFORMANCE'
    },
    context: {
      headers: { 'X-Hasura-Role': 'organization-staff' }
    }
  });

  if (talentBadgeError || talentPerformanceError) {
    return console.error(
      'Get talent badge data error: ' + talentBadgeError,
      'Get talent performance data error: ' + talentPerformanceError
    );
  }

  return (
    <div>
      <WidgetProfilWrapper title="Lencana">
        {talentBadgeData && talentPerformanceData ? (
          <Badges
            nameUser={props.nameUser}
            talentBadgeData={talentBadgeData}
            talentPerformanceData={talentPerformanceData}
          />
        ) : (
          <Skeleton active />
        )}
      </WidgetProfilWrapper>
      <WidgetProfilWrapper title="Umpan Balik Berkelanjutan">
        <OnGoingFeedback profileId={profileId} />
      </WidgetProfilWrapper>
    </div>
  );
};

export default WidgetPeopleProfileContainer;
