import gql from 'graphql-tag';

const DELETE_LOCATION = gql`
  mutation updateLocationVendor($deleted_at: timestamptz!, $id: Int!) {
    update_company_origin_shipping_addresses(
      _set: { deleted_at: $deleted_at }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

const GET_LIST_LOCATION = gql`
  query getOriginShipping($company: uuid!, $limit: Int!, $offset: Int!) {
    total: company_origin_shipping_addresses_aggregate(
      where: { company: { _eq: $company } }
    ) {
      aggregate {
        count
      }
    }
    company_origin_shipping_addresses(
      where: { company: { _eq: $company } }
      limit: $limit
      offset: $offset
    ) {
      id
      label_name
      address
      province_code
      province_name
      city_code
      city_name
      district_code
      district_name
      post_code
      status
    }
  }
`;

export { DELETE_LOCATION, GET_LIST_LOCATION };
