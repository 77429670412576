import styled from 'styled-components';
import Assets from '../../Assets';

export const StatusWrap = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Status = styled.div`
  background-color: ${props => (props.active ? '#039be5' : '#e5e5e5')};
  width: 20px;
  height: 20px;
  margin-left: 8px;
  padding: 3px;
  border-radius: 3px;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  .tooltip {
    color: #039be5;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.24);
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    font-family: ${Assets.FONT.MULI};
    font-size: 14px;
    pointer-events: none;
    white-space: nowrap;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 3px;
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    z-index: 2;
  }

  &:hover .tooltip {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .icon {
    color: #fff;
    position: relative;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 14px;
    min-height: 14px;

    &.payment {
      background-image: url(${Assets.ICON.MONEY_DOLLAR});
    }
    &.review {
      background-image: url(${Assets.ICON.EYE});
      top: 2px;
    }
    &.packing {
      background-image: url(${Assets.ICON.GIFT_CARD});
    }
    &.shipping {
      background-image: url(${Assets.ICON.SHIPPING});
      top: 2px;
    }
    &.completed {
      background-image: url(${Assets.ICON.COMPLETED});
      top: 2px;
    }

    &.rejected {
      background-image: url(${Assets.ICON.CANCEL_WHITE});
    }
    &.refunded {
      background-image: url(${Assets.ICON.RESTART_ALT_WHITE});
    }
  }
`;
