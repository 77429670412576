import { gql } from 'apollo-boost';

export const GET_COMPANY = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      id
      legal_name
      brand_name
      logo
    }
  }
`;
