import React from "react";
import { TextSecondary, TextStandard, TextGrey } from "../UserProfileCV.style";

export default function Speaker({title, company, speaker, location, participant, desc,}) {
  return (
    <div style={{ marginBottom: 35 }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextSecondary>{company}</TextSecondary>
      <TextGrey>{speaker}</TextGrey>
      <TextGrey>{location}</TextGrey>
      <TextGrey>{participant}</TextGrey>
      <TextStandard>{desc}</TextStandard>
    </div>
  );
}
