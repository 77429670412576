import gql from 'graphql-tag';

export const GET_PAYMENT_GUIDE = gql`
  query($bankId: Int!) {
    bank: global_banks_by_pk(id: $bankId) {
      id
      name
      logo
      bank_fields
      guides: global_bank_payment_guides {
        id
        via
        instructions
        group_type
        name
        code_name
      }
    }
  }
`;

export const ATTACH_REFUND_PROOF = gql`
  mutation($files: [marketplace_refund_request_files_insert_input!]!) {
    insert_marketplace_refund_request_files(objects: $files) {
      affected_rows
    }
  }
`;
