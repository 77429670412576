import Axios from 'axios';
import Assets from '../../../Assets';

export const getFileType = url => {
  if (!url) {
    return null;
  }

  const idx = url.lastIndexOf('.');

  if (idx === -1) {
    return null;
  }

  const lurl = url.toLowerCase();
  const ext = lurl.slice(idx + 1);

  switch (ext) {
    case 'pdf':
      return 'pdf';
    case 'docx':
    case 'doc':
      return 'doc';
    case 'xlsx':
    case 'xls':
      return 'xls';
    case 'pptx':
    case 'ppt':
      return 'ppt';
    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
    case 'img':
      return 'img';
  }

  // NOTE(intrnl): for handling future cases with multiple dot extensions
  // e.g. .tar.gz archives

  // idx = lurl.lastIndexOf('.', idx - 1)

  // if (idx === -1) {
  //   return null
  // }

  // ext = lurl.slice(idx + 1)

  return null;
};

export const getFileIcon = (type, href) => {
  switch (type) {
    case 'doc':
      return Assets.ICON.WORD_SOLID;
    case 'xls':
      return Assets.ICON.EXCEL_SOLID;
    case 'pdf':
      return Assets.ICON.PDF_SOLID;
    case 'ppt':
      return Assets.ICON.POWERPOINT_SOLID;
    case 'img':
      return href || Assets.ICON.TXT_SOLID;
    default:
      return Assets.ICON.TXT_SOLID;
  }
};

const getGenerateId = url =>
  url
    .substring(url.lastIndexOf('/') + 1)
    .split('.')
    .slice(0, -1)
    .join('.');

export const downloadAttachmentAs = (url, name) => {
  const generatedId = getGenerateId(url);

  Axios.get(url, {
    headers: { 'Content-Type': 'application/octet-stream' },
    responseType: 'blob'
  }).then(response => {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(response.data);
    a.href = url;
    a.download = `${generatedId}_${name}`;
    a.click();
  });
};
