import React from 'react';
import DetailScreenComponent from './DetailScreen.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER_DETAIL_SECTION } from './DetailScreen.graphql';
import { WrapperDetailSection } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { Skeleton } from 'antd';
import { AuthenticationService } from '../../../Services';
import gql from 'graphql-tag';

export const DEFAULT_LIMIT_ITEM_SECTIONS = 2;

const DetailScreenContainer = props => {
  const { profileId } = props.match.params;
  const userLogin = AuthenticationService.getUserId();

  const isShare = !(profileId === userLogin) || undefined;
  const isShareEducation = {
    is_shared: isShare
  };

  const { data, loading, error, refetch } = useQuery(GET_USER_DETAIL_SECTION, {
    skip: !profileId,
    fetchPolicy: 'no-cache',
    variables: {
      user_id: profileId,
      is_share: isShare,
      is_share_education: isShareEducation
    },
    context: {
      headers: {
        'X-Hasura-Role': 'public'
      }
    }
  });

  const DATA_FOLLOW = gql`
    query {
      getTotalFollowersCompany(company: "${profileId}") {
        total
        is_following
        is_followed
      }
    }
  `;

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data: dataFollow } = useQuery(DATA_FOLLOW, {
    context: CONTEXT_USER
  });

  if (error) {
    return JSON.stringify(error);
  } else if (data === undefined || loading) {
    return (
      <WrapperDetailSection style={{ padding: 20, marginBottom: 120 }}>
        <Skeleton active />
      </WrapperDetailSection>
    );
  } else {
    return (
      <DetailScreenComponent
        nameUser={data && data?.global_users?.[0]?.name}
        isShare={isShare}
        profileId={profileId}
        userLogin={userLogin}
        refetch={refetch}
        isUser={userLogin === profileId}
        summary={data && data.global_users[0]}
        experiences={data && data.experiences}
        educations={data && data.educations}
        licences={data && data.licences}
        hobbies={data && data.hobbies}
        recommendation={data && data.recommendation}
        disciplinaries={data && data.disciplinaries}
        service_agreements={data && data.service_agreements}
        people_profile={data && data.people_profile}
        projects={data && data.projects}
        speaker={data && data.speaker}
        awards={data && data.awards}
        organization={data && data.organization_activities}
        ohsa={data && data.ohsa_list}
        isFollowingFollowed={
          dataFollow &&
          dataFollow.getTotalFollowersCompany.is_followed &&
          dataFollow.getTotalFollowersCompany.is_following
        }
        publications={data && data.publications}
        {...props}
      />
    );
  }
};

export default DetailScreenContainer;
