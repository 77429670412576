import  React from 'react'
import {CenterLayoutWrapper} from "./style";

function CenterLayout(props){
    return(
        <CenterLayoutWrapper>
            {props.children}
        </CenterLayoutWrapper>
    )
}

export default CenterLayout