import Constants from '../../Config/Constants';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => (props.read ? '#fff' : '#eff2f4')};
  cursor: pointer;

  .notification {
    display: flex;
    align-items: center;

    padding: 18px 20px 18px 32px .avatar-notification {
      display: flex;
      align-items: center;
    }

    .body-notification {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .content-notiication {
        display: flex;
        flex-direction: row;
        max-width: 82%;

        p {
          margin: 0px;
        }

        .component-notif-body {
          word-break: break-all;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
      .time-notification {
        height: 100%;
        align-items: center;
        display: flex;
        position: relative;
        padding: 18px;

        &:before {
          content: ${props => (props.read ? null : '" "')};
          position: absolute;
          top: 0;
          right: 0;
          width: 8px;
          height: 8px;
          background-color: #ef4d5e;
          border-radius: 50%;
        }
        p {
          margin: 0;
        }
      }
    }
  }
`;

const defaultCol = {
  fontSize: 12,
  minHeight: 50,
  padding: 20
};

export default {
  wrapper: {
    width: '100%',
    backgroundColor: Constants.COLORS.WHITE,
    borderRadius: 2.5,
    boxShadow: '1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    marginBottom: 30,

    fontFamily: 'Muli-Regular, sans-serif'
  },
  typeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20
  },
  bold: {
    fontWeight: 600
  },
  headerNotif: {
    padding: '22px 32px',
    fontSize: 14
  },
  defaultCol,
  row: {
    padding: '22px 32px'
  },

  hr: {
    margin: 0,
    height: 1,
    backgroundColor: Constants.COLORS.LIGHT_GREY,
    border: 'none',
    width: '100%'
  },
  dateCol: {
    ...defaultCol,
    textAlign: 'right',
    color: Constants.COLORS.GREY
  }
};

export const WrapperBodyNotification = styled.div`
  .blue {
    color: #039be5;
  }

  .red {
    color: #ef4d5e;
  }
`;
