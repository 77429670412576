import styled from 'styled-components';
import CONSTANTS from '../../Config/Constants';

export const VendorPrintHeaderWrapper = styled.div`
  .component_print_invoice_header {
    background-color: ${CONSTANTS.COLORS.DARK_BLUE};
    padding: 0;
    margin-top: 23px;
    position: relative;
    height: 60px;

    .component_print_invoice_title {
      background-color: #fff;
      position: absolute;
      top: -14px;
      right: 53px;
      display: block;
      font-size: 71px;
      font-family: 'Muli-light';
      line-height: 1.2;
      padding-left: 30px;
      padding-right: 30px;
      color: ${CONSTANTS.COLORS.BLACK};
    }
  }
`;

export const VendorPrintContentWrapper = styled.div`
  padding: 50px;
  position: relative;

  .component_print_invoice_content_header {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 10px;
  }

  .component_print_invoice_content_purpose {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1.16px;
    text-align: left;
    color: ${CONSTANTS.COLORS.BLACK};
    font-weight: bold;

    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.8;
      letter-spacing: 1.16px;
      text-align: left;
      color: ${CONSTANTS.COLORS.BLACK};
    }
  }

  .component_print_invoice_content_date {
    position: absolute;
    right: 0;
  }

  .component_print_invoice_date {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 1.16px;
    text-align: left;
    color: ${CONSTANTS.COLORS.BLACK};

    div:nth-child(1) {
      /* margin-right: 10px; */
    }

    div:nth-child(2) {
      width: 10px;
    }

    div:nth-child(3) {
      width: 210px;
    }
  }

  .component_print_invoice_content {
    margin-top: 60px;

    h5 {
      font-size: 21px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #373e41;
      display: block;
      margin-bottom: 30px;
    }

    table {
      width: 100%;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: normal;
      text-align: right;
      color: #131313;
      td:first-child {
        text-align: left;
      }

      thead {
        border-top: 1px solid rgba(0, 0, 0, 0.16);
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        th:first-child {
          text-align: left;
        }
      }

      tr.component_vendor_print_invoice_table {
        border-radius: 5px;
        background-color: #eff2f4;

        td {
          padding: 15px 20px;
        }
      }

      tr.component_vendor_print_invoice_logistic {
        border-top: 1px solid rgba(0, 0, 0, 0.16);
      }

      th,
      td {
        padding: 30px 20px;
      }
    }
  }

  .component_vendor_print_invoice_payment_info {
    margin-top: 50px;

    .component_vendor_print_invoice_title {
      font-size: 21px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal;
      text-align: left;
      color: #007fb2;
    }
  }

  .component_vendor_print_invoice_detail {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 45%);
    grid-gap: 10px;
    width: 100%;

    .component_vendor_print_invoice_detail_item {
      display: grid;
      grid-template-columns: 220px 10px 1fr;
      font-size: 21px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal;
      text-align: left;
      color: ${CONSTANTS.COLORS.BLACK};
      margin-bottom: 20px;
    }

    .component_vendor_print_invoice_detail_amount {
      justify-self: end;

      .component_vendor_print_invoice_detail_item {
        div:first-child {
          font-size: 21px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.24;
          letter-spacing: normal;
          text-align: left;
          color: #007fb2;
        }

        div:last-child {
          text-align: right;
        }
      }
    }
  }
`;

export const VendorPrintFooterWrapper = styled.div`
  padding: 50px;

  a {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: ${CONSTANTS.COLORS.BLUE};
    margin-bottom: 20px;
    display: inline-block;
  }

  h4 {
    font-size: 53px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: ${CONSTANTS.COLORS.BLACK};
    display: block;
    margin-bottom: 70px;
  }

  .component_print_invoice_thank {
    font-size: 21px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: left;
    color: ${CONSTANTS.COLORS.BLACK};
  }
`;
