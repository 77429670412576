import React from "react";
import { SectionWrap } from "../UserProfileCV.style";

export default function Section({ title, ...children }) {
  return (
    <SectionWrap>
      <div className="title">{title}</div>
      <div className="content" {...children} />
    </SectionWrap>
  );
}