import { Drawer } from 'antd';
import styled from 'styled-components';

import Assets from '../../Assets';
import Constant from '../../Config/Constants';

export const OrgChartDrawer = styled(Drawer)`
  .ant-drawer-header {
    background-color: ${Assets.COLOR_PALLETE.dark_blue};
    font-family: ${Assets.FONT.MULI};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    padding: 0 24px;
    border: 0;
    border-radius: 0;
  }

  .ant-drawer-title,
  .ant-drawer-close {
    color: #ffffff;
    margin-right: -16px;
    position: unset;
  }
`;

export const OrgTitleLegend = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;

  .legend-dots {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    margin-right: 8px;
  }
`;

export const OrgChartDisplay = styled.div`
  cursor: grab;
  display: grid;
  user-select: none;
  background-color: #eff2f4;
  width: 100%;
  position: absolute;
  top: 65px;
  bottom: 0;
  left: 0;

  &:active {
    cursor: grabbing;
  }

  .react-transform-component {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  #osChart,
  #ouChart {
    --orgchart-stroke-color: #a9a8a8;
    padding: 240px;
  }

  .zoom-buttons {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const PrimitiveOrgNode = styled.div`
  color: #000000;
  font-family: ${Assets.FONT.MULI};
  font-size: 14px;
  line-height: normal;
  background-color: #ffffff;
  box-shadow: ${Constant.BOX_SHADOW};
  margin: 0 20px;
  border-width: 0;
  border-top-width: 4px;
  border-radius: 4px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
  }
  .column {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: baseline;
  }
  .title {
    font-size: 14px;
    font-weight: 700;
    text-overflow: ellipsis;
    display: -webkit-box;
    margin: 4px;
    margin-right: 8px;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .legend {
    display: flex;
    align-items: center;
    margin: 4px;
  }
  .legend-dots {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    margin-right: 8px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(min(var(--items), 3), 240px);
    grid-gap: 8px;
    padding: 8px;
  }
  .subcard {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
  }

  .list {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    align-items: center;
    padding: 8px;
  }
  .item-avatar {
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }
  .item-name {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
  }
  .link {
    background-color: transparent;
    color: ${Assets.COLOR_PALLETE.blue_link};
    font-size: 12px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;

    &:hover {
      text-decoration: underline;
    }
  }
  .spacer {
    flex-grow: 1;
  }

  .more {
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4px;
    border: 0;
    border-radius: 0 0 4px 4px;

    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #b6b6b6;
    }
  }

  .expand {
    color: #757575;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    vertical-align: middle;
    padding: 3px;
    border: 0;
    border-radius: 50%;

    &:hover {
      background-color: #f5f5f5;
    }
    &:active {
      background-color: #b6b6b6;
    }
  }

  .more-icon,
  .expand-icon {
    font-size: 20px;
  }
`;

export const buildListToTree = (array, options = {}) => {
  const { idKey = 'id', parentKey = 'parent' } = options;

  const map = new Map([[null, []]]);

  for (let idx = 0, len = array.length; idx < len; idx++) {
    const children = [];
    const node = { ...array[idx], children };
    const parent = map.get(node[parentKey]);

    map.set(node[idKey], children);
    parent.push(node);
  }

  return map.get(null);
};

export const mapStrokeStyle = type => {
  switch (type) {
    case 1:
      return 'solid';
    case 2:
      return 'dotted';
    default:
      return 'solid';
  }
};

export const mapOrgChartNode = (item, parent) => {
  return {
    data: item,
    name: item.name || item.title,
    id: item.id,
    parent: parent ? parent.id : null,
    parentNode: parent || null,
    staff: item.as_assistant || false,
    strokeStyle: mapStrokeStyle(item.line_type),
    // NOTE(intrnl): this will be filled in by `buildListToTree`
    children: null
  };
};
