import BaseService from '../BaseService';
import Constants from '../../Config/baseUrl';
import { isEmpty } from 'lodash';

const getListFollowers = (userId, page = 1, querySearch = '') => {
  let parameters = {
    params: {
      query: querySearch,
      page: page,
      maxResults: 20
    }
  };
  if (isEmpty(querySearch)) {
    delete parameters.params.query;
  }
  const url = Constants.FOLLOWER(userId);
  return BaseService.get(url, parameters);
};

const getListFollowing = (userId, page = 1, querySearch = '') => {
  let parameters = {
    params: {
      query: querySearch,
      page: page,
      maxResults: 20
    }
  };
  if (isEmpty(querySearch)) {
    delete parameters.params.query;
  }
  const url = Constants.FOLLOWING(userId);
  return BaseService.get(url, parameters);
};

const postFollow = userId => {
  const url = Constants.FOLLOW(userId);
  return BaseService.post(url);
};

const postUnfollow = userId => {
  const url = Constants.UNFOLLOW(userId);
  return BaseService.post(url);
};

const totalFollowerFollowing = (userId, options) => {
  const url = Constants.TOTAL_FOLLOWING_FOLLOWER(userId);
  return BaseService.get(url, options);
};

export default {
  getListFollowers,
  getListFollowing,
  postFollow,
  postUnfollow,
  totalFollowerFollowing
};
