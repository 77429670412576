import styled from 'styled-components';
import Constants from '../../../Config/Constants';
import Assets from '../../../Assets';

export const Wrapper = styled.div`
  padding: 15px;
  font-family: ${Assets.FONT.MULI};
  .search-block-account {
    width: 50%;
    margin-bottom: 30px;
  }
  .not-founded {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 200px;
    margin-top: 30px;
    img {
      width: 80px;
      margin: 10px;
    }
  }
`;

export const style = {
  title: {
    width: 280,
    overflow: 'hidden',
    lineHeight: 'normal',
    marginTop: 10
  },
  name: {
    fontSize: 12,
    color: `${Constants.COLORS.BLACK}`
  },
  description: {
    color: `${Constants.COLORS.GREY}`,
    fontSize: 10,
    lineHeight: 0.5
  }
};
