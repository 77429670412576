import React from 'react';
import 'antd/dist/antd.css';
import {
  CategoryLabel,
  SearchNotFoundWrapper,
  SearchCategory,
  SearchContainer
} from '../SearchPage.style';
import GroupResultList from '../../../Components/TeamResultList';
import SearchNotFound from '../../../Components/SearchNotFound';
import { isObjectEmpty } from '../../../Utils/CommonUtils';
import { useGlobalState } from '../../../globalState';

const GroupScreenComponent = props => {
  const { searchCategory, searchedGroupResults } = props;
  const [
    keyword
    // setKeyword
  ] = useGlobalState('keyword');
  const [
    loading
    // setLoading
  ] = useGlobalState('loading');

  return (
    <SearchContainer>
      {isObjectEmpty(searchedGroupResults) && keyword && !loading ? (
        <SearchNotFoundWrapper>
          <SearchNotFound />
        </SearchNotFoundWrapper>
      ) : (
        <React.Fragment>
          <SearchCategory>
            <CategoryLabel>{searchCategory}</CategoryLabel>
          </SearchCategory>
          <GroupResultList
            {...props}
            searchedGroupResults={searchedGroupResults}
          />
        </React.Fragment>
      )}
    </SearchContainer>
  );
};

export default GroupScreenComponent;
