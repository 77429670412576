import React from 'react';
import { ComponentWrapper } from './style';
import { Icon } from 'antd';

const Index = props => {
  const { deleteEvent } = props;

  return (
    <ComponentWrapper>
      <a href={props.eventDetailUrl} target="_blank" rel="noopener noreferrer">
        <div className="component_image">
          <img src={props.img} alt={props.title} />
        </div>
        <div className="component_content">
          <div className="component_title">{props.title}</div>
          <div className="component_name">{props.name}</div>
          <div className="component_description">{props.description}</div>
          <div className="component_time">{props.time}</div>
          {deleteEvent && (
            <Icon type="close" className="close-icon" onClick={deleteEvent} />
          )}
        </div>
      </a>
    </ComponentWrapper>
  );
};

export default Index;
