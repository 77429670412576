import React from 'react';
import {
  VendorPrintHeaderWrapper,
  VendorPrintContentWrapper,
  VendorPrintFooterWrapper
} from './VendorPrintInvoice.style';
import Assets from '../../Assets';
import { ToPrice } from '../../Utils/currencyFormat';

const Header = () => {
  return (
    <VendorPrintHeaderWrapper>
      <img src={Assets.LOGO_WLB} alt="WLB Logo" />
      <div className="component_print_invoice_header">
        <div className="component_print_invoice_title">INVOICE</div>
      </div>
    </VendorPrintHeaderWrapper>
  );
};

const Content = props => {
  let total = 0;
  return (
    <VendorPrintContentWrapper>
      <div className="component_print_invoice_content_header">
        <div>
          <div className="component_print_invoice_content_purpose">
            Invoice <br />
            {props.purpose} <br />
            <p>{props.address}</p>
          </div>
        </div>
        <div className="component_print_invoice_content_date">
          <div className="component_print_invoice_date">
            <div>Date</div>
            <div>:</div>
            <div>{props.date}</div>
          </div>
          <div className="component_print_invoice_date">
            <div>Invoice #</div>
            <div>:</div>
            <div>{props.invoiceCode}</div>
          </div>
        </div>
      </div>
      <div className="component_print_invoice_content">
        <h5>ORDER DETAIL</h5>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Amount</th>
              <th>Weight</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {props?.items?.map(item => {
              let subTotal = item.qty * item.single_price;
              total = total + subTotal;
              return (
                <tr key={item.id}>
                  <td>{item.itemname}</td>
                  <td>{item.qty}</td>
                  <td>{item?.weight ? `${item.weight} g` : ''} </td>
                  <td>Rp{ToPrice(item.single_price)}</td>
                  <td>{item?.discount ?? '-'}</td>
                  <td>Rp{ToPrice(subTotal)}</td>
                </tr>
              );
            })}
            {/* <tr>
              <td>Samsung Galaxy</td>
              <td>1</td>
              <td>1g</td>
              <td>Rp{ToPrice(10000000)}</td>
              <td>-</td>
              <td>Rp{ToPrice(10000000)}</td>
            </tr> */}
            {/* <tr className="component_vendor_print_invoice_table">
              <td colSpan="5">Subtotal</td>
              <td>Rp{ToPrice(10000000)}</td>
            </tr> */}
            {/* <tr className="component_vendor_print_invoice_logistic">
              <td>{props.logisticsBrand}</td>
              <td></td>
              <td>1g</td>
              <td>Rp{ToPrice(10000)}</td>
              <td></td>
              <td>Rp{ToPrice(10000)}</td>
            </tr> */}
            <tr className="component_vendor_print_invoice_table">
              <td colSpan="5">Total</td>
              <td>Rp{ToPrice(total)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="component_vendor_print_invoice_payment_info">
        <div className="component_vendor_print_invoice_title">
          Payment Info:
        </div>
        <div className="component_vendor_print_invoice_detail">
          <div>
            <div className="component_vendor_print_invoice_detail_item">
              <div>Account#</div>
              <div>:</div>
              <div>qweq12345</div>
            </div>
            <div className="component_vendor_print_invoice_detail_item">
              <div>A/C Name</div>
              <div>:</div>
              <div>Aulia Hidayatullah</div>
            </div>
            <div className="component_vendor_print_invoice_detail_item">
              <div>Status</div>
              <div>:</div>
              <div>Paid</div>
            </div>
          </div>
          <div className="component_vendor_print_invoice_detail_amount">
            <div className="component_vendor_print_invoice_detail_item">
              <div>Current Balance</div>
              <div>:</div>
              <div>Rp{ToPrice(14000000)}</div>
            </div>
            <div className="component_vendor_print_invoice_detail_item">
              <div>Total Price</div>
              <div>:</div>
              <div>Rp{ToPrice(10010000)}</div>
            </div>
            <div className="component_vendor_print_invoice_detail_item">
              <div>Remaining Balance</div>
              <div>:</div>
              <div>Rp{ToPrice(1000000)}</div>
            </div>
          </div>
        </div>
      </div> */}
    </VendorPrintContentWrapper>
  );
};

const Footer = () => {
  return (
    <VendorPrintFooterWrapper>
      <a href="/">Terms & Condition</a>
      <h4>WorkLife & Beyond states that</h4>
      <div className="component_print_invoice_thank">
        Thank you for your bussiness
      </div>
    </VendorPrintFooterWrapper>
  );
};

export default { Header, Content, Footer };
