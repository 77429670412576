import React, { useState } from 'react';
import { BannerBox } from '../../Containers';
import { useParams } from 'react-router-dom';
import { useAsyncEffect } from 'use-async-effect';
import { CompanyGlobal } from '../../Services';
import { Error404Page } from '../../Pages';

const BannerPage = props => {
  const params = useParams();
  const { setDataLoadedCompany, isDetailPage = false, children } = props;
  const companyId = params.id;
  const [isLoading, setIsLoading] = useState(false);
  const [isFounded, setIsFounded] = useState();

  useAsyncEffect(async isMounted => {
    document.title = 'SmartASN - Instansi';
    setIsLoading(true);
    await CompanyGlobal.getCompanyById(companyId)
      .then(res => {
        if (isDetailPage) {
          setDataLoadedCompany(res);
        }
        setIsFounded(true);
      })
      .catch(() => {
        setIsFounded(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isFounded === false && isLoading === false) {
    return <Error404Page />;
  }

  return (
    <>
      <BannerBox
        {...props}
        setDataLoadedCompany={setDataLoadedCompany}
        companyId={companyId}
      />
      {children}
    </>
  );
};

export default BannerPage;
