import styled from 'styled-components';

const AccountSettingsMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 198px;
  height: 200px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-right: 15px;

  #account-settings-title {
    font-family: 'Muli-SemiBold';
    margin: 15px 0px 10px 15px;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    /* border-bottom:  */
  }

  .ant-menu-item {
    font-family: 'Muli-SemiBold';
    font-size: 12px !important;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.48;
    letter-spacing: normal;
    text-align: left;
    color: #a9a8a8;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    display: flex;
    align-items: center;
  }

  .ant-menu-item.ant-menu-item-selected {
    color: #007fb2;
    border-left: #039be5 2.5px solid;
  }
`;

export { AccountSettingsMenu };
