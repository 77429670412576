import React from 'react';
import { Col, Row } from 'antd';
import { useMediaQuery } from '@material-ui/core';
import { PostList } from '../../../Containers';
import style from './PostScreen.style';
import Constants from '../../../Config/Constants';

const PostScreenComponent = props => {
  const lg = useMediaQuery('(min-width: 1200px)');
  return (
    <Row gutter={16}>
      <Col id="company-timeline" span={lg ? 18 : 24} style={style.top16}>
        <PostList postType={Constants.POST_TYPE.COMPANY_TIMELINE} {...props} />
      </Col>
    </Row>
  );
};

export default PostScreenComponent;
