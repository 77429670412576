import React from 'react';
import { TextSecondary, TextStandard, TextGrey } from '../UserProfileCV.style';

export default function Project({
  title,
  company,
  type,
  date,
  location,
  url,
  desc,
  assoc
}) {
  return (
    <div style={{ marginBottom: 35 }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextSecondary>{company}</TextSecondary>
      <TextGrey>{type}</TextGrey>
      <TextGrey>{date}</TextGrey>
      <TextStandard>{desc}</TextStandard>
      <TextGrey>{location}</TextGrey>
      <TextStandard>{assoc}</TextStandard>
      <div style={{ paddingTop: '5px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'start',
            paddingTop: '10px'
          }}
        >
          <TextGrey>{`Project Url : `}</TextGrey>
          <TextSecondary>{` ${url}`}</TextSecondary>
        </div>
      </div>
    </div>
  );
}
