import React from 'react';
import { ModalEdit, ButtonFullWlb } from '../../../../Components';
import SkilsItem from './SkilsItem';
import { WrapperSkill } from './Skils.style';
import { Skeleton } from 'antd';

export default function MoreSkilsModal({
  open,
  setOpen,
  data,
  nameUser,
  isUser,
  onEndorse,
  onUnendorsed,
  isFollowingFollowed,
  loading,
  onClickMorePeople
}) {
  return (
    <ModalEdit
      sizeSmall
      centered
      padding="0px"
      title={`Keahlian ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false, fromList: false }))}
    >
      <WrapperSkill>
        {loading ? (
          <div style={{ padding: 24 }}>
            <Skeleton avatar active />
          </div>
        ) : (
          data.map((res, i) => (
            <SkilsItem
              isFollowingFollowed={isFollowingFollowed}
              isUser={isUser}
              key={i}
              data={res}
              count={res.countpeople}
              skils={res.nameIndicator}
              level={res.title}
              has_endorse={res?.has_endorse}
              people_profile_skills={res.people_profile_skills || []}
              count_people_skill={res.count_people_skill || []}
              onEndorse={onEndorse}
              onUnendorsed={onUnendorsed}
              onClickMorePeople={() =>
                onClickMorePeople({ data: res.dataEdit, isFromList: true })
              }
              onClickEdit={() =>
                setOpen(e => ({
                  ...e,
                  openMore: false,
                  open: true,
                  data: res,
                  actionType: 'edit',
                  fromList: true
                }))
              }
            />
          ))
        )}
      </WrapperSkill>
      {isUser && (
        <ButtonFullWlb
          label="Tambahkan Keahlian"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
}
