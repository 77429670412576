import styled from 'styled-components';
import Assets from '../../Assets';

export const GroupFollower = styled.div`
  /* display: flex; */
  /* flex-direction: row; */
  font-size: 12px;
  color: #a9a8a8;
  font-family: ${Assets.FONT.MULI};

  /* justify-content: flex-start; */
`;

export const GroupDetail = styled.div`
  display: flex;
  /* width: 70%; */
  /* flex-direction: column; */
  font-family: ${Assets.FONT.MULI};

  .team-member {
    margin-left: 20px;
  }
`;

export const GroupDescription = styled.div`
  font-size: 11px;
  padding-top: 5px;
  margin: 0 10px 0 20px;
  font-family: ${Assets.FONT.MULI};
`;

export const GroupTitle = styled.div`
  width: 178px;
`;

export const GroupName = styled.label`
  font-family: 'Muli-SemiBold';
  font-size: 14px;
  font-weight: bold;
  color: black;
  cursor: pointer;
`;
