import React from 'react';
import { ModalEdit } from '../../../../Components';
import { Headline, SelectedPepole, TitleField } from './Recommendation.style';
import { Form, Input, Button, message } from 'antd';
import { useMutation } from 'react-apollo';
import { ASK_FOR_REVISION } from './Recommendation.graphql';

const AskForRevisionComponent = ({ open, onClose, form, askData }) => {
  const { validateFields, getFieldDecorator, resetFields } = form;
  const { TextArea } = Input;

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [askForRevision] = useMutation(ASK_FOR_REVISION, {
    context: CONTEXT_USER
  });

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const submit = {
          objects: [
            {
              request_to: askData.request_to,
              type: 2,
              comment: values.message,
              recommendation_id: askData.id
            }
          ]
        };
        askForRevision({
          variables: submit
        })
          .then(() => {
            message.success(
              `Ask for Revision to ${askData?.name} successfully added`
            );
            resetFields();
            onClose();
          })
          .catch(err => {
            message.error(`Ask for Revision error, ${err}`);
          });
      }
    });
  };

  const handleChange = () => {};
  const handleClose = () => {
    resetFields();
    onClose();
  };

  return (
    <ModalEdit sizeSmall open={open} onClose={handleClose} title="Minta Revisi">
      <Headline>
        Minta <SelectedPepole>{askData.name}</SelectedPepole> untuk Merevisi ?
      </Headline>
      <Form onSubmit={handleSubmit}>
        <Form.Item
          style={{ padding: '0px 22px', marginBottom: 8 }}
          label={<TitleField>Pesan</TitleField>}
        >
          {getFieldDecorator('message', {
            initialValue: '',
            rules: [
              {
                required: false,
                message: 'Bagian ini diperlukan'
              }
            ]
          })(
            <TextArea
              rows={4}
              onChange={handleChange}
              placeholder="Tambahkan rujukan"
            />
          )}
        </Form.Item>
        <Form.Item
          style={{
            marginBottom: 0,
            padding: '20px 22px 0px 22px',
            marginTop: 22
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Batalkan
            </Button>
            <Button type="primary" htmlType="submit">
              Kirim
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ModalEdit>
  );
};

const AskForRevision = Form.create({ name: 'Ask_For_revisioin' })(
  AskForRevisionComponent
);

export default AskForRevision;
