import styled from 'styled-components';

const FollowerContent = styled.div`
  margin: auto;
  width: 850px;
  margin-bottom: 30px;
`;

const FollowerPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 10px;

  .ant-input-search {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 3px;
    background-color: #ffffff;
    width: 250px;
    .ant-input {
      font-size: 12px;
    }
  }

  .follow-type {
    font-family: 'Muli-SemiBold';
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserRow = styled.div`
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  justify-content: space-between;

  .ant-card-body {
    display: flex;
    flex-direction: row;
  }

  .ant-card-meta-title {
    font-family: 'Muli-SemiBold';
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  .ant-card-meta {
    width: 100%;
  }

  .user-card {
    width: 420px;
    height: 75px;
  }
`;

export { FollowerContent, FollowerPageHeader, UserList, UserRow };
