import React, { useState, useEffect } from 'react';
import NotificationComponent from './Notification.component';
import { Notification } from '../../Services';
import { message } from 'antd';
import useInfiniteScroll from '../../SharedComponents/CustomHook/useInfiniteScroll';
import { checkNextPage } from '../../Utils/CommonUtils';
import { getSubType, getType } from './getParams';

const NotificationContainer = props => {
  const { type } = props.match.params;
  const [notification, setNotification] = useState([]);
  const [page, setPage] = useState(1);
  const [isBottom, setIsBottom] = useInfiniteScroll('notificationPage');
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const _subType = getSubType(type);
  const _type = getType(type);

  React.useEffect(() => {
    setNotification([]);
    setPage(1);
    setIsFetching(false);
    setHasNextPage(true);
    setIsBottom('notificationPage');
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (hasNextPage && !isFetching && (isBottom || page === 1)) {
      setIsFetching(true);
      Notification.getNotification(page, 20, _type, _subType)
        .then(response => {
          const { limit, totalDocs, docs } = response;
          const isNextPage = checkNextPage(totalDocs, page, limit);
          setHasNextPage(isNextPage);
          if (isNextPage) {
            setPage(page + 1);
          }
          setNotification(notification.concat(docs));
        })
        .catch(error => {
          message.error('Something went wrong');
        })
        .finally(() => {
          setIsFetching(false);
          setIsBottom(false);
        });
    }
  }, [
    _type,
    _subType,
    notification,
    hasNextPage,
    isBottom,
    setIsFetching,
    page,
    setIsBottom,
    isFetching
  ]);

  // const _handleClickNotif = (id, userId) => {};
  // useEffect(() => {
  //   const fetchNotification = async () => {
  //     await Notification.FETCH_LIST_NOTIFICATION(page).then(async result => {
  //       if (result.message === 'OK.') {
  //         if (result.next_page_url) {
  //           if (result.data) {
  //             setDataNotification(dataNotification.concat(result.data));
  //           } else {
  //             message.error(
  //               "Sorry, we can't get notifications. Please try again later !"
  //             );
  //             setDataNotification([]);
  //           }
  //           setPage(page + 1);
  //         } else {
  //           setIsFetchingScroll(false);
  //         }
  //       }
  //     });
  //     setIsFetchingScroll(false);
  //   };
  //   if (page === 1 || isFetchingScroll) {
  //     fetchNotification();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFetchingScroll, page, setIsFetchingScroll]);

  return (
    <NotificationComponent
      type={type}
      notification={notification}
      isFetching={isFetching}
      // isFetchingScroll={isFetchingScroll}
      // handleClick={_handleClickNotif}
      {...props}
    />
  );
};

export default NotificationContainer;
