import gql from 'graphql-tag';

const GET_USER_DETAIL = gql`
  query getProfile($id: uuid!) {
    main_profile: global_users(where: { id: { _eq: $id } }) {
      id
      name
    }
    profile_about: people_profile(where: { id: { _eq: $id } }) {
      id
      gender
      birthdate
      marital_status
      blood_type
    }
    profile_phone: people_phones(where: { user: { _eq: $id } }) {
      id
      country_code
      phone
    }
    profile_address: people_addresses(where: { user: { _eq: $id } }) {
      id
      address
      postal_code
      global_city {
        id
        name
      }
      global_province {
        id
        name
      }
    }
  }
`;
const GET_USER_DETAIL_SECTION = gql`
  query getDetailSection(
    $user_id: uuid!
    $is_share: Boolean
    $is_share_education: jsonb
  ) {
    global_users(where: { id: { _eq: $user_id } }) {
      id
      name
      people_profiles {
        profile_fields
      }
    }
    people_profile(where: { id: { _eq: $user_id } }) {
      id
      settings_section
    }
    experiences: people_profile_experiences(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ from: desc_nulls_last }, { to: desc_nulls_first }]
    ) {
      id
      title
      company_name
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      location
      from
      to
      decree
      description
      achievements
      initial_salary
      last_salary
      is_present
      is_share
      company_name
      referal_name
      referal_position
      additional_fields
      reason_to_leave
      company_line_of_business
      is_masterpiece
    }
    educations: people_profile_educations(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { education_fields: { _contains: $is_share_education } }
          {
            _or: [
              {
                company_profile: {
                  _and: [
                    { types: { _ilike: "_1%" } }
                    { deletedAt: { _is_null: true } }
                  ]
                }
              }
              { other_school: { _is_null: false } }
            ]
          }
        ]
      }
      order_by: [{ from: desc_nulls_last }, { to: desc_nulls_first }]
    ) {
      id
      company_profile {
        id
        logo
        school_name: legal_name
        company_address {
          city: global_city {
            name
            id
          }
          province: global_province {
            name
            id
          }
          address_field
        }
      }
      faculty: global_faculty {
        id
        name
      }
      degree: global_degree {
        id
        name
      }
      major: global_major {
        id
        name
      }
      other_school
      start_date: from
      end_date: to
      description
      is_present
      education_fields # other info
    }
    licences: people_profile_certificates(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_shared: { _eq: $is_share } }
        ]
      }
      order_by: [
        { date_issued: desc_nulls_last }
        { date_expired: desc_nulls_first }
      ]
    ) {
      id
      is_shared
      name
      type
      level
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      issuer_name
      issues_date: date_issued
      expirations_date: date_expired
      can_expire
      credential_id
      credential_url
      description
      attachments
    }
    hobbies: people_profile_hobbies(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ id: desc }]
    ) {
      id
      hobby
      is_share
      people_hobby {
        id
        icon
        name
        hobby_category {
          id
          name
        }
        hobby_sub_category {
          id
          name
        }
      }
      hobby_fields
      description
      people_profile_hobby_endorsements {
        id
        global_user {
          id
          name
          avatar
        }
        comment
      }
    }
    disciplinaries: people_profile_disciplinary(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [
        { start_date: desc }
        { end_date: desc }
        { reference_date: desc }
      ]
    ) {
      id
      is_share
      discipline_name
      reference_number
      start_date
      end_date
      discipline_letter_number
      reference_date
      remark
      attachments
    }
    recommendation: people_profile_recommendations(
      where: {
        recommendation_to: { _eq: $user_id }
        deletedAt: { _is_null: true }
      }
      order_by: [{ id: desc }]
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      position
      relationship
      description
      status
      date_added
      last_modified
    }
    projects: people_profile_projects(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [
        { start_date: desc_nulls_last }
        { end_date: desc_nulls_first }
      ]
    ) {
      id
      is_current
      is_share
      project_name
      creators
      location
      start_date
      end_date
      description
      associate_user {
        id
        name
        people_work_placements(where: { status: { _eq: "ACTIVE" } }) {
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      project_url
      position
    }
    speaker: people_profile_speaker(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: { id: desc }
    ) {
      id
      is_share
      event_name
      location
      organizer
      roles
      scale
      total_participants
      description
    }
    awards: people_profile_awards(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ reference_date: desc }]
    ) {
      id
      is_share
      award_name
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      reference_date
      reference_number
      letter_number
      remark
      certificate_number
      attachments
      issuing_organization
    }
    organization_activities: people_profile_organization_activities(
      where: {
        _and: [
          { user_id: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_shared_with_network: { _eq: $is_share } }
        ]
      }
      order_by: [
        { start_date: desc_nulls_last }
        { end_date: desc_nulls_first }
      ]
    ) {
      id
      organization_name
      organization_type
      member_id
      position_name
      position_description
      start_date
      end_date
      country
      province: global_province {
        name
        id
      }
      city: global_city {
        name
        id
      }
      is_shared_with_network
      logo
    }
    ohsa_list: people_profile_ohsa(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ incident_date_time: desc_nulls_last }]
    ) {
      id
      is_share
      company_name
      company_location
      category
      employee: global_user {
        name
      }
      incident_location
      incident_date_time
      severity
      description
      incident_code
      attachments
    }
    service_agreements: people_profile_service_agreements(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ start_date: desc }, { end_date: desc }]
    ) {
      id
      is_share
      code
      name
      start_date
      end_date
      description
      attachments
    }
    publications: people_profile_publications(
      where: {
        _and: [
          { user: { _eq: $user_id } }
          { deletedAt: { _is_null: true } }
          { is_share: { _eq: $is_share } }
        ]
      }
      order_by: [{ publication_date: desc }]
    ) {
      id
      is_share
      publication_title
      authors
      publication_media
      publication_date
      description
      publication_url
      attachments
    }
  }
`;

const GET_COMPANY_ID_USER = gql`
  query getCompanyId($id: uuid!) {
    working: people_work_placements(
      where: {
        _and: [
          { user: { _eq: $id } }
          { deletedAt: { _is_null: true } }
          { status: { _in: ["ACTIVE", "INACTIVE", "REGISTERED"] } }
        ]
      }
      order_by: [{ id: desc }]
      limit: 1
    ) {
      id
      company
    }
  }
`;

export { GET_USER_DETAIL, GET_USER_DETAIL_SECTION, GET_COMPANY_ID_USER };
