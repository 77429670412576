/* eslint-disable max-len */
import { LoadingWrapper } from './commonUtils.styles';
import Assets from '../Assets';
import React from 'react';
import { isEmpty } from 'lodash';

const isObjectEmpty = object => {
  return object === undefined || object.length === 0;
};

const isLastItemOfArray = (elementToCheck, array = []) => {
  const itemIndex = array.indexOf(elementToCheck);
  const dataLength = array.length - 1;

  return itemIndex === dataLength;
};

const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase();

const displayLoading = (isLoading, id = '') => {
  return isLoading ? (
    <LoadingWrapper id={isEmpty(id) ? '' : id}>
      <img alt={'loading ...'} src={Assets.ICON.LOADING} />
    </LoadingWrapper>
  ) : null;
};

//return input to integer
function checkInteger(x) {
  const ans = x.replace(/(?!-)[^0-9]/g, '');
  return parseInt(ans < 0 || ans === '' ? 0 : ans, 10);
}

const getQueryParameter = url => {
  // isObjectEmpty(!url);
  // {
  //   const queryParameters = url.split('?')[1];
  //   if (!isEmpty(queryParameters)) {
  //     const parameters = queryParameters.split('&');
  //     parameters.forEach(parameter => {
  //       const pair = parameter.split('=');
  //       parametersObject[pair[0]] = pair[1];
  //     });
  //   }
  // }
  return {};
  //return parametersObject;
};

function checkNextPage(total, page, limit) {
  if (total > limit * page) return true;
  return false;
}

function delaySearch(timeout, setKeyword) {
  clearTimeout(timeout);
  return setTimeout(function() {
    setKeyword();
  }, 1000);
}
function isUrl(str) {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line no-useless-escape
  const checker = /(http(s)?:\/\/.)?(ftp?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gim;
  return checker.test(str);
}
function isHashtag(str) {
  // eslint-disable-next-line max-len
  // eslint-disable-next-line no-useless-escape
  const checker = /(^|\s)(#[a-z\d-]+)/gi;
  return checker.test(str);
}

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

function hasModule(data, moduleName) {
  return (
    data?.company_module_settings?.length === 0 ||
    data?.company_module_settings?.some(
      o => o.global_module.name === moduleName
    )
  );
}
function getPercent(value, total) {
  if (total <= 0) {
    return 0;
  } else {
    return (value / total) * 100;
  }
}

export {
  isObjectEmpty,
  displayLoading,
  capitalize,
  isLastItemOfArray,
  getQueryParameter,
  checkNextPage,
  delaySearch,
  isUrl,
  isHashtag,
  checkInteger,
  getCookie,
  hasModule,
  getPercent
};
