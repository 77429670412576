import gql from 'graphql-tag';

export const GET_ORDER_DETAIL = gql`
  query getDetailOrder($id: uuid!) {
    invoice: global_invoices_by_pk(id: $id) {
      id
      code
      invoice_to
      status
      final_price
      delivery_option
      delivery_data
      date_added
      tracking_status
      global_wallets {
        global_users {
          id
        }
      }
      global_payment {
        id
        global_direct_transfer {
          id
          global_direct_transfer_proofs {
            id
            name
            type
            file
            date_added
          }
        }
      }
      global_shipping_provider {
        name
        logo
      }
      global_invoice_items {
        id
        invoice_id
        item_table
        item_table_id
        itemname
        qty
        single_price
        type
        thumbnail
        weight
      }
      marketplace_refund_requests(limit: 1, order_by: { date_added: desc }) {
        id
        date_added
        reason
        bank_account_num
        bank_account_holder
        marketplace_refund_request_files {
          id
          vendor_side
          name
          file
        }
        global_bank {
          id
        }
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation updateOrder($status: String!, $id: String!, $shippingId: String) {
    updateInvoice(status: $status, id: $id, id_shipping: $shippingId) {
      success
    }
  }
`;

export const UPDATE_ORDER_PAYMENT = gql`
  mutation($id: uuid!, $status: String!, $paymentStatus: String!) {
    update_global_payments(
      _set: { payment_status: $paymentStatus }
      where: { global_invoices: { id: { _eq: $id } } }
    ) {
      affected_rows
    }
    update_global_invoices(
      _set: { status: $status }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
