import React from 'react';
import { Steps } from 'antd';
import { StepsWrap } from './Steps.style';
const { Step } = Steps;
const StepsComponent = ({ menuStepsList, currentSteps }) => {
  return (
    <StepsWrap>
      <Steps current={currentSteps}>
        {menuStepsList.map(item => (
          <Step key={item.name} title={item.name} />
        ))}
      </Steps>
    </StepsWrap>
  );
};

export default StepsComponent;
