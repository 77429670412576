import { gql } from 'apollo-boost';

export const UPDATE_REVIEW = gql`
  mutation(
    $classroomId: uuid!
    $userId: uuid!
    $description: String!
    $star: Int!
  ) {
    update_classroom_reviews(
      where: {
        classroom_id: { _eq: $classroomId }
        member_user_id: { _eq: $userId }
      }
      _set: { description: $description, star: $star }
    ) {
      affected_rows
    }
  }
`;
