import React from 'react';
import ListChips from '../../../../Components/DetailUserSection/ListChips.component';
import { AttachementItemIcon } from '../../../../SharedComponents';

export default function DisciplinaryItem({
  onClickEdit,
  code,
  start_date,
  end_date,
  description,
  attachments,
  noBorder = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <ListChips
      fullchips
      noBorder={noBorder}
      onClickEdit={onClickEdit}
      chipsName={code}
    >
      <p className="color-primary font-14 mb-regular">
        {start_date} - {end_date}
      </p>
      <p className="font-12 color-black pre-line">
        {description.slice(0, (!isMore && 370) || description.length) || '-'}
      </p>
      {isMore && (
        <div className="attachment-wrapper">
          {attachments?.map((res, i) => (
            <AttachementItemIcon
              key={`${i}-idx`}
              className="attachment-icon"
              name={res.name}
              url={res.url || res.link}
              size={res.size}
            />
          ))}
        </div>
      )}
      <span className="see-more" onClick={() => setIsMore(!isMore)}>
        ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
      </span>
    </ListChips>
  );
}
