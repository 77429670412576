import Styled from "styled-components";
import { Slider } from "antd";
import ReactCrop from "react-image-crop";

export const Cropper = Styled(ReactCrop)`
  & > .ReactCrop__crop-selection {
    border-radius: 50%;
    border: 8px solid #fff;
  }
  &:after {
    content: " ";
    width: 100vw;
    height: 100px;
    border: 2px solid #fff;
    padding: 20px;
      
    }
`
export const Body = Styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  min-height: 300px;
  max-height: 500px;
  overflow: hidden;
`;
export const SliderStyled = Styled(Slider)`
  .ant-slider-rail {
    background-color: #fff !important;
  }
  .ant-slider-track {
    background-color: #014a62 !important;
  }
  .ant-slider-handle {
    background-color: #039be5 !important;
    border: none !important;
    height: 21px !important;
    width: 21px !important;
    margin-top: -8px !important;
  }

`;

export const Footer = Styled.div`
  height: 92px;
  background-color: #eff2f4;
  display: flex;
  flex-direction: row;
  padding: 15px 22px;
  justify-content: space-between;
  align-items: center;
  
  .wrapper-slider {
    display: flex;
    flex-direction: column;
    width: 300px;

    .zoom-title {
      font-size: 14px;
      font-weight: 600;
      color: #000000;
    }
  }
  .wrapper-action {
    display: flex;
    flex-direction: row;

    .button-upload {    
      margin-right: 21px;
      padding: 11px 14px;
      border: 1px solid #014a62;
      background-color: #eff2f4;
      color: #014a62;
      font-size: 12px;
      border-radius: 5px;
      min-width: 110px;
      cursor: pointer;
    }
    .button-save {
      border-radius: 5px;
      font-size: 12px;
      border: none;
      padding: 0px 40px;
      background-color: #014a62;
      color: #fff;
    }
  }
`;
