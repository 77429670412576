import React from "react";
import { Form, Select, Button, message } from "antd";
import { ModalEdit } from "../../../../Components";
import {
  Headline,
  SelectedPepole,
  FormItemStyled,
} from "../DetailScreen.style";
import { useMutation } from "react-apollo";
import { ADD_SKILL_ENDORSEMENT } from "./Skills.graphql";
import { ButtonFrom } from "../../../../SharedComponents";

const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

const EndorseSkillsComponent = ({
  open,
  onClose,
  form,
  data,
  endorsement,
  nameUser,
  refetch,
}) => {
  const { Option } = Select;
  const { getFieldDecorator, validateFields,  resetFields } = form;

  const [addEndorsement] = useMutation(ADD_SKILL_ENDORSEMENT);
  const handleSubmit = async (event) => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const submit = {
          objects: [
            {
              people_skill: data.dataEdit.id,
              relationship: values.relationship,
            },
          ],
        };
        addEndorsement({
          variables: submit,
        })
          .then(() => {
            refetch();
            message.success(`You have endorse for ${data?.nameIndicator} skill`);
            onClose();
            resetFields()
          })
          .catch((err) => {
            message.error(`Endorse for ${data?.nameIndicator} skill failed, ${err}`);
          });
      }
    });
  };

  const handleChange = () => {};
  const handleClose = () => {
    onClose()
    resetFields()
  };

  const newEndorsement = []
  endorsement.forEach((res) => {
    newEndorsement.push({
      ...res,
      name: capitalize(res.name).replace("#subject#", nameUser)
    })
  })

  return (
    <ModalEdit
      sizeSmall
      title="Skill Endorsement"
      open={open}
      onClose={handleClose}
    >
      <Headline>
        How do you know <SelectedPepole>{nameUser}</SelectedPepole>{"'s "}
        {data?.nameIndicator} Skill?
      </Headline>
      <Form onSubmit={handleSubmit}>
        <FormItemStyled
          style={{
            padding: "22px 22px 12px 22px",
            marginBottom: 8,
            display: "flex",
          }}
        >
          {getFieldDecorator("relationship", {
            initialValue: null,
            rules: [
              {
                required: true,
                message: "Bagian ini diperlukan",
              },
            ],
          })(
            <Select
              style={{ width: "100%" }}
              size="large"
              onChange={(value, e) => handleChange(value, e)}
            >
              <Option value={null} disabled>
                Chose Relationship
              </Option>
              {newEndorsement.map((res) => (
                <Option
                  key={res.id}
                  value={res.name}
                >
                  {res.name}
                </Option>
              ))}
            </Select>
          )}
        </FormItemStyled>
        <ButtonFrom
          onCancel={handleClose}
          submitLabel="Save"
        />
      </Form>
    </ModalEdit>
  );
};

const EndorseSkills = Form.create({ name: "Endorse_Skills" })(
  EndorseSkillsComponent
);

export default EndorseSkills;
