import React, { memo } from 'react';
import { Icon } from 'antd';
import {
  WrapperItemDependent,
  ItemWrapper
} from '../UserProfileDependent/UserDependent.style';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Person from '@material-ui/icons/Person';
import { Typography } from '@material-ui/core';
import { AttachementItemIcon } from '../../SharedComponents';
import { AttachmentWrapper } from '../../Components/DetailUserSection/DetailUserSection.style';

const ItemField = ({ icon, value, isBlueColor }) => {
  return (
    <ItemWrapper style={{ alignItems: 'end', gap: 10 }}>
      {icon}
      <p
        style={{ textTransform: 'capitalize', color: isBlueColor && '#039be5' }}
        className="value"
      >
        {value === '' ? '-' : value}
      </p>
    </ItemWrapper>
  );
};
export default memo(function UserDependentItem({
  onEdit,
  isUser,
  data,
  onClickDetail
}) {
  const {
    name,
    date,
    hospital_name,
    doctor_name,
    disease,
    consequence,
    attachments
  } = data || {};

  const handleClickDetail = () => {
    onClickDetail({
      ...data
    });
  };

  return (
    <WrapperItemDependent>
      <div className="header">
        <div className="title">
          <h4>{name}</h4>
          <span>{date}</span>
        </div>
        {isUser && <Icon type="edit" onClick={onEdit} className="icon" />}
      </div>

      <div className="content">
        <ItemField
          isBlueColor
          icon={<LocationOnIcon color="primary" fontSize="small" />}
          value={hospital_name ?? '-'}
        />

        <ItemField
          icon={<Person color="primary" fontSize="small" />}
          value={doctor_name || '-'}
        />

        {disease && (
          <Typography
            color="primary"
            variant="caption"
            style={{ paddingBottom: 6 }}
          >
            {disease}
          </Typography>
        )}

        {consequence && (
          <Typography variant="caption" color="textPrimary">
            {consequence}
          </Typography>
        )}
      </div>

      <AttachmentWrapper>
        {attachments?.slice(0, 3)?.map((res, i) => (
          <AttachementItemIcon
            key={`${i}-idx`}
            className="attachment-icon"
            name={res.name}
            url={res.url || res.link}
            size={res.size}
          />
        ))}
      </AttachmentWrapper>

      <p onClick={handleClickDetail} className="show-detail">
        Tampilkan Detail Rekam Medis
      </p>
    </WrapperItemDependent>
  );
});
