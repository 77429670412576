import gql from 'graphql-tag';

const UPDATE_INFORMATION = gql`
  mutation updateVendorInformation(
    $brand_name: String!
    $logo: String!
    $profile_field: jsonb!
    $id: uuid!
  ) {
    update_company_profiles(
      _set: {
        brand_name: $brand_name
        logo: $logo
        profile_field: $profile_field
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export { UPDATE_INFORMATION };
