import { gql } from 'apollo-boost';

export const REMOVE_MEMBER = gql`
  mutation($classroomId: String!, $memberUserId: String!) {
    classroomKickMember(
      classroomId: $classroomId
      memberUserId: $memberUserId
    ) {
      success
    }
  }
`;
