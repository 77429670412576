import React from 'react';
import { Checkbox, Col, Row } from 'antd';

import './FollowerCheckBox.css';
import style from './FollowerCheckBox.style';
import config from './FollowerCheckBox.config';
import Assets from '../../Assets';

const FollowerCheckBoxComponent = ({
  followers,
  handleChangeCheckBox,
  isChecked
}) => {
  const _renderContent = () =>
    followers.map((follower, id) => {
      let _avatar, peoplePosition, peopleCompany;
      if (follower?.global_user) {
        _avatar = follower?.global_user?.avatar
          ? follower?.global_user?.avatar
          : Assets.IMAGE.AVATAR;
      } else {
        _avatar = follower.avatar ? follower.avatar : Assets.IMAGE.AVATAR;
      }

      if (follower?.company_employee_position) {
        peoplePosition = follower?.company_employee_position?.name ?? '';
      } else {
        peoplePosition =
          follower?.people_work_placements?.[0]?.company_employee_position
            ?.name ?? '';
      }

      if (follower?.company_address) {
        peopleCompany = follower?.company_address?.office_name ?? '-';
      } else {
        peopleCompany =
          follower?.people_work_placements?.[0]?.company_profile?.brand_name ??
          '-';
      }
      return (
        <Row key={id} style={style.wrapper}>
          {/* {console.log(follower)} */}
          <Col span={4}>
            <img style={style.img} src={_avatar} alt="avatar" />
          </Col>
          <Col span={18} style={style.center}>
            <div style={style.name}>
              {follower?.global_user
                ? follower?.global_user?.name
                : follower.name}
            </div>
            <div style={style.description}>{peoplePosition}</div>
            <div style={style.description}>{peopleCompany}</div>
          </Col>
          <Col span={2} style={style.center}>
            <Checkbox
              className="follower-check-box"
              key={follower.id}
              value={follower}
              checked={isChecked}
              onChange={() => handleChangeCheckBox(follower, !isChecked)}
            />
          </Col>
        </Row>
      );
    });

  return _renderContent();
};

FollowerCheckBoxComponent.propTypes = config.propTypes;
FollowerCheckBoxComponent.defaultProps = config.defaultProps;

export default FollowerCheckBoxComponent;
