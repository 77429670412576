import React from 'react';
import Global from './global';
import User from './userProfile';
import MainContainer from './PostBox.container';
import Constant from '../../Config/Constants';
import { useScriptGMaps } from '../../SharedComponents/CustomHook';

function PostBox(props) {
  useScriptGMaps();
  const postType = props.postType;
  switch (postType) {
    case Constant.POST_TYPE.GLOBAL_TIMELINE:
      return <Global {...props} />;
    case Constant.POST_TYPE.PROFILE:
      return <User {...props} />;
    default:
      return <MainContainer {...props} />;
  }
}
export default PostBox;
