import React, { useState } from 'react';
import LearningComponent from './component';
import { message } from 'antd';
import { SearchService } from '../../../Services';
import queryString from 'query-string';
import { SearchNotFound, DummySpace } from '../../../Components';
import { useAsyncEffect } from 'use-async-effect';
// import { checkNextPage } from '../../../Utils/CommonUtils';
// import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';

const LearningScreenContainer = props => {
  const queryWord = queryString.parse(props.location.search).keyword;
  const [learning, setLearning] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //   const [page, setPage] = useState(1);
  //   const [hasNextPage, setHasNextPage] = useState(true);
  //   const [isBottom, setIsBottom] = useInfiniteScroll('search-people');

  useAsyncEffect(
    async isMount => {
      if (queryWord && !isLoading) {
        setIsLoading(true);
        SearchService.searchByKeywordAndType(queryWord, 'learning')
          .then(res => {
            if (!isMount()) return;
            setLearning(res.data.learning);
          })
          .catch(err => {
            message.error('Something Went Wrong');
          })
          .finally(() => {
            if (!isMount()) return;
            setIsLoading(false);
          });
      }
    },
    [queryWord]
  );

  return (
    <div id="search-people">
      {learning.length === 0 && !isLoading ? (
        <SearchNotFound
          title={`We could not find  "${queryWord}"`}
          desc={
            'Nothing came up for that search. Try shortening or rephrasing your search.'
          }
        />
      ) : (
        <LearningComponent
          {...props}
          learningList={learning}
          isLoading={isLoading}
        />
      )}
      <DummySpace />
    </div>
  );
};

export default LearningScreenContainer;
