import React, { memo } from 'react';
import { Icon } from 'antd';
import { WrapperItemEmergency, ItemWrapper } from './UserEmergency.style';
// import { useLazyQuery } from "react-apollo";
// import { GET_LIST_LOCATION } from "../../Components/LocationForm/LocationForm.graphql";

const ItemField = ({ field, value }) => {
  return (
    <ItemWrapper>
      <p className="key">{field}</p>
      <p style={{ textTransform: 'capitalize' }} className="value">
        {value}
      </p>
    </ItemWrapper>
  );
};

export default memo(function UserEmergency({
  onEdit,
  name,
  isUser,
  relationship,
  phone,
  address,
  postal_code
}) {
  // const [getLocation, {data}] = useLazyQuery(GET_LIST_LOCATION)

  // console.log("Data loc: ",  data)
  // useEffect(() => {
  //   if(emergency_contact_fields) {
  //     getLocation({
  //       variables: {
  //         city: emergency_contact_fields.city,
  //         province: emergency_contact_fields.province
  //       }
  //     })
  //   }
  // }, [emergency_contact_fields])
  return (
    <WrapperItemEmergency>
      <div className="header">
        <h4>{name ?? '-'}</h4>
        {isUser && <Icon type="edit" onClick={onEdit} className="icon" />}
      </div>
      <div className="content">
        <ItemField field="Hubungan" value={relationship ?? '-'} />
        <ItemField field="Nomor Telepon" value={phone ?? '-'} />
        <ItemField field="Alamat" value={address ?? '-'} />
        <ItemField field="Kode Pos" value={postal_code ?? '-'} />
      </div>
    </WrapperItemEmergency>
  );
});
