import gql from "graphql-tag";

const GET_LIST_PHONENUMBER = gql`
  query($user: uuid!) {
    phone_numbers: people_phones(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [{ is_main: desc_nulls_last }, { id: desc }]
    ) {
      id
      global_phone_code_id
      country_code
      phone
      is_main
      is_visible
    }
  }
`;

const UPDATE_VISIBILITY_PHONE = gql`
  mutation($id: Int!, $userId: uuid!, $isVisible: Boolean!) {
    update_people_phones(
      _set: { is_visible: $isVisible }
      where: { _and: { id: { _eq: $id }, user: { _eq: $userId } } }
    ) {
      affected_rows
    }
  }
`;

const ADD_LIST_PHONENUMBER = gql`
  mutation(
    $globalPhoneCodeId: Int!
    $countryCode: String!
    $phone: String!
    $isMain: Boolean
  ) {
    insert_people_phones(
      objects: [
        {
          global_phone_code_id: $globalPhoneCodeId
          country_code: $countryCode
          phone: $phone
          is_main: $isMain
        }
      ]
    ) {
      affected_rows
    }
  }
`;


const UPDATE_LIST_PHONENUMBER = gql`
  mutation(
    $id: Int!
    $userId: uuid!
    $globalPhoneCodeId: Int
    $countryCode: String
    $phone: String
    $is_main: Boolean
  ) {
    update_people_phones(
      _set: {
        global_phone_code_id: $globalPhoneCodeId
        country_code: $countryCode
        phone: $phone
        is_main: $is_main
      }
      where: {
        _and: {
          id: { _eq: $id }
          user: { _eq: $userId }
          deletedAt: { _is_null: true }
        }
      }
    ) {
      affected_rows
    }
  }
`;
const DELETE_LIST_PHONENUMBER = gql`
  mutation($id: Int!, $userId: uuid!) {
    update_people_phones(
      where: {
        _and: [
          { id: { _eq: $id } }
          { user: { _eq: $userId } }
        ]
      }
      _set: {deletedAt: "now()"}
    ) {
      affected_rows
    }
  }
`;

const GET_LIST_PHONECODE = gql`
  {
    global_phone_code(order_by: [{ country: asc }]) {
      id
      country
      code
    }
  }
`;
const GET_LIST_PHONECODE_BY_CODE = gql`
  query($id: Int!) {
    global_phone_code(
      order_by: [{ country: asc }]
      where: { id: { _eq: $id } }
    ) {
      id
      country
    }
  }
`;
const SET_MAIN_PHONECODE = gql`
  mutation($user: uuid!, $id: Int!) {
    q1: update_people_phones(
      where: { user: { _eq: $user } }
      _set: { is_main: false }
    ) {
      affected_rows
    }

    q2: update_people_phones(
      where: { id: { _eq: $id } }
      _set: { is_main: true }
    ) {
      affected_rows
    }
  }
`;
const SET_UNMAIN_PHONECODE = gql`
  mutation($user: uuid!) {
    update_people_phones(
      where: { user: { _eq: $user } }
      _set: { is_main: false }
    ) {
      affected_rows
    }
  }
`;

export {
  GET_LIST_PHONENUMBER,
  ADD_LIST_PHONENUMBER,
  GET_LIST_PHONECODE,
  UPDATE_LIST_PHONENUMBER,
  DELETE_LIST_PHONENUMBER,
  SET_MAIN_PHONECODE,
  UPDATE_VISIBILITY_PHONE,
  SET_UNMAIN_PHONECODE,
  GET_LIST_PHONECODE_BY_CODE,
};
