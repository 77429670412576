import React from 'react';

import config from './SearchPage.config';
import { Enhancer } from '../../SharedComponents';

const SearchPageContainer = () => <div />;

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.searchBarList,
  defaultMenu: config.defaultMenuBar
})(SearchPageContainer);
