import React, { useState } from 'react';
import {
  Banner,
  ButtonWlb,
  OrganizationStructureModal
} from '../../Components';

const BannerBoxComponent = props => {
  const [open, setOpen] = useState(false);

  const {
    activeCompanyId,
    companyId,
    isStructureVisible,
    isFollow,
    handleFollowCompany
  } = props;

  return (
    <>
      <Banner {...props}>
        {(activeCompanyId === companyId || isStructureVisible) && (
          <ButtonWlb
            type="default"
            onClick={() => setOpen(true)}
            style={{ marginRight: '5px' }}
          >
            Struktur Organisasi
          </ButtonWlb>
        )}
        <ButtonWlb
          type={isFollow ? 'default' : 'primary'}
          onClick={() => handleFollowCompany()}
        >
          {isFollow ? 'Mengikuti Instansi' : 'Ikuti Instansi'}
        </ButtonWlb>
      </Banner>

      <OrganizationStructureModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default BannerBoxComponent;
