import React from 'react';
import { Avatar } from 'antd';
import 'antd/dist/antd.css';
import {
  CompanyResultWrapper,
  AvatarWrapper,
  InfoWrapper,
  Description,
  FollowedBy,
  JobDetails,
  Name,
  ButtonWrapper
} from './CompanyResultList.style';
import { ButtonWlb } from '../../Components';
import { TotalFollower } from './CompanyResultList.style';
import { Link } from 'react-router-dom';

const CompanyResultComponent = props => {
  const { company, handleFollowCompany, isFollow } = props;

  return (
    <CompanyResultWrapper>
      <div className="container-searchCompany-borderBottom">
        <AvatarWrapper>
          <Avatar shape={'square'} size={65} src={company.logo} />
        </AvatarWrapper>
        <InfoWrapper>
          <Link to={`/company/${company.id}/post`}>
            <Name>{company.brand_name}</Name>
          </Link>
          <Description>
            <JobDetails>{company.industry_type}</JobDetails>
            <FollowedBy>
              <span className="company-follower" />
              <TotalFollower>{company.totalFollower}</TotalFollower>
              <label>Followers</label>
            </FollowedBy>
          </Description>
        </InfoWrapper>
        <ButtonWrapper>
          <ButtonWlb
            type={isFollow ? 'default' : 'primary'}
            size={'small'}
            style={{ width: 85, fontSize: 14 }}
            onClick={() =>
              isFollow
                ? handleFollowCompany(company, 'unfollow')
                : handleFollowCompany(company, 'follow')
            }
          >
            {isFollow ? 'Following' : 'Follow'}
          </ButtonWlb>
        </ButtonWrapper>
      </div>
    </CompanyResultWrapper>
  );
};

export default CompanyResultComponent;
