import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Schedule, QuickAccess, CenterLayout } from '../../Components';
import { PostCard } from '../../Containers';
import { BannerBox } from '../../Containers';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_COMPANY } from './GetCompany.graphql';
import { AuthenticationService } from '../../Services';

const DetailPostComponent = props => {
  const { itemPost, postType, listType, companyId } = props;
  const client = useApolloClient();
  const [companyData, setCompanyData] = useState(null);
  const userData = AuthenticationService.getUserData();
  const isAdmin =
    userData.roles.includes('organization-administrator') &&
    userData.company_id === companyId;

  useEffect(() => {
    if (
      listType === 'company-timeline-detail' &&
      postType === 'companyTimeline'
    ) {
      client
        .query({
          query: GET_COMPANY,
          variables: { id: companyId }
        })
        .then(res => {
          setCompanyData(res?.data);
        });
    }
  }, [postType, listType]);

  if (listType === 'company-timeline-detail') {
    return (
      <CenterLayout>
        <div style={{ marginTop: '15px' }}>
          <BannerBox {...props} companyId={companyId} />
        </div>
        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col span={18} id={`detail-post-company`}>
            {/* <h3 style={{ marginBottom: '20px' }}>{`Detail Post`}</h3> */}
            <PostCard
              key={1}
              itemPost={itemPost}
              setLoading={false}
              postType={postType}
              listType={listType}
              dataCompany={companyData}
              isAdmin={isAdmin}
              {...props}
            />
          </Col>
        </Row>
      </CenterLayout>
    );
  } else {
    return (
      <CenterLayout>
        <Row gutter={16} style={{ marginTop: '15px' }}>
          <Col span={18} id={`detail-post-company`}>
            <PostCard
              key={1}
              itemPost={itemPost}
              setLoading={false}
              postType={postType}
              // listType={listType}
              // match={null}
              // deletePost={null}
              // restartPage={null}
              {...props}
            />
          </Col>
          <Col span={6}>
            <Schedule />
            <QuickAccess />
          </Col>
        </Row>
      </CenterLayout>
    );
  }
};

export default DetailPostComponent;
