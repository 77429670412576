import styled from 'styled-components';

const SidebarProfileWrapper = styled.div`
  min-height: 50px;
  width: 100%;
  border-radius: 2.5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: inline-block;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 110px;

  .profile-wrapper {
    position: relative;
    display: block;
    .photo {
      margin: 0 auto;
      padding-top: 28px;
      width: 77px;
      img {
        width: 77px;
        height: 77px;
        border-radius: 100%;
      }
    }
    .info {
      margin-top: 8px;
      margin-bottom: 20px;
      text-align: center;
      span {
        display: block;
      }
      .name {
        font-family: 'Muli-Bold', sans-serif;
        color: #252525;
        font-size: 14px;
      }
      .desc {
        font-family: 'Muli-SemiBold', sans-serif;
        color: #a9a8a8;
        font-size: 11px;
        padding-top: 3px;
        width: 180px;
        margin: auto;
      }
    }
  }

  .item-count {
    display: flex;
    .count {
      width: 50%;
      border: 1px solid #a9a8a8;
      text-align: center;
      padding: 11px 0px;
      cursor: pointer;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-left: none;
        border-right: none;
      }
      span {
        display: block;
      }
      .number {
        font-family: 'Muli-SemiBold', sans-serif;
        font-size: 12px;
        color: #252525;
      }
      .text {
        font-family: 'Muli-Regular', sans-serif;
        font-size: 11px;
        color: #a9a8a8;
      }
    }
  }

  .skills {
    display: block;
    margin: 14px;
    img {
      width: 100%;
    }
  }
`;

export { SidebarProfileWrapper };
