import React, { memo } from 'react';
import { Avatar, Col, Row } from 'antd';
import Assets from '../../Assets';
import style, { Wrapper } from './NotificationActivity.style';
import moment from 'moment';
export default memo(function NotificationItem({
  read,
  onClick,
  avatar,
  data,
  ...content
}) {
  return (
    <Wrapper onClick={onClick} read={read}>
      <Row className="notification">
        <Col className="avatar-notification" span={2} style={style.defaultCol}>
          <Avatar
            size={40}
            src={avatar ?? Assets.IMAGE.AVATAR_ALT} // checking for organization post
          />
        </Col>
        <Col className="body-notification" span={22} style={style.defaultCol}>
          <div className="content-notiication" {...content} />
          <div className="time-notification">
            <p>{` ${moment(data?.date).fromNow()}`}</p>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
});
