import React from 'react';
import { ReadyScreenWrap } from './ReadyScreen.style';
import { ButtonWlb } from '../../../Components';
import { Icon } from 'antd';
import { routes } from '../../../Config/Router';
import { Link } from 'react-router-dom';

const ReadyScreenComponent = props => {
  return (
    <ReadyScreenWrap>
      <div className="box-finish">
        <h1>WELCOME !!</h1>
        <Icon type="check" />
        <p>You just registered as a Vendor</p>
        <Link to={routes.VENDOR_LIST.path}>
          <ButtonWlb type="primary" onClick={null}>
            Finish
          </ButtonWlb>
        </Link>
      </div>
    </ReadyScreenWrap>
  );
};

export default ReadyScreenComponent;
