import styled from 'styled-components';
import Assets from '../../Assets';
const PostContentWrapper = styled.div`
  .text-hidden {
    max-height: 120px;
    overflow: hidden;
    overflow-wrap: break-word;
  }
  .text-show {
    max-height: none;
  }
  .text-more {
    cursor: pointer;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export { PostContentWrapper };
