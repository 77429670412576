import gql from 'graphql-tag';

const GET_REGULATION = gql`
query getRegulation($id:uuid!, $key:String){
  company_regulations(where:{
    company:{_eq:$id},
    _or:[
      {name:{_ilike:$key}},
      {description:{_ilike:$key}}
    ]
  }){
    id
    name
    description
    company_regulation_clauses(order_by:{name:asc}){
      id
      name
      content
      date_added
    }
  }
}
`;

export { GET_REGULATION };
