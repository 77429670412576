import React from 'react';
import MainContainer from './PostBox.container';
import { useMutation } from 'react-apollo';
import { message } from 'antd';
import _ from 'lodash';

import {
  ADD_POST,
  ADD_EVENT,
  ADD_LEARNING_TRACKER
} from './createPost.graphql';
import { UPDATE_LEARNING_TRACKER } from './updatePost.graphql';
import moment from 'moment';

function ClassroomPostbox(props) {
  const classroomId = props.match.params.id;
  const postId = props.match.params.postId;

  const [addLearningTracker] = useMutation(ADD_LEARNING_TRACKER);
  const [addPost] = useMutation(ADD_POST);
  const [addEvent] = useMutation(ADD_EVENT);
  const [updateLearningTracker] = useMutation(UPDATE_LEARNING_TRACKER);

  const onSubmit = async (content, resetContent, formatBody) => {
    const bodyPost = formatBody(content);
    const {
      type,
      images,
      tags,
      event,
      location,
      subject,
      activity,
      competencyId,
      date,
      duration,
      description,
      mentees,
      files
    } = bodyPost;

    delete bodyPost.privacy;
    delete bodyPost.canSee;
    delete bodyPost.sharedPost;

    const _location = _.isEmpty(location) ? null : location;

    const newFiles = [];
    images.forEach(item => {
      let newObj = {};

      newObj.type = item.filetype;
      newObj.name = item.name;
      newObj.size = item.size;
      newObj.file = item.url;
      newFiles.push(newObj);
    });

    files.forEach(item => {
      let newObj = {};

      newObj.type = item.filetype;
      newObj.name = item.name;
      newObj.size = item.size;
      newObj.file = item.url;
      newFiles.push(newObj);
    });
    let submit = {};

    if (props.edit) {
      props.setLoading(true);
      if (type === 3) {
        const selectedMenteesId = mentees.map(item => item.id);
        submit = {
          postId,
          activity,
          date: moment(date),
          duration,
          mentees: selectedMenteesId,
          files: newFiles,
          subject,
          competencyId,
          description,
          tags,
          location: _location
        };
        updateLearningTracker({ variables: submit })
          .then(() => {
            props.history.goBack();
            message.success('Post success updated');
          })
          .catch(err => {
            message.error('Something went wrong');
          })
          .finally(() => {
            props.setLoading(false);
            resetContent();
          });
      }
    } else {
      if (type === 0) {
        submit = {
          classroomId,
          content: bodyPost.content,
          files: newFiles,
          tags,
          location: _location
        };
        await addPost({ variables: submit })
          .then(response => {
            const { id } = response.data.classroomNewPost;
            props.restartPage({ id, ...submit, type });
            message.success('Post success submitted');
          })
          .catch(() => {
            message.error('Something went wrong');
          })
          .finally(() => {
            props.setLoading(false);
            if (props.listType === 'detail-post') {
              props.history.push(`/social-learning/${classroomId}/discussion`);
            } else {
              resetContent();
            }
          });
      }
      if (type === 1) {
        submit = {
          classroomId,
          event: event,
          content: bodyPost.content,
          files: newFiles,
          tags,
          location: _location
        };
        await addEvent({ variables: submit })
          .then(response => {
            const { id } = response.data.classroomNewEvent;
            props.restartPage({ id, ...submit, type });
            message.success('Post success submitted');
          })
          .catch(() => {
            message.error('Something went wrong');
          })
          .finally(() => {
            props.setLoading(false);
            if (props.listType === 'detail-post') {
              props.history.push(`/social-learning/${classroomId}/discussion`);
            } else {
              resetContent();
            }
          });
      }
      if (type === 3) {
        const selectedMenteesId = mentees.map(item => item.id);
        submit = {
          classroomId,
          subject,
          activity,
          competencyId,
          date: moment(date),
          duration,
          description,
          mentees: selectedMenteesId,
          files: newFiles,
          tags,
          location: _location
        };
        addLearningTracker({ variables: submit })
          .then(response => {
            const { id } = response.data.classroomCreateLearningTracker;
            props.restartPage({ id, ...submit, mentees, type });
            message.success('Post success submitted');
          })
          .catch(() => {
            message.error('Something went wrong');
          })
          .finally(() => {
            props.setLoading(false);
            if (props.listType === 'detail-post') {
              props.history.push(`/social-learning/${classroomId}/discussion`);
            } else {
              resetContent();
            }
          });
      }
    }
  };

  return (
    <MainContainer onSubmit={onSubmit} postType={props.postType} {...props} />
  );
}

export default ClassroomPostbox;
