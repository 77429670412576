import React, { useState } from 'react';

import style from './NotificationActivity.style';
import moment from 'moment';
import { displayLoading } from '../../Utils/CommonUtils';
import NotificationItem from './NotificationItem';
import NotificationContent from './NotificationContent';
import { Notification } from '../../Services';
import { useDirectNotification } from '../../SharedComponents/CustomHook';
import { Typography } from '@material-ui/core';
import { ModalConfirmation } from '..';
import { message } from 'antd';
import { withRouter } from 'react-router';

const initial_op_read_all = {
  isOpen: false,
  recent: false
};

const NotificationActivityComponent = ({
  type,
  notification,
  loading,
  history,
  setRecomendation
}) => {

  const path = history.location.pathname;
  const [openReadAll, setOpenReadAll] = useState(initial_op_read_all);
  const directTo = useDirectNotification();
  const handleClickNotification = notif => {
    const { type, subtype, _id } = notif;
    Notification.readANotification(_id); //request read notification

    if (
      type === 'social' &&
      (subtype === 'social-referral-request' ||
        subtype === 'social-referral-revision-request')
    ) {
      setRecomendation(e => ({ ...e, open: true, data: notif, subtype }));
    } else {
      directTo(notif);
    }
  };

  const _type = () => {
    let types = notification?.[0]?.type;
    switch (true) {
      case path.includes('/all'):
        types = 'all';
        break;
      case path.includes('/time'):
        types = '^company-time';
        break;
      case path.includes('/company'):
        types = '^company-placement';
        break;
    }
    return types;
  };

  const earlyData = notification.filter(
    notif => !moment(notif?.data?.date).isSame(new Date(), 'day')
  );

  const recentData = notification.filter(notif =>
    moment(notif?.data?.date).isSame(new Date(), 'day')
  );

  const onClickReadAll = recent => {
    // tambahkan disini untuk notification modul apa saja yg membutuhkan popup agreement saat read all
    if (_type() === 'learning') {
      setOpenReadAll({ isOpen: true, recent });
    } else {
      onSubmitReadAll(recent);
    }
  };

  const closeReadAll = () => setOpenReadAll(initial_op_read_all);

  const onSubmitReadAll = recent => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    const yesterday = moment(new Date())
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    const recentParams = recent ? recent : openReadAll.recent;

    const onHitAPI =
      _type() === '^company-time' || _type() === '^company-placement'
        ? Notification.readAllNotificationSubType(
          _type(),
          recentParams,
          today,
          yesterday
        )
        : Notification.readAllNotificationType(
          _type(),
          recentParams,
          today,
          yesterday
        );

    onHitAPI
      .then(() => window.location.reload(false))
      .catch(e => message.error('Something went wrong, ', e));
  };

  const renderContent = recent => {
    const _renderData = recent ? recentData : earlyData;
    const showMarkAsRead = _renderData.some(i => !i.read);

    return (
      <div style={style.wrapper}>
        <div style={style.typeWrapper}>
          <Typography variant="body1" style={style.bold}>
            {recent ? 'Recent' : 'Earlier'}
          </Typography>
          {showMarkAsRead && (
            <Typography
              variant="body2"
              color="secondary"
              style={{ ...style.bold, cursor: 'pointer' }}
              onClick={() => onClickReadAll(recent)}
            >
              Mark as Read
            </Typography>
          )}
        </div>
        <hr style={style.hr} />
        {_renderData.map((notif, id) => {
          const data = notif?.data;
          return (
            <NotificationItem
              key={id}
              read={notif.read}
              onClick={() => handleClickNotification(notif)}
              avatar={data.avatar || data.avatar_url}
              data={data}
            >
              <NotificationContent type={type} data={notif} />
            </NotificationItem>
          );
        })}
        {recent ? (
          !recentData && loading && displayLoading(loading)
        ) : (
          <>
            {loading ? (
              displayLoading(loading)
            ) : (
              <div style={{ padding: '20px 0px' }} />
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {renderContent(true)}
      {renderContent(false)}
      <ModalConfirmation
        open={openReadAll.isOpen}
        title="Mark as Read?"
        onCancel={closeReadAll}
        content="Are you sure want to mark as read your notification?
This module need further agreement."
        textPrimary="Submit"
        onSubmit={() => onSubmitReadAll()}
      />
    </>
  );
};

export default withRouter(NotificationActivityComponent);
