import React from 'react';
import { ThypoStyled } from '../DetailUserSection/DetailUserSection.style';

export const ShowMoreText = ({ label, onClick }) => {
  return (
    <ThypoStyled
      color="#039be5"
      fontSize="16px"
      fontWeight="600"
      cursor="pointer"
      margin="0px 0px 23px 0px"
      onClick={onClick}
    >
      {label}
    </ThypoStyled>
  );
};
