import React from 'react';
import { Row, Col } from 'antd';
import { SettingClassroomBox } from '../../../Containers';
import { Mentorship } from '../../../Containers';

const SettingScreenComponent = props => {
  const title = 'Aturan Kelompok Belajar';
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={18}>
          <SettingClassroomBox title={title} {...props} />
        </Col>
        <Col span={6}>
          <Mentorship {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default SettingScreenComponent;
