import React from 'react';
import { Follower } from '../../Containers';

const menuBarList = [
  {
    renderComponent: props => (
      <Follower followsCategory={'Followers'} {...props} />
    ),
    name: 'Followers',
    path: '/followers/:profileId/'
  },
  {
    renderComponent: props => (
      <Follower followsCategory={'Following'} {...props} />
    ),
    name: 'Following',
    path: '/following/:profileId/'
  }
];

const defaultMenuBar = 'Followers';

export default {
  menuBarList,
  defaultMenuBar
};
