import React from 'react';
import { WrapperAttachment } from './PostAttachment.style';
import { Button } from 'antd';
import {
  displayFileTypeImage,
  displayFileDesc
} from '../../Utils/displayFileTypeImage';

const PostAttachmentComponent = ({ file }) => {
  const _Attachment = () =>
    file.map((item, index) => {
      const name = item?.name;
      return (
        <div className="item" key={index}>
          <div className="thumbnail">
            <img src={displayFileTypeImage(item.url)} alt="thumbnail" />
          </div>
          <div className="info">
            <span className="name">{name}</span>
            <span className="desc">{displayFileDesc(item.name)}</span>
          </div>
          <div className="action">
            <Button href={item.url} target="_blank">
              <span className="icon download" />
            </Button>
          </div>
        </div>
      );
    });

  return <WrapperAttachment>{_Attachment()}</WrapperAttachment>;
};

export default PostAttachmentComponent;
