import styled from 'styled-components';
import Assets from '../../../Assets';

export const ReadyScreenWrap = styled.div`
  text-align: center;
  font-family: ${Assets.FONT.MULI};
  margin-top: 40px;
  position: relative;

  p {
    margin: 0;
    font-size: 11px;
  }

  .box-finish {
    position: relative;
    width: 37%;
    background: ${Assets.COLOR_PALLETE.white};
    height: 250px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border: 3px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);

    h1 {
      font-size: 22px;
      color: ${Assets.COLOR_PALLETE.blue};
      font-family: ${Assets.FONT.MULI_BOLD};
      padding-top: 33px;
    }
  }

  .anticon {
    width: 100px;
    height: 80px;
    color: ${Assets.COLOR_PALLETE.blue};
    svg {
      width: 100%;
      height: 100%;
    }
  }

  button {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: unset;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: ${Assets.COLOR_PALLETE.blue};
    border-color: ${Assets.COLOR_PALLETE.blue};
  }
`;
