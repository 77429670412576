import styled from 'styled-components';
import Assets from '../../Assets';
import { Typography, Divider, Button, Row } from 'antd';
import { Link } from 'react-router-dom';

export const MemberListWrap = styled.div`
  background: ${Assets.COLOR_PALLETE.white};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0px 4.3%;
  padding-bottom: 40px !important;
`;

export const BlackTitle = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  line-height: 1.47;
  color: #000 !important;
`;

export const BlackSubTitle = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  line-height: 1.47;
  color: #000000 !important;
  padding: 15px 0px;
`;

export const GreySubTitle = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  line-height: 1.47;
  color: #a9a8a8 !important;
  padding: 15px 0px;
`;

export const BlueSubTitle = styled(Typography)`
  font-size: 15px;
  font-weight: 600;
  line-height: 1.47;
  color: #014a62 !important;
  padding: 15px 0px;
  text-align: left;
`;

export const BlueLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.47;
  color: #039be5;
`;

export const CustomDivider = styled(Divider)`
  margin: 3% 0px;
`;

export const AverageRating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .py3 {
    padding: 3px 0px;
  }
`;

export const VeryJumboTitle = styled(Typography)`
  font-size: 53px;
  font-weight: 400;
  line-height: 1.47;
  color: #000 !important;
`;
export const TextGrey = styled(Typography)`
  font-size: 11px;
  color: #a9a8a8 !important;
  line-height: 0.73;
`;

export const TextSmallGrey = styled(Typography)`
  font-size: 9px;
  color: #414141 !important;
`;

export const FlexBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FlexEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilterRatingButton = styled(Button)`
  border-radius: 5px;
  border: solid 1px #a9a8a8;
  padding: 1px 10px 2px 10px !important;
  margin: 0px 4px;
`;

export const VerticalDivider = styled.div`
  height: 70%;
  border: solid 1px #a9a8a8;
  margin: auto 4px;
`;

export const ReviewListWrapper = styled(Row)`
  margin: 10px 0px !important;

  .px10 {
    padding: 0px 10px;
  }

  .mb8 {
    margin-bottom: 8px;
  }

  .avatar {
    margin-right: 10px;
  }

  .name {
    font-weight: bold;
    color: #4d4f5c !important;
    font-size: 12px;
  }
`;

export const ChildReview = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 10.3px 27px 5.9px 13.2px;
  background-color: #f7f8f9;
`;

export const BigStarWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const ButtonLink = styled(Button)`
  width: 94px;
  padding: 0px !important;
  margin-left: -2px !important;
`;
