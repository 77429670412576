import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import EditSummary from './EditSummary';

export default function Summary({ summary, isUser, refetch, profileId }) {
  const [open, setOpen] = useState({
    open: false
  });

  return (
    <>
      <DetailUserSection
        title="Ringkasan"
        onClickAction={() => setOpen({ open: true })}
        action={isUser ? 'edit' : null}
      >
        {summary?.people_profiles?.[0]?.profile_fields?.summary && (
          <div style={{ margin: '28px 0px' }} id="summary_people_profile">
            {summary?.people_profiles?.[0]?.profile_fields?.summary ?? '-'}
          </div>
        )}
      </DetailUserSection>
      <EditSummary
        profileId={profileId}
        refetch={refetch}
        open={open.open}
        data={summary?.people_profiles?.[0]?.profile_fields?.summary ?? '-'}
        onClose={() => setOpen({ open: false })}
        setOpen={setOpen}
      />
    </>
  );
}
