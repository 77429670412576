import React from 'react';
import PeopleScreen from './PeopleScreen';
import GroupScreen from './TeamScreen';
import AllScreen from './AllResultScreen';
import CompanyScreen from './CompanyScreen';
import LearningScreen from './Learning';
import { Row, Col } from 'antd';
import { Widget } from '../../Containers';
const SearchLayout = props => {
  return (
    <Row gutter={16}>
      <Col span={18}>{props.children}</Col>
      <Col span={6} style={{ marginTop: '15px' }}>
        <Widget {...props} />
      </Col>
    </Row>
  );
};
const searchBarList = [
  {
    renderComponent: props => (
      <SearchLayout>
        <AllScreen {...props} />
      </SearchLayout>
    ),
    name: 'All',
    path: '/search-result/all/:params'
  },
  {
    renderComponent: props => (
      <SearchLayout>
        <PeopleScreen searchCategory={'People'} {...props} />
      </SearchLayout>
    ),
    name: 'People',
    path: '/search-result/people/:params'
  },
  {
    renderComponent: props => (
      <SearchLayout>
        <CompanyScreen
          id={'companySearch'}
          searchCategory={'Company'}
          {...props}
        />
      </SearchLayout>
    ),
    name: 'Company',
    path: '/search-result/company/:params'
  },
  {
    renderComponent: props => (
      <SearchLayout>
        <GroupScreen searchCategory={'Group'} {...props} />
      </SearchLayout>
    ),
    name: 'Group',
    path: '/search-result/group/:params'
  },
  {
    renderComponent: props => (
      <SearchLayout>
        <LearningScreen searchCategory={'Learning'} {...props} />
      </SearchLayout>
    ),
    name: 'Learning',
    path: '/search-result/learning/:params'
  }
  // {
  //   renderComponent: (props) => <PeopleScreen searchCategory={"Job"} {...props}/>,
  //   name: 'Job',
  //   path:'/search-result/job'
  // }
];

const defaultMenuBar = 'All';

export default {
  searchBarList,
  defaultMenuBar
};
