import React from 'react';
import PostCardContainer from './PostCard.container';
import Global from './global';
import User from './userProfile';
import Group from './team';
import CompanyPost from './companyPost';
import CompanyTimeline from './companyTimeline';
import Constant from '../../Config/Constants';
import Classroom from './classroom';

function PostCard(props) {
  const postType = props.postType;
  switch (postType) {
    case Constant.POST_TYPE.GLOBAL_TIMELINE:
      return <Global {...props} />;
    case Constant.POST_TYPE.PROFILE:
      return <User {...props} />;
    case Constant.POST_TYPE.TEAM:
      return <Group {...props} />;
    case Constant.POST_TYPE.COMPANY:
      return <CompanyPost {...props} />;
    case Constant.POST_TYPE.COMPANY_TIMELINE:
      return <CompanyTimeline {...props} />;
    case Constant.POST_TYPE.CLASSROOM:
      return <Classroom {...props} />;
    default:
      return <PostCardContainer {...props} />;
  }
}
export default PostCard;
