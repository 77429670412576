export const LIST_BLOOD_TYPE = [
  {
    id: "A",
    name: "A",
  },
  {
    id: "B",
    name: "B",
  },
  {
    id: "AB",
    name: "AB",
  },
  {
    id: "O",
    name: "O",
  },
];
