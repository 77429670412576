import React, { useCallback, useEffect, useState } from 'react';
import MemberCardBoxComponent from './MemberCardBox.component';
import { message } from 'antd';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useLazyQuery } from 'react-apollo';
import _ from 'lodash';
import { getCookie } from '../../Utils/CommonUtils';
import {
  REMOVE_MEMBER,
  MEMBER_REQUEST_LIST,
  SET_AS_ADMIN,
  SET_AS_MEMBER,
  LEAVE_CLASSROOM,
  APPROVE_MEMBER,
  DECLINE_MEMBER,
  ADD_LEARNING_CONTRIB,
  CONTRIBUTOR_LIST,
  CANCEL_LEARNING_CONTRIB,
  MEMBER_LIST_ADMIN,
  MEMBER_LIST_CONTRIBUTOR,
  MEMBER_LIST_PARTICIPANT
} from './graphql';
import FollowPageServices from '../../Services/FollowPage';

const MemberCardBoxContainer = props => {
  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const token = _.isEmpty(userData) ? '' : userData.oauth.access_token;
  const CONTEXT_USER = {
    headers: { 'X-Hasura-Role': 'user', Authorization: `Bearer ${token}` }
  };

  const { isAdmin, refetchContribData } = props;
  const params = useParams();
  const classroomId = params.id;

  const [following, setFollowing] = useState([]);
  const [friendshipLoading, setFriendshipLoading] = useState(false);
  const [requestMember, setRequestMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [contributorCandidates, setContributorCandidates] = useState(null);
  const [selectedContrib, setSelectedContrib] = useState(null);
  const [openModalContrib, setOpenModalContrib] = useState(false);
  const [modalConfirm, setModalConfirm] = useState({
    open: false,
    title: '',
    content: '',
    textPrimary: '',
    isAlert: false
  });

  const [mutationSetAsAdmin] = useMutation(SET_AS_ADMIN);
  const [mutationSetAsMember] = useMutation(SET_AS_MEMBER);
  const [approveRequest] = useMutation(APPROVE_MEMBER);
  const [rejectRequest] = useMutation(DECLINE_MEMBER);
  const [removeMember] = useMutation(REMOVE_MEMBER, { context: CONTEXT_USER });
  const [leaveClassroom] = useMutation(LEAVE_CLASSROOM, {
    context: CONTEXT_USER
  });
  const [addLearningContributor] = useMutation(ADD_LEARNING_CONTRIB);
  const [cancelLearningContributor] = useMutation(CANCEL_LEARNING_CONTRIB);

  const {
    data: listAdmin,
    refetch: refetchAdmin,
    loading: loadingAdmin
  } = useQuery(MEMBER_LIST_ADMIN, {
    variables: {
      classroom_id: {
        _eq: classroomId
      }
    },
    context: CONTEXT_USER
  });

  const {
    data: listContributor,
    refetch: refetchListContributor,
    loading: loadingListContributor
  } = useQuery(MEMBER_LIST_CONTRIBUTOR, {
    variables: {
      classroom_id: {
        _eq: classroomId
      }
    },
    context: CONTEXT_USER
  });

  const {
    data: listParticipant,
    refetch: refetchParticipant,
    loading: loadingParticipant
  } = useQuery(MEMBER_LIST_PARTICIPANT, {
    variables: {
      classroom_id: {
        _eq: classroomId
      }
    },
    context: CONTEXT_USER
  });

  const mappedMember = val => ({
    ...val.global_user,
    created_at: val.created_at,
    followed: following.some(item => item.id === val.global_user.id)
  });

  const admin =
    listAdmin?.classroom_members.map(val => mappedMember(val)) || [];
  const mentor =
    listContributor?.classroom_members.map(val => mappedMember(val)) || [];
  const member =
    listParticipant?.classroom_members.map(val => mappedMember(val)) || [];

  const refetchMember = useCallback(() => {
    refetchAdmin();
    refetchListContributor();
    refetchParticipant();
  }, [refetchAdmin, refetchListContributor, refetchParticipant]);

  const loadingMember =
    loadingAdmin || loadingListContributor || loadingParticipant;

  const { data: listRequest, refetch: refetchRequest } = useQuery(
    MEMBER_REQUEST_LIST,
    {
      variables: {
        classroom_id: classroomId
      },
      context: CONTEXT_USER
    }
  );

  const [
    searchContribCandidate,
    { data: contribCandidates, loading: loadingContribCandidate }
  ] = useLazyQuery(CONTRIBUTOR_LIST);

  const fetchFollowing = async () => {
    setFriendshipLoading(true);
    await FollowPageServices.fetchFollowedPeopleByUserId()
      .then(res => setFollowing(res.data))
      .catch(err => console.error(err))
      .finally(() => setFriendshipLoading(false));
  };

  useEffect(() => {
    fetchFollowing();
    refetchMember();
  }, [refetchMember]);

  useEffect(() => {
    if (listRequest) {
      setRequestMember(listRequest.classroom_member_requests);
    }
    // eslint-disable-next-line
  }, [listRequest]);

  useEffect(() => {
    searchContribCandidate({
      variables: {
        offset: 0,
        limit: 10,
        contributorLabel: null,
        searchQuery: `%%`
      }
    });
  }, [searchContribCandidate]);

  useEffect(() => {
    if (contribCandidates) {
      setContributorCandidates(contribCandidates);
    }
  }, [contribCandidates]);

  const handleSetAsAdmin = item => {
    const _variables = { classroomId: classroomId, memberUserId: item.id };
    mutationSetAsAdmin({ variables: _variables, context: CONTEXT_USER }).then(
      () => {
        message.success(`${item.name} has became an admin`);
        refetchMember();
      }
    );
  };

  const handleSetAsMember = item => {
    const _variables = { classroomId: classroomId, memberUserId: item.id };
    mutationSetAsMember({ variables: _variables, context: CONTEXT_USER })
      .then(() => {
        message.success(`${item.name} has became a member`);
        refetchMember();
      })
      .catch(err => {
        const errorMessage = err.message.split(':');
        message.error(errorMessage[errorMessage.length - 1]);
      });
  };

  const handleKickMember = (_, item) => {
    removeMember({
      variables: { classroomId, memberUserId: item.id }
    })
      .then(() => {
        message.success(`${item.name} has removed from classroom`);
        refetchMember();
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  const handleLeaveMember = () => {
    const adminValidation = isAdmin && admin.length === 1;
    if (!adminValidation) {
      leaveClassroom({
        variables: { classroomId: classroomId },
        context: CONTEXT_USER
      }).then(() => {
        refetchMember();
        message.success('Leave classroom success');
        props.history.push(`/social-learning/${classroomId}/member`);
      });
    } else message.error('Please assign 1 more admin to leave classroom');
  };

  const approveMember = acceptId => {
    setIsLoading(true);
    approveRequest({
      variables: {
        classroomId: classroomId,
        newMemberId: acceptId
      },
      context: CONTEXT_USER
    })
      .then(() => {
        message.success('Add member success');
        setIsLoading(false);
        refetchRequest();
        refetchMember();
      })
      .catch(() => message.error('Something went wrong'));
  };

  const rejectMember = rejectId => {
    setIsLoading(true);
    rejectRequest({
      variables: {
        classroomId: classroomId,
        newMemberId: rejectId
      },
      context: CONTEXT_USER
    })
      .then(() => {
        message.success('Reject member success');
        setIsLoading(false);
        refetchRequest();
        refetchMember();
      })
      .catch(() => message.error('Something went wrong'));
  };

  const clearModalConfirm = () => {
    setModalConfirm({
      open: false,
      title: '',
      content: '',
      textPrimary: '',
      isAlert: false
    });
  };

  const onSubmitContrib = item => {
    setSelectedContrib(item);
    setOpenModalContrib(false);
    setModalConfirm({
      open: true,
      title: 'Request New Learning Contributor?',
      content: 'Are you sure you want to request new learning contributor?',
      textPrimary: 'Confirm',
      isAlert: false
    });
  };

  const addLearningContrib = () => {
    const variables = {
      classroomId,
      contribUserId: selectedContrib.id
    };
    addLearningContributor({ variables })
      .then(() => {
        refetchContribData();
        message.success('Add learning contributor success');
        setSelectedContrib(null);
        setOpenModalContrib(false);
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => clearModalConfirm());
  };

  const onSearchContrib = value => {
    searchContribCandidate({
      variables: {
        offset: 0,
        limit: 10,
        contributorLabel: null,
        searchQuery: `%${value}%`
      }
    });
  };

  const onCancelContrib = item => {
    setSelectedContrib(item);
    setModalConfirm({
      open: true,
      title: 'Cancel Learning Contributor Invitation?',
      content: `Are you sure you want to cancel invite ${item?.contrib?.name} 
      to become Learning contributor for this social learning?`,
      textPrimary: 'Cancel',
      isAlert: true
    });
  };

  const cancelContribRequest = () => {
    cancelLearningContributor({ variables: { requestId: selectedContrib.id } })
      .then(() => {
        refetchContribData();
        message.success('Learning Contributor request canceled');
        setSelectedContrib(null);
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => clearModalConfirm());
  };

  const onFollowUser = async (followed, userId) => {
    setFriendshipLoading(true);
    if (!followed) {
      await FollowPageServices.followUser(userId)
        .then(() => {
          message.success('Success follow user');
          fetchFollowing();
        })
        .catch(err => console.error(err))
        .finally(() => setFriendshipLoading(false));
    } else {
      await FollowPageServices.unFolloweUser(userId)
        .then(() => {
          message.success('Success unfollow user');
          fetchFollowing();
        })
        .catch(err => console.error(err))
        .finally(() => setFriendshipLoading(false));
    }
  };

  return (
    <MemberCardBoxComponent
      handleSetAsAdmin={handleSetAsAdmin}
      handleSetAsMember={handleSetAsMember}
      handleKickMember={handleKickMember}
      handleLeaveMember={handleLeaveMember}
      memberLoading={loadingMember}
      admin={admin}
      mentor={mentor}
      member={member}
      requestMember={requestMember}
      approveMember={approveMember}
      rejectMember={rejectMember}
      isLoading={isLoading}
      contribCandidates={contributorCandidates}
      loadingContribCandidate={loadingContribCandidate}
      openModalContrib={openModalContrib}
      setOpenModalContrib={setOpenModalContrib}
      modalConfirm={modalConfirm}
      clearModalConfirm={clearModalConfirm}
      onSubmitContrib={onSubmitContrib}
      mutationLearningContrib={
        modalConfirm.isAlert ? cancelContribRequest : addLearningContrib
      }
      setSearchContrib={onSearchContrib}
      onCancelContrib={onCancelContrib}
      friendshipLoading={friendshipLoading}
      onFollowUser={onFollowUser}
      {...props}
    />
  );
};

export default MemberCardBoxContainer;
