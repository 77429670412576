import Collapse from 'antd/es/collapse';
import { Button, Form, Input, Spin } from 'antd';
import React from 'react';
import { LoginAndSecurityPanel } from './LoginAndSecuritySettings.style';

const { Panel } = Collapse;

const LoginAndSecurityConfigComponent = props => {
  const {
    handleSubmit,
    validateNewPassword,
    validateToNextPassword,
    handleForgotPassword,
    isLoading
  } = props;
  const { getFieldDecorator } = props.form;
  // const newPassError =
  //   isFieldTouched('newPassword') && getFieldError('newPassword');
  // const confirmPassError =
  //   isFieldTouched('confirmPassword') && getFieldError('confirmPassword');
  return (
    <LoginAndSecurityPanel>
      <Collapse
        defaultActiveKey={['2']}
        expandIconPosition={'right'}
        className={'login-and-security__settings'}
      >
        {/*<Panel header="Manage Email Address" key="1" disabled={true}/>*/}
        <Panel header="Kelola Kata Sandi" key="2">
          <Form layout={'vertical'} className={'manage-password'}>
            <Form.Item label="Kata Sandi Sekarang">
              {getFieldDecorator('currentPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Silakan masukkan Kata Sandi Anda!'
                  }
                ]
              })(
                <Input.Password
                  placeholder="Kata Sandi Sekarang"
                  className="password-input"
                />
              )}
            </Form.Item>

            <Form.Item
              label="Kata Sandi Baru"
              // validateStatus={newPassError ? 'error' : ''}
            >
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Silakan masukkan Kata Sandi Baru Anda!'
                  },
                  {
                    validator: validateToNextPassword
                  }
                ]
              })(
                <Input.Password
                  placeholder="Kata Sandi Baru"
                  className="password-input"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Konfirmasi Kata Sandi Baru"
              // validateStatus={confirmPassError ? 'error' : ''}
            >
              {getFieldDecorator('confirmPassword', {
                rules: [
                  {
                    required: true,
                    message: 'Silakan konfirmasi dengan Kata Sandi Baru Anda!!'
                  },
                  {
                    validator: validateNewPassword
                  }
                ]
              })(
                <Input.Password
                  placeholder="Konfirmasi Kata Sandi Baru"
                  className="password-input"
                />
              )}
            </Form.Item>
            {/* <div style={{ marginBottom: 20 }}>
              <button
                id={'forget-password'}
                onClick={() => handleForgotPassword()}
                disabled={isLoading}
                style={{ marginRight: 10 }}
              >
                Forgot My Password
              </button>
              <Spin size="small" spinning={isLoading} />
            </div> */}

            <Form.Item>
              <Button type="primary" onClick={handleSubmit}>
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </LoginAndSecurityPanel>
  );
};

export { LoginAndSecurityConfigComponent };
