import gql from 'graphql-tag';

export const GET_IDENTITY = gql`
  query($userId: uuid!) {
    people_identities(
      where: {
        _and: [
          { user: { _eq: $userId } }
          { deletedAt: { _is_null: true } }
          {
            type: {
              _in: [
                "KTP"
                "SIM A"
                "SIM B1"
                "SIM B2"
                "SIM C"
                "SIM D"
                "NPWP"
                "PASSPORT"
              ]
            }
          }
        ]
      }
      order_by: [{ date_added: desc_nulls_last }]
    ) {
      id
      type
      user
      identity_fields
      deletedAt
      global_user {
        name
        email
      }
    }
  }
`;

export const GET_PROVINCE_AND_CITY = gql`
  query($province: Int, $city: Int) {
    global_provinces(where: { id: { _eq: $province } }) {
      id
      name
    }

    global_cities(where: { id: { _eq: $city } }) {
      id
      name
    }
  }
`;

export const GET_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      name
    }
  }
`;

export const GET_CITY = gql`
  query($prov: Int) {
    global_cities(
      where: { province: { _eq: $prov } }
      order_by: { name: asc }
      limit: 50
    ) {
      id
      name
    }
  }
`;
export const GET_CITY_BY_PROV_NAME = gql`
  query($prov: String) {
    global_cities(
      where: { global_province: { name: { _eq: $prov } } }
      order_by: { name: asc }
      limit: 50
    ) {
      id
      name
      global_province {
        id
        name
      }
    }
  }
`;

export const UPDATE_IDENTITY_CARD = gql`
  mutation(
    $id: String!
    $new_card_id: String!
    $type: String!
    $new_type: String!
    $identityFields: jsonb
  ) {
    update_people_identities(
      _set: { type: $new_type, id: $new_card_id }
      _append: { identity_fields: $identityFields }
      where: { id: { _eq: $id }, type: { _eq: $type } }
    ) {
      affected_rows
    }
  }
`;
export const ADD_IDENTITY_CARD = gql`
  mutation($type: String!, $id: String!, $identity_fields: jsonb) {
    insert_people_identities(
      objects: { id: $id, type: $type, identity_fields: $identity_fields }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_IDENTITY_CARD = gql`
  mutation($type: String!, $id: String!) {
    update_people_identities(
      _set: { deletedAt: "now()" }
      where: {
        _and: [
          { type: { _eq: $type } }
          { id: { _eq: $id } }
          { deletedAt: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

export const CHECKER_DUPLICATE = gql`
  query CheckerIdentities($id: String, $user: uuid, $type: String) {
    people_identities(
      where: { id: { _eq: $id }, user: { _eq: $user }, type: { _eq: $type } }
    ) {
      id
    }
  }
`;

export const UPDATE_WITH_ADD_QUERY = gql`
  mutation update_people_identities(
    $id: String
    $changes: people_identities_set_input
  ) {
    update_people_identities(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
    }
  }
`;

export const GET_KTP_NPWP_FROM_LATEST_SALARY = gql`
  query($user: uuid!) {
    people_work_salaries(
      where: { people_work_placement: { user: { _eq: $user } } }
      order_by: [{ id: desc }]
      limit: 2
    ) {
      id
      ktp
      npwp
    }
  }
`;

export const UPDATE_KTP = gql`
  mutation($id: Int!, $id_number: String) {
    update_people_work_salaries_by_pk(
      pk_columns: { id: $id }
      _set: { ktp: $id_number }
    ) {
      id
      value
      start_date
      end_date
      ktp
      npwp
    }
  }
`;

export const UPDATE_NPWP = gql`
  mutation($id: Int!, $id_number: String) {
    update_people_work_salaries_by_pk(
      pk_columns: { id: $id }
      _set: { npwp: $id_number }
    ) {
      id
      value
      start_date
      end_date
      ktp
      npwp
    }
  }
`;

export const DELETE_NPWP_PWS = gql`
  mutation($user_id: uuid!) {
    update_people_work_salaries(
      where: { people_work_placement: { user: { _eq: $user_id } } }
      _set: { npwp: null }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_KTP_PWS = gql`
  mutation($user_id: uuid!) {
    update_people_work_salaries(
      where: { people_work_placement: { user: { _eq: $user_id } } }
      _set: { ktp: null }
    ) {
      affected_rows
    }
  }
`;
