const defaultProps = {
  listType: ''
};

const fileTypeXls =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const fileTypePpt = 'application/vnd.ms-powerpoint';
const acceptedFormat = [
  'txt',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx'
];

export default {
  defaultProps,
  fileTypeXls,
  acceptedFormat,
  fileTypePpt
};
