import Constants from '../../Config/Constants';

const text = {
  fontSize: 11
};

export default {
  wrapper: {
    marginBottom: 18
  },
  center: {
    height: 57,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  img: {
    height: 50,
    width: 50,
    borderRadius: '50%'
  },
  name: {
    ...text,
    fontWeight: 'bold'
  },
  description: {
    ...text,
    color: Constants.COLORS.GREY
  }
};
