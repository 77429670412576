import React from 'react';
import { Select, Spin, Avatar } from 'antd';
import { Wrap } from './InviteMemberGroup.style';
import ButtonWlb from '../../Components/ButtonWlb';

const InviteMemberGroupComponent = props => {
  const {
    users,
    loading,
    inviteHandler,
    searchHandler,
    changeHandler,
    value,
    isFetching
  } = props;

  let timeout = 0;
  const _Search = value => {
    const keyword = value.trim();
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      searchHandler(keyword);
    }, 1000);
  };

  const option = users.map(user => (
    <Select.Option key={user.id} label={user.name} value={user.id}>
      <Avatar src={user.avatar} />
      &nbsp;{user.name}
    </Select.Option>
  ));

  return (
    <Wrap>
      <p className="heading">Add Member</p>
      <Select
        className="select"
        showArrow={false}
        // value={value}
        showSearch
        onSearch={_Search}
        onChange={value => changeHandler(value)}
        filterOption={false}
        optionLabelProp="label"
        labelInValue
        notFoundContent={isFetching ? <Spin size="small" /> : 'Tidak ada data'}
      >
        {option}
      </Select>
      <ButtonWlb
        type="primary"
        disabled={!value}
        loading={loading}
        style={{ align: 'center', marginTop: '10px' }}
        onClick={inviteHandler}
      >
        Add
      </ButtonWlb>
    </Wrap>
  );
};

export default InviteMemberGroupComponent;
