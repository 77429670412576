import gql from 'graphql-tag';

const GET_MAIN_PROFILE = gql`
  query getProfile($id: uuid!) {
    main_profile: global_users(where: { id: { _eq: $id } }) {
      id
      name
      avatar
      email
      biography
      people_work_placements {
        id
        company_employee_position {
          id
          name
        }
        company_address {
          id
          global_city {
            id
            name
          }
          global_province {
            id
            name
          }
        }
      }
    }
    working_status: people_work_placements(
      where: {
        user: { _eq: $id }
        status: { _in: ["ACTIVE", "REGISTERED", "INACTIVE"] }
        deletedAt: { _is_null: true }
      }
      order_by: [{ id: desc }]
      limit: 1
    ) {
      id
      status
      company
      company_profile {
        id
        legal_name
        brand_name
      }
      company_job_profile {
        id
        title
        company_address {
          id
          office_name
          global_city {
            id
            name
          }
          global_province {
            id
            name
          }
        }
      }
      company_employee_position {
        id
        name
        position_fields
      }
    }
    educations: people_profile_educations(where: { user: { _eq: $id } }) {
      id
      company_profile {
        id
        legal_name
      }
    }
    profile_about: people_profile(where: { id: { _eq: $id } }) {
      id
      is_verified
      social_links
      gender
      birthdate
      religion
      marital_status
      blood_type
      birthplace_province
      birthplace_city
      birthplace_province_name
      birthplace_city_name
      global_user {
        id
        name
        main_education
        people_profile_educations {
          id
          school
        }
      }
      profile_fields
    }

    profile_phone: people_phones(where: { user: { _eq: $id } }) {
      id
      country_code
      phone
    }
    profile_address: people_addresses(where: { user: { _eq: $id } }) {
      id
      address
      postal_code
      global_city {
        id
        name
      }
      global_province {
        id
        name
      }
    }
  }
`;

const GET_USER_DETAIL = gql`
  query getProfile($id: uuid!) {
    main_profile: global_users(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

const GET_USER_BADGE = gql`
  query getBadgeUser($id: uuid!) {
    talent_assignments(
      where: { user: { _eq: $id } }
      order_by: [{ id: desc }]
    ) {
      id
      talent_box {
        id
        badge
      }
    }
  }
`;

export { GET_MAIN_PROFILE, GET_USER_DETAIL, GET_USER_BADGE };
