import Constants from '../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../Assets';
import { Checkbox } from 'antd';

export const OptionItemWrapper = styled.div`
  font-family: ${Assets.FONT.MULI};
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey2};
  padding: 8px 15px;

  .component_item_title {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
  }

  .component_item_description {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export const UserListWrapper = styled.div`
  padding-top: '10px';
  padding-bottom: '10px';
  border: 1px solid black

  .justify-between: {
    justify-content: 'space-between';
  }
`;

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Constants.COLORS.DARK_BLUE};
    border-color: ${Constants.COLORS.DARK_BLUE};
  }
`;

export default {
  title: {
    marginTop: 17.8,
    marginBottom: 9.5,
    fontSize: 12,
    color: Constants.COLORS.GREY
  },
  selectedBox: {
    paddingTop: 9.5,
    paddingBottom: 9.5,
    paddingLeft: 12.5,
    paddingRight: 12.5,
    marginBottom: 25,
    backgroundColor: Constants.COLORS.DARK_GREY
  },
  select: {
    width: '100%',
    height: 25,
    fontSize: 12,
    color: Constants.COLORS.BLACK
  },
  autocomplete: {
    width: '100%',
    height: 25,
    fontSize: 12,
    color: Constants.COLORS.BLACK
  },
  optionCompany: {
    fontSize: 12,
    color: Constants.COLORS.BLACK
  },
  optionTask: {
    padding: 0,
    margin: 0
  },
  optionChildrenStyle: {
    rowTask: {
      paddingTop: 8,
      paddingLeft: 12,
      paddingRight: 12
    },
    imgTask: {
      width: 44,
      height: 44
    },
    fromTask: {
      height: 11,
      fontSize: 10,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    },
    asTask: {
      height: 11,
      fontSize: 10,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    }
  },
  optionSelectedStyle: {
    rowTask: {
      padding: 0
    },
    imgTask: {
      width: 44,
      height: 44
    },
    titleTask: {
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 2.5
    },
    fromTask: {
      height: 13,
      fontSize: 12,
      color: Constants.COLORS.DARK_BLUE,
      marginBottom: 1.5
    },
    asTask: {
      height: 13,
      fontSize: 12,
      color: Constants.COLORS.GREY,
      marginBottom: 1.5
    }
  },
  subjectInput: {
    width: '100%',
    height: 30
  },
  durationInput: {
    width: '30%',
    height: 30
  },
  menteeAvatar: {
    marginRight: 20,
    border: '1px solid #a9a8a8'
  },
  menteeWrapper: {
    marginBottom: 30
  },
  btnPlus: {
    backgroundColor: '#fff',
    marginRight: 20,
    color: Constants.COLORS.DARK_BLUE,
    border: `1px solid ${Constants.COLORS.GREY}`,
    cursor: 'pointer'
  },
  actionModalWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginBottom: 0,
    paddingTop: '20px',
    borderTop: '0.5px solid #d0d4d4',
    marginTop: 22
  },
  userListWrapper: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  btnSubmitMentee: {
    backgroundColor: Constants.COLORS.DARK_BLUE,
    color: '#fff'
  },
  justifyBetween: {
    justifyContent: 'space-between'
  },
  menteeCheckbox: {
    color: Constants.COLORS.DARK_BLUE
  },
  fontBold: {
    fontFamily: Assets.FONT.MULI_BOLD,
    color: `#252525`,
    fontSize: '12px',
    marginTop: '3px'
  }
};
