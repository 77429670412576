import Axios from 'axios';
import Auth from './Auth';

function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=');
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      let c_end = document.cookie.indexOf(';', c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return '';
}

const getTokenAuth = () => {
  if (getCookie('userData') !== '' && getCookie('userData') !== 'undefined') {
    //console.log(JSON.parse(getCookie('userData')).oauth.access_token)  ;
    return JSON.parse(getCookie('userData')).oauth.access_token;
  }
  return '';
};

const createAxiosInstance = url => {
  const instance = Axios.create({
    baseURL: url,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'es',
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });

  instance.interceptors.request.use(
    config => {
      config.headers.Authorization = `Bearer ${getTokenAuth()}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    response => {
      return response.data;
    },
    error => {
      if (error.response && error.response.status === 401) {
        Auth.removeToken();
        window.location.reload();
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const BaseService = createAxiosInstance(process.env.REACT_APP_BASE_URL_REST);
export const JwService = createAxiosInstance(
  process.env.REACT_APP_JW_PLATFORM_MEDIA_URL
);
export const UploadService = createAxiosInstance(
  process.env.REACT_APP_UPLOAD_URL
);

// export const TrendingTopicServices = axios.create({
//   baseURL: process.env.REACT_APP_TRENDING_TOPIC,
//   headers: {
//     Accept: 'application/json',
//     'Access-Control-Allow-Origin': '*',
//     'Content-Type': 'application/json'
//   }
// });

export default BaseService;
