import styled from 'styled-components';
import Assets from '../../../Assets';
import CONSTANTS from '../../../Config/Constants';

export const ContentWrap = styled.div`
  position: relative;
  padding: 15px 24px;
  background: ${Assets.COLOR_PALLETE.white};
  box-shadow: '0px 1.5px 3px 0px ' + ${CONSTANTS.COLORS.SHADOW};
  h2 {
    margin: 2 0;
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 16px;
    font-family: ${Assets.FONT.MULI};
  }

  p {
    margin: 0;
    color: ${Assets.COLOR_PALLETE.grey};
    font-size: 11px;
    font-family: ${Assets.FONT.MULI};
    margin-bottom: 24px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 130px;
  }

  .ant-row {
    margin-bottom: 23px;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      color: ${Assets.COLOR_PALLETE.grey};
      font-size: 11px;
      font-family: ${Assets.FONT.MULI};
      line-height: 2em;
    }
    .anticon {
      margin-right: 12px;
      color: ${Assets.COLOR_PALLETE.blue};
    }
  }
`;

export const Footer = styled.div`
  position: relative;
  text-align: right;
  margin-top: 50px;
  padding: 15px 24px;

  .ant-checkbox-wrapper {
    width: 100%;
  }
`;

export const ShopStatusWrap = styled.div`
  position: relative;
  margin-bottom: 24px;

  .ant-btn {
    margin-right: 24px;
  }
`;

export const WorkingHoursWrap = styled.div`
  position: relative;

  .ant-row {
    margin-bottom: 5px;
  }
`;

export const Wrapper = styled.div`
  box-shadow: 0 1.5px 3px 0 ${CONSTANTS.COLORS.SHADOW};
  font-family: ${Assets.FONT.MULI};
`;
