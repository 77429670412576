import moment from 'moment';

export const formatDate = date => moment(date).format('MMM YYYY');

export function accessRightNEWSwitcher(res) {
  let data = [];
  const _res = res.split('');
  if (_res[0] === '1') {
    data.push('Organization Administrator, ');
  }
  if (_res[1] === '1') {
    data.push('Organization Department Head, ');
  }
  if (_res[2] === '1') {
    data.push('Organization Finance Admin, ');
  }
  if (_res[3] === '1') {
    data.push('Organization HR_Admin, ');
  }
  if (_res[4] === '1') {
    data.push('Organization HR_Assistant, ');
  }
  if (_res[5] === '1') {
    data.push('Organization Payroll Master, ');
  }
  if (_res[6] === '1') {
    data.push('Organization Staff, ');
  }
  if (_res[7] === '1') {
    data.push('Organization Supervisor, ');
  }
  return data;
}

export const generateDateWorkingStatus = (start_date, end_date) => {
  if (start_date) {
    return `${formatDate(start_date)} - ${
      end_date
        ? formatDate(end_date) + ` (` + deferentDate(start_date, end_date) + ')'
        : `present (` + moment(start_date).toNow(true) + ')'
    }`;
  }
  return '-';
};

export const generateDateExperience = (start_date, end_date, isPresent) => {
  if (start_date) {
    return `${formatDate(start_date)} - ${
      !isPresent
        ? formatDate(end_date) + ` (` + deferentDate(start_date, end_date) + ')'
        : `present (` + moment(start_date).toNow(true) + ')'
    }`;
  }
  return '-';
};

const generateDegree = (degree, faculty) => {
  if (degree && faculty) {
    return `${degree} Degree, ${faculty}`;
  } else if (!degree) {
    return `${faculty}`;
  } else {
    return '-';
  }
};

const generateLocation = (city, province, country) => {
  if (country && city && province) {
    return `${city}, ${province}, ${country}`;
  } else if (city && province) {
    return `${city}, ${province}`;
  } else if (city) {
    return `${city}`;
  } else if (province) {
    return `${province}`;
  } else if (country) {
    return `${country}`;
  }
  return '-';
};

export const setData = (data, type) => {
  let newData = [];

  if (data && type === 'experiences') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.title ?? '-',
        company: res?.company_name ?? '-',
        time: generateDateExperience(res.from, res.to, res.is_present),
        location: res?.location?.experience_location ?? '-',
        description: res?.description ?? '-',
        country_code:
          res?.additional_fields?.referal_contact_phone?.country_code !== null
            ? res?.additional_fields?.referal_contact_phone?.country_code
            : '',
        phone:
          res?.additional_fields?.referal_contact_phone?.phone !== ''
            ? res?.additional_fields?.referal_contact_phone?.phone
            : '',
        dataEdit: res
      });
    });
  } else if (data && type === 'educations') {
    data.forEach(res => {
      let attach = [];
      if (res.education_fields !== null && res.education_fields) {
        res.education_fields.attachments &&
          res.education_fields.attachments.length >= 1 &&
          res.education_fields.attachments.map(row => {
            attach.push({
              url: row.url,
              name: row.name,
              size: row.size
            });
          });
      }

      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.company_profile?.school_name ?? '-',
        company: generateDegree(
          res.degree && res.degree.name,
          res.faculty && res.faculty.name
        ),
        time: generateDateWorkingStatus(res.start_date, res.end_date),
        location: res?.company_profile?.company_address,
        description: res?.description ?? '-',
        attachments: attach,
        detail_school: generateLocation(
          res?.education_fields?.city?.name,
          res?.education_fields?.province?.name,
          res?.education_fields?.country
        ),
        dataEdit: res
      });
    });
  } else if (data && type === 'licences') {
    data.forEach(res => {
      let attach = [];
      res.attachments &&
        res.attachments.length >= 1 &&
        res.attachments.map(row => {
          attach.push({
            url: row.link,
            name: row.name,
            size: row.size
          });
        });
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.name ?? '-',
        company: res?.issuer_name ?? '-',
        // company: res?.company_profile?.legal_name ?? "-",
        time: formatDate(res.issues_date),
        credential: `Credential ID ${
          res.credential_id ? res.credential_id : '-'
        }`,
        description: res?.description ?? '',
        url: res?.credential_url ?? '',
        attachments: attach,
        label: `${res?.level === null ? '' : res?.level} - ${
          res?.type === null ? '' : res?.type + ' Training'
        }`,
        dataEdit: {
          id: res?.id,
          title: res?.name ?? '-',
          company: res?.issuer_name ?? '-',
          credential_id: res?.credential_id,
          description: res?.description ?? '-',
          credential_url: res?.credential_url ?? '-',
          date_issued: res.issues_date,
          date_expired: res.expirations_date,
          can_expire: res.can_expire,
          level: res.level,
          type: res.type,
          attachments: res.attachments,
          is_shared: res.is_shared
        }
      });
    });
  } else if (data && type === 'skills') {
    data.forEach(res => {
      newData.push({
        levelIndicator: res?.people_skill_level?.name ?? '-',
        nameIndicator: res?.people_skill?.name ?? '-',
        name: res?.people_skill_level?.name ?? '-',
        // description: res?.description ?? '-',
        dataEdit: res,

        title: res?.people_skill_level?.name ?? '-',
        people_profile_skills: res?.people_skill?.people_profile_skills,
        count_people_skill:
          res?.people_skill?.people_profile_skills_aggregate?.aggregate.count,
        countpeople: res?.endorsements_skill_count?.aggregate?.count,
        has_endorse: res?.checker_user_login_endorsements?.length
      });
    });
  } else if (data && type === 'hobbies') {
    data.forEach(res => {
      newData.push({
        id: res?.id,
        is_share: res?.is_share,
        levelIndicator: res?.people_hobby?.icon,
        nameIndicator: res?.hobby_fields?.type ?? '-',
        title: `${res?.people_hobby?.hobby_category?.name ?? '-'} - ${res
          ?.people_hobby?.hobby_sub_category?.name ?? '-'}`,
        category: res?.people_hobby?.hobby_category?.id,
        sub_category: res?.people_hobby?.hobby_sub_category?.id,
        type: res?.hobby,
        description: res?.description ?? '-',
        detail: res?.hobby_fields?.details ?? [],
        people_profile_endorsements:
          res?.people_profile_hobby_endorsements ?? []
      });
    });
  } else if (data && type === 'projects') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.project_name ?? '-',
        company: arrayToString(res.creators),
        time: generateDateWorkingStatus(res.start_date, res.end_date),
        location: res.location ? res.location?.project_location : '-',
        description: res?.description ?? '-',
        project_url: res?.project_url ?? '-',
        associate_user: arrayToString(res.creators),
        dataEdit: res
      });
    });
  } else if (data && type === 'speaker') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.event_name ?? '-',
        company: res?.organizer ?? '-',
        // company: res?.company_profile?.legal_name ?? "-",
        // time: formatDate(res.date_issued),
        location: res?.location?.event_location,
        description: res?.description ?? '',
        roles: arrayToString(res.roles),
        total_participants: res?.total_participants ?? '0',
        dataEdit: res
      });
    });
  } else if (data && type === 'award') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.award_name ?? '-',
        company: res?.issuing_organization ?? '-',
        // company: res?.company_profile?.legal_name ?? "-",
        time: formatDate(res.reference_date),
        location: '',
        description: res?.remark ?? '',
        letter_number: res?.letter_number ?? '',
        certificate_number: res?.certificate_number ?? '-',
        reference_number: res?.reference_number ?? '',
        attachments: res?.attachments ?? [],
        dataEdit: res
      });
    });
  } else if (data && type === 'organization') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.position_name ?? '-',
        company: res?.organization_name ?? '-',
        time: generateDateWorkingStatus(res.start_date, res.end_date),
        location: generateLocation(res?.city?.name, res?.province?.name)
          ? `${generateLocation(res?.city?.name, res?.province?.name)}, ${
              res.country
            }`
          : '-',
        description: res?.position_description ?? '-',
        dataEdit: res
      });
    });
  } else if (data && type === 'disciplinaries') {
    data.forEach(res => {
      newData.push(res);
    });
  } else if (data && type === 'service') {
    data.forEach(res => {
      newData.push(res);
    });
  } else if (data && type === 'ohsa') {
    data.forEach(res => {
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.company_name ?? '-',
        description: res?.description ?? '-',
        attachments: res?.attachments,
        employee: res?.employee?.name ?? '-',
        time_ohsa: formatDateDetail(res.incident_date_time),
        dataEdit: res
      });
    });
  } else if (data && type === 'publication') {
    data.forEach(res => {
      let attach = [];
      if (res.attachments !== null && res.attachments) {
        res.attachments &&
          res.attachments.length >= 1 &&
          res.attachments.map(row => {
            attach.push({
              url: row.url,
              name: row.name,
              size: row.size
            });
          });
      }
      newData.push({
        avatar: res?.company_profile?.logo ?? undefined,
        title: res?.publication_title ?? '-',
        company: arrayToString(res?.authors),
        time: res?.publication_media ?? '-',
        date: formatDate(res?.publication_date),
        attachments: attach,
        description: res?.description ?? '-',
        dataEdit: res
      });
    });
  }
  return newData;
};

export function accessRightSwitcher(res) {
  let accessRight = '';
  const _res = res.split('');
  if (_res[0] === '1') {
    accessRight += 'Organization Administrator, ';
  }
  if (_res[1] === '1') {
    accessRight += 'Organization Department Head, ';
  }
  if (_res[2] === '1') {
    accessRight += 'Organization Finance Admin, ';
  }
  if (_res[3] === '1') {
    accessRight += 'Organization HR_Admin, ';
  }
  if (_res[4] === '1') {
    accessRight += 'Organization HR_Assistant, ';
  }
  if (_res[5] === '1') {
    accessRight += 'Organization Payroll Master, ';
  }
  if (_res[6] === '1') {
    accessRight += 'Organization Staff, ';
  }
  if (_res[7] === '1') {
    accessRight += 'Organization Supervisor, ';
  }
  return accessRight;
}

export function toArrayRight(data) {
  let arr = [];
  data.map(res => {
    arr.push(res.name);
  });
  return arr;
}

const formatDateDetail = date => moment(date).format('hh:mm in MMMM D, YYYY');

export function arrayToString(data) {
  if (data !== null) {
    if (typeof data !== 'string') {
      if (data.length > 0) {
        return data.join(', ');
      } else {
        return '-';
      }
    } else {
      return data;
    }
  } else {
    return '-';
  }
}

export function deferentDate(start_date, end_date) {
  const a = moment(end_date);
  const b = moment(start_date);
  if (a.diff(b, 'years') > 0) {
    if (a.diff(b, 'years') === 1) {
      return 'in a year';
    } else {
      return `in ${a.diff(b, 'years')} year`;
    }
  } else if (a.diff(b, 'months') > 0) {
    return `in ${a.diff(b, 'months')} month`;
  } else {
    return moment(start_date).to(end_date);
  }
}
