import React from 'react';
import { useApolloClient, useQuery } from 'react-apollo';

import { Collapse, message } from 'antd';

import axios from 'axios';

import { Attachment, AttachmentItem, ButtonFrom } from '../../SharedComponents';
import {
  getFileIcon,
  getFileType
} from '../../SharedComponents/SharedPeopleProfile/Attachment/helperAttachment';
import ModalEdit from '../ModalEdit';
import ButtonWlb from '../ButtonWlb';
import { PaymentGuideWrapper } from './VendorOrderShippingStatus.style';

import { AuthenticationService } from '../../Services';
import {
  GET_PAYMENT_GUIDE,
  ATTACH_REFUND_PROOF
} from './VendorOrderShippingStatus.graphql';
import currencyFormat from '../../Utils/currencyFormat';
import DisplayLoading from '../DisplayLoading';
import Assets from '../../Assets';
import DOMPurify from 'dompurify';

const STATE_GUIDE = 0;
const STATE_PROOF = 1;

const STATE_UPLOAD_ERROR = 2;
const STATE_UPLOADING = 3;
const STATE_DISPATCH = 4;

const INITIAL_FORM_DATA = {
  overSize: false,
  invalidType: false,
  files: []
};

const RefundProcessModal = props => {
  const { open, invoiceId, data, price, onClose, onSubmit } = props;

  const client = useApolloClient();

  const [formState, setFormState] = React.useState(STATE_GUIDE);
  const [formData, setFormData] = React.useState(INITIAL_FORM_DATA);

  const isDispatching = formState >= STATE_UPLOADING;

  const handleClose = () => {
    if (isDispatching) {
      return;
    }

    setFormState(STATE_GUIDE);

    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async ev => {
    ev.preventDefault();

    if (isDispatching) {
      return;
    }

    setFormState(STATE_UPLOADING);

    try {
      const TOKEN = AuthenticationService.getTokenAuth();
      const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;

      for (const file of formData.files) {
        const blob = file.blob;

        if (!blob) {
          continue;
        }

        const form = new FormData();
        form.append('file', blob);

        const response = await axios.post(UPLOAD_URL, form, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        });

        URL.revokeObjectURL(file.url);

        file.blob = null;
        file.url = response.data.url;
      }
    } catch (err) {
      message.error(`Failed to upload proof attachments`);

      console.error(`Failed to upload:`, err);
      setFormState(STATE_UPLOAD_ERROR);
      return;
    }

    setFormState(STATE_DISPATCH);

    try {
      await client.mutate({
        mutation: ATTACH_REFUND_PROOF,
        variables: {
          files: formData.files.map(file => ({
            refund_request_id: data.id,
            name: file.name,
            file: file.url,
            type: file.type
          }))
        },
        context: {
          headers: {
            'X-Hasura-Role': 'organization-administrator'
          }
        }
      });
    } catch (err) {
      message.error(`Failed to submit proof`);
      console.error(`Failed to submit:`, err);

      setFormState(STATE_PROOF);
      return;
    }

    if (onSubmit) {
      onSubmit();
    }
  };

  const handleAttachmentAdd = ev => {
    /** @type {FileList} */
    const pending = ev.target.files;
    const files = [];

    let invalidType = false;
    let overSize = false;

    for (const file of pending) {
      let name = file.name;
      let size = file.size;
      let type = getFileType(name);

      let skip = false;

      if (!type) {
        skip = invalidType = true;
      }

      if (size > 25 * 1024 * 1024) {
        skip = overSize = true;
      }

      if (skip) {
        continue;
      }

      files.push({
        name: name,
        size: size,
        type: type,
        url: URL.createObjectURL(file),
        blob: file
      });
    }

    setFormData({
      ...formData,
      invalidType: invalidType,
      overSize: overSize,
      files: files.length ? [...formData.files, ...files] : formData.files
    });
  };

  const bindAttachmentNameChange = idx => event => {
    const prev = formData.files;
    const next = prev.slice();

    next[idx].name = event.target.value;
    setFormData({ ...formData, files: next });
  };

  const bindAttachmentRemove = idx => () => {
    const prev = formData.files;
    const next = prev.slice();

    next.splice(idx, 1);
    setFormData({ ...formData, files: next });
  };

  return (
    <ModalEdit
      open={open}
      onClose={handleClose}
      title={
        formState >= STATE_PROOF ? 'Upload Payment Attachment' : 'How to Pay'
      }
      padding="20px 22px"
    >
      {formState >= STATE_PROOF ? (
        <form onSubmit={handleSubmit}>
          <div>
            <Attachment
              id="refund-proof-attachments"
              multiple
              handleChangeFile={handleAttachmentAdd}
              disabled={isDispatching}
            />

            {formState === STATE_UPLOADING && <div>Uploading...</div>}
            {formState === STATE_UPLOAD_ERROR && (
              <div>Some attachments failed to upload</div>
            )}

            {formData.invalidType && (
              <div>Attachments can only be in specified file types</div>
            )}
            {formData.overSize && (
              <div>Attachments cannot exceed 25 MB in size</div>
            )}

            {formData.files.map((file, idx) => (
              <AttachmentItem
                key={idx}
                fileName={file.name}
                size={file.size}
                src={file.url}
                type={file.type}
                onChange={bindAttachmentNameChange(idx)}
                onDelete={bindAttachmentRemove(idx)}
              />
            ))}
          </div>

          <ButtonFrom onCancel={handleClose} />
        </form>
      ) : formState >= STATE_GUIDE ? (
        <>
          <PaymentGuide
            bankId={data.global_bank.id}
            accountName={data.bank_account_holder}
            accountNumber={data.bank_account_num}
            amount={price}
          />

          <ButtonWlb
            type="primary"
            style={{ width: '100%' }}
            onClick={() => setFormState(STATE_PROOF)}
          >
            I Already Paid
          </ButtonWlb>
        </>
      ) : null}
    </ModalEdit>
  );
};

export default RefundProcessModal;

const PaymentGuide = props => {
  const { bankId, accountName, accountNumber, amount } = props;

  const { data, error } = useQuery(GET_PAYMENT_GUIDE, {
    variables: {
      bankId: bankId
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });

  if (error) {
    return (
      <div style={{ marginBottom: 20 }}>
        Failed to retrieve payment instructions
      </div>
    );
  }

  if (!data) {
    return (
      <div style={{ marginBottom: 20 }}>
        <DisplayLoading />
      </div>
    );
  }

  const bank = data.bank;
  const guides = bank.guides;

  return (
    <PaymentGuideWrapper>
      <div className="header">
        <h1 className="title">Pay to</h1>

        <div className="bank">
          <img
            className="bank-logo"
            src={bank.logo}
            alt={`${bank.name} logo`}
          />
          <div className="account-info">
            <p className="account-name">{accountNumber}</p>
            <p className="account-number">{accountName}</p>
          </div>
        </div>
      </div>

      <div className="body">
        <div className="section">
          <h2 className="section-header">Amount to pay</h2>
          <p className="payment-amount">{currencyFormat(amount)}</p>
        </div>
        <div className="section">
          <h2 className="section-header">How to pay</h2>

          <Collapse expandIconPosition="right">
            {guides.map(guide => {
              const instruction = guide.instructions
                .replace(
                  '#NUMBER#',
                  `<span class='highlight'>${accountNumber}</span>`
                )
                .replace(
                  '#TOTALPAYMENT#',
                  `<span class='highlight'>${amount}</span>`
                );

              return (
                <Collapse.Panel key={guide.id} header={guide.name}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(instruction)
                    }}
                  />
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </div>
      </div>
    </PaymentGuideWrapper>
  );
};
