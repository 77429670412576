import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import baseUrl from '../../Config/baseUrl';

import { isObjectEmpty } from '../../Utils/CommonUtils';

const createClassroomService = async payload => {
  const userId = AuthenticationService.getUserId();
  const createClassroomUrl = baseUrl.CLASSROOMS(userId);

  return await BaseService.post(createClassroomUrl, payload);
};

const getListClassroom = (page = 1, name = '', maxResults = 50) => {
  let parameters = {
    params: {
      name: name,
      page: page,
      maxResults: maxResults
    }
  };
  if (isObjectEmpty(name)) {
    delete parameters.params.name;
  }
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.CLASSROOMS(userId);

  return BaseService.get(url, parameters);
};

const getListClassroomGlobal = (page = 1, name = '', maxResults = 30) => {
  let parameters = {
    params: {
      name: name,
      page: page,
      maxResults: maxResults
    }
  };
  if (isObjectEmpty(name)) {
    delete parameters.params.name;
  }
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.CLASSROOMS_GLOBAL(userId);

  return BaseService.get(url, parameters);
};

const deleteClassroom = classroomId => {
  const userId = AuthenticationService.getUserId();
  const deleteClassroomUrl = baseUrl.CLASSROOMS_ID(userId, classroomId);
  return BaseService.delete(deleteClassroomUrl);
};

const updateIntroClassroom = (classroomId, payload) => {
  const userId = AuthenticationService.getUserId();
  const url = baseUrl.CLASSROOMS_ID(userId, classroomId);
  return BaseService.put(url, payload);
};

export default {
  createClassroomService,
  getListClassroom,
  getListClassroomGlobal,
  deleteClassroom,
  updateIntroClassroom
};
