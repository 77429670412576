import Constants from '../../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../../Assets';

export const GroupWrapper = styled.div`
  margin-bottom: 11.2px;
  padding: 12.3px;
  width: 100%;
  border-radius: 2.5px;
  box-shadow: ${props =>
    props.boxShadow || `0px 1.5px 3px 0px ${Constants.COLORS.SHADOW}`};
  background-color: ${Constants.COLORS.WHITE};
  .headerTitle {
    font-size: 16px;
    margin-bottom: 3px;
    font-family: ${Assets.FONT.MULI};
    font-weight: 600;
    color: black;
  }
  .headerIcon {
    width: 25px;
    margin-right: 20px;
  }
  .team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
  }
  .teamTitle {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .teamDesc {
    font-size: 12px;
  }

  .hr {
    margin-top: 10px;
    height: 1px;
    background-color: ${Constants.COLORS.LIGHT_GREY};
    border: none;
    margin-bottom:16px;
  },
`;
