import React, { useEffect, useState } from 'react';
import { ModalEdit } from '../..';
import { Form, Select, Input, Button, message, Avatar } from 'antd';
import { TitleField } from '../ActionMore.style';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import {
  GET_LIST_RELATIONSHIP,
  GET_LIST_POSITION,
  REQUEST_RECOMMENDATION,
  GET_PEOPLE_BY_ID
} from './RequestRecommendation.graphql';
import { AuthenticationService, ConnectionService } from '../../../Services';
import { AvatarItemSelect, Position, SelectHidden } from '../ActionMore.style';
import { generateValue, getRelationship } from '../ActionMoreHelper';
import { useParams } from 'react-router-dom';

export const removeDuplicate = arr => {
  return arr.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);
};

const RequestRecommendationComponent = ({ open, onClose, form }) => {
  const { profileId } = useParams();
  const { Option } = Select;
  const { TextArea } = Input;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue
  } = form;
  const [listWhoYouAsk, setListWhoYouAsk] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(null);

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };
  const userId = AuthenticationService.getUserId();

  const [addRequestRecommendation] = useMutation(REQUEST_RECOMMENDATION, {
    context: CONTEXT_USER
  });
  const { data: dataRelationShip } = useQuery(GET_LIST_RELATIONSHIP, {
    context: CONTEXT_PUBLIC
  });
  const { data: dataPosition } = useQuery(GET_LIST_POSITION, {
    context: CONTEXT_PUBLIC,
    variables: {
      userLogin: userId
    }
  });

  const [getUserProfile, { data: dataUser }] = useLazyQuery(GET_PEOPLE_BY_ID, {
    context: CONTEXT_PUBLIC
  });

  useEffect(() => {
    if (profileId && userId !== profileId) {
      getUserProfile({
        variables: {
          id: profileId
        }
      });
    }
  }, [userId, profileId, getUserProfile]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      const submit = {
        objects: [
          {
            request_to: values.who_ask,
            relationship: values.relationship,
            position: values.position,
            type: 1,
            comment: values.message
          }
        ]
      };
      if (!error) {
        addRequestRecommendation({
          variables: submit
        })
          .then(() => {
            message.success(`Request referral success`);
            onClose();
            resetFields();
          })
          .catch(err => {
            message.error(`Request referral error, ${err}`);
          });
      }
    });
  };

  const handleChange = (formValue, target) => {
    if (target === 'who_ask') {
      setFieldsValue({ relationship: null });
      setSubject(formValue);
    }
  };

  const handleSearch = (e, fieldName) => {
    if (fieldName === 'who_ask' && userId === profileId) {
      setLoading(true);
      ConnectionService.getListFollowing(userId, 1, e)
        .then(response => {
          if (response) {
            setLoading(false);
            const { data } = response;
            setListWhoYouAsk(data);
          }
        })
        .catch(err => {
          console.error('err code : ', err);
        });
    }
  };

  const handleGetSugestion = open => {
    if (open) {
      // if(open && userId === profileId) {
      setLoading(true);
      ConnectionService.getListFollowing(userId)
        .then(response => {
          if (response) {
            setLoading(false);
            const { data } = response;
            setListWhoYouAsk(data);
          }
        })
        .catch(err => {
          console.error('err code : ', err);
        });
    }
  };

  const _switchComponentToRender = res => {
    const { type, fieldName, option, labelName } = res;

    if (type === 'select') {
      return (
        <SelectHidden
          loading={fieldName === 'who_ask' ? loading : false}
          onDropdownVisibleChange={open =>
            fieldName === 'who_ask' ? handleGetSugestion(open) : null
          }
          size="large"
          onChange={(value, el) =>
            handleChange(
              fieldName === 'who_ask' ? el?.props?.subject : value,
              fieldName
            )
          }
          style={
            fieldName === 'position'
              ? null
              : { textTransform: 'capitalize', overflow: 'hidden' }
          }
          onSearch={search => handleSearch(search, fieldName)}
          showSearch={fieldName === 'who_ask'}
          filterOption="children"
        >
          <Option value={null} disabled>
            Pilih {fieldName === 'who_ask' ? 'Orang' : labelName}
          </Option>
          {fieldName === 'who_ask'
            ? option &&
              option.map((people, i) => {
                return (
                  <Option subject={people.name} key={i} value={people.id}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Avatar
                        style={{ height: 26, width: 26, marginTop: 1 }}
                        src={people.avatar}
                        size="large"
                      />
                      <AvatarItemSelect>
                        <Position size="14px">{people.name}</Position>
                        <Position size="12px">
                          {people?.people_work_placements?.[0]
                            ?.company_employee_position?.name + ' '}
                          {people?.people_work_placements?.[0]
                            ?.company_organization?.name + ' '}
                          {people?.people_work_placements?.[0]?.company_address
                            ?.office_name + ' '}
                        </Position>
                      </AvatarItemSelect>
                    </div>
                  </Option>
                );
              })
            : option &&
              option.map((res, i) => {
                return (
                  <Option
                    style={
                      fieldName !== 'position' && {
                        textTransform: 'capitalize'
                      }
                    }
                    key={i}
                    value={fieldName === 'relationship' ? res.name : res.id}
                  >
                    {res.name}
                  </Option>
                );
              })}
        </SelectHidden>
      );
    } else if (type === 'textArea') {
      return <TextArea rows={4} placeholder="Tambahkan pesan khusus" />;
    }
  };

  const listFields = [
    {
      labelName: 'Siapa yang akan diminta memberikan referensi?',
      fieldName: 'who_ask',
      type: 'select',
      fieldValue:
        userId !== profileId ? dataUser?.global_users?.[0]?.id ?? null : null,
      option:
        userId !== profileId
          ? dataUser?.global_users
          : removeDuplicate(listWhoYouAsk),
      required: true
    },
    {
      labelName: 'Hubungan',
      fieldName: 'relationship',
      type: 'select',
      fieldValue: null,
      option: getRelationship(
        dataRelationShip,
        dataUser?.global_users?.[0]?.name || subject
      ),
      required: true
    },
    {
      labelName: 'Jabatan',
      fieldName: 'position',
      type: 'select',
      fieldValue: null,
      option: generateValue(dataPosition),
      required: true
    },
    {
      labelName: 'Pesan Khusus',
      fieldName: 'message',
      type: 'textArea',
      fieldValue: '',
      required: true
    }
  ];

  const handleClose = () => {
    onClose();
    resetFields();
    setSubject(null);
  };

  return (
    <ModalEdit
      title="Ajukan Referensi"
      open={open}
      onClose={handleClose}
      sizeSmall
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={i}
                style={{ padding: '0px 22px', marginBottom: 8 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules: [
                    {
                      required: res.required,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <Form.Item
          style={{
            marginBottom: 0,
            padding: '20px 22px 0px 22px',
            marginTop: 22
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Batalkan
            </Button>
            <Button type="primary" htmlType="submit">
              Kirimkan
            </Button>
          </div>
        </Form.Item>
      </Form>
    </ModalEdit>
  );
};

const RequestRecommendation = Form.create({ name: 'Request_Recommendation' })(
  RequestRecommendationComponent
);

export default RequestRecommendation;
