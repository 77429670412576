import React from 'react';
import { Button, Icon, Input } from 'antd';

import './Postbox.css';
import {
  BottomPostBox,
  EventPost,
  TaskPost,
  TopPostBox,
  UploadFile,
  ClickOut,
  LocationPost,
  LearningTrackerPostBox,
  PollingPost
  // Hashtag
} from '../../Components';
import UploadImage from '../UploadImage';
import Constants from '../../Config/Constants';
import style, { PostBoxWrap } from './PostBox.style';
import { SpecificFollower, TagPeople } from '../';

const { TextArea } = Input;
const { TEXT_AREA_PLACEHOLDER, VALUE } = Constants.MESSAGES.POST_BOX;
const { EVENT, TASK, TRACKER, POLLING } = VALUE;

const { IMAGE, FILES } = Constants.UTILITIES;

const PostBoxComponent = props => {
  const {
    post,
    handlePost,
    handlePostType,
    isShowBottomPostBox,
    handleFocusTextArea,
    handleUtility,
    utility,
    handleSubmit,
    isShowSpecificFollower,
    setIsShowModal,
    handleUpload,
    groupId,
    postType,
    setSelectPrivacy,
    tagsUtility,
    selectedEvent,
    selectedTask,
    listEvent,
    handleEventName,
    isFocus,
    setEvent,
    handleTask,
    listTask,
    listCompanies,
    locationUtility,
    // hashtagUtility,
    setEventKeyword,
    setTaskKeyword,
    setFocusPost,
    setTask,
    setIsUploading,
    isUploading,
    sharedPost,
    classroomMember,
    dataMentee,
    handleSelectMentee,
    listActivity,
    selectedActivity,
    handleSelectActivity,
    isFinished,
    onSearchAutocomplete,
    onSelectAutocomplete,
    listCompetence,
    competencyValue,
    onChangeAutocomplete,
    edit,
    handleAddPollingOption,
    handleChangePollingOption,
    handleRemovePollingOption
  } = props;

  const _renderTopPostBox = () => (
    <TopPostBox
      type={post.type}
      handlePostType={handlePostType}
      onFocus={handleFocusTextArea}
      isFocus={isFocus}
      isFinished={isFinished}
    />
  );
  const _renderEventPost = () => (
    <EventPost
      isFocus={isFocus}
      type={post.type}
      selectedEvent={selectedEvent}
      handleEventName={handleEventName}
      listEvent={listEvent}
      setEventKeyword={setEventKeyword}
      handlePost={handlePost}
      setEvent={setEvent}
    />
  );

  const _renderTaskPost = () => (
    <TaskPost
      selectedTask={selectedTask}
      handleTask={handleTask}
      listTask={listTask}
      listCompanies={listCompanies}
      setTaskKeyword={setTaskKeyword}
      handlePost={handlePost}
      setTask={setTask}
    />
  );

  const _renderLearningTrackerPost = () => (
    <LearningTrackerPostBox
      post={post}
      handlePost={handlePost}
      classroomMember={classroomMember}
      dataMentee={dataMentee}
      handleSelectMentee={handleSelectMentee}
      listActivity={listActivity}
      selectedActivity={selectedActivity}
      handleSelectActivity={handleSelectActivity}
      onSearchAutocomplete={onSearchAutocomplete}
      onSelectAutocomplete={onSelectAutocomplete}
      listCompetence={listCompetence}
      competencyValue={competencyValue}
      onChangeAutocomplete={onChangeAutocomplete}
    />
  );
  const _renderPollingPost = () => (
    <PollingPost
      post={post}
      handlePost={handlePost}
      handleAddPollingOption={handleAddPollingOption}
      handleChangePollingOption={handleChangePollingOption}
      handleRemovePollingOption={handleRemovePollingOption}
    />
  );

  const _renderTextArea = () => (
    <TextArea
      className="textArea"
      style={style.textArea}
      autoSize={{ minRows: 4 }}
      focus={'true'}
      onFocus={() => handleFocusTextArea(true)}
      value={post.content}
      onChange={e => handlePost('content', e.target.value)}
      placeholder={
        post.type === POLLING ? 'Ajukan Pertanyaan' : TEXT_AREA_PLACEHOLDER
      }
    />
  );

  const _renderImageUploadFile = () => (
    <UploadImage
      acceptedFormat=".png,.jpg,.jpeg"
      listType="picture-card"
      fileList={post.images}
      target="images"
      handleUpload={handleUpload}
      handleChange={handlePost}
      maxUpload={10}
      setIsUploading={setIsUploading}
    />
  );

  // const _renderVideoUploadFile = () => (
  //   <UploadFile
  //     acceptedFormat=".mp4,.avi,.3gp"
  //     handleUpload={handleUploadVideo}
  //     fileList={post.videos}
  //     target="videos"
  //     handleChange={handlePost}
  //   >
  //     <Button size="small" style={{ fontSize: 11 }}>
  //       <Icon type="upload" /> Upload
  //     </Button>
  //   </UploadFile>
  // );

  const _renderAttachmentUploadFile = () => (
    <UploadFile
      acceptedFormat=".doc,.docx,.xlsx,.xls,.ppt,.pptx,.txt,.pdf"
      fileList={post.files}
      target="files"
      handleUpload={handleUpload}
      setIsUploading={setIsUploading}
      handleChange={handlePost}
      {...props}
    >
      <Button size="small" style={{ fontSize: 11 }}>
        <Icon type="upload" /> Unggah
      </Button>
    </UploadFile>
  );

  const _renderLocationPost = () => (
    <LocationPost
      post={post}
      handlePost={handlePost}
      handleUtility={handleUtility}
    />
  );

  const _renderBottomPostBox = () => (
    <BottomPostBox
      post={post}
      handlePost={handlePost}
      handleUtility={handleUtility}
      handleSubmit={handleSubmit}
      setIsShowModal={setIsShowModal}
      postType={postType}
      setSelectPrivacy={setSelectPrivacy}
      isUploading={isUploading}
      sharedPost={sharedPost}
    />
  );

  const _renderSpecificFollower = () => (
    <SpecificFollower
      isShowSpecificFollower={isShowSpecificFollower}
      canSee={post.canSee}
      handlePost={handlePost}
      setIsShowModal={setIsShowModal}
      setFocusPost={setFocusPost}
    />
  );

  const _renderTagPeople = () => (
    <TagPeople groupId={groupId} postType={postType} handlePost={handlePost} />
  );

  // const _renderHashtag = () => <Hashtag handlePost={handlePost} />;
  return (
    <ClickOut
      onClickOutside={() => {
        if (post.type !== EVENT && tagsUtility) {
          handleFocusTextArea(false);
        }
      }}
    >
      <PostBoxWrap style={!edit ? style.container(isShowBottomPostBox) : null}>
        {!edit && _renderSpecificFollower()}
        {!edit && _renderTopPostBox()}
        {post.type === EVENT ? _renderEventPost() : null}
        {post.type === TASK ? _renderTaskPost() : null}
        {post.type === TRACKER && !isFinished
          ? _renderLearningTrackerPost()
          : null}
        {post.type !== TRACKER && post.type !== POLLING && _renderTextArea()}
        {post.type === POLLING ? _renderPollingPost() : null}
        {utility === IMAGE ? _renderImageUploadFile() : null}
        {/* {utility === VIDEO ? _renderVideoUploadFile() : null} */}
        {utility === FILES ? _renderAttachmentUploadFile() : null}
        {tagsUtility ? _renderTagPeople() : null}
        {locationUtility && _renderLocationPost()}
        {/* {hashtagUtility && isFocus ? _renderHashtag() : null} */}
        {/* <span hidden={utility !== TAGS}>{_renderTagPeople()}</span> */}
        {props.children ? props.children : null}
        {(isShowBottomPostBox || edit) && _renderBottomPostBox()}
      </PostBoxWrap>
    </ClickOut>
  );
};

export default PostBoxComponent;
