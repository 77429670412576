import React from 'react';
import { Row, Col } from 'antd';
import {
  SidebarClassroomInfoBox,
  MemberCardBoxClassroom
} from '../../../Containers';
import InviteMemberClassroom from '../../../Containers/InviteMemberClassroom';

const MemberScreenComponent = props => {
  const { isAdmin, isMentor } = props;

  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarClassroomInfoBox {...props} />
        </Col>
        <Col span={12}>
          <MemberCardBoxClassroom {...props} />
        </Col>
        <Col span={6}>
          {(isAdmin || isMentor) && <InviteMemberClassroom {...props} />}
        </Col>
      </Row>
    </div>
  );
};

export default MemberScreenComponent;
