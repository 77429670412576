import React from 'react';

import { LoginForm } from '../../Components';

const LoginPageComponent = props => {
  const { form, updateField, login } = props;

  return <LoginForm form={form} updateField={updateField} login={login} />;
};

export default LoginPageComponent;
