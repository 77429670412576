import { gql } from 'apollo-boost';

const GET_COMPANY_DESCRIPTION = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      brand_name
      profile_field
    }
  }
`;
const UPDATE_COMPANY_DESCTIPTION = gql`
  mutation($company:uuid!, $description:jsonb){
    update_company_profiles(
      _append:{
        profile_field:$description
      },
      where:{
        id:{_eq:$company}
      }
    ){
      affected_rows
    }
  }
`;

export { GET_COMPANY_DESCRIPTION, UPDATE_COMPANY_DESCTIPTION };
