import styled from 'styled-components';
import Assets from '../../Assets';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #014a62;
  padding: 22px 26px;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  color: white;
  font-weight: 600;
`;

export const ModalContent = styled.div`
  text-align: left;
  padding: ${props => props.padding};
`;

// export const ButtonStyled = styled(Button)`
//   background-color: ${Assets.COLOR_PALLETE.dark_blue};
// `;
