import { gql } from 'apollo-boost';

export const LIST_OF_MEMBERS = gql`
  query($classroom_id: uuid_comparison_exp!, $limit: Int, $offset: Int) {
    classroom_members_aggregate(where: { classroom_id: $classroom_id }) {
      aggregate {
        count
      }
    }
    classroom_members(
      where: { classroom_id: $classroom_id }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      id
      is_admin
      is_mentor
      is_individual_mentor
      global_user {
        id
        avatar
        name
        email
        people_work_placements {
          id
          company_profile {
            id
            brand_name
            legal_name
          }
          company_job_profile {
            id
            position
            title
          }
        }
      }
      created_at
    }
  }
`;
