import { Icon } from 'antd';
import React, { memo } from 'react';
import Assets from '../../../../Assets';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import { WrapperExperience } from './Experience.style';

export default memo(function ExperienceItem({
  onClickEdit,
  isUser,
  avatar,
  title,
  company,
  date,
  location,
  description,
  data,
  organizationType,
  dividerWidth = null,
  contentPadding = null,
  contentWidth = null,
  noDivider = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <WrapperExperience
      contentPadding={contentPadding}
      contentWidth={contentWidth}
      dividerWidth={dividerWidth}
    >
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="title">{title}</div>
          <div className="company">{company}</div>
          <div className="organization-type">{organizationType}</div>
          <div className="location">{location}</div>
          <div className="date">{date}</div>
          <ThypoStyled
            margin="0 0 12px 0"
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
          >
            {`Member ID ${
              data.dataEdit.member_id !== null
                ? data.dataEdit.member_id !== ''
                  ? data.dataEdit.member_id
                  : '-'
                : '-'
            }`}
          </ThypoStyled>
          <div
            className="description"
            style={{ paddingTop: '6px', whiteSpace: 'pre-line' }}
          >
            {description.slice(0, (!isMore && 370) || description.length) ||
              '-'}
          </div>
          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      {!noDivider && <div className="divider" />}
    </WrapperExperience>
  );
});
