import styled from 'styled-components';
import Assets from '../../../Assets';
import { Avatar } from 'antd';

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Title = styled.span`
  color: #000;
  font-size: 12px;
  font-family: ${Assets.FONT.MULI_SEMIBOLD};
  text-align: center;
  `
  
  export const BlueText = styled.span`
  color: ${Assets.COLOR_PALLETE.blue};
  font-size: 12px;
  font-family: ${Assets.FONT.MULI};
  text-align: center;
  `
  
  export const GreyText = styled.span`
  color: ${Assets.COLOR_PALLETE.grey};
  font-size: 12px;
  font-family: ${Assets.FONT.MULI};
  text-align: center;
`

export const ViewAll = styled.p`
  color: #007ebc;
  font-size: 12px;
  cursor: pointer;
  margin: 12px 0px 24px 0px;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${Assets.COLOR_PALLETE.light_blue};
  padding: 22px 26px;
`;


export const ModalTitle = styled.div`
  font-size: 18px;
  color: #000;
  font-weight: 600;
`;

export const ModalContent = styled.div`
  text-align: left;
  padding: 0px 0px 0px 20px;
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  max-height: 430px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16); 
    border-radius: 5px;
  }
`;

export const DetailBadgeItem = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0px 0px 0px;
`;

export const BadgeLogo = styled.div`
  width: 93px;
  height: 93px;
  margin-right: 30px;
`;

export const Description = styled.p`
  font-size: 12px;
  text-align: left;
  margin: 10px 0px 30px 0px;
`;

export const BadgeContent = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  width: 100%
`;

export const AvatarStyled = styled(Avatar)`
  marginBottom: 10px;
  width: auto;
  height: 62px;

  img {
    width: auto !important;
    height: 100% !important
    margin: auto;
  }
`;


