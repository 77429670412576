import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import {
  Form,
  Input,
  DatePicker,
  Checkbox,
  message,
  Select,
  Switch
} from 'antd';
import { TitleField } from '../DetailScreen.style';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import {
  ADD_LICENCES,
  UPDATE_LICENCES,
  DELETE_LICENCES
} from './Licences.graphql';
import {
  generateDateFormat,
  getErrorMessage,
  isAfter
} from '../HelperDetailScreen';
import {
  Attachment,
  AttachmentItem,
  ButtonFrom
} from '../../../../SharedComponents';
import { AuthenticationService } from '../../../../Services';
import Axios from 'axios';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import TextArea from 'antd/lib/input/TextArea';

const AddLicencesComponent = ({
  open,
  onClose,
  actionType,
  form,
  refetch,
  people_profile,
  data,
  setOpen,
  openState
}) => {
  const { Option } = Select;
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    getFieldValue
  } = form;
  const [canExpire, setCanExpire] = useState(true);
  const [share, setShare] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const [addLicences] = useMutation(ADD_LICENCES);
  const [updateLicences] = useMutation(UPDATE_LICENCES);
  const [deleteLicences] = useMutation(DELETE_LICENCES);

  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [errorMax, setErrorMax] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  // const [setShareLicences] = useMutation(SET_SHARE_LICENCES);
  useEffect(() => {
    setLoadingUpload(false);
    setErrorFile(false);
    setErrorSize(false);
    setErrorMax(false);
    setShare(false);
    setAttachment([]);
    if (data) {
      setCanExpire(data.date_expired !== null);
      setShare(data?.is_shared);

      setAttachment(data?.attachments);
    } else {
      setAttachment([]);
      setCanExpire(true);
    }
  }, [data, people_profile]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        let attach = [];
        attachment.length >= 1 &&
          attachment.map(row => {
            attach.push(row);
          });
        const objects = {
          is_shared: share,
          name: values.certificate_name,
          type: values.type,
          level: values.level,
          issuer_name: values.organization_name,
          date_issued: generateDateFormat(values.issues_date),
          date_expired: generateDateFormat(values.expiration_dates),
          can_expire: canExpire,
          credential_id: values.credential_id,
          credential_url: values.credential_url,
          description: values.description,
          attachments: attach
        };
        if (actionType === 'add') {
          addLicences({
            variables: { objects }
          })
            .then(() => {
              refetch();
              resetFields();
              setCanExpire(true);
              setAttachment([]);
              message.success(
                `Berhasil menambahkan Pelatihan, Lisensi & Sertifikasi`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(
                msg || `Gagal menambahkan Pelatihan, Lisensi & Sertifikasi, ${err}`
              );
            });
        } else if (actionType === 'edit') {
          updateLicences({
            variables: { id: data.id, objects: objects }
          })
            .then(() => {
              setCanExpire(true);
              refetch();
              setAttachment([]);
              message.success(
                `Berhasil mengubah Pelatihan, Lisensi & Sertifikasi`
              );
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(
                msg || `Gagal mengubah Pelatihan, Lisensi & Sertifikasi, ${err}`
              );
            });
        }
      }
    });
  };

  const handleChange = (formValue, target) => {
    if (target === 'upload-attachment') {
      uploadAttachment(formValue);
    }
  };
  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setErrorMax(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (tempFile.length + attachment.length > 5) {
      setErrorMax(true);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { link: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
      Array.from(document.querySelectorAll('#upload-licenses')).forEach(
        input => (input.value = '')
      );
    }
  };

  const listFields = [
    {
      labelName: 'Nama Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'certificate_name',
      type: 'text',
      fieldValue: data?.title,
      placeholder: 'Tambahkan nama Pelatihan/Lisensi/Sertifikasi',
      required: true
    },
    {
      labelName: 'Tipe Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'type',
      type: 'select',
      fieldValue: data?.type,
      placeholder: 'Pilih tipe Pelatihan/Lisensi/Sertifikasi',
      required: true,
      option: [
        {
          name: 'Fungsional',
          id: 'Fungsional'
        },
        {
          name: 'Jabatan',
          id: 'Jabatan'
        },
        {
          name: 'Non-Formal',
          id: 'Non-Formal'
        }
      ]
    },
    {
      labelName: 'Tingkatan Pelatihan/Lisensi/Sertifikasi',
      fieldName: 'level',
      type: 'select',
      fieldValue: data?.level,
      placeholder: 'Pilih tingkatan Pelatihan/Lisensi/Sertifikasi',
      required: true,
      option: [
        {
          name: 'Perusahaan',
          id: 'Perusahaan'
        },
        {
          name: 'Nasional',
          id: 'Nasional'
        },
        {
          name: 'Internasional',
          id: 'Internasional'
        },
        {
          name: 'Non-Formal',
          id: 'Non-Formal'
        }
      ]
    },
    {
      labelName: 'Organisasi Penerbit',
      fieldName: 'organization_name',
      type: 'text',
      fieldValue: data?.company,
      placeholder: 'Tambahkan organisasi',
      required: true
    }
  ];
  const listFields2 = [
    {
      labelName: 'Nomor Sertifikat',
      fieldName: 'credential_id',
      type: 'text',
      fieldValue: data?.credential_id,
      placeholder: 'Tambahkan nomor sertifikat',
      required: false
    },
    {
      labelName: 'Tautan Sertifikat',
      fieldName: 'credential_url',
      type: 'text',
      fieldValue: data?.credential_url,
      placeholder: 'Tambahkan tautan sertifikat',
      required: false
    }
  ];

  const _switchComponentToRender = res => {
    const { type, placeholder, option, labelName } = res;
    if (type === 'text') {
      return <Input placeholder={placeholder} style={{ width: '100%' }} />;
    } else if (type === 'select') {
      return (
        <Select
          filterOption={false}
          placeholder={placeholder}
          showSearch
          size="large"
        >
          <Option value={null} disabled>
            Pilih {labelName}
          </Option>
          {option &&
            option.map((res, i) => {
              return (
                <Option key={i} value={res.id}>
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    }
  };

  const handleDelete = () => {
    deleteLicences({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        setCanExpire(true);
        message.success(`Berhasil menghapus Pelatihan, Lisensi & Sertifikasi`);
        onClose();
      })
      .catch(err => {
        message.error(
          `Gagal menghapus Pelatihan, Lisensi & Sertifikasi, ${err}`
        );
      });
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  const handleClose = () => {
    resetFields();
    setCanExpire(true);
    // setShare(false)
    onClose();
  };
  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
  };

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'
          } Pelatihan, Lisensi & Sertifikasi`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={<TitleField>Tanggal Terbit</TitleField>}
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                  width: '50%'
                }}
                extra={
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(
                      'can_expire',
                      {}
                    )(
                      <Checkbox
                        checked={!canExpire}
                        onClick={() => {
                          setFieldsValue({ expiration_dates: undefined });
                          setCanExpire(!canExpire);
                        }}
                      >
                        Sertifikat masih belum berakhir
                      </Checkbox>
                    )}
                  </Form.Item>
                }
              >
                {getFieldDecorator('issues_date', {
                  initialValue:
                    data && data.date_issued
                      ? moment(data.date_issued, 'YYYY/MM/DD')
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Pilih tanggal"
                    format={'YYYY-MM-DD'}
                    style={{ width: '90%' }}
                    onChange={() =>
                      setFieldsValue({ expiration_dates: undefined })
                    }
                    disabledDate={current =>
                      canExpire &&
                      isAfter(current, getFieldValue('expiration_dates'))
                    }
                  />
                )}
              </Form.Item>
              {canExpire && (
                <Form.Item
                  label={<TitleField>Tanggal Berakhir</TitleField>}
                  style={{ display: 'inline-block', width: '50%' }}
                >
                  {getFieldDecorator('expiration_dates', {
                    initialValue:
                      data && data.date_expired
                        ? moment(data.date_expired, 'YYYY/MM/DD')
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(
                    <DatePicker
                      placeholder="Pilih tanggal"
                      format={'YYYY-MM-DD'}
                      style={{ width: '90%' }}
                      disabledDate={current =>
                        isAfter(getFieldValue('issues_date'), current)
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          {listFields2.length > 0 &&
            listFields2.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules:
                      res.fieldName === 'credential_url'
                        ? [
                          {
                            required: res.required,
                            message: 'Bagian ini diperlukan'
                          }
                          // {
                          //   type: 'url',
                          //   message: 'This field must be a valid url.'
                          // }
                        ]
                        : [
                          {
                            required: res.required,
                            message: 'Bagian ini diperlukan'
                          }
                        ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: data && data?.description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan keterangan" />)}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-licenses"
              disabled={(attachment.length === 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorMax && <span>Jumlah lampiran tidak boleh lebih dari 5</span>}
            {attachment.length > 5 && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.length > 0 &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.link}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>
          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
            disabled={attachment.length > 5}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Pelatihan, Lisensi & Sertifikasi"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddLicences = Form.create({ name: 'Add_Licences' })(AddLicencesComponent);

export default AddLicences;
