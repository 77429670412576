import React, { useState } from 'react';
// import { ButtonWlb } from '..';
import { ShowcaseModalWrap } from './VendorShowcase.style';
import { Form, Input, Modal, message, Button } from 'antd';
import './ModalShowcase.css';

const EditShowcase = props => {
  const {
    editModalVisibility,
    hideModal,
    modalTitle,
    record,
    selectedRecordId,
    refetchShowcaseData,
    updateVendorShowcaseMutation
  } = props;
  const { getFieldDecorator } = props.form;

  const [title, setTitle] = useState(record.name);
  const [description, setDescription] = useState(record.description);

  const _resetInput = () => {
    setDescription('');
    setTitle('');
  };
  const _handleCancelButton = () => {
    hideModal();
    _resetInput();
  };

  const _handleEditShowcaseItem = async () => {
    let error = undefined;
    let successMessage = `Successfully edit showcase item`;
    let errorMessage = `Failed edit showcase item`;

    if (editModalVisibility) {
      error = (
        await updateVendorShowcaseMutation({
          variables: {
            name: title,
            description: description,
            id: selectedRecordId
          }
        })
      ).error;
      successMessage = `Successfully edit showcase ${title}`;
      errorMessage = `Failed to edit showcase ${title}`;
    }

    return { error, successMessage, errorMessage };
  };

  const _handlePositiveActionShowcaseModal = async e => {
    // if (isEmpty(title) || isEmpty(description)) {
    //   // message.error('All item are required');
    //   return;
    // }
    // if (isEmpty(description)) {
    //   message.error('Please input Description');
    //   return;
    // }
    e.preventDefault();

    props.form.validateFields(async (err, values) => {
      if (!err) {
        const {
          error,
          successMessage,
          errorMessage
        } = await _handleEditShowcaseItem();

        if (error) {
          message.error(errorMessage);
          return;
        }

        hideModal();
        _resetInput();
        message.success(successMessage);
        await refetchShowcaseData();
      }
    });
  };

  return (
    <Modal
      visible={editModalVisibility}
      onCancel={hideModal}
      footer={null}
      title={modalTitle}
    >
      <ShowcaseModalWrap>
        <Form layout="vertical" onSubmit={null}>
          <Form.Item label={'Name'}>
            {getFieldDecorator('title', {
              rules: [
                { required: true, message: 'Please input Showcase Name' }
              ],
              initialValue: title
            })(<Input onChange={event => setTitle(event.target.value)} />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              rules: [{ required: true, message: 'Please input Description' }],
              initialValue: description
            })(
              <Input.TextArea
                rows={4}
                onChange={event => setDescription(event.target.value)}
              />
            )}
          </Form.Item>
          <div className="component_button_wrapper">
            <Button onClick={_handleCancelButton}>Cancel</Button>
            <Button type="primary" onClick={_handlePositiveActionShowcaseModal}>
              Save
            </Button>
          </div>
        </Form>
      </ShowcaseModalWrap>
    </Modal>
  );
};

export default Form.create({ name: 'VendorShowcaseEditForm' })(EditShowcase);
