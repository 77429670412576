import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import VendorShowcaseComponent from './VendorShowcase.component';
import { useMutation, useQuery } from '@apollo/react-hooks';
import TableItemActionContainer from '../PopOver/TableItemAction/TableItemActionContainer';
import {
  ADD_SHOWCASE_ITEM,
  DELETE_SHOWCASE_ITEM,
  EDIT_SHOWCASE_ITEM,
  GET_ALL_SHOWCASE
} from './VendorShowcaseGraphQl';
import Constants from '../../Config/Constants';

const { GRAPHQL_HEADER_OPTIONS, USER_ROLE } = Constants;

const VendorShowcaseContainer = props => {
  const { companyId } = props;
  const [addModalVisibility, setAddModalVisibility] = useState(false);
  const [editModalVisibility, setEditModalVisibility] = useState(false);
  const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(0);
  const [hasMoreShowcase] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [modalTitle, setModalTitle] = useState('Add Showcase');
  const typeForm = 'showcase';
  let dataSource = [];

  const companyIdVariables = {
    variables: { companyId: companyId, offset: offset, limit: limit },
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } },
    notifyOnNetworkStatusChange: true
  };
  const {
    loading,
    data,
    refetch,
    error,
    fetchMore: fetchMoreShowcase
  } = useQuery(GET_ALL_SHOWCASE, companyIdVariables);
  const [addVendorShowcaseItem] = useMutation(
    ADD_SHOWCASE_ITEM,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  const [editVendorShowcaseItem] = useMutation(
    EDIT_SHOWCASE_ITEM,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  const [deleteVendorShowcaseItem] = useMutation(
    DELETE_SHOWCASE_ITEM,
    GRAPHQL_HEADER_OPTIONS(USER_ROLE.ORG_ADMIN)
  );

  if (data) {
    data.marketplace_vendor_showcase.forEach(item => {
      const { id, name, description } = item;
      const totalShowcase = item.marketplace_products_aggregate.aggregate.count;
      dataSource.push({
        key: id,
        name: name,
        description: description,
        product: totalShowcase
      });
    });
  }
  useEffect(() => {
    if (data) {
      setTotalData(data.total.aggregate.count);
    }
  }, [data]);

  if (error) {
    message.error('Something went wrong');
  }
  if (offset < 0) {
    setOffset(0);
  }

  const _handleAddModalVisibility = () => {
    setModalTitle('Add Showcase');
    setAddModalVisibility(!addModalVisibility);
  };

  const _handleEditModalVisibility = () => {
    setModalTitle('Edit Showcase');
    setEditModalVisibility(!editModalVisibility);
  };

  const _handleDeleteModalVisibility = () =>
    setDeleteModalVisibility(!deleteModalVisibility);

  const _hideModal = () => {
    setAddModalVisibility(false);
    setEditModalVisibility(false);
    setDeleteModalVisibility(false);
  };

  const columns = [
    {
      title: 'List',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (recordId, source) => {
        return (
          <TableItemActionContainer
            dataSource={source}
            recordId={recordId}
            setSelectedRecordId={setSelectedRecordId}
            handleEditModalVisibility={_handleEditModalVisibility}
            handleDeleteModalVisibility={_handleDeleteModalVisibility}
            typeForm={typeForm}
          />
        );
      }
    }
  ];

  const handleLimit = limit => {
    setLimit(parseInt(limit));
  };
  const fetchMore = (offsetState, newOffset) => {
    fetchMoreShowcase({
      variables: {
        offset: offsetState,
        companyId: companyId,
        limit: limit
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }
        return Object.assign({}, prevResult, {
          marketplace_vendor_showcase: [
            ...fetchMoreResult.marketplace_vendor_showcase
          ]
        });
      }
    });
    setOffset(newOffset);
  };

  const nextShowcase = () => {
    fetchMore(offset + limit, offset + limit);
  };
  const prevShowcase = () => {
    const prevOffset = offset - limit < 0 ? 0 : offset - limit;
    fetchMore(prevOffset, prevOffset);
  };

  return (
    <VendorShowcaseComponent
      {...props}
      columns={columns}
      loading={loading}
      modalTitle={modalTitle}
      dataSource={dataSource}
      refetchShowcaseData={refetch}
      selectedRecordId={selectedRecordId}
      setSelectedRecordId={setSelectedRecordId}
      addModalVisibility={addModalVisibility}
      editModalVisibility={editModalVisibility}
      addVendorShowcaseMutation={addVendorShowcaseItem}
      updateVendorShowcaseMutation={editVendorShowcaseItem}
      updateVendorShowcaseItem={editVendorShowcaseItem}
      handleAddModalVisibility={_handleAddModalVisibility}
      deleteShowcaseMutation={deleteVendorShowcaseItem}
      deleteModalVisibility={deleteModalVisibility}
      companyIdVariable={companyIdVariables}
      hideModal={_hideModal}
      offset={offset}
      limit={limit}
      hasMoreShowcase={hasMoreShowcase}
      handleLimit={handleLimit}
      nextShowcase={nextShowcase}
      prevShowcase={prevShowcase}
      totalData={totalData}
    />
  );
};

export default VendorShowcaseContainer;
