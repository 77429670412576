import React from 'react';
import { Modal, Input, Button } from 'antd';
import './SpecificFollower.css';
import style from './SpecificFollower.style';
import { FollowerCheckBox, SearchNotFound } from '../../Components';
import { delaySearch } from '../../Utils/CommonUtils';

const { Search } = Input;

const SpecificFollowerComponent = props => {
  const {
    followers,
    checkedFollowers,
    handleChangeCheckBox,
    isShowSpecificFollower,
    saveFollowers,
    resetFollowers,
    setSearch,
    handleScroll
  } = props;

  let timeout = 0;
  const quickSearch = search => {
    timeout = delaySearch(timeout, () => setSearch(search.trim()));
  };

  const unchecked = followers?.filter(user => {
    const checkValue = checkedFollowers?.map(follower => {
      return follower.id === user.id;
    });
    return !checkValue.includes(true);
  });

  const _renderSearch = () => (
    <Search
      className="search-modal"
      style={style.search(checkedFollowers)}
      placeholder="Search Follower"
      onChange={event => quickSearch(event.target.value)}
    />
  );

  const _renderFooter = () => [
    <Button key="reset" onClick={resetFollowers}>
      Cancel
    </Button>,
    <Button key="submit" style={style.saveButton} onClick={saveFollowers}>
      Save
    </Button>
  ];

  const _renderFollowerCheckBox = (followers, isChecked) => (
    <FollowerCheckBox
      handleChangeCheckBox={handleChangeCheckBox}
      followers={followers}
      isChecked={isChecked}
    />
  );

  const _renderContentModal = () => {
    return (
      <>
        <div style={style.div}>
          {_renderSearch()}
          {_renderFollowerCheckBox(checkedFollowers, true)}
        </div>
        {followers?.length > 0 && (
          <>
            <hr style={style.hr} />
            <div
              style={style.div}
              className="component_render_follower_check_box"
              id="follower-checkbox"
              onScroll={handleScroll ? handleScroll : null}
            >
              {_renderFollowerCheckBox(unchecked, false)}
            </div>
          </>
        )}
        {followers?.length === 0 && (
          <div style={style.div}>
            <hr style={style.hr} />
            <SearchNotFound
              title="Empty Specific Follower"
              desc="Nothing came up for that search."
            />
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      closable={false}
      bodyStyle={style.bodyModal}
      title={'Specific Follower'}
      visible={isShowSpecificFollower}
      footer={_renderFooter()}
    >
      {_renderContentModal()}
    </Modal>
  );
};

export default SpecificFollowerComponent;
