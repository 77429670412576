import Constants from '../../../Config/Constants';

export default {
  container: {
    backgroundColor: Constants.COLORS.WHITE,
    marginBottom: 30,
    boxShadow: Constants.BOX_SHADOW
  },
  contentProduct: {
    width: '95%',
    margin: 'auto',
    marginTop: 20,
    paddingBottom: 20
  },
  buttonProduct: {
    color: Constants.COLORS.WHITE,
    backgroundColor: Constants.COLORS.DARK_BLUE,
    fontSize: 12,
    width: 110
  },
  editText: {
    marginLeft: 14,
    color: Constants.COLORS.LIGHT_BLUE,
    cursor: 'pointer'
  },
  select: {
    width: 220,
    fontSize: 12
  },
  option: {
    fontSize: 12
  },
  collapseText: {
    cursor: 'pointer',
    fontSize: 12,
    color: Constants.COLORS.LIGHT_BLUE,
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 10
  }
};
