import React from "react";
import { BadgeContainer, Title, BlueText, GreyText, AvatarStyled } from "./Badges.style";
import { Col } from "antd";
import { getYear } from "./utilsBadge";

export default function Badge({ data }) {
  return (
    <Col span={12} style={{ margin: "12px 0px" }}>
      <BadgeContainer>
        <AvatarStyled
          size={62}
          shape="square"
          src={data?.talent_box?.badge ?? null}
        />
        <Title>{data?.talent_box?.name ?? "-"}</Title>
        <BlueText>{data?.talentVersionByTalentVersion?.name ?? "-"}</BlueText>
        <GreyText>
          {getYear(data?.talentVersionByTalentVersion?.start_date, data?.talentVersionByTalentVersion?.end_date)}
        </GreyText>
      </BadgeContainer>
    </Col>
  );
}
