import baseService from '../BaseService';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';

const searchByKeywordAndType = (
  keyword = '',
  type = 'all',
  addedParam = {}
) => {
  // type 'all', 'users', 'jobs', 'learning', 'company', 'teams'
  let parameters = {
    params: {
      query: keyword,
      type: type
    }
  };
  if (isObjectEmpty(keyword)) {
    delete parameters.query;
  }
  // const userId = AuthenticationService.getUserId();
  const searchUrl = Constants.SEARCH;

  return baseService.get(searchUrl, parameters, addedParam);
};

const searchGroup = () => baseService.get(`search/team`);
export const searchMyGroup = async (keyword, nextPage, maxResults = 5) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: maxResults,
      name: keyword
    }
  };

  if (isObjectEmpty(nextPage)) {
    delete parameters.page;
  }
  if (isObjectEmpty(keyword)) {
    delete parameters.params.name;
  }

  const userId = AuthenticationService.getUserId();
  const getListGroupUrl = Constants.GROUPS(userId);
  return await baseService.get(getListGroupUrl, parameters);
};

const getUser = (keyword = '', page = 1, addedParam = {}) => {
  let parameters = {
    params: {
      query: keyword,
      maxResults: 20,
      page: page
    }
  };
  if (isObjectEmpty(keyword)) {
    delete parameters.params.query;
  }
  // const userId = AuthenticationService.getUserId();
  const searchUrl = Constants.USER;
  return baseService.get(searchUrl, parameters, addedParam);
};

const getDetailUser = userId => {
  // const userId = AuthenticationService.getUserId();
  const searchUrl = Constants.USER_ID(userId);
  return baseService.get(searchUrl);
};

const searchByKeywordAndTypeActive = (
  keyword = '',
  type = 'active',
  addedParam = {}
) => {
  // type 'all', 'users', 'jobs', 'learning', 'company', 'teams'
  let parameters = {
    params: {
      query: keyword,
      status: type
    }
  };
  if (isObjectEmpty(keyword)) {
    delete parameters.query;
  }
  // const userId = AuthenticationService.getUserId();
  const searchUrl = Constants.USER;

  return baseService.get(searchUrl, parameters, addedParam);
};

export default {
  searchByKeywordAndType,
  searchGroup,
  searchMyGroup,
  getUser,
  getDetailUser,
  searchByKeywordAndTypeActive
};
