import React, { useState } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { ButtonWlb, DummySpace } from '../../../Components';
import style, { PaymentScreenWrap } from './PaymentScreen.style';

const PaymentScreenComponent = props => {
  const { dataReg, addRegistration, dataUploadFile, dataBank } = props;
  const [location, setLocation] = useState({});
  const { getFieldDecorator } = props.form;
  const { setMenuAndCurrentSteps } = props;
  const { Option } = Select;
  const { TextArea } = Input;
  const selectionData = {
    bank_name: dataBank
  };
  const formItems = [
    {
      type: 'number',
      labelName: 'Bank Account',
      fieldName: 'bank_account'
    },
    {
      type: 'number',
      labelName: 'Bank Account Number',
      fieldName: 'bank_account_number'
    },
    {
      type: 'selection',
      labelName: 'Bank Name',
      fieldName: 'bank_name',
      dependencyField: null
    },
    {
      type: 'text',
      labelName: 'Owner Name',
      fieldName: 'owner_name'
    }
  ];
  const updateLocationField = (
    fieldName,
    value,
    locationToUpdate = location
  ) => {
    let updatedLocation = { ...locationToUpdate };
    updatedLocation[fieldName] = value;
    setLocation(updatedLocation);
  };

  const isLocationFieldUndefined = fieldName => {
    return location[fieldName] === undefined;
  };

  const _mapOptionFromMockSelection = fieldName => {
    if (fieldName === 'bank_name') {
      return selectionData[fieldName].map(item => (
        <Option key={item.id} value={item.name}>
          {item.name}
        </Option>
      ));
    }
    return selectionData[fieldName].map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  };

  const _switchComponentToRender = formItem => {
    const { type, dependencyField, labelName, fieldName } = formItem;

    if (type === 'selection') {
      const isDisabled =
        dependencyField === null
          ? false
          : isLocationFieldUndefined(dependencyField);
      return (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          disabled={isDisabled}
          placeholder={`Select ${labelName}`}
          onChange={value => updateLocationField(fieldName, value)}
        >
          {_mapOptionFromMockSelection(fieldName)}
        </Select>
      );
    }

    if (type === 'text' || type === 'number') {
      return (
        <Input
          type={type}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }

    if (type === 'textArea') {
      return (
        <TextArea
          rows={4}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }
  };
  const renderFormItem = formItem => {
    const { fieldName, labelName } = formItem;
    return (
      <Form.Item key={formItem.fieldName} label={labelName}>
        {getFieldDecorator(fieldName, {
          rules: [
            {
              required: true,
              message: `Please input ${labelName.toLowerCase()}`
            }
          ]
        })(_switchComponentToRender(formItem))}
      </Form.Item>
    );
  };

  const _handleSubmit = event => {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const variable = {
          data: {
            brand_name: dataReg.vendor_name,
            industry_type: dataReg.vendor_category,
            legal_name: dataReg.pt_cv,
            types: '00100',
            type: 'vendor',
            company_addresses: {
              data: {
                office_name: dataReg.vendor_name,
                address: dataReg.business_address,
                province: dataReg.province,
                city: dataReg.city,
                postal_code: dataReg.zip_code,
                npwp_number: dataReg.npwp
              }
            },
            company_files: {
              data: {
                type: 5,
                name: dataUploadFile.name,
                file: dataUploadFile.url
              }
            },
            company_profile_bank: {
              data: {
                bank: values.bank_account,
                bank_name: values.bank_name,
                account_name: values.owner_name,
                account_number: values.bank_account_number
              }
            },
            profile_field: {
              vendor_classification: dataReg.vendor_classification
            }
          }
        };

        addRegistration({
          variables: variable
        });
        setMenuAndCurrentSteps('Ready', 2);
      }
    });
  };

  return (
    <PaymentScreenWrap>
      <Row>
        <Col span={24}>
          <div style={style.center}>
            <h2>Payment Setup</h2>
          </div>
          <Form layout="vertical" onSubmit={_handleSubmit}>
            {formItems.map(item => renderFormItem(item))}
            <div style={style.buttonContinue}>
              <ButtonWlb
                type="secondary"
                onClick={() => setMenuAndCurrentSteps('Store', 0)}
              >
                Previous
              </ButtonWlb>
              <ButtonWlb type="primary" htmlType="submit">
                Next
              </ButtonWlb>
              <DummySpace />
            </div>
          </Form>
        </Col>
      </Row>
    </PaymentScreenWrap>
  );
};

export default Form.create({ name: 'PaymentScreenForm' })(
  PaymentScreenComponent
);
