import { Avatar, Col, Row } from 'antd';
import React from 'react';
import Assets from '../../../Assets';
import { AuthenticationService } from '../../../Services';
import { style, AkhlakWrapper } from './Akhlak.style';

const AkhlakItem = ({ icon, title, link }) => {
  return (
    <AkhlakWrapper
      onClick={() => window.open(link, '_blank', 'noreferrer')}
      className={`item`}
    >
      <Avatar shape="square" src={icon} size={43} />
      <h2 className="title">{title}</h2>
    </AkhlakWrapper>
  );
};

const _renderHeader = () => (
  <div>
    <Row>
      <Col span={3}>
        <img
          src={Assets.ICON.TEAM_ICON}
          alt="AKHLAK"
          style={style.headerIcon}
        />
      </Col>
      <Col span={21}>
        <span style={style.headerTitle}>Solusi Budaya</span>
      </Col>
    </Row>

    <hr style={style.hr} />
  </div>
);

export default function AkhlakComponent({ withHeader = true }) {
  const DATA = [
    {
      id: 1,
      icon: Assets.LOGO_ACHI,
      title: 'Indeks Budaya',
      link: `https://ochi.actconsulting.co/achi-report/${AuthenticationService.getCompanyId()}`
    }
    // {
    //   id: 2,
    //   icon: Assets.LOGO_CMS,
    //   title: 'Bimbingan Budaya',
    //   link: `https://cms.actconsulting.co/auth/login_cms/${AuthenticationService.getCompanyId()}`
    // },
    // {
    //   id: 3,
    //   icon: Assets.LOGO_ADI,
    //   title: 'Internalisasi Digital',
    //   link: `https://corporate.esqtrainingonline.com/u/${AuthenticationService.getCompanyId()}`
    // },
    // {
    //   id: 4,
    //   icon: Assets.LOGO_GAMIFIKASI,
    //   title: 'Gim Android',
    //   link:
    //     'https://play.google.com/store/apps/details?id=com.Tictech.AKHLAKLIFE'
    // },
    // {
    //   id: 5,
    //   icon: Assets.LOGO_GAMIFIKASI,
    //   title: 'Gim iPhone',
    //   link: 'https://apps.apple.com/id/app/akhlak-in-action/id1568062975'
    // }
  ];

  const _renderBody = () => (
    <Row type="flex" style={style.wrapperMenu}>
      {DATA.map(res => (
        <AkhlakItem key={res.id} {...res} />
      ))}
    </Row>
  );

  return (
    <div style={withHeader ? style.container : {}}>
      {withHeader && _renderHeader()}
      {_renderBody()}
    </div>
  );
}
