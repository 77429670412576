import styled from 'styled-components';
import Constants from '../../Config/Constants';

export const MemberCardBoxWrap = styled.div`
  .box {
    margin-bottom: 24px;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: ${Constants.COLORS.WHITE};
    hr {
      background-color: ${Constants.COLORS.LIGHT_GREY};
      height: 1px;
      border: none;
    }
    .request {
      color: ${Constants.COLORS.DARK_BLUE};
      cursor: pointer;
      font-weight: 600;
    }
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  .component_no_member {
    text-align: center;
    font-weight: bold;
    text-transform: capitalize;
    padding: 30px 0;
  }
`;
