import React, { useState, useEffect } from 'react';
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient
} from 'react-apollo';
import _ from 'lodash';
import PostListComponent from './PostList.component';
import useInfiniteScroll from '../../SharedComponents/CustomHook/useInfiniteScroll';
import { getCookie } from '../../Utils/CommonUtils';
import { message } from 'antd';

import {
  GET_LIST_CLASSROOM_POST,
  DELETE_CLASSROOM_POST,
  GET_CLASSROOM_POST_BY_ID
} from './classroomPost.graphql';
import { GET_LIST_MEMBER } from './ListClassroomMembers.graphql';

const PostListClassroom = props => {
  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const user = _.isEmpty(userData)
    ? {}
    : {
      id: userData.id,
      avatar: userData.avatar,
      name: userData.name,
      people_work_placements: userData.people_work_placements
    };
  const { blockedMe, listType } = props;
  const classroomId = props.match.params.id;
  const postId = props.match.params.postId;
  const client = useApolloClient();

  const [dataPost, setDataPost] = useState([]);
  const [limit, setLimit] = useState(3);
  const [isBottom, setIsBottom] = useInfiniteScroll('classroom-post');
  const [isLoading, setIsLoading] = useState(false);

  const [
    getClassroomPosts,
    { data: dataClassroomPost, loading: loadingTest }
  ] = useLazyQuery(GET_LIST_CLASSROOM_POST, {
    fetchPolicy: 'no-cache',
    context: { headers: { 'X-Hasura-Role': 'user' } },
  });
  const { data: listMember } = useQuery(GET_LIST_MEMBER, {
    variables: { classroom_id: { _eq: classroomId } },
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [deleteClassroomPost] = useMutation(DELETE_CLASSROOM_POST);

  const renderPost = array => {
    const newData = [];
    array.forEach(item => {
      let newObj = {};
      newObj.activity = item.activity;
      newObj.classroom_id = item.classroom_id;
      newObj.date = item.date;
      newObj.learning_tracker_mentees =
        item.classroom_learning_tracker_mentees || [];
      newObj.commitment = item.commitment;
      newObj.location = item.location;
      newObj.content = item.content;
      newObj.date_added = item.created_at;
      newObj.duration = item.duration;
      newObj.event = item.event_schedule ? item.event_schedule.id : null;
      newObj.user = item.global_user;
      newObj.id = item.id;
      newObj.topic = item.topic;
      newObj.total_comment = item.classroom_post_comments.length;
      newObj.total_like = item.classroom_post_likes.length;
      newObj.type = item.type;
      newObj.userId = item.global_user.id;
      newObj.user.people_work_placements = newWorkPlacements(
        item.global_user.people_work_placements
      );

      const newLikes = [];
      newObj.description = item.description;
      item.classroom_post_likes.forEach(item => {
        newLikes.push(item.global_user.id);
      });
      newObj.likedBy = newLikes;

      const newTags = [];
      if (!_.isNull(item.tags)) {
        item.tags.forEach(tag => {
          listMember.classroom_members.forEach(member => {
            if (tag === member.global_user.id) {
              newTags.push(member.global_user);
            }
          });
        });
      }
      newObj.tags = newTags;

      const newFiles = [];
      const newImages = [];
      item.classroom_post_files.forEach(file => {
        let newObjFile = {};

        newObjFile.name = file.name;
        newObjFile.filetype = file.type;
        newObjFile.url = file.file;

        if (file.type.includes('image')) {
          newImages.push(newObjFile);
        } else {
          newFiles.push(newObjFile);
        }
      });
      newObj.images = newImages;
      newObj.files = newFiles;

      const newComments = [];
      item.classroom_post_comments.forEach(comment => {
        let newObjComment = {};

        newObjComment.id = comment.id;
        newObjComment.user = comment.global_user;
        newObjComment.userId = comment.global_user.id;
        newObjComment.content = comment.content;
        newObjComment.date_added = comment.date_added;
        newObjComment.user.people_work_placements = newWorkPlacements(
          comment.global_user.people_work_placements
        );
        newComments.push(newObjComment);
      });
      newObj.comment = newComments;

      newData.push(newObj);
    });

    let filteredPost = newData.filter(item => !blockedMe.includes(item.userId));

    return filteredPost;
  };

  useEffect(() => {
    if (!dataClassroomPost) {
      getClassroomPosts({ variables: { classroomId, offset: 0, limit } });
    }
    setIsBottom(false);
    if (!loadingTest && isBottom) {
      setLimit(limit + 3);
      getClassroomPosts({
        variables: { classroomId, offset: 0, limit: limit + 3 }
      });
      setIsBottom(false);
    }
    if (listType === 'detail-post') {
      setIsLoading(true);
      client
        .query({
          query: GET_CLASSROOM_POST_BY_ID,
          variables: {
            id: postId
          }
        })
        .then(res => {
          setDataPost(renderPost(res.data.classroom_posts));
        })
        .catch(() => {
          setDataPost([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      if (dataClassroomPost) {
        setDataPost(renderPost(dataClassroomPost.classroom_posts));
      }
    }
  }, [dataClassroomPost, listMember, blockedMe, isBottom, listType]);

  const newWorkPlacements = workPlacements => {
    const newWorkPlacements = [];
    workPlacements.forEach(work => {
      let newObjWorkPlacements = {};

      if (work.company_job_profile) {
        newObjWorkPlacements.company_employee_position = {
          name: work.company_job_profile.title
        };
        newObjWorkPlacements.company_profile = work.company_profile;
      } else {
        newObjWorkPlacements = work;
      }
      newWorkPlacements.push(newObjWorkPlacements);
    });
    return newWorkPlacements;
  };

  function deletePost(post) {
    deleteClassroomPost({ variables: { postId: post.id } })
      .then(() => {
        let newDataPost = dataPost.filter(p => p.id !== post.id);
        setDataPost(newDataPost);
        message.success('Postingan Anda telah dihapus');
      })
      .catch(() => message.error('Something went wrong'));
  }

  function restartPage(newData) {
    const dataMentees =
      newData.mentees &&
      newData.mentees.map(item => {
        return { classroom_member: { global_user: { ...item } } };
      });

    let newObj = {};
    newObj.classroom_id = newData.classroomId;
    newObj.activity = newData.activity || '';
    newObj.comment = [];

    const newFiles = [];
    const newImages = [];
    newData.files.forEach(file => {
      let newObjFile = {};

      newObjFile.name = file.name;
      newObjFile.filetype = file.type;
      newObjFile.url = file.file;

      if (file.type.includes('image')) {
        newImages.push(newObjFile);
      } else {
        newFiles.push(newObjFile);
      }
    });
    newObj.files = newFiles;
    newObj.images = newImages;

    newObj.images = newImages;
    newObj.files = newFiles;
    newObj.commitment = '';
    newObj.content = newData.content || '';
    newObj.date_added = new Date().toISOString();
    newObj.description = newData.description || '';
    newObj.likedBy = [];
    newObj.learning_tracker_mentees = dataMentees || [];
    newObj.duration = newData.duration || 0;
    newObj.event = newData.event || '';
    newObj.user = user;
    newObj.id = newData.id;
    newObj.total_comment = 0;
    newObj.total_like = 0;
    newObj.type = newData.type;
    newObj.userId = user.id;

    const newTags = [];
    if (newData.tags.length > 0 && listMember) {
      newData.tags.forEach(tag => {
        listMember.classroom_members.forEach(member => {
          if (tag === member.global_user.id) {
            newTags.push(member.global_user);
          }
        });
      });
    }
    newObj.tags = newTags;
    newObj.location = newData.location || {};

    const newDataPost = [];
    newDataPost.push(newObj);
    dataPost.forEach(data => {
      newDataPost.push(data);
    });

    setDataPost(newDataPost);
  }

  function onClickEdit(item) {
    props.history.push(`/social-learning/${classroomId}/post/${item.id}/edit`);
  }

  return (
    <PostListComponent
      data={dataPost}
      isFetching={listType === 'detail-post' ? isLoading : loadingTest}
      deletePost={deletePost}
      editPost={onClickEdit}
      restartPage={restartPage}
      {...props}
    />
  );
};
export default PostListClassroom;
