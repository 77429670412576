import { gql } from 'apollo-boost';

export const GET_COMPETENCIES = gql`
  query($query: String!) {
    learning_log_competencies(where: { title: { _ilike: $query } }) {
      id
      title
    }
  }
`;
