import styled from 'styled-components';
import Assets from '../../Assets';

export const BalanceTrackWrap = styled.div`
  position: relative;
  display: flex;
  padding: 16px 0;
  .item {
    width: 100%;
    .trans-id {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 17px;
      color: #373e41;
    }
    .trans-time {
      color: ${Assets.COLOR_PALLETE.grey};
      font-family: ${Assets.FONT.MULI_BOLD};
      font-size: 11px;
    }
    .total {
      color: ${props => (props.type === 'spend' ? `#ef4d5e` : `#4caf50`)};
      font-family: ${Assets.FONT.MULI_BOLD};
      font-size: 20px;
    }
    .remaining {
      color: ${Assets.COLOR_PALLETE.grey};
      font-family: ${Assets.FONT.MULI_BOLD};
      font-size: 11px;
    }
  }
  .item:last-child {
    text-align: right;
  }

  .ant-btn {
    font-family: ${Assets.FONT.MULI};
    font-size: 11px;
    color: #03a5e7;
    padding: 0;
  }
`;
