import React from 'react';
import { Icon, Input, Select, Table } from 'antd';
import {
  TopSettingGroup,
  DisplayLoading,
  Pagination
} from '../../../Components';
import config from './VendorOrderList.config';
import style, {
  OrderListWrapper,
  SelectBorderless
} from './VendorOrderList.style';

import OrderDetail from '../OrderDetail';
import { Filter } from '@worklifebeyond/wlb-utils-components';
import { ButtonFilter, FilterText } from '../../../SharedComponents';
import {
  anchorOrigin,
  dateFormat,
  listFilter,
  transformOrigin
} from './OrderFilterData';
import { FlexBetween } from '../../../Components/ClassroomFeedback/ClassroomFeedback.style';

const searchStyle = {
  height: 40,
  backgroundColor: '#f7f8f9',
  borderRadius: '5px'
};

const VendorOrderListComponent = props => {
  const {
    orderList,
    totalOrder,
    setPage,
    offset,
    limit,
    setLimit,
    // rowSelection,
    loading,
    handleOrderDetail,
    orderDetail,
    selectedId,
    filterData,
    setFilterData
  } = props;
  const { Option } = Select;

  const prevPage = () => {
    setPage(offset - limit);
  };

  const nextPage = () => {
    setPage(offset + limit);
  };

  const handleApply = res => {
    const raw = res[0];
    const include = {};

    for (const key in raw) {
      let value = raw[key];

      if (Array.isArray(value)) {
        let next = [];

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx];
          const val = item.value;

          if (key === 'status' && Array.isArray(val)) {
            next = next.concat(val);
          } else {
            next.push(val);
          }
        }

        value = next.length ? next : null;
      } else if (key === 'date') {
        value = {
          from: dateFormat(value.from),
          to: dateFormat(value.to)
        };
      }

      include[key] = value;
    }

    setFilterData({ ...filterData, include });
  };
  return (
    <>
      {orderDetail ? (
        <OrderDetail
          orderId={selectedId}
          onPageBack={() => handleOrderDetail(null)}
        />
      ) : (
        <OrderListWrapper style={style.container}>
          <TopSettingGroup title={'Order List'} />
          <FlexBetween>
            <SelectBorderless
              value={filterData.orderBy}
              style={{ width: 120, border: 'none' }}
              bordered={false}
              onChange={e => {
                setFilterData({
                  ...filterData,
                  orderBy: e
                });
              }}
            >
              <Option key={1} value="desc">
                Latest
              </Option>
              <Option key={2} value="asc">
                Oldest
              </Option>
            </SelectBorderless>
            <Filter
              includeExclude={false}
              anchorOrigin={anchorOrigin}
              transformOrigin={transformOrigin}
              id="filter-employee-wlb"
              onApply={handleApply}
              listFilter={listFilter}
              maxHeight="450px"
            >
              <ButtonFilter position="end">
                <FilterText variant="body2">Filter</FilterText>
              </ButtonFilter>
            </Filter>
          </FlexBetween>
          <div style={{ padding: '10px 21px' }}>
            <Input
              style={searchStyle}
              suffix={
                <Icon className={'icon-search-bar-header-wlb'} type="search" />
              }
              value={filterData.search}
              onChange={e => {
                setFilterData({
                  ...filterData,
                  search: e.target.value
                });
              }}
              placeholder={'Search'}
            />
          </div>

          {loading ? (
            <DisplayLoading />
          ) : (
            <>
              <Table
                onRow={record => {
                  return {
                    onClick: () => {
                      handleOrderDetail(record);
                    }
                  };
                }}
                className="order-list-table"
                style={style.table}
                pagination={false}
                // rowSelection={rowSelection}
                columns={config.columns}
                dataSource={orderList?.global_invoices}
                rowKey={order => order.id}
              />
              <div className={'order-list-page'}>
                <Pagination
                  offset={offset}
                  limit={limit}
                  total={totalOrder}
                  prevClick={() => prevPage()}
                  nextClick={() => nextPage()}
                  onChangeLimit={e => setLimit(parseInt(e.target.value))}
                />
              </div>
            </>
          )}
        </OrderListWrapper>
      )}
    </>
  );
};

export default VendorOrderListComponent;
