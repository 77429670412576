import React from 'react';
import { Select, Avatar, Icon, Spin, Row, Col } from 'antd';
import { Wrapper, style } from './Blocked.style';
import BlockCard from './BlockCard';
import Assets from '../../../Assets';
import { isEmpty } from 'lodash';
import { DisplayLoading } from '../../../Components';

const BlockedComponent = props => {
  const {
    usersBlocked,
    searchUser,
    isFetching,
    isLoading,
    searchHandler,
    changeBlockHandler
  } = props;

  const notFounded = () => (
    <div className="not-founded">
      <img src={Assets.ICON.PEOPLE_NOT_FOUND} alt="People Not Found" />
      <h3>Anda belum memasukkan daftar akun untuk diblok</h3>
      <p>Anda belum memasukkan daftar akun untuk diblok</p>
    </div>
  );
  let timeout = 0;
  const _Search = value => {
    const keyword = value.trim();
    clearTimeout(timeout);
    timeout = setTimeout(function() {
      searchHandler(keyword);
    }, 1000);
  };

  const option = searchUser.map(user => (
    <Select.Option key={user.id} label={user.name} value={user.id}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar src={user.avatar} style={{ marginRight: 10 }} />
          <span style={style.title}>
            <p style={style.name}>{user.name}</p>
            <p style={style.description}>
              {user.title} di {user.company}
            </p>
          </span>
        </div>
        <div style={{ color: 'red' }}>
          <Icon type="stop" /> Blok
        </div>
      </div>
    </Select.Option>
  ));

  const addBlockedAccount = () => {
    return (
      <Select
        className="search-block-account"
        placeholder="Cari Nama Akun yang akan diblok"
        showArrow={false}
        // value={value}
        showSearch
        onSearch={_Search}
        onChange={value => changeBlockHandler(value)}
        filterOption={false}
        optionLabelProp="label"
        labelInValue
        notFoundContent={isFetching ? <Spin size="small" /> : 'Tidak ada data'}
      >
        {option}
      </Select>
    );
  };
  const renderBlockedUser = () => {
    return (
      <Row gutter={16}>
        {isLoading && <DisplayLoading />}
        {!isLoading &&
          usersBlocked?.map(user => (
            <Col key={user.id} span={12}>
              <BlockCard user={user} {...props} />
            </Col>
          ))}
      </Row>
    );
  };
  return (
    <Wrapper id="blocked-user">
      {/* <p>Block Account</p> */}
      {addBlockedAccount()}
      {renderBlockedUser()}
      {isEmpty(usersBlocked) && !isLoading && notFounded()}
    </Wrapper>
  );
};

export default BlockedComponent;
