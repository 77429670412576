import { Avatar, Col, Row } from 'antd';
import React from 'react';
import Assets from '../../../Assets';
import { routes } from '../../../Config/Router';
import { style, AkhlakWrapper } from '../Akhlak/Akhlak.style';

const CultureItem = ({ icon, title, onClick, _id, className = '' }) => {
  return (
    <AkhlakWrapper id={_id} onClick={onClick} className={`item`}>
      <Avatar shape="square" src={icon} size={35} />
      <h2 className={'title' + className}>{title}</h2>
    </AkhlakWrapper>
  );
};

const _renderHeader = () => (
  <div>
    <Row>
      <Col span={3}>
        <img
          src={Assets.ICON.TEAM_ICON}
          alt="AKHLAK"
          style={style.headerIcon}
        />
      </Col>
      <Col span={21}>
        <span style={style.headerTitle}>Ekosistem Budaya</span>
      </Col>
    </Row>

    <hr style={style.hr} />
  </div>
);

const _open = link => window.open(link, '_blank', 'noreferrer');

export default function AkhlakComponent({ withHeader = true }) {
  const DATA = [
    {
      _id: '__communication',
      icon: Assets.LOGO_COMMUNICATION_COLLABORATION,
      title: 'Komunikasi & Kolaborasi',
      onClick: () => _open(routes.DEFAULT.path)
    },
    {
      _id: '__feedback',
      icon: Assets.LOGO_FEEDBACK,
      title: 'Umpan Balik',
      onClick: () => _open(`${process.env.REACT_APP_HC_PERFORMANCE}survey`)
    },
    {
      _id: '__working-group',
      icon: Assets.LOGO_WORKING_GROUP,
      title: 'Grup',
      onClick: () => _open(routes.TEAM.path),
      className: ' p4'
    },
    {
      _id: '__learning',
      icon: Assets.LOGO_LEARNING,
      title: 'Pembelajaran',
      onClick: () => _open(process.env.REACT_APP_NEW_LEARNING)
    }
  ];

  const _renderBody = () => (
    <Row type="flex" style={style.wrapperMenu}>
      {DATA.map(res => (
        <CultureItem key={res._id} {...res} />
      ))}
    </Row>
  );

  return (
    <div style={withHeader ? style.container : {}}>
      {withHeader && _renderHeader()}
      {_renderBody()}
    </div>
  );
}
