import React from 'react';
import { Status, StatusWrap } from './VendorOrderStatus.style';
import config from './VendorOrderStatus.config';

const VendorOrderStatusComponent = ({ status }) => {
  const isRefundPaid = status === 'refund_paid';

  const isRejected = status === 'rejected';

  const isPaid = status === 'paid';
  const isReview = status === 'review';
  const isPacking = status === 'packing';
  const isShipping = status === 'shipping';
  const isCompleted = status === 'completed';

  const statuses = [
    {
      label: 'Payment Review',
      name: 'payment',
      active: isPaid || isReview || isPacking || isShipping || isCompleted
    },
    {
      label: 'Order Review',
      name: 'review',
      active: isReview || isPacking || isShipping || isCompleted
    },
    {
      label: 'Packing',
      name: 'packing',
      active: isPacking || isShipping || isCompleted
    },
    {
      label: 'Shipping',
      name: 'shipping',
      active: isShipping || isCompleted
    },
    {
      label: 'Completed',
      name: 'completed',
      active: isCompleted
    }
  ];

  const extras = [
    {
      label: 'Rejected',
      name: 'rejected',
      active: isRejected
    },
    {
      label: 'Refunded',
      name: 'refunded',
      active: isRefundPaid
    }
  ];

  return (
    <StatusWrap>
      {statuses.map((item, index) => (
        <Status key={index} active={item.active}>
          <span className={`icon ${item.name}`} />
          <span className="tooltip">{item.label}</span>
        </Status>
      ))}

      <div style={{ flexGrow: 1, marginRight: 8 }} />

      {extras.map((item, index) => (
        <Status key={index} active={item.active}>
          <span className={`icon ${item.name}`} />
          <span className="tooltip">{item.label}</span>
        </Status>
      ))}
    </StatusWrap>
  );
};

VendorOrderStatusComponent.propTypes = config.propTypes;
VendorOrderStatusComponent.defaultProps = config.defaultProps;

export default VendorOrderStatusComponent;
