import styled from 'styled-components';
import Assets from '../../../Assets';

export const ShippingWrap = styled.div`
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
`;

export const ContentWrap = styled.div`
  position: relative;
  padding: 15px 24px;
  background: ${Assets.COLOR_PALLETE.white};

  h2 {
    margin: 0;
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 11px;
    font-family: ${Assets.FONT.MULI};
  }
`;

export const Footer = styled.div`
  position: relative;
  text-align: right;
  margin-top: 50px;
  padding: 15px 24px;

  .ant-checkbox-wrapper {
    width: 100%;
  }
`;

export default {
  select: {
    width: 120,
    fontSize: 12,
    marginTop: '8px'
  },
  option: {
    fontSize: 12
  }
};
