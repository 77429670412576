import { Badge } from '@material-ui/core';
import { GlobalHeader } from '@smartasn/wlb-utils-components';
import { message } from 'antd';
// import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useLocation } from 'react-router';
import { ModalConfirmation } from '..';
import EditRecommendation from '../../Pages/UserProfile/DetailScreen/Recommendation/EditRecommendation';
import { LIST_SENT_RECOMMENDATION } from '../../Pages/UserProfile/DetailScreen/Recommendation/Recommendation.graphql';
import { AuthenticationService, Notification } from '../../Services';
import {
  useGetNotification,
  useGetUnreadNotification
} from '../../SharedComponents/CustomHook';
import GiveRecommendation from '../ActionMorePeopleProfile/GiveRecommendation/GiveRecommendation.container';
import { Icon, NavbarWrapper } from './NavBar.style';
import PopoverDropdown from './PopoverDropdown';
import PopupHeader from './PopupHeader';
import PopupList from './PopupList';

const NavBarComponent = () => {
  const [recomendation, setRecomendation] = useState({
    open: false,
    data: null,
    subtype: null
  });
  const [visible, setVisible] = useState(false);
  const [openReadAll, setOpenReadAll] = useState(false);
  const [dataNotification, fetchNotification] = useGetNotification(4);
  const [count] = useGetUnreadNotification(true);
  const location = useLocation();

  const [
    getListSentRemnd,
    { data: dataSent, refetch: refetchSent }
  ] = useLazyQuery(LIST_SENT_RECOMMENDATION, {
    context: { headers: { 'X-Hasura-Role': 'public' } }
  });

  useEffect(() => {
    if (recomendation.subtype === 'social-referral-revision-request') {
      getListSentRemnd({
        variables: {
          user: AuthenticationService.getUserId(),
          id: recomendation?.data?.data?.recommendation_id
        }
      });
    }
  }, [getListSentRemnd, recomendation]);

  const splitPathname = i => location.pathname.split('/')[i];

  const renderHomeURL = () => {
    const url = process.env.REACT_APP_HC_SOSMED;
    switch (splitPathname(1)) {
      case 'group':
      case 'createGroup':
        return `${url}/group`;
      case 'company':
        return `${url}/company/${splitPathname(2)}/detail`;
      default:
        return url;
    }
  };

  const onOpenReadAll = () => {
    setOpenReadAll(!openReadAll);
    setVisible(false);
  };

  const handleVisibleChange = (isVisible, name) => {
    setVisible(!visible);
    if (name === 'notification') {
      if (isVisible) {
        fetchNotification();
      }
    }
  };

  const onSubmitReadAll = () => {
    Notification.readAllNotification()
      .then(() => window.location.reload(false))
      .catch(e => message.error('Something went wrong, ', e));
  };

  const env = {
    REACT_APP_SOSMED: process.env.REACT_APP_HC_SOSMED + '/',
    REACT_APP_HC_ADMIN: process.env.REACT_APP_HC_ADMIN,
    REACT_APP_HC_HOLDING: process.env.REACT_APP_HC_HOLDING,
    REACT_APP_DOMAIN: process.env.REACT_APP_DOMAIN,
    HOME_URL: renderHomeURL(),
    REACT_APP_CHAT: process.env.REACT_APP_CHAT,
    SSO_URL: process.env.REACT_APP_SSO_URL + '/',
    REACT_APP_LEARNING: process.env.REACT_APP_HC_LEARNING,
    rest_url: process.env.REACT_APP_BASE_URL_REST,
    wlb_rest_url: process.env.REACT_APP_NOTIFICATION_URL,
    USER_URL: process.env.REACT_APP_HC_TIME + '/',
    PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
    LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
    TALENT_URL: process.env.REACT_APP_HC_TALENT,
    VENDOR_URL: process.env.REACT_APP_HC_VENDOR
  };

  return (
    <NavbarWrapper>
      <div className="main">
        <GlobalHeader
          env={env}
          language="indonesian"
          renderNotif={
            <PopoverDropdown
              visible={visible}
              onVisibleChange={isVisible =>
                handleVisibleChange(isVisible, 'notification')
              }
              title={<PopupHeader title={'notification'} />}
              content={
                <PopupList
                  setVisible={setVisible}
                  setRecomendation={setRecomendation}
                  list={dataNotification}
                  onSeeAll={'/notification/all'}
                  onMarkAsRead={onOpenReadAll}
                />
              }
            >
              <Badge badgeContent={count} color="error">
                <Icon className={'notification'} active />
              </Badge>
            </PopoverDropdown>
          }
        />
      </div>
      <GiveRecommendation
        open={
          recomendation.open &&
          recomendation.subtype === 'social-referral-request'
        }
        onClose={() =>
          setRecomendation({
            ...recomendation,
            open: false,
            data: null,
            subtype: null
          })
        }
        dataNotification={recomendation.data}
        from="notification"
        subtype={recomendation.subtype}
        name={recomendation.data?.title}
      />
      <EditRecommendation
        refetchSent={refetchSent}
        open={
          recomendation.open &&
          recomendation.subtype === 'social-referral-revision-request'
        }
        onClose={() =>
          setRecomendation({
            ...recomendation,
            open: false,
            data: null,
            subtype: null
          })
        }
        name={recomendation.data?.data?.name?.replace(
          'asking you to revise your referral',
          ''
        )}
        id={recomendation?.data?.data?.recommendation_id}
        dataEdit={dataSent?.people_profile_recommendations?.[0] || {}}
      />
      <ModalConfirmation
        open={openReadAll}
        title="Mark as Read?"
        onCancel={onOpenReadAll}
        // tambahkan wording disini untuk notification modul apa saja yg membutuhkan popup agreement saat read all
        content={
          <>
            Are you sure want to mark as read your notification?{' '}
            <b>Learning </b> need further agreement.
          </>
        }
        textPrimary="Submit"
        onSubmit={onSubmitReadAll}
      />
    </NavbarWrapper>
  );
};

export default NavBarComponent;
