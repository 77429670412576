import styled from 'styled-components'

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px 43px 0px;
`;
export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Title = styled.h4`
  font-size: 21px;
  color: #ef4d5e;
  margin: 0px 0px 32px 0px;
`;
export const SubTitle = styled.h6`
  font-size: 12px;
  color: #000;
  margin: 0px;
`;
export const ButtonAction = styled.div`
  height: 45.5px;
  width: 50%;
  background-color: ${props => props.background};
  color: ${props => props.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;