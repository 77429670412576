import React, { useState } from 'react';
import { Switch } from 'antd';
import { UserProfile, AuthenticationService } from '../../../Services';
import useAsyncEffect from 'use-async-effect';

const FollowInformation = () => {
  const userId = AuthenticationService.getUserId();
  const [followSetting, setFollowSetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useAsyncEffect(async isMount => {
    setIsLoading(true);
    await UserProfile.getUserInfo(userId)
      .then(res => {
        if (!isMount) return;
        if (res.who_can_see_my_follow) {
          setFollowSetting(true);
        } else {
          setFollowSetting(false);
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const switchFollowSetting = () => {
    setIsLoading(true);
    if (followSetting) {
      UserProfile.whoCanSeeMyFollow(followSetting)
        .then(() => {
          setFollowSetting(false);
        })
        .finally(() => setIsLoading(false));
    } else {
      UserProfile.whoCanSeeMyFollow(followSetting)
        .then(() => {
          setFollowSetting(true);
        })
        .finally(() => setIsLoading(false));
    }
  };
  return (
    <>
      <div className="component_profile_information_panel">
        <section>
          <h4>Tentukan siapa yang dapat melihat informasi pengikut anda</h4>
        </section>
        <div className={'widget-toggle'}>
          <div className={'widget-status'}>
            {followSetting ? 'Pribadi' : 'Publik'}
          </div>
          <Switch
            loading={isLoading}
            onChange={() => switchFollowSetting()}
            checked={followSetting}
          />
        </div>
      </div>
    </>
  );
};

export default FollowInformation;
