import { message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { AuthenticationService } from '../../Services';
import UserBankAccountComponent from './UserBankAccount.component';
import { GET_LIST_BANK_ACCOUNT } from './UserBankAccount.graphql';

const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

export default function UserBankAccount({ userId, profileId }) {
  const [dataBank, setDataBank] = useState([]);
  const { data, error, refetch } = useQuery(GET_LIST_BANK_ACCOUNT, {
    variables: { userId: AuthenticationService.getUserId() },
    context: CONTEXT_USER,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data) {
      setDataBank([
        ...data.main_bank_account?.map(res => ({
          ...res?.people_profile_bank,
          is_main: true
        })),
        ...data.all_bank_accounts.filter(
          account =>
            account?.account_number !==
            data.main_bank_account?.[0]?.people_profile_bank?.account_number
        )
      ]);
    }
  }, [data]);

  if (error) {
    message.error(`Error : ${error}`);
  }

  return (
    <UserBankAccountComponent
      userId={userId}
      isUser={userId === profileId}
      refetch={refetch}
      data={dataBank || []}
    />
  );
}
