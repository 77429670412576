import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Progress, Avatar } from 'antd';
import {
  MemberListWrap,
  BlackSubTitle,
  CustomDivider,
  AverageRating,
  VeryJumboTitle,
  TextSmallGrey,
  FlexBetween,
  FilterRatingButton,
  BlackTitle,
  FlexEnd,
  VerticalDivider,
  ReviewListWrapper,
  FlexRow,
  FlexCol,
  BigStarWrapper,
  GreySubTitle,
  ButtonLink
} from './ClassroomFeedback.style';
import { AuthenticationService } from '../../Services';
import Assets from '../../Assets';
import ModalAddFeedback from './ModalAddFeedback';
import { DisplayLoading } from '../../Components';

const ClassroomFeedbackComponent = props => {
  const {
    rating,
    description,
    handleSubmitReview,
    reviewList,
    reviewListLoading,
    feedbackReview,
    feedbackReviewLoading,
    averageReview,
    averageReviewLoading,
    isAdmin
  } = props;
  const defaultAvatar = Assets.DEFAULT_PHOTO;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempRating, setTempRating] = useState(rating);
  const [tempDesc, setTempDesc] = useState(description);
  const [hoverRating, setHoverRating] = useState(0);
  const [filterList, setFilterList] = useState(null);
  const [listDataReview, setListDataReview] = useState([]);

  useEffect(() => {
    if (reviewList && !reviewListLoading) {
      setListDataReview(reviewList.classroom_reviews);
    }
  }, [reviewList, reviewListLoading]);

  const onMouseEnter = index => {
    setHoverRating(index);
  };

  const onMouseLeave = () => {
    setHoverRating(0);
  };

  const onSaveRating = index => {
    setTempRating(index);
  };

  const toggleModal = () => {
    const val = !isModalVisible;
    setIsModalVisible(val);
  };

  const getPercent = (val, total) => {
    return (val / total) * 100;
  };

  const submitFeedback = () => {
    const data = {
      star: tempRating,
      description: tempDesc
    };
    handleSubmitReview(data);
    setIsModalVisible(false);
  };

  const renderStar = (value, size) => {
    const starArray = [];
    let starStyle = {};
    if (size === 'jumbo') {
      starStyle = {
        width: '50px',
        height: '50px',
        margin: '0px 15px'
      };
    } else {
      starStyle = {
        width: '15px',
        height: '15px'
      };
    }
    const fullStars = parseInt(value);
    for (let i = 0; i < fullStars; i++) {
      starArray.push(Assets.ICON.STAR_FILLED);
    }

    const halfStars = value % 1 > 0 ? 1 : 0;
    if (halfStars) {
      starArray.push(Assets.ICON.STAR_HALF);
    }

    for (let i = 0; i < 5 - (fullStars + halfStars); i++) {
      starArray.push(Assets.ICON.STAR_FILLED_GREY);
    }

    return (
      <div
        className={
          'flex flex-1 flex-row content-start justify-start items-center mt-16'
        }
      >
        {starArray.map((star, index) => {
          return <img src={star} alt="avatar" style={starStyle} key={index} />;
        })}
      </div>
    );
  };

  const AddFeedback = () => {
    if (feedbackReview && !feedbackReviewLoading) {
      return (
        <>
          <BlackSubTitle>Umpan Balik Anda</BlackSubTitle>
          <BigStarWrapper>{renderStar(rating, 'jumbo')}</BigStarWrapper>
          <FlexCol>
            {description && (
              <>
                <GreySubTitle>Deskripsi</GreySubTitle>
                <Typography>{description}</Typography>
              </>
            )}
            <ButtonLink type="link" onClick={toggleModal}>
              {rating > 0 ? 'Ubah umpan balik' : 'Tambahkan umpan balik'}
            </ButtonLink>
          </FlexCol>
        </>
      );
    } else {
      return <DisplayLoading />;
    }
  };

  const ReviewFeedback = () => {
    if (averageReview && !averageReviewLoading) {
      const value = averageReview.classroomGetStars;
      const _percent = [
        getPercent(value.stars.five, value.count),
        getPercent(value.stars.four, value.count),
        getPercent(value.stars.three, value.count),
        getPercent(value.stars.two, value.count),
        getPercent(value.stars.one, value.count)
      ];
      return (
        <Row>
          <Col span={6}>
            <AverageRating>
              <VeryJumboTitle>
                {value.average ? value.average.toFixed(1) : 0}
              </VeryJumboTitle>
              <div>{renderStar(value.average ? value.average : 0)}</div>
              <TextSmallGrey>
                Berdasarkan {value.count ? value.count : 0} ulasan
              </TextSmallGrey>
            </AverageRating>
          </Col>
          <Col span={18}>
            <Row>
              <Col span={6}>
                <AverageRating>
                  {[5, 4, 3, 2, 1].map(val => {
                    return (
                      <div className="py3" key={val}>
                        {renderStar(val)}
                      </div>
                    );
                  })}
                </AverageRating>
              </Col>
              <Col span={18}>
                <AverageRating>
                  {_percent.map((val, index) => {
                    return (
                      <Progress
                        className="py3"
                        strokeLinecap="square"
                        percent={val}
                        key={index}
                      />
                    );
                  })}
                </AverageRating>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return <DisplayLoading />;
    }
  };

  const ReviewListComponent = val => {
    return (
      <>
        <ReviewListWrapper key={val.id}>
          <Col span={8}>
            <FlexRow className="px10">
              <Avatar
                src={
                  val.global_user.avatar
                    ? val.global_user.avatar
                    : defaultAvatar
                }
                className="avatar"
              />
              <FlexCol>
                <Typography>{renderStar(val.star ? val.star : 0)}</Typography>
                <Typography className="name">{val.global_user.name}</Typography>
              </FlexCol>
            </FlexRow>
          </Col>
          <Col span={16}>
            <FlexCol>
              {/* <TextGrey className="mb8">{val.date}</TextGrey> */}
              <Typography>{val.description}</Typography>
              {/* <ChildReview>
                <TextGrey className="mb8">
                  Daniel Schwarz (Instructor), 10 February 2019
                </TextGrey>
                <Typography>
                  Thanks David, appreciate the positive feedback and all the
                  best in your design career. Happy Days. DS
                </Typography>
              </ChildReview> */}
            </FlexCol>
          </Col>
        </ReviewListWrapper>
        <CustomDivider />
      </>
    );
  };

  const ListReview = () => {
    const iconStar = Assets.ICON.STAR_FILLED;
    const iconStyle = {
      width: '15px',
      height: '15px',
      marginRight: '3px',
      marginTop: '-2px'
    };
    let listData = [];

    if (!filterList && listDataReview) {
      listData = listDataReview;
    } else if (filterList && listDataReview) {
      listData = listDataReview.filter(val => val.star === filterList);
    }

    if (!reviewListLoading) {
      return (
        <div>
          <FlexBetween style={{ margin: '28px 0px' }}>
            <BlackTitle>Ulasan</BlackTitle>
            <FlexEnd>
              <FilterRatingButton onClick={() => setFilterList(null)}>
                Semua
              </FilterRatingButton>
              <VerticalDivider />
              {[1, 2, 3, 4, 5].map(val => {
                return (
                  <FilterRatingButton
                    onClick={() => setFilterList(val)}
                    key={val}
                  >
                    <img src={iconStar} style={iconStyle} alt="" />
                    {val}
                  </FilterRatingButton>
                );
              })}
            </FlexEnd>
          </FlexBetween>
          {listData && listData.length > 0 ? (
            listData.map(val => {
              return ReviewListComponent(val);
            })
          ) : (
            <AverageRating>
              <img
                src={Assets.ICON.SEARCH_NOT_FOUND}
                width="45px"
                height="45px"
                alt=""
              />
              <BlackSubTitle>Sorry, review data is empty</BlackSubTitle>
            </AverageRating>
          )}
        </div>
      );
    } else {
      return <DisplayLoading />;
    }
  };

  return (
    <MemberListWrap>
      {!isAdmin && (
        <>
          <AddFeedback />
          <CustomDivider />
        </>
      )}
      <ReviewFeedback />
      <ListReview />
      <ModalAddFeedback
        isModalVisible={isModalVisible}
        submitFeedback={submitFeedback}
        toggleModal={toggleModal}
        rating={tempRating}
        description={tempDesc}
        hoverRating={hoverRating}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onSaveRating={onSaveRating}
        setDescription={setTempDesc}
      />
    </MemberListWrap>
  );
};

export default ClassroomFeedbackComponent;
