import { gql } from 'apollo-boost';

export const ADD_POST = gql`
  mutation(
    $content: String!
    $classroomId: String!
    $files: [FileType]
    $location: LocationType
    $tags: [String]
  ) {
    classroomNewPost(
      classroomId: $classroomId
      content: $content
      location: $location
      tags: $tags
      files: $files
    ) {
      id
    }
  }
`;

export const ADD_EVENT = gql`
  mutation(
    $classroomId: String!
    $event: Int!
    $content: String!
    $files: [FileType]
    $location: LocationType
    $tags: [String]
  ) {
    classroomNewEvent(
      classroomId: $classroomId
      event: $event
      files: $files
      content: $content
      location: $location
      tags: $tags
    ) {
      id
    }
  }
`;

export const ADD_LEARNING_TRACKER = gql`
  mutation(
    $activity: String!
    $date: DateTime!
    $duration: Int!
    $mentees: [String]!
    $classroomId: String!
    $files: [FileType]
    $commitment: String
    $description: String
    $subject: String
    $location: LocationType
    $tags: [String]
    $peopleTags: [String]
    $competencyId: Int
  ) {
    classroomCreateLearningTracker(
      activity: $activity
      date: $date
      duration: $duration
      mentees: $mentees
      classroomId: $classroomId
      files: $files
      commitment: $commitment
      description: $description
      subject: $subject
      tags: $tags
      location: $location
      peopleTags: $peopleTags
      competency: $competencyId
    ) {
      id
    }
  }
`;

export const ADD_COMPETENCY = gql`
  mutation($title: String) {
    insert_learning_log_competencies_one(object: { title: $title }) {
      id
    }
  }
`;
