import React, { useRef, useState } from 'react';
import style, { CarouselWrapper } from './BNI.style';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const square = [
  {
    to: 'https://eform.bni.co.id/BNI_eForm/index.html',
    img: require('../../../Assets/images/banner/bni-1.png')
  },
  {
    to: 'https://eform.bni.co.id/BNI_eForm/index.html',
    img: require('../../../Assets/images/banner/bni-2.png')
  }
];

const rectangle = [
  {
    to: 'https://eform.bni.co.id/BNI_eForm/index.html',
    img: require('../../../Assets/images/banner/bni-1-horizontal.png')
  },
  {
    to: 'https://eform.bni.co.id/BNI_eForm/index.html',
    img: require('../../../Assets/images/banner/bni-2-horizontal.png')
  }
];

const BNIComponent = (props) => {
  const { variant } = props
  
  const slider = useRef();
  const [isShown, setIsShown] = useState(true);

  const handleNext = () => slider.current.next();

  const handlePrev = () => slider.current.prev();

  const content = variant === 'rectangle' ? rectangle : square

  return (
    <div
      style={style.container}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      {isShown && (
        <div style={style.arrowsWrapper}>
          <div style={style.arrow} onClick={handlePrev}>
            <NavigateBeforeIcon color="primary" />
          </div>
          <div style={style.arrow} onClick={handleNext}>
            <NavigateNextIcon color="primary" />
          </div>
        </div>
      )}

      <CarouselWrapper
        arrows
        autoplay
        effect="fade"
        ref={ref => {
          slider.current = ref;
        }}
      >
        {content.map((val, i) => {
          return (
            <img
              src={val.img}
              alt=""
              onClick={() => window.open(val.to)}
              key={i}
            />
          );
        })}
      </CarouselWrapper>
    </div>
  );
};

export default BNIComponent;
