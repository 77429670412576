import { Icon } from 'antd';
import React, { memo } from 'react';
import Assets from '../../../../Assets';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';
import { WrapperExperience } from './Experience.style';

export default memo(function ExperienceItem({
  onClickEdit,
  isUser,
  avatar,
  title,
  company,
  date,
  location,
  description,
  achievements,
  refName,
  refPosition,
  refPhone,
  company_line_of_business,
  dividerWidth = null,
  contentPadding = null,
  contentWidth = null,
  noDivider = false
}) {
  const [isMore, setIsMore] = React.useState(false);

  return (
    <WrapperExperience
      contentPadding={contentPadding}
      contentWidth={contentWidth}
      dividerWidth={dividerWidth}
    >
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="title">{title}</div>
          <div className="company">{company}</div>
          <div className="location">{location}</div>
          <div className="date">{date}</div>
          <div className="line-of-business">{company_line_of_business}</div>
          <p className="description">
            {description.slice(0, (!isMore && 370) || description.length) ||
              '-'}
          </p>
          {isMore && (
            <>
              <p className="description italic">{achievements || '-'}</p>
              <p className="reference">Reference</p>
              <p className="ref ref-name">{refName || '-'}</p>
              <p className="ref ref-position">{refPosition || '-'}</p>
              <p className="ref ref-phone">{refPhone || '-'}</p>
            </>
          )}
          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      {!noDivider && <div className="divider" />}
    </WrapperExperience>
  );
});
