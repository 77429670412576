import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

const GetInformationServices = (id) => {
  const query = gql`
  {
    company_profiles(where:{
      id:{_eq:"${id}"}
    }){
      id
      brand_name
      legal_name
      logo
      profile_field
      global_company_industry_type{
        id
        name
      }
    }
  }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.company_profiles[0];
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

export default { GetInformationServices };
