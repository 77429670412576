import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import LicencesItem from './LicencesItem';
import AddLicences from './AddEditLicences';
import MoreLicences from './MoreLicences';
import { ShowMoreText } from '../../../../Components/DetailUserSection/SeeMoreText';

export default function Licences({
  isUser,
  dataLicences,
  refetch,
  people_profile,
  profileId,
  nameUser
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });

  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: false,
      actionType: 'edit',
      data
    }));
  };

  const _data = [...(dataLicences || [])].slice(0, 2);

  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ open: true, data: null, actionType: 'add' })
        }
        title={`Pelatihan, Lisensi & Sertifikasi`}
        action={isUser ? 'plus' : null}
      >
        {_data?.map((res, i) => {
          return (
            <LicencesItem
              key={i}
              isUser={isUser}
              avatar={res.avatar}
              company={res.company}
              title={res.title}
              date={res.time}
              credential={res.location}
              url={res.url}
              data={res}
              onClickEdit={() => handleEditItem(res.dataEdit)}
              noDivider={_data.length === i + 1}
              contentPadding="25px 0px"
              contentWidth="650px"
              dividerWidth="720px"
            />
          );
        })}
        {dataLicences?.length > 2 && (
          <ShowMoreText
            label="Tampilkan Lebih Banyak Pelatihan, Lisensi & Sertifikasi..."
            onClick={() => {
              setOpen(e => ({
                ...e,
                openMore: true
              }));
            }}
          />
        )}
      </DetailUserSection>

      <MoreLicences
        open={open.openMore}
        setOpen={setOpen}
        isUser={isUser}
        nameUser={nameUser}
        data={dataLicences}
      />

      <AddLicences
        setOpen={setOpen}
        openState={open}
        profileId={profileId}
        people_profile={people_profile}
        refetch={refetch}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            openMore: open.fromList,
            open: false,
            data: null,
            actionType: null,
            fromList: false
          })
        }
      />
    </>
  );
}
