import styled from 'styled-components';

export const WrapperCKEditor = styled.div`
  .wrapper {
    padding: 0px 30px
  
    .ck-toolbar {
      display: none !important;
    }
  
    .ck-content {
      border: 1px solid #a9a8a8 !important;
      border-radius: 5px !important;
      min-height: 294px !important;
      position: relative;

      &:before {
        content: '${props => props.desc}';
        position: absolute;
        top: 12px;
        left: 8px;
      }
    }
  }
`
export const ActionWrapperDesc = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  .action {
    padding: 0px 30px;
    display: flex;
    flex-direction: row;
    margin-left: auto;

  }
`