import React from 'react';
import { Button, Col, Row } from 'antd';

import style from './BottomPostBox.style';
import IMAGES from '../../Assets/images';
import Constants from '../../Config/Constants';
import { PrivacyPostBox } from '../';
import _ from 'lodash';

const {
  MESSAGES: {
    POST_BOX: { SUBMIT_BUTTON }
  },
  UTILITIES: { IMAGE, TAGS, FILES, LOCATION }
} = Constants;

const BottomPostBoxComponent = props => {
  const {
    post,
    handleUtility,
    handleSubmit,
    handlePost,
    setIsShowModal,
    postType,
    setSelectPrivacy,
    isUploading = false,
    sharedPost,
    disabeledSomeEl = false
  } = props;

  const isPostEmpty = () => {
    if (isUploading) return true;
    if (post.type === '1' && post?.event === undefined) return true;
    if (post.type === '1' && post?.event !== undefined && post.content === '')
      return false;
    else if (post.type === '2' && _.isEmpty(post?.task)) return true;
    else if (post.type === '2' && !_.isEmpty(post?.task) && post.content === '')
      //
      return false;
    //
    else if (
      //
      String(post.type) === '3' && //
      _.isNull(post.subject) && //
      _.isNull(post.activity) && //
      _.isNull(post.competencyId) && //
      _.isNull(post.date) && //
      _.isNull(post.duration) && //
      _.isNull(post.description) && //
      post.mentees?.length === 0 //
    )
      //
      return true;
    //
    else if (
      //
      String(post.type) === '3' && //
      !_.isNull(post.subject) && //
      !_.isNull(post.activity) && //
      !_.isNull(post.competencyId) && //
      !_.isNull(post.date) && //
      !_.isNull(post.duration) && //
      !_.isNull(post.description) && //
      post.mentees?.length > 0 //
    )
      //
      return false;
    //
    else if (
      String(post.type) === '4' &&
      post.poll.duration === 0 &&
      post.poll.question === '' &&
      post.poll.options.find(val => val === '') !== undefined
    )
      return true;
    //
    else if (
      String(post.type) === '4' &&
      post.content === '' &&
      post.poll.duration !== 0 &&
      post.poll.question !== '' &&
      post.poll.options.find(val => val === '') === undefined
    )
      return false;
    //
    else if (!_.isEmpty(sharedPost?.kudos)) return false;
    //
    else if (
      post.content === '' &&
      post.images?.length === 0 &&
      post.videos?.length === 0 &&
      post.files?.length === 0 &&
      post.tags?.length === 0 &&
      _.isEmpty(post.location) &&
      _.isEmpty(post.sharedPost)
    )
      return true;
    else return false;
  };

  const handleCheckSubmit = () => {
    if (!isPostEmpty()) return handleSubmit(post);
  };

  const _renderIcon = (styleIcon, icon, utility, disabeledSomeEl = false) => (
    <img
      style={
        disabeledSomeEl ? { ...styleIcon, cursor: 'not-allowed' } : styleIcon
      }
      src={icon}
      onClick={disabeledSomeEl ? null : () => handleUtility(utility)}
      alt="icon"
    />
  );

  const leftColumn = () => (
    <Col span={12}>
      {_renderIcon(style.iconImage, IMAGES.IMAGE_ICON, IMAGE, disabeledSomeEl)}
      {/* {_renderIcon(style.iconVideo, IMAGES.VIDEO_ICON, VIDEO)} */}
      {_renderIcon(style.iconPerson, IMAGES.PERSON_ICON, TAGS)}
      {_renderIcon(
        style.iconAttachment,
        IMAGES.ATTACHMENT_ICON,
        FILES,
        disabeledSomeEl
      )}
      {_renderIcon(style.iconLocation, IMAGES.LOCATION_ICON, LOCATION)}
      {/* {_renderIcon(style.iconHashtag, IMAGES.HASHTAG_ICON, HASHTAG)} */}
    </Col>
  );

  const rightColumn = () => (
    <Col span={12} style={style.rightColumn}>
      {(postType === Constants.POST_TYPE.GLOBAL_TIMELINE ||
        postType === Constants.POST_TYPE.PROFILE ||
        postType === Constants.POST_TYPE.COMPANY) && (
        <PrivacyPostBox
          canSee={post.canSee}
          handlePost={handlePost}
          setIsShowModal={setIsShowModal}
          setSelectPrivacy={setSelectPrivacy}
          postType={postType}
          privacy={post.privacy}
        />
      )}
      <Button
        style={isPostEmpty() ? style.postDisableButton : style.postButton}
        onClick={() => handleCheckSubmit()}
      >
        {SUBMIT_BUTTON}
      </Button>
    </Col>
  );

  return (
    <div style={style.container}>
      <hr style={style.hr} />
      <Row>
        {leftColumn()}
        {rightColumn()}
      </Row>
    </div>
  );
};

export default BottomPostBoxComponent;
