import React from 'react';
import { VendorPrintInvoice } from '../../Containers';
import { CenterLayout } from '../../Components';

const VendorPrintInvoicePage = props => {
  return (
    <CenterLayout>
      <VendorPrintInvoice />
    </CenterLayout>
  );
};

export default VendorPrintInvoicePage;
