import { Icon } from 'antd';
import React, { memo } from 'react';
import { WrapperItemIdentity, ItemWrapper } from './UserIndentity.style';

const ItemField = ({ field, value }) => {
  return (
    <ItemWrapper>
      <p className="key">{field}</p>
      <p className="value">{value}</p>
    </ItemWrapper>
  );
};

export default memo(function IdentityItem({
  type,
  id,
  identity_fields,
  onEdit,
  isUser
}) {
  return (
    <WrapperItemIdentity>
      <div className="header">
        <h4>{type ?? '-'}</h4>
        {isUser && <Icon type="edit" onClick={onEdit} className="icon" />}
      </div>
      <div className="content">
        <ItemField field="Nomor Identitas" value={id ?? '-'} />
        <ItemField field="Alamat" value={identity_fields?.address ?? '-'} />
        <ItemField
          field="Kode Pos"
          value={identity_fields?.postalCode ?? '-'}
        />
        <ItemField
          field="Masa Berlaku"
          value={identity_fields?.expiredDate ?? '-'}
        />
      </div>
    </WrapperItemIdentity>
  );
});
