import gql from "graphql-tag";

export const GET_GLOBAL_PHONE_CODE = gql`
  query {
    global_phone_code(order_by: [{ country: asc }]) {
      country
      code
    }
  }
`;

export const GET_LIST_RELATIONSHIP = gql`
  query {
    people_dependant_relationships {
      id
      name
    }
  }
`;

export const UPDATE_EMERGENCY_CONTACT = gql`
  mutation(
    $id: Int!
    $user: uuid!
    $name: String
    $relationship: String
    $countryCode: String
    $phone: String
    $emergencyContactFields: jsonb
    $address: String
  ) {
    update_people_emergency_contacts(
      _set: {
        name: $name
        relationship: $relationship
        country_code: $countryCode
        phone: $phone
        address: $address
      }
      _append: { emergency_contact_fields: $emergencyContactFields }
      where: { _and: [{ id: { _eq: $id } }, { user: { _eq: $user } }] }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_EMERGENCY_CONTACT = gql`
  mutation($id: Int!) {
    update_people_emergency_contacts(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const ADD_EMERGENCY_CONTACT = gql`
  mutation($objects: [people_emergency_contacts_insert_input!]!) {
    insert_people_emergency_contacts(objects: $objects) {
      affected_rows
    }
  }
`;
export const GET_LIST_CITY = gql`
  query($id: Int) {
    global_cities(
      where: { province: { _eq: $id } }
      order_by: { name: asc }
      limit: 50
    ) {
      id
      code
      name
    }
  }
`;
export const GET_LIST_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;
export const GET_LIST_LOCATION = gql`
  query($city: Int, $province: Int) {
    city: global_cities(
      where: { id: { _eq: $city } }
      order_by: { name: asc }
      limit: 1
    ) {
      id
      name
    }

    province: global_provinces(
      where: { id: { _eq: $province } }
      order_by: { name: asc }
      limit: 1
    ) {
      id
      name
    }
  }
`;
