import React, { useState, useEffect } from 'react';
import { Form, Input, Select, message } from 'antd';
import { ModalEdit } from '../../Components';
import { TitleField } from '../UserProfileDependent/UserDependent.style';
import { useLazyQuery, useMutation } from 'react-apollo';
import { UPDATE_ABOUT, LIST_RACE, LIST_DIALECT } from './About.graphql';
import { listMaritalStatus, listGender, month } from './HelperAbout';
import { ButtonFrom, FormDateInline, TagForm } from '../../SharedComponents';

const initialState = {
  nationality: [],
  salutation: [],
  race: [],
  dialect: []
};

const removeSelected = (_myArray, _toRemove) => {
  if (_myArray) {
    const myArray = [..._myArray];
    for (var i = myArray.length - 1; i >= 0; i--) {
      for (var j = 0; j < _toRemove.length; j++) {
        if (myArray[i] && myArray[i].id === _toRemove[j].id) {
          myArray.splice(i, 1);
        }
      }
    }
    return myArray;
  } else return [];
};

const optionNationaly = [
  {
    id: 'WNA',
    name: 'WNA'
  },
  {
    id: 'WNI',
    name: 'WNI'
  }
];
const optionSalutation = [
  {
    id: 'Tuan',
    name: 'Tuan'
  },
  {
    id: 'Nyonya',
    name: 'Nyonya'
  },
  {
    id: 'Nona',
    name: 'Nona'
  }
];
const EditAbout = ({
  open,
  onClose,
  form,
  data,
  // dataProvince,
  // dataCity,
  // setProvince,
  dataReligion,
  profileId,
  refetch,
  isIntro = false
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields
    // getFieldValue,
  } = form;
  const { Option } = Select;
  // const [dataLocation, setDataLocation] = useState({
  //   province: [],
  //   city: [],
  // });
  // const [sendLocation, setSendLocation] = useState({
  //   province: "",
  //   city: "",
  // });
  const [list, setList] = useState(initialState);
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [updateAbout] = useMutation(UPDATE_ABOUT, {
    context: CONTEXT_USER
  });

  const [getListRace, { data: dataRace, loading: loadingRace }] = useLazyQuery(
    LIST_RACE
  );
  const [
    getListDialect,
    { data: dataDialect, loading: loadingDialect }
  ] = useLazyQuery(LIST_DIALECT);

  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };
  const generateDateMarital = date => {
    if (date) {
      const newDate = date.split('T');
      return newDate[0].split('-');
    }

    return null;
  };

  useEffect(() => {
    if (open) {
      getListRace();
      getListDialect();
    }
    if (data) {
      const { dialect, nationality, salutation, race } =
        data?.profile_fields ?? {};
      // setSendLocation({
      //   province: data.province,
      //   city: data.city,
      // })
      setList({
        nationality: nationality?.map(res => ({ id: res, name: res })) ?? [],
        salutation: salutation?.map(res => ({ id: res, name: res })) ?? [],
        race: race ?? [],
        dialect: dialect ?? []
      });
    }
  }, [data, getListRace, getListDialect, open]);

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      const generateDate = (day, month, year, isMarital = false) => {
        // request date format from android
        if (isMarital) {
          return `${day}-${month}-${year}T00:00:00.000Z`;
        } else {
          if (day.length === 1) {
            return `${year}-${month}-0${day}`;
          } else {
            return `${year}-${month}-${day}`;
          }
        }
      };
      if (!error) {
        const { marital_day, marital_month, marital_year } = values;
        const submit = {
          id: profileId,
          name: values.name,
          gender: values.gender,
          birthDate: generateDate(values.day, values.month, values.year),
          religion: values.religion,
          maritalStatus: values.marital_status,
          data: {
            place_of_birth: values.place_of_birth,
            nationality: values.nationality,
            salutation: values.salutation,
            religion_sect: values.religion_sect,
            race: list.race,
            dialect: list.dialect,
            marital_place: values.marital_place,
            marital_date:
              !marital_year || !marital_month || !marital_day
                ? null
                : generateDate(marital_year, marital_month, marital_day, true)
          }
        };
        updateAbout({
          variables: submit
        })
          .then(() => {
            refetch();
            message.success(`Berhasil mengubah tentang`);
            onClose();
          })
          .catch(err => {
            message.error(`Gagal mengubah tentang, ${err}`);
          });
      }
    });
  };

  const handleChange = (_, e, target, data) => {
    // if (e === "province") {
    //   setSendLocation({
    //     ...sendLocation,
    //     province: target.props.children,
    //   });
    //   setFieldsValue({
    //     city: null,
    //   });
    //   setProvince(formValue);
    // } else if (e === "city") {
    //   setSendLocation({
    //     ...sendLocation,
    //     city: target.props.children,
    //   });
    // } else
    if (e === 'tag') {
      const result = [...list[target]];
      data.forEach(res => {
        result.push({
          id: res?.props?.data?.id,
          name: res?.props?.data?.name
        });
      });
      const _result = result.filter(el => el.id !== undefined);
      setList({
        ...list,
        [target]: _result
      });
      setFieldsValue({
        [target]: _result.map(res => res.id)
      });
      if (target === 'race') {
        getListRace();
      }
      if (target === 'dialect') {
        getListDialect();
      }
    }
  };

  const listFields = [
    {
      labelName: 'Nama Lengkap',
      fieldName: 'name',
      type: 'text',
      fieldValue: data?.name ?? '',
      required: true,
      disable: false,
      placeholder: 'Tambah Nama Lengkap'
    },
    {
      labelName: 'Jenis Kelamin',
      fieldName: 'gender',
      type: 'select',
      fieldValue: data?.gender === '-' ? undefined : data?.gender,
      option: listGender,
      required: true,
      placeholder: 'Pilih Jenis Kelamin'
    },
    {
      labelName: 'Tempat Lahir',
      fieldName: 'place_of_birth',
      type: 'text',
      fieldValue: data?.profile_fields?.place_of_birth,
      required: true,
      style: { width: '55%' },
      placeholder: 'Tambah Tempat Lahir'
    }
  ];
  const listFields2 = [
    {
      labelName: 'Agama',
      fieldName: 'religion',
      type: 'select',
      fieldValue: data?.religion === '-' ? undefined : data?.religion,
      option: dataReligion,
      required: true,
      placeholder: 'Tambah Agama'
    },
    {
      labelName: 'Sekte Agama',
      fieldName: 'religion_sect',
      type: 'text',
      fieldValue: data?.profile_fields?.religion_sect,
      required: false,
      placeholder: 'Tambah Sekte Agama'
    }
  ];
  const listFields3 = [
    {
      labelName: 'Status Pernikahan',
      fieldName: 'marital_status',
      type: 'select',
      fieldValue:
        data?.marital_status === '-' ? undefined : data?.marital_status,
      option: listMaritalStatus,
      required: true,
      placeholder: 'Pilih Status Pernikahan'
    },
    {
      labelName: 'Tempat Pernikahan',
      fieldName: 'marital_place',
      type: 'text',
      fieldValue: data?.profile_fields?.marital_place,
      required: false,
      placeholder: 'Tambah Tempat Pernikahan'
    }
  ];

  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }

  const FieldBirthdate = [
    {
      fieldName: 'day',
      fieldValue:
        generateDate(data && data.birthdate)?.[2] === ''
          ? undefined
          : generateDate(data && data.birthdate)?.[2],
      isRequired: true,
      placeholder: 'Tanggal',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue:
        generateDate(data && data.birthdate)?.[1] === ''
          ? undefined
          : generateDate(data && data.birthdate)?.[1],
      isRequired: true,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue:
        generateDate(data && data.birthdate)?.[0] === ''
          ? undefined
          : generateDate(data && data.birthdate)?.[0],
      isRequired: true,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];

  const maritalDate = generateDateMarital(data?.profile_fields?.marital_date);
  const maritalDay =
    maritalDate?.[2]?.length > 2
      ? maritalDate?.[2]?.split(' ')?.[0]
      : maritalDate?.[2];

  const FieldMaritalDate = [
    {
      fieldName: 'marital_day',
      fieldValue: maritalDay,
      isRequired: false,
      placeholder: 'Tanggal',
      optionList: optionDay,
      allowClear: true,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'marital_month',
      fieldValue: maritalDate?.[1],
      isRequired: false,
      placeholder: 'Bulan',
      optionList: month,
      allowClear: true,
      option: month => {
        return month.map(res => {
          return {
            value: res.valueMini,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'marital_year',
      fieldValue: maritalDate?.[0],
      isRequired: false,
      placeholder: 'Tahun',
      optionList: optionYear,
      allowClear: true,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];

  const _switchComponentToRender = res => {
    const { type, fieldName, option, disable, style, placeholder } = res;

    if (type === 'select') {
      return (
        <Select
          size="large"
          onChange={value => handleChange(value, fieldName)}
          style={{ textTransform: 'capitalize' }}
          placeholder={placeholder}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option
                  style={{ textTransform: 'capitalize' }}
                  key={i}
                  value={res.id}
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <Input
          style={style}
          disabled={disable}
          placeholder={placeholder}
          type={type}
          onChange={value => handleChange(value, fieldName)}
        />
      );
    }
  };

  const handleClose = () => {
    resetFields();
    onClose();
  };

  const handleSearch = (e, name) => {
    if (name === 'race') {
      getListRace({
        variables: {
          search: `%${e}%`
        }
      });
    } else if (name === 'dialect') {
      getListDialect({
        variables: {
          search: `%${e}%`
        }
      });
    }
  };

  const handleDeleteTag = (res, fieldName) => {
    let result = [...list[fieldName]];
    const i = result.indexOf(res);
    if (i >= 0) {
      result.splice(i, 1);
    }
    setList(e => ({ ...e, [fieldName]: result }));
    const _result = result.map(result => result.id);
    setFieldsValue({
      [fieldName]: _result
    });
  };

  const handleKeyDown = (e, target) => {
    if (e.keyCode === 8) {
      setFieldsValue({
        [target]: list[target].map(result => result.id)
      });
    }
  };

  return (
    <ModalEdit
      sizeSmall
      title={`Ubah ${isIntro ? 'Intro' : 'Tentang'}`}
      open={open}
      onClose={handleClose}
    >
      <Form onSubmit={handleSubmit}>
        {listFields.map((res, i) => {
          return (
            <Form.Item
              key={i}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: [
                  {
                    required: res.required,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <FormDateInline
          title="Tanggal Lahir"
          required={true}
          date={FieldBirthdate}
          getFieldDecorator={getFieldDecorator}
        />
        <TagForm
          labelName="Kebangsaan"
          fieldName="nationality"
          fieldValue={data?.profile_fields?.nationality ?? []}
          isRequired={true}
          placeholder="Tambah Kebangsaan"
          onChange={(value, data) =>
            handleChange(value, 'tag', 'nationality', data)
          }
          list={list.nationality}
          onDelete={res => handleDeleteTag(res, 'nationality')}
          getFieldDecorator={getFieldDecorator}
          mode="multiple"
          // option={optionNationaly}
          // value={getFieldValue("nationality") ?? []}
          onSearch={handleSearch}
          onInputKeyDown={e => handleKeyDown(e, 'nationality')}
        >
          {removeSelected(optionNationaly, list.nationality).map((res, i) => (
            <Option data={res} key={res.id} value={res.id}>
              {res.name}
            </Option>
          ))}
        </TagForm>
        <TagForm
          labelName="Sapaan"
          fieldName="salutation"
          fieldValue={data?.profile_fields?.salutation ?? []}
          isRequired={true}
          placeholder="Pilih Sapaan"
          onChange={(value, data) =>
            handleChange(value, 'tag', 'salutation', data)
          }
          list={list.salutation}
          onDelete={res => handleDeleteTag(res, 'salutation')}
          getFieldDecorator={getFieldDecorator}
          mode="multiple"
          // option={optionSalutation}
          // value={getFieldValue("salutation") ?? []}
          onSearch={e => handleSearch(e, 'salutation')}
          onInputKeyDown={e => handleKeyDown(e, 'salutation')}
        >
          {removeSelected(optionSalutation, list.salutation).map((res, i) => (
            <Option data={res} key={`${i}-itm`} value={res.id}>
              {res.name}
            </Option>
          ))}
        </TagForm>
        {listFields2.map((res, i) => {
          return (
            <Form.Item
              key={i}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: [
                  {
                    required: res.required,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <TagForm
          labelName="Suku"
          fieldName="race"
          fieldValue={data?.profile_fields?.race?.map(res => res.id) ?? []}
          isRequired={true}
          placeholder="Pilih Suku"
          onChange={(value, data) => handleChange(value, 'tag', 'race', data)}
          list={list.race}
          onDelete={res => handleDeleteTag(res, 'race')}
          getFieldDecorator={getFieldDecorator}
          mode="multiple"
          // option={dataRace?.global_race ?? []}
          // value={getFieldValue("race") ?? []}
          onSearch={e => handleSearch(e, 'race')}
          loading={loadingRace}
          onInputKeyDown={e => handleKeyDown(e, 'race')}
        >
          {dataRace &&
            removeSelected(dataRace?.global_race, list.race).map((res, i) => (
              <Option data={res} key={`${i}-itm`} value={res.id}>
                {res.name}
              </Option>
            ))}
        </TagForm>
        <TagForm
          labelName="Dialek"
          fieldName="dialect"
          fieldValue={data?.profile_fields?.dialect?.map(res => res.id) ?? []}
          isRequired={false}
          placeholder="Pilih Dialek"
          onChange={(value, data) =>
            handleChange(value, 'tag', 'dialect', data)
          }
          list={list.dialect}
          onDelete={res => handleDeleteTag(res, 'dialect')}
          getFieldDecorator={getFieldDecorator}
          mode="multiple"
          // option={dataDialect?.global_dialects ?? []}
          // value={getFieldValue("dialect") ?? []}
          onSearch={e => handleSearch(e, 'dialect')}
          loading={loadingDialect}
          onInputKeyDown={e => handleKeyDown(e, 'dialect')}
        >
          {removeSelected(dataDialect?.global_dialects, list.dialect).map(
            (res, i) => (
              <Option data={res} key={`${i}-itm`} value={res.id}>
                {res.name}
              </Option>
            )
          )}
        </TagForm>
        {listFields3.map((res, i) => {
          return (
            <Form.Item
              key={i}
              style={{ padding: '0px 22px', marginBottom: 0 }}
              label={<TitleField>{res.labelName}</TitleField>}
            >
              {getFieldDecorator(res.fieldName, {
                initialValue: res.fieldValue,
                rules: [
                  {
                    required: res.required,
                    message: 'Bagian ini diperlukan'
                  }
                ]
              })(_switchComponentToRender(res))}
            </Form.Item>
          );
        })}
        <FormDateInline
          title="Tanggal Pernikahan"
          required={false}
          date={FieldMaritalDate}
          getFieldDecorator={getFieldDecorator}
        />
        <ButtonFrom
          submitLabel="Simpan"
          actionType="edit"
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const EditAboutContainer = Form.create({ name: 'Edit_About' })(EditAbout);

export default EditAboutContainer;
