import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import TagPeopleComponent from './TagPeople.component';
import { message } from 'antd';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import { useParams } from 'react-router-dom';
import { GET_CLASSROOM_MEMBER } from './classroomMember.graphql';

function TagPeopleClassroom(props) {
  const { handlePost } = props;
  const params = useParams();
  const classroomId = params.id;
  const [peopleList, setPeopleList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [blockedMe, blockLoaded] = useBlockMe();

  const [
    getClassroomMember,
    { data: dataMember, loading, errors }
  ] = useLazyQuery(GET_CLASSROOM_MEMBER, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (keyword.length > 0) {
      getClassroomMember({
        variables: {
          classroom_id: {
            _eq: classroomId
          },
          keyword: `%${keyword}%`,
          limit: 10,
          offset: 0
        }
      });
    }
    // eslint-disable-next-line
  }, [keyword]);

  useEffect(() => {
    if (dataMember && !loading && blockLoaded) {
      const newDataMembers = [];

      dataMember.classroom_members.forEach(item => {
        let newObj = {};

        newObj.id = item.global_user.id;
        newObj.name = item.global_user.name;
        newObj.avatar = item.global_user.avatar;

        const newWorkPlacements = [];
        item.global_user.people_work_placements.forEach(work => {
          let newObjWorkPlacements = {};

          if (work.company_job_profile) {
            newObjWorkPlacements.company_employee_position = {
              name: work.company_job_profile.title
            };
            newObjWorkPlacements.company_profile = work.company_profile;
          } else {
            newObjWorkPlacements = work;
          }
          newWorkPlacements.push(newObjWorkPlacements);
        });
        newObj.people_work_placements = newWorkPlacements;

        newDataMembers.push(newObj);
      });

      const filteredMember = newDataMembers.filter(
        item => !blockedMe?.includes(item.id)
      );

      setPeopleList(filteredMember);
    }
    if (errors && errors.length > 0) {
      message.error('Something went wrong');
    }
    // eslint-disable-next-line
  }, [dataMember, errors, blockLoaded]);

  return (
    <TagPeopleComponent
      users={peopleList}
      setKeyword={setKeyword}
      handlePost={handlePost}
      isFetching={loading}
    />
  );
}

export default TagPeopleClassroom;
