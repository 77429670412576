import styled from 'styled-components';
import Assets from '../../Assets';
import { Input } from 'antd';

export const Wrap = styled.div`
  position: relative;
  padding: 32px;
  display: flex;
  font-family: ${Assets.FONT.MULI};
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};

  .information {
    width: 50%;
    span {
      display: block;
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.grey};
    }
    button {
      span {
        color: ${Assets.COLOR_PALLETE.white};
      }
    }
    .name {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 18px;
      color: ${Assets.COLOR_PALLETE.black};
    }
    .desc {
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.black2};
    }
    .number {
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      font-size: 15px;
      color: ${Assets.COLOR_PALLETE.black};
      display: inline-block;
    }
    .count {
      display: flex;
      margin-top: 8px;
      .total {
        width: 100%;
      }
    }
    td {
      line-height: 32px;
    }
    td:nth-child(1) {
      color: ${Assets.COLOR_PALLETE.black};
      padding-right: 40px;
    }
  }
`;

export const ButtonEdit = styled.div`
  position: absolute;
  right: 32px;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`;

export const WrapperItemIdentity = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  // only tablet and ups
  @media (min-width: 768px) {
    width: 46%;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
`;

export const ItemWrapper = styled.div`
  margin-bottom: 19px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  p {
    margin: 0px;
  }
  .key {
    width: 40%;
    color: #a9a8a8;
    margin-right: 10px;
  }
  .value {
    width: 60%;
    color: #000;
  }
`;

export const WrapperIdentity = styled.div`
  padding: 24px 32px 12px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
  }
`;
export const WrapperAddIdentity = styled.div`
  display: flex;
  padding: 0px 30px 24px 30px;
  flex-direction: row;
  align-items: center;
  .icon {
    margin-right: 8px;
    color: #039be5;
  }
  .text {
    cursor: pointer;
    font-size: 14px;
    color: #039be5;
    margin: 0px;
  }
`;

export default {
  iconEdit: {
    fontSize: 18,
    color: `${Assets.COLOR_PALLETE.grey}`
  }
};

export const TitleField = styled.span`
  font-size: 12px;
  color: ${Assets.COLOR_PALLETE.grey};
  // margin-bottom: 10px;
`;

export const WrapperField = styled.div`
  margin-bottom: 18px;
`;

export const InputStyled = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
