import React, { useEffect } from 'react';
import {
  ButtonWlb,
  GroupCard,
  TopSettingGroup,
  DummySpace,
  // DisplayLoading,
  SearchNotFound,
  HCEcosystem
} from '../../../Components';
import { Col, Form, Input, Row } from 'antd';
import { SearchWrap, Wrap } from './MyGroupScreen.style';
import { delaySearch } from '../../../Utils/CommonUtils';
import { SidebarProfile } from '../../../Containers';

const MyGroupScreenComponent = props => {
  const { dataGroup, fetchSearch, defaultSearch, isLoading } = props;

  useEffect(() => {
    document.title = 'SmartASN - Kelompok';
  }, []);
  // /const { Option } = Select;
  const { Search } = Input;
  let timeout = 0;

  const _buttonCreateGroup = [
    <ButtonWlb
      key="createGroup"
      type="primary"
      onClick={() => props.history.push('/createGroup')}
    >
      Buat Tim Kerja
    </ButtonWlb>
  ];

  const MappingDataGroup = () =>
    dataGroup.map((data, index) => {
      return (
        <Col key={index} span={8}>
          <GroupCard data={data} />
        </Col>
      );
    });

  const _Top = () => (
    <TopSettingGroup title="Tim Kerja" buttonTop={_buttonCreateGroup} />
  );

  const quickSearch = e => {
    const keyword = e.target.value.trim();
    timeout = delaySearch(timeout, () => fetchSearch(keyword));
  };

  const _messageInfo = () => {
    if (defaultSearch !== undefined) {
      return {
        title: 'We could not find anything for you',
        desc:
          'Nothing came up for that search. Try shortening or rephrasing your search.'
      };
    } else {
      return {
        title: "You're not joined any group",
        desc: 'Please create new group or join any group.'
      };
    }
  };

  const _Form = () => (
    <SearchWrap>
      <Col span={12} offset={12}>
        <Search
          placeholder="Cari Tim Kerja"
          onChange={quickSearch}
          defaultValue={defaultSearch}
          loading={isLoading}
        />
      </Col>
    </SearchWrap>
  );
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarProfile history={props.history} />
          <HCEcosystem />
        </Col>
        <Col span={18}>
          <Wrap>
            {_Top()}
            {_Form()}
          </Wrap>

          {dataGroup.length === 0 && !isLoading ? (
            <div style={{ marginTop: '30px' }}>
              <SearchNotFound
                title={_messageInfo().title}
                desc={_messageInfo().desc}
              />
            </div>
          ) : (
            <MappingDataGroup />
          )}
          <DummySpace />
          {/* <> {isLoading && <DisplayLoading />}</> */}
        </Col>
      </Row>
      <DummySpace />
    </div>
  );
};

export default Form.create({ name: 'MyGroupForm' })(MyGroupScreenComponent);
