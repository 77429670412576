import React from 'react';
import { ButtonWlb } from '..';
import { BalanceTrackWrap } from './BalanceTrack.style';
import currencyFormat from '../../Utils/currencyFormat';
import moment from 'moment';

const BalanceTrackComponent = props => {
  const { handleDetail, dataBalance, type = 'spend' } = props;

  return (
    <BalanceTrackWrap type={type ? type : 'spend'}>
      <div className="item">
        <p className="trans-id">{`Transaction ID : #${dataBalance.id}`}</p>
        <p className="trans-time">
          {moment(dataBalance.date_created).format('DD/MM/YYYY HH:mm')}
        </p>
        <ButtonWlb key="1" size="small" type="link" onClick={handleDetail}>
          Detail
        </ButtonWlb>
      </div>
      <div className="item">
        <p className="total">
          {type === 'spend' ? `-` : null}{' '}
          {`${currencyFormat(dataBalance.value)}`}
        </p>
      </div>
    </BalanceTrackWrap>
  );
};

export default BalanceTrackComponent;
