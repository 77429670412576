import styled from 'styled-components';
import Assets from '../../Assets';

export const SearchNotFound = styled.div`
  position: relative;
  width: 90%;
  margin: auto;
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 3px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 30px;
  height: 120px;
  margin-top: 30px;
  div {
    margin-right: 20px;
    float: left;
    img {
      width: 61px;
      height: 61px;
      border-radius: 100%;
    }
  }
  p {
    margin: 0;
    padding: 0;
    line-height: normal;
    &:nth-child(2) {
      font-family: ${Assets.FONT.MULI_BOLD};
      font-size: 14px;
      color: ${Assets.COLOR_PALLETE.black2};
      margin-top: 16px;
    }
    &:nth-child(3) {
      font-family: ${Assets.FONT.MULI};
      font-size: 12px;
      color: ${Assets.COLOR_PALLETE.grey};
      margin-top: 4px;
    }
  }
`;
