import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import { ActionButtons } from '../TableItemAction/TableAction.style';
// import Button from 'antd/es/button';
import { ButtonWlb, ModalConfirmation } from '../..';
import { capitalize } from '../../../Utils/CommonUtils';

const PopOverRoleMemberActionComponent = props => {
  const {
    handleSetAsRole,
    handleKickMember,
    handleLeaveMember,
    roleLabel,
    isMyCard,
    isShowKickMember = true,
    type,
    item,
    isSocialLearning
  } = props;
  const [display, setDisplay] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [actionModal, setActionModal] = useState('');

  const handleDisplay = () => {
    setDisplay(!display);
  };

  const currentPage = !isSocialLearning ? 'grup' : 'pembelajaran sosial';

  const confirmModal = () => {
    return (
      <ModalConfirmation
        open={displayModal}
        title={`Keluar ${capitalize(currentPage)}?`}
        content={`Apakah Anda yakin ingin keluar ${currentPage} ini?`}
        textPrimary="Konfirmasi"
        onCancel={() => setDisplayModal(false)}
        onSubmit={() =>
          actionModal === 'Leave Group'
            ? (handleLeaveMember(type), setDisplayModal(false))
            : (handleKickMember(type, item), setDisplayModal(false))
        }
      />
    );
  };
  const handleAction = action => {
    setDisplay(false);
    setActionModal(action);
    setDisplayModal(true);
  };
  const _popOver = () => (
    <Popover
      placement="bottom"
      trigger="click"
      visible={display}
      onVisibleChange={() => handleDisplay()}
      content={
        <ActionButtons>
          {isMyCard && (
            <Button onClick={() => handleAction('Leave Group')}>
              Meninggalkan
            </Button>
          )}
          {!isMyCard && (
            <Button onClick={() => handleSetAsRole()}>{roleLabel}</Button>
          )}
          {!isMyCard && isShowKickMember ? (
            <Button onClick={() => handleAction('hapus anggota')}>Hapus</Button>
          ) : // <Button onClick={() => handleKickMember()}>Kick</Button>
          null}
        </ActionButtons>
      }
    >
      <ButtonWlb type="secondary" onClick={() => handleDisplay()} size="small">
        <span className="icon more"></span>
      </ButtonWlb>
    </Popover>
  );
  return (
    <div>
      {_popOver()}
      {confirmModal()}
    </div>
  );
};

export default PopOverRoleMemberActionComponent;
