import React, { useState, useEffect } from 'react';
import SettingGroupBoxComponent from './SettingGroupBox.component';
import { AuthenticationService, UploadService, Group } from '../../Services';
import { message, Form } from 'antd';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';

const SettingGroupBoxContainer = props => {
  const { title } = props;
  const params = useParams();
  const groupId = params.id;
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayUploadImage, setDisplayUploadImage] = useState(false);
  const { getFieldDecorator } = props.form;
  const [dataGroup, setDataGroup] = useState({
    avatar: '',
    name: '',
    description: '',
    privacy: null,
    rules: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetch, setIsRefetch] = useState(true);
  const initial = { images: [] };
  const [post, setPost] = useState(initial);

  const _deleteGroupHandler = () => {
    const userId = AuthenticationService.getUserId();
    Group.Discussion.fetchGroupInfo(groupId).then(async response => {
      if (response.admin.includes(userId)) {
        Group.GroupGlobal.deleteGroup(groupId);
        props.history.push('/group');
        message.success('Successfully delete group');
      }
    });
    setDisplayDeleteModal(false);
  };

  const _handleAttributeChange = (attributeName, value) => {
    dataGroup[attributeName] = value;
    let clonedGroup = cloneDeep(dataGroup);
    setDataGroup(clonedGroup);
  };

  const handleUploadImage = async ({ onSuccess, file }) => {
    const { url } = await UploadService.uploadFile(file);
    onSuccess(url, file);
  };

  const handleChangeImage = async (target, value) => {
    await setPost({
      ...post,
      [target]: value
    });
    await setDataGroup({
      ...dataGroup,
      avatar: post?.images[0]?.response
    });
    setDisplayUploadImage(false);
  };

  useEffect(() => {
    const _fetchGroupInfo = async () => {
      await Group.Discussion.fetchGroupInfo(groupId).then(result => {
        if (result.name !== null) {
          setDataGroup({
            avatar: result.avatar,
            name: result.name,
            description: result.description,
            privacy: result.privacy,
            rules: result.rules
          });
        }
      });
      setIsRefetch(false);
    };
    if (isRefetch) {
      _fetchGroupInfo();
    }
  }, [isRefetch, groupId]);

  const execUpdateIntroGroup = async () => {
    setIsLoading(true);
    await Group.GroupGlobal.updateIntroGroup(props.match.params.id, dataGroup)
      .then(result => {
        if (result.name !== undefined) {
          message.success('data successfully updated.');
          setIsLoading(false);
        } else {
          message.error('Something went wrong !');
        }
      })
      .catch(() => message.error('Something went wrong'))
      .finally(() => setIsLoading(false));
    setIsRefetch(true);
  };

  const handleUpdateGroup = event => {
    event.preventDefault();
    props.form.validateFields((error, values) => {
      if (!error) {
        execUpdateIntroGroup();
      }
    });
  };

  const handleDisplayChangeImage = action => {
    setPost(initial);
    setDisplayUploadImage(action);
  };

  return (
    <SettingGroupBoxComponent
      title={title}
      {...props}
      deleteGroupHandler={_deleteGroupHandler}
      displayDeleteModal={displayDeleteModal}
      setDisplayDeleteModal={setDisplayDeleteModal}
      handleAttributeChange={_handleAttributeChange}
      getFieldDecorator={getFieldDecorator}
      group={dataGroup}
      handleUpdateGroup={handleUpdateGroup}
      isLoading={isLoading}
      isRefetch={isRefetch}
      handleUpload={handleUploadImage}
      handleChangeAvatar={handleChangeImage}
      initData={post}
      displayUploadImage={displayUploadImage}
      handleDisplayChangeImage={handleDisplayChangeImage}
    />
  );
};

const SettingGroupBoxContainerForm = Form.create({
  name: 'Setting_Group_Form_Control'
})(SettingGroupBoxContainer);

export default SettingGroupBoxContainerForm;
