import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';

const fetchGroupPostList = (groupId, nextPage) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: 3
    }
  };
  const userId = AuthenticationService.getUserId();
  const userGroupTimeline = Constants.GROUP_POST(userId, groupId);
  return BaseService.get(userGroupTimeline, parameters);
};

const createPost = (body, groupId) => {
  const userId = AuthenticationService.getUserId();
  const userGroupTimeline = Constants.GROUP_POST(userId, groupId);
  return BaseService.post(userGroupTimeline, body);
};

const deletePost = (groupId, postId) => {
  const userId = AuthenticationService.getUserId();
  const postOnGroup = Constants.GROUP_POST_ID(userId, groupId, postId);
  return BaseService.delete(postOnGroup);
};
const postLikePost = (postOwner, groupId, postId, likeValue) => {
  const postUrl = Constants.GROUP_POST_LIKE(
    postOwner,
    groupId,
    postId,
    likeValue
  );

  return BaseService.post(postUrl);
};

const postComment = (postOwner, groupId, postId, content) => {
  const commentContent = {
    content: content
  };
  const postUrl = Constants.GROUP_COMMENT(postOwner, groupId, postId);

  return BaseService.post(postUrl, commentContent);
};

const fetchCommentList = (postOwner, groupId, postId, nextPage, maxResults) => {
  let parameters = {
    params: {
      page: nextPage,
      maxResults: maxResults
    }
  };
  const commentUrl = Constants.GROUP_COMMENT(postOwner, groupId, postId);
  return BaseService.get(commentUrl, parameters);
};

const deleteComment = (comment, groupId) => {
  const commentUrl = Constants.GROUP_COMMENT_ID(
    comment.userId,
    groupId,
    comment.postId,
    comment.id
  );
  return BaseService.delete(commentUrl);
};

const fetchGroupInfo = groupId => {
  const userId = AuthenticationService.getUserId();
  const groupInformation = Constants.GROUPS_ID(userId, groupId);
  return BaseService.get(groupInformation);
};

const getGroupPostId = (groupId, postId) => {
  const userId = AuthenticationService.getUserId();
  const url = Constants.GROUP_POST_ID(userId, groupId, postId);
  return BaseService.get(url);
};

export default {
  createPost,
  fetchGroupPostList,
  fetchGroupInfo,
  deletePost,
  postLikePost,
  fetchCommentList,
  deleteComment,
  postComment,
  getGroupPostId
};
