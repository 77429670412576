import React from 'react';
import { DisplayLoading, LearningResult } from '../../../Components';
//import { PeopleResultList } from '../../../Containers';
import {
  CategoryLabel,
  SearchCategory,
  SearchContainer
} from '../SearchPage.style';

const PeopleScreenComponent = props => {
  const { searchCategory, learningList, isLoading } = props;

  const learningMapped = learningList.map((learning, id) => {
    return <LearningResult key={id} learning={learning} />;
  });
  return (
    <SearchContainer>
      <SearchCategory>
        <CategoryLabel>{searchCategory}</CategoryLabel>
      </SearchCategory>
      {learningMapped}
      {isLoading && <DisplayLoading />}
    </SearchContainer>
  );
};

export default PeopleScreenComponent;
