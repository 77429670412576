import styled, { css } from 'styled-components';
import Assets from '../../Assets';

export const SearchCategoriesWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;

  .ant-tabs-nav-container {
    height: 38px;
    background: #f6f8f9;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.05);
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 13px 30px;
    margin: 0;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.grey};
    font-size: 11px;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${Assets.COLOR_PALLETE.black3};
  }

  .ant-tabs-ink-bar {
    background: ${Assets.COLOR_PALLETE.blue};
  }

  .ant-list {
    padding: 0 4%;
  }

  .resultWrapper {
    background: ${Assets.COLOR_PALLETE.white};
    border-radius: 3px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: block;
  width: 960px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

export const Container = styled.div`
  margin: 0 75px;
`;

export const SearchCategory = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #f7f8f9;
  padding: 4%;
`;

export const CategoryLabel = styled.label`
  font-size: 11px;
  font-family: ${Assets.FONT.MULI_BOLD};
  color: ${Assets.COLOR_PALLETE.black2};
`;

export const Description = styled.div`
  font-size: 12px;
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-right: 30%;
`;

export const Name = styled.label`
  font-size: 18px;
  color: black;
  font-weight: bold;
`;

export const JobDetails = styled.label`
  font-size: 12px;
  color: grey;
`;

export const FollowedBy = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${Assets.FONT.MULI_SEMIBOLD};
  color: #a9a8a8;
  .desc {
    margin: 0 3px;
    font-size: 11px !important;
  }
  .follower {
    color: ${Assets.COLOR_PALLETE.black};
    font-size: 11px !important;
  }
`;

export const Follower = styled.label`
  font-weight: bold;
  color: black;
  margin-left: 5px;
`;

export const Result = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  .author {
    width: 100%;
    display: inline-block;
    .photo {
      width: 35px;
      height: 35px;
      float: left;
      img {
        width: 100%;
        border-radius: 100%;
      }
    }
    .right {
      float: right;
    }
    .info {
      margin-left: 10px;
      margin-top: 3px;
      float: left;
      span {
        display: block;
      }
      .name {
        font-family: ${Assets.FONT.MULI_BOLD};
        color: #252525;
        font-size: 11px;
      }
      .desc {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 11px;
      }
    }
    .more-option {
      display: block;
      float: right;
      width: 9px;
      height: 4px;
      img {
        width: 100%;
      }
    }
    .time {
      margin-top: 6px;
      width: 100%;
      display: block;
      float: right;
      text-align: right;
      span {
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        color: #a9a8a8;
        font-size: 11px;
      }
    }
  }
`;

export const IconCustom = styled.span`
  position: relative;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  width: 11px;
  height: 10px;
  margin-top: 2px;
  &.love {
    background-image: url(${Assets.ICON.LOVE});
  }
  &.comment {
    background-image: url(${Assets.ICON.COMMENT});
  }
  &.share {
    background-image: url(${Assets.ICON.SHARE});
  }
  &.star {
    background-image: url(${Assets.ICON.STAR});
  }
  ${props =>
    props.follow &&
    css`
      background-image: url(${Assets.ICON.FOLLOWED});
      top: 2px;
    `}
`;

export const Button = styled.button`
  border: none;
  outline: none;
  padding: 4px 16px;
  border-radius: 3px;
  font-family: ${Assets.FONT.MULI_SEMIBOLD};
  font-size: 11px;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.primary &&
    css`
      background: ${Assets.COLOR_PALLETE.dark_blue};
      color: ${Assets.COLOR_PALLETE.white};
    `}

  ${props =>
    props.secondary &&
    css`
      background: ${Assets.COLOR_PALLETE.blue};
      color: ${Assets.COLOR_PALLETE.white};
    `}
`;

export const ViewMore = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: #f7f8f9;
  color: ${Assets.COLOR_PALLETE.blue};
  padding: 20px 0;
  &:hover {
    cursor: pointer;
  }
`;
