import React from 'react';
import { WrapperPostTaggedPeople } from './PostTaggedPeople.style';
import { isLastItemOfArray } from '../../Utils/CommonUtils';
//import { Link } from 'react-router-dom';

const PostTaggedPeopleComponent = props => {
  const { taggedUsers } = props;
  const RenderedUser = ({ user }) => {
    const isLastIndexUser = isLastItemOfArray(user, taggedUsers);

    if (isLastIndexUser) {
      return <a href={'/profile/' + user.id + '/detail'}>{user.name}</a>;
    }
    return (
      <a className={'userTagged'} href={'/profile/' + user.id + '/detail'}>
        {user.name},
      </a>
    );
  };
  const renderTaggedUsers = taggedUsers.map((user, id) => {
    return <RenderedUser user={user} key={id} />;
  });

  return (
    <WrapperPostTaggedPeople>
      <span>Dengan {renderTaggedUsers}</span>
    </WrapperPostTaggedPeople>
  );
};

export default PostTaggedPeopleComponent;
