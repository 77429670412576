import styled from 'styled-components';
import Constants from '../../Config/Constants';
import Assets from '../../Assets';

export const Wrapper = styled.div`
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin-top: 2px;
    &.lock {
      background-image: url(${Assets.ICON.LOCK});
    }
    &.up-arrow {
      background-image: url(${Assets.ICON.UP_ARROW});
    }
    &.down-arrow {
      background-image: url(${Assets.ICON.DOWN_ARROW});
    }
  }
  .requested-button {
    /* display: block; */
    &:hover {
      /* display: none; */
    }
  }
  .cancel-button {
    /* display: none; */
  }
`;

export const TopBoxGroup = styled.div`
  width: 100%;
  background: ${Constants.COLORS.WHITE};
  padding: 7.4%;
  display: flex;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};

  .logo {
    width: 40px;
    height: 40px;
    img {
      width: inherit;
      border-radius: 100%;
    }
  }

  .title {
    margin-left: 5.2%;
    span:first-child {
      font-size: 14px;
      font-family: ${Assets.FONT.MULI_BOLD};
      color: ${Constants.COLORS.BLACK};
    }
    span:last-child {
      font-size: 11px;
      font-family: ${Assets.FONT.MULI};
      color: ${Constants.COLORS.GREY};
    }
    i {
      float: left;
      height: 15px;
      margin-right: 5px;
    }
    span {
      display: block;
    }
  }
`;

export const BodyBoxGroup = styled.div`
  width: 100%;
  background: ${Constants.COLORS.WHITE};
  padding: 7.4%;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .sub {
    margin-bottom: 16px;
    label {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI};
      color: ${Constants.COLORS.GREY};
    }
    p {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      color: ${Constants.COLORS.BLACK};
      margin: 0;
    }
  }
  .ant-btn:after {
    animation: 0s !important;
  }
  .showmore {
    padding: 0;
    margin-top: 10px;
    border: none;
    outline: none;
    font-size: 12px;
    color: #039be5;
    font-family: ${Assets.FONT.MULI};
    background: none;
    line-height: 0;
    display: flex;
    .icon {
      margin-left: 10px;
      bottom: 5px;
      position: relative;
    }
  }
`;

export const textHidden = {
  maxHeight: '75px',
  marginTop: 6,
  overflow: 'hidden',
  textAlign: 'justify'
};

export const textShow = {
  maxHeight: 'none',
  marginTop: 6,
  textAlign: 'justify'
};
