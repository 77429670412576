import styled from "styled-components";
import Assets from "../../../Assets";

export const OGFWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0px;
  cursor: pointer;
  .icon-wrapper {
    display: flex;
    flex-direction: column;
    p {
      margin: 7px 0 0;
      font-size: 12px;
      color: inherit;
      text-align: center;
    }
  }
  .red {
    color: ${Assets.COLOR_PALLETE.red};
  }
  .yellow {
    color: ${Assets.COLOR_PALLETE.yellow};
  }
  .green {
    color: ${Assets.COLOR_PALLETE.green};
  }
`;
