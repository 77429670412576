import PropTypes from 'prop-types';

const propTypes = {
  form: PropTypes.shape({
    username: PropTypes.string,
    password: PropTypes.string,
  }),
  updateField: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

const defaultProps = {
  form: {
    username: '',
    password: ''
  }
};

export default {
  propTypes,
  defaultProps
}
