import React from "react";
import { TextSecondary, TextStandard, TextGrey } from "../UserProfileCV.style";

export default function Hobbies({ title, type, desc, details }) {
  let detail = "";
  if (details.length > 0) {
    details.forEach((res) => {
      detail += `${res}, `;
    });
  }

  return (
    <div style={{ marginBottom: 35 }}>
      <TextStandard fontSize="18px">{title}</TextStandard>
      <TextSecondary>{type}</TextSecondary>
      <TextGrey>{detail}</TextGrey>
      <TextStandard>{desc}</TextStandard>
    </div>
  );
}
