import gql from 'graphql-tag';

export const GLOBAL_PROVINCES_SEARCHABLE = gql`
  query getProvinces(
    $where: global_provinces_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total_data: global_provinces_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    global_provinces(
      where: $where
      limit: $limit
      offset: $offset
      order_by: [{ name: asc }]
    ) {
      id
      code
      name
    }
  }
`;

export const GLOBAL_COUNTRY_SEARCHABLE = gql`
  query getProvinces($id: Int, $search: String, $limit: Int, $offset: Int) {
    total_data: global_country_aggregate(
      where: {
        country_name: { _ilike: $search }
        deletedAt: { _is_null: true }
        id: { _eq: $id }
      }
    ) {
      aggregate {
        count
      }
    }

    global_country(
      where: {
        country_name: { _ilike: $search }
        id: { _eq: $id }
        deletedAt: { _is_null: true }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      country_code
      country_name
    }
  }
`;

export const COUNTRY_INDONESIA = gql`
  query getCountryIndonesia(
    $where: global_country_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total_data: global_country_aggregate(where: $where) {
      aggregate {
        count
      }
    }

    global_country(where: $where, limit: $limit, offset: $offset) {
      id
      code
      country_code
      country_name
    }
  }
`;

export const countryIndonesiaVar = params => {
  const { search } = params || {};
  return {
    where: {
      id: { _eq: 103 },
      deletedAt: { _is_null: true },
      ...(search && { country_name: { _ilike: `%${search}%` } })
    }
  };
};

export const GLOBAL_CITIES_SEARCHABLE = gql`
  query getCitiesByProvince(
    $where: global_cities_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total_data: global_cities_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    global_cities(
      where: $where
      limit: $limit
      offset: $offset
      order_by: [{ name: asc }]
    ) {
      id
      code
      name
      province
    }
  }
`;

export const globalCitiesSeachableVars = params => {
  const { province, search } = params || {};
  return {
    where: {
      deletedAt: { _is_null: true },
      ...(!!province && { province: { _eq: province } }),
      ...(!!search && { name: { _ilike: `%${search}%` } })
    }
  };
};

export const GLOBAL_PHONE_CODE_SEARCHABLE = gql`
  query getGlobalPhoneCodeSearch(
    $where: global_phone_code_bool_exp
    $limit: Int
  ) {
    global_phone_code(
      order_by: [{ country: asc }]
      where: $where
      limit: $limit
    ) {
      id
      country
      code
    }
  }
`;
