import React from 'react';
import { Icon } from 'antd';
import { WrapperEducation } from './Education.style';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import Assets from '../../../../Assets';
import { AttachementItemIcon } from '../../../../SharedComponents';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';

export default function EducationItem({
  onClickEdit,
  avatar,
  school,
  degree,
  date,
  description,
  isUser,
  data
}) {
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <WrapperEducation>
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="school">{renderValue(school, '-')}</div>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            {renderValue(
              data?.dataEdit?.company_location?.company_location,
              '-'
            )}
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color="#014a62"
            margin="0px 0px 12px 0px"
          >
            {`${data?.dataEdit?.category ?? '-'}`}
          </ThypoStyled>
          <ThypoStyled
            style={{ textTransform: 'capitalize' }}
            fontSize="12px"
            margin="6px 0px"
            color={Assets.COLOR_PALLETE.blue_link}
          >
            {data?.employee ?? '-'}
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            {renderValue(
              data?.dataEdit?.incident_location?.incident_location,
              '-'
            )}
          </ThypoStyled>

          <ThypoStyled
            fontSize="12px"
            color="#014a62"
            margin="0px 0px 12px 0px"
          >
            {`${data?.time_ohsa ?? '-'}`}
          </ThypoStyled>
          <ThypoStyled /* height="53px" overflow="hidden" */ fontSize="12px">
            {data?.dataEdit?.severity}
          </ThypoStyled>
          <div className="degree">{degree}</div>
          <div className="date">{date} </div>
          <div className="description" style={{ whiteSpace: 'pre-line' }}>
            {renderValue(description, '-')}
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'start',
                paddingTop: '10px'
              }}
            >
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.grey}
                fontStyle="italic"
              >
                Incident Code :
              </ThypoStyled>
              <ThypoStyled
                fontSize="12px"
                color={Assets.COLOR_PALLETE.black}
                fontStyle="italic"
                style={{ paddingLeft: '10px' }}
              >
                {`${renderValue(data.dataEdit.incident_code, '-')}`}
              </ThypoStyled>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            {data.attachments &&
              data.attachments !== '[]' &&
              data.attachments.length >= 1 &&
              data.attachments.map((res, key) => (
                <AttachementItemIcon key={key} url={res.url} name={res.name} />
              ))}
          </div>
        </div>

        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      <div className="divider" />
    </WrapperEducation>
  );
}
