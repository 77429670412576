import { gql } from 'apollo-boost';

export const DECLINE_MEMBER = gql`
  mutation($newMemberId: String!, $classroomId: String!) {
    classroomDeclineMember(
      newMemberId: $newMemberId
      classroomId: $classroomId
    ) {
      success
    }
  }
`;
