import styled from 'styled-components'
import Assets from '../../Assets';

export const WrapperVideo = styled.div`
  display: block;
  width: 100%;
  height: 100%;
    .item {
      display: flex;
      padding: 5px;
      &:hover {
        cursor: pointer;
      }
    }
    img {
      border-radius: 3px;
      width: 100%;
    }
    .show-2 {
      width: 100%;
      height: 100%;
      .item { 
        width: 50%;
        &:first-child {
        width: 50%;
        float: left;
      }
      }
    }
    .show-3 {
      display: block;
      width: 100%;
      height: 100%;
      .item { 
        overflow: hidden;
        img {
          border-radius: 3px;
        }
        &:first-child {
          width: 50%;
          float: left;
        }
        &:nth-child(2) {
          width: 50%;
          height: 7em;
          img {
            width: 100%;
          }
        }
        &:nth-child(3) {
          width: 50%;
          height: 8em;
          img {
            width: 100%;
          }
        }
      }
    }

    .show-4 {
      display: block;
      width: 100%;
      height: 100%;
      .item { 
        overflow: hidden;
        img {
          border-radius: 3px;
        }
        &:first-child {
          width: 100%;
        }
        &:nth-child(2) {
          width: 33.3333%;
          height: 10em;
          float: left;
          img {
            width: 100%;
          }
        }
        &:nth-child(3) {
          width: 33.3333%;
          height: 10em;
          float: left;
          img {
            width: 100%;
          }
        }
        &:nth-child(4) {
          width: 33.3333%;
          height: 10em;
          float: left;
          img {
            width: 100%;
          }
        }
      }
    }

    .show-5 {
      display: block;
      width: 100%;
      height: 100%;
      .item { 
        overflow: hidden;
        img {
          border-radius: 3px;
        }
        &:first-child {
          width: 50%;
          float: left;
        }
        &:nth-child(2) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
        &:nth-child(3) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
        &:nth-child(4) {
          width: 50%;
          float: left;
          img {
            width: 100%;
          }
        }
        &:nth-child(5) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
      }
    }

    .show-more {
      display: block;
      width: 100%;
      height: 100%;
      .item { 
        overflow: hidden;
        img {
          border-radius: 3px;
        }
        &:first-child {
          width: 50%;
          float: left;
        }
        &:nth-child(2) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
        &:nth-child(3) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
        &:nth-child(4) {
          width: 50%;
          float: left;
          img {
            width: 100%;
          }
        }
        &:nth-child(5) {
          width: 50%;
          height: 10em;
          float: right;
          img {
            width: 100%;
          }
        }
        &:nth-child(6),&:nth-child(7),&:nth-child(8),
        &:nth-child(9),&:nth-child(10),&:nth-child(11)
        &:nth-child(12),&:nth-child(13),&:nth-child(14) {display:none}
      }
      .item.display {
        position: absolute;
        width: calc(46% - 2px);
        height: 10em;
        display: inline-block!important;
        bottom: 153px;
      }
      .overlay-count {
        width: 100%;
        height: 100%;
        border-radius: 3px;
        color: ${Assets.COLOR_PALLETE.white};
        background-color: rgba(0, 0, 0, 0.65);
        font-size: 3em;
        font-family: ${Assets.FONT.MULI_SEMIBOLD};
        text-align: center;
        padding-top: 30px;
      }
    }
`;

