import gql from 'graphql-tag';

export const LIST_SCHOOL = gql`
  query($where: company_profiles_bool_exp, $offset: Int, $limit: Int) {
    company_profiles(
      where: $where
      order_by: [{ legal_name: asc }]
      offset: $offset
      limit: $limit
    ) {
      id
      logo
      legal_name
    }
  }
`;

export const LIST_SCHOOL_ID = gql`
  query($id: uuid) {
    company_profiles(where: { id: { _eq: $id } }) {
      id
      logo
      legal_name
    }
  }
`;
export const LIST_FACULTIES = gql`
  query($where: global_faculties_bool_exp, $limit: Int) {
    global_faculties(where: $where, limit: $limit) {
      id
      name
    }
  }
`;

export const LIST_DEGREES = gql`
  query($where: global_degrees_bool_exp, $limit: Int) {
    global_degrees(where: $where, limit: $limit) {
      id
      name
    }
  }
`;

export const LIST_MAJOR = gql`
  query getGlobalMajor($where: global_major_bool_exp, $limit: Int) {
    global_major(where: $where, limit: $limit, order_by: { id: asc }) {
      id
      name
    }
  }
`;

export const ADD_EDUCATION = gql`
  mutation($objects: [people_profile_educations_insert_input!]!) {
    insert_people_profile_educations(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_EDUCATION = gql`
  mutation($id: Int!, $objects: people_profile_educations_set_input!) {
    update_people_profile_educations_by_pk(
      pk_columns: { id: $id }
      _set: $objects
    ) {
      id
    }
  }
`;

export const DELETE_EDUCATION = gql`
  mutation($id: Int!) {
    update_people_profile_educations(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const SET_SHARE_EDUCATON = gql`
  mutation($section: Boolean!, $user: uuid!) {
    a: update_people_profile(
      _delete_key: { settings_section: "education" }
      where: { id: { _eq: $user } }
    ) {
      affected_rows
    }

    b: update_people_profile(
      _append: { settings_section: "education" }
      where: { id: { _eq: $user }, _not: { id: { _is_null: $section } } }
    ) {
      affected_rows
    }
  }
`;
