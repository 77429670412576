import styled from 'styled-components';

export const Header = styled.div`
  padding: 19px 21.7px;
  background-color: #eff2f4;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #000;
`;

export const Content = styled.div`
  padding: 19px 5px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;

  .title {
    font-size: 14px;
    font-weight: bold;
    padding: 20px 15px;
    color: #000;
  }

  .search {
    padding: 0px 15px;
  }

  .user-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 5px 15px;

    &:hover {
      background-color: #eff2f4;
    }

    .avatar {
      margin-top: 3px;
      width: 45px;
    }

    .user {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      overflow: hidden;

      .name {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .position {
        font-size: 12px;
        font-weight: normal;
        color: #a9a8a8;
      }
    }
  }
`;
