import React from 'react';
import MyGroupScreen from './MyGroupScreen';

const menuBarList = [
  {
    renderComponent: props => <MyGroupScreen {...props} />,
    name: 'Tim Kerja',
    path: '/group'
  }
];
const defaultMenuBar = 'Group';

export default {
  menuBarList,
  defaultMenuBar
};
