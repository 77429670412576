import React from 'react';
import { DummySpace } from '../../../Components';
import { setData } from '../../../Components/DetailUserSection/helperForDetail';
import Summary from './Summary';
import Working from './WorkingStatus';
import Experience from './Experience';
import Education from './Education';
import Licences from './Licences';
import Hobbies from './Hobbies';
import Skills from './Skills';
import Recommendation from './Recommendation';
import Project from './Project';
import Speaker from './Speaker';
import Award from './Award';
import Organization from './Organization';
import Disciplinary from './Disciplinary';
import ServiceAgreement from './ServiceAgreement';
import Ohsa from './Ohsa';
import { useGetSettingSecion } from '../../../SharedComponents/CustomHook';
import Publication from './Publication';

const DetailScreenComponent = props => {
  const {
    summary,
    experiences,
    educations,
    licences,
    hobbies,
    isUser,
    refetch,
    profileId,
    people_profile,
    nameUser,
    recommendation,
    isFollowingFollowed,
    userLogin,
    projects,
    speaker,
    disciplinaries,
    awards,
    organization,
    service_agreements,
    isShare,
    ohsa,
    publications
  } = props;
  const [settingSection] = useGetSettingSecion(profileId);

  let dataExperiences = [];
  let dataEducation = [];
  let dataLicences = [];
  let dataHobbies = [];
  let dataProject = [];
  let dataSpeaker = [];
  let dataAward = [];
  let dataOrganization = [];
  let dataDisciplinaries = [];
  let dataServiceAgreement = [];
  let dataOhsa = [];
  let dataPublication = [];
  // console.log(experiences)

  dataExperiences = setData(experiences, 'experiences');
  dataEducation = setData(educations, 'educations');
  dataLicences = setData(licences, 'licences');
  dataHobbies = setData(hobbies, 'hobbies');
  dataProject = setData(projects, 'projects');
  dataSpeaker = setData(speaker, 'speaker');
  dataAward = setData(awards, 'award');
  dataDisciplinaries = setData(disciplinaries, 'disciplinaries');
  dataOrganization = setData(organization, 'organization');
  dataOhsa = setData(ohsa, 'ohsa');
  dataServiceAgreement = setData(service_agreements, 'service');
  dataPublication = setData(publications, 'publication');

  return (
    <>
      {(isUser || settingSection.includes('summary')) && (
        <Summary
          profileId={profileId}
          refetch={refetch}
          summary={summary}
          isUser={isUser}
        />
      )}
      {isUser && (
        <Working userLogin={userLogin} isUser={isUser} nameUser={nameUser} />
      )}
      {(isUser || settingSection.includes('experience')) && (
        <Experience
          profileId={profileId}
          people_profile={people_profile}
          refetch={refetch}
          isUser={isUser}
          dataExperiences={dataExperiences}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('project')) && (
        <Project
          profileId={profileId}
          people_profile={people_profile}
          refetch={refetch}
          isUser={isUser}
          dataProject={dataProject}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('organization_activity')) && (
        <Organization
          profileId={profileId}
          people_profile={people_profile}
          refetch={refetch}
          isUser={isUser}
          dataOrganization={dataOrganization}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('education')) && (
        <Education
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          isUser={isUser}
          dataEducation={dataEducation}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('certification')) && (
        <Licences
          profileId={profileId}
          people_profile={people_profile}
          refetch={refetch}
          isUser={isUser}
          dataLicences={dataLicences}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('speaker')) && (
        <Speaker
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          dataSpeaker={dataSpeaker}
          isUser={isUser}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('skill')) && (
        <Skills
          isFollowingFollowed={isFollowingFollowed}
          nameUser={nameUser}
          people_profile={people_profile}
          profileId={profileId}
          isUser={isUser}
          userLogin={userLogin}
          isShare={isShare}
        />
      )}
      {(isUser || settingSection.includes('award')) && (
        <Award
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          dataAward={dataAward}
          isUser={isUser}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('publication')) && (
        <Publication
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          isUser={isUser}
          dataPublication={dataPublication}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('service_agreement')) && (
        <ServiceAgreement
          isUser={isUser}
          refetch={refetch}
          nameUser={nameUser}
          people_profile={people_profile}
          dataServiceAgreement={dataServiceAgreement}
        />
      )}
      {(isUser || settingSection.includes('disciplinary')) && (
        <Disciplinary
          isUser={isUser}
          refetch={refetch}
          nameUser={nameUser}
          people_profile={people_profile}
          dataDisciplinaries={dataDisciplinaries}
        />
      )}
      {(isUser || settingSection.includes('ohsa')) && (
        <Ohsa
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          isUser={isUser}
          dataOhsa={dataOhsa}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('hobbies')) && (
        <Hobbies
          people_profile={people_profile}
          profileId={profileId}
          refetch={refetch}
          dataHobbies={dataHobbies}
          isUser={isUser}
          nameUser={nameUser}
        />
      )}
      {(isUser || settingSection.includes('referal')) && (
        <Recommendation
          dataRecommendations={recommendation}
          isUser={isUser}
          nameUser={nameUser}
          refetch={refetch}
          people_profile={people_profile}
          profileId={profileId}
        />
      )}
      <DummySpace />
    </>
  );
};

export default DetailScreenComponent;
