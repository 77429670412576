import React from 'react';
import { CreateGroupBox, SidebarProfile } from '../../../Containers';
import { Row, Col } from 'antd';
import { DummySpace, HCEcosystem } from '../../../Components';

const CreateGroupScreenComponent = props => {
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarProfile history={props.history} />
          <HCEcosystem />
        </Col>
        <Col span={16}>
          <CreateGroupBox {...props} />
        </Col>
      </Row>
      <DummySpace />
    </div>
  );
};

export default CreateGroupScreenComponent;
