import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';

import config from './ClassroomHomePage.config';
import { Enhancer } from '../../SharedComponents';
import { AuthenticationService } from '../../Services';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { MenuBar } from '../../Containers';
import { DisplayLoading } from '../../Components';
import { useBlockMe } from '../../SharedComponents/CustomHook';
import {
  GET_REQUESTED_MEMBERS,
  GET_MEMBERS,
  GET_CONTRIB_REQUEST
} from './getMembersClassroom.graphql';
import { GET_CLASSROOM_BY_ID } from './getClassroomById.graphql';

import { CenterLayout } from '../../Components';
import { Content, Container } from './ClassroomHomePage.style';
import EditPostScreen from './EditPost';
import PageWrapper from './PageWrapper.component';
import DetailPostScreen from './PostDetail';

const ClassroomHomePageContainer = props => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { state } = location;
  const currentPath = location.pathname.split('/')[3];
  const classroomId = params.id;
  const ownId = AuthenticationService.getUserId();
  const menuBarList = config.menuBarList;
  const isEdit = location.pathname.includes('edit');

  const [isAdmin, setIsAdmin] = useState(false);
  const [isMentor, setIsMentor] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  const [menuList, setMenuList] = useState(menuBarList);
  const [blockedMe] = useBlockMe();

  let redirectFrom = '';
  if (state?.redirectFrom) {
    redirectFrom = state?.redirectFrom;
  }

  const {
    data: classroomInfo,
    loading: classroomInfoIsLoading,
    refetch
  } = useQuery(GET_CLASSROOM_BY_ID, {
    variables: { classroomId: classroomId },
    context: { headers: { 'X-Hasura-Role': 'user' } },
  });

  const {
    data: requestedData,
    loading: requestedDataIsLoading,
    refetch: refetchRequestData
  } = useQuery(GET_REQUESTED_MEMBERS, {
    variables: { classroom_id: classroomId },
    context: { headers: { 'X-Hasura-Role': 'user' } },

  });

  const {
    data: membersData,
    loading: membersDataIsLoading,
    refetch: refetchMemberData
  } = useQuery(GET_MEMBERS, {
    variables: { classroom_id: { _eq: classroomId }, offset: 0 },
    context: { headers: { 'X-Hasura-Role': 'user' } },

  });

  const {
    data: contribData,
    loading: RequestDataContrib,
    refetch: refetchContribData
  } = useQuery(GET_CONTRIB_REQUEST, {
    variables: {
      classroomId: classroomId
      // contribUserId: AuthenticationService.getUserId(),
      // limit: 5,
      // offset: 0
    },
    context: { headers: { 'X-Hasura-Role': 'user' } },

  });

  useEffect(() => {
    refetch();
    refetchRequestData();
    refetchMemberData();
    refetchContribData();
  }, [refetch, refetchContribData, refetchMemberData, refetchRequestData]);

  useEffect(() => {
    if (
      !requestedDataIsLoading &&
      !membersDataIsLoading &&
      !classroomInfoIsLoading &&
      !RequestDataContrib
    ) {
      // if social learning has not been approved by learning contributors,
      // then social learning will not be found (response from BE = null)
      if (!classroomInfo?.classrooms_by_pk) {
        return history.replace(`/error404`);
      }

      if (requestedData && membersData && classroomInfo && contribData) {
        const memberIdx = requestedData.classroom_member_requests.findIndex(
          item => item.global_user.id === ownId
        );
        if (memberIdx >= 0) setIsRequest(true);

        // const memberRequstApproval =
        //   contribData.classroom_contrib_requests.length > 0
        //     ? contribData.classroom_contrib_requests[0].status
        //     : null;

        const member = membersData.classroom_members.find(
          member => member.global_user.id === ownId
        );

        if (member) {
          setIsJoined(true);

          if (member.is_admin) {
            setIsAdmin(true);
          }
          if (member.is_mentor) {
            setIsMentor(true);
          }
        }
        const _isFinished =
          classroomInfo.classrooms_by_pk.is_finished ||
          redirectFrom === 'end-session';

        if (_isFinished) {
          setIsFinished(true);
        }

        const _isIndividual = classroomInfo.classrooms_by_pk.is_individual;
        if (_isIndividual) {
          setIsIndividual(true);
        }

        const _isPaid = classroomInfo.classrooms_by_pk.price !== 0;

        if (_isPaid) setIsPaid(true);
        if (!member) {
          history.replace(`/social-learning/${classroomId}/about`);
        }
        if (member && currentPath === 'about') {
          history.replace(`/social-learning/${classroomId}/discussion`);
        }
        if (member && !member.is_admin && currentPath === 'setting') {
          history.replace(`/social-learning/${classroomId}/discussion`);
        }

        if (member && !_isFinished && currentPath === 'review') {
          history.replace(`/social-learning/${classroomId}/discussion`);
        }

        const menuNotJoined = [menuList.find(menu => menu.name === 'Tentang')];
        const menuAdmin = menuList.filter(menu => menu.name !== 'Tentang');
        const menuAdminNotFinished = menuAdmin.filter(
          menu => menu.name !== 'Ulasan'
        );
        const menuFinished = menuAdmin.filter(
          menu => menu.name !== 'Pengaturan'
        );
        const menuNotFinished = menuFinished.filter(
          menu => menu.name !== 'Ulasan'
        );

        if (!member) {
          // if (!member || memberRequstApproval === 0) {
          setMenuList(menuNotJoined);
        } else {
          if (!member.is_admin) {
            if (!_isFinished) {
              setMenuList(menuNotFinished);
            } else {
              setMenuList(menuFinished);
            }
          } else {
            if (!_isFinished) {
              setMenuList(menuAdminNotFinished);
            } else {
              setMenuList(menuAdmin);
            }
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    requestedData,
    requestedDataIsLoading,
    membersData,
    membersDataIsLoading,
    classroomInfo,
    classroomInfoIsLoading,
    contribData,
    RequestDataContrib,
    isFinished
  ]);

  if (
    requestedDataIsLoading ||
    membersDataIsLoading ||
    classroomInfoIsLoading ||
    RequestDataContrib
  ) {
    return <DisplayLoading />;
  } else {
    if (isEdit) {
      return (
        <Content>
          <Container>
            <CenterLayout>
              <PageWrapper>
                <EditPostScreen
                  // location={location}
                  // isAdmin={isAdmin}
                  isRequest={isRequest}
                  setIsRequest={setIsRequest}
                  isJoined={isJoined}
                  // isPaid={isPaid}
                  // blockedMe={blockedMe}
                  // isFinished={isFinished}
                  // isIndividual={isIndividual}
                  contribData={contribData}
                  {...props}
                />
                ;
              </PageWrapper>
            </CenterLayout>
          </Container>
        </Content>
      );
    } else if (currentPath === 'post' && !isEdit) {
      return (
        <Content>
          <Container>
            <CenterLayout>
              <PageWrapper>
                <DetailPostScreen
                  location={location}
                  isAdmin={isAdmin}
                  isRequest={isRequest}
                  setIsRequest={setIsRequest}
                  isJoined={isJoined}
                  isPaid={isPaid}
                  blockedMe={blockedMe}
                  isFinished={isFinished}
                  isIndividual={isIndividual}
                  contribData={contribData}
                  {...props}
                />
                ;
              </PageWrapper>
            </CenterLayout>
          </Container>
        </Content>
      );
    } else {
      return (
        <MenuBar
          menuBarList={
            isIndividual
              ? menuList.filter(menu => menu.name !== 'Member')
              : menuList
          }
          location={location}
          isAdmin={isAdmin}
          isRequest={isRequest}
          setIsRequest={setIsRequest}
          isJoined={isJoined}
          isPaid={isPaid}
          blockedMe={blockedMe}
          isFinished={isFinished}
          isIndividual={isIndividual}
          contribData={contribData}
          isMentor={isMentor}
          refetchContribData={refetchContribData}
          {...props}
        />
      );
    }
  }
};

export default Enhancer({})(ClassroomHomePageContainer);
