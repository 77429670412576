import React from 'react';
import GlobalScreen from './GlobalScreen';

const menuBarList = [
  {
    renderComponent: props => (
      <GlobalScreen timelineType="GLOBAL_TIMELINE" {...props} />
    ),
    name: 'Keseluruhan',
    path: '/'
  },
  {
    renderComponent: props => (
      <GlobalScreen timelineType="COMPANY" {...props} />
    ),
    name: 'Instansi',
    path: '/companypost'
  }
];
const defaultMenuBar = 'Keseluruhan';

export default {
  menuBarList,
  defaultMenuBar
};
