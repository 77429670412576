import React from 'react';
import { WidgetHoliday } from '@worklifebeyond/wlb-utils-components';
import { AuthenticationService } from '../../../Services';

const ScheduleComponent = () => {
  const userData = AuthenticationService.getUserData();
  return (
    <div style={{ marginBottom: 11.2 }}>
      <WidgetHoliday
        company={userData?.company_id}
        self={userData?.id}
        language="indonesian"
      />
    </div>
  );
};

export default ScheduleComponent;
