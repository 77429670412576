import React from 'react';
import { ModalEdit, ButtonFullWlb } from '../../../../Components';
import EducationItem from './EducationItem';
import { WrapperScroll } from './Education.style';

export default function MoreEducation({
  open,
  nameUser,
  setOpen,
  isUser,
  data
}) {
  return (
    <ModalEdit
      centered
      padding="0px"
      title={`Pendidikan ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false, fromList: false }))}
    >
      <WrapperScroll>
        {data &&
          data.length > 0 &&
          data.map((res, i) => {
            return (
              <EducationItem
                key={i}
                avatar={res.avatar}
                school={res.title}
                other_school={res?.dataEdit?.other_school}
                degree={res.company}
                date={res.time}
                description={res.description}
                isUser={isUser}
                location={res.detail_school}
                data={res}
                onClickEdit={() =>
                  setOpen(e => ({
                    ...e,
                    open: true,
                    openMore: false,
                    fromList: true,
                    actionType: 'edit',
                    data: res.dataEdit
                  }))
                }
              />
            );
          })}
      </WrapperScroll>
      {isUser && (
        <ButtonFullWlb
          label="Tambahkan Pendidikan"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
}
