import React from 'react';
import { Col, Dropdown, Rate, Row, Menu, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import './ProductList.css';
import style, { ProductName } from './ProductList.style';
import Utils from '../../../Utils';
import { Link } from 'react-router-dom';
import Assets from '../../../Assets';
import { AuthenticationService } from '../../../Services';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ReportIcon from '@material-ui/icons/ReportOutlined';
import { CHECK_DELETE_PRODUCT } from '../../../Containers/Vendor/ProductList/VendorProductGraphQl';
import { useApolloClient } from 'react-apollo';

const ProductListComponent = ({
  productList,
  vendorId,
  title,
  setOpen,
  open
}) => {
  const userData = AuthenticationService.getUserData();
  const selectedVendorId = vendorId;

  const client = useApolloClient();

  const cekDelete = async (productId, name) => {
    const { data: check } = await client.query({
      query: CHECK_DELETE_PRODUCT,
      variables: {
        productId: productId
      },
      context: { headers: { 'X-Hasura-Role': 'organization-hr-admin' } }
    });
    if (check) {
      if (check.global_invoice_items_aggregate.aggregate.count === 0) {
        setOpen({
          ...open,
          confirmation: true,
          name: name,
          id: productId
        });
      } else {
        setOpen({
          ...open,
          noDelete: true
        });
      }
    }
  };
  const _renderContent = product => (
    <a href={`${ process.env.REACT_APP_HC_VENDOR}/vendor/detail/${selectedVendorId}/product/${product.id}`}>
      <div style={style.content} onClick={() => window.scrollTo(0, 0)}>
        <ProductName>{product.name}</ProductName>
        <p style={style.productAuthor}>{product.author}</p>
        <div style={style.divRating}>
          <Rate
            className="rate-product-list"
            allowHalf
            style={style.rating}
            value={product.rating}
            disabled
          />
          <span style={style.valueRating}>{product.rating} </span>
          <span style={style.totalRating}>
            {product.totalRating && product.totalRating}
          </span>
        </div>
        <div style={style.divRating}>
          <span style={style.truePrice}>
            {Utils.currencyFormat(product.price)}
          </span>
          {
            // <span style={style.truePrice}>{Utils.currencyFormat(product.truePrice)}</span>
          }
        </div>
      </div>
    </a>
  );
  const _renderProduct = () => {
    return productList.map(product => {
      return (
        <Col span={8} key={product.id}>
          <div style={style.colContainer}>
            <div
              style={{
                backgroundImage: `url(${
                  !isEmpty(product.images)
                    ? (userData?.thumbnail_url ?? '') + product.images[0]
                    : Assets.ICON.DEFAULT_ITEM
                })`,
                width: '100%',
                objectFit: 'cover',
                height: 164
                // display : 'flex',
                // flexDirection : 'between'
              }}
            >
              <div style={style.headerImage}>
                <div
                  style={{
                    backgroundColor: '#fff',
                    width: '22px',
                    height: '22px',
                    borderRadius: '5px'
                  }}
                >
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          {' '}
                          <Link
                            to={`${selectedVendorId}/product/${product.id}`}
                          >
                            Edit
                          </Link>
                        </Menu.Item>
                        <Menu.Item>Share</Menu.Item>
                        <Menu.Item
                          onClick={() => {
                             setOpen({
                               ...open,
                               openArchive: true,
                               id: product.id,
                               stok: !product.is_always_available ? product.available_stock : 99,
                               type: title
                             });
                          }}
                        >
                          {title === 'Archived' ? 'Unarchive' : 'Archive'}
                        </Menu.Item>
                        <Menu.Item
                          style={{ color: 'red' }}
                          onClick={async => {
                            cekDelete(product.id, product.name);
                          }}
                        >
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a
                      className="ant-dropdown-link"
                      onClick={e => {
                        e.preventDefault();
                      }}
                    >
                      <MoreHorizIcon
                        style={{ color: '#014A62', fontSize: '22px' }}
                      />
                    </a>
                  </Dropdown>
                </div>
                {_labelRight(product)}
              </div>
            </div>
            {_renderContent(product)}
          </div>
        </Col>
      );
    });
  };

  const _labelRight = product => {
    if (title === 'Archived') {
      return (
        <span
          style={{
            background: '#EF4D5E',
            color: 'white',
            padding: '8px 12px',
            borderRadius: '5px',
            fontSize: '10px'
          }}
        >
          Archived
        </span>
      );
    } else if (product.available_stock <= 5) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            width: '22px',
            height: '22px',
            borderRadius: '5px'
          }}
        >
          <Tooltip
            placement="right"
            color={'#eff2f4'}
            title={() => {
              return (
                <div style={{ padding: '2px' }}>
                  <span style={{ fontSize: '12px' }}>
                    This product to needs be re-stocked
                  </span>
                  <br />
                  <span
                    style={{ color: '#EF4D5E', fontSize: '12px' }}
                  >{`Current Stok : ${product.available_stock}`}</span>
                </div>
              );
            }}
          >
            <ReportIcon style={{ color: '#EF4D5E', fontSize: '22px' }} />
          </Tooltip>
        </div>
      );
    }
  };
  return (
    <Row type="flex" gutter={24}>
      {_renderProduct()}
    </Row>
  );
};

export default ProductListComponent;
