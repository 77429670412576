import React from 'react';
import CreateGroupScreen from './CreateGroupScreen';

const menuBarList = [
  {
    renderComponent: props => <CreateGroupScreen {...props} />,
    name: 'Tim Kerja',
    path: '/createGroup'
  }
];
const defaultMenuBar = 'Group';

export default {
  menuBarList,
  defaultMenuBar
};
