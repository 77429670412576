import React, { useState, useEffect } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import ListUtils from '../../../../Components/DetailUserSection/ListUtils.component';
import AddSkills from './AddSkills';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import {
  LIST_SKILL_ENDORSEMENT,
  DELETE_SKILL,
  DELETE_SKILL_ENDORSEMENT,
  GET_LIST_USER_SKILL
} from './Skills.graphql';
import EndorseSkills from './EndorseSkills';
import { message } from 'antd';
import { ModalDeleteWLBCheck } from '../../../../Components';
import MoreSkilsModal from './MoreSkilsModal';
import { setData } from '../../../../Components/DetailUserSection/helperForDetail';
import { DEFAULT_LIMIT_ITEM_SECTIONS } from '../DetailScreen.container';
import PeopleWithSameSkill from './PeopleWithSameSkill';

export default function Skills({
  isUser,
  profileId,
  people_profile,
  nameUser,
  isFollowingFollowed,
  userLogin,
  isShare
}) {
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    delete: false,
    dataDelete: null,
    openMore: false,
    fromList: false,

    // more people state
    morePeople: false,
    dataPeopleSkill: null
  });
  const [openEndorse, setOpenEndorse] = useState({
    open: false,
    data: null,
    actionType: null
  });
  const [endorsement, setEndorsment] = useState([]);

  const CONTEXT_PUBLIC = { headers: { 'X-Hasura-Role': 'public' } };
  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const { data, loading, refetch } = useQuery(GET_LIST_USER_SKILL, {
    variables: {
      limit: DEFAULT_LIMIT_ITEM_SECTIONS,
      id: profileId,
      userLogin: userLogin,
      is_share: isShare
    },
    context: CONTEXT_PUBLIC
  });

  const dataSkills = setData(data && data.skills, 'skills');

  const [deleteSkill] = useMutation(DELETE_SKILL, {
    context: CONTEXT_USER
  });
  const [deleteEnndorsement] = useMutation(DELETE_SKILL_ENDORSEMENT, {
    context: CONTEXT_USER
  });

  const [getListEndorsement, { data: listEndorsement }] = useLazyQuery(
    LIST_SKILL_ENDORSEMENT,
    {
      context: CONTEXT_PUBLIC
    }
  );

  useEffect(() => {
    if (!isUser) {
      getListEndorsement();
    }
    if (listEndorsement) {
      setEndorsment(listEndorsement.endorsement_relationships);
    }
  }, [isUser, getListEndorsement, listEndorsement]);

  const handleChidAction = resEdit => {
    setOpen({
      ...open,
      open: true,
      data: resEdit,
      actionType: 'edit'
    });
  };

  const handleDelete = () => {
    deleteSkill({
      variables: {
        id: open.dataDelete.id
      }
    })
      .then(() => {
        refetch();
        if (open.fromList) {
          setOpen({ ...open, delete: false, dataDelete: null, openMore: true });
        } else {
          setOpen({ ...open, delete: false, dataDelete: null });
        }
        message.success(`Berhasil menghapus keahlian`);
      })
      .catch(err => {
        message.error(`Gagal menghapus keahlian, ${err}`);
      });
  };

  const handleUnedorsed = res => {
    deleteEnndorsement({
      variables: {
        id: res?.dataEdit?.checker_user_login_endorsements?.[0]?.id
      }
    })
      .then(() => {
        refetch();
        message.success(`You have unendorsed for ${res?.nameIndicator} skill`);
      })
      .catch(err => {
        message.error(
          `Unendorsed for ${res?.nameIndicator} skill error, ${err}`
        );
      });
  };

  const handleOpenEndorse = data => {
    setOpenEndorse({
      open: true,
      data,
      actionType: 'add'
    });
    setOpen(e => ({
      ...e,
      openMore: false,
      fromList: true
    }));
  };

  const handleCloseModalEndorse = () => {
    setOpenEndorse({ ...open, open: false, actionType: null });
    if (open.fromList) {
      setOpen(e => ({
        ...e,
        openMore: true
      }));
    }
  };

  const handleClickMore = () => {
    setOpen({ ...open, openMore: true });
    refetch({
      limit: undefined
    });
  };

  const handleClickMorePeople = ({ data, isFromList = false }) => {
    setOpen(e => ({
      ...e,
      morePeople: true,
      dataPeopleSkill: data,
      openMore: false,
      fromList: isFromList
    }));
  };

  return (
    <>
      <DetailUserSection
        title="Keahlian"
        onClickAction={() =>
          setOpen({ ...open, open: true, data: null, actionType: 'add' })
        }
        action={isUser ? 'plus' : null}
      >
        <ListUtils
          setOpenEndorse={setOpenEndorse}
          isFollowingFollowed={isFollowingFollowed}
          isUser={isUser}
          data={dataSkills}
          total={data?.total?.aggregate?.count || 0}
          onUnendorsed={handleUnedorsed}
          editOnChild
          showMoreText=" Keahlian"
          handleChidAction={handleChidAction}
          onClickMore={handleClickMore}
          onClickMorePeople={handleClickMorePeople}
        />
      </DetailUserSection>

      <MoreSkilsModal
        loading={loading}
        nameUser={nameUser}
        open={open.openMore}
        setOpen={setOpen}
        data={dataSkills}
        isUser={isUser}
        isFollowingFollowed={isFollowingFollowed}
        onEndorse={handleOpenEndorse}
        onUnendorsed={handleUnedorsed}
        onClickMorePeople={handleClickMorePeople}
      />

      <AddSkills
        people_profile={people_profile}
        profileId={profileId}
        refetch={refetch}
        data={open.data?.dataEdit}
        actionType={open.actionType}
        open={open.open}
        onClose={
          open.fromList
            ? () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  openMore: true
                })
            : () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false
                })
        }
        onDelete={data => {
          setOpen(e => ({
            ...e,
            open: false,
            openMore: false,
            delete: true,
            dataDelete: data
          }));
        }}
      />

      <EndorseSkills
        refetch={refetch}
        nameUser={nameUser}
        endorsement={endorsement}
        data={openEndorse.data}
        open={openEndorse.open}
        onClose={handleCloseModalEndorse}
      />
      <ModalDeleteWLBCheck
        centered
        open={open.delete}
        target="Skill"
        onClose={
          open.fromList
            ? () =>
                setOpen({
                  ...open,
                  delete: false,
                  dataDelete: null,
                  // fromList: false,
                  open: true
                })
            : () =>
                setOpen({
                  ...open,
                  delete: false,
                  dataDelete: null,
                  // fromList: false,
                  open: true
                })
        }
        onDelete={handleDelete}
      />

      <PeopleWithSameSkill
        open={open.morePeople}
        data={open.dataPeopleSkill}
        isUser={isUser}
        onClose={() =>
          setOpen(e => ({
            ...e,
            morePeople: false,
            openMore: e.fromList,
            dataPeopleSkill: null
          }))
        }
      />
    </>
  );
}
