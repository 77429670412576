import React from 'react';
import { ComponentWrapper } from './style';
import { Icon } from 'antd';

const Index = props => {
  const { deleteTask } = props;
  return (
    <ComponentWrapper>
      <div className="component_image">
        <img src={props.img} alt={props.name} />
      </div>
      <div className="component_content">
        <div className="component_item_title">{props.name}</div>
        <div className="component_item_description">{props.description}</div>
        <div>{props.as}</div>
      </div>
      {deleteTask && (
        <Icon type="close" className="close-icon" onClick={deleteTask} />
      )}
    </ComponentWrapper>
  );
};

export default Index;
