import React, { useState } from 'react';
import GroupResultComponent from './TeamResult.component';
// import { AuthenticationService } from '../../Services';
import { Group } from '../../Services';
import { message } from 'antd';

const GroupResultContainer = props => {
  const { team } = props;
  // const userId = AuthenticationService.getUserId();
  const [isJoin, setIsJoin] = useState(team.isJoined);
  const [isRequest,setRequest] = useState(team.isRequest)
  // const [isJoin, setIsJoin] = useState(team.member.includes(userId));

  const joinGroupHandler = team => {
    if (isJoin === false) {
      Group.Member.joinGroup(team.id).then(res => {
        if (res.status === 200) {
          if(team.privacy === 2){
            setRequest(true)
          }
          setIsJoin(true);
          message.success(`Join ${team.name} success!`);
        }
      });
    } else {
      message.info('If you want to leave, do it via the group page');
    }
  };
  return (
    <GroupResultComponent
      team={team}
      isJoin={isJoin}
      isRequest={isRequest}
      joinGroupHandler={joinGroupHandler}
    />
  );
};

export default GroupResultContainer;
