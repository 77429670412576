import React, { useState } from 'react';
import { message } from 'antd';
import { useMutation } from 'react-apollo';
import { ModalDeleteWLBCheck } from '../../Components';
import { ButtonAddList, TabWrapper } from '../../SharedComponents';
import AddEditPsychologicalTest from './AddEditPsychologicalTest';
import PsychologicalItem from './PsychologicalItem';
import AssessmentModalDetail from './AssessmentModalDetail.component';
import { DELETE_PSYCHOLOGICAL_TEST } from './UserPsychological.graphql';

const initialOpen = {
  open: false,
  actionType: 'add',
  data: null,
  modalDelete: false
};

export default function UserPsychologicalTestComponent({
  data,
  isUser,
  profileId,
  refetch
}) {
  const [open, setOpen] = useState(initialOpen);
  const [detail, setDetail] = React.useState({
    open: false,
    data: null
  });

  const handleClickDetail = data =>
    setDetail({
      open: true,
      data
    });
  const handleCloseDetail = () => setDetail({ open: false, data: null });

  const [deletePsycologicalTest] = useMutation(DELETE_PSYCHOLOGICAL_TEST, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const handleModalEdit = state => {
    setOpen({
      ...open,
      modalDelete: false,
      open: state === 'open',
      data: state === 'open' ? open.data : null,
      actionType: state === 'open' ? 'edit' : null
    });
  };

  const onButtonEditClick = data => {
    setOpen({
      ...open,
      open: true,
      actionType: 'edit',
      data: data
    });
  };

  const handleDelete = () => {
    deletePsycologicalTest({
      variables: {
        id: open.data.id
        // userId: profileId,
      }
    })
      .then(() => {
        refetch();
        message.success(`Berhasil menghapus asesmen`);
        handleModalEdit('close');
      })
      .catch(err => {
        message.error(
          `Gagal menghapus asesmen, silakan coba lagi nanti, ${err}`
        );
      });
  };

  return (
    <>
      <TabWrapper>
        {data &&
          data.psychological_tests.map((res, i) => {
            return (
              <PsychologicalItem
                key={`${i}-${res.id}`}
                data={res}
                isUser={isUser}
                onClickDetail={handleClickDetail}
                onEdit={() => onButtonEditClick(res)}
              />
            );
          })}
      </TabWrapper>
      {isUser && (
        <ButtonAddList
          onClick={() =>
            setOpen({ ...open, open: true, actionType: 'add', data: null })
          }
          label="Tambah Asesmen"
        />
      )}
      <AssessmentModalDetail
        open={detail.open}
        data={detail.data}
        onClose={handleCloseDetail}
        isUser={isUser}
        onEdit={() => {
          setDetail(e => ({ ...e, open: false }));
          onButtonEditClick(detail.data);
        }}
      />
      <AddEditPsychologicalTest
        refetch={refetch}
        profileId={profileId}
        open={open.open}
        actionType={open.actionType}
        setOpen={setOpen}
        data={open.data}
        onClose={() => setOpen(initialOpen)}
      />
      <ModalDeleteWLBCheck
        open={open.modalDelete}
        centered
        target="Asesmen"
        onClose={() => handleModalEdit('open')}
        onDelete={handleDelete}
      />
    </>
  );
}
