import React from 'react';
import { FloatingMenu } from '@worklifebeyond/wlb-utils-components';
import { useMediaQuery } from '@material-ui/core';
import {
  Schedule,
  QuickAccess,
  Group,
  Akhlak,
  CultureEcosystem,
  BNIWidget
} from '../../Components';
import { AuthenticationService } from '../../Services';

const { Calendar, Section } = FloatingMenu;

const WidgetComponent = props => {
  const { widgets } = props;
  const userData = AuthenticationService.getUserData();
  const listOfWidgets = widgets.map(w => Object.values(w.widget)[1]);

  const isMobileScreen = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      <div hidden={isMobileScreen}>
        <BNIWidget />
        {listOfWidgets.includes('Announcement') && <Schedule />}
        {listOfWidgets.includes('Quick Access') && <QuickAccess />}
        <CultureEcosystem /> {/* new wording Culture Solution */}
        <Akhlak /> {/* new wording Culture Solution */}
        {listOfWidgets.includes('Group') && <Group {...props} />}
      </div>

      <FloatingMenu language="indonesian" hidden={!isMobileScreen} scrub>
        {listOfWidgets.includes('Announcement') && (
          <Calendar language="indonesian" company={userData?.company_id} self={userData?.id} />
        )}

        {listOfWidgets.includes('Quick Access') && (
          <Section label="Akses Cepat">
            <QuickAccess withHeader={false} />
          </Section>
        )}

        <Section label="Ekosistem Budaya">
          <CultureEcosystem withHeader={false} />
        </Section>

        <Section label="Solusi Budaya">
          <Akhlak withHeader={false} />
        </Section>

        {listOfWidgets.includes('Group') && (
          <Section label="Group">
            <Group withHeader={false} {...props} />
          </Section>
        )}

        <div style={{ padding: 42 }} />
      </FloatingMenu>
    </>
  );
};

export default WidgetComponent;
