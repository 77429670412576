import React from 'react';
import { Icon } from 'antd';
import { WrapperEducation } from './Education.style';
import { ThypoStyled } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import Assets from '../../../../Assets';
import { AttachementItemIcon } from '../../../../SharedComponents';
import AvatarDetail from '../../../../Components/DetailUserSection/AvatarDetail';

export default function EducationItem({
  onClickEdit,
  avatar,
  school,
  degree,
  other_school,
  date,
  description,
  isUser,
  location,
  data
}) {
  return (
    <WrapperEducation>
      <div className="content">
        <div className="avatar-wrapper">
          <AvatarDetail avatar={avatar || Assets.DEFAULT_LOGO} />
        </div>
        <div className="detail-section">
          <div className="school">{other_school || school}</div>
          <div className="date">{location}</div>
          <div className="degree">{degree}</div>
          <div className="date">{date} </div>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.black}
            fontStyle="italic"
          >
            {`GPA : ${data.dataEdit?.education_fields?.gpa ?? '-'}`}
          </ThypoStyled>
          <div className="description">{description}</div>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.blue_link}
            fontStyle="italic"
            style={{ padingLeft: '10px' }}
          >
            {`Certificate ID ${data.dataEdit?.education_fields
              ?.ceritificate_id ?? '-'}`}
          </ThypoStyled>
          <div
            style={{
              display: 'flex',
              flexDirection: 'start',
              paddingTop: '10px'
            }}
          >
            {data.attachments &&
              data.attachments !== '[]' &&
              data.attachments.length >= 1 &&
              data.attachments.map((res, key) => (
                <AttachementItemIcon key={key} url={res.url} name={res.name} />
              ))}
          </div>
        </div>
        {isUser && <Icon onClick={onClickEdit} type="edit" />}
      </div>
      <div className="divider" />
    </WrapperEducation>
  );
}
