import React from 'react';
import { Link } from 'react-router-dom';
import {
  TopSettingGroup,
  DummySpace,
  ClassroomFeedbackComponent
} from '../../Components';
import { MemberCardBoxWrap, DescriptionWrap } from './ReviewCardBox.style';
import { DisplayLoading } from '../../Components';

const ReviewCardBoxComponent = props => {
  const { fullResume, toggleResume, detailClassroom, classroomLoading } = props;
  const resume =
    detailClassroom &&
    detailClassroom.classrooms_by_pk &&
    detailClassroom.classrooms_by_pk.resume
      ? detailClassroom.classrooms_by_pk.resume
      : 'Ringkasan tidak tersedia';

  const DataResume = () => {
    return (
      <div className="box">
        <TopSettingGroup title="Ringkasan" />
        <DescriptionWrap>
          {detailClassroom && !classroomLoading ? (
            <>
              <div>
                {!fullResume && !classroomLoading && resume
                  ? resume.slice(0, 300)
                  : resume}
              </div>
              {detailClassroom && resume.length > 300 && (
                <Link onClick={toggleResume}>
                  {!fullResume
                    ? 'Tampilkan Lebih Banyak'
                    : 'Tampilkan Lebih Sedikit'}
                </Link>
              )}
            </>
          ) : (
            <DisplayLoading />
          )}
        </DescriptionWrap>
      </div>
    );
  };

  const FeedBackData = () => {
    return (
      <div className="box">
        <TopSettingGroup title="Umpan balik" />
        <ClassroomFeedbackComponent {...props} />
      </div>
    );
  };

  return (
    <MemberCardBoxWrap>
      <DataResume />
      <FeedBackData />
      <DummySpace />
    </MemberCardBoxWrap>
  );
};

export default ReviewCardBoxComponent;
