import React, { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import FormProductComponent from './FormProduct.component';
import { FormTitle } from './FormProduct.style';
import { useGlobalState } from '../../../globalState';
import { UploadService } from '../../../Services';
import { isEmpty } from 'lodash';
import {
  ADD_PRODUCT,
  GET_DETAIL_PRODUCT,
  GET_SHOWCASE_LIST_BY_VENDOR_ID,
  SHOW_CATEGORY_PRODUCT,
  UPDATE_PRODUCT_GRAPHQL
} from './FormProduct.graphql';
// import Constants from '../../../Config/Constants';
import { useHistory, useParams } from 'react-router-dom';

// const {
//   SIDEBAR_VENDOR: { PRODUCT }
// } = Constants;
const OPTIONS = {
  context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
};

const AddProduct = props => {
  const history = useHistory();
  const params = useParams();
  const { form } = props;
  const vendorId = params.id;
  const productId = props.match.params.productID;
  const [uploadImages, setUploadImages] = useState({ images: [] });
  const [productImages, setProductImages] = useState([]);
  // const [uploadFile, setUploadFile] = useState([]);
  const [, setActiveMenu] = useGlobalState('vendorPath');

  const { data: categoryData } = useQuery(SHOW_CATEGORY_PRODUCT);
  const [addProduct] = useMutation(ADD_PRODUCT, OPTIONS);
  const [updateProduct] = useMutation(UPDATE_PRODUCT_GRAPHQL, OPTIONS);
  const { data: showcaseData } = useQuery(GET_SHOWCASE_LIST_BY_VENDOR_ID, {
    variables: { vendorId: vendorId }
  });

  const { data: detailProduct } = useQuery(GET_DETAIL_PRODUCT, {
    ...OPTIONS,
    skip: !productId,
    fetchPolicy: 'cache-and-network',
    variables: { id: productId }
  });

  useEffect(() => {
    if (detailProduct) {
      setProductImages(detailProduct.v_marketplace_product[0].images);
    }
  }, [detailProduct]);

  const handleUploadImage = async ({ onSuccess, file }) => {
    const { url } = await UploadService.uploadFile(file);
    onSuccess(url, file);
  };

  const handleChangeImage = (target, value) => {
    setUploadImages({
      ...uploadImages,
      [target]: value
    });
  };

  const handleDeleteImage = image => {
    const idx = productImages.indexOf(image);
    const filterImage = productImages
      .slice(0, idx)
      .concat(productImages.slice(idx + 1, productImages.length));
    setProductImages(filterImage);
    // setProductImages(productImages.filter(img => img !== image));
  };
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let dataImages = uploadImages.images
          .slice(0, 5 - productImages.length)
          .map(image => image.response);

        const product = detailProduct?.v_marketplace_product?.[0];
        const ongoingStock = product
          ? product.current_stock - product.available_stock
          : 0;

        const variableData = {
          data: {
            name: values.name,
            category: values.category,
            showcase: values.showcase,
            description: values?.description ?? '',
            minimum_order: 1,
            price: parseInt(values.price),
            // discount_price: parseInt(values.discount_price),
            current_stock:
              (values.current_stock ? values.current_stock : 0) + ongoingStock,
            weight: values.is_digital_product ? 0 : values.weight,
            insurance: values.insurance ? values.insurance : false,
            images: productImages.concat(dataImages),
            is_always_available: values.is_always_available,
            is_digital_product: values.is_digital_product,
            is_preorder: values.preorder ? values.preorder : false,
            preorder_time: values.preorder_time ? values.preorder_time : 0,
            detail_field: {
              volume: {
                p: values.is_digital_product ? 0 : values.panjang,
                l: values.is_digital_product ? 0 : values.lebar,
                t: values.is_digital_product ? 0 : values.tinggi
              }
            },
            dummy_price: values.discount_price
              ? parseInt(values.discount_price)
              : null
          }
        };

        if (isEmpty(productId)) {
          await addProduct({
            variables: variableData
          })
            .then(res => {
              setActiveMenu('Product');
              message.success('Successfully add new Product');
              history.push('/vendor/' + props.match.params.id);
            })
            .catch(err => {
              // console.log(err);
            });
        } else {
          let updatedData = variableData.data;
          updatedData.id = productId;
          await updateProduct({
            variables: updatedData
          })
            .then(res => {
              // setProductImages([]);
              setActiveMenu('Product');
              message.success('Successfully update product');
              history.push('/vendor/' + props.match.params.id);
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }
    });
  };

  return (
    <>
      <FormTitle>
        {!isEmpty(productId) ? 'Edit Product' : 'Add Product'}
      </FormTitle>
      <Form onSubmit={handleSubmit}>
        <FormProductComponent
          {...props}
          uploadImages={uploadImages}
          handleUploadImage={handleUploadImage}
          handleChangeImage={handleChangeImage}
          categoryData={categoryData}
          showcaseData={showcaseData}
          detailProduct={detailProduct}
          handleDeleteImage={handleDeleteImage}
          productImages={productImages}
        />
      </Form>
    </>
  );
};

export default Form.create()(AddProduct);
