import BaseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';
import { isObjectEmpty } from '../../Utils/CommonUtils';

const userId = AuthenticationService.getUserId();

const getListAllMember = (groupId, maxResults, keyword) => {
  let parameters = {
    params: {
      name: keyword,
      maxResults: maxResults
    }
  };

  if (isObjectEmpty(keyword)) {
    delete parameters.params.name;
  }
  const getListMemberUrl = Constants.GROUP_ALL_MEMBER(userId, groupId);

  return BaseService.get(getListMemberUrl, parameters);
};

const getListMember = (groupId, page, maxResults, keyword) => {
  let parameters = {
    params: {
      name: keyword,
      page: page,
      maxResults: maxResults
    }
  };

  if (isObjectEmpty(keyword)) {
    delete parameters.params.name;
  }
  const getListMemberUrl = Constants.GROUP_MEMBER(userId, groupId);

  return BaseService.get(getListMemberUrl, parameters);
};

const getListAdmin = (groupId, page, maxResults) => {
  const userId = AuthenticationService.getUserId();
  const getListAdminUrl = Constants.GROUP_ADMIN(
    userId,
    groupId,
    page,
    maxResults
  );

  return BaseService.get(getListAdminUrl);
};

const setAdminAsMember = (groupId, groupMemberId) => {
  const url = Constants.GROUP_MEMBER_TO_ADMIN(userId, groupId, groupMemberId);
  return BaseService.post(url);
};

const setMemberAsAdmin = (groupId, groupMemberId) => {
  const url = Constants.GROUP_ADMIN_TO_MEMBER(userId, groupId, groupMemberId);
  return BaseService.post(url);
};

const kickMemberGroup = (groupId, groupMemberId) => {
  const url = Constants.GROUP_MEMBER_ID(userId, groupId, groupMemberId);
  return BaseService.delete(url);
};

const leaveMemberGroup = groupId => {
  const url = Constants.GROUP_LEAVE(userId, groupId);
  return BaseService.post(url);
};

const inviteMember = (groupId, memberId) => {
  const url = Constants.GROUP_MEMBER_ID(userId, groupId, memberId);
  return BaseService.post(url);
};

const joinGroup = groupId => {
  const url = Constants.GROUP_JOIN(userId, groupId);
  return BaseService.post(url);
};

const getListRequestMember = (groupId, page, maxResults) => {
  // let parameters = {
  //   params: {
  //     page: page,
  //     maxResults: maxResults
  //   }
  // };
  const getRequestMemberList = Constants.GROUP_REQUEST_MEMBER_LIST(
    userId,
    groupId
  );
  return BaseService.get(getRequestMemberList);
};
const approveRequest = (groupId, acceptId) => {
  const acceptRequest = Constants.GROUP_ACCEPT_REQUEST_MEMBER(userId, groupId);
  return BaseService.post(acceptRequest, { users: [acceptId] });
};
const rejectRequest = (groupId, rejectId) => {
  const rejectRequest = Constants.GROUP_DECLINE_REQUEST_MEMBER(userId, groupId);
  return BaseService.post(rejectRequest, { users: [rejectId] });
};
const cancelRequestJoin = groupId => {
  const url = Constants.GROUP_CANCEL_REQUEST_JOIN(userId, groupId);
  return BaseService.post(url);
};

export default {
  getListAllMember,
  getListMember,
  getListAdmin,
  setAdminAsMember,
  setMemberAsAdmin,
  leaveMemberGroup,
  kickMemberGroup,
  inviteMember,
  joinGroup,
  getListRequestMember,
  approveRequest,
  rejectRequest,
  cancelRequestJoin
};
