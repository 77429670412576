import React from 'react';
import { TopSettingGroup, MemberList, DummySpace } from '../../Components';
import { MemberCardBoxWrap } from './MemberCardBox.style';
import { DisplayLoading } from '../../Components';
import { isEmpty } from 'lodash';

const MemberCardBoxComponent = props => {
  const {
    admin,
    member,
    requestMember,
    handleSetAsAdmin,
    handleSetAsMember,
    handleLeaveMember,
    handleKickMember,
    memberLoading,
    isAdmin
  } = props;

  const _renderEmptyMember = () => {
    return <div className="component_no_member">Tidak Ada Anggota</div>;
  };

  const RequestMember = () => {
    return (
      <div className="box">
        <TopSettingGroup
          title={`Permintaan Anggota (${requestMember.length})`}
        />
        <MemberList
          type="request"
          dataMember={requestMember}
          handleSetAsAdmin={handleSetAsAdmin}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          {...props}
        />
        {/* <hr />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 30
          }}
        >
          <p className="request">See All Request</p>
        </div> */}
      </div>
    );
  };
  const DataAdmin = () => {
    const memberLength = !memberLoading ? `(${admin.length})` : '';
    return (
      <div className="box">
        <TopSettingGroup title={`Admin ${memberLength}`} />
        <MemberList
          type="admin"
          dataMember={admin}
          handleSetAsAdmin={handleSetAsAdmin}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          {...props}
        />
      </div>
    );
  };

  const _renderDataMember = () => {
    const memberLength = !memberLoading ? `(${member.length})` : '';
    return (
      <div className="box">
        <TopSettingGroup title={`Anggota ${memberLength}`} />
        <MemberList
          type="member"
          dataMember={member}
          handleSetAsMember={handleSetAsMember}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          {...props}
        />
        {memberLoading && <DisplayLoading />}
        {isEmpty(member) && !memberLoading && _renderEmptyMember()}
      </div>
    );
  };

  return (
    <MemberCardBoxWrap>
      {isAdmin && !isEmpty(requestMember) && <RequestMember />}
      <DataAdmin />
      <div id="group-member">{_renderDataMember()}</div>
      <DummySpace />
    </MemberCardBoxWrap>
  );
};

export default MemberCardBoxComponent;
