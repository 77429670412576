import styled from 'styled-components';
import Assets from '../../Assets';

export const BannerWrap = styled.div`
  position: relative;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  .backdropImg {
    img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
    }
  }

  .information {
    position: relative;
    width: 100%;
    background: ${Assets.COLOR_PALLETE.white};
    display: flex;
    font-size: 12px;
    font-family: ${Assets.FONT.MULI};
    padding: 1.7%;
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .info-left span,
    .info-right span {
      display: block;
    }
    .info-left {
      width: 100%;
      padding-left: 20%;
      @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding-left: 0%;
        text-align: center;
        margin-top: 100px;
      }
    }
    .info-right {
      width: 100%;
      max-width: 230px;

      @media (min-width: 1200px) {
        max-width: unset;
      }

      .ant-row {
        display: flex;
        justify-content: center;
        gap: 12px;

        @media (min-width: 1200px) {
          justify-content: end;
          gap: unset;
        }
      }
    }
  }

  .logo {
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      bottom: unset;
      left: unset;
      width: 125px;
      top: -17%;
    }
    position: absolute;
    width: 16%;
    bottom: 20%;
    left: 20px;
    z-index: 1;
    max-height: 200px;
    padding: 16px;
    background: ${Assets.COLOR_PALLETE.white};
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  .footer {
    display: flex;
    @media only screen and (min-width: 320px) and (max-width: 767px) {
      flex-direction: column;
      height: auto;
    }
    justify-content: space-between;
    width: 100%;
    background: #f7f8f9;
    height: 38px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .socialMedia {
      padding: 9px 15px;
      text-align: center;

      a {
        padding: 0 16px;
      }
    }
    .buttonGroup {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: center;
      margin: 7px 0;

      @media (min-width: 767px) {
        padding-right: 10px;
      }

      .ant-btn {
        padding: 3px 16px;
      }
      .ant-btn-secondary {
        margin-right: 12px;
      }
    }
  }
`;
