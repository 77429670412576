import React, { useState } from 'react';
import { Spin, Empty, Avatar, Tabs } from 'antd';
import { PostService } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { ShowVoteWrapper, ModalHeaderWrapper } from './ModalShowVote.style';

const ModalShowVotes = props => {
  const { open, handleClose, itemPost, userId } = props;
  const { TabPane } = Tabs;

  const [tabValue, setTabValue] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const postId = itemPost.id;

  useAsyncEffect(async () => {
    if (itemPost) {
      if (itemPost.type === 4) {
        if (itemPost.poll.options.length > 0) {
          const _fetchUserVote = async () => {
            setLoading(true);
            const optionId = itemPost.poll.options[tabIndex].id;
            await PostService.getUsersByVote(userId, postId, optionId)
              .then(response => {
                setUserList(response.data);
              })
              .catch(error => {})
              .finally(() => {
                setLoading(false);
              });
          };

          if (open) {
            _fetchUserVote();
          }

          setTabValue(itemPost.poll.options);
        }
      }
    }
  }, [tabIndex, itemPost, open]);

  const _renderPosition = (type, data) => {
    let value = '';
    const pos = data.user.people_work_placements;
    if (type === 'position') {
      value = pos.length > 0 ? `${pos[0]?.company_job_profile?.title} at ` : '';
    } else {
      value = pos.length > 0 ? pos[0].company_profile.brand_name : '';
    }

    return value;
  };

  const handleChangeTab = activeKey => {
    setTabIndex(activeKey);
  };

  const ModalHeader = () => {
    return (
      <ModalHeaderWrapper>
        <div className="title">
          <span className="title-text">Votes</span>
        </div>
        <Tabs
          activeKey={tabIndex}
          defaultActiveKey={0}
          onChange={handleChangeTab}
          tabBarStyle={{ height: '45px' }}
        >
          {tabValue.length > 0
            ? tabValue.map((data, key) => {
                return <TabPane tab={data.option} key={key} />;
              })
            : 'No Options'}
        </Tabs>
      </ModalHeaderWrapper>
    );
  };

  return (
    <ShowVoteWrapper
      visible={open}
      footer={false}
      onCancel={() => handleClose()}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalHeader />
      <div style={{ padding: '20px 25px' }}>
        {!loading ? (
          userList.length > 0 ? (
            userList.map((val, i) => {
              return (
                <div className="user-like" key={i}>
                  <div className="avatar">
                    <Avatar size={45} src={val.user.avatar} />
                  </div>
                  <div className="info">
                    <p className="name">{val.user.name}</p>
                    <p className="pos">
                      {_renderPosition('position', val)}
                      {_renderPosition('company', val)}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <Empty />
          )
        ) : (
          <Spin />
        )}
      </div>
    </ShowVoteWrapper>
  );
};

export default ModalShowVotes;
