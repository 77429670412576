import React from 'react';
import ClassroomEditPostComponent from './ClassroomEditPost.component';

const ClassroomEditPostContainer = props => {
  return (
    <div>
      <ClassroomEditPostComponent {...props} />
    </div>
  );
};

export default ClassroomEditPostContainer;
