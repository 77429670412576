import React from 'react';
import { Button, Collapse } from 'antd';
import { CollapseWrap, PanelItem } from './Collapse.style';
import { DeleteModal, CreateGroupForm, ButtonWlb } from '..';
import { displayLoading } from '../../Utils/CommonUtils';

const { Panel } = Collapse;

const CollapseComponent = props => {
  const {
    deleteGroupHandler,
    displayDeleteModal,
    setDisplayDeleteModal,
    handleUpdateGroup,
    isLoading,
    isRefetch,
    title
  } = props;
  return (
    <CollapseWrap>
      <Collapse defaultActiveKey={['group-intro']}>
        <Panel header="Pengaturan Utama" key="group-intro">
          {isRefetch ? (
            displayLoading(isRefetch)
          ) : (
            <CreateGroupForm title={title} {...props} />
          )}
          <ButtonWlb
            style={{ marginLeft: '4.3%', marginBottom: '4.3%' }}
            key="1"
            type="primary"
            loading={isLoading}
            onClick={handleUpdateGroup}
          >
            Perbaharui
          </ButtonWlb>
        </Panel>
        <Panel header="Kelola Tim Kerja" key="manage-group">
          {/*<PanelItem>*/}
          {/*  <div className="text">*/}
          {/*    <p>Post Approval</p>*/}
          {/*    <p>When Post Approval is admin only, only admin can posting in the group discussion.</p>*/}
          {/*  </div>*/}
          {/*  <div className="group-button">*/}
          {/*    <span>Anyone</span>*/}
          {/*    <Switch defaultChecked />*/}
          {/*  </div>*/}
          {/*</PanelItem>*/}
          {/*<PanelItem>*/}
          {/*  <div className="text">*/}
          {/*    <p>Membership Approval</p>*/}
          {/*    <p>When Membership Approval is admin only, only admin can approve new member request.</p>*/}
          {/*  </div>*/}
          {/*  <div className="group-button">*/}
          {/*    <span>Anyone</span>*/}
          {/*    <Switch defaultChecked />*/}
          {/*  </div>*/}
          {/*</PanelItem>*/}
          <PanelItem>
            <div className="text">
              <p>Hapus Tim Kerja</p>
              <p>
                Jika Anda menghapus tim kerja, tim kerja tersebut akan dihapus
                secara permanen dan tidak dapat dikembalikan
              </p>
              <Button
                type="danger"
                ghost
                onClick={() => setDisplayDeleteModal(true)}
              >
                Hapus Tim Kerja
              </Button>
              <DeleteModal
                displayModal={displayDeleteModal}
                closeModal={() => setDisplayDeleteModal(false)}
                handleDelete={() => deleteGroupHandler()}
                modalTitle={'Delete Group'}
                modalContent={`Are you sure want to delete this Group? This will delete record permanently`}
              />
            </div>
          </PanelItem>
        </Panel>
      </Collapse>
    </CollapseWrap>
  );
};

export default CollapseComponent;
