import React from 'react';
import CompanyDetailBoxComponent from './CompanyDetailBox.component';
import { useQuery } from 'react-apollo';
import { GET_COMPANY_DETAIL_BOX_NEW } from './CompanyDetailBox.graphql';
import { isEmpty } from 'lodash';
import dateTime from '../../Utils/dateTime';
import { Spin } from 'antd';

const CompanyDetailBoxContainer = props => {
  const { companyId, updateedFollowers } = props;
  const OPTIONS = {
    variables: {
      id: companyId
    }
  };

  const { data, loading } = useQuery(GET_COMPANY_DETAIL_BOX_NEW, OPTIONS);

  let companyBox = {
    industry: '-',
    founded: '-',
    specialities: '-',
    type: '-',
    totalEmployee: 0,
    headquarter: '-'
  };

  if (data) {
    const { main_profile } = data;
    if (!isEmpty(main_profile)) {
      const res = main_profile?.[0];
      companyBox = {
        industry: main_profile?.[0]?.global_company_industry_type?.name,
        founded: dateTime.americanDateFormat(
          main_profile?.[0]?.profile_field?.founded
        ),
        specialities: main_profile?.[0]?.profile_field?.specialities,
        type: main_profile?.[0]?.company_legal_type?.legal_type,
        headquarter:
          res?.main_office?.global_city?.name +
          ', ' +
          res?.main_office?.global_province?.name
      };
    }
    companyBox = {
      ...companyBox,
      totalEmployee: data.total_employee.aggregate.count
    };
  }
  return (
    <CompanyDetailBoxComponent
      {...props}
      companyBox={companyBox}
      loading={loading}
      followerCompany={
        props?.dataLoadedCompany?.totalFollower + updateedFollowers || <Spin size="small" />
      }
    />
  );
};

export default CompanyDetailBoxContainer;
