import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import {
  Form,
  Select,
  Input,
  DatePicker,
  Checkbox,
  Switch,
  message
} from 'antd';
import { TitleField } from '../DetailScreen.style';
import Axios from 'axios';
import { useMutation, useLazyQuery, useQuery } from 'react-apollo';
import {
  ADD_EDUCATION,
  LIST_FACULTIES,
  LIST_DEGREES,
  UPDATE_EDUCATION,
  DELETE_EDUCATION,
  LIST_MAJOR
} from './Education.graphql';
import {
  generateDateFormat,
  getErrorMessage,
  isAfter
} from '../HelperDetailScreen';
import moment from 'moment';
import { LIST_SCHOOL } from './Education.graphql';
import {
  Attachment,
  AttachmentItem,
  FormDateInline,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  SearchableSelect,
  ButtonFrom,
  globalCitiesSeachableVars
} from '../../../../SharedComponents';
import { AuthenticationService } from '../../../../Services';
import { checkFilesType } from '../../../../Containers/UserProfileMedicalHistory/helperMedicalHistory';
import { month } from '../../../../Containers/UserProfileAbout/HelperAbout';

const AddEducationComponent = ({
  open,
  onClose,
  openState,
  onCloseModal,
  form,
  refetch,
  actionType,
  data,
  setOpen
}) => {
  const {
    validateFields,
    getFieldDecorator,
    setFieldsValue,
    resetFields,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [currently, setCurrently] = useState(false);
  const [deleteState, setDeleteState] = useState({
    open: false
  });
  const [share, setShare] = useState(false);

  const [
    getProvince,
    { data: queryProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);
  const [getCity, { data: queryCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );

  const [location, setLocation] = useState({
    city: '',
    province: ''
  });

  const [attachment, setAttachment] = useState([]);
  const [errorFile, setErrorFile] = useState(false);
  const [errorMax, setErrorMax] = useState(false);
  const [errorSize, setErrorSize] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const [addEducation] = useMutation(ADD_EDUCATION, {
    context: CONTEXT_USER
  });
  const [updateEducation] = useMutation(UPDATE_EDUCATION, {
    context: CONTEXT_USER
  });
  const [deleteEducation] = useMutation(DELETE_EDUCATION, {
    context: CONTEXT_USER
  });

  const [
    getSchools,
    { data: daftarSchool, loading: loadingSchool }
  ] = useLazyQuery(LIST_SCHOOL, {
    context: CONTEXT_USER,
    fetchPolicy: 'network-only'
  });
  const [
    getFaculties,
    { data: daftarFaculties, loading: loadingFaculties }
  ] = useLazyQuery(LIST_FACULTIES, {
    context: CONTEXT_USER
  });
  const [
    getDegrees,
    { data: daftarDegree, loading: loadingDegree }
  ] = useLazyQuery(LIST_DEGREES, {
    context: CONTEXT_USER
  });
  const { data: globalDegrees } = useQuery(LIST_DEGREES, {
    context: CONTEXT_USER,
    fetchPolicy: 'cache-first'
  });

  const [getMajor, { data: daftarMajor, loading: loadingMajor }] = useLazyQuery(
    LIST_MAJOR,
    {
      context: CONTEXT_USER
    }
  );

  useEffect(() => {
    if (actionType === 'edit') {
      setFieldsValue({
        school: data?.company_profile?.id || undefined,
        other_school: data?.other_school,
        faculty: data?.faculty?.id || undefined,
        degree: data?.degree?.id || undefined,
        major: data?.major?.id || undefined,
        description: data?.description,
        province: data?.education_fields?.province?.id || undefined,
        city: data?.education_fields?.city?.id || undefined
      });
      setAttachment(data?.education_fields?.attachments ?? []);
      setLoadingUpload(false);
      setCurrently(data.end_date === null);
      getProvince({
        variables: {
          where: {
            deletedAt: { _is_null: true }
          }
        }
      });
      getCity({
        variables: {
          ...globalCitiesSeachableVars({
            province: data?.education_fields?.province?.id
          })
        }
      });
      setShare(data?.education_fields?.is_shared);

      setLocation(prev => ({
        ...prev,
        province: data?.education_fields?.province?.name ?? null,
        city: data?.education_fields?.city?.name ?? null
      }));
    } else if (actionType === 'add') {
      setAttachment([]);
      setLoadingUpload(false);
      setCurrently(false);
      setShare(false);
      setFieldsValue({
        school: data?.company_profile?.id || undefined,
        other_school: data?.other_school,
        faculty: data?.faculty?.id || undefined,
        degree: data?.degree?.id || undefined,
        major: data?.major?.id || undefined,
        description: data?.description,
        province: data?.education_fields?.province?.id || undefined,
        city: data?.education_fields?.city?.id || undefined
      });
    }

    return () => {
      // Cleanup function
    };
  }, [data, setFieldsValue, actionType, getProvince, getCity]);

  useEffect(() => {
    if (open) {
      getSchools({
        variables: {
          ...getWhere('SCHOOLS', {
            id: getFieldValue('school') || data?.company_profile?.id
          }),
          limit: 1
        }
      });
      getFaculties({
        variables: {
          ...getWhere('FACULTY', { id: getFieldValue('faculty') }),
          limit: 1
        }
      });
      getDegrees({
        variables: {
          ...getWhere('DEGREE', { id: getFieldValue('degree') }),
          limit: 1
        }
      });
      getMajor({
        variables: {
          ...getWhere('MAJOR', { id: getFieldValue('major') }),
          limit: 1
        }
      });
    }

    return () => {
      // Cleanup function
    };
  }, [
    open,
    getSchools,
    getFaculties,
    getDegrees,
    getMajor,
    getFieldValue,
    data
  ]);

  const handleChange = (formValue, target) => {
    if (target === 'upload-attachment') {
      uploadAttachment(formValue);
    }
  };

  const TOKEN = AuthenticationService.getTokenAuth();
  const uploadAttachment = e => {
    setErrorFile(false);
    setErrorSize(false);
    setErrorMax(false);
    setLoadingUpload(false);
    const tempFile = Array.from(e.target.files);
    const [isErrorType, isMaxsize] = checkFilesType(tempFile);
    if (tempFile.length + attachment.length > 5) {
      setErrorMax(true);
    } else if (isErrorType) {
      setErrorFile(true);
    } else if (isMaxsize) {
      setErrorSize(true);
    } else if (tempFile.length > 0) {
      setLoadingUpload(true);
      tempFile.forEach(val => {
        const formData = new FormData();
        formData.append('file', val);
        return Axios.post(process.env.REACT_APP_UPLOAD_URL, formData, {
          headers: {
            Authorization: 'Bearer ' + TOKEN,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            setLoadingUpload(false);
            setAttachment(prev => {
              return [
                ...prev,
                { url: res.data.url, name: val.name, size: val.size }
              ];
            });
          })
          .catch(() => {
            setLoadingUpload(false);
          });
      });
    }
  };

  const listFields = [
    {
      labelName: 'Tingkatan Pendidikan',
      fieldName: 'degree',
      type: 'searchable-select',
      placeholder: 'Pilih tingkatan pendidikan',
      fieldValue: getFieldValue('degree') || undefined,
      option: daftarDegree?.global_degrees || [],
      loading: loadingDegree,
      isCapitalizeOptions: true,
      onSearch: ({ value }) => {
        getDegrees({
          variables: {
            ...getWhere('DEGREE', { search: value }),
            limit: 20
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getDegrees({
            variables: {
              ...getWhere('DEGREE', { id: getFieldValue('degree') }),
              limit: 20
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          degree: e
        });
      }
    },
    {
      labelName: 'Sekolah',
      fieldName: 'school',
      type: 'searchable-select',
      fieldValue:
        getFieldValue('school') || data?.company_profile?.id || undefined,
      placeholder: 'Pilih Sekolah',
      required: true,
      option:
        daftarSchool?.company_profiles.map(({ id, legal_name }) => ({
          id,
          name: legal_name
        })) || [],
      loading: loadingSchool,
      onSearch: ({ value }) => {
        getSchools({
          variables: {
            ...getWhere('SCHOOLS', { search: value }),
            limit: 20
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getSchools({
            variables: {
              ...getWhere('SCHOOLS', { id: getFieldValue('school') }),
              limit: 20
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          school: e
        });
      }
    },
    {
      labelName: 'Jurusan',
      fieldName: 'major',
      type: 'searchable-select',
      placeholder: 'Pilih jurusan',
      required: true,
      fieldValue: getFieldValue('major') || undefined,
      option: daftarMajor?.global_major || [],
      loading: loadingMajor,
      onSearch: ({ value }) => {
        getMajor({
          variables: {
            ...getWhere('MAJOR', { search: value }),
            limit: 20
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getMajor({
            variables: {
              ...getWhere('MAJOR', { id: getFieldValue('major') }),
              limit: 20
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          major: e
        });
      }
    },
    {
      labelName: 'Fakultas',
      fieldName: 'faculty',
      type: 'searchable-select',
      placeholder: 'Pilih fakultas',
      fieldValue: getFieldValue('faculty') || undefined,
      required: true,
      option: daftarFaculties?.global_faculties || [],
      loading: loadingFaculties,
      isCapitalizeOptions: true,
      onSearch: ({ value }) => {
        getFaculties({
          variables: {
            ...getWhere('FACULTY', { search: value }),
            limit: 20
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getFaculties({
            variables: {
              ...getWhere('FACULTY', { id: getFieldValue('faculty') }),
              limit: 20
            }
          });
        }
      },
      onChange: e => {
        setFieldsValue({
          faculty: e
        });
      }
    }
  ];

  const schoolDegree = globalDegrees?.global_degrees
    .filter(degree => {
      if (
        [
          'junior high school',
          'senior high school',
          'elementary school'
        ].includes(degree.name?.toLowerCase())
      )
        return degree;
    })
    .map(degree => degree.id);

  if (schoolDegree?.includes(getFieldValue('degree'))) {
    listFields[1] = {
      labelName: 'Sekolah',
      fieldName: 'other_school',
      type: 'text',
      fieldValue: data?.other_school,
      placeholder: 'Tambahkan sekolah',
      required: true,
      onChange: e => {
        setFieldsValue({
          other_school: e
        });
      }
    };
    listFields[2].disabled = true;
    listFields[3].disabled = true;
  }

  const listFields2 = [
    {
      labelName: 'Indeks Prestasi Kumulatif (IPK)',
      fieldName: 'gpa',
      type: 'text',
      fieldValue: data && data?.education_fields?.gpa,
      placeholder: 'Tambahkan IPK',
      required: false
    },
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'select',
      fieldValue: data && data?.education_fields?.country,
      placeholder: 'Pilih negara',
      required: true,
      option: [
        {
          name: 'Indonesia',
          id: 'Indonesia'
        }
      ]
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      placeholder: 'Pilih provinsi',
      required: true,
      option: queryProvince?.global_provinces ?? [],
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: (e, data) => {
        setFieldsValue({
          city: undefined,
          province: e
        });
        setLocation({
          ...location,
          province: data.props.children
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      fieldValue: getFieldValue('city'),
      placeholder: 'Pilih kota',
      required: true,
      option: queryCity?.global_cities ?? [],
      loading: loadingCity,
      onSearch: ({ value }) => {
        getCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      },
      onChange: (_, data) => {
        setLocation({
          ...location,
          city: data.props.children
        });
      }
    },
    {
      labelName: 'Ijazah',
      fieldName: 'certificate_name',
      type: 'text',
      fieldValue: data && data?.education_fields?.certificate_name,
      placeholder: 'Tambahkan ijazah',
      required: false
    },
    {
      labelName: 'Nomor Ijazah',
      fieldName: 'ceritificate_id',
      type: 'text',
      fieldValue: data && data?.education_fields?.ceritificate_id,
      placeholder: 'Tambahkan nomor ijazah',
      required: false
    }
  ];

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        let attach = [];
        attachment.length >= 1 &&
          attachment.map(row => {
            attach.push(row);
          });
        const present =
          values.currently_studying === undefined
            ? false
            : values.currently_studying;
        const objects = {
          school: schoolDegree?.includes(values.degree) ? null : values.school,
          other_school: schoolDegree?.includes(values.degree)
            ? values.other_school
            : null,
          from: generateDateFormat(values.start_date),
          to: present === true ? null : generateDateFormat(values.end_date),
          // is_present: present,
          description: values.description,
          field_of_study: schoolDegree?.includes(values.degree)
            ? null
            : values.faculty,
          degree: values.degree,
          major: schoolDegree?.includes(values.degree) ? null : values.major,
          education_fields: {
            is_shared: share,
            gpa: values.gpa,
            country: values.country,
            province: { id: values.province, name: location.province },
            city: { id: values.city, name: location.city },
            certificate_name: values.certificate_name,
            ceritificate_id: values.ceritificate_id,
            certificate_date:
              generateDateFormat(
                moment(
                  `${values.year}/${values.month}/${values.day}`,
                  'YYYY/MM/DD'
                )
              ) === 'Invalid date'
                ? null
                : generateDateFormat(
                    moment(
                      `${values.year}/${values.month}/${values.day}`,
                      'YYYY/MM/DD'
                    )
                  ),
            attachments: attach
          }
        };
        if (actionType === 'add') {
          addEducation({
            variables: { objects }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil menambahkan pendidikan`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal menambahkan pendidikan, ${err}`);
            });
        } else if (actionType === 'edit') {
          updateEducation({
            variables: { id: data.id, objects: objects }
          })
            .then(() => {
              refetch();
              resetFields();
              message.success(`Berhasil mengubah pendidikan`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Gagal mengubah pendidikan, ${err}`);
            });
        }
      }
    });
  };

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      labelName,
      isLoading,
      placeholder,
      loading,
      fieldValue,
      onSearch,
      onTouch,
      onChange,
      isCapitalizeOptions,
      disabled = false
    } = res;
    if (type === 'select') {
      return (
        <Select
          // defaultActiveFirstOption
          loading={loading || isLoading}
          showSearch
          size="large"
          style={
            fieldName === 'degree' ? { textTransform: 'capitalize' } : null
          }
          filterOption={(input, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
        >
          <Option value={null} disabled>
            Pilih {labelName}
          </Option>
          {option.length > 0 &&
            option.map((res, i) => {
              return (
                <Option
                  style={
                    fieldName === 'degree'
                      ? { textTransform: 'capitalize' }
                      : null
                  }
                  key={i}
                  value={res.id}
                >
                  {fieldName === 'school' ? res.legal_name : res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
          isCapitalizeOptions={isCapitalizeOptions}
          disabled={disabled}
        />
      );
    } else if (type === 'text') {
      return (
        <Input
          mode="tags"
          placeholder={placeholder}
          style={{ width: '100%' }}
        />
      );
    }
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
    setLoadingUpload(false);
  };

  const handleDelete = () => {
    deleteEducation({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        resetFields();
        refetch();
        setDeleteState({ open: false });
        message.success(`Berhasil menghapus pendidikan`);
        onClose();
      })
      .catch(err => {
        message.error(`Gagal menghapus pendidikan, ${err}`);
      });
  };

  const handleClose = () => {
    onClose();
    resetFields();
  };

  const handleModalDelete = () => {
    setDeleteState({ open: true });
    onCloseModal();
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleChangeNameFile = (e, i) => {
    const newState = [...attachment];
    newState[i].name = e.target.value;
    setAttachment(newState);
  };

  const handleDeleteAttachement = i => {
    const newState = [...attachment];
    if (i > -1) {
      newState.splice(i, 1);
    }
    setAttachment(newState);
    document.getElementById('upload-education').value = null;
  };

  const generateDate = date => {
    if (date) {
      return date.split('-');
    } else {
      return null;
    }
  };
  const year = new Date().getFullYear();
  const until = year - 70;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(i);
  }
  const FieldCertificate_date = [
    {
      fieldName: 'day',
      fieldValue: generateDate(
        data && data.education_fields?.certificate_date
      )?.[2],
      isRequired: false,
      placeholder: 'Hari',
      optionList: optionDay,
      option: day => {
        return day.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    },
    {
      fieldName: 'month',
      fieldValue: generateDate(
        data && data.education_fields?.certificate_date
      )?.[1],
      isRequired: false,
      placeholder: 'Bulan',
      optionList: month,
      option: month => {
        return month.map(res => {
          return {
            value: res.value,
            name: res.text
          };
        });
      }
    },
    {
      fieldName: 'year',
      fieldValue:
        data &&
        data.education_fields?.certificate_date !== 'Invalid date' &&
        data &&
        data.education_fields?.certificate_date !== null
          ? generateDate(data && data.education_fields?.certificate_date)?.[0]
          : undefined,
      isRequired: false,
      placeholder: 'Tahun',
      optionList: optionYear,
      option: year => {
        return year.map(res => {
          return {
            value: res,
            name: res
          };
        });
      }
    }
  ];

  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambahkan' : 'Ubah'} Pendidikan`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />
          {listFields.length > 0 &&
            listFields.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.disabled ? false : true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}

          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={<TitleField>Tanggal Mulai</TitleField>}
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                  width: '50%'
                }}
                extra={
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(
                      'currently_studying',
                      {}
                    )(
                      <Checkbox
                        checked={currently}
                        onClick={() => {
                          setFieldsValue({ end_date: undefined });
                          setCurrently(!currently);
                        }}
                      >
                        Saat ini saya sedang belajar disini
                      </Checkbox>
                    )}
                  </Form.Item>
                }
              >
                {getFieldDecorator('start_date', {
                  initialValue:
                    data && data.start_date
                      ? moment(data.start_date, 'YYYY/MM/DD')
                      : null,
                  rules: [
                    {
                      required: true,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Pilih tanggal"
                    format={'YYYY-MM-DD'}
                    style={{ width: '90%' }}
                    onChange={() => setFieldsValue({ end_date: undefined })}
                    disabledDate={current =>
                      !currently && isAfter(current, getFieldValue('end_date'))
                    }
                  />
                )}
              </Form.Item>
              {!currently && (
                <Form.Item
                  label={<TitleField>Tanggal Selesai</TitleField>}
                  style={{ display: 'inline-block', width: '50%' }}
                >
                  {getFieldDecorator('end_date', {
                    initialValue:
                      data && data.end_date
                        ? moment(data.end_date, 'YYYY/MM/DD')
                        : null,
                    rules: [
                      {
                        required: true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(
                    <DatePicker
                      placeholder="Pilih tanggal"
                      format={'YYYY-MM-DD'}
                      style={{ width: '90%' }}
                      disabledDate={current =>
                        isAfter(getFieldValue('start_date'), current)
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          {listFields2.length > 0 &&
            listFields2.map((res, i) => {
              return (
                <Form.Item
                  key={i}
                  style={{ padding: '0px 22px', marginBottom: 8 }}
                  label={<TitleField>{res.labelName}</TitleField>}
                  help={res.extra}
                >
                  {getFieldDecorator(res.fieldName, {
                    initialValue: res.fieldValue,
                    rules: [
                      {
                        required: res.required,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(_switchComponentToRender(res))}
                </Form.Item>
              );
            })}
          <FormDateInline
            title="Tanggal Terbit Ijazah"
            required={false}
            date={FieldCertificate_date}
            getFieldDecorator={getFieldDecorator}
          />
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
          >
            {getFieldDecorator('description', {
              initialValue: '',
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan keterangan" />)}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Attachment
              handleChangeFile={e => handleChange(e, 'upload-attachment')}
              id="upload-education"
              disabled={(attachment.length === 5 || loadingUpload) ?? false}
              multiple={true}
            />
            {loadingUpload && <span>Mengunggah</span>}
            {errorFile && (
              <span> Ada jenis lampiran yang tidak diperbolehkan</span>
            )}
            {errorSize && (
              <span>
                Ukuran lampiran yang diunggah tidak boleh melebihi 25MB
              </span>
            )}
            {errorMax && <span>Jumlah lampiran tidak boleh lebih dari 5</span>}
            {attachment.length > 5 && (
              <span>Jumlah lampiran tidak boleh lebih dari 5</span>
            )}
            {attachment &&
              attachment.length > 0 &&
              attachment.map((res, i) => (
                <AttachmentItem
                  src={res.url}
                  key={`${i}`}
                  fileName={res.name}
                  size={res.size}
                  onChange={e => handleChangeNameFile(e, i)}
                  onDelete={() => handleDeleteAttachement(i)}
                />
              ))}
          </div>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
            disabled={attachment?.length > 5}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Pendidikan"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEditEducation = Form.create({ name: 'Add_Education' })(
  AddEducationComponent
);

export default AddEditEducation;

const getWhere = (type, { id, search }) => {
  switch (type) {
    case 'DEGREE':
      return {
        where: {
          id: id ? { _eq: id } : undefined,
          name: search ? { _ilike: `%${search}%` } : undefined
        }
      };
    case 'SCHOOLS':
      return {
        where: {
          deletedAt: { _is_null: true },
          id: id ? { _eq: id } : undefined,
          types: { _ilike: '_1%' },
          legal_name: search ? { _ilike: `%${search}%` } : undefined
        }
      };
    case 'MAJOR':
      return {
        where: {
          id: id ? { _eq: id } : undefined,
          name: search ? { _ilike: `%${search}%` } : undefined,
          deletedAt: { _is_null: true }
        }
      };
    case 'FACULTY':
      return {
        where: {
          id: id ? { _eq: id } : undefined,
          name: search ? { _ilike: `%${search}%` } : undefined,
          deleted_at: { _is_null: true }
        }
      };
    default:
      return {};
  }
};
