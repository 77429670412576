import React from 'react';
import { ModalConfirmation, ModalEdit } from '../../../../Components';
import { Form, Input, message } from 'antd';
import { useMutation } from 'react-apollo';
import { UPDATE_SUMMARY } from './Summary.graphql';
import { ButtonFrom } from '../../../../SharedComponents';

const EditSummaryComponent = ({
  open,
  onClose,
  data = '',
  form,
  refetch,
  profileId,
  setOpen
}) => {
  const { getFieldDecorator, validateFields, resetFields } = form;
  const { TextArea } = Input;
  const [confirmation, setConfirmation] = React.useState({
    open: false,
    title: '',
    content: '',
    textPrimary: '',
    onCancel: null,
    onConfirm: null
  });

  const [updateSummary] = useMutation(UPDATE_SUMMARY, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) handleOpenConfirmation(values);
    });
  };

  const handleUpdateSummary = values => {
    const submit = {
      userId: profileId,
      data: {
        summary: values.summary
      }
    };
    updateSummary({
      variables: submit
    })
      .then(() => {
        refetch();
        message.success(`Update summary success`);
        onClose();
        resetFields();
      })
      .catch(err => {
        message.error(`Update summary error, ${err}`);
      });
  };

  const resetConfirmation = () => {
    setConfirmation({
      open: false,
      title: '',
      content: '',
      textPrimary: '',
      onCancel: null,
      onConfirm: null
    });
  };

  const handleOpenDiscard = () => {
    onClose();
    setConfirmation({
      open: true,
      title: 'Buang Perubahan?',
      content: 'Apakah anda yakin akan membuang perubahan?',
      textPrimary: 'Buang',
      onCancel: () => {
        resetConfirmation();
        setOpen(prev => ({ ...prev, open: true }));
      },
      onSubmit: () => {
        resetConfirmation();
        resetFields();
      }
    });
  };

  const handleOpenConfirmation = values => {
    onClose();
    setConfirmation({
      open: true,
      title: 'Lanjutkan Perubahan?',
      content: 'Apakah anda yakin akan menyimpan perubahan?',
      textPrimary: 'Lanjut',
      onCancel: () => {
        resetConfirmation();
        setOpen(prev => ({ ...prev, open: true }));
      },
      onSubmit: () => {
        resetConfirmation();
        handleUpdateSummary(values);
      }
    });
  };

  return (
    <>
      <ModalEdit
        sizeSmall
        title="Ringkasan"
        open={open}
        onClose={handleOpenDiscard}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item style={{ padding: '0px 22px', marginBottom: 8 }}>
            {getFieldDecorator('summary', {
              initialValue: data,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea style={{ width: '100%' }} rows={7} />)}
          </Form.Item>
          <ButtonFrom
            submitLabel="Simpan"
            actionType="edit"
            onCancel={handleOpenDiscard}
          />
        </Form>
      </ModalEdit>

      <ModalConfirmation
        open={confirmation.open}
        title={confirmation.title}
        content={confirmation.content}
        onCancel={confirmation.onCancel}
        onSubmit={confirmation.onSubmit}
        textPrimary={confirmation.textPrimary}
      />
    </>
  );
};

const EditSummary = Form.create({ name: 'Edit_Summary' })(EditSummaryComponent);

export default EditSummary;
