export const LIST_TYPE_IDENTITY = [
  {
    id: "KTP",
    name: "KTP",
  },
  {
    id: "SIM A",
    name: "SIM A",
  },
  {
    id: "SIM B1",
    name: "SIM B1",
  },
  {
    id: "SIM B2",
    name: "SIM B2",
  },
  {
    id: "SIM C",
    name: "SIM C",
  },
  {
    id: "SIM D",
    name: "SIM D",
  },
  {
    id: "NPWP",
    name: "NPWP",
  },
  {
    id: "PASSPORT",
    name: "PASSPORT",
  },
]