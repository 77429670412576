import React, { useState } from 'react';
import { message } from 'antd';
import 'antd/dist/antd.css';
import { CompanyGlobal } from '../../Services';
import CompanyResultComponent from './CompanyResult.component';

const CompanyResult = props => {
  const { company } = props;
  const [isFollow, setIsFollow] = useState(company.isFollowed);
  const handleFollowCompany = (company, action) => {
    CompanyGlobal.followCompanyServices(company.id, action)
      .then(res => {
        message.success(`Success ${action} ${company.brand_name}`);
        setIsFollow(!isFollow);
      })
      .catch(err => message.error('Something went wrong'));
  };
  return (
    <CompanyResultComponent
      company={company}
      handleFollowCompany={handleFollowCompany}
      isFollow={isFollow}
    />
  );
};

export default CompanyResult;
