import styled from 'styled-components';
import Assets from '../../Assets';

export const Wrapper = styled.div`
  font-family: ${Assets.FONT.MULI};

  .component_button_wrapper {
    text-align: right;

    .ant-btn.ant-btn-primary {
      margin-left: 10px;
      background-color: ${Assets.COLOR_PALLETE.dark_blue};
    }
  }
`;
