import { Form, Icon } from 'antd';
import React, { useState } from 'react';
import { TitleField } from '../GlobalStyle';
import { TagInput, WrapperItemTag } from './Shared.style';

// const removeSelected = (_myArray, _toRemove) => {
//   const myArray = [..._myArray]
//   const toRemove = [..._toRemove]
//   for (var i = myArray.length - 1; i >= 0; i--) {
//     for (var j = 0; j < toRemove.length; j++) {
//       if (myArray[i] && myArray[i].id === toRemove[j].id) {
//         myArray.splice(i, 1);
//       }
//     }
//   }
//   return myArray
// }

export const TagForm = ({
  list,
  onDelete,
  labelName,
  fieldName,
  fieldValue,
  isRequired,
  onChange,
  placeholder,
  getFieldDecorator,
  mode,
  // option = [],
  // value,
  onSearch,
  children,
  loading = false,
  onInputKeyDown,
  showArrow = true
}) => {
  // const { Option } = Select;
  const [searchText, setSearchText] = useState(null);

  // const filterOptions = removeSelected(option, list)

  const handleSearch = value => {
    setSearchText(value);
    onSearch(value);
  };

  const handleChange = (value, data) => {
    onChange(value, data);
    setSearchText(null);
  };

  return (
    <>
      <Form.Item
        style={{ padding: '0px 22px', marginBottom: 0 }}
        label={<TitleField>{labelName}</TitleField>}
      >
        {getFieldDecorator(fieldName, {
          initialValue: fieldValue,
          rules: [
            {
              required: isRequired,
              message: 'Bagian ini diperlukan'
            }
          ]
        })(
          <TagInput
            placeholderDisplay={
              searchText === null || searchText === '' ? 'block' : 'none'
            }
            mode={mode}
            style={{ width: '100%' }}
            onChange={handleChange}
            placeholder={placeholder}
            onSearch={handleSearch}
            loading={loading}
            autoClearSearchValue={true}
            filterOption={false}
            showSearch={false}
            onInputKeyDown={onInputKeyDown}
            notFoundContent={null}
            showArrow={showArrow}
          >
            {children}
            {/* {filterOptions.map((res, i) => (
              <Option data={res} key={`${i}-itm`} value={res.id}>
                {res.name}
              </Option>
            ))} */}
          </TagInput>
        )}
      </Form.Item>
      <WrapperItemTag>
        {list &&
          list.map((res, i, arr) => {
            return (
              <div key={`${i}-list`} className="item">
                <p>{res.name}</p>
                <Icon
                  type="close"
                  style={{
                    color: '#fff',
                    cursor: 'pointer',
                    fontSize: 12
                  }}
                  onClick={() => onDelete(res, i, arr)}
                />
              </div>
            );
          })}
      </WrapperItemTag>
    </>
  );
};
