import styled from 'styled-components';

const SubNav = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 1200px) {
    min-width: 1150px;
    top: 62px;
  }
  display: inline-block;
  height: 48px;
  background: #f6f8f9;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  z-index: 10;

  .main {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    .container {
    }

    .menu-subnav {
      left: 0;
      right: 0;
      position: relative;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      ul {
        margin: 0;
        padding: 0;
      }
    }
  }
`;

const StyledTabs = styled.li`
  cursor: pointer;
  display: block;
  float: left;
  padding: 13px 20px;
  font-family: 'Muli-SemiBold', sans-serif;
  font-size: 12px;
  color: #a9a8a8;
  position: relative;
  &:before {
    content: ${props => (props.isUnread ? '" "' : null)};
    position: absolute;
    top: 14px;
    right: 11px;
    width: 4px;
    height: 4px;
    background-color: #ef4d5e;
    border-radius: 50%;
  }
  &.active {
    border-bottom: 3.6px solid #039be5;
    color: #0d0d0d;
    height: 48px;
  }
`;

export { SubNav, StyledTabs };
