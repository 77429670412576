const LOCALE = 'id-ID';

// 'IDR 757,854.00' -> { currency: 'IDR', amount: 757854 }
export const parseCurrency = str => {
  // NOTE(intrnl): Some notification makes use of `\u00a0` to separate currency
  // and amount, we'll match all kinds of whitespaces instead.
  const m = /\s+/.exec(str);

  const neg = str[0] === '-';
  const sign = neg ? -1 : 1;

  const raw = (m ? str.slice(m.index + m[0].length) : str).replace(/,/g, '');

  const amount = truncateFractional(Number(raw), 2) * sign;
  let currency = m ? str.slice(0, m.index) : null;

  // workaround for wrong data
  if (currency === 'Rp') {
    currency = 'IDR';
  }

  return { currency, amount };
};

export const formatCurrency = (amount, padded = false, currency = 'IDR') => {
  if (!amount) {
    amount = 0;
  }

  const fractions = padded ? 2 : 0;
  const truncated = truncateFractional(amount, fractions);

  const formatted = truncated.toLocaleString(LOCALE, {
    style: currency ? 'currency' : 'decimal',
    currency: currency || undefined,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: fractions
  });

  return formatted.replace(/\s+/g, '');
};

export const truncateFractional = (num, digits) => {
  const pow = 10 ** digits;
  return Math.trunc(num * pow) / pow;
};

/** @deprecated use `formatCurrency` */
export default amount => {
  return formatCurrency(amount, false, 'IDR');

  // return 'Rp' + amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

/** @deprecated use `formatCurrency` */
export function ToPrice(x) {
  return formatCurrency(x, false, null);

  // if (!x) {
  //   return '0';
  // }
  // return x.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const locale = ['ban', 'id'];

/** @deprecated */
export const currencyFormatter = selectedCurrOpt => value => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: selectedCurrOpt.split('::')[1]
  }).format(value);
};

/** @deprecated */
export const currencyParser = val => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      // val = '0.0';
      val = '';
    }

    // detecting and parsing between comma and dot
    var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    var reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    //  => 1232.21 €

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21

    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > 2;

    if (needsDigitsAppended) {
      reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
    }

    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    // console.error(error);
  }
};
