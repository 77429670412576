import styled from 'styled-components';
import Assets from '../../Assets';

export const ButtonWlb = styled.span`
  button {
    padding: 10px 33px;
    font-size: 12px;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    border-radius: 5px;
    height: unset;
  }

  .ant-btn-link {
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.grey};
  }

  .ant-btn-primary {
    background-color: ${Assets.COLOR_PALLETE.dark_blue};
    border-color: ${Assets.COLOR_PALLETE.dark_blue};
    color: ${Assets.COLOR_PALLETE.white};
  }

  .ant-btn-sm {
    padding: 2px 10px;
  }

  .ant-btn-secondary {
    background-color: #eff2f4;
    border: none;
  }
  .ant-btn-default {
    background-color: white;
    border-color: ${Assets.COLOR_PALLETE.dark_blue};
    color: ${Assets.COLOR_PALLETE.dark_blue};
    border-radius: 5px;
  }

  .icon {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 10px;
    margin-top: 2px;
    &.more {
      background-image: url(${Assets.ICON.MORE});
    }
  }

  .icon {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin-top: 0;
    top: 5px;
    position: relative;
  }
`;
