import React, { useState } from 'react';
import FollowerCardComponent from './FollowerCard.component';
import FollowPageServices from '../../Services/FollowPage';
import { message } from 'antd';

const FollowerCardContainer = props => {
  const { user, setTotalUser } = props;
  const [loading, setLoading] = useState(false);
  const [isFollow, setIsFollow] = useState(user.isFollowed);

  const _buttonHandler = async (userId, name) => {
    if (!loading) {
      setLoading(true);
      if (isFollow === 1) {
        await FollowPageServices.unFolloweUser(userId)
          .then(res => {
            setIsFollow(0);
            setTotalUser(-1);
            message.success(`Success unfollow ${name}`);
          })
          .catch(() => {
            message.error(`Failed to unfollow ${name}`);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        await FollowPageServices.followUser(userId)
          .then(res => {
            setIsFollow(1);
            setTotalUser(1);
            message.success(`Success follow ${name}`);
          })
          .catch(() => {
            message.error(`Failed to follow ${name}`);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  return (
    <FollowerCardComponent
      buttonHandler={_buttonHandler}
      user={user}
      isFollow={isFollow}
      loading={loading}
    ></FollowerCardComponent>
  );
};

export default FollowerCardContainer;
