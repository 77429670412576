import { Skeleton } from 'antd';
import React from 'react';
import { useQuery } from 'react-apollo';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import { WrapperDetailSection } from '../../../../Components/DetailUserSection/DetailUserSection.style';
import WorkingStatus from '../../../../Components/DetailUserSection/WorkingStatus.compoenent';
import { GET_WORKING_STATUS } from './WorkingStatus.query';

export default function Working({ isUser, userLogin, nameUser }) {
  const { data, loading, error } = useQuery(GET_WORKING_STATUS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: userLogin
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user'
      }
    }
  });

  if (error) {
    return JSON.stringify(error);
  } else if (data === undefined || loading) {
    return (
      <WrapperDetailSection style={{ padding: 20, marginBottom: 120 }}>
        <Skeleton active />
      </WrapperDetailSection>
    );
  }

  return (
    <DetailUserSection title="Status Pekerjaan">
      <WorkingStatus
        isUser={isUser}
        dataWorkingStatus={data && data.working}
        nameUser={nameUser}
      />
    </DetailUserSection>
  );
}
