import React from 'react';
import { Col, Form, Icon, Input, Row, Select } from 'antd';
import { ButtonWlb, DummySpace, UploadFile } from '../../../Components';
import style, { StoreScreenWrap } from './StoreScreen.style';
import { isEmpty } from 'lodash';

const StoreScreenComponent = props => {
  const {
    setMenuAndCurrentSteps,
    selectionData,
    dataReg,
    setDataReg,
    setLocation,
    location,
    handleUpload,
    handleChange,
    files,
    dataUpload
  } = props;
  const { Option } = Select;
  const { getFieldDecorator } = props.form;
  const { TextArea } = Input;

  const checkSK = (rule, value, callback) => {
    if (!isEmpty(dataUpload)) {
      return callback();
    }
    callback('Please upload SK Kemenkumham');
  };

  const _handleSubmit = event => {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      const sk = { sk_kemenkumham: dataUpload };
      if (!err) {
        setDataReg(Object.assign(values, sk));
        setMenuAndCurrentSteps('Payment', 1);
      }
    });
  };

  const formItems = [
    {
      type: 'text',
      labelName: 'First Name',
      fieldName: 'first_name'
    },
    {
      type: 'text',
      labelName: 'Last Name',
      fieldName: 'last_name'
    },
    {
      type: 'text',
      labelName: 'Email',
      fieldName: 'email'
    },
    {
      type: 'text',
      labelName: 'Vendor Name',
      fieldName: 'vendor_name'
    },
    {
      type: 'selection',
      labelName: 'Vendor Category',
      fieldName: 'vendor_category',
      dependencyField: null
    },
    {
      type: 'selection',
      labelName: 'Vendor Classification',
      fieldName: 'vendor_classification',
      dependencyField: null
    },
    {
      type: 'text',
      labelName: 'PT / CV',
      fieldName: 'pt_cv'
    },
    {
      type: 'number',
      labelName: 'NPWP',
      fieldName: 'npwp'
    },
    {
      type: 'text',
      labelName: 'Business Address',
      fieldName: 'business_address'
    },
    {
      type: 'selection',
      labelName: 'Province',
      fieldName: 'province',
      dependencyField: null
    },
    {
      type: 'selection',
      labelName: 'City',
      fieldName: 'city',
      dependencyField: null
    },
    {
      type: 'number',
      labelName: 'Zip Code',
      fieldName: 'zip_code'
    }
  ];

  const renderFormItem = formItem => {
    const { fieldName, labelName } = formItem;
    return (
      <Form.Item key={formItem.fieldName} label={labelName}>
        {getFieldDecorator(fieldName, {
          rules: [
            {
              required: true,
              message: `Please input ${labelName.toLowerCase()}`
            }
          ],
          initialValue: dataReg[fieldName] ? dataReg[fieldName] : null
        })(_switchComponentToRender(formItem))}
      </Form.Item>
    );
  };

  const _switchComponentToRender = formItem => {
    const { type, dependencyField, labelName, fieldName } = formItem;

    if (type === 'selection') {
      const isDisabled =
        dependencyField === null
          ? false
          : isLocationFieldUndefined(dependencyField);
      return (
        <Select
          disabled={isDisabled}
          placeholder={`Select ${labelName}`}
          onChange={value => updateLocationField(fieldName, value)}
        >
          {_mapOptionFromMockSelection(fieldName)}
        </Select>
      );
    }

    if (type === 'text' || type === 'number') {
      return (
        <Input
          type={type}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }

    if (type === 'textArea') {
      return (
        <TextArea
          rows={4}
          onChange={event => updateLocationField(fieldName, event.target.value)}
        />
      );
    }
  };

  const _mapOptionFromMockSelection = fieldName => {
    if (fieldName === 'province') {
      return selectionData[fieldName].map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ));
    }
    if (fieldName === 'city') {
      return selectionData[fieldName].map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ));
    }
    if (fieldName === 'district') {
      return selectionData[fieldName].map(item => (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      ));
    }
    return selectionData[fieldName].map(item => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    ));
  };
  const updateLocationField = (
    fieldName,
    value,
    locationToUpdate = location
  ) => {
    let updatedLocation = { ...locationToUpdate };
    updatedLocation[fieldName] = value;
    setLocation(updatedLocation);
  };

  const isLocationFieldUndefined = fieldName => {
    return location[fieldName] === undefined;
  };

  return (
    <StoreScreenWrap>
      <Row>
        <Col span={24}>
          <div style={style.center}>
            <h2>Store Setup</h2>
          </div>
          <Form layout="vertical" onSubmit={_handleSubmit}>
            {formItems.map(item => renderFormItem(item))}
            <Form.Item label="SK Kemenkumham">
              {getFieldDecorator('sk_kemenkumham', {
                rules: [
                  {
                    // required: true,
                    // message: 'Please upload SK Kemenkumham',
                    validator: checkSK
                  }
                ]
              })(
                <UploadFile
                  acceptedFormat=".doc,.docx,.xlsx,.xls,.ppt,.txt, .jpeg, .pdf"
                  fileList={files.files}
                  target="files"
                  handleUpload={handleUpload}
                  handleChange={handleChange}
                >
                  <div className="uploadBtn">
                    <Icon type="upload" /> Add Attachment
                  </div>
                </UploadFile>
              )}
            </Form.Item>

            <div style={style.buttonContinue}>
              <ButtonWlb type="primary" htmlType="submit">
                Continue
              </ButtonWlb>
            </div>
            <DummySpace />
          </Form>
        </Col>
      </Row>
    </StoreScreenWrap>
  );
};

export default Form.create({ name: 'StoreSetupForm' })(StoreScreenComponent);
