import React, { useState, useEffect } from 'react';
import { ModalEdit, ModalDeleteWLBCheck } from '../../../../Components';
import {
  Form,
  Select,
  Input,
  Switch,
  DatePicker,
  Checkbox,
  message,
  InputNumber
} from 'antd';
import { SelectStyled, TitleField } from '../DetailScreen.style';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { ADD_PROJECT, UPDATE_PROJECT, DELETE_PROJECT } from './Project.graphql';
import {
  generateDateFormat,
  removeDuplicate,
  isAfter,
  getErrorMessage
} from '../HelperDetailScreen';
import { ButtonFrom } from '../../../../SharedComponents';

const AddEditComponent = ({
  open,
  onClose,
  form,
  refetch,
  actionType,
  data,
  people_profile,
  setOpen,
  openState,
  onSearch,
  dataQuery
}) => {
  const {
    validateFields,
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    getFieldValue
  } = form;
  const { Option } = Select;
  const { TextArea } = Input;
  const [share, setShare] = useState(data?.is_share);
  const [currently, setCurrently] = useState(data?.end_date === null);
  const [deleteState, setDeleteState] = useState({
    open: false
  });

  const [addMutation] = useMutation(ADD_PROJECT);
  const [UpdateProject] = useMutation(UPDATE_PROJECT);
  const [deleteProject] = useMutation(DELETE_PROJECT);

  const [loading] = useState({
    associate: false
  });

  useEffect(() => {
    if (data) {
      setCurrently(data?.end_date === null);
      setShare(data?.is_share);
    }
  }, [open, data, people_profile]);
  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const object = {
          is_share: share,
          project_name: values.project_name,
          start_date: generateDateFormat(values.start_date),
          end_date: generateDateFormat(values.end_date),
          is_current: currently,
          location: { project_location: values.location },
          creators: values.creators,
          position: values.associate,
          project_url: values.project_url !== null ? values.project_url : '',
          description: values.description !== null ? values.description : ''
        };
        if (actionType === 'add') {
          addMutation({
            variables: { object }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Add Project success`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Add project error, ${err}`);
            });
        } else if (actionType === 'edit') {
          UpdateProject({
            variables: {
              id: data.id,
              object: object
            }
          })
            .then(() => {
              refetch();
              setCurrently(false);
              resetFields();
              message.success(`Edit Project success`);
              onClose();
            })
            .catch(err => {
              const msg = getErrorMessage(err.message);
              message.error(msg || `Edit project error, ${err}`);
            });
        }
      }
    });
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setCurrently(false);
  };

  const handleOpenModalDelete = () => {
    setDeleteState({ open: true });
    setOpen({
      ...openState,
      open: false
    });
  };

  const handleCloseModalDelete = () => {
    setDeleteState({ open: false });
    setOpen({
      ...openState,
      open: true
    });
  };

  const _switchComponentToRender = res => {
    const { type, fieldName, option, labelName, placeholder, isLoading } = res;
    if (type === 'select') {
      return (
        <Select
          // defaultActiveFirstOption
          showSearch
          size="large"
          onSearch={e => onSearch(e, fieldName)}
          // onDropdownVisibleChange={isOpen =>
          //   handleRequestSugestion(isOpen, fieldName)
          // }
          loading={isLoading}
          style={
            fieldName === 'degree' ? { textTransform: 'capitalize' } : null
          }
          filterOption={(input, option) => {
            if (typeof option.props.children === 'string') {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
        >
          <Option value={null} disabled>
            Tambahkan {labelName}
          </Option>
          {option.length > 0 &&
            option.map((res, i) => {
              const dataTestId = `${res.id}-${res.name}`;
              return (
                <Option
                  style={
                    fieldName === 'degree'
                      ? { textTransform: 'capitalize' }
                      : null
                  }
                  key={i}
                  value={res.id}
                  data-testId={dataTestId}
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'text') {
      return (
        <Input placeholder={placeholder} style={{ width: '100%' }}></Input>
      );
    } else if (type === 'number') {
      return (
        <InputNumber
          placeholder={placeholder}
          style={{ width: '100%' }}
        ></InputNumber>
      );
    } else if (type === 'text-multiple') {
      return (
        <SelectStyled
          mode="tags"
          style={{ width: '100%' }}
          placeholder={`${placeholder}`}
        ></SelectStyled>
      );
    }
  };

  const handleDelete = () => {
    deleteProject({
      variables: {
        id: data.id
      }
    })
      .then(() => {
        refetch();
        resetFields();
        setDeleteState({ open: false });
        message.success(`Delete project success`);
        onClose();
        setCurrently(false);
      })
      .catch(err => {
        message.error(`Delete project error, ${err}`);
      });
  };
  return (
    <>
      <ModalEdit
        onClickDelete={actionType === 'edit' ? handleOpenModalDelete : null}
        sizeSmall
        title={`${actionType === 'add' ? 'Tambah' : 'Ubah'}  Projek`}
        open={open}
        onClose={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            id="is_share"
          >
            <TitleField> Bagikan dengan jaringan:</TitleField>
            <Switch
              style={{ marginLeft: '8px' }}
              // size="small"
              checked={share}
              onClick={() => setShare(!share)}
            />
          </Form.Item>
          <hr />

          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Nama Projek</TitleField>}
            id="project_name"
            // help={res.extra}
          >
            {getFieldDecorator('project_name', {
              initialValue: data?.project_name,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                labelName: 'Nama Projek',
                fieldName: 'project_name',
                type: 'text',
                fieldValue: data?.project_name,
                placeholder: 'Tambahkan nama projek',
                required: true
              })
            )}
          </Form.Item>
          <div style={{ padding: '0px 22px', marginBottom: 8 }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                id="start_date"
                label={<TitleField>Tanggal Mulai</TitleField>}
                style={{
                  display: 'inline-block',
                  marginBottom: 0,
                  width: '50%'
                }}
                extra={
                  <Form.Item style={{ marginBottom: 0 }}>
                    {getFieldDecorator(
                      'currently_studying',
                      {}
                    )(
                      <Checkbox
                        checked={currently}
                        onClick={() => {
                          setFieldsValue({ end_date: undefined });
                          setCurrently(!currently);
                        }}
                      >
                        <TitleField>
                          Saat ini saya sedang bekerja dalam projek ini
                        </TitleField>
                      </Checkbox>
                    )}
                  </Form.Item>
                }
              >
                {getFieldDecorator('start_date', {
                  initialValue:
                    data && data.start_date
                      ? moment(data.start_date, 'YYYY/MM/DD')
                      : moment(new Date(), 'YYYY/MM/DD'),
                  rules: [
                    {
                      required: true,
                      message: 'Bagian ini diperlukan'
                    }
                  ]
                })(
                  <DatePicker
                    placeholder="Pilih tanggal"
                    format={'YYYY-MM-DD'}
                    style={{ width: '90%' }}
                    onChange={() => setFieldsValue({ end_date: undefined })}
                    disabledDate={current =>
                      !currently && isAfter(current, getFieldValue('end_date'))
                    }
                  />
                )}
              </Form.Item>
              {!currently && (
                <Form.Item
                  id="end_date"
                  label={<TitleField>Tanggal Selesai</TitleField>}
                  style={{ display: 'inline-block', width: '50%' }}
                >
                  {getFieldDecorator('end_date', {
                    initialValue:
                      data && data.end_date
                        ? moment(data.end_date, 'YYYY/MM/DD')
                        : moment(new Date(), 'YYYY/MM/DD'),
                    rules: [
                      {
                        required: true,
                        message: 'Bagian ini diperlukan'
                      }
                    ]
                  })(
                    <DatePicker
                      placeholder="Pilih tanggal"
                      format={'YYYY-MM-DD'}
                      style={{ width: '90%' }}
                      disabledDate={current =>
                        isAfter(getFieldValue('start_date'), current)
                      }
                    />
                  )}
                </Form.Item>
              )}
            </Form.Item>
          </div>
          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Lokasi Projek</TitleField>}
            id="project_location"
            // help={res.extra}
          >
            {getFieldDecorator('location', {
              initialValue: data && data?.location?.project_location,
              rules: [
                {
                  required: true,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                labelName: 'Lokasi Projek',
                fieldName: 'location',
                type: 'text',
                fieldValue: data && data?.location,
                placeholder: 'Tambahkan lokasi projek',
                required: true
              })
            )}
          </Form.Item>
          <Form.Item
            id="project_creator"
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Kreator Projek</TitleField>}
            // help={res.extra}
          >
            {getFieldDecorator('creators', {
              initialValue: data?.creators ?? [],
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                fieldName: 'creators',
                type: 'text-multiple',
                placeholder: 'i Tambahkan kreator projek',
                fieldValue: data?.creators ?? []
              })
            )}
          </Form.Item>

          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Kerja Sama</TitleField>}
            id="associate_with"
            // help={res.extra}
          >
            {getFieldDecorator('associate', {
              initialValue: data?.position ?? null,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                fieldName: 'associate',
                type: 'select',
                fieldValue: data?.position ?? null,
                labelName: 'Kerja Sama',
                option: removeDuplicate([...(dataQuery?.associate ?? [])]),
                isLoading: loading.associate
              })
            )}
          </Form.Item>

          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Tautan Projek</TitleField>}
            id="project_url"
            // help={res.extra}
          >
            {getFieldDecorator('project_url', {
              initialValue: data && data?.project_url,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(
              _switchComponentToRender({
                labelName: 'Tautan Projek',
                fieldName: 'project_url',
                type: 'text',
                fieldValue: data && data?.project_url,
                placeholder: 'Tambahkan tautan projek',
                required: false
              })
            )}
          </Form.Item>

          <Form.Item
            style={{ padding: '0px 22px', marginBottom: 8 }}
            label={<TitleField>Keterangan</TitleField>}
            id="description"
          >
            {getFieldDecorator('description', {
              initialValue: data && data?.description,
              rules: [
                {
                  required: false,
                  message: 'Bagian ini diperlukan'
                }
              ]
            })(<TextArea rows={4} placeholder="Tambahkan keterangan" />)}
          </Form.Item>

          <ButtonFrom
            submitLabel="Simpan"
            actionType={actionType}
            onCancel={handleClose}
          />
        </Form>
      </ModalEdit>
      <ModalDeleteWLBCheck
        centered
        open={deleteState.open}
        target="Projek"
        onClose={handleCloseModalDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

const AddEdit = Form.create({ name: 'Add_Project' })(AddEditComponent);

export default AddEdit;
