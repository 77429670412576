import gql from "graphql-tag";

export const GET_LIST_ANOTHER_CONTACT = gql`
  query($userId: uuid!) {
    social_contacts: people_profile_social_contacts(
      where: {
        _and: [{ user_id: { _eq: $userId } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [{ is_main: desc_nulls_last }, { id: desc }]
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      user_id
      url
      is_main
      is_visible
    }
  }
`;
export const GET_LIST_SOCIALS = gql`
  {
    global_social_media {
      id
      name
    }
  }
`;
export const ADD_LIST_SOCIALS = gql`
  mutation($objects: [people_profile_social_contacts_insert_input!]!) {
    insert_people_profile_social_contacts(objects: $objects) {
      affected_rows
    }
  }
`;
export const EDIT_LIST_SOCIALS = gql`
  mutation($id: Int!, $objects: people_profile_social_contacts_set_input) {
    update_people_profile_social_contacts_by_pk(
      pk_columns: { id: $id }
      _set: $objects
    ) {
      id
      global_social_medium {
        id
        name
      }
      name
      url
      is_main
      is_visible
    }
  }
`;
export const DELETE_LIST_SOCIALS = gql`
  mutation($id: Int!) {
    update_people_profile_social_contacts(
      _set: { deletedAt: "now()" }
      where: { _and: [{ id: { _eq: $id } }, { deletedAt: { _is_null: true } }] }
    ) {
      affected_rows
    }
  }
`;
export const SET_VISIBILITY_LIST_SOCIALS = gql`
  mutation($id: Int!, $is_visible: Boolean!) {
    update_people_profile_social_contacts(
      _set: { is_visible: $is_visible }
      where: { _and: [{ id: { _eq: $id } }] }
    ) {
      affected_rows
    }
  }
`;
export const SET_MAIN_CONTACT = gql`
  mutation($id: Int!, $user_id: uuid!, $sosmedId: Int!) {
    a: update_people_profile_social_contacts(
      _set: { is_main: false }
      where: { user_id: { _eq: $user_id }, social_media_id: {_eq: $sosmedId} }
    ) {
      affected_rows
    }

    b: update_people_profile_social_contacts(
      _set: { is_main: true }
      where: { _and: [{ id: { _eq: $id } }, { user_id: { _eq: $user_id } }] }
    ) {
      affected_rows
    }
  }
`;
export const SET_UNMAINMAIN_CONTACT = gql`
  mutation($user_id: uuid!, $sosmedId: Int!) {
    update_people_profile_social_contacts(
      _set: { is_main: false }
      where: { user_id: { _eq: $user_id }, social_media_id: {_eq: $sosmedId} }
    ) {
      affected_rows
    }
  }
`;
