import React from 'react';
import Assets from '../../Assets';

const starStyle = {
  width: '33.4px',
  height: '31.8px'
};

const RatingStar = props => {
  const {
    index,
    rating,
    hoverRating,
    onMouseEnter,
    onMouseLeave,
    onSaveRating
  } = props;

  const fill = React.useMemo(() => {
    if (hoverRating >= index) {
      return <img src={Assets.ICON.STAR_FILLED} style={starStyle} />;
    } else if (!hoverRating && rating >= index) {
      return <img src={Assets.ICON.STAR_FILLED} style={starStyle} />;
    }
    return <img src={Assets.ICON.STAR_FILLED_GREY} style={starStyle} />;
  }, [rating, hoverRating, index]);

  return (
    <div
      style={{ cursor: 'pointer' }}
      onMouseEnter={() => onMouseEnter(index)}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => onSaveRating(index)}
    >
      {fill}
    </div>
  );
};

export default RatingStar;
