import { gql } from 'apollo-boost';

export const GET_CLASSROOM_CATEGORIES = gql`
  query($search: String, $limit: Int, $nin: [Int!]) {
    academy_course_categories(
      where: {
        id: { _nin: $nin }
        parent: { _is_null: false }
        _or: [
          { name: { _ilike: $search } }
          { academy_course_category: { name: { _ilike: $search } } }
        ]
      }
      limit: $limit
      order_by: [{ parent: asc_nulls_first }]
    ) {
      id
      name
      academy_course_category {
        id
        name
      }
    }
    total: academy_course_categories_aggregate(
      where: {
        parent: { _is_null: false }
        _or: [
          { name: { _ilike: $search } }
          { academy_course_category: { name: { _ilike: $search } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
