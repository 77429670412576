import { Tooltip } from "antd";
import React from "react";
import { TooltipInfo } from "./Attachment.style";
import './style.css'

const Info = () => {
  return (
    <TooltipInfo>
      <p>Appropritae file extension:</p>
      <ul>
        <li>Document (<span>doc, docx, pdf, xls, xlsx, ppt</span>)</li>
        <li>Image (<span>jpg, jpeg, png, tiff, pjp, pjpeg</span>)</li>
      </ul>
      <p>Max size: <span>25 MB</span></p>
    </TooltipInfo>
  );
};

export const TooltipAttachment = ({ children }) => {
  return (
    <Tooltip
      color={"#eff2f4"}
      placement="right"
      title={<Info />}
      overlayClassname="tooltip-attachment-informmation"
    >
      {children}
    </Tooltip>
  );
};
