import {
  BankAccountCard,
  BankAccountListHeader
} from './BankAccountListCard.style';
import React from 'react';
import { displayLoading } from '../../Utils/CommonUtils';
import { isEmpty } from 'lodash';
import BankAccountFormContainer from '../../Containers/BankAccountForm';
import { BankAccountCardComponent } from '../index';
import { ButtonWlb, TopSettingGroup } from '..';
import { BankAccountListWrapper } from './BankAccountListCard.style';

const BankAccountListCardComponent = props => {
  const {
    showConfirmationDeleteModal,
    handleAddModalVisibility,
    handleDeleteBankAccount,
    handleEditBankAccount,
    bankAccounts,
    loading
  } = props;

  const _renderHeader = () => (
    <BankAccountListHeader>
      <TopSettingGroup
        title="Bank Account"
        buttonTop={
          <ButtonWlb
            // id="add-bank-account-btn"
            onClick={() => handleAddModalVisibility(true)}
            type="primary"
            // size="small"
          >
            Add New Bank
          </ButtonWlb>
        }
      />
    </BankAccountListHeader>
  );

  const _renderBankAccountList = () => {
    return bankAccounts.map(account => (
      <BankAccountCardComponent
        handleEditBankAccount={handleEditBankAccount}
        key={account.id}
        bankAccount={account}
        handleDeleteBankAccount={handleDeleteBankAccount}
      />
    ));
  };

  const _renderEmptyMessage = () => {
    return (
      <BankAccountCard>
        <span id={'message'}>
          Oops, Currently there is no bank Account registered
        </span>
      </BankAccountCard>
    );
  };

  const _renderContent = () => {
    if (isEmpty(bankAccounts)) {
      return _renderEmptyMessage();
    }
    return _renderBankAccountList();
  };

  return (
    <BankAccountListWrapper>
      {_renderHeader()}
      {loading ? displayLoading(true) : _renderContent()}
      <BankAccountFormContainer {...props} />
      {showConfirmationDeleteModal()}
    </BankAccountListWrapper>
  );
};

export default BankAccountListCardComponent;
