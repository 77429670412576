import React from 'react';
import { Col, Form, Input, Select, Avatar } from 'antd';
import { CreateGroupFormWrap } from './CreateGroupForm.style';
import { UploadImage } from '../../Containers';
import Assets from '../../Assets';
import { ButtonWlb } from '..';

const CreateGroupFormComponent = props => {
  const {
    handleAttributeChange,
    getFieldDecorator,
    group,
    handleUpload,
    handleChangeAvatar,
    initData,
    title,
    displayUploadImage,
    handleDisplayChangeImage
  } = props;
  const { Option } = Select;
  const { TextArea } = Input;

  const _uploadImage = () => (
    <UploadImage
      acceptedFormat=".png,.jgp,.jpeg"
      listType="picture-card"
      fileList={initData.images}
      target="images"
      handleUpload={handleUpload}
      handleChange={handleChangeAvatar}
    />
  );

  const _avatarForm = () => (
    <Form.Item label="Logo Tim Kerja">
      {getFieldDecorator(
        'Group Avatar',
        {}
      )(
        <>
          <div className={`display-avatar`} hidden={displayUploadImage}>
            <Avatar
              shape="square"
              size="large"
              icon="user"
              src={group?.avatar ?? Assets.IMAGE.DEFAULT_IMAGE}
            />
            <ButtonWlb
              key="1"
              htmlType="button"
              onClick={() => handleDisplayChangeImage(true)}
            >
              Ganti Gambar
            </ButtonWlb>
          </div>
          <div hidden={!displayUploadImage}>
            {_uploadImage()}
            <ButtonWlb
              key="1"
              htmlType="button"
              onClick={() => handleDisplayChangeImage(false)}
            >
              Cancel
            </ButtonWlb>
          </div>
        </>
      )}
    </Form.Item>
  );
  return (
    <CreateGroupFormWrap>
      <Col span={12}>
        <Form layout="vertical" onSubmit={null}>
          {title === 'Pengaturan Tim Kerja' && _avatarForm()}
          <Form.Item label="Nama Tim Kerja">
            {getFieldDecorator('Group Name', {
              rules: [
                { required: true, message: 'Silahkan tambahkan nama tim kerja' }
              ],
              initialValue: group.name && group.name
            })(
              <Input
                // value={group['name']}
                placeholder={'Nama Tim Kerja'}
                onChange={event =>
                  handleAttributeChange('name', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Pengaturan Privasi" className="privacy-form">
            {getFieldDecorator('privacy', {
              rules: [
                {
                  required: true,
                  message: 'Silahkan pilih privasi tim kerja anda'
                }
              ],
              initialValue: group?.privacy ?? undefined
            })(
              <Select
                placeholder={'Silahkan pilih privasi tim kerja anda'}
                // value={group['privacy']}
                onChange={value => handleAttributeChange('privacy', value)}
              >
                <Option value={2}>Tertutup</Option>
                <Option value={1}>Pribadi</Option>
                <Option value={0}>Publik</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Deskripsi">
            {getFieldDecorator('Group Description', {
              rules: [
                {
                  required: true,
                  message: 'Silahkan tambahkan deskripsi tim kerja anda'
                }
              ],
              initialValue: group.description && group.description
            })(
              <TextArea
                rows={4}
                // value={group['description']}
                placeholder={'Silahkan tambahkan deskripsi tim kerja anda'}
                onChange={event =>
                  handleAttributeChange('description', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Aturan Tim Kerja">
            {getFieldDecorator('Group Rules', {
              rules: [
                {
                  required: true,
                  message: 'Silahkan tambahkan aturan tim kerja anda'
                }
              ],
              initialValue: group.rules && group.rules
            })(
              <TextArea
                rows={4}
                // value={group['rules']}
                placeholder={'Silahkan tambahkan aturan tim kerja anda'}
                onChange={event =>
                  handleAttributeChange('rules', event.target.value)
                }
              />
            )}
          </Form.Item>
        </Form>
      </Col>
    </CreateGroupFormWrap>
  );
};

export default CreateGroupFormComponent;
