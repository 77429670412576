import React, { useState, useEffect } from 'react';
import SidebarClassroomInfo from './SidebarClassroomInfo.component';
import { useQuery, useMutation } from 'react-apollo';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { GET_CLASSROOM_BY_ID } from './getClassroomById.graphql';
import {
  JOIN_CLASSROOM,
  CANCEL_JOIN_CLASSROOM,
  PAY_CLASSROOM
} from './joinClassroom.graphql';

const SidebarClassroomInfoBoxContainer = props => {
  const { isJoined, isRequest, setIsRequest } = props;
  const vendorUrl = process.env.REACT_APP_HC_VENDOR;
  const history = useHistory();

  const [classroomInfo, setClassroomInfo] = useState({});
  const params = useParams();
  const classroomId = params.id;

  const { data: dataClassroom } = useQuery(GET_CLASSROOM_BY_ID, {
    variables: { classroomId },
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [joinClassroom] = useMutation(JOIN_CLASSROOM);
  const [cancelJoinClassroom] = useMutation(CANCEL_JOIN_CLASSROOM);
  const [payClassroom, { loading: paymentIsLoading }] = useMutation(
    PAY_CLASSROOM
  );

  useEffect(() => {
    if (dataClassroom) {
      setClassroomInfo(dataClassroom.classrooms_by_pk);
    }
    // eslint-disable-next-line
  }, [dataClassroom]);

  const [textWrapRules, setTextWrapRules] = useState(false);
  const [textWrapDescription, setTextWrapDescription] = useState(false);

  const handleShowRules = () => setTextWrapRules(!textWrapRules);

  const handleShowDescription = () =>
    setTextWrapDescription(!textWrapDescription);

  const requestJoin = () => {
    joinClassroom({ variables: { classroomId } })
      .then(() => {
        message.success('Request has been sent to group learning admin.');
        setIsRequest(true);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const checkoutClassroom = () => {
    const variables = {
      objects: [
        {
          item_id: classroomInfo.id,
          item_object: {
            name: classroomInfo.name,
            image: classroomInfo.avatar_url,
            price: classroomInfo.price,
            weight: 0,
            note: ''
          },
          item_table: 'classrooms',
          item_vendor: classroomInfo.global_user.name,
          item_vendor_id: classroomInfo.global_user.id,
          item_vendor_table: 'global_users',
          item_quantity: 1
        }
      ]
    };

    payClassroom({ variables })
      .then(() => {
        window.location.assign(`${vendorUrl}/profile/shopping-cart`);
      })
      .catch(error => {
        message.error(error.message);
      });
  };

  const cancelRequestJoin = () => {
    cancelJoinClassroom({ variables: { classroomId } })
      .then(() => {
        setIsRequest(false);
        message.success('Your request has been canceled');
      })
      .catch(error => {
        message.error(error.message);
      });
  };
  return (
    <SidebarClassroomInfo
      dataClassroom={classroomInfo}
      handleShowRules={handleShowRules}
      handleShowDescription={handleShowDescription}
      textWrapRules={textWrapRules}
      textWrapDescription={textWrapDescription}
      isAbout={!isJoined}
      isRequest={isRequest}
      checkoutClassroom={checkoutClassroom}
      paymentIsLoading={paymentIsLoading}
      requestJoin={requestJoin}
      cancelRequestJoin={cancelRequestJoin}
      {...props}
    />
  );
};

export default SidebarClassroomInfoBoxContainer;
