import React from 'react';
import { ErrorPageWrapper } from '../ErrorPage.style';
import Assets from '../../../Assets/images';

const MaintenancePageComponent = () => {
  return (
    <ErrorPageWrapper>
      <div
        // className="error"
        style={{ overflow: 'hidden' }}
      >
        {/* <div className="test-column"> */}
        <img
          src={Assets.ERROR_IMAGE.MAINTENANCE}
          alt="Maintenance"
          style={{ width: '100vw' }}
        ></img>
        {/* </div> */}
        {/* <div className="column-2">
          <h1>System is under maintenance</h1>
          <p className="description">
            Sorry for the convenience but we&apos;re performing some maintenance
            at the moment. If you need some help, you can always contact us on{' '}
            <br />
            <a className="link">wlb.co.id</a>
          </p>
        </div> */}
      </div>
    </ErrorPageWrapper>
  );
};

export default MaintenancePageComponent;
