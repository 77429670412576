import Constants from '../../Config/baseUrl';
import { AuthenticationService } from '..';
import BaseService from '../BaseService';
import { isObjectEmpty } from '../../Utils/CommonUtils';

const followCompanyServices = (companyId, action) => {
  // action must be 'follow' or 'unfollow'
  const userId = AuthenticationService.getUserId();
  const body = {
    companyId: companyId
  };
  const url = Constants.COMPANY_FOLLOW(userId, companyId, action);
  return BaseService.post(url, body);
};

const getListFollowerCompany = (companyId, page = 1) => {
  let body = {
    params: {
      maxResults: 3,
      page: page
    }
  };
  const userId = AuthenticationService.getUserId();
  const url = Constants.COMPANY_FOLLOWER(userId, companyId);
  return BaseService.get(url, body);
};

const getListCompany = (name = '', page = 1) => {
  let body = {
    params: {
      brand_name: name,
      maxResults: 20,
      page: page
    }
  };

  if (isObjectEmpty(name)) {
    delete body.params.brand_name;
  }

  const userId = AuthenticationService.getUserId();
  const url = Constants.COMPANY(userId);
  return BaseService.get(url, body);
};

const getCompanyById = companyId => {
  const userId = AuthenticationService.getUserId();
  return BaseService.get(Constants.COMPANY_BY_ID(userId, companyId));
};

export default {
  followCompanyServices,
  getListFollowerCompany,
  getListCompany,
  getCompanyById
};
