import React from 'react';
import Component from './VendorBanner.component';
import {
  GET_COMPANY_PROFILE,
  GET_AVG_RATING,
  GET_PRODUCT_SOLD
} from './graphql';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

const Container = () => {
  const params = useParams();
  const { data, error } = useQuery(GET_COMPANY_PROFILE, {
    variables: { companyId: params.id },
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
  });

  const { data: ratingData } = useQuery(GET_AVG_RATING, {
    variables: { vendor: params.id, star: 5 }
  });
  const { data: productSold } = useQuery(GET_PRODUCT_SOLD, {
    variables: { vendor: params.id },
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } }
  });
  let total = 0;
  let n = 0;
  if (ratingData) {
    for (let i = 1; i <= 5; i++) {
      total += i * ratingData?.[`star${i}`]?.aggregate?.count;
      n += ratingData?.[`star${i}`]?.aggregate?.count;
    }
  }
  // const rating =
  //   ratingData?.star1?.aggregate?.count * 1 +
  //   ratingData?.star2?.aggregate?.count * 2 +
  //   ratingData?.star3?.aggregate?.count * 3 +
  //   ratingData?.star4?.aggregate?.count * 4 +
  //   ratingData?.star?.aggregate?.count * 5;

  if (error) return <div />;
  if (data)
    return (
      <Component
        vendor={data?.company_profiles?.[0]}
        rating={(total * 1.0) / n}
        productSold={productSold}
      />
    );
  return <div />;
};

export default Container;
