import React, { useState } from 'react';
import PostListComponent from './PostList.component';
import { CompanyTimeline, AuthenticationService } from '../../Services';
import { checkNextPage } from '../../Utils/CommonUtils';
import { message } from 'antd';
import { useAsyncEffect } from 'use-async-effect';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import {
  useBlockMe,
  useInfiniteScroll
} from '../../SharedComponents/CustomHook';

const PostListCompanyTimeline = props => {
  const [dataPost, setDataPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('company-timeline');
  const [isFetching, setIsFetching] = useState(false);
  const [blockedMe, blockLoaded] = useBlockMe();
  const companyId = props.match.params.id;
  const userData = AuthenticationService.getUserData();
  const isAdmin =
    userData.roles.includes('organization-administrator') &&
    userData.company_id === companyId;

  const GET_COMPANY = gql`
    query getCompany($id: uuid!) {
      main_profile: company_profiles(where: { id: { _eq: $id } }) {
        id
        legal_name
        brand_name
        logo
      }
    }
  `;
  const { data } = useQuery(GET_COMPANY, {
    variables: {
      id: companyId
    }
  });
  useAsyncEffect(
    async isMount => {
      if (
        blockLoaded &&
        hasNextPage &&
        !isFetching &&
        (isBottom || page === 1)
      ) {
        setIsFetching(true);
        await CompanyTimeline.fetchCompanyTimelineList(page, companyId)
          .then(response => {
            if (!isMount()) return;
            const { per_page, total, data } = response;
            const isNextPage = checkNextPage(total, page, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }
            let filteredPost = data.filter(
              item => !blockedMe?.includes(item.userId)
            );
            setDataPost(dataPost.concat(filteredPost));
          })
          .catch(error => {})
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
            setIsBottom(false);
          });
      }
    },
    [
      dataPost,
      hasNextPage,
      isBottom,
      isFetching,
      page,
      setIsBottom,
      blockLoaded
    ]
  );

  function restartPage() {
    setDataPost([]);
    setHasNextPage(true);
    setPage(1);
  }

  function deletePost(itemPost) {
    CompanyTimeline.deletePost(companyId, itemPost.id)
      .then(res => {
        if (res.status === 200) {
          let newDataPost = dataPost.filter(p => p.id !== itemPost.id);
          setDataPost(newDataPost);
          message.success('Post has been deleted');
        }
      })
      .catch(err => message.error('Something went wrong'));
  }
  return (
    <PostListComponent
      data={dataPost}
      isFetching={isFetching}
      deletePost={deletePost}
      restartPage={restartPage}
      isAdmin={isAdmin}
      dataCompany={data ? data : {}}
      {...props}
    />
  );
};
export default PostListCompanyTimeline;
