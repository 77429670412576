import { gql } from "apollo-boost";

const GET_LIST_EMPLOYEES = gql`
  query($company: String!, $search: String, $offset: Int, $limit: Int) {
    getEmployeeInCompany(
      company: $company
      search: $search
      offset: $offset
      limit: $limit
    ) {
      total
      employee {
        id
        name
        avatar
        position
        is_followed
      }
    }
  }
`;

export { GET_LIST_EMPLOYEES };
