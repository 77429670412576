import React, { useState } from 'react';
import PeopleScreenComponent from './PeopleScreen.component';
import { message } from 'antd';
import { SearchService } from '../../../Services';
import queryString from 'query-string';
import { SearchNotFound, DummySpace } from '../../../Components';
import { useAsyncEffect } from 'use-async-effect';
import { checkNextPage } from '../../../Utils/CommonUtils';
import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';
import { useBlockMe } from '../../../SharedComponents/CustomHook';

const PeopleScreenContainer = props => {
  const queryWord = queryString.parse(props.location.search).keyword;
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('search-people');
  const [blockedMe, blockLoaded] = useBlockMe();

  useAsyncEffect(
    async isMount => {
      if (
        hasNextPage &&
        blockLoaded &&
        queryWord &&
        !isLoading &&
        (isBottom || page === 1)
      ) {
        setIsLoading(true);
        SearchService.getUser(queryWord, page)
          .then(res => {
            if (!isMount()) return;
            const nextPage = checkNextPage(res.total, page, res.per_page);
            setHasNextPage(nextPage);
            if (nextPage) {
              setPage(page + 1);
            }
            const filteredUser = res.data.filter(
              item => !blockedMe?.includes(item.id)
            );
            setUsers(users.concat(filteredUser));
          })
          .catch(err => {
            message.error('Something Went Wrong');
          })
          .finally(() => {
            if (!isMount()) return;
            setIsLoading(false);
            setIsBottom(false);
          });
      }
    },
    [
      queryWord,
      page,
      hasNextPage,
      isLoading,
      isBottom,
      setIsBottom,
      users,
      blockLoaded
    ]
  );

  return (
    <div id="search-people">
      {users.length === 0 && !isLoading ? (
        <SearchNotFound
          title={`We could not find  "${queryWord}"`}
          desc={
            'Nothing came up for that search. Try shortening or rephrasing your search.'
          }
        />
      ) : (
        <PeopleScreenComponent
          {...props}
          peopleList={users}
          isLoading={isLoading}
        />
      )}
      <DummySpace />
    </div>
  );
};

export default PeopleScreenContainer;
