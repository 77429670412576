import React, { useState } from 'react';
import { Avatar, Card, Icon } from 'antd';
import {
  // Company,
  Description,
  JobDetails,
  UserCard
} from './BlockCard.style';
import { DeleteModal } from '../../../Components';
// import Assets from '../../../Assets';

const BlockCard = props => {
  const [showModal, setShowModal] = useState(false);
  const { filterUsers, user } = props;
  const { name, avatar } = user.user;
  const peoplePosition =
    user.user?.people_work_placements?.[0]?.company_employee_position?.name ??
    '';
  const peopleCompany =
    user.user?.people_work_placements?.[0]?.company_profile?.brand_name ?? '-';
  return (
    <UserCard>
      <Icon
        type="close"
        className="closed-icon"
        onClick={() => setShowModal(true)}
      />
      <Card className={'user-card'}>
        <div className={'user-card-meta'}>
          <Card.Meta
            avatar={<Avatar src={avatar} size={60} />}
            title={name}
            description={
              <Description>
                <JobDetails>{peoplePosition}</JobDetails>
                <JobDetails>{peopleCompany}</JobDetails>
              </Description>
            }
          />
        </div>
      </Card>
      {/* <div className={'social-media-wrapper'}>
        <Icon className={'social-media'} type={'facebook'} />
        <Icon type={'instagram'} />
        <Icon type={'twitter'} />
        <Icon type={'mail'} />
      </div> */}
      <DeleteModal
        modalTitle={`Unblocked ${name}`}
        modalContent={`Are you sure want to unblock ${name}?`}
        displayModal={showModal}
        closeModal={() => setShowModal(false)}
        wording={'Unblock'}
        handleDelete={() => filterUsers(user.blocked_user_id)}
      />
    </UserCard>
  );
};

export default BlockCard;
