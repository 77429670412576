import styled from 'styled-components';
import Assets from '../../Assets';

const CommentListWrapper = styled.div`
  .author {
    width: 100%;
    display: flex;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }
  .content {
    margin-left: 45px;
    border-bottom: 1px solid ${Assets.COLOR_PALLETE.grey};
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.black2};
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 15px;
    -ms-word-break: break-all;
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
  }
  .comment-like {
    float: right;
    &:hover {
      cursor: pointer;
    }
    .icon {
      height: 18px;
      width: 18px;
    }
  }
  .total-like-comment {
    color: ${Assets.COLOR_PALLETE.grey};
    margin-bottom: 8px;
  }
`;

const MoreComment = styled.div`
  cursor: pointer;
  font-family: ${Assets.FONT.MULI};
  font-size: 11px;
  margin-bottom: 15px;
  color: ${Assets.COLOR_PALLETE.blue};
`;

const DeletedComment = styled.span`
  display: inline-block;
  border-radius: 5px;
  background-color: #a9a8a8;
  padding: 5px 10px;
  padding-right: 12px;
  color: white;
  margin-bottom: 10px;
  img {
    vertical-align: bottom;
  }
`;

export { CommentListWrapper, MoreComment, DeletedComment };
