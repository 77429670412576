import React, { memo } from 'react';
import { ButtonFullWlb, ModalEdit } from '../../../../Components';
import { WrapperScroll } from './Experience.style';
import ExperienceItem from './ExperienceItem';

export default memo(function MoreExperience({
  open,
  nameUser,
  setOpen,
  isUser,
  data
}) {
  const handleEditItem = data => {
    setOpen(e => ({
      ...e,
      open: true,
      openMore: false,
      fromList: true,
      actionType: 'edit',
      data
    }));
  };

  return (
    <ModalEdit
      centered
      padding="0px"
      title={`Pengalaman ${nameUser.split(' ')[0]}`}
      open={open}
      onClose={() => setOpen(e => ({ ...e, openMore: false, fromList: false }))}
    >
      <WrapperScroll>
        {data &&
          data.map((res, i) => {
            const { country_code = '', phone = '' } =
              res.dataEdit?.additional_fields?.referal_contact_phone || {};
            return (
              <ExperienceItem
                key={i}
                avatar={res.avatar}
                isUser={isUser}
                title={res.title}
                company={res.company}
                date={res.time}
                location={res.location}
                description={res.description}
                onClickEdit={() => handleEditItem(res.dataEdit)}
                company_line_of_business={
                  res.dataEdit?.company_line_of_business
                }
                achievements={res?.dataEdit?.achievements}
                refName={res.dataEdit.referal_name}
                refPosition={res.dataEdit.referal_position}
                refPhone={`${country_code + phone || '-'}`}
              />
            );
          })}
      </WrapperScroll>
      {isUser && (
        <ButtonFullWlb
          label="Tambahkan Pengalaman"
          onClick={() =>
            setOpen(e => ({
              ...e,
              openMore: false,
              open: true,
              data: null,
              actionType: 'add',
              fromList: true
            }))
          }
        />
      )}
    </ModalEdit>
  );
});
