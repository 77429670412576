import styled from 'styled-components';
import Assets from '../../../Assets';

export const Box = styled.div`
  padding: 0 16px;
  background: ${Assets.COLOR_PALLETE.white};
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .content {
    padding: 3.9% 0;
  }

  p {
    font-size: 11px;
    color: ${Assets.COLOR_PALLETE.grey};
  }
`;

export default {
  top16: {
    marginTop: '16px'
  }
};
