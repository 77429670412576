import { Avatar, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { AuthenticationService, PostService } from '../../Services';
import { ShowLikeWrapper } from './ModalShowLike.style';
import { Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';

function ModalShowLike(props) {
  const { postType, open, handleClose, userId, postId, match } = props;
  const [likeData, setLikeData] = useState(null);

  useEffect(() => {
    const _fetchPostLike = async () => {
      const endpoint =
        postType === 'company' || postType === 'companyTimeline'
          ? PostService.getCompanyPostLike(
              userId,
              postId,
              AuthenticationService.getCompanyId()
            )
          : postType === 'team'
          ? PostService.getGroupPostLike(userId, match.params.id, postId)
          : PostService.getGlobalPostLike(userId, postId);

      await endpoint
        .then(d => {
          setLikeData(
            d.data.map(res => ({
              name: res?.user?.name,
              avatar: res?.user?.avatar,
              pos: res?.user?.people_work_placements[0]
            }))
          );
        })
        .catch(() => {
          message.error('Something went wrong');
        });
    };

    if (open) {
      _fetchPostLike();
    }
  }, [userId, postId, open, postType]);

  return (
    <ShowLikeWrapper
      title="Likes"
      visible={open}
      footer={false}
      onCancel={handleClose}
    >
      {likeData ? (
        likeData.map((res, i) => {
          return (
            <div className="user-like" key={i}>
              <Avatar size={45} src={res.avatar} />
              <div className="info">
                <p className="name">{res.name}</p>
                <p className="pos">
                  {res.pos?.company_employee_position?.name} at{' '}
                  {res.pos?.company_profile?.brand_name}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <Skeleton avatar paragraph={{ rows: 1 }} />
          <Skeleton avatar paragraph={{ rows: 1 }} />
        </>
      )}
    </ShowLikeWrapper>
  );
}

export default withRouter(ModalShowLike);
