import React from 'react';
import { Menu } from 'antd';

import Constants from '../../../Config/Constants';
import style, { SideBarWrapper } from './SideBarVendor.style';

const { SubMenu } = Menu;
const {
  SIDEBAR_VENDOR: {
    ORDER,
    PRODUCT,
    INFORMATION,
    SHOWCASE,
    LOCATION,
    SHIPPING,
    BANK_ACCOUNT,
    BALANCE
  }
} = Constants;

const SidebarVendorComponent = ({
  setActiveMenu,
  activeMenu,
  setOrderDetail
}) => {
  const _renderHeader = () => <h3 className="header">Menu</h3>;
  const _renderMenuItem = value => <Menu.Item key={value}>{value}</Menu.Item>;

  const _renderMenu = () => (
    <Menu
      onClick={({ key }) => {
        setActiveMenu(key);
        setOrderDetail(false);
      }}
      defaultSelectedKeys={[activeMenu]}
      mode="inline"
      className="menu"
    >
      {_renderMenuItem(ORDER)}
      {_renderMenuItem(PRODUCT)}
      <SubMenu title="Settings">
        {_renderMenuItem(INFORMATION)}
        {_renderMenuItem(SHOWCASE)}
        {_renderMenuItem(LOCATION)}
        {_renderMenuItem(SHIPPING)}
        {_renderMenuItem(BANK_ACCOUNT)}
        {_renderMenuItem(BALANCE)}
      </SubMenu>
    </Menu>
  );

  return (
    <SideBarWrapper style={style.container}>
      {_renderHeader()}
      {_renderMenu()}
    </SideBarWrapper>
  );
};

export default SidebarVendorComponent;
