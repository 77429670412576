import React from 'react';
import { Col, Form, Input, Select, Avatar } from 'antd';
import { CreateClassroomFormWrap } from './CreateClassroomForm.style';
import { UploadImage } from '../../Containers';
import Assets from '../../Assets';
import { ButtonWlb } from '..';

const CreateClassroomFormComponent = props => {
  const {
    handleAttributeChange,
    getFieldDecorator,
    dataClassroom,
    handleUpload,
    handleChangeAvatar,
    initData,
    title,
    displayUploadImage,
    handleDisplayChangeImage,
    clasroomCategories,
    isIndividual
  } = props;
  const { Option } = Select;
  const { TextArea } = Input;

  const _uploadImage = () => (
    <UploadImage
      acceptedFormat=".png,.jgp,.jpeg"
      listType="picture-card"
      fileList={initData.images}
      target="images"
      handleUpload={handleUpload}
      handleChange={handleChangeAvatar}
    />
  );

  const _avatarForm = () => (
    <Form.Item label="Avatar Kelompok">
      {getFieldDecorator(
        'Group Learning Avatar',
        {}
      )(
        <>
          <div className={`display-avatar`} hidden={displayUploadImage}>
            <Avatar
              shape="square"
              size="large"
              icon="user"
              src={dataClassroom?.avatarUrl ?? Assets.IMAGE.DEFAULT_IMAGE}
            />
            <ButtonWlb
              key="1"
              htmlType="button"
              onClick={() => handleDisplayChangeImage(true)}
            >
              Ganti Gambar
            </ButtonWlb>
          </div>
          <div hidden={!displayUploadImage}>
            {_uploadImage()}
            <ButtonWlb
              key="1"
              htmlType="button"
              onClick={() => handleDisplayChangeImage(false)}
            >
              Batal
            </ButtonWlb>
          </div>
        </>
      )}
    </Form.Item>
  );
  return (
    <CreateClassroomFormWrap>
      <Col span={12}>
        <Form layout="vertical" onSubmit={null}>
          {title === 'Aturan Kelompok Belajar' && _avatarForm()}
          <Form.Item label="Nama Kelompok Belajar">
            {getFieldDecorator('Group Name', {
              rules: [
                {
                  required: true,
                  message: 'Please provide group learning name'
                }
              ],
              initialValue: dataClassroom.name && dataClassroom.name
            })(
              <Input
                // value={group['name']}
                placeholder="Nama Kelompok Belajar"
                onChange={event =>
                  handleAttributeChange('name', event.target.value)
                }
              />
            )}
          </Form.Item>

          {!isIndividual && (
            <Form.Item label="Tipe Kelompok Belajar ">
              {getFieldDecorator('categoryId', {
                rules: [
                  { required: true, message: 'Please provide group category' }
                ],
                initialValue:
                  dataClassroom.categoryId && dataClassroom.categoryId
              })(
                <Select
                  placeholder="Silakan pilih kategori kelompok"
                  // value={dataClassroom.category}
                  onChange={value => handleAttributeChange('categoryId', value)}
                >
                  {clasroomCategories?.academy_course_categories.length > 0 ? (
                    clasroomCategories.academy_course_categories.map(val => {
                      return (
                        <Option value={val.id} key={val.id}>
                          {`${
                            val.academy_course_category?.name
                              ? val.academy_course_category.name + ' - '
                              : ''
                          } ${val.name}`}
                        </Option>
                      );
                    })
                  ) : (
                    <Option value="" disabled>
                      Tidak ada daftar kategori
                    </Option>
                  )}
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item label="Privasi" className="privacy-form">
            {getFieldDecorator('privacy', {
              rules: [
                {
                  required: true,
                  message: 'Please select your group privacy'
                }
              ],
              initialValue: dataClassroom?.privacy ?? 1
            })(
              <Select
                placeholder="Silakan pilih privasi kelompok"
                // value={group['privacy']}
                onChange={value => handleAttributeChange('privacy', value)}
              >
                <Option value={2}>Tertutup</Option>
                <Option value={1}>Privasi</Option>
                <Option value={0}>Umum</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Keterangan Kelompok">
            {getFieldDecorator('Group Description', {
              rules: [
                {
                  required: true,
                  message: 'Please provide your group description'
                }
              ],
              initialValue:
                dataClassroom.description && dataClassroom.description
            })(
              <TextArea
                rows={4}
                // value={group['description']}
                placeholder={'Describe your group description'}
                onChange={event =>
                  handleAttributeChange('description', event.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Aturan Kelompok">
            {getFieldDecorator('Group Rules', {
              rules: [
                {
                  required: true,
                  message: 'Please provide your group rules'
                }
              ],
              initialValue: dataClassroom.rules && dataClassroom.rules
            })(
              <TextArea
                rows={4}
                // value={group['rules']}
                placeholder={'Describe your group rules'}
                onChange={event =>
                  handleAttributeChange('rules', event.target.value)
                }
              />
            )}
          </Form.Item>
        </Form>
      </Col>
    </CreateClassroomFormWrap>
  );
};

export default CreateClassroomFormComponent;
