import React from 'react';
import { Input, Icon } from 'antd';
import { displayFileTypeImage } from '../../Utils/displayFileTypeImage';
import { DisplayFileWrap } from './DisplayFile.style';
import DisplayLoading from '../DisplayLoading';

const DisplayFile = props => {
  const {
    editedFiles,
    file,
    deleteFileUpload,
    setEditedFiles,
    handleChange,
    target
  } = props;
  const fileSize = size => {
    const sizes = size / 1024 / 1024;
    return sizes.toFixed(2);
  };
  const fileName = file.name.split('.');
  const reg = /(?:\.([^.]+))?$/;
  const fileFormat = reg.exec(file.name)[0];

  const changeName = e => {
    let changedName = e.target.value;
    const changedFiles = editedFiles.map(item => {
      if (item.uid === file.uid) {
        return { ...file, name: `${changedName}${fileFormat}` };
      } else {
        return item;
      }
    });
    setEditedFiles(changedFiles);
    handleChange(target, changedFiles);
  };

  return (
    <DisplayFileWrap>
      {file.status === 'uploading' ? (
        <DisplayLoading />
      ) : (
        <>
          <div className="file">
            <img
              className="file-image"
              src={displayFileTypeImage(file?.name)}
              alt="thumbnail"
            />
            <div className="file-input">
              <Input
                placeholder="Input your file name"
                value={fileName[0]}
                onChange={e => changeName(e)}
              />
              <div style={{ fontSize: 10, textAlign: 'end' }}>
                {fileSize(file?.size)} MB
              </div>
            </div>
          </div>
          <Icon
            type="close"
            className="closed-icon"
            onClick={() => deleteFileUpload(file.uid)}
          />
        </>
      )}
    </DisplayFileWrap>
  );
};

export default DisplayFile;
