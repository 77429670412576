import Constants from '../../Config/Constants';

export default {
  container: {
    // marginTop: 11.2,
    marginBottom: 11.2,
    padding: 12.3,
    width: '100%',
    borderRadius: 2.5,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW,
    backgroundColor: Constants.COLORS.WHITE
  },
  headerTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 3.2
  },
  headerIcon: {
    width: 25,
    marginRight: 20
  },
  hr: {
    marginTop: 10,
    height: 1,
    backgroundColor: Constants.COLORS.LIGHT_GREY,
    border: 'none'
  },
  wrapperMenu: {
    justifyContent: 'start'
  },
  colImage: {
    margin: '10px 4px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  colIndividual: {
    margin: '10px 4px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  divProfile: {
    width: '100%',
    marginLeft: '12px'
  },
  profileName: {
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 600
  },
  profileWork: {
    textAlign: 'left',
    fontSize: 12,
    color: '#a9a8a8'
  },
  divImage: {
    marginTop: 4,
    marginBottom: 2,
    display: 'flex',
    justifyContent: 'center'
  },
  iconQuick: {
    height: 36,
    width: 36,
    margin: 'auto',
    borderRadius: '50%'
  },
  menuTitle: {
    textAlign: 'left',
    fontSize: 12,
    marginBottom: 4,
    fontWeight: 600,
    color: '#014a62'
  },
  moreIcon: {
    fontSize: 15,
    color: Constants.COLORS.DARK_BLUE
  },
  colMore: {
    marginTop: 4,
    marginBottom: 2,
    width: 36,
    height: 36,
    border: '1px solid',
    borderColor: Constants.COLORS.DARK_BLUE,
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mentorshipTooltip: {
    fontSize: '12px'
  }
};
