import PropTypes from 'prop-types';

const propTypes = {
  notifications: PropTypes.array.isRequired,
  markAll: PropTypes.func.isRequired
};

const defaultProps = {

};

export default {
  propTypes,
  defaultProps
}
