import styled from 'styled-components';

const OverlayWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .Overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 1;
    right: 0;
    height: 100%;
    width: 100%;
    background: grey;
    opacity: 0.8;
    &:hover {
      cursor: pointer;
    }
  }
  .textOverlay {
    color: white;
    position: absolute;
    top: 50%;
    left: 45%;
    font-size: 50px;
    text-align: center;
  }
`;

export { OverlayWrapper };
