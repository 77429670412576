import React from 'react';
import { Col, Row } from 'antd';
import { PostList, SidebarClassroomInfoBox } from '../../../Containers';
import Constants from '../../../Config/Constants';
import { Mentorship } from '../../../Containers';

const DetailPostComponent = props => {
  return (
    <div>
      <Row gutter={16} style={{ marginTop: '15px' }}>
        <Col span={6}>
          <SidebarClassroomInfoBox {...props} />
        </Col>
        <Col id={'classroom-post'} span={12}>
          <PostList
            postType={Constants.POST_TYPE.CLASSROOM}
            listType={'detail-post'}
            {...props}
          />
        </Col>
        <Col span={6}>
          <Mentorship {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default DetailPostComponent;
