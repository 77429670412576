import React, { useState } from 'react';
import { AccountSettingsWrapper } from './AccountSettings.style';
import SidebarSettings from '../../Components/SidebarSettings';
import { WidgetSettings, LanguageSettings } from '../../Containers';
import LoginAndSecurity from '../../Containers/LoginAndSecuritySettings';

const AccountSettingsComponent = () => {
  const [activeMenu, setActiveMenu] = useState('Masuk dan Keamanan');
  const handleSwitchMenu = selectedMenu => setActiveMenu(selectedMenu);
  const menu = [
    'Masuk dan Keamanan',
    // "Notifications",
    'Widget',
    'Bahasa'
  ];
  const renderMenu = () => {
    if (activeMenu === 'Masuk dan Keamanan') {
      return <LoginAndSecurity />;
    } else if (activeMenu === 'Bahasa') {
      return <LanguageSettings />;
    } else {
      return <WidgetSettings />;
    }
  };
  return (
    <AccountSettingsWrapper>
      <SidebarSettings
        handleSwitchMenu={handleSwitchMenu}
        menu={menu}
        title="Pengaturan Akun"
      />
      <div className={'active-menu-content'}>
        <div className={'active-menu-header'}>{activeMenu}</div>
        {renderMenu()}
      </div>
    </AccountSettingsWrapper>
  );
};

export default AccountSettingsComponent;
