import React, { useState } from 'react';
import PostListComponent from './PostList.component';
import { Company, Kudos } from '../../Services';
import { checkNextPage } from '../../Utils/CommonUtils';
import { message } from 'antd';
import CompanyPost from '../../Services/Company/Post';
import { useAsyncEffect } from 'use-async-effect';
import {
  useBlockMe,
  useInfiniteScroll
} from '../../SharedComponents/CustomHook';

const PostListCompany = props => {
  const [dataPost, setDataPost] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isBottom, setIsBottom] = useInfiniteScroll('company-post');
  const [isFetching, setIsFetching] = useState(false);
  const [blockedMe, blockLoaded] = useBlockMe();
  const [kudosList, setKudosList] = useState([]);

  useAsyncEffect(
    async isMount => {
      if (
        blockLoaded &&
        hasNextPage &&
        !isFetching &&
        (isBottom || page === 1)
      ) {
        setIsFetching(true);
        await Company.fetchCompanyPostList(page)
          .then(response => {
            if (!isMount()) return;
            const { per_page, total, data } = response;
            const isNextPage = checkNextPage(total, page, per_page);
            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }
            let filteredPost = data.filter(
              item => !blockedMe?.includes(item.userId)
            );
            setDataPost(dataPost.concat(filteredPost));
          })
          .catch(error => {})
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
            setIsBottom(false);
          });
      }
    },
    [
      dataPost,
      hasNextPage,
      isBottom,
      isFetching,
      page,
      setIsBottom,
      blockLoaded
    ]
  );

  useAsyncEffect(async isMounted => {
    await Kudos.getListKudos()
      .then(res => {
        if (!isMounted()) return;
        setKudosList(res.data);
      })
      .catch(() => {
        message.error('Something went wrong when load list of kudos');
      });
  }, []);

  function deletePost(post) {
    CompanyPost.deletePost(post.id)
      .then(res => {
        if (res.status === 200) {
          let newDataPost = dataPost.filter(p => p.id !== post.id);
          setDataPost(newDataPost);
          message.success('Postingan Anda telah dihapus');
        }
      })
      .catch(err => message.error('Something went wrong'));
  }

  function restartPage() {
    setDataPost([]);
    setHasNextPage(true);
    setPage(1);
  }

  return (
    <PostListComponent
      data={dataPost}
      isFetching={isFetching}
      deletePost={deletePost}
      restartPage={restartPage}
      kudosList={kudosList}
      {...props}
    />
  );
};

export default PostListCompany;
