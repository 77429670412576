import gql from 'graphql-tag';

export const GET_LIST_EMAIL_ADDRESS = gql`
  query($user: uuid!) {
    owner: global_users_by_pk(id: $user) {
      id
      name
      email
    }
    people_emails(
      where: {
        _and: [{ user: { _eq: $user } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [
        { is_verified: desc }
        { is_main: desc }
        { date_added: desc_nulls_last }
      ]
    ) {
      email
      user
      status
      deletedAt
      is_main
      is_visible
      is_verified
      date_exp_verification
      global_user {
        email
      }
    }
  }
`;
export const GET_ADDED_EMAIL = gql`
  query CheckerEmail($email: String) {
    checker_global_user: global_users(where: { email: { _eq: $email } }) {
      email
      name
    }

    checker_people_email: people_emails(where: { email: { _eq: $email } }) {
      email
      user
      status
      deletedAt
      global_user {
        email
      }
    }
  }
`;
export const INSERT_EMAIL = gql`
  mutation($email: String!, $isMain: Boolean) {
    insert_people_emails(objects: { email: $email, is_main: $isMain }) {
      affected_rows
    }
  }
`;
export const UPDATE_EMAIL = gql`
  mutation(
    $userId: uuid!
    $oldEmail: String!
    $newEmail: String!
    $isMain: Boolean!
  ) {
    update_people_emails(
      _set: { email: $newEmail, is_main: $isMain }
      where: { _and: { user: { _eq: $userId }, email: { _eq: $oldEmail } } }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_EMAIL = gql`
  mutation($email: String!) {
    update_people_emails(
      _set: { deletedAt: "now()" }
      where: {
        _and: [{ email: { _eq: $email } }, { deletedAt: { _is_null: true } }]
      }
    ) {
      affected_rows
    }
  }
`;
export const SET_MAIN_EMAIL = gql`
  mutation($userId: uuid!, $email: String!) {
    q1: update_people_emails(
      _set: { is_main: false }
      where: { user: { _eq: $userId } }
    ) {
      affected_rows
    }

    q2: update_people_emails(
      _set: { is_main: true }
      where: { _and: { user: { _eq: $userId }, email: { _eq: $email } } }
    ) {
      affected_rows
    }
  }
`;
export const SET_VISIBILITY_EMAIL = gql`
  mutation($userId: uuid!, $email: String!, $isVisible: Boolean) {
    update_people_emails(
      _set: { is_visible: $isVisible }
      where: { _and: { user: { _eq: $userId }, email: { _eq: $email } } }
    ) {
      affected_rows
    }
  }
`;

export const SET_UNMAIN_ALL_EMAIL_BY_USER = gql`
  mutation($user: uuid!) {
    update_people_emails(
      where: { user: { _eq: $user } }
      _set: { is_main: false }
    ) {
      affected_rows
    }
  }
`;

export const PERMANENT_DELETE = gql`
  mutation($email: String) {
    delete_people_emails(
      where: { email: { _eq: $email }, is_verified: { _eq: false } }
    ) {
      affected_rows
    }
  }
`;
export const SOFT_DELETE = gql`
  mutation($email: String) {
    update_people_emails(
      _set: { deletedAt: "now()" }
      where: {
        _and: [{ email: { _eq: $email } }, { deletedAt: { _is_null: true } }]
      }
    ) {
      affected_rows
    }
  }
`;
