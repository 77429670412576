import { Avatar } from "antd";
import Styled from "styled-components";

export const WrapperScroll = Styled.div`
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "450px")};
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16); 
    border-radius: 5px;
  }
`;

export const WrapperExperience = Styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;
    padding: 25px 33px 30px 33px;

    .avatar-wrapper {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 24px;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      width: 580px;
      margin-right: auto;

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 8px;
      }

      .company {
        font-size: 12px;
        color: #014a62;
        margin-bottom: 8px;
      }

      .date {
        font-size: 12px;
        font-style: italic;
        text-align: left;
        color: #a9a8a8;
      }
      .location {
        font-size: 12px;
        font-style: italic;
        text-align: left;
        color: #a9a8a8;
        margin-bottom: 8px;
      }
      .description {
        font-size: 12px;
        color: #000000;
      }
    }
  }

  .divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    width: 580px;
    margin-left: auto
}
`;

export const AvatarStyled = Styled(Avatar)`
  width: 100% !important;
  height: 100% !important;

  img {
    width: auto !important;
    height: 100% !important
    margin: auto;
  }
`;
