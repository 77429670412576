import gql from "graphql-tag";

export const UPDATE_PROFILE = gql`
  mutation($id: uuid!, $avatar: String!) {
    update_global_users(
      where: { id: { _eq: $id } }
      _set: { avatar: $avatar }
    ) {
      affected_rows
    }
  }
`;
