import React, { useState } from 'react';
import VendorBalanceComponent from './VendorBalance.component';
import { useQuery } from 'react-apollo';
import {
  GET_LIST_SPEND,
  GET_LIST_INCOME,
  GET_BALANCE
} from './VendorBalance.graphql';

const VendorBalanceContainer = props => {
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const companyId = props.match.params.id;
  const GET_BALANCE_OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } },
    variables: {
      id_vendor: companyId
    }
  };
  const OPTIONS = {
    context: { headers: { 'X-Hasura-Role': 'organization-administrator' } },
    variables: {
      id_vendor: companyId,
      limit: limit,
      offset: offset
    },
    notifyOnNetworkStatusChange: true
  };

  const getListSpend = useQuery(GET_LIST_SPEND, OPTIONS);
  const { data: dataSpend, fetchMore: fetchMoreSpend } = getListSpend;
  const getListIncome = useQuery(GET_LIST_INCOME, OPTIONS);
  const { data: dataIncome, fetchMore: fetchMoreIncome } = getListIncome;
  const getBalance = useQuery(GET_BALANCE, GET_BALANCE_OPTIONS);
  const { data: balance } = getBalance;

  const cursorSpend = newOffset => {
    fetchMoreSpend({
      variables: {
        offset: newOffset,
        id_vendor: companyId,
        limit: limit
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }

        return Object.assign({}, prevResult, {
          global_transactions: [...fetchMoreResult.global_transactions]
        });
      }
    });
    setOffset(newOffset);
  };
  const nextSpend = () => {
    cursorSpend(offset + limit);
  };
  const prevSpend = () => {
    cursorSpend(offset - limit);
  };

  const cursorIncome = newOffset => {
    fetchMoreIncome({
      variables: {
        offset: newOffset,
        id_vendor: companyId,
        limit: limit
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }

        return Object.assign({}, prevResult, {
          global_transactions: [...fetchMoreResult.global_transactions]
        });
      }
    });
    setOffset(newOffset);
  };
  const nextIncome = () => {
    cursorIncome(offset + limit);
  };
  const prevIncome = () => {
    cursorIncome(offset - limit);
  };

  return (
    <VendorBalanceComponent
      balance={balance}
      dataSpendLoading={getListSpend.loading}
      dataSpend={dataSpend?.global_transactions}
      totalSpend={dataSpend?.total?.aggregate?.count}
      totalIncome={dataIncome?.total?.aggregate?.count}
      dataIncomeLoading={getListIncome.loading}
      dataIncome={dataIncome?.global_transactions}
      handleLimit={limit => {
        setLimit(parseInt(limit));
      }}
      prevSpend={prevSpend}
      nextSpend={nextSpend}
      prevIncome={prevIncome}
      nextIncome={nextIncome}
      limit={limit}
      offset={offset}
      {...props}
    />
  );
};

export default VendorBalanceContainer;
