import styled from 'styled-components';

const Content = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  // phone
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0 24px 24px;
  }

  // tablet
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0 30px 30px;
  }
`;

const Container = styled.div`
  position: relative;
`;

export { Content, Container };
