import React from 'react';
import {
  ContentWrap,
  Footer,
  ShopStatusWrap,
  WorkingHoursWrap,
  Wrapper
} from './VendorInformation.style';
import { Button, Col, Form, Input, Row, TimePicker } from 'antd';
import {
  ButtonWlb,
  TopSettingGroup
  // UploadFile
} from '../../../Components';
import { UploadImage } from '../../../Containers';
import Checkbox from 'antd/es/checkbox';
import moment from 'moment';
import { isEmpty } from 'lodash';
import ImageCoverPreview from './ImageCoverPreview';

const VendorInformationComponent = props => {
  const {
    btnOpenActive,
    setBtnOpenActive,
    btnClosedActive,
    setBtnClosedActive,
    btnScheduledActive,
    setBtnScheduledActive,
    vendor,
    handleSubmit,
    days,
    handleChangeImage,
    handleUploadImage,
    dataImage,
    schedule,
    handleChangeSchedule,
    setActiveMenu
  } = props;
  const { getFieldDecorator } = props.form;
  const { TextArea } = Input;
  const daysInWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ];

  const _Footer = (title1, title2) => (
    <Footer>
      <ButtonWlb
        type="secondary"
        size="large"
        disabled={null}
        onClick={() => setActiveMenu('Dashboard')}
      >
        {title2}
      </ButtonWlb>
      <ButtonWlb
        type="primary"
        size="large"
        disabled={null}
        htmlType="submit"
        style={{ marginLeft: '20px' }}
      >
        {title1}
      </ButtonWlb>
    </Footer>
  );

  const _ScheduledDay = () => (
    <div>
      <Form.Item label="Scheduled day">
        <Checkbox.Group
          onChange={handleChangeSchedule}
          defaultValue={daysInWeek.map(day => {
            if (!isEmpty(schedule?.[day])) {
              return day;
            } else return undefined;
          })}
        >
          {days.map(day => {
            const key = Object.keys(day)[0];
            return (
              <div key={key}>
                <Checkbox value={key}>{day[key]}</Checkbox>
                <br />
                <br />
              </div>
            );
          })}
        </Checkbox.Group>
      </Form.Item>
    </div>
  );

  const _WorkingHours = () => (
    <WorkingHoursWrap>
      <Form.Item label="Working Hours">
        <Input.Group size="small">
          {days.map(day => {
            const key = Object.keys(day)[0];
            return (
              <div key={key}>
                <Row gutter={8}>
                  <Col span={10}>
                    <Form.Item label={null}>
                      {getFieldDecorator(day[key] + '_from', {
                        rules: [
                          {
                            required: true,
                            message: `Please input ${day[key]} from!`
                          }
                        ],
                        initialValue: !isEmpty(
                          vendor?.profile_field?.schedule?.[key]
                        )
                          ? moment(
                              vendor?.profile_field?.schedule?.[key].from,
                              'HH.mm'
                            )
                          : moment('00.00', 'HH.mm')
                      })(
                        <TimePicker
                          size="small"
                          format={'HH.mm'}
                          disabled={isEmpty(schedule?.[key])}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1}>-</Col>
                  <Col span={10}>
                    <Form.Item label={null}>
                      {getFieldDecorator(day[key] + '_to', {
                        rules: [
                          {
                            required: true,
                            message: `Please input ${day[key]} to!`
                          }
                        ],
                        initialValue: !isEmpty(
                          vendor?.profile_field?.schedule?.[key]
                        )
                          ? moment(
                              vendor.profile_field.schedule[key].to,
                              'HH.mm'
                            )
                          : moment('00.00', 'HH.mm')
                      })(
                        <TimePicker
                          size="small"
                          format={'HH.mm'}
                          disabled={isEmpty(schedule?.[key])}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          })}
        </Input.Group>
      </Form.Item>
    </WorkingHoursWrap>
  );

  const _ShopStatus = () => (
    <ShopStatusWrap>
      <Button
        type="primary"
        ghost={btnOpenActive ? false : true}
        onClick={setBtnOpenActive}
      >
        Open
      </Button>
      <Button
        type="danger"
        ghost={btnClosedActive ? false : true}
        onClick={setBtnClosedActive}
      >
        Closed
      </Button>
      <Button
        type="primary"
        ghost={btnScheduledActive ? false : true}
        onClick={setBtnScheduledActive}
      >
        Scheduled
      </Button>
    </ShopStatusWrap>
  );

  const _Content = () => (
    <div>
      <TopSettingGroup title={'Vendor Information'} />
      <ContentWrap>
        <Form.Item label="Vendor Name">
          {getFieldDecorator('brand_name', {
            rules: [
              {
                required: true,
                message: 'Please input your Vendor Name!'
              }
            ],
            initialValue: vendor.brand_name ? vendor.brand_name : ''
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Tagline">
          {getFieldDecorator('headline', {
            rules: [
              {
                required: true,
                message: 'Please input your headline!'
              }
            ],
            initialValue: vendor.profile_field
              ? vendor.profile_field.headline
              : ''
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Please input your description!'
              }
            ],
            initialValue: vendor.profile_field
              ? vendor.profile_field.description
              : ''
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label="Shop Status">
          {_ShopStatus()}
          <Row>
            <Col span={6}>{btnScheduledActive ? _ScheduledDay() : null}</Col>
            <Col span={10}>{btnScheduledActive ? _WorkingHours() : null}</Col>
          </Row>
        </Form.Item>
      </ContentWrap>
      {/* {_Title('Vendor Image')} */}
      <ContentWrap>
        <h2>Vendor Image</h2>
        <p>
          {/* 750 x 422 pixels */}
          Recommended ratio 1:1
          <br />
          JPG, JPEG, or PNG
          <br />
          (no text on the image).
        </p>
        <div style={{ width: 130 }}>
          <UploadImage
            acceptedFormat=".png,.jpg,.jpeg"
            // listType="picture-card"
            fileList={dataImage.images}
            target="images"
            handleUpload={handleUploadImage}
            handleChange={handleChangeImage}
            maxUpload={1}
          />
        </div>
        {!isEmpty(dataImage.images) && (
          <ImageCoverPreview type="avatar" source={dataImage.images} />
        )}
      </ContentWrap>
      {/* {_Title('Vendor Cover')} */}
      <ContentWrap>
        <h2>Vendor Cover</h2>
        <p>
          1195 x 270 pixel
          <br />
          JPG, JPEG, or PNG
          <br />
          (no text on the image).
        </p>
        <UploadImage
          acceptedFormat=".png,.jpg,.jpeg"
          // listType="picture-card"
          fileList={dataImage.cover}
          target="cover"
          handleUpload={handleUploadImage}
          handleChange={handleChangeImage}
          maxUpload={1}
        />
        {!isEmpty(dataImage.cover) && (
          <ImageCoverPreview type="cover" source={dataImage.cover} />
        )}
      </ContentWrap>
      {_Footer('Save', 'Cancel')}
    </div>
  );

  return (
    <Wrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        {_Content()}
      </Form>
    </Wrapper>
  );
};

export default VendorInformationComponent;
