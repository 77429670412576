import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

const GetCityServices = () => {
  const query = gql`
    {
      getCity{
        city_id
        city_name
        type
        postal_code
      }
    }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.getCity;
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

const GetCityByProvinceIdServices = provinceId => {
  const query = gql`
  {
    getCity(province_id:${provinceId}){
        city_id
        city_name
        type
        postal_code
    }
  }
  `;
  const { data, loading, error } = useQuery(query);
  if (data) {
    return data.getCity;
  }
  if (error) {
    return [
      {
        province_id: 0,
        province: 'Error fetching please report'
      }
    ];
  }
  if (loading) {
    return [
      {
        province_id: 0,
        province: 'Loading'
      }
    ];
  }
};

export default { GetCityServices, GetCityByProvinceIdServices };
