import { Button, Modal } from 'antd';
import React, { memo } from 'react';
import { ModalWrapperDeleteCheck } from './ModalDeleteCheck.style';

export default memo(function ModalDeleteCheck({
  centered,
  open,
  target,
  message,
  confirm,
  onConfirm
}) {
  const handleDelete = () => {
    onConfirm();
  };

  return (
    <Modal
      centered={centered}
      visible={open}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 0 }}
    >
      <ModalWrapperDeleteCheck>
        <div className="header">{target}</div>
        <div className="body">
          <p>{message}</p>
        </div>
        <div className="action">
          <Button
            onClick={handleDelete}
            style={{ backgroundColor: '#014a62', color: 'white' }}
            className="delete"
          >
            {confirm}
          </Button>
        </div>
      </ModalWrapperDeleteCheck>
    </Modal>
  );
});
