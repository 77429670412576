import React from 'react';
import {
  ButtonWlb,
  DummySpace,
  TopSettingGroup,
  VendorList
} from '../../Components';
import { BoxShadow, VendorListBoxWrap } from './VendorListBox.style';
import { Link } from 'react-router-dom';
import { routes } from '../../Config/Router';
import { CHECK_VENDOR_COPORATE } from './VendorListBoxGraphQl';
import { AuthenticationService } from '../../Services';
import { useQuery } from 'react-apollo';

const VendorListBoxComponent = props => {
  const { dataListVendor, isLoadingListVendor, errorVendor } = props;
  const userData = AuthenticationService.getUserData();

  const { data, loading, error } = useQuery(CHECK_VENDOR_COPORATE, {
    variables: {
      company: `${userData.company_id}`
    }
  });

  // di hide dulu
  // const _buttonTop = [
  //   data?.company_profiles?.length  === 0 ? 
  //   <Link key={'step'} to={routes.STEPS.path}>
  //     <ButtonWlb key="1" type="primary" onClick={null}>
  //       Create Store
  //     </ButtonWlb>
  //   </Link> :
  //   <></>
  // ];
  const _buttonTop = null
  return (
    <BoxShadow>
      <TopSettingGroup
        title="Vendor List"
        buttonTop={!dataListVendor && !isLoadingListVendor && _buttonTop}
      />
      <VendorListBoxWrap>
        {dataListVendor && (
          <VendorList
            dataListVendor={dataListVendor}
            isLoadingListVendor={isLoadingListVendor}
            errorVendor={errorVendor}
          />
        )}
        <DummySpace />
      </VendorListBoxWrap>
    </BoxShadow>
  );
};

export default VendorListBoxComponent;
