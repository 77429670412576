import React from 'react';
import { Avatar } from 'antd';
import {
  Description,
  JobDetails,
  Name,
  PeopleResultWrapper,
  InfoWrapper,
  AvatarWrapper,
  ButtonWrapper
} from './PeopleResultList.style';
import { ButtonWlb } from '../../Components';
import { Link } from 'react-router-dom';
import { AuthenticationService } from '../../Services';

const PeopleResultComponent = props => {
  const { person, isFollow, handleFollow, handleUnfollow } = props;
  const userId = AuthenticationService.getUserId();
  return (
    <PeopleResultWrapper>
      <div className={'container-peopleResult-borderBottom'}>
        <AvatarWrapper>
          <Avatar size={50} src={person.avatar} />
        </AvatarWrapper>
        <InfoWrapper>
          <Link to={`/profile/${person.id}/detail`}>
            <Name>{person.name}</Name>
          </Link>
          <Description>
            <JobDetails>
              {person?.people_work_placements?.[0]?.company_job_profile?.title
                ? person?.people_work_placements?.[0]?.company_job_profile
                    ?.title
                : '-'}
            </JobDetails>
            <JobDetails>
              {person?.people_work_placements?.[0]?.company_profile?.brand_name
                ? person?.people_work_placements?.[0]?.company_profile
                    ?.brand_name
                : '-'}
            </JobDetails>
          </Description>
        </InfoWrapper>
        <ButtonWrapper>
          {userId !== person.id && (
            <ButtonWlb
              type={isFollow ? 'default' : 'primary'}
              size={'small'}
              style={{ width: 85, fontSize: 14 }}
              onClick={() =>
                isFollow ? handleUnfollow(person) : handleFollow(person)
              }
            >
              {isFollow ? 'Following' : 'Follow'}
            </ButtonWlb>
          )}
        </ButtonWrapper>
      </div>
    </PeopleResultWrapper>
  );
};

export default PeopleResultComponent;
