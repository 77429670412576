import React from 'react';
import { ComponentWrapper } from './style';

const Index = props => {
  return (
    <ComponentWrapper>
      <div className="component_giving_kudos_image">
        <img src={props.img} alt={props.kudosName} />
      </div>
      <div className="component_giving_kudos_content">
        <div className="compoent_giving_kudos_badge">{props.kudosName}</div>
        <div className="compoent_giving_kudos_name">{props.name}</div>
        <div className="compoent_giving_kudos_description">
          {props.description}
        </div>
      </div>
    </ComponentWrapper>
  );
};

export default Index;
