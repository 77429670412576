import styled from 'styled-components';
import Assets from '../../Assets';

const WidgetSettingsWrapper = styled.div`
  display: flex;
  width: 608.4px;
  background-color: #ffffff;
  flex-direction: column;

  .widget {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid #f7f8f9;
    background-color: #ffffff;
  }

  .widget-toggle {
    display: flex;
    width: 100px;
    justify-content: space-between;

    /* div {
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.44;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    } */
    .widget-status {
      font-size: 13px;
      color: ${Assets.COLOR_PALLETE.black2};
      display: flex;
      align-items: center;
    }
  }

  .widget-name {
    font-family: 'Muli-SemiBold';
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #014a62;
  }

  .ant-switch.ant-switch-checked {
    background-color: #014a62;
    height: 21px;
  }
`;

export { WidgetSettingsWrapper };
