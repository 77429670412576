import React, { useState } from 'react';
import Switch from 'antd/es/switch';
import { WidgetService } from '../../Services';
import { message } from 'antd';

const WidgetSettingsComponent = props => {
  const { widget } = props;
  const [status, setStatus] = useState(widget.status);

  const handleChange = widget => {
    WidgetService.updateWidgetStatus(!widget.status, widget.widgetId)
      .then(res => setStatus(!status))
      .catch(err => message.error('Something went wrong'));
  };
  return (
    <div className={'widget'}>
      <div className={'widget-name'}>
        {widget.widget?.name === 'Team' ? 'Group' : widget.widget?.name}
      </div>
      <div className={'widget-toggle'}>
        <div className={'widget-status'}>
          {status ? 'Aktif' : 'Tidak Aktif'}
        </div>
        <Switch onChange={() => handleChange(widget)} checked={status} />
      </div>
    </div>
  );
};

export default WidgetSettingsComponent;
