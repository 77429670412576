import styled from 'styled-components';
import Assets from '../../Assets';

export const CompanyDetailBoxWrap = styled.div`
  .sub-info {
    padding: 8px 0;
    p {
      font-size: 12px;
      font-family: ${Assets.FONT.MULI};
      margin: 0;
      padding: 0;
    }
    p:first-child {
      color: ${Assets.COLOR_PALLETE.grey};
    }
    p:last-child {
      color: ${Assets.COLOR_PALLETE.black2};
    }

    .sub-info-value {
      margin-top: 8px;
    }
  }

  .item-count {
    display: flex;
    .count {
      width: 50%;
      border: 1px solid #a9a8a8;
      text-align: center;
      padding: 11px 0px;
      border-top: none;

      &:first-child {
        border-left: none;
      }
      &:last-child {
        border-left: none;
        border-right: none;
      }
      span {
        display: block;
      }
      .number {
        font-family: 'Muli-SemiBold', sans-serif;
        font-size: 12px;
        color: #252525;
      }
      .text {
        font-family: 'Muli-Regular', sans-serif;
        font-size: 12px;
        color: #a9a8a8;
      }
    }
  }
`;
