import React, { useState } from 'react';
import DetailUserSection from '../../../../Components/DetailUserSection/DetailUserSection.component';
import ListDetail from '../../../../Components/DetailUserSection/ListDetail.component';
import AddEditEducation from './AddEdit';
import { useApolloClient } from 'react-apollo';
import { LIST_CATEGORY } from './Education.graphql';
import MoreEducation from './MoreEducation';

export default function Education({
  isUser,
  dataOhsa,
  refetch,
  profileId,
  people_profile,
  nameUser
}) {
  const client = useApolloClient();
  const [open, setOpen] = useState({
    open: false,
    data: null,
    actionType: null,
    openMore: false,
    fromList: false
  });
  const [dataQuery, setDataQuery] = useState({
    category: []
  });
  const [listcategory, setListcategory] = useState({
    category: []
  });

  const CONTEXT_USER = { headers: { 'X-Hasura-Role': 'user' } };

  const handleSearch = async (val, target) => {
    if (target === 'category') {
      const { data: dataCategory } = await client.query({
        query: LIST_CATEGORY,
        context: CONTEXT_USER
      });
      setListcategory({
        ...listcategory,
        category: []
      });
      if (dataCategory) {
        setDataQuery({
          ...dataQuery,
          category: dataCategory.people_profile_ohsa_categories
        });
      }
    }
  };

  return (
    <>
      <DetailUserSection
        onClickAction={() =>
          setOpen({ ...open, open: true, data: null, actionType: 'add' })
        }
        title="Riyawat Kesehatan dan Keselamatan Kerja"
        action={isUser ? 'plus' : null}
      >
        <ListDetail
          setOpen={setOpen}
          isUser={isUser}
          data={dataOhsa}
          editOnChild
          showMoreText="Riwayat Kesehatan dan Keselamatan Kerja"
          type={'ohsa'}
        />
      </DetailUserSection>

      <MoreEducation
        open={open.openMore}
        nameUser={nameUser}
        setOpen={setOpen}
        isUser={isUser}
        data={dataOhsa}
      />
      <AddEditEducation
        setOpen={setOpen}
        openState={open}
        setDataQuery={setDataQuery}
        people_profile={people_profile}
        setListcategory={setListcategory}
        profileId={profileId}
        refetch={refetch}
        onSearch={handleSearch}
        dataQuery={dataQuery}
        actionType={open.actionType}
        data={open.data}
        open={open.open}
        nameUser={nameUser}
        onClose={
          open.fromList
            ? () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false,
                  openMore: true
                })
            : () =>
                setOpen({
                  ...open,
                  open: false,
                  data: null,
                  actionType: null,
                  fromList: false
                })
        }
        onCloseModal={
          open.fromList
            ? () => setOpen({ ...open, open: false, openMore: true })
            : () => setOpen({ ...open, open: false })
        }
      />
    </>
  );
}
