import React from 'react';

import About from './About';
import Discussion from './Discussion';
import Setting from './Setting';
import MemberScreen from './MemberScreen';
import ReviewScreen from './Review';
import PageWrapper from './PageWrapper.component';

const menuBarList = [
  {
    renderComponent: props => (
      <PageWrapper>
        <ReviewScreen {...props} />
      </PageWrapper>
    ),
    name: 'Ulasan',
    path: '/social-learning/:id/review'
  },
  {
    renderComponent: props => (
      <PageWrapper>
        <About {...props} />
      </PageWrapper>
    ),
    name: 'Tentang',
    path: '/social-learning/:id/about'
  },
  {
    renderComponent: props => (
      <PageWrapper>
        <Discussion {...props} />
      </PageWrapper>
    ),
    name: 'Diskusi',
    path: '/social-learning/:id/discussion'
  },
  {
    renderComponent: props => (
      <PageWrapper>
        <MemberScreen {...props} />,
      </PageWrapper>
    ),
    name: 'Anggota',
    path: '/social-learning/:id/member'
  },
  {
    renderComponent: props => (
      <PageWrapper>
        <Setting {...props} />
      </PageWrapper>
    ),
    name: 'Pengaturan',
    path: '/social-learning/:id/setting'
  }
];

const defaultMenuBar = 'Diskusi';

export default {
  menuBarList,
  defaultMenuBar
};
