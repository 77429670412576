import { Avatar, Spin } from "antd";
import React from "react";
import { OGFWrapper } from "./OnGoingFeedback.style";
import Assets from "../../../Assets";

const Expression = ({ icon, count, className }) => {
  return (
    <div className={`icon-wrapper ${className}`}>
      <Avatar alt="expression" src={icon} size={30} />
      <p>{count}</p>
    </div>
  );
};

export default function OnGoingFeedbackComponent({ data, loading }) {
  const DATA = [
    {
      id: 1,
      icon: Assets.ICON.EXPRESSION_DISSATISFIED,
      count: data?.disappointed_all?.aggregate?.count,
      className: "red",
    },
    {
      id: 2,
      icon: Assets.ICON.EXPRESSION_NEUTRAL,
      count: data?.ordinary_all?.aggregate?.count,
      className: "yellow",
    },
    {
      id: 3,
      icon: Assets.ICON.EXPRESSION_STATISFIED,
      count: data?.satisfied_all?.aggregate?.count,
      className: "green",
    },
  ];

  return (
    <OGFWrapper
      onClick={() =>
        window.open(process.env.REACT_APP_HC_PERFORMANCE + "/survey", "_blank")
      }
    >
      {loading && <Spin />}
      {!loading && DATA.map(({ id, icon, count, className }) => (
        <Expression key={id} icon={icon} count={count} className={className} />
      ))}
    </OGFWrapper>
  );
}
