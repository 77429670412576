import { gql } from 'apollo-boost';

export const GET_FEEDBACK_REVIEW = gql`
  query($classroomId: uuid!) {
    classroom_reviews(where: { classroom_id: { _eq: $classroomId } }) {
      id
      star
      description
      classroom_review_responses {
        content
        global_user {
          id
          name
          avatar
        }
        date_added
      }
      global_user {
        id
        avatar
        name
      }
      created_at
    }
  }
`;
