import { gql } from 'apollo-boost';

export const APPROVE_MEMBER = gql`
  mutation($classroomId: String!, $newMemberId: String!) {
    classroomAcceptMember(
      newMemberId: $newMemberId
      classroomId: $classroomId
    ) {
      success
    }
  }
`;
