import React from 'react';
import MainContainer from './PostBox.container';
import { CompanyTimeline } from '../../Services';
import config from './PostBox.config';
import { message } from 'antd';

function CompanyTimelinePost(props) {
  const idCompany = props.match.params.id;
  const onSubmit = (content, resetContent, formatBody, sharedPost) => {
    const bodyPost = formatBody(content);
    props.setLoading(true);
    delete bodyPost.canSee;
    delete bodyPost.sharedPost;
    CompanyTimeline.createPost(bodyPost, idCompany)
      .then(() => {
        if (sharedPost) {
          message.success('Berhasil membagikan postingan');
        } else {
          message.success('Postingan berhasil dikirim');
        }
      })
      .catch(() => {
        message.error('Something went wrong');
      })
      .finally(() => {
        props.setLoading(false);
        resetContent(config.initialData);
        props.restartPage();
      });
  };
  return <MainContainer onSubmit={onSubmit} postType={props.postType} />;
}

export default CompanyTimelinePost;
