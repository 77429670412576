import React, { useState, useEffect, useMemo } from 'react';
import { ModalEdit } from '../../Components';
import { InputStyled, TitleField } from './UserIndentity.style';
import { Select, Input, Form, message, Checkbox } from 'antd';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from 'react-apollo';
import {
  UPDATE_IDENTITY_CARD,
  ADD_IDENTITY_CARD,
  CHECKER_DUPLICATE,
  UPDATE_WITH_ADD_QUERY,
  GET_KTP_NPWP_FROM_LATEST_SALARY
} from './UserIdentityCard.graphql';
import { AuthenticationService } from '../../Services';
import {
  ButtonFrom,
  GLOBAL_CITIES_SEARCHABLE,
  GLOBAL_PROVINCES_SEARCHABLE,
  SearchableSelect,
  globalCitiesSeachableVars
} from '../../SharedComponents';
import { month } from '../UserProfileAbout/HelperAbout';
import { LIST_TYPE_IDENTITY } from './constantIdentity';
import { updatePws } from './updatePws';

const generateDate = date => {
  if (date) {
    if (date !== 'Lifetime') {
      return date.replace(',', '').split(' ');
    } else {
      return [null, null, null];
    }
  } else {
    return [null, null, null];
  }
};

const rule = isRequired => {
  return {
    required: isRequired,
    message: 'Bagian ini diperlukan'
  };
};

const getResponseMessage = (err, type = 'menambahkan') => {
  if (err?.message.includes('Uniqueness violation')) {
    message.error(`Nomor ID telah digunakan`);
  } else {
    message.error(
      `Gagal ${type} kartu identitas, silakan coba lagi nanti, ${err}`
    );
  }
};

const EditIdentityCard = props => {
  const {
    open,
    onClose,
    data,
    refetch,
    actionType,
    setOpen,
    userIdentity,
    profileId
  } = props;
  const client = useApolloClient();
  const {
    getFieldDecorator,
    setFieldsValue,
    validateFields,
    resetFields,
    setFields,
    getFieldValue,
    getFieldsValue
  } = props.form;
  const { Option } = Select;
  const { TextArea } = Input;

  const [check, setCheck] = useState(false);

  const [value, setValue] = useState({
    type: null,
    id: null,
    address: null,
    city: null,
    province: null,
    postalCode: null
  });

  const id_type = getFieldValue('type');
  const isSelectPrimaryIdentity = ['KTP', 'NPWP'].includes(id_type);

  const [updateIdentity] = useMutation(UPDATE_IDENTITY_CARD, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [insertIdentity] = useMutation(ADD_IDENTITY_CARD, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });
  const [updateWithAdd] = useMutation(UPDATE_WITH_ADD_QUERY, {
    context: { headers: { 'X-Hasura-Role': 'user' } }
  });

  const { data: dataPws } = useQuery(GET_KTP_NPWP_FROM_LATEST_SALARY, {
    skip: !open,
    variables: {
      user: profileId
    }
  });

  const [
    getProvince,
    { data: dataProvince, loading: loadingProvince }
  ] = useLazyQuery(GLOBAL_PROVINCES_SEARCHABLE);
  const [getCity, { data: dataCity, loading: loadingCity }] = useLazyQuery(
    GLOBAL_CITIES_SEARCHABLE
  );

  const valDate = data?.identity_fields?.expiredDate;

  useEffect(() => {
    if (data) {
      getProvince({
        variables: {
          where: {
            deletedAt: { _is_null: true }
          }
        }
      });
      getCity({
        variables: {
          ...globalCitiesSeachableVars({
            province: data?.identity_fields?.province?.id
          })
        }
      });
      if (actionType === 'edit') {
        setFieldsValue({
          province: data?.identity_fields?.province?.id,
          city: data?.identity_fields?.city?.id
        });
      }
      setValue(e => ({
        ...e,
        city: data?.identity_fields?.city,
        province: data?.identity_fields?.province
      }));
    }
    if (actionType === 'edit' && valDate === 'Lifetime') {
      setCheck(true);
    } else {
      setCheck(false);
    }
    if (actionType === 'add') {
      setFieldsValue({
        province: undefined,
        city: undefined
      });
    }
  }, [
    valDate,
    setCheck,
    data,
    getProvince,
    getCity,
    actionType,
    setFieldsValue
  ]);

  const year = new Date().getFullYear() + 40;
  const until = year - 140;
  const optionYear = [];
  for (let i = year; i >= until; i--) {
    optionYear.push(i);
  }

  const optionDay = [];
  for (let i = 1; i <= 31; i++) {
    optionDay.push(('0' + i).slice(-2));
  }

  const onSuccess = () => {
    if (['KTP', 'NPWP'].includes(id_type)) {
      updatePws({ client, formData: getFieldsValue(), dataPws });
    }
  };

  const handleCheckAndMutation = async (values, _identityFields) => {
    const { data: dataIdentities } = await client.query({
      query: CHECKER_DUPLICATE,
      variables: {
        id: values.id,
        user: AuthenticationService.getUserId(),
        type: values.type
      },
      context: { headers: { 'X-Hasura-Role': 'user' } },
      fetchPolicy: 'no-cache'
    });
    if (dataIdentities) {
      if (dataIdentities.people_identities.length > 0) {
        // add with UPDATE query
        updateWithAdd({
          variables: {
            id: dataIdentities.people_identities[0].id,
            changes: {
              id: values.id,
              // type: values.type,
              identity_fields: _identityFields,
              deletedAt: null
            }
          }
        })
          .then(() => {
            onSuccess();
            refetch();
            handleClose();
            message.success('Berhasil menambahkan kartu identitas');
          })
          .catch(err => {
            getResponseMessage(err);
          });
      } else {
        const submit = {
          type: values.type,
          id: values.id,
          identity_fields: _identityFields
        };
        // add with INSERT query
        insertIdentity({
          variables: submit
        })
          .then(() => {
            onSuccess();
            refetch();
            handleClose();
            message.success('Berhasil menambahkan kartu identitas');
          })
          .catch(err => {
            getResponseMessage(err);
          });
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    await validateFields((error, values) => {
      if (!error) {
        const _identityFields = {
          country: values.country,
          province: {
            id: value.province?.id,
            name: value.province?.name
          },
          city: {
            id: value.city?.id,
            name: value.city?.name
          },
          district: values.district,
          subDistrict: values.sub_district,
          postalCode: values.postalCode,
          address: values.address,
          expiredDate: check
            ? 'Lifetime'
            : values.month
            ? `${values.month} ${values.day}, ${values.year}`
            : null
        };
        if (actionType === 'edit') {
          const submit = {
            id: data.id,
            new_card_id: values.id,
            type: data.type,
            new_type: values.type,
            identityFields: _identityFields
          };
          updateIdentity({
            variables: submit
          })
            .then(() => {
              onSuccess();
              refetch();
              handleClose();
              message.success('Berhasil mengubah kartu identitas');
            })
            .catch(err => {
              getResponseMessage(err, 'mengubah');
            });
        } else {
          handleCheckAndMutation(values, _identityFields);
        }
      }
    });
  };

  const listIdentityType = useMemo(() => getListIdentityType(userIdentity), [
    userIdentity
  ]);

  const listFields = [
    {
      labelName: 'Tipe Identitas',
      fieldName: 'type',
      type: 'select',
      fieldValue: data?.type,
      option: listIdentityType,
      placeholder: 'Pilih tipe identitas',
      required: true
    },
    {
      labelName: 'Nomor Identitas',
      fieldName: 'id',
      type: 'text',
      fieldValue: data?.id,
      placeholder: 'Tambahkan nomor identitas',
      required: true
    },
    {
      labelName: 'Negara',
      fieldName: 'country',
      type: 'select',
      fieldValue: data?.identity_fields?.country,
      placeholder: 'Pilih negara',
      required: !isSelectPrimaryIdentity,
      option: [
        {
          id: 'Indonesia',
          name: 'Indonesia'
        }
      ]
    },
    {
      labelName: 'Provinsi',
      fieldName: 'province',
      type: 'searchable-select',
      fieldValue: getFieldValue('province'),
      option: dataProvince?.global_provinces,
      placeholder: 'Pilih provinsi',
      required: !isSelectPrimaryIdentity,
      loading: loadingProvince,
      onSearch: ({ value }) => {
        getProvince({
          variables: {
            limit: 20,
            where: {
              name: value ? { _ilike: `%${value}%` } : undefined,
              deletedAt: { _is_null: true }
            }
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getProvince({
            variables: {
              limit: 20,
              where: {
                deletedAt: { _is_null: true }
              }
            }
          });
        }
      },
      onChange: (e, data) => {
        setFieldsValue({
          city: undefined,
          province: e
        });
        setValue({
          ...value,
          province: { id: data?.props?.value, name: data?.props?.children },
          city: null
        });
      }
    },
    {
      labelName: 'Kota',
      fieldName: 'city',
      type: 'searchable-select',
      fieldValue: getFieldValue('city'),
      option: dataCity?.global_cities,
      placeholder: 'Pilih kota',
      required: !isSelectPrimaryIdentity,
      loading: loadingCity,
      onSearch: ({ value }) => {
        getCity({
          variables: {
            limit: 20,
            ...globalCitiesSeachableVars({
              province: getFieldValue('province'),
              search: value
            })
          }
        });
      },
      onTouch: ({ isOpen }) => {
        if (isOpen) {
          getCity({
            variables: {
              limit: 20,
              ...globalCitiesSeachableVars({
                province: getFieldValue('province')
              })
            }
          });
        }
      },
      onChange: (e, data) => {
        setFieldsValue({
          city: e
        });
        setValue({
          ...value,
          city: { id: data?.props?.value, name: data?.props?.children }
        });
      }
    },
    {
      labelName: 'Kecamatan',
      fieldName: 'district',
      type: 'text',
      fieldValue: data?.identity_fields?.district,
      placeholder: 'Tambahkan kecamatan',
      required: !isSelectPrimaryIdentity
    },
    {
      labelName: 'Kelurahan',
      fieldName: 'sub_district',
      type: 'text',
      fieldValue: data?.identity_fields?.subDistrict,
      placeholder: 'Tambahkan kelurahan',
      required: !isSelectPrimaryIdentity
    },
    {
      labelName: 'Kode Pos',
      fieldName: 'postalCode',
      type: 'number',
      fieldValue: data?.identity_fields?.postalCode,
      placeholder: 'Tambahkan kode pos',
      required: !isSelectPrimaryIdentity
    },
    {
      labelName: 'Alamat',
      fieldName: 'address',
      type: 'textArea',
      fieldValue: data?.identity_fields?.address ?? null,
      placeholder: 'Tambahkan alamat',
      required: !isSelectPrimaryIdentity
    }
  ];

  const listDate = [
    {
      fieldName: 'day',
      type: 'select-date',
      fieldValue: generateDate(valDate)?.[1] ?? undefined,
      option: optionDay,
      placeholder: 'Tanggal'
    },
    {
      fieldName: 'month',
      type: 'select-date',
      fieldValue: generateDate(valDate)?.[0] ?? undefined,
      option: month,
      placeholder: 'Bulan'
    },
    {
      fieldName: 'year',
      type: 'select-date',
      fieldValue: generateDate(valDate)?.[2] ?? undefined,
      option: optionYear,
      placeholder: 'Tahun'
    }
  ];

  const _switchComponentToRender = res => {
    const {
      type,
      fieldName,
      option,
      placeholder,
      fieldValue,
      loading,
      onSearch,
      onTouch,
      onChange
    } = res;

    if (type === 'select') {
      return (
        <Select
          size="large"
          placeholder={placeholder}
          style={{ width: '100%' }}
        >
          {option &&
            option.map((res, i) => {
              return (
                <Option
                  key={i}
                  data={res}
                  disabled={res.disabled}
                  value={
                    fieldName === 'province' || fieldName === 'city'
                      ? res.name
                      : res.id
                  }
                >
                  {res.name}
                </Option>
              );
            })}
        </Select>
      );
    } else if (type === 'searchable-select') {
      return (
        <SearchableSelect
          fieldValue={fieldValue}
          placeholder={placeholder}
          loading={loading}
          option={option}
          onSearch={onSearch}
          fieldName={fieldName}
          onTouch={onTouch}
          onChange={onChange}
        />
      );
    } else if (type === 'text' || type === 'number') {
      return (
        <InputStyled
          style={{ width: '100%' }}
          type={type}
          placeholder={placeholder}
        />
      );
    } else if (type === 'textArea') {
      return <TextArea rows={6} placeholder={placeholder} />;
    } else if (type === 'select-date') {
      return (
        <Select
          placeholder={placeholder}
          disabled={check}
          style={{ width: '90%' }}
        >
          {option.map((res, i) => (
            <Option
              key={`${i}-idx`}
              value={fieldName === 'month' ? res.short : res}
            >
              {fieldName === 'month' ? res.text : res}
            </Option>
          ))}
        </Select>
      );
    }
  };

  const handleClose = () => {
    onClose();
    resetFields();
    setFieldsValue({
      province: undefined,
      city: undefined
    });
    setCheck(false);
  };

  const rulesMax = type => {
    return [
      {
        required: type === 'id' ? true : !isSelectPrimaryIdentity,
        message: 'Bagian ini diperlukan'
      },
      {
        max: 25,
        message: 'Anda telah melampaui batas karakter maksimum'
      }
    ];
  };

  const handleCheck = e => {
    setCheck(e.target.checked);
    setFields({
      day: {
        errors: null
      },
      month: {
        errors: null
      },
      year: {
        errors: null
      }
    });
  };

  return (
    <ModalEdit
      sizeSmall
      title={`${actionType === 'edit' ? 'Ubah' : 'Tambahkan'} Kartu Identitas`}
      open={open}
      onClose={handleClose}
      onClickDelete={
        actionType === 'edit'
          ? () => setOpen(e => ({ ...e, modalDelete: true, open: false }))
          : null
      }
    >
      <Form onSubmit={handleSubmit}>
        {listFields.length > 0 &&
          listFields.map((res, i) => {
            return (
              <Form.Item
                key={i}
                style={{ padding: '0px 22px', marginBottom: 0 }}
                label={<TitleField>{res.labelName}</TitleField>}
              >
                {getFieldDecorator(res.fieldName, {
                  initialValue: res.fieldValue,
                  rules:
                    res.fieldName === 'id' || res.fieldName === 'postalCode'
                      ? rulesMax(res.fieldName)
                      : [rule(res.required)]
                })(_switchComponentToRender(res))}
              </Form.Item>
            );
          })}
        <div style={{ padding: '0px 22px', marginBottom: 8 }}>
          <Form.Item
            label={<TitleField>Masa Berlaku</TitleField>}
            style={{
              marginBottom: 0
            }}
            required={isSelectPrimaryIdentity ? false : check}
          >
            {listDate.length > 0 &&
              listDate.map((res, i) => {
                return (
                  <Form.Item
                    key={`${i}-idx-field`}
                    style={{
                      display: 'inline-block',
                      marginBottom: 0,
                      width: '33.33%'
                    }}
                  >
                    {getFieldDecorator(res.fieldName, {
                      initialValue: res.fieldValue,
                      rules: check
                        ? [{ required: false }]
                        : [rule(!isSelectPrimaryIdentity)]
                    })(_switchComponentToRender(res))}
                  </Form.Item>
                );
              })}
          </Form.Item>
          <Checkbox
            style={{ marginTop: 12 }}
            checked={check}
            onChange={handleCheck}
          >
            {'Kartu identitas ini masih berlaku'}
          </Checkbox>
        </div>
        <ButtonFrom
          submitLabel="Simpan"
          actionType={actionType}
          onCancel={handleClose}
        />
      </Form>
    </ModalEdit>
  );
};

const EditIdentityCardContainer = Form.create({ name: 'Edit_Identity_Card' })(
  EditIdentityCard
);

export default EditIdentityCardContainer;

const getListIdentityType = userIdentity => {
  const ownedType = userIdentity?.people_identities.map(({ type }) => {
    if (['KTP', 'NPWP'].includes(type)) {
      return type;
    }
  });

  return LIST_TYPE_IDENTITY.map(type => ({
    ...type,
    disabled: ownedType.includes(type.id)
  }));
};
