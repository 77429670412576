import { gql } from 'apollo-boost';

const GET_COMPANY_DETAIL_BOX = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      id
      profile_field
      type
      global_company_industry_type {
        id
        name
      }
      types
    }
    total_employee: people_work_placements_aggregate(
      where: { company: { _eq: $id }, status: { _eq: "ACTIVE" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
const GET_COMPANY_DETAIL_BOX_NEW = gql`
  query getCompany($id: uuid!) {
    main_profile: company_profiles(where: { id: { _eq: $id } }) {
      id
      profile_field
      type
      main_office: company_address {
        id
        office_name
        address
        global_province {
          id
          name
        }
        global_city {
          id
          name
        }
      }
      global_company_industry_type {
        id
        name
      }
      company_legal_type {
        id
        legal_type
        description
      }
      types
    }
    total_employee: people_work_placements_aggregate(
      where: {
        company: { _eq: $id }
        status: { _eq: "ACTIVE" }
        global_user: { status: { _eq: "ACTIVE" }, people_profiles: {} }
        start_date: { _lte: "now()", _gt: null }
        replacement_status: { _eq: false }
        global_placement_termination_id: { _is_null: true }
        company_job_profile: {}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const GET_TOTAL_FOLLOWER_COMPANY = gql`
  query($id: String!) {
    getTotalFollowersCompany(company: $id) {
      total
    }
  }
`;

export {
  GET_COMPANY_DETAIL_BOX,
  GET_COMPANY_DETAIL_BOX_NEW,
  GET_TOTAL_FOLLOWER_COMPANY
};
