import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import MainContainer from './mainContainer';
import { message } from 'antd';
import { checkNextPage, getCookie } from '../../Utils/CommonUtils';

import { CREATE_COMMENT, DELETE_COMMENT } from './classroomCommentPost.graphql';

function CommentListGroup(props) {
  const userData = getCookie('userData')
    ? JSON.parse(getCookie('userData'))
    : {};
  const user = _.isEmpty(userData)
    ? {}
    : {
        id: userData.id,
        avatar: userData.avatar,
        name: userData.name,
        people_work_placements: userData.people_work_placements
      };

  const [commentList, setCommentList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [limit, setLimit] = useState(3);

  const [createComment] = useMutation(CREATE_COMMENT);
  const [deleteComment, { loading: deleteIsLoading }] = useMutation(
    DELETE_COMMENT
  );

  const { data, postId, totalComment, setTotalComment, setIsLoading } = props;

  useEffect(() => {
    data.map(item => {
      if (item.id === postId) {
        setCommentList(item.comment.slice(0, limit));
        if (checkNextPage(item.comment.length, page, limit)) {
          setHasNextPage(true);
        } else {
          setHasNextPage(false);
        }
      }
    });
    // eslint-disable-next-line
  }, [data, page, limit]);

  useEffect(() => {
    setIsLoading(deleteIsLoading);
    // eslint-disable-next-line
  }, [deleteIsLoading]);

  const setFetchMoreComment = () => {
    setPage(page + 1);
    setLimit(limit + 3);
  };

  const _handleDelete = comment => {
    deleteComment({ variables: { commentId: comment.id } })
      .then(() => {
        const commentUpdated = commentList.map(oldComment => {
          if (oldComment.id === comment.id) {
            return { ...oldComment, content: 'comment was deleted' };
          } else {
            return oldComment;
          }
        });
        setCommentList(commentUpdated);
        message.success('Komentar dihapus');
        setTotalComment(totalComment - 1);
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  const _handleSubmit = async content => {
    createComment({ variables: { postId, content } })
      .then(response => {
        const { id } = response.data.classroomPostCreateComment;
        data.map(item => {
          if (item.id === postId) {
            const commentUpdated = [
              ...commentList,
              { id, content, user: { ...user } }
            ];
            setCommentList(commentUpdated);
            setTotalComment(totalComment + 1);
          }
        });
      })
      .catch(() => {
        message.error('Something went wrong');
      });
  };

  return (
    <MainContainer
      commentList={commentList}
      handleDelete={_handleDelete}
      handleSubmit={_handleSubmit}
      hasNextPage={hasNextPage}
      setFetchMoreComment={setFetchMoreComment}
      {...props}
    />
  );
}

export default CommentListGroup;
