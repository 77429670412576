import { gql } from 'apollo-boost';

export const GET_POST_BY_ID = gql`
  query($id: uuid!) {
    classroom_posts(where: { id: { _eq: $id } }) {
      id
      classroom_id
      global_user {
        name
      }
      classroom_learning_tracker_mentees {
        classroom_member {
          global_user {
            id
            name
            avatar
            people_work_placements {
              company_job_profile {
                position
                title
              }
            }
          }
        }
      }
      classroom_post_comments {
        id
        content
        global_user {
          id
          name
          date_added
        }
        date_added
        classroom_post_comment_likes {
          global_user {
            id
            name
          }
        }
      }
      classroom_post_likes {
        global_user {
          name
        }
      }
      created_at
      content
      activity
      duration
      type
      description
      commitment
      topic
      date
      subject
      learning_log_competency {
        id
        title
      }
      people_tags {
        global_user {
          id
          name
        }
      }
      event_schedule {
        id
        type
        category
        title
        slug
        thumbnail
        description
        location_info
        pricing
      }
      location
      classroom_post_files {
        file
        name
        type
        size
      }
      edited_at
      edited_by
      edited_by_user {
        id
        name
        avatar
      }
    }
  }
`;
