import { gql } from 'apollo-boost';

export const UPDATE_CLASSROOM = gql`
  mutation(
    $classroomId: uuid!
    $avatarUrl: String
    $name: String
    $description: String
    $finished: Boolean
    $categoryId: Int
    $resume: String
    $privacy: Int
    $rules: String
  ) {
    update_classrooms(
      _set: {
        privacy: $privacy
        avatar_url: $avatarUrl
        name: $name
        rules: $rules
        description: $description
        is_finished: $finished
        category_id: $categoryId
        resume: $resume
      }
      where: { id: { _eq: $classroomId } }
    ) {
      affected_rows
    }
  }
`;

export const END_SESSION = gql`
  mutation($classroomId: String!, $resume: String!) {
    classroomFinish(classroomId: $classroomId, resume: $resume) {
      id
      message
      success
    }
  }
`;
