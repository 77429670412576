import React, { useState } from 'react';
import { WrapperMessage } from '../ActionMore.style';

export default function MessageAnswer({ message }) {
  const [split, setSplit] = useState(200);
  return (
    <WrapperMessage>
      <p>{message && message.slice(0, split)}</p>
      {message && message.length > 200 && message.length !== split && (
        <span onClick={() => setSplit(message.length)}>
          Tampilkan Lebih Banyak
        </span>
      )}
    </WrapperMessage>
  );
}
