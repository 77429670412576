export default {
  LOGO_WLB: require('./images/logo-wlb.svg'),
  LOGO_INGENIUM_X_WLB: require('./images/IngeniumXlogo-01.png'),
  LOGO_ACHI: require('./images/CULTURE_INDEX.svg'),
  LOGO_CMS: require('./images/CULTURE_MONITORING.svg'),
  LOGO_ADI: require('./images/DIGITAL_INTERNALIZATION.svg'),
  LOGO_GAMIFIKASI: require('./images/GAMIFIKASI.svg'),
  LOGO_COMMUNICATION_COLLABORATION: require('./images/culture-ecosystem/communication-collaboration.svg'),
  LOGO_FEEDBACK: require('./images/culture-ecosystem/feedback.svg'),
  LOGO_WORKING_GROUP: require('./images/culture-ecosystem/working-group.svg'),
  LOGO_LEARNING: require('./images/culture-ecosystem/learning.svg'),
  ICON: {
    HOME_BLUE: require('./images/icon/home-blue.svg'),
    TEAM_ICON: require('./images/icon/hc-ecosystem/team.svg'),
    HOME: require('./images/icon/home.svg'),
    PEOPLE: require('./images/icon/people.svg'),
    PEOPLE_BLUE: require('./images/icon/people-blue.svg'),
    EXPRESSION_DISSATISFIED: require('./images/icon/expression_dissatisfied.svg'),
    EXPRESSION_NEUTRAL: require('./images/icon/expression_neutral.svg'),
    EXPRESSION_STATISFIED: require('./images/icon/expression_satisfied.svg'),
    PEOPLE2: require('./images/icon/people2.svg'),
    MESSAGE: require('./images/icon/message-closed-envelope.svg'),
    MAIL: require('./images/icon/mail.svg'),
    MAIL_GREY: require('./images/icon/mail-grey.svg'),
    FAX: require('./images/icon/fax.svg'),
    NOTIFICATION: require('./images/icon/notifications.svg'),
    NOTIFICATION_BLUE: require('./images/icon/notifications-blue.svg'),
    SEARCH: require('./images/icon/search.svg'),
    SEARCH_NOT_FOUND: require('./images/icon/search-not-found.svg'),
    MORE: require('./images/icon/more.svg'),
    LOVE: require('./images/icon/love.svg'),
    COMMENT: require('./images/icon/comment.svg'),
    SHARE: require('./images/icon/share.svg'),
    STAR: require('./images/icon/star.svg'),
    FOLLOWED: require('./images/icon/followed.svg'),
    STAR_YELLOW: require('./images/icon/star-yellow.svg'),
    WORD_SOLID: require('./images/icon/file-word-solid.svg'),
    EXCEL_SOLID: require('./images/icon/file-excel-solid.svg'),
    POWERPOINT_SOLID: require('./images/icon/file-powerpoint-solid.svg'),
    PDF_SOLID: require('./images/icon/file-pdf-solid.svg'),
    DOWNLOAD: require('./images/icon/download.svg'),
    COMPANY_FOLLOWER: require('./images/icon/people-follower-icon.svg'),
    LEFT_ARROW: require('./images/icon/left-arrow.svg'),
    UP_ARROW: require('./images/icon/arrow-up.svg'),
    DOWN_ARROW: require('./images/icon/arrow-down.svg'),
    CLOSE: require('./images/icon/close.svg'),
    LOCK: require('./images/icon/lock.svg'),
    LOADING: require('./images/icon/loading-icon.gif'),
    EYE: require('./images/icon/status-eye.svg'),
    MONEY_DOLLAR: require('./images/icon/money-dollar.svg'),
    GIFT_CARD: require('./images/icon/status-gift-card.svg'),
    SHIPPING: require('./images/icon/status-shipping.svg'),
    COMPLETED: require('./images/icon/status-checked.svg'),
    LOVE_PINK: require('./images/icon/love-pink.svg'),
    SMARTASN_LOGO_CV: require('./images/icon/Logo_SmartAsn.svg'),
    CV_WLB: require('./images/icon/cv-wlb.svg'),
    TRASH: require('./images/icon/trash.svg'),
    SEND: require('./images/icon/send.svg'),
    PEOPLE_NOT_FOUND: require('./images/icon/people-not-found.svg'),
    TXT_SOLID: require('./images/icon/file.svg'),
    DEFAULT_STORE: require('./images/icon/default-store.svg'),
    DEFAULT_ITEM: require('./images/icon/default-item.svg'),
    VERIFIED: require('./images/icon/verified.svg'),
    SHARE_KUDOS: require('./images/icon/shareKudos.png'),
    BLOCK: require('./images/icon/block.svg'),
    FACEBOOK: require('./images/icon/facebook.svg'),
    FACEBOOK_GREY: require('./images/icon/facebook-grey.svg'),
    TWITTER: require('./images/icon/twitter.svg'),
    TWITTER_GREY: require('./images/icon/twitter-grey.svg'),
    INSTRAGRAM: require('./images/icon/instagram.svg'),
    LINKEDIN: require('./images/icon/linkedin.svg'),
    LINKEDIN_GREY: require('./images/icon/linkedin-grey.svg'),
    TIKTOK: require('./images/icon/tiktok.svg'),
    STAR_FILLED: require('./images/icon/star_filled.svg'),
    STAR_BORDER: require('./images/icon/star_border.svg'),
    STAR_HALF: require('./images/icon/star_half.svg'),
    STAR_FILLED_GREY: require('./images/icon/star_filled_grey.svg'),
    HOLIDAY_VOICE: require('./images/icon/outline-record_voice_over.svg'),
    CHEVRON_LEFT: require('./images/icon/chevron_left.svg'),
    CHEVRON_RIGHT: require('./images/icon/chevron_right.svg'),
    CHECK_CIRCLE: require('./images/icon/check_circle.svg'),
    PHONE_GREY: require('./images/icon/phone-grey.png'),
    CANCEL_WHITE: require('./images/icon/cancel_white.svg'),
    RESTART_ALT_WHITE: require('./images/icon/restart_alt_white.svg'),
    GLOBE_OUTLINED: require('./images/icon/globe.svg')
  },
  DEFAULT_PHOTO: require('./images/photo/default.png'),
  DEFAULT_BANNER: require('./images/photo/default-banner.png'),
  DEFAULT_LOGO: require('./images/photo/default-logo.svg'),
  DEFAULT_GROUP_AVATAR: require('./images/group-logo.png'),
  SKILLS: require('./images/skills.svg'),
  COLOR_PALLETE: {
    black: '#252525',
    black2: '#000000',
    black3: '#0d0d0d',
    black4: '#2c2424',
    grey: '#a9a8a8',
    grey2: '#f7f8f9',
    grey3: '#e5e5e5',
    blue: '#039be5',
    blue_link: '#007fb2',
    white: '#ffffff',
    dark_blue: '#014a62',
    red: '#ef4d5e',
    light_blue: '#eff2f4',
    yellow: '#ffa000',
    green: '#4caf50'
  },
  FONT: {
    MULI: 'Muli-Regular, sans-serif',
    MULI_SEMIBOLD: 'Muli-SemiBold, sans-serif',
    MULI_BOLD: 'Muli-Bold, sans-serif',
    MULI_LIGHT: 'Muli-Light, sans-serif'
  },
  IMAGE: {
    DEFAULT_IMAGE: require('./images/image.png'),
    AVATAR: require('./images/person.png'),
    AVATAR_ALT: require('./images/person_alt.png')
  }
};
