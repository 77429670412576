import gql from 'graphql-tag';

export const LIST_PROVINCE = gql`
  {
    global_provinces(order_by: { name: asc }) {
      id
      code
      name
    }
  }
`;

export const GET_RELIGION_BY_ID = gql`
  query getReligionById($religion: Int) {
    global_religions(where: { id: { _eq: $religion } }) {
      id
      name
    }
  }
`;

export const LIST_CITY = gql`
  query getListCity($where: global_cities_bool_exp, $limit: Int) {
    global_cities(where: $where, order_by: { name: asc }, limit: $limit) {
      id
      code
      name
    }
  }
`;
export const LIST_RELIGIONS = gql`
  query getListReligions {
    global_religions(
      where: { deleted_at: { _is_null: true } }
      order_by: [{ id: asc }]
    ) {
      id
      name
    }
  }
`;
export const LIST_RACE = gql`
  query getGlobalRace($search: String) {
    global_race(
      where: { name: { _ilike: $search } }
      limit: 20
      order_by: { id: asc }
    ) {
      id
      name
    }
  }
`;
export const LIST_DIALECT = gql`
  query($search: String) {
    global_dialects(
      where: { name: { _ilike: $search } }
      limit: 20
      order_by: { id: asc }
    ) {
      id
      name
    }
  }
`;

export const UPDATE_ABOUT = gql`
  mutation(
    $id: uuid!
    $name: String!
    $gender: enum_gender!
    $birthDate: date!
    $religion: Int!
    $maritalStatus: enum_marital_status!
    $data: jsonb!
  ) {
    update_global_users(_set: { name: $name }, where: { id: { _eq: $id } }) {
      affected_rows
    }
    update_people_profile(
      _set: {
        gender: $gender
        birthdate: $birthDate
        religion: $religion
        marital_status: $maritalStatus
      }
      _append: { profile_fields: $data }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const EDUCATION_BY_ID = gql`
  query($id: Int) {
    people_profile_educations(where: { id: { _eq: $id } }) {
      id
      company_profile {
        id
        legal_name
        brand_name
      }
    }
  }
`;
