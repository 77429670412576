import React, { useEffect } from 'react';
import SearchAndFilterComponent from '../../../Components/SearchAndFilterCard';
import { EmployeeListComponent } from '../../../Components';
import { Row, Col } from 'antd';
import { useInfiniteScroll } from '../../../SharedComponents/CustomHook';

const PeopleScreenComponent = props => {
  const [isBottom, setIsBottom] = useInfiniteScroll('list-company-empolyee');
  const { employees, isFetching, onLoadMore, totalEmployee } = props;

  useEffect(() => {
    if (isBottom) {
      onLoadMore();
      setIsBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);
  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <SearchAndFilterComponent
            totalEmployee={totalEmployee}
            dataEmployees={employees}
            {...props}
          />
          <EmployeeListComponent
            idList={'list-company-empolyee'}
            totalColumn={3}
            dataEmployees={employees}
            isFetching={isFetching}
            {...props}
          />
        </Col>
      </Row>
    </>
  );
};

export default PeopleScreenComponent;
