import React, { useState } from 'react';
import { KudosPopupWrapper } from './KudosPopup.style';
import { Kudos } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';
import { DisplayLoading } from '..';

const BodyItem = ({ item }) => {
  return (
    <div className="component_kudos_body_item">
      <div className="component_kudos_body_item_left">
        <div className="component_kudos_body_item_avatar">
          <img src={item?.user?.avatar} alt={'Avatar'} />
        </div>
        <div>
          <div className="component_kudos_avatar_title">{item?.user?.name}</div>
          <div className="component_kudos_avatar_description">
            {item?.user?.biography}
          </div>
        </div>
      </div>
      <div className="component_kudos_badge">
        <div className="component_kudos_badge_image">
          <img src={item?.kudos?.url_image} alt={'Badge'} />
        </div>
        <div className="component_kudos_badge_text">{item?.kudos?.name}</div>
      </div>
    </div>
  );
};

const KudosPostCard = props => {
  const {
    kudosList,
    postId,
    companyId,
    setModalVisibility,
    modalVisibility
  } = props;
  const [selected, setSelected] = useState(0);
  const [peoples, setPeoples] = useState([]);
  const [kudosLoading, setKudosLoading] = useState(false);
  // const [kudosLength, setKudosLength] = useState(0);

  useAsyncEffect(
    async isMounted => {
      setKudosLoading(true);
      const kudosId = selected === 0 ? '' : selected;
      await Kudos.getListKudosPostByCompanyId(companyId, postId, kudosId)
        .then(res => {
          if (!isMounted()) return;
          setPeoples(res?.data);
          // setKudosLength(res?.total);
        })
        // .catch(err => {
        //   // eslint-disable-next-line no-console
        //   console.log('err', err);
        // })
        .finally(() => {
          setKudosLoading(false);
        });
    },
    [companyId, postId, selected]
  );

  return (
    <KudosPopupWrapper modalVisibility={modalVisibility}>
      <div className="component_kudos_content">
        <div className="component_kudos_header">
          <span
            onClick={() => setSelected(0)}
            className={
              'component_kudos_header_item ' +
              ' component_kudos_header_text ' +
              (selected === 0 && 'selected')
            }
          >
            All
          </span>
          {kudosList.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => setSelected(item?.id)}
                className={
                  'component_kudos_header_item ' +
                  (selected === item?.id && 'selected')
                }
              >
                <div className="component_kudos_header_image">
                  <img src={item?.url_image} alt={item?.name} />
                </div>
              </div>
            );
          })}
        </div>
        {kudosLoading ? (
          <DisplayLoading />
        ) : (
          <div className="component_kudos_body">
            {peoples.length === 0 && (
              <div className="component_kudos_empty_kudos">no Kudos yet</div>
            )}
            {selected === 0 &&
              peoples.map((item, i) => {
                return <BodyItem key={i} item={item} />;
              })}
            {selected !== 0 &&
              peoples
                .filter(item => item?.kudos?.id === selected)
                .map((item, i) => {
                  return <BodyItem key={i} item={item} />;
                })}
          </div>
        )}
      </div>
      <div
        className="ant-modal-mask"
        onClick={() => setModalVisibility(false)}
      ></div>
    </KudosPopupWrapper>
  );
};

export default KudosPostCard;
