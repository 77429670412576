import styled from 'styled-components';
import Assets from '../../Assets';

export const CompanyAddressWrapper = styled.div`
  font-family: ${Assets.FONT.MULI};
  height: 400px;
  display: grid;
  grid-template-columns: 224px minmax(0, 1fr);
  grid-gap: 8px;
  padding: 16px 0;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    height: auto;
  }

  .aside {
    overflow-y: auto;
  }

  .search {
    margin-bottom: 16px;
    position: sticky;
    top: 0;
  }

  .main {
    @media (max-width: 1200px) {
      height: 200px;
    }
  }
`;

export const AddressItemWrapper = styled.div`
  background-color: ${props => (props.selected ? '#f7f8f9' : 'transparent')};
  cursor: pointer;
  padding: 12px 0;

  & + & {
    border-top: 1px solid ${Assets.COLOR_PALLETE.grey3};
  }

  .name {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }

  .active {
    color: ${Assets.COLOR_PALLETE.dark_blue};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-top: 4px;
  }

  .address {
    font-size: 14px;
    margin: 0;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }

  .detail {
    all: unset;
    color: ${Assets.COLOR_PALLETE.blue_link};
    margin-top: 4px;
  }

  .detail:hover {
    text-decoration: underline;
  }
`;

export const AddressDetailsHeader = styled.div`
  background-color: ${Assets.COLOR_PALLETE.dark_blue};
  color: ${Assets.COLOR_PALLETE.white};
  font-family: ${Assets.FONT.MULI};
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  margin: -24px -24px 24px;
  border-radius: 4px 4px 0 0;

  span {
    flex-grow: 1;
  }

  .close {
    all: unset;
    cursor: pointer;
    color: currentColor;
  }
`;

export const AddressDetailsBody = styled.div`
  font-family: ${Assets.FONT.MULI};
  text-align: left;

  .maps-container {
    height: 250px;
  }

  .twopane {
    @media (max-width: 1200px) {
      display: block;
    }
    display: grid;
    grid-template-columns: minmax(0, 1fr) 350px;
    grid-gap: 36px;
    margin-top: 24px;
  }

  p {
    margin-bottom: 0;
  }

  p + p {
    margin-top: 4px;
  }

  .header {
    color: ${Assets.COLOR_PALLETE.black};
    font-size: 16px;
    font-weight: 600;
  }

  .office-main {
    color: ${Assets.COLOR_PALLETE.dark_blue};
    font-size: 12px;
    font-weight: 600;
  }

  .office-address {
    color: ${Assets.COLOR_PALLETE.black};
  }

  .office-location,
  .office-postal {
    font-size: 12px;
  }

  .header-mt {
    margin-top: 24px;
  }

  .contact {
    color: ${Assets.COLOR_PALLETE.black};
    display: flex;
    align-items: center;
    margin-top: 12px;

    img {
      height: 22px;
      width: 22px;
      margin-right: 16px;
    }
  }
`;
