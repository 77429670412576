import gql from "graphql-tag";

export const GET_CATEGORY_AND_SUB = gql`
  query {
    hobby_categories(
      where:{
        deletedAt:{_is_null:true}
      }
    ) {
      id
      name
    }

    hobby_sub_categories(
      where:{
        deletedAt:{_is_null:true}
      }
    ) {
      id
      name
    }
  }
`;

export const GET_TYPE = gql`
  query($category: Int, $sub_category: Int) {
    people_hobbies(
      where: {
        category: { _eq: $category }
        sub_category: { _eq: $sub_category }
      }
      order_by: [{ name: asc }]
    ) {
      id
      name
    }
  }
`;

export const ADD_HOBBIES = gql`
  mutation(
    $isShare: Boolean
    $name: String
    $hobby: Int
    $hobbyFields: jsonb
    $description: String
  ) {
    insert_people_profile_hobbies(
      objects: [
        {
          is_share: $isShare
          name: $name
          hobby: $hobby
          hobby_fields: $hobbyFields
          description: $description
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const SET_SHARE_HOBBY = gql`
  mutation($section: Boolean!, $user: uuid!) {
    a: update_people_profile(
      _delete_key: { settings_section: "hobby" }
      where: { id: { _eq: $user } }
    ) {
      affected_rows
    }

    b: update_people_profile(
      _append: { settings_section: "hobby" }
      where: { id: { _eq: $user }, _not: { id: { _is_null: $section } } }
    ) {
      affected_rows
    }
  }
`;

export const EDIT_HOBBIES = gql`
  mutation UpdateHobbies(
    $id: Int!
    $hobby: Int
    $hobby_fields: jsonb
    $description: String
    $isShare: Boolean
  ) {
    update_people_profile_hobbies(
      _set: {
        hobby: $hobby
        is_share: $isShare
        hobby_fields: $hobby_fields
        description: $description
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_HOBBIES = gql`
  mutation($id: Int!) {
    update_people_profile_hobbies(
      _set: { deletedAt: "now()" }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;
