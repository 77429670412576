import React from 'react';
import { DisplayLoading } from '../../../Components';
import { PeopleResultList } from '../../../Containers';
import {
  CategoryLabel,
  SearchCategory,
  SearchContainer
} from '../SearchPage.style';

const PeopleScreenComponent = props => {
  const { searchCategory, peopleList, isLoading } = props;

  const peopleMapped = peopleList.map((people, id) => {
    return <PeopleResultList key={id} person={people} />;
  });
  return (
    <SearchContainer>
      <SearchCategory>
        <CategoryLabel>{searchCategory}</CategoryLabel>
      </SearchCategory>
      {peopleMapped}
      {isLoading && <DisplayLoading />}
    </SearchContainer>
  );
};

export default PeopleScreenComponent;
