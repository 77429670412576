import { gql } from 'apollo-boost';

export const GET_REVIEW_LIST = gql`
  query($classroomId: uuid!) {
    classroom_reviews(where: { classroom_id: { _eq: $classroomId } }) {
      id
      star
      description
      global_user {
        id
        avatar
        name
      }
    }
  }
`;
