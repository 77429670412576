import { gql } from 'apollo-boost';

export const MEMBER_REQUEST_LIST = gql`
  query($classroom_id: uuid!, $limit: Int, $offset: Int) {
    classroom_member_requests(
      where: { classroom_id: { _eq: $classroom_id }, status: { _eq: 0 } }
      limit: $limit
      offset: $offset
      order_by: { created_at: desc_nulls_last }
    ) {
      status
      global_user {
        people_work_placements {
          company_job_profile {
            title
          }
        }
        id
        avatar
        name
        email
      }
      created_at
    }
  }
`;
