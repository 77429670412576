import React, { useState } from 'react';
import 'antd/dist/antd.css';
import CompanyScreenComponent from './CompanyScreen.component';
import { message } from 'antd';
import { CompanyGlobal } from '../../../Services';
import queryString from 'query-string';
import { SearchNotFound, DummySpace } from '../../../Components';
import { useAsyncEffect } from 'use-async-effect';
import useInfiniteScroll from '../../../SharedComponents/CustomHook/useInfiniteScroll';
import { checkNextPage } from '../../../Utils/CommonUtils';

const CompanyScreenContainer = props => {
  const queryWord = queryString.parse(props.location.search).keyword;
  const [companyResult, setCompanyResult] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isBottom, setIsBottom] = useInfiniteScroll('companySearch');
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);

  useAsyncEffect(
    async isMount => {
      if (hasNextPage && queryWord && !isFetching && (isBottom || page === 1)) {
        setIsFetching(true);
        await CompanyGlobal.getListCompany(queryWord, page)
          .then(res => {
            if (!isMount()) return;
            const { per_page, total, data } = res;
            const isNextPage = checkNextPage(total, page, per_page);

            setHasNextPage(isNextPage);
            if (isNextPage) {
              setPage(page + 1);
            }

            setCompanyResult(companyResult.concat(data));
          })
          .catch(err => {
            message.error('Something Went Wrong');
          })
          .finally(() => {
            if (!isMount()) return;
            setIsFetching(false);
            setIsBottom(false);
          });
      }
    },
    [
      queryWord,
      isFetching,
      hasNextPage,
      isBottom,
      page,
      companyResult,
      setIsBottom
    ]
  );
  //console.log(isFetching, queryWord);
  return (
    <div id={'companySearch'}>
      {companyResult.length === 0 && !isFetching ? (
        <SearchNotFound
          title={`We could not find  "${queryWord}"`}
          desc={
            'Nothing came up for that search. Try shortening or rephrasing your search.'
          }
        />
      ) : (
        <CompanyScreenComponent
          {...props}
          isFetching={isFetching}
          companyList={companyResult}
        />
      )}
      <DummySpace />
    </div>
  );
};

export default CompanyScreenContainer;
