import styled from 'styled-components';

const UserCardWrapper = styled.div`
  width: 377px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 16px;
  padding: 0 16px;

  .user-content.vertical {
    flex-direction: column;
  }

  .user-content.horizontal {
    flex-direction: row;
  }

  .user-content {
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 31px 0px;
  }

  .user-card {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;

const UserContent = styled.div`
  width: 140.2px;
  .ant-card-meta-detail > div:not(:last-child) {
    padding-bottom: 0px;
  }

  /* .ant-btn{
    width: 140.2px;
    height: 26.3px;
    background-color: #014a62;
    border-radius: 0px;
    font-family: "Muli-SemiBold";
    color: #ffffff;
    font-size: 12px;
  } */
`;

const UserInformation = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  font-family: 'Muli-SemiBold';
  letter-spacing: normal;
  text-align: left;
  color: #a9a8a8;
  margin-bottom: 38px;

  .position {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
  }

  .user-title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const AvatarWrapper = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px;
`;

export { UserCardWrapper, UserContent, UserInformation, AvatarWrapper };
