import React, { useEffect, useState } from 'react';
import VendorProductListComponent from './VendorProductList.component';
import { useQuery } from '@apollo/react-hooks';
import { GET_ALL_PRODUCT } from './VendorProductGraphQl';

const VendorProductListContainer = props => {
  const { companyId } = props;

  //product by showcase
  const [showCase, setShowCase] = useState(0);
  const [productByShowcase, setProductByShowcase] = useState([]);
  const [limitShowcase, setLimitShowcase] = useState(10);
  const [offsetShowcase, setOffsetShowcase] = useState(0);
  const [totalShowcaseProduct, setTotalShowcaseProduct] = useState(0);

  const showcaseOption = showCase !== 0 ? { showcaseId: showCase } : {};
  let variableProductByShowcase = {
    vendor: companyId,
    limit: limitShowcase,
    offset: offsetShowcase,
    archivedStatus: false
  };
  variableProductByShowcase = {
    ...variableProductByShowcase,
    ...showcaseOption
  };
  const OPTIONS_SHOWCASE = {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-administrator'
      }
    },
    variables: variableProductByShowcase,
    notifyOnNetworkStatusChange: true,
  };

  const showcaseProductList = useQuery(GET_ALL_PRODUCT, OPTIONS_SHOWCASE);
  const {
    data: showcaseProduct,
    loading: showcaseProductLoading,
    fetchMore: showcaseFetchMore,
    refetch
  } = showcaseProductList;

  useEffect(() => {
    if (showcaseProduct) {
      setProductByShowcase(showcaseProduct.v_marketplace_product);
      setTotalShowcaseProduct(showcaseProduct.total.aggregate.count);
    }
  }, [showcaseProduct]);

  const changeShowcaseFilter = val => {
    setOffsetShowcase(0);
    setLimitShowcase(10);
    setShowCase(val);
  };
  const cursorPaginationShowcase = newOffset => {
    showcaseFetchMore({
      variables: {
        offset: newOffset,
        vendor: companyId,
        limit: limitShowcase,
        archivedStatus: false
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevResult;
        }
        return Object.assign({}, prevResult, {
          marketplace_products: [...fetchMoreResult.v_marketplace_product]
        });
      }
    });
    setOffsetShowcase(newOffset);
  };

  //all Product
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [productList, setProductList] = useState([]);

  const variablesAllProduct = {
    vendor: companyId,
    limit: limit,
    offset: offset,
    archivedStatus: false
  };
  const OPTIONS = {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-administrator'
      }
    },
    variables: variablesAllProduct,
    notifyOnNetworkStatusChange: true,
  };

  const allProductList = useQuery(GET_ALL_PRODUCT, OPTIONS);
  const {
    data: dataProduct,
    loading: allProductLoading,
    fetchMore,
    refetch: refetchProduct
  } = allProductList;

  useEffect(() => {
    refetchProduct();
  }, [refetchProduct]);

  useEffect(() => {
    if (dataProduct) {
      setProductList(dataProduct.v_marketplace_product);
      setTotalProduct(dataProduct.total.aggregate.count);
    }
  }, [dataProduct]);
   const cursorPagination = newOffset => {
     fetchMore({
       variables: {
         offset: newOffset,
         vendor: companyId,
         limit: limit,
         archivedStatus: false
       },
       updateQuery: (prevResult, { fetchMoreResult }) => {
         if (!fetchMoreResult) {
           return prevResult;
         }
         return Object.assign({}, prevResult, {
           marketplace_products: [...fetchMoreResult.v_marketplace_product]
         });
       }
     });
     setOffset(newOffset);
   };

  //all archive
  const [limitArchive, setLimitArchive] = useState(10);
  const [offsetArchive, setOffsetArchive] = useState(0);
  const [totalProductArchive, setTotalProductArchive] = useState(0);
  const [productArchiveList, setProductArchiveList] = useState([]);

  const variablesAllArchiveProduct = {
    vendor: companyId,
    limit: limitArchive,
    offset: offsetArchive,
    archivedStatus: true
  };
  const OPTIONS_ARCHIVE = {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-administrator'
      }
    },
    variables: variablesAllArchiveProduct,
    notifyOnNetworkStatusChange: true,
  };

  const allProductArchiveList = useQuery(GET_ALL_PRODUCT, OPTIONS_ARCHIVE);
  const {
    data: dataArchive,
    loading: allArchiveLoading,
    fetchMore: fetchMoreArchive,
    refetch: refetchArchive
  } = allProductArchiveList;

  useEffect(() => {
    refetchArchive();
  }, [refetchArchive]);

  useEffect(() => {
    if (dataArchive) {
      setProductArchiveList(dataArchive.v_marketplace_product);
      setTotalProductArchive(dataArchive.total.aggregate.count);
    }
  }, [dataArchive]);

   const cursorPaginationArchive = newOffset => {
     fetchMoreArchive({
       variables: {
         offset: newOffset,
         vendor: companyId,
         limit: limit,
         archivedStatus: true
       },
       updateQuery: (prevResult, { fetchMoreResult }) => {
         if (!fetchMoreResult) {
           return prevResult;
         }
         return Object.assign({}, prevResult, {
           marketplace_products: [...fetchMoreResult.v_marketplace_product]
         });
       }
     });
     setOffset(newOffset);
   };

   const loadQuery=()=>{
     refetchArchive()
     refetchProduct()
     refetch()
   }
  return (
    <VendorProductListComponent
      showcaseProductLoading={showcaseProductLoading}
      setSelectedShowCase={changeShowcaseFilter}
      selectedShowCase={showCase}
      showCaseList={productByShowcase}
      totalShowcaseProduct={totalShowcaseProduct}
      limitShowcase={limitShowcase}
      offsetShowcase={offsetShowcase}
      changeLimitShowcaseProduct={setLimitShowcase}
      productList={productList}
      isLoadingProductList={allProductLoading}
      changeLimitProduct={setLimit}
      cursorPagination={cursorPagination}
      cursorPaginationShowcase={cursorPaginationShowcase}
      offset={offset}
      limit={limit}
      totalProduct={totalProduct}
      cursorPaginationArchive={cursorPaginationArchive}
      offsetArchive={offsetArchive}
      limitArchive={limitArchive}
      totalArchiveProduct={totalProductArchive}
      productArchiveList={productArchiveList}
      allArchiveLoading={allArchiveLoading}
      changeLimitArchive={setLimitArchive}
      loadQuery={loadQuery}
      {...props}
    />
  );
};

export default VendorProductListContainer;
