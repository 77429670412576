import React from 'react';
import { Col, Row } from 'antd';
import { PostList, SidebarGroupInfoBox, Widget } from '../../../Containers';
import { Content, Container } from '../TeamHomePage.style';
import Constants from '../../../Config/Constants';
import { CenterLayout } from '../../../Components';
import PageWrapper from '../PageWrapper.component';

const DetailPostComponent = props => {
  return (
    <Content>
      <Container>
        <CenterLayout>
          <PageWrapper>
            {/* <DetailPostScreen
                  location={location}
                  isAdmin={isAdmin}
                  isRequest={isRequest}
                  setIsRequest={setIsRequest}
                  isJoined={isJoined}
                  isPaid={isPaid}
                  blockedMe={blockedMe}
                  isFinished={isFinished}
                  isIndividual={isIndividual}
                  contribData={contribData}
                  {...props}
                />
                ; */}
            <Row gutter={16} style={{ marginTop: '15px' }}>
              <Col span={6}>
                <SidebarGroupInfoBox {...props} />
              </Col>
              <Col id={'classroom-post'} span={12}>
                <PostList
                  postType={Constants.POST_TYPE.TEAM}
                  listType={'detail-post'}
                  {...props}
                />
              </Col>
              <Col span={6}>
                <Widget {...props} />
              </Col>
            </Row>
          </PageWrapper>
        </CenterLayout>
      </Container>
    </Content>
  );
};

export default DetailPostComponent;
