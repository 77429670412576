import React, { useState } from 'react';
import config from './SpecificFollower.config';
import SpecificFollowerComponent from './SpecificFollower.component';
import { ConnectionService, AuthenticationService } from '../../Services';
import { useAsyncEffect } from 'use-async-effect';

const SpecificFollowerContainer = props => {
  const { handlePost, setIsShowModal, canSee, setFocusPost } = props;
  const [search, setSearch] = useState('');
  const [followers, setFollowers] = useState([]);
  const [checked, setChecked] = useState(canSee);
  const [isFetching, setIsFetching] = useState(false);
  const userId = AuthenticationService.getUserId();

  useAsyncEffect(
    async isMount => {
      setIsFetching(true);
      await ConnectionService.getListFollowers(userId, 1, search).then(item => {
        if (!isMount()) return;
        setFollowers(item.data);
        setIsFetching(false);
      });
    },
    [search, userId]
  );

  const _addCheckedFollower = follower => {
    setChecked(checked.concat(follower));
  };

  const _removeCheckedFollower = follower => {
    const checkedFollower = checked.filter(user => {
      return user.id !== follower.id;
    });
    setChecked(checkedFollower);
  };

  const handleChangeCheckBox = (followerSelected, value) => {
    if (value) {
      return _addCheckedFollower(followerSelected);
    } else {
      _removeCheckedFollower(followerSelected);
    }
  };

  const saveFollowers = () => {
    handlePost('canSee', checked);
    setIsShowModal(false);
    setFocusPost(true);
  };

  const resetFollowers = () => {
    setIsShowModal(false);
    setChecked(canSee);
  };
  return (
    <SpecificFollowerComponent
      checkedFollowers={checked}
      followers={followers}
      handleChangeCheckBox={handleChangeCheckBox}
      saveFollowers={saveFollowers}
      resetFollowers={resetFollowers}
      setSearch={setSearch}
      isFetching={isFetching}
      {...props}
    />
  );
};

SpecificFollowerContainer.propTypes = config.propTypes;
SpecificFollowerContainer.defaultProps = config.defaultProps;

export default SpecificFollowerContainer;
