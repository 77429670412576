import React from 'react';
import { ModalEdit } from '../../../../Components';
import { Tabs, Empty, Skeleton } from 'antd';
import ContentRecommendation from './ContentRecommendation';
import { WrapperData, ContentWrapperDetail } from './Recommendation.style';

export default function MoreRecommendationTab({
  open,
  userName,
  onClose,
  dataRecaived,
  dataSent
}) {
  const { TabPane } = Tabs;

  return (
    <ModalEdit
      title={`Rujukan ${userName}`}
      open={open}
      onClose={onClose}
      padding="0px"
      centered
    >
      <ContentWrapperDetail>
        <Tabs defaultActiveKey="1">
          <TabPane tab={`Diterima (${dataRecaived.length})`} key="1">
            <WrapperData maxHeight="500px">
              {dataRecaived.length > 0
                ? dataRecaived.map((recommendation, i) => {
                    if (recommendation.status === 'PUBLISH') {
                      return (
                        <ContentRecommendation
                          key={i}
                          lastItem={i + 1 === dataRecaived.length}
                          recommendation={recommendation}
                        />
                      );
                    } else {
                      return <Empty style={{ margin: '60px 0px' }} />;
                    }
                  })
                : null}
            </WrapperData>
          </TabPane>
          <TabPane
            tab={`Dikirimkan (${dataSent?.people_profile_recommendations.length})`}
            key="2"
          >
            <WrapperData maxHeight="500px">
              {dataSent ? (
                dataSent.people_profile_recommendations.length > 0 ? (
                  dataSent.people_profile_recommendations.map(
                    (recommendation, i) => {
                      if (recommendation.status === 'PUBLISH') {
                        return (
                          <ContentRecommendation
                            type="sent"
                            key={i}
                            lastItem={
                              i + 1 ===
                              dataSent.people_profile_recommendations.length
                            }
                            recommendation={recommendation}
                          />
                        );
                      } else {
                        return null;
                      }
                    }
                  )
                ) : (
                  <Empty style={{ margin: '60px 0px' }} />
                )
              ) : (
                <Skeleton active avatar paragraph={{ rows: 4 }} />
              )}
            </WrapperData>
          </TabPane>
        </Tabs>
      </ContentWrapperDetail>
    </ModalEdit>
  );
}
