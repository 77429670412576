import baseService from '../BaseService';
import AuthenticationService from '../Auth';
import Constants from '../../Config/baseUrl';

const userId = AuthenticationService.getUserId();

const getUserInfo = profileId => {
  const url = Constants.USER_ID(profileId);
  return baseService.get(url);
};

const getUserSearch = query => {
  return baseService.get(`users?query=${query}`);
};

const getListBlockedUsers = (page, maxResults) => {
  let parameters = {
    params: {
      page: page,
      maxResults: maxResults
    }
  };
  const url = Constants.USER_BLOCK(userId);
  return baseService.get(url, parameters);
};
const getListUserBlockedMe = () => {
  const url = Constants.USER_BLOCK_ME(userId);
  return baseService.get(url);
};
const addBlockedUser = blockedId => {
  const url = Constants.USER_BLOCK(userId);
  return baseService.post(url, { user_id: blockedId });
};
const deleteBlockedUser = blockedId => {
  const url = `${Constants.USER_BLOCK(userId)}/${blockedId}`;
  return baseService.delete(url);
};

const whoCanSeeMyFollow = setting => {
  const url = Constants.USER_CAN_SEE_MY_FOLLOW(userId);
  return baseService.put(url, {
    who_can_see_my_follow: setting ? 0 : 1
  });
};

export default {
  getUserSearch,
  getListBlockedUsers,
  addBlockedUser,
  deleteBlockedUser,
  getUserInfo,
  getListUserBlockedMe,
  whoCanSeeMyFollow
};
