import React from 'react';

import config from './NotificationPage.config';
import {Enhancer} from '../../SharedComponents';

const NotificationPageContainer = () => (<div />);

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.menuBarList,
  defaultMenu: config.defaultMenuBar
})(NotificationPageContainer);
