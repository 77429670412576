import React from "react";
import { WrapperContact } from "./AnotherContact.style";
import Assets from "../../../Assets";
import ListItem from "./ListItem";

const style = {
  width: 26,
  height: 26,
};

const generateIcon = (name) => {
  switch (name) {
    case "Facebook":
      return Assets.ICON.FACEBOOK;
    case "Twitter":
      return Assets.ICON.TWITTER;
    case "Instagram":
      return Assets.ICON.INSTRAGRAM;
    case "LinkedIn":
      return Assets.ICON.LINKEDIN;
    case "TikTok":
      return Assets.ICON.TIKTOK;
    default:
      return Assets.ICON.FACEBOOK;
  }
};

export default function ListContact({
  onClickEdit,
  data,
  isUser,
  onClickVisibility,
  onClickSetMain,
}) {
  if (data) {
    const name = data[0].global_social_medium.name;
    let isVisibleForGuest = false 
    data.forEach((res) => {
      if (res.is_visible) {
        isVisibleForGuest = true
      }
    });
    if (!isUser && !isVisibleForGuest) {
      return "";
    } else {
      return (
        <WrapperContact>
          <div className="header">
            <img className="icon" src={generateIcon(name)} style={style} />
            <h2 className="name">{name}</h2>
          </div>
          {data &&
            data.map((res, i) => {
              if (!isUser && !res.is_visible) return "";
              else {
                return (
                  <ListItem
                    key={`${i}`}
                    userName={res.name}
                    url={res.url}
                    onClickVisibility={() => onClickVisibility(res.id, res.is_visible)}
                    onClickEdit={() => onClickEdit(res)}
                    onClickSetMain={() => onClickSetMain(res.id, res.global_social_medium && res.global_social_medium.id)}
                    isUser={isUser}
                    isMain={res.is_main}
                    isVisible={res.is_visible}
                  />
                );
              }
            })}
        </WrapperContact>
      );
    }
  }
}
