import React from 'react';
import {
  TopSettingGroup,
  MemberListClassroom,
  DummySpace,
  ModalAddContributor,
  ModalConfirmation
} from '../../Components';
import { MemberCardBoxWrap } from './MemberCardBox.style';
import { DisplayLoading } from '../../Components';
import { isEmpty } from 'lodash';
import { EmptyState } from '@worklifebeyond/wlb-utils-components';
import { capitalize } from '../../Utils/CommonUtils';

import ButtonWlb from '../../Components/ButtonWlb';

const MemberCardBoxComponent = props => {
  const {
    admin,
    mentor,
    member,
    requestMember,
    handleSetAsAdmin,
    handleSetAsMember,
    handleLeaveMember,
    handleKickMember,
    memberLoading,
    isAdmin,
    contribData,
    openModalContrib,
    setOpenModalContrib,
    onSubmitContrib,
    modalConfirm,
    clearModalConfirm,
    mutationLearningContrib,
    contribCandidates,
    loadingContribCandidate,
    setSearchContrib,
    onCancelContrib,
    friendshipLoading,
    onFollowUser
  } = props;
  const classroomId = props.match.params.id;
  let seeAll = '';

  if (props.location.state) {
    seeAll = props.location.state.active;
  }

  const _renderEmptyMember = () => {
    return <div className="component_no_member">No Member</div>;
  };

  const _seeAll = title => {
    return (
      <>
        <hr />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 40
          }}
        >
          <p
            className="request"
            onClick={() =>
              props.history.push(`/social-learning/${classroomId}/member`, {
                active: `see-all-${title.toLowerCase()}`
              })
            }
          >
            See All {title}
          </p>
        </div>
      </>
    );
  };

  const RequestMember = () => {
    return (
      <div className="box">
        <TopSettingGroup
          title={`Permintaan Anggota (${requestMember.length})`}
        />
        <MemberListClassroom
          type="request"
          dataMember={requestMember.slice(0, 5)}
          handleSetAsAdmin={handleSetAsAdmin}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          friendshipLoading={friendshipLoading}
          onFollowUser={onFollowUser}
          {...props}
        />
        {requestMember.length > 5 && _seeAll('Request')}
      </div>
    );
  };

  const DataAdmin = props => {
    const title = props && props.mentor ? 'Kontributor Pembelajaran' : 'Admin';
    const memberLength =
      !memberLoading && !props.mentor
        ? `(${admin.length})`
        : `(${mentor.length})`;
    return (
      <div className="box">
        <TopSettingGroup title={`${title} ${memberLength}`} />
        <MemberListClassroom
          type={props.mentor ? 'mentor' : 'admin'}
          dataMember={props.mentor ? mentor : admin.slice(0, 5)}
          handleSetAsMember={handleSetAsMember}
          handleSetAsAdmin={handleSetAsAdmin}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          isAdmin={isAdmin}
          friendshipLoading={friendshipLoading}
          onFollowUser={onFollowUser}
          {...props}
        />

        {(mentor.length > 5 || admin.length > 5) && _seeAll('Admin')}
      </div>
    );
  };

  const DataContributor = props => {
    const isAllowAdd =
      (contribData.classroom_contrib_requests.length === 0 ||
        contribData?.classroom_contrib_requests[0]?.status === 2 ||
        contribData?.classroom_contrib_requests[0]?.status === 3) &&
      isAdmin;

    return (
      <div className="box">
        <TopSettingGroup
          title="Kontributor Pembelajaran"
          buttonTop={
            isAllowAdd && (
              <ButtonWlb
                type="primary"
                onClick={() => setOpenModalContrib(true)}
              >
                Tambahkan Kontributor Pembelajaran Baru
              </ButtonWlb>
            )
          }
        />
        {contribData?.classroom_contrib_requests?.length > 0 ? (
          <MemberListClassroom
            type="contributor"
            dataMember={
              contribData?.classroom_contrib_requests.slice(0, 1) || []
            }
            handleSetAsAdmin={handleSetAsAdmin}
            handleKickMember={handleKickMember}
            handleLeaveMember={handleLeaveMember}
            onCancelContrib={onCancelContrib}
            {...props}
          />
        ) : (
          <div style={{ padding: 20 }}>
            <EmptyState
              message1="Maaf, tidak ada daftar"
              message2="Saat ini tidak ada kontributor pembelajaran, silakan tambahkan kontributor pembelajaran terlebih dahulu"
              width="313"
            />
          </div>
        )}
      </div>
    );
  };

  const _renderDataMember = () => {
    const memberLength = !memberLoading ? `(${member.length})` : '';
    const _seeAllTitle = seeAll.split('-');

    const see_all_label = {
      request: 'permintaan',
      admin: 'admin',
      member: 'anggota'
    }

    const label = _seeAllTitle[_seeAllTitle.length - 1]

    const title = seeAll
      ? see_all_label[label] || label
      : `Peserta ${memberLength}`;

    const renderList = () => {
      switch (seeAll) {
        case 'see-all-request':
          return { type: 'request', data: requestMember };
        case 'see-all-admin':
          return { type: 'admin', data: admin };
        default:
          return { type: 'member', data: member };
      }
    };

    return (
      <div className="box">
        {seeAll ? (
          <TopSettingGroup
            title={`${capitalize(title)} ${title === 'request' && 'Member'}`}
            onBack={() =>
              props.history.push(`/social-learning/${classroomId}/member`)
            }
          />
        ) : (
          <TopSettingGroup title={capitalize(title)} />
        )}
        <MemberListClassroom
          type={renderList().type}
          dataMember={renderList().data}
          handleSetAsMember={handleSetAsMember}
          handleKickMember={handleKickMember}
          handleLeaveMember={handleLeaveMember}
          enableSearch
          {...props}
        />
        {memberLoading && <DisplayLoading />}
        {isEmpty(renderList().data) && !memberLoading && _renderEmptyMember()}
      </div>
    );
  };

  return (
    <MemberCardBoxWrap>
      {!seeAll && isAdmin && !isEmpty(requestMember) && <RequestMember />}
      {!seeAll && <DataAdmin />}
      {!seeAll && <DataContributor />}
      <div id="group-member">{_renderDataMember()}</div>
      <DummySpace />
      <ModalAddContributor
        open={openModalContrib}
        onClose={() => setOpenModalContrib(false)}
        listContributor={contribCandidates?.global_users || []}
        loadingListContributor={loadingContribCandidate}
        onSubmit={onSubmitContrib}
        setSearchContrib={setSearchContrib}
      />
      <ModalConfirmation
        open={modalConfirm.open}
        title={modalConfirm.title}
        content={modalConfirm.content}
        textPrimary={modalConfirm.textPrimary}
        onCancel={clearModalConfirm}
        onSubmit={mutationLearningContrib}
        alert={modalConfirm.isAlert}
      />
    </MemberCardBoxWrap>
  );
};

export default MemberCardBoxComponent;
