import Constants from '../../../Config/Constants';
import styled from 'styled-components';
import ASSETS from '../../../Assets';

export default {
  container: {
    backgroundColor: Constants.COLORS.WHITE,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW
  }
};

export const SideBarWrapper = styled.div`
  font-family: ${ASSETS.FONT.MULI};
  .header {
    padding-top: 25px;
    padding-left: 25px;
    padding-bottom: 10;
    color: ${Constants.COLORS.DARK_BLUE};
    font-weight: bold;
  }
  .menu {
    /* background: pink; */
  }
`;
