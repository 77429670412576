import React from 'react';
import { TopSettingClassroom, CollapseClassroom } from '../../Components';

const SettingClassroomBoxComponent = props => {
  const { title, buttonTop } = props;
  return (
    <div>
      <TopSettingClassroom title={title} buttonTop={buttonTop} {...props} />

      <CollapseClassroom title={title} {...props} />
    </div>
  );
};

export default SettingClassroomBoxComponent;
