import { Modal } from 'antd';
import Button from 'antd/es/button';
import React from 'react';
import './DeleteModal.style.css';
import config from './DeleteModal.config';

const DeleteModalComponent = props => {
  const {
    modalTitle,
    modalContent,
    displayModal,
    closeModal,
    wording,
    handleDelete
  } = props;
  //console.log(closeModal, displayModal)
  return (
    <Modal
      className={'confirmation-modal'}
      visible={displayModal}
      closable={true}
      title={<label className={'modal-title'}>{modalTitle}</label>}
      content={null}
      onCancel={closeModal}
      footer={
        <div className={'button-wrapper'}>
          <Button id={'cancel-button'} key="back" onClick={closeModal}>
            Batalkan
          </Button>
          <Button id={'delete-button'} key="submit" onClick={handleDelete}>
            {wording}
          </Button>
        </div>
      }
    >
      <p id={'modal-content'}>{modalContent}</p>
    </Modal>
  );
};

DeleteModalComponent.propTypes = config.propTypes;
DeleteModalComponent.defaultProps = config.defaultProps;

export default DeleteModalComponent;
