import baseService from '../BaseService';
import { isObjectEmpty } from '../../Utils/CommonUtils';
import AuthenticationService from '../Auth/AuthenticationService';
import Constants from '../../Config/baseUrl';

const setupHeader = (page, querySearch) => {
  let parameters = {
    params: {
      query: querySearch,
      page: page
    }
  };

  if (isObjectEmpty(querySearch)) {
    delete parameters.params.query;
  }

  if (isObjectEmpty(page)) {
    delete parameters.params.page;
  }

  const userId = AuthenticationService.getUserId();
  return { parameters, userId };
};

const fetchFollowedPeopleByUserId = (page = 1, querySearch = '') => {
  let { parameters, userId } = setupHeader(page, querySearch);

  const followingUrl = `${Constants.FRIENDSHIP(userId)}following`;

  return baseService.get(followingUrl, parameters);
};

const fetchFollowerByUserId = (page, querySearch = '') => {
  let { parameters, userId } = setupHeader(page, querySearch);
  const followingUrl = `${Constants.FRIENDSHIP(userId)}followers`;

  return baseService.get(followingUrl, parameters);
};

const unFolloweUser = followedUser => {
  const payload = { userId: followedUser };
  const userId = AuthenticationService.getUserId();
  const unfollowUrl = `${Constants.FRIENDSHIP(userId)}unfollow`;

  return baseService.post(unfollowUrl, payload);
};

const followUser = followedUser => {
  const payload = { userId: followedUser };
  const userId = AuthenticationService.getUserId();
  const hitUrl = `${Constants.FRIENDSHIP(userId)}follow`;

  return baseService.post(hitUrl, payload);
};

const totalFollowerFollowing = () => {
  const userId = AuthenticationService.getUserId();
  const getTotalFollowerFollowing = `${Constants.FRIENDSHIP(userId)}detail`;
  return baseService.get(getTotalFollowerFollowing);
};

export default {
  fetchFollowedPeopleByUserId,
  fetchFollowerByUserId,
  unFolloweUser,
  followUser,
  totalFollowerFollowing
};
