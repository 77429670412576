import React from 'react';

const ImageCoverPreview = props => {
  const { type, source } = props;
  return (
    <>
      <p>Preview :</p>
      {type === 'cover' ? (
        <img
          src={source[0]?.response}
          style={{ width: '100%', height: 240 }}
          alt={'preview'}
        />
      ) : (
        <img
          src={source[0]?.response}
          style={{ width: '150px', borderRadius: '50%' }}
          alt={'preview'}
        />
      )}
    </>
  );
};

export default ImageCoverPreview;
