import React, { useEffect } from 'react';
import PrintInvoiceComponent from './PrintInvoice.component';
import { useQuery } from 'react-apollo';
import { useParams } from 'react-router-dom';
import {
  GET_ORDER_DETAIL
  // UPDATE_ORDER_STATUS
} from '../OrderDetail/OrderDetail.graphql';
// import { message } from 'antd';

const context = { headers: { 'X-Hasura-Role': 'organization-administrator' } };

const Index = props => {
  const params = useParams();
  const OPTIONS2 = {
    context: context,
    variables: {
      id: params.id
    }
  };
  const { data: dataOrderDetail, loading: detailLoading, error } = useQuery(
    GET_ORDER_DETAIL,
    OPTIONS2
  );
  if (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }

  useEffect(() => {
    if (dataOrderDetail) {
      window.print();
    }
  }, [dataOrderDetail]);

  return (
    <>
      {!detailLoading && (
        <PrintInvoiceComponent
          dataOrderDetail={dataOrderDetail}
          detailLoading={detailLoading}
        />
      )}
    </>
  );
};

export default Index;
