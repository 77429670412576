import gql from 'graphql-tag';

export const GET_ORG_UNIT_CHILDREN = gql`
  query(
    $organizationWhere: company_organizations_bool_exp
    $profileWhere: company_job_profiles_bool_exp
    $placementWhere: people_work_placements_bool_exp
  ) {
    organizations: company_organizations(where: $organizationWhere) {
      id
      name
      parent
      as_assistant
      line_type
      organization_level {
        id
        name
        color_hex
      }
      company_job_profiles(where: $profileWhere) {
        id
        title
        people_work_placements(where: $placementWhere) {
          id
          regno
          company
          deletedAt
          global_user {
            id
            name
            avatar
          }
        }
      }
      organization_children_aggregate(limit: 1) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_ORG_DETAIL_CHILDREN = gql`
  query(
    $profileWhere: company_job_profiles_bool_exp
    $childrenWhere: company_job_profiles_bool_exp
  ) {
    profiles: company_job_profiles(where: $profileWhere) {
      id
      title
      parent
      as_assistant
      line_type
      company_employee_rank {
        id
        name
        color_hex
      }
      people_work_placements(
        where: {
          status: { _eq: "ACTIVE" }
          global_user: { status: { _eq: "ACTIVE" } }
          deletedAt: { _is_null: true }
        }
      ) {
        id
        regno
        global_user {
          id
          name
          avatar
        }
      }
      job_profile_children_aggregate(limit: 1, where: $childrenWhere) {
        aggregate {
          count
        }
      }
    }
  }
`;
