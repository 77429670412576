import React, { useState } from 'react';
import SidebarGroupInfo from './SidebarGroupInfo.component';

import { Group } from '../../Services';
import { useParams } from 'react-router-dom';
import { useAsyncEffect } from 'use-async-effect';
const SidebarGroupInfoBoxContainer = props => {
  const [groupInfo, setGroupInfo] = useState({});
  const params = useParams();
  const groupId = params.id;

  useAsyncEffect(
    async isMount => {
      await Group.Discussion.fetchGroupInfo(groupId).then(response => {
        if (!isMount()) return;
        setGroupInfo(response);
      });
    },
    [groupId]
  );

  const [textWrapRules, setTextWrapRules] = useState(false);
  const [textWrapDescription, setTextWrapDescription] = useState(false);

  const handleShowRules = () => setTextWrapRules(!textWrapRules);

  const handleShowDescription = () =>
    setTextWrapDescription(!textWrapDescription);

  return (
    <SidebarGroupInfo
      dataGroup={groupInfo}
      handleShowRules={handleShowRules}
      handleShowDescription={handleShowDescription}
      textWrapRules={textWrapRules}
      textWrapDescription={textWrapDescription}
      {...props}
    />
  );
};

export default SidebarGroupInfoBoxContainer;
