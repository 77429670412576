import { Popover } from 'antd'
import React, { memo } from 'react'
import './NavBar.style.css'

export default memo(function PopoverDropdown({title, onVisibleChange, content, visible, children}) { 
  return (
    <Popover
      placement="bottomRight"
      title={title}
      visible={visible}
      onVisibleChange={onVisibleChange}
      content={content}
      trigger={'click'}
      overlayStyle={{ position: 'fixed' }}
      overlayClassName='wlb-popup-header'
    >
      {children}
    </Popover>
  )
})
