import React from "react";
import { WrapperSection, WrapperRecmndItm } from "../DetailScreen.style";
import { ThypoStyled } from "../../../../Components/DetailUserSection/DetailUserSection.style";
import { Avatar } from "antd";
import { DirectToUserDetail } from "../../../../Components";

export default function ContentRecommendation({
  recommendation,
  type,
  lastItem = false,
}) {
  const perfix =
    type === "sent" ? "globalUserByRecommendationTo" : "global_user";
  return (
    <DirectToUserDetail userId={recommendation?.[perfix]?.id}>
      <WrapperSection>
        <Avatar src={recommendation?.[perfix]?.avatar} size={64} />
        <WrapperRecmndItm
          style={
            lastItem
              ? {
                  borderBottom: "none",
                }
              : {
                  borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
                  paddingBottom: 16,
                }
          }
        >
          <ThypoStyled
            fontSize="14px"
            color="#000"
            fontWeight="600"
            margin="0px 0px 8px 0px"
          >
            {recommendation?.[perfix]?.name}
          </ThypoStyled>
          <ThypoStyled fontSize="12px" color="#a9a8a8" margin="0px 0px 12px 0px">
            {recommendation?.position}
          </ThypoStyled>
          <ThypoStyled fontSize="12px" color="#014a62" margin="0px 0px 12px 0px">
            {recommendation?.relationship}
          </ThypoStyled>
          <ThypoStyled fontSize="12px" color="#000">
            {recommendation?.description}
          </ThypoStyled>
        </WrapperRecmndItm>
      </WrapperSection>
    </DirectToUserDetail>
  );
}
