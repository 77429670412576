import styled from 'styled-components';
import CONSTANTS from '../../Config/Constants';

export const PrintCVWrap = styled.div`
         @media print {
           -webkit-print-color-adjust: exact;
         }
         width: 851px;
         overflow: visible;
         padding: 40px;
         background: ${CONSTANTS.COLORS.WHITE};
         position: absolute;
         top: 0;
         left: 0;
         transform-origin: top left;
         transform: scale(${props => props.scale});
         .wlb-logo {
           width: 326px;
           position: absolute;
           top: 0;
           left: 100%;
           margin-right: 80px;
         }
         .footer {
           padding: 24px 50px;
           background: #014a62;
           width: 100%;
           .img-footer {
             width: 125px;
           }
         }
       `;
export const PHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  .profile {
    width: 200px;
    height: 200px;
    margin-right: 35px;
  }
  .info {
    p {
      margin: 0
      margin-bottom: 6px;
    }
    .user-name {
      font-size: 22px;
      margin-bottom: 4px;
    }
    .possition {
      font-size: 16px;
      margin-bottom: 4px;
    }
    .location {
      color: #a9a8a8;
      font-size: 12px;
    }
    .url, .phone, .email {
      font-size: 12px;
    }
  }
`;
export const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .title {
    font-size: 24px;
    color: #014a62;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
`;

export const TextPrimary = styled.p`
  color: #014a62;
  font-size: ${props => props.fontSize ?? '12px'};
  margin-bottom: ${props => props.marginBottom ?? '6px'};
`;
export const TextGrey = styled.p`
  color: #a9a8a8;
  font-size: ${props => props.fontSize ?? '12px'};
  margin-bottom: ${props => props.marginBottom ?? '4px'};
`;

export const TextSecondary = styled.p`
  color: #039be5;
  font-size: ${props => props.fontSize ?? '12px'};
  margin-bottom: ${props => props.marginBottom ?? '6px'};
`;
export const TextStandard = styled.p`
  color: #000;
  font-size: ${props => props.fontSize ?? '12px'};
  margin-bottom: ${props => props.marginBottom ?? '6px'};
`;
export const WrapRelative = styled.div`
  position: relative;
`;
