import React from 'react';
import DetailScreenComponent from './DetailScreen.component';
import { useQuery } from 'react-apollo';
import { GET_COMPANY_DESCRIPTION } from './DetailScreen.graphql';
import { isEmpty } from 'lodash';
import { AuthenticationService } from '../../../Services';

const activeCompanyId = AuthenticationService.getCompanyId();

const DetailScreenContainer = props => {
  const companyId = props.match.params.id;
  let dataCompany = {
    description: '-'
  };
  const {
    data,
    refetch
    // loading
  } = useQuery(GET_COMPANY_DESCRIPTION, {
    variables: { id: companyId }
  });
  if (data) {
    if (!isEmpty(data.main_profile)) {
      dataCompany = {
        description: data.main_profile[0].profile_field.description
      };
    }
  }

  return (
    <DetailScreenComponent
      dataCompany={dataCompany}
      companyId={companyId}
      activeCompanyId={activeCompanyId}
      refetch={refetch}
      {...props}
    />
  );
};

export default DetailScreenContainer;
