import React from 'react';
import { Modal, Input } from 'antd';
import RatingStar from './RatingStar';
import { FlexRow, BlueSubTitle } from './ClassroomFeedback.style';

const ModalAddFeedback = props => {
  const {
    isModalVisible,
    submitFeedback,
    toggleModal,
    rating,
    hoverRating,
    onMouseEnter,
    onMouseLeave,
    onSaveRating,
    description,
    setDescription
  } = props;
  const { TextArea } = Input;

  return (
    <Modal
      title="Review"
      visible={isModalVisible}
      onOk={submitFeedback}
      onCancel={toggleModal}
    >
      <BlueSubTitle>Rate Group Learning</BlueSubTitle>
      <FlexRow>
        {[1, 2, 3, 4, 5].map(index => {
          return (
            <RatingStar
              key={index}
              index={index}
              rating={rating}
              hoverRating={hoverRating}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onSaveRating={onSaveRating}
            />
          );
        })}
      </FlexRow>
      <BlueSubTitle>Description</BlueSubTitle>
      <TextArea
        rows={4}
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
    </Modal>
  );
};

export default ModalAddFeedback;
