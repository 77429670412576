import gql from "graphql-tag";

export const ADD_DISCIPLINARY = gql`
  mutation addDisciplineByUser(
    $object: people_profile_disciplinary_insert_input!
  ) {
    insert_people_profile_disciplinary_one(object: $object) {
      id
    }
  }
`;
export const UPDATE_DISCIPLINARY = gql`
  mutation updateDiscipline(
    $id: Int!
    $object: people_profile_disciplinary_set_input!
  ) {
    update_people_profile_disciplinary_by_pk(
      pk_columns: { id: $id }
      _set: $object
    ) {
      id
    }
  }
`;
export const DELETE_DISCIPLINARY = gql`
  mutation($id: Int!, $userId: uuid!) {
    update_people_profile_disciplinary(
      _set: { deletedAt: "now()" }
      where: {
        _and: [
          { id: { _eq: $id } }
          { user: { _eq: $userId } }
          { deletedAt: { _is_null: true } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;
