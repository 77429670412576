import gql from "graphql-tag";

export const GET_LIST_RELATIONSHIP = gql`
  {
    people_profile_recommendation_relationships {
      id
      name
    }
  }
`;

export const GET_LIST_REQUEST_TO = gql`
  query($search: String) {
    global_users(where: { name: { _ilike: $search } }, limit: 10) {
      id
      name
      avatar
      people_work_placements(where: { status: { _eq: "ACTIVE" } }, limit: 1) {
        company_job_profile {
          title
        }
      }
    }
  }
`;
export const GET_PEOPLE_BY_ID = gql`
  query($id: uuid!) {
    global_users(where: { id: { _eq: $id } }, limit: 1) {
      id
      name
      avatar
      people_work_placements(where: { status: { _eq: "ACTIVE" } }, limit: 1) {
        company_employee_position {
          id
          name
        }
        company_organization {
          id
          name
        }
        company_address {
          id
          office_name
        }
        company_job_profile {
          title
        }
      }
    }
  }
`;
export const GET_LIST_POSITION = gql`
  query($userLogin: uuid!) {
    experience: people_profile_experiences(
      where: { user: { _eq: $userLogin } }
    ) {
      id
      company_name
    }

    education: people_profile_educations(where: { user: { _eq: $userLogin } }) {
      company_profile {
        id
        brand_name
      }
    }

    working: people_work_placements(where: { user: { _eq: $userLogin } }) {
      id
      company_job_profile {
        id
        company_profile {
          id
          legal_name
          brand_name
        }
      }
    }
  }
`;

export const REQUEST_RECOMMENDATION = gql`
  mutation($objects: [people_profile_request_recommendations_insert_input!]!) {
    insert_people_profile_request_recommendations(objects: $objects) {
      affected_rows
    }
  }
`;
