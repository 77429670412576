import moment from 'moment';
import React from 'react';
import Assets from '../../../Assets';
import { ThypoStyled } from '../../../Components/DetailUserSection/DetailUserSection.style';
import { TextStandard } from '../UserProfileCV.style';

function Ohsa({ data }) {
  const formatDateDetail = date => moment(date).format('hh:mm in MMMM D, YYYY');
  const employee = data?.employee?.name ?? '-';
  const time_ohsa = formatDateDetail(data.incident_date_time);
  const title = data?.company_name ?? '-';
  const renderValue = (value, secondValue) => {
    if (value !== null && value !== '' && value !== undefined) {
      return value;
    } else {
      return secondValue;
    }
  };
  return (
    <div className="detail-section">
      <TextStandard fontSize="18px">{renderValue(title, '-')}</TextStandard>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.grey}
        fontStyle="italic"
      >
        {renderValue(data?.company_location?.company_location, '-')}
      </ThypoStyled>
      <ThypoStyled fontSize="12px" color="#014a62" margin="0px 0px 12px 0px">
        {`${data?.category ?? '-'}`}
      </ThypoStyled>
      <ThypoStyled
        style={{ textTransform: 'capitalize' }}
        fontSize="12px"
        margin="6px 0px"
        color={Assets.COLOR_PALLETE.blue_link}
      >
        {employee ?? '-'}
      </ThypoStyled>
      <ThypoStyled
        fontSize="12px"
        color={Assets.COLOR_PALLETE.grey}
        fontStyle="italic"
      >
        {data?.incident_location?.incident_location}
      </ThypoStyled>

      <ThypoStyled fontSize="12px" color="#014a62" margin="0px 0px 12px 0px">
        {`${time_ohsa ?? '-'}`}
      </ThypoStyled>
      <ThypoStyled /* height="53px" overflow="hidden" */ fontSize="12px">
        {data?.severity}
      </ThypoStyled>
      <div className="degree"></div>
      <div className="date">{data?.time} </div>
      <div className="description" style={{ whiteSpace: 'pre-line' }}>
        {data.description}
      </div>
      <div style={{ paddingTop: '10px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'start',
            paddingTop: '10px'
          }}
        >
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.grey}
            fontStyle="italic"
          >
            Incident Code :
          </ThypoStyled>
          <ThypoStyled
            fontSize="12px"
            color={Assets.COLOR_PALLETE.black}
            fontStyle="italic"
            style={{ paddingLeft: '10px' }}
          >
            {`${renderValue(data?.incident_code, '-')}`}
          </ThypoStyled>
        </div>
      </div>
    </div>
  );
}

export default Ohsa;
