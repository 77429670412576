import gql from "graphql-tag";

export const GET_LIST_PSYCHOLOGICAL = gql`
  query($userId: uuid!) {
    psychological_tests: people_profile_psychological_tests(
      where: {
        _and: [{ user_id: { _eq: $userId } }, { deletedAt: { _is_null: true } }]
      }
      order_by: [{ test_date: desc_nulls_last }]
    ) {
      id
      name
      subject
      institution
      test_date
      test_cost
      test_score
      test_score_range
      test_result
      recommendation_result
      general_conclusion
      purpose
      attachments
    }
  }
`;
export const ADD_PSYCHOLOGICAL_TEST = gql`
  mutation($objects: [people_profile_psychological_tests_insert_input!]!) {
    insert_people_profile_psychological_tests(objects: $objects) {
      affected_rows
    }
  }
`;
export const DELETE_PSYCHOLOGICAL_TEST = gql`
  mutation($id: Int!) {
    update_people_profile_psychological_tests(
      _set: { deletedAt: "now()" }
      where: { _and: [{ id: { _eq: $id } }, { deletedAt: { _is_null: true } }] }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_PSYCHOLOGICAL_TEST = gql`
  mutation($id: Int!, $objects: people_profile_psychological_tests_set_input!) {
    update_people_profile_psychological_tests_by_pk(
      pk_columns: { id: $id }
      _set: $objects
    ) {
      id
      name
      subject
      institution
      test_date
      test_cost
      test_score
      test_score_range
      test_result
      recommendation_result
      general_conclusion
      purpose
      attachments
    }
  }
`;
