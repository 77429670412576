import Constants from '../../Config/Constants';
import styled from 'styled-components';
import Assets from '../../Assets';

export default {
  container: isShowBottomPostBox => ({
    backgroundColor: Constants.COLORS.WHITE,
    paddingRight: 20,
    paddingLeft: 20,
    minHeight: 140,
    borderRadius: 2.5,
    boxShadow: '0px 1.5px 3px 0px ' + Constants.COLORS.SHADOW
  }),
  textArea: {
    border: 'none',
    resize: 'none'
  }
};

export const PostBoxWrap = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};

  .ant-modal-mask {
    z-index: 100;
  }

  .component_wrapper {
    z-index: 101;
    position: absolute;
    /* position: fixed; */
    top: 70px;
    left: 30%;
    width: 542px;
    min-height: 404px;
    background-color: ${Assets.COLOR_PALLETE.white};
    display: inline-block;
    margin: auto;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    /* overflow: hidden; */
    /* bottom: 0; */
    margin-bottom: 100px;
  }

  .component_title {
    width: 100%;
    padding: 20px 25px;
    font-family: ${Assets.FONT.MULI};
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
    background-color: ${Assets.COLOR_PALLETE.light_blue};
    position: relative;

    img {
      width: 24px;
      margin-right: 10px;
    }

    .component_close {
      position: absolute;
      top: 20px;
      right: 25px;
      cursor: pointer;

      svg {
        font-size: 24px;
        color: ${Assets.COLOR_PALLETE.grey};
      }
    }
  }

  .component_content {
    padding: 25px;
  }

  .textArea {
    font-family: ${Assets.FONT.MULI};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: ${Assets.COLOR_PALLETE.black};
  }

  .textArea:focus {
    box-shadow: none !important;
  }
`;
