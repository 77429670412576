import React from 'react';
import SpecificFollower from './GlobalSpecificFollower.container';
import CompanySpecificFollower from './CompanySpecificFollower.container';
import { useLocation } from 'react-router-dom';
// export { default } from './SpecificFollower.container'
const Index = props => {
  const location = useLocation();
  if (location.pathname !== '/companypost') {
    return <SpecificFollower {...props} />;
  } else {
    return <CompanySpecificFollower {...props} />;
  }
};

export default Index;
