import React from 'react';
import { ConfigProvider, Empty } from 'antd';

import idID from 'antd/es/locale/id_ID';

/**
 * Usage for global antd configuration
 * see: https://ant.design/components/config-provider/
 *
 * `renderEmpty` Usage for global antd empty state by default
 * ex: dropdown empty state
 *
 * but it has no effect for components directly written
 * with `<Empty />` or `<Empty description={your description} />`
 *
 */

export default function AntdConfigProvider({ children }) {
  return (
    <ConfigProvider
      locale={idID}
      renderEmpty={() => <Empty description="Tidak ada data" />}
    >
      {children}
    </ConfigProvider>
  );
}
