import { gql } from 'apollo-boost';

export const GET_CLASSROOM_BY_ID = gql`
  query($classroomId: uuid!) {
    classrooms_by_pk(id: $classroomId) {
      id
      avatar_url
      name
      description
      price
      rules
      is_finished
      is_individual
      academy_course_category {
        code
        name
        id
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
  }
`;

export const CONSUME_APPROVE_CLASSROOM = gql`
  mutation($classroomId: String!, $approve: Boolean!, $message: String) {
    approveRejectContribRequest(
      classroomId: $classroomId
      approve: $approve
      message: $message
    ) {
      success
    }
  }
`;
