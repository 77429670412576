import React from 'react';

import config from './CompanyProfile.config';
import {Enhancer} from '../../SharedComponents';

const CompanyProfileContainer = () => (<div />);

export default Enhancer({
  isShowMenubar: true,
  menuBarList: config.menuBarList,
  defaultMenu: config.defaultMenuBar
})(CompanyProfileContainer);
