import React, { useState } from 'react';
import { Icon, Input, Menu, Tabs, Row, Col, Collapse } from 'antd';
import { isEmpty } from 'lodash';
import { useMediaQuery } from '@material-ui/core';
import DOMPurify from 'dompurify';

import { RegulationScreenWrapper } from './RegulationScreen.style';
import { CenterLayout } from '../../../Components';

const { TabPane } = Tabs;
const { Search } = Input;
const { Panel } = Collapse;
const { Item } = Menu;

const RegulationScreenComponent = props => {
  const { regulationData, handleSearch } = props;
  const [activeMenu, setActiveMenu] = useState(0);
  const [tab, setTab] = useState('menu');

  const isMobileScreen = useMediaQuery('(max-width: 767px)');

  function _renderSelectedContent() {
    if (isEmpty(regulationData)) {
      return null;
    }

    return (
      <div className={'regulation-context'}>
        <div className="button-back">
          {isMobileScreen && (
            <Icon
              onClick={() => setTab('menu')}
              className="icon"
              type="arrow-left"
            />
          )}

          <div className={'selected-regulation'}>
            <span>{regulationData?.[activeMenu]?.name}</span>
          </div>
        </div>

        <div className={'regulation-clause-list'}>
          <Collapse style={{ overflowY: 'auto' }} expandIconPosition={'right'}>
            {regulationData?.[activeMenu]?.company_regulation_clauses?.map(
              clause => (
                <Panel
                  header={clause?.name === '' ? 'no title' : clause.name}
                  key={clause?.name ?? clause.id}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(clause?.content)
                    }}
                  />
                </Panel>
              )
            )}
          </Collapse>
        </div>
      </div>
    );
  }

  const _renderRegulationHeader = () => {
    return (
      <div className={'regulation-search'}>
        <label>Regulasi</label>
        <Search
          className={'search-input'}
          placeholder="Cari Regulasi"
          onSearch={handleSearch}
        />
      </div>
    );
  };

  function _renderRegulationMenu() {
    return (
      <div className={'regulation-catalogue'}>
        {isMobileScreen && _renderRegulationHeader()}
        <Menu
          className={'regulation-list'}
          theme={'light'}
          defaultSelectedKeys={['0']}
          onClick={() => setTab('content')}
        >
          {regulationData.map((item, index) => (
            <Item key={index} onClick={() => setActiveMenu(index)}>
              {item.name}
            </Item>
          ))}
        </Menu>
      </div>
    );
  }

  function _renderRegulationContent() {
    if (isMobileScreen) {
      return (
        <div className={'regulation-content'}>
          <Tabs
            activeKey={tab}
            defaultActiveKey="menu"
            onChange={key => setTab(key)}
          >
            <TabPane key="menu">{_renderRegulationMenu()}</TabPane>
            <TabPane key="content">{_renderSelectedContent()}</TabPane>
          </Tabs>
        </div>
      );
    }

    return (
      <div className={'regulation-content'}>
        {_renderRegulationMenu()}
        {_renderSelectedContent()}
      </div>
    );
  }

  return (
    <Row gutter={16}>
      <Col span={24}>
        <CenterLayout>
          <RegulationScreenWrapper>
            {!isMobileScreen && _renderRegulationHeader()}
            {_renderRegulationContent()}
          </RegulationScreenWrapper>
        </CenterLayout>
      </Col>
    </Row>
  );
};

export default RegulationScreenComponent;
