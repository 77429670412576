import React from "react";
import { WrapperHobbies } from "./Hobbires.style";
import { Avatar, Icon } from "antd";

export default function HobbiesItem({
  description,
  logo,
  name,
  title,
  detail,
  endorsement,
  isUser,
  onClickEdit,
}) {
  return (
    <WrapperHobbies>
      <div className="content">
        <div className="hobbies">
          <div className="hobbies-img">
            <Avatar alt="logo" src={logo} />
          </div>
          {name}
        </div>

        <div className="detail-section">
          <div className="name">{title}</div>
          <div className="wrapper-category">
            {detail &&
              detail.length > 0 &&
              detail.map((res, i) => (
                <div key={i} className="detail-hobbies">
                  {res}
                </div>
              ))}
          </div>
          <div className="description">{description}</div>
          {endorsement && endorsement.length > 0 && (
            <>
              <div className="share">Share the same hobby with :</div>
              <div className="wrapper-recommendation">
                {endorsement.slice(0, 4).map((endorse, idx) => (
                  <Avatar
                    key={idx}
                    src={endorse?.global_user?.avatar}
                    size={38}
                    style={{ marginRight: 8 }}
                  />
                ))}
                {endorsement.length > 4 && (
                  <Avatar size={38} style={{ marginRight: 8 }}>
                    +{endorsement.length - 4}
                  </Avatar>
                )}
              </div>
            </>
          )}
        </div>
        {isUser && (
          <Icon
            onClick={onClickEdit}
            type="edit"
            style={{ color: "#a9a8a8", cursor: "pointer" }}
          />
        )}
      </div>
      <div className="divider"></div>
    </WrapperHobbies>
  );
}
