import styled from 'styled-components';
import Assets from '../../Assets';

export const CollapseWrap = styled.div`
  position: relative;
  background: ${Assets.COLOR_PALLETE.white};

  .ant-collapse {
    border: none;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: unset;
    right: 3.4%;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: ${Assets.COLOR_PALLETE.white};
    padding-left: 0;
    padding: 3.5% 4.3%;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
    font-size: 12px;
    line-height: 17px;
    color: ${Assets.COLOR_PALLETE.dark_blue};
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 4.3%;
  }
`;

export const PanelItem = styled.div`
  display: flex;
  padding: 3.5% 0;

  .group-button {
    display: flex;

    span {
      color: ${Assets.COLOR_PALLETE.black2};
      font-size: 12px;
      line-height: 17px;
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      margin-right: 16px;
    }

    .ant-switch {
      min-width: 26px;
      width: 26px;
      height: 11px;
      top: 5px;
      &:after {
        width: 16px;
        height: 16px;
        top: -4px;
        margin-left: -2px;
      }
    }

    .ant-switch-checked::after {
      margin-left: 2px;
    }
  }

  .text {
    position: relative;
    width: 85%;

    p {
      margin: 0;
      color: ${Assets.COLOR_PALLETE.grey};
      font-size: 11px;
      line-height: 15px;
      font-family: ${Assets.FONT.MULI};
      width: 73.9%;
    }

    p:first-child {
      color: ${Assets.COLOR_PALLETE.black2};
      font-size: 12px;
      line-height: 17px;
      font-family: ${Assets.FONT.MULI_SEMIBOLD};
      margin-bottom: 2.3%;
    }
    p {
      font-size: 12px;
    }
  }

  .ant-btn {
    margin-top: 16px;
    font-size: 12px;
    line-height: 13px;
    font-family: ${Assets.FONT.MULI_SEMIBOLD};
  }
`;
