import styled from 'styled-components';
import Assets from '../../Assets';

const SearchResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  margin-bottom: 40px;

  .search-result-wrapper {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 165px;
  }

  .search-result-category {
    display: flex;
    align-items: center;
    width: 100px;
    height: 170px;
    font-family: 'Muli-Regular', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-align: left;
    color: #014a62;
    padding-right: 20px;
    border-right: solid #e8e6e1;
    border-width: thin;
    margin-left: 30px;
  }

  .search-result-count {
    font-weight: bold;
    font-family: 'Muli-Regular', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #014a62;
  }

  .search-result-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 2px;
    font-family: 'Muli-Regular', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #a9a8a8;
    padding-left: 20px;
    margin-bottom: 15px;
  }
`;

const ResultInformation = styled.div`
  /* display: flex;
  flex-direction: column; */
  padding-left: 20px;
  width: 400px;
`;

const ViewAll = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: solid #e8e6e1;
  border-width: thin;
  justify-content: center;
  display: flex;
  justify-items: center;
  font-family: 'Muli-Regular', sans-serif;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  padding: 20px 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  a {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    font-family: ${Assets.FONT.MULI};
    color: ${Assets.COLOR_PALLETE.dark_blue};
  }
`;

const Wrapper = styled.div`
  .component_search_result {
    width: 100%;
    position: sticky;
    background-color: #fff;
    max-height: 430px;
    min-height: 130px;
    z-index: 102;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    margin-top: 5px;
  }

  .component_search_option_items {
    min-height: 130px;
    max-height: 360px;
    overflow-y: scroll;
  }

  .component_search_spin {
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .component_free_space {
    height: 60px;
    width: 100%;
  }
`;

export { SearchResultsWrapper, ResultInformation, ViewAll, Wrapper };
