import React from 'react';

import { useLocation } from 'react-router-dom';
import Constants from '../../Config/Constants';
import IMAGES from '../../Assets/images';
import style, { TopBoxWrapper } from './TopPostBox.style';
import Assets from '../../Assets';

const { TITLE, VALUE } = Constants.MESSAGES.POST_BOX;

const TopPostBoxComponent = ({ type, handlePostType, isFocus, isFinished }) => {
  const location = useLocation();

  const getColTitle = (title, value, images, imageStyle) => {
    return (
      <div
        className="component_menu_item"
        onClick={() => handlePostType('type', value)}
        style={{
          ...style.title,
          borderBottom:
            isFocus && value === type
              ? '2px solid #039be5'
              : `2px solid ${Assets.COLOR_PALLETE.grey3}`,
          fontWeight: isFocus && value === type ? 'bold' : 'normal'
        }}
      >
        <img src={images} style={imageStyle} alt="icon" />
        {title}
      </div>
    );
  };

  const replaceUrl = location.pathname.split('/')[1];

  return (
    <TopBoxWrapper>
      {getColTitle(TITLE.POST, VALUE.POST, IMAGES.POST_ICON, style.iconPost)}
      {getColTitle(
        TITLE.EVENT,
        VALUE.EVENT,
        IMAGES.EVENT_ICON,
        style.iconEvent
      )}
      {(location.pathname === '/' || replaceUrl === 'profile') &&
        getColTitle(
          TITLE.POLLING,
          VALUE.POLLING,
          IMAGES.POLLING_ICON,
          style.iconTask
        )}
      {location.pathname === '/companypost' &&
        getColTitle(TITLE.TASK, VALUE.TASK, IMAGES.TASK_ICON, style.iconTask)}
      {replaceUrl === 'social-learning' &&
        !isFinished &&
        getColTitle(
          TITLE.TRACKER,
          VALUE.TRACKER,
          IMAGES.TASK_ICON,
          style.iconTask
        )}
    </TopBoxWrapper>
  );
};

export default TopPostBoxComponent;
