import React from 'react';
import { ClickOut } from '../';
import { KudosPostCardWrapper } from './KudosPostCard.style';

// import Constants from '../../Config/Constants';

// const kudosList = Constants.KUDOS;

const KudosPostCard = props => {
  const { kudosList } = props;

  const itemsRendered = kudosList
    ? kudosList.map((item, i) => {
        const onClickHandler = () => {
          if (props.onClickItem) {
            props.onClickItem(item);
          } else {
            return null;
          }
        };
        return (
          <div
            key={i}
            className="component_kudos_post_card_item"
            onClick={onClickHandler}
          >
            <div className="component_kudos_image_wrapper">
              <img src={item.url_image} alt={item.name} />
            </div>
            <div className="component_kudos_post_card_item_right">
              <div className="component_kudos_post_card_item_title">
                {item.name}
              </div>
              <div className="component_kudos_post_card_item_desription">
                {item.desc}
              </div>
            </div>
          </div>
        );
      })
    : '';

  return (
    <ClickOut
      onClickOutside={props.setVisible ? () => props.setVisible(false) : null}
    >
      <KudosPostCardWrapper
        isShow={props.visible}
        className="component_kudos_post_card"
      >
        <div className="component_kudos_post_card_title">Berikan Kudos</div>
        <div className="component_kudos_post_card_items">{itemsRendered}</div>
      </KudosPostCardWrapper>
    </ClickOut>
  );
};

export default KudosPostCard;
