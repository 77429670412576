import gql from "graphql-tag";

export const GET_ASSOC = gql`
  query($search: String, $limit: Int) {
    global_users(
      where: {
        _and: [
          {name: {_ilike: $search}},
          {status: {_eq: "ACTIVE"}},
          {deletedAt: {_is_null: true}}
        ]
      },
      limit: $limit,
      order_by: {name: asc}
    ) {
      id
      name
      status
    }
  }
`;

export const GET_POSITION = gql`
query getPeopleProfileProjectPosition($userId:uuid,$search:String,$limit:Int){
  people_profile_experiences(where:{
    user:{_eq:$userId}
    _or:[
      {
        company_name:{_ilike:$search}
      }
      {
        title:{_ilike:$search}
      }
    ]
  }
  limit:$limit){
      working_at:company_name,
      as:title
  }

  people_profile_educations(where:{
    user:{_eq:$userId}
      _or:[
      {
        company_profile:{
          brand_name:{_ilike:$search}
        }
      }
    ]
  }
  limit:$limit){
      study_at:company_profile{
          brand_name
      }
  }
}
`;

export const UPDATE_PROJECT = gql`
mutation(
  $id: Int!,
  $object: people_profile_projects_set_input!
) {
  update_people_profile_projects_by_pk(
    pk_columns: {id:$id},
    _set: $object
  ) {
    id
  }
}
`;


export const ADD_PROJECT = gql`
  mutation addProjectByUser($object:people_profile_projects_insert_input!){
  insert_people_profile_projects_one(object:$object){
    id
  }
}

`;

export const DELETE_PROJECT = gql`
mutation deleteProject($id: Int!) {
  update_people_profile_projects(
    _set: {deletedAt: "now()"},
    where: {
      _and: [
        {id: {_eq: $id}},
        {deletedAt: {_is_null: true}}
      ]
    }
  ) {
    affected_rows
  }
}
`;
