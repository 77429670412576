import React from 'react';
import { Menu } from 'antd';
import { AccountSettingsMenu } from './SidebarSettings.style';
import PropTypes from 'prop-types';

const SidebarSettings = props => {
  const { handleSwitchMenu, title, menu } = props;

  return (
    <AccountSettingsMenu>
      <label id={'account-settings-title'}>{title}</label>
      <Menu theme={'light'} defaultSelectedKeys={['1']}>
        {menu.map((item, index) => (
          <Menu.Item
            key={`${index + 1}`}
            onClick={() => handleSwitchMenu(item)}
          >
            {item}
          </Menu.Item>
        ))}
      </Menu>
    </AccountSettingsMenu>
  );
};

SidebarSettings.propTypes = {
  title: PropTypes.string.isRequired,
  handleSwitchMenu: PropTypes.func.isRequired,
  menu: PropTypes.array.isRequired
};

export default SidebarSettings;
