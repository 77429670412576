import React from 'react';
import { TopSettingGroup, ButtonWlb } from '..';
import { ContentWrap } from '../VendorShowcase/VendorShowcase.style';
import { Icon, Checkbox } from 'antd';
import { Footer } from './VendorService.style';

const VendorServiceComponent = props => {
  const { agreeChecked, setAgreeChecked, disabledBtn } = props;

  const _Top = () => <TopSettingGroup title="Service Detail" />;

  const _Content1 = () => (
    <ContentWrap>
      <h2>Bayar di Tempat</h2>
      <p>{`your product can be purchased with offline payment system via courier`}</p>
      <h2>Benefits</h2>
      <p>{`Bayar di tempat could attract the new costumers to buy you product`}</p>
      <h2>Terms and Condition</h2>
      <ul>
        <li>
          <Icon type="check" />
          All product that meets terms and condition will be automatically
          registered to Bayar di Tempat
        </li>
        <li>
          <Icon type="check" />
          Shipping and return process must follow the service policies
        </li>
        <li>
          <Icon type="check" />
          Shipping process can only be done using SiCepat. Please attach the
          shipping label to the package you will send.
        </li>
      </ul>
      {_Footer()}
    </ContentWrap>
  );

  const _Footer = () => (
    <Footer>
      <Checkbox checked={agreeChecked} onChange={setAgreeChecked}>
        {' '}
        I agree to Terms and Conditions of Bayar di Tempat
      </Checkbox>
      <ButtonWlb type="primary" size="large" disabled={disabledBtn}>
        Activate Service
      </ButtonWlb>
    </Footer>
  );
  return (
    <div>
      {_Top()}
      {_Content1()}
    </div>
  );
};

export default VendorServiceComponent;
